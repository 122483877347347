/// <reference path="../core.ts" />

class Slideout {
    id: string;
    callingId: string;

    constructor(slideoutId: string, callingId: string) {
        this.id = slideoutId;
        this.callingId = callingId;
    }

    show() {
        let _that = this;
        let element = document.getElementById(this.id);
        Core.trapFocus(element);
        element.classList.add("opened");
        element.setAttribute("aria-hidden", "false");
        let closeElementId = "closeSlideout" + this.id;
        let closeElement = document.getElementById(closeElementId);
        closeElement.addEventListener("click", function (e) { _that.hide() });
        closeElement.addEventListener("keypress", function (e) { if (Core.a11yClick(e)) { _that.hide(); } });
        let h1ElementId = "h1Slideout" + this.id;
        let h1Element = document.getElementById(h1ElementId);
        h1Element.focus();
    }

    hide() {
        let _that = this;
        let element = document.getElementById(this.id);
        element.classList.remove("opened");
        element.setAttribute("aria-hidden", "true");
        let closeElementId = "closeSlideout" + this.id;
        let closeElement = document.getElementById(closeElementId);
        closeElement.removeEventListener("click", function (e) { _that.hide() });
        closeElement.removeEventListener("keypress", function (e) { if (Core.a11yClick(e)) { _that.hide(); } });
        let callingElement = document.getElementById(this.callingId);
        callingElement.focus();
    }
}