/// <reference path="page.ts" />
/// <reference path="interfaces/educatorinduction/Interfaces.ts" />

class EducatorInductionPlanPage extends Page {
    constructor(
        navigationCode: string,
        pageCode: string,
        pageLabel: string,
        planPK: number,
        templateFK: number,
        allowDuplicates: boolean = false,
        customValidationFields: string[] = null,
        runValidation: boolean = true
    ) {
        super(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates, customValidationFields)

        if (runValidation && this._fromSaveElement?.value === 'true') this._validationHandler.validate()
    }
}

class EducatorInductionPlanProfileAndPlanEssentials extends EducatorInductionPlanPage {
    private _profileHandler: ProfileHandler

    constructor(
        navigationCode: string,
        pageCode: string,
        pageLabel: string,
        planPK: number,
        templateFK: number,
        allowDuplicates: boolean = false,
    ) {
        super(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates)
        this._profileHandler = new ProfileHandler(this._navigationCode, this._planPK, 'educatorInduction')
        this.setUpProfile()
    }

    private setUpProfile() {
        //const refreshProfileButton = document.getElementById('k12guidancePlanProfileRefreshProfile') as HTMLButtonElement
        //refreshProfileButton.addEventListener('click', (e: Event) => this.refreshProfile())

        //document.querySelectorAll('[data-percent="1.00"][disabled="disabled"]:not([data-propertygroupcode="801"])')
        //    .forEach(element => this._core.forceElementOptional(element as HTMLElement))

        const saveExecutiveButton = document.getElementById('educatorinductionplanProfileAndPlanEssentialsSaveButtonExecutive') as HTMLButtonElement
        if (saveExecutiveButton) saveExecutiveButton.addEventListener('click', (e: Event) => this._saveHandler.saveExecutiveFieldsForPDEPlanWriters(['4000']))
    }
}

class EducatorInductionPlanPlanCommittee extends EducatorInductionPlanPage { 
    constructor(navigationCode: string, pageCode: string, pageLabel: string, planPK: number, templateFK: number, allowDuplicates: boolean = true) {
        super(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates, null, false);
        this._validationHandler.MinRequiredGroupRows = { 4002: 2 };
        if (this._fromSaveElement && this._fromSaveElement.value === 'true') this._validationHandler.validate();
    }
}

class EducatorInductionPlanMentors extends EducatorInductionPlanPage { }

class EducatorInductionPlanNeedsAssessment extends EducatorInductionPlanPage { }

class EducatorInductionPlanPlanTopicAreas extends EducatorInductionPlanPage {
    constructor(
        navigationCode: string,
        pageCode: string,
        pageLabel: string,
        planPK: number,
        templateFK: number,
        allowDuplicates: boolean = false
    ) {
        super(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates, ['multiSelectTopicAreasTopicAreas'])
        this.constructEventListeners()
    }

    /**
     * Adds all the event listeners to the various elements on the page 
     */
    private constructEventListeners() {

        // Create Accordions
        const accordions = document.getElementsByClassName('plan-topic-areas-accordion') as HTMLCollectionOf<HTMLButtonElement>
        for (const accordion of accordions) new SharedPageAccordion(accordion.id)

        // Wire up the 'Report Selected Topics' button
        const reportSelectedTopicsButton = document.getElementById('educatorInductionPlanTopicAreasReportSelectedTopics') as HTMLButtonElement
        reportSelectedTopicsButton?.addEventListener('click', (e: Event) => this.reportSelectedTopics())

        // Wire up the 'Add' buttons for observational components
        const addObservationalComponentsButtons = document.querySelectorAll('.add-observational-components-button') as NodeListOf<HTMLButtonElement>
        if (addObservationalComponentsButtons.length) {
            for (const button of addObservationalComponentsButtons) {
                const parentPlanPropertyFK = parseInt((button.closest('.Accordion-panel') as HTMLDivElement).dataset.planpropertypk)
                button.addEventListener('click', (e: Event) => this.addObservationalComponents(parentPlanPropertyFK))
            }
        }

        // Wire up the 'Report Additional Topics' Button
        const reportAdditionalTopicButton = document.getElementById('educatorInductionPlanTopicAreasReportAdditionalTopicButton') as HTMLButtonElement
        reportAdditionalTopicButton?.addEventListener('click', (e: Event) => this.reportAdditionalTopic())

        // Wire up the checkboxes to delete the components when going from checked -> unchecked
        const observationalComponentInputs = document.querySelectorAll('input[name="component"]') as NodeListOf<HTMLInputElement>
        for (const component of observationalComponentInputs) {
            component.addEventListener('click', (e: Event) => this.deleteSelectedComponentOnUncheck(component))
        }

        const deleteTopicButtons = document.querySelectorAll('button.delete-topic-button') as NodeListOf<HTMLButtonElement>
        for (const button of deleteTopicButtons) {
            button.addEventListener('click', (e: Event) => this.deleteTopic(button))
        }

        // Wire up the delete buttons on the components
        this.constructDeleteSelectedComponentButtons()
    }

    private async deleteTopic(button: HTMLButtonElement) {
        const topicAccordion = button.closest('.plan-topic-areas-accordion-container') as HTMLDivElement
        const topicPlanPropertyModel: IPlanProperty = {
            PlanFK: this._planPK,
            PlanPropertyPK: parseInt(topicAccordion.dataset.planpropertypk),
            PropertyFK: 0,
            TextValue: null,
            LookupCodeFK: null,
            RowNbr: null,
            IsDeletedInd: false
        } 

        Core.showLoader();

        const response = await fetch(
            `/${this._navigationCode}/DeleteTopic/`,
            {
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ PlanPK: this._planPK, PageCode: this._pageCode, PlanPropertyModels: [topicPlanPropertyModel] })
            }
        )

        if (response?.ok && response.json()) {
            Core.hideLoader();
            topicAccordion.remove()
            const topicOptionInput = document.querySelector(`input[name="topic"][data-planpropertypk="${topicAccordion.dataset.planpropertypk}"]`) as HTMLInputElement
            if(topicOptionInput != null) {
                topicOptionInput.checked = false
                topicOptionInput.dataset.planpropertypk = '0'

                const topicOptionDropdownList = topicOptionInput.closest('#topicOptionsList') as HTMLUListElement
                const topicOptionDropdownLabel = topicOptionInput.closest('#multi-select-plugin').querySelector('label') as HTMLLabelElement

                this.updateMultiSelectDropdownLabelOnDelete(topicOptionDropdownList, topicOptionDropdownLabel)
            }

            Core.createHTMLAlert('alertMessageDiv', 'Successfully deleted the Topic.', 'success', 3000, null)
        } else {
            Core.createHTMLAlert('alertMessageDiv', 'There was an error deleting the Topic, please try again.', 'error', 3000, null)
        }
    }

    /**
     * Wires up the delete component buttons on the page to call the deleteSelectedComponents method 
     */
    private constructDeleteSelectedComponentButtons() {
        const deleteSelectedComponentButtons = document.querySelectorAll('.delete-selected-component-button') as NodeListOf<HTMLButtonElement>
        if (deleteSelectedComponentButtons.length) {
            for (const button of deleteSelectedComponentButtons) {
                button.addEventListener('click', (e: Event) => this.deleteSelectedComponent(button.dataset.planpropertypk))
            }
        }
    }

    /**
     * Provides a conditional statement to only fire the deleteSelectedComponent method if the input goes from
     * checked to unchecked
     * @param component The checkbox of the Observational Component dropdown being checked/unchecked
     */
    private deleteSelectedComponentOnUncheck(component: HTMLInputElement) {
        // Components has been unchecked, and was checked when the page loaded (i.e. is still checked in the DB)
        if (!component.checked && component.dataset.waschecked === 'true') {
            this.deleteSelectedComponent(component.dataset.planpropertypk)
            component.dataset.waschecked = 'false'
        }
    }

    /**
     * Checks to see if there are any outstanding topics to report, and if so makes a call to silently save the page 
     */
    private async reportSelectedTopics() {
        const topicsToReport: NodeListOf<HTMLInputElement> = this.checkForSelectedCheckboxes(
            'topic',
            'Please select a topic to report.',
            'All selected topics have been reported!'
        )

        if (topicsToReport?.length && await this.savePlanTopicAreas(topicsToReport)) this.reloadPageOnReportingTopics()        
    }

    private reloadPageOnReportingTopics() {
        Core.showLoader();
        const url: string = window.location.href
        const fromSave: string = '?fromSave=true'

        if (url.includes(fromSave)) url.replace(fromSave, '')

        window.location.href = url
    }

    private async reportAdditionalTopic() {
        const additionalTopicToReportInput = document.querySelector('[data-propertycode="educatorInductionPlanTopicAreasAdditionalTopic"]') as HTMLInputElement

        if (!additionalTopicToReportInput.value) {
            Core.createHTMLAlert('alertMessageDiv', 'Please enter a name for the additional topic.', 'warning', 3000, null)
            return
        }

        const response = await fetch(
            `/${this._navigationCode}/SaveEducatorInductionPlanTopicAreas/`,
            {
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    PageCode: this._pageCode,
                    PlanPK: this._planPK,
                    PlanPropertyModels: [{
                        PlanPropertyPK: parseInt(additionalTopicToReportInput.dataset.planpropertypk),
                        PlanFK: this._planPK,
                        PropertyFK: parseInt(additionalTopicToReportInput.dataset.propertypk),
                        TextValue: additionalTopicToReportInput.value,
                        LookupCodeFK: null,
                        RowNbr: parseInt(additionalTopicToReportInput.dataset.row),
                        IsDeletedInd: false
                    }]
                })
            }
        )

        if (response?.ok && await response.json()) {
            this.reloadPageOnReportingTopics()
        } else {
            Core.createHTMLAlert('alertMessageDiv', 'There was an issue reporting this topic, please try again.', 'error', 3000, null)
        }
    }

    /**
     * Checks to see that any checkboxes with a given name are selected at all, and if they have been changed
     * @param name The name of the group of checkboxes
     * @param noCheckboxesSelectedMessage The display message for the HTMLAlert if no checkboxes are selected
     * @param noCheckboxesChangedMessage The display message for the HTMLAlert if no checkboxes have been changed (newly checked/unchecked)
     * @param parentPlanPropertyFK The optional parent plan property key (for observational components and timelines)
     */
    private checkForSelectedCheckboxes(
        name: string,
        noCheckboxesSelectedMessage: string,
        noCheckboxesChangedMessage: string,
        parentPlanPropertyFK: number = null
    ): NodeListOf<HTMLInputElement> {
        const parentPlanPropertySelector = parentPlanPropertyFK
            ? `[data-parentplanpropertypk="${parentPlanPropertyFK}"]`
            : ''

        let selectedCheckboxes
            = document.querySelectorAll(
                `input[name="${name}"]${parentPlanPropertySelector}:checked`
            ) as NodeListOf<HTMLInputElement>

        if (!selectedCheckboxes.length) {
            Core.createHTMLAlert('alertMessageDiv', noCheckboxesSelectedMessage, 'warning', 3000, null)
            return
        }

        selectedCheckboxes
            = document.querySelectorAll(
                `input[name="${name}"]${parentPlanPropertySelector}[data-waschecked="false"]:checked, input[name="${name}"]${parentPlanPropertySelector}[data-waschecked="true"]:not(:checked)`
            ) as NodeListOf<HTMLInputElement>

        if (!selectedCheckboxes.length) {
            Core.createHTMLAlert('alertMessageDiv', noCheckboxesChangedMessage, 'warning', 3000, null)
            return
        }

        return selectedCheckboxes
    }

    /**
     * Custom save method for the page.
     * @param propertiesToSave List of all properties to save.
     */
    private async savePlanTopicAreas(propertiesToSave: NodeListOf<HTMLInputElement>): Promise<boolean> {
        Core.showLoader()

        const planPropertiesToSave: IPlanProperty[] = this.getAllPlanPropertiesToSave(propertiesToSave)

        const response = await fetch(
            `/${this._navigationCode}/SaveEducatorInductionPlanTopicAreas/`,
            {
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ PlanPK: this._planPK, PageCode: this._pageCode, PlanPropertyModels: planPropertiesToSave })
            }
        )

        Core.hideLoader()

        return await response.json()
    }

    /**
     * Returns a list of all plan properties that need to be saved.
     * @param elementsToSave The list of elements corresponding to reportable topics (pre-filtered in other methods to
     * include only elements that need plan properties).
     */
    private getAllPlanPropertiesToSave(elementsToSave: NodeListOf<HTMLInputElement>): IPlanProperty[] {
        const allPlanPropertiesToSave: IPlanProperty[] = []

        for (const element of elementsToSave) {
            allPlanPropertiesToSave.push({
                PlanPropertyPK: parseInt(element.dataset.planpropertypk),
                PlanFK: this._planPK,
                PropertyFK: parseInt(element.dataset.propertypk),
                TextValue: element.checked ? 'on' : 'off',
                LookupCodeFK: null,
                RowNbr: this._saveHandler.getRowNumber(element),
                IsDeletedInd: false,
                ParentPlanPropertyPK: "parentplanpropertypk" in element.dataset && parseInt(element.dataset.parentplanpropertypk) > 0 ? parseInt(element.dataset.parentplanpropertypk) : null,
                PlanPropertyRelationTypePK: "propertyrelationtype" in element.dataset && parseInt(element.dataset.propertyrelationtype) > 0 ? parseInt(element.dataset.propertyrelationtype) : null
            })
        }

        return allPlanPropertiesToSave
    }

    /**
     * Checks to see that there are any observational components to save when the user clicks the 'Add' button, calls the saveObservationalComponents method if so.
     * @param parentPlanPropertyFK The plan property PK of the Topic Area checkbox corresponding to the accordion.
     */
    private async addObservationalComponents(parentPlanPropertyFK: number) {
        const componentsToAdd: NodeListOf<HTMLInputElement> = this.checkForSelectedCheckboxes(
            'component',
            'Please select observational components to add.',
            'All selected components have been added!',
            parentPlanPropertyFK
        )

        return componentsToAdd?.length ? await this.saveObservationalComponents(componentsToAdd, parentPlanPropertyFK) : false;
    }

    /**
     * Glorified save method specifically for the observational components. Makes a call to save the page (which will set the checkbox text values to 'on' in
     * the database), then fetches the '_SelectedObservationalComponentsForTeaching.cshtml' view to update the UI.
     * @param componentsToAdd List of the checkboxes to save, which dictate the UI to display the observational components for teaching.
     * @param parentPlanPropertyFK The plan property PK of the Topic Area checkbox corresponding to the accordion.
     */
    private async saveObservationalComponents(componentsToAdd: NodeListOf<HTMLInputElement>, parentPlanPropertyFK: number): Promise<boolean> {
        Core.showLoader();

        try {

            const planPropertiesToSave: IPlanProperty[] = this.getAllPlanPropertiesToSave(componentsToAdd);

            const response = await fetch(
                `/${this._navigationCode}/SaveObservationalComponents/`,
                {
                    method: 'POST',
                    credentials: 'include',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ PlanPK: this._planPK, PageCode: this._pageCode, PlanPropertyModels: planPropertiesToSave })
                }
            );

            Core.hideLoader();

            const text = await response.text()

            if (text) {
                const componentsContainerOuter
                    = document.querySelector(
                        `.observational-compenents-container-outer[data-planpropertyfk="${parentPlanPropertyFK}"]`
                    ) as HTMLDivElement;
                if(componentsContainerOuter != null) {
                    $(componentsContainerOuter).html(text);

                    $('.multiSelectTopicAreasTopicAreas').MultiSelect();

                    const addObservationalComponentsButtons = componentsContainerOuter.querySelectorAll('.add-observational-components-button') as NodeListOf<HTMLButtonElement>;
                    if (addObservationalComponentsButtons.length) {
                        for (const button of addObservationalComponentsButtons) {
                            const parentPlanPropertyFK = parseInt((button.closest('.Accordion-panel') as HTMLDivElement).dataset.planpropertypk)
                            button.addEventListener('click', (e: Event) => this.addObservationalComponents(parentPlanPropertyFK))
                        }
                    }

                    // Wire up the checkboxes to delete the components when going from checked -> unchecked
                    const observationalComponentInputs = componentsContainerOuter.querySelectorAll('input[name="component"]') as NodeListOf<HTMLInputElement>
                    for (const component of observationalComponentInputs) {
                        component.addEventListener('click', (e: Event) => this.deleteSelectedComponentOnUncheck(component))
                    }

                    const deleteSelectedComponentButtons = componentsContainerOuter.querySelectorAll('.delete-selected-component-button') as NodeListOf<HTMLButtonElement>
                    if (deleteSelectedComponentButtons.length) {
                        for (const button of deleteSelectedComponentButtons) {
                            button.addEventListener('click', (e: Event) => this.deleteSelectedComponent(button.dataset.planpropertypk))
                        }
                    }

                    Core.createHTMLAlert('alertMessageDiv', 'Successfully added the Observational Component for Teaching.', 'success', 3000, null);
                }
            }
            else
            {
                return false;
            }
        }
        catch(error)
        {
            return false;
        }

        return true;
    }

    /**
     * Makes a call to 'delete' (set the TextValue to "off") the plan property record in the database and, if successful, updates the UI
     * @param componentPlanPropertyPK The plan property PK of the component to delete
     */
    private async deleteSelectedComponent(componentPlanPropertyPK: string): Promise<boolean> {
        const inputElement = document.querySelector(
            `input[name="component"][data-planpropertypk="${componentPlanPropertyPK}"]`
        ) as HTMLInputElement

        const planProperty: IPlanProperty = {
            PropertyFK: parseInt(inputElement.dataset.propertypk),
            PlanFK: this._planPK,
            PlanPropertyPK: parseInt(inputElement.dataset.planpropertypk),
            TextValue: 'off',
            LookupCodeFK: null,
            IsDeletedInd: false,
            RowNbr: null
        }

        const success: boolean = await this._saveHandler.savePlanProperty(
            planProperty,
            'Successfully deleted the observational component.',
            'There was an error deleting the observational component, please try again.'
        )

        if (success) {
            // Uncheck the component in the components dropdown
            const observationalComponentCheckbox = document.querySelector(
                `input[name="component"][data-planpropertypk="${componentPlanPropertyPK}"]`
            ) as HTMLInputElement
            observationalComponentCheckbox.checked = false;
            observationalComponentCheckbox.dataset.waschecked = "false";

            // Remove the component from the UI
            document.querySelector(`.selected-component-container[data-planpropertypk="${componentPlanPropertyPK}"]`).remove()

            const observationalComponentLabel = observationalComponentCheckbox.closest('li') as HTMLLIElement
            observationalComponentLabel.classList.remove('selected')

            const parentPlanPropertyFK = observationalComponentCheckbox.dataset.parentplanpropertypk

            // Update the rest of the UI depending on if any components are selected or not
            const multiSelectDropdown = document.querySelector(
                `ul.observational-components-list[data-parentplanpropertyfk="${parentPlanPropertyFK}"]`
            ) as HTMLUListElement
            const multiSelectDropdownLabel = document.querySelector(
                `label.observational-components-dropdown-label[data-parentplanpropertyfk="${parentPlanPropertyFK}"]`
            ) as HTMLLabelElement

            const numberOfSelectedObservationalComponents = this.updateMultiSelectDropdownLabelOnDelete(multiSelectDropdown, multiSelectDropdownLabel)

            if (!numberOfSelectedObservationalComponents) {
                const componentsContainerOuter = document.querySelector(`.selected-components-container-outer[data-parentplanpropertyfk="${parentPlanPropertyFK}"]`) as HTMLDivElement
                componentsContainerOuter.classList.add('display-none')

                multiSelectDropdownLabel.innerText = 'Select a value'
            }
        }

        return success
    }

    private updateMultiSelectDropdownLabelOnDelete(dropdown: HTMLUListElement, label: HTMLLabelElement): number {
        const allCheckboxes = dropdown.querySelectorAll('input') as NodeListOf<HTMLInputElement>
        const checkedCheckboxes = dropdown.querySelectorAll('input:checked') as NodeListOf<HTMLInputElement>
        const itemCase = checkedCheckboxes.length === 1 ? 'item' : 'items'
        label.innerText = checkedCheckboxes.length > 0 ? `${checkedCheckboxes.length} ${itemCase} selected` : 'Select a value'

        dropdown.setAttribute('aria-label', `${checkedCheckboxes.length} ${itemCase} selected of ${allCheckboxes.length} items`)

        return checkedCheckboxes.length
    }
}

class EducatorInductionPlanEvaluationAndMonitoring extends EducatorInductionPlanPage { }

class EducatorInductionPlanDocumentationOfParticipationAndCompletion extends EducatorInductionPlanPage { }

class EducatorInductionPlanSignatureAndAssurances extends EducatorInductionPlanPage {
    constructor(navigationCode: string, pageCode: string, pageLabel: string, planPK: number, templateFK: number, allowDuplicates: boolean = false) {
        super(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates)
        this.constructEventListeners()
    }

    private constructEventListeners() {
        const checkboxes = document.querySelectorAll('input[type=checkbox]') as NodeListOf<HTMLInputElement> // Assurance Checkboxes
        const signOffElements = document.querySelectorAll('[data-propertygroupcodepk="4010"]') as NodeListOf<HTMLInputElement> // Signature and Date inputs

        for (const checkbox of checkboxes) {
            if (checkbox.disabled) {
                // Checkboxes are disabled (user doesn't have access), disable sign off
                this.disableSignOffElements(signOffElements)
                return
            } 

            // Checkboxes are enabled (user has access), add toggle sign off listener
            checkbox.addEventListener('click', (e: Event) => this.toggleEnableSignOff(checkboxes, signOffElements))
            this.toggleEnableSignOff(checkboxes, signOffElements)            
        }
    }

    private toggleEnableSignOff(checkboxes: NodeListOf<HTMLInputElement>, signOffElements: NodeListOf<HTMLInputElement>) {
        for (const checkbox of checkboxes) {
            if (!checkbox.checked) {
                // If any checkboxes are unchecked, disable sign off
                this.disableSignOffElements(signOffElements)
                return
            }
        }

        // All checkboxes are checked, enable sign off
        for (const element of signOffElements) element.disabled = false
    }

    private disableSignOffElements(signOffElements: NodeListOf<HTMLInputElement>) {
        for (const element of signOffElements) element.disabled = true
    }
}

class EducatorInductionSummaryChecklistAndSubmission extends EducatorInductionPlanPage {
    private _summaryChecklistAndSubmissionHandler: SummaryChecklistAndSubmissionHandler

    constructor(
        navigationCode: string,
        pageCode: string,
        pageLabel: string,
        planPK: number,
        templateFK: number,
        allowDuplicates: boolean = false
    ) {
        super(navigationCode, pageCode, pageLabel, planPK, templateFK)
        this._summaryChecklistAndSubmissionHandler = new SummaryChecklistAndSubmissionHandler(navigationCode)
    }
}
