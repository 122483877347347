/// <reference path="core.ts" />

class Home {
    constructor() {
        let comprensive = document.getElementById("comprehensivePlans");
        if (comprensive !== null)
            comprensive.addEventListener("click", (e: Event) => this.comprehensivePlans());

        let hideWelcomeMessage = document.getElementById("dontShowAgain");
        if (hideWelcomeMessage !== null)
            hideWelcomeMessage.addEventListener("click", (e: Event) => this.hideMessage());

        let school = document.getElementById("schoolPlans");
        if(school !== null)
            school.addEventListener("click", (e: Event) => this.schoolPlans());

        let stateReports = document.getElementById("stateReports");
        if (stateReports !== null)
            stateReports.addEventListener("click", (e: Event) => this.stateReports());
    }

    comprehensivePlans() {
        window.location.href = "/Home/MyPlans";
    }

    schoolPlans() {
        window.location.href = "/Home/SchoolPlans";
    }

    stateReports() {
        window.location.href = "/Reports/StateRequiredReports";
    }

    hideMessage() {
        let xhr = new XMLHttpRequest();
        xhr.open('GET', '/Home/HideWelcomeMessage');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let close = document.getElementById("closeModalHomeDisclaimer");
                close.click();
            }
            else {
                alert('Request failed.  Returned status of ' + xhr.status);
            }
        };
        xhr.send();
    }
}