/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />

// FederalLEAProfile
class FederalLEAProfile {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["federalLEAProfileField"];

        let federalLEAProfileSaveButton = document.getElementById("federalLEAProfileSave");
        if (federalLEAProfileSaveButton !== null)
            federalLEAProfileSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let refreshButton = document.getElementById("resetProfile");
        if (refreshButton !== null)
            refreshButton.addEventListener("click", (e: Event) => Core.refreshProfile("federalLEAProfileForm"));
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("federalLEAProfileForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("federalLEAProfileField");

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsLEA/SaveFederalLEAProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Page successfully saved. Please wait while the page refreshes.", 'success', 3000, null);
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

// FederalLEAParent
class FederalLEAParent {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["federalLEAParentField", "federalLEAParentRadioField", "federalLEAParentFileField"];

        let federalLEAParentSaveButton = document.getElementById("federalLEAParentSave");
        if (federalLEAParentSaveButton !== null)
            federalLEAParentSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let uploads = document.getElementsByClassName("federalLEAParentFileField");
        for (let upload of uploads)
            upload.addEventListener("change", (e: Event) => this.uploadFile(<HTMLInputElement>e.target));

        const deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e: Event) => this.deleteFileConfirm(e));

        const deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e: Event) => this.deleteFileCancel());

        this.bindDeleteFiles();
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("federalLEAParentForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("federalLEAParentField");

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let radioInputs = document.getElementsByClassName("federalLEAParentRadioField");
        for (let ele of radioInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = "0";

            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "",
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }

        let allFileElements = [];

        let files = document.getElementsByClassName("federalLEAParentFile");
        for (let ele of files) {
            let element = <HTMLInputElement>ele;

            let sequenceNbr = parseInt(element.dataset.sequencenbr);
            let fileUploadPK = parseInt(element.dataset.fileuploadpk);
            let propertyPK = parseInt(element.dataset.propertypk);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk);

            let saveItem: IPlanPropertyFile = {
                SequenceNbr: sequenceNbr,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanPropertyFilePK: planPropertyFilePK,
                PlanFK: planPK
            };

            allFileElements.push(saveItem);
        }

        var allData = {
            "ElementData": allSaveElements,
            "FileData": allFileElements
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsLEA/SaveFederalLEAParent', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Page successfully saved. Please wait while the page refreshes.", 'success', 3000, null);
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }

    uploadFile(e: HTMLInputElement) {
        let core = this._core;
        Core.showLoader();
        let formName = e.dataset.formname;

        let uploadSampleForm = <HTMLFormElement>document.getElementById(formName);
        let formData = new FormData(uploadSampleForm);
        let propertyPK = e.dataset.propertypk;
        let propertyCode = e.id;
        let planPropertyPK = e.dataset.planpropertypk;
        const fileType = e.dataset.filetype;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsLEA/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();

                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);

                        e.value = "";

                        let fileList = document.querySelector(`.uploadedFiles[data-filetype='${fileType}']`)

                        let fileWrapper = <HTMLDivElement>document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = <HTMLDivElement>document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = <HTMLAnchorElement>document.createElement("a");
                        fileA.dataset.planpropertyfilepk = res.payload.planPropertyFilePK;
                        fileA.dataset.planpropertyplanpropertyfilepk = "0";
                        fileA.dataset.planpropertypk = planPropertyPK;
                        fileA.dataset.propertycode = propertyCode;
                        fileA.dataset.sequencenbr = "0";
                        fileA.dataset.propertypk = propertyPK;
                        fileA.classList.add("federalLEAParentFile");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);

                        fileList.appendChild(fileWrapper);

                        let fullContainer = document.querySelector(`.uploadedFiles`);
                        if (fullContainer.classList.contains("hide"))
                            fullContainer.classList.remove("hide");
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }

    bindDeleteFiles() {
        const deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (const deleteFileButton of deleteFileButtons)
            deleteFileButton.addEventListener("click", (e: Event) => this.showDeleteFile(e));
    }

    showDeleteFile(e: Event) {
        const buttonElement = <HTMLButtonElement>e.target;
        const planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        const planFK = buttonElement.dataset.planfk;
        const fileType = buttonElement.dataset.filetype;

        const modal: Modal = new Modal("deleteFileModal", null);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "planpropertyfilepk", planPropertyFilePK);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "planfk", planFK);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "filetype", fileType);
        modal.show();
    }

    deleteFileConfirm(e: Event) {
        const buttonElement = <HTMLButtonElement>e.target;
        const planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        const planFK = parseInt(buttonElement.dataset.planfk);
        const fileType = buttonElement.dataset.filetype;
        const that = this;

        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsLEA/DeleteFileData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    let element = document.querySelector(`.uploadFileColumn[data-planpropertyfilefk='${planPropertyFilePK}'][data-filetype='${fileType}']`);
                    element.remove();

                    const moreFiles = document.querySelectorAll(`.uploadFileColumn[data-filetype='${fileType}']`);
                    if (moreFiles.length === 0) {
                        const uploadsElement = <HTMLDivElement>document.querySelector(`.uploadedFiles[data-filetype='${fileType}']`);
                        if (uploadsElement !== null) {
                            uploadsElement.classList.add("hide");
                        }
                    }

                    Core.createHTMLAlert("alertMessageDiv", "File successfully removed.", 'success', 3000, null);
                } else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the file. Please try again.", 'error', 3000, null);
                }

                Core.hideLoader();

                let modal: Modal = new Modal("deleteFileModal", null);
                modal.hide();
            } else {
                let modal: Modal = new Modal("deleteFileModal", null);
                modal.hide();

                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planPropertyFilePK));
    }

    deleteFileCancel() {
        const modal: Modal = new Modal("deleteFileModal", null);
        modal.hide();
    }
}

// FederalLEATransportation
class FederalLEATransportation {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["federalLEATransportationField", "federalLEATransportationRadioField", "federalLEATransportationCheckboxField", "federalLEATransportationFileField"];

        let federalLEATransportationSaveButton = document.getElementById("federalLEATransportationSave");
        if (federalLEATransportationSaveButton !== null)
            federalLEATransportationSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let uploads = document.getElementsByClassName("federalLEATransportationFileField");
        for (let upload of uploads)
            upload.addEventListener("change", (e: Event) => this.uploadFile(<HTMLInputElement>e.target));
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("federalLEATransportationForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("federalLEATransportationField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let radioInputs = document.getElementsByClassName("federalLEATransportationRadioField");
        for (let ele of radioInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = "0";

            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "",
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }

        let checkInputs = document.getElementsByClassName("federalLEATransportationCheckboxField");
        for (let ele of checkInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let textValue = "";

            if (element.checked)
                textValue = "on";
            else
                textValue = "off";

            planPropertyPK = parseInt(element.dataset.planpropertypk);

            let saveItem: IPlanProperty = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: planPK,
                PropertyFK: propertyPK,
                TextValue: textValue,
                LookupCodeFK: null,
                RowNbr: parseInt(rowNumber),
                IsDeletedInd: false
            };

            allSaveElements.push(saveItem);
        }

        let allFileElements = [];

        let files = document.getElementsByClassName("safeSchoolsFile");
        for (let ele of files) {
            let element = <HTMLInputElement>ele;

            let sequenceNbr = parseInt(element.dataset.sequencenbr);
            let fileUploadPK = parseInt(element.dataset.fileuploadpk);
            let propertyPK = parseInt(element.dataset.propertypk);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk);

            let saveItem: IPlanPropertyFile = {
                SequenceNbr: sequenceNbr,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanPropertyFilePK: planPropertyFilePK,
                PlanFK: planPK
            };

            allFileElements.push(saveItem);
        }

        var allData = {
            "ElementData": allSaveElements,
            "FileData": allFileElements
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsLEA/SaveFederalLEATransportation', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Page successfully saved. Please wait while the page refreshes.", 'success', 3000, null);
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }

    uploadFile(e: HTMLInputElement) {
        let core = this._core;
        Core.showLoader();
        let formName = e.dataset.formname;

        let uploadSampleForm = <HTMLFormElement>document.getElementById(formName);
        let formData = new FormData(uploadSampleForm);
        let propertyPK = e.dataset.propertypk;
        let propertyCode = e.id;
        let planPropertyPK = e.dataset.planpropertypk;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsLEA/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();

                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);

                        e.value = "";

                        let fileList = document.querySelector(`.uploadedFiles`)

                        let fileWrapper = <HTMLDivElement>document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = <HTMLDivElement>document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = <HTMLAnchorElement>document.createElement("a");
                        fileA.dataset.planpropertyfilepk = res.payload.planPropertyFilePK;
                        fileA.dataset.planpropertyplanpropertyfilepk = "0";
                        fileA.dataset.planpropertypk = planPropertyPK;
                        fileA.dataset.propertycode = propertyCode;
                        fileA.dataset.sequencenbr = "0";
                        fileA.dataset.propertypk = propertyPK;
                        fileA.classList.add("federalLEAParentFileField");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);

                        fileList.appendChild(fileWrapper);

                        let fullContainer = document.querySelector(`.uploadedFiles`);
                        if (fullContainer.classList.contains("hide"))
                            fullContainer.classList.remove("hide");
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
}

// FederalLEAHead
class FederalLEAHead {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.checkForExempt();

        this.validationClasses = ["federalLEAHeadField", "federalLEAHeadRadioField", "federalLEAHeadFileField", "federalLEAHeadCheckboxField", "federalLEAHeadSelectField"];

        let federalLEAHeadSaveButton = document.getElementById("federalLEAHeadSave");
        if (federalLEAHeadSaveButton !== null)
            federalLEAHeadSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let uploads = document.getElementsByClassName("federalLEAHeadFileField");
        for (let upload of uploads)
            upload.addEventListener("change", (e: Event) => this.uploadFile(<HTMLInputElement>e.target));

        const federalLEAHeadExempt = document.getElementById("federalLEAHeadExempt");
        if (federalLEAHeadExempt !== null)
            federalLEAHeadExempt.addEventListener("click", (e: Event) => this.checkForExempt());
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("federalLEAHeadForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("federalLEAHeadField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let radioInputs = document.getElementsByClassName("federalLEAHeadRadioField");
        for (let ele of radioInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = "0";

            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "",
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }

        let checkInputs = document.getElementsByClassName("federalLEAHeadCheckboxField");
        for (let ele of checkInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let textValue = "";

            if (element.checked)
                textValue = "on";
            else
                textValue = "off";

            planPropertyPK = parseInt(element.dataset.planpropertypk);

            let saveItem: IPlanProperty = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: planPK,
                PropertyFK: propertyPK,
                TextValue: textValue,
                LookupCodeFK: null,
                RowNbr: parseInt(rowNumber),
                IsDeletedInd: false
            };

            allSaveElements.push(saveItem);
        }

        let selects = document.getElementsByClassName("federalLEAHeadSelectField");
        for (let ele of selects) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);

            let element = <HTMLSelectElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: "",
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let allFileElements = [];
        let files = document.getElementsByClassName("federalLEAHeadFile");
        for (let ele of files) {
            let element = <HTMLInputElement>ele;

            let sequenceNbr = parseInt(element.dataset.sequencenbr);
            let fileUploadPK = parseInt(element.dataset.fileuploadpk);
            let propertyPK = parseInt(element.dataset.propertypk);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk);

            let saveItem: IPlanPropertyFile = {
                SequenceNbr: sequenceNbr,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanPropertyFilePK: planPropertyFilePK,
                PlanFK: planPK
            };

            allFileElements.push(saveItem);
        }

        var allData = {
            "ElementData": allSaveElements,
            "FileData": allFileElements
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsLEA/SaveFederalLEAHead', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Page successfully saved. Please wait while the page refreshes.", 'success', 3000, null);
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }

    uploadFile(e: HTMLInputElement) {
        let core = this._core;
        Core.showLoader();
        let formName = e.dataset.formname;

        let uploadSampleForm = <HTMLFormElement>document.getElementById(formName);
        let formData = new FormData(uploadSampleForm);
        let propertyPK = e.dataset.propertypk;
        let propertyCode = e.id;
        let planPropertyPK = e.dataset.planpropertypk;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsLEA/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();

                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);

                        e.value = "";

                        let fileList = document.querySelector(`.uploadedFiles`)

                        let fileWrapper = <HTMLDivElement>document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = <HTMLDivElement>document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = <HTMLAnchorElement>document.createElement("a");
                        fileA.dataset.planpropertyfilepk = res.payload.planPropertyFilePK;
                        fileA.dataset.planpropertyplanpropertyfilepk = "0";
                        fileA.dataset.planpropertypk = planPropertyPK;
                        fileA.dataset.propertycode = propertyCode;
                        fileA.dataset.sequencenbr = "0";
                        fileA.dataset.propertypk = propertyPK;
                        fileA.classList.add("federalLEAParentFileField");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);

                        fileList.appendChild(fileWrapper);

                        let fullContainer = document.querySelector(`.uploadedFiles`);
                        if (fullContainer.classList.contains("hide"))
                            fullContainer.classList.remove("hide");
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }

    checkForExempt() {
        const federalLEAHeadExempt = <HTMLInputElement>document.getElementById("federalLEAHeadExempt");
        const allElements = document.getElementsByClassName("federalLEAHeadElement");

        for (const ele of allElements) {
            if (ele instanceof HTMLInputElement) {
                const element = <HTMLInputElement>ele;
                if (federalLEAHeadExempt.checked) {
                    element.readOnly = true;
                    element.disabled = true;
                    this._core.forceElementOptional(element);
                }
                else {
                    element.readOnly = false;
                    element.disabled = false;
                    if (element.dataset.originalpercent === "1.0") {
                        this._core.forceElementRequired(element);
                    }
                }
            } else if (ele instanceof HTMLSelectElement) {
                const element = <HTMLSelectElement>ele;
                if (federalLEAHeadExempt.checked) {
                    element.disabled = true;
                    this._core.forceElementOptional(element);
                }
                else {
                    element.disabled = false;
                    if (element.dataset.originalpercent === "1.0") {
                        this._core.forceElementRequired(element);
                    }
                }
            } else if (ele instanceof HTMLTextAreaElement) {
                const element = <HTMLTextAreaElement>ele;
                if (federalLEAHeadExempt.checked) {
                    element.readOnly = true;
                    this._core.forceElementOptional(element);
                }
                else {
                    element.readOnly = false;
                    if (element.dataset.originalpercent === "1.0") {
                        this._core.forceElementRequired(element);
                    }
                }
            }
        }
    }
}

// FederalLEAEquity
class FederalLEAEquity {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["federalLEAEquityField", "federalLEAEquityCheckboxField", "federalLEAEquitySelectField", "federalLEAEquitySpecialSelectField"];

        let federalLEAEquitySaveButton = document.getElementById("federalLEAEquitySave");
        if (federalLEAEquitySaveButton !== null)
            federalLEAEquitySaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let equityWorksheetCreateButton = document.getElementById("equityWorksheetCreate");
        if (equityWorksheetCreateButton !== null)
            equityWorksheetCreateButton.addEventListener("click", (e: Event) => this.addEquityWorksheet());

        this.bindDeleteEquityWorksheets();

        let deleteEquityWorksheetCancelButton = document.getElementById("deleteEquityWorksheetCancel");
        if (deleteEquityWorksheetCancelButton !== null)
            deleteEquityWorksheetCancelButton.addEventListener("click", (e: Event) => this.deleteEquityWorksheetCancel());

        let deleteEquityWorksheetConfirmButton = document.getElementById("deleteEquityWorksheetConfirm");
        if (deleteEquityWorksheetConfirmButton !== null)
            deleteEquityWorksheetConfirmButton.addEventListener("click", (e: Event) => this.deleteEquityWorksheetConfirm(e));
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let allAccordionSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("federalLEAEquityForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("federalLEAEquityField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.dataset.datapointplanpropertypk) {
                let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
                if (element.value !== "" || hadValue) {
                    let saveItem: IPlanPropertyExtended = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: element.value,
                        LookupCodeFK: null,
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false,
                        ButtonRow: 0,
                        DataPointPlanPropertyPK: dataPointPlanPropertyPK
                    };

                    allAccordionSaveElements.push(saveItem);
                }
            } else {
                if (element.value !== "" || hadValue) {
                    let saveItem: IPlanProperty = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: element.value,
                        LookupCodeFK: null,
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };

                    allSaveElements.push(saveItem);
                }
            }
        }

        let checkInputs = document.getElementsByClassName("federalLEAEquityCheckboxField");
        for (let ele of checkInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let textValue = "";

            if (element.checked)
                textValue = "on";
            else
                textValue = "off";

            planPropertyPK = parseInt(element.dataset.planpropertypk);

            if (element.dataset.datapointplanpropertypk) {
                let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
                let saveItem: IPlanPropertyExtended = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: textValue,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    ButtonRow: 0,
                    DataPointPlanPropertyPK: dataPointPlanPropertyPK
                };

                allAccordionSaveElements.push(saveItem);
            } else {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: textValue,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let specialSelects = document.getElementsByClassName("federalLEAEquitySpecialSelectField");
        for (let ele of specialSelects) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);

            let element = <HTMLSelectElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }
        
        let selects = document.getElementsByClassName("federalLEAEquitySelectField");
        for (let ele of selects) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);

            let element = <HTMLSelectElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.dataset.datapointplanpropertypk) {
                let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
                if (element.value !== "" || hadValue) {
                    let saveItem: IPlanPropertyExtended = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: '',
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false,
                        ButtonRow: 0,
                        DataPointPlanPropertyPK: dataPointPlanPropertyPK
                    };

                    allAccordionSaveElements.push(saveItem);
                }
            } else {
                if (element.value !== "" || hadValue) {
                    let saveItem: IPlanProperty = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: '',
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };

                    allSaveElements.push(saveItem);
                }
            }
        }

        let everything = {
            "ElementData": allSaveElements,
            "AccordionElementData": allAccordionSaveElements
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsLEA/SaveFederalLEAEquity', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Page successfully saved. Please wait while the page refreshes.", 'success', 3000, null);
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(everything));
        }
    }

    addEquityWorksheet() {
        let dropdown = <HTMLSelectElement>document.getElementById("equityWorksheetSchoolName");
        let dropdownValue = parseInt(dropdown.value);

        let planForm = document.getElementById("federalLEAEquityForm");
        let planFK = planForm.dataset.planfk;

        let that = this;
        let newRow = 0;

        if (dropdownValue > 0) {
            Core.showLoader();

            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/FederalProgramsLEA/AddEquityWorksheet', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    let container = document.getElementById("equityWorksheets");
                    let newDiv = <HTMLDivElement>document.createElement("div");

                    newDiv.innerHTML = xhr.responseText;

                    container.appendChild(newDiv);

                    let idControl = <HTMLButtonElement>newDiv.querySelector(".Accordion h2 button");
                    let id = idControl.id;

                    new CustomAccordion(id);

                    that.bindDeleteEquityWorksheets();

                    Core.createHTMLAlert("alertMessageDiv", "Equity Plan Worksheet added!", 'success', 2000, null);

                    dropdown.selectedIndex = 0;
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", `Request failed.  Returned status of '${xhr.status}'`, 'error', 3000, null);
                    Core.hideLoader();
                }
            };
            xhr.send(`institutionPK=${dropdownValue}&planFK=${planFK}&row=${newRow}`);
        }
    }

    bindDeleteEquityWorksheets() {
        let allDeletes = document.getElementsByClassName("deleteThisWorksheet");
        for (let del of allDeletes)
            del.addEventListener("click", (e: Event) => this.deleteThisWorksheet(e));
    }

    deleteThisWorksheet(e) {
        let buttonElement = <HTMLButtonElement>e.target;
        let dataPointPlanPropertyPK = buttonElement.dataset.datapointplanpropertypk;

        let modal: Modal = new Modal("deleteEquityWorksheetModal", null);
        modal.addAttributeToElement("deleteEquityWorksheetModal", "#deleteEquityWorksheetConfirm", "datapointplanpropertypk", dataPointPlanPropertyPK);
        modal.show();
    }

    deleteEquityWorksheetCancel() {
        let modal: Modal = new Modal("deleteEquityWorksheetModal", null);
        modal.hide();
    }

    deleteEquityWorksheetConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let planProps = [];
        let dataPointPlanPropertyPK = buttonElement.dataset.datapointplanpropertypk;

        let container = <HTMLDivElement>document.querySelector(`.equityWorksheet[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        let allElements = container.querySelectorAll(`.equityWorksheetField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        for (let ele of allElements) {
            let element = <HTMLElement>ele;
            let planProp = element.dataset.planpropertypk;

            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }

        planProps.push(dataPointPlanPropertyPK);

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsLEA/DeleteEquityWorksheet', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();

                let accordionElement = <HTMLButtonElement>document.querySelector(`.Accordion-trigger[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                accordionElement.parentElement.parentElement.parentElement.remove();

                let modal: Modal = new Modal("deleteEquityWorksheetModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The equity worksheet has been removed.", 'success', 3000, null);
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }
}

// FederalLEASignatures
class FederalLEASignatures {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["federalLEASignaturesField"];

        let federalLEASignaturesSaveButton = document.getElementById("federalLEASignaturesSave");
        if (federalLEASignaturesSaveButton !== null)
            federalLEASignaturesSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("federalLEASignaturesForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("federalLEASignaturesField");

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsLEA/SaveFederalLEASignatures', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Page successfully saved. Please wait while the page refreshes.", 'success', 3000, null);
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

// FederalLEASummary
class FederalLEASummary {
    private _core: Core;
    constructor() {
        this._core = new Core();
        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.back(e));

        let submitButton = document.getElementById("submitPlanButton");
        if (submitButton !== null)
            submitButton.addEventListener("click", (e: Event) => this.submit(e));

        this._core.leftnav(this);
    }

    back(e: Event) {
        let newPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }

    getCore() {
        return this._core;
    }

    submit(e: Event) {
        let element = <HTMLButtonElement>e.target;
        if (!element.classList.contains("disabled")) {
            let planFK = 0;
            planFK = parseInt(element.dataset.planfk);
            let core = this._core;
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/FederalProgramsLEA/SubmitPlan', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "This report has been submitted for review!", 'success', 3000, window.location.reload());

                    setTimeout(function () { window.location.href = '/Reports/StateRequiredReports' }, 3000);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status + "'", 'error', 3000, null);
                }
            };
            xhr.send("planFK=" + planFK);
        }
    }
}
