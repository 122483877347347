/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />

class ReviewAdmin {
    searchForm: HTMLFormElement;
    _core: Core;

    constructor() {
        let that = this;
        this._core = new Core();
        this.searchForm = document.getElementById("reviewSearchForm") as HTMLFormElement;

        this._core.activateSubMenu("pdeApproval");
        this._core.bindChangeLEA();

        let clearButton = document.getElementById("clearSearch");
        if (clearButton !== null) {
            clearButton.addEventListener("click", () => {
                that.clearSearchFilter();
            });
        }

        let pagingHdnInput = document.getElementById("Query_PageNumber") as HTMLInputElement;
        (<any>window).pagingAction = function(pageToGoTo) {
            if(pagingHdnInput !== null && that.searchForm !== null)
            {
                pagingHdnInput.value = pageToGoTo;
                that.searchForm.submit();
            }
        }

        this.searchForm.addEventListener("submit", function(e) {
            e.preventDefault();
            if(that.validateSearch()) {
                if(pagingHdnInput !== null)
                {
                    pagingHdnInput.value = "1";
                }
                that.searchForm.submit();
            }
        });

        $('[data-multi-select]').MultiSelect();
    }

    clearSearchFilter() : void {
        const inputs = this.searchForm.querySelectorAll("input");
        for(let input of inputs) {
            if(input instanceof HTMLInputElement) {
                input.value = "";
                input.checked = false;
            }
            if(input instanceof HTMLSelectElement) {
                input.selectedIndex = 0
            }
        }
        this.searchForm.submit();
    }

    validateSearch() : boolean {
        const daysInReviewMin = this.searchForm.querySelector("[name='Query.DaysInReviewMin']") as HTMLInputElement;
        const daysInReviewMax = this.searchForm.querySelector("[name='Query.DaysInReviewMax']") as HTMLInputElement;
        const timesInRevisionMin = this.searchForm.querySelector("[name='Query.TimesInRevisionMin']") as HTMLInputElement;
        const timesInRevisionMax = this.searchForm.querySelector("[name='Query.TimesInRevisionMax']") as HTMLInputElement;
        const daysSinceSubmissionMin = this.searchForm.querySelector("[name='Query.DaysSinceSubmissionMin']") as HTMLInputElement;
        const daysSinceSubmissionMax = this.searchForm.querySelector("[name='Query.DaysSinceSubmissionMax']") as HTMLInputElement;
        [].forEach.call(this.searchForm.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });

        if(daysInReviewMin !== null && daysInReviewMax !== null && parseInt(daysInReviewMax.value) < parseInt(daysInReviewMin.value)) {
            Core.createHTMLAlert("alertMessageDiv", "Days in Review Max cannot be less than Days in Review Min", "error", 3000, null);
            daysInReviewMax.classList.add("missing-field");
            return false;
        }

        if(timesInRevisionMin !== null && timesInRevisionMax !== null && parseInt(timesInRevisionMax.value) < parseInt(timesInRevisionMin.value)) {
            Core.createHTMLAlert("alertMessageDiv", "Times in Revision Max cannot be less than Times in Revision Min", "error", 3000, null);
            timesInRevisionMax.classList.add("missing-field");
            return false;
        }

        if(daysSinceSubmissionMin !== null && daysSinceSubmissionMax !== null && parseInt(daysSinceSubmissionMax.value) < parseInt(daysSinceSubmissionMin.value)) {
            Core.createHTMLAlert("alertMessageDiv", "Days Since Submission Max cannot be less than Days Since Submission Max Min", "error", 3000, null);
            daysSinceSubmissionMax.classList.add("missing-field");
            return false;
        }

        return true;
    }
}

class ReviewQueue extends ReviewAdmin {
    backToLEAModal: Modal;
    backToLEAPlanPK: number;

    constructor() {
        super();
        let that = this;
        that.backToLEAModal = new Modal("backToLEAModal", null);

        let reviewConfirmButton = document.getElementById("reviewConfirm");
        if (reviewConfirmButton !== null)
            reviewConfirmButton.addEventListener("click", (e: Event) => this.reviewPlanConfirm(e));

        let reviewCancelButton = document.getElementById("reviewCancel");
        if (reviewCancelButton !== null)
            reviewCancelButton.addEventListener("click", (e: Event) => this.reviewPlanCancel(e));

        let backToLEAConfirmButton = document.getElementById("backToLEAConfirm");
        if (backToLEAConfirmButton !== null)
            backToLEAConfirmButton.addEventListener("click", (e: Event) => this.backToLEAConfirm());

        let backToLEACancelButton = document.getElementById("backToLEACancel");
        if (backToLEACancelButton !== null)
            backToLEACancelButton.addEventListener("click", (e: Event) => this.backToLEACancel());

        let emailLinks = document.querySelectorAll(".emailLink[data-plan-pk]");
        for (let link of emailLinks) {
            let linkElement = <HTMLButtonElement>link;
            linkElement.addEventListener("click", () => {
                ReviewQueue.emailLEA(parseInt(linkElement.dataset.planPk));
            });
        }

        this.bindReview();
        this.bindResume();
        this.bindBackToLEA();

        let workflowLogButtons = document.querySelectorAll(".openWorkflowLog[data-workflow-instance-fk][data-planfk]");
        for(let btn of workflowLogButtons)
        {
            let btnEl = <HTMLButtonElement>btn;
            btnEl.addEventListener("click", function() {
                that.showWorkflowLog(parseInt(btnEl.dataset.workflowInstanceFk), parseInt(btnEl.dataset.planfk));
            });
        }

        let workflowChangeButtons = document.querySelectorAll(".moveWorkflow[data-workflow-instance-fk][data-planfk]");
        for(let btn of workflowChangeButtons)
        {
            let btnEl = <HTMLButtonElement>btn;
            btnEl.addEventListener("click", function() {
                that.showWorkflowStateChanger(parseInt(btnEl.dataset.workflowInstanceFk), parseInt(btnEl.dataset.planfk));
            });
        }
    }

    resumePlan(e: Event) {
        let button = <HTMLButtonElement>e.target;
        let planFK = button.dataset.planfk;
        let planTypeCode = button.dataset.plantypecode;
        let planTypeFK = button.dataset.plantypefk;

        window.location.href = `/Review/Resume/${planFK}/${planTypeCode}/${planTypeFK}`;
    }

    reviewPlan(e: Event) {
        let button = <HTMLButtonElement>e.target;
        let planFK = button.dataset.planfk;
        let planTypeCode = button.dataset.plantypecode;
        let planTypeFK = button.dataset.plantypefk;
        let planTypeGroupCodeFK = button.dataset.plantypegroupcodefk;

        //Load Modal
        let modal = new Modal("reviewModal", null);
        modal.addAttributeToElement("reviewModal", "#reviewConfirm", "planfk", planFK);
        modal.addAttributeToElement("reviewModal", "#reviewConfirm", "plantypecode", planTypeCode);
        modal.addAttributeToElement("reviewModal", "#reviewConfirm", "plantypefk", planTypeFK);
        modal.addAttributeToElement("reviewModal", "#reviewConfirm", "plantypegroupcodefk", planTypeGroupCodeFK);
        modal.show();
    }

    reviewPlanConfirm(e: Event) {
        let modal = new Modal("reviewModal", null);
        modal.hide();
        let reviewText = <HTMLTextAreaElement>document.getElementById("reviewEmailCustom");
        let that = this;

        let reviewConfirmButton = <HTMLTextAreaElement>document.getElementById("reviewConfirm");
        let planFK = reviewConfirmButton.dataset.planfk;
        let planTypeCode = reviewConfirmButton.dataset.plantypecode;
        let planTypeFK = reviewConfirmButton.dataset.plantypefk;
        let planTypeGroupCodeFK = reviewConfirmButton.dataset.plantypegroupcodefk;

        Core.showLoader();

        let data: IReviewStart = {
            "PlanFK": parseInt(planFK),
            "EmailText": reviewText.value
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Review/StartReview', true);
        xhr.setRequestHeader('Content-type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {

                var resp = JSON.parse(xhr.response);

                if (resp.success === true) {
                    Core.hideLoader();

                    Core.createHTMLAlert("alertMessageDiv", "The plan is now being reviewed. Please wait while you are redirected to the plan", "success", 3000, null);

                    let redirect = `/Review/Resume/${planFK}/${planTypeCode}/${planTypeFK}`
                    let reviewText = <HTMLTextAreaElement>document.getElementById("reviewEmailCustom");
                    reviewText.value = "";

                    setTimeout(function () { window.location.href = redirect }, 3000);
                } else {
                    Core.createHTMLAlert("alertMessageDiv", resp.reason, "error", 3000, null);
                    Core.hideLoader();
                }

            } else {
                Core.hideLoader();
            }
        };
        xhr.send(JSON.stringify(data));
    }

    reviewPlanCancel(e: Event) {
        let modal = new Modal("reviewModal", null);
        modal.hide();
        let reviewText = <HTMLTextAreaElement>document.getElementById("reviewEmailCustom");
        reviewText.value = "";
    }

    backToLEA(e: Event) {
        let that = this;
        let button = <HTMLButtonElement>e.target;
        that.backToLEAPlanPK = parseInt(button.dataset.planPk);

        that.backToLEAModal.callingId = button.id;
        that.backToLEAModal.show();
        let backToLEAText = <HTMLTextAreaElement>document.getElementById("backToLEAComment");
        backToLEAText.value = "";
    }

    backToLEACancel() {
        let that = this;
        that.backToLEAModal.hide();
    }

    backToLEAConfirm() {

        let that = this;

        that.backToLEAModal.hide();

        let reviewText = <HTMLTextAreaElement>document.getElementById("backToLEAComment");

        Core.showLoader();

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Review/BackToLEA', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {

                var resp = JSON.parse(xhr.response);

                if (resp.success === true) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "The plan/report has been sent back to the LEA.", "success", 3000, window.location.reload());
                } else {
                    Core.createHTMLAlert("alertMessageDiv", resp.reason, "error", 3000, null);
                    Core.hideLoader();
                }

            } else {
                Core.hideLoader();
            }
        };

        xhr.send(`PlanPK=${that.backToLEAPlanPK}&AdditionalComments=${reviewText.value}`);

    }

    bindResume() {
        let reviewStopGapResumePlanButtons = document.getElementsByClassName("reviewStopGapResumePlan");
        for (let resume of reviewStopGapResumePlanButtons)
            resume.addEventListener("click", (e: Event) => this.resumePlan(e));
    }

    bindReview() {
        let reviewStopGapReviewPlanButtons = document.getElementsByClassName("reviewStopGapReviewPlan");
        for (let review of reviewStopGapReviewPlanButtons)
            review.addEventListener("click", (e: Event) => this.reviewPlan(e));
    }

    bindBackToLEA() {
        let reviewStopGapBackToLEAButtons = document.getElementsByClassName("returnToLEA");
        for (let backToLEA of reviewStopGapBackToLEAButtons)
            backToLEA.addEventListener("click", (e: Event) => this.backToLEA(e));
    }

    showWorkflowLog(workflowInstancePK: number, planPK: number)
    {
        let that = this;
        const xhr = new XMLHttpRequest();
        Core.showLoader();
        xhr.open('GET', '/Review/GetWorkflowLog?workflowInstancePK='+workflowInstancePK + '&planPK='+planPK, true);
        xhr.setRequestHeader('Content-type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                const modal: Modal = new Modal('workflowLogModal', null);
                modal.show();
                const body = document.getElementById('workflowLogModalBody');
                if (body != null) {
                    $(body).html(xhr.responseText);
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert('alertMessageDiv', 'An unexpected error occurred', 'error', 3000, null);
            }
        };
        xhr.send();
    }

    showWorkflowStateChanger(workflowInstancePK: number, planPK: number) {
        let that = this;
        const xhr = new XMLHttpRequest();
        Core.showLoader();
        xhr.open('GET', '/Review/GetWorkflowStateChanger?workflowInstancePK='+workflowInstancePK + '&planPK='+planPK, true);
        xhr.setRequestHeader('Content-type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                const modal: Modal = new Modal('workflowStateChanger', null);
                modal.show();
                const workflowStateChangerBody = document.getElementById('workflowStateChangerBody');
                if (workflowStateChangerBody != null) {
                    $(workflowStateChangerBody).html(xhr.responseText);
                    let stateSelector = document.getElementById("moveToState") as HTMLSelectElement;
                    let submitButton = document.getElementById("moveWorkflowInstanceToState") as HTMLButtonElement;
                    if(stateSelector != null)
                    {
                        stateSelector.addEventListener("change", function(e) {
                            let eventContainer = document.getElementById("possibleEventsContainer");

                            eventContainer.classList.add("hide");
                            submitButton.disabled = true;
                            if(stateSelector.value != "")
                            {
                                submitButton.disabled = false;
                                Core.showLoader();
                                const eventXHR = new XMLHttpRequest();
                                eventXHR.open('GET', '/Review/GetWorkflowEventsForState?workflowInstancePK='+workflowInstancePK + '&statePK='+stateSelector.value, true);
                                eventXHR.setRequestHeader('Content-type', 'application/json');
                                eventXHR.onload = function () {
                                    Core.hideLoader();
                                    if (eventXHR.status === 200) {
                                        $(eventContainer).html(eventXHR.responseText);
                                        eventContainer.classList.remove("hide");
                                    } else {
                                        Core.createHTMLAlert('alertMessageDiv', 'An unexpected error occurred loading possible Events', 'error', 3000, null);
                                    }
                                };
                                eventXHR.send();
                            }
                        });
                    }

                    if(submitButton != null) {
                        submitButton.addEventListener("click", function() {
                            if(stateSelector != null && stateSelector.value != "") {
                                Core.showLoader();
                                const moveXHR = new XMLHttpRequest();
                                moveXHR.open('POST', '/Review/MoveWorkflowInstanceToState?workflowInstancePK='+workflowInstancePK + '&statePK='+stateSelector.value, true);
                                moveXHR.setRequestHeader('Content-type', 'application/json');
                                moveXHR.onload = function () {
                                    Core.hideLoader();
                                    var resp = JSON.parse(moveXHR.response)
                                    if (moveXHR.status === 200 && resp.success) {
                                        Core.createHTMLAlert('alertMessageDiv', 'Successfully moved Workflow.', 'success', 3000, window.location.reload());
                                    } else {
                                        Core.createHTMLAlert('alertMessageDiv', 'An unexpected error occurred moving workflow.', 'error', 3000, null);
                                    }
                                };

                                var moveModel = {
                                    WorkflowInstancePK: workflowInstancePK,
                                    StatePK: stateSelector.value,
                                    OmittedEvents: []
                                };
                            
                                let omittedEvents = document.querySelectorAll(".workflowEventToOmit");
                                for(let oe of omittedEvents)
                                {
                                    let oeEl = <HTMLInputElement>oe;
                                    if(!oeEl.checked) {
                                        moveModel.OmittedEvents.push(parseInt(oeEl.value));
                                    }
                                }

                                moveXHR.send(JSON.stringify(moveModel));
                            }
                        });
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert('alertMessageDiv', 'An unexpected error occurred', 'error', 3000, null);
            }
        };
        xhr.send();
    }

    static emailLEA(planPK: number) {
        Core.showLoader();
        let xhr = new XMLHttpRequest();      
        xhr.open('GET', `/Review/GetPlanContactInformation?planFK=${planPK}`, true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {

                var resp = JSON.parse(xhr.response);

                if (resp.success === true) {
                    Core.hideLoader();
                    window.location.href = `mailto:${resp.emailAddress}`;
                } else {
                    Core.createHTMLAlert("alertMessageDiv", resp.reason, "error", 3000, null);
                    Core.hideLoader();
                }

            } else {
                Core.hideLoader();
            }
        };
        xhr.send();
    }
}

class ReviewAssign extends ReviewAdmin {

    constructor() {
        super();
        let that = this;

        this.bindAddButtons();
        this.bindSaveButtons();
        this.bindDeleteButtons();

        //For the autoselect dropdowns (i.e. datalists), when selection is made, update the main input with the data-value
        document.addEventListener("input", (event) => {
            let el = <HTMLElement>event.target;
            if (el.classList.contains("reviewerAutoComplete") && el.hasAttribute("list")) {
                let elInput = <HTMLInputElement>el;
                //Find the corresponding option in the datalist and grab its data-value
                var selectedDataListOption = document.querySelector(`#${elInput.getAttribute("list")} option[value='${elInput.value}'][data-value]`) as HTMLOptionElement;
                if (selectedDataListOption != null) {
                    elInput.dataset.value = selectedDataListOption.dataset.value;
                }
            }
        });
    }

    bindAddButtons() {
        let bindAddButtons = document.getElementsByClassName("addButton");
        for (let bindAddButton of bindAddButtons) {
            bindAddButton.addEventListener("click", (e: Event) => this.addSelect(e));
        }
    }

    bindSaveButtons() {
        let bindSaveButtons = document.getElementsByClassName("assignButton");
        for (let bindSaveButton of bindSaveButtons) {
            bindSaveButton.addEventListener("click", (e: Event) => this.save(e));
        }
    }

    bindDeleteButtons() {
        let bindDeleteButtons = document.getElementsByClassName("deleteButton");
        for (let bindDeleteButton of bindDeleteButtons) {
            let bindDeleteButtonEl = <HTMLButtonElement>bindDeleteButton;
            if (!("deleteEventAdded" in bindDeleteButtonEl.dataset)) {
                bindDeleteButtonEl.addEventListener("click", (e: Event) => this.deleteUser(e));
                bindDeleteButtonEl.dataset.deleteEventAdded = "true";
            }
        }
    }

    addSelect(e: Event) {
        let core = new Core();
        let that = this;
        let button = <HTMLButtonElement>e.currentTarget;
        //let userPK = button.dataset.usereddiruser;
        let currentPage = button.dataset.currentpage;
        let divName = button.dataset.add;
        let groupPk = button.dataset.grouppk;
        let pdeBureauPK = button.dataset.pdebureaupk;
        let frcppInstitutionPK = button.dataset.frcppinstitutionpk;
        let planPK = button.dataset.planFk;
        let div = (<HTMLTableElement>document.getElementById(divName));
        let rows = div.querySelectorAll("select");
        let rowCount = 1;

        for (let row of rows) {
            rowCount++;
        }

        let xhr = new XMLHttpRequest();
        Core.showLoader();
        xhr.open('POST', '/Review/AssignDataFieldRow', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            Core.hideLoader();
            if (xhr.status === 200) {
                //let element = div.querySelector("tbody");
                $(div).append(xhr.responseText);
                Core.createHTMLAlert("alertMessageDiv", "New Reviewer Added", 'success', 3000, null);
                that.bindDeleteButtons();
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("currentPage=" + currentPage +  "&groupPK=" + groupPk + "&pdebureaupk=" + pdeBureauPK + "&frcppInstitutionPK=" + frcppInstitutionPK + "&rowCount=" + rowCount + "&planPK=" + planPK  );
    }

    save(e: Event) {
        let core = new Core();
        let allSaveElements = [];
        let button = <HTMLButtonElement>e.currentTarget;
        let process = parseInt(button.dataset.process);
        let institution = parseInt(button.dataset.institution);
        let divName = button.dataset.div;
        let div = (<HTMLDivElement>document.getElementById(divName));
        let planFK = parseInt(button.dataset.planFk);

        let selectElements = div.getElementsByClassName("groupSelect");
        let selectElementValue = 0;
        for (let selectElement of selectElements) {
            let element = <HTMLSelectElement>selectElement;
            selectElementValue = parseInt(element.value);
            let groupPK = parseInt(element.dataset.group);

            if (selectElementValue !== 0) {
                let saveItem: IReviewAssignUserAccessCreate = {
                    MyPDESuitePortalObjectID: selectElementValue,
                    GroupPK: groupPK,
                    FRCPPInstitutionPK: institution,
                    ProcessPK: process,
                    PlanFK: planFK
                };

                allSaveElements.push(saveItem);
            }
        }

        let autoCompleteElements = div.querySelectorAll(".reviewerAutoComplete[data-group][data-value]");
        for (let autoCompleteElement of autoCompleteElements) {
            let element = <HTMLInputElement>autoCompleteElement;
            if (element.dataset.value !== "") {
                let saveItem: IReviewAssignUserAccessCreate = {
                    MyPDESuitePortalObjectID: parseInt(element.dataset.value),
                    GroupPK: parseInt(element.dataset.group),
                    FRCPPInstitutionPK: institution,
                    ProcessPK: process,
                    PlanFK: planFK
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Review/SaveAssignRecords', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(allSaveElements));
    }

    deleteUser(e: Event) {
        let core = new Core();
        let allSaveElements = [];
        let button = <HTMLButtonElement>e.currentTarget;
        let portalObjectID = parseInt(button.dataset.user);
        let fRCPPInstitutionPK = parseInt(button.dataset.institution);
        let groupPK = parseInt(button.dataset.group);
        let div = Core.findClosest(button, ".reviewerContainer");
        if (portalObjectID === 0 || isNaN(portalObjectID)) {
            div.parentNode.removeChild(div);
            return;
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Review/DeleteAssignedUser', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                //parent.parentElement.removeChild(parent);
                div.parentNode.removeChild(div);
                Core.createHTMLAlert("alertMessageDiv", "User Successfully Removed", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("portalObjectID=" + portalObjectID + "&groupPK=" + groupPK + "&fRCPPInstitutionPK=" + fRCPPInstitutionPK);
    }
}

class ReviewAssignByProcess {
    private _core: Core;

    constructor() {
        let that = this;
        that._core = new Core();
        that._core.bindChangeLEA();

        let processDropDown = document.getElementById("processSelection") as HTMLSelectElement;
        processDropDown.addEventListener("change", () => {
            if(processDropDown.value)
            {
                that.loadProcessDetails(parseInt(processDropDown.value));
            }
            else
            {
                let container = document.getElementById("processGroupDetails");
                if (container != null) {
                    container.innerHTML = "";
                }
            }
        });

        //For the autoselect dropdowns (i.e. datalists), when selection is made, update the main input with the data-value
        document.addEventListener("click", (event) => {
            let el = <HTMLElement>event.target;
            if (el.classList.contains("addAllInstProcessGroupUser") && "processGroupPk" in el.dataset) {
                //See if there's a value for user selected
                var userSelection = document.querySelector(`.allInstProcessGroupUserSelect[data-process-group-pk='${el.dataset.processGroupPk}']`) as HTMLSelectElement;
                if(userSelection != null && userSelection.value)
                {
                    that.addProcessGroupUser(parseInt(el.dataset.processGroupPk), parseInt(userSelection.value));
                }
                else
                {
                    Core.createHTMLAlert("alertMessageDiv", "Please select a reviewer to add.", 'error', 3000, null);
                }
            } else if(el.classList.contains("removeAllInstProcessGroupUser") && "processGroupUserPk" in el.dataset) {
                that.removeProcessGroupUser(parseInt(el.dataset.processGroupUserPk));
            }
        });
    }

    loadProcessDetails(processFK: number) {
        Core.showLoader();
        let xhr = new XMLHttpRequest();
        xhr.open('GET', `/Review/GetAssignByProcessDetails?processPK=${processFK}`, true);
        xhr.setRequestHeader('Content-type', 'application/json');
        xhr.onload = function () {   
            Core.hideLoader();
            if (xhr.status === 200) {
                let container = document.getElementById("processGroupDetails");
                if (container != null) {
                    container.innerHTML = xhr.responseText;
                }
            }
            else
            {
                Core.createHTMLAlert("alertMessageDiv", "There was an error loading details for this process.", 'error', 3000, null);
            }
        };
        xhr.send();
    }

    addProcessGroupUser(processGroupFK: number, userFK: number) {
        let that = this;
        Core.showLoader();
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Review/AssignUserToProcess', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            Core.hideLoader();
            if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                let processDropDown = document.getElementById("processSelection") as HTMLSelectElement;
                that.loadProcessDetails(parseInt(processDropDown.value));
            } else {
                Core.createHTMLAlert("alertMessageDiv", "There was an error adding reviewer.", 'error', 3000, null);
            }
        };
        xhr.send(`processGroupPK=${processGroupFK}&userFK=${userFK}`);
    }

    removeProcessGroupUser(processGroupUserFK: number) {
        let that = this;
        Core.showLoader();
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Review/RemoveAllInstProcessAccessForUser', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            Core.hideLoader();
            if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                let processDropDown = document.getElementById("processSelection") as HTMLSelectElement;
                that.loadProcessDetails(parseInt(processDropDown.value));
            } else {
                Core.createHTMLAlert("alertMessageDiv", "There was an error removing reviewer.", 'error', 3000, null);
            }
        };
        xhr.send(`processGroupUserPK=${processGroupUserFK}`);
    }
}


class SpecialEducationReviewAssign {
    validationClasses: string[];
    private _core: Core;

    constructor() {
        this._core = new Core();

        this.bindAddRowButtons();
        this.bindSaveButtons();
        this.bindDeleteButtons();
        this.bindDeleteRowButtons();

        //this._core.activateSubMenu("pdeApproval");
    }

    bindSaveButtons() {
        let saveUserAccessButtons = document.getElementsByClassName("saveUserAccess");
        for (let saveUserAccessButton of saveUserAccessButtons) {
            saveUserAccessButton.addEventListener("click", (e: Event) => this.save(e));
        }
    }

    bindAddRowButtons() {
        let bindAddRowButtons = document.getElementsByClassName("addAnotherRow");
        for (let bindAddRowButton of bindAddRowButtons) {
            bindAddRowButton.addEventListener("click", (e: Event) => this.addRow(e));
        }
    }

    bindDeleteButtons() {
        let deleteUserAccessButtons = document.getElementsByClassName("deleteUserAccess");
        for (let deleteUserAccessButton of deleteUserAccessButtons) {
            deleteUserAccessButton.addEventListener("click", (e: Event) => this.deleteUserAccess(e));
        }
    }

    bindDeleteRowButtons() {
        let deleteUserAccessButtons = document.getElementsByClassName("deleteRow");
        for (let deleteUserAccessButton of deleteUserAccessButtons) {
            deleteUserAccessButton.addEventListener("click", (e: Event) => this.deleteUserRow(e));
        }
    }

    save(e: Event) {
        let core = new Core();
        Core.showLoader();
        let allSaveElements = [];
        let allDeleteElements = [];
        let saveObject: ISaveSpecialEducationNetwork;
        let button = <HTMLButtonElement>e.currentTarget;
        let userid = button.dataset.userid;
        let tableid = button.dataset.tableid;
        let table = (<HTMLTableElement>document.getElementById(tableid));
        let rows = table.querySelectorAll("tr[data-rownumber]");

        for (let row of rows) {
            let thisrow = <HTMLTableRowElement>row;
            let rowid = thisrow.dataset.rownumber;
            let select = document.getElementById("select_" + rowid);
            let selectElement = <HTMLSelectElement>select;
            let selectElementValue = 0;
            selectElementValue = parseInt(selectElement.value);
            let reviewer = document.getElementById("reviewer_" + rowid);
            let reviewerElement = <HTMLInputElement>reviewer;
            if (reviewerElement.checked) {
                let saveItem: ISpecialEducationCreate = {
                    SpecialEducationLearningNetworkFK: selectElementValue,
                    UserFK: parseInt(userid),
                    SeprnPK: parseInt(reviewerElement.dataset.seprn),
                    ReviewPK: parseInt(reviewerElement.dataset.review),
                };
                allSaveElements.push(saveItem);
            };
            if (reviewerElement.checked === false && (reviewerElement.dataset.currentlyisassigned).toLowerCase() === "true") {
                let deleteitem: ISpecialEducationDeleteNetwork = {
                    SpecialEducationLearningNetworkFK: selectElementValue,
                    UserFK: parseInt(userid),
                    SeprnPK: parseInt(reviewerElement.dataset.seprn),
                    ReviewPK: parseInt(reviewerElement.dataset.review),
                };
                allDeleteElements.push(deleteitem);
            };

            let approver = document.getElementById("approver_" + rowid);
            if(approver != null) {
                let approverElement = <HTMLInputElement>approver;
                if (approverElement.checked) {
                    let saveItem: ISpecialEducationCreate = {
                        SpecialEducationLearningNetworkFK: selectElementValue,
                        UserFK: parseInt(userid),
                        SeprnPK: parseInt(approverElement.dataset.seprn),
                        ReviewPK: parseInt(approverElement.dataset.review),
                    };
                    allSaveElements.push(saveItem);
                };
                if (approverElement.checked === false && (approverElement.dataset.currentlyisassigned).toLowerCase() === "true") {
                    let deleteItem: ISpecialEducationDeleteNetwork = {
                        SpecialEducationLearningNetworkFK: selectElementValue,
                        UserFK: parseInt(userid),
                        SeprnPK: parseInt(approverElement.dataset.seprn),
                        ReviewPK: parseInt(approverElement.dataset.review),
                    };
                    allDeleteElements.push(deleteItem);
                };
            }
        }

        saveObject = {
            SaveList: allSaveElements,
            DeleteList: allDeleteElements,
            UserPK: parseInt(userid)
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Review/SaveSpecialEducationAssignRecords', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            Core.hideLoader();
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, core.pageReload(true, null));
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));
    }

    deleteUserAccess(e: Event) {
        let core = new Core();
        Core.showLoader();
        let allSaveElements = [];
        let allDeleteElements = [];
        let saveObject: ISaveSpecialEducationNetwork;
        let button = <HTMLButtonElement>e.currentTarget;
        let userid = button.dataset.userid;
        let tableid = button.dataset.tableid;
        let table = (<HTMLTableElement>document.getElementById(tableid));
        let rows = table.querySelectorAll("tr[data-rownumber]");

        for (let row of rows) {
            let thisrow = <HTMLTableRowElement>row;
            let rowid = thisrow.dataset.rownumber;
            let select = document.getElementById("select_" + rowid);
            let selectElement = <HTMLSelectElement>select;
            let selectElementValue = 0;
            selectElementValue = parseInt(selectElement.value);
            let reviewer = document.getElementById("reviewer_" + rowid);
            let reviewerElement = <HTMLInputElement>reviewer;
            if (reviewerElement.checked) {
                let deleteitem: ISpecialEducationDeleteNetwork = {
                    SpecialEducationLearningNetworkFK: selectElementValue,
                    UserFK: parseInt(userid),
                    SeprnPK: parseInt(reviewerElement.dataset.seprn),
                    ReviewPK: parseInt(reviewerElement.dataset.review),
                };
                allDeleteElements.push(deleteitem);
            };
            if (reviewerElement.checked === false && (reviewerElement.dataset.currentlyisassigned).toLowerCase() === "true") {
                let deleteitem: ISpecialEducationDeleteNetwork = {
                    SpecialEducationLearningNetworkFK: selectElementValue,
                    UserFK: parseInt(userid),
                    SeprnPK: parseInt(reviewerElement.dataset.seprn),
                    ReviewPK: parseInt(reviewerElement.dataset.review),
                };
                allDeleteElements.push(deleteitem);
            };

            let approver = document.getElementById("approver_" + rowid);
            if(approver != null) {
                let approverElement = <HTMLInputElement>approver;
                if (approverElement.checked) {
                    let deleteItem: ISpecialEducationDeleteNetwork = {
                        SpecialEducationLearningNetworkFK: selectElementValue,
                        UserFK: parseInt(userid),
                        SeprnPK: parseInt(approverElement.dataset.seprn),
                        ReviewPK: parseInt(approverElement.dataset.review),
                    };
                    allDeleteElements.push(deleteItem);
                };
                if (approverElement.checked === false && (approverElement.dataset.currentlyisassigned).toLowerCase() === "true") {
                    let deleteItem: ISpecialEducationDeleteNetwork = {
                        SpecialEducationLearningNetworkFK: selectElementValue,
                        UserFK: parseInt(userid),
                        SeprnPK: parseInt(approverElement.dataset.seprn),
                        ReviewPK: parseInt(approverElement.dataset.review),
                    };
                    allDeleteElements.push(deleteItem);
                };
            }
        }

        saveObject = {
            SaveList: allSaveElements,
            DeleteList: allDeleteElements,
            UserPK: parseInt(userid)
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Review/SaveSpecialEducationAssignRecords', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            Core.hideLoader();
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, core.pageReload(true, null));
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));
    }

    addRow(e: Event) {
        let core = new Core();
        let that = this;
        let button = <HTMLButtonElement>e.currentTarget;
        let userid = button.dataset.userid;
        let tableid = button.dataset.tableid;
        let isReviewer = button.dataset.isreviewer;
        let isApprover = button.dataset.isapprover;
        let table = (<HTMLTableElement>document.getElementById(tableid));
        let rows = document.querySelectorAll("tr[data-rownumber]");
        let rowCount = 1;

        for (let row of rows) {
            rowCount++;
        }

        let xhr = new XMLHttpRequest();
        Core.showLoader();
        xhr.open('POST', '/Review/SpecialEducationAssignDataFieldRow', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            Core.hideLoader();
            if (xhr.status === 200) {
                let element = table.querySelector("tbody");
                $(element).append(xhr.responseText);
                Core.createHTMLAlert("alertMessageDiv", "New Reviewer Added", 'success', 3000, null);
                that.bindDeleteRowButtons();
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("userPK=" + userid + "&rowCount=" + rowCount + "&isApprover=" + isApprover + "&isReviewer=" + isReviewer);
    }

    deleteUserRow(e: Event) {
        let core = new Core();
        Core.showLoader();
        let allSaveElements = [];
        let allDeleteElements = [];
        let saveObject: ISaveSpecialEducationNetwork;
        let button = <HTMLButtonElement>e.currentTarget;
        let userid = button.dataset.userid;
        let rowid = button.dataset.rowid;
        let row = (<HTMLTableRowElement>document.getElementById(rowid));
        let parent = button.parentElement.parentElement; //To the TR

        let select = document.getElementById("select_" + rowid);
        let selectElement = <HTMLSelectElement>select;
        let selectElementValue = 0;
        selectElementValue = parseInt(selectElement.value);
        let reviewer = document.getElementById("reviewer_" + rowid);
        let reviewerElement = <HTMLInputElement>reviewer;
        if (reviewerElement.checked) {
            let deleteitem: ISpecialEducationDeleteNetwork = {
                SpecialEducationLearningNetworkFK: selectElementValue,
                UserFK: parseInt(userid),
                SeprnPK: parseInt(reviewerElement.dataset.seprn),
                ReviewPK: parseInt(reviewerElement.dataset.review),
            };
            allDeleteElements.push(deleteitem);
        };
        if (reviewerElement.checked === false && (reviewerElement.dataset.currentlyisassigned).toLowerCase() === "true") {
            let deleteitem: ISpecialEducationDeleteNetwork = {
                SpecialEducationLearningNetworkFK: selectElementValue,
                UserFK: parseInt(userid),
                SeprnPK: parseInt(reviewerElement.dataset.seprn),
                ReviewPK: parseInt(reviewerElement.dataset.review),
            };
            allDeleteElements.push(deleteitem);
        };

        let approver = document.getElementById("approver_" + rowid);
        if(approver != null) {
            let approverElement = <HTMLInputElement>approver;
            if (approverElement.checked) {
                let deleteItem: ISpecialEducationDeleteNetwork = {
                    SpecialEducationLearningNetworkFK: selectElementValue,
                    UserFK: parseInt(userid),
                    SeprnPK: parseInt(approverElement.dataset.seprn),
                    ReviewPK: parseInt(approverElement.dataset.review),
                };
                allDeleteElements.push(deleteItem);
            };
            if (approverElement.checked === false && (approverElement.dataset.currentlyisassigned).toLowerCase() === "true") {
                let deleteItem: ISpecialEducationDeleteNetwork = {
                    SpecialEducationLearningNetworkFK: selectElementValue,
                    UserFK: parseInt(userid),
                    SeprnPK: parseInt(approverElement.dataset.seprn),
                    ReviewPK: parseInt(approverElement.dataset.review),
                };
                allDeleteElements.push(deleteItem);
            };
        }

        saveObject = {
            SaveList: allSaveElements,
            DeleteList: allDeleteElements,
            UserPK: parseInt(userid)
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Review/SaveSpecialEducationAssignRecords', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            Core.hideLoader();
            if (xhr.status === 200) {
                parent.parentElement.removeChild(parent);
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, core.pageReload(true, null));
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));
    }
}

class AuthorizingLEAReviewDashboard extends ReviewQueue {
    constructor() {
        super();

        let exports = document.getElementsByClassName("exportToWord") as HTMLCollectionOf<HTMLButtonElement>;
        for (let exportOption of exports) {
            exportOption.addEventListener("click", function () {
                Core.showLoader();

                //Get all components for given template
                let xhr = new XMLHttpRequest();
                xhr.open('GET', `/ExportDocx/FullReportCharterSchoolExport?planFK=${exportOption.dataset.planPk}`, true);
                xhr.responseType = 'blob';
                xhr.onload = function () {

                    Core.hideLoader();

                    if (xhr.status === 200) {
                        let blob = this.response;
                        let leaName = "";
                        let leaNameElement = document.querySelector(`.districtName[data-plan-pk='${exportOption.dataset.planPk}']`);
                        if (leaNameElement != null) {
                            leaName = leaNameElement.innerHTML;
                        }
                        let filename = `${leaName} - Charter School Annual Report.docx`;

                        if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                            window.navigator.msSaveBlob(blob, filename);
                        } else {
                            var a = <HTMLAnchorElement>document.createElement("a");
                            var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                            document.body.appendChild(a);
                            a.style.display = "none";
                            a.href = blobUrl;
                            a.download = filename;
                            a.click();
                        }
                    } else {
                        Core.createHTMLAlert("alertMessageDiv", "There was an issue generating this report. Please try again later.", 'error', 3000, null);
                    }
                }
                xhr.send();
            });
        }

        this._core.activateSubMenu("AuthorizingLEAReviewQueue");
    }
}