/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />
/// <reference path="controls/modal.ts" />

// SafeSchoolsBullyingProfile
class SafeSchoolsBullyingProfile {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["safeSchoolsBullyingProfileField"];

        let safeSchoolsBullyingProfileSaveButton = document.getElementById("safeSchoolsBullyingProfileSave");
        if (safeSchoolsBullyingProfileSaveButton !== null)
            safeSchoolsBullyingProfileSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        let educatorEffectivenessExecutiveSaveButton = document.getElementById("ExecutiveSave");
        if (educatorEffectivenessExecutiveSaveButton !== null)
            educatorEffectivenessExecutiveSaveButton.addEventListener("click", (e: Event) => this.executiveSave("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let refreshButton = document.getElementById("resetProfile");
        if (refreshButton !== null)
            refreshButton.addEventListener("click", (e: Event) => Core.refreshProfile("safeSchoolsBullyingProfileForm"));
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("safeSchoolsBullyingProfileForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("safeSchoolsBullyingProfileField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }
                                                        
        let selects = document.getElementsByClassName("safeSchoolsBullyingProfileSelectField");
        for (let ele of selects) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);

            let element = <HTMLSelectElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: "",
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/SaveSafeSchoolsBullyingProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    executiveSave(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("safeSchoolsBullyingProfileForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("profileICDataSave");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/SaveSafeSchoolsBullyingProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

// SafeSchoolsBullyingPolicy
class SafeSchoolsBullyingPolicy {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["safeSchoolsBullyingPolicyField", "safeSchoolsBullyingPolicyRadioField", "safeSchoolsBullyingPolicyCheckboxField", "safeSchoolsBullyingPolicyFileField"];

        let safeSchoolsBullyingPolicySaveButton = document.getElementById("safeSchoolsBullyingPolicySave");
        if (safeSchoolsBullyingPolicySaveButton !== null)
            safeSchoolsBullyingPolicySaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let deleteFileConfirmButton = <HTMLButtonElement>document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e: Event) => this.deleteFileConfirm(deleteFileConfirmButton));

        let deleteFileCancelButton = <HTMLButtonElement>document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e: Event) => this.deleteFileCancel());

        let deleteFileButtons = document.getElementsByClassName("deleteFile") as HTMLCollectionOf<HTMLButtonElement>;
        for (let deleteButton of deleteFileButtons)
            deleteButton.addEventListener("click", (e: Event) => this.deleteFile(e));

        let fileUploader = document.getElementById("safeSchoolsBullyingPolicyUploadofBullyingPolicy");
        if (fileUploader !== null)
            fileUploader.addEventListener("change", (e: Event) => this.uploadFile(<HTMLInputElement>e.target));

        this.bindFileDeletes();
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("safeSchoolsBullyingPolicyForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("safeSchoolsBullyingPolicyField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let radioInputs = document.getElementsByClassName("safeSchoolsBullyingPolicyRadioField");
        for (let ele of radioInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = "0";

            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "",
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }

        let checkInputs = document.getElementsByClassName("safeSchoolsBullyingPolicyCheckboxField");
        for (let ele of checkInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = "0";

            let propertyPK = parseInt(element.dataset.propertypk);
            planPropertyPK = parseInt(element.dataset.planpropertypk);
            let textValue = "";

            if (element.checked) {
                textValue = "on";
            } else {
                textValue = "off";
            }

            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
            }

            let saveItem = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: planPK,
                PropertyFK: propertyPK,
                TextValue: textValue,
                LookupCodeFK: null,
                RowNbr: parseInt(rowNumber),
                IsDeletedInd: false,
            };
            allSaveElements.push(saveItem);
        }

        let allFileElements = [];

        let files = document.getElementsByClassName("safeSchoolsFile");
        for (let ele of files) {
            let element = <HTMLInputElement>ele;

            let sequenceNbr = parseInt(element.dataset.sequencenbr);
            let fileUploadPK = parseInt(element.dataset.fileuploadpk);
            let propertyPK = parseInt(element.dataset.propertypk);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk);

            let saveItem: IPlanPropertyFile = {
                SequenceNbr: sequenceNbr,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanPropertyFilePK: planPropertyFilePK,
                PlanFK: planPK
            };

            allFileElements.push(saveItem);
        }

        var allData = {
            "ElementData": allSaveElements,
            "FileData": allFileElements
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/SaveSafeSchoolsBullyingPolicy', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }

    uploadFile(e: HTMLInputElement) {
        let core = this._core;
        Core.showLoader();
        let formName = e.dataset.formname;

        let uploadSampleForm = <HTMLFormElement>document.getElementById(formName);
        let formData = new FormData(uploadSampleForm);

        let propertyPK = e.dataset.propertypk;

        let that = this;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();

                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);

                        let formFile = <HTMLInputElement>document.getElementById(`${e.id}`);
                        formFile.value = "";

                        let fileList = document.getElementById("uploadedFiles");

                        let currentFileList = fileList.querySelectorAll(".uploadFileList");

                        let sequence = -1;
                        for (let file of currentFileList) {
                            let fileEle = <HTMLDivElement>file;

                            let thisSequence = parseInt(fileEle.dataset.sequencenbr);
                            if (thisSequence > sequence) {
                                sequence = thisSequence;
                            }
                        }

                        let uploadedContainer = document.getElementById("uploadedFiles");
                        uploadedContainer.classList.remove("hide");

                        sequence++;

                        let fileWrapper = <HTMLDivElement>document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = <HTMLDivElement>document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = <HTMLAnchorElement>document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.dataset.sequencenbr = sequence.toString();
                        fileA.dataset.propertypk = propertyPK;
                        fileA.classList.add("safeSchoolsFile");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);

                        fileList.appendChild(fileWrapper);

                        that.bindFileDeletes();
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }

    bindFileDeletes() {
        let allButtons = document.getElementsByClassName("deleteFile");
        for (let delButton of allButtons)
            delButton.addEventListener("click", (e: Event) => this.deleteFile(e));
    }

    deleteFile(e: Event) {
        let fileButton = <HTMLButtonElement>e.target;
        let planPropertyFilePK = fileButton.dataset.planpropertyfilepk;

        if (planPropertyFilePK && parseInt(planPropertyFilePK) > 0) {
            let modal: Modal = new Modal("deleteFileModal", null);
            let deleteConfirmButton = <HTMLButtonElement>document.getElementById("deleteFileConfirm");
            deleteConfirmButton.dataset.planpropertyfilepk = planPropertyFilePK;
            modal.show();
        }
    }

    deleteFileConfirm(confirmButton: HTMLButtonElement) {
        let core = this._core;
        Core.showLoader();

        let planPropertyFilePK = confirmButton.dataset.planpropertyfilepk;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/DeleteFiles', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file has been successfully deleted.", 'success', 3000, null);

                        let containerElement = <HTMLElement>document.querySelector(`.uploadFileColumn[data-planpropertyfilepk='${planPropertyFilePK}']`);
                        if (containerElement !== null) {
                            containerElement.parentNode.removeChild(containerElement);
                        }

                        let uploads = document.querySelectorAll(`#uploadedFiles .uploaded-file-container`);
                        if (uploads.length === 0) {
                            let uploadContainer = document.querySelector(`#uploadedFiles`);
                            uploadContainer.classList.add("hide");
                        }

                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting file.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file removal failed. Please try again.", 'error', 3000, null);
            }

            let modal: Modal = new Modal("deleteFileModal", null);
            modal.hide();
        };
        xhr.send(JSON.stringify([planPropertyFilePK]));
    }

    deleteFileCancel() {
        let modal: Modal = new Modal("deleteFileModal", null);
        modal.hide();
    }

    affirmationClick(checkbox: HTMLInputElement) {
        const dateReviewed = <HTMLInputElement>document.getElementById("safeSchoolsBullyingPolicyDateReviewed");
        const dateReviewedRow = <HTMLInputElement>document.getElementById("dateReviewedRow");
        if (checkbox.checked) {
            this._core.forceElementRequired(dateReviewed);
            dateReviewedRow.classList.remove("hide");
        } else {
            this._core.forceElementOptional(dateReviewed);
            dateReviewedRow.classList.add("hide");
        }
    }
}

// SafeSchoolsBullyingSignatures
class SafeSchoolsBullyingSignatures {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["safeSchoolsBullyingSignaturesField", "safeSchoolsBullyingSignaturesCheckboxField"];

        let safeSchoolsBullyingSignaturesSaveButton = document.getElementById("safeSchoolsBullyingSignaturesSave");
        if (safeSchoolsBullyingSignaturesSaveButton !== null)
            safeSchoolsBullyingSignaturesSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("safeSchoolsBullyingSignaturesForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("safeSchoolsBullyingSignaturesField");

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let checkInputs = document.getElementsByClassName("safeSchoolsBullyingSignaturesCheckboxField");
        for (let ele of checkInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let textValue = "";

            if (element.checked)
                textValue = "on";
            else
                textValue = "off";

            planPropertyPK = parseInt(element.dataset.planpropertypk);

            let saveItem: IPlanProperty = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: planPK,
                PropertyFK: propertyPK,
                TextValue: textValue,
                LookupCodeFK: null,
                RowNbr: parseInt(rowNumber),
                IsDeletedInd: false
            };

            allSaveElements.push(saveItem);
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/SaveSafeSchoolsBullyingSignatures', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

// SafeSchoolsBullyingSummaryChecklist
class SafeSchoolsBullyingSummaryChecklist {
    private _core: Core;
    constructor() {
        this._core = new Core();
        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.back(e));

        let submitButton = document.getElementById("submitPlanButton");
        if (submitButton !== null)
            submitButton.addEventListener("click", (e: Event) => this.submit(e));

        this._core.leftnav(this);
    }

    back(e: Event) {
        let newPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }

    getCore() {
        return this._core;
    }

    submit(e: Event) {
        let element = <HTMLButtonElement>e.target;
        if (!element.classList.contains("disabled")) {
            let planFK = 0;
            planFK = parseInt(element.dataset.planfk);
            let core = this._core;
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SafeSchools/SubmitPlan', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "This report has been submitted for review!", 'success', 3000, window.location.reload());

                    setTimeout(function () { window.location.href = '/Reports/StateRequiredReports' }, 3000);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "An unexpected error occurred while submitting report. Please try again later.", 'error', 3000, null);
                }
            };
            xhr.send("planFK=" + planFK);
        }
    }
}

// SafeSchoolsBusEvacuationProfile
class SafeSchoolsBusEvacuationProfile {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["safeSchoolsBusEvacuationProfileField"];

        let safeSchoolsBusEvacuationProfileSaveButton = document.getElementById("safeSchoolsBusEvacuationProfileSave");
        if (safeSchoolsBusEvacuationProfileSaveButton !== null)
            safeSchoolsBusEvacuationProfileSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        let educatorEffectivenessExecutiveSaveButton = document.getElementById("ExecutiveSave");
        if (educatorEffectivenessExecutiveSaveButton !== null)
            educatorEffectivenessExecutiveSaveButton.addEventListener("click", (e: Event) => this.executiveSave("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let refreshButton = document.getElementById("resetProfile");
        if (refreshButton !== null)
            refreshButton.addEventListener("click", (e: Event) => Core.refreshProfile("safeSchoolsBusEvacuationProfileForm"));
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("safeSchoolsBusEvacuationProfileForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("safeSchoolsBusEvacuationProfileField");

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let selects = document.getElementsByClassName("safeSchoolsBusEvacuationProfileSelectField");
        for (let ele of selects) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);

            let element = <HTMLSelectElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: "",
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/SaveSafeSchoolsBusEvacuationProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    executiveSave(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("safeSchoolsBusEvacuationProfileForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("profileICDataSave");

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/SaveSafeSchoolsBusEvacuationProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

// SafeSchoolsBusEvacuationBusEvacuationDrillACS
class SafeSchoolsBusEvacuationBusEvacuationDrillACS {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["safeSchoolsBusEvacuationBusEvacuationDrillACSField", "safeSchoolsBusEvacuationBusEvacuationDrillACSRadioField", "safeSchoolsBusEvacuationBusEvacuationDrillACSFileUploadField"];

        this.checkPIMSQuestion();

        let safeSchoolsBusEvacuationBusEvacuationDrillACSSaveButton = document.getElementById("safeSchoolsBusEvacuationBusEvacuationDrillACSSave");
        if (safeSchoolsBusEvacuationBusEvacuationDrillACSSaveButton !== null)
            safeSchoolsBusEvacuationBusEvacuationDrillACSSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let evacuationDrillReportButton = document.getElementById("evacuationDrillReport");
        if (evacuationDrillReportButton !== null)
            evacuationDrillReportButton.addEventListener("click", (e: Event) => this.generatePDF());

        const pimsQuestionRadios = document.getElementsByClassName("pimsQuestion");
        for (const pimsQuestionRadio of pimsQuestionRadios)
            pimsQuestionRadio.addEventListener("change", (e: Event) => this.checkPIMSQuestion());

        const safeSchoolsBusEvacuationBusEvacuationDrillUploadRecordfromPIMS = document.getElementById("safeSchoolsBusEvacuationBusEvacuationDrillUploadRecordfromPIMS");
        if (safeSchoolsBusEvacuationBusEvacuationDrillUploadRecordfromPIMS !== null)
            safeSchoolsBusEvacuationBusEvacuationDrillUploadRecordfromPIMS.addEventListener("change", (e: Event) => this.uploadFile(e));

        this.bindDeleteFiles();

        const deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e: Event) => this.deleteFileConfirm(e.target as HTMLButtonElement));

        const deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e: Event) => this.deleteFileCancel());
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        const allSaveElements = [];
        const core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        const formElement = document.getElementById("safeSchoolsBusEvacuationBusEvacuationDrillACSForm");
        const planPK = parseInt(formElement.dataset.planfk);

        const radioInputs = document.getElementsByClassName("safeSchoolsBusEvacuationBusEvacuationDrillACSRadioField");
        for (let ele of radioInputs) {
            let planPropertyPK = 0;

            const element = <HTMLInputElement>ele;
            const rowNumber = "0";
            const propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                if (element.value !== "" || hadValue) {
                    let saveItem: IPlanProperty = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };

                    allSaveElements.push(saveItem);
                }
            }
        }

        const allFileElements = [];
        const files = document.getElementsByClassName("safeSchoolsBusEvacuationBusEvacuationDrillACSFileField")
        for (let ele of files) {
            let element = <HTMLElement>ele;
            let sequenceNbr = parseInt(element.dataset.sequencenbr);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk);
            let fileUploadPK = 0;
            if (!isNaN(parseInt(element.dataset.fileuploadpk)))
                fileUploadPK = parseInt(element.dataset.fileuploadpk);

            let propertyPK = parseInt(element.dataset.propertypk);
            let saveItem = {
                PlanPropertyFilePK: planPropertyFilePK,
                SequenceNbr: sequenceNbr,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanFK: planPK,
                PlanPropertyPlanPropertyFilePK: 0
            };
            allFileElements.push(saveItem);
        }

        const data = {
            "Elements": allSaveElements,
            "Files": allFileElements,
            "PlanFK": planPK
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/SaveSafeSchoolsBusEvacuationBusEvacuationDrillACS', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(data));
        }
    }

    generatePDF() {
        let formElement = document.getElementById("safeSchoolsBusEvacuationBusEvacuationDrillACSForm");
        let planFK = formElement.dataset.planfk;

        Core.showLoader();

        this.howManySchools(parseInt(planFK)).then((count: number) => {

            let filename = "BusEvacuationDrillDetail.pdf";
            if (count > 20) {
                filename = "BusEvacuationDrillDetail.zip";
            }

            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SafeSchools/GenerateBusEvacuationDrillACSPDF', true);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.responseType = 'blob';
            xhr.onload = function () {

                Core.hideLoader();

                if (xhr.status === 200) {
                    let blob = this.response;

                    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                        window.navigator.msSaveBlob(blob, filename);
                    } else {
                        var a = <HTMLAnchorElement>document.createElement("a");
                        var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                        document.body.appendChild(a);
                        a.style.display = "none";
                        a.href = blobUrl;
                        a.download = filename;
                        a.click();
                    }
                } else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue generating this report. Please try again later.", 'error', 3000, null);
                }
            }
            xhr.send(`planFK=${planFK}`);
        });
    }

    howManySchools(planFK: number) {
        return new Promise(async (resolve, reject) => {
            let count = 0;
            const response = await fetch(`/SafeSchools/GetSchoolCount/${planFK}`, { credentials: 'include' })
            if (response.ok) {
                const json = await response.json();

                count = json.schoolCount;

                resolve(count);
            } else {
                reject("Error");
            }
        });
    }

    checkPIMSQuestion() {
        const pimsQuestionRadio = <HTMLInputElement>document.querySelector(".pimsQuestion:checked");
        if (pimsQuestionRadio !== null) {
            const yesSelected: boolean = pimsQuestionRadio.dataset.lookupcode === "yes";

            const busEvacPIMSFileUpload = <HTMLDivElement>document.getElementById("busEvacPIMSFileUpload");
            const safeSchoolsBusEvacuationBusEvacuationDrillUploadRecordfromPIMS = <HTMLInputElement>document.getElementById("safeSchoolsBusEvacuationBusEvacuationDrillUploadRecordfromPIMS");
            
            if (yesSelected) {
                busEvacPIMSFileUpload.classList.add("hide");
                this._core.forceElementOptional(safeSchoolsBusEvacuationBusEvacuationDrillUploadRecordfromPIMS);
            } else {
                busEvacPIMSFileUpload.classList.remove("hide");
                this._core.forceElementRequired(safeSchoolsBusEvacuationBusEvacuationDrillUploadRecordfromPIMS);
            }
        }
    }

    uploadFile(e: Event) {
        const core = this._core;
        Core.showLoader();
        const element = <HTMLInputElement>e.target;
        const formName = element.dataset.formname;

        const uploadPIMS = <HTMLFormElement>document.querySelector(`#${formName}`);
        const formData = new FormData(uploadPIMS);

        const that = this;
        const propertyFK = element.dataset.propertypk;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();

                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);

                        element.value = "";

                        let fileList = document.querySelector(`#pimsFileUploads`)

                        let fileWrapper = <HTMLDivElement>document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = <HTMLDivElement>document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = <HTMLAnchorElement>document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        fileA.classList.add("safeSchoolsBusEvacuationBusEvacuationDrillACSFileField");
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.dataset.sequencenbr = "0";
                        fileA.dataset.propertypk = propertyFK;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);

                        fileList.appendChild(fileWrapper);
                        that.bindDeleteFiles();

                        const busEvacPIMSFileUploadFiles = <HTMLDivElement>document.getElementById("busEvacPIMSFileUploadFiles");
                        busEvacPIMSFileUploadFiles.classList.remove("hide");

                        fileList.classList.remove("hide");
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }

    showDeleteFile(e: Event) {
        const fileButton = <HTMLButtonElement>e.target;
        const planPropertyFilePK = fileButton.dataset.planpropertyfilepk;

        const modal: Modal = new Modal("deleteFileModal", null);
        const deleteConfirmButton = <HTMLButtonElement>document.getElementById("deleteFileConfirm");
        deleteConfirmButton.dataset.planpropertyfilepk = planPropertyFilePK;
        modal.show();
    }

    deleteFileConfirm(confirmButton: HTMLButtonElement) {
        let core = this._core;
        Core.showLoader();

        let planPropertyFilePK = confirmButton.dataset.planpropertyfilepk;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Base/DeleteFiles', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file has been successfully deleted.", 'success', 3000, null);

                        let containerElement = <HTMLElement>document.querySelector(`.uploadFileColumn[data-planpropertyfilepk='${planPropertyFilePK}']`);
                        if (containerElement !== null) {
                            containerElement.parentNode.removeChild(containerElement);
                        }

                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting file.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file removal failed. Please try again.", 'error', 3000, null);
            }

            let modal: Modal = new Modal("deleteFileModal", null);
            modal.hide();
        };
        xhr.send(JSON.stringify([planPropertyFilePK]));
    }

    deleteFileCancel() {
        const modal: Modal = new Modal("deleteFileModal", null);
        modal.hide();
    }

    bindDeleteFiles() {
        const allDeletes = document.getElementsByClassName("deleteFile");
        for (const del of allDeletes)
            del.addEventListener("click", (e: Event) => this.showDeleteFile(e));
    }
}

// SafeSchoolsBusEvacuationBusEvacuationDrillSummary
class SafeSchoolsBusEvacuationBusEvacuationDrillSummary {
    private _core: Core;

    validationClasses: string[];

    constructor() {

        this.validationClasses = ["safeSchoolsBusEvacuationBusEvacuationDrillSummaryField"];

        this._core = new Core();
        let safeSchoolsBusEvacuationBusEvacuationDrillACSSaveButton = document.getElementById("safeSchoolsBusEvacuationBusEvacuationDrillSummarySave");
        if (safeSchoolsBusEvacuationBusEvacuationDrillACSSaveButton !== null)
            safeSchoolsBusEvacuationBusEvacuationDrillACSSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let evacuationDrillReportButton = document.getElementById("evacuationDrillSummary");
        if (evacuationDrillReportButton !== null)
            evacuationDrillReportButton.addEventListener("click", (e: Event) => this.generatePDF());
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("safeSchoolsBusEvacuationBusEvacuationDrillSummaryForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("safeSchoolsBusEvacuationBusEvacuationDrillSummaryField");

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchoolsBusEvacuation/SaveSafeSchoolsBusEvacuationBusEvacuationSummary', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    generatePDF() {
        let formElement = document.getElementById("safeSchoolsBusEvacuationBusEvacuationDrillSummaryForm");
        let planFK = formElement.dataset.planfk;

        Core.showLoader();

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/GenerateBusEvacuationDrillSummaryPDF', true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.responseType = 'blob';
        xhr.onload = function () {

            Core.hideLoader();

            if (xhr.status === 200) {
                let blob = this.response;
                let filename = "BusEvacuationDrillSummary.pdf";

                if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                    window.navigator.msSaveBlob(blob, filename);
                } else {
                    var a = <HTMLAnchorElement>document.createElement("a");
                    var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                    document.body.appendChild(a);
                    a.style.display = "none";
                    a.href = blobUrl;
                    a.download = filename;
                    a.click();
                }
            } else {
                Core.createHTMLAlert("alertMessageDiv", "There was an issue generating this report. Please try again later.", 'error', 3000, null);
            }
        }
        xhr.send(`planFK=${planFK}`);
    }
}

// SafeSchoolsBusEvacuationSignatures
class SafeSchoolsBusEvacuationSignatures {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["safeSchoolsBusEvacuationSignaturesField", "safeSchoolsBusEvacuationSignaturesCheckboxField", "safeSchoolsBusEvacuationSignaturesRadioField"];

        let safeSchoolsBusEvacuationSignaturesSaveButton = document.getElementById("safeSchoolsBusEvacuationSignaturesSave");
        if (safeSchoolsBusEvacuationSignaturesSaveButton !== null)
            safeSchoolsBusEvacuationSignaturesSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.doValidationCustom(this.validationClasses);
        }
        this.initializeRequiredFieldsCustom(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        const safeSchoolsBusEvacuationSignaturesProvideTransportationRadios = document.getElementsByClassName("safeSchoolsBusEvacuationSignaturesProvideTransportation");
        for (const safeSchoolsBusEvacuationSignaturesProvideTransportationRadio of safeSchoolsBusEvacuationSignaturesProvideTransportationRadios)
            safeSchoolsBusEvacuationSignaturesProvideTransportationRadio.addEventListener("change", (e: Event) => this.changeTransportation());

        this.changeTransportation();
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("safeSchoolsBusEvacuationSignaturesForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("safeSchoolsBusEvacuationSignaturesField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let checkInputs = document.getElementsByClassName("safeSchoolsBusEvacuationSignaturesCheckboxField");
        for (let ele of checkInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let textValue = "";

            if (element.checked)
                textValue = "on";
            else
                textValue = "off";

            planPropertyPK = parseInt(element.dataset.planpropertypk);

            let saveItem: IPlanProperty = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: planPK,
                PropertyFK: propertyPK,
                TextValue: textValue,
                LookupCodeFK: null,
                RowNbr: parseInt(rowNumber),
                IsDeletedInd: false
            };

            allSaveElements.push(saveItem);
        }

        const radioInputs = document.getElementsByClassName("safeSchoolsBusEvacuationSignaturesRadioField");
        for (let ele of radioInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                if (element.value !== "" || hadValue) {
                    let saveItem: IPlanPropertyExtended = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                        ButtonRow: 0
                    };

                    allSaveElements.push(saveItem);
                }
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/SaveSafeSchoolsBusEvacuationSignatures', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    doValidationCustom(allClasses: string[], showMessageOverride?: boolean) {
        let showMessage = showMessageOverride === undefined ? this.clientSideValidationCustom(allClasses) : showMessageOverride;
        let messageContainerColumn = <HTMLElement>document.getElementById("validationColumn");
        let messageContainer = <HTMLElement>document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = <HTMLElement>document.getElementById("validationMessageIcon");

        setTimeout(function () {
            messageContainer.focus();
        }, 500);

        if (showMessage) {

            let message = <HTMLElement>document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";

        } else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";

            let successMessage = <HTMLElement>document.getElementById("saveSuccess");

            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved."
            }
        }
    }

    clientSideValidationCustom(validationClasses: string[]) {
        let showMessage: boolean = false;
        let totalErrors = 0;

        let formattedAllClasses = [];
        validationClasses.forEach(function (part, index) {
            //if(validationClasses[index].indexOf("Checkbox") < 0)
                formattedAllClasses[index] = "." + validationClasses[index];
        });

        let classesToValidate = formattedAllClasses.join(",");

        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });

        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });

        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });
        //need to put this back in if we add the other checkboxes back in

        //const anyChecked = document.querySelectorAll(".safeSchoolsBusEvacuationSignaturesCheckboxField:checked");
        //const allCheckboxes = document.querySelectorAll(".safeSchoolsBusEvacuationSignaturesCheckboxField");
        //if (anyChecked.length === 0) {
        //    for (const check of allCheckboxes)
        //        this._core.forceElementRequired(check as HTMLElement);

        //    totalErrors++;
        //    showMessage = true;
        //} else {
        //    for (const check of allCheckboxes)
        //        this._core.forceElementOptional(check as HTMLElement);
        //}

        let allElements = document.querySelectorAll(classesToValidate);

        for (let element of allElements) {
            let alreadyExists = false;
            let htmlElement = <HTMLElement>element;
            if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {
                if (!alreadyExists || ("forcerequired" in htmlElement.dataset && htmlElement.dataset.forcerequired === "true")) {
                    if (!element.classList.contains("missing-field")) {
                        //For more custom validation, use data-is-valid to specify whether a field is valid/invalid
                        if ("isValid" in htmlElement.dataset) {
                            if (htmlElement.dataset.isValid === "false") {
                                htmlElement.classList.add("missing-field");
                                htmlElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(htmlElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        } else {
                            if (element instanceof HTMLInputElement) {
                                let inputElement = <HTMLInputElement>element;

                                //Only validate once for radio buttons
                                if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "radio" && !inputElement.checked) {
                                    if (inputElement.hasAttribute("name")) {
                                        let radioName = inputElement.getAttribute("name");

                                        let radioButtons = document.getElementsByName(radioName);
                                        let alreadyValidated: boolean = false;
                                        let isChecked: boolean = false;

                                        for (var i = 0, length = radioButtons.length; i < length; i++) {
                                            let radioElement = <HTMLInputElement>radioButtons[i];
                                            if (radioElement.classList.contains("missing-field")) {
                                                alreadyValidated = true;
                                            }

                                            if (radioElement.checked) {
                                                isChecked = true;
                                            }
                                        }

                                        if (!alreadyValidated && !isChecked) {
                                            inputElement.classList.add("missing-field");
                                            inputElement.setAttribute("aria-invalid", "true");
                                            Core.createErrorLabelForInput(inputElement);
                                            showMessage = true;
                                            totalErrors++;
                                        }
                                    }
                                } else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "checkbox" && !inputElement.checked) {
                                    inputElement.classList.add("missing-field");
                                    inputElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(inputElement);
                                    showMessage = true;
                                    totalErrors++;
                                } else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "file") {
                                    if ("hasuploaded" in inputElement.dataset && inputElement.dataset.hasuploaded != "true") {
                                        inputElement.classList.add("missing-field");
                                        inputElement.setAttribute("aria-invalid", "true");
                                        Core.createErrorLabelForInput(inputElement);
                                        showMessage = true;
                                        totalErrors++;
                                    }
                                } else if (inputElement.value === "") {
                                    inputElement.classList.add("missing-field");
                                    inputElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(inputElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            } else if (element instanceof HTMLSelectElement) {
                                let selectElement = <HTMLSelectElement>element;
                                //if the page select element does not have a place holder it needs custom validation.  do not use this one.
                                if (selectElement.selectedIndex <= 0 || selectElement.options[selectElement.selectedIndex].value === "") {
                                    selectElement.classList.add("missing-field");
                                    selectElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(selectElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            } else if (element instanceof HTMLTextAreaElement) {
                                let textAreaElement = <HTMLTextAreaElement>element;
                                if (textAreaElement.value === "") {
                                    textAreaElement.classList.add("missing-field");
                                    textAreaElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(textAreaElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            } else if ("multiselectvalidate" in htmlElement.dataset && htmlElement.dataset.multiselectvalidate === "true") {
                                let multiselectCheckboxes = htmlElement.getElementsByTagName("input") as HTMLCollectionOf<HTMLInputElement>;
                                let hasSelection = false;

                                for (let selectBox of multiselectCheckboxes) {
                                    if (selectBox.checked) {
                                        hasSelection = true;
                                        break;
                                    }
                                }

                                if (!hasSelection) {
                                    htmlElement.classList.add("missing-field");
                                    htmlElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(htmlElement);
                                    showMessage = true;
                                    totalErrors++;
                                }

                            }
                        }
                    }
                }
            }
        }

        let message = <HTMLDivElement>document.getElementById("validationMessage");

        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        } else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");

        if (goToError !== null) {

            let that = this;

            let firstFocusableEl = <HTMLElement>document.querySelector(".missing-field");

            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");

                        let accordionElement = <HTMLButtonElement>document.getElementById(id);
                        accordionElement.click();
                    }

                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    } else {
                        firstFocusableEl.focus();
                    }
                });
            } else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }

    initializeRequiredFieldsCustom(validationClasses: string[]) {
        this._core.initializeRequiredFields(validationClasses);
    }

    changeTransportation() {
        const safeSchoolsBusEvacuationSignaturesProvideTransportationRadios = document.getElementsByClassName("safeSchoolsBusEvacuationSignaturesProvideTransportation");
        const assuranceContainer = <HTMLDivElement>document.getElementById("assurance");
        const safeSchoolsBusEvacuationsSignaturesCertificationElement = <HTMLInputElement>document.getElementById("safeSchoolsBusEvacuationsSignaturesCertification");

        for (const safeSchoolsBusEvacuationSignaturesProvideTransportationRadio of safeSchoolsBusEvacuationSignaturesProvideTransportationRadios) {
            const element = <HTMLInputElement>safeSchoolsBusEvacuationSignaturesProvideTransportationRadio;

            if (element.checked) {
                if (element.dataset.lookupcode === "yes") {
                    assuranceContainer.classList.remove("hide");
                    this._core.forceElementRequired(safeSchoolsBusEvacuationsSignaturesCertificationElement);
                } else if (element.dataset.lookupcode === "no") {
                    assuranceContainer.classList.add("hide");
                    this._core.forceElementOptional(safeSchoolsBusEvacuationsSignaturesCertificationElement);
                }
            }
        }
    }
}

// SafeSchoolsBusEvacuationSummaryChecklist
class SafeSchoolsBusEvacuationSummaryChecklist {
    private _core: Core;
    constructor() {
        this._core = new Core();
        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.back(e));

        let submitButton = document.getElementById("submitPlanButton");
        if (submitButton !== null)
            submitButton.addEventListener("click", (e: Event) => this.submit(e));

        this._core.leftnav(this);
    }

    back(e: Event) {
        let newPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }

    getCore() {
        return this._core;
    }

    submit(e: Event) {
        let element = <HTMLButtonElement>e.target;
        if (!element.classList.contains("disabled")) {
            let planFK = 0;
            planFK = parseInt(element.dataset.planfk);
            let core = this._core;
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SafeSchools/SubmitPlan', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "This report has been submitted for review!", 'success', 3000, window.location.reload());

                    setTimeout(function () { window.location.href = '/Reports/StateRequiredReports' }, 3000);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "An unexpected error occurred while submitting report. Please try again later.", 'error', 3000, null);
                }
            };
            xhr.send("planFK=" + planFK);
        }
    }
}

// SafeSchoolsFireEvacuationProfile
class SafeSchoolsFireEvacuationProfile {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["safeSchoolsFireEvacuationProfileField"];

        let safeSchoolsFireEvacuationProfileSaveButton = document.getElementById("safeSchoolsFireEvacuationProfileSave");
        if (safeSchoolsFireEvacuationProfileSaveButton !== null)
            safeSchoolsFireEvacuationProfileSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        let educatorEffectivenessExecutiveSaveButton = document.getElementById("ExecutiveSave");
        if (educatorEffectivenessExecutiveSaveButton !== null)
            educatorEffectivenessExecutiveSaveButton.addEventListener("click", (e: Event) => this.executiveSave("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let refreshButton = document.getElementById("resetProfile");
        if (refreshButton !== null)
            refreshButton.addEventListener("click", (e: Event) => Core.refreshProfile("safeSchoolsFireEvacuationProfileForm"));
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("safeSchoolsFireEvacuationProfileForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("safeSchoolsFireEvacuationProfileField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let selects = document.getElementsByClassName("safeSchoolsFireEvacuationProfileSelectField");
        for (let ele of selects) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);

            let element = <HTMLSelectElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: "",
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/SaveSafeSchoolsFireEvacuationProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    executiveSave(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("safeSchoolsFireEvacuationProfileForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("profileICDataSave");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/SaveSafeSchoolsFireEvacuationProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

// SafeSchoolsFireEvacuationReport
class SafeSchoolsFireEvacuationReport {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.checkPIMSQuestion();

        this.validationClasses = ["safeSchoolsFireEvacuationReportField", "safeSchoolsFireEvacuationReportRadioField", "safeSchoolsFireEvacuationReportFileUploadField"];

        let safeSchoolsFireEvacuationReportSaveButton = document.getElementById("safeSchoolsFireEvacuationReportSave");
        if (safeSchoolsFireEvacuationReportSaveButton !== null)
            safeSchoolsFireEvacuationReportSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let fireDrillReportButton = document.getElementById("fireDrillReport");
        if (fireDrillReportButton !== null)
            fireDrillReportButton.addEventListener("click", (e: Event) => this.generatePDF());

        const pimsQuestionRadios = document.getElementsByClassName("pimsQuestion");
        for (const pimsQuestionRadio of pimsQuestionRadios)
            pimsQuestionRadio.addEventListener("change", (e: Event) => this.checkPIMSQuestion());

        const safeSchoolsBusEvacuationBusEvacuationDrillUploadRecordfromPIMS = document.getElementById("safeSchoolsFireEvacuationFireEvacuationReportUploadRecordfromPIMS");
        if (safeSchoolsBusEvacuationBusEvacuationDrillUploadRecordfromPIMS !== null)
            safeSchoolsBusEvacuationBusEvacuationDrillUploadRecordfromPIMS.addEventListener("change", (e: Event) => this.uploadFile(e));

        this.bindDeleteFiles();

        const deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e: Event) => this.deleteFileConfirm(e.target as HTMLButtonElement));

        const deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e: Event) => this.deleteFileCancel());
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("safeSchoolsFireEvacuationReportForm");
        let planPK = parseInt(formElement.dataset.planfk);

        const radioInputs = document.getElementsByClassName("safeSchoolsFireEvacuationReportRadioField");
        for (let ele of radioInputs) {
            let planPropertyPK = 0;

            const element = <HTMLInputElement>ele;
            const rowNumber = "0";
            const propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                if (element.value !== "" || hadValue) {
                    let saveItem: IPlanProperty = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };

                    allSaveElements.push(saveItem);
                }
            }
        }

        const allFileElements = [];
        const files = document.getElementsByClassName("safeSchoolsFireEvacuationReportFileField")
        for (let ele of files) {
            let element = <HTMLElement>ele;
            let sequenceNbr = parseInt(element.dataset.sequencenbr);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk);
            let fileUploadPK = 0;
            if (!isNaN(parseInt(element.dataset.fileuploadpk)))
                fileUploadPK = parseInt(element.dataset.fileuploadpk);

            let propertyPK = parseInt(element.dataset.propertypk);
            let saveItem = {
                PlanPropertyFilePK: planPropertyFilePK,
                SequenceNbr: sequenceNbr,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanFK: planPK,
                PlanPropertyPlanPropertyFilePK: 0
            };
            allFileElements.push(saveItem);
        }

        const data = {
            "Elements": allSaveElements,
            "Files": allFileElements,
            "PlanFK": planPK
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/SaveSafeSchoolsFireEvacuationReport', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(data));
        }
    }

    generatePDF() {
        let formElement = document.getElementById("safeSchoolsFireEvacuationReportForm");
        let planFK = formElement.dataset.planfk;

        Core.showLoader();

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/GenerateFireDrillReportPDF', true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.responseType = 'blob';
        xhr.onload = function () {

            Core.hideLoader();

            if (xhr.status === 200) {
                let blob = this.response;
                let filename = "FireEvacuationDrillDetail.pdf";

                if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                    window.navigator.msSaveBlob(blob, filename);
                } else {
                    var a = <HTMLAnchorElement>document.createElement("a");
                    var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                    document.body.appendChild(a);
                    a.style.display = "none";
                    a.href = blobUrl;
                    a.download = filename;
                    a.click();
                }
            } else {
                Core.createHTMLAlert("alertMessageDiv", "There was an issue generating this report. Please try again later.", 'error', 3000, null);
            }
        }
        xhr.send(`planFK=${planFK}`);
    }

    checkPIMSQuestion() {
        const pimsQuestionRadio = <HTMLInputElement>document.querySelector(".pimsQuestion:checked");
        if (pimsQuestionRadio !== null) {
            const yesSelected: boolean = pimsQuestionRadio.dataset.lookupcode === "yes";

            const fireEvacPIMSFileUpload = <HTMLDivElement>document.getElementById("fireEvacPIMSFileUpload");
            const safeSchoolsFireEvacuationFireEvacuationDrillUploadRecordfromPIMS = <HTMLInputElement>document.getElementById("safeSchoolsFireEvacuationFireEvacuationReportUploadRecordfromPIMS");

            if (yesSelected) {
                fireEvacPIMSFileUpload.classList.add("hide");
                this._core.forceElementOptional(safeSchoolsFireEvacuationFireEvacuationDrillUploadRecordfromPIMS);
            } else {
                fireEvacPIMSFileUpload.classList.remove("hide");
                this._core.forceElementRequired(safeSchoolsFireEvacuationFireEvacuationDrillUploadRecordfromPIMS);
            }
        }
    }

    uploadFile(e: Event) {
        const core = this._core;
        Core.showLoader();
        const element = <HTMLInputElement>e.target;
        const formName = element.dataset.formname;

        const uploadPIMS = <HTMLFormElement>document.querySelector(`#${formName}`);
        const formData = new FormData(uploadPIMS);

        const that = this;
        const propertyFK = element.dataset.propertypk;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();

                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);

                        element.value = "";

                        let fileList = document.querySelector(`#pimsFileUploads`)

                        let fileWrapper = <HTMLDivElement>document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = <HTMLDivElement>document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = <HTMLAnchorElement>document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        fileA.classList.add("safeSchoolsFireEvacuationReportFileField");
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.dataset.sequencenbr = "0";
                        fileA.dataset.propertypk = propertyFK;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);

                        fileList.appendChild(fileWrapper);
                        that.bindDeleteFiles();

                        const busEvacPIMSFileUploadFiles = <HTMLDivElement>document.getElementById("fireEvacPIMSFileUploadFiles");
                        busEvacPIMSFileUploadFiles.classList.remove("hide");

                        fileList.classList.remove("hide");
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }

    showDeleteFile(e: Event) {
        const fileButton = <HTMLButtonElement>e.target;
        const planPropertyFilePK = fileButton.dataset.planpropertyfilepk;

        const modal: Modal = new Modal("deleteFileModal", null);
        const deleteConfirmButton = <HTMLButtonElement>document.getElementById("deleteFileConfirm");
        deleteConfirmButton.dataset.planpropertyfilepk = planPropertyFilePK;
        modal.show();
    }

    deleteFileConfirm(confirmButton: HTMLButtonElement) {
        let core = this._core;
        Core.showLoader();

        let planPropertyFilePK = confirmButton.dataset.planpropertyfilepk;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Base/DeleteFiles', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file has been successfully deleted.", 'success', 3000, null);

                        let containerElement = <HTMLElement>document.querySelector(`.uploadFileColumn[data-planpropertyfilepk='${planPropertyFilePK}']`);
                        if (containerElement !== null) {
                            containerElement.parentNode.removeChild(containerElement);
                        }

                        const allUploads = document.querySelectorAll("#pimsFileUploads .uploadFileColumn");
                        if (allUploads.length === 0) {
                            const busEvacPIMSFileUploadFiles = <HTMLDivElement>document.getElementById("fireEvacPIMSFileUploadFiles");
                            busEvacPIMSFileUploadFiles.classList.add("hide");
                        }

                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting file.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file removal failed. Please try again.", 'error', 3000, null);
            }

            let modal: Modal = new Modal("deleteFileModal", null);
            modal.hide();
        };
        xhr.send(JSON.stringify([planPropertyFilePK]));
    }

    deleteFileCancel() {
        const modal: Modal = new Modal("deleteFileModal", null);
        modal.hide();
    }

    bindDeleteFiles() {
        const allDeletes = document.getElementsByClassName("deleteFile");
        for (const del of allDeletes)
            del.addEventListener("click", (e: Event) => this.showDeleteFile(e));
    }
}

// SafeSchoolsFireEvacuationDrill 
class SafeSchoolsFireEvacuationDrill  {
    private _core: Core;
    validationClasses: string[];


    constructor() {
        this._core = new Core();
        this.validationClasses = ["safeSchoolsFireEvacuationReportField"];

        let safeSchoolsFireEvacuationReportSaveButton = document.getElementById("safeSchoolsFireEvacuationReportSave");
        if (safeSchoolsFireEvacuationReportSaveButton !== null)
            safeSchoolsFireEvacuationReportSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let fireDrillSummaryButton = document.getElementById("fireDrillSummary");
        if (fireDrillSummaryButton !== null)
            fireDrillSummaryButton.addEventListener("click", (e: Event) => this.generatePDF());
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("safeSchoolsFireEvacuationReportForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("safeSchoolsFireEvacuationReportField");

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/SaveSafeSchoolsFireEvacuationReport', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    generatePDF() {
        let formElement = document.getElementById("safeSchoolsFireEvacuationReportForm");
        let planFK = formElement.dataset.planfk;

        Core.showLoader();

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/GenerateFireDrillSummaryPDF', true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.responseType = 'blob';
        xhr.onload = function () {

            Core.hideLoader();

            if (xhr.status === 200) {
                let blob = this.response;
                let filename = "FireEvacuationDrillSummary.pdf";

                if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                    window.navigator.msSaveBlob(blob, filename);
                } else {
                    var a = <HTMLAnchorElement>document.createElement("a");
                    var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                    document.body.appendChild(a);
                    a.style.display = "none";
                    a.href = blobUrl;
                    a.download = filename;
                    a.click();
                }
            } else {
                Core.createHTMLAlert("alertMessageDiv", "There was an issue generating this report. Please try again later.", 'error', 3000, null);
            }
        }
        xhr.send(`planFK=${planFK}`);
    }
}

// SafeSchoolsFireEvacuationSignatures
class SafeSchoolsFireEvacuationSignatures {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["safeSchoolsFireEvacuationSignaturesField", "safeSchoolsFireEvacuationSignaturesCheckboxField"];

        let safeSchoolsFireEvacuationSignaturesSaveButton = document.getElementById("safeSchoolsFireEvacuationSignaturesSave");
        if (safeSchoolsFireEvacuationSignaturesSaveButton !== null)
            safeSchoolsFireEvacuationSignaturesSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("safeSchoolsFireEvacuationSignaturesForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("safeSchoolsFireEvacuationSignaturesField");

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let checkInputs = document.getElementsByClassName("safeSchoolsFireEvacuationSignaturesCheckboxField");

        for (let ele of checkInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let textValue = "";

            if (element.checked)
                textValue = "on";
            else
                textValue = "off";

            planPropertyPK = parseInt(element.dataset.planpropertypk);

            let saveItem: IPlanProperty = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: planPK,
                PropertyFK: propertyPK,
                TextValue: textValue,
                LookupCodeFK: null,
                RowNbr: parseInt(rowNumber),
                IsDeletedInd: false
            };

            allSaveElements.push(saveItem);
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/SaveSafeSchoolsFireEvacuationSignatures', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

// SafeSchoolsFireEvacuationSummaryChecklist
class SafeSchoolsFireEvacuationSummaryChecklist {
    private _core: Core;
    constructor() {
        this._core = new Core();
        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.back(e));

        let submitButton = document.getElementById("submitPlanButton");
        if (submitButton !== null)
            submitButton.addEventListener("click", (e: Event) => this.submit(e));

        this._core.leftnav(this);
    }

    back(e: Event) {
        let newPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }

    getCore() {
        return this._core;
    }

    submit(e: Event) {
        let element = <HTMLButtonElement>e.target;
        if (!element.classList.contains("disabled")) {
            let planFK = 0;
            planFK = parseInt(element.dataset.planfk);
            let core = this._core;
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SafeSchools/SubmitPlan', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "This report has been submitted for review!", 'success', 3000, window.location.reload());

                    setTimeout(function () { window.location.href = '/Reports/StateRequiredReports' }, 3000);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "An unexpected error occurred while submitting report. Please try again later.", 'error', 3000, null);
                }
            };
            xhr.send("planFK=" + planFK);
        }
    }
}

// SafeSchoolsMOUProfile
class SafeSchoolsMOUProfile {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["safeSchoolsMOUProfileField"];

        let safeSchoolsMOUProfileSaveButton = document.getElementById("safeSchoolsMOUProfileSave");
        if (safeSchoolsMOUProfileSaveButton !== null)
            safeSchoolsMOUProfileSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        let educatorEffectivenessExecutiveSaveButton = document.getElementById("ExecutiveSave");
        if (educatorEffectivenessExecutiveSaveButton !== null)
            educatorEffectivenessExecutiveSaveButton.addEventListener("click", (e: Event) => this.executiveSave("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let refreshButton = document.getElementById("resetProfile");
        if (refreshButton !== null)
            refreshButton.addEventListener("click", (e: Event) => Core.refreshProfile("safeSchoolsMOUProfileForm"));
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("safeSchoolsMOUProfileForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("safeSchoolsMOUProfileField");

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let selects = document.getElementsByClassName("safeSchoolsMOUProfileSelectField");
        for (let ele of selects) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);

            let element = <HTMLSelectElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: "",
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/SaveSafeSchoolsMOUProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    executiveSave(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("safeSchoolsMOUProfileForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("profileICDataSave");

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/SaveSafeSchoolsMOUProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

// SafeSchoolsMOUDocumentation
class SafeSchoolsMOUDocumentation {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["safeSchoolsMOUDocumentationField", "safeSchoolsMOUDocumentationCheckField", "safeSchoolssMOUDocumentationFileField", "safeSchoolsMOUDocumentationSelectField"];

        let safeSchoolsMOUDocumentationSaveButton = document.getElementById("safeSchoolsMOUDocumentationSave");
        if (safeSchoolsMOUDocumentationSaveButton !== null)
            safeSchoolsMOUDocumentationSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses, false, true);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let deleteFileConfirmButton = <HTMLButtonElement>document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e: Event) => this.deleteFileConfirm(deleteFileConfirmButton));

        let deleteFileCancelButton = <HTMLButtonElement>document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e: Event) => this.deleteFileCancel());

        let deleteFileButtons = document.getElementsByClassName("deleteFile") as HTMLCollectionOf<HTMLButtonElement>;
        for (let deleteButton of deleteFileButtons)
            deleteButton.addEventListener("click", (e: Event) => this.deleteFile(e));

        this.bindFileUploads();

        this.bindDeleteButtons();

        this.bindDeleteMOUs();

        let deleteEnforcementConfirmButton = document.getElementById("safeSchoolsMOURowConfirm");
        if (deleteEnforcementConfirmButton !== null)
            deleteEnforcementConfirmButton.addEventListener("click", (e: Event) => this.deleteMOUConfirm(e));

        let deleteEnforcementCancelButton = document.getElementById("safeSchoolsMOURowCancel");
        if (deleteEnforcementCancelButton !== null)
            deleteEnforcementCancelButton.addEventListener("click", (e: Event) => this.deleteMOUCancel());

        let safeSchoolsMOUDocumentationAddMOUButton = document.getElementById("safeSchoolsMOUDocumentationAddMOU");
        if (safeSchoolsMOUDocumentationAddMOUButton !== null)
            safeSchoolsMOUDocumentationAddMOUButton.addEventListener("click", (e: Event) => this.addAnotherMOU());
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("safeSchoolsMOUDocumentationForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("safeSchoolsMOUDocumentationField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let checkInputs = document.getElementsByClassName("safeSchoolsMOUDocumentationCheckField");
        for (let ele of checkInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let textValue = "";

            if (element.checked)
                textValue = "on";
            else
                textValue = "off";

            planPropertyPK = parseInt(element.dataset.planpropertypk);

            let saveItem: IPlanProperty = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: planPK,
                PropertyFK: propertyPK,
                TextValue: textValue,
                LookupCodeFK: null,
                RowNbr: parseInt(rowNumber),
                IsDeletedInd: false
            };

            allSaveElements.push(saveItem);
        }

        let selects = document.getElementsByClassName("safeSchoolsMOUDocumentationSelectField");
        for (let ele of selects) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);

            let element = <HTMLSelectElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: "",
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let allFileElements = [];
        let files = document.getElementsByClassName("safeSchoolsFile");
        for (let ele of files) {
            let element = <HTMLInputElement>ele;

            let sequenceNbr = parseInt(element.dataset.row);
            let fileUploadPK = parseInt(element.dataset.fileuploadpk);
            let propertyPK = parseInt(element.dataset.propertypk);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk);

            let saveItem: IPlanPropertyFile = {
                SequenceNbr: sequenceNbr,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanPropertyFilePK: planPropertyFilePK,
                PlanFK: planPK
            };

            allFileElements.push(saveItem);
        }

        var allData = {
            "ElementData": allSaveElements,
            "FileData": allFileElements
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/SaveSafeSchoolsMOUDocumentation', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }

    uploadFile(e: HTMLInputElement) {
        let core = this._core;
        Core.showLoader();
        let formName = e.dataset.formname;

        let uploadSampleForm = <HTMLFormElement>document.getElementById(formName);
        let formData = new FormData(uploadSampleForm);

        let propertyPK = e.dataset.propertypk;
        let row = e.dataset.row;

        let that = this;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();

                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);

                        let formFile = <HTMLInputElement>document.getElementById(`${e.id}`);
                        formFile.value = "";

                        let fileList = document.querySelector(`.uploadFileList[data-row='${row}']`);
                        let uploadedContainer = document.querySelector(`.uploadedFiles[data-row='${row}']`);
                        uploadedContainer.classList.remove("hide");

                        let fileWrapper = <HTMLDivElement>document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = <HTMLDivElement>document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = <HTMLAnchorElement>document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.row = row;
                        fileA.classList.add("safeSchoolsFileField");
                        fileA.classList.add("safeSchoolsFile");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);

                        fileList.appendChild(fileWrapper);

                        that.bindDeleteButtons();
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }

    bindDeleteButtons() {
        let allDeleteButtons = document.getElementsByClassName("deleteFile");
        for (let delButton of allDeleteButtons)
            delButton.addEventListener("click", (e: Event) => this.deleteFile(e));
    }

    deleteFile(e: Event) {
        let fileButton = <HTMLButtonElement>e.target;
        let planPropertyFilePK = fileButton.dataset.planpropertyfilepk;
        let row = fileButton.dataset.row;

        if (planPropertyFilePK && parseInt(planPropertyFilePK) > 0) {
            let modal: Modal = new Modal("deleteFileModal", null);
            let deleteConfirmButton = <HTMLButtonElement>document.getElementById("deleteFileConfirm");
            deleteConfirmButton.dataset.planpropertyfilepk = planPropertyFilePK;
            deleteConfirmButton.dataset.row = row;
            modal.show();
        }
    }

    deleteFileConfirm(confirmButton: HTMLButtonElement) {
        let core = this._core;
        Core.showLoader();

        let planPropertyFilePK = confirmButton.dataset.planpropertyfilepk;
        let row = confirmButton.dataset.row;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/DeleteFiles', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file has been successfully deleted.", 'success', 3000, null);

                        let containerElement = <HTMLElement>document.querySelector(`.uploadFileColumn[data-planpropertyfilepk='${planPropertyFilePK}']`);
                        if (containerElement !== null) {
                            containerElement.parentNode.removeChild(containerElement);
                        }

                        let uploads = document.querySelectorAll(`.uploadFileColumn[data-row='${row}']`);
                        if (uploads.length === 0) {
                            let uploadContainer = document.querySelector(`.uploadedFiles[data-row='${row}']`);
                            uploadContainer.classList.add("hide");
                        }

                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting file.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file removal failed. Please try again.", 'error', 3000, null);
            }

            let modal: Modal = new Modal("deleteFileModal", null);
            modal.hide();
        };
        xhr.send(JSON.stringify([planPropertyFilePK]));
    }

    deleteFileCancel() {
        let modal: Modal = new Modal("deleteFileModal", null);
        modal.hide();
    }

    addAnotherMOU() {
        let planForm = document.getElementById("safeSchoolsMOUDocumentationForm");
        let planFK = planForm.dataset.planfk;

        let that = this;
        let newRow = 0;

        Core.showLoader();

        let list = document.getElementsByClassName("mouListItem");

        for (let item of list) {
            let ele = <HTMLDivElement>item;

            if (ele.dataset.row && parseInt(ele.dataset.row) > newRow) {
                newRow = parseInt(ele.dataset.row);
            }
        }

        newRow++;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/AddSafeSchoolsMOUDocumentation', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                let container = document.getElementById("mouList");
                let newDiv = <HTMLDivElement>document.createElement("div");

                newDiv.innerHTML = xhr.responseText;
                newDiv.dataset.row = newRow.toString();
                newDiv.classList.add("mouListItem");
                newDiv.classList.add("mou-list-item");

                container.append(newDiv);

                that.bindFileUploads();
                that.bindDeleteMOUs();

                that._core.initializeRequiredFields(that.validationClasses, false, true);

                Core.createHTMLAlert("alertMessageDiv", "MOU record added!", 'success', 2000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", `Request failed.  Returned status of '${xhr.status}'`, 'error', 3000, null);
                Core.hideLoader();
            }
        };
        xhr.send(`planFK=${planFK}&row=${newRow}`);
    }

    bindDeleteMOUs() {
        const deletes = document.getElementsByClassName("deleteMOURow");
        for (const del of deletes) {
            del.addEventListener("click", (e: Event) => this.showDeleteMOURow(e));
        }
    }

    showDeleteMOURow(e: Event) {
        const button = <HTMLButtonElement>e.target;

        const row = button.dataset.row;
        let modal: Modal = new Modal("deleteMOUModal", null);
        modal.addAttributeToElement("deleteMOUModal", "#safeSchoolsMOURowConfirm", "row", row);
        modal.show();
    }

    deleteMOUCancel() {
        let modal: Modal = new Modal("deleteMOUModal", null);
        modal.hide();
    }

    deleteMOUConfirm(e: Event) {
        const button = <HTMLButtonElement>e.target;
        const row = button.dataset.row;

        let core = this._core;
        Core.showLoader();

        const planPropertyFiles = [];
        const planPropertyPKs = [];
        const allChecks = document.querySelectorAll(`.safeSchoolsMOUDocumentationCheckField[data-row='${row}']`);
        for (const check of allChecks) {
            const ele = <HTMLInputElement>check;
            if (ele.dataset.planpropertypk && parseInt(ele.dataset.planpropertypk) > 0)
                planPropertyPKs.push(ele.dataset.planpropertypk);
        }

        const alltexts = document.querySelectorAll(`.safeSchoolsMOUDocumentationField[data-row='${row}']`);
        for (const text of alltexts) {
            const ele = <HTMLInputElement>text;
            if (ele.dataset.planpropertypk && parseInt(ele.dataset.planpropertypk) > 0)
                planPropertyPKs.push(ele.dataset.planpropertypk);
        }

        const allSelects = document.querySelectorAll(`.safeSchoolsMOUDocumentationSelectField[data-row='${row}']`);
        for (const select of allSelects) {
            const ele = <HTMLSelectElement>select;
            if (ele.dataset.planpropertypk && parseInt(ele.dataset.planpropertypk) > 0)
                planPropertyPKs.push(ele.dataset.planpropertypk);
        }

        const allFiles = document.querySelectorAll(`.uploadFileColumn[data-row='${row}']`);
        for (const file of allFiles) {
            const ele = <HTMLInputElement>file;
            if (ele.dataset.planpropertyfilepk && parseInt(ele.dataset.planpropertyfilepk) > 0)
                planPropertyFiles.push(ele.dataset.planpropertyfilepk);
        }

        const data = {
            "PlanPropertyPKs": planPropertyPKs,
            "PlanPropertyFilePKs": planPropertyFiles
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/DeleteMOURow', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The MOU has been successfully deleted.", 'success', 3000, null);

                        const container = document.querySelector(`.mouListItem[data-row='${row}']`);
                        if (container)
                            container.remove();

                        let modal: Modal = new Modal("deleteMOUModal", null);
                        modal.hide();
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting the row.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting the row.", 'error', 3000, null);
            }

            let modal: Modal = new Modal("deleteMOUModal", null);
            modal.hide();
        };
        xhr.send(JSON.stringify(data));
    }

    bindFileUploads() {
        let fileUploaders = document.getElementsByClassName("safeSchoolsMOUDocumentationUploader");
        for (let fileUploader of fileUploaders)
            fileUploader.addEventListener("change", (e: Event) => this.uploadFile(<HTMLInputElement>e.target));
    }
}

// SafeSchoolsMOUSignatures
class SafeSchoolsMOUSignatures {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["safeSchoolsMOUSignaturesField", "safeSchoolsMOUSignaturesCheckboxField"];

        let safeSchoolsMOUSignaturesSaveButton = document.getElementById("safeSchoolsMOUSignaturesSave");
        if (safeSchoolsMOUSignaturesSaveButton !== null)
            safeSchoolsMOUSignaturesSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("safeSchoolsMOUSignaturesForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("safeSchoolsMOUSignaturesField");

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let checkInputs = document.getElementsByClassName("safeSchoolsMOUSignaturesCheckboxField");
        for (let ele of checkInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let textValue = "";

            if (element.checked)
                textValue = "on";
            else
                textValue = "off";

            planPropertyPK = parseInt(element.dataset.planpropertypk);

            let saveItem: IPlanProperty = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: planPK,
                PropertyFK: propertyPK,
                TextValue: textValue,
                LookupCodeFK: null,
                RowNbr: parseInt(rowNumber),
                IsDeletedInd: false
            };

            allSaveElements.push(saveItem);
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/SaveSafeSchoolsMOUSignatures', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

// SafeSchoolsMOUSummaryChecklist
class SafeSchoolsMOUSummaryChecklist {
    private _core: Core;
    constructor() {
        this._core = new Core();
        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.back(e));

        let submitButton = document.getElementById("submitPlanButton");
        if (submitButton !== null)
            submitButton.addEventListener("click", (e: Event) => this.submit(e));

        this._core.leftnav(this);
    }

    back(e: Event) {
        let newPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }

    getCore() {
        return this._core;
    }

    submit(e: Event) {
        let element = <HTMLButtonElement>e.target;
        if (!element.classList.contains("disabled")) {
            let planFK = 0;
            planFK = parseInt(element.dataset.planfk);
            let core = this._core;
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SafeSchools/SubmitPlan', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "This report has been submitted for review!", 'success', 3000, window.location.reload());

                    setTimeout(function () { window.location.href = '/Reports/StateRequiredReports' }, 3000);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "An unexpected error occurred while submitting report. Please try again later.", 'error', 3000, null);
                }
            };
            xhr.send("planFK=" + planFK);
        }
    }
}

// SafeSchoolsAnnualACSProfile
class SafeSchoolsAnnualACSProfile {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["safeSchoolsAnnualACSProfileField"];

        let safeSchoolsAnnualACSProfileSaveButton = document.getElementById("safeSchoolsAnnualACSProfileSave");
        if (safeSchoolsAnnualACSProfileSaveButton !== null)
            safeSchoolsAnnualACSProfileSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        let educatorEffectivenessExecutiveSaveButton = document.getElementById("ExecutiveSave");
        if (educatorEffectivenessExecutiveSaveButton !== null)
            educatorEffectivenessExecutiveSaveButton.addEventListener("click", (e: Event) => this.executiveSave("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let refreshButton = document.getElementById("resetProfile");
        if (refreshButton !== null)
            refreshButton.addEventListener("click", (e: Event) => Core.refreshProfile("safeSchoolsAnnualACSProfileForm"));
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("safeSchoolsAnnualACSProfileForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("safeSchoolsAnnualACSProfileField");

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let selects = document.getElementsByClassName("safeSchoolsAnnualACSProfileSelectField");
        for (let ele of selects) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);

            let element = <HTMLSelectElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: "",
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/SaveSafeSchoolsAnnualACSProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    executiveSave(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("safeSchoolsAnnualACSProfileForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("profileICDataSave");

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/SaveSafeSchoolsAnnualACSProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

// SafeSchoolsAnnualACSSafeSchoolsACS
class SafeSchoolsAnnualACSSafeSchoolsACS {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["safeSchoolsAnnualACSSafeSchoolsACSField"];

        let safeSchoolsAnnualACSMisconductSaveButton = document.getElementById("safeSchoolsAnnualACSSafeSchoolsACSSave");
        if (safeSchoolsAnnualACSMisconductSaveButton !== null)
            safeSchoolsAnnualACSMisconductSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let exportLEAReportButton = document.getElementById("safeSchoolsAnnualACSLEAExport");
        if (exportLEAReportButton !== null)
            exportLEAReportButton.addEventListener("click", (e: Event) => this.exportReport(e));

        let exportSchoolReportButton = document.getElementById("safeSchoolsAnnualACSSchoolExport");
        if (exportSchoolReportButton !== null)
            exportSchoolReportButton.addEventListener("click", (e: Event) => this.exportReport(e));
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("safeSchoolsAnnualACSMisconductForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("safeSchoolsAnnualACSMisconductField");

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/SaveSafeSchoolsAnnualACSMisconduct', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    exportReport(e) {
        let formElement = document.getElementById("safeSchoolsAnnualACSSafeSchoolsACSForm");
        let planFK = formElement.dataset.planfk;
        let button = <HTMLButtonElement>e.target;
        let pageCode = button.dataset.pagecode;
        let whichReport = button.dataset.report;

        Core.showLoader();

        let url = `SafeSchoolsAnnualReport${whichReport}Export`;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', `/SafeSchools/${url}`, true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.responseType = 'blob';
        xhr.onload = function () {

            Core.hideLoader();

            if (xhr.status === 200) {
                let blob = this.response;
                let filename = `SafeSchoolsACS${whichReport}.pdf`;

                if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                    window.navigator.msSaveBlob(blob, filename);
                } else {
                    var a = <HTMLAnchorElement>document.createElement("a");
                    var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                    document.body.appendChild(a);
                    a.style.display = "none";
                    a.href = blobUrl;
                    a.download = filename;
                    a.click();
                }
            } else {
                Core.createHTMLAlert("alertMessageDiv", "There was an issue generating this report. Please try again later.", 'error', 3000, null);
            }
        }
        xhr.send(`planFK=${planFK}&pageCode=${pageCode}`);
    }
}

// SafeSchoolsAnnualACSSignatures
class SafeSchoolsAnnualACSSignatures {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["safeSchoolsAnnualACSSignaturesField", "safeSchoolsAnnualACSSignaturesUploadChiefLawSignature", "safeSchoolsAnnualACSSignaturesCheckboxField"];

        let safeSchoolsAnnualACSSignaturesSaveButton = document.getElementById("safeSchoolsAnnualACSSignaturesSave");
        if (safeSchoolsAnnualACSSignaturesSaveButton !== null)
            safeSchoolsAnnualACSSignaturesSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this.bindExplain();

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses, false, true);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let deleteFileConfirmButton = <HTMLButtonElement>document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e: Event) => this.deleteFileConfirm(deleteFileConfirmButton));

        let deleteFileCancelButton = <HTMLButtonElement>document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e: Event) => this.deleteFileCancel());

        let safeSchoolsACSLawEnforcementAddButton = document.getElementById("safeSchoolsACSLawEnforcementAdd");
        if (safeSchoolsACSLawEnforcementAddButton !== null)
            safeSchoolsACSLawEnforcementAddButton.addEventListener("click", (e: Event) => this.addLawEnforcement())

        this.bindUploaders();
        this.bindDeleteButtons();

        this.bindDeleteEnforcements();

        let deleteEnforcementConfirmButton = document.getElementById("safeSchoolsEnforcementRowConfirm");
        if (deleteEnforcementConfirmButton !== null)
            deleteEnforcementConfirmButton.addEventListener("click", (e: Event) => this.deleteEnforcementConfirm(e));

        let deleteEnforcementCancelButton = document.getElementById("safeSchoolsEnforcementRowCancel");
        if (deleteEnforcementCancelButton !== null)
            deleteEnforcementCancelButton.addEventListener("click", (e: Event) => this.deleteEnforcementCancel());

        let exportReport = document.getElementById("exportFullReport");
        if(exportReport !== null) {
            exportReport.addEventListener("click", function () {
                Core.showLongLoader();

                //Get all components for given template
                let xhr = new XMLHttpRequest();
                xhr.open('POST', '/ExportDocx/FullReportSafeSchoolsAnnualACSExport', true);
                xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
                xhr.responseType = 'blob';
                xhr.onload = function () {

                    Core.hideLongLoader();

                    if (xhr.status === 200) {
                        let blob = this.response;
                        let filename = exportReport.textContent;
                        filename = filename + ".pdf";

                        if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                            window.navigator.msSaveBlob(blob, filename);
                        } else {
                            var a = <HTMLAnchorElement>document.createElement("a");
                            var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                            document.body.appendChild(a);
                            a.style.display = "none";
                            a.href = blobUrl;
                            a.download = filename;
                            a.click();
                        }
                    } else {
                        Core.createHTMLAlert("alertMessageDiv", "There was an issue generating this report. Please try again later.", 'error', 3000, null);
                    }
                }
                xhr.send("planFK=" + exportReport.dataset.planfk + "&templateFK=" + exportReport.dataset.templatefk);
            });
        }

        const safeSchoolsAnnualACSSignaturesChiefLawEnforcementOfficerSignatureUploader = document.querySelector(".safeSchoolsAnnualACSSignaturesChiefLawEnforcementOfficerSignature");
        if (safeSchoolsAnnualACSSignaturesChiefLawEnforcementOfficerSignatureUploader !== null)
            safeSchoolsAnnualACSSignaturesChiefLawEnforcementOfficerSignatureUploader.addEventListener("change", (e: Event) => this.uploadLawEnforcement(<HTMLInputElement>e.target));
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("safeSchoolsAnnualACSSignaturesForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("safeSchoolsAnnualACSSignaturesField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let checkInputs = document.getElementsByClassName("safeSchoolsAnnualACSSignaturesCheckboxField");
        for (let ele of checkInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            planPropertyPK = parseInt(element.dataset.planpropertypk);
            let textValue = "";

            if (element.checked)
                textValue = "on";
            else
                textValue = "off";
 
            let saveItem: IPlanProperty = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: planPK,
                PropertyFK: propertyPK,
                TextValue: textValue,
                LookupCodeFK: null,
                RowNbr: parseInt(rowNumber),
                IsDeletedInd: false
            };

            allSaveElements.push(saveItem);
        }

        let allFileElements = [];
        let files = document.getElementsByClassName("safeSchoolsFile");
        for (let ele of files) {
            let element = <HTMLInputElement>ele;

            let sequenceNbr = parseInt(element.dataset.row);
            let fileUploadPK = parseInt(element.dataset.fileuploadpk);
            let propertyPK = parseInt(element.dataset.propertypk);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk);

            let saveItem: IPlanPropertyFile = {
                SequenceNbr: sequenceNbr,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanPropertyFilePK: planPropertyFilePK,
                PlanFK: planPK
            };

            allFileElements.push(saveItem);
        }

        var allData = {
            "ElementData": allSaveElements,
            "FileData": allFileElements
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/SaveSafeSchoolsAnnualACSSignatures', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allData === null) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }

    uploadFile(e: HTMLInputElement) {
        Core.showLoader();
        let formName = e.dataset.formname;

        let uploadSampleForm = <HTMLFormElement>document.getElementById(formName);
        let formData = new FormData(uploadSampleForm);

        let propertyPK = e.dataset.propertypk;
        let row = e.dataset.row;

        let that = this;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();

                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);

                        let formFile = <HTMLInputElement>document.getElementById(`${e.id}`);
                        formFile.value = "";

                        let fileList = document.querySelector(`.uploadFileList[data-row='${row}']`);

                        let uploadedContainer = document.querySelector(`.uploadedFiles[data-row='${row}']`);
                        uploadedContainer.classList.remove("hide");

                        let fileWrapper = <HTMLDivElement>document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = <HTMLDivElement>document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = <HTMLAnchorElement>document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.row = row;
                        fileA.classList.add("safeSchoolsFileField");
                        fileA.classList.add("safeSchoolsFile");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);

                        fileList.appendChild(fileWrapper);

                        that.bindDeleteButtons();
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }

    deleteFile(e: Event) {
        let fileButton = <HTMLButtonElement>e.target;
        let planPropertyFilePK = fileButton.dataset.planpropertyfilepk;
        let row = fileButton.dataset.row;

        if (planPropertyFilePK && parseInt(planPropertyFilePK) > 0) {
            let modal: Modal = new Modal("deleteFileModal", null);
            let deleteConfirmButton = <HTMLButtonElement>document.getElementById("deleteFileConfirm");
            deleteConfirmButton.dataset.planpropertyfilepk = planPropertyFilePK;
            deleteConfirmButton.dataset.row = row;
            modal.show();
        }
    }

    deleteFileConfirm(confirmButton: HTMLButtonElement) {
        let core = this._core;
        Core.showLoader();

        let row = confirmButton.dataset.row;
        let planPropertyFilePK = confirmButton.dataset.planpropertyfilepk;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/DeleteFiles', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file has been successfully deleted.", 'success', 3000, null);

                        let containerElement = <HTMLElement>document.querySelector(`.uploadFileColumn[data-planpropertyfilepk='${planPropertyFilePK}']`);
                        if (containerElement !== null) {
                            containerElement.parentNode.removeChild(containerElement);
                        }

                        let uploads = document.querySelectorAll(`.uploadFileColumn[data-row='${row}']`);
                        if (uploads.length === 0) {
                            let uploadContainer = document.querySelector(`.uploadedFiles[data-row='${row}']`);
                            uploadContainer.classList.add("hide");
                        }
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting file.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file removal failed. Please try again.", 'error', 3000, null);
            }

            let modal: Modal = new Modal("deleteFileModal", null);
            modal.hide();
        };
        xhr.send(JSON.stringify([planPropertyFilePK]));
    }

    deleteFileCancel() {
        let modal: Modal = new Modal("deleteFileModal", null);
        modal.hide();
    }

    bindUploaders() {
        let fileUploaders = document.getElementsByClassName("safeSchoolsAnnualACSSignaturesUploadChiefLawSignature");
        for (let fileUploader of fileUploaders)
            fileUploader.addEventListener("change", (e: Event) => this.uploadFile(<HTMLInputElement>e.target));
    }

    bindDeleteButtons() {
        let allDeleteButtons = document.getElementsByClassName("deleteFile");
        for (let delButton of allDeleteButtons)
            delButton.addEventListener("click", (e: Event) => this.deleteFile(e));
    }

    addLawEnforcement() {
        let planForm = document.getElementById("safeSchoolsAnnualACSSignaturesForm");
        let planFK = planForm.dataset.planfk;

        let that = this;
        let newRow = 0;

        Core.showLoader();

        let list = document.getElementsByClassName("safeSchoolsACSLawEnforcement");

        for (let item of list) {
            let ele = <HTMLDivElement>item;

            if (ele.dataset.row && parseInt(ele.dataset.row) > newRow) {
                newRow = parseInt(ele.dataset.row);
            }
        }

        newRow++;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/AddSafeSchoolsAnnualACSLawEnforcement', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                let container = document.getElementById("safeSchoolsACSLawEnforcementList");
                let newDiv = <HTMLDivElement>document.createElement("div");

                newDiv.innerHTML = xhr.responseText;
                newDiv.dataset.row = newRow.toString();
                newDiv.classList.add("safeSchoolsACSLawEnforcement");
                newDiv.classList.add("mou-list-item");

                container.append(newDiv);

                that.bindUploaders();
                that.bindExplain();
                let file = <HTMLInputElement>document.getElementById("safeSchoolsAnnualACSSignaturesUploadChiefLawSignature" + newRow.toString());
                that._core.forceElementRequired(file);

                that.bindDeleteEnforcements();

                Core.createHTMLAlert("alertMessageDiv", "Local Enforcement Agency with School Entity Jurisdiction record added!", 'success', 2000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", `Request failed.  Returned status of '${xhr.status}'`, 'error', 3000, null);
                Core.hideLoader();
            }
        };
        xhr.send(`planFK=${planFK}&row=${newRow}`);
    }

    bindExplain() {
        const checkboxes = document.getElementsByClassName("safeSchoolsAnnualACSSignaturesCheckboxFieldHide");
        for (let checkbox of checkboxes) {
            checkbox.addEventListener("click", (e: Event) => this.ExplanationClick(e.target as HTMLInputElement));
            this.ExplanationClick(checkbox as HTMLInputElement);
        }
    }

    ExplanationClick(checkbox: HTMLInputElement) {
        const row = checkbox.dataset.row;
        const ExplainInput = <HTMLInputElement>document.getElementById("safeSchoolsAnnualACSSignaturesExplanation" + row);
        const ExplainRow = <HTMLInputElement>document.getElementById("Explanation_" + row);
        if (checkbox.checked) {
            this._core.forceElementRequired(ExplainInput);
            ExplainRow.classList.remove("hide");
        } else {
            this._core.forceElementOptional(ExplainInput);
            ExplainRow.classList.add("hide");
        }
    }

    bindDeleteEnforcements() {
        const deletes = document.getElementsByClassName("deleteEnforcementRow");
        for (const del of deletes) {
            del.addEventListener("click", (e: Event) => this.showDeleteEnforcementRow(e));
        }
    }

    showDeleteEnforcementRow(e: Event) {
        const button = <HTMLButtonElement>e.target;

        const row = button.dataset.row;
        let modal: Modal = new Modal("deleteEnforcementModal", null);
        modal.addAttributeToElement("deleteEnforcementModal", "#safeSchoolsEnforcementRowConfirm", "row", row);
        modal.show();
    }

    deleteEnforcementCancel() {
        let modal: Modal = new Modal("deleteEnforcementModal", null);
        modal.hide();
    }

    deleteEnforcementConfirm(e: Event) {
        const button = <HTMLButtonElement>e.target;
        const row = button.dataset.row;

        let core = this._core;
        Core.showLoader();

        const planPropertyFiles = [];
        const planPropertyPKs = [];
        const allChecks = document.querySelectorAll(`.safeSchoolsAnnualACSSignaturesCheckboxField[data-row='${row}']`);
        for (const check of allChecks) {
            const ele = <HTMLInputElement>check;
            if (ele.dataset.planpropertypk && parseInt(ele.dataset.planpropertypk) > 0)
                planPropertyPKs.push(ele.dataset.planpropertypk);
        }

        const allFiles = document.querySelectorAll(`.uploadFileColumn[data-row='${row}']`);
        for (const file of allFiles) {
            const ele = <HTMLInputElement>file;
            if (ele.dataset.planpropertyfilepk && parseInt(ele.dataset.planpropertyfilepk) > 0)
                planPropertyFiles.push(ele.dataset.planpropertyfilepk);
        }

        const data = {
            "PlanPropertyPKs": planPropertyPKs,
            "PlanPropertyFilePKs": planPropertyFiles
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/DeleteEnforcementRow', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The row has been successfully deleted.", 'success', 3000, null);

                        const container = document.querySelector(`.safeSchoolsACSLawEnforcement[data-row='${row}']`);
                        if (container)
                            container.remove();

                        let modal: Modal = new Modal("deleteEnforcementModal", null);
                        modal.hide();
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting the row.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting the row.", 'error', 3000, null);
            }

            let modal: Modal = new Modal("deleteFileModal", null);
            modal.hide();
        };
        xhr.send(JSON.stringify(data));
    }

    uploadLawEnforcement(e: HTMLInputElement) {
        Core.showLoader();
        let formName = e.dataset.formname;

        let uploadSampleForm = <HTMLFormElement>document.getElementById(formName);
        let formData = new FormData(uploadSampleForm);

        let propertyPK = e.dataset.propertypk;
        let row = "0";

        let that = this;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();

                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);

                        let formFile = <HTMLInputElement>document.getElementById(`${e.id}`);
                        formFile.value = "";

                        let fileList = document.querySelector(`.uploadFileList[data-lawofficer="true"]`);

                        let uploadedContainer = document.querySelector(`.uploadedFiles[data-lawofficer="true"]`);
                        uploadedContainer.classList.remove("hide");

                        let fileWrapper = <HTMLDivElement>document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = <HTMLDivElement>document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = <HTMLAnchorElement>document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.row = row;
                        fileA.classList.add("safeSchoolsFileField");
                        fileA.classList.add("safeSchoolsFile");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);

                        fileList.appendChild(fileWrapper);

                        that.bindDeleteButtons();
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
}

// SafeSchoolsAnnualACSSummary
class SafeSchoolsAnnualACSSummary {
    private _core: Core;
    constructor() {
        this._core = new Core();
        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.back(e));

        let submitButton = document.getElementById("submitPlanButton");
        if (submitButton !== null)
            submitButton.addEventListener("click", (e: Event) => this.submit(e));

        this._core.leftnav(this);
    }

    back(e: Event) {
        let newPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }

    getCore() {
        return this._core;
    }

    submit(e: Event) {
        let element = <HTMLButtonElement>e.target;
        if (!element.classList.contains("disabled")) {
            let planFK = 0;
            planFK = parseInt(element.dataset.planfk);
            let core = this._core;
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SafeSchools/SubmitPlan', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "This report has been submitted for review!", 'success', 3000, window.location.reload());

                    setTimeout(function () { window.location.href = '/Reports/StateRequiredReports' }, 3000);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "An unexpected error occurred while submitting report. Please try again later.", 'error', 3000, null);
                }
            };
            xhr.send("planFK=" + planFK);
        }
    }
}