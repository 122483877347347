/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />
/// <reference path="interfaces/common/JQuery.d.ts" />

class OutcomeBasedReports {
    private _core: Core;
    private currentModal: Modal;
    private planFK: string;
    private templateFK: string;
    private formatCodeFK: string;
    private formatCode: string;
    private reportTypeCode: string;

    constructor() {
        let that = this;
        that._core = new Core();

        let categoryElement = document.getElementById("planTypeGroup");
        if (categoryElement != null) {
            categoryElement.addEventListener("change", that.populatePlanTypeList);
        }

        that.populatePlanTypeList();
        that.addEventListenersForPlans();

        let selectAll = document.querySelector(".selectAll");
        if (selectAll !== null) {
            selectAll.addEventListener("change", (e: Event) => that.customizeReportSelectAll(<Element>e.target));
        }

        let onePageReportModals = document.getElementsByClassName("onePageReportContainer") as HTMLCollectionOf<HTMLElement>;
        if (onePageReportModals.length) {
            for (let onePageReportModal of onePageReportModals) {
                let format = onePageReportModal.dataset.format;

                let onePageReportButtons = onePageReportModal.getElementsByClassName("onePageCreateReport");
                for (let onePageReportButton of onePageReportButtons) {
                    let functionName = "createOnePageReport" + format.toUpperCase();
                    onePageReportButton.addEventListener("click", (e: Event) => that[functionName](onePageReportModal));
                }
            }
        }

        let createCustomReportButton = document.getElementById("createCustomReport");
        if (createCustomReportButton != null) {
            createCustomReportButton.addEventListener("click", function () {
                switch (that.formatCode) {
                    case "pdf":
                        that.createCustomReportPDF();
                        break;
                    case "docx":
                        that.createCustomReportDOCX();
                        break;
                }
            });
        }

        let clearButton = <HTMLButtonElement>document.getElementById("clearSearch");
        if (clearButton !== null)
            clearButton.addEventListener("click", (e: Event) => this.clearSearchFilter());

        let searchButton = <HTMLButtonElement>document.getElementById("runSearch");
        if (searchButton !== null)
            searchButton.addEventListener("click", (e: Event) => this.search(1));

        let leaName = <HTMLInputElement>document.getElementById("leaName");
        if (leaName !== null)
            leaName.addEventListener("keypress", (e: KeyboardEvent) => { if (e.keyCode === 13) { this.search(1); } });

        let schoolName = <HTMLInputElement>document.getElementById("schoolName");
        if (schoolName !== null)
            schoolName.addEventListener("keypress", (e: KeyboardEvent) => { if (e.keyCode === 13) { this.search(1); } });

        this._core.bindChangeLEA();

        window.addEventListener('popstate', function (e) {
            let query: IPlanQueryModel = e.state;

            let yearElement = <HTMLSelectElement>document.getElementById("planYear");
            let leaNameElement = <HTMLInputElement>document.getElementById("leaName");
            let statusElement = <HTMLSelectElement>document.getElementById("planStatus");
            let typeElement = <HTMLSelectElement>document.getElementById("planType");
            let phaseElement = <HTMLSelectElement>document.getElementById("planPhase");
            let categoryElement = <HTMLSelectElement>document.getElementById("planTypeGroup");
            let schoolNameElement = <HTMLSelectElement>document.getElementById("schoolName");

            if (yearElement != null) {
                if (query != null && query.Year != null) {
                    yearElement.value = query.Year.toString();
                } else {
                    yearElement.selectedIndex = 0;
                }
            }

            if (leaNameElement != null) {
                if (query != null && query.LEAName != null) {
                    leaNameElement.value = query.LEAName;
                } else {
                    leaNameElement.value = "";
                }
            }

            if (schoolNameElement != null) {
                if (query != null && query.SchoolName != null) {
                    schoolNameElement.value = query.SchoolName;
                } else {
                    schoolNameElement.value = "";
                }
            }

            if (categoryElement != null) {
                if (query != null && query.PlanTypeGroupCode != null) {
                    categoryElement.value = query.PlanTypeGroupCode;
                } else {
                    categoryElement.selectedIndex = 0;
                }
            }

            if (statusElement != null) {
                if (query != null && query.StatusPKs != null) {
                    statusElement.value = query.StatusPKs.toString();
                } else {
                    statusElement.selectedIndex = 0;
                }
            }

            if (typeElement != null) {
                if (query != null && query.PlanTypePK != null) {
                    typeElement.value = query.PlanTypePK.toString();
                } else {
                    typeElement.selectedIndex = 0;
                }
            }

            if (phaseElement != null) {
                if (query != null && query.PhaseCodeFK != null) {
                    phaseElement.value = query.PhaseCodeFK.toString();
                } else {
                    phaseElement.selectedIndex = 0;
                }
            }

            if (query != null) {
                that.search(query.PageNumber, query, true);
            } else {
                let container = document.getElementById("plansList");
                if (container != null) {
                    container.innerHTML = "";
                }
            }
        });


    }

    customizeReportSelectAll(selectAllCheckbox: Element) {
        let modal = Core.findClosest(selectAllCheckbox, ".customizePlanReportContainer");
        let allCheckboxes = modal.getElementsByClassName("customizeItem");
        let allChecked: boolean = true;
        for (let checkbox of allCheckboxes) {
            let element = <HTMLInputElement>checkbox;
            if (!element.checked)
                allChecked = false;
        }

        let word = modal.querySelector(".selectAllWord");

        if (!allChecked) {
            for (let checkbox of allCheckboxes) {
                let element = <HTMLInputElement>checkbox;
                element.checked = true;
            }
            word.textContent = "Remove";
        } else {
            for (let checkbox of allCheckboxes) {
                let element = <HTMLInputElement>checkbox;
                element.checked = false; 
            }
            word.textContent = "Select";
        }
    }

    checkForCheckboxWord(checkbox: Element) {
        let container = Core.findClosest(checkbox, ".customizePlanReportContainer");
        let allCheckboxes = container.getElementsByClassName("customizeItem");
        let selectAll = container.querySelector(".selectAll") as HTMLInputElement;

        let allChecked: boolean = true;
        for (let checkbox of allCheckboxes) {
            let element = <HTMLInputElement>checkbox;
            if (!element.checked)
                allChecked = false;
        }

        let word = container.querySelector(".selectAllWord");

        if (allChecked) {
            word.textContent = "Remove";
            selectAll.checked = true;
        } else {
            word.textContent = "Select";
            selectAll.checked = false;
        }
    }

    showCustomizeReport(exportOption: HTMLElement) {

        this.reportTypeCode = exportOption.dataset.reporttypecode;

        let that = this;
        Core.showLoader();
        //Reset Export Info
        that.planFK = exportOption.dataset.planfk;
        that.templateFK = exportOption.dataset.templatefk;
        that.formatCodeFK = exportOption.dataset.exportFormatCodeFk;
        that.formatCode = exportOption.dataset.exportFormatCode;

        let modalId = exportOption.dataset.modal;
        let modalElement = document.getElementById("customizeReport");
        that.currentModal = new Modal("customizeReport", null);

        let planDetails = document.querySelector(`.planContainer[data-planfk='${that.planFK}'] .planTypeDetails`);
        if (planDetails != null) {
            let modalElementHeading = modalElement.querySelector("h1");
            if (modalElementHeading != null) {
                modalElementHeading.innerHTML = planDetails.innerHTML;
            }
        }

        //Make call to populate modal
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/OutcomeBasedReports/GetExportModalComponentsView', true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let componentContainer = document.getElementById("exportComponentsContainer");
                componentContainer.innerHTML= xhr.responseText;

                let allCheckboxes = modalElement.getElementsByClassName("customizeItem");
                for (let checkbox of allCheckboxes) {
                    let element = <HTMLInputElement>checkbox;
                    element.checked = false;
                    element.addEventListener("change", (e: Event) => that.checkForCheckboxWord(<Element>e.target));
                }

                let word = modalElement.querySelector(".selectAllWord");
                word.textContent = "Select";

                let selectAll = modalElement.querySelector(".selectAll") as HTMLInputElement;
                selectAll.checked = false;
                Core.hideLoader();
                that.currentModal.show();
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "This action could not be performed. Please try again later.", 'error', 3000, null);
            }
        }
        xhr.send("templateFK="+that.templateFK+"&exportFormatCodeFK="+that.formatCodeFK);
    }

    showOnePageReport(exportOption: HTMLElement) {

        let that = this;

        //Reset Export Info
        that.planFK = exportOption.dataset.planfk;
        that.templateFK = exportOption.dataset.templatefk;

        let modalId = exportOption.dataset.modal;
        let modalElement = document.getElementById(modalId);
        that.currentModal = new Modal(modalId, null);

        let planDetails = document.querySelector(`.planContainer[data-planfk='${that.planFK}'] .planTypeDetails`);
        if (planDetails != null) {
            let modalElementHeading = modalElement.querySelector("h1");
            if (modalElementHeading != null) {
                modalElementHeading.innerHTML = planDetails.innerHTML;
            }
        }

        that.currentModal.show();
    }

    createCustomReportDOCX() {
        let that = this;
        let core = that._core;

        if (this.currentModal !== undefined) {
            this.currentModal.hide();
        }

        Core.showLoader();

        let modalContainer = document.getElementById("customizeReport");

        let allCheckboxes = modalContainer.getElementsByClassName("customizeItem");
        let componentsToBuild:string[] = [];

        let headerComponent = document.getElementById("headerComponent") as HTMLInputElement;
        if (headerComponent != null && headerComponent.value) {
            componentsToBuild.push(headerComponent.value);
        }

        for (let checkbox of allCheckboxes) {
            let element = <HTMLInputElement>checkbox;
            if (element.checked && "component" in element.dataset) {
                componentsToBuild.push(element.dataset.component);
            }
        }

        let request: IReportExportRequestModel = {
            Components: componentsToBuild,
            PlanFK: parseInt(that.planFK)
        };

        let url = "/ExportDocx/CustomReportDataExport";
        if (that.reportTypeCode !== "") {
            url = `/ExportDocx/CustomReport${that.reportTypeCode}Export`;
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', url, true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.responseType = "blob";
        xhr.onload = function () {
            Core.hideLoader();

            if (xhr.status === 200) {
                let blob = this.response;
                let filename = "planexport";
                let planDetails = document.querySelector(`.planContainer[data-planfk='${that.planFK}'] .planTypeDetails`);
                if (planDetails != null) {
                    filename = planDetails.innerHTML;
                }
                filename = filename + ".docx";

                if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                    window.navigator.msSaveBlob(blob, filename);
                } else {
                    var a = <HTMLAnchorElement>document.createElement("a");
                    var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                    document.body.appendChild(a);
                    a.style.display = "none";
                    a.href = blobUrl;
                    a.download = filename;
                    a.click();
                }
            } else {
                Core.createHTMLAlert("alertMessageDiv", "There was an issue during export to Word. Please try again later.", 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(request));
    }

    createFullReportDOCX() {
        let that = this;
        let core = that._core;
        Core.showLoader();

        let url = "/ExportDocx/FullReportDataExport";
        if (that.reportTypeCode !== "") {
            url = `/ExportDocx/FullReport${that.reportTypeCode}Export`;
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', url, true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.responseType = "blob";
        xhr.onload = function () {
            Core.hideLoader();

            if (xhr.status === 200) {
                let blob = this.response;
                let filename = "planexport";
                let planDetails = document.querySelector(`.planContainer[data-planfk='${that.planFK}'] .planTypeDetails`);
                if (planDetails != null) {
                    filename = planDetails.innerHTML;
                }
                filename = filename + ".docx";

                if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                    window.navigator.msSaveBlob(blob, filename);
                } else {
                    var a = <HTMLAnchorElement>document.createElement("a");
                    var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                    document.body.appendChild(a);
                    a.style.display = "none";
                    a.href = blobUrl;
                    a.download = filename;
                    a.click();
                }
            } else {
                Core.createHTMLAlert("alertMessageDiv", "There was an issue during export to Word. Please try again later.", 'error', 3000, null);
            }
        };
        xhr.send(`planFK=${that.planFK}&templateFK=${that.templateFK}`);
    }

    createCustomReportPDF() {

        let that = this;
        let core = that._core;

        if (this.currentModal !== undefined) {
            this.currentModal.hide();
        }

        Core.showLoader();

        let modalContainer = document.getElementById("customizeReport");

        let allCheckboxes = modalContainer.getElementsByClassName("customizeItem");
        let componentsToBuild: string[] = [];

        let headerComponent = document.getElementById("headerComponent") as HTMLInputElement;
        if (headerComponent != null && headerComponent.value) {
            componentsToBuild.push(headerComponent.value);
        }

        for (let checkbox of allCheckboxes) {
            let element = <HTMLInputElement>checkbox;
            if (element.checked && "component" in element.dataset) {
                componentsToBuild.push(element.dataset.component);
            }
        }

        let request: IReportExportRequestModel = {
            Components: componentsToBuild,
            PlanFK: parseInt(that.planFK)
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/OutcomeBasedReports/CustomReportDataExportPDF', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.responseType = "blob";
        xhr.onload = function () {
            Core.hideLoader();
            if (xhr.status === 200) {
                let blob = this.response;
                let filename = "planexport";
                let planDetails = document.querySelector(`.planContainer[data-planfk='${that.planFK}'] .planTypeDetails`);
                if (planDetails != null) {
                    filename = planDetails.innerHTML;
                }
                filename = filename + ".pdf";

                if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                    window.navigator.msSaveBlob(blob, filename);
                } else {
                    var a = <HTMLAnchorElement>document.createElement("a");
                    var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                    document.body.appendChild(a);
                    a.style.display = "none";
                    a.href = blobUrl;
                    a.download = filename;
                    a.click();
                }
            } else {
                Core.createHTMLAlert("alertMessageDiv", "There was an issue during export. Please try again later.", 'error', 3000, null);
            }
        }
        xhr.send(JSON.stringify(request));
    }

    createFullReportPDF() {
        let that = this;
        Core.showLoader();

        //Get all components for given template
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/OutcomeBasedReports/FullReportDataExportPDF', true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.responseType = 'blob';
        xhr.onload = function () {

            Core.hideLoader();

            if (xhr.status === 200) {
                let blob = this.response;
                let filename = "planexport";
                let planDetails = document.querySelector(`.planContainer[data-planfk='${that.planFK}'] .planTypeDetails`);
                if (planDetails != null) {
                    filename = planDetails.innerHTML;
                }
                filename = filename + ".pdf";

                if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                    window.navigator.msSaveBlob(blob, filename);
                } else {
                    var a = <HTMLAnchorElement>document.createElement("a");
                    var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                    document.body.appendChild(a);
                    a.style.display = "none";
                    a.href = blobUrl;
                    a.download = filename;
                    a.click();
                }
            } else {
                Core.createHTMLAlert("alertMessageDiv", "There was an issue generating this report. Please try again later.", 'error', 3000, null);
            }
        }
        xhr.send("planFK=" + that.planFK + "&templateFK=" + that.templateFK);
    }

    createFullReportDOCXWithFiles() {
        let that = this;
        let core = that._core;
        Core.showLongLoader();

        let url = "";
        if (that.reportTypeCode !== "") {
            url = `/ExportDocx/FullReportExportWithFiles`;
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', url, true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.responseType = "blob";
        xhr.onload = function () {
            Core.hideLongLoader();

            if (xhr.status === 200) {
                let blob = this.response;
                let filename = "planexport";
                let planDetails = document.querySelector(`.planContainer[data-planfk='${that.planFK}'] .planTypeDetails`);
                if (planDetails != null) {
                    filename = planDetails.innerHTML;
                }
                filename = filename + ".zip";

                if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                    window.navigator.msSaveBlob(blob, filename);
                } else {
                    var a = <HTMLAnchorElement>document.createElement("a");
                    var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                    document.body.appendChild(a);
                    a.style.display = "none";
                    a.href = blobUrl;
                    a.download = filename;
                    a.click();
                }
            } else {
                Core.createHTMLAlert("alertMessageDiv", "There was an issue during export to Word. Please try again later.", 'error', 3000, null);
            }
        };
        xhr.send(`planFK=${that.planFK}&templateFK=${that.templateFK}`);
    }

    createFullReportPDFWithFiles() {
        //Todo: Implement if needed.
    }

    createOnePageReportPDF(modalContainer: HTMLElement) {
        let that = this;

        let button = modalContainer.querySelector(".onePageCreateReport") as HTMLButtonElement;
        let baseUrl = button.dataset.url;
        let report = button.dataset.report;

        that.currentModal.hide();

        Core.showLoader();

        let radios = modalContainer.querySelectorAll("[name='layoutSelection']");
        let whichLayout = "";

        for (let radio of radios) {
            let ele = <HTMLInputElement>radio;
            if (ele.checked)
                whichLayout = ele.value;
        }

        this.buildSinglePageReportPDF(that.planFK, that.templateFK, baseUrl, whichLayout, report);
    }

    createOnePageReportDOCX(modalContainer: HTMLElement) {
        let that = this;

        let button = modalContainer.querySelector(".onePageCreateReport") as HTMLButtonElement;
        let report = button.dataset.report;

        that.currentModal.hide();

        Core.showLoader();

        let radios = modalContainer.querySelectorAll("[name='layoutSelection']");
        let whichLayout = "";

        for (let radio of radios) {
            let ele = <HTMLInputElement>radio;
            if (ele.checked)
                whichLayout = ele.value;
        }

        this.buildSinglePageReportDOCX(that.planFK, that.templateFK, whichLayout);
    }

    buildComponent(planFK: string, templateFK: string, component: string) {
        var xhr = new XMLHttpRequest();
        return new Promise(function (resolve, reject) {
            xhr.onreadystatechange = function () {
                if (xhr.readyState == 4) {
                    if (xhr.status >= 300) {
                        reject("Error, status code = " + xhr.status)
                    } else {
                        resolve(xhr.responseText);
                    }
                }
            }
            xhr.open('POST', `/OutcomeBasedReports/${component}`, true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.send(`planFK=${planFK}&templateFK=templateFK`);
        });
    }

    buildSinglePageReportPDF(planFK, templateFK, baseUrl, whichLayout, report) {
        let that = this;

        const getReportStuff = async () => {
            let componentsToBuild = [];

            const componentToBuild = await that.buildSinglePageComponent(planFK, templateFK, whichLayout, report);

            componentsToBuild.push(componentToBuild);

            return componentsToBuild;
        }

        const allTheReports = getReportStuff();

        let htmlReport = "";
        htmlReport += "<!DOCTYPE html>";
        htmlReport += "<html lang=\"en-us\">";
        htmlReport += "<head>";
        htmlReport += "<meta charset=\"utf-8\" />";
        htmlReport += "<meta name=\"viewport\" content=\"width=device-width, initial-scale=1.0\" />";
        htmlReport += "<title>Outcome based Report - Future Ready Comprehensive Planning Portal</title>";
        htmlReport += `<link rel=\"stylesheet\" href=\"${baseUrl}/css/foundation.css\" />`;
        htmlReport += `<link rel=\"stylesheet\" href=\"${baseUrl}/css/site.css\" />`;
        htmlReport += "<link rel=\"stylesheet\" href=\"https://use.typekit.net/hrl7phx.css\" />";
        htmlReport += "<script src='https://kit.fontawesome.com/968075e8cc.js' crossorigin=\"anonymous\"> </script>";
        htmlReport += `<script src=\"${baseUrl}/js/vendor.js\" type=\"text/javascript\"></script>`;
        htmlReport += `<script src=\"${baseUrl}/js/site.js\" type=\"text/javascript\"></script>`;
        htmlReport += "</head>"
        htmlReport += "<body>";

        let landscape = false;
        if (whichLayout.includes("Landscape"))
            landscape = true;

        allTheReports.then(reports => {
            for (let report of reports) {
                if (report)
                    htmlReport += report;
            }

            htmlReport += "</body>";
            htmlReport += "</html>";

            let saveElement = {
                Html: htmlReport,
                Landscape: landscape
            };

            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/OutcomeBasedReports/ExportReport', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.responseType = "blob";
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    let blob = this.response;
                    let filename = `${whichLayout}.pdf`;

                    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                        window.navigator.msSaveBlob(blob, filename);
                    } else {
                        var a = <HTMLAnchorElement>document.createElement("a");
                        var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                        document.body.appendChild(a);
                        a.style.display = "none";
                        a.href = blobUrl;
                        a.download = filename;
                        a.click();
                    }
                } else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue during export. Please try again later.", 'error', 3000, null);
                }
            }
            xhr.send(JSON.stringify(saveElement));
        }).catch(e => {
            console.error(e);
            Core.hideLoader();
            Core.createHTMLAlert("alertMessageDiv", "There was an issue generating report", 'error', 3000, null);
        });
    }

    buildSinglePageReportDOCX(planFK, templateFK, whichLayout) {
        let that = this;

        let core = that._core;
        Core.showLoader();

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/ExportDocx/SinglePage/'+whichLayout, true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.responseType = "blob";
        xhr.onload = function () {
            Core.hideLoader();

            if (xhr.status === 200) {
                let blob = this.response;
                let filename = "planexport";
                let planDetails = document.querySelector(`.planContainer[data-planfk='${planFK}'] .planTypeDetails`);
                if (planDetails != null) {
                    filename = planDetails.innerHTML;
                }
                filename = filename + ".docx";

                if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                    window.navigator.msSaveBlob(blob, filename);
                } else {
                    var a = <HTMLAnchorElement>document.createElement("a");
                    var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                    document.body.appendChild(a);
                    a.style.display = "none";
                    a.href = blobUrl;
                    a.download = filename;
                    a.click();
                }
            } else {
                Core.createHTMLAlert("alertMessageDiv", "There was an issue during export to Word. Please try again later.", 'error', 3000, null);
            }
        };
        xhr.send("planFK="+planFK+"&templateFK="+templateFK);
    }

    buildSinglePageComponent(planFK, templateFK, whichLayout, report) {
        var xhr = new XMLHttpRequest();
        return new Promise(function (resolve, reject) {
            xhr.onreadystatechange = function () {
                if (xhr.readyState == 4) {
                    if (xhr.status >= 300) {
                        reject("Error, status code = " + xhr.status)
                    } else {
                        resolve(xhr.responseText);
                    }
                }
            }
            xhr.open('POST', `/OutcomeBasedReports/OnePageReport`, true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.send(`planFK=${planFK}&templateFK=${templateFK}&layout=${whichLayout}&report=${report}`);
        });
    }

    search(pageNumber: number, searchQuery: IPlanQueryModel = null, fromHistory: boolean = false) {
        let that = this;

        let query = searchQuery;
        if (query === null) {
            query = Core.GetPlanQueryModel(pageNumber);
        }

        let params = "";
        for (var key in query) {
            if (params != "") {
                params += "&";
            }
            params += key + "=" + encodeURIComponent(query[key]);
        }

        let queryString = "?" + params; 

        Core.showLoader();
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/OutcomeBasedReports/RefreshPlansList', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            Core.hideLoader();

            let container = document.getElementById("plansList");
            if (container != null) {
                container.innerHTML = xhr.responseText;

                //Add to history so back button works
                if (!fromHistory) {
                    history.pushState(query, '', queryString);
                }

                that.addEventListenersForPlans();
            }
        };
        xhr.send(JSON.stringify(query));
    }

    clearSearchFilter() {
        let yearElement = <HTMLSelectElement>document.getElementById("planYear");
        let leaNameElement = <HTMLInputElement>document.getElementById("leaName");
        let statusElement = <HTMLSelectElement>document.getElementById("planStatus");
        let typeElement = <HTMLSelectElement>document.getElementById("planType");
        let phaseElement = <HTMLSelectElement>document.getElementById("planPhase");
        let categoryElement = <HTMLSelectElement>document.getElementById("planTypeGroup");
        let schoolNameElement = <HTMLSelectElement>document.getElementById("schoolName");

        if (yearElement != null) {
            yearElement.selectedIndex = 0;
        }

        if (leaNameElement != null) {
            leaNameElement.value = "";
        }

        if (statusElement != null) {
            statusElement.selectedIndex = 0;
        }

        if (typeElement != null) {
            typeElement.selectedIndex = 0;
        }

        if (phaseElement != null) {
            phaseElement.selectedIndex = 0;
        }
        
        if (categoryElement != null) {
            categoryElement.selectedIndex = 0;
        }

        if (schoolNameElement != null) {
            schoolNameElement.value = "";
        }

        this.populatePlanTypeList();

        let container = document.getElementById("plansList");
        if (container != null) {
            container.innerHTML = "";
        }

        history.pushState(null, '', '/OutcomeBasedReports');
        document.title = "Plan Reports and Print - Future Ready Comprehensive Planning Portal";
    }

    populatePlanTypeList() {

        let typeElement = <HTMLSelectElement>document.getElementById("planType");
        let categoryElement = <HTMLSelectElement>document.getElementById("planTypeGroup");

        let type = typeElement == null ? "" : typeElement.value;
        let category = categoryElement == null ? "" : categoryElement.value;

        typeElement.disabled = true;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/OutcomeBasedReports/GetPlanTypeList', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            
            if (xhr.status === 200) {

                let planTypeList = document.getElementById("planTypeContainer");
                if (planTypeList != null) {
                    planTypeList.innerHTML = xhr.responseText;
                    typeElement.disabled = false;
                }
            } else {
                typeElement.disabled = false;
            }
        };
        xhr.send("planTypeGroupCode=" + category+"&selectedPlanType="+type);
    }

    addEventListenersForPlans() {
        let that = this;

        let completeReports = document.getElementsByClassName("completeReport") as HTMLCollectionOf<HTMLElement>;
        for (let completeReport of completeReports) {
            completeReport.addEventListener("click", function () {
                //Reset Export Info
                that.planFK = completeReport.dataset.planfk;
                that.templateFK = completeReport.dataset.templatefk;
                that.formatCodeFK = completeReport.dataset.exportFormatCodeFk;
                that.formatCode = completeReport.dataset.exportFormatCode;
                that.reportTypeCode = completeReport.dataset.reporttypecode;

                switch (that.formatCode) {
                    case "pdf":
                        that.createFullReportPDF();
                        break;
                    case "docx":
                        that.createFullReportDOCX();
                        break;
                }
            });
        }

        const completeReportWithFiles = document.getElementsByClassName("completeReportWithFiles") as HTMLCollectionOf<HTMLElement>;
        for (const report of completeReportWithFiles) {
            report.addEventListener("click", function () {
                //Reset Export Info
                that.planFK = report.dataset.planfk;
                that.templateFK = report.dataset.templatefk;
                that.formatCodeFK = report.dataset.exportFormatCodeFk;
                that.formatCode = report.dataset.exportFormatCode;
                that.reportTypeCode = report.dataset.reporttypecode;

                switch (that.formatCode) {
                    case "pdf":
                        that.createFullReportPDFWithFiles();
                        break;
                    case "docx":
                        that.createFullReportDOCXWithFiles();
                        break;
                }
            });
        }

        let customizeReports = document.getElementsByClassName("customizeReport") as HTMLCollectionOf<HTMLElement>;
        for (let customizeReport of customizeReports) {
            customizeReport.addEventListener("click", (e: Event) => that.showCustomizeReport(<HTMLElement>e.target));
        }

        let onePageReports = document.getElementsByClassName("onePageReport");
        for (let onePageReport of onePageReports) {
            onePageReport.addEventListener("click", (e: Event) => that.showOnePageReport(<HTMLElement>e.target));
        }

        let selectToExport = document.getElementsByClassName("selectExportCheckbox");
        for (let selectCheckbox of selectToExport) {
            selectCheckbox.addEventListener("change", that.updateSelectToExport);
        }

        let exportSelectAll = document.getElementById("exportSelectAll");
        if (exportSelectAll != null) {
            let exportSelectAllElement = <HTMLInputElement>exportSelectAll;
            exportSelectAll.addEventListener("change", function (e) {
                let exportCheckboxes = document.getElementsByClassName("selectExportCheckbox") as HTMLCollectionOf<HTMLInputElement>;
                for (let checkbox of exportCheckboxes) {
                    if (exportSelectAllElement.checked) {
                        checkbox.checked = true;
                    } else {
                        checkbox.checked = false;
                    }
                }

                that.updateSelectToExport();
            });
        }

        let bulkExport = document.getElementById("bulkExport");
        if (bulkExport != null) {
            bulkExport.addEventListener("click", function () {
                that.bulkExport();
            });
        }

        let searchResultsMessage = document.getElementById("searchResultsMessage");
        if (searchResultsMessage != null) {
            document.title = searchResultsMessage.textContent + " - Plan Reports and Print - Future Ready Comprehensive Planning Portal";
            searchResultsMessage.focus();
        }

        //Reinitialize Foundation dropdowns
        $(document).foundation();
    }

    updateSelectToExport() {
        let that = this;

        let exportSelectAll = document.getElementById("exportSelectAll") as HTMLInputElement;
        let allCheckboxes = document.getElementsByClassName("selectExportCheckbox") as HTMLCollectionOf<HTMLInputElement>;

        let allChecked: boolean = true;
        let atLeastOneChecked: boolean = false;

        for (let checkbox of allCheckboxes) {
            let element = <HTMLInputElement>checkbox;
            if (!element.checked) {
                allChecked = false;
            } else {
                atLeastOneChecked = true;
            }
        }

        let bulkExport = document.getElementById("bulkExport") as HTMLButtonElement;
        if (bulkExport != null) {
            bulkExport.disabled = !atLeastOneChecked;
        }

        let word = document.getElementById("exportSelectAllWord");

        if (allChecked) {
            word.textContent = "Deselect";
            exportSelectAll.checked = true;
        } else {
            word.textContent = "Select";
            exportSelectAll.checked = false;
        }
    }

    bulkExport() {
        let that = this;

        Core.showLoader();

        let planFKs: number[] = [];

        let exportCheckboxes = document.getElementsByClassName("selectExportCheckbox") as HTMLCollectionOf<HTMLInputElement>;
        for (let checkbox of exportCheckboxes) {
            if (checkbox.checked) {
                planFKs.push(parseInt(checkbox.dataset.planfk));
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/OutcomeBasedReports/BulkExportPDF', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            Core.hideLoader();
            let jsonResponse = JSON.parse(xhr.response);
            if (xhr.status === 200 && jsonResponse.success) {
                
                let messageContainer = document.getElementById("bulkExportMessageContainer");
                let message = document.getElementById("bulkExportMessage");
                if (messageContainer != null) {
                    messageContainer.classList.remove("hide");
                }

                if (message != null) {
                    message.innerHTML = "Successfully queued export. " + jsonResponse.email + " will receive an email with a download link once the export is complete.";
                    message.focus();
                }

                for (let checkbox of exportCheckboxes) {
                    checkbox.checked = false;
                    that.updateSelectToExport();
                }
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error exporting plans. Please try again later.", 'error', 3000, null);
            }
        };
        if (planFKs.length === 0) {
            Core.hideLoader();
            Core.createHTMLAlert("alertMessageDiv", "No plans were selected for export", 'error', 3000, null);
        }
        else {
            xhr.send(JSON.stringify(planFKs));
        }
    }
}