/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />

// Induction Plan Profile
class InductionPlanProfile {

    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["inductionPlanProfileField"];

        let inductionPlanProfileSaveButton = document.getElementById("inductionPlanProfileSave");
        if (inductionPlanProfileSaveButton !== null)
            inductionPlanProfileSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];

        let planPK = 0;

        let profileFormElement = <HTMLDivElement>document.getElementById("inductionPlanProfileForm");
        let inputs = document.getElementsByClassName("inductionPlanProfileField");

        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/InductionPlanReport/SaveInductionPlanProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

// Teacher Induction Committee Participants
class TeacherInductionCommitteeParticipants {

    validationClasses: string[];

    private _core: Core;
    private planPK: number;

    constructor() {
        let that = this;
        this._core = new Core();
        this.bindDeleteButtons();
        this.validationClasses = ["steeringCommitteeField"];

        let steeringCommitteeTable = document.getElementById("steeringCommittee");
        if (steeringCommitteeTable != null) {
            that.planPK = parseInt(steeringCommitteeTable.dataset.planpk);
        }

        let exportToWordButton = <HTMLButtonElement>document.getElementById("inductionExportToWord");
        if (exportToWordButton !== null)
            exportToWordButton.addEventListener("click", (e: Event) => this.exportToWord());

        let exportToExcelButton = <HTMLButtonElement>document.getElementById("inductionExportToExcel");
        if (exportToExcelButton !== null)
            exportToExcelButton.addEventListener("click", (e: Event) => this.exportToExcel());

        let saveInductionCommitteeMembers = <HTMLButtonElement>document.getElementById("saveInductionCommitteeMembers");
        if (saveInductionCommitteeMembers !== null)
            saveInductionCommitteeMembers.addEventListener("click", (e: Event) => this.save("save"));

        let inductionPlanSteeringCommitteeDeleteCancel = <HTMLButtonElement>document.getElementById("inductionPlanSteeringCommitteeDeleteCancel");
        if (inductionPlanSteeringCommitteeDeleteCancel !== null)
            inductionPlanSteeringCommitteeDeleteCancel.addEventListener("click", (e: Event) => this.deleteRowConfirmCancel());

        let inductionPlanSteeringCommitteeDeleteConfirm = <HTMLButtonElement>document.getElementById("inductionPlanSteeringCommitteeDeleteConfirm");
        if (inductionPlanSteeringCommitteeDeleteConfirm !== null)
            inductionPlanSteeringCommitteeDeleteConfirm.addEventListener("click", (e: Event) => this.deleteRowConfirmConfirm(e));

        let steeringCommitteeAddRowsButton = document.getElementById("inductionSteeringCommitteeAddRows");
        if (steeringCommitteeAddRowsButton !== null)
            steeringCommitteeAddRowsButton.addEventListener("click", (e: Event) => this.addNewSteeringCommitteeRow());

        let steeringCommitteeAddRowsNumberInput = document.getElementById("steeringCommitteeAddRowsNumber");
        if (steeringCommitteeAddRowsNumberInput !== null)
            steeringCommitteeAddRowsNumberInput.addEventListener("change", (e: Event) => this.checkForPlural());

        let steeringCommitteeExportExcel = document.getElementById("exportExcelCommitteeMembers");
        if (steeringCommitteeExportExcel !== null)
            steeringCommitteeExportExcel.addEventListener("click", (e: Event) => this.exportToExcel());

        //let continueButton = document.getElementById("continueButton");
        //if (continueButton !== null)
        //    continueButton.addEventListener("click", (e: Event) => this._core.continue(e));

        //let backButton = document.getElementById("backButton");
        //if (backButton !== null)
        //    backButton.addEventListener("click", (e: Event) => this._core.back(e));
        this._core.leftnav(this);

        this.initializeRequiredFieldsCustom();

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let steeringCommitteeTable = document.getElementById("steeringCommittee");
        let steeringCommitteeTableRows = steeringCommitteeTable.querySelectorAll("tr[data-row]");
        let allSaveElements = [];

        let planPK = 0;

        let inputs = document.getElementsByClassName("steeringCommitteeField");

        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(steeringCommitteeTable.dataset.planpk);

            if (ele instanceof HTMLSelectElement) {
                let element = <HTMLSelectElement>ele;
                let rowNumber = element.dataset.row;
                let propertyPK = parseInt(element.dataset.propertypk);
                let hadValue = false;
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                let lookupCodeFK = null;
                let textValue = null;
                if (ele.dataset.propertycode == "inductionPlanCommitteeAppointedBy") {
                    lookupCodeFK = parseInt(element.value);
                } else if (ele.dataset.propertycode == "inductionPlanCommitteeRole") {
                    //Note that even though this is a select, the value is being stored as TextValue NOT LookupCodeFK
                    textValue = ele.value;
                }

                if (element.value !== "" || hadValue) {
                    let saveItem: IPlanProperty = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: textValue,
                        LookupCodeFK: lookupCodeFK,
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };

                    allSaveElements.push(saveItem);
                }
            } else {
                let element = <HTMLInputElement>ele;
                let rowNumber = element.dataset.row;
                let propertyPK = parseInt(element.dataset.propertypk);
                let hadValue = false;
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                if (element.value !== "" || hadValue) {
                    let saveItem: IPlanProperty = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: element.value,
                        LookupCodeFK: null,
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };

                    allSaveElements.push(saveItem);
                }
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/InductionPlanReport/SaveSteeringCommittee', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    exportToWord() {

    }

    exportToExcel() {
        let core = this._core;
        Core.showLoader();

        let steeringCommitteeTable = document.getElementById("steeringCommittee");
        let planPK = 0;
        planPK = parseInt(steeringCommitteeTable.dataset.planpk);
        window.open("/ExportExcel/InductionSteeringCommitteeDataExport?id=" + planPK);
        setTimeout(function () {
            Core.hideLoader();
        }, 5000);
    }

    bindDeleteButtons() {
        let deleteButtons = document.getElementsByClassName("removeInductionSteeringCommitteeRow");
        for (let deleteButton of deleteButtons) {
            deleteButton.addEventListener("click", (e: Event) => this.showDeleteRowConfirm(e));
        }
    }

    showDeleteRowConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.srcElement;
        let row = buttonElement.dataset.row;

        let modal: Modal = new Modal("inductionPlanDeleteSteeringCommitteeRow", null);
        modal.addAttributeToElement("inductionPlanDeleteSteeringCommitteeRow", "#inductionPlanSteeringCommitteeDeleteConfirm", "row", row);
        modal.show();
    }

    deleteRowConfirmCancel() {
        let modal: Modal = new Modal("inductionPlanDeleteSteeringCommitteeRow", null);
        modal.hide();
    }

    deleteRowConfirmConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.srcElement;
        let row = buttonElement.dataset.row;

        let planProps = [];
        let core = this._core;
        let formData = document.getElementById("steeringCommittee");
        let planPK = parseInt(formData.dataset.planpk);
        let allBlank = true;
        Core.showLoader();

        let committeeFields = document.getElementsByClassName("steeringCommitteeField");
        for (let comm of committeeFields) {
            let inputElement = <HTMLInputElement>comm;
            if (inputElement.dataset.row === row && "planpropertypk" in inputElement.dataset && inputElement.dataset.planpropertypk !== "0") {
                planProps.push(inputElement.dataset.planpropertypk);
                if (inputElement.value !== "" && inputElement.value != "-- Select One --")
                    allBlank = false;
            }
        }

        if (allBlank) {
            let allTheRows = document.getElementsByClassName("steeringCommitteeRow");
            for (let thisRow of allTheRows) {
                let thisRowElement = <HTMLDivElement>thisRow;
                if (thisRowElement.dataset.row === row) {
                    thisRowElement.parentNode.removeChild(thisRowElement);

                    let modal: Modal = new Modal("inductionPlanDeleteSteeringCommitteeRow", null);
                    modal.hide();

                    Core.createHTMLAlert("alertMessageDiv", "The steering committee row has been removed.", 'success', 3000, null);
                }
            }
            Core.hideLoader();
        } else {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/InductionPlanReport/DeleteSteeringCommitteeRow', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();

                    let allTheRows = document.getElementsByClassName("steeringCommitteeRow");
                    for (let thisRow of allTheRows) {
                        let thisRowElement = <HTMLDivElement>thisRow;
                        if (thisRowElement.dataset.row === row) {
                            thisRowElement.parentNode.removeChild(thisRowElement);
                        }
                    }

                    let modal: Modal = new Modal("inductionPlanDeleteSteeringCommitteeRow", null);
                    modal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "The steering committee row has been removed.", 'success', 3000, null);
                } else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send(JSON.stringify(planProps));
        }
    }

    addNewSteeringCommitteeRow() {
        let that = this;
        let core = this._core;
        let newRow: number = 0;
        let _that = this;
        let numberOfRowsElement = <HTMLInputElement>document.getElementById("steeringCommitteeAddRowsNumber");
        let numberOfRows = parseInt(numberOfRowsElement.value);

        if (numberOfRows !== null && numberOfRows > 0) {

            let communicationFields = document.getElementsByClassName("steeringCommitteeField");
            for (let comm of communicationFields) {
                let commElement = <HTMLInputElement>comm;
                if (newRow < parseInt(commElement.dataset.row))
                    newRow = parseInt(commElement.dataset.row);
            }

            for (let i = 0; i < numberOfRows; i++) {
                newRow++;
                let xhr = new XMLHttpRequest();
                xhr.open('POST', '/InductionPlanReport/AddSteeringCommitteeRow', true);
                xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        $("#steeringCommittee tbody").append(xhr.responseText);
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "New steering committee row added", 'success', 3000, null);
                        _that.bindDeleteButtons();
                        numberOfRowsElement.value = "";
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                    }
                };
                xhr.send("newRow=" + newRow + "&planPK=" + that.planPK);
            }
        } else {
            Core.createHTMLAlert("alertMessageDiv", "Please specify the number of rows to add", 'error', 3000, null);
        }
    }

    checkForPlural(): void {
        let word = <HTMLSpanElement>document.getElementById("rowWord");
        let num = <HTMLInputElement>document.getElementById("federalPreparingAddRow")
        let newWord = "rows";
        if (parseInt(num.value) === 1) {
            newWord = "row";
        }

        word.textContent = newWord;
    }

    initializeRequiredFieldsCustom() {
        let steeringCommitteeTableRows = document.querySelectorAll("tr.steeringCommitteeRow[data-row]");

        for (let row of steeringCommitteeTableRows) {

            let fields = row.getElementsByClassName("steeringCommitteeField") as HTMLCollectionOf<HTMLElement>;

            for (let element of fields) {

                if (element instanceof HTMLSelectElement) {
                    if(element.selectedIndex > 0) {
                        for (let otherElement of fields) {
                            otherElement.dataset.forcerequired = "true";
                        }
                        break;
                    }
                } else if (element instanceof HTMLInputElement) {
                    if(element.value != "") {
                        for (let otherElement of fields) {
                            otherElement.dataset.forcerequired = "true";
                        }
                        break;
                    }
                }
            }
        }

        this._core.initializeRequiredFields(this.validationClasses);
    }
}

// Educator Induction Plan
class EducatorInductionPlan {

    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();
        this.validationClasses = ["inductionPlanEducatorInductionPlan"];

        this.checkPreCount();

        let educatorInductionPlanSaveButton = document.getElementById("saveEducatorInductionPlan");
        if (educatorInductionPlanSaveButton !== null)
            educatorInductionPlanSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        let educatorInductionPlanExportToWordButton = document.getElementById("exportToWordEducatorInductionPlan");
        if (educatorInductionPlanExportToWordButton !== null)
            educatorInductionPlanExportToWordButton.addEventListener("click", (e: Event) => this.exportToWord());

        let educatorInductionPlanExportToExcelButton = document.getElementById("exportToExcelEducatorInductionPlan");
        if (educatorInductionPlanExportToExcelButton !== null)
            educatorInductionPlanExportToExcelButton.addEventListener("click", (e: Event) => this.exportToExcel());

        this._core.leftnav(this);

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }

        const preCounts = document.getElementsByClassName("precount");
        for (const preCount of preCounts) {
            preCount.addEventListener("change", (e: Event) => this.checkPreCount());
        }

        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }

        let allSaveElements = [];

        let formElement = document.getElementById("inductionPlanEducatorInductionPlanForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let inputs = document.getElementsByClassName("inductionPlanEducatorInductionPlan");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                if (element.value !== "" || hadValue) {
                    let saveItem: IPlanProperty = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "",
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };

                    allSaveElements.push(saveItem);
                }
            }
        }

        let textInputs = document.getElementsByClassName("inductionPlanEducatorInductionPlanText");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;

            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/InductionPlanReport/SaveEducatorInductionPlan', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    exportToWord() {

    }

    exportToExcel() {

    }

    checkPreCount() {
        const preCount = <HTMLInputElement>document.querySelector(".precount:checked");
        if (preCount !== null) {
            const preKCountsContainer = <HTMLDivElement>document.getElementById("preKCountsContainer");
            const inductionPlanEducatorPreCountList = <HTMLTextAreaElement>document.getElementById("inductionPlanEducatorPreCountListId");

            if (preCount.dataset.lookupcode === "no") {
                preKCountsContainer.classList.add("hide");
                this._core.forceElementOptional(inductionPlanEducatorPreCountList);
            } else {
                preKCountsContainer.classList.remove("hide");
                this._core.forceElementRequired(inductionPlanEducatorPreCountList);
            }
        }
    }
}

// Mentors
class Mentors{

    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["indctionPlanMentorRadioField", "inductionPlanMentorField"];

        let mentorsSaveButton = document.getElementById("saveMentors");
        if (mentorsSaveButton !== null)
            mentorsSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        let mentorsExportToWordButton = document.getElementById("exportToWordMentors");
        if (mentorsExportToWordButton !== null)
            mentorsExportToWordButton.addEventListener("click", (e: Event) => this.exportToWord());

        let mentorsExportToExcelButton = document.getElementById("exportToExcelMentors");
        if (mentorsExportToExcelButton !== null)
            mentorsExportToExcelButton.addEventListener("click", (e: Event) => this.exportToExcel());

        this._core.leftnav(this);

        this.setMentorsOther();

        let otherRadio = document.querySelectorAll('input[name="inductionplanmentorsinductionPlanMentorOther"]');
        if (otherRadio !== null) {
            for (let radioElement of otherRadio) {
                radioElement.addEventListener("change", (e: Event) => this.setMentorsOther());
            }
        }

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }

        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];

        let formElement = document.getElementById("inductionPlanMentorsForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("inductionPlanMentorField");
        let inputs = document.getElementsByClassName("indctionPlanMentorRadioField");

        for (let ele of inputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                if (element.value !== "" || hadValue) {
                    let saveItem: IPlanProperty = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "",
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };

                    allSaveElements.push(saveItem);
                }
            }
        }

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/InductionPlanReport/SaveMentors', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    setMentorsOther() {
        let otherRadioChecked = <HTMLInputElement>document.querySelector('input[name="inductionplanmentorsinductionPlanMentorOther"]:checked');
        let otherExplanation = <HTMLTextAreaElement>document.getElementById("inductionPlanMentorOtherText");

        //Show explanation and make explanation required if user picks "No"
        if (otherRadioChecked !== null && otherRadioChecked.dataset.lookuplabel !== null && otherRadioChecked.dataset.lookuplabel === "Yes" && otherExplanation !== null) {
            this._core.forceElementRequired(otherExplanation);
        } else {
            this._core.forceElementOptional(otherExplanation);
        }
    }

    exportToWord() {

    }

    exportToExcel() {

    }
}

// Needs Assessment
class NeedsAssessment {

    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["inductionPlanNeedsAssessmentRadioField", "inductionPlanNeedsAssessmentField"];

        let needsAssessmentSaveButton = document.getElementById("saveNeedsAssessment");
        if (needsAssessmentSaveButton !== null)
            needsAssessmentSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        let needsAssessmentExportToWordButton = document.getElementById("exportToWordMentors");
        if (needsAssessmentExportToWordButton !== null)
            needsAssessmentExportToWordButton.addEventListener("click", (e: Event) => this.exportToWord());

        let needsAssessmentExportToExcelButton = document.getElementById("exportToExcelMentors");
        if (needsAssessmentExportToExcelButton !== null)
            needsAssessmentExportToExcelButton.addEventListener("click", (e: Event) => this.exportToExcel());

        this._core.leftnav(this);

        this.setNeedsAssessmentOther();

        let otherRadio = document.querySelectorAll('input[name="inductionPlanNeedsOther"]');
        if (otherRadio !== null) {
            for (let radioElement of otherRadio) {
                radioElement.addEventListener("change", (e: Event) => this.setNeedsAssessmentOther());
            }
        }

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }

        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }

        let allSaveElements = [];

        let formElement = document.getElementById("inductionPlanNeedsAssessmentForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("inductionPlanNeedsAssessmentField");
        let inputs = document.getElementsByClassName("inductionPlanNeedsAssessmentRadioField");

        for (let ele of inputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                if (element.value !== "" || hadValue) {
                    let saveItem: IPlanProperty = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "",
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };

                    allSaveElements.push(saveItem);
                }
            }
        }

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/InductionPlanReport/SaveNeedsAssessment', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    setNeedsAssessmentOther() {
        let otherRadioChecked = <HTMLInputElement>document.querySelector('input[name="inductionPlanNeedsOther"]:checked');
        let otherExplanation = <HTMLTextAreaElement>document.getElementById("inductionPlanNeedsOtherSpecify");

        //Show explanation and make explanation required if user picks "Yes"
        if (otherRadioChecked !== null && otherRadioChecked.dataset.lookuplabel !== null && otherRadioChecked.dataset.lookuplabel === "Yes" && otherExplanation !== null) {
            this._core.forceElementRequired(otherExplanation);
        } else {
            this._core.forceElementOptional(otherExplanation);
        }
    }

    exportToWord() {

    }

    exportToExcel() {

    }
}

// Educator Induction Plan Topic Areas
class EducatorInductionPlanTopicAreas {

    validationClasses: string[];
    hashClasses: string[];
    deleteFileModal: Modal;
    planPropertyFileToDeletePK: number;

    private _core: Core;
    constructor() {
        let that = this;
        this._core = new Core();

        this.validationClasses = ["multiSelectTopicAreasTimeline", "multiSelectTopicAreasTopicAreas", "inductionPlanFileUpload"];
        this.hashClasses = ["addDanielsonFrameworkToListItem", "inductionPlanTopicAreasTimeline"];

        this.deleteFileModal = new Modal('deleteFileModal', null);

        let fileUploaders = document.getElementsByClassName('fileUpload') as HTMLCollectionOf<HTMLInputElement>;
        for (let upload of fileUploaders) {
            if ("propertyFk" in upload.dataset) {
                 upload.addEventListener('change', (e: Event) => that.uploadFile(parseInt(upload.dataset.propertyFk)));
            } 
        }

        document.addEventListener("click", function (event) {
            let target = <HTMLElement>event.target;
            if (target.tagName == "BUTTON" && target.classList.contains("deleteFile") && "planPropertyFilePk" in target.dataset) {
                that.deleteFileModal.show();
                that.deleteFileModal.callingId = target.id;
                that.planPropertyFileToDeletePK = parseInt(target.dataset.planPropertyFilePk);
            }
        });

        let deleteFileConfirm = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirm != null) {
            deleteFileConfirm.addEventListener("click", () => that.deleteFileConfirm());
        }

        let deleteFileCancel = document.getElementById("deleteFileCancel");
        if (deleteFileCancel != null) {
            deleteFileCancel.addEventListener("click", () => that.deleteFileModal.hide());
        }

        let educatorInductionPlanTopicAreasSaveButton = document.getElementById("saveEducatorInductionPlanTopicAreas");
        if (educatorInductionPlanTopicAreasSaveButton !== null)
            educatorInductionPlanTopicAreasSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        let reportTopicAreasButton = document.getElementById("inductionReportSelectedTopics");
        if (reportTopicAreasButton !== null)
            reportTopicAreasButton.addEventListener("click", (e: Event) => this.reportTopicAreas());

        let topicAreaExportToWordButton = document.getElementById("exportToWordTopicAreas");
        if (topicAreaExportToWordButton !== null)
            topicAreaExportToWordButton.addEventListener("click", (e: Event) => this.exportToWord());

        let topicAreaExportToExcelButton = document.getElementById("exportToExcelTopicAreas");
        if (topicAreaExportToExcelButton !== null)
            topicAreaExportToExcelButton.addEventListener("click", (e: Event) => this.exportToExcel());

        let allAddDanielsonButtons = document.getElementsByClassName("addThisDanielson");
        for (let addDanielsonButton of allAddDanielsonButtons)
            addDanielsonButton.addEventListener("click", (e: Event) => this.addDanielson(e));

        //let continueButton = document.getElementById("continueButton");
        //if (continueButton !== null)
        //    continueButton.addEventListener("click", (e: Event) => this._core.continue(e));

        //let backButton = document.getElementById("backButton");
        //if (backButton !== null)
        //    backButton.addEventListener("click", (e: Event) => this._core.back(e));
        this._core.leftnav(this);

        this.initializeRequiredFields();

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }

        this.bindRemoveDanielsonButtons();

        let hidden = this._core.createHash(this.hashClasses);  // using hashclasses instead of validation classes due to danielson framework

        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let checkboxes = document.getElementsByClassName("inductionPlanTopicAreasCheckbox");
        for (let checkbox of checkboxes)
            checkbox.addEventListener("click", (e: Event) => this.checkForReadonly(e));
    }

    checkForReadonly(e: Event) {
        let checkbox = <HTMLInputElement>e.srcElement;

        if (checkbox.readOnly) {
            if (checkbox.checked)
                checkbox.checked = false;
            else
                checkbox.checked = true;

            return false;
        }
    }

    createHash() {
        //using custom hashclasses for this page.
        let hash = this._core.createHash(this.hashClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    addDanielson(e: Event) {
        let core = this._core;
        let _that = this;
        let lookupCodePK = parseInt((<HTMLButtonElement>e.srcElement).dataset.lookupcodepk);
        let danielsonSelection = <HTMLSelectElement>document.querySelector(`.danielsonFrameworkList[data-lookupcodepk="${lookupCodePK}"]`);
        let propertyPK = danielsonSelection.dataset.propertypk;
        let danielsonLookupCodePK = danielsonSelection.value;

        if (danielsonLookupCodePK === "0") {
            Core.createHTMLAlert("alertMessageDiv", "Please select a Danielson Framework to add", "warning", 3000, null);
        } else {
            let isNotInList: boolean = true;
            let allListElements = document.querySelectorAll(`.addDanielsonFrameworkToListItem[data-lookupcodepk="${lookupCodePK}"]`);
            for (let listElement of allListElements) {
                let element = <HTMLDivElement>listElement;
                if (element.dataset.danielsonlookupcodepk === danielsonLookupCodePK.toString()) {
                    isNotInList = false;
                }
            }

            if (isNotInList) {
                let xhr = new XMLHttpRequest();
                xhr.open('POST', '/InductionPlanReport/AddDanielsonFrameworkToList');
                xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        $(".addDanielsonFrameworkToList[data-lookupcodepk='" + lookupCodePK + "']").append(xhr.responseText);
                        _that.bindRemoveDanielsonButtons();
                        Core.createHTMLAlert("alertMessageDiv", "New Danielson Framework Added", 'success', 3000, null);
                    } else {
                        Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                    }
                };
                xhr.send("lookupCodePK=" + lookupCodePK + "&danielsonLookupPK=" + danielsonLookupCodePK + "&propertyFK=" + propertyPK);
            }
        }
    }

    removeDanielson(e: Event) {
        e.stopPropagation();
        let element: HTMLButtonElement;
        let go = true;
        element = <HTMLButtonElement>e.srcElement;
        let lookupCodePK = element.dataset.lookupcodepk;
        let danielsonLookupCodePK = element.dataset.danielsonlookupcodepk;

        let allLookups = document.querySelectorAll(`.addDanielsonFrameworkToListItem[data-lookupcodepk="${lookupCodePK}"]`);
        for (let lookup of allLookups) {
            let thisElement = <HTMLDivElement>lookup;

            if (thisElement.dataset.danielsonlookupcodepk === danielsonLookupCodePK) {
                thisElement.parentNode.removeChild(thisElement);
            }
        }
    }

    bindRemoveDanielsonButtons() {
        let allDeleteButtons = document.getElementsByClassName("deletePDDanielsonFramework");
        for (let deleteButton of allDeleteButtons) {
            deleteButton.removeEventListener("click", (e: Event) => this.removeDanielson(e));
            deleteButton.addEventListener("click", (e: Event) => this.removeDanielson(e));
        }
    }

    reportTopicAreas() {
        let _that = this;
        let successes: boolean[] = [];
        let allTopicAreas = document.getElementsByClassName("inductionPlanTopicAreasLookup");

        for (let topicArea of allTopicAreas) {
            let topicAreaElement = <HTMLInputElement>topicArea;
            let topLevelAccordionElement = <HTMLDivElement>document.getElementById("inductionPlanTopicAreasAccordionList");
            let accordionTrigger = <HTMLButtonElement>topLevelAccordionElement.querySelector(`.Accordion-trigger[data-lookupcodepk="${topicAreaElement.dataset.lookupcodepk}"]`);
            let accordionPanel = <HTMLDivElement>topLevelAccordionElement.querySelector(`.Accordion-panel[data-lookupcodepk="${topicAreaElement.dataset.lookupcodepk}"]`);

            if (topicAreaElement.checked) {
                if (accordionTrigger.classList.contains("hide-accordion")) {
                    accordionTrigger.classList.remove("hide-accordion");
                    accordionPanel.classList.remove("hide-accordion");

                    let timeline = <HTMLElement>accordionPanel.querySelector(".multiSelectTopicAreasTimeline");
                    if (timeline != null) {
                        this._core.forceElementRequired(timeline);
                    }
                }
            } else {
                let selects = accordionPanel.querySelectorAll("select");
                for (let select of selects) {
                    let selectElement = <HTMLSelectElement>select;
                    selectElement.value = "0";
                }

                if (!accordionTrigger.classList.contains("hide-accordion")) {
                    accordionTrigger.classList.add("hide-accordion");
                    accordionPanel.classList.add("hide-accordion");
                }
                

                //TODO: Remove the danielson elements from the list.

            }
        }

        Core.createHTMLAlert("alertMessageDiv", "Topic Area(s) successfully added", 'success', 3000, null);

        this._core.initializeRequiredFields(this.validationClasses);
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }

        let allSaveElements = [];
        let hadDataElements = [];
        let allDanielsonElements = [];
        let allTopicAreaElements = [];

        let formElement = document.getElementById("inductionPlanEducatorInductionPlanTopicAreasForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let timelines = document.getElementsByClassName("inductionPlanTopicAreasTimeline");

        for (let ele of timelines) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = 0
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            let lookupCodePK = element.dataset.lookupcodepk;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                if (element.checked || hadValue) {
                    let saveItem: IPlanPropertyExtended = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "",
                        LookupCodeFK: parseInt(element.dataset.timelinelookupcodepk),
                        RowNbr: rowNumber,
                        IsDeletedInd: false,
                        ButtonRow: parseInt(lookupCodePK),
                        DataPointPlanPropertyPK: parseInt(element.dataset.datapointplanpropertypk)
                    };

                    allSaveElements.push(saveItem);
                }
            }
        }

        let danielsonList = document.getElementsByClassName("addDanielsonFrameworkToListItem");

        for (let danielson of danielsonList) {
            let danielsonElement = <HTMLDivElement>danielson;

            let planPropertyPK = 0;
            let propertyPK = parseInt(danielsonElement.dataset.propertypk);
            let danielsonLookupCodePK = danielsonElement.dataset.danielsonlookupcodepk;
            let lookupCodePK = danielsonElement.dataset.lookupcodepk;
            if (danielsonElement.dataset.planpropertypk !== "" && danielsonElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(danielsonElement.dataset.planpropertypk);
            }

            let saveItem: IPlanPropertyExtended = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: planPK,
                PropertyFK: propertyPK,
                TextValue: "",
                LookupCodeFK: parseInt(danielsonLookupCodePK),
                RowNbr: 0,
                IsDeletedInd: false,
                ButtonRow: parseInt(lookupCodePK),
                DataPointPlanPropertyPK: parseInt(danielsonElement.dataset.datapointplanpropertypk)
            };

            allDanielsonElements.push(saveItem);
        }

        let allTopicAreas = document.getElementsByClassName("inductionPlanTopicAreasLookup");
        for (let topic of allTopicAreas) {
            let topicElement = <HTMLInputElement>topic;
            let propertyPK = 0;
            let topicLookupCodePK = 0;

            if ("lookupcodepk" in topicElement.dataset) {
                topicLookupCodePK = parseInt(topicElement.dataset.lookupcodepk);
            }

            if ("propertyPK" in topicElement.dataset) {
                propertyPK = parseInt(topicElement.dataset.propertypk);
            }

            if (!topicElement.checked && "hadvalue" in topicElement.dataset && topicElement.dataset.hadvalue === "true") {

                let planPropertyPK = 0;
                if (topicElement.dataset.planpropertypk !== "" && topicElement.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(topicElement.dataset.planpropertypk);

                    let saveItem: IPlanProperty = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "",
                        RowNbr: 0,
                        LookupCodeFK: topicLookupCodePK,
                        IsDeletedInd: true,
                    };
                    hadDataElements.push(saveItem);
                }
            } else if (topicElement.checked) {
                let planPropertyPK = 0;
                if (topicElement.dataset.planpropertypk) {
                    planPropertyPK = parseInt(topicElement.dataset.planpropertypk);
                }

                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: "",
                    RowNbr: 0,
                    LookupCodeFK: topicLookupCodePK,
                    IsDeletedInd: false,
                };
                allTopicAreaElements.push(saveItem);
            }
        }

        let saveData = {
            "Timeline": allSaveElements,
            "Danielson": allDanielsonElements,
            "RemovedTopicAreas": hadDataElements,
            "TopicAreas": allTopicAreaElements,
            "PlanFK": planPK
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/InductionPlanReport/SaveTopicAreas', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0 && allDanielsonElements.length === 0 && hadDataElements.length === 0 && allTopicAreaElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(saveData));
        }
    }

    initializeRequiredFields() {
        let hiddenTimelines = document.querySelectorAll(".hide-accordion .multiSelectTopicAreasTimeline") as NodeListOf<HTMLElement>;

        for (let hiddenTimeline of hiddenTimelines) {
            this._core.forceElementOptional(hiddenTimeline);
        }

        this._core.initializeRequiredFields(this.validationClasses);
    }

    exportToWord() {

    }

    exportToExcel() {

    }

    uploadFile(propertyFK: number) {
        const core = this._core;
        Core.showLoader();
        const uploadForm = <HTMLFormElement>document.querySelector(`.uploadForm[data-property-fk='${propertyFK}']`);
        const formData = new FormData(uploadForm);

        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/Base/UploadFile', true);
        xhr.onload = () => {
            if (xhr.status === 200) {
                if (xhr.responseText !== null) {
                    const response = JSON.parse(xhr.responseText);
                    if (response.success) {                        
                        Core.hideLoader();
                        Core.createHTMLAlert('alertMessageDiv', 'The file has been successfully uploaded!', 'success', 3000, null);
                        
                        const formFile = document.querySelector(`.fileUpload[data-property-fk='${propertyFK}']`) as HTMLInputElement;
                        formFile.value = '';             

                        const planPropertyFile = response.payload;

                        const xhrForPartialView = new XMLHttpRequest();
                        xhrForPartialView.open('GET', `/Base/GetFileUploadPartialView?planPropertyFilePK=${planPropertyFile.planPropertyFilePK}`);                                               
                        xhrForPartialView.onload = () => {
                            if (xhrForPartialView.status === 200) {
                                const fileUploadDiv = document.querySelector(`.uploadedFilesContainer[data-property-fk='${propertyFK}']`) as HTMLDivElement;
                                $(fileUploadDiv).append(xhrForPartialView.response);
                            } 
                        };
                        xhrForPartialView.send();  
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert('alertMessageDiv', 'The file upload failed. Please try again.', 'error', 3000, null);
                    }      
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert('alertMessageDiv', 'The file upload failed. Please try again.', 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }

    deleteFileConfirm() {
        let that = this;
        Core.showLoader();

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Base/DeleteFiles', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file has been successfully deleted.", 'success', 3000, null);
                        let containerElement = document.querySelector(`.uploadFileContainer[data-plan-property-file-pk='${that.planPropertyFileToDeletePK}']`);
                        if (containerElement !== null) {
                            containerElement.parentNode.removeChild(containerElement);
                        }
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting file.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file removal failed. Please try again.", 'error', 3000, null);
            }
            that.deleteFileModal.hide();
        };
        xhr.send(JSON.stringify([that.planPropertyFileToDeletePK]));
    }
}

// Evaluation and Monitoring
class EvaluationAndMonitoring {

    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["evaluationAndMonitoringField"];

        let evaluationAndMonitoringSaveButton = document.getElementById("saveEvaluationAndMonitoring");
        if (evaluationAndMonitoringSaveButton !== null)
            evaluationAndMonitoringSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }

        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }

        let allSaveElements = [];

        let formElement = document.getElementById("inductionPlanEvaluationAndMonitoringForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("evaluationAndMonitoringField");

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/InductionPlanReport/SaveEvaluationAndMonitoring', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

// Documentation of Participation and Completion
class DocumentationOfParticipationAndCompletion {

    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["documentationOfParticipationField", "documentationOfParticipationRadioField"];

        let documentationOfParticipationAndCompletionSaveButton = document.getElementById("saveDocumentationOfParticipationAndCompletion");
        if (documentationOfParticipationAndCompletionSaveButton !== null)
            documentationOfParticipationAndCompletionSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");

        this.setConfirmFirstYearTeachers();

        let firstYearTeachersRadio = document.querySelectorAll('input[name="inductionPlanConfirmFirstYear"]');
        if (firstYearTeachersRadio !== null) {
            for (let radioElement of firstYearTeachersRadio) {
                radioElement.addEventListener("change", (e: Event) => this.setConfirmFirstYearTeachers());
            }
        }

        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }

        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    setConfirmFirstYearTeachers() {
        let confirmTeachersRadioChecked = <HTMLInputElement>document.querySelector('input[name="inductionPlanConfirmFirstYear"]:checked');
        let confirmTeachersExplanation = <HTMLTextAreaElement>document.getElementById("confirmTeachersExplanation");

        //Show explanation and make explanation required if user picks "No"
        if (confirmTeachersRadioChecked !== null && confirmTeachersRadioChecked.dataset.lookuplabel !== null && confirmTeachersRadioChecked.dataset.lookuplabel === "No" && confirmTeachersExplanation !== null) {
            this._core.forceElementRequired(confirmTeachersExplanation);
        } else {
            this._core.forceElementOptional(confirmTeachersExplanation);
        }
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }

        let allSaveElements = [];

        let formElement = document.getElementById("inductionPlanDocumentationOfParticipationAndCompletionForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("documentationOfParticipationField");
        let radioInputs = document.getElementsByClassName("documentationOfParticipationRadioField");

        for (let ele of radioInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                if (element.value !== "" || hadValue) {
                    let saveItem: IPlanProperty = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "",
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };

                    allSaveElements.push(saveItem);
                }
            }
        }

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/InductionPlanReport/SaveDocumentationOfParticipationAndCompletion', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

// Educator Induction Plan Statement Of Assurance
class EducatorInductionPlanStatementOfAssurance {

    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["inductionPlanSignatureAndAssuranceField"];

        let EducatorInductionPlanStatementOfAssuranceSaveButton = document.getElementById("saveEducatorInductionPlanStatementOfAssurance");
        if (EducatorInductionPlanStatementOfAssuranceSaveButton !== null)
            EducatorInductionPlanStatementOfAssuranceSaveButton.addEventListener("click", (e: Event) => this.save("save"));


        let fileUploader = document.getElementById("inductionPlanUploadMinutesFile");
        if (fileUploader !== null) {
            fileUploader.addEventListener("change", (e: Event) => this.uploadFile(<HTMLInputElement>e.srcElement));
        }

        //let continueButton = document.getElementById("continueButton");
        //if (continueButton !== null)
        //    continueButton.addEventListener("click", (e: Event) => this._core.continue(e));

        //let backButton = document.getElementById("backButton");
        //if (backButton !== null)
        //    backButton.addEventListener("click", (e: Event) => this._core.back(e));
        this._core.leftnav(this);

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }

        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    uploadFile(e: HTMLInputElement) {
        let core = this._core;
        Core.showLoader();
        let uploadMinutesForm = <HTMLFormElement>document.getElementById("inductionPlanUploadMinutes");
        let formData = new FormData(uploadMinutesForm);

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/InductionPlanReport/UploadFile', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file has been successfully uploaded! Save your progress to see the updated list of files.", 'success', 3000, null);

                        let formFile = <HTMLInputElement>document.getElementById("inductionPlanUploadMinutesFile");
                        formFile.value = "";
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }

        let allSaveElements = [];

        let formElement = document.getElementById("inductionPlanEducatorInductionPlanStatementOfAssuranceForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("inductionPlanSignatureAndAssuranceField");

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: 0,
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/InductionPlanReport/SaveEducatorInductionPlanStatementOfAssurance', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

//Curriculum Summary Checklist and Submission
class InductionPlanSummaryChecklistAndSubmission {

    private _core: Core;
    constructor() {
        this._core = new Core();

        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.back(e));
        this._core.leftnav(this);

        let submitButton = document.getElementById("submitPlanButton");
        if (submitButton !== null)
            submitButton.addEventListener("click", (e: Event) => this.submit(e));
    }

    back(e: Event) {
        let newPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;

        //Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }

        if (refreshPage && refreshPage !== "")
            window.location.href = refreshPage;
        else
            Core.hideLoader();
    }

    submit(e: Event) {
        let element = <HTMLButtonElement>e.srcElement;
        if (!element.classList.contains("disabled")) {
            let planFK = 0;
            planFK = parseInt(element.dataset.planfk);
            let core = this._core;
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/InductionPlanReport/SubmitPlan', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "This report has been submitted for review!", 'success', 3000, window.location.reload);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status + "'", 'error', 3000, null);
                }
            };
            xhr.send("planFK=" + planFK);
        }
    }
}