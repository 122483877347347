/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />

class PlanMonitoringPage {
    deleteFileModal: Modal;
    planMonitoringFileToDeletePK: number;
    private _core: Core;
    validationClasses: string[];

    constructor(validationClasses: string[]) {
        let that = this;
        that.validationClasses = validationClasses;
        this._core = new Core();
        this._core.activateSubMenu("subnavPlanMonitoring");
        this._core.leftnav(this);
        this._core.tabLinkSave(this);
        this.deleteFileModal = new Modal('deleteFileModal', null);

        this._core.initializeRequiredFields(this.validationClasses);

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let fileUploaders = document.getElementsByClassName('fileUpload') as HTMLCollectionOf<HTMLInputElement>;
        for (let upload of fileUploaders) {
            if ("propertyFk" in upload.dataset && "planFk" in upload.dataset) {
                 upload.addEventListener('change', (e: Event) => this.uploadFile(parseInt(upload.dataset.propertyFk), parseInt(upload.dataset.planFk)));
            } 
        }

        document.addEventListener("click", function (event) {
            let target = <HTMLElement>event.target;
            if (target.tagName == "BUTTON" && target.classList.contains("deleteFile") && "monitoringFilePk" in target.dataset) {
                that.deleteFileModal.show();
                that.deleteFileModal.callingId = target.id;
                that.planMonitoringFileToDeletePK = parseInt(target.dataset.monitoringFilePk);
            }
        });

        let deleteFileConfirm = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirm != null) {
            deleteFileConfirm.addEventListener("click", () => that.deleteFileConfirm());
        }

        let deleteFileCancel = document.getElementById("deleteFileCancel");
        if (deleteFileCancel != null) {
            deleteFileCancel.addEventListener("click", () => that.deleteFileModal.hide());
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    uploadFile(propertyFK: number, planFK: number) {
        const core = this._core;
        Core.showLoader();
        const uploadForm = <HTMLFormElement>document.querySelector(`.uploadForm[data-property-fk='${propertyFK}']`);
        const formData = new FormData(uploadForm);

        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/PlanMonitoring/UploadMonitoringFile', true);
        xhr.onload = () => {
            if (xhr.status === 200) {
                if (xhr.responseText !== null) {
                    const response = JSON.parse(xhr.responseText);
                    if (response.success) {                        
                        Core.hideLoader();
                        Core.createHTMLAlert('alertMessageDiv', 'The file has been successfully uploaded!', 'success', 3000, null);
                        
                        const formFile = document.querySelector(`.fileUpload[data-property-fk='${propertyFK}']`) as HTMLInputElement;
                        formFile.value = '';             

                        const fileUpload = response.planMonitoringFile;

                        const xhrForPartialView = new XMLHttpRequest();
                        xhrForPartialView.open('GET', `/PlanMonitoring/GetPlanMonitoringFileUploadPartialView?planMonitoringFilePK=${fileUpload.planMonitoringFilePK}&planFK=${planFK}`);                                               
                        xhrForPartialView.onload = () => {
                            if (xhrForPartialView.status === 200) {
                                const fileUploadDiv = document.querySelector(`.uploadedFilesContainer[data-property-fk='${propertyFK}']`) as HTMLDivElement;
                                $(fileUploadDiv).append(xhrForPartialView.response);
                            } 
                        };
                        xhrForPartialView.send();  
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert('alertMessageDiv', 'The file upload failed. Please try again.', 'error', 3000, null);
                    }      
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert('alertMessageDiv', 'The file upload failed. Please try again.', 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }

    deleteFileConfirm() {
        let that = this;
        const core = this._core;
        Core.showLoader();

        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/PlanMonitoring/DeleteMonitoringFile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    const response = JSON.parse(xhr.responseText);
                    if (response.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert('alertMessageDiv', 'The file has been successfully deleted.', 'success', 3000, null);

                        const containerElement = <HTMLElement>document.querySelector(`.uploadFileContainer[data-monitoring-file-pk='${that.planMonitoringFileToDeletePK}']`);
                        
                        if (containerElement !== null) {
                            let fileInput = document.querySelector(`.fileUpload[data-property-fk='${containerElement.dataset.propertyFk}']`) as HTMLInputElement;
                            if (fileInput != null) {
                                that.deleteFileModal.callingId = fileInput.id;
                            }
                            containerElement.parentElement.removeChild(containerElement);
                        }
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert('alertMessageDiv', 'There was an unexpected error deleting file.', 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert('alertMessageDiv', 'The file removal failed. Please try again.', 'error', 3000, null);
            }

            that.deleteFileModal.hide();
        };
        xhr.send(that.planMonitoringFileToDeletePK.toString());
    }
}

class MeasurableGoalsProgress extends PlanMonitoringPage {

    constructor() {
        super(["planMonitoringField"]);
        let that = this;

        let saveMeasurableGoalsProgressButton = document.getElementById("saveMeasurableGoalsProgress");
        if (saveMeasurableGoalsProgressButton !== null) {
            saveMeasurableGoalsProgressButton.addEventListener("click", (e: Event) => that.save("save"));
        }

        let exportMeasurableGoalsProgressWord = document.getElementById("exportMeasurableGoalsProgressWord");
        if (exportMeasurableGoalsProgressWord !== null) {
            exportMeasurableGoalsProgressWord.addEventListener("click", (e: Event) => that.getCore().exportDocx(e, "MeasurableGoalsProgressDataExport", "measurablegoalsprogress"));
        }

        let exportQuarterlyReportWord = document.getElementById("exportQuarterlyReportWord");
        if (exportQuarterlyReportWord !== null) {
            exportQuarterlyReportWord.addEventListener("click", (e: Event) => that.getCore().exportDocx(e, "SchoolImprovementQuarterlyReportDataExport", "schoolimprovementquarterlyreport"));
        }
    }

    save(referrer) {
        let core = this.getCore();
        if (referrer !== "save" && core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }

        let planFK = 0;
        let planMonitoringFK = 0;
        let pageFK = 0;

        let formElement = document.getElementById("measurableGoalsProgressData");
        planFK = parseInt(formElement.dataset.planfk);
        planMonitoringFK = parseInt(formElement.dataset.planmonitoringfk);
        pageFK = parseInt(formElement.dataset.pagefk);

        let targetSaveElements = [];

        let targets = document.getElementsByClassName("goalSettingTarget") as HTMLCollectionOf<HTMLTextAreaElement>;
        for (let target of targets) {

            if ("targetplanpropertyfk" in target.dataset && "goaltargetpk" in target.dataset) {

                let goalTargetPlanPropertyFK = parseInt(target.dataset.targetplanpropertyfk);
                let planMonitoringGoalTargetPK = parseInt(target.dataset.goaltargetpk);

                let actualPerformanceValueText = "";
                let performance = document.querySelector(`.actualPerformanceText[data-targetplanpropertyfk='${goalTargetPlanPropertyFK}']`) as HTMLTextAreaElement;
                if (performance != null) {
                    actualPerformanceValueText = performance.value;
                }

                let summaryNotesValueText = "";
                let summary = document.querySelector(`.summaryNotes[data-targetplanpropertyfk='${goalTargetPlanPropertyFK}']`) as HTMLTextAreaElement;
                if (summary != null) {
                    summaryNotesValueText = summary.value;
                }

                let thisData: IPlanMonitoringGoalTarget = {
                    ActualPerformanceValueText: actualPerformanceValueText,
                    GoalTargetPlanPropertyFK: goalTargetPlanPropertyFK,
                    PlanMonitoringFK: planMonitoringFK,
                    PlanMonitoringGoalTargetPK: planMonitoringGoalTargetPK,
                    SummaryNotesValueText: summaryNotesValueText
                };

                targetSaveElements.push(thisData);

            }

        }

        let saveData = JSON.stringify({
            "PlanFK": planFK,
            "PageFK": pageFK,
            "PlanMonitoringFK": planMonitoringFK,
            "GoalTargets" : targetSaveElements
        });

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/PlanMonitoring/SaveMeasurableGoalsProgress', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planFK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };

        xhr.send(saveData);
    }
}

class ActionPlanReview extends PlanMonitoringPage {

    constructor() {
        super(["planMonitoringField"]);
        let that = this;

        let saveActionPlanReviewButton = document.getElementById("saveActionPlanReview");
        if (saveActionPlanReviewButton !== null) {
            saveActionPlanReviewButton.addEventListener("click", (e: Event) => that.save("save"));
        }

        let levelOfCompletion = document.getElementsByClassName("levelOfCompletion") as HTMLCollectionOf<HTMLSelectElement>;
        for (let levelDropdown of levelOfCompletion) {
            levelDropdown.addEventListener("change", (e: Event) => that.setBarriersToCompletion(levelDropdown));
        }

        let proposedChanges = document.getElementsByClassName("notesProposedChanges") as HTMLCollectionOf<HTMLTextAreaElement>;
        for (let change of proposedChanges) {
            change.addEventListener("input", (e: Event) => that.setProposedChanges(e));
        }

        let exportActionPlanReviewWord = document.getElementById("exportActionPlanReviewWord");
        if (exportActionPlanReviewWord !== null) {
            exportActionPlanReviewWord.addEventListener("click", (e: Event) => this.getCore().exportDocx(e, "ActionPlanReviewDataExport", "actionplanreview"));
        }

        let barriersToCompletionMoreInfo = document.querySelectorAll(".barriersMoreInfo");
        for (let infoButton of barriersToCompletionMoreInfo) {
            infoButton.addEventListener("click", function() {
                let slideout = new Slideout("barriersToCompletionSlideout", infoButton.id);
                slideout.show();
            });

            infoButton.addEventListener("keypress", (e) => {
                if (Core.a11yClick(<KeyboardEvent>e)) {
                    let slideout = new Slideout("barriersToCompletionSlideout", infoButton.id);
                    slideout.show();
                }
            });
        }
    }

    //When the user selects "Behind" for the completion level of an action step, enable barriers of completion and make it required.
    setBarriersToCompletion(dropdown: HTMLSelectElement) {
        let that = this;
        let selectedOption = dropdown.options[dropdown.selectedIndex];
        if (selectedOption != null && "lookupcode" in selectedOption.dataset && "actionplanstepplanpropertyfk" in dropdown.dataset) {
            let barrierContainer = document.querySelector(`.barriersToCompletionContainer[data-actionplanstepplanpropertyfk='${dropdown.dataset.actionplanstepplanpropertyfk}']`) as HTMLDivElement;
            let barrier = document.querySelector(`.barriersToCompletion[data-actionplanstepplanpropertyfk='${dropdown.dataset.actionplanstepplanpropertyfk}']`) as HTMLTextAreaElement;
            if (barrierContainer != null && barrier != null) {
                if (selectedOption.dataset.lookupcode == "levelOfCompletionBehind") {
                    barrierContainer.classList.remove("hide");
                    that.getCore().forceElementRequired(barrier);
                } else {
                    barrierContainer.classList.add("hide");
                    that.getCore().forceElementOptional(barrier);
                }
                
                let barriersToCompletionMoreInfo = document.querySelectorAll(".barriersMoreInfo");
                for (let infoButton of barriersToCompletionMoreInfo) {
                    infoButton.addEventListener("click", function() {
                        let slideout = new Slideout("barriersToCompletionSlideout", infoButton.id);
                        slideout.show();
                    });

                    infoButton.addEventListener("keypress", (e) => {
                        if (Core.a11yClick(<KeyboardEvent>e)) {
                            let slideout = new Slideout("barriersToCompletionSlideout", infoButton.id);
                            slideout.show();
                        }
                    });
                }
            }
        }
    }

    //When user makes a change to the "Notes on Proposed Changes" for an action plan, make the corresponding change in the "Summary To Proposed Changes"
    setProposedChanges(e: Event) {
        let change = e.target as HTMLInputElement;
        if ("actionplanplanpropertyfk" in change.dataset && "propertyfk" in change.dataset) {
            let summaryChange = document.querySelector(`.notesProposedChangesSummary[data-actionplanplanpropertyfk='${change.dataset.actionplanplanpropertyfk}'][data-propertyfk='${change.dataset.propertyfk}']`) as HTMLTextAreaElement;
            if (summaryChange != null) {
                summaryChange.value = change.value;
            }
        }
    }

    save(referrer) {
        let core = this.getCore();
        if (referrer !== "save" && core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }

        let planFK = 0;
        let planMonitoringFK = 0;
        let pageFK = 0;

        let formElement = document.getElementById("actionPlanReviewData");
        planFK = parseInt(formElement.dataset.planfk);
        planMonitoringFK = parseInt(formElement.dataset.planmonitoringfk);
        pageFK = parseInt(formElement.dataset.pagefk);

        let actionPlans = [];

        //Get all action plans
        let actionPlanHiddenInputs = document.getElementsByClassName("actionPlan") as HTMLCollectionOf<HTMLInputElement>;
        for (let actionPlan of actionPlanHiddenInputs) {

            if ("actionplanplanpropertyfk" in actionPlan.dataset && "planmonitoringactionplanfk" in actionPlan.dataset) {

                let actionPlanSaveElement: IPlanMonitoringActionPlan = {
                    ActionPlanPlanPropertyFK: parseInt(actionPlan.dataset.actionplanplanpropertyfk),
                    PlanMonitoringActionPlanPK: parseInt(actionPlan.dataset.planmonitoringactionplanfk),
                    PlanMonitoringFK: planMonitoringFK
                }

                //Get the action steps
                let actionPlanStepSaveElements: IPlanMonitoringActionPlanStep[] = [];

                let actionPlanSteps = document.querySelectorAll(`.actionPlanStep[data-actionplanplanpropertyfk='${actionPlanSaveElement.ActionPlanPlanPropertyFK}']`);
                for (let actionPlanStep of actionPlanSteps) {
                    let actionPlanStepElement = <HTMLDivElement>actionPlanStep;
                    if ("actionplanstepplanpropertyfk" in actionPlanStepElement.dataset && "actionplanplanpropertyfk" in actionPlanStepElement.dataset && "planmonitoringactionplanstepfk" in actionPlanStepElement.dataset) {

                        //level of completion
                        let levelOfCompletion = null;
                        let levelOfCompletionElement = actionPlanStepElement.querySelector(`.levelOfCompletion[data-actionplanplanpropertyfk='${actionPlanStepElement.dataset.actionplanplanpropertyfk}'][data-actionplanstepplanpropertyfk='${actionPlanStepElement.dataset.actionplanstepplanpropertyfk}']`) as HTMLSelectElement;
                        if (levelOfCompletionElement != null && parseInt(levelOfCompletionElement.value)) {
                            levelOfCompletion = parseInt(levelOfCompletionElement.value);
                        }

                        //revise action plan
                        let reviseActionPlan = null;
                        let reviseActionPlanElement = actionPlanStepElement.querySelector(`.reviseActionPlan[data-actionplanplanpropertyfk='${actionPlanStepElement.dataset.actionplanplanpropertyfk}'][data-actionplanstepplanpropertyfk='${actionPlanStepElement.dataset.actionplanstepplanpropertyfk}']`) as HTMLSelectElement;
                        if (reviseActionPlanElement != null && parseInt(reviseActionPlanElement.value)) {
                            reviseActionPlan = parseInt(reviseActionPlanElement.value);
                        }

                        //barriers to completion
                        let barriersToCompletion = "";
                        let barriersToCompletionElement = actionPlanStepElement.querySelector(`.barriersToCompletion[data-actionplanplanpropertyfk='${actionPlanStepElement.dataset.actionplanplanpropertyfk}'][data-actionplanstepplanpropertyfk='${actionPlanStepElement.dataset.actionplanstepplanpropertyfk}']`) as HTMLTextAreaElement;
                        if (barriersToCompletionElement != null) {
                            barriersToCompletion = barriersToCompletionElement.value;
                        }

                        let actionPlanStepSaveElement: IPlanMonitoringActionPlanStep = {
                            ActionPlanStepPlanPropertyFK: parseInt(actionPlanStepElement.dataset.actionplanstepplanpropertyfk),
                            BarriersToCompletionValueText: barriersToCompletion,
                            LevelOfCompletionLookupCodeFK: levelOfCompletion,
                            PlanMonitoringActionPlanFK: actionPlanSaveElement.PlanMonitoringActionPlanPK,
                            PlanMonitoringActionPlanStepPK: parseInt(actionPlanStepElement.dataset.planmonitoringactionplanstepfk),
                            ReviseActionPlanLookupCodeFK: reviseActionPlan
                        }

                        actionPlanStepSaveElements.push(actionPlanStepSaveElement);
                    }
                }

                //Get the summary stuff
                let actionPlanSummarySaveElements: IPlanMonitoringActionPlanSummary[] = [];

                //Proposed Changes
                let proposedChanges = document.querySelectorAll(`.notesProposedChanges[data-actionplanplanpropertyfk='${actionPlan.dataset.actionplanplanpropertyfk}']`);
                for (let change of proposedChanges) {
                    let changeElement = <HTMLTextAreaElement>change;
                    let propertyFK = parseInt(changeElement.dataset.propertyfk);
                    let notesOnProposedChangesValueText = null;
                    let levelOfCompletionLookupCodeFK = null;
                    let reviseActionPlanLookupCodeFK = null;

                    if (changeElement.value != "") {
                        notesOnProposedChangesValueText = changeElement.value;
                    }

                    let levelOfCompletionElement = document.querySelector(`.levelOfCompletionSummary[data-actionplanplanpropertyfk='${actionPlan.dataset.actionplanplanpropertyfk}'][data-propertyfk='${propertyFK}']`) as HTMLSelectElement;
                    if (levelOfCompletionElement != null && levelOfCompletionElement.selectedIndex > 0) {
                        levelOfCompletionLookupCodeFK = levelOfCompletionElement.value;
                    }

                    let reviseActionPlanElement = document.querySelector(`.reviseActionPlanSummary[data-actionplanplanpropertyfk='${actionPlan.dataset.actionplanplanpropertyfk}'][data-propertyfk='${propertyFK}']`) as HTMLSelectElement;
                    if (reviseActionPlanElement != null && reviseActionPlanElement.selectedIndex > 0) {
                        reviseActionPlanLookupCodeFK = reviseActionPlanElement.value;
                    }

                    let summarySaveElement: IPlanMonitoringActionPlanSummary = {
                        LevelOfCompletionLookupCodeFK: levelOfCompletionLookupCodeFK,
                        NotesOnProposedChangesValueText: notesOnProposedChangesValueText,
                        PlanMonitoringActionPlanFK: parseInt(actionPlan.dataset.planmonitoringactionplanfk),
                        PropertyFK: propertyFK,
                        ReviseActionPlanLookupCodeFK: reviseActionPlanLookupCodeFK
                    }

                    actionPlanSummarySaveElements.push(summarySaveElement);
                }

                let actionPlanSaveData: IPlanMonitoringActionPlanSaveData = {
                    ActionPlan: actionPlanSaveElement,
                    ActionPlanSteps: actionPlanStepSaveElements,
                    Summary: actionPlanSummarySaveElements
                }

                actionPlans.push(actionPlanSaveData)
            }
        }

        let saveData = JSON.stringify({
            "PlanFK": planFK,
            "PageFK": pageFK,
            "PlanMonitoringFK": planMonitoringFK,
            "ActionPlans": actionPlans
        });

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/PlanMonitoring/SaveActionPlanReview', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planFK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };

        xhr.send(saveData);
    }
}

class PlanMonitoringArticulationAgreement {

    validationClasses: string[];
    deletePlanMonitoringFileModal: Modal;
    deleteArticulationAgreementFileModal: Modal;
    deleteArticulationConfirmModal: Modal;
    planMonitoringArticulationAgreementFileUploadToDeletePK: number;
    articulationAgreementToDeletePK: number;
    planMonitoringFK: number;
    templateFK: number;
    planFK: number;
    canEdit: boolean;
    planMonitoringFileToDeletePK: number;

    private _core: Core;

    constructor() {
        let that = this;

        var planMonitoringHdn = <HTMLInputElement>document.querySelector("[name='planMonitoringFK']");
        if (planMonitoringHdn) {
            that.planMonitoringFK = parseInt(planMonitoringHdn.value);
        }

        var templateHdn = <HTMLInputElement>document.querySelector("[name='templateFK']");
        if (templateHdn) {
            that.templateFK = parseInt(templateHdn.value);
        }

        var planHdn = <HTMLInputElement>document.querySelector("[name='planFK']");
        if (planHdn) {
            that.planFK = parseInt(planHdn.value);
        }

        var canEditHdn = <HTMLInputElement>document.querySelector("[name='canEdit']");
        if (planHdn) {
            that.canEdit = canEditHdn.value === "True";
        }
        
        that._core = new Core();
        that._core.activateSubMenu("subnavPlanMonitoring");
        that._core.leftnav(that);
        that._core.tabLinkSave(that);
        that.deletePlanMonitoringFileModal = new Modal('deletePlanMonitoringFileModal', null);
        that.deleteArticulationAgreementFileModal = new Modal('deleteArticulationAgreementFileModal', null);
        that.deleteArticulationConfirmModal = new Modal("deleteArticulationAgreementConfirmModal", null);

        that.validationClasses = ["planMonitoringField"];
        that.initializeRequiredFields();

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            that._core.doValidation(that.validationClasses);
        }

        let saveButton = document.getElementById("savePage");
        if (saveButton !== null) {
            saveButton.addEventListener("click", (e: Event) => that.save("save"));
        }

        let hidden = that._core.createHash(that.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let deleteArticulationAgreementFileConfirm = document.getElementById("deleteArticulationAgreementFileConfirm");
        if (deleteArticulationAgreementFileConfirm != null) {
            deleteArticulationAgreementFileConfirm.addEventListener("click", () => that.deleteArticulationAgreementFileConfirm());
        }

        let deleteArticulationAgreementFileCancel = document.getElementById("deleteArticulationAgreementFileCancel");
        if (deleteArticulationAgreementFileCancel != null) {
            deleteArticulationAgreementFileCancel.addEventListener("click", () => that.deleteArticulationAgreementFileModal.hide());
        }

        let deletePlanMonitoringFileConfirm = document.getElementById("deleteFileConfirm");
        if (deletePlanMonitoringFileConfirm != null) {
            deletePlanMonitoringFileConfirm.addEventListener("click", () => that.deletePlanMonitoringFileConfirm());
        }

        let deletePlanMonitoringFileCancel = document.getElementById("deleteFileCancel");
        if (deletePlanMonitoringFileCancel != null) {
            deletePlanMonitoringFileCancel.addEventListener("click", () => that.deletePlanMonitoringFileModal.hide());
        }

        //Event listener for deleting an articulation agreement
        let deleteArticulationConfirmButton = document.getElementById("deleteArticulationConfirm") as HTMLButtonElement;
        if (deleteArticulationConfirmButton != null) {

            deleteArticulationConfirmButton.addEventListener("click", () => {
                Core.showLoader();
                that.deleteArticulationConfirmModal.hide();
                that.deleteArticulationAgreement(that.articulationAgreementToDeletePK)
                .then((response) => {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", response, 'success', 3000, null);
                })
                .catch((error) => {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                });
            });
        }

        //Event listener for cancelling the delete of a support
        let deleteArticulationCancelButton = document.getElementById("deleteArticulationCancel") as HTMLButtonElement;
        if (deleteArticulationCancelButton != null) {
            deleteArticulationCancelButton.addEventListener("click", () => {
                that.deleteArticulationConfirmModal.hide();
                var returnFocusTo = document.querySelector(`.deletePlanMonitoringArticulationAgreement[data-articulation-pk='${that.articulationAgreementToDeletePK}']`) as HTMLElement;
                if (returnFocusTo != null) {
                    returnFocusTo.focus();
                }
            });
        }

        let addArticulationAgreement = document.getElementById("planMonitoringAddArticulationAgreement");
        if (addArticulationAgreement != null) {
            addArticulationAgreement.addEventListener("click", () => {
                Core.showLoader();
                that.addArticulationAgreement()
                .then((response) => {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", response, 'success', 3000, null);
                    that._core.initializeRequiredFields(that.validationClasses);
                })
                .catch((error) => {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                });
            });
        }

        document.addEventListener("click", (e) => {
            let target = e.target as HTMLElement;
            
            //Deleting an articulation agreement
            if (target.classList.contains("deletePlanMonitoringArticulationAgreement") && "articulationPk" in target.dataset) {
                that.deleteArticulationConfirmModal.show();
                that.articulationAgreementToDeletePK = parseInt(target.dataset.articulationPk);
            }
            else if (target.tagName == "BUTTON" && target.classList.contains("deleteArticulationAgreementFile") && "articulationFilePk" in target.dataset) {
                that.deleteArticulationAgreementFileModal.show();
                that.deleteArticulationAgreementFileModal.callingId = target.id;
                that.planMonitoringArticulationAgreementFileUploadToDeletePK = parseInt(target.dataset.articulationFilePk);
            } else if (target.tagName == "BUTTON" && target.classList.contains("deleteFile") && "monitoringFilePk" in target.dataset) {
                that.deletePlanMonitoringFileModal.show();
                that.deletePlanMonitoringFileModal.callingId = target.id;
                that.planMonitoringFileToDeletePK = parseInt(target.dataset.monitoringFilePk);
            }

        });

        document.addEventListener("change", (e) => {
            let target = e.target as HTMLElement;
            
            //Uploading an articulation agreement file
            if (target.classList.contains("articulationAgreementFileUpload") && "articulationFk" in target.dataset && "planFk" in target.dataset) {
                that.uploadArticulationAgreementFile(parseInt(target.dataset.articulationFk), parseInt(target.dataset.planFk));
            } else if(target.classList.contains("fileUpload") && "propertyFk" in target.dataset) {
                that.uploadPlanMonitoringFile(parseInt(target.dataset.propertyFk));
            }

        });

        let hasArticulationsCheckbox = document.getElementById("noArticulationAgreements") as HTMLInputElement;
        if (hasArticulationsCheckbox != null) {
            hasArticulationsCheckbox.addEventListener("change", () => {
                that.initializeRequiredFields.apply(that);
            });
        }
    }

    initializeRequiredFields() {
        let that = this;
        let hasArticulationsCheckbox = document.getElementById("noArticulationAgreements") as HTMLInputElement;

        let formattedAllClasses = [];
        that.validationClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + that.validationClasses[index];
        });

        let classesToValidate = formattedAllClasses.join(",");

        let allElements = document.querySelectorAll(classesToValidate) as NodeListOf<HTMLElement>;

        if (hasArticulationsCheckbox != null) {
            let hasArticulations = !hasArticulationsCheckbox.checked;
            for (let element of allElements) {
                if(hasArticulations) {
                    that._core.forceElementRequired(element);
                } else {
                    that._core.forceElementOptional(element);
                }
                if(that.canEdit) {
                    if (element instanceof HTMLSelectElement) {
                        element.disabled = !hasArticulations;
                    } else if (element instanceof HTMLInputElement && element.id != "noArticulationAgreements") {
                        element.disabled = !hasArticulations;
                    } 
                }
            }
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let that = this;
        let core = that._core;
        if (referrer !== "save" && that._core.checkSave(that) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }

        let articulations: IPlanMonitoringArticulationAgreement[] = [];
        var articulationContainers = document.querySelectorAll(".newArticulationAgreementContainer[data-articulation-pk]") as NodeListOf<HTMLElement>;
        for (let articulation of articulationContainers) {
            let partneringInstitutionValue = "";
            let partneringInstitutionInput = document.querySelector(`.newArticulationPartneringInstitution[data-articulation-pk='${articulation.dataset.articulationPk}']`) as HTMLInputElement;
            if(partneringInstitutionInput != null)
            {
                partneringInstitutionValue = partneringInstitutionInput.value
            }

            let agreementTypeValue = null;
            let agreementTypeSelect = document.querySelector(`.newArticulationAgreementType[data-articulation-pk='${articulation.dataset.articulationPk}']`) as HTMLSelectElement;
            if (agreementTypeSelect != null && parseInt(agreementTypeSelect.value)) {
                agreementTypeValue = parseInt(agreementTypeSelect.value);
            }

            let programCourseValue = "";
            let programCourseInput = document.querySelector(`.newArticulationProgramCourseAreaName[data-articulation-pk='${articulation.dataset.articulationPk}']`) as HTMLInputElement;
            if(programCourseInput != null)
            {
                programCourseValue = programCourseInput.value
            }

            let articulationSaveData: IPlanMonitoringArticulationAgreement = {
                PartneringInstitutionName: partneringInstitutionValue,
                AgreementTypeLookupCodeFK: agreementTypeValue,
                ProgramCourseAreaName: programCourseValue,
                PlanMonitoringArticulationAgreementPK: parseInt(articulation.dataset.articulationPk),
                PlanMonitoringFK: that.planMonitoringFK
            };

            articulations.push(articulationSaveData);
        }

        let isMonitoringArticulationAgreements: boolean = true;
        let isMonitoringCheckbox = document.getElementById("noArticulationAgreements") as HTMLInputElement;
        if(isMonitoringCheckbox != null && isMonitoringCheckbox.checked)
        {
            isMonitoringArticulationAgreements = false;
        }

        let saveData = JSON.stringify({
            "ArticulationAgreements": articulations,
            "IsMonitoringArticulationAgreements": isMonitoringArticulationAgreements,
            "PlanMonitoringFK": that.planMonitoringFK
        });

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/PlanMonitoring/SaveArticulationAgreement', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, that.planFK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };

        xhr.send(saveData);
    }

    deleteArticulationAgreementFileConfirm() {
        let that = this;
        const core = this._core;
        Core.showLoader();

        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/PlanMonitoring/DeleteArticulationAgreementFile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    const response = JSON.parse(xhr.responseText);
                    if (response.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert('alertMessageDiv', 'The file has been successfully deleted.', 'success', 3000, null);

                        const containerElement = <HTMLElement>document.querySelector(`.uploadFileContainer[data-articulation-file-pk='${that.planMonitoringArticulationAgreementFileUploadToDeletePK}']`);
                        
                        if (containerElement !== null) {
                            let nextFocusable = Core.getNextFocusableElement(containerElement);
                            containerElement.parentElement.removeChild(containerElement);
                            if (nextFocusable != null) {
                                nextFocusable.focus();
                            }
                        }
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert('alertMessageDiv', 'There was an unexpected error deleting file.', 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert('alertMessageDiv', 'The file removal failed. Please try again.', 'error', 3000, null);
            }

            that.deleteArticulationAgreementFileModal.hide();
        };
        xhr.send(that.planMonitoringArticulationAgreementFileUploadToDeletePK.toString());
    }

    addArticulationAgreement() {
        let that = this;

        return new Promise((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/PlanMonitoring/CreateNewArticulationAgreement', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {

                    let container = document.getElementById("newUpdatedArticulationAgreementContainer");
                    if (container != null) {
                        $(container).append(xhr.responseText);
                        resolve("Successfully added the new articulation agreement.");
                        that.initializeRequiredFields();
                    } else {
                        reject("There was an unexpected error adding the new articulation agreement.");
                    }
                } else {
                    reject("There was an unexpected error adding the new articulation agreement");
                }
            };
            xhr.send("planMonitoringFK=" + that.planMonitoringFK + "&templateFK=" + that.templateFK + '&planFK=' + that.planFK);
        });
    }

    deleteArticulationAgreement(articulationPK: number) {
        return new Promise((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/PlanMonitoring/DeleteArticulationAgreement', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                let jsonResponse = JSON.parse(xhr.response);
                if (xhr.status === 200 && jsonResponse.success) {

                    let container = document.querySelector(`.newArticulationAgreementContainer[data-articulation-pk='${articulationPK}']`);
                    if (container != null) {
                        let deleteButton = document.querySelector(`.deletePlanMonitoringArticulationAgreement[data-articulation-pk='${articulationPK}']`) as HTMLElement;
                        let nextFocusable = Core.getNextFocusableElement(deleteButton);

                        container.parentNode.removeChild(container);
                        nextFocusable.focus();
                        resolve("Successfully deleted articulation agreement");
                    } else {
                        reject("There was an unexpected error removing this articulation agreement");
                    }
                } else {
                    reject("There was an unexpected error deleting this articulation agreement");
                }
            };
            xhr.send("articulationPK=" + articulationPK);
        });
    }

    uploadArticulationAgreementFile(articulationPK: number, planPK: number) {
        const core = this._core;
        Core.showLoader();
        const uploadForm = <HTMLFormElement>document.querySelector(`.uploadArticulationFileForm[data-articulation-fk='${articulationPK}']`);
        const formData = new FormData(uploadForm);

        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/PlanMonitoring/UploadArticulationAgreementFile', true);
        xhr.onload = () => {
            if (xhr.status === 200) {
                if (xhr.responseText !== null) {
                    const response = JSON.parse(xhr.responseText);
                    if (response.success) {                        
                        Core.hideLoader();
                        Core.createHTMLAlert('alertMessageDiv', 'The file has been successfully uploaded!', 'success', 3000, null);
                        
                        const formFile = document.querySelector(`.articulationAgreementFileUpload[data-articulation-fk='${articulationPK}']`) as HTMLInputElement;
                        formFile.value = '';             

                        const fileUpload = response.articulationFile;

                        const xhrForPartialView = new XMLHttpRequest();
                        xhrForPartialView.open('GET', `/PlanMonitoring/GetPlanMonitoringArticulationAgreementFileUploadPartialView?planMonitoringArticulationAgreementFileUploadPK=${fileUpload.planMonitoringArticulationAgreementFileUploadPK}&planFK=${planPK}`);                                               
                        xhrForPartialView.onload = () => {
                            if (xhrForPartialView.status === 200) {
                                const fileUploadDiv = document.querySelector(`.uploadedFilesContainer[data-articulation-fk='${articulationPK}']`) as HTMLDivElement;
                                $(fileUploadDiv).append(xhrForPartialView.response);
                            } 
                        };
                        xhrForPartialView.send();  
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert('alertMessageDiv', 'The file upload failed. Please try again.', 'error', 3000, null);
                    }      
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert('alertMessageDiv', 'The file upload failed. Please try again.', 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }

    uploadPlanMonitoringFile(propertyFK: number) {
        const core = this._core;
        Core.showLoader();
        const uploadForm = <HTMLFormElement>document.querySelector(`.uploadForm[data-property-fk='${propertyFK}']`);
        const formData = new FormData(uploadForm);

        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/PlanMonitoring/UploadMonitoringFile', true);
        xhr.onload = () => {
            if (xhr.status === 200) {
                if (xhr.responseText !== null) {
                    const response = JSON.parse(xhr.responseText);
                    if (response.success) {                        
                        Core.hideLoader();
                        Core.createHTMLAlert('alertMessageDiv', 'The file has been successfully uploaded!', 'success', 3000, null);
                        
                        const formFile = document.querySelector(`.fileUpload[data-property-fk='${propertyFK}']`) as HTMLInputElement;
                        formFile.value = '';             

                        const fileUpload = response.planMonitoringFile;

                        const xhrForPartialView = new XMLHttpRequest();
                        xhrForPartialView.open('GET', `/PlanMonitoring/GetPlanMonitoringFileUploadPartialView/${fileUpload.planMonitoringFilePK}`);                                               
                        xhrForPartialView.onload = () => {
                            if (xhrForPartialView.status === 200) {
                                const fileUploadDiv = document.querySelector(`.uploadedFilesContainer[data-property-fk='${propertyFK}']`) as HTMLDivElement;
                                $(fileUploadDiv).append(xhrForPartialView.response);
                            } 
                        };
                        xhrForPartialView.send();  
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert('alertMessageDiv', 'The file upload failed. Please try again.', 'error', 3000, null);
                    }      
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert('alertMessageDiv', 'The file upload failed. Please try again.', 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }

    deletePlanMonitoringFileConfirm() {
        let that = this;
        const core = this._core;
        Core.showLoader();

        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/PlanMonitoring/DeleteMonitoringFile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    const response = JSON.parse(xhr.responseText);
                    if (response.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert('alertMessageDiv', 'The file has been successfully deleted.', 'success', 3000, null);

                        const containerElement = <HTMLElement>document.querySelector(`.uploadFileContainer[data-monitoring-file-pk='${that.planMonitoringFileToDeletePK}']`);
                        
                        if (containerElement !== null) {
                            let fileInput = document.querySelector(`.fileUpload[data-property-fk='${containerElement.dataset.propertyFk}']`) as HTMLInputElement;
                            if (fileInput != null) {
                                that.deletePlanMonitoringFileModal.callingId = fileInput.id;
                            }
                            containerElement.parentElement.removeChild(containerElement);
                        }
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert('alertMessageDiv', 'There was an unexpected error deleting file.', 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert('alertMessageDiv', 'The file removal failed. Please try again.', 'error', 3000, null);
            }

            that.deletePlanMonitoringFileModal.hide();
        };
        xhr.send(that.planMonitoringFileToDeletePK.toString());
    }
}