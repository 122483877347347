/// <reference path="page.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />

class CharterSchoolPage extends Page {
    constructor(navigationCode: string, pageCode: string, pageLabel: string, planPK: number, templateFK: number, allowDuplicates: boolean = false, runValidation: boolean = true) {
        super(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates)

        if (runValidation && this._fromSaveElement && this._fromSaveElement.value === 'true') this._validationHandler.validate()
    }
}

class CharterSchoolAnnualReportSchoolProfile extends CharterSchoolPage {
    constructor(navigationCode: string, pageCode: string, pageLabel: string, planPK: number, templateFK: number, allowDuplicates: boolean = false) {
        super(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates)
        this.constructEventListeners()
        this.formatElements()
        const currentCharterUpload = document.querySelector("input[type='file'][data-propertycode='charterschoolprofilecurrentcharterupload']") as HTMLInputElement;
        if(currentCharterUpload) {
            currentCharterUpload.accept = "application/pdf";
        }
    }

    private formatElements() {
        document.querySelectorAll('select').forEach(select => select.closest('.shared-page-row').classList.add('d-flex'))
    }

    private constructEventListeners() {
        this.constructCyberCharterCheckbox()

        const refreshProfileButton = document.getElementById('charterschoolprofilerefreshprofile') as HTMLButtonElement
        refreshProfileButton.addEventListener('click', (e: Event) => this.refreshProfile())

        this.constructAUNSearchInputs()
        this._groupHandler.Callback = this.constructAUNSearchInputs
    }

    private constructAUNSearchInputs = () => {
        const aunSearchInputs = document.querySelectorAll(`.${this._fieldset.Text}[data-propertypk='2101']`) as NodeListOf<HTMLInputElement>
        for (const input of aunSearchInputs) this.constructAUNSearch(input)
        this.formatElements()
    }

    private constructCyberCharterCheckbox() {
        const modal: Modal = new Modal('cyberCharterModal', null)

        const cyberCharterCheckbox = document.getElementById('charterschoolprofilecybercharter') as HTMLInputElement
        if (cyberCharterCheckbox.checked) {
            const addAnAuthorizorButton = document.getElementById('charterschooladdAuthorizor') as HTMLButtonElement
            addAnAuthorizorButton.classList.add('display-none')
            if(this._fromSaveElement && this._fromSaveElement.value === 'true') {
                this._validationHandler.Correction = 7
                this._validationHandler.validate()
            }
        }

        if (cyberCharterCheckbox) cyberCharterCheckbox.addEventListener('click', (e: Event) => this.cyberCharterSelect(cyberCharterCheckbox, modal))

        const cyberCharterConfirm = document.getElementById('cyberCharterConfirm') as HTMLButtonElement
        if (cyberCharterConfirm) cyberCharterConfirm.addEventListener('click', (e: Event) => this.cyberCharterConfirm(modal))

        const cyberCharterCancel = document.getElementById('cyberCharterCancel') as HTMLButtonElement
        if (cyberCharterCancel) cyberCharterCancel.addEventListener('click', (e: Event) => this.cyberCharterCancel(cyberCharterCheckbox, modal))

        if (cyberCharterCheckbox.checked) this.cyberCharterConfirm(modal)
    }

    public constructAUNSearch(aunInput: HTMLInputElement) {
        aunInput.addEventListener('blur', (e: Event) => this.findSchoolInfoFromAunBlur(aunInput))
        aunInput.addEventListener('input', (e: Event) => this.findSchoolInfoFromAunInput(aunInput))
    }

    private async refreshProfile() {
        const response = await fetch(
            `/${this._navigationCode}/GetICDataForProfile/`,
            {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: `planFK=${this._planPK}`
            }
        )

        if (response.ok) {
            let wasUpdated = false
            const json = await response.json()
            if (!json.instDetails) Core.createHTMLAlert('alertMessageDiv', 'There was an error retrieving data from EdNA, please try again.', 'error', 3000, null)

            const leaName = document.getElementById('charterschoolprofileleaname') as HTMLInputElement
            const aun = document.getElementById('charterschoolprofileaun') as HTMLInputElement
            const address1 = document.getElementById('charterschoolprofileaddress1') as HTMLInputElement
            const address2 = document.getElementById('charterschoolprofileaddress2') as HTMLInputElement
            const city = document.getElementById('charterschoolprofilecity') as HTMLInputElement
            const state = document.getElementById('charterschoolprofilestate') as HTMLInputElement
            const zipCode = document.getElementById('charterschoolprofilezipcode') as HTMLInputElement
            const ceoName = document.getElementById('charterschoolprofileceoname') as HTMLInputElement
            const ceoEmail = document.getElementById('charterschoolprofileceoemail') as HTMLInputElement
            const ceoPhone = document.getElementById('charterschoolprofileceophone') as HTMLInputElement

            if (leaName.value !== json.instDetails.instName) {
                leaName.value = json.instDetails.instName
                wasUpdated = true
            }

            if (parseInt(aun.value) !== json.instDetails.auNumber) {
                aun.value = json.instDetails.auNumber
                wasUpdated = true
            }

            if (address1.value !== json.instDetails.addressLine1) {
                address1.value = json.instDetails.addressLine1
                wasUpdated = true
            }

            if (address2.value !== json.instDetails.addressLine2) {
                address2.value = json.instDetails.addressLine2
                wasUpdated = true
            }

            if (city.value !== json.instDetails.city) {
                city.value = json.instDetails.city
                wasUpdated = true
            }

            if (state.value !== json.instDetails.state) {
                state.value = json.instDetails.state
                wasUpdated = true
            }

            if (zipCode.value !== json.instDetails.zipCode) {
                zipCode.value = json.instDetails.zipCode
                wasUpdated = true
            }

            if (zipCode.value !== json.instDetails.zipCode) {
                zipCode.value = json.instDetails.zipCode
                wasUpdated = true
            }

            if (ceoName.value !== json.superintendentInfo.adminName) {
                ceoName.value = json.superintendentInfo.adminName
                wasUpdated = true
            }

            if (ceoEmail.value !== json.superintendentInfo.email) {
                ceoEmail.value = json.superintendentInfo.email
                wasUpdated = true
            }

            if (ceoPhone.value !== json.superintendentInfo.phoneNumber) {
                ceoPhone.value = json.superintendentInfo.phoneNumber
                wasUpdated = true
            }

            if (wasUpdated) {
                Core.createHTMLAlert('alertMessageDiv', 'Successfully updated the profile with the most recent EdNA data, please save your changes.', 'success', 3000, null)
            } else {
                Core.createHTMLAlert('alertMessageDiv', 'Profile is up to date with the most recent EdNA data.', 'success', 3000, null)
            }
        } else {
            Core.createHTMLAlert('alertMessageDiv', 'There was an error retrieving data from EdNA, please try again.', 'error', 3000, null)
        }
    }

    private findSchoolInfoFromAunBlur(input: HTMLInputElement) {
        if (input.value.length < 9) Core.createHTMLAlert('alertMessageDiv', 'The AUN must be 9 digits. If your AUN contains leading zeros, please include them here.', 'warning', 3000, null);
    }

    private findSchoolInfoFromAunInput(input: HTMLInputElement) {
        const value = input.value
        const row = input.dataset.row

        if (value.length === 9) {
            if (!isNaN(parseInt(value))) {
                this.runFindSchool(value, row)
            }
            else {
                Core.createHTMLAlert('alertMessageDiv', 'The AUN must be 9 digits. Please check your entry for invalid characters and try again.', 'warning', 3000, null)
            }
        }
    }

    private async runFindSchool(value, row) {
        Core.showLoader()

        const request = await HttpRequestHandler.post(
            `/${this._navigationCode}/FindSchoolDataFromAUN`,
            'Successfully retrieved data',
            'There was an error retrieving the data, please try again.',
            JSON.stringify(value),
            'application/json'
        )

        if (request.ok) {
            const json = await request.json()
            if (json.success) {
                const leaName = json.instDetails.instName
                const superName = json.superintendentDetails.adminName
                const superEmail = json.superintendentDetails.email
                const superPhone = json.superintendentDetails.phoneNumber
                const superExt = json.superintendentDetails.extension

                const leaElement = document.querySelector(`.${this._fieldset.Text}[data-propertypk='1815'][data-row='${row}']`) as HTMLInputElement
                const superNameElement = document.querySelector(`.${this._fieldset.Text}[data-propertypk='1818'][data-row='${row}']`) as HTMLInputElement
                const superEmailElement = document.querySelector(`.${this._fieldset.Text}[data-propertypk='1819'][data-row='${row}']`) as HTMLInputElement
                const superPhoneElement = document.querySelector(`.${this._fieldset.Text}[data-propertypk='1820'][data-row='${row}']`) as HTMLInputElement
                const superExtElement = document.querySelector(`.${this._fieldset.Text}[data-propertypk='1821'][data-row='${row}']`) as HTMLInputElement

                if (leaName) leaElement.value = leaName
                if (superName) superNameElement.value = superName
                if (superEmail) superEmailElement.value = superEmail
                if (superPhone) superPhoneElement.value = superPhone
                if (superExt) superExtElement.value = superExt
            }
        }
    }


    private cyberCharterSelect(cyberCharterCheckbox: HTMLInputElement, modal: Modal) {
        if (!cyberCharterCheckbox.checked) {
            const addAnAuthorizorButton = document.getElementById('charterschooladdAuthorizor') as HTMLButtonElement
            addAnAuthorizorButton.classList.remove('display-none')
            this._saveHandler.save('save')
            return
        }

        modal.show()
    }

    private cyberCharterConfirm(modal: Modal) {
        const addAnAuthorizorButton = document.getElementById('charterschooladdAuthorizor') as HTMLButtonElement
        addAnAuthorizorButton.classList.add('display-none')

        const deleteAuthorizorButtons = document.querySelectorAll('.charterschooldeleteAuthorizorButton ')

        for (const button of deleteAuthorizorButtons) {
            const deleteAuthorizorButton = button as HTMLButtonElement
            this._groupHandler.deleteGroup(deleteAuthorizorButton)
        }

        modal.hide()
    }

    private cyberCharterCancel(cyberCharterCheckbox: HTMLInputElement, modal: Modal) {
        cyberCharterCheckbox.checked = false
        modal.hide()
    }
}

class CharterSchoolAnnualReportGovernanceAndStaff extends CharterSchoolPage {
    constructor(navigationCode: string, pageCode: string, pageLabel: string, planPK: number, templateFK: number, allowDuplicates: boolean = true) {
        super(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates)

        this.constructQualityOfTeachingInputs()
    }

    private constructQualityOfTeachingInputs() {
        const tableHeaders = document.querySelectorAll('tr.shared-page-table-header-row th') as NodeListOf<HTMLTableHeaderCellElement>

        for (let column = 0; column < tableHeaders.length; column++) {
            const inputsInColumn = document.querySelectorAll(`input.column-${column}:not(.prepopulated)`) as NodeListOf<HTMLInputElement>
            const totalInput = document.querySelector(`input.column-${column}.prepopulated`) as HTMLInputElement

            for(const input of inputsInColumn) input.addEventListener('input', (e: Event) => this.totalizeColumn(inputsInColumn, totalInput))
        }
    }

    private totalizeColumn(inputsInColumn: NodeListOf<HTMLInputElement>, totalInput: HTMLInputElement) {
        let total = 0

        for (const input of inputsInColumn) {
            if (input.value) total += parseInt(input.value)
        }

        totalInput.value = total.toString()
    }
}

class CharterSchoolAnnualReportFiscalMatters extends CharterSchoolPage { 
    constructor(navigationCode: string, pageCode: string, pageLabel: string, planPK: number, templateFK: number, allowDuplicates: boolean = true) {
        super(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates, false);
        this.toggleConsolidatedReviewSection();
        if (this._fromSaveElement && this._fromSaveElement.value === 'true') this._validationHandler.validate();
        this._radioButtonControlHandler.Callback = this.toggleConsolidatedReviewSection;
    }

    private toggleConsolidatedReviewSection = () => {
        //see if "no" was selected for Federal Programs Consolidated Review Document(s) 
        var yesChecked = document.querySelector(`input[name="charterschoolfiscalconsolidatedreviewdocumentsRadio"][data-lookuplabel='Yes']:checked`);
        var sectionToHide = document.querySelector(".shared-page-table-container[data-propertygroupcodepk='90']");
        if(yesChecked != null)
        {
            if (sectionToHide != null) {
                $(sectionToHide).prev(".shared-page-title").show();
                $(sectionToHide).prev(".page-section-break").show();
                $(sectionToHide).show();

                let textAreas = document.querySelectorAll(".shared-page-table-container[data-propertygroupcodepk='90'] .charterschoolFiscalMattersTextAreaField") as NodeListOf<HTMLElement>;
                for (let el of textAreas) {
                    this._validationHandler.forceElementRequired(el);
                }
            }
        }
        else
        {
            if (sectionToHide != null) {
                $(sectionToHide).prev(".shared-page-title").hide();
                $(sectionToHide).prev(".page-section-break").hide();
                $(sectionToHide).hide();

                let textAreas = document.querySelectorAll(".shared-page-table-container[data-propertygroupcodepk='90'] .charterschoolFiscalMattersTextAreaField") as NodeListOf<HTMLElement>;
                for (let el of textAreas) {
                    this._validationHandler.forceElementOptional(el);
                }
            }
        }
    } 
}

class SpecialEducationPersonnelDevelopment {
    protected _fieldset: Fieldset
    protected _validationHandler: ValidationHandler

    protected _planPK: number
    protected _pageLabel: string

    protected _core: Core = new Core()

    constructor(pageLabel: string, planPK: number) {
        this._pageLabel = pageLabel
        this._planPK = planPK

        this._fieldset = new Fieldset('charterSchool', this._pageLabel)

        this._validationHandler = new ValidationHandler(this._fieldset)
        this._core.leftnav(this)

        let noTrainingCheckboxes = document.querySelectorAll("[data-propertycode='charterschoolpersonneldevelopmentdidnotoffertraining'][data-accordionpk]") as NodeListOf<HTMLInputElement>;
        for (let checkbox of noTrainingCheckboxes) {
            this.handleNoTrainingCheckbox(checkbox);
            checkbox.addEventListener("click", () => {
                this.handleNoTrainingCheckbox(checkbox);
            });
        }

        this._core.initializeRequiredFields(this._fieldset.ValidationClasses, false, true)
        const fromSaveElement = document.getElementById('fromSave') as HTMLInputElement
        if (fromSaveElement && fromSaveElement.value === 'true') {
            this._validationHandler.validate()
        }

        const hidden = this.createHash()
        const hiddenInput = document.getElementById('hashValue') as HTMLInputElement
        if (hiddenInput) hiddenInput.value = hidden

        this.constructEventListeners();
    }

    public getCore() {
        return this._core
    }

    public createHash() {
        return this._core.createHash(this._fieldset.ValidationClasses)
    }

    public constructEventListeners() {
        const accordions = document.getElementsByClassName(`${this._fieldset.Accordion}`) as HTMLCollectionOf<HTMLButtonElement>
        for (const accordion of accordions) new CharterSchoolAccordion(accordion.id)

        const addTrainingButtons = document.querySelectorAll(`.${this._fieldset.Button}[data-propertypk='2105']`)
        for (const button of addTrainingButtons) this.constructAddTrainingEventListener(button as HTMLButtonElement)

        const addDateTrainingCompletionButtons = document.querySelectorAll(`.${this._fieldset.Button}`)
        for (const button of addDateTrainingCompletionButtons) if (button.hasAttribute('data-trainingcompletion')) this.constructAddDateTrainingCompletionEventListener(button as HTMLButtonElement)

        const deleteTrainingButtons = document.querySelectorAll('.charterSchoolPersonnelDevelopmentDeleteTrainingButton')
        for (const button of deleteTrainingButtons) this.constructDeleteTrainingEventListener(button as HTMLButtonElement)

        const deleteDateTrainingCompletionButtons = document.querySelectorAll('.charterSchoolPersonnelDevelopmentDeleteButton')
        for (const button of deleteDateTrainingCompletionButtons) this.constructDeleteDateTrainingCompletionEventListener(button as HTMLButtonElement)

        const charterSchoolSaveButton = document.getElementById(`charterSchool${this._pageLabel}Save`)
        if (charterSchoolSaveButton) charterSchoolSaveButton.addEventListener('click', (e: Event) => this.save('save'))

        const fileUploadElements = document.querySelectorAll(`.${this._fieldset.File}`)
        for (const element of fileUploadElements) this.constructFileUploadElementEventListeners(element as HTMLInputElement)

        const deleteFileCancelButton = document.getElementById('deleteFileCancel') as HTMLButtonElement
        if (deleteFileCancelButton) deleteFileCancelButton.addEventListener('click', (e: Event) => this.deleteFileCancel())

        const deleteFileConfirmButton = document.getElementById('deleteFileConfirm') as HTMLButtonElement
        if (deleteFileConfirmButton) deleteFileConfirmButton.addEventListener('click', (e: Event) => this.deleteFile(deleteFileConfirmButton))

        this.constructFileDeleteElements()
    }

    private constructFileDeleteElements() {
        const deleteFileButtons = document.getElementsByClassName('deleteFile') as HTMLCollectionOf<HTMLButtonElement>
        for (const deleteFileButton of deleteFileButtons) deleteFileButton.addEventListener('click', (e: Event) => this.showDeleteFileConfirmation(deleteFileButton))
    }

    private constructFileUploadElementEventListeners(input: HTMLInputElement) {
        input.addEventListener('change', (e: Event) => this.uploadFile(input))
    }

    private constructAddTrainingEventListener(button: HTMLButtonElement) {
        button.addEventListener('click', (e: Event) => this.addTraining(button))
    }

    private constructAddDateTrainingCompletionEventListener(button: HTMLButtonElement) {
        button.addEventListener('click', (e: Event) => this.addDateTrainingCompletion(button))
    }

    private constructDeleteTrainingEventListener(button: HTMLButtonElement) {
        button.addEventListener('click', (e: Event) => this.deleteTraining(button))
    }

    private constructDeleteDateTrainingCompletionEventListener(button: HTMLButtonElement) {
        button.addEventListener('click', (e: Event) => this.deleteTrainingCompletion(button))
    }

    public async uploadFile(input: HTMLInputElement) {
        const uploadFileForm = input.parentElement as HTMLFormElement
        const formData = new FormData(uploadFileForm)
        formData.append('planFK', this._planPK.toString())

        const response = await HttpRequestHandler.post(
            '/CharterSchool/UploadPersonnelDevelopmentFile',
            'File successfully uploaded!',
            'There was an error uploading your file, please try again.',
            formData
        )

        const json = await response.json()

        if (json && json.success) {
            input.value = ''
            input.dataset.hasuploaded = 'true'
            this.getFileUploadPartialView(json.planPropertyFile, input.dataset)
        }
    }

    public async deleteFile(confirmButton: HTMLButtonElement) {
        const planPropertyFilePK: string = confirmButton.dataset.planpropertyfilepk

        const response = await HttpRequestHandler.post(
            '/CharterSchool/DeleteFiles',
            'File successfully deleted.',
            'There was an error deleting this file, please try again',
            JSON.stringify([planPropertyFilePK]),
            'application/json'
        )

        const json = await response.json()

        if (json.success) this.deleteFileSuccess(planPropertyFilePK)
    }

    public showDeleteFileConfirmation(deleteButton: HTMLButtonElement) {
        const planPropertyFilePK = deleteButton.dataset.planpropertyfilepk

        if (planPropertyFilePK && parseInt(planPropertyFilePK) > 0) {
            const modal: Modal = new Modal('deleteFileModal', null)
            const deleteConfirmButton = document.getElementById('deleteFileConfirm') as HTMLButtonElement
            deleteConfirmButton.dataset.planpropertyfilepk = planPropertyFilePK
            modal.show()
        }
    }

    public deleteFileCancel() {
        const modal: Modal = new Modal('deleteFileModal', null)
        modal.hide()
    }

    private deleteFileSuccess(planPropertyFilePK: string) {
        const fileUploadElement = document.querySelector(`.uploadFileColumn[data-planpropertyfilepk='${planPropertyFilePK}']`) as HTMLElement
        if (fileUploadElement) fileUploadElement.remove()

        const modal: Modal = new Modal('deleteFileModal', null)
        modal.hide()
    }

    private async getFileUploadPartialView(planPropertyFile, dataset) {
        const response = await HttpRequestHandler.get('/CharterSchool/GetCharterSchoolFileUploadPartialView', {
            planPropertyFilePK: planPropertyFile.planPropertyFilePK,
            contentType: planPropertyFile.contentType,
            filename: planPropertyFile.filename
        })

        const text = await response.text()

        if (response.ok && text) {
            const fileUploadDiv = document.querySelector(`.charterSchoolFileUploadDivInner[data-propertypk='${dataset.propertypk
                }'][data-accordiontitle='${dataset.accordiontitle
                }'][data-training='${dataset.training
                }'][data-trainingcompletion='${dataset.trainingcompletion}']`) as HTMLDivElement

            fileUploadDiv.insertAdjacentHTML('beforeend', text)
            this.constructFileDeleteElements()
        }
    }

    public async addTraining(button: HTMLButtonElement) {
        const dataset = button.dataset
        const training = parseInt(dataset.training)
        const nextTraining = training + 1

        const addtrainingModel = JSON.stringify({
            PlanPK: this._planPK,
            AccordionPK: dataset.accordionpk,
            Training: nextTraining,
            AccordionTitle: dataset.accordiontitle
        })

        const response = await HttpRequestHandler.post(
            '/CharterSchool/AddPersonnelDevelopmentTraining',
            'Successfully added a training.',
            'There was an error adding a training, please try again',
            addtrainingModel,
            'application/json'
        )

        const text = await response.text()

        if (response.ok && text) {
            const accordionTitle = dataset.accordiontitle

            button.parentElement.insertAdjacentHTML('beforebegin', text)

            button.dataset.training = nextTraining.toString()

            const newTrainingInputs = document.querySelectorAll(`.personnel-development-training.personnel-development-row[data-accordiontitle='${accordionTitle
                }'][data-training='${nextTraining}'] input`)

            for (const input of newTrainingInputs) this._validationHandler.forceElementRequired(input as HTMLInputElement)

            const newAddDateTrainingCompletionButton
                = document.querySelector(`.${this._fieldset.Button
                    }[data-accordiontitle='${accordionTitle
                    }'][data-training='${nextTraining}']`) as HTMLButtonElement
            this.constructAddDateTrainingCompletionEventListener(newAddDateTrainingCompletionButton)

            const newTrainingCompletionFileUploadInputs
                = document.querySelectorAll(`.${this._fieldset.File
                    }[data-accordiontitle='${accordionTitle
                    }'][data-training='${nextTraining}']`)
            for (const fileUpload of newTrainingCompletionFileUploadInputs) {
                this.constructFileUploadElementEventListeners(fileUpload as HTMLInputElement)
            }

            const newDeleteTrainingButton
                = document.querySelector(`.charterSchoolPersonnelDevelopmentDeleteTrainingButton[data-accordiontitle='${accordionTitle}'][data-training='${nextTraining}']`)
            this.constructDeleteTrainingEventListener(newDeleteTrainingButton as HTMLButtonElement)

            this.addDeleteTrainingCompletionButton(dataset)
        }
    }

    public async addDateTrainingCompletion(button: HTMLButtonElement) {
        const dataset = button.dataset

        const accordionTitle = dataset.accordiontitle
        const training = dataset.training
        const trainingCompletion = dataset.trainingcompletion
        const nextTrainingCompletion = parseInt(trainingCompletion) + 1

        const addDateTrainingCompletionModel = JSON.stringify({
            AccordionPK: dataset.accordionpk,
            AccordionTitle: dataset.accordiontitle,
            PlanPK: this._planPK,
            TrainingCompletion: nextTrainingCompletion,
            Training: dataset.training,
        })

        const response = await HttpRequestHandler.post(
            '/CharterSchool/AddPersonnelDevelopmentDateTrainingCompletion',
            'Successfully added a training completion record.',
            'There was an error adding a training completion, please try again.',
            addDateTrainingCompletionModel,
            'application/json'
        )

        const text = await response.text()

        if (response.ok && text) {
            const buttonRow = button.closest('div')

            buttonRow.insertAdjacentHTML('beforebegin', text)

            const newTrainingCompletionInputs = document.querySelectorAll(`div.personnel-development-training-completion[data-accordiontitle='${accordionTitle
                }'][data-training='${training
                }'][data-trainingcompletion='${nextTrainingCompletion
                }'] input`)

            for (const input of newTrainingCompletionInputs) this._validationHandler.forceElementRequired(input as HTMLInputElement)

            button.dataset.trainingcompletion = nextTrainingCompletion.toString()

            const newTrainingCompletionFileUploadInputs = document.querySelectorAll(`.${this._fieldset.File
                }[data-accordiontitle='${accordionTitle
                }'][data-training='${training
                }'][data-trainingcompletion='${nextTrainingCompletion}']`)
            for (const fileUpload of newTrainingCompletionFileUploadInputs) {
                this.constructFileUploadElementEventListeners(fileUpload as HTMLInputElement)
            }

            this.addDeleteTrainingCompletionButton(dataset, nextTrainingCompletion)
        }
    }

    private addDeleteTrainingCompletionButton(dataset: DOMStringMap, trainingCompletion = 0) {
        const deleteTrainingCompletionButton = document.querySelector(`.charterSchoolPersonnelDevelopmentDeleteButton[data-accordionpk='${dataset.accordionpk
            }'][data-training='${dataset.training
            }'][data-trainingcompletion='${trainingCompletion}']`) as HTMLButtonElement

        deleteTrainingCompletionButton.addEventListener('click', (e: Event) => this.deleteTrainingCompletion(deleteTrainingCompletionButton))
    }

    public async deleteTraining(deleteTrainingButton: HTMLButtonElement) {
        const dataset = deleteTrainingButton.dataset
        let count = 0;

        let personnelRows = document.querySelectorAll(`.personnel-development-training.personnel-development-row[data-accordiontitle='${dataset.accordiontitle}']`);

        for (let thisRow of personnelRows) {
            count++;
        }

        if (count > 1) {
            const training = deleteTrainingButton.closest('.personnel-development-training.personnel-development-row')
            const elementsInTraining = training.querySelectorAll(`[data-training='${dataset.training}']`) as NodeListOf<HTMLElement>

            const planPropertiesToDelete = []

            for (const element of elementsInTraining) {
                if (element.dataset.planpropertypk) {
                    planPropertiesToDelete.push(element.dataset.planpropertypk)
                }
            }

            const filesToDelete = training.querySelectorAll(`.charterSchoolFileUploadDivInner .uploadFileColumn`) as NodeListOf<HTMLDivElement>
            const planPropertyFilePKsToDelete = []
            for (const file of filesToDelete) planPropertyFilePKsToDelete.push(file.dataset.planpropertyfilepk)

            const accordion = document.querySelector(`.${this._fieldset.Accordion}[data-propertypk='${dataset.accordionpk}']`) as HTMLButtonElement

            const data = {
                PlanPropertyPKs: planPropertiesToDelete,
                PlanPropertyFilePKs: planPropertyFilePKsToDelete,
                AccordionPlanPropertyPK: accordion.dataset.planpropertypk
            }

            const response = await HttpRequestHandler.post(
                '/CharterSchool/DeletePersonnelDevelopmentTraining',
                'Successfully deleted the training.',
                'There was an error deleting this training , please try again',
                JSON.stringify(data),
                'application/json'
            )

            const text = await response.text()

            if (response.ok && text) training.remove()
        }
        else {
            //show alert to let them know to just change the data
            alert("This is the last row.  Please change your data.");
        }
    }

    public async deleteTrainingCompletion(deleteTrainingCompletionButton: HTMLButtonElement) {
        const dataset = deleteTrainingCompletionButton.dataset

        const dateFieldToDelete = document.querySelector(`.${this._fieldset.Calendar
            }[data-accordionpk='${dataset.accordionpk
            }'][data-training='${dataset.training
            }'][data-trainingcompletion='${dataset.trainingcompletion}']`) as HTMLInputElement

        const dateFieldToDeletePlanPropertyPK = [dateFieldToDelete.dataset.planpropertypk]

        const filesToDelete = document.querySelectorAll(`.charterSchoolFileUploadDivInner[data-relatedplanpropertypk='${dateFieldToDeletePlanPropertyPK}'] .uploadFileColumn`)

        const planPropertyFilePKsToDelete = []

        for (const f of filesToDelete) {
            const file = f as HTMLDivElement
            planPropertyFilePKsToDelete.push(file.dataset.planpropertyfilepk)
        }

        const accordion = document.querySelector(`.${this._fieldset.Accordion}[data-propertypk='${dataset.accordionpk}']`) as HTMLButtonElement

        const data = {
            PlanPropertyPKs: dateFieldToDeletePlanPropertyPK,
            PlanPropertyFilePKs: planPropertyFilePKsToDelete,
            AccordionPlanPropertyPK: accordion.dataset.planpropertypk
        }

        const response = await HttpRequestHandler.post(
            '/CharterSchool/DeletePersonnelDevelopmentDateTrainingCompletion',
            'Successfully deleted the training completion.',
            'There was an error deleting this training completion , please try again',
            JSON.stringify(data),
            'application/json'
        )

        const text = await response.text()

        if (response.ok && text) {
            const trainingcompletion = document.querySelector(`.personnel-development-training-completion[data-accordionpk='${dataset.accordionpk}'][data-training='${dataset.training}'][data-trainingcompletion='${dataset.trainingcompletion}']`)
            if (trainingcompletion) trainingcompletion.remove()
        }
    }


    public save(referrer: string) {
        Core.showLoader()

        let refreshPage = referrer
        let planPK = this._planPK

        if (referrer !== 'save' && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }

        if (referrer === 'continue') refreshPage = document.getElementById('continueButton').getAttribute('data-redirect-url')
        if (referrer === 'back') refreshPage = document.getElementById('backButton').getAttribute('data-redirect-url'); else refreshPage = referrer;

        const allDataToSave: ICharterSchoolPersonnelDevelopmentSaveData[] = this.getAllDataToSave()

        let xhr = new XMLHttpRequest()
        xhr.open('POST', `/CharterSchool/SaveCharterSchool${this._pageLabel}`, true)
        xhr.setRequestHeader('Content-Type', 'application/json')
        xhr.onload = () => {

            Core.hideLoader()

            if (referrer === 'save') {
                if (xhr.status === 200) {
                    this._core.pageReload(true, planPK)
                } else {
                    Core.createHTMLAlert('alertMessageDiv', 'Request failed.  Returned status of ' + xhr.status, 'error', 3000, null)
                }
            } else {
                if (xhr.status === 200 && refreshPage && refreshPage !== '') {
                    window.location.href = refreshPage
                } else {
                    Core.createHTMLAlert('alertMessageDiv', 'Request failed.  Returned status of ' + xhr.status, 'error', 3000, null)
                }
            }
        }

        if (allDataToSave.length === 0) {
            if (referrer === 'save') {
                Core.hideLoader()
                Core.createHTMLAlert('alertMessageDiv', 'Please enter a value to save first', 'warning', 3000, null)
                this._core.doValidation(this._fieldset.ValidationClasses)
            } else {
                if (refreshPage && refreshPage !== '') {
                    window.location.href = refreshPage
                } else {
                    Core.hideLoader()
                }
            }
        } else {
            xhr.send(JSON.stringify(allDataToSave))
        }
    }

    private getAllDataToSave(): ICharterSchoolPersonnelDevelopmentSaveData[] {
        const allDataToSave: ICharterSchoolPersonnelDevelopmentSaveData[] = []

        const accordions = document.getElementsByClassName(this._fieldset.Accordion) as HTMLCollectionOf<HTMLButtonElement>
        if (accordions && accordions.length) {
            for (const accordion of accordions) {
                const accordionPlanProperty = this.getPlanPropertyToSave(accordion, this._fieldset.Accordion)
                const accordionElements = this.getAccordionElements(accordion)

                if (accordionPlanProperty && accordionElements && accordionElements.length) {
                    const personnelDevelopmentSaveDatum: ICharterSchoolPersonnelDevelopmentSaveData = {
                        Accordion: accordionPlanProperty,
                        Elements: accordionElements
                    }

                    allDataToSave.push(personnelDevelopmentSaveDatum)
                }
            }
        }

        return allDataToSave
    }

    private getAccordionElements(accordion: HTMLButtonElement): ICharterSchoolPersonnelDevelopmentField[] {
        const personnelDevelopmentSaveData: ICharterSchoolPersonnelDevelopmentField[] = []

        const dataset = accordion.dataset

        for (const field of this._fieldset.SaveableClasses) {
            const accordionElements = document.querySelectorAll(`.${field}[data-accordionpk='${dataset.propertypk}']`)

            for (const e of accordionElements) {
                const element = e as HTMLElement

                const planProperty = this.getPlanPropertyToSave(element as HTMLElement, field)

                const personnelDevelopmentSaveDatum: ICharterSchoolPersonnelDevelopmentField = {
                    PlanPropertyPK: planProperty.PlanPropertyPK,
                    PlanFK: this._planPK,
                    PropertyFK: planProperty.PropertyFK,
                    TextValue: planProperty.TextValue,
                    LookupCodeFK: planProperty.LookupCodeFK,
                    RowNbr: planProperty.RowNbr,
                    IsDeletedInd: false,
                    ParentPropertyPK: parseInt(dataset.propertypk)
                }

                personnelDevelopmentSaveData.push(personnelDevelopmentSaveDatum)
            }
        }

        return personnelDevelopmentSaveData
    }

    private getPlanPropertyToSave(element: HTMLElement, field: string): IPlanProperty {
        let planProperty: IPlanProperty

        switch (field) {
            case this._fieldset.Accordion:
                planProperty = this.getAccordionPlanProperty(element)
                break

            case this._fieldset.Calendar:
            case this._fieldset.Text:
                planProperty = this.getInputPlanProperty(element)
                break
            case this._fieldset.Checkbox:
                planProperty = this.getCheckboxPlanProperty(element)
                break
        }

        return planProperty
    }

    private getAccordionPlanProperty(element: HTMLElement): IPlanProperty {
        const accordionElement = element as HTMLButtonElement
        if (accordionElement.disabled) return null

        const planProperty: IPlanProperty = {
            PlanPropertyPK: parseInt(accordionElement.dataset.planpropertypk),
            PlanFK: this._planPK,
            PropertyFK: parseInt(accordionElement.dataset.propertypk),
            TextValue: accordionElement.dataset.label,
            LookupCodeFK: null,
            RowNbr: null,
            IsDeletedInd: false
        }

        return planProperty
    }

    private getInputPlanProperty(element: HTMLElement): IPlanProperty {
        const inputElement = element as HTMLInputElement
        if (inputElement.readOnly) return null

        const planProperty: IPlanProperty = {
            PlanPropertyPK: parseInt(inputElement.dataset.planpropertypk),
            PlanFK: this._planPK,
            PropertyFK: parseInt(inputElement.dataset.propertypk),
            TextValue: inputElement.value,
            LookupCodeFK: null,
            RowNbr: parseInt(inputElement.dataset.training),
            IsDeletedInd: false
        }

        return planProperty
    }

    private getCheckboxPlanProperty(element: HTMLElement): IPlanProperty {
        const inputElement = element as HTMLInputElement

        const planProperty: IPlanProperty = {
            PlanPropertyPK: parseInt(inputElement.dataset.planpropertypk),
            PlanFK: this._planPK,
            PropertyFK: parseInt(inputElement.dataset.propertypk),
            TextValue: inputElement.checked ? 'on' : 'off',
            LookupCodeFK: null,
            RowNbr: parseInt(inputElement.dataset.training),
            IsDeletedInd: false
        }

        return planProperty
    }

    private handleNoTrainingCheckbox(checkbox: HTMLInputElement) {
        let containerToToggle = document.querySelector(`.offersTrainingContainer[data-accordionpk='${checkbox.dataset.accordionpk}']`);
        if (containerToToggle != null) {
            if (checkbox.checked) {
                containerToToggle.classList.add("hide");
                
            } else {
                containerToToggle.classList.remove("hide");
            }
        }

        for (const field of this._fieldset.SaveableClasses) {
            const accordionElements = document.querySelectorAll(`.${field}[data-accordionpk='${checkbox.dataset.accordionpk}']:not([data-propertycode='charterschoolpersonneldevelopmentdidnotoffertraining'])`)

            for (const e of accordionElements) {
                const element = e as HTMLElement;
                if (checkbox.checked) {
                    this._validationHandler.forceElementOptional(element);
                } else {
                    if("initiallyrequired" in element.dataset && element.dataset.initiallyrequired === "true") {
                        this._validationHandler.forceElementRequired(element);
                    }
                }
            }
        }

        let fileFields = containerToToggle.querySelectorAll(`.charterSchoolSpecialEducationPersonnelDevelopmentFileField`) as NodeListOf<HTMLInputElement>;
        for (const fileField of fileFields) {
            if (checkbox.checked) {
                this._validationHandler.forceElementOptional(fileField);
            } else {
                if("initiallyrequired" in fileField.dataset && fileField.dataset.initiallyrequired === "true") {
                    this._validationHandler.forceElementRequired(fileField);
                }
            }
        }
    }
}

class CharterSchoolAnnualReportSpecialEducation extends CharterSchoolPage {
    constructor(navigationCode: string, pageCode: string, pageLabel: string, planPK: number, templateFK: number, allowDuplicates: boolean = true) {
        super(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates)
    }
}

class CharterSchoolAnnualReportFacilitiesAndAgreements extends CharterSchoolPage { 
    constructor(navigationCode: string, pageCode: string, pageLabel: string, planPK: number, templateFK: number, allowDuplicates: boolean = true) {
        super(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates, false);
        let that = this;
        
        //Memorandum of understanding
        that.handleShowMOM();
        let noMOMCheckbox = document.getElementById("charterschoolfacilitiesnomom") as HTMLInputElement;
        if (noMOMCheckbox != null) {
            noMOMCheckbox.addEventListener("change", that.handleShowMOM)
        }

        //Articulation Agreements
        that.handleShowArticulationAgreements();
        let noArticulationsCheckbox = document.getElementById("charterschoolfacilitiesnoarticulationagreements") as HTMLInputElement;
        if (noArticulationsCheckbox != null) {
            noArticulationsCheckbox.addEventListener("change", that.handleShowArticulationAgreements)
        }

        if (that._fromSaveElement && that._fromSaveElement.value === 'true') that._validationHandler.validate();
    }

    private handleShowMOM = () => {
        let checkbox = document.getElementById("charterschoolfacilitiesnomom") as HTMLInputElement;

        for (const field of this._fieldset.SaveableClasses) {
            const requiredElements = document.querySelectorAll(`.${field}[data-propertygroupcodepk='89']`);

            for (const e of requiredElements) {
                const element = e as HTMLElement;
                if (checkbox.checked) {
                    this._validationHandler.forceElementOptional(element);
                } else {
                    if("initiallyrequired" in element.dataset && element.dataset.initiallyrequired === "true") {
                        this._validationHandler.forceElementRequired(element);
                    }
                }
            }
        }

        let fileFields = document.querySelectorAll(".charterschoolFacilitiesAndAgreementsFileField[data-propertygroupcodepk='212']") as NodeListOf<HTMLInputElement>;
        for (const fileField of fileFields) {
            if (checkbox.checked) {
                this._validationHandler.forceElementOptional(fileField);
            } else {
                if("initiallyrequired" in fileField.dataset && fileField.dataset.initiallyrequired === "true") {
                    this._validationHandler.forceElementRequired(fileField);
                }
            }
        }

        var sectionsToHide = document.querySelectorAll(".shared-page-table-container[data-propertygroupcodepk='89'], .property-group[data-propertygroupcodepk='212']");
        if (sectionsToHide != null) {
            if (checkbox.checked) {
                sectionsToHide.forEach(el => el.classList.add("hide"));
            } else {
                sectionsToHide.forEach(el => el.classList.remove("hide"));
            }
        }

    }

    private handleShowArticulationAgreements = () => {
        let checkbox = document.getElementById("charterschoolfacilitiesnoarticulationagreements") as HTMLInputElement;

        for (const field of this._fieldset.SaveableClasses) {
            const requiredElements = document.querySelectorAll(`.${field}[data-propertygroupcodepk='226']`);

            for (const e of requiredElements) {
                const element = e as HTMLElement;
                if (checkbox.checked) {
                    this._validationHandler.forceElementOptional(element);
                } else {
                    if("initiallyrequired" in element.dataset && element.dataset.initiallyrequired === "true") {
                        this._validationHandler.forceElementRequired(element);
                    }
                }
            }
        }

        let fileFields = document.querySelectorAll(".charterschoolFacilitiesAndAgreementsFileField[data-propertygroupcodepk='226']") as NodeListOf<HTMLInputElement>;
        for (const fileField of fileFields) {
            if (checkbox.checked) {
                this._validationHandler.forceElementOptional(fileField);
            } else {
                if("initiallyrequired" in fileField.dataset && fileField.dataset.initiallyrequired === "true") {
                    this._validationHandler.forceElementRequired(fileField);
                }
            }
        }

        var sectionToHide = document.querySelector(".property-group-section[data-propertygroupcodepk='226']");
        if (sectionToHide != null) {
            let addGroup = $(sectionToHide).next(".shared-page-button")[0];
            if (checkbox.checked) {
                sectionToHide.classList.add("hide");
                if (addGroup != null) {
                    addGroup.classList.add("hide");
                }
            } else {
                sectionToHide.classList.remove("hide");
                if (addGroup != null) {
                    addGroup.classList.remove("hide");
                }
            }
        }

    }
}

class CharterSchoolAnnualReportManagementSurvey extends CharterSchoolPage {
    constructor(navigationCode: string, pageCode: string, pageLabel: string, planPK: number, templateFK: number, allowDuplicates: boolean = true) {
        super(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates, false);
        this.forceOptionalFields()
        if (this._fromSaveElement && this._fromSaveElement.value === 'true') this._validationHandler.validate()
        this._radioButtonControlHandler.Callback = this.forceOptionalFields
    }

    private forceOptionalFields = () => {
        const address2 = document.getElementById('charterschoolmanagementsurveyaddress2') as HTMLInputElement
        const additonalComments = document.getElementById('charterschoolmanagementsurveyadditionalcomments') as HTMLTextAreaElement

        this._validationHandler.forceElementOptional(address2)
        this._validationHandler.forceElementOptional(additonalComments)
    }
}

class CharterSchoolAnnualReportSignaturesAndAffirmations extends CharterSchoolPage {
    constructor(navigationCode: string, pageCode: string, pageLabel: string, planPK: number, templateFK: number, allowDuplicates: boolean = true) {
        super(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates)

        const calendar = document.getElementById('charterschoolsignaturesaffirmationsdateofapproval')
        const row = calendar.closest('.charterschool-content-row')
        row.insertAdjacentHTML('afterend', '<a href="/documents/affirmation_statement_charterschool.docx" target="_blank" aria-label="Download the Board Affirmation Document"><i class="fas fa-file-download"></i>&nbsp; Download Board Affirmation Statement</a>')

        this.constructEventListeners()
    }

    private constructEventListeners() {
        this.constructFileUploadSuccessEventListener()
        // this.checkForSignoff()
    }

    private constructFileUploadSuccessEventListener() {
        this._fileHandler.uploadFile = null
        const fileUploadElement = document.querySelector(`.${this._fieldset.File}`) as HTMLInputElement
        if (fileUploadElement) fileUploadElement.addEventListener('change', (e: Event) => this.uploadFile(fileUploadElement))
    }

    private async uploadFile(fileUploadElement) {
        const uploadFileForm = fileUploadElement.parentElement as HTMLFormElement
        const formData = new FormData(uploadFileForm)

        const planFK = document.querySelector('main').dataset.planfk
        formData.append('planFK', planFK)

        const response = await HttpRequestHandler.post(
            `/${this._navigationCode}/UploadFile/`,
            'File successfully uploaded!',
            'There was an error uploading your file, please try again.',
            formData
        )

        const json = await response.json()

        if (json.success) this._core.pageReload(false, this._planPK)
    }
}

class CharterSchoolAnnualReportSummaryChecklistAndSubmission {
    private _core: Core;
    _lateEmailPlanFK: number;
    _lateEmailModal: Modal;

    constructor() {
        let that = this;
        this._core = new Core()
        that._lateEmailModal = new Modal("sendLateEmailModal", "sendLateEmail");

        let backButton = document.getElementById('backButton')
        if (backButton !== null) backButton.addEventListener('click', (e: Event) => this.back(e))

        let submitButton = document.getElementById('submitPlanButton')
        if (submitButton !== null) submitButton.addEventListener('click', (e: Event) => this.submit(e))

        let sendLateEmailButton = document.querySelector('#sendLateEmail[data-plan-fk]') as HTMLButtonElement;
        if (sendLateEmailButton != null) sendLateEmailButton.addEventListener('click', () => {
            that._lateEmailPlanFK = parseInt(sendLateEmailButton.dataset.planFk);
            that._lateEmailModal.show();
        });

        let sendLateEmailConfirmButton = document.querySelector('#sendLateEmailConfirm') as HTMLButtonElement;
        if (sendLateEmailConfirmButton != null) sendLateEmailConfirmButton.addEventListener('click', () => this.sendLateEmail())

        let sendLateEmailDueDate = document.getElementById('sendLateEmailNewDate');
        if (sendLateEmailDueDate != null) {
            sendLateEmailDueDate.addEventListener('change', () => {
                if (sendLateEmailConfirmButton != null) {
                    sendLateEmailConfirmButton.disabled = false;
                }
            });
        }

        this._core.leftnav(this)
    }

    back(e: Event) {
        let newPage = document.getElementById('backButton').getAttribute('data-redirect-url')
        window.location.href = newPage
    }

    getCore() {
        return this._core
    }

    save(referrer) {
        let refreshPage = ''

        if (referrer === 'continue') {
            refreshPage = document.getElementById('continueButton').getAttribute('data-redirect-url')
        }
        if (referrer === 'back') {
            refreshPage = document.getElementById('backButton').getAttribute('data-redirect-url')
        }
        else {
            refreshPage = referrer
        }

        if (refreshPage && refreshPage !== '') {
            window.location.href = refreshPage
        } else {
            Core.hideLoader()
        }
    }

    submit(e: Event) {
        let element = e.srcElement as HTMLButtonElement

        if (!element.classList.contains('disabled')) {
            Core.showLoader()

            let planFK = parseInt(element.dataset.planfk)

            let xhr = new XMLHttpRequest()

            xhr.open('POST', '/CharterSchool/SubmitPlan', true)
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded')
            xhr.onload = () => {
                xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true
                    ? Core.createHTMLAlert('alertMessageDiv', 'This report has been submitted for review!', 'success', 3000, window.location.reload())
                    : Core.createHTMLAlert('alertMessageDiv', 'An unexpected error occurred while submitting report. Please try again later.', 'error', 3000, null)

                Core.hideLoader()
            }

            xhr.send('planFK=' + planFK)
        }
    }

    sendLateEmail() {
        let that = this;
        Core.showLoader();
        let sendLateEmailDueDate = document.getElementById('sendLateEmailNewDate') as HTMLInputElement;

        let xhr = new XMLHttpRequest()
        xhr.open('POST', '/CharterSchool/SendLateEmail', true)
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded')
        xhr.onload = () => {
            xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true
                ? Core.createHTMLAlert('alertMessageDiv', 'Successfully sent late email', 'success', 3000, null)
                : Core.createHTMLAlert('alertMessageDiv', 'There was an unexpected error sending late email', 'error', 3000, null)

            Core.hideLoader();
            that._lateEmailModal.hide();

            let sendLateEmailButton = document.querySelector('#sendLateEmail[data-plan-fk]') as HTMLButtonElement;
            if (sendLateEmailButton != null) {
                sendLateEmailButton.disabled = true;
            }
        }

        xhr.send(`planFK=${that._lateEmailPlanFK}&newDueDateStr=${sendLateEmailDueDate.value}`);
    }
}

class CharterSchoolAccordion {
    constructor(accordionId: string) {
        const accordion = document.getElementById(accordionId)
        const accordionButton = accordion as HTMLButtonElement
        accordionButton.setAttribute('data-hasclick', 'true')
        accordionButton.addEventListener('click', (e: Event) => this.toggle(accordion.id))
    }

    public toggle(id: string) {
        const element = document.getElementById(id)
        const contentId = element.getAttribute('aria-controls')
        const contentElement = document.getElementById(contentId)
        contentElement.classList.contains('open')
            ? this.hide(element, contentElement)
            : this.show(element, contentElement)
    }

    private show(element: HTMLElement, contentElement: HTMLElement) {
        element.classList.add('open')
        element.setAttribute('aria-expanded', 'true')
        contentElement.classList.add('open')
    }

    private hide(element: HTMLElement, contentElement: HTMLElement) {
        element.classList.remove('open')
        element.setAttribute('aria-expanded', 'false')
        contentElement.classList.remove('open')
    }
}
