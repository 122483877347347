/// <reference path="core.ts" />
/// <reference path="interfaces/ready/interfaces.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />

//Profile and Plan Essentials
class ProfileAndPlanEssentials {

    validationClasses: string[];
    private _core: Core;
    constructor() {
        this._core = new Core(); 
        this.validationClasses = ["ProfiePlanEssentials"];
        let saveButtonElement = document.getElementById("profileAndPlanSave");
        if (saveButtonElement !== null)
            saveButtonElement.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }

        let executiveSaveButton = document.getElementById("ExecutiveSave");
        if (executiveSaveButton !== null)
            executiveSaveButton.addEventListener("click", (e: Event) => this.executiveSave("save"));

        let controls = new Array("leftBarprofileplanessentials");

        this._core.activateMenu(controls, "ready");
        this._core.initializeRequiredFields(this.validationClasses);
        this._core.activateSubMenu("subNavReadySetGo");
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let refreshButton = document.getElementById("resetProfile");
        if (refreshButton !== null)
            refreshButton.addEventListener("click", (e: Event) => Core.refreshProfile("profilePlanEssentialsData"));
    }

    getCore() {
        return this._core;
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
                window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planElement = document.getElementById("profilePlanEssentialsData");
        let planPK = planElement.dataset.planpk;
        let allElements = document.getElementsByClassName("ProfiePlanEssentials");
        for (let element of allElements) {
            let htmlElement = <HTMLInputElement>element;
            let planPropertyPK: number = 0;

            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }

            let saveItem: IPlanProperty = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: parseInt(planPK),
                PropertyFK: parseInt(htmlElement.dataset.propertypk),
                TextValue: htmlElement.value,
                LookupCodeFK: null,
                RowNbr: 0,
                IsDeletedInd: false
            };

            allSaveElements.push(saveItem);
        } 

        let processed = JSON.stringify(allSaveElements[0]);

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Ready/SaveProfilePlanEssentials', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    core.pageReload(true, parseInt(planPK));
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    executiveSave(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];

        let planPK = 0;

        let profileFormElement = <HTMLDivElement>document.getElementById("profilePlanEssentialsData");
        let inputs = document.getElementsByClassName("profileICDataSave");

        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Ready/SaveProfilePlanEssentials', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
} 

//Steering Committee
class SteeringCommittee {

    validationClasses: string[];
    private _core: Core;
    private templateFK: number;
    private planPK: number;

    constructor() {
        this._core = new Core();

        let steeringCommitteeTable = document.getElementById("steeringCommittee");
        this.planPK = parseInt(steeringCommitteeTable.dataset.planpk);
        this.templateFK = parseInt(steeringCommitteeTable.dataset.templatefk);

        this.validationClasses = ["steeringCommitteeField"];

        let steeringCommitteeAddRows = document.getElementById("steeringCommitteeAddRows");
        if (steeringCommitteeAddRows !== null)
            steeringCommitteeAddRows.addEventListener("click", (e: Event) => this.steeringCommitteeAddRows());

        let steeringCommitteeSave = document.getElementById("saveCommitteeMembers");
        if (steeringCommitteeSave !== null)
            steeringCommitteeSave.addEventListener("click", (e: Event) => this.save("save"));

        let steeringCommitteeExportExcel = document.getElementById("exportExcelCommitteeMembers");
        if (steeringCommitteeExportExcel !== null)
            steeringCommitteeExportExcel.addEventListener("click", (e: Event) => this.exportExcel());

        let wordPluralizer = document.getElementById("steeringCommitteeAddRowsNumber");
        if (wordPluralizer !== null)
            wordPluralizer.addEventListener("change", (e: Event) => this.checkForPlural());

        var rows = document.getElementsByClassName("removeSteeringCommitteeRow");

        for (let row of rows) {
            row.addEventListener("click", (e: Event) => this.removeSteeringCommitteeRow(e));
        }

        this._core.leftnav(this);

        this.initializeRequiredFieldsCustom();

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        let IsCompleteElement = <HTMLInputElement>document.getElementById("isComplete");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        
        this._core.activateSubMenu("subNavReadySetGo");
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let exportWord = document.getElementById("exportSteeringCommitteeWord");
        if (exportWord !== null) {
            exportWord.addEventListener("click", (e: Event) => this._core.exportDocx(e, "SteeringCommitteeDataExport", "steeringcommittee"));
        }
    }

    getCore() {
        return this._core;
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    save(referrer) {
        let core = this._core;
        if (this._core.checkSave(this) === false && referrer !== "save") {
                window.location.href = referrer;
            return false;
        }
        Core.showLoader();

        let steeringCommitteeTable = document.getElementById("steeringCommittee");
        let steeringCommitteeTableRows = steeringCommitteeTable.querySelectorAll("tr[data-rownumber]");
        let allSaveElements = [];
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let planPK = 0;
        for (let row of steeringCommitteeTableRows)
        {
            let rowNumber = parseInt((<HTMLTableRowElement>row).dataset.rownumber);

            let elements = row.querySelectorAll(".steeringCommitteeField");

            for (let element of elements) {
                let planPropertyPK = 0;
                planPK = parseInt(steeringCommitteeTable.dataset.planpk);

                if(element instanceof HTMLSelectElement)
                {
                    let htmlElement = <HTMLSelectElement>element;
                    let hadValue = false;
                    let planPropertyPK: number = 0;

                    if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                        planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                        hadValue = true;
                    }

                    //Note that even though this is a select, the value is being stored as TextValue NOT LookupCodeFK
                    if(element.value != null || hadValue) {
                        let saveItem: IPlanProperty = {
                            PlanPropertyPK: planPropertyPK,
                            PlanFK: planPK,
                            PropertyFK: parseInt(htmlElement.dataset.propertypk),
                            TextValue: htmlElement.value,
                            LookupCodeFK: null,
                            RowNbr: rowNumber,
                            IsDeletedInd: false
                        };

                        allSaveElements.push(saveItem);
                    }

                } else if(element instanceof HTMLInputElement) {
                    let htmlElement = <HTMLInputElement>element;
                    let hadValue = false;
                    if (htmlElement.dataset.planpropertypk !== "" && htmlElement.dataset.planpropertypk !== "0") {
                        planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                        hadValue = true;
                    }

                    if (element.value !== "" || hadValue) {
                        let saveItem: IPlanProperty = {
                            PlanPropertyPK: planPropertyPK,
                            PlanFK: planPK,
                            PropertyFK:  parseInt(htmlElement.dataset.propertypk),
                            TextValue: htmlElement.value,
                            LookupCodeFK: null,
                            RowNbr: rowNumber,
                            IsDeletedInd: false
                        };

                        allSaveElements.push(saveItem);
                    }
                }
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Ready/SaveSteeringCommittee', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    steeringCommitteeAddRows() {
        let core = this._core;
        let value = (<HTMLInputElement>document.getElementById("steeringCommitteeAddRowsNumber"));
        let _that = this;

        if (value.value === "") {
            Core.createHTMLAlert("alertMessageDiv", "Please specify the number of rows to add", 'error', 3000, null);
        } else { 
            let rows = parseInt(value.value); 
            let row = 0;
            let table = (<HTMLTableElement>document.getElementById("steeringCommittee"));
            let trs = table.rows;
            for (let tr of trs) {
                if (parseInt(tr.getAttribute("data-rownumber")) > row)
                    row = parseInt(tr.getAttribute("data-rownumber"));
            }

            row++;

            for (let i = 0; i < rows; i++) {

                let xhr = new XMLHttpRequest();
                xhr.open('POST', '/Ready/SteeringCommitteeRow', true);
                xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        Core.hideLoader();
                        $("#steeringCommittee tbody").append(xhr.responseText);
                        value.value = "";

                        let rows = document.getElementsByClassName("removeSteeringCommitteeRow");
                        let row = rows[rows.length - 1];

                        row.addEventListener("click", (e: Event) => _that.removeSteeringCommitteeRow(e));
                        Core.createHTMLAlert("alertMessageDiv", "New Steering Committee Row Added", 'success', 3000, null);
                    }
                    else {
                        Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                    }
                };
                xhr.send("templateFK="+_that.templateFK+"&row=" + row);
                row++;
            }
        }
    } 

    removeSteeringCommitteeRow(e: Event) {
        let core = this._core;
        let button = <HTMLButtonElement>e.currentTarget;
        let parent = button.parentElement.parentElement; //To the TR
        let rowNumber = parent.dataset.rownumber;
        let hasData = button.dataset.hasdata;
        let planPK = 0;
        let allRemoveElements = [];

        if (hasData !== "") {

            planPK = parseInt(document.getElementById("steeringCommittee").dataset.planpk);

            let elements = parent.querySelectorAll(".steeringCommitteeField");

            for (let element of elements) {

                let planPropertyPK = 0;
                let hadValue = false;

                if (element instanceof HTMLSelectElement) {

                    let htmlElement = <HTMLSelectElement>element;

                    if (htmlElement.dataset.planpropertypk !== "" && htmlElement.dataset.planpropertypk !== "0") {
                        planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                        hadValue = true;
                    }

                    //Note that even though this is a select, the value is being stored as TextValue NOT LookupCodeFK
                    if (htmlElement.selectedIndex > 0 || hadValue) {

                        let removeItem: IPlanProperty = {
                            PlanPropertyPK: planPropertyPK,
                            PlanFK: planPK,
                            PropertyFK: parseInt(element.dataset.propertypk),
                            TextValue: htmlElement.value,
                            LookupCodeFK: null,
                            RowNbr: parseInt(rowNumber),
                            IsDeletedInd: true
                        };

                        allRemoveElements.push(removeItem);

                    }   

                } else if (element instanceof HTMLInputElement) {
                    let htmlElement = <HTMLInputElement>element;

                    if (htmlElement.dataset.planpropertypk !== "" && htmlElement.dataset.planpropertypk !== "0") {
                        planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                        hadValue = true;
                    }

                    if (htmlElement.value !== "" || hadValue) {

                        let removeItem: IPlanProperty = {
                            PlanPropertyPK: planPropertyPK,
                            PlanFK: planPK,
                            PropertyFK: parseInt(element.dataset.propertypk),
                            TextValue: htmlElement.value,
                            LookupCodeFK: null,
                            RowNbr: parseInt(rowNumber),
                            IsDeletedInd: true
                        };

                        allRemoveElements.push(removeItem);

                    }   
                }
                
            }

            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/Ready/SaveSteeringCommittee', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    //window.location.reload();
                    Core.createHTMLAlert("alertMessageDiv", "Steering Committee Row Successfully Removed", 'success', 3000, core.pageReload);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send(JSON.stringify(allRemoveElements));
        }

        parent.parentElement.removeChild(parent);
        Core.createHTMLAlert("alertMessageDiv", "Steering Committee Row Successfully Removed", 'success', 3000, null);
    }

    checkForPlural(): void {
        let word = <HTMLSpanElement>document.getElementById("rowWord");
        let num = <HTMLInputElement>document.getElementById("steeringCommitteeAddRowsNumber")
        let newWord = "rows";
        if (parseInt(num.value) === 1) {
            newWord = "row";
        }

        word.textContent = newWord;
    }

    exportExcel() {
        let core = this._core;
        Core.showLoader();

        let steeringCommitteeTable = document.getElementById("steeringCommittee");
        let planPK = 0;
        planPK = parseInt(steeringCommitteeTable.dataset.planpk);
        window.open("/ExportExcel/SteeringCommitteeDataExport?id=" + planPK);
        setTimeout(function () {
            Core.hideLoader();
        }, 5000);             
    }

    initializeRequiredFieldsCustom() {

        //If any field in a row is filled out, force rest of row required (except email)
        let steeringCommitteeTable = document.getElementById("steeringCommittee");
        let steeringCommitteeTableRows = steeringCommitteeTable.querySelectorAll("tr[data-rownumber]");
        for (let row of steeringCommitteeTableRows) {
            let elements = row.getElementsByClassName("steeringCommitteeField") as HTMLCollectionOf<HTMLElement>;

            for (let element of elements) {

                if (element instanceof HTMLSelectElement) {
                    let htmlElement = <HTMLSelectElement>element;
                    if(element.selectedIndex > 0) {
                        for (let otherElement of elements) {
                            if (otherElement.dataset.propertycode != "steeringCommitteeEmail") {
                                otherElement.dataset.forcerequired = "true";
                            }
                        }
                        break;
                    }
                } else if (element instanceof HTMLInputElement) {
                    let htmlElement = <HTMLInputElement>element;
                    if(element.value != "") {
                        for (let otherElement of elements) {
                            if (otherElement.dataset.propertycode != "steeringCommitteeEmail") {
                                otherElement.dataset.forcerequired = "true";
                            }
                        }
                        break;
                    }
                }
            }
        }

        this._core.initializeRequiredFields(this.validationClasses);

    }
}

var tinymce: any;

//LEAProfile
class LEAProfile {

    validationClasses: string[];
    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["leaProfileField"];

        let saveButton = document.getElementById("saveLEAProfile");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e: Event) => this.save("save"));

        //let continueButton = document.getElementById("continueButton");
        //if (continueButton !== null)
        //    continueButton.addEventListener("click", (e: Event) => this.save("continue"));

        //let backButton = document.getElementById("backButton");
        //if (backButton !== null)
        //    backButton.addEventListener("click", (e: Event) => this.save("back"));

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.leftnav(this);
        this._core.initializeRequiredFields(this.validationClasses);
        this._core.activateSubMenu("subNavReadySetGo");
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let exportWord = document.getElementById("exportLEAProfileWord");
        if (exportWord !== null) {
            let fileName = "export";
            let heading = document.querySelector("h1");
            if (heading != null) {
                fileName = heading.innerText;
            }
            exportWord.addEventListener("click", (e: Event) => this._core.exportDocx(e, "LEAProfileDataExport", fileName));
        }
    }

    getCore() {
        return this._core;
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    save(referrer) {
        let core = this._core;
        if (this._core.checkSave(this) === false && referrer !== "save") {
                window.location.href = referrer;
            return false;
        }
        Core.showLoader();

        let htmlElement = <HTMLTextAreaElement>document.getElementById("leaProfileDescription");
        let planPropertyPK = htmlElement.dataset.planpropertypk;
        let planPK = htmlElement.dataset.planpk;
        let propertyPK = htmlElement.dataset.propertypk;
        let value = tinymce.get('leaProfileDescription').getContent();

        var allSaveElements = [];
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }

        if (value !== "") {
            let saveItem: IPlanProperty = {
                PlanPropertyPK: parseInt(planPropertyPK),
                PlanFK: parseInt(planPK),
                PropertyFK: parseInt(htmlElement.dataset.propertypk),
                TextValue: value,
                LookupCodeFK: null,
                RowNbr: 0,
                IsDeletedInd: false
            };

            allSaveElements.push(saveItem);
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Ready/SaveLEAProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, parseInt(planPK))
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

//MissionAndVision
class MissionAndVision {

    validationClasses: string[];
    private _core: Core;
    constructor() {
        this._core = new Core();
        this.validationClasses = ["missionAndVisionData"];
        let saveButton = document.getElementById("missionAndVisionSave");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e: Event) => this.save("save"));

        //let continueButton = document.getElementById("continueButton");
        //if (continueButton !== null)
        //    continueButton.addEventListener("click", (e: Event) => this.save("continue"));

        //let backButton = document.getElementById("backButton");
        //if (backButton !== null)
        //    backButton.addEventListener("click", (e: Event) => this.save("back"));

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.leftnav(this);
        this._core.initializeRequiredFields(this.validationClasses);
        this._core.activateSubMenu("subNavReadySetGo");
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let exportWord = document.getElementById("exportMissionVisionWord");
        if (exportWord !== null) {
            let fileName = "export";
            let heading = document.querySelector("h1");
            if (heading != null) {
                fileName = heading.innerText;
            }
            exportWord.addEventListener("click", (e: Event) => this._core.exportDocx(e, "MissionVisionDataExport", fileName));
        }
    }

    getCore() {
        return this._core;
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    save(referrer) {
        let core = this._core;
        if (this._core.checkSave(this) === false && referrer !== "save") {
                window.location.href = referrer;
            return false;
        }
        Core.showLoader();

        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planElement = document.getElementById("missionAndVisionForm");
        let planPk = planElement.dataset.planpk;
        let allElements = document.getElementsByClassName("missionAndVisionData");
        for (let element of allElements) {
            let htmlElement = <HTMLInputElement>element;
            let planPropertyPK: number = 0;

            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }

            if (htmlElement.value !== "" || planPropertyPK > 0) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: parseInt(planPk),
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: htmlElement.value,
                    LookupCodeFK: null,
                    RowNbr: 0,
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let processed = JSON.stringify(allSaveElements[0]);

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Ready/SaveMissionAndVision', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, parseInt(planPk))
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

//EducationalValues
class EducationalValues {
    validationClasses: string[];
    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["educationalValuesDataElement"];

        let saveButton = document.getElementById("educationalValuesSave");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e: Event) => this.save("save"));

        //let continueButton = document.getElementById("continueButton");
        //if (continueButton !== null)
        //    continueButton.addEventListener("click", (e: Event) => this.save("continue"));

        //let backButton = document.getElementById("backButton");
        //if (backButton !== null)
        //    backButton.addEventListener("click", (e: Event) => this.save("back"));

        let otherOptional = document.getElementById("educationValuesOtherOptionalCheckbox");
        if (otherOptional !== null)
            otherOptional.addEventListener("click", (e: Event) => this.noPrograms("educationValuesOtherOptional", "educationValuesOtherOptionalCheckbox"));

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.leftnav(this);
        this._core.initializeRequiredFields(this.validationClasses);
        this._core.activateSubMenu("subNavReadySetGo");
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let exportWord = document.getElementById("exportEducationalValuesWord");
        if (exportWord !== null) {
            let fileName = "export";
            let heading = document.querySelector("h1");
            if (heading != null) {
                fileName = heading.innerText;
            }
            exportWord.addEventListener("click", (e: Event) => this._core.exportDocx(e, "EducationalValuesDataExport", fileName));
        }
    }

    getCore() {
        return this._core;
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    noPrograms(textarea: string, checkboxName: string) {
        let textArea = (<HTMLInputElement>document.getElementById(textarea));
        let checkbox = <HTMLInputElement>document.getElementById(checkboxName);

        if (checkbox.readOnly) {
            if (checkbox.checked)
                checkbox.checked = false;
            else
                checkbox.checked = true;

            return false;
        } else {
            if (checkbox.checked) {
                textArea.value = " ";
                textArea.readOnly = true;
            }
            else {
                textArea.readOnly = false;
                textArea.value = "";
            }
        }
    }

    checkForCheckbox() {
            let textArea = (<HTMLInputElement>document.getElementById("educationValuesOtherOptional"));
            let checkbox = <HTMLInputElement>document.getElementById("educationValuesOtherOptionalCheckbox");
            if (checkbox.checked) {
                    textArea.readOnly = true;
            }
    }

    save(referrer) {
        let core = this._core;
        if (this._core.checkSave(this) === false && referrer !== "save") {
                window.location.href = referrer;
            return false;
        }
        Core.showLoader();

        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planElement = document.getElementById("educationalValuesForm");
        let planPk = planElement.dataset.planpk;
        let allElements = document.getElementsByClassName("educationalValuesDataElement");
        for (let element of allElements) {
            let htmlElement = <HTMLInputElement>element;
            let planPropertyPK: number = 0;

            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }

            if (htmlElement.value !== "" || planPropertyPK > 0) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: parseInt(planPk),
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: htmlElement.value,
                    LookupCodeFK: null,
                    RowNbr: 0,
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        allElements = document.getElementsByClassName("educationalValuesCheckElement");
        for (let element of allElements) {
            let htmlElement = <HTMLInputElement>element;
            let planPropertyPK: number = 0;
            let isChecked = "off";

            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }

            if (htmlElement.checked) {
                isChecked = "on";
            }
            let saveItem: IPlanProperty = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: parseInt(planPk),
                PropertyFK: parseInt(htmlElement.dataset.propertypk),
                TextValue: isChecked,
                LookupCodeFK: null,
                RowNbr: 0,
                IsDeletedInd: false
            };

            allSaveElements.push(saveItem);
        }

        let processed = JSON.stringify(allSaveElements[0]);

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Ready/SaveEducationalValues', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, parseInt(planPk));
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage; 
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}