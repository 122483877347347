/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />

class Page {
    protected _fileHandler: FileHandler
    protected _groupHandler: GroupHandler
    protected _radioButtonControlHandler: RadioButtonControlHandler
    protected _checkboxControlHandler: CheckboxControlHandler
    protected _saveHandler: SaveHandler
    protected _validationHandler: ValidationHandler
    protected _exportHandler: ExportHandler
    protected _pageSectionInfoHandler: PageSectionInfoHandler

    protected _core: Core = new Core()
    protected _fieldset: Fieldset
    protected _navigationCode: string
    protected _pageCode: string
    protected _pageLabel: string
    protected _planPK: number
    protected _templateFK: number
    
    protected _fromSaveElement: HTMLInputElement

    constructor(
        navigationCode: string,
        pageCode: string,
        pageLabel: string,
        planPK: number,
        templateFK: number,
        allowDuplicates: boolean = false,
        customValidationFields: string[] = null
    ) {
        this._navigationCode = navigationCode
        this._pageCode = pageCode
        this._pageLabel = pageLabel
        this._planPK = planPK        
        this._templateFK = templateFK
                
        this._fieldset = new Fieldset(this._navigationCode, this._pageLabel)
        this._fileHandler = new FileHandler(this._fieldset.File, this._navigationCode, this._pageCode, this._planPK)
        this._saveHandler = new SaveHandler(this._pageCode, this._planPK, this._pageLabel, this._fieldset, this._navigationCode)
        this._validationHandler = new ValidationHandler(this._fieldset, customValidationFields)
        this._groupHandler = new GroupHandler(this._core, this._navigationCode, this._pageCode, this._planPK, this._templateFK, this._validationHandler)
        this._radioButtonControlHandler = new RadioButtonControlHandler(this._core, this._validationHandler)
        this._checkboxControlHandler = new CheckboxControlHandler(this._core, this._validationHandler)
        this._exportHandler = new ExportHandler(this._core, this._planPK, this._templateFK);
        this._pageSectionInfoHandler = new PageSectionInfoHandler();

        this._validationHandler.initializeRequiredFields()
        this._fromSaveElement = document.getElementById('fromSave') as HTMLInputElement

        const hiddenInput = document.getElementById('hashValue') as HTMLInputElement
        if (hiddenInput) hiddenInput.value = this._saveHandler.createHash()
    }

    public getCore() {
        return this._core
    }
}

class Fieldset {
    Accordion: string
    Button: string
    Calendar: string
    Checkbox: string
    File: string
    MultiSelect: string
    MultiSelectOption: string
    Radio: string
    Select: string
    Text: string
    Textarea: string

    ValidationClasses: string[]
    SaveableClasses: string[]

    constructor(navigationCode: string, pageLabel: string) {
        this.Accordion = `${navigationCode + pageLabel}AccordionField`
        this.Button = `${navigationCode + pageLabel}Button`
        this.Calendar = `${navigationCode + pageLabel}DateField`
        this.Checkbox = `${navigationCode + pageLabel}CheckboxField`
        this.File = `${navigationCode + pageLabel}FileField`
        this.MultiSelect = `${navigationCode + pageLabel}MultiSelectField`
        this.MultiSelectOption = `${navigationCode + pageLabel}MultiSelectOptionField`
        this.Radio = `${navigationCode + pageLabel}RadioField`
        this.Select = `${navigationCode + pageLabel}SelectField`
        this.Text = `${navigationCode + pageLabel}TextField`
        this.Textarea = `${navigationCode + pageLabel}TextAreaField`

        this.ValidationClasses = [
            this.Calendar,
            this.Checkbox,
            this.File,
            this.MultiSelect,
            this.Radio,
            this.Select,
            this.Text,
            this.Textarea
        ]

        this.SaveableClasses = [
            this.Calendar,
            this.Checkbox,
            this.MultiSelectOption,
            this.Radio,
            this.Select,
            this.Text,
            this.Textarea
        ]
    }
}

class SharedPageAccordion {
    constructor(accordionId: string) {
        const accordion = document.getElementById(accordionId)
        const accordionButton = accordion as HTMLButtonElement
        accordionButton.setAttribute('data-hasclick', 'true')
        accordionButton.addEventListener('click', (e: Event) => this.toggle(accordion.id))
    }

    public toggle(id: string) {
        const element = document.getElementById(id)
        const contentId = element.getAttribute('aria-controls')
        const contentElement = document.getElementById(contentId)
        contentElement.classList.contains('open')
            ? this.hide(element, contentElement)
            : this.show(element, contentElement)
    }

    private show(element: HTMLElement, contentElement: HTMLElement) {
        element.classList.add('open')
        element.setAttribute('aria-expanded', 'true')
        contentElement.classList.add('open')
    }

    private hide(element: HTMLElement, contentElement: HTMLElement) {
        element.classList.remove('open')
        element.setAttribute('aria-expanded', 'false')
        contentElement.classList.remove('open')
    }
}

class HttpRequestHandler {
    public static async post(url: string, successMessage: string, errorMessage: string, data: any, contentType?: string) : Promise<Response> {
        Core.showLoader()

        let message = successMessage
        let alertType = 'success'

        let request: any = {
            method: 'POST',
            credentials: 'include',
            body: data
        }

        if (contentType) request.headers = { 'Content-Type': contentType }

        let response: Response

        try {
            response = await fetch(url, request)
        } catch {
            message = errorMessage
            alertType = 'error'
        }

        if (!response.ok) {
            message = errorMessage
            alertType = 'error'
        }
        
        Core.createHTMLAlert('alertMessageDiv', message, alertType, 3000, null)
        Core.hideLoader()
        return response
    }

    public static async get(url: string, data: any) : Promise<Response> {
        Core.showLoader

        const parameters = new URLSearchParams(data)
        const response = await fetch(`${url}?${parameters}`, { credentials: 'include' })

        Core.hideLoader()
        return response
    }
}

class SaveHandler {
    private _core = new Core()

    private _planPK: number
    private _pageCode: string
    private _pageLabel: string
    private _fieldset: Fieldset
    private _navigationCode: string    

    private readonly GROUP = 4
    private readonly TABLE = 2

    public SkipElements: string[] = []
    public customSave: Function

    constructor(pageCode: string, planPK: number, pageLabel: string, fieldset: Fieldset, navigationCode: string) {
        this._planPK = planPK
        this._pageCode = pageCode
        this._pageLabel = pageLabel
        this._fieldset = fieldset
        this._navigationCode = navigationCode

        this.constructEventListeners()
    }

    /****************************
     ****** Public Methods ******
     ****************************/

    public createHash() {
        return this._core.createHash(this._fieldset.SaveableClasses)
    }

    public async save(referrer: string): Promise<boolean> {
        Core.showLoader()

        if(this._core.Workflows.length > 0) {
            await Workflow.SaveAllWorkflows(this._core.Workflows);
        }

        const refreshPage = this.setRefreshPage(referrer)

        if (referrer !== 'save' && this._core.checkSave(this) === false) {
            window.location.href = refreshPage
            return false
        }

        const planPropertiesToSave: IPlanProperty[] = this.getAllPlanPropertiesToSave()

        return this.sendXMLHttpRequest(
            JSON.stringify({
                PlanPK: this._planPK,
                PageCode: this._pageCode,
                PlanPropertyModels: planPropertiesToSave
            }),
            `/${this._navigationCode}/save${this._navigationCode}page/`,
            referrer,
            refreshPage
        )
    }

    public saveExecutiveFieldsForPDEPlanWriters(propertyGroupCodes: string[]) {
        const planPropertiesToSave: IPlanProperty[] = []
        for (const propertyGroupCode of propertyGroupCodes) {
            planPropertiesToSave.push(...this.getAllPlanPropertiesToSave(propertyGroupCode))
        }

        return this.sendXMLHttpRequest(
            JSON.stringify({
                PlanPK: this._planPK,
                PageCode: this._pageCode,
                PlanPropertyModels: planPropertiesToSave
            }),
            `/${this._navigationCode}/save${this._navigationCode}page/`,
            'save',
            null
        )
    }

    public sendXMLHttpRequest(data: string, url: string, referrer: string = 'save', refreshPage: string = null, noReload: boolean = false): boolean {
        const xhr = new XMLHttpRequest()

        xhr.open(
            'POST',
            url,
            true
        )
        xhr.setRequestHeader(
            'Content-Type',
            'application/json'
        )

        xhr.onload = () => {
            Core.hideLoader()

            if (xhr.status === 200) {
                if (noReload) {
                    return true
                }
                if (referrer === 'save') {
                    this._core.pageReload(true, this._planPK)
                    return true
                }
                if (refreshPage) {
                    window.location.href = refreshPage
                    return true
                }
            }

            Core.createHTMLAlert(
                'alertMessageDiv',
                `Request failed.  Returned status of ${xhr.status}`,
                'error',
                3000,
                null
            )

            return false
        }

        xhr.send(data)
        return false
    }

    public async newsave(referrer: string) {
        if (!(referrer === 'save' || this._core.checkSave(this))) {
            window.location.href = referrer
            return false
        }

        const refreshPage: string = this.setRefreshPage(referrer)
        const planPropertiesToSave: IPlanProperty[] = this.getAllPlanPropertiesToSave()

        if (!planPropertiesToSave.length) {
            if (referrer === 'save') {
                Core.createHTMLAlert('alertMessageDiv', 'Please enter a value to save first', 'warning', 3000, null)
                this._core.doValidation(this._fieldset.ValidationClasses)
            } else if (refreshPage) {
                window.location.href = refreshPage
            }
            return
        }

        const response = await HttpRequestHandler.post(
            `/${this._navigationCode}/save${this._navigationCode}page/`,
            'Successfully saved the page.',
            'Could not save the page.',
            JSON.stringify({
                PlanPK: this._planPK,
                PageCode: this._pageCode,
                PlanPropertyModels: planPropertiesToSave
            }),
            'application/json'
        )

        const json = await response.json()

        if (json.success) {
            if (referrer === 'save') {
                console.log('a')
                this._core.pageReload(true, this._planPK)

            }
            if (refreshPage) {
                console.log('b')
                window.location.href = refreshPage
            }
        } else {
            Core.createHTMLAlert('alertMessageDiv', 'Request failed.  Returned status of ' + response.status, 'error', 3000, null)
        }
    }

    public setRefreshPage(referrer: string): string {
        let refreshPage: string = referrer

        if (referrer === 'continue') {
            refreshPage = document.getElementById('continueButton').getAttribute('data-redirect-url')
        }

        if (referrer === 'back') {
            refreshPage = document.getElementById('backButton').getAttribute('data-redirect-url')
        } 

        return refreshPage
    }

    public getRowNumber(element: HTMLElement): number {
        let rowNumber: number

        if (parseInt(element.dataset.propertygrouptypecode) === this.GROUP) {
            const container = element.closest('.property-group') as HTMLElement
            rowNumber = parseInt(container.dataset.row)
        }

        if (parseInt(element.dataset.propertygrouptypecode) === this.TABLE) {
            const container = element.closest('.table-row') as HTMLElement
            rowNumber = parseInt(container.dataset.row)
        }

        return rowNumber
    }

    public async deletePlanProperty(planPropertyFK: number, successText: string, errorText: string): Promise<boolean> {
        const request = await HttpRequestHandler.post(
            `/${this._navigationCode}/DeletePlanProperties`,
            successText,
            errorText,
            JSON.stringify([planPropertyFK]),
            'application/json'
        )

        return request.ok
    }

    public async savePlanProperty(planProperty: IPlanProperty, successText: string, errorText: string): Promise<boolean> {
        const request = await HttpRequestHandler.post(
            `/${this._navigationCode}/save${this._navigationCode}page/`,
            successText,
            errorText,
            JSON.stringify({
                PlanPK: this._planPK,
                PageCode: this._pageCode,
                PlanPropertyModels: [planProperty]
            }),
            'application/json'
        )

        return request.ok
    }

    public getAllPlanPropertiesToSave(propertyGroupCodeFK: string = null): IPlanProperty[] {
        const allPlanPropertiesToSave: IPlanProperty[] = []

        for (const field of this._fieldset.SaveableClasses) {
            const elements: NodeListOf<HTMLElement> = propertyGroupCodeFK
                ? document.querySelectorAll(`.${field}[data-propertygroupcodepk='${propertyGroupCodeFK}']`)
                : document.querySelectorAll(`.${field}`)

            if (elements?.length) {
                const planPropertiesToSave = this.pushPlanPropertiesToSave(elements, field)
                allPlanPropertiesToSave.push(...planPropertiesToSave)
            }
        }

        return allPlanPropertiesToSave
    }

    /*****************************
     ****** Private Methods ******
     *****************************/

    private constructEventListeners() {
        const saveButton = document.getElementById(`${this._navigationCode}${this._pageLabel}SaveButton`)
        if (saveButton) saveButton.addEventListener('click', (e: Event) => typeof this.customSave !== 'undefined' ? this.customSave('save') : this.save('save'))

        const continueButton = document.getElementById('continueButton')
        if (continueButton) continueButton.addEventListener('click', (e: Event) => typeof this.customSave !== 'undefined' ? this.customSave('continue') : this.save('continue'))

        const backButton = document.getElementById('backButton')
        if (backButton) backButton.addEventListener('click', (e: Event) => typeof this.customSave !== 'undefined' ? this.customSave('back') : this.save('back'))

        const navLinks = document.querySelectorAll('a.navLink') as NodeListOf<HTMLLinkElement>
        for (const link of navLinks) link.addEventListener('click', (e: Event) => this.save(link.dataset.redirectUrl))
    }

    private pushPlanPropertiesToSave(elements: NodeListOf<HTMLElement>, field: string): IPlanProperty[] {
        let planPropertiesToSave: IPlanProperty[] = []

        for (const element of elements) {
            if (this.SkipElements.indexOf(element.dataset.propertypk) >= 0) continue

            let textValue: string = null
            let lookupCode: number = null
            let forceSave: boolean = false

            switch (field) {
                case this._fieldset.Calendar:
                case this._fieldset.Text:                    
                    textValue = this.getInputPlanProperty(element)
                    break

                case this._fieldset.Checkbox:
                case this._fieldset.MultiSelectOption:
                    textValue = this.getCheckboxPlanProperty(element)
                    break

                case this._fieldset.Radio:
                    lookupCode = this.getRadioButtonPlanProperty(element)
                    break

                case this._fieldset.Select:
                    lookupCode = this.getSelectPlanProperty(element)
                    break

                case this._fieldset.Textarea:
                    const result = this.getTextAreaPlanProperty(element)
                    textValue = result.Value
                    forceSave = result.ForceSave
                    break
            }

            if (textValue != null || lookupCode || forceSave) {
                planPropertiesToSave.push({
                    PropertyFK: parseInt(element.dataset.propertypk),
                    PlanFK: this._planPK,
                    PlanPropertyPK: parseInt(element.dataset.planpropertypk),
                    LookupCodeFK: lookupCode,
                    TextValue: textValue,
                    RowNbr: this.getRowNumber(element),
                    IsDeletedInd: false,
                    ParentPlanPropertyPK: "parentplanpropertypk" in element.dataset && parseInt(element.dataset.parentplanpropertypk) > 0 ? parseInt(element.dataset.parentplanpropertypk) : null,
                    PlanPropertyRelationTypePK: "propertyrelationtype" in element.dataset && parseInt(element.dataset.propertyrelationtype) > 0 ? parseInt(element.dataset.propertyrelationtype) : null
                })
            }
        }

        return planPropertiesToSave
    }

    private getInputPlanProperty(element: HTMLElement): string {
        const inputElement = element as HTMLInputElement

        if (inputElement.value === '' && inputElement.dataset.planpropertypk === '0') return        
        if (inputElement.value === inputElement.defaultValue && !inputElement.classList.contains('prepopulated') && inputElement.dataset.planpropertypk !== '0') return

        return inputElement.value
    }

    private getCheckboxPlanProperty(element: HTMLElement): string {
        const checkboxElement = element as HTMLInputElement

        if (!checkboxElement.checked && checkboxElement.dataset.planpropertypk === '0') return
        if (checkboxElement.checked === checkboxElement.defaultChecked) return

        return checkboxElement.checked ? 'on' : 'off'
    }

    private getRadioButtonPlanProperty(element: HTMLElement): number {
        const radioButtonElement = element as HTMLInputElement

        /* 
         * Disabling this check for now in the K12 plan, specifically        
         * because validating file uploads could break if this check 
         * is in place. May want to revisit the logic here later.
         */
        //if (radioButtonElement.checked === radioButtonElement.defaultChecked) return

        if (!radioButtonElement.checked) return

        return parseInt(radioButtonElement.value)
    }

    private getSelectPlanProperty(element: HTMLElement): number {
        const selectElement = element as HTMLSelectElement

        if (selectElement.options[selectElement.selectedIndex].value === '0' && selectElement.dataset.planpropertypk === '0') return
        if (selectElement.options[selectElement.selectedIndex].defaultSelected) return

        return parseInt(selectElement.value)
    }

    private getTextAreaPlanProperty(element: HTMLElement): { Value: string, ForceSave: boolean } {
        const textAreaElement = element as HTMLTextAreaElement        

        if ((textAreaElement.value === '' && textAreaElement.dataset.planpropertypk === '0')
            || (textAreaElement.value === textAreaElement.defaultValue)) {
            return { Value: null, ForceSave: false }
        }

        return { Value: textAreaElement.value, ForceSave: !textAreaElement.value }
    }
}

class FileHandler  {
    private _fileField: string
    private _navigationCode: string
    private _pageCode: string
    private _planFK: number
    public OnUpload: Function

    constructor(fileField: string, navigationCode: string, pageCode: string, planFK: number) {
        this._fileField = fileField
        this._navigationCode = navigationCode
        this._pageCode = pageCode
        this._planFK = planFK

        this.constructEventListeners()
    }

    public constructEventListeners() {
        const deleteFileCancelButton = document.getElementById('deleteFileCancel') as HTMLButtonElement
        if (deleteFileCancelButton) deleteFileCancelButton.addEventListener('click', (e: Event) => this.deleteFileCancel())

        const deleteFileConfirmButton = document.getElementById('deleteFileConfirm') as HTMLButtonElement
        if (deleteFileConfirmButton) deleteFileConfirmButton.addEventListener('click', (e: Event) => this.deleteFile(deleteFileConfirmButton))

        this.constructFileUploadElements()
        this.constructFileDeleteElements()
    }

    public constructFileUploadElements() {
        const fileUploadElements = document.querySelectorAll(`.${this._fileField}`)
        for (const element of fileUploadElements) element.addEventListener('change', (e: Event) => this.uploadFile(e.srcElement as HTMLInputElement))
    }

    public constructFileDeleteElements() {
        const deleteFileButtons = document.getElementsByClassName('deleteFile') as HTMLCollectionOf<HTMLButtonElement>
        for (const deleteFileButton of deleteFileButtons) deleteFileButton.addEventListener('click', (e: Event) => this.showDeleteFileConfirmation(deleteFileButton))
    }

    public async uploadFile(input: HTMLInputElement) {
        if(input.files.length > 0) {
            if(!input.hasAttribute("accept") || input.accept === "" || input.accept.includes(input.files[0].type)) {
                const uploadFileForm = input.parentElement as HTMLFormElement
                const dataset = uploadFileForm.dataset
                const formData = new FormData(uploadFileForm)

                const planFK = document.querySelector('main').dataset.planfk
                formData.append('planFK', planFK)

                const response = await HttpRequestHandler.post(
                    `/${this._navigationCode}/UploadFile/`,
                    'File successfully uploaded!',
                    'There was an error uploading your file, please try again.',
                    formData
                )

                const json = await response.json()

                if (json.success) {
                    input.value = ''
                    input.dataset.hasuploaded = 'true'
                    this.getFileUploadPartialView(json.payload, dataset.propertypk, dataset.row)

                    ValidationHandler.runServerSideValidation(this._navigationCode, this._planFK, this._pageCode)

                    if(typeof this.OnUpload === "function")
                    {
                        this.OnUpload(input);
                    }
                }
            } else {
                input.value = '';
                Core.createHTMLAlert("alertMessageDiv", "Please upload a file with the correct file type.", 'error', 3000, null);
            }
        }
    }

    public async deleteFile(confirmButton: HTMLButtonElement) {
        const planPropertyFilePK: string = confirmButton.dataset.planpropertyfilepk

        const response = await HttpRequestHandler.post(
            `/${this._navigationCode}/DeleteFiles`,
            'File successfully deleted.',
            'There was an error deleting this file, please try again',
            JSON.stringify([planPropertyFilePK]),
            'application/json'
        )

        const json = await response.json()

        if (json.success) {
            this.deleteFileSuccess(planPropertyFilePK)
            ValidationHandler.runServerSideValidation(this._navigationCode, this._planFK, this._pageCode)
        }
    }

    public showDeleteFileConfirmation(deleteButton: HTMLButtonElement) {
        const planPropertyFilePK = deleteButton.dataset.planpropertyfilepk

        if (planPropertyFilePK && parseInt(planPropertyFilePK) > 0) {
            const modal: Modal = new Modal('deleteFileModal', null)
            const deleteConfirmButton = document.getElementById('deleteFileConfirm') as HTMLButtonElement
            deleteConfirmButton.dataset.planpropertyfilepk = planPropertyFilePK
            modal.show()
        }
    }

    public deleteFileCancel() {
        const modal: Modal = new Modal('deleteFileModal', null)
        modal.hide()
    }

    private deleteFileSuccess(planPropertyFilePK: string) {
        const fileUploadElement = document.querySelector(`.upload-file-column[data-planpropertyfilepk='${planPropertyFilePK}']`) as HTMLElement
        if (fileUploadElement) fileUploadElement.remove()

        const modal: Modal = new Modal('deleteFileModal', null)
        modal.hide()
    }

    private async getFileUploadPartialView(planPropertyFile, propertyPK, row) {
        const response = await HttpRequestHandler.get(
            `/${this._navigationCode}/GetFileUploadSharedPartialView`, {
            planPropertyFilePK: planPropertyFile.planPropertyFilePK,
            contentType: planPropertyFile.contentType,
            filename: planPropertyFile.filename
        })

        const text = await response.text()

        if (text) {
            const fileUploadDiv = document.querySelector(`.file-upload-div-inner[data-propertypk='${propertyPK}'][data-row='${row}']`) as HTMLDivElement
            fileUploadDiv.insertAdjacentHTML('beforeend', text)
            this.constructFileDeleteElements()
        }
    }
}

class ValidationHandler {
    private _fieldset: Fieldset

    public Correction: number = 0;

    //This is used to force a property group to have a certain number of rows filled out.
    //Key is the PropetyGroupCodePK and value is the minimum number of rows required.
    public MinRequiredGroupRows: {[key: number]: number};

    constructor(fieldset: Fieldset, customValidationFields: string[] = null) {
        this._fieldset = fieldset

        if (customValidationFields?.length) {
            this._fieldset.ValidationClasses.push(...customValidationFields)
        }
    }

    public validate() {
        const errorCount = this.validateElements() - this.Correction
        const showErrorMessage = !!errorCount

        const singularOrPlural = {
            toBe: errorCount === 1 ? 'is' : 'are',
            plural: errorCount === 1 ? '' : 's',
            first: errorCount === 1 ? '' : 'first'
        }

        const message = document.getElementById('validationMessage') as HTMLDivElement

        message.innerHTML = `<p class='validationErrorCountMessage'>There ${singularOrPlural.toBe
            } ${errorCount
            } issue${singularOrPlural.plural
            } to fix on this page.</p><a id='goToFirstError' href='javascript:void(0)'>Go to ${singularOrPlural.first
            } issue.</a>`

        const goToError = document.getElementById('goToFirstError')

        if (goToError) {
            const firstMissingElement = document.querySelector('.missing-field') as HTMLElement

            if (firstMissingElement) {
                goToError.addEventListener('click', (e: Event) => {
                    const accordion = Core.findClosest(firstMissingElement, '.Accordion-panel')

                    if (accordion) {
                        const id = accordion.getAttribute("aria-labelledby");

                        const accordionElement = <HTMLButtonElement>document.getElementById(id);
                        if(!accordionElement.classList.contains("open")) {
                            accordionElement.click();
                        }
                    }

                    if (firstMissingElement.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstMissingElement.id);
                    } else if(firstMissingElement.classList.contains("multi-select-label")) {
                        const parent = Core.findClosest(firstMissingElement, '.multi-select');
                        if(parent != null){
                            parent.focus();
                        }
                    } else {
                        if(!firstMissingElement.hasAttribute("tabindex")) {
                            firstMissingElement.setAttribute("tabindex", "-1");
                        }
                        firstMissingElement.focus();
                    }  
                })
            } else {
                goToError.parentNode.removeChild(goToError)
            }
        }

        const messageContainerColumn = document.getElementById('validationColumn') as HTMLElement
        const messageContainer = document.getElementById('validationMessageContainer') as HTMLElement
        messageContainerColumn.classList.add('show')
        const validationIcon = document.getElementById('validationMessageIcon') as HTMLElement

        setTimeout(() => messageContainer.focus(), 500)

        if (showErrorMessage) {
            const message = document.getElementById('validationMessage') as HTMLElement
            messageContainer.classList.add('warning')
            message.classList.add('show')
            validationIcon.innerHTML = `<i class='fas fa-exclamation-triangle'></i>`
        } else {
            messageContainer.classList.add('success')
            validationIcon.innerHTML = `<i class='fas fa-check-circle'></i>`
            const message = document.getElementById('validationMessage') as HTMLElement
            if(message !== null) {
                message.classList.remove("show");
            }
            const successMessage = document.getElementById('saveSuccess') as HTMLElement
            if (successMessage) successMessage.innerHTML = 'The page has been successfully saved.'
        }
    }

    public validateElements(skipElements: string[] = []): number {
        let errorCount = 0
        let radioButtonName = ''
        let classesToValidate = this._fieldset.ValidationClasses.map(vclass => "."+vclass).join(",");
        const allElements = document.querySelectorAll(`${classesToValidate}`) as NodeListOf<HTMLElement>;
        for (const validationClass of this._fieldset.ValidationClasses) {
            const elements = document.querySelectorAll(`.${validationClass}`) as NodeListOf<HTMLElement>
             
            if(validationClass == this._fieldset.Checkbox) {
                errorCount += this.validateCheckboxGroups(elements as NodeListOf<HTMLInputElement>, skipElements);
            }

            for (const element of elements) {
                if (skipElements.indexOf(element.dataset.propertypk) >= 0) continue

                const isElementRequired: boolean
                    = element.dataset.percent && element.dataset.percent === '1.00'

                if (isElementRequired) {
                    let isElementInTable = element.dataset.propertygrouptypecode == '2'
                        && !element.classList.contains('first-table-row');

                    let isElementInMinimumRequiredRow = false;
                    if(this.MinRequiredGroupRows != null && parseInt(element.dataset.propertygroupcodepk) in this.MinRequiredGroupRows )
                    {
                        //Get distinct row numbers
                        let rowNumbersToValidate = [...allElements].filter(el => el.dataset.propertygroupcodepk == element.dataset.propertygroupcodepk).map(el => parseInt(el.dataset.row)).sort().slice(0, this.MinRequiredGroupRows[parseInt(element.dataset.propertygroupcodepk)]);
                        if(rowNumbersToValidate != null && parseInt(element.dataset.row) in rowNumbersToValidate)
                        {
                            isElementInMinimumRequiredRow = true;
                        }
                    }
                    
                    //If there is an element in the row with a planproperty (i.e. it is filled out), then the element is required
                    let otherElementInRowIsFilledOut = [...allElements].some((el) => 
                        "planpropertypk" in el.dataset 
                        && parseInt(el.dataset.planpropertypk) > 0 
                        && el.dataset.propertygroupcodepk == element.dataset.propertygroupcodepk
                        && el.dataset.row == element.dataset.row
                    );

                    if (isElementInTable && !isElementInMinimumRequiredRow && !otherElementInRowIsFilledOut) continue

                    let isValid = true

                    switch (validationClass) {
                        case this._fieldset.Text:
                        case this._fieldset.Calendar:
                            isValid = this.validateInputElement(element as HTMLInputElement)
                            break
                        case this._fieldset.Radio:

                            const radioButton = element as HTMLInputElement
                            isValid = this.validateRadioButtonElement(radioButton, radioButtonName)

                            radioButtonName = radioButton.name
                            break
                        case this._fieldset.File:
                            isValid = this.validateFileElement(element as HTMLInputElement)
                            break
                        case this._fieldset.MultiSelect:
                            isValid = this.validateMultiSelectElement(element as HTMLUListElement)
                            break
                        case this._fieldset.Select:
                            isValid = this.validateSelectElement(element as HTMLSelectElement)
                            break
                        case this._fieldset.Textarea:
                            isValid = this.validateTextareaElement(element as HTMLTextAreaElement)
                            break
                        default:
                            break
                    }

                    if (!isValid) {

                        if (element.classList.contains(this._fieldset.MultiSelect)) {
                            const span = element.parentElement.querySelector('span.multi-select-label') as HTMLSpanElement
                            span.classList.add('missing-field')
                        }

                        element.classList.add('missing-field')
                        element.setAttribute('aria-invalid', 'true')
                        Core.createErrorLabelForInput(element)
                        errorCount++
                    }
                }
            }
        }

        return errorCount
    }

    private validateCheckboxGroups(checkboxes: NodeListOf<HTMLInputElement>, skipElements: string [] = []): number {
        let errorCount = 0
        let currentPropertyGroupCode = ''

        for (const checkbox of checkboxes) {
            if (skipElements.indexOf(checkbox.dataset.propertypk) >= 0) continue

            if ((checkbox.dataset.propertygrouptypecode === '11') && currentPropertyGroupCode !== checkbox.dataset.propertygroupcodepk) {

                currentPropertyGroupCode = checkbox.dataset.propertygroupcodepk
                const checkboxesInGroup = document.querySelectorAll(`input[data-propertygroupcodepk='${currentPropertyGroupCode}']`) as NodeListOf<HTMLInputElement>
                let areAnyCheckboxesChecked = false

                for (const checkboxInGroup of checkboxesInGroup) {
                    if (checkboxInGroup.checked) {
                        areAnyCheckboxesChecked = true
                        break
                    }
                }

                if (!areAnyCheckboxesChecked) {
                    errorCount++

                    const label = document.querySelector(`label[data-propertygroupcodepk='${currentPropertyGroupCode}']`)
                    if (label && !label.classList.contains('hasBeenValidated')) {
                        label.classList.add('hasBeenValidated')
                        label.classList.add('missing-field')
                        label.innerHTML = `<span class='missing-field-label'><i class='fas fa-exclamation-triangle' aria-hidden='true'></i></span> ${label.innerHTML}`
                    }
                }
            }
        }

        return errorCount
    }

    private validateSelectElement(select: HTMLSelectElement) {
        return select.selectedIndex > 0
    }

    private validateTextareaElement(textarea: HTMLTextAreaElement): boolean {
        return !!textarea.value
    }

    private validateInputElement(input: HTMLInputElement): boolean {
        return !!input.value
    }

    private validateFileElement(file: HTMLInputElement): boolean {
        return file.dataset.hasplanpropertyfiles === 'true'
    }

    private validateMultiSelectElement(list: HTMLUListElement): boolean {
        const checkedCheckboxes = list.querySelectorAll('input:checked') as NodeListOf<HTMLInputElement>
        return !!checkedCheckboxes.length
    }

    private validateRadioButtonElement(radio: HTMLInputElement, name: string) {
        // Added this check so that an unchecked yes/no radio button doesn't count as 2 errors, only 1
        if (radio.name === name) return true

        const allRadioButtons = document.getElementsByName(radio.name) as NodeListOf<HTMLInputElement>
        for (const radioButton of allRadioButtons) if (radioButton.checked) return true

        return false
    }

    public forceElementRequired(element: HTMLElement) {
        element.dataset.percent = '1.00'
        element.setAttribute('aria-required', 'true')
        element.dataset.forcerequired = 'true'
        const label = Core.findLabelForInput(element)

        if (label && !label.classList.contains('isRequired')) {
            let labelPlaceholder = label.querySelector(".requiredLabelPlaceholder");
            if(labelPlaceholder) {
                labelPlaceholder.innerHTML = "<span class='required-label'>*</span>";
            }
            else
            {
                label.innerHTML = `${label.innerHTML} <span class='required-label'>*</span>`
            }
            label.classList.add('isRequired')
        }
    }

    public forceElementOptional(element: HTMLElement) {
        element.dataset.percent = '0.00'
        element.setAttribute('aria-required', 'false')
        element.dataset.forcerequired = 'false'
        const label = Core.findLabelForInput(element)

        if (label) {
            label.classList.remove('isRequired')
            const requiredLabel = label.querySelector('.required-label')
            if (requiredLabel) requiredLabel.parentNode.removeChild(requiredLabel)
        }
    }

    public initializeRequiredFields() {

        //let formattedAllClasses = [];
        //allClasses.forEach(function (part, index) {
        //    formattedAllClasses[index] = "." + allClasses[index];
        //});

        //let classesToValidate = formattedAllClasses.join(",");

        //if (refresh) {
        //    let allElements = document.querySelectorAll(classesToValidate);

        //    for (let element of allElements) {
        //        let htmlElement = <HTMLElement>element;

        //        htmlElement.removeAttribute("aria-required");
        //        let label = Core.findLabelForInput(htmlElement);

        //        if (label !== null) {
        //            label.classList.remove("isRequired");
        //            let asterisk = label.querySelector(".required-label") as HTMLElement;
        //            if (asterisk != null) {
        //                asterisk.parentNode.removeChild(asterisk);
        //            }
        //        }
        //    }
        //}

        const allElements: HTMLElement[] = []

        for (const validationClass of this._fieldset.ValidationClasses) {
            const elementsWithClass = document.querySelectorAll(`.${validationClass}`) as NodeListOf<HTMLElement>
            allElements.push(...elementsWithClass)
        }

        for (const element of allElements) {
            const isRequired
                = (element.dataset.percent && element.dataset.percent === '1.00')
                || (element.dataset.forcerequired && element.dataset.forcerequired === 'true')
            const hasAriaRequiredAttribute
                = element.hasAttribute('aria-required') && element.getAttribute('aria-required') === 'true'

            if (isRequired && !hasAriaRequiredAttribute) {
                element.setAttribute('aria-required', 'true')
                const label = Core.findLabelForInput(element)

                if (label && !label.classList.contains('isRequired')) {
                    let labelPlaceholder = label.querySelector(".requiredLabelPlaceholder");
                    if(labelPlaceholder) {
                        labelPlaceholder.innerHTML = "<span class='required-label'>*</span>";
                    } else {
                        label.innerHTML = `${label.innerHTML} <span class='required-label'>*</span>`
                    }
                    label.classList.add('isRequired')
                }
            }
        }
    }

    public static async runServerSideValidation(navigationCode: string, planFK: number, pageCode: string): Promise<boolean> {

        const request = await fetch(
            `/${navigationCode}/RunValidation/`,
            {
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    PlanFK: planFK,
                    PageCode: pageCode
                })
            })

        return request?.ok && await request.json()
    }
}

class RadioButtonControlHandler {   
    readonly RADIOBUTTON_YES = 100
    readonly RADIOBUTTON_NO = 101
    readonly RADIOBUTTONCONTROL_PROPERTYGROUPTYPECODE = 5

    private _core: Core
    private _validationHandler: ValidationHandler

    public Callback: any

    constructor(core: Core, validationHandler: ValidationHandler) {
        this._core = core
        this._validationHandler = validationHandler
        this.constructRadioButtonControl()
    }

    private constructRadioButtonControl() {
        const radioButtons = document.querySelectorAll(`input[type='radio'][data-propertygrouptypecode='${this.RADIOBUTTONCONTROL_PROPERTYGROUPTYPECODE}']`) as NodeListOf<HTMLInputElement>

        for (const radioButton of radioButtons) {
            if(document.querySelector(`[data-property-relation-parent-property-fk='${radioButton.dataset.propertypk}']`)) {
                radioButton.addEventListener('click', (e: Event) => this.toggleRadioButtonControl(radioButton))
            }
        }

        this.toggleRadioButtonControlOnload()
    }

    private toggleRadioButtonControlOnload() {
        const yesRadioButtons = document.querySelectorAll(`input[type='radio'][data-propertygrouptypecode='${this.RADIOBUTTONCONTROL_PROPERTYGROUPTYPECODE }'][data-lookuplabel='Yes']`) as NodeListOf<HTMLInputElement>
        for (const yesRadioButton of yesRadioButtons) {
            if(document.querySelector(`[data-property-relation-parent-property-fk='${yesRadioButton.dataset.propertypk}']`)) {
                const radioButtons = document.querySelectorAll(`input[type='radio'][name='${yesRadioButton.name}']`) as NodeListOf<HTMLInputElement>
                let isChecked = false

                radioButtons.forEach(button => {
                    if (button.checked) {
                        this.toggleRadioButtonControl(button)
                        isChecked = true
                    }
                })

                if (!isChecked) this.toggleRadioButtonControl(yesRadioButton, true)
            }
        }
    }

    public toggleRadioButtonControl(radioButton: HTMLInputElement, hideAllElements: boolean = false) {
        const propertyGroupCode = radioButton.dataset.propertygroupcodepk
        const yesElements = document.querySelectorAll(
            `[data-propertygroupcodepk='${propertyGroupCode}'][data-propertyrelationtype='${this.RADIOBUTTON_YES}'][data-property-relation-parent-property-fk='${radioButton.dataset.propertypk}']`) as NodeListOf<HTMLElement>
        const noElements = document.querySelectorAll(
            `[data-propertygroupcodepk='${propertyGroupCode}'][data-propertyrelationtype='${this.RADIOBUTTON_NO}'][data-property-relation-parent-property-fk='${radioButton.dataset.propertypk}']`) as NodeListOf<HTMLElement>

        if (hideAllElements) {
            // Hide all elements regardless of radiobutton selection. Used for when nothing is selected (i.e. an unsaved page)
            (new Set([
                ...yesElements,
                ...noElements
            ])).forEach(element => {
                element.closest('.shared-page-row').classList.add('display-none')
                this._validationHandler.forceElementOptional(element)
            })
        } else if (radioButton.dataset.lookuplabel === 'Yes') {
            // Radio Button selected yes, show the 'yes' elements and hide the 'no' elements
            yesElements.forEach(element => {
                element.closest('.shared-page-row').classList.remove('display-none')
                this._validationHandler.forceElementRequired(element)
            })
            noElements.forEach(element => {
                element.closest('.shared-page-row').classList.add('display-none')
                this._validationHandler.forceElementOptional(element)
            })
        } else {
            // Radio Button selected no, vice versa
            noElements.forEach(element => {
                element.closest('.shared-page-row').classList.remove('display-none')
                this._validationHandler.forceElementRequired(element)
            })
            yesElements.forEach(element => {
                element.closest('.shared-page-row').classList.add('display-none')
                this._validationHandler.forceElementOptional(element)
            })
        }

        if (this.Callback) this.Callback()
    }
}

class CheckboxControlHandler {
    readonly CHECKBOXCONTROL_CHECKED = 102
    readonly CHECKBOXCONTROL_UNCHECKED = 103
    readonly CHECKBOXCONTROL_PROPERTYGROUPTYPECODE = 6

    private _core: Core
    private _validationHandler: ValidationHandler

    constructor(core: Core, validationHandler: ValidationHandler) {
        this._core = core
        this._validationHandler = validationHandler
        this.constructCheckboxControl()
    }

    private constructCheckboxControl() {
        const checkboxes = document.querySelectorAll(`input[type='checkbox'][data-propertygrouptypecode='${this.CHECKBOXCONTROL_PROPERTYGROUPTYPECODE}']`) as NodeListOf<HTMLInputElement>

        for (const checkbox of checkboxes) {
            checkbox.addEventListener('click', (e: Event) => this.toggleCheckboxControl(checkbox))
            this.toggleCheckboxControl(checkbox)
        }
    }

    public toggleCheckboxControl(checkbox: HTMLInputElement) {
        const propertyGroupCode = checkbox.dataset.propertygroupcodepk
        const checkedElements = document.querySelectorAll(
            `[data-propertygroupcodepk='${propertyGroupCode}'][data-propertyrelationtype='${this.CHECKBOXCONTROL_CHECKED}'][data-property-relation-parent-property-fk='${checkbox.dataset.propertypk}']`) as NodeListOf<HTMLElement>
        const uncheckedElements = document.querySelectorAll(
            `[data-propertygroupcodepk='${propertyGroupCode}'][data-propertyrelationtype='${this.CHECKBOXCONTROL_UNCHECKED}'][data-property-relation-parent-property-fk='${checkbox.dataset.propertypk}']`) as NodeListOf<HTMLElement>

        if (checkbox.checked) {
            checkedElements.forEach(element => {
                element.closest('.shared-page-row').classList.remove('display-none')
                if (element.classList.contains('force-optional')) this._validationHandler.forceElementRequired(element)
            })
            uncheckedElements.forEach(element => {
                element.closest('.shared-page-row').classList.add('display-none')
                if (element.dataset.percent !== '0.00') {
                    this._validationHandler.forceElementOptional(element)
                    element.classList.add('force-optional')
                }
            })
        } else {
            uncheckedElements.forEach(element => {
                element.closest('.shared-page-row').classList.remove('display-none')
                if (element.classList.contains('force-optional')) this._validationHandler.forceElementRequired(element)
            })
            checkedElements.forEach(element => {
                element.closest('.shared-page-row').classList.add('display-none')
                if (element.dataset.percent !== '0.00') {
                    this._validationHandler.forceElementOptional(element)
                    element.classList.add('force-optional')
                }
            })
        }
    }
}

class GroupHandler {
    private _core: Core
    private _navigationCode: string
    private _pageCode: string
    private _planFK: number
    private _templateFK: number
    private _validationHandler: ValidationHandler

    public Callback: any

    constructor(core: Core, _navigationCode: string, pageCode: string, planFK: number, templateFK: number, validationHandler: ValidationHandler) {
        this._core = core
        this._navigationCode = _navigationCode
        this._pageCode = pageCode
        this._planFK = planFK
        this._templateFK = templateFK
        this._validationHandler = validationHandler

        this.constructEventListeners()
    }

    private constructEventListeners() {
        const addGroupButtons = document.querySelectorAll('.add-group-button') as NodeListOf<HTMLButtonElement>
        for (const button of addGroupButtons) {
            button.addEventListener('click', (e: Event) => this.addGroup(button))
        }

        this.constructDeleteGroupButtons()
        this.constructDeleteTableRowsButtons()
    }

    private constructDeleteGroupButtons(propertyGroupCodePK: string = null, row: string = null) {
        const deleteGroupButtons = propertyGroupCodePK && row
            ? document.querySelectorAll(`button.delete-group-button[data-propertygroupcodepk='${propertyGroupCodePK}'][data-row='${row}']`) as NodeListOf<HTMLButtonElement>
            : document.querySelectorAll('.delete-group-button') as NodeListOf<HTMLButtonElement>

        for (const button of deleteGroupButtons) button.addEventListener('click', (e: Event) => this.deleteGroup(button))        
    }

    private constructDeleteTableRowsButtons() {
        const deleteRowButtons = document.querySelectorAll('.delete-row-button') as NodeListOf<HTMLButtonElement>
        for (const button of deleteRowButtons) button.addEventListener('click', (e: Event) => this.deleteTableRow(button))
    }

    private async addGroup(button: HTMLButtonElement) { 
        const propertyGroupCodePK = button.dataset.propertygroupcodepk
        const propertyGroupDescription = button.dataset.propertygroupdescription
        const row = button.dataset.row

        const selectElement = document.querySelector(`select[data-propertygroupcodepk='${propertyGroupCodePK}']`) as HTMLSelectElement
        const lookupGroupCode = selectElement ? selectElement.dataset.lookupgroupcode : null;        
        const addRowsInput = document.querySelector(`input.add-button-input[data-propertygroupcodepk='${propertyGroupCodePK}']`) as HTMLInputElement
        const numberOfRowsToAdd = addRowsInput ? parseInt(addRowsInput.value) : 1

        const request = await HttpRequestHandler.post(
            `/${this._navigationCode}/AddPropertyGroup`,
            `Successfully added ${propertyGroupDescription}`,
            `There was an error adding a ${propertyGroupDescription}, please try again`,
            JSON.stringify({
                LookupGroupCode: lookupGroupCode,
                NavigationCode: this._navigationCode,
                NextRow: parseInt(row),
                NumberOfPropertyGroupsToAdd: numberOfRowsToAdd,
                PageCode: this._pageCode,
                PropertyGroupCodePK: propertyGroupCodePK,
                TemplateFK: this._templateFK
            }),
            'application/json'
        )

        if (request.ok) {
            const text = await request.text()
            if (text) {
                const propertyGroupContainer
                    = document.querySelector(`.property-group-section[data-propertygroupcodepk='${propertyGroupCodePK}']`)                
                propertyGroupContainer.insertAdjacentHTML('beforeend', text)

                const elementsInGroup = this.getAllElementsInPropertyGroup(propertyGroupCodePK, row)
                elementsInGroup.forEach(element => this._validationHandler.forceElementRequired(element))

                this.constructDeleteGroupButtons(propertyGroupCodePK, row)
                this.constructDeleteTableRowsButtons()

                button.dataset.row = (numberOfRowsToAdd + parseInt(row)).toString()
                if (this.Callback) this.Callback()
            }
        }
    }

    private async deletePlanProperties(propertyGroupCodePK: string, propertyGroupDescription: string, row: string): Promise<boolean> {
        let successfullyDeletedPlanProperties = true

        const elementsInGroup = this.getAllElementsInPropertyGroup(propertyGroupCodePK, row)
        const planPropertyPKs = []

        elementsInGroup.forEach(element => {
            if (parseInt(element.dataset.planpropertypk)) planPropertyPKs.push(element.dataset.planpropertypk)
        })

        if (planPropertyPKs.length) {
            const request = await HttpRequestHandler.post(
                `/${this._navigationCode}/DeletePlanProperties`,
                `Successfully deleted ${propertyGroupDescription}`,
                `There was an error deleting the ${propertyGroupDescription}, please try again`,
                JSON.stringify(planPropertyPKs),
                'application/json'
            )

            if (request.ok) {
                const json = await request.json()

                if (json.Success === 'false') {
                    successfullyDeletedPlanProperties = false
                } else {
                    ValidationHandler.runServerSideValidation(this._navigationCode, this._planFK, this._pageCode)
                }

            } else {
                successfullyDeletedPlanProperties = false
            }
        }

        return successfullyDeletedPlanProperties
    }

    public async deleteGroup(button: HTMLButtonElement) {
        const propertyGroupCodePK = button.dataset.propertygroupcodepk
        const propertyGroupDescription = button.dataset.propertygroupdescription
        const row = button.dataset.row
        
        if (this.deletePlanProperties(propertyGroupCodePK, propertyGroupDescription, row)) {
            const propertyGroupContainer
                = document.querySelector(`div.property-group[data-propertygroupcodepk='${propertyGroupCodePK}'][data-row='${row}']`)

            propertyGroupContainer.remove()
        }        
    }

    private async deleteTableRow(button: HTMLButtonElement) {
        const propertyGroupCodePK = button.dataset.propertygroupcodepk
        const propertyGroupDescription = button.dataset.propertygroupdescription
        const row = button.dataset.row

        if (this.deletePlanProperties(propertyGroupCodePK, propertyGroupDescription, row)) {
            button.closest('tr').remove()
        }
    }

    private getAllElementsInPropertyGroup(propertyGroupCodePK: string, row: string): NodeListOf<HTMLElement> {
        return document.querySelectorAll(`[data-propertygroupcodepk='${propertyGroupCodePK}'][data-row='${row}'][data-propertypk]`) as NodeListOf<HTMLElement>
    }
}

class ExportHandler {
    private _core: Core
    private _planPK: number
    private _templateFK: number

    public Callback: any

    constructor(core: Core, planPK:number, templateFK: number) {
        this._core = core
        this._planPK = planPK
        this._templateFK = templateFK

        this.constructEventListeners()
    }

    private constructEventListeners() {
        const exportToPDFButton = document.getElementById("pdfExportButton")
        if (exportToPDFButton) exportToPDFButton.addEventListener('click', (e: Event) => this.exportToPDF())
    }

    private exportToPDF() {

        Core.showLoader();
        //Get all components for given template
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/OutcomeBasedReports/FullReportDataExportPDF', true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.responseType = 'blob';
        xhr.onload = function () {

            Core.hideLoader();

            if (xhr.status === 200) {
                let blob = this.response;
                let filename = "PDF Export";
                filename = filename + ".pdf";

                if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                    window.navigator.msSaveBlob(blob, filename);
                } else {
                    var a = <HTMLAnchorElement>document.createElement("a");
                    var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                    document.body.appendChild(a);
                    a.style.display = "none";
                    a.href = blobUrl;
                    a.download = filename;
                    a.click();
                }
            } else {
                Core.createHTMLAlert("alertMessageDiv", "There was an issue generating this report. Please try again later.", 'error', 3000, null);
            }
        }
        xhr.send("planFK=" + this._planPK + "&templateFK=" + this._templateFK);
    }
}

class SummaryChecklistAndSubmissionHandler {
    private _core: Core
    private _navigationCode: string
    private _lateEmailPlanFK: number
    private _lateEmailModal: Modal

    constructor(navigationCode: string) {
        this._core = new Core()
        this._navigationCode = navigationCode

        this.constructEventListeners()

        this._lateEmailModal = new Modal('sendLateEmailModal', 'sendLateEmail')
        this._core.leftnav(this)
    }

    private constructEventListeners() {
        // Back and Submit buttons
        const backButton = document.getElementById('backButton') as HTMLButtonElement
        backButton?.addEventListener('click', (e: Event) => this.back())

        const submitButton = document.getElementById('submitPlanButton') as HTMLButtonElement
        submitButton?.addEventListener('click', (e: Event) => this.submit(submitButton))

        // Late Email Buttons
        const sendLateEmailButton = document.querySelector('#sendLateEmail[data-plan-fk]') as HTMLButtonElement
        sendLateEmailButton?.addEventListener('click', () => {
            this._lateEmailPlanFK = parseInt(sendLateEmailButton.dataset.planFk)
            this._lateEmailModal.show()
        })

        const sendLateEmailConfirmButton = document.querySelector('#sendLateEmailConfirm') as HTMLButtonElement
        if (sendLateEmailConfirmButton != null) sendLateEmailConfirmButton.addEventListener('click', () => this.sendLateEmail())

        const sendLateEmailDueDate = document.getElementById('sendLateEmailNewDate')
        if (sendLateEmailDueDate) {
            sendLateEmailDueDate.addEventListener('change', () => {
                if (sendLateEmailConfirmButton) sendLateEmailConfirmButton.disabled = false
            })
        }

        // Withdraw plan buttons
        const withdrawPlanButton = document.getElementById('withdrawPlanButton') as HTMLButtonElement
        withdrawPlanButton?.addEventListener('click', (e: Event) => this.withdrawPlan(withdrawPlanButton))

        const withdrawPlanConfirmButton = document.getElementById('withdrawPlanConfirm') as HTMLButtonElement
        withdrawPlanConfirmButton?.addEventListener('click', (e: Event) => this.withdrawPlanConfirm(withdrawPlanConfirmButton))

        const withdrawPlanCancelButton = document.getElementById('withdrawPlanCancel') as HTMLButtonElement
        withdrawPlanCancelButton?.addEventListener('click', (e: Event) => this.withdrawPlanCancel())
    }

    public back() {
        let newPage = document.getElementById('backButton').getAttribute('data-redirect-url')
        window.location.href = newPage
    }

    public getCore() {
        return this._core
    }

    public async submit(submitButton: HTMLButtonElement) {
        if (!submitButton.classList.contains('disabled')) {
            Core.showLoader()
            const planFK = parseInt(submitButton.dataset.planfk)

            const response = await fetch(
                `/${this._navigationCode}/SubmitPlan/`,
                {
                    method: 'POST',
                    credentials: 'include',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(planFK)
                }
            )

            Core.hideLoader()

            if (response?.ok && (await response.json()).success) {
                Core.createHTMLAlert('alertMessageDiv', 'This report has been submitted for review!', 'success', 3000, window.location.reload())
                setTimeout(() => { window.location.href = '/Reports/StateRequiredReports' }, 3000)
            } else {
                Core.createHTMLAlert('alertMessageDiv', 'Request failed. Please try again', 'error', 3000, null)
            }
        }
    }

    public async sendLateEmail() {
        let that = this
        Core.showLoader()
        let sendLateEmailDueDate = document.getElementById('sendLateEmailNewDate') as HTMLInputElement

        const response = await fetch(
            `/${this._navigationCode}/SendLateEmail/`,
            {
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: `planFK=${that._lateEmailPlanFK}&newDueDateStr=${sendLateEmailDueDate.value}`
            }
        )

        Core.hideLoader()
        this._lateEmailModal.hide()

        if (response?.ok && (await response.json()).success) {
            Core.createHTMLAlert('alertMessageDiv', 'Successfully sent late email', 'success', 3000, null)
        } else {
            Core.createHTMLAlert('alertMessageDiv', 'There was an unexpected error sending late email', 'error', 3000, null)
        }

        const sendLateEmailButton = document.querySelector('#sendLateEmail[data-plan-fk]') as HTMLButtonElement
        if (sendLateEmailButton) sendLateEmailButton.disabled = true
    }

    public withdrawPlan(button: HTMLButtonElement) {
        const planFK = button.dataset.planfk

        const modal: Modal = new Modal('withdrawPlanModal', null)
        modal.addAttributeToElement('withdrawPlanModal', '#withdrawPlanConfirm', 'planfk', planFK)

        modal.show()
    }

    public async withdrawPlanConfirm(confirmButton: HTMLButtonElement) {
        Core.showLoader()

        let planFK = confirmButton.dataset.planfk

        const response = await fetch(
            `/${this._navigationCode}/WithdrawPlan/`,
            {
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(planFK)
            }
        )

        Core.hideLoader()

        if (response?.ok && (await response.json()).success) {

            Core.createHTMLAlert('alertMessageDiv', 'The plan has been withdrawn.', 'success', 3000, null)

            //back to report page
            setTimeout(() => { window.location.href = '/Reports/StateRequiredReports' }, 3000)
        } else {
            Core.createHTMLAlert('alertMessageDiv', 'There was an unexpected withdrawing this plan.', 'error', 3000, null)
        }

        let modal: Modal = new Modal('withdrawPlanModal', null)
        modal.hide()
    }

    public withdrawPlanCancel() {
        let modal: Modal = new Modal('withdrawPlanModal', null)
        modal.hide()
    }
}

class ProfileHandler {
    private _core: Core
    private _navigationCode: string
    private _propertyCodePrefix: string
    private _planPK: number

    constructor(navigationCode: string, planPK: number, propertyCodePrefix: string) {
        this._core = new Core()
        this._navigationCode = navigationCode
        this._propertyCodePrefix = propertyCodePrefix
        this._planPK = planPK

        const refreshProfileButton = document.getElementById(`${this._propertyCodePrefix}ProfileRefreshProfile`) as HTMLButtonElement
        refreshProfileButton.addEventListener('click', (e: Event) => this.refreshProfile())
    }

    private async refreshProfile() {
        const response = await fetch(
            `/${this._navigationCode}/GetICDataForProfile/`,
            {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: `planFK=${this._planPK}`
            }
        )

        if (response.ok) {
            let wasUpdated = false
            const json = await response.json()

            const leaName = document.getElementById(`${this._propertyCodePrefix}ProfileLEAName`) as HTMLInputElement
            const aun = document.getElementById(`${this._propertyCodePrefix}ProfileAUN`) as HTMLInputElement
            const address1 = document.getElementById(`${this._propertyCodePrefix}ProfileAddress1`) as HTMLInputElement
            const address2 = document.getElementById(`${this._propertyCodePrefix}ProfileAddress2`) as HTMLInputElement
            const city = document.getElementById(`${this._propertyCodePrefix}ProfileCity`) as HTMLInputElement
            const state = document.getElementById(`${this._propertyCodePrefix}ProfileState`) as HTMLInputElement
            const zipCode = document.getElementById(`${this._propertyCodePrefix}ProfileZipCode`) as HTMLInputElement
            const county = document.getElementById(`${this._propertyCodePrefix}ProfileCounty`) as HTMLInputElement
            const directorName = document.getElementById(`${this._propertyCodePrefix}ProfileDirectorName`) as HTMLInputElement
            const directorEmail = document.getElementById(`${this._propertyCodePrefix}ProfileDirectorEmail`) as HTMLInputElement
            const directorPhone = document.getElementById(`${this._propertyCodePrefix}ProfileDirectorPhone`) as HTMLInputElement
            const institutionName = document.getElementById(`${this._propertyCodePrefix}ProfileInstitutionName`) as HTMLInputElement
            const federalEIN = document.getElementById(`${this._propertyCodePrefix}ProfileFederalEIN`) as HTMLInputElement
            const vendorIN = document.getElementById(`${this._propertyCodePrefix}ProfileVendorIN`) as HTMLInputElement        
            const iu = document.getElementById(`${this._propertyCodePrefix}ProfileIntermediateUnit`) as HTMLInputElement        

            if (leaName && leaName.value !== json.instDetails.instName) {
                leaName.value = json.instDetails.instName
                wasUpdated = true
            }

            if (aun && parseInt(aun.value) !== json.instDetails.auNumber) {
                aun.value = json.instDetails.auNumber
                wasUpdated = true
            }

            if (address1 && address1.value !== json.instDetails.addressLine1) {
                address1.value = json.instDetails.addressLine1
                wasUpdated = true
            }

            if (address2 && address2.value !== json.instDetails.addressLine2) {
                address2.value = json.instDetails.addressLine2
                wasUpdated = true
            }

            if (city && city.value !== json.instDetails.city) {
                city.value = json.instDetails.city
                wasUpdated = true
            }

            if (state && state.value !== json.instDetails.state) {
                state.value = json.instDetails.state
                wasUpdated = true
            }

            if (zipCode && zipCode.value !== json.instDetails.zipCode) {
                zipCode.value = json.instDetails.zipCode
                wasUpdated = true
            }

            if (county && county.value !== json.instDetails.countyName) {
                county.value = json.instDetails.countyName
                wasUpdated = true
            }

            if (institutionName && institutionName.value !== json.instDetails.instName) {
                institutionName.value = json.instDetails.instName
                wasUpdated = true
            }

            if (vendorIN && vendorIN.value !== json.instDetails.vendorId) {
                vendorIN.value = json.instDetails.vendorID
                wasUpdated = true
            }

            if (federalEIN && federalEIN.value !== json.instDetails.federalEmployerIdNumber) {
                federalEIN.value = json.instDetails.federalEmployerIdNumber
                wasUpdated = true
            }

            if (directorName && directorName.value !== json.superintendentInfo.adminName) {
                directorName.value = json.superintendentInfo.adminName
                wasUpdated = true
            }

            if (directorEmail && directorEmail.value !== json.superintendentInfo.email) {
                directorEmail.value = json.superintendentInfo.email
                wasUpdated = true
            }

            if (directorPhone && directorPhone.value !== json.superintendentInfo.phoneNumber) {
                directorPhone.value = json.superintendentInfo.phoneNumber
                wasUpdated = true
            }

            if(iu && iu.value !== json.instDetails.iuNumber) {
                iu.value = json.instDetails.iuNumber
                wasUpdated = true
            }

            if (wasUpdated) {
                Core.createHTMLAlert('alertMessageDiv', 'Successfully updated the profile with the most recent EdNA data, please save your changes.', 'success', 3000, null)
            } else {
                Core.createHTMLAlert('alertMessageDiv', 'Profile is up to date with the most recent EdNA data.', 'success', 3000, null)
            }
        } else {
            Core.createHTMLAlert('alertMessageDiv', 'There was an error retrieving data from EdNA, please try again.', 'error', 3000, null)
        }
    }
}

class PageSectionInfoHandler {
    private _moreInfoModal: Modal

    constructor() {
        this._moreInfoModal = new Modal("pageSectionMoreInfoModal", null);
        this.constructEventListeners();
    }

    private constructEventListeners() {
        let that = this;
        const moreInfoButtons = document.querySelectorAll("button.pageSectionMoreInfo[data-instructions][data-title]") as NodeListOf<HTMLButtonElement>;
        for(let but of moreInfoButtons) {
            but.addEventListener("click", () => {
                that._moreInfoModal.show();
                that._moreInfoModal.callingId = but.id;

                let infoContainer = document.getElementById("pageSectionMoreInfoInstructions") as HTMLParagraphElement;
                if (infoContainer != null) {
                    infoContainer.innerHTML = but.dataset.instructions;
                }

                let title = document.getElementById("h1ModalpageSectionMoreInfoModal") as HTMLElement;
                if (title != null) {
                    title.innerHTML = but.dataset.title;
                }
            })
        }

        let closeModalButton = document.getElementById("closeMoreInfo") as HTMLButtonElement;
        if (closeModalButton != null) {
            closeModalButton.addEventListener("click", () => {
                that._moreInfoModal.hide();
            })
        }
    }
}