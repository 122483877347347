/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />

// Graduation Pathways Profile
class GraduationReportGraduationPathwaysProfile {

    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["graduationReportProfileField"];

        let graduationPathwaysProfileSaveButton = document.getElementById("graduationReportProfileSave");
        if (graduationPathwaysProfileSaveButton !== null)
            graduationPathwaysProfileSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        let educatorEffectivenessExecutiveSaveButton = document.getElementById("ExecutiveSave");
        if (educatorEffectivenessExecutiveSaveButton !== null)
            educatorEffectivenessExecutiveSaveButton.addEventListener("click", (e: Event) => this.executiveSave("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");

        this.initializeRequiredFieldsCustom(this.validationClasses);
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.doValidationCustom(this.validationClasses);
        }

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let graduationReportProfileGrade12EnrollmentElement = document.getElementById("graduationReportProfileGrade12Enrollment");
        if (graduationReportProfileGrade12EnrollmentElement !== null)
            graduationReportProfileGrade12EnrollmentElement.addEventListener("input", (e: Event) => this.checkEnrollment());

        let graduationReportProfileGrade12NonGraduationElement = document.getElementById("graduationReportProfileGrade12NonGraduation");
        if (graduationReportProfileGrade12NonGraduationElement !== null)
            graduationReportProfileGrade12NonGraduationElement.addEventListener("input", (e: Event) => this.checkEnrollment());

        let graduationReportProfileGrade12GraduationElement = document.getElementById("graduationReportProfileGrade12Graduation");
        if (graduationReportProfileGrade12GraduationElement !== null)
            graduationReportProfileGrade12GraduationElement.addEventListener("input", (e: Event) => this.checkEnrollment());

        let refreshButton = document.getElementById("resetProfile");
        if (refreshButton !== null)
            refreshButton.addEventListener("click", (e: Event) => Core.refreshProfile("graduationReportProfileForm"));

        const graduationReportPathwaysData = document.getElementById("graduationReportPathwaysData");
        if (graduationReportPathwaysData !== null)
            graduationReportPathwaysData.addEventListener("click", (e: Event) => this.showGetDataFromPathways(e));

        const getPathwaysDataConfirm = document.getElementById("getPathwaysDataConfirm");
        if (getPathwaysDataConfirm !== null)
            getPathwaysDataConfirm.addEventListener("click", (e: Event) => this.getDataFromPathways(e));

        const getPathwaysDataCancel = document.getElementById("getPathwaysDataCancel");
        if (getPathwaysDataCancel !== null)
            getPathwaysDataCancel.addEventListener("click", (e: Event) => this.closeGetDataFromPathways());
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("graduationReportProfileForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("graduationReportProfileField");

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/GraduationReport/SaveGraduationReportGraduationPathwaysProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    executiveSave(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("graduationReportProfileForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("profileICDataSave");

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/GraduationReport/SaveGraduationReportGraduationPathwaysProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    checkEnrollment() {
        let grade12Enrollment = 0;
        let grade12Grad = 0;
        let isEmpty = false;

        let graduationReportProfileGrade12EnrollmentElement = <HTMLInputElement>document.getElementById("graduationReportProfileGrade12Enrollment");
        if (graduationReportProfileGrade12EnrollmentElement !== null)
            grade12Enrollment = parseInt(graduationReportProfileGrade12EnrollmentElement.value);

        let graduationReportProfileGrade12NonGraduationElement = <HTMLInputElement>document.getElementById("graduationReportProfileGrade12NonGraduation");

        let graduationReportProfileGrade12GraduationElement = <HTMLInputElement>document.getElementById("graduationReportProfileGrade12Graduation");
        if (graduationReportProfileGrade12GraduationElement !== null) {
            if (graduationReportProfileGrade12GraduationElement.value === "" || graduationReportProfileGrade12GraduationElement.value === null)
                isEmpty = true;
            else
                grade12Grad = parseInt(graduationReportProfileGrade12GraduationElement.value);
        }

        if (!isEmpty) {

            const eligibleErrorElement = <HTMLDivElement>document.getElementById("enrollmentErrorEligibleToGraduate");
            const enrollmentErrorElement = <HTMLDivElement>document.getElementById("enrollmentErrorGraduated");

            if (!isNaN(grade12Enrollment)) {
                if (grade12Enrollment >= 0) {
                    eligibleErrorElement.classList.add("hide");
                    graduationReportProfileGrade12EnrollmentElement.classList.remove("missing-field");
                } else {
                    eligibleErrorElement.classList.remove("hide");
                    graduationReportProfileGrade12EnrollmentElement.classList.add("missing-field");
                }
            }

            if (!isNaN(grade12Grad) && !isNaN(grade12Enrollment)) {
                if (grade12Grad >= 0 && grade12Grad <= grade12Enrollment) {
                    enrollmentErrorElement.classList.add("hide");
                    graduationReportProfileGrade12GraduationElement.classList.remove("missing-field");
                } else {
                    enrollmentErrorElement.classList.remove("hide");
                    graduationReportProfileGrade12GraduationElement.classList.add("missing-field");
                }
            }

            if (!isNaN(grade12Grad) && !isNaN(grade12Enrollment)) {
                const nonGrads = grade12Enrollment - grade12Grad;

                graduationReportProfileGrade12NonGraduationElement.value = nonGrads.toString();
            }
        } else
            graduationReportProfileGrade12NonGraduationElement.value = "";
    }

    initializeRequiredFieldsCustom(validationClasses: string[]) {

        this._core.initializeRequiredFields(this.validationClasses);
    }

    doValidationCustom(allClasses: string[], showMessageOverride?: boolean) {
        let showMessage = showMessageOverride === undefined ? this.clientSideValidationCustom(allClasses) : showMessageOverride;
        let messageContainerColumn = <HTMLElement>document.getElementById("validationColumn");
        let messageContainer = <HTMLElement>document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = <HTMLElement>document.getElementById("validationMessageIcon");

        setTimeout(function () {
            messageContainer.focus();
        }, 500);

        if (showMessage) {

            let message = <HTMLElement>document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";

        } else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";

            let successMessage = <HTMLElement>document.getElementById("saveSuccess");

            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved."
            }
        }
    }

    clientSideValidationCustom(allClasses: string[]): boolean {
        let showMessage: boolean = false;
        let totalErrors = 0;

        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });

        let classesToValidate = formattedAllClasses.join(",");

        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });

        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });

        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });

        let allElements = document.querySelectorAll(classesToValidate);

        for (let element of allElements) {
            let alreadyExists = false;
            let htmlElement = <HTMLElement>element;
            if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {

                //check to see if there are other fields the same as this one on this page (i.e. fields that have the same propertypk)
                var otherElements = document.querySelectorAll(`[data-propertypk='${htmlElement.dataset.propertypk}']`);
                for (let otherElement of otherElements) {
                    let otherHtmlElement = <HTMLElement>otherElement;
                    if (otherHtmlElement.classList.contains("missing-field")) {
                        alreadyExists = true;
                    } else {
                        if (otherHtmlElement instanceof HTMLInputElement) {
                            if (otherHtmlElement.hasAttribute("type") && otherHtmlElement.getAttribute("type") === "radio") {
                                //Check to see if a prior group of radio buttons has already been validated
                                let OtherInputElement = <HTMLInputElement>otherHtmlElement;

                                if (OtherInputElement.hasAttribute("name")) {
                                    let radioGroupName = OtherInputElement.getAttribute("name");

                                    let radios = document.getElementsByName(radioGroupName);
                                    let radioIsChecked: boolean = false;
                                    let isAlreadyValidated: boolean = false;

                                    for (var i = 0, length = radios.length; i < length; i++) {
                                        let radioElement = <HTMLInputElement>radios[i];
                                        if (radioElement.checked) {
                                            radioIsChecked = true;
                                        }

                                        if (radioElement.classList.contains("missing-field")) {
                                            isAlreadyValidated = true;
                                        }
                                    }

                                    if (isAlreadyValidated || radioIsChecked) {
                                        alreadyExists = true;
                                    }
                                }
                            } else {
                                let OtherInputElement = <HTMLInputElement>otherHtmlElement;
                                if (OtherInputElement.value !== "") {
                                    alreadyExists = true;
                                }
                            }
                        } else if (otherHtmlElement instanceof HTMLSelectElement) {
                            let otherSelectElement = <HTMLSelectElement>otherHtmlElement;
                            if (otherSelectElement.selectedIndex >= 0 && otherSelectElement.options[otherSelectElement.selectedIndex].value !== "") {
                                alreadyExists = true;
                            }
                        } else if (otherHtmlElement instanceof HTMLTextAreaElement) {
                            let otherTextAreaElement = <HTMLTextAreaElement>otherHtmlElement;
                            if (otherTextAreaElement.value !== "") {
                                alreadyExists = true;
                            }
                        } else if ("multiselectvalidate" in otherHtmlElement.dataset && otherHtmlElement.dataset.multiselectvalidate === "true") {
                            //See if any options have been checked in multiselect
                            let multiselectCheckboxes = otherHtmlElement.getElementsByTagName("input") as HTMLCollectionOf<HTMLInputElement>;
                            for (let selectBox of multiselectCheckboxes) {
                                if (selectBox.checked) {
                                    alreadyExists = true;
                                    break;
                                }
                            }
                        }
                    }
                }

                if (!alreadyExists || ("forcerequired" in htmlElement.dataset && htmlElement.dataset.forcerequired === "true")) {
                    if (!element.classList.contains("missing-field")) {
                        if (element instanceof HTMLInputElement) {
                            let inputElement = <HTMLInputElement>element;

                            //Only validate once for radio buttons
                            if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "radio" && !inputElement.checked) {
                                if (inputElement.hasAttribute("name")) {
                                    let radioName = inputElement.getAttribute("name");

                                    let radioButtons = document.getElementsByName(radioName);
                                    let alreadyValidated: boolean = false;
                                    let isChecked: boolean = false;

                                    for (var i = 0, length = radioButtons.length; i < length; i++) {
                                        let radioElement = <HTMLInputElement>radioButtons[i];
                                        if (radioElement.classList.contains("missing-field")) {
                                            alreadyValidated = true;
                                        }

                                        if (radioElement.checked) {
                                            isChecked = true;
                                        }
                                    }

                                    if (!alreadyValidated && !isChecked) {
                                        inputElement.classList.add("missing-field");
                                        inputElement.setAttribute("aria-invalid", "true");
                                        Core.createErrorLabelForInput(inputElement);
                                        showMessage = true;
                                        totalErrors++;
                                    }
                                }
                            } else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "file") {
                                if ("hasuploaded" in inputElement.dataset && inputElement.dataset.hasuploaded != "true") {
                                    inputElement.classList.add("missing-field");
                                    inputElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(inputElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            } else if (inputElement.value === "") {
                                inputElement.classList.add("missing-field");
                                inputElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(inputElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        } else if (element instanceof HTMLSelectElement) {
                            let selectElement = <HTMLSelectElement>element;
                            if (selectElement.selectedIndex < 0 || selectElement.options[selectElement.selectedIndex].value === "") {
                                selectElement.classList.add("missing-field");
                                selectElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(selectElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        } else if (element instanceof HTMLTextAreaElement) {
                            let textAreaElement = <HTMLTextAreaElement>element;
                            if (textAreaElement.value === "") {
                                textAreaElement.classList.add("missing-field");
                                textAreaElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(textAreaElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        } else if ("multiselectvalidate" in htmlElement.dataset && htmlElement.dataset.multiselectvalidate === "true") {
                            let multiselectCheckboxes = htmlElement.getElementsByTagName("input") as HTMLCollectionOf<HTMLInputElement>;
                            let hasSelection = false;

                            for (let selectBox of multiselectCheckboxes) {
                                if (selectBox.checked) {
                                    hasSelection = true;
                                    break;
                                }
                            }

                            if (!hasSelection) {
                                htmlElement.classList.add("missing-field");
                                htmlElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(htmlElement);
                                showMessage = true;
                                totalErrors++;
                            }

                        }
                    }
                }
            }
        }

        let message = <HTMLDivElement>document.getElementById("validationMessage");

        this.checkEnrollment();

        let graduationReportProfileGrade12EnrollmentElement = <HTMLInputElement>document.getElementById("graduationReportProfileGrade12Enrollment");
        let graduationReportProfileGrade12GraduationElement = <HTMLInputElement>document.getElementById("graduationReportProfileGrade12Graduation");

        const eligibleErrorElement = <HTMLDivElement>document.getElementById("enrollmentErrorEligibleToGraduate");
        const enrollmentErrorElement = <HTMLDivElement>document.getElementById("enrollmentErrorGraduated");

        if (eligibleErrorElement) {
            if (!eligibleErrorElement.classList.contains("hide")) {
                totalErrors++;
                showMessage = true;
                Core.createErrorLabelForInput(graduationReportProfileGrade12EnrollmentElement);
            }
        }

        if (enrollmentErrorElement) {
            if (!enrollmentErrorElement.classList.contains("hide")) {
                totalErrors++;
                showMessage = true;
                Core.createErrorLabelForInput(graduationReportProfileGrade12GraduationElement);
            }
        }


        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        } else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");

        if (goToError !== null) {

            let that = this;

            let firstFocusableEl = <HTMLElement>document.querySelector(".missing-field");

            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");

                        let accordionElement = <HTMLButtonElement>document.getElementById(id);
                        accordionElement.click();
                    }

                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    } else {
                        firstFocusableEl.focus();
                    }
                });
            } else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }

    showGetDataFromPathways(e: Event) {
        const button = <HTMLButtonElement>e.target;
        const planFK = button.dataset.planfk;

        const modal: Modal = new Modal("getPathwaysDataModal", null);
        let getDataConfirmButton = <HTMLButtonElement>document.getElementById("getPathwaysDataConfirm");
        getDataConfirmButton.dataset.planfk = planFK;
        modal.show();
    }

    closeGetDataFromPathways() {
        const modal: Modal = new Modal("getPathwaysDataModal", null);
        modal.hide();
    }

    async getDataFromPathways(e: Event) {
        Core.showLongLoader();
        const button = <HTMLButtonElement>e.target;
        const planFK = button.dataset.planfk;
        let response = await fetch(`/GraduationReport/GetPathwaysData/${planFK}`, { credentials: 'include' })
        if (response.ok) {
            const output = await response.json();

            if (output.success === true && output.pathwaysHasData === true) {
                Core.hideLongLoader();
                this.closeGetDataFromPathways();
                Core.createHTMLAlert("alertMessageDiv", "Data from the Pathways to Graduation tool has been imported. You will need to verify each page for correct data.", "success", 3000, null);
                this._core.pageReload(false, parseInt(planFK));
            } else if (output.success === true && output.pathwaysHasData === false) {
                Core.hideLongLoader();
                this.closeGetDataFromPathways();
                Core.createHTMLAlert("alterMessageDiv", "There wasn't any data from the Pathways to Graduation tool.", "warning", 3000, null);

                const modal: Modal = new Modal("getPathwaysDataModal", null);
                modal.hide();
            } else {
                Core.hideLongLoader();
                this.closeGetDataFromPathways();
                Core.createHTMLAlert("alterMessageDiv", "There was an issue gathering the data from the Pathways to Graduation tool. Please try again.", "error", 3000, null);

                const modal: Modal = new Modal("getPathwaysDataModal", null);
                modal.hide();
            }
        } else {
            Core.hideLongLoader();
            Core.createHTMLAlert("alterMessageDiv", "There was an issue gathering the data from the Pathways to Graduation tool. Please try again.", "error", 3000, null);

            const modal: Modal = new Modal("getPathwaysDataModal", null);
            modal.hide();
        }
    }
}

// Pathways
class GraduationReportPathways {

    validationClasses: string[];

    private _core: Core;
    constructor() {
        let that = this;
        this._core = new Core();

        this.validationClasses = ["graduationReportPathwaysField"];

        let graduationPathwaySaveButton = document.getElementById("graduationReportPathwaysSave");
        if (graduationPathwaySaveButton !== null)
            graduationPathwaySaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);

        const graduationReportWaiversStudentinGrade12Element = <HTMLInputElement>document.getElementById("graduationReportWaiversStudentinGrade12");
            graduationReportWaiversStudentinGrade12Element.addEventListener("input", (e: Event) => this.checkMoreThanFivePercent());

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        const allSlideouts = document.getElementsByClassName("showPathwaySlideout");
        for (const slideout of allSlideouts)
            this.bindSlideout(slideout as HTMLButtonElement);

        this.checkMoreThanFivePercent();

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        this.initializeRequiredFieldsCustom(this.validationClasses);
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.calculateTotal();
            this.calculateAlternativePathways();
            this.calculateEvidencePathwaySectionOnes();
            this.calculateEvidencePathwaySectionTwos();
            this.allGraduateValidation();
            this.allSectionOneAndTwoValidation();
            this.extenuatingCircumstance();
            this.doValidationCustom(this.validationClasses);
        }

        const allGraduatesFields = document.getElementsByClassName("graduationReportPathwaysCalculatedAverageField");
        for (let graduatesField of allGraduatesFields) {
            graduatesField.addEventListener("input", function() {
                that.graduateValidation(<HTMLElement>graduatesField); 
            });   
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let allCheckboxSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("graduationReportPathwaysForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("graduationReportPathwaysField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = "0";
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/GraduationReport/SaveGraduationReportPathways', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.createHTMLAlert("alertMessageDiv", "Page saved successfully! Please wait.", "success", 3000, null);
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    initializeRequiredFieldsCustom(validationClasses: string[]) {
        this._core.initializeRequiredFields(validationClasses);
    }

    doValidationCustom(allClasses: string[], showMessageOverride?: boolean) {
        let showMessage = showMessageOverride === undefined ? this.clientSideValidationCustom(allClasses) : showMessageOverride;
        let messageContainerColumn = <HTMLElement>document.getElementById("validationColumn");
        let messageContainer = <HTMLElement>document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = <HTMLElement>document.getElementById("validationMessageIcon");

        setTimeout(function () {
            messageContainer.focus();
        }, 500);

        if (showMessage) {

            let message = <HTMLElement>document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";

        } else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";

            let successMessage = <HTMLElement>document.getElementById("saveSuccess");

            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved."
            }
        }
    }

    clientSideValidationCustom(allClasses: string[]): boolean {
        let showMessage: boolean = false;
        let totalErrors = 0;

        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });

        let classesToValidate = formattedAllClasses.join(",");

        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });

        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });

        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });

        let allElements = document.querySelectorAll(classesToValidate);

        for (let element of allElements) {
            let htmlElement = <HTMLElement>element;
            if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {
                if (!element.classList.contains("missing-field")) {
                    if (element instanceof HTMLInputElement) {
                        let inputElement = <HTMLInputElement>element;

                        if (inputElement.value === "") {
                            inputElement.classList.add("missing-field");
                            inputElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(inputElement);
                            showMessage = true;
                            totalErrors++;
                        }
                    } else if (element instanceof HTMLTextAreaElement) {
                        let textAreaElement = <HTMLTextAreaElement>element;
                        if (textAreaElement.value === "") {
                            textAreaElement.classList.add("missing-field");
                            textAreaElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(textAreaElement);
                            showMessage = true;
                            totalErrors++;
                        }
                    }
                }
            }
        }

        const totalGraduatesElement = <HTMLInputElement>document.getElementById("graduatedTotalCount");
        const totalGraduates = parseInt(totalGraduatesElement.value);
        if (!isNaN(totalGraduates)) {
            //We need to check several rules here:
            //1. The total of all graduates (for all pathways) (class="graduationReportPathwaysNumberGraduated") is EQUAL to the total graduates listed in the profile. This must include the "pathways" from the Waivers page.
            //2. The total of all graduates in the alternative assessment pathway breakdown (class = "graduationReportPathwaysAlternativePathway") is EQUAL to the total graduates for the pathway (id="graduationReportPathwaysAlternativeGrade12Graduated").
            //3. The total of all graduates in the evidence based pathway breakdown section one (class = "graduationReportPathwaysEvidencePathwaySectionOne") is EQUAL Or Greater than to the total graduates for the pathway (id="graduationReportPathwaysEvidenceGrade12Graduated"").
            //4. The total of all graduates in the evidence based pathway breakdown sections one and two (classes = "graduationReportPathwaysEvidencePathwaySectionOne, graduationReportPathwaysEvidencePathwaySectionTwo") are not more than 3x the total graduates for the pathway.
            //5. The total of all graduates in the extenuating circumstances breakdown (class = "graduationReportWaiverExtenuating") is less than or equal to the total graduates for the pathway (id="graduationReportPathwaysWaiverGrade12Graduated").

            //1. 
            const allGradTotals = document.getElementsByClassName("graduationReportPathwaysNumberGraduated");
            let totalGrads = 0;
            for (const grad of allGradTotals) {
                const ele = <HTMLInputElement>grad;
                let val = parseInt(ele.value);
                if (!isNaN(val))
                    totalGrads += val;
            }

            if (totalGrads !== totalGraduates) {
                totalErrors++;
                showMessage = true;

                for (const grad of allGradTotals) {
                    const ele = <HTMLInputElement>grad;
                    Core.createErrorLabelForInput(ele);
                    ele.classList.add("missing-field");
                    ele.setAttribute("aria-invalid", "true");
                }
            }

            //2.
            const alternativeGradsElement = <HTMLInputElement>document.getElementById("graduationReportPathwaysAlternativeGrade12Graduated");
            const totalAlternativeGrads = parseInt(alternativeGradsElement.value);
            const alternativeBreakdowns = document.getElementsByClassName("graduationReportPathwaysAlternativePathway");
            let totalAlternativeBreakdown = 0;
            for (const alternative of alternativeBreakdowns) {
                const ele = <HTMLInputElement>alternative;

                const val = parseInt(ele.value);

                if (!isNaN(val))
                    totalAlternativeBreakdown += val;
            }

            if (totalAlternativeBreakdown !== totalAlternativeGrads) {
                totalErrors++;
                showMessage = true;

                for (const grad of alternativeBreakdowns) {
                    const ele = <HTMLInputElement>grad;
                    Core.createErrorLabelForInput(ele);
                    ele.classList.add("missing-field");
                    ele.setAttribute("aria-invalid", "true");
                }
            }

            //3.
            const evidenceGradsElement = <HTMLInputElement>document.getElementById("graduationReportPathwaysEvidenceGrade12Graduated");
            const totalEvidenceGrads = parseInt(evidenceGradsElement.value);
            const evidenceBreakdowns = document.getElementsByClassName("graduationReportPathwaysEvidencePathwaySectionOne");
            let totalEvidenceBreakdown = 0;
            for (const evidence of evidenceBreakdowns) {
                const ele = <HTMLInputElement>evidence;

                const val = parseInt(ele.value);

                if (!isNaN(val)) {
                    totalEvidenceBreakdown += val;

                    if (val > totalEvidenceGrads) {
                        Core.createErrorLabelForInput(ele);
                        ele.classList.add("missing-field");
                        ele.setAttribute("aria-invalid", "true");
                        totalErrors++;
                        showMessage = true;
                    }
                }

                
            }

            if (totalEvidenceBreakdown < totalEvidenceGrads) {
                totalErrors++;
                showMessage = true;

                for (const grad of evidenceBreakdowns) {
                    const ele = <HTMLInputElement>grad;
                    Core.createErrorLabelForInput(ele);
                    ele.classList.add("missing-field");
                    ele.setAttribute("aria-invalid", "true");
                }
            }

            //4.
            const evidenceSectionTwoBreakdowns = document.getElementsByClassName("graduationReportPathwaysEvidencePathwaySectionTwo");
            let totalSectionTwoBreakdowns = 0;
            for (const evidenceSectionTwo of evidenceSectionTwoBreakdowns) {
                const ele = <HTMLInputElement>evidenceSectionTwo;

                const val = parseInt(ele.value);
                if (!isNaN(val))
                    totalSectionTwoBreakdowns += val;
            }

            const totalOfTwoSections = totalSectionTwoBreakdowns + totalEvidenceBreakdown;

            if (totalOfTwoSections > (3 * totalEvidenceGrads)) {
                showMessage = true;
                totalErrors++;

                for (const grad of [...evidenceBreakdowns, ...evidenceSectionTwoBreakdowns]) {
                    const ele = <HTMLInputElement>grad;
                    Core.createErrorLabelForInput(ele);
                    ele.classList.add("missing-field");
                    ele.setAttribute("aria-invalid", "true");
                }
            }

            //5.
            const waiverGradsElement = <HTMLInputElement>document.getElementById("graduationReportPathwaysWaiverGrade12Graduated");
            const totalWaiverGrads = parseInt(waiverGradsElement.value);
            const waiverBreakdowns = document.getElementsByClassName("graduationReportWaiverExtenuating");
            let totalWaiverBreakdown = 0;
            for (const waiver of waiverBreakdowns) {
                const ele = <HTMLInputElement>waiver;

                const val = parseInt(ele.value);

                if (!isNaN(val))
                    totalWaiverBreakdown += val;
            }

            if (totalWaiverBreakdown !== totalWaiverGrads) {
                totalErrors++;
                showMessage = true;

                for (const waiver of waiverBreakdowns) {
                    const ele = <HTMLInputElement>waiver;
                    Core.createErrorLabelForInput(ele);
                    ele.classList.add("missing-field");
                    ele.setAttribute("aria-invalid", "true");
                }

                Core.createErrorLabelForInput(waiverGradsElement);
                waiverGradsElement.classList.add("missing-field");
                waiverGradsElement.setAttribute("aria-invalid", "true");
            }
        }


        const message = <HTMLDivElement>document.getElementById("validationMessage");
        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        } else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");

        if (goToError !== null) {

            let that = this;

            let firstFocusableEl = <HTMLElement>document.querySelector(".missing-field");

            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");

                        let accordionElement = <HTMLButtonElement>document.getElementById(id);
                        accordionElement.click();
                    }

                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    } else {
                        firstFocusableEl.focus();
                    }
                });
            } else {
                goToError.parentNode.removeChild(goToError);
            }
        }

        return showMessage;
    }

    allGraduateValidation() {
        const allGraduatesFields = document.getElementsByClassName("graduationReportPathwaysCalculatedAverageField");
        for (let graduatesField of allGraduatesFields)
            this.graduateValidation(<HTMLElement>graduatesField);
    }

    graduateValidation(element: HTMLElement) {
        let elementId = element.id;
        let graduatedElementId = element.dataset.graduated;

        if (elementId === graduatedElementId) {
            let graduatedValue: number = 0;
            let enrollmentValue: number = 0;

            let enrollmentElement = <HTMLInputElement>document.getElementById("graduatedTotalCount");
            if (enrollmentElement && enrollmentElement.value !== "") {
                enrollmentValue = parseInt(enrollmentElement.value);
            }

            let graduatedElement = <HTMLInputElement>element;
            if (graduatedElement && graduatedElement.value !== "") {
                graduatedValue = parseInt(graduatedElement.value);
            }

            if (graduatedValue > enrollmentValue) {
                graduatedElement.classList.add("missing-field");
                let errorElement = <HTMLDivElement>document.querySelector(`.graduatedError[data-graduated='${elementId}']`);
                if (errorElement)
                    errorElement.classList.remove("hide");
            } else {
                let errorElement = <HTMLDivElement>document.querySelector(`.graduatedError[data-graduated='${elementId}']`);
                if (errorElement)
                    errorElement.classList.add("hide");
                graduatedElement.classList.remove("missing-field");
            }
        }

        this.calculatePercentage(element);
    }

    calculatePercentage(element: HTMLElement) {
        const graduatedElementId = element.dataset.graduated;
        const enrollmentElementId = element.dataset.enrollment;
        const percentageElementId = element.dataset.average;

        const graduatedElement = <HTMLInputElement>document.getElementById(graduatedElementId);
        const enrollmentElement = <HTMLInputElement>document.getElementById(enrollmentElementId);
        const percentageElement = <HTMLInputElement>document.getElementById(percentageElementId);

        if (graduatedElement && enrollmentElement && percentageElement) {
            const graduatedValue = parseFloat(graduatedElement.value);
            const enrollmentValue = parseFloat(enrollmentElement.value);
            let percentageValue = parseFloat(percentageElement.value);

            if (!isNaN(enrollmentValue)) {
                const calc = (graduatedValue / enrollmentValue) * 100;
                percentageValue = parseFloat(calc.toFixed(2));
            }

            if (isNaN(percentageValue))
                percentageValue = 0;

            percentageElement.value = percentageValue.toString();
        }
    }

    calculateAlternativePathways() {
        const graduationReportPathwaysAlternativePathwayFields = document.getElementsByClassName("graduationReportPathwaysAlternativePathway");

        let total: number = 0;

        for (const graduationReportPathwaysAlternativePathway of graduationReportPathwaysAlternativePathwayFields) {
            const element = <HTMLInputElement>graduationReportPathwaysAlternativePathway;

            const value = parseInt(element.value);

            if (!isNaN(value))
                total += value;
        }

        const totalGradsElement = <HTMLInputElement>document.getElementById("graduationReportPathwaysAlternativeGrade12Graduated");
        const totalGradsValue = parseInt(totalGradsElement.value);
        const alternativeAssessmentPathwayGraduatedErrorElement = <HTMLDivElement>document.getElementById("alternativeAssessmentPathwayGraduatedError");

        if (!isNaN(totalGradsValue)) {
            if (total !== totalGradsValue) {
                alternativeAssessmentPathwayGraduatedErrorElement.classList.remove("hide");
                for (const graduationReportPathwaysAlternativePathway of graduationReportPathwaysAlternativePathwayFields) {
                    graduationReportPathwaysAlternativePathway.classList.add("missing-field");
                }

            } else {
                alternativeAssessmentPathwayGraduatedErrorElement.classList.add("hide");
                for (const graduationReportPathwaysAlternativePathway of graduationReportPathwaysAlternativePathwayFields) {
                    graduationReportPathwaysAlternativePathway.classList.remove("missing-field");
                }
            }
        }
    }

    calculateEvidencePathwaySectionOnes() {
        const graduationReportPathwaysEvidencePathwayFields = document.getElementsByClassName("graduationReportPathwaysEvidencePathwaySectionOne");
        let total: number = 0;

        for (const graduationReportPathwaysEvidencePathway of graduationReportPathwaysEvidencePathwayFields) {
            const element = <HTMLInputElement>graduationReportPathwaysEvidencePathway;

            const value = parseInt(element.value);

            if (!isNaN(value))
                total += value;
        }

        const totalGradsElement = <HTMLInputElement>document.getElementById("graduationReportPathwaysEvidenceGrade12Graduated");
        const totalGradsValue = parseInt(totalGradsElement.value);
        const evidenceAssessmentPathwayGraduatedErrorElement = <HTMLDivElement>document.getElementById("evidenceAssessmentPathwayGraduatedError");

        if (!isNaN(totalGradsValue)) {
            if (total < totalGradsValue) {
                evidenceAssessmentPathwayGraduatedErrorElement.classList.remove("hide");
                for (const graduationReportPathwaysEvidencePathway of graduationReportPathwaysEvidencePathwayFields) {
                    graduationReportPathwaysEvidencePathway.classList.add("missing-field");
                }

            } else {
                evidenceAssessmentPathwayGraduatedErrorElement.classList.add("hide");
                for (const graduationReportPathwaysEvidencePathway of graduationReportPathwaysEvidencePathwayFields) {
                    graduationReportPathwaysEvidencePathway.classList.remove("missing-field");
                }
            }
        }

        const sectionOneOnlyGreaterThanValidationError = document.getElementById("sectionOneOnlyGreaterThanValidationError");
        for (const graduationReportPathwaysEvidencePathway of graduationReportPathwaysEvidencePathwayFields) {
            const element = <HTMLInputElement>graduationReportPathwaysEvidencePathway;

            const value = parseInt(element.value);

            if (!isNaN(value)) {
                if (value > totalGradsValue)
                    sectionOneOnlyGreaterThanValidationError.classList.remove("hide");
            }
        }


        this.calculateEvidenceBothSections();
    }

    calculateEvidencePathwaySectionTwos() {
        this.calculateEvidenceBothSections();
    }

    sectionOneAndTwoInputValidation(e: HTMLInputElement) {
        const element = e;
        const totalGrads = <HTMLInputElement>document.getElementById("graduatedTotalCount");
        const totalGradsValue = parseInt(totalGrads.value);
        const thisFieldValue = parseInt(element.value);

        const sectionOneAndTwoValidationError = <HTMLDivElement>document.getElementById("sectionOneAndTwoValidationError");
        if (totalGradsValue < thisFieldValue) {
            sectionOneAndTwoValidationError.classList.remove("hide");
        } else {
            sectionOneAndTwoValidationError.classList.add("hide");
        }
    }

    calculateEvidenceBothSections() {
        const graduationReportPathwaysEvidencePathwayFields = document.getElementsByClassName("graduationReportPathwaysEvidencePathway");
        let total: number = 0;

        for (const graduationReportPathwaysEvidencePathway of graduationReportPathwaysEvidencePathwayFields) {
            const element = <HTMLInputElement>graduationReportPathwaysEvidencePathway;

            const value = parseInt(element.value);

            if (!isNaN(value))
                total += value;
        }

        const totalGradsElement = <HTMLInputElement>document.getElementById("graduationReportPathwaysEvidenceGrade12Graduated");
        const totalGradsValue = parseInt(totalGradsElement.value);
        const evidenceAssessmentPathwayGraduatedErrorElement = <HTMLDivElement>document.getElementById("evidenceAssessmentPathwayGraduatedCombinedError");

        if (!isNaN(totalGradsValue)) {
            const highestGradsValue = totalGradsValue * 3;

            if (total > highestGradsValue) {
                evidenceAssessmentPathwayGraduatedErrorElement.classList.remove("hide");
            } else {
                evidenceAssessmentPathwayGraduatedErrorElement.classList.add("hide");
            }
        }
    }

    bindSlideout(button: HTMLButtonElement) {
        const slideoutId = button.dataset.slideout;
        const callingId = button.id;
        const slideout = new Slideout(slideoutId, callingId);
        button.addEventListener("click", (e: Event) => slideout.show());
        button.addEventListener("keypress", (e: Event) => { if (Core.a11yClick(e as KeyboardEvent)) { slideout.show(); } });
    }

    calculateTotal() {
        const calculateTotalFields = document.getElementsByClassName("graduationReportPathwaysNumberGraduated");
        let total: number = 0;
        for (const totalField of calculateTotalFields) {
            const element = <HTMLInputElement>totalField;
            const thisVal = parseInt(element.value);

            if (!isNaN(thisVal))
                total += thisVal;
        }

        if (total > 0) {
            const graduatedTotalCountElement = <HTMLInputElement>document.getElementById("graduatedTotalCount");
            const graduatedTotalCount = parseInt(graduatedTotalCountElement.value);

            const graduationExceedsEnrollmentErrorElement = <HTMLDivElement>document.getElementById("graduationExceedsEnrollmentError");

            if (total !== graduatedTotalCount) {
                graduationExceedsEnrollmentErrorElement.classList.remove("hide");
                for (const totalField of calculateTotalFields) {
                    totalField.classList.add("missing-field");
                }
            } else {
                graduationExceedsEnrollmentErrorElement.classList.add("hide");
                for (const totalField of calculateTotalFields) {
                    totalField.classList.remove("missing-field");
                }
            }
        }
    }

    checkMoreThanFivePercent() {
        const graduationReportWaiversStudentinGrade12Element = <HTMLInputElement>document.getElementById("graduationReportWaiversStudentinGrade12");
        const graduationReportWaiversStudentinGrade12Value = parseInt(graduationReportWaiversStudentinGrade12Element.value);
        const graduationReportPathwaysWaiverGrade12EnrollmentElement = <HTMLInputElement>document.getElementById("graduationReportPathwaysWaiverGrade12Enrollment");
        const graduationReportPathwaysWaiverGrade12EnrollmentValue = parseInt(graduationReportPathwaysWaiverGrade12EnrollmentElement.value);

        if (!isNaN(graduationReportWaiversStudentinGrade12Value) && !isNaN(graduationReportPathwaysWaiverGrade12EnrollmentValue)) {
            const percent = graduationReportWaiversStudentinGrade12Value / graduationReportPathwaysWaiverGrade12EnrollmentValue;

            const allOverFiveFields = document.getElementsByClassName("graduationReportWaiversOverFiveField");

            const container = <HTMLDivElement>document.getElementById("moreThanFivePercent");

            if (percent > 0.05) {
                for (const field of allOverFiveFields) {
                    const ele = <HTMLTextAreaElement>field;
                    this._core.forceElementRequired(ele);
                }

                container.classList.remove("hide");
            } else {
                for (const field of allOverFiveFields) {
                    const ele = <HTMLTextAreaElement>field;
                    this._core.forceElementOptional(ele);
                }

                container.classList.add("hide");
            }
        }
    }

    allSectionOneAndTwoValidation() {
        const graduationReportPathwaysEvidencePathwayFields = document.getElementsByClassName("graduationReportPathwaysEvidencePathwaySectionOne");
        for (const graduationReportPathwaysEvidencePathway of graduationReportPathwaysEvidencePathwayFields)
            this.sectionOneAndTwoInputValidation(graduationReportPathwaysEvidencePathway as HTMLInputElement);

        const graduationReportPathwaysEvidencePathwaySectionTwoFields = document.getElementsByClassName("graduationReportPathwaysEvidencePathwaySectionTwo");
        for (const graduationReportPathwaysEvidencePathwaySectionTwo of graduationReportPathwaysEvidencePathwaySectionTwoFields)
            this.sectionOneAndTwoInputValidation(graduationReportPathwaysEvidencePathwaySectionTwo as HTMLInputElement);
    }

    extenuatingCircumstance() {
        const graduationReportPathwaysWaiverGrade12GraduatedElement = <HTMLInputElement>document.getElementById("graduationReportPathwaysWaiverGrade12Graduated");
        const graduationReportPathwaysWaiverGrade12GraduatedValue = parseInt(graduationReportPathwaysWaiverGrade12GraduatedElement.value);

        const allExtenuatingInputs = document.getElementsByClassName("graduationReportWaiverExtenuating");
        let totalExtenuating = 0;
        for (const extenuating of allExtenuatingInputs) {
            const element = <HTMLInputElement>extenuating;
            const thisVal = parseInt(element.value);

            if (!isNaN(thisVal))
                totalExtenuating += thisVal;
        }

        const waiverGraduationErrorElement = <HTMLDivElement>document.getElementById("waiverGraduationError");

        if (!isNaN(graduationReportPathwaysWaiverGrade12GraduatedValue)) {
            if (totalExtenuating !== graduationReportPathwaysWaiverGrade12GraduatedValue) {
                for (const extenuating of allExtenuatingInputs) {
                    const element = <HTMLInputElement>extenuating;
                    element.classList.add("missing-field");
                }
                waiverGraduationErrorElement.classList.remove("hide");
            } else {
                for (const extenuating of allExtenuatingInputs) {
                    const element = <HTMLInputElement>extenuating;
                    element.classList.remove("missing-field");
                }
                waiverGraduationErrorElement.classList.add("hide");
            }
        }
    }
}

// Graduation Pathway Percentages
class GraduationReportGraduationPathwayPercentages {

    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["graduationReportPathwayPercentagesField"];

        let graduationPathwayPercentagesSaveButton = document.getElementById("graduationReportGraduationPathwayPercentagesSave");
        if (graduationPathwayPercentagesSaveButton !== null)
            graduationPathwayPercentagesSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);

        const graduationReportPathwayPercentagesNonGraduatesEligibleElement = document.getElementById("graduationReportPathwayPercentagesNonGraduatesEligible");
        if (graduationReportPathwayPercentagesNonGraduatesEligibleElement !== null)
            graduationReportPathwayPercentagesNonGraduatesEligibleElement.addEventListener("input", (e: Event) => this.checkGradNumber());

        this.checkGradNumber();

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.doValidationCustom(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        const allSlideouts = document.getElementsByClassName("showPathwaySlideout");
        for (const slideout of allSlideouts)
            this.bindSlideout(slideout as HTMLButtonElement);
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];

        let formElement = document.getElementById("graduationReportGraduationPathwayPercentagesForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("graduationReportPathwayPercentagesField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }
                                                           
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/GraduationReport/SaveGraduationPathwayPercentages', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    bindSlideout(button: HTMLButtonElement) {
        const slideoutId = button.dataset.slideout;
        const callingId = button.id;
        const slideout = new Slideout(slideoutId, callingId);
        button.addEventListener("click", (e: Event) => slideout.show());
        button.addEventListener("keypress", (e: Event) => { if (Core.a11yClick(e as KeyboardEvent)) { slideout.show(); } });
    }

    checkGradNumber() {
        const numberNotGraduatedElement = <HTMLInputElement>document.getElementById("numberNotGraduated");
        const numberNotGraduatedValue = parseInt(numberNotGraduatedElement.value)
        const numberNotGraduatedErrorElement = <HTMLDivElement>document.getElementById("numberNotGraduatedError");
        const graduationReportPathwayPercentagesNonGraduatesEligibleElement = <HTMLInputElement>document.getElementById("graduationReportPathwayPercentagesNonGraduatesEligible");
        const graduationReportPathwayPercentagesNonGraduatesEligibleValue = parseInt(graduationReportPathwayPercentagesNonGraduatesEligibleElement.value);

        if (!isNaN(graduationReportPathwayPercentagesNonGraduatesEligibleValue) && !isNaN(numberNotGraduatedValue)) {
            if (graduationReportPathwayPercentagesNonGraduatesEligibleValue > numberNotGraduatedValue) {
                numberNotGraduatedErrorElement.classList.remove("hide");

                graduationReportPathwayPercentagesNonGraduatesEligibleElement.classList.add("missing-field");
                graduationReportPathwayPercentagesNonGraduatesEligibleElement.setAttribute("aria-invalid", "true");
                Core.createErrorLabelForInput(graduationReportPathwayPercentagesNonGraduatesEligibleElement);
            } else {
                numberNotGraduatedErrorElement.classList.add("hide");

                graduationReportPathwayPercentagesNonGraduatesEligibleElement.classList.remove("missing-field");
                graduationReportPathwayPercentagesNonGraduatesEligibleElement.setAttribute("aria-invalid", "false");
                Core.removeErrorLabelForInput(graduationReportPathwayPercentagesNonGraduatesEligibleElement);
            }
        }
    }

    doValidationCustom(allClasses: string[], showMessageOverride ?: boolean) {
        let showMessage = showMessageOverride === undefined ? this.clientSideValidationCustom(allClasses) : showMessageOverride;
        let messageContainerColumn = <HTMLElement>document.getElementById("validationColumn");
        let messageContainer = <HTMLElement>document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = <HTMLElement>document.getElementById("validationMessageIcon");

        setTimeout(function () {
            messageContainer.focus();
        }, 500);

        if (showMessage) {

            let message = <HTMLElement>document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";

        } else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";

            let successMessage = <HTMLElement>document.getElementById("saveSuccess");

            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved."
            }
        }
    }

    clientSideValidationCustom(allClasses: string[]): boolean {
        let showMessage: boolean = false;
        let totalErrors = 0;

        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });

        let classesToValidate = formattedAllClasses.join(",");

        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });

        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });

        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });

        let allElements = document.querySelectorAll(classesToValidate);

        for (let element of allElements) {
            let htmlElement = <HTMLElement>element;
            if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {
                if (!element.classList.contains("missing-field")) {
                    //For more custom validation, use data-is-valid to specify whether a field is valid/invalid
                    if ("isValid" in htmlElement.dataset) {
                        if (htmlElement.dataset.isValid === "false") {
                            htmlElement.classList.add("missing-field");
                            htmlElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(htmlElement);
                            showMessage = true;
                            totalErrors++;
                        }
                    } else {
                        if (element instanceof HTMLInputElement) {
                            let inputElement = <HTMLInputElement>element;

                            if (inputElement.value === "") {
                                inputElement.classList.add("missing-field");
                                inputElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(inputElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        }
                    }
                }
            }
        }

        const numberNotGraduatedElement = <HTMLInputElement>document.getElementById("numberNotGraduated");
        const numberNotGraduatedValue = parseInt(numberNotGraduatedElement.value)
        const numberNotGraduatedErrorElement = <HTMLDivElement>document.getElementById("numberNotGraduatedError");
        const graduationReportPathwayPercentagesNonGraduatesEligibleElement = <HTMLInputElement>document.getElementById("graduationReportPathwayPercentagesNonGraduatesEligible");
        const graduationReportPathwayPercentagesNonGraduatesEligibleValue = parseInt(graduationReportPathwayPercentagesNonGraduatesEligibleElement.value);

        if (!isNaN(graduationReportPathwayPercentagesNonGraduatesEligibleValue) && !isNaN(numberNotGraduatedValue)) {
            if (graduationReportPathwayPercentagesNonGraduatesEligibleValue > numberNotGraduatedValue) {
                numberNotGraduatedErrorElement.classList.remove("hide");

                graduationReportPathwayPercentagesNonGraduatesEligibleElement.classList.add("missing-field");
                graduationReportPathwayPercentagesNonGraduatesEligibleElement.setAttribute("aria-invalid", "true");
                Core.createErrorLabelForInput(graduationReportPathwayPercentagesNonGraduatesEligibleElement);
                totalErrors++;
                showMessage = true;
            } 
        }

        const totalGradErrorElement = <HTMLDivElement>document.getElementById("totalGradError");
        if (totalGradErrorElement !== null) {
            if (!totalGradErrorElement.classList.contains("hide")) {
                showMessage = true;
                totalErrors++;
            }
        }


        let message = <HTMLDivElement>document.getElementById("validationMessage");

        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        } else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");

        if (goToError !== null) {

            let that = this;

            let firstFocusableEl = <HTMLElement>document.querySelector(".missing-field");

            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");

                        let accordionElement = <HTMLButtonElement>document.getElementById(id);
                        if (!accordionElement.classList.contains("open")) {
                            accordionElement.click();
                        }
                    }

                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    } else {
                        firstFocusableEl.focus();
                    }
                });
            } else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }
}

//Signatures and Assurances
class GraduationReportSignaturesAndAssurances {

    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["graduationReportSignaturesAndAssurancesCheckboxField", "graduationReportSignaturesAndAssurancesField"];

        let signaturesSaveButton = document.getElementById("graduationReportSignaturesAndAssurancesSave");
        if (signaturesSaveButton !== null)
            signaturesSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        let fileUploader = document.getElementById("graduationReportSignaturesAndAssurancesBoardGraduationPolicy");
        if (fileUploader !== null) {
            fileUploader.addEventListener("change", (e: Event) => this.uploadFile(<HTMLInputElement>e.target));
        }

        this._core.leftnav(this);

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let deleteFileConfirmButton = <HTMLButtonElement>document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e: Event) => this.deleteFileConfirm(deleteFileConfirmButton));

        let deleteFileCancelButton = <HTMLButtonElement>document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e: Event) => this.deleteFileCancel());

        let deleteFileButtons = document.getElementsByClassName("deleteFile") as HTMLCollectionOf<HTMLButtonElement>;
        for (let deleteButton of deleteFileButtons)
            deleteButton.addEventListener("click", (e: Event) => this.deleteFile(e));


        let exports = document.getElementsByClassName("fullReport") as HTMLCollectionOf<HTMLLinkElement>;
        for (let exportOption of exports) {
            exportOption.addEventListener("click", function () {
                Core.showLongLoader();

                //Get all components for given template
                let xhr = new XMLHttpRequest();
                xhr.open('POST', '/ExportDocx/FullReportgraduationrequirementsExport', true);
                xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
                xhr.responseType = 'blob';
                xhr.onload = function () {

                    Core.hideLongLoader();

                    if (xhr.status === 200) {
                        let blob = this.response;
                        let filename = exportOption.textContent;
                        filename = filename + ".docx";

                        if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                            window.navigator.msSaveBlob(blob, filename);
                        } else {
                            var a = <HTMLAnchorElement>document.createElement("a");
                            var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                            document.body.appendChild(a);
                            a.style.display = "none";
                            a.href = blobUrl;
                            a.download = filename;
                            a.click();
                        }
                    } else {
                        Core.createHTMLAlert("alertMessageDiv", "There was an issue generating this report. Please try again later.", 'error', 3000, null);
                    }
                }
                xhr.send("planFK=" + exportOption.dataset.planfk);
            });
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    uploadFile(e: HTMLInputElement) {
        let core = this._core;
        Core.showLoader();
        let formName = e.dataset.formname;

        let uploadSampleForm = <HTMLFormElement>document.getElementById(formName);
        let formData = new FormData(uploadSampleForm);

        let propertyPK = e.dataset.propertypk;

        let that = this;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/GraduationReport/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();

                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);

                        let formFile = <HTMLInputElement>document.getElementById(`${e.id}`);
                        formFile.value = "";

                        let fileList = document.getElementById("uploadedFiles");

                        let currentFileList = fileList.querySelectorAll(".uploadFileList");

                        let sequence = -1;
                        for (let file of currentFileList) {
                            let fileEle = <HTMLDivElement>file;

                            let thisSequence = parseInt(fileEle.dataset.sequencenbr);
                            if (thisSequence > sequence) {
                                sequence = thisSequence;
                            }
                        }

                        let uploadedContainer = document.getElementById("uploadedFiles");
                        uploadedContainer.classList.remove("hide");

                        sequence++;

                        let fileWrapper = <HTMLDivElement>document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = <HTMLDivElement>document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = <HTMLAnchorElement>document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.dataset.sequencenbr = sequence.toString();
                        fileA.dataset.propertypk = propertyPK;
                        fileA.classList.add("graduationReportSignaturesAndAssurancesFile");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);

                        fileList.appendChild(fileWrapper);

                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);

    }

    deleteFile(e: Event) {
        let fileButton = <HTMLButtonElement>e.target;
        let planPropertyFilePK = fileButton.dataset.planpropertyfilepk;

        if (planPropertyFilePK && parseInt(planPropertyFilePK) > 0) {
            let modal: Modal = new Modal("deleteFileModal", null);
            let deleteConfirmButton = <HTMLButtonElement>document.getElementById("deleteFileConfirm");
            deleteConfirmButton.dataset.planpropertyfilepk = planPropertyFilePK;
            modal.show();
        }
    }

    deleteFileConfirm(confirmButton: HTMLButtonElement) {
        let core = this._core;
        Core.showLoader();

        let planPropertyFilePK = confirmButton.dataset.planpropertyfilepk;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/GraduationReport/DeleteFiles', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file has been successfully deleted.", 'success', 3000, null);

                        let containerElement = <HTMLElement>document.querySelector(`.uploadFileColumn[data-planpropertyfilepk='${planPropertyFilePK}']`);
                        if (containerElement !== null) {
                            containerElement.parentNode.removeChild(containerElement);
                        }

                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting file.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file removal failed. Please try again.", 'error', 3000, null);
            }

            let modal: Modal = new Modal("deleteFileModal", null);
            modal.hide();
        };
        xhr.send(JSON.stringify([planPropertyFilePK]));
    }

    deleteFileCancel() {
        let modal: Modal = new Modal("deleteFileModal", null);
        modal.hide();
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];

        let formElement = document.getElementById("graduationReportSignaturesAndAssurancesForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let checkInputs = document.getElementsByClassName("graduationReportSignaturesAndAssurancesCheckboxField");
        for (let ele of checkInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let row = element.dataset.row;
            let rowNumber = 0;
            if (row)
                rowNumber = parseInt(element.dataset.row);
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.checked || hadValue) {
                let val = "";
                if (element.checked)
                    val = "on";
                else
                    val = "off";

                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: rowNumber,
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let textInputs = document.getElementsByClassName("graduationReportSignaturesAndAssurancesField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let allFileElements = [];

        let files = document.getElementsByClassName("graduationReportSignaturesAndAssurancesFile");
        for (let ele of files) {
            let element = <HTMLInputElement>ele;

            let sequenceNbr = parseInt(element.dataset.sequencenbr);
            let fileUploadPK = parseInt(element.dataset.fileuploadpk);
            let propertyPK = parseInt(element.dataset.propertypk);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk);

            let saveItem: IPlanPropertyFile = {
                SequenceNbr: sequenceNbr,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanPropertyFilePK: planPropertyFilePK,
                PlanFK: planPK
            };

            allFileElements.push(saveItem);
        }

        var allData = {
            "ElementData": allSaveElements,
            "FileData": allFileElements
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/GraduationReport/SaveGraduationReportSignaturesAndAssurances', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }
}

//Summary Checklist and Submission
class GraduationReportSummaryChecklistAndSubmission {

    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.back(e));

        this._core.leftnav(this);

        let submitButton = document.getElementById("submitPlanButton");
        if (submitButton !== null)
            submitButton.addEventListener("click", (e: Event) => this.submit(e));

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        //this._core.initializeRequiredFields(this.validationClasses);
    }

    back(e: Event) {
        let newPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;

        //Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }

        if (refreshPage && refreshPage !== "")
            window.location.href = refreshPage;
        else
            Core.hideLoader();
    }

    submit(e: Event) {
        let element = <HTMLButtonElement>e.target;
        if (!element.classList.contains("disabled")) {
            let planFK = 0;
            planFK = parseInt(element.dataset.planfk);
            let core = this._core;
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/GraduationReport/SubmitPlan', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "This report has been submitted for review!", 'success', 3000, window.location.reload());
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status + "'", 'error', 3000, null);
                }
            };
            xhr.send("planFK=" + planFK);
        }
    }
}