/// <reference path="../core.ts" />

class Tab {
    container: HTMLElement;
    tabs: TabComponent[];

    constructor(container: HTMLElement) {
        this.container = container;
        this.tabs = [];
        let tabTriggers = container.querySelectorAll("[data-tab-trigger][data-tab-content]");

        if (tabTriggers.length > 0) {
            let triggerIndex = 0;
            for (let trigger of tabTriggers) {
                let triggerElement = <HTMLElement>trigger;
                let tabContent = document.getElementById(triggerElement.dataset.tabContent);
                if (tabContent != null) {
                    let tabContentElement = <HTMLElement>tabContent;
                    let tabComponent = new TabComponent(triggerElement, tabContentElement);
                    if (triggerIndex == 0) {
                        tabComponent.Show();
                    }
                    else {
                        tabComponent.Hide();
                    }
                    this.tabs.push(tabComponent);
                    triggerIndex++;
                } 
            }
        }

        //Add event listeners to triggers
        for (let tabComponent of this.tabs) {
            tabComponent.tabTrigger.addEventListener("click", () => {
                for (let otherTabComponent of this.tabs) {
                    otherTabComponent.Hide();
                }

                tabComponent.Show();
            });
        }
    }

    //Initialize all Tab in the DOM
    static Init() {
        let tabContainers = document.querySelectorAll("[data-tab-container]");
        for (let tabContainer of tabContainers) {
            let tabContainerElement = <HTMLElement>tabContainer;
            let tab = new Tab(tabContainerElement);
        }
    }
}

class TabComponent {
    tabTrigger: HTMLElement;
    tabContent: HTMLElement;
    isActive: boolean;

    constructor(trigger: HTMLElement, content: HTMLElement) {
        this.tabTrigger = trigger;
        this.tabContent = content;
        this.isActive = false;
    }

    Hide() {
        this.tabTrigger.classList.remove("active");
        this.tabContent.classList.add("hide");
        this.isActive = false;
    }

    Show() {
        this.tabTrigger.classList.add("active");
        this.tabContent.classList.remove("hide");
        this.isActive = true;
    }
}