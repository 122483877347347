/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />

// CatsSystemContactInformation
class CatsSystemContactInformation {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["catsSystemContactInformationField", "catsSystemContactInformationSelectField"];

        let catsSystemContactInformationSaveButton = document.getElementById("catsSystemContactInformationSave");
        if (catsSystemContactInformationSaveButton !== null)
            catsSystemContactInformationSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        let catsSystemExecutiveSaveButton = document.getElementById("executiveSave");
        if (catsSystemExecutiveSaveButton !== null)
            catsSystemExecutiveSaveButton.addEventListener("click", (e: Event) => this.executiveSave("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let refreshButton = document.getElementById("resetProfile");
        if (refreshButton !== null) {
            refreshButton.addEventListener("click", (e: Event) => this.refreshProfile());
        }
        let phoneNumber = document.getElementById("catsSystemContactInfornationCatsPhoneNumber");
        if (phoneNumber !== null) {
            phoneNumber.addEventListener("blur", (e: Event) => this.maskPhoneNumber(e));
        }

        this.saveAccess();
    }

    maskPhoneNumber(e: Event) {
        let element = <HTMLInputElement>e.target;
        let x = element.value.replace(/\D/g, '').match(/(\d{3})(\d{3})(\d{4})/);
        element.value = '(' + x[1] + ') ' + x[2] + '-' + x[3];
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("catsSystemContactInformationForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("catsSystemContactInformationField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let selectInputs = document.getElementsByClassName("catsSystemContactInformationSelectField");
        for (let ele of selectInputs) {
            let planPropertyPK = 0;
            let element = <HTMLSelectElement>ele;
            let rowNumber = "0";
            if (element.dataset.row !== null)
                rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if ((element.value !== "" && element.value !== "0") || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: null,
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemContactInformation', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    executiveSave(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("catsSystemContactInformationForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("profileICDataSave");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemContactInformation', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    refreshProfile() {
        const formName = "catsSystemContactInformationForm";
        Core.showLoader();
        let planFK = 0;
        let form = document.getElementById(formName);
        planFK = parseInt(form.dataset.planfk);

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/GetICDataForContact', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {

                const leaNameElement = <HTMLInputElement>document.querySelector(".profileICData[data-field='LEAName']");
                const leaAUNElement = <HTMLInputElement>document.querySelector(".profileICData[data-field='LEAAUN']");
                const schoolNameElement = <HTMLInputElement>document.querySelector(".profileICData[data-field='SchoolName']");
                const schoolNumberElement = <HTMLInputElement>document.querySelector(".profileICData[data-field='SchoolNumber']");
                const schoolCountyElement = <HTMLInputElement>document.querySelector(".profileICData[data-field='SchoolCounty']");
                const iuElement = <HTMLInputElement>document.querySelector(".profileICData[data-field='IU']");
                const schoolAddressLine1Element = <HTMLInputElement>document.querySelector(".profileICData[data-field='SchoolAddress1']");
                const schoolAddressLine2Element = <HTMLInputElement>document.querySelector(".profileICData[data-field='SchoolAddress2']");
                const schoolCityElement = <HTMLInputElement>document.querySelector(".profileICData[data-field='SchoolCity']");
                const schoolStateElement = <HTMLInputElement>document.querySelector(".profileICData[data-field='SchoolState']");
                const schoolZipCodeElement = <HTMLInputElement>document.querySelector(".profileICData[data-field='SchoolZip']");
                const leaPhoneElement = <HTMLInputElement>document.querySelector(".profileICData[data-field='LEAPhone']");
                const leaPhoneExtElement = <HTMLInputElement>document.querySelector(".profileICData[data-field='LEAPhoneExt']");
                const schoolPhoneElement = <HTMLInputElement>document.querySelector(".profileICData[data-field='SchoolPhone']");
                const schoolPhoneExtElement = <HTMLInputElement>document.querySelector(".profileICData[data-field='SchoolPhoneExt']");
                
                const instDetails = JSON.parse(xhr.response);

                if (leaNameElement)
                    leaNameElement.value = instDetails.leaInstDetails.instName;
                if (leaAUNElement)
                    leaAUNElement.value = instDetails.leaInstDetails.auNumber;
                if (schoolNameElement)
                    schoolNameElement.value = instDetails.schoolInstDetails.instName;
                if (schoolNumberElement)
                    schoolNumberElement.value = instDetails.schoolInstDetails.pubSchOrBranchNumber;
                if (schoolCountyElement)
                    schoolCountyElement.value = instDetails.schoolInstDetails.countyName;
                if (iuElement)
                    iuElement.value = instDetails.iuInstDetails.instName;
                if (schoolAddressLine1Element)
                    schoolAddressLine1Element.value = instDetails.schoolInstDetails.addressLine1;
                if (schoolAddressLine2Element)
                    schoolAddressLine2Element.value = instDetails.schoolInstDetails.addressLine2;
                if (schoolCityElement)
                    schoolCityElement.value = instDetails.schoolInstDetails.city;
                if (schoolStateElement)
                    schoolStateElement.value = instDetails.schoolInstDetails.state;
                if (schoolZipCodeElement)
                    schoolZipCodeElement.value = instDetails.schoolInstDetails.zipCode;
                if (leaPhoneElement)
                    leaPhoneElement.value = instDetails.leaInstDetails.phoneNumber;
                if (leaPhoneExtElement)
                    leaPhoneExtElement.value = instDetails.leaInstDetails.phoneNumberExtn;
                if (schoolPhoneElement)
                    schoolPhoneElement.value = instDetails.schoolInstDetails.phoneNumber;
                if (schoolPhoneExtElement)
                    schoolPhoneExtElement.value = instDetails.schoolInstDetails.phoneNumberExtn;

                const superNameElement = <HTMLInputElement>document.querySelector(".profileICData[data-field='SuperName']");
                const superTitleElement = <HTMLInputElement>document.querySelector(".profileICData[data-field='SuperTitle']");
                const superPhoneElement = <HTMLInputElement>document.querySelector(".profileICData[data-field='SuperPhone']");
                const superPhoneExtElement = <HTMLInputElement>document.querySelector(".profileICData[data-field='SuperPhoneExt']");
                const superEmailElement = <HTMLInputElement>document.querySelector(".profileICData[data-field='SuperEmail']");
                
                if (superNameElement)
                    superNameElement.value = instDetails.administratorInfo.adminName;
                if (superTitleElement)
                    superTitleElement.value = instDetails.administratorInfo.jobTitle;
                if (superPhoneElement)
                    superPhoneElement.value = instDetails.administratorInfo.phoneNumber;
                if (superEmailElement)
                    superEmailElement.value = instDetails.administratorInfo.email;

                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Contact Information has been refreshed, please remember to save the changes.", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(`planFK=${planFK}`);
    }

    saveAccess() {
        const formName = "catsSystemContactInformationForm";
        let planFK = 0;
        let form = document.getElementById(formName);
        planFK = parseInt(form.dataset.planfk);

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemAccess', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {

            }
        };
        xhr.send(`planFK=${planFK}`);
    }
}

// CatsSystemCommonSupportService
class CatsSystemCommonSupportService {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["catsSystemCommonSupportServiceField", "catsSystemStudentTechnicalFileField", "catsSystemCommonSupportServiceRadioField"];

        let catsSystemCommonSupportServiceSaveButton = document.getElementById("catsSystemCommonSupportServiceSave");
        if (catsSystemCommonSupportServiceSaveButton !== null)
            catsSystemCommonSupportServiceSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this.checkForCooperative();

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses, false, true);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        const fileCourseCatalog = document.querySelector(`.catsSystemStudentTechnicalFileField[data-file='coursecatalog']`);
        if (fileCourseCatalog !== null)
            fileCourseCatalog.addEventListener("change", (e: Event) => this.uploadFile(<HTMLInputElement>e.target, "coursecatalog"));

        let deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e: Event) => this.deleteFileConfirm(e));

        let deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e: Event) => this.deleteFileCancel());

        this.bindDeleteFiles();
        this.saveAccess();

        const catsSystemCommonSupportCoops = document.getElementsByClassName("catsSystemCommonSupportCoop");
        for (const catsSystemCommonSupportCoop of catsSystemCommonSupportCoops)
            catsSystemCommonSupportCoop.addEventListener("change", (e: Event) => this.checkForCooperative());

        const addCooperativeRowButton = <HTMLButtonElement>document.getElementById("addCooperativeRow");
        if (addCooperativeRowButton !== null)
            addCooperativeRowButton.addEventListener("click", (e: Event) => this.addCooperativeRow());

        this.bindDeleteRows();

        let deleteCooperativeRowConfirmButton = document.getElementById("catsSystemCooperativeDeleteConfirm");
        if (deleteCooperativeRowConfirmButton !== null)
            deleteCooperativeRowConfirmButton.addEventListener("click", (e: Event) => this.deleteCooperativeRowConfirm(e));

        let deleteCooperativeRowCancelButton = document.getElementById("catsSystemCooperativeDeleteCancel");
        if (deleteCooperativeRowCancelButton !== null)
            deleteCooperativeRowCancelButton.addEventListener("click", (e: Event) => this.deleteCooperativeRowCancel());

        this.bindTeacherCerts();
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("catsSystemCommonSupportServiceForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("catsSystemCommonSupportServiceField");

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let allFileElements: IPlanPropertyFile[] = [];
        let files = document.getElementsByClassName("catsSystemStudentTechnicalFileUploadField");
        for (let ele of files) {
            let element = <HTMLInputElement>ele;
            let sequenceNbr = parseInt(element.dataset.sequencenbr);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk)
            let fileUploadPK = parseInt(element.dataset.fileuploadpk);
            let propertyPK = parseInt(element.dataset.propertypk);

            let saveItem: IPlanPropertyFile = {
                PlanPropertyFilePK: planPropertyFilePK,
                SequenceNbr: sequenceNbr,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanFK: planPK
            };

            allFileElements.push(saveItem);
        }

        const radios = document.getElementsByClassName("catsSystemCommonSupportServiceRadioField");
        for (const ele of radios) {
            let planPropertyPK = 0;
            const element = <HTMLInputElement>ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            const dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);

            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                if (element.value !== "" || hadValue) {
                    let saveItem: IPlanPropertyExtended = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "",
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                        ButtonRow: 0
                    };

                    allSaveElements.push(saveItem);
                }
            }
        }

        var allData = {
            "ElementData": allSaveElements,
            "FileData": allFileElements,
            "PlanFK": planPK
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemCommonSupportService', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }

    saveAccess() {
        const formName = "catsSystemCommonSupportServiceForm";
        let planFK = 0;
        let form = document.getElementById(formName);
        planFK = parseInt(form.dataset.planfk);

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemAccess', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {

            }
        };
        xhr.send(`planFK=${planFK}`);
    }

    uploadFile(e: HTMLInputElement, fileType) {
        Core.showLoader();
        const formName = e.dataset.formname;
        const uploadSampleForm = <HTMLFormElement>document.getElementById(formName);
        const formData = new FormData(uploadSampleForm);
        const propertyPK = e.dataset.propertypk;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();

                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);

                        const formFile = <HTMLInputElement>document.querySelector(`.catsSystemStudentTechnicalFileField[data-formname='${formName}']`);
                        formFile.value = "";

                        const fileList = document.querySelector(`.uploadFileList[data-file='${fileType}']`)

                        let uploadedContainer = document.querySelector(`.uploadedFiles[data-file='${fileType}']`);
                        uploadedContainer.classList.remove("hide");

                        let fileWrapper = <HTMLDivElement>document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = <HTMLDivElement>document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = <HTMLAnchorElement>document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.sequencenbr = "0";
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.dataset.save = fileType;
                        fileA.classList.add("catsSystemStudentTechnicalFileUploadField");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);

                        fileList.appendChild(fileWrapper);
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }

    bindDeleteFiles() {
        let deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (let deleteFileButton of deleteFileButtons)
            deleteFileButton.addEventListener("click", (e: Event) => this.showDeleteFile(e));
    }

    showDeleteFile(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let row = buttonElement.dataset.row;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;

        let modal: Modal = new Modal("deleteFileModal", null);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "row", row);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "planpropertyfilepk", planPropertyFilePK);
        modal.show();
    }

    deleteFileConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        let row = buttonElement.dataset.row;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/DeleteFileData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    let element = document.querySelector(`.catsSystemStudentTechnicalFileUploadField[data-planpropertyfilepk='${planPropertyFilePK}'][data-row='${row}']`);
                    let parent = element.parentElement.parentElement;
                    parent.remove();

                    const moreFiles = document.querySelectorAll(`.uploadFileList[data-row='${row}'] .uploadFileColumn`);
                    if (moreFiles.length === 0) {
                        const uploadsElement = <HTMLDivElement>document.getElementById(`uploadedFiles${row}`);
                        if (uploadsElement !== null) {
                            uploadsElement.classList.add("hide");
                        }
                    }

                    Core.createHTMLAlert("alertMessageDiv", "File successfully removed.", 'success', 3000, null);
                } else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the file. Please try again.", 'error', 3000, null);
                }

                Core.hideLoader();

                let modal: Modal = new Modal("deleteFileModal", null);
                modal.hide();
            } else {
                let modal: Modal = new Modal("deleteFileModal", null);
                modal.hide();

                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planPropertyFilePK));
    }

    deleteFileCancel() {
        let modal: Modal = new Modal("deleteFileModal", null);
        modal.hide();
    }

    checkForCooperative() {
        const catsSystemCommonSupportCoops = document.getElementsByClassName("catsSystemCommonSupportCoop");
        for (const catsSystemCommonSupportCoop of catsSystemCommonSupportCoops) {
            const element = <HTMLInputElement>catsSystemCommonSupportCoop;

            if (element.checked) {
                const checkedValue = element.dataset.lookupcode;

                const container = <HTMLDivElement>document.getElementById("cooperativeTable");

                if (checkedValue === "yes") {
                    container.classList.remove("hide");
                } else {
                    container.classList.add("hide");
                }
            }
        }
    }

    async addCooperativeRow() {
        const formElement = <HTMLElement>document.getElementById("catsSystemCommonSupportServiceForm");
        const planFK = formElement.dataset.planfk;
        const addNewPPID = <HTMLInputElement>document.getElementById("addNewPPID");

        Core.showLoader();

        let ppid = "";
        if (addNewPPID !== null) {
            ppid = addNewPPID.value;
        }

        if (ppid !== "") {
            //First, check to see if this PPID has already been added
            let ppidNotInList = true;
            const allPPIDS = document.querySelectorAll(`.catsSystemCommonSupportServicePPID[data-ppid='${ppid}']`);
            if (allPPIDS.length > 0) {
                ppidNotInList = false;
                Core.createHTMLAlert("alertMessageDiv", "This PPID is already in the list, you can only add it once.", 'warning', 3000, null);
            }

            if (ppidNotInList) {
                //2 step process:
                //1. call ValidTIMSWithCoopCert to make sure the ppid is valid + has the coop cert.
                //2. If 1 == true, call the partial build to display the record.

                const validPPIDResponse = await fetch(`/CATSSystem/ValidTIMSWithCoopCert/${ppid}`)
                if (validPPIDResponse.ok) {
                    const validResp = await validPPIDResponse.json();
                    if (validResp.ppidFound) {
                        const allCoopRows = document.querySelectorAll(`.cooperativeRow`);
                        let newRow = -1;
                        for (const coopRow of allCoopRows) {
                            const ele = <HTMLTableRowElement>coopRow;
                            const thisRow = parseInt(ele.dataset.row);

                            if (thisRow > newRow)
                                newRow = thisRow;
                        }

                        newRow++;

                        const response = await fetch(`/CATSSystem/AddCooperativeRow/${planFK}/${ppid}/${newRow}`, { credentials: 'include' })
                        if (response.ok) {
                            const output = await response.text();

                            const container = <HTMLTableRowElement>document.createElement("tr");
                            container.dataset.row = newRow.toString();
                            container.classList.add("cooperativeRow");
                            container.innerHTML = output;

                            const table = document.querySelector("#cooperativeData tbody");
                            table.append(container);

                            this.bindDeleteRows();

                            //These extra rows are necessary.
                            //The first one is for consistency, so the current row will match the background of the
                            //injected row (row "3" here). The injected row will contain the data that comes from TIMS when
                            //the user clicks on the name in the initial row created here.
                            const secondTR = <HTMLTableRowElement>document.createElement("tr");
                            secondTR.dataset.row = newRow.toString();
                            secondTR.classList.add("hide");
                            secondTR.classList.add("cooperativeRowSecond");
                            secondTR.setAttribute("aria-hidden", "true");
                            table.append(secondTR);

                            const thirdTR = <HTMLTableRowElement>document.createElement("tr");
                            thirdTR.dataset.row = newRow.toString();
                            thirdTR.dataset.ppid = ppid;
                            thirdTR.classList.add("ppidCertDisplay");
                            thirdTR.classList.add("hide");
                            thirdTR.classList.add("cooperativeRowThird");
                            table.append(thirdTR);

                            const cooperativeTableData = <HTMLDivElement>document.getElementById("cooperativeTableData");
                            cooperativeTableData.classList.remove("hide");

                            Core.hideLoader();
                            addNewPPID.value = "";
                            this.bindTeacherCerts();
                        }
                    } else {
                        Core.createHTMLAlert("alertMessageDiv", validResp.message, 'warning', 3000, null);
                    }
                }
            }
        } else {
            Core.createHTMLAlert("alertMessageDiv", "Please enter a PPID to add.", 'warning', 3000, null);
        }
    }

    bindDeleteRows() {
        const allDeleteButtons = document.getElementsByClassName("deleteCoopRecord");
        for (const deleteButton of allDeleteButtons)
            deleteButton.addEventListener("click", (e: Event) => this.showDeleteRow(e));
    }

    showDeleteRow(e: Event) {
        const button = <HTMLButtonElement>e.target;
        const row = button.dataset.row;

        let modal: Modal = new Modal("deleteCooperativeRowModal", null);
        modal.addAttributeToElement("deleteCooperativeRowModal", "#catsSystemCooperativeDeleteConfirm", "row", row);
        modal.show();
    }

    deleteCooperativeRowConfirm(e: Event) {
        Core.showLoader();
        const buttonElement = <HTMLButtonElement>e.target;
        const row = buttonElement.dataset.row;
        const hiddenElement = <HTMLInputElement>document.querySelector(`.catsSystemCommonSupportServicePPID[data-row='${row}']`);
        const rowElement = document.querySelector(`.cooperativeRow[data-row='${row}']`);
        const secondRowElement = document.querySelector(`.cooperativeRowSecond[data-row='${row}']`);
        const thirdRowElement = document.querySelector(`.cooperativeRowThird[data-row='${row}']`);

        if (hiddenElement.dataset.planpropertypk && hiddenElement.dataset.planpropertypk !== "" && hiddenElement.dataset.planpropertypk !== "0") {
            const planPropertys = [];
            const thisRow = document.querySelectorAll(`.catsSystemCommonSupportServiceData[data-row='${row}']`);
            for (const item of thisRow) {
                const ele = <HTMLInputElement>item;

                if (ele.dataset.planpropertypk && ele.dataset.planpropertypk !== "" && ele.dataset.planpropertypk !== "0")
                    planPropertys.push(parseInt(ele.dataset.planpropertypk));
            }

            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/CATSSystem/DeleteCooperativeRow', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                        rowElement.remove();
                        secondRowElement.remove();
                        thirdRowElement.remove();

                        Core.createHTMLAlert("alertMessageDiv", "Row successfully removed.", 'success', 3000, null);
                    } else {
                        Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the row. Please try again.", 'error', 3000, null);
                    }

                    Core.hideLoader();

                    let modal: Modal = new Modal("deleteCooperativeRowModal", null);
                    modal.hide();
                } else {
                    let modal: Modal = new Modal("deleteCooperativeRowModal", null);
                    modal.hide();

                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send(JSON.stringify(planPropertys));
        } else {
            rowElement.remove();
            secondRowElement.remove();
            thirdRowElement.remove();
        }

    }

    deleteCooperativeRowCancel() {
        let modal: Modal = new Modal("deleteCooperativeRowModal", null);
        modal.hide();
    }

    bindTeacherCerts() {
        const teacherCerts = document.getElementsByClassName("teacherCerts");
        for (const teacherCert of teacherCerts)
            teacherCert.addEventListener("click", (e: Event) => this.openTeacherCert(e.target as HTMLAnchorElement));
    }

    async openTeacherCert(element: HTMLAnchorElement) {
        const ppid = element.dataset.ppid;
        const row = element.dataset.row;
        const ppidCertDisplay = <HTMLTableRowElement>document.querySelector(`.ppidCertDisplay[data-row='${row}'][data-ppid='${ppid}']`);
        const planForm = document.getElementById("catsSystemCommonSupportServiceForm");
        const planFK = planForm.dataset.planfk;

        const response = await fetch(`/CATSSystem/GetTeacherCertificationData/${ppid}/${planFK}`, { credentials: 'include' })
        if (response.ok) {
            const output = await response.text();

            const newTD = <HTMLTableCellElement>document.createElement("td");
            newTD.colSpan = 6;

            newTD.innerHTML = output;

            ppidCertDisplay.append(newTD);
            ppidCertDisplay.classList.remove("hide");

            this.bindCloseCert();
        }
    }

    bindCloseCert() {
        const closeCerts = document.getElementsByClassName("closeCert");
        for (const closeCert of closeCerts)
            closeCert.addEventListener("click", (e: Event) => this.closeCert(e.target as HTMLButtonElement));
    }

    closeCert(button: HTMLButtonElement) {
        const ppid = button.dataset.ppid;
        const container = <HTMLTableRowElement>document.querySelector(`.ppidCertDisplay[data-ppid='${ppid}']`);
        container.innerHTML = "";
        container.classList.add("hide");
    }
}

// CatsSystemAdvisoryCommitteeDetails
class CatsSystemAdvisoryCommitteeDetails {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["catsSystemAdvisoryCommitteeDetailsField", "catsSystemAdvisoryCommitteeDetailsFileField"];

        let catsSystemAdvisoryCommitteeDetailsSaveButton = document.getElementById("catsSystemAdvisoryCommitteeDetailsSave");
        if (catsSystemAdvisoryCommitteeDetailsSaveButton !== null)
            catsSystemAdvisoryCommitteeDetailsSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses, false, true);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e: Event) => this.deleteFileConfirm(e));

        let deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e: Event) => this.deleteFileCancel());

        let deleteCommitteeConfirmButton = document.getElementById("deleteCommitteeConfirm");
        if (deleteCommitteeConfirmButton !== null)
            deleteCommitteeConfirmButton.addEventListener("click", (e: Event) => this.deleteCommitteeConfirm(e));

        let deleteCommitteeCancelButton = document.getElementById("deleteCommitteeCancel");
        if (deleteCommitteeCancelButton !== null)
            deleteCommitteeCancelButton.addEventListener("click", (e: Event) => this.deleteCommitteeCancel());



        this.bindFileUploaders();
        this.bindDeleteFiles();
        this.bindDeleteCommittees();
        this.saveAccess();

        let catsSystemAddAdvisoryCommitteeButton = document.getElementById("catsSystemAddAdvisoryCommittee");
        if (catsSystemAddAdvisoryCommitteeButton !== null)
            catsSystemAddAdvisoryCommitteeButton.addEventListener("click", (e: Event) => this.addAdvisoryCommittee());
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("catsSystemAdvisoryCommitteeDetailsForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("catsSystemAdvisoryCommitteeDetailsField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let allFileElements: IPlanPropertyFile[] = [];
        let files = document.getElementsByClassName("catsSystemAdvisoryCommitteeDetailsFileUploadField");
        for (let ele of files) {
            let element = <HTMLInputElement>ele;
            let sequenceNbr = parseInt(element.dataset.row);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk)
            let fileUploadPK = parseInt(element.dataset.fileuploadpk);
            let propertyPK = parseInt(element.dataset.propertypk);

            let saveItem: IPlanPropertyFile = {
                PlanPropertyFilePK: planPropertyFilePK,
                SequenceNbr: sequenceNbr,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanFK: planPK
            };

            allFileElements.push(saveItem);
        }

        var allData = {
            "ElementData": allSaveElements,
            "FileData": allFileElements
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemAdvisoryCommitteeDetails', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0 && allFileElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }

    saveAccess() {
        const formName = "catsSystemAdvisoryCommitteeDetailsForm";
        let planFK = 0;
        let form = document.getElementById(formName);
        planFK = parseInt(form.dataset.planfk);

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemAccess', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {

            }
        };
        xhr.send(`planFK=${planFK}`);
    }

    bindFileUploaders() {
        let fileUploaders = document.getElementsByClassName("catsSystemAdvisoryCommitteeDetailsFileInputField");
        for (const fileUploader of fileUploaders) {
            let ele = <HTMLInputElement>fileUploader;
            let listener = ele.dataset.listener;
            if (listener === "false") {
                ele.addEventListener("change", (e: Event) => this.uploadFile(<HTMLInputElement>e.target));
                ele.setAttribute("listener", "true");
                ele.dataset.listener = "true";
            }
        }
    }

    bindDeleteFiles() {
        let deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (let deleteFileButton of deleteFileButtons)
            deleteFileButton.addEventListener("click", (e: Event) => this.showDeleteFile(e));
    }

    showDeleteFile(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let row = buttonElement.dataset.row;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;

        let modal: Modal = new Modal("deleteFileModal", null);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "row", row);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "planpropertyfilepk", planPropertyFilePK);
        modal.show();
    }

    deleteFileConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        let row = buttonElement.dataset.row;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/DeleteFileData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    let element = document.querySelector(`.catsSystemAdvisoryCommitteeDetailsFileUploadField[data-planpropertyfilepk='${planPropertyFilePK}'][data-row='${row}']`);
                    let parent = element.parentElement.parentElement;
                    parent.remove();

                    const moreFiles = document.querySelectorAll(`.uploadFileList[data-row='${row}'] .uploadFileColumn`);
                    if (moreFiles.length === 0) {
                        const uploadsElement = <HTMLDivElement>document.getElementById(`uploadedFiles${row}`);

                        uploadsElement.classList.add("hide");
                    }

                    Core.createHTMLAlert("alertMessageDiv", "File successfully removed.", 'success', 3000, null);
                } else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the file. Please try again.", 'error', 3000, null);
                }

                Core.hideLoader();

                let modal: Modal = new Modal("deleteFileModal", null);
                modal.hide();
            } else {
                let modal: Modal = new Modal("deleteFileModal", null);
                modal.hide();

                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planPropertyFilePK));
    }

    deleteFileCancel() {
        let modal: Modal = new Modal("deleteFileModal", null);
        modal.hide();
    }

    async addAdvisoryCommittee() {
        Core.showLoader();
        let numberOfRows = 0;
        let formElement = <HTMLDivElement>document.getElementById("catsSystemAdvisoryCommitteeDetailsForm");

        let planFK = formElement.dataset.planfk;
        let that = this;
        let newRow = 0;

        let allRows = document.querySelectorAll("#advisoryCommitteeDetail .row");
        for (let row of allRows) {
            let rowEle = <HTMLTableRowElement>row;
            let thisRow = parseInt(rowEle.dataset.row);

            if (thisRow > newRow)
                newRow = thisRow;
        }

        newRow++;

        let response = await fetch(`/CATSSystem/AddSystemAdvisoryCommitteeRow/${planFK}/${newRow}`, { credentials: 'include' })
        if (response.ok) {

            const output = await response.text();

            const container = <HTMLDivElement>document.createElement("div");
            container.classList.add("row");
            container.classList.add("committee-detail-row");
            container.classList.add("committeeDetailRow");
            container.dataset.row = newRow.toString();
            const containerInner = <HTMLDivElement>document.createElement("div");
            containerInner.classList.add("medium-12");
            containerInner.classList.add("column");

            const newDiv = <HTMLDivElement>document.createElement("div");
            newDiv.innerHTML = output;

            containerInner.appendChild(newDiv);
            container.appendChild(containerInner);

            let table = document.querySelector("#advisoryCommitteeDetail");
            table.append(container);

            that.bindDeleteFiles();
            that.bindFileUploaders();
            that.bindDeleteCommittees();
        }
  
        this._core.initializeRequiredFields(this.validationClasses, false, true);
        Core.hideLoader();
        Core.createHTMLAlert("alertMessageDiv", "The Advisory Committees Details Row has been added!", 'success', 2000, null);
    }

    bindDeleteCommittees() {
        let deleteCommitteeButtons = document.getElementsByClassName("deletecommittee");
        for (let deleteCommitteeButton of deleteCommitteeButtons)
        deleteCommitteeButton.addEventListener("click", (e: Event) => this.showDeleteCommittee(e));
    }

    showDeleteCommittee(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let row = buttonElement.dataset.row;

        let modal: Modal = new Modal("deleteCommitteeModal", null);
        modal.addAttributeToElement("deleteCommitteeModal", "#deleteCommitteeConfirm", "row", row);
        modal.show();

    }

    deleteCommitteeConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        let row = buttonElement.dataset.row;

        let allSaveElements = [];
        let formElement = document.getElementById("catsSystemAdvisoryCommitteeDetailsForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("catsSystemAdvisoryCommitteeDetailsField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (rowNumber === row) {
                if (element.value !== "" || hadValue) {
                    let saveItem: IPlanProperty = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: element.value,
                        LookupCodeFK: null,
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };

                    allSaveElements.push(saveItem);
                }
            }
        }

        let allFileElements: IPlanPropertyFile[] = [];
        let files = document.getElementsByClassName("catsSystemAdvisoryCommitteeDetailsFileUploadField");
        for (let ele of files) {
            let element = <HTMLInputElement>ele;
            let fileRowNumber = element.dataset.row;
            let sequenceNbr = parseInt(element.dataset.row);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk)
            let fileUploadPK = parseInt(element.dataset.fileuploadpk);
            let propertyPK = parseInt(element.dataset.propertypk);
            if (fileRowNumber === row) {
                let saveItem: IPlanPropertyFile = {
                    PlanPropertyFilePK: planPropertyFilePK,
                    SequenceNbr: sequenceNbr,
                    FileUploadPK: fileUploadPK,
                    PropertyPK: propertyPK,
                    PlanFK: planPK
                };

                allFileElements.push(saveItem);
            }
        }

        var allData = {
            "ElementData": allSaveElements,
            "FileData": allFileElements
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/DeleteAdvisoryCommitteeData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    let element = document.querySelector(`.committeeDetailRow[data-row='${row}']`);
                    element.remove();

                    Core.createHTMLAlert("alertMessageDiv", "Committee successfully removed.", 'success', 3000, null);
                } else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the Committee. Please try again.", 'error', 3000, null);
                }

                Core.hideLoader();

                let modal: Modal = new Modal("deleteCommitteeModal", null);
                modal.hide();
            } else {
                let modal: Modal = new Modal("deleteCommitteeModal", null);
                modal.hide();

                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(allData));
    }

    deleteCommitteeCancel() {
        let modal: Modal = new Modal("deleteCommitteeModal", null);
        modal.hide();
    }

    uploadFile(e: HTMLInputElement) {
        let core = this._core;
        Core.showLoader();
        let formName = e.dataset.formname;

        let row = e.dataset.row;

        const that = this;

        let uploadSampleForm = <HTMLFormElement>document.getElementById(formName);
        let formData = new FormData(uploadSampleForm);

        let propertyPK = e.dataset.propertypk;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();

                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);

                        let formFile = <HTMLInputElement>document.querySelector(`.catsSystemAdvisoryCommitteeDetailsFileField[data-formname='${formName}']`);
                        formFile.value = "";

                        let fileList = document.querySelector(`.uploadFileList[data-row='${row}']`)

                        let currentFileList = fileList.querySelectorAll(`.uploadFileList[data-row='${row}']`);

                        let sequence = -1;
                        for (let file of currentFileList) {
                            let fileEle = <HTMLDivElement>file;

                            let thisSequence = parseInt(fileEle.dataset.sequencenbr);
                            if (thisSequence > sequence) {
                                sequence = thisSequence;
                            }
                        }

                        let uploadedContainer = document.querySelector(`#uploadedFiles${row}`);
                        uploadedContainer.classList.remove("hide");

                        sequence++;

                        let fileWrapper = <HTMLDivElement>document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = <HTMLDivElement>document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = <HTMLAnchorElement>document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.row = row;
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.sequencenbr = sequence.toString();
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.classList.add("catsSystemAdvisoryCommitteeDetailsFileUploadField");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);

                        fileList.appendChild(fileWrapper);

                        that.bindDeleteFiles();
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
}

// CatsSystemLEAWebsite
class CatsSystemLEAWebsite {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["catsSystemLEAWebsiteField", "catsSystemLEAWebsiteFileField"];

        const catsSystemLEAWebsiteSaveButton = document.getElementById("catsSystemLEAWebsiteSave");
        if (catsSystemLEAWebsiteSaveButton !== null)
            catsSystemLEAWebsiteSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        const fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        const hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        const fileUploader = document.getElementById("catsSystemLEAWebsiteLEAWebsiteHousingArticulationAgreements");
        if (fileUploader !== null)
            fileUploader.addEventListener("change", (e: Event) => this.uploadFile(<HTMLInputElement>e.target));

        const deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e: Event) => this.deleteFileConfirm(e));

        const deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e: Event) => this.deleteFileCancel());

        this.bindDeleteFiles();
        this.bindDeleteRows();
        this.saveAccess();

        const catsSystemAddLEAURLButton = document.getElementById("catsSystemAddLEAURL");
        if (catsSystemAddLEAURLButton !== null)
            catsSystemAddLEAURLButton.addEventListener("click", (e: Event) => this.addLEAURL());

        const deleteURLRowConfirmButton = document.getElementById("catsSystemCATSRowDeleteConfirm");
        if (deleteURLRowConfirmButton !== null)
            deleteURLRowConfirmButton.addEventListener("click", (e: Event) => this.deleteURLRowConfirm(e));

        const deleteURLRowCancelButton = document.getElementById("catsSystemCATSRowDeleteCancel");
        if (deleteURLRowCancelButton !== null)
            deleteURLRowCancelButton.addEventListener("click", (e: Event) => this.deleteURLRowCancel());
    }

    showDeleteFile(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;

        let modal: Modal = new Modal("deleteFileModal", null);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "planpropertyfilepk", planPropertyFilePK);
        modal.show();
    }

    deleteFileConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/DeleteFileData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    let element = document.querySelector(`.catsSystemLEAWebsiteFileUploadField[data-planpropertyfilepk='${planPropertyFilePK}']`);
                    let parent = element.parentElement.parentElement;
                    parent.remove();

                    const moreFiles = document.querySelectorAll(`.uploadFileList .uploadFileColumn`);
                    if (moreFiles.length === 0) {
                        const uploadsElement = <HTMLDivElement>document.getElementById("uploadedFiles");

                        uploadsElement.classList.add("hide");
                    }

                    Core.createHTMLAlert("alertMessageDiv", "File successfully removed.", 'success', 3000, null);
                } else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the file. Please try again.", 'error', 3000, null);
                }

                Core.hideLoader();

                let modal: Modal = new Modal("deleteFileModal", null);
                modal.hide();
            } else {
                let modal: Modal = new Modal("deleteFileModal", null);
                modal.hide();

                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planPropertyFilePK));
    }

    deleteFileCancel() {
        let modal: Modal = new Modal("deleteFileModal", null);
        modal.hide();
    }

    bindDeleteFiles() {
        let deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (let deleteFileButton of deleteFileButtons)
            deleteFileButton.addEventListener("click", (e: Event) => this.showDeleteFile(e));
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("catsSystemLEAWebsiteForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("catsSystemLEAWebsiteField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let allFileElements: IPlanPropertyFile[] = [];
        let files = document.getElementsByClassName("catsSystemLEAWebsiteFileUploadField");
        for (let ele of files) {
            let element = <HTMLInputElement>ele;
            let sequenceNbr = parseInt(element.dataset.sequencenbr);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk)
            let fileUploadPK = parseInt(element.dataset.fileuploadpk);
            let propertyPK = parseInt(element.dataset.propertypk);

            let saveItem: IPlanPropertyFile = {
                PlanPropertyFilePK: planPropertyFilePK,
                SequenceNbr: sequenceNbr,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanFK: planPK
            };

            allFileElements.push(saveItem);
        }

        var allData = {
            "ElementData": allSaveElements,
            "FileData": allFileElements
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemLEAWebsite', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }

    saveAccess() {
        const formName = "catsSystemLEAWebsiteForm";
        let planFK = 0;
        let form = document.getElementById(formName);
        planFK = parseInt(form.dataset.planfk);

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemAccess', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {

            }
        };
        xhr.send(`planFK=${planFK}`);
    }

    uploadFile(e: HTMLInputElement) {
        let core = this._core;
        Core.showLoader();
        let formName = e.dataset.formname;

        let row = e.dataset.row;

        let uploadSampleForm = <HTMLFormElement>document.getElementById(formName);
        let formData = new FormData(uploadSampleForm);

        let propertyPK = e.dataset.propertypk;

        const that = this;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();

                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);

                        let formFile = <HTMLInputElement>document.querySelector(`#catsSystemLEAWebsiteLEAWebsiteHousingArticulationAgreements`);
                        formFile.value = "";

                        let fileList = document.querySelector(`.uploadFileList`);

                        let currentFileList = fileList.querySelectorAll(`.uploadFileList`);

                        let sequence = -1;
                        for (let file of currentFileList) {
                            let fileEle = <HTMLDivElement>file;

                            let thisSequence = parseInt(fileEle.dataset.sequencenbr);
                            if (thisSequence > sequence) {
                                sequence = thisSequence;
                            }
                        }

                        let uploadedContainer = document.querySelector(`#uploadedFiles`);
                        uploadedContainer.classList.remove("hide");

                        sequence++;

                        let fileWrapper = <HTMLDivElement>document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = <HTMLDivElement>document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = <HTMLAnchorElement>document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.row = row;
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.sequencenbr = sequence.toString();
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.classList.add("catsSystemLEAWebsiteFileUploadField");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);

                        fileList.appendChild(fileWrapper);

                        that.bindDeleteFiles();
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }

    async addLEAURL() {
        Core.showLoader();
        let numberOfRows = 0;
        let formElement = <HTMLDivElement>document.getElementById("catsSystemLEAWebsiteForm");

        let planFK = formElement.dataset.planfk;
        let that = this;
        let newRow = 0;

        let allRows = document.querySelectorAll("#leaURLList tbody tr");
        for (let row of allRows) {
            let rowEle = <HTMLTableRowElement>row;
            let thisRow = parseInt(rowEle.dataset.row);

            if (thisRow > newRow)
                newRow = thisRow;
        }

        newRow++;

        let response = await fetch(`/CATSSystem/AddLEAURL/${planFK}/${newRow}`, { credentials: 'include' })
        if (response.ok) {

            const output = await response.text();

            let newTR = <HTMLTableRowElement>document.createElement("tr");
            newTR.dataset.row = newRow.toString();

            newTR.innerHTML = output;

            const container = document.querySelector("#leaURLList tbody");
            container.appendChild(newTR);

            that.bindDeleteRows();

            Core.hideLoader();
            Core.createHTMLAlert("alertMessageDiv", "The Website URL Housing Articulation Agreement(s) Row has been added!", 'success', 2000, null);
        } else {
            Core.hideLoader();
            Core.createHTMLAlert("alertMessageDiv", "There was an issue adding a new row. Please try again.", 'error', 2000, null);
        }
    }

    bindDeleteRows() {
        const allDeleteRows = document.getElementsByClassName("deleteURLRow");
        for (const deleteRow of allDeleteRows)
            deleteRow.addEventListener("click", (e: Event) => this.showDeleteURLRow(e));
    }

    showDeleteURLRow(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let row = buttonElement.dataset.row;

        let modal: Modal = new Modal("deleteRowModal", null);
        modal.addAttributeToElement("deleteRowModal", "#catsSystemCATSRowDeleteConfirm", "row", row);
        modal.show();
    }

    deleteURLRowConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let planProps = [];
        let row = buttonElement.dataset.row;

        let allElements = document.querySelectorAll(`.catsSystemLEAWebsiteField[data-row='${row}']`);
        for (let ele of allElements) {
            let element = <HTMLElement>ele;
            let planProp = element.dataset.planpropertypk;

            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/DeleteRowData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();

                let rowElement = document.querySelector(`#leaWebsites table tbody tr[data-row='${row}']`);
                rowElement.remove();

                let modal: Modal = new Modal("deleteRowModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The row data has been removed.", 'success', 3000, null);
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }

    deleteURLRowCancel() {
        let modal: Modal = new Modal("deleteRowModal", null);
        modal.hide();
    }
}

// CatsSystemProgramList
class CatsSystemProgramList {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["catsSystemProgramListField"];

        let catsSystemProgramListSaveButton = document.getElementById("catsSystemProgramListSave");
        if (catsSystemProgramListSaveButton !== null)
            catsSystemProgramListSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        const addNewProgramButton = document.getElementById("addNewProgram");
        if (addNewProgramButton !== null)
            addNewProgramButton.addEventListener("click", (e: Event) => this.addNewProgram());

        const viewListButton = document.getElementById("viewList");
        if (viewListButton !== null)
            viewListButton.addEventListener("click", (e: Event) => this.filter());

        const showDeletedProgramsElement = document.getElementById("showDeletedPrograms");
        if (showDeletedProgramsElement !== null) {
            showDeletedProgramsElement.addEventListener("click", (e: Event) => this.showDeletedPrograms(e.target as HTMLInputElement));
        }

        const catsButton = document.getElementById("CATS320");
        if (catsButton !== null) {
            catsButton.addEventListener("click", (e: Event) => this.printCats(e));
        }

        const studentIndustryCertsButton = document.getElementById("StudentIndustryCerts");
        if (studentIndustryCertsButton !== null) {
            studentIndustryCertsButton.addEventListener("click", (e: Event) => this.printStudentIndustryCerts(e));
        }

        const exportToWordButtons = document.getElementsByClassName("exportProgramToWord");
        for (const exportToWordButton of exportToWordButtons)
            exportToWordButton.addEventListener("click", (e: Event) => this.exportToWord(e));

        this.saveAccess();
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    showDeletedPrograms(checkbox: HTMLInputElement) {
        let planFK = checkbox.dataset.planfk;
        let href = `/CATSSystem/CatsSystemProgramList/${planFK}`;
        if (checkbox.checked) {
            location.href = href + '?showDeleted=true';
        } else {
            location.href = href;
        }
    }

    filter() {
        let select = <HTMLSelectElement>document.getElementById("programListSelect");
        let searchString = select.value.toUpperCase();
        let searchRows = document.getElementsByClassName("rowForSearch");
        let startYearElement = <HTMLSelectElement>document.getElementById("catsSystemProgramListSchoolYears");
        let endYearElement = <HTMLSelectElement>document.getElementById("catsSystemProgramListSchoolYearsEnd");
        let startYear = parseInt(startYearElement.value);
        let endYear = parseInt(endYearElement.value);
        let useYears = false;

        for (let searchRow of searchRows) {
            let count = 0;
            let element = <HTMLElement>searchRow;
            let searchRowValue = element.dataset.planstatus.toUpperCase();
            let rowYear = parseInt(element.dataset.year);
            if (rowYear && rowYear > 0 && startYear > 0 && endYear > 0) {
                useYears = true;
            }
            let showThisYear = false;
            if (startYear <= rowYear && rowYear <= endYear) {
                showThisYear = true;
            }
            if (useYears) {
                if (searchString == "ALL" && showThisYear && element.style.display == "none") {
                    element.style.display = "";
                }
                else if ((searchString != searchRowValue && searchString != "ALL") || !showThisYear) {
                    element.style.display = "none";
                }
                else if (searchString == searchRowValue && element.style.display == "none"  && showThisYear) {
                    element.style.display = "";
                }
            }
            else {
                if (searchString == "ALL" && element.style.display == "none") {
                    element.style.display = "";
                }
                else if (searchString != searchRowValue && searchString != "ALL") {
                    element.style.display = "none";
                }
                else if (searchString == searchRowValue && element.style.display == "none") {
                    element.style.display = "";
                }
            }
        }
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("catsSystemProgramListForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("catsSystemProgramListField");

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemProgramList', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    saveAccess() {
        const formName = "catsSystemProgramListForm";
        let planFK = 0;
        let form = document.getElementById(formName);
        planFK = parseInt(form.dataset.planfk);

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemAccess', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {

            }
        };
        xhr.send(`planFK=${planFK}`);
    }

    addNewProgram() {
        let planForm = document.getElementById("catsSystemProgramListForm");
        let planFK = planForm.dataset.planfk;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/AddNewProgram', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();

                const returnData = JSON.parse(xhr.responseText);
                const newPlanPK = returnData.newPlanPK;
                const newProgramPK = returnData.newProgramPK;
                const href = `/CATSSystem/CatsSystemProgramData/${planFK}/${newPlanPK}/${newProgramPK}`;

                Core.createHTMLAlert("alertMessageDiv", "A new program outline has been created.  Please wait while we redirect you to the program.", 'success', 3000, null);
                setTimeout(function () { window.location.href = href }, 3000);
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status + "'", 'error', 3000, null);
            }
        };
        xhr.send(`planFK=${planFK}`);
    }

    exportToWord(e: Event) {
        Core.showLoader();
        const button = <HTMLButtonElement>e.target;
        const catsProgramFK = button.dataset.catsprogramfk;
        let xhr = new XMLHttpRequest();
        xhr.open('GET', `/ExportDocx/FullReportCATSProgramExport/${catsProgramFK}`, true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.responseType = "blob";
        xhr.onload = function () {
            if (xhr.status === 200) {
                let blob = this.response;
                let filename = "Program.docx";

                if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                    window.navigator.msSaveBlob(blob, filename);
                } else {
                    var a = <HTMLAnchorElement>document.createElement("a");
                    var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                    document.body.appendChild(a);
                    a.style.display = "none";
                    a.href = blobUrl;
                    a.download = filename;
                    a.click();
                }

                Core.hideLoader();
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "There was an issue generating the document. Please try again.", 'error', 3000, null);
            }
        }
        xhr.send();
    }

    printCats(e: Event) {
        Core.showLoader();
        let formElement = document.getElementById("catsSystemProgramListForm");
        let catsPlanFK = parseInt(formElement.dataset.planfk);
        let xhr = new XMLHttpRequest();
        /*xhr.open('GET', `/ExportDocx/CATS320WordExport/${catsPlanFK}`, true);*/
        xhr.open('GET', `/CATSSystem/CATS320PDFExport/${catsPlanFK}`, true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.responseType = "blob";
        xhr.onload = function () {
            if (xhr.status === 200) {
                let blob = this.response;
                let filename = "CATS-320.pdf";

                if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                    window.navigator.msSaveBlob(blob, filename);
                } else {
                    var a = <HTMLAnchorElement>document.createElement("a");
                    var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                    document.body.appendChild(a);
                    a.style.display = "none";
                    a.href = blobUrl;
                    a.download = filename;
                    a.click();
                }

                Core.hideLoader();
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "There was an issue generating the document. Please try again.", 'error', 3000, null);
            }
        }
        xhr.send();
    }

    printStudentIndustryCerts(e: Event) {
        Core.showLoader();
        let formElement = document.getElementById("catsSystemProgramListForm");
        let catsPlanFK = parseInt(formElement.dataset.planfk);
        let xhr = new XMLHttpRequest();
        xhr.open('GET', `/ExportDocx/CATSStudentIndustryCertificationsWordExport/${catsPlanFK}`, true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.responseType = "blob";
        xhr.onload = function () {
            if (xhr.status === 200) {
                let blob = this.response;
                let filename = "Student Industry Credentials.docx";

                if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                    window.navigator.msSaveBlob(blob, filename);
                } else {
                    var a = <HTMLAnchorElement>document.createElement("a");
                    var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                    document.body.appendChild(a);
                    a.style.display = "none";
                    a.href = blobUrl;
                    a.download = filename;
                    a.click();
                }

                Core.hideLoader();
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "There was an issue generating the document. Please try again.", 'error', 3000, null);
            }
        }
        xhr.send();
    }
}

// CatsSystemSUMMARYOFPROGRAMS
class CatsSystemSUMMARYOFPROGRAMS {
    private _core: Core;
    constructor() {
        this._core = new Core();
        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.back(e));

        this._core.leftnav(this);
        this.saveAccess();

        const addNewProgramButton = document.getElementById("catsSystemSummaryOfProgramsAddNewProgram");
        if (addNewProgramButton !== null)
            addNewProgramButton.addEventListener("click", (e: Event) => this.addNewProgram());
    }

    back(e: Event) {
        let newPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }

    getCore() {
        return this._core;
    }

    saveAccess() {
        const formName = "catsSystemSUMMARYOFPROGRAMSForm";
        let planFK = 0;
        let form = document.getElementById(formName);
        planFK = parseInt(form.dataset.planfk);

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemAccess', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {

            }
        };
        xhr.send(`planFK=${planFK}`);
    }

    addNewProgram() {
        let planForm = document.getElementById("catsSystemSUMMARYOFPROGRAMSForm");
        let planFK = planForm.dataset.planfk;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/AddNewProgram', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();

                const returnData = JSON.parse(xhr.responseText);
                const newPlanPK = returnData.newPlanPK;
                const newProgramPK = returnData.newProgramPK;
                const href = `/CATSSystem/CatsSystemProgramData/${planFK}/${newPlanPK}/${newProgramPK}`;

                Core.createHTMLAlert("alertMessageDiv", "A new program outline has been created.  Please wait while we redirect you to the program.", 'success', 3000, null);
                setTimeout(function () { window.location.href = href }, 3000);
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status + "'", 'error', 3000, null);
            }
        };
        xhr.send(`planFK=${planFK}`);
    }
}

// CatsSystemPROGRAMSSUBMISSION
class CatsSystemPROGRAMSSUBMISSION {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["catsSystemPROGRAMSSUBMISSIONField"];

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        this.saveAccess();

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        const proceedToSignedAssuranceButton = document.getElementById("proceedToSignedAssurance");
        if (proceedToSignedAssuranceButton !== null)
            proceedToSignedAssuranceButton.addEventListener("click", (e: Event) => this.proceedToSignedAssurance());
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    saveAccess() {
        const formName = "catsSystemPROGRAMSSUBMISSIONForm";
        let planFK = 0;
        let form = document.getElementById(formName);
        planFK = parseInt(form.dataset.planfk);

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemAccess', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {

            }
        };
        xhr.send(`planFK=${planFK}`);
    }

    async proceedToSignedAssurance() {
        const form = <HTMLDivElement>document.getElementById("catsSystemPROGRAMSSUBMISSIONForm");
        const planFK = form.dataset.planfk;

        Core.showLoader();

        let checkedPrograms = [];

        const allChecks = document.querySelectorAll(`.catsSystemSubmissionItem:checked`);
        for (const check of allChecks) {
            const element = <HTMLInputElement>check;

            const catsProgramPK = parseInt(element.dataset.catsprogrampk);

            checkedPrograms.push(catsProgramPK);
        }

        if (checkedPrograms.length > 0) {
            const dataModel = {
                "PlanFK": planFK,
                "Programs": checkedPrograms
            };

            const settings = {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(dataModel)
            };

            const response = await fetch(`/CATSSystem/CreateSubmission`, settings);
            if (response.ok) {
                const output = await response.json();

                const url = `/CATSSystem/CatsSystemSIGNATUREASSURANCE/${planFK}/${output.catsSubmissionPK}`;

                window.location.href = url;
            }
        } else {
            Core.createHTMLAlert("alertMessageDiv", "Please choose one or more programs to submit.", 'warning', 3000, null);

            Core.hideLoader();
        }
    }
}

// CatsSystemSIGNATUREASSURANCE
class CatsSystemSIGNATUREASSURANCE {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["catsSystemSIGNATUREASSURANCEField"];

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        this.saveAccess();

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        const submitPlanButton = document.getElementById("submitPlanButton");
        if (submitPlanButton !== null)
            submitPlanButton.addEventListener("click", (e: Event) => this.submit(e));

        const catsSystemSignaturesWarningCloseButton = document.getElementById("catsSystemSignaturesWarningClose");
        if (catsSystemSignaturesWarningCloseButton !== null)
            catsSystemSignaturesWarningCloseButton.addEventListener("click", (e: Event) => this.closeWarning());
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    saveAccess() {
        const formName = "catsSystemSIGNATUREASSURANCEForm";
        let planFK = 0;
        let form = document.getElementById(formName);
        planFK = parseInt(form.dataset.planfk);

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemAccess', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {

            }
        };
        xhr.send(`planFK=${planFK}`);
    }

    async submit(e: Event) {
        Core.showLoader();
        const button = <HTMLButtonElement>e.target;

        const allowSubmit = button.dataset.allowsubmit;

        if (allowSubmit === "false") {
            Core.hideLoader();
            const modal: Modal = new Modal("signaturesWarningModal", null);
            modal.show();
        } else {
            const catsSystemSIGNATUREASSURANCEChiefSchoolAdministratorCTEDirectorElement = <HTMLInputElement>document.getElementById("catsSystemSIGNATUREASSURANCEChiefSchoolAdministratorCTEDirector");
            const catsSystemSIGNATUREASSURANCEDateElement = <HTMLInputElement>document.getElementById("catsSystemSIGNATUREASSURANCEDate");

            let hasName = true;
            let hasDate = true;

            if (catsSystemSIGNATUREASSURANCEChiefSchoolAdministratorCTEDirectorElement !== null) {
                const name = catsSystemSIGNATUREASSURANCEChiefSchoolAdministratorCTEDirectorElement.value;

                if (name === "")
                    hasName = false;
            }

            if (catsSystemSIGNATUREASSURANCEDateElement !== null) {
                const date = catsSystemSIGNATUREASSURANCEDateElement.value;

                if (date === "")
                    hasDate = false;
            }

            if (!hasName || !hasDate) {
                Core.hideLoader();
                const modal: Modal = new Modal("signaturesWarningModal", null);
                modal.show();
            } else {
                const formElement = <HTMLDivElement>document.getElementById("catsSystemSIGNATUREASSURANCEForm");
                const planFK = parseInt(formElement.dataset.planfk);
                const catsSubmissionFK = parseInt(formElement.dataset.catssubmissionfk);
                const allSaveElements = [];

                let textInputs = document.getElementsByClassName("catsSystemSIGNATUREASSURANCEField");
                for (let ele of textInputs) {
                    let planPropertyPK = 0;
                    let element = <HTMLInputElement>ele;
                    let rowNumber = 0;
                    let propertyPK = parseInt(element.dataset.propertypk);
                    let hadValue = false;
                    if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                        planPropertyPK = parseInt(element.dataset.planpropertypk);
                        hadValue = true;
                    }

                    if (element.value !== "" || hadValue) {
                        let saveItem: IPlanProperty = {
                            PlanPropertyPK: planPropertyPK,
                            PlanFK: planFK,
                            PropertyFK: propertyPK,
                            TextValue: element.value,
                            LookupCodeFK: null,
                            RowNbr: rowNumber,
                            IsDeletedInd: false
                        };

                        allSaveElements.push(saveItem);
                    }
                }

                const dataModel = {
                    "PlanFK": planFK,
                    "CATSSubmissionFK": catsSubmissionFK,
                    "SaveData": allSaveElements
                }

                const settings = {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(dataModel)
                };

                const submissionResponse = await fetch(`/CATSSystem/SubmitCATS`, settings);
                if (submissionResponse) {
                    const resp = await submissionResponse.json();

                    if (resp.success === true) {
                        button.disabled = true;
                        Core.createHTMLAlert("alertMessageDiv", "The selected programs have been submitted for review. Please wait for re-direction.", 'success', 3000, null);

                        const url = `/CATSSystem/CatsSystemProgramList/${planFK}`

                        setTimeout(function () { window.location.href = url }, 3000);

                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "There was an issue submitting the programs. Please try again.", 'error', 3000, null);
                    }
                }
            }
        }
    }

    closeWarning() {
        const modal: Modal = new Modal("signaturesWarningModal", null);
        modal.hide();
    }
}

// CatsSystemProgramData
class CatsSystemProgramData {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["catsSystemProgramDataCheckboxField", "catsSystemProgramDataRadioField", "catsSystemProgramDataSelectField", "catsSystemProgramDataFileField"];

        
        const catsSystemProgramDataSaveButton = document.getElementById("catsSystemProgramDataSave");
        if (catsSystemProgramDataSaveButton !== null)
            catsSystemProgramDataSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        const pathway = document.getElementById("catsSystemProgramDataProgramType");
        if (pathway !== null) {
            pathway.addEventListener("click", (e: Event) => this.lockdownPathway(e.target as HTMLSelectElement));
            this.lockdownPathway(pathway as HTMLSelectElement);
        }

        const teacherFurloughed = document.querySelectorAll(".catsSystemProgramDataRadioField[data-save='teacherfurloughed']");
        for (const teacher of teacherFurloughed) {
            const element = teacher as HTMLInputElement;

            element.addEventListener("change", (e: Event) => this.checkTeacherFurloughed(e.target as HTMLInputElement));
            this.checkTeacherFurloughed(element);
        }

        this.checkMarkForDeletion();

        this._core.leftnav(this);
        const fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.cipAlreadySelected();
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        this.saveAccess();

        const instructionalRadioButtons = document.getElementsByClassName("catsSystemProgramDataTOPRadioField");
        for (const instructionalRadioButton of instructionalRadioButtons)
            instructionalRadioButton.addEventListener("change", (e: Event) => this.programAreaOrCluster(<HTMLInputElement>e.target));

        const programAreaOrClusterRadio = <HTMLInputElement>document.querySelector(".catsSystemProgramDataTOPRadioField:checked");
        if (programAreaOrClusterRadio !== null) 
            this.programAreaOrCluster(programAreaOrClusterRadio);
        

        const clusterProgramAreaSelects = document.getElementsByClassName("catsSystemProgramDataClusterPASelectField")
        for (const clusterProgramAreaSelect of clusterProgramAreaSelects) {
            clusterProgramAreaSelect.addEventListener("change", (e: Event) => this.narrowCIPs(<HTMLSelectElement>e.target));
        }

        const fileDeletionFile = document.querySelector(`.catsSystemProgramDataFileField[data-file='programdeletion']`);
        if (fileDeletionFile !== null)
            fileDeletionFile.addEventListener("change", (e: Event) => this.uploadFile(<HTMLInputElement>e.target, "programdeletion"));

        const programApprovalFile = document.querySelector(`.catsSystemProgramDataFileField[data-file='programapproval']`);
        if (programApprovalFile !== null)
            programApprovalFile.addEventListener("change", (e: Event) => this.uploadFile(<HTMLInputElement>e.target, "programapproval"));

        let deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e: Event) => this.deleteFileConfirm(e));

        let deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e: Event) => this.deleteFileCancel());

        let lockoutexceptions = document.getElementsByClassName("lockoutException");
        for (let lockoutexception of lockoutexceptions) {
            lockoutexception.addEventListener("click", (e: Event) => this._core.updateLockoutException(e));
        }

        const catsSystemProgramDataCIPCodeElement = <HTMLSelectElement>document.getElementById("catsSystemProgramDataCIPCode");
        if (catsSystemProgramDataCIPCodeElement !== null)
            catsSystemProgramDataCIPCodeElement.addEventListener("change", (e: Event) => this.changeCIPValue(e));

        const catsSystemProgramDataMarkforDeletion = document.getElementById("catsSystemProgramDataMarkforDeletion");
        if (catsSystemProgramDataMarkforDeletion !== null)
            catsSystemProgramDataMarkforDeletion.addEventListener("change", (e: Event) => this.toggleMarkForDeletion());

        const catsSystemCATSProgramDataMarkForDeleteConfirm = document.getElementById("catsSystemCATSProgramDataMarkForDeleteConfirm");
        if(catsSystemCATSProgramDataMarkForDeleteConfirm !== null)
            catsSystemCATSProgramDataMarkForDeleteConfirm.addEventListener("click", (e: Event) => this.markForDeletionConfirm());

        const catsSystemCATSProgramDataMarkForDeleteCancel = document.getElementById("catsSystemCATSProgramDataMarkForDeleteCancel");
        if (catsSystemCATSProgramDataMarkForDeleteCancel !== null)
            catsSystemCATSProgramDataMarkForDeleteCancel.addEventListener("click", (e: Event) => this.markForDeletionCancel());

        this.bindDeleteFiles();

        this.cipAlreadySelected();
    }

    setHash() {
        const hidden = this._core.createHash(this.validationClasses);
        const hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    saveAccess() {
        const formName = "catsSystemProgramDataForm";
        let planFK = 0;
        let form = document.getElementById(formName);
        planFK = parseInt(form.dataset.parentplanfk);

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemAccess', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {

            }
        };
        xhr.send(`planFK=${planFK}`);
    }

    async save(referrer) {
        let allSaveElements = [];
        const core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        const formElement = document.getElementById("catsSystemProgramDataForm");
        const planPK = parseInt(formElement.dataset.planfk);
        const programPK = parseInt(formElement.dataset.programfk);

        const typeOfProgramElement = <HTMLInputElement>document.querySelector(".catsSystemProgramDataRadioField[data-save='typeofprogram']:checked");
        const clusterElement = <HTMLSelectElement>document.querySelector(".catsSystemProgramDataSelectField[data-save='cluster']");
        const programAreaElement = <HTMLSelectElement>document.querySelector(".catsSystemProgramDataSelectField[data-save='programarea']");
        const cipElement = <HTMLSelectElement>document.querySelector(".catsSystemProgramDataSelectField[data-save='cip']");
        const programTypeElement = <HTMLSelectElement>document.querySelector(".catsSystemProgramDataSelectField[data-save='programtype']");
        const programLengthElement = <HTMLSelectElement>document.querySelector(".catsSystemProgramDataSelectField[data-save='programlength']");
        const startSchoolYearElement = <HTMLSelectElement>document.querySelector(".catsSystemProgramDataSelectField[data-save='schoolyearstart']");
        const posElement = <HTMLInputElement>document.querySelector(".catsSystemProgramDataRadioField[data-save='pos']:checked");
        const markForDeletionElement = <HTMLInputElement>document.querySelector(".catsSystemProgramDataCheckboxField[data-save='markfordeletion']");
        const teacherFurloghedElement = <HTMLInputElement>document.querySelector(".catsSystemProgramDataRadioField[data-save='teacherfurloughed']:checked");

        let typeOfProgram = null;
        let cluster = null;
        let programArea = null;
        let cip = null;
        let programType = null;
        let programLength = null;
        let startSchoolYear = null;
        let pos = null;
        let markForDeletion = null;
        let teacherFurloughed = null;

        if (typeOfProgramElement !== null)
            typeOfProgram = typeOfProgramElement.value;
        if (clusterElement !== null)
            cluster = clusterElement.value;
        if (programAreaElement !== null)
            programArea = programAreaElement.value;
        if (cipElement !== null && cipElement.value !== "0")
            cip = cipElement.value;
        if (programTypeElement !== null && programTypeElement.value !== "0")
            programType = programTypeElement.value;
        if (programLengthElement !== null && programLengthElement.value !== "0")
            programLength = programLengthElement.value;
        if (startSchoolYearElement !== null && startSchoolYearElement.value !== "0")
            startSchoolYear = startSchoolYearElement.value;
        if (posElement !== null)
            pos = posElement.value;
        if (typeOfProgramElement !== null && typeOfProgramElement.value !== "0")
            typeOfProgram = typeOfProgramElement.value;
        if (markForDeletionElement !== null)
            if (markForDeletionElement.checked)
                markForDeletion = true;
            else
                markForDeletion = false;
        if (teacherFurloghedElement !== null)
            teacherFurloughed = teacherFurloghedElement.value;


        const saveData = {
            "PlanFK": planPK,
            "ProgramFK": programPK,
            "TypeOfProgram": typeOfProgram,
            "Cluster": cluster,
            "ProgramArea": programArea,
            "Cip": cip,
            "ProgramType": programType,
            "ProgramLength": programLength,
            "StartSchoolYear": startSchoolYear,
            "Pos": pos,
            "MarkForDeletion": markForDeletion,
            "TeacherFurloughed": teacherFurloughed
        };

        let isDuplicate: boolean = false;

        let response = await fetch(`/CATSSystem/CheckForDuplicateCIP/${planPK}/${cip}`, { credentials: 'include' })
        if (response.ok) {
            const output = await response.json();

            if (output.isDuplicate === true)
                isDuplicate = true;

            if (!isDuplicate) {
                let allFileElements: IPlanPropertyFile[] = [];
                let files = document.getElementsByClassName("catsSystemProgramDataFileUploadField");
                for (let ele of files) {
                    let element = <HTMLInputElement>ele;
                    let sequenceNbr = parseInt(element.dataset.sequencenbr);
                    let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk)
                    let fileUploadPK = parseInt(element.dataset.fileuploadpk);
                    let propertyPK = parseInt(element.dataset.propertypk);
                    let fileType = element.dataset.save;

                    let saveItem = {
                        PlanPropertyFilePK: planPropertyFilePK,
                        SequenceNbr: sequenceNbr,
                        FileUploadPK: fileUploadPK,
                        PropertyPK: propertyPK,
                        PlanFK: planPK,
                        FileType: fileType
                    };

                    allFileElements.push(saveItem);
                }

                var allData = {
                    "ElementData": saveData,
                    "FileData": allFileElements
                };

                let programDataXHR = new XMLHttpRequest();
                programDataXHR.open('POST', '/CATSSystem/SaveCatsSystemProgramData', true);
                programDataXHR.setRequestHeader('Content-Type', 'application/json');
                programDataXHR.onload = function () {
                    if (referrer === "save") {
                        if (programDataXHR.status === 200) {
                            Core.hideLoader();
                            core.pageReload(true, planPK);
                        }
                        else {
                            Core.hideLoader();
                            Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + programDataXHR.status, 'error', 3000, null);
                        }
                    }
                    else {
                        if (programDataXHR.status === 200) {
                            if (refreshPage && refreshPage !== "")
                                window.location.href = refreshPage;
                            else
                                Core.hideLoader();
                        }
                        else {
                            Core.hideLoader();
                            Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + programDataXHR.status, 'error', 3000, null);
                        }
                    }
                };
                programDataXHR.send(JSON.stringify(allData));
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "This program CIP already exists. Please change the CIP and save again.", 'error', 3000, null);
            }
        }
    }

    programAreaOrCluster(radioElement: HTMLInputElement) {
        if (radioElement && radioElement.checked) {
            const whichOne = radioElement.dataset.lookuplabel;
            const clusterElement = <HTMLSelectElement>document.querySelector(".catsSystemProgramDataSelectField[data-typeofprogram='cluster']");
            const programAreaElement = <HTMLSelectElement>document.querySelector(".catsSystemProgramDataSelectField[data-typeofprogram='programarea']");
            const cipSelect = <HTMLSelectElement>document.querySelector(".catsSystemProgramDataSelectField[data-typeofprogram='cip']");

            if (whichOne.toLowerCase().indexOf("cluster") >= 0) {
                programAreaElement.selectedIndex = 0;
                //cipSelect.selectedIndex = 0;)
                clusterElement.disabled = false;
                programAreaElement.disabled = true;
            } else if (whichOne.toLowerCase().indexOf("program") >= 0) {
                clusterElement.selectedIndex = 0;
                //cipSelect.selectedIndex = 0;
                clusterElement.disabled = true;
                programAreaElement.disabled = false;
            }

            cipSelect.disabled = true;

            const leaveDisabled = clusterElement.dataset.leavedisabled;
            if (leaveDisabled === "true") {
                clusterElement.disabled = true;
                programAreaElement.disabled = true;
                cipSelect.disabled = true;
            }
        }
    }

    cipAlreadySelected() {
        const selectedElement = <HTMLInputElement>document.querySelector(".catsSystemProgramDataTOPRadioField:checked");

        if (selectedElement) {
            const typeOfProgram = selectedElement.dataset.lookupcode;
            const cipSelect = <HTMLSelectElement>document.querySelector(".catsSystemProgramDataSelectField[data-typeofprogram='cip']");

            const cluster = <HTMLSelectElement>document.querySelector(".catsSystemProgramDataClusterPASelectField[data-typeofprogram='cluster']");
            const programArea = <HTMLSelectElement>document.querySelector(".catsSystemProgramDataClusterPASelectField[data-typeofprogram='programarea']");

            if (cipSelect.selectedIndex > 0) {
                const selectedIndex = cipSelect.selectedIndex;
                const selectedOption = <HTMLOptionElement>cipSelect[selectedIndex];

                const allCips = document.querySelectorAll(".catsSystemProgramDataSelectField[data-typeofprogram='cip'] option");
                for (let cip of allCips) {
                    let ele = <HTMLOptionElement>cip;
                    if (ele.classList.contains("hide"))
                        ele.classList.remove("hide");
                }

                if (selectedOption) {
                    if (typeOfProgram.toLowerCase().indexOf("cluster") >= 0) {
                        const clusterPK = selectedOption.dataset.cluster;
                        const programAreaPK = selectedOption.dataset.programarea;
                        cluster.value = clusterPK;

                        for (let i = 0; i < cipSelect.length; i++) {
                            const option = <HTMLOptionElement>cipSelect[i];

                            if (option.dataset.cluster !== clusterPK)
                                cipSelect[i].classList.add("hide");
                        }

                        programArea.value = programAreaPK;

                        programArea.disabled = true;
                        cipSelect.disabled = false;
                    } else if (typeOfProgram.toLowerCase().indexOf("program") >= 0) {
                        const programAreaPK = selectedOption.dataset.programarea;
                        const clusterPK = selectedOption.dataset.cluster;
                        programArea.value = programAreaPK;

                        for (let i = 0; i < cipSelect.length; i++) {
                            const option = <HTMLOptionElement>cipSelect[i];

                            if (option.dataset.programarea !== programAreaPK)
                                cipSelect[i].classList.add("hide");
                        }

                        cluster.value = clusterPK;

                        cluster.disabled = true;
                        cipSelect.disabled = false;
                    }
                }
            }

            const leaveDisabled = cluster.dataset.leavedisabled;
            if (leaveDisabled === "true") {
                cluster.disabled = true;
                programArea.disabled = true;
                cipSelect.disabled = true;
            }
        }
        this.setHash();
    }

    narrowCIPs(selectElement: HTMLSelectElement, resetIndex: boolean = true, firstRun: boolean = false) {
        const typeOfProgram = selectElement.dataset.typeofprogram;
        const cipSelect = <HTMLSelectElement>document.querySelector(".catsSystemProgramDataSelectField[data-typeofprogram='cip']");
        if(resetIndex)
            cipSelect.selectedIndex = 0;

        const cluster = <HTMLSelectElement>document.querySelector(".catsSystemProgramDataClusterPASelectField[data-typeofprogram='cluster']");
        const programArea = <HTMLSelectElement>document.querySelector(".catsSystemProgramDataClusterPASelectField[data-typeofprogram='programarea']");

        if (cluster.selectedIndex >= 0 || programArea.selectedIndex >= 0)
            firstRun = false; //Override this if there is a value selected.

        const allCips = document.querySelectorAll(".catsSystemProgramDataSelectField[data-typeofprogram='cip'] option");
        for (let cip of allCips) {
            let ele = <HTMLOptionElement>cip;
            if (ele.classList.contains("hide"))
                ele.classList.remove("hide");
        }

        if (typeOfProgram === "cluster") {
            if (selectElement.selectedIndex <= 0) {
                cipSelect.disabled = true;
            } else {
                cipSelect.disabled = false;
                const clusterPK = selectElement.value;

                for (let i = 0; i < cipSelect.length; i++) {
                    const option = <HTMLOptionElement>cipSelect[i];

                    if (option.dataset.cluster !== clusterPK) {
                        cipSelect[i].classList.add("hide");
                    }
                }
            }
        } else if (typeOfProgram === "programarea") {
            if (selectElement.selectedIndex <= 0) {
                cipSelect.disabled = true;
            } else {
                cipSelect.disabled = false;
                const programAreaPK = selectElement.value;

                for (let i = 0; i < cipSelect.length; i++) {
                    const option = <HTMLOptionElement>cipSelect[i];

                    if (option.dataset.programarea !== programAreaPK) {
                        cipSelect[i].classList.add("hide");
                    }
                }
            }
        }

        if(!firstRun)
            cipSelect.disabled = false;
    }

    bindDeleteFiles() {
        let deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (let deleteFileButton of deleteFileButtons)
            deleteFileButton.addEventListener("click", (e: Event) => this.showDeleteFile(e));
    }

    showDeleteFile(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let row = buttonElement.dataset.row;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;

        let modal: Modal = new Modal("deleteFileModal", null);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "row", row);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "planpropertyfilepk", planPropertyFilePK);
        modal.show();
    }

    deleteFileConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        let row = buttonElement.dataset.row;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/DeleteFileData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    let element = document.querySelector(`.catsSystemProgramDataFileUploadField[data-planpropertyfilepk='${planPropertyFilePK}'][data-row='${row}']`);
                    let parent = element.parentElement.parentElement;
                    parent.remove();

                    //const moreFiles = document.querySelectorAll(`.uploadFileList[data-row='${row}'] .uploadFileColumn`);
                    //if (moreFiles.length === 0) {
                    //    const uploadsElement = <HTMLDivElement>document.getElementById(`uploadedFiles${row}`);

                    //    uploadsElement.classList.add("hide");
                    //}

                    Core.createHTMLAlert("alertMessageDiv", "File successfully removed.", 'success', 3000, null);
                } else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the file. Please try again.", 'error', 3000, null);
                }

                Core.hideLoader();

                let modal: Modal = new Modal("deleteFileModal", null);
                modal.hide();
            } else {
                let modal: Modal = new Modal("deleteFileModal", null);
                modal.hide();

                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planPropertyFilePK));
    }

    deleteFileCancel() {
        let modal: Modal = new Modal("deleteFileModal", null);
        modal.hide();
    }

    uploadFile(e: HTMLInputElement, fileType) {
        Core.showLoader();
        const formName = e.dataset.formname;
        const uploadSampleForm = <HTMLFormElement>document.getElementById(formName);
        const formData = new FormData(uploadSampleForm);
        const propertyPK = e.dataset.propertypk;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();

                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);

                        const formFile = <HTMLInputElement>document.querySelector(`.catsSystemProgramDataFileField[data-formname='${formName}']`);
                        formFile.value = "";

                        const fileList = document.querySelector(`.uploadFileList[data-file='${fileType}']`)
                        const currentFileList = fileList.querySelectorAll(`.uploadFileList[data-file='${fileType}']`);

                        let uploadedContainer = document.querySelector(`.uploadedFiles[data-file='${fileType}']`);
                        uploadedContainer.classList.remove("hide");

                        let fileWrapper = <HTMLDivElement>document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = <HTMLDivElement>document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = <HTMLAnchorElement>document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.sequencenbr = "0";
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.dataset.save = fileType;
                        fileA.classList.add("catsSystemProgramDataFileUploadField");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);

                        fileList.appendChild(fileWrapper);
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }

    checkMarkForDeletion() {
        const checkbox = <HTMLInputElement>document.getElementById("catsSystemProgramDataMarkforDeletion");

        const catsSystemProgramDataLetterofExplanationforProgramDeletionElement = <HTMLInputElement>document.getElementById("catsSystemProgramDataLetterofExplanationforProgramDeletion");
        const catsSystemProgramDataBoardMinutesContainingCurtailmentofProgramApprovalElement = <HTMLInputElement>document.getElementById("catsSystemProgramDataBoardMinutesContainingCurtailmentofProgramApproval");
        const teacherFurloughedRadios = document.querySelectorAll(`.catsSystemProgramDataRadioField[data-save='teacherfurloughed']`);
        const teacherFurloughedElement = <HTMLDivElement>document.getElementById("teacherFurloughed");
        const markForDeletionContainerElement = <HTMLDivElement>document.getElementById("markForDeletionContainer");


        if (checkbox.checked) {
            this._core.forceElementRequired(catsSystemProgramDataLetterofExplanationforProgramDeletionElement);
            this._core.forceElementRequired(catsSystemProgramDataBoardMinutesContainingCurtailmentofProgramApprovalElement);
            teacherFurloughedElement.classList.remove("hide");
            for (const radio of teacherFurloughedRadios)
                this._core.forceElementRequired(radio as HTMLInputElement);
            markForDeletionContainerElement.classList.add("marked-for-deletion");
        } else {
            this._core.forceElementOptional(catsSystemProgramDataLetterofExplanationforProgramDeletionElement);
            this._core.forceElementOptional(catsSystemProgramDataBoardMinutesContainingCurtailmentofProgramApprovalElement);
            teacherFurloughedElement.classList.add("hide");
            for (const radio of teacherFurloughedRadios)
                this._core.forceElementOptional(radio as HTMLInputElement);
            markForDeletionContainerElement.classList.remove("marked-for-deletion");
        }
    }

    lockdownPathway(select: HTMLSelectElement) {
        const pathwayRadios = document.querySelectorAll(`.catsSystemProgramDataRadioField[data-save='pos']`);

        if (select.value === "11101") {
            for (const radio of pathwayRadios) {
                var element = <HTMLInputElement>radio;
                var lookupValue = element.dataset.lookupcode;
                if (lookupValue = "no")
                    element.checked = true;
                element.disabled = true;
            }
        } else {
            for (const radio of pathwayRadios) {
                var element = <HTMLInputElement>radio;
                element.disabled = false;
            }
        }
    }

    checkTeacherFurloughed(radioButton: HTMLInputElement) {
        if (radioButton.checked) {
            const value = radioButton.dataset.lookuplabel.toLowerCase();

            const errorMessage = document.getElementById("teacherFurloughedWarningMessage")

            if (value === "yes") {
                errorMessage.classList.remove("hide");
            } else {
                errorMessage.classList.add("hide");
            }
        }
    }

    async changeCIPValue(e: Event) {
        const selectElement = <HTMLSelectElement>e.target;
        const selectedCIPFK = selectElement.value;
        const programTypeSelect = <HTMLSelectElement>document.getElementById("catsSystemProgramDataProgramType");

        const response = await fetch(`/CATSSystem/GetCIPProgramTypeLookup/${selectedCIPFK}`, { credentials: 'include' })
        if (response.ok) {
            const json = await response.json();

            if (json.programTypeLookupCodeFK !== 0) {
                programTypeSelect.value = json.programTypeLookupCodeFK;
            }
        }
    }

    toggleMarkForDeletion() {
        const catsSystemProgramDataMarkforDeletion = <HTMLInputElement>document.getElementById("catsSystemProgramDataMarkforDeletion");
        if (catsSystemProgramDataMarkforDeletion !== null) {
            if (catsSystemProgramDataMarkforDeletion.checked) {
                const modal: Modal = new Modal("markForDeletionModal", null);
                modal.show();
            } else {
                this.checkMarkForDeletion();
            }
        }
    }

    markForDeletionCancel() {
        const catsSystemProgramDataMarkforDeletion = <HTMLInputElement>document.getElementById("catsSystemProgramDataMarkforDeletion");
        if (catsSystemProgramDataMarkforDeletion !== null) {
            if (catsSystemProgramDataMarkforDeletion.checked) {
                catsSystemProgramDataMarkforDeletion.checked = false;
            }
        }

        const modal: Modal = new Modal("markForDeletionModal", null);
        modal.hide();

        this.checkMarkForDeletion();
    }

    markForDeletionConfirm() {
        const modal: Modal = new Modal("markForDeletionModal", null);
        modal.hide();

        this.checkMarkForDeletion();
    }
}

// CatsSystemLaborMarketAnalysis
class CatsSystemLaborMarketAnalysis {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["catsSystemLaborMarketAnalysisField", "catsSystemLaborMarketAnalysisSelectField", "catsSystemLaborMarketAnalysisFileField", "catsSystemLaborMarketAnalysisAdvisoryCommitteeField"];

        const catsSystemLaborMarketAnalysisSaveButton = document.getElementById("catsSystemLaborMarketAnalysisSave");
        if (catsSystemLaborMarketAnalysisSaveButton !== null)
            catsSystemLaborMarketAnalysisSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);

        this.initializeRequiredFieldsCustom();
        this.saveAccess();

        const fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.doValidationCustom(this.validationClasses);
        }

        const hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        const catsSystemLaborMarketAnalysisAddRowAddRowButton = document.getElementById("catsSystemLaborMarketAnalysisAddRow");
        if (catsSystemLaborMarketAnalysisAddRowAddRowButton !== null)
            catsSystemLaborMarketAnalysisAddRowAddRowButton.addEventListener("click", (e: Event) => this.addRow());

        const catsSystemLaborMarketAnalysisRowDeleteCancelButton = document.getElementById("catsSystemAdvisoryCommitteeRowDeleteCancel");
        if (catsSystemLaborMarketAnalysisRowDeleteCancelButton !== null)
            catsSystemLaborMarketAnalysisRowDeleteCancelButton.addEventListener("click", (e: Event) => this.deleteRowCancel());

        const catsSystemLaborMarketAnalysisRowDeleteConfirmButton = document.getElementById("catsSystemAdvisoryCommitteeRowDeleteConfirm");
        if (catsSystemLaborMarketAnalysisRowDeleteConfirmButton !== null)
            catsSystemLaborMarketAnalysisRowDeleteConfirmButton.addEventListener("click", (e: Event) => this.deleteRowConfirm(e));

        const catsSystemLaborMarketAnalysisAddRowNumberElement = document.getElementById("catsSystemLaborMarketAnalysisAddRowNumber");
        if (catsSystemLaborMarketAnalysisAddRowNumberElement !== null)
            catsSystemLaborMarketAnalysisAddRowNumberElement.addEventListener("input", (e: Event) => this.checkPlural(e));

        this.bindDeleteCommitteeRows();

        const fileMeeting1 = document.querySelector(`.catsSystemLaborMarketAnalysisFileField[data-file='meeting1']`);
        if (fileMeeting1 !== null)
            fileMeeting1.addEventListener("change", (e: Event) => this.uploadFile(<HTMLInputElement>e.target, "meeting1"));

        const fileMeeting2 = document.querySelector(`.catsSystemLaborMarketAnalysisFileField[data-file='meeting2']`);
        if (fileMeeting2 !== null)
            fileMeeting2.addEventListener("change", (e: Event) => this.uploadFile(<HTMLInputElement>e.target, "meeting2"));

        const fileSupport1 = document.querySelector(`.catsSystemLaborMarketAnalysisFileField[data-file='support1']`);
        if (fileSupport1 !== null)
            fileSupport1.addEventListener("change", (e: Event) => this.uploadFile(<HTMLInputElement>e.target, "support1"));

        const fileSupport2 = document.querySelector(`.catsSystemLaborMarketAnalysisFileField[data-file='support2']`);
        if (fileSupport2 !== null)
            fileSupport2.addEventListener("change", (e: Event) => this.uploadFile(<HTMLInputElement>e.target, "support2"));

        let deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e: Event) => this.deleteFileConfirm(e));

        let deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e: Event) => this.deleteFileCancel());

        this.bindDeleteFiles();

    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("catsSystemLaborMarketAnalysisForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let parentPlanFK = parseInt(formElement.dataset.parentplanfk);

        let textInputs = document.getElementsByClassName("catsSystemLaborMarketAnalysisField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let committeeTextInputs = document.getElementsByClassName("catsSystemLaborMarketAnalysisAdvisoryCommitteeInputField");
        for (let ele of committeeTextInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let selectInputs = document.getElementsByClassName("catsSystemLaborMarketAnalysisSelectField");
        for (let ele of selectInputs) {
            let planPropertyPK = 0;
            let element = <HTMLSelectElement>ele;
            let rowNumber = "0";
            if (element.dataset.row !== null)
                rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if ((element.value !== "" && element.value !== "0") || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: null,
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }

        let radios = document.getElementsByClassName("catsSystemLaborMarketAnalysisRadioField");
        for (let ele of radios) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                if (element.value !== "" || hadValue) {
                    let saveItem: IPlanPropertyExtended = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                        ButtonRow: 0
                    };

                    allSaveElements.push(saveItem);
                }
            }
        }

        let checkboxes = document.getElementsByClassName("catsSystemLaborMarketAnalysisPathwayCheckboxField");
        for (let ele of checkboxes) {
            let planPropertyPK = 0;

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            planPropertyPK = parseInt(element.dataset.planpropertypk);

            let val = "";
            if (element.checked)
                val = element.dataset.cipcode;
            else
                val = "";

            let saveItem: IPlanProperty = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: planPK,
                PropertyFK: propertyPK,
                TextValue: val,
                LookupCodeFK: null,
                RowNbr: parseInt(rowNumber),
                IsDeletedInd: false
            };

            allSaveElements.push(saveItem);
        }

        let allFileElements: IPlanPropertyFile[] = [];
        let files = document.getElementsByClassName("catsSystemLaborMarketAnalysisFileUploadField");
        for (let ele of files) {
            let element = <HTMLInputElement>ele;
            let sequenceNbr = parseInt(element.dataset.sequencenbr);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk)
            let fileUploadPK = parseInt(element.dataset.fileuploadpk);
            let propertyPK = parseInt(element.dataset.propertypk);

            let saveItem: IPlanPropertyFile = {
                PlanPropertyFilePK: planPropertyFilePK,
                SequenceNbr: sequenceNbr,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanFK: planPK
            };

            allFileElements.push(saveItem);
        }

        var allData = {
            "ParentPlanFK": parentPlanFK,
            "CATSProgramPlanFK": planPK,
            "ElementData": allSaveElements,
            "FileData": allFileElements
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemLaborMarketAnalysis', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0 && allFileElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }

    saveAccess() {
        const formName = "catsSystemLaborMarketAnalysisForm";
        let planFK = 0;
        let form = document.getElementById(formName);
        planFK = parseInt(form.dataset.parentplanfk);

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemAccess', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {

            }
        };
        xhr.send(`planFK=${planFK}`);
    }

    bindDeleteFiles() {
        let deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (let deleteFileButton of deleteFileButtons)
            deleteFileButton.addEventListener("click", (e: Event) => this.showDeleteFile(e));
    }

    showDeleteFile(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let row = buttonElement.dataset.row;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;

        let modal: Modal = new Modal("deleteFileModal", null);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "row", row);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "planpropertyfilepk", planPropertyFilePK);
        modal.show();
    }

    deleteFileConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        let row = buttonElement.dataset.row;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/DeleteFileData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    let element = document.querySelector(`.catsSystemLaborMarketAnalysisFileUploadField[data-planpropertyfilepk='${planPropertyFilePK}'][data-row='${row}']`);
                    let parent = element.parentElement.parentElement;
                    parent.remove();

                    const moreFiles = document.querySelectorAll(`.uploadFileList[data-row='${row}'] .uploadFileColumn`);
                    if (moreFiles.length === 0) {
                        const uploadsElement = <HTMLDivElement>document.getElementById(`uploadedFiles${row}`);
                        if (uploadsElement !== null) {
                            uploadsElement.classList.add("hide");
                        }
                    }

                    Core.createHTMLAlert("alertMessageDiv", "File successfully removed.", 'success', 3000, null);
                } else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the file. Please try again.", 'error', 3000, null);
                }

                Core.hideLoader();

                let modal: Modal = new Modal("deleteFileModal", null);
                modal.hide();
            } else {
                let modal: Modal = new Modal("deleteFileModal", null);
                modal.hide();

                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planPropertyFilePK));
    }

    deleteFileCancel() {
        let modal: Modal = new Modal("deleteFileModal", null);
        modal.hide();
    }

    bindDeleteCommitteeRows() {
        let allDeletes = document.getElementsByClassName("deleteCommitteeRow");
        for (let del of allDeletes)
            del.addEventListener("click", (e: Event) => this.showDeleteRow(e));
    }

    showDeleteRow(e: Event) {
        let deleteButton = <HTMLButtonElement>e.target;
        let row = deleteButton.dataset.row;

        let modal: Modal = new Modal("deleteAdvisoryCommitteeRowModal", null);
        modal.addAttributeToElement("deleteAdvisoryCommitteeRowModal", "#catsSystemAdvisoryCommitteeRowDeleteConfirm", "row", row);
        modal.show();
    }

    async addRow() {
        Core.showLoader();
        let numberOfRows = 0;
        let formElement = <HTMLDivElement>document.getElementById("catsSystemLaborMarketAnalysisForm");
        let valueElement = <HTMLInputElement>document.getElementById("catsSystemLaborMarketAnalysisAddRowNumber");
        if (valueElement !== null) {
            numberOfRows = parseInt(valueElement.value);
        }

        if (!isNaN(numberOfRows) && numberOfRows > 0) {
            let planFK = formElement.dataset.planfk;
            let that = this;
            let newRow = 0;

            let allRows = document.querySelectorAll("#occupationalAdvisoryCommittee table tbody tr");
            for (let row of allRows) {
                let rowEle = <HTMLTableRowElement>row;
                let thisRow = parseInt(rowEle.dataset.row);

                if (thisRow > newRow)
                    newRow = thisRow;
            }

            for (let i = 0; i < numberOfRows; i++) {
                newRow++;

                let response = await fetch(`/CATSSystem/AddOccupationalAdvisoryCommitteeRow/${planFK}/${newRow}`, { credentials: 'include' })
                if (response.ok) {

                    const output = await response.text();

                    let newTR = <HTMLTableRowElement>document.createElement("tr");
                    newTR.innerHTML = output;
                    newTR.dataset.row = newRow.toString();

                    let table = document.querySelector("#occupationalAdvisoryCommittee table tbody");

                    table.append(newTR);

                    that.bindDeleteCommitteeRows();
                }
            }

            valueElement.value = "";
            let plural = "s";
            if (numberOfRows === 1) plural = "";
            const message = `${numberOfRows} row${plural} added!`

            const wordElement = <HTMLSpanElement>document.getElementById("catsSystemLaborMarketAnalysisAddRowNumberWord");
            wordElement.innerText = "rows";

            Core.hideLoader();
            Core.createHTMLAlert("alertMessageDiv", message, 'success', 2000, null);
        } else {
            valueElement.value = "";
            Core.createHTMLAlert("alertMessageDiv", 'An invalid number was entered for number of rows to add. Please correct the number and try again.', 'error', 3000, null);
            Core.hideLoader();
        }
    }

    deleteRowCancel() {
        let modal: Modal = new Modal("deleteAdvisoryCommitteeRowModal", null);
        modal.hide();
    }

    deleteRowConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let planProps = [];
        let row = buttonElement.dataset.row;

        let allElements = document.querySelectorAll(`.catsSystemLaborMarketAnalysisAdvisoryCommitteeField[data-row='${row}']`);
        for (let ele of allElements) {
            let element = <HTMLElement>ele;
            let planProp = element.dataset.planpropertypk;

            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/DeleteRowData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();

                let rowElement = document.querySelector(`#occupationalAdvisoryCommittee table tbody tr[data-row='${row}']`);
                rowElement.remove();

                let modal: Modal = new Modal("deleteAdvisoryCommitteeRowModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The row data has been removed.", 'success', 3000, null);
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }

    checkPlural(e: Event) {
        const catsSystemLaborMarketAnalysisAddRowNumberElement = <HTMLInputElement>e.target;
        const wordElement = <HTMLSpanElement>document.getElementById("catsSystemLaborMarketAnalysisAddRowNumberWord");

        const value = parseInt(catsSystemLaborMarketAnalysisAddRowNumberElement.value);
        if (!isNaN(value)) {
            if (value === 1)
                wordElement.innerText = "row";
            else
                wordElement.innerText = "rows";
        } else {
            wordElement.innerText = "rows";
        }
    }

    uploadFile(e: HTMLInputElement, fileType) {
        Core.showLoader();
        const formName = e.dataset.formname;
        const uploadSampleForm = <HTMLFormElement>document.getElementById(formName);
        const formData = new FormData(uploadSampleForm);
        const propertyPK = e.dataset.propertypk;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();

                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);

                        const formFile = <HTMLInputElement>document.querySelector(`.catsSystemLaborMarketAnalysisFileField[data-formname='${formName}']`);
                        formFile.value = "";

                        const fileList = document.querySelector(`.uploadFileList[data-file='${fileType}']`)

                        let uploadedContainer = document.querySelector(`.uploadedFiles[data-file='${fileType}']`);
                        uploadedContainer.classList.remove("hide");

                        let fileWrapper = <HTMLDivElement>document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = <HTMLDivElement>document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = <HTMLAnchorElement>document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.sequencenbr = "0";
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.dataset.save = fileType;
                        fileA.classList.add("catsSystemLaborMarketAnalysisFileUploadField");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);

                        if (fileType.indexOf("meeting") >= 0)
                            fileList.innerHTML = "";

                        fileList.appendChild(fileWrapper);
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }

    initializeRequiredFieldsCustom() {

        //If any field in a row is filled out, force rest of row required (except email)
        let committeeTable = document.getElementById("occupationalAdvisoryCommitteeTable");
        let committeeTableRows = committeeTable.querySelectorAll("tr[data-row]");
        let rowCounter = 0;
        for (let row of committeeTableRows) {
            let elements = row.getElementsByClassName("catsSystemLaborMarketAnalysisAdvisoryCommitteeField") as HTMLCollectionOf<HTMLElement>;

            for (let element of elements) {

                if (element instanceof HTMLSelectElement) {
                    let htmlElement = <HTMLSelectElement>element;
                    if (htmlElement.selectedIndex > 0) {
                        for (let otherElement of elements) {
                            otherElement.dataset.forcerequired = "true";
                        }
                        break;
                    }
                } else if (element instanceof HTMLInputElement) {
                    let htmlElement = <HTMLInputElement>element;
                    if (element.value != "") {
                        for (let otherElement of elements) {
                            otherElement.dataset.forcerequired = "true";
                        }
                        break;
                    }
                }
            }

            for (let ele of elements) {
                let valueCounter = 0;
                if (ele instanceof HTMLSelectElement) {
                    let htmlElement = <HTMLSelectElement>ele;
                    if (htmlElement.selectedIndex > 0) {
                            valueCounter++;
                    }
                } else if (ele instanceof HTMLInputElement) {
                    let htmlElement = <HTMLInputElement>ele;
                    if (ele.value != "") {
                        valueCounter++;
                    }
                }
                if (valueCounter === 4) {
                    rowCounter++
                }
            }
        }

        let alreadyApproved = <HTMLInputElement>document.getElementById("alreadyApproved");
        if (alreadyApproved !== null && alreadyApproved.value === "true") {
            const fieldsToForce = document.querySelectorAll(".forceApprovedOAC");
            for (const field of fieldsToForce) {
                const ele = <HTMLInputElement>field;
                    this._core.forceElementRequired(ele);
            }
        }

        this._core.initializeRequiredFields(this.validationClasses);
    }

    doValidationCustom(allClasses: string[], showMessageOverride?: boolean) {
        let showMessage = showMessageOverride === undefined ? this.clientSideValidationCustom(allClasses) : showMessageOverride;
        let messageContainerColumn = <HTMLElement>document.getElementById("validationColumn");
        let messageContainer = <HTMLElement>document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = <HTMLElement>document.getElementById("validationMessageIcon");

        setTimeout(function () {
            messageContainer.focus();
        }, 500);

        if (showMessage) {

            let message = <HTMLElement>document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";

        } else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";

            let successMessage = <HTMLElement>document.getElementById("saveSuccess");

            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved."
            }
        }
    }

    clientSideValidationCustom(allClasses: string[]): boolean {
        let showMessage: boolean = false;
        let totalErrors = 0;

        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });

        let classesToValidate = formattedAllClasses.join(",");

        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });

        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });

        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });

        let allElements = document.querySelectorAll(classesToValidate);

        for (let element of allElements) {
            let alreadyExists = false;
            let htmlElement = <HTMLElement>element;
            if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {

                //check to see if there are other fields the same as this one on this page (i.e. fields that have the same propertypk)
                var otherElements = document.querySelectorAll(`[data-propertypk='${htmlElement.dataset.propertypk}']`);
                for (let otherElement of otherElements) {
                    let otherHtmlElement = <HTMLElement>otherElement;
                    if (otherHtmlElement.classList.contains("missing-field")) {
                        alreadyExists = true;
                    } else {
                        if (otherHtmlElement instanceof HTMLInputElement) {
                            if (otherHtmlElement.hasAttribute("type") && otherHtmlElement.getAttribute("type") === "radio") {
                                //Check to see if a prior group of radio buttons has already been validated
                                let OtherInputElement = <HTMLInputElement>otherHtmlElement;

                                if (OtherInputElement.hasAttribute("name")) {
                                    let radioGroupName = OtherInputElement.getAttribute("name");

                                    let radios = document.getElementsByName(radioGroupName);
                                    let radioIsChecked: boolean = false;
                                    let isAlreadyValidated: boolean = false;

                                    for (var i = 0, length = radios.length; i < length; i++) {
                                        let radioElement = <HTMLInputElement>radios[i];
                                        if (radioElement.checked) {
                                            radioIsChecked = true;
                                        }

                                        if (radioElement.classList.contains("missing-field")) {
                                            isAlreadyValidated = true;
                                        }
                                    }

                                    if (isAlreadyValidated || radioIsChecked) {
                                        alreadyExists = true;
                                    }
                                }
                            } else {
                                let OtherInputElement = <HTMLInputElement>otherHtmlElement;
                                if (OtherInputElement.value !== "") {
                                    alreadyExists = true;
                                }
                            }
                        } else if (otherHtmlElement instanceof HTMLSelectElement) {
                            let otherSelectElement = <HTMLSelectElement>otherHtmlElement;
                            if (otherSelectElement.selectedIndex >= 0 && otherSelectElement.options[otherSelectElement.selectedIndex].value !== "") {
                                alreadyExists = true;
                            }
                        } else if (otherHtmlElement instanceof HTMLTextAreaElement) {
                            let otherTextAreaElement = <HTMLTextAreaElement>otherHtmlElement;
                            if (otherTextAreaElement.value !== "") {
                                alreadyExists = true;
                            }
                        } else if ("multiselectvalidate" in otherHtmlElement.dataset && otherHtmlElement.dataset.multiselectvalidate === "true") {
                            //See if any options have been checked in multiselect
                            let multiselectCheckboxes = otherHtmlElement.getElementsByTagName("input") as HTMLCollectionOf<HTMLInputElement>;
                            for (let selectBox of multiselectCheckboxes) {
                                if (selectBox.checked) {
                                    alreadyExists = true;
                                    break;
                                }
                            }
                        }
                    }
                }

                if (!alreadyExists || ("forcerequired" in htmlElement.dataset && htmlElement.dataset.forcerequired === "true")) {
                    if (!element.classList.contains("missing-field")) {
                        //For more custom validation, use data-is-valid to specify whether a field is valid/invalid
                        if ("isValid" in htmlElement.dataset) {
                            if (htmlElement.dataset.isValid === "false") {
                                htmlElement.classList.add("missing-field");
                                htmlElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(htmlElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        } else {
                            if (element instanceof HTMLInputElement) {
                                let inputElement = <HTMLInputElement>element;

                                //Only validate once for radio buttons
                                if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "radio" && !inputElement.checked) {
                                    if (inputElement.hasAttribute("name")) {
                                        let radioName = inputElement.getAttribute("name");

                                        let radioButtons = document.getElementsByName(radioName);
                                        let alreadyValidated: boolean = false;
                                        let isChecked: boolean = false;

                                        for (var i = 0, length = radioButtons.length; i < length; i++) {
                                            let radioElement = <HTMLInputElement>radioButtons[i];
                                            if (radioElement.classList.contains("missing-field")) {
                                                alreadyValidated = true;
                                            }

                                            if (radioElement.checked) {
                                                isChecked = true;
                                            }
                                        }

                                        if (!alreadyValidated && !isChecked) {
                                            inputElement.classList.add("missing-field");
                                            inputElement.setAttribute("aria-invalid", "true");
                                            Core.createErrorLabelForInput(inputElement);
                                            showMessage = true;
                                            totalErrors++;
                                        }
                                    }
                                } else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "file") {
                                    if ("hasuploaded" in inputElement.dataset && inputElement.dataset.hasuploaded != "true") {
                                        inputElement.classList.add("missing-field");
                                        inputElement.setAttribute("aria-invalid", "true");
                                        Core.createErrorLabelForInput(inputElement);
                                        showMessage = true;
                                        totalErrors++;
                                    }
                                } else if (inputElement.value === "") {
                                    inputElement.classList.add("missing-field");
                                    inputElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(inputElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            } else if (element instanceof HTMLSelectElement) {
                                let selectElement = <HTMLSelectElement>element;
                                //if the page select element does not have a place holder it needs custom validation.  do not use this one.
                                if (selectElement.selectedIndex <= 0 || selectElement.options[selectElement.selectedIndex].value === "") {
                                    selectElement.classList.add("missing-field");
                                    selectElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(selectElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            } else if (element instanceof HTMLTextAreaElement) {
                                let textAreaElement = <HTMLTextAreaElement>element;
                                if (textAreaElement.value === "") {
                                    textAreaElement.classList.add("missing-field");
                                    textAreaElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(textAreaElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            } else if ("multiselectvalidate" in htmlElement.dataset && htmlElement.dataset.multiselectvalidate === "true") {
                                let multiselectCheckboxes = htmlElement.getElementsByTagName("input") as HTMLCollectionOf<HTMLInputElement>;
                                let hasSelection = false;

                                for (let selectBox of multiselectCheckboxes) {
                                    if (selectBox.checked) {
                                        hasSelection = true;
                                        break;
                                    }
                                }

                                if (!hasSelection) {
                                    htmlElement.classList.add("missing-field");
                                    htmlElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(htmlElement);
                                    showMessage = true;
                                    totalErrors++;
                                }

                            }
                        }
                    }
                }
            }
        }
        //do the pathway validation
        let pathwayEle = <HTMLInputElement>document.getElementById("pathwayIsThere");
        if (pathwayEle != null) {
            const allChecks = document.querySelectorAll(".catscheckbox:checked");
            var label = document.getElementById("catsSystemLaborMarketAnalysisProgramsLabelId");
            if (allChecks.length <= 1) {
                label.classList.add("hasBeenValidated");
                label.innerHTML = "<span class='missing-field-label'><i class='fas fa-exclamation-triangle' aria-hidden='true'></i></span> " + label.innerHTML + " <span class='required-label'>*</span>";
                label.classList.add("isRequired");
                label.classList.add("missing-field");
                label.setAttribute("aria-invalid", "true");
                showMessage = true;
                totalErrors++;
            }
        }

        let message = <HTMLDivElement>document.getElementById("validationMessage");

        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        } else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");

        if (goToError !== null) {

            let that = this;

            let firstFocusableEl = <HTMLElement>document.querySelector(".missing-field");

            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");

                        let accordionElement = <HTMLButtonElement>document.getElementById(id);
                        accordionElement.click();
                    }

                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    } else {
                        firstFocusableEl.focus();
                    }
                });
            } else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }
}

// CatsSystemStudentTechnical
class CatsSystemStudentTechnical {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["catsSystemStudentTechnicalField", "catsSystemStudentTechnicalFileField"];

        let catsSystemStudentTechnicalSaveButton = document.getElementById("catsSystemStudentTechnicalSave");
        if (catsSystemStudentTechnicalSaveButton !== null)
            catsSystemStudentTechnicalSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        this.saveAccess();

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        const fileTaskGrid = document.querySelector(`.catsSystemStudentTechnicalFileField[data-file='taskgrid']`);
        if (fileTaskGrid !== null)
            fileTaskGrid.addEventListener("change", (e: Event) => this.uploadFile(<HTMLInputElement>e.target, "taskgrid"));

        const deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e: Event) => this.deleteFileConfirm(e));

        const deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e: Event) => this.deleteFileCancel());

        this.bindDeleteFiles();
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("catsSystemStudentTechnicalForm");
        let planPK = parseInt(formElement.dataset.planfk);
         
        let textInputs = document.getElementsByClassName("catsSystemStudentTechnicalField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let allFileElements: IPlanPropertyFile[] = [];
        let files = document.getElementsByClassName("catsSystemStudentTechnicalFileUploadField");
        for (let ele of files) {
            let element = <HTMLInputElement>ele;
            let sequenceNbr = parseInt(element.dataset.sequencenbr);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk)
            let fileUploadPK = parseInt(element.dataset.fileuploadpk);
            let propertyPK = parseInt(element.dataset.propertypk);

            let saveItem: IPlanPropertyFile = {
                PlanPropertyFilePK: planPropertyFilePK,
                SequenceNbr: sequenceNbr,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanFK: planPK
            };

            allFileElements.push(saveItem);
        }

        var allData = {
            "ElementData": allSaveElements,
            "FileData": allFileElements
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemStudentTechnical', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0  && allFileElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }

    saveAccess() {
        const formName = "catsSystemStudentTechnicalForm";
        let planFK = 0;
        let form = document.getElementById(formName);
        planFK = parseInt(form.dataset.parentplanfk);

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemAccess', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {

            }
        };
        xhr.send(`planFK=${planFK}`);
    }

    uploadFile(e: HTMLInputElement, fileType) {
        Core.showLoader();
        const formName = e.dataset.formname;
        const uploadSampleForm = <HTMLFormElement>document.getElementById(formName);
        const formData = new FormData(uploadSampleForm);
        const propertyPK = e.dataset.propertypk;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();

                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);

                        const formFile = <HTMLInputElement>document.querySelector(`.catsSystemStudentTechnicalFileField[data-formname='${formName}']`);
                        formFile.value = "";

                        const fileList = document.querySelector(`.uploadFileList[data-file='${fileType}']`)

                        let uploadedContainer = document.querySelector(`.uploadedFiles[data-file='${fileType}']`);
                        uploadedContainer.classList.remove("hide");

                        let fileWrapper = <HTMLDivElement>document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = <HTMLDivElement>document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = <HTMLAnchorElement>document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.sequencenbr = "0";
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.dataset.save = fileType;
                        fileA.classList.add("catsSystemStudentTechnicalFileUploadField");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);

                        fileList.appendChild(fileWrapper);
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }

    bindDeleteFiles() {
        let deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (let deleteFileButton of deleteFileButtons)
            deleteFileButton.addEventListener("click", (e: Event) => this.showDeleteFile(e));
    }

    showDeleteFile(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let row = buttonElement.dataset.row;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;

        let modal: Modal = new Modal("deleteFileModal", null);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "row", row);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "planpropertyfilepk", planPropertyFilePK);
        modal.show();
    }

    deleteFileConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        let row = buttonElement.dataset.row;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/DeleteFileData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    let element = document.querySelector(`.catsSystemStudentTechnicalFileUploadField[data-planpropertyfilepk='${planPropertyFilePK}'][data-row='${row}']`);
                    let parent = element.parentElement.parentElement;
                    parent.remove();

                    const moreFiles = document.querySelectorAll(`.uploadFileList[data-row='${row}'] .uploadFileColumn`);
                    if (moreFiles.length === 0) {
                        const uploadsElement = <HTMLDivElement>document.getElementById(`uploadedFiles${row}`);
                        if (uploadsElement !== null) {
                            uploadsElement.classList.add("hide");
                        }
                    }

                    Core.createHTMLAlert("alertMessageDiv", "File successfully removed.", 'success', 3000, null);
                } else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the file. Please try again.", 'error', 3000, null);
                }

                Core.hideLoader();

                let modal: Modal = new Modal("deleteFileModal", null);
                modal.hide();
            } else {
                let modal: Modal = new Modal("deleteFileModal", null);
                modal.hide();

                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planPropertyFilePK));
    }

    deleteFileCancel() {
        let modal: Modal = new Modal("deleteFileModal", null);
        modal.hide();
    }
}

// CatsSystemAccountabilityTargets
class CatsSystemAccountabilityTargets {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();
                                   
        this.validationClasses = ["catsSystemAccountabilityTargetsField", "catsSystemAccountabilityTargetsRadioField", "catsSystemAccountabilityTargetsSelectField"];

        let catsSystemAccountabilityTargetsSaveButton = document.getElementById("catsSystemAccountabilityTargetsSave");
        if (catsSystemAccountabilityTargetsSaveButton !== null)
            catsSystemAccountabilityTargetsSaveButton.addEventListener("click", (e: Event) => this.save("save"));


        const flexibleInstructionDaysExRadioFields = document.getElementsByClassName("catsSystemAccountabilityTargetsNewApprovalRadioField");
        for (const flexibleInstructionDaysExRadioField of flexibleInstructionDaysExRadioFields) {
            flexibleInstructionDaysExRadioField.addEventListener("change", (e: Event) => this.checkForSchoolPerformance(e.target as HTMLInputElement));
            this.checkForSchoolPerformance(flexibleInstructionDaysExRadioField as HTMLInputElement)
        }

        this.bindAssessmentTypeDropdowns();

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.doValidationCustom(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        this.saveAccess();

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let catsSystemAddAccountibilityTargetButton = document.getElementById("catsSystemAddAccountabilityTarget");
        if (catsSystemAddAccountibilityTargetButton !== null)
            catsSystemAddAccountibilityTargetButton.addEventListener("click", (e: Event) => this.addAccountabilityTarget());

        this.changeAccountabilityTargets();
        this.updateAccountabilityTargets();
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    saveAccess() {
        const formName = "catsSystemAccountabilityTargetsForm";
        let planFK = 0;
        let form = document.getElementById(formName);
        planFK = parseInt(form.dataset.parentplanfk);

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemAccess', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {

            }
        };
        xhr.send(`planFK=${planFK}`);
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("catsSystemAccountabilityTargetsForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("catsSystemAccountabilityTargetsField");

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }
        
        let radios = document.getElementsByClassName("catsSystemAccountabilityTargetsRadioField");
        for (let ele of radios) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                if (element.value !== "" || hadValue) {
                    let saveItem: IPlanPropertyExtended = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                        ButtonRow: 0
                    };

                    allSaveElements.push(saveItem);
                }
            }
        }

        let selectInputs = document.getElementsByClassName("catsSystemAccountabilityTargetsSelectField");
        for (let ele of selectInputs) {
            let planPropertyPK = 0;
            let element = <HTMLSelectElement>ele;
            let rowNumber = "0";
            if (element.dataset.row !== null)
                rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if ((element.value !== "" && element.value !== "0") || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: null,
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemAccountabilityTargets', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                this.doValidationCustom(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    async addAccountabilityTarget() {
        Core.showLoader();
        let formElement = <HTMLDivElement>document.getElementById("catsSystemAccountabilityTargetsForm");

        let planFK = formElement.dataset.planfk;
        let newRow = 0;

        let allRows = document.querySelectorAll(".accountabilityTargetsRow");
        for (let row of allRows) {
            let rowEle = <HTMLTableRowElement>row;
            let thisRow = parseInt(rowEle.dataset.row);

            if (thisRow > newRow)
                newRow = thisRow;
        }
        newRow++;

        let response = await fetch(`/CATSSystem/AddAccountabilityTarget/${planFK}/${newRow}`, { credentials: 'include' })
        if (response.ok) {

            const output = await response.text();

            let newDiv = <HTMLDivElement>document.createElement("div");
            newDiv.innerHTML = output;
            newDiv.dataset.row = newRow.toString();
            newDiv.classList.add("accountabilityTargetsRow");

            let comtainer = document.querySelector("#accountabilityTargets");

            comtainer.append(newDiv);

            this.bindAssessmentTypeDropdowns();
        }
            
        const message = `Assessment row item has been added!`

        Core.hideLoader();
        Core.createHTMLAlert("alertMessageDiv", message, 'success', 2000, null);
    }

    initializeRequiredFieldsCustom(validationClasses: string[]) {

        this._core.initializeRequiredFields(this.validationClasses);

    }

    doValidationCustom(allClasses: string[], showMessageOverride?: boolean) {
        let showMessage = showMessageOverride === undefined ? this.clientSideValidationCustom(allClasses) : showMessageOverride;
        let messageContainerColumn = <HTMLElement>document.getElementById("validationColumn");
        let messageContainer = <HTMLElement>document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = <HTMLElement>document.getElementById("validationMessageIcon");

        setTimeout(function () {
            messageContainer.focus();
        }, 500);

        if (showMessage) {

            let message = <HTMLElement>document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";

        } else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";

            let successMessage = <HTMLElement>document.getElementById("saveSuccess");

            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved."
            }
        }
    }

    clientSideValidationCustom(allClasses: string[]): boolean {
        let showMessage: boolean = false;
        let totalErrors = 0;

        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });

        let classesToValidate = formattedAllClasses.join(",");

        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });

        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });

        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });

        let allElements = document.querySelectorAll(classesToValidate);

        for (let element of allElements) {
            let alreadyExists = false;
            let htmlElement = <HTMLElement>element;
            if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {
                if (!alreadyExists || ("forcerequired" in htmlElement.dataset && htmlElement.dataset.forcerequired === "true")) {
                    if (!element.classList.contains("missing-field")) {
                        if (element instanceof HTMLInputElement) {
                            let inputElement = <HTMLInputElement>element;

                            //Only validate once for radio buttons
                            if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "radio" && !inputElement.checked) {
                                if (inputElement.hasAttribute("name")) {
                                    let radioName = inputElement.getAttribute("name");

                                    let radioButtons = document.getElementsByName(radioName);
                                    let alreadyValidated: boolean = false;
                                    let isChecked: boolean = false;

                                    for (var i = 0, length = radioButtons.length; i < length; i++) {
                                        let radioElement = <HTMLInputElement>radioButtons[i];
                                        if (radioElement.classList.contains("missing-field")) {
                                            alreadyValidated = true;
                                        }

                                        if (radioElement.checked) {
                                            isChecked = true;
                                        }
                                    }

                                    if (!alreadyValidated && !isChecked) {
                                        inputElement.classList.add("missing-field");
                                        inputElement.setAttribute("aria-invalid", "true");
                                        Core.createErrorLabelForInput(inputElement);
                                        showMessage = true;
                                        totalErrors++;
                                    }
                                }
                            } else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "file") {
                                if ("hasuploaded" in inputElement.dataset && inputElement.dataset.hasuploaded != "true") {
                                    inputElement.classList.add("missing-field");
                                    inputElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(inputElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            } else if (inputElement.value === "") {
                                inputElement.classList.add("missing-field");
                                inputElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(inputElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        } else if (element instanceof HTMLSelectElement) {
                            let selectElement = <HTMLSelectElement>element;
                            if (selectElement.selectedIndex < 0 || selectElement.options[selectElement.selectedIndex].value === "") {
                                selectElement.classList.add("missing-field");
                                selectElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(selectElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        } else if (element instanceof HTMLTextAreaElement) {
                            let textAreaElement = <HTMLTextAreaElement>element;
                            if (textAreaElement.value === "") {
                                textAreaElement.classList.add("missing-field");
                                textAreaElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(textAreaElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        } else if ("multiselectvalidate" in htmlElement.dataset && htmlElement.dataset.multiselectvalidate === "true") {
                            let multiselectCheckboxes = htmlElement.getElementsByTagName("input") as HTMLCollectionOf<HTMLInputElement>;
                            let hasSelection = false;

                            for (let selectBox of multiselectCheckboxes) {
                                if (selectBox.checked) {
                                    hasSelection = true;
                                    break;
                                }
                            }

                            if (!hasSelection) {
                                htmlElement.classList.add("missing-field");
                                htmlElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(htmlElement);
                                showMessage = true;
                                totalErrors++;
                            }

                        }
                    }
                }
            }
        }

        let message = <HTMLDivElement>document.getElementById("validationMessage");

        let numberOfStudents
        let onlyNumbersElement = document.getElementsByClassName("onlyNumbers");
        for (let ele of onlyNumbersElement) {
            let element = <HTMLInputElement>ele;
            numberOfStudents = Number(element.value);

            if (!isNaN(numberOfStudents)) {
                element.classList.remove("missing-field");
                //showMessage = false;
            }
            else {
                totalErrors++;
                showMessage = true;
                element.classList.add("missing-field");
            }
        }

        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        } else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");

        if (goToError !== null) {

            let that = this;

            let firstFocusableEl = <HTMLElement>document.querySelector(".missing-field");

            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");

                        let accordionElement = <HTMLButtonElement>document.getElementById(id);
                        accordionElement.click();
                    }

                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    } else {
                        firstFocusableEl.focus();
                    }
                });
            } else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }

    checkForSchoolPerformance(radio: HTMLInputElement) {
        if (radio.checked) {
            const catsSystemAccountabilityTargetsSchoolPerformanceFields = document.getElementsByClassName("catsSystemAccountabilityTargetsSchoolPerformanceField");

            if (radio.dataset.lookupcode === "yes") {
                for (const field of catsSystemAccountabilityTargetsSchoolPerformanceFields) {
                    const ele = <HTMLInputElement>field;
                    ele.disabled = true;
                    this._core.forceElementOptional(ele);
                    if (ele.dataset.lookupcode === "no")
                        ele.checked = true;
                }
                this.changeAssessmentType(true);
            } else {
                for (const field of catsSystemAccountabilityTargetsSchoolPerformanceFields) {
                    const ele = <HTMLInputElement>field;
                    ele.disabled = false;
                    this._core.forceElementRequired(ele);
                    this.changeAssessmentType(true);
                }
            }
        }
    }

    async changeAccountabilityTargets() {
        const yearElement = <HTMLInputElement>document.getElementById("catsSystemAccountabilityTargetsTargetYear");
        const academicYearFK = yearElement.dataset.academicyearfk;

        let response = await fetch(`/CATSSystem/ChangeAccountabilityTargets/${academicYearFK}`, { credentials: 'include' })
        if (response.ok) {
            const output = await response.json();

            //clear everything first
            const allTargets = document.querySelectorAll(".catsSystemAccountabilityTargetsEditField");
            for (const target of allTargets)
                (target as HTMLInputElement).value = "0";

            if (output.narrowedList.length > 0) {
                for (const ele of output.narrowedList) {
                    const code = ele.performanceIndicatorCode;

                    const textElement = <HTMLInputElement>document.querySelector(`.catsSystemAccountabilityTargetsEditField[data-code='${code}']`);
                    textElement.value = ele.annualStateTargetPercent;
                }
            }

            this.updateAccountabilityTargets();
        }
    }

    updateAccountabilityTargets() {
        const spanElement = <HTMLSpanElement>document.getElementById("annualStateTargetsYear");
        if (spanElement !== null) {
            const yearElement = <HTMLInputElement>document.getElementById("catsSystemAccountabilityTargetsTargetYear");

            spanElement.textContent = yearElement.value;
        }
    }

    bindAssessmentTypeDropdowns() {
        const allAssessmentTypes = document.getElementsByClassName("catsSystemAccountabilityTargetsSelectField");
        for (const assessmentType of allAssessmentTypes) {
            assessmentType.addEventListener("change", (e: Event) => this.changeAssessmentType(false));
            this.changeAssessmentType(true)
        }
    }

    changeAssessmentType(firstLoad: boolean) {
        let that = this;
        let row = 0;
        const selectElement = <HTMLSelectElement>document.querySelector(`.catsSystemAccountabilityTargetsSelectField[data-row='${row}']`);
        const selectedOption = selectElement[selectElement.selectedIndex] as HTMLOptionElement;
        const selectedText = selectedOption.text;
        /*const row = assessmentType.dataset.row;*/
        const codeElement = <HTMLInputElement>document.querySelector(`.catsSystemAccountabilityTargetsTestCodeField[data-row='${row}']`);
        const levelElement = <HTMLInputElement>document.getElementById("catsSystemAccountabilityTargetsExpectedLevelofStudentPerformanceonNOCTINIMS");
        const teacher5S4FurloughedRadios = document.querySelectorAll(`.catsSystemAccountabilityTargetsRadioField[name='catsSystemAccountabilityTargets5S4SchoolPerformanceName']`);
        const teacher5S1FurloughedRadios = document.querySelectorAll(`.catsSystemAccountabilityTargetsRadioField[name='catsSystemAccountabilityTargets5S1SchoolPerformanceName']`);
        let tr = <HTMLElement>document.getElementById("tr5S4");

        const newProgramRadios = document.querySelectorAll(`.catsSystemAccountabilityTargetsRadioField[name='catsSystemAccountabilityTargetsNewProgramApprovalName']`) as unknown as HTMLCollectionOf<HTMLInputElement>;
        var newProgram = false;
        for (const radio of newProgramRadios) {
           if (radio.checked) {
                if (radio.dataset.lookuplabel === "Yes") {
                    newProgram = true;
                } else {
                    newProgram = false;
                }
            }
        }

        if (selectedText === "NOCTI") {
            codeElement.readOnly = false;
            levelElement.readOnly = false;
            that._core.forceElementRequired(levelElement);
            for (const radio of teacher5S4FurloughedRadios) {
                this._core.forceElementRequired(radio as HTMLInputElement);
                tr.classList.remove("hide");
            }
            if (!firstLoad)
                codeElement.value = "";
        } else if (selectedText === "NIMS") {
            codeElement.value = "0001";
            codeElement.readOnly = true;
            levelElement.readOnly = false;
            that._core.forceElementRequired(levelElement);
            for (const radio of teacher5S4FurloughedRadios) {
                this._core.forceElementRequired(radio as HTMLInputElement);
                tr.classList.remove("hide");
            }
        } else if (selectedText === "Test Exempt") {
            codeElement.value = "0000";
            codeElement.readOnly = true;
            levelElement.value = "";
            levelElement.readOnly = true;
            that._core.forceElementOptional(levelElement);
            for (const radio of teacher5S4FurloughedRadios) {
                this._core.forceElementRequired(radio as HTMLInputElement);
                tr.classList.add("hide");
            }
            if (!newProgram) {
                for (const radio of teacher5S1FurloughedRadios) {
                    this._core.forceElementRequired(radio as HTMLInputElement);
                }
            }
        }
    }
}

// CatsSystemProspectiveStudents
class CatsSystemProspectiveStudents {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["catsSystemProspectiveStudentsField"];

        let catsSystemProspectiveStudentsSaveButton = document.getElementById("catsSystemProspectiveStudentsSave");
        if (catsSystemProspectiveStudentsSaveButton !== null)
            catsSystemProspectiveStudentsSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.doValidationCustom(this.validationClasses);
        }
        this.initializeRequiredFieldsCustom(this.validationClasses);
        this.saveAccess();

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    saveAccess() {
        const formName = "catsSystemProspectiveStudentsForm";
        let planFK = 0;
        let form = document.getElementById(formName);
        planFK = parseInt(form.dataset.parentplanfk);

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemAccess', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {

            }
        };
        xhr.send(`planFK=${planFK}`);
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("catsSystemProspectiveStudentsForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("catsSystemProspectiveStudentsField");

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemProspectiveStudents', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                this.doValidationCustom(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    initializeRequiredFieldsCustom(validationClasses: string[]) {

        this._core.initializeRequiredFields(this.validationClasses);
        let showMessage: boolean = false;

    }

    doValidationCustom(allClasses: string[], showMessageOverride?: boolean) {
        let showMessage = showMessageOverride === undefined ? this.clientSideValidationCustom(allClasses) : showMessageOverride;
        let messageContainerColumn = <HTMLElement>document.getElementById("validationColumn");
        let messageContainer = <HTMLElement>document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = <HTMLElement>document.getElementById("validationMessageIcon");

        setTimeout(function () {
            messageContainer.focus();
        }, 500);

        if (showMessage) {

            let message = <HTMLElement>document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";

        } else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";

            let successMessage = <HTMLElement>document.getElementById("saveSuccess");

            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved."
            }
        }
    }

    clientSideValidationCustom(allClasses: string[]): boolean {
        let showMessage: boolean = false;
        let totalErrors = 0;

        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });

        let classesToValidate = formattedAllClasses.join(",");

        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });

        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });

        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });

        let allElements = document.querySelectorAll(classesToValidate);

        for (let element of allElements) {
            let alreadyExists = false;
            let htmlElement = <HTMLElement>element;
            if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {

                //check to see if there are other fields the same as this one on this page (i.e. fields that have the same propertypk)
                var otherElements = document.querySelectorAll(`[data-propertypk='${htmlElement.dataset.propertypk}']`);
                for (let otherElement of otherElements) {
                    let otherHtmlElement = <HTMLElement>otherElement;
                    if (otherHtmlElement.classList.contains("missing-field")) {
                        alreadyExists = true;
                    } else {
                        if (otherHtmlElement instanceof HTMLInputElement) {
                            if (otherHtmlElement.hasAttribute("type") && otherHtmlElement.getAttribute("type") === "radio") {
                                //Check to see if a prior group of radio buttons has already been validated
                                let OtherInputElement = <HTMLInputElement>otherHtmlElement;

                                if (OtherInputElement.hasAttribute("name")) {
                                    let radioGroupName = OtherInputElement.getAttribute("name");

                                    let radios = document.getElementsByName(radioGroupName);
                                    let radioIsChecked: boolean = false;
                                    let isAlreadyValidated: boolean = false;

                                    for (var i = 0, length = radios.length; i < length; i++) {
                                        let radioElement = <HTMLInputElement>radios[i];
                                        if (radioElement.checked) {
                                            radioIsChecked = true;
                                        }

                                        if (radioElement.classList.contains("missing-field")) {
                                            isAlreadyValidated = true;
                                        }
                                    }

                                    if (isAlreadyValidated || radioIsChecked) {
                                        alreadyExists = true;
                                    }
                                }
                            } else {
                                let OtherInputElement = <HTMLInputElement>otherHtmlElement;
                                if (OtherInputElement.value !== "") {
                                    alreadyExists = true;
                                }
                            }
                        } else if (otherHtmlElement instanceof HTMLSelectElement) {
                            let otherSelectElement = <HTMLSelectElement>otherHtmlElement;
                            if (otherSelectElement.selectedIndex >= 0 && otherSelectElement.options[otherSelectElement.selectedIndex].value !== "") {
                                alreadyExists = true;
                            }
                        } else if (otherHtmlElement instanceof HTMLTextAreaElement) {
                            let otherTextAreaElement = <HTMLTextAreaElement>otherHtmlElement;
                            if (otherTextAreaElement.value !== "") {
                                alreadyExists = true;
                            }
                        } else if ("multiselectvalidate" in otherHtmlElement.dataset && otherHtmlElement.dataset.multiselectvalidate === "true") {
                            //See if any options have been checked in multiselect
                            let multiselectCheckboxes = otherHtmlElement.getElementsByTagName("input") as HTMLCollectionOf<HTMLInputElement>;
                            for (let selectBox of multiselectCheckboxes) {
                                if (selectBox.checked) {
                                    alreadyExists = true;
                                    break;
                                }
                            }
                        }
                    }
                }

                if (!alreadyExists || ("forcerequired" in htmlElement.dataset && htmlElement.dataset.forcerequired === "true")) {
                    if (!element.classList.contains("missing-field")) {
                        if (element instanceof HTMLInputElement) {
                            let inputElement = <HTMLInputElement>element;

                            //Only validate once for radio buttons
                            if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "radio" && !inputElement.checked) {
                                if (inputElement.hasAttribute("name")) {
                                    let radioName = inputElement.getAttribute("name");

                                    let radioButtons = document.getElementsByName(radioName);
                                    let alreadyValidated: boolean = false;
                                    let isChecked: boolean = false;

                                    for (var i = 0, length = radioButtons.length; i < length; i++) {
                                        let radioElement = <HTMLInputElement>radioButtons[i];
                                        if (radioElement.classList.contains("missing-field")) {
                                            alreadyValidated = true;
                                        }

                                        if (radioElement.checked) {
                                            isChecked = true;
                                        }
                                    }

                                    if (!alreadyValidated && !isChecked) {
                                        inputElement.classList.add("missing-field");
                                        inputElement.setAttribute("aria-invalid", "true");
                                        Core.createErrorLabelForInput(inputElement);
                                        showMessage = true;
                                        totalErrors++;
                                    }
                                }
                            } else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "file") {
                                if ("hasuploaded" in inputElement.dataset && inputElement.dataset.hasuploaded != "true") {
                                    inputElement.classList.add("missing-field");
                                    inputElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(inputElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            } else if (inputElement.value === "") {
                                inputElement.classList.add("missing-field");
                                inputElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(inputElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        } else if (element instanceof HTMLSelectElement) {
                            let selectElement = <HTMLSelectElement>element;
                            if (selectElement.selectedIndex < 0 || selectElement.options[selectElement.selectedIndex].value === "") {
                                selectElement.classList.add("missing-field");
                                selectElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(selectElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        } else if (element instanceof HTMLTextAreaElement) {
                            let textAreaElement = <HTMLTextAreaElement>element;
                            if (textAreaElement.value === "") {
                                textAreaElement.classList.add("missing-field");
                                textAreaElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(textAreaElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        } else if ("multiselectvalidate" in htmlElement.dataset && htmlElement.dataset.multiselectvalidate === "true") {
                            let multiselectCheckboxes = htmlElement.getElementsByTagName("input") as HTMLCollectionOf<HTMLInputElement>;
                            let hasSelection = false;

                            for (let selectBox of multiselectCheckboxes) {
                                if (selectBox.checked) {
                                    hasSelection = true;
                                    break;
                                }
                            }

                            if (!hasSelection) {
                                htmlElement.classList.add("missing-field");
                                htmlElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(htmlElement);
                                showMessage = true;
                                totalErrors++;
                            }

                        }
                    }
                }
            }
        }

        let message = <HTMLDivElement>document.getElementById("validationMessage");

        let numberOfStudents 
        let prospectiveStudentsElement = <HTMLInputElement>document.getElementById("catsSystemProspectiveStudentsofProspectiveStudents");
        if (prospectiveStudentsElement !== null)
            numberOfStudents = Number(prospectiveStudentsElement.value);

        if (!isNaN(numberOfStudents) && numberOfStudents > 0) {
            prospectiveStudentsElement.classList.remove("missing-field");
            showMessage = false;
        }
        else {
            totalErrors++;
            showMessage = true;
            prospectiveStudentsElement.classList.add("missing-field");
        }

        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        } else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");

        if (goToError !== null) {

            let that = this;

            let firstFocusableEl = <HTMLElement>document.querySelector(".missing-field");

            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");

                        let accordionElement = <HTMLButtonElement>document.getElementById(id);
                        accordionElement.click();
                    }

                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    } else {
                        firstFocusableEl.focus();
                    }
                });
            } else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }
}

// CatsSystemTechAndResources
class CatsSystemTechAndResources {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["catsSystemTechAndResourcesField"];

        let catsSystemTechAndResourcesSaveButton = document.getElementById("catsSystemTechAndResourcesSave");
        if (catsSystemTechAndResourcesSaveButton !== null)
            catsSystemTechAndResourcesSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        this.saveAccess();

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    saveAccess() {
        const formName = "catsSystemTechAndResourcesForm";
        let planFK = 0;
        let form = document.getElementById(formName);
        planFK = parseInt(form.dataset.parentplanfk);

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemAccess', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {

            }
        };
        xhr.send(`planFK=${planFK}`);
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("catsSystemTechAndResourcesForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("catsSystemTechAndResourcesField");

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemTechAndResources', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

// CatsSystemSupportService
class CatsSystemSupportService {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["catsSystemSupportServiceField"];

        let catsSystemSupportServiceSaveButton = document.getElementById("catsSystemSupportServiceSave");
        if (catsSystemSupportServiceSaveButton !== null)
            catsSystemSupportServiceSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("catsSystemSupportServiceForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("catsSystemSupportServiceField");

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemSupportService', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

// CatsSystemCareerAndTech
class CatsSystemCareerAndTech {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["catsSystemCareerAndTechField", "catsSystemCareerAndTechFileField"];

        let catsSystemCareerAndTechSaveButton = document.getElementById("catsSystemCareerAndTechSave");
        if (catsSystemCareerAndTechSaveButton !== null)
            catsSystemCareerAndTechSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses, false, true);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        const catsSystemAddCTSOButton = document.getElementById("catsSystemAddCTSO");
        if (catsSystemAddCTSOButton !== null)
            catsSystemAddCTSOButton.addEventListener("click", (e: Event) => this.addCTSO());

        this.bindUploaders();
        this.bindDeletes();
        this.saveAccess();

        const catsSystemCTSODeleteConfirmButton = document.getElementById("catsSystemCTSODeleteConfirm");
        if (catsSystemCTSODeleteConfirmButton !== null)
            catsSystemCTSODeleteConfirmButton.addEventListener("click", (e: Event) => this.deleteCTSOConfirm(e));

        const catsSystemCTSODeleteCancelButton = document.getElementById("catsSystemCTSODeleteCancel");
        if (catsSystemCTSODeleteCancelButton !== null)
            catsSystemCTSODeleteCancelButton.addEventListener("click", (e: Event) => this.deleteCTSOCancel());

        const deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e: Event) => this.deleteFileConfirm(e));

        const deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e: Event) => this.deleteFileCancel());

        this.bindDeleteFiles();
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    saveAccess() {
        const formName = "catsSystemCareerAndTechForm";
        let planFK = 0;
        let form = document.getElementById(formName);
        planFK = parseInt(form.dataset.parentplanfk);

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemAccess', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {

            }
        };
        xhr.send(`planFK=${planFK}`);
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("catsSystemCareerAndTechForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("catsSystemCareerAndTechField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanPropertyExtended = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                    ButtonRow: 0
                };

                allSaveElements.push(saveItem);
            }
        }

        let files = document.getElementsByClassName("catsSystemCareerAndTechFileUploadField");
        let allFileElements = [];
        for (let ele of files) {
            planPK = parseInt(formElement.dataset.planfk);

            let element = <HTMLInputElement>ele;

            let sequenceNbr = parseInt(element.dataset.sequencenbr);
            let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk)
            let propertyRelationshipTypePK = parseInt(element.dataset.propertyrelationtypepk);
            let fileUploadPK = parseInt(element.dataset.fileuploadpk);
            let propertyPK = parseInt(element.dataset.propertypk);
            let planPropertyPlanPropertyFilePK = parseInt(element.dataset.planpropertyplanpropertyfilepk);

            let saveItem: IPlanPropertyPlanPropertyFile = {
                PlanPropertyPK: dataPointPlanPropertyPK,
                PlanPropertyFilePK: planPropertyFilePK,
                SequenceNbr: sequenceNbr,
                PropertyRelationshipTypePK: propertyRelationshipTypePK,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanPropertyPlanPropertyFilePK: planPropertyPlanPropertyFilePK
            };

            allFileElements.push(saveItem);
        }


        var allData = {
            "ElementData": allSaveElements,
            "FileData": allFileElements,
            "PlanPK": planPK
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemCareerAndTech', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0 && allFileElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Add a CTSO (or data inside of an added CTSO).", 'warning', 3000, null);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }

    async addCTSO() {
        const form = document.getElementById("catsSystemCareerAndTechForm");
        const planFK = form.dataset.planfk;
        const dropdown = <HTMLSelectElement>document.getElementById("catsSystemCareerAndTechCTSODropdown");
        const dropdownValue = parseInt(dropdown.value);

        if (dropdownValue && !isNaN(dropdownValue) && dropdownValue > 0) {
            Core.showLoader();

            const response = await fetch(`/CATSSystem/AddCTSO/${planFK}/${dropdownValue}`, { credentials: 'include' })
            if (response.ok) {

                const output = await response.text();

                let newDiv = <HTMLDivElement>document.createElement("div");
                newDiv.innerHTML = output;

                let container = document.getElementById("ctsoContainer");
                container.append(newDiv);

                let idControl = newDiv.querySelector(".Accordion h2 button");
                let id = idControl.id;
                new CustomAccordion(id);

                this.bindUploaders();
                this.bindDeletes();

                this._core.initializeRequiredFields(this.validationClasses, false, true);
            }

            Core.hideLoader();
        } else {
            Core.createHTMLAlert("alertMessageDiv", `Please select a CTSO value to add to the list`, "warning", 3000, null);
        }
    }

    bindUploaders() {
        const fileUploaders = document.getElementsByClassName("catsSystemCareerAndTechUploadCTSOMembershipDocuments");
        for (const fileUploader of fileUploaders) {
            let ele = <HTMLInputElement>fileUploader;
            let listener = ele.dataset.listener;
            if (listener === "false") {
                ele.addEventListener("change", (e: Event) => this.uploadFile(<HTMLInputElement>e.target));
                ele.setAttribute("listener", "true");
                ele.dataset.listener = "true";
            }
        }
    }

    uploadFile(e: HTMLInputElement) {
        let core = this._core;
        Core.showLoader();
        let formName = e.dataset.formname;
        let dataPointPlanPropertyFK = e.dataset.datapointplanpropertypk;

        let uploadSampleForm = <HTMLFormElement>document.getElementById(formName);
        let formData = new FormData(uploadSampleForm);
        let that = this;

        let propertyPK = e.dataset.propertypk;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SchoolReconfigurations/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();

                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);

                        let formFile = <HTMLInputElement>document.querySelector(`.catsSystemCareerAndTechUploadCTSOMembershipDocuments[data-datapointplanpropertypk='${dataPointPlanPropertyFK}']`);
                        formFile.value = "";

                        let fileList = document.querySelector(`.uploadedFiles[data-datapointplanpropertypk='${dataPointPlanPropertyFK}']`)

                        let currentFileList = fileList.querySelectorAll(".uploadFileList[data-datapointplanpropertypk='${dataPointPlanPropertyFK}']");

                        let sequence = -1;
                        for (let file of currentFileList) {
                            let fileEle = <HTMLDivElement>file;

                            let thisSequence = parseInt(fileEle.dataset.sequencenbr);
                            if (thisSequence > sequence) {
                                sequence = thisSequence;
                            }
                        }


                        let uploadedContainer = document.querySelector(`.uploadedFiles[data-datapointplanpropertypk='${dataPointPlanPropertyFK}']`);
                        uploadedContainer.classList.remove("hide");

                        sequence++;

                        let propertyRelationTypePK = e.dataset.propertyrelationtypepk;

                        let fileWrapper = <HTMLDivElement>document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = <HTMLDivElement>document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = <HTMLAnchorElement>document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.planpropertypk = dataPointPlanPropertyFK.toString();
                        fileA.dataset.datapointplanpropertypk = dataPointPlanPropertyFK.toString();
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.dataset.sequencenbr = sequence.toString();
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.propertyrelationtypepk = propertyRelationTypePK;
                        fileA.dataset.planpropertyplanpropertyfilepk = "0";
                        fileA.classList.add("catsSystemCareerAndTechFileUploadField");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);

                        fileList.appendChild(fileWrapper);

                        that.bindDeleteFiles();
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }

    bindDeletes() {
        const allDeletes = document.getElementsByClassName("deleteCTSO");
        for (const thisDelete of allDeletes)
            thisDelete.addEventListener("click", (e: Event) => this.showDeleteCTSO(e));
    }

    showDeleteCTSO(e: Event) {
        const buttonElement = <HTMLButtonElement>e.target;
        const dataPointPlanPropertyFK = buttonElement.dataset.datapointplanpropertypk;

        const modal: Modal = new Modal("deleteCTSOModal", null);
        modal.addAttributeToElement("deleteCTSOModal", "#catsSystemCTSODeleteConfirm", "datapointplanpropertypk", dataPointPlanPropertyFK);
        modal.show();
    }

    deleteCTSOCancel() {
        const modal: Modal = new Modal("deleteCTSOModal", null);
        modal.hide();
    }

    async deleteCTSOConfirm(e: Event) {
        const buttonElement = <HTMLButtonElement>e.target;
        const dataPointPlanPropertyFK = buttonElement.dataset.datapointplanpropertypk;

        Core.showLoader();

        const response = await fetch(`/CATSSystem/DeleteCTSO/${dataPointPlanPropertyFK}`, { credentials: 'include' })
        if (response.ok) {
            const output = await response.text();
            if (output === "true") {
                const container = <HTMLButtonElement>document.querySelector(`.Accordion-trigger[data-datapointplanpropertyfk='${dataPointPlanPropertyFK}']`);
                // button > h2          > div.Accordion     > div
                container.parentElement.parentElement.parentElement.remove();

                Core.createHTMLAlert("alertMessageDiv", `The record was successfully removed.`, "success", 3000, null);
                Core.hideLoader();
            } else {
                Core.createHTMLAlert("alertMessageDiv", `There was an issue removing the record. Please try again.`, "error", 3000, null);
                Core.hideLoader();
            }

            const modal: Modal = new Modal("deleteCTSOModal", null);
            modal.hide();
        } else {
            Core.createHTMLAlert("alertMessageDiv", `There was an issue removing the record. Please try again.`, "error", 3000, null);
            Core.hideLoader();

            const modal: Modal = new Modal("deleteCTSOModal", null);
            modal.hide();
        }
    }

    bindDeleteFiles() {
        let deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (let deleteFileButton of deleteFileButtons)
            deleteFileButton.addEventListener("click", (e: Event) => this.showDeleteFile(e));
    }

    showDeleteFile(e: Event) {
        const buttonElement = <HTMLButtonElement>e.target;
        const planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        const dataPointPlanPropertyPK = buttonElement.dataset.datapointplanpropertypk;

        let modal: Modal = new Modal("deleteFileModal", null);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "planpropertyfilepk", planPropertyFilePK);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "datapointplanpropertypk", dataPointPlanPropertyPK);
        modal.show();
    }

    deleteFileConfirm(e: Event) {
        const buttonElement = <HTMLButtonElement>e.target;
        const planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        const dataPointPlanPropertyPK = buttonElement.dataset.datapointplanpropertypk;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/DeleteFileData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    const element = document.querySelector(`.catsSystemCareerAndTechFileUploadField[data-planpropertyfilepk='${planPropertyFilePK}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                    const parent = element.parentElement.parentElement;
                    parent.remove();

                    const moreFiles = document.querySelectorAll(`.uploadFileList[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'] .uploadFileColumn`);
                    if (moreFiles.length === 0) {
                        const uploadsElement = <HTMLDivElement>document.querySelector(`.uploadedFiles[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                        if (uploadsElement !== null) {
                            uploadsElement.classList.add("hide");
                        }
                    }

                    Core.createHTMLAlert("alertMessageDiv", "File successfully removed.", 'success', 3000, null);
                } else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the file. Please try again.", 'error', 3000, null);
                }

                Core.hideLoader();

                let modal: Modal = new Modal("deleteFileModal", null);
                modal.hide();
            } else {
                let modal: Modal = new Modal("deleteFileModal", null);
                modal.hide();

                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planPropertyFilePK));
    }

    deleteFileCancel() {
        let modal: Modal = new Modal("deleteFileModal", null);
        modal.hide();
    }
}

// CatsSystemAdvisoryCommittees
class CatsSystemAdvisoryCommittees {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["catsSystemAdvisoryCommitteesField", "catsSystemAdvisoryCommitteesFileField"];

        let catsSystemAdvisoryCommitteesSaveButton = document.getElementById("catsSystemAdvisoryCommitteesSave");
        if (catsSystemAdvisoryCommitteesSaveButton !== null)
            catsSystemAdvisoryCommitteesSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        const addCommitteeButton = document.getElementById("catsSystemAddAdvisoryCommitteesDetails");
        if (addCommitteeButton !== null)
            addCommitteeButton.addEventListener("click", (e: Event) => this.addAdvisoryCommitteesDetails())

        this.bindFileUploaders();
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("catsSystemAdvisoryCommitteesForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("catsSystemAdvisoryCommitteesField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let allFileElements: IPlanPropertyFile[] = [];
        let files = document.getElementsByClassName("catsSystemAdvisoryCommitteesFileUploadField");
        for (let ele of files) {
            let element = <HTMLInputElement>ele;
            let sequenceNbr = parseInt(element.dataset.row);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk)
            let fileUploadPK = parseInt(element.dataset.fileuploadpk);
            let propertyPK = parseInt(element.dataset.propertypk);

            let saveItem: IPlanPropertyFile = {
                PlanPropertyFilePK: planPropertyFilePK,
                SequenceNbr: sequenceNbr,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanFK: planPK
            };

            allFileElements.push(saveItem);
        }

        var allData = {
            "ElementData": allSaveElements,
            "FileData": allFileElements
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemAdvisoryCommittees', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }

    async addAdvisoryCommitteesDetails() {
        Core.showLoader();
        const form = document.getElementById("catsSystemAdvisoryCommitteesForm");
        const planFK = form.dataset.planfk;
        const allRows = document.querySelectorAll(".advisoryCommitteesDetailsRow");
        let row = 0;
        for (let thisRow of allRows) {
            const ele = <HTMLDivElement>thisRow;
            const currentRow = parseInt(ele.dataset.row);
            if (!isNaN(currentRow) && currentRow > row)
                row = currentRow
        }

        row++;

        const response = await fetch(`/CATSSystem/AddAdvisoryCommitteesDetails/${planFK}/${row}`, { credentials: 'include' })
        if (response.ok) {

            const output = await response.text();

            let newDiv = <HTMLDivElement>document.createElement("div");
            newDiv.innerHTML = output;
            newDiv.classList.add("advisoryCommitteesDetailsRow");
            newDiv.dataset.row = row.toString();
            newDiv.classList.add("advisory-committees-details");

            let container = document.getElementById("advisoryCommitteesDetails");
            container.append(newDiv);

            this.bindFileUploaders();
        }

        Core.hideLoader();
    }

    bindFileUploaders() {
        const fileUploaders = document.getElementsByClassName("catsSystemAdvisoryCommitteesFileField");
        for (const fileUploader of fileUploaders)
            fileUploader.addEventListener("change", (e: Event) => this.uploadFile(<HTMLInputElement>e.target));
    }

    uploadFile(e: HTMLInputElement) {
        let core = this._core;
        Core.showLoader();
        let formName = e.dataset.formname;

        let row = e.dataset.row;

        let uploadSampleForm = <HTMLFormElement>document.getElementById(formName);
        let formData = new FormData(uploadSampleForm);

        let propertyPK = e.dataset.propertypk;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();

                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);

                        let formFile = <HTMLInputElement>document.querySelector(`#catsSystemAdvisoryCommitteeMeetingMinutes${row}`);
                        formFile.value = "";

                        let fileList = document.querySelector(`.uploadFileList[data-row='${row}']`);

                        let uploadedContainer = document.querySelector(`.uploadedFiles[data-row='${row}']`);
                        uploadedContainer.classList.remove("hide");

                        let fileWrapper = <HTMLDivElement>document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = <HTMLDivElement>document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = <HTMLAnchorElement>document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.row = row.toString();
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.sequencenbr = row.toString();
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.classList.add("catsSystemAdvisoryCommitteesFileUploadField");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);

                        fileList.appendChild(fileWrapper);

                        //that.bindDeleteFiles();
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
}

// CatsSystemScope
class CatsSystemScope {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["catsSystemScopeField","catsSystemScopeFileField"];

        let catsSystemScopeSaveButton = document.getElementById("catsSystemScopeSave");
        if (catsSystemScopeSaveButton !== null)
            catsSystemScopeSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        this._core.initializeRequiredFields(this.validationClasses);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }

        this.saveAccess();

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        const deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e: Event) => this.deleteFileConfirm(e));

        const deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e: Event) => this.deleteFileCancel());

        const catsSystemScopeAndSequenceAddArticulationAgreementButton = document.getElementById("catsSystemScopeAndSequenceAddArticulationAgreement");
        if (catsSystemScopeAndSequenceAddArticulationAgreementButton !== null)
            catsSystemScopeAndSequenceAddArticulationAgreementButton.addEventListener("click", (e: Event) => this.addArticulationAgreement());

        this.bindUploaders();
        this.bindDeleteFiles();
        this.bindDeleteSecondaryArticulationAgreements();
        this.bindDeletePrimaryArticulationAgreements();

        const catsSystemScopeAndSequenceSecondaryArticulationAgreementDeleteConfirmButton = document.getElementById("catsSystemScopeAndSequenceSecondaryArticulationAgreementDeleteConfirm");
        if (catsSystemScopeAndSequenceSecondaryArticulationAgreementDeleteConfirmButton !== null)
            catsSystemScopeAndSequenceSecondaryArticulationAgreementDeleteConfirmButton.addEventListener("click", (e: Event) => this.deleteSecondaryArticulationAgreementConfirm(e));

        const catsSystemScopeAndSequenceSecondaryArticulationAgreementDeleteCancelButton = document.getElementById("catsSystemScopeAndSequenceSecondaryArticulationAgreementDeleteCancel");
        if (catsSystemScopeAndSequenceSecondaryArticulationAgreementDeleteCancelButton !== null)
            catsSystemScopeAndSequenceSecondaryArticulationAgreementDeleteCancelButton.addEventListener("click", (e: Event) => this.deleteSecondaryArticulationAgreementCancel());

        const catsSystemScopeAndSequencePrimaryArticulationAgreementDeleteConfirmButton = document.getElementById("catsSystemScopeAndSequencePrimaryArticulationAgreementDeleteConfirm");
        if (catsSystemScopeAndSequencePrimaryArticulationAgreementDeleteConfirmButton !== null)
            catsSystemScopeAndSequencePrimaryArticulationAgreementDeleteConfirmButton.addEventListener("click", (e: Event) => this.deletePrimaryArticulationAgreementConfirm(e));

        const catsSystemScopeAndSequencePrimaryArticulationAgreementDeleteCancelButton = document.getElementById("catsSystemScopeAndSequencePrimaryArticulationAgreementDeleteCancel");
        if (catsSystemScopeAndSequencePrimaryArticulationAgreementDeleteCancelButton !== null)
            catsSystemScopeAndSequencePrimaryArticulationAgreementDeleteCancelButton.addEventListener("click", (e: Event) => this.deletePrimaryArticulationAgreementCancel());

    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    saveAccess() {
        const formName = "catsSystemScopeForm";
        let planFK = 0;
        let form = document.getElementById(formName);
        planFK = parseInt(form.dataset.parentplanfk);

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemAccess', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {

            }
        };
        xhr.send(`planFK=${planFK}`);
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        const formElement = document.getElementById("catsSystemScopeForm");
        const planPK = parseInt(formElement.dataset.planfk);
        const programFK = parseInt(formElement.dataset.catsprogramfk);

        let primaryArticulations = [];
        let secondaryArticulations = [];

        const allArticulations = document.querySelectorAll(".articulationAgreementContainer");
        for (const articulation of allArticulations) {
            const ele = <HTMLDivElement>articulation;
            const articulationAgreementPK = ele.dataset.articulationagreementpk;
            const sequence = ele.dataset.sequence;
            const articulationCode = ele.dataset.articulationcode;
            const nameQuery = <HTMLInputElement>document.querySelector(`.catsSystemScopeArticulationNameField[data-articulationagreementpk='${articulationAgreementPK}']`);
            let articulationName = "";
            if (nameQuery && nameQuery.value !== "")
                articulationName = nameQuery.value;

            const typeQuery = <HTMLSelectElement>document.querySelector(`.catsSystemScopeArticulationTypeField[data-articulationagreementpk='${articulationAgreementPK}']`);
            let articulationType = "";
            if (typeQuery && typeQuery.value !== "")
                articulationType = typeQuery.value;

            //Primary file
            const primaryFile = <HTMLAnchorElement>document.querySelector(`.catsSystemScopePrimaryFileUploadField[data-articulationagreementpk='${articulationAgreementPK}'][data-sequence='${sequence}']`);

            let primaryFilePlanPropertyFilePK = "0";
            let primaryFileFileUploadPK = "0";
            let primaryFileSequenceNbr = "0";
            let primaryPropertyPK = "0";
            let articulationAgreementFilePK = "0";

            if (articulationCode === "catsArticulationAgreementStatewide")
                primaryFilePlanPropertyFilePK = null;

            if (primaryFile !== null) {
                primaryFilePlanPropertyFilePK = primaryFile.dataset.planpropertyfilepk;
                primaryFileFileUploadPK = primaryFile.dataset.fileuploadpk;
                primaryFileSequenceNbr = primaryFile.dataset.filesequence;
                primaryPropertyPK = primaryFile.dataset.propertypk;
                articulationAgreementFilePK = primaryFile.dataset.catsarticulationagreementplanpropertyfilepk;
            }

            const primaryArticulation = {
                "ArticulationAgreementPK": articulationAgreementPK,
                "CATSArticulationAgreementPlanPropertyFilePK": articulationAgreementFilePK,
                "ArticulationName": articulationName,
                "ArticulationTypeName": articulationType,
                "SequenceNbr": sequence,
                "PlanPropertyFilePK": primaryFilePlanPropertyFilePK,
                "FileUploadPK": primaryFileFileUploadPK,
                "FileSequenceNbr": primaryFileSequenceNbr,
                "PlanPK": planPK,
                "CATSProgramPK": programFK,
                "PropertyPK": primaryPropertyPK
            };

            primaryArticulations.push(primaryArticulation);
        }

        const allSecondaryArticulations = document.querySelectorAll(`.catsSystemScopeSecondaryFileUploadField`);
        for (const secondary of allSecondaryArticulations) {
            const ele = <HTMLAnchorElement>secondary;

            const sequence = ele.dataset.filesequence;
            const fileUploadPK = ele.dataset.fileuploadpk;
            const articulationAgreementPK = ele.dataset.articulationagreementpk;
            const catsArticulationAgreementPlanPropertyFilePK = ele.dataset.catsarticulationagreementplanpropertyfilepk;
            const propertyPK = ele.dataset.propertypk;
            const filePlanPropertyFilePK = ele.dataset.planpropertyfilepk;

            const secondaryArticulation = {
                "CATSArticulationAgreementPlanPropertyFilePK": catsArticulationAgreementPlanPropertyFilePK,
                "ArticulationAgreementPK": articulationAgreementPK,
                "FileUploadPK": fileUploadPK,
                "Sequence": sequence,
                "PropertyPK": propertyPK,
                "PlanPropertyFilePK": filePlanPropertyFilePK
            };

            secondaryArticulations.push(secondaryArticulation);
        }

        const data = {
            "PrimaryArticulations": primaryArticulations,
            "SecondaryArticulations": secondaryArticulations,
            "PlanPK": planPK
        };

        if (data !== null && data.PrimaryArticulations.length > 0) {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/CATSSystem/SaveCatsSystemScope', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (referrer === "save") {
                    if (xhr.status === 200) {
                        Core.hideLoader();
                        core.pageReload(true, planPK);
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                    }
                }
                else {
                    if (xhr.status === 200) {
                        if (refreshPage && refreshPage !== "")
                            window.location.href = refreshPage;
                        else
                            Core.hideLoader();
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                    }
                }
            };
            xhr.send(JSON.stringify(data));
        } else {
            Core.createHTMLAlert("alertMessageDiv", `Each CIP must have an associated articulation agreement.`, 'warning', 5000, null);
            Core.hideLoader();
        }
    }


    async addArticulationAgreement() {
        Core.showLoader();
        const selection = <HTMLSelectElement>document.getElementById("catsSystemScopeArticulationAgreementwith");
        let selectionLookupPK = "";
        let type = "lookup";
        if (selection !== null) {
            selectionLookupPK = selection.value;

            const option = <HTMLOptionElement>selection[selection.selectedIndex];
            type = option.dataset.type;
        }
        const form = <HTMLDivElement>document.getElementById("catsSystemScopeForm");
        let planFK = form.dataset.planfk;
        let parentPlanFK = form.dataset.parentplanfk;
        let catsProgramFK = form.dataset.catsprogramfk;

        let newSequence = 0;

        const allArticulations = document.querySelectorAll(".articulationAgreementContainer");
        for (const articulation of allArticulations) {
            const ele = <HTMLDivElement>articulation;

            let thisSeq = parseInt(ele.dataset.sequence);
            if (!isNaN(thisSeq) && thisSeq > newSequence)
                newSequence = thisSeq;
        }

        newSequence++;

        let response = await fetch(`/CATSSystem/AddArticulationAgreement/${parentPlanFK}/${planFK}/${catsProgramFK}/${selectionLookupPK}/${type}/${newSequence}`, { credentials: 'include' })
        if (response.ok) {

            const output = await response.text();

            const newDiv = <HTMLDivElement>document.createElement("div");
            newDiv.classList.add("articulationAgreement");
            newDiv.dataset.sequence = newSequence.toString();

            newDiv.innerHTML = output;

            const deleteButton = <HTMLButtonElement>newDiv.querySelector(".deleteArticulationAgreement");
            const articulationAgreementPK = deleteButton.dataset.articulationagreementpk;

            newDiv.dataset.articulationagreementpk = articulationAgreementPK;

            const container = document.getElementById("catsSystemArticulationAgreements");
            container.appendChild(newDiv);

            selection.selectedIndex = -1;

            this.bindUploaders();
            this.bindDeletePrimaryArticulationAgreements();
            this._core.initializeRequiredFields(this.validationClasses);

            Core.hideLoader();
        } else {
            Core.hideLoader();
        }
    }

    bindUploaders() {
        const uploaders = document.getElementsByClassName("catsSystemScopeFileUploader");
        for (const uploader of uploaders)
            uploader.addEventListener("change", (e: Event) => this.uploadFile(<HTMLInputElement>e.target));
    }

    uploadFile(e: HTMLInputElement) {
        Core.showLoader();
        let formName = e.dataset.formname;

        const articulationAgreementPK = e.dataset.articulationagreementpk;
        const articulationName = e.dataset.articulationname;
        const articulationSequence = e.dataset.sequence;

        const uploadSampleForm = <HTMLFormElement>document.getElementById(formName);
        const formData = new FormData(uploadSampleForm);

        const propertyPK = e.dataset.propertypk;

        const pageForm = document.getElementById("catsSystemScopeForm");
        const planFK = pageForm.dataset.planfk;
        const programFK = pageForm.dataset.catsprogramfk;
        const that = this;

        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    const res = JSON.parse(xhr.responseText);
                    if (res.success === true) {

                        const formFile = <HTMLInputElement>document.querySelector(`.catsSystemScopeFileUploader[data-formname='${formName}']`);
                        formFile.value = "";

                        const primaryCheck = document.querySelectorAll(`.primaryFile[data-articulationagreementpk='${articulationAgreementPK}'] .uploadFileColumn`);
                        let sequence = 1;
                        if (primaryCheck && primaryCheck.length == 0) {

                            let fileWrapper = <HTMLDivElement>document.createElement("div");
                            fileWrapper.classList.add("medium-6");
                            fileWrapper.classList.add("columns");
                            fileWrapper.classList.add("end");
                            fileWrapper.classList.add("uploadFileColumn");
                            let newFile = <HTMLDivElement>document.createElement("div");
                            newFile.classList.add("uploaded-file-container");
                            let fileA = <HTMLAnchorElement>document.createElement("a");
                            fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                            fileA.dataset.row = sequence.toString();
                            fileA.dataset.propertypk = propertyPK;
                            fileA.dataset.filesequence = sequence.toString();
                            fileA.dataset.sequence = articulationSequence.toString();
                            fileA.dataset.propertypk = propertyPK;
                            fileA.dataset.planpropertyfilepk = "0";
                            fileA.dataset.primaryfile = 'primary';
                            fileA.dataset.articulationagreementpk = articulationAgreementPK;
                            fileA.classList.add("catsSystemScopeFileUploadField");
                            fileA.classList.add("catsSystemScopePrimaryFileUploadField");
                            fileA.href = "javascript:void(0);";
                            fileA.text = res.payload.filename;
                            newFile.appendChild(fileA);
                            fileWrapper.appendChild(newFile);

                            const primaryFileContainer = document.querySelector(`.primaryFile[data-articulationagreementpk='${articulationAgreementPK}']`);
                            primaryFileContainer.appendChild(fileWrapper);

                            //that.bindDeleteFiles();

                            const uploadedFilesContainer = document.querySelector(`.uploadedFiles[data-articulationagreementpk='${articulationAgreementPK}']`);
                            uploadedFilesContainer.classList.remove("hide");

                            Core.hideLoader();
                            Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);

                        } else {
                            const allCurrentFiles = document.querySelectorAll(`.catsSystemScopeFileUploadField[data-articulationagreementpk='${articulationAgreementPK}']`);
                            let thisSequence = 1; //The default will start at 1, since there will ALWAYS be 1 file there before this is run.
                            for (const currentFile of allCurrentFiles) {
                                const ele = <HTMLAnchorElement>currentFile;
                                const seq = parseInt(ele.dataset.filesequence);

                                if (seq > thisSequence)
                                    thisSequence = seq;
                            }

                            thisSequence++;

                            const data = {
                                "SequenceNbr": thisSequence,
                                "ArticulationName": articulationName,
                                "ArticulationAgreementPK": articulationAgreementPK,
                                "PlanFK": planFK,
                                "CATSProgramFK": programFK,
                                "FileUploadFK": res.payload.fileUploadPK,
                                "Filename": res.payload.filename,
                                "IsPrimaryFileInd": false
                            };

                            const xhrInner = new XMLHttpRequest();
                            xhrInner.open('POST', '/CATSSystem/AddScopeAndSequenceSecondaryFile', true);
                            xhrInner.setRequestHeader('Content-Type', 'application/json');
                            xhrInner.onload = function () {
                                if (xhr.responseText !== null) {
                                    const output = xhrInner.responseText;
                                    const newDiv = <HTMLDivElement>document.createElement("div");
                                    newDiv.classList.add("articulationAgreement");
                                    newDiv.dataset.sequence = thisSequence.toString();

                                    newDiv.innerHTML = output;

                                    const container = document.getElementById("catsSystemArticulationAgreements");
                                    container.appendChild(newDiv);

                                    Core.hideLoader();
                                    Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);
                                } else {
                                    Core.hideLoader();
                                    Core.createHTMLAlert("alertMessageDiv", "There was an issue uploading the file. Please try again.", 'error', 3000, null);
                                }
                            }
                            xhrInner.send(JSON.stringify(data));
                        }

                        that.bindDeleteFiles();
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            }
        };
        xhr.send(formData);
    }

    bindDeleteFiles() {
        let deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (let deleteFileButton of deleteFileButtons)
            deleteFileButton.addEventListener("click", (e: Event) => this.showDeleteFile(e));
    }

    showDeleteFile(e: Event) {
        const buttonElement = <HTMLButtonElement>e.target;
        const articulationAgreementPK = buttonElement.dataset.articulationagreementpk;
        const planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        const catsArticulationAgreementPlanPropertyFilePK = buttonElement.dataset.catsarticulationagreementplanpropertyfilepk;

        const modal: Modal = new Modal("deleteFileModal", null);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "articulationagreementpk", articulationAgreementPK);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "catsarticulationagreementplanpropertyfilepk", catsArticulationAgreementPlanPropertyFilePK);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "planpropertyfilepk", planPropertyFilePK);
        modal.show();
    }

    deleteFileConfirm(e: Event) {
        const buttonElement = <HTMLButtonElement>e.target;
        const planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        const articulationAgreementPK = buttonElement.dataset.articulationagreementpk;
        const catsArticulationAgreementPlanPropertyFilePK = parseInt(buttonElement.dataset.catsarticulationagreementplanpropertyfilepk);
        const modal: Modal = new Modal("deleteFileModal", null);

        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/DeleteArticulationFileData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    const element = document.querySelector(`.catsSystemScopePrimaryFileUploadField[data-planpropertyfilepk='${planPropertyFilePK}']`);
                    const parent = element.parentElement.parentElement;
                    parent.remove();

                    const moreFiles = document.querySelectorAll(`.primaryFile[data-articulationagreementpk='${articulationAgreementPK}'] .uploadFileColumn`);
                    if (moreFiles.length === 0) {
                        const uploadsElement = <HTMLDivElement>document.querySelector(`.uploadedFiles[data-articulationagreementpk='${articulationAgreementPK}']`);

                        uploadsElement.classList.add("hide");
                    }

                    Core.createHTMLAlert("alertMessageDiv", "File successfully removed.", 'success', 3000, null);
                } else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the file. Please try again.", 'error', 3000, null);
                }

                Core.hideLoader();

                modal.hide();
            } else {
                modal.hide();

                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(catsArticulationAgreementPlanPropertyFilePK));
    }

    deleteFileCancel() {
        let modal: Modal = new Modal("deleteFileModal", null);
        modal.hide();
    }

    bindDeleteSecondaryArticulationAgreements() {
        const allSecondaryAgreements = document.getElementsByClassName("removeSecondaryArticulationAgreement");
        for (const agreement of allSecondaryAgreements)
            agreement.addEventListener("click", (e: Event) => this.showDeleteSecondaryArticulationAgreement(e));
    }

    showDeleteSecondaryArticulationAgreement(e: Event) {
        const buttonElement = <HTMLButtonElement>e.target;
        const articulationAgreementPK = buttonElement.dataset.articulationagreementpk;
        const planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;

        const modal: Modal = new Modal("deleteSecondaryArticulationAgreementModal", null);
        modal.addAttributeToElement("deleteSecondaryArticulationAgreementModal", "#catsSystemScopeAndSequenceSecondaryArticulationAgreementDeleteConfirm", "articulationagreementpk", articulationAgreementPK);
        modal.addAttributeToElement("deleteSecondaryArticulationAgreementModal", "#catsSystemScopeAndSequenceSecondaryArticulationAgreementDeleteConfirm", "planpropertyfilepk", planPropertyFilePK);
        modal.show();
    }

    deleteSecondaryArticulationAgreementCancel() {
        let modal: Modal = new Modal("deleteSecondaryArticulationAgreementModal", null);
        modal.hide();
    }

    deleteSecondaryArticulationAgreementConfirm(e: Event) {
        const buttonElement = <HTMLButtonElement>e.target;
        const planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        const articulationAgreementPK = buttonElement.dataset.articulationagreementpk;
        const modal: Modal = new Modal("deleteSecondaryArticulationAgreementModal", null);

        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/DeleteSecondaryArticulationAgreementData', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    const element = document.querySelector(`.removeSecondaryArticulationAgreementContainer[data-articulationagreementpk='${articulationAgreementPK}'][data-planpropertyfilepk='${planPropertyFilePK}']`);
                    element.remove();

                    Core.createHTMLAlert("alertMessageDiv", "Articulation Agreement successfully removed.", 'success', 3000, null);
                } else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the articulation agreement. Please try again.", 'error', 3000, null);
                }

                Core.hideLoader();

                modal.hide();
            } else {
                modal.hide();

                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(`articulationAgreementPK=${articulationAgreementPK}&planPropertyFilePK=${planPropertyFilePK}`);
    }

    bindDeletePrimaryArticulationAgreements() {
        const allPrimaryAgreements = document.getElementsByClassName("deleteArticulationAgreement");
        for (const agreement of allPrimaryAgreements)
            agreement.addEventListener("click", (e: Event) => this.showDeletePrimaryArticulationAgreement(e));
    }

    showDeletePrimaryArticulationAgreement(e: Event) {
        const buttonElement = <HTMLButtonElement>e.target;
        const articulationAgreementPK = buttonElement.dataset.articulationagreementpk;
        const planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;

        const modal: Modal = new Modal("deletePrimaryArticulationAgreementModal", null);
        modal.addAttributeToElement("deletePrimaryArticulationAgreementModal", "#catsSystemScopeAndSequencePrimaryArticulationAgreementDeleteConfirm", "articulationagreementpk", articulationAgreementPK);
        modal.show();
    }

    deletePrimaryArticulationAgreementCancel() {
        let modal: Modal = new Modal("deletePrimaryArticulationAgreementModal", null);
        modal.hide();
    }

    deletePrimaryArticulationAgreementConfirm(e: Event) {
        const buttonElement = <HTMLButtonElement>e.target;
        const articulationAgreementPK = buttonElement.dataset.articulationagreementpk;
        const modal: Modal = new Modal("deletePrimaryArticulationAgreementModal", null);

        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/DeletePrimaryArticulationAgreementData', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    const element = document.querySelector(`.articulationAgreement[data-articulationagreementpk='${articulationAgreementPK}']`);
                    element.remove();

                    Core.createHTMLAlert("alertMessageDiv", "Articulation Agreement successfully removed.", 'success', 3000, null);
                } else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the articulation agreement. Please try again.", 'error', 3000, null);
                }

                Core.hideLoader();

                modal.hide();
            } else {
                modal.hide();

                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(`articulationAgreementPK=${articulationAgreementPK}`);
    }
}

// CatsSystemsSecondary
class CatsSystemSecondary {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["catsSystemSecondaryField"];

        let catsSystemSecondarySaveButton = document.getElementById("catsSystemSecondarySave");
        if (catsSystemSecondarySaveButton !== null)
            catsSystemSecondarySaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this.alignTechnical();
        this.sumHours();

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.doValidationCustom(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        this.saveAccess();

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        const addSecondarySchoolTechnicalRowButton = document.querySelector(`.addSecondarySchoolTechnicalRow`);
        if (addSecondarySchoolTechnicalRowButton !== null)
            addSecondarySchoolTechnicalRowButton.addEventListener("click", (e: Event) => this.addSecondarySchoolTechnicalRow());

        const addSecondarySchoolAcademicRows = document.getElementsByClassName("addSecondarySchoolAcademicRow");
        for (const addSecondarySchoolAcademicRow of addSecondarySchoolAcademicRows)
            addSecondarySchoolAcademicRow.addEventListener("click", (e: Event) => this.addSecondarySchoolAcademicRow(e));

        const catsSystemScopeDeleteCancelButton = document.getElementById("catsSystemScopeDeleteCancel");
        if (catsSystemScopeDeleteCancelButton !== null)
            catsSystemScopeDeleteCancelButton.addEventListener("click", (e: Event) => this.deleteScopeCancel());

        const catsSystemScopeDeleteConfirmButton = document.getElementById("catsSystemScopeDeleteConfirm");
        if (catsSystemScopeDeleteConfirmButton !== null)
            catsSystemScopeDeleteConfirmButton.addEventListener("click", (e: Event) => this.deleteScopeConfirm(e));

        this.bindDeleteButtons();
        this.bindSumHours();
    }

    bindSumHours() {
        const catsSystemScopeAndSequenceSecondaryHoursElements = document.getElementsByClassName("catsSystemScopeAndSequenceSecondaryHours");
        for (const catsSystemScopeAndSequenceSecondaryHours of catsSystemScopeAndSequenceSecondaryHoursElements)
            catsSystemScopeAndSequenceSecondaryHours.addEventListener("input", (e: Event) => this.sumHours());
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    saveAccess() {
        const formName = "catsSystemSecondaryForm";
        let planFK = 0;
        let form = document.getElementById(formName);
        planFK = parseInt(form.dataset.parentplanfk);

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemAccess', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {

            }
        };
        xhr.send(`planFK=${planFK}`);
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("catsSystemSecondaryForm");
        let planPK = parseInt(formElement.dataset.planfk);


        let textInputs = document.getElementsByClassName("catsSystemSecondaryField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;

            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                };

                allSaveElements.push(saveItem);
            }
        }

        const allData = {
            "ElementData": allSaveElements,
            "PlanFK": planPK,
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemSecondary', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }

    async addSecondarySchoolTechnicalRow() {
        Core.showLoader();
        const form = document.getElementById("catsSystemSecondaryForm");
        const propertyCodeType = "technical";
        const planPK = form.dataset.planfk;
        const programPK = form.dataset.programfk;
        const allRows = document.querySelectorAll(".secondaryTechnicalRow");
        let row = 0;
        for (let thisRow of allRows) {
            const ele = <HTMLTableRowElement>thisRow;
            const currentRow = parseInt(ele.dataset.row);
            if (!isNaN(currentRow) && currentRow > row)
                row = currentRow
        }

        row++;

        let isFirstRow: boolean = false;
        if (allRows.length === 0)
            isFirstRow = true;

        const response = await fetch(`/CATSSystem/AddSecondarySchoolTechnicalCoursesRow/${planPK}/${programPK}/${row}/${isFirstRow}`, { credentials: 'include' })
        if (response.ok) {

            const output = await response.text();

            let newTR = <HTMLTableRowElement>document.createElement("tr");
            newTR.innerHTML = output;
            newTR.classList.add("secondaryTechnicalRow");
            newTR.classList.add("scopeAndSequenceRow");
            newTR.dataset.propertycodetype = propertyCodeType;
            newTR.dataset.row = row.toString();

            let container = document.querySelector("#secondarySchoolTechnicalCourses tbody");
            container.append(newTR);

            this.bindDeleteButtons();

            this.alignTechnical();
            this.bindSumHours();
        }

        Core.hideLoader();
    }

    async addSecondarySchoolAcademicRow(e: Event) {
        Core.showLoader();
        const buttonElement = <HTMLButtonElement>e.target;
        const propertyCodeType = buttonElement.dataset.propertycodetype;
        const form = document.getElementById("catsSystemSecondaryForm");
        const planPK = form.dataset.planfk;
        const programPK = form.dataset.programfk;
        const allRows = document.querySelectorAll(`.secondaryAcademicRow[data-propertycodetype='${propertyCodeType}']`);
        let row = 0;
        for (let thisRow of allRows) {
            const ele = <HTMLTableRowElement>thisRow;
            const currentRow = parseInt(ele.dataset.row);
            if (!isNaN(currentRow) && currentRow > row)
                row = currentRow
        }

        row++;

        const response = await fetch(`/CATSSystem/AddSecondarySchoolAcademicCoursesRow/${planPK}/${programPK}/${row}/${propertyCodeType}`, { credentials: 'include' })
        if (response.ok) {

            const output = await response.text();

            let newTR = <HTMLTableRowElement>document.createElement("tr");
            newTR.innerHTML = output;
            newTR.classList.add("secondaryAcademicRow");
            newTR.classList.add("scopeAndSequenceRow");
            newTR.dataset.propertycodetype = propertyCodeType;
            newTR.dataset.row = row.toString();

            let container = document.querySelector(`.secondarySchoolAcademicCourses[data-propertycodetype='${propertyCodeType}'] tbody`);
            container.append(newTR);

            this.bindDeleteButtons();
        }

        Core.hideLoader();
    }

    bindDeleteButtons() {
        const deleteButtons = document.getElementsByClassName("deleteScope");
        for (const deleteButton of deleteButtons)
            deleteButton.addEventListener("click", (e: Event) => this.showDeleteRow(deleteButton as HTMLButtonElement));
    }

    showDeleteRow(e: HTMLButtonElement) {
        const buttonElement = e;
        const row = buttonElement.dataset.row;
        const propertyCodeType = buttonElement.dataset.propertycodetype;

        const modal: Modal = new Modal("deleteScopeModal", null);
        modal.addAttributeToElement("deleteScopeModal", "#catsSystemScopeDeleteConfirm", "propertycodetype", propertyCodeType);
        modal.addAttributeToElement("deleteScopeModal", "#catsSystemScopeDeleteConfirm", "row", row);
        modal.show();
    }

    deleteScopeCancel() {
        const modal: Modal = new Modal("deleteScopeModal", null);
        modal.hide();
    }

    async deleteScopeConfirm(e: Event) {
        const button = <HTMLButtonElement>e.target;
        const row = button.dataset.row;
        const propertyCodeType = button.dataset.propertycodetype;

        const planProperties = [];

        const allPropertiesInRow = document.querySelectorAll(`.catsSystemScopeAndSequenceField[data-row='${row}'][data-propertycodetype='${propertyCodeType}']`);
        for (const prop of allPropertiesInRow) {
            const element = <HTMLElement>prop;
            if (element.dataset.planpropertypk !== "0")
                planProperties.push(element.dataset.planpropertypk);
        }

        const dataModel = {
            "PropertyCodeType": propertyCodeType,
            "Row": row,
            "PlanPropertyValues": planProperties
        };

        const settings = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataModel)
        };


        const response = await fetch(`/CATSSystem/DeleteScopeAndSequenceRow`, settings)
        if (response.ok) {
            const row = <HTMLTableRowElement>document.querySelector(`.scopeAndSequenceRow[data-row='${dataModel.Row}'][data-propertycodetype='${dataModel.PropertyCodeType}']`);

            const modal: Modal = new Modal("deleteScopeModal", null);
            modal.hide();

            row.remove();
            Core.createHTMLAlert("alertMessageDiv", "The row was successfully deleted.", 'success', 3000, null);
        } else {
            Core.createHTMLAlert("alertMessageDiv", "There was an issue deleting this row. Please try again.", 'error', 3000, null);
        }
    }

    alignTechnical() {
        const lengthElement = <HTMLInputElement>document.getElementById("catsSystemSecondaryForm");
        const lengthValue = lengthElement.dataset.length;
        let length = 0;
        if (lengthValue !== "")
            length = parseInt(lengthValue);

        const grade12s = document.querySelectorAll(".catsSystemScopeAndSequenceSecondaryGrade12");
        const grade11s = document.querySelectorAll(".catsSystemScopeAndSequenceSecondaryGrade11");
        const grade10s = document.querySelectorAll(".catsSystemScopeAndSequenceSecondaryGrade10");
        const grade9s = document.querySelectorAll(".catsSystemScopeAndSequenceSecondaryGrade9");
        const grade12Hours = document.querySelectorAll(".catsSystemScopeAndSequenceSecondaryGrade12Hours");
        const grade11Hours = document.querySelectorAll(".catsSystemScopeAndSequenceSecondaryGrade11Hours");
        const grade10Hours = document.querySelectorAll(".catsSystemScopeAndSequenceSecondaryGrade10Hours");
        const grade9Hours = document.querySelectorAll(".catsSystemScopeAndSequenceSecondaryGrade9Hours");

        const catsSystemScopeAndSequenceSecondaryTotalHours = document.querySelectorAll(".catsSystemScopeAndSequenceSecondaryTotalHours");

        if (length == 1) {
            //grade 12 only
            //total hours: 720
            for (const grade12 of grade12s) {
                this._core.forceElementRequired(grade12 as HTMLInputElement);
                (grade12 as HTMLInputElement).readOnly = false;
            }
            for (const grade12Hour of grade12Hours) {
                this._core.forceElementRequired(grade12Hour as HTMLInputElement);
                (grade12Hour as HTMLInputElement).readOnly = false;
            }

            for (const grade11 of grade11s) {
                this._core.forceElementOptional(grade11 as HTMLInputElement);
                (grade11 as HTMLInputElement).readOnly = true;
                (grade11 as HTMLInputElement).value = "";
            }
            for (const grade11Hour of grade11Hours) {
                this._core.forceElementOptional(grade11Hour as HTMLInputElement);
                (grade11Hour as HTMLInputElement).readOnly = true;
                (grade11Hour as HTMLInputElement).value = "";
            }
            for (const grade10 of grade10s) {
                this._core.forceElementOptional(grade10 as HTMLInputElement);
                (grade10 as HTMLInputElement).readOnly = true;
                (grade10 as HTMLInputElement).value = "";
            }
            for (const grade10Hour of grade10Hours) {
                this._core.forceElementOptional(grade10Hour as HTMLInputElement);
                (grade10Hour as HTMLInputElement).readOnly = true;
                (grade10Hour as HTMLInputElement).value = "";
            }
            for (const grade9 of grade9s) {
                this._core.forceElementOptional(grade9 as HTMLInputElement);
                (grade9 as HTMLInputElement).readOnly = true;
                (grade9 as HTMLInputElement).value = "";
            }
            for (const grade9Hour of grade9Hours) {
                this._core.forceElementOptional(grade9Hour as HTMLInputElement);
                (grade9Hour as HTMLInputElement).readOnly = true;
                (grade9Hour as HTMLInputElement).value = "";;
            }

            for (const totalHoursElement of catsSystemScopeAndSequenceSecondaryTotalHours)
                (totalHoursElement as HTMLInputElement).dataset.totalhours = "720";

        } else if (length == 2) {
            //grade 12,11
            //total hours: 720
            for (const grade12 of grade12s) {
                this._core.forceElementRequired(grade12 as HTMLInputElement);
                (grade12 as HTMLInputElement).readOnly = false;
            }
            for (const grade12Hour of grade12Hours) {
                this._core.forceElementRequired(grade12Hour as HTMLInputElement);
                (grade12Hour as HTMLInputElement).readOnly = false;
            }
            for (const grade11 of grade11s) {
                this._core.forceElementRequired(grade11 as HTMLInputElement);
                (grade11 as HTMLInputElement).readOnly = false;
            }
            for (const grade11Hour of grade11Hours) {
                this._core.forceElementRequired(grade11Hour as HTMLInputElement);
                (grade11Hour as HTMLInputElement).readOnly = false;
            }

            for (const grade10 of grade10s) {
                this._core.forceElementOptional(grade10 as HTMLInputElement);
                (grade10 as HTMLInputElement).readOnly = true;
                (grade10 as HTMLInputElement).value = "";
            }
            for (const grade10Hour of grade10Hours) {
                this._core.forceElementOptional(grade10Hour as HTMLInputElement);
                (grade10Hour as HTMLInputElement).readOnly = true;
                (grade10Hour as HTMLInputElement).value = "";
            }
            for (const grade9 of grade9s) {
                this._core.forceElementOptional(grade9 as HTMLInputElement);
                (grade9 as HTMLInputElement).readOnly = true;
                (grade9 as HTMLInputElement).value = "";
            }
            for (const grade9Hour of grade9Hours) {
                this._core.forceElementOptional(grade9Hour as HTMLInputElement);
                (grade9Hour as HTMLInputElement).readOnly = true;
                (grade9Hour as HTMLInputElement).value = "";
            }

            for (const totalHoursElement of catsSystemScopeAndSequenceSecondaryTotalHours)
                (totalHoursElement as HTMLInputElement).dataset.totalhours = "720";
        } else if (length == 3) {
            //grade 12,11,10
            //total hours: 1080
            for (const grade12 of grade12s) {
                this._core.forceElementRequired(grade12 as HTMLInputElement);
                (grade12 as HTMLInputElement).readOnly = false;
            }
            for (const grade12Hour of grade12Hours) {
                this._core.forceElementRequired(grade12Hour as HTMLInputElement);
                (grade12Hour as HTMLInputElement).readOnly = false;
            }
            for (const grade11 of grade11s) {
                this._core.forceElementRequired(grade11 as HTMLInputElement);
                (grade11 as HTMLInputElement).readOnly = false;
            }
            for (const grade11Hour of grade11Hours) {
                this._core.forceElementRequired(grade11Hour as HTMLInputElement);
                (grade11Hour as HTMLInputElement).readOnly = false;
            }
            for (const grade10 of grade10s) {
                this._core.forceElementRequired(grade10 as HTMLInputElement);
                (grade10 as HTMLInputElement).readOnly = false;
            }
            for (const grade10Hour of grade10Hours) {
                this._core.forceElementRequired(grade10Hour as HTMLInputElement);
                (grade10Hour as HTMLInputElement).readOnly = false;
            }

            for (const grade9 of grade9s) {
                this._core.forceElementOptional(grade9 as HTMLInputElement);
                (grade9 as HTMLInputElement).readOnly = true;
                (grade9 as HTMLInputElement).value = "";
            }
            for (const grade9Hour of grade9Hours) {
                this._core.forceElementOptional(grade9Hour as HTMLInputElement);
                (grade9Hour as HTMLInputElement).readOnly = true;
                (grade9Hour as HTMLInputElement).value = "";
            }

            for (const totalHoursElement of catsSystemScopeAndSequenceSecondaryTotalHours)
                (totalHoursElement as HTMLInputElement).dataset.totalhours = "1080";
        } else if (length == 4) {
            //grade 12,11,10,9
            //total hours: 1320
            for (const grade12 of grade12s) {
                this._core.forceElementRequired(grade12 as HTMLInputElement);
                (grade12 as HTMLInputElement).readOnly = false;
            }
            for (const grade12Hour of grade12Hours) {
                this._core.forceElementRequired(grade12Hour as HTMLInputElement);
                (grade12Hour as HTMLInputElement).readOnly = false;
            }
            for (const grade11 of grade11s) {
                this._core.forceElementRequired(grade11 as HTMLInputElement);
                (grade11 as HTMLInputElement).readOnly = false;
            }
            for (const grade11Hour of grade11Hours) {
                this._core.forceElementRequired(grade11Hour as HTMLInputElement);
                (grade11Hour as HTMLInputElement).readOnly = false;
            }
            for (const grade10 of grade10s) {
                this._core.forceElementRequired(grade10 as HTMLInputElement);
                (grade10 as HTMLInputElement).readOnly = false;
            }
            for (const grade10Hour of grade10Hours) {
                this._core.forceElementRequired(grade10Hour as HTMLInputElement);
                (grade10Hour as HTMLInputElement).readOnly = false;
            }
            for (const grade9 of grade9s) {
                this._core.forceElementRequired(grade9 as HTMLInputElement);
                (grade9 as HTMLInputElement).readOnly = false;
            }
            for (const grade9Hour of grade9Hours) {
                this._core.forceElementRequired(grade9Hour as HTMLInputElement);
                (grade9Hour as HTMLInputElement).readOnly = false;
            }

            for (const totalHoursElement of catsSystemScopeAndSequenceSecondaryTotalHours)
                (totalHoursElement as HTMLInputElement).dataset.totalhours = "1320";
        }
    }

    sumHours() {
        const catsSystemScopeAndSequenceSecondaryTotalHours = document.querySelectorAll(".catsSystemScopeAndSequenceSecondaryTotalHours");

        for (const totalHours of catsSystemScopeAndSequenceSecondaryTotalHours) {
            let currentTotal: number = 0;
            const totalHoursElement = <HTMLInputElement>totalHours;
            const row = totalHoursElement.dataset.row;
            const catsSystemScopeAndSequenceSecondaryHoursElements = document.querySelectorAll(`.catsSystemScopeAndSequenceSecondaryHours`);

            for (const catsSystemScopeAndSequenceSecondaryHours of catsSystemScopeAndSequenceSecondaryHoursElements) {
                if ((catsSystemScopeAndSequenceSecondaryHours as HTMLInputElement).value !== "") {
                    const thisNumber = parseInt((catsSystemScopeAndSequenceSecondaryHours as HTMLInputElement).value);

                    if (!isNaN(thisNumber))
                        currentTotal += thisNumber;
                }
            }

            totalHoursElement.value = currentTotal.toString();
        }
    }

    doValidationCustom(allClasses: string[], showMessageOverride?: boolean) {
        let showMessage = showMessageOverride === undefined ? this.clientSideValidationCustom(allClasses) : showMessageOverride;
        let messageContainerColumn = <HTMLElement>document.getElementById("validationColumn");
        let messageContainer = <HTMLElement>document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = <HTMLElement>document.getElementById("validationMessageIcon");

        setTimeout(function () {
            messageContainer.focus();
        }, 500);

        if (showMessage) {

            let message = <HTMLElement>document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";

        } else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";

            let successMessage = <HTMLElement>document.getElementById("saveSuccess");

            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved."
            }
        }
    }

    clientSideValidationCustom(allClasses: string[]): boolean {
        let showMessage: boolean = false;
        let totalErrors = 0;

        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });

        let classesToValidate = formattedAllClasses.join(",");

        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });

        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });

        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });

        let allElements = document.querySelectorAll(classesToValidate);

        for (let element of allElements) {
            let alreadyExists = false;
            let htmlElement = <HTMLElement>element;
            if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {

                //check to see if there are other fields the same as this one on this page (i.e. fields that have the same propertypk)
                var otherElements = document.querySelectorAll(`[data-propertypk='${htmlElement.dataset.propertypk}']`);
                for (let otherElement of otherElements) {
                    let otherHtmlElement = <HTMLElement>otherElement;
                    if (otherHtmlElement.classList.contains("missing-field")) {
                        alreadyExists = true;
                    } else {
                        if (otherHtmlElement instanceof HTMLInputElement) {
                            if (otherHtmlElement.hasAttribute("type") && otherHtmlElement.getAttribute("type") === "radio") {
                                //Check to see if a prior group of radio buttons has already been validated
                                let OtherInputElement = <HTMLInputElement>otherHtmlElement;

                                if (OtherInputElement.hasAttribute("name")) {
                                    let radioGroupName = OtherInputElement.getAttribute("name");

                                    let radios = document.getElementsByName(radioGroupName);
                                    let radioIsChecked: boolean = false;
                                    let isAlreadyValidated: boolean = false;

                                    for (var i = 0, length = radios.length; i < length; i++) {
                                        let radioElement = <HTMLInputElement>radios[i];
                                        if (radioElement.checked) {
                                            radioIsChecked = true;
                                        }

                                        if (radioElement.classList.contains("missing-field")) {
                                            isAlreadyValidated = true;
                                        }
                                    }

                                    if (isAlreadyValidated || radioIsChecked) {
                                        alreadyExists = true;
                                    }
                                }
                            } else {
                                let OtherInputElement = <HTMLInputElement>otherHtmlElement;
                                if (OtherInputElement.value !== "") {
                                    alreadyExists = true;
                                }
                            }
                        } else if (otherHtmlElement instanceof HTMLSelectElement) {
                            let otherSelectElement = <HTMLSelectElement>otherHtmlElement;
                            if (otherSelectElement.selectedIndex >= 0 && otherSelectElement.options[otherSelectElement.selectedIndex].value !== "") {
                                alreadyExists = true;
                            }
                        } else if (otherHtmlElement instanceof HTMLTextAreaElement) {
                            let otherTextAreaElement = <HTMLTextAreaElement>otherHtmlElement;
                            if (otherTextAreaElement.value !== "") {
                                alreadyExists = true;
                            }
                        } else if ("multiselectvalidate" in otherHtmlElement.dataset && otherHtmlElement.dataset.multiselectvalidate === "true") {
                            //See if any options have been checked in multiselect
                            let multiselectCheckboxes = otherHtmlElement.getElementsByTagName("input") as HTMLCollectionOf<HTMLInputElement>;
                            for (let selectBox of multiselectCheckboxes) {
                                if (selectBox.checked) {
                                    alreadyExists = true;
                                    break;
                                }
                            }
                        }
                    }
                }

                if (!alreadyExists || ("forcerequired" in htmlElement.dataset && htmlElement.dataset.forcerequired === "true")) {
                    if (!element.classList.contains("missing-field")) {
                        //For more custom validation, use data-is-valid to specify whether a field is valid/invalid
                        if ("isValid" in htmlElement.dataset) {
                            if (htmlElement.dataset.isValid === "false") {
                                htmlElement.classList.add("missing-field");
                                htmlElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(htmlElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        } else {
                            if (element instanceof HTMLInputElement) {
                                let inputElement = <HTMLInputElement>element;

                                //Only validate once for radio buttons
                                if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "radio" && !inputElement.checked) {
                                    if (inputElement.hasAttribute("name")) {
                                        let radioName = inputElement.getAttribute("name");

                                        let radioButtons = document.getElementsByName(radioName);
                                        let alreadyValidated: boolean = false;
                                        let isChecked: boolean = false;

                                        for (var i = 0, length = radioButtons.length; i < length; i++) {
                                            let radioElement = <HTMLInputElement>radioButtons[i];
                                            if (radioElement.classList.contains("missing-field")) {
                                                alreadyValidated = true;
                                            }

                                            if (radioElement.checked) {
                                                isChecked = true;
                                            }
                                        }

                                        if (!alreadyValidated && !isChecked) {
                                            inputElement.classList.add("missing-field");
                                            inputElement.setAttribute("aria-invalid", "true");
                                            Core.createErrorLabelForInput(inputElement);
                                            showMessage = true;
                                            totalErrors++;
                                        }
                                    }
                                } else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "file") {
                                    if ("hasuploaded" in inputElement.dataset && inputElement.dataset.hasuploaded != "true") {
                                        inputElement.classList.add("missing-field");
                                        inputElement.setAttribute("aria-invalid", "true");
                                        Core.createErrorLabelForInput(inputElement);
                                        showMessage = true;
                                        totalErrors++;
                                    }
                                } else if (inputElement.value === "") {
                                    inputElement.classList.add("missing-field");
                                    inputElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(inputElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            } else if (element instanceof HTMLSelectElement) {
                                let selectElement = <HTMLSelectElement>element;
                                //if the page select element does not have a place holder it needs custom validation.  do not use this one.
                                if (selectElement.selectedIndex <= 0 || selectElement.options[selectElement.selectedIndex].value === "") {
                                    selectElement.classList.add("missing-field");
                                    selectElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(selectElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            } else if (element instanceof HTMLTextAreaElement) {
                                let textAreaElement = <HTMLTextAreaElement>element;
                                if (textAreaElement.value === "") {
                                    textAreaElement.classList.add("missing-field");
                                    textAreaElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(textAreaElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            } else if ("multiselectvalidate" in htmlElement.dataset && htmlElement.dataset.multiselectvalidate === "true") {
                                let multiselectCheckboxes = htmlElement.getElementsByTagName("input") as HTMLCollectionOf<HTMLInputElement>;
                                let hasSelection = false;

                                for (let selectBox of multiselectCheckboxes) {
                                    if (selectBox.checked) {
                                        hasSelection = true;
                                        break;
                                    }
                                }

                                if (!hasSelection) {
                                    htmlElement.classList.add("missing-field");
                                    htmlElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(htmlElement);
                                    showMessage = true;
                                    totalErrors++;
                                }

                            }
                        }
                    }
                }
            }
        }

        const allTotalHoursFields = document.getElementsByClassName("catsSystemScopeAndSequenceSecondaryTotalHours");
        for (const totalHoursField of allTotalHoursFields) {
            const element = <HTMLInputElement>totalHoursField;

            const requiredTotalHours = parseInt(element.dataset.totalhours);
            const currentHours = parseInt(element.value);

            let valid: boolean = false;
            if (!isNaN(requiredTotalHours) && !isNaN(currentHours)) {
                if (requiredTotalHours <= currentHours)
                    valid = true;
            }

            if (!valid) {
                element.classList.add("missing-field");
                element.setAttribute("aria-invalid", "true");
                Core.createErrorLabelForInput(element);
                showMessage = true;
                totalErrors++;

                const errorMessage = document.querySelector(`.minimumHoursError[data-row='${element.dataset.row}']`);
                const hoursNumber = <HTMLSpanElement>document.querySelector(`.minimumHours[data-row='${element.dataset.row}']`);
                hoursNumber.textContent = element.dataset.totalhours;

                errorMessage.classList.remove("hide");

                const minimiumHoursErrorPlaceholder = document.querySelector(`.minimiumHoursErrorPlaceholder[data-row='${element.dataset.row}']`);
                minimiumHoursErrorPlaceholder.classList.remove("hide");
            }
        }

        let message = <HTMLDivElement>document.getElementById("validationMessage");

        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        } else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");

        if (goToError !== null) {

            let that = this;

            let firstFocusableEl = <HTMLElement>document.querySelector(".missing-field");

            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");

                        let accordionElement = <HTMLButtonElement>document.getElementById(id);
                        accordionElement.click();
                    }

                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    } else {
                        firstFocusableEl.focus();
                    }
                });
            } else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }
}

// CatsSystemPostSecondary
class CatsSystemPostSecondary {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["catsSystemPostSecondaryField"];

        let catsSystemPostSecondarySaveButton = document.getElementById("catsSystemPostSecondarySave");
        if (catsSystemPostSecondarySaveButton !== null)
            catsSystemPostSecondarySaveButton.addEventListener("click", (e: Event) => this.save("save"));

        const allRequiredCheckboxes = document.getElementsByClassName("catsSystemPostSecondaryRequiredField");
        for (const required of allRequiredCheckboxes) {
            required.addEventListener("change", (e: Event) => this.checkRequired(<HTMLInputElement>e.target));
            this.checkRequired(<HTMLInputElement>required);
        }

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        this.saveAccess();

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        const addPostSecondarySchoolTechnicalRowButton = document.querySelector(`.addPostSecondarySchoolTechnicalRow`);
        if (addPostSecondarySchoolTechnicalRowButton !== null)
            addPostSecondarySchoolTechnicalRowButton.addEventListener("click", (e: Event) => this.addPostSecondarySchoolTechnicalRow());

        const addPostSecondarySchoolAcademicRows = document.getElementsByClassName("addPostSecondarySchoolAcademicRow");
        for (const addPostSecondarySchoolAcademicRow of addPostSecondarySchoolAcademicRows)
            addPostSecondarySchoolAcademicRow.addEventListener("click", (e: Event) => this.addPostSecondarySchoolAcademicRow(e));

        const catsSystemScopeDeleteCancelButton = document.getElementById("catsSystemScopeDeleteCancel");
        if (catsSystemScopeDeleteCancelButton !== null)
            catsSystemScopeDeleteCancelButton.addEventListener("click", (e: Event) => this.deleteScopeCancel());

        const catsSystemScopeDeleteConfirmButton = document.getElementById("catsSystemScopeDeleteConfirm");
        if (catsSystemScopeDeleteConfirmButton !== null)
            catsSystemScopeDeleteConfirmButton.addEventListener("click", (e: Event) => this.deleteScopeConfirm(e));

        this.bindDeleteButtons();
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    saveAccess() {
        const formName = "catsSystemPostSecondaryForm";
        let planFK = 0;
        let form = document.getElementById(formName);
        planFK = parseInt(form.dataset.parentplanfk);

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemAccess', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {

            }
        };
        xhr.send(`planFK=${planFK}`);
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("catsSystemPostSecondaryForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let articulationAgreementPK = parseInt(formElement.dataset.articulationagreementfk);

        let textInputs = document.getElementsByClassName("catsSystemPostSecondaryField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            let catsArticulationAgreementPlanPropertyFK = 0;
            if (element.dataset.catsarticulationagreementplanpropertyfk && element.dataset.catsarticulationagreementplanpropertyfk !== "")
                catsArticulationAgreementPlanPropertyFK = parseInt(element.dataset.catsarticulationagreementplanpropertyfk);

            if (element.value !== "" || hadValue) {
                let saveItem: IArticulationAgreementPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    CATSArticulationAgreementPlanPropertyFK: catsArticulationAgreementPlanPropertyFK
                };

                allSaveElements.push(saveItem);
            }
        }

        const checkboxes = document.getElementsByClassName("catsSystemPostSecondaryCheckboxField");
        for (let ele of checkboxes) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            let textValue = "off";
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;

                if (!element.checked)
                    textValue = "off";
            }

            if (element.checked)
                textValue = "on";

            let catsArticulationAgreementPlanPropertyFK = 0;
            if (element.dataset.catsarticulationagreementplanpropertyfk && element.dataset.catsarticulationagreementplanpropertyfk !== "")
                catsArticulationAgreementPlanPropertyFK = parseInt(element.dataset.catsarticulationagreementplanpropertyfk);

            if (element.value !== "" || hadValue) {
                let saveItem: IArticulationAgreementPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: textValue,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    CATSArticulationAgreementPlanPropertyFK: catsArticulationAgreementPlanPropertyFK
                };

                allSaveElements.push(saveItem);
            }
        }

        var allData = {
            "ArticulationAgreementPK": articulationAgreementPK,
            "ElementData": allSaveElements,
            "PlanFK": planPK
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemPostSecondary', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }

    async addPostSecondarySchoolTechnicalRow() {
        Core.showLoader();
        const form = document.getElementById("catsSystemPostSecondaryForm");
        const articulationAgreementPK = form.dataset.articulationagreementfk;
        const propertyCodeType = "technical";
        const planPK = form.dataset.planfk;
        const programPK = form.dataset.programfk;
        const allRows = document.querySelectorAll(".postSecondaryTechnicalRow");
        let row = 0;
        for (let thisRow of allRows) {
            const ele = <HTMLTableRowElement>thisRow;
            const currentRow = parseInt(ele.dataset.row);
            if (!isNaN(currentRow) && currentRow > row)
                row = currentRow
        }

        row++;

        const response = await fetch(`/CATSSystem/AddPostSecondarySchoolTechnicalCoursesRow/${articulationAgreementPK}/${planPK}/${programPK}/${row}`, { credentials: 'include' })
        if (response.ok) {

            const output = await response.text();

            let newTR = <HTMLTableRowElement>document.createElement("tr");
            newTR.innerHTML = output;
            newTR.classList.add("postSecondaryTechnicalRow");
            newTR.classList.add("scopeAndSequenceRow");
            newTR.dataset.propertycodetype = propertyCodeType;
            newTR.dataset.row = row.toString();

            let container = document.querySelector("#postSecondarySchoolTechnicalCourses tbody");
            container.append(newTR);

            this.bindDeleteButtons();
        }

        Core.hideLoader();
    }

    async addPostSecondarySchoolAcademicRow(e: Event) {
        Core.showLoader();
        const buttonElement = <HTMLButtonElement>e.target;
        const propertyCodeType = buttonElement.dataset.propertycodetype;
        const form = document.getElementById("catsSystemPostSecondaryForm");
        const articulationAgreementPK = form.dataset.articulationagreementfk;
        const planPK = form.dataset.planfk;
        const programPK = form.dataset.programfk;
        const allRows = document.querySelectorAll(`.postSecondaryAcademicRow[data-propertycodetype='${propertyCodeType}']`);
        let row = 0;
        for (let thisRow of allRows) {
            const ele = <HTMLTableRowElement>thisRow;
            const currentRow = parseInt(ele.dataset.row);
            if (!isNaN(currentRow) && currentRow > row)
                row = currentRow
        }

        row++;

        const response = await fetch(`/CATSSystem/AddPostSecondarySchoolAcademicCoursesRow/${articulationAgreementPK}/${planPK}/${programPK}/${row}/${propertyCodeType}`, { credentials: 'include' })
        if (response.ok) {

            const output = await response.text();

            let newTR = <HTMLTableRowElement>document.createElement("tr");
            newTR.innerHTML = output;
            newTR.classList.add("postSecondaryAcademicRow");
            newTR.classList.add("scopeAndSequenceRow");
            newTR.dataset.propertycodetype = propertyCodeType;
            newTR.dataset.row = row.toString();

            let container = document.querySelector(`.postSecondarySchoolAcademicCourses[data-propertycodetype='${propertyCodeType}'] tbody`);
            container.append(newTR);

            this.bindDeleteButtons();
        }

        Core.hideLoader();
    }

    checkRequired(checkbox: HTMLInputElement) {
        const codeType = checkbox.dataset.codetype;

        const allTextFields = document.querySelectorAll(`.catsSystemPostSecondaryField[data-propertycodetype='${codeType}']`);
        for (const text of allTextFields) {
            const ele = <HTMLInputElement>text;

            if (checkbox.checked)
                this._core.forceElementRequired(ele);
            else
                this._core.forceElementOptional(ele);
        }

        const requiredElement = <HTMLDivElement>document.querySelector(`.postSecondaryAcademicRequired[data-propertycodetype='${codeType}']`);
        const button = <HTMLButtonElement>document.querySelector(`.addRowButton[data-propertycodetype='${codeType}']`);
        if (checkbox.checked) {
            requiredElement.classList.remove("hide");
            button.disabled = false;
        } else {
            requiredElement.classList.add("hide");
            button.disabled = true;
        }
    }

    bindDeleteButtons() {
        const deleteButtons = document.getElementsByClassName("deleteScope");
        for (const deleteButton of deleteButtons)
            deleteButton.addEventListener("click", (e: Event) => this.showDeleteRow(e));
    }

    showDeleteRow(e: Event) {
        const buttonElement = e.target as HTMLButtonElement;
        const row = buttonElement.dataset.row;
        const propertyCodeType = buttonElement.dataset.propertycodetype;

        const modal: Modal = new Modal("deleteScopeModal", null);
        modal.addAttributeToElement("deleteScopeModal", "#catsSystemScopeDeleteConfirm", "propertycodetype", propertyCodeType);
        modal.addAttributeToElement("deleteScopeModal", "#catsSystemScopeDeleteConfirm", "row", row);
        modal.show();
    }

    deleteScopeCancel() {
        const modal: Modal = new Modal("deleteScopeModal", null);
        modal.hide();
    }

    async deleteScopeConfirm(e: Event) {
        const button = <HTMLButtonElement>e.target;
        const row = button.dataset.row;
        const propertyCodeType = button.dataset.propertycodetype;

        const planProperties = [];

        const allPropertiesInRow = document.querySelectorAll(`.catsSystemScopeAndSequenceField[data-row='${row}'][data-propertycodetype='${propertyCodeType}']`);
        for (const prop of allPropertiesInRow) {
            const element = <HTMLElement>prop;
            if (element.dataset.planpropertypk !== "0")
                planProperties.push(element.dataset.planpropertypk);
        }

        const dataModel = {
            "PropertyCodeType": propertyCodeType,
            "Row": row,
            "PlanPropertyValues": planProperties
        };

        const settings = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataModel)
        };


        const response = await fetch(`/CATSSystem/DeleteScopeAndSequenceRow`, settings)
        if (response.ok) {
            const row = <HTMLTableRowElement>document.querySelector(`.scopeAndSequenceRow[data-row='${dataModel.Row}'][data-propertycodetype='${dataModel.PropertyCodeType}']`);

            const modal: Modal = new Modal("deleteScopeModal", null);
            modal.hide();

            row.remove();
            Core.createHTMLAlert("alertMessageDiv", "The row was successfully deleted.", 'success', 3000, null);
        } else {
            Core.createHTMLAlert("alertMessageDiv", "There was an issue deleting this row. Please try again.", 'error', 3000, null);
        }
    }
}

// CatsSystemDualEnrollment
class CatsSystemDualEnrollment {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["catsSystemDualEnrollmentField", "catsSystemDualEnrollmentRadioField", "catsSystemDualEnrollmentCheckboxField"];

        let catsSystemDualEnrollmentSaveButton = document.getElementById("catsSystemDualEnrollmentSave");
        if (catsSystemDualEnrollmentSaveButton !== null)
            catsSystemDualEnrollmentSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        const yesNoRadios = document.getElementsByClassName("catsSystemDualEnrollmentRadioField");
        for (const yesNoRadio of yesNoRadios) {
            yesNoRadio.addEventListener("change", (e: Event) => this.checkForYesNo(<HTMLInputElement>e.target));
            this.checkForYesNo(<HTMLInputElement>yesNoRadio)
        }

        const allRequiredCheckboxes = document.getElementsByClassName("catsSystemDualEnrollmentRequiredField");
        for (const notRequired of allRequiredCheckboxes) {
            notRequired.addEventListener("change", (e: Event) => this.checkRequired(<HTMLInputElement>e.target));
            this.checkRequired(<HTMLInputElement>notRequired);
        }

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        this.saveAccess();

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        const addDualEnrollmentSchoolTechnicalRowButton = document.querySelector(`.addDualEnrollmentTechnicalRow`);
        if (addDualEnrollmentSchoolTechnicalRowButton !== null)
            addDualEnrollmentSchoolTechnicalRowButton.addEventListener("click", (e: Event) => this.addDualEnrollmentSchoolTechnicalRow());

        const addDualEnrollmentSchoolAcademicRows = document.getElementsByClassName("addDualEnrollmentAcademicRow");
        for (const addDualEnrollmentSchoolAcademicRow of addDualEnrollmentSchoolAcademicRows)
            addDualEnrollmentSchoolAcademicRow.addEventListener("click", (e: Event) => this.addDualEnrollmentSchoolAcademicRow(e));

        const catsSystemScopeDeleteCancelButton = document.getElementById("catsSystemScopeDeleteCancel");
        if (catsSystemScopeDeleteCancelButton !== null)
            catsSystemScopeDeleteCancelButton.addEventListener("click", (e: Event) => this.deleteScopeCancel());

        const catsSystemScopeDeleteConfirmButton = document.getElementById("catsSystemScopeDeleteConfirm");
        if (catsSystemScopeDeleteConfirmButton !== null)
            catsSystemScopeDeleteConfirmButton.addEventListener("click", (e: Event) => this.deleteScopeConfirm(e));

        this.bindDeleteButtons();
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    saveAccess() {
        const formName = "catsSystemDualEnrollmentForm";
        let planFK = 0;
        let form = document.getElementById(formName);
        planFK = parseInt(form.dataset.parentplanfk);

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemAccess', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {

            }
        };
        xhr.send(`planFK=${planFK}`);
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("catsSystemDualEnrollmentForm");
        let planPK = parseInt(formElement.dataset.planfk);
        const articulationAgreementPK = parseInt(formElement.dataset.articulationagreementfk);

        const textInputs = document.getElementsByClassName("catsSystemDualEnrollmentField");
        for (const ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            let catsArticulationAgreementPlanPropertyFK = 0;
            if (element.dataset.catsarticulationagreementplanpropertyfk && element.dataset.catsarticulationagreementplanpropertyfk !== "")
                catsArticulationAgreementPlanPropertyFK = parseInt(element.dataset.catsarticulationagreementplanpropertyfk);

            if (element.value !== "" || hadValue) {
                let saveItem: IArticulationAgreementPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    CATSArticulationAgreementPlanPropertyFK: catsArticulationAgreementPlanPropertyFK
                };

                allSaveElements.push(saveItem);
            }
        }

        const checkboxes = document.getElementsByClassName("catsSystemDualEnrollmentCheckboxField");
        for (let ele of checkboxes) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            let textValue = "off";
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;

                if (!element.checked)
                    textValue = "off";
            }

            let catsArticulationAgreementPlanPropertyFK = 0;
            if (element.dataset.catsarticulationagreementplanpropertyfk && element.dataset.catsarticulationagreementplanpropertyfk !== "")
                catsArticulationAgreementPlanPropertyFK = parseInt(element.dataset.catsarticulationagreementplanpropertyfk);

            if (element.checked)
                textValue = "on";

            if (element.value !== "" || hadValue) {
                let saveItem: IArticulationAgreementPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: textValue,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    CATSArticulationAgreementPlanPropertyFK: catsArticulationAgreementPlanPropertyFK
                };

                allSaveElements.push(saveItem);
            }
        }

        const radios = document.getElementsByClassName("catsSystemDualEnrollmentRadioField");
        for (const ele of radios) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                let catsArticulationAgreementPlanPropertyFK = 0;
                if (element.dataset.catsarticulationagreementplanpropertyfk && element.dataset.catsarticulationagreementplanpropertyfk !== "")
                    catsArticulationAgreementPlanPropertyFK = parseInt(element.dataset.catsarticulationagreementplanpropertyfk);

                if (element.value !== "" || hadValue) {
                    let saveItem: IArticulationAgreementPlanPropertyExtended = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                        ButtonRow: 0,
                        CATSArticulationAgreementPlanPropertyFK: catsArticulationAgreementPlanPropertyFK
                    };

                    allSaveElements.push(saveItem);
                }
            }
        }

        var allData = {
            "ElementData": allSaveElements,
            "ArticulationAgreementPK": articulationAgreementPK,
            "PlanFK": planPK
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemDualEnrollment', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    if (xhr.responseText === "true") {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "Data has been successfully saved. Please wait.", 'success', 3000, null);
                        core.pageReload(true, planPK);
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "There was an issue saving your data. Please try again.", 'error', 3000, null);
                    }
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }

    async addDualEnrollmentSchoolTechnicalRow() {
        Core.showLoader();
        const form = document.getElementById("catsSystemDualEnrollmentForm");
        const propertyCodeType = "technical";
        const articulationAgreementPK = form.dataset.articulationagreementfk;
        const planPK = form.dataset.planfk;
        const programPK = form.dataset.programfk;
        const allRows = document.querySelectorAll(".dualEnrollmentTechnicalRow");
        let row = 0;
        for (let thisRow of allRows) {
            const ele = <HTMLTableRowElement>thisRow;
            const currentRow = parseInt(ele.dataset.row);
            if (!isNaN(currentRow) && currentRow > row)
                row = currentRow
        }

        row++;

        const response = await fetch(`/CATSSystem/AddDualEnrollmentTechnicalCoursesRow/${articulationAgreementPK}/${planPK}/${programPK}/${row}`, { credentials: 'include' })
        if (response.ok) {

            const output = await response.text();

            let newTR = <HTMLTableRowElement>document.createElement("tr");
            newTR.innerHTML = output;
            newTR.classList.add("dualEnrollmentTechnicalRow");
            newTR.classList.add("scopeAndSequenceRow");
            newTR.dataset.row = row.toString();
            newTR.dataset.propertycodetype = propertyCodeType;

            let container = document.querySelector("#dualEnrollmentTechnicalCourses tbody");
            container.append(newTR);

            this.bindDeleteButtons();
        }

        Core.hideLoader();
    }

    async addDualEnrollmentSchoolAcademicRow(e: Event) {
        Core.showLoader();
        const buttonElement = <HTMLButtonElement>e.target;
        const propertyCodeType = buttonElement.dataset.propertycodetype;
        const form = document.getElementById("catsSystemDualEnrollmentForm");
        const articulationAgreementPK = form.dataset.articulationagreementfk;
        const planPK = form.dataset.planfk;
        const programPK = form.dataset.programfk;
        const allRows = document.querySelectorAll(`.dualEnrollmentAcademicRow[data-propertycodetype='${propertyCodeType}']`);
        let row = 0;
        for (let thisRow of allRows) {
            const ele = <HTMLTableRowElement>thisRow;
            const currentRow = parseInt(ele.dataset.row);
            if (!isNaN(currentRow) && currentRow > row)
                row = currentRow
        }

        row++;

        const response = await fetch(`/CATSSystem/AddDualEnrollmentAcademicCoursesRow/${articulationAgreementPK}/${planPK}/${programPK}/${row}/${propertyCodeType}`, { credentials: 'include' })
        if (response.ok) {

            const output = await response.text();

            let newTR = <HTMLTableRowElement>document.createElement("tr");
            newTR.innerHTML = output;
            newTR.classList.add("dualEnrollmentAcademicRow");
            newTR.classList.add("scopeAndSequenceRow");
            newTR.dataset.propertycodetype = propertyCodeType;
            newTR.dataset.row = row.toString();

            let container = document.querySelector(`.dualEnrollmentAcademicCourses[data-propertycodetype='${propertyCodeType}'] tbody`);
            container.append(newTR);

            this.bindDeleteButtons();
        }

        Core.hideLoader();
    }

    checkRequired(checkbox: HTMLInputElement) {
        const codeType = checkbox.dataset.codetype;

        const allTextFields = document.querySelectorAll(`.catsSystemDualEnrollmentField.required[data-propertycodetype='${codeType}']`);
        for (const text of allTextFields) {
            const ele = <HTMLInputElement>text;

            if (checkbox.checked)
                this._core.forceElementRequired(ele);
            else
                this._core.forceElementOptional(ele);
        }

        const requiredElement = <HTMLDivElement>document.querySelector(`.dualEnrollmentAcademicRequired[data-propertycodetype='${codeType}']`);
        const button = <HTMLButtonElement>document.querySelector(`.addRowButton[data-propertycodetype='${codeType}']`);
        if (checkbox.checked) {
            requiredElement.classList.remove("hide");
            button.disabled = false;
        } else {
            requiredElement.classList.add("hide");
            button.disabled = true;
        }
    }

    checkForYesNo(radio: HTMLInputElement) {

        let allRequired = false;
        let go = false;
        if (radio.checked) {
            if (radio.dataset.lookuplabel === "Yes") {
                allRequired = true;
                go = true;
            } else {
                allRequired = false;
                go = true;
            }
        }

        if (go) {
            const allTextFields = document.querySelectorAll(`.catsSystemDualEnrollmentField.required`);
            for (const text of allTextFields) {
                const ele = <HTMLInputElement>text;
                const propertyCodeType = ele.dataset.propertycodetype;
                const checkEle = <HTMLInputElement>document.querySelector(`.catsSystemDualEnrollmentRequiredField[data-codetype='${propertyCodeType}']`);
                if (allRequired) {
                    if (checkEle && checkEle.checked)
                        this._core.forceElementRequired(ele);
                    else
                        this._core.forceElementOptional(ele);

                    checkEle.disabled = false;
                }
                else {
                    this._core.forceElementOptional(ele);
                    if (checkEle.checked)
                        checkEle.click();
                    checkEle.disabled = true;
                }
            }
        }
    }

    bindDeleteButtons() {
        const deleteButtons = document.getElementsByClassName("deleteScope");
        for (const deleteButton of deleteButtons)
            deleteButton.addEventListener("click", (e: Event) => this.showDeleteRow(deleteButton as HTMLButtonElement));
    }

    showDeleteRow(e: HTMLButtonElement) {
        const buttonElement = e;
        const row = buttonElement.dataset.row;
        const propertyCodeType = buttonElement.dataset.propertycodetype;

        const modal: Modal = new Modal("deleteScopeModal", null);
        modal.addAttributeToElement("deleteScopeModal", "#catsSystemScopeDeleteConfirm", "propertycodetype", propertyCodeType);
        modal.addAttributeToElement("deleteScopeModal", "#catsSystemScopeDeleteConfirm", "row", row);
        modal.show();
    }

    deleteScopeCancel() {
        const modal: Modal = new Modal("deleteScopeModal", null);
        modal.hide();
    }

    async deleteScopeConfirm(e: Event) {
        const button = <HTMLButtonElement>e.target;
        const row = button.dataset.row;
        const propertyCodeType = button.dataset.propertycodetype;

        const planProperties = [];

        const allPropertiesInRow = document.querySelectorAll(`.catsSystemScopeAndSequenceField[data-row='${row}'][data-propertycodetype='${propertyCodeType}']`);
        for (const prop of allPropertiesInRow) {
            const element = <HTMLElement>prop;
            if (element.dataset.planpropertypk !== "0")
                planProperties.push(element.dataset.planpropertypk);
        }

        const dataModel = {
            "PropertyCodeType": propertyCodeType,
            "Row": row,
            "PlanPropertyValues": planProperties
        };

        const settings = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataModel)
        };


        const response = await fetch(`/CATSSystem/DeleteScopeAndSequenceRow`, settings)
        if (response.ok) {
            const row = <HTMLTableRowElement>document.querySelector(`.scopeAndSequenceRow[data-row='${dataModel.Row}'][data-propertycodetype='${dataModel.PropertyCodeType}']`);

            const modal: Modal = new Modal("deleteScopeModal", null);
            modal.hide();

            row.remove();
            Core.createHTMLAlert("alertMessageDiv", "The row was successfully deleted.", 'success', 3000, null);
        } else {
            Core.createHTMLAlert("alertMessageDiv", "There was an issue deleting this row. Please try again.", 'error', 3000, null);
        }
    }
}

// CatsSystemCredits
class CatsSystemCredits {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["catsSystemCreditsField", "catsSystemCreditsFileField", "catsSystemCreditsCheckboxField", "catsSystemCreditsRadioField"];

        let catsSystemCreditsSaveButton = document.getElementById("catsSystemCreditsSave");
        if (catsSystemCreditsSaveButton !== null)
            catsSystemCreditsSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        const allNotRequiredCheckboxes = document.getElementsByClassName("catsSystemCreditsCheckboxField");
        for (const notRequired of allNotRequiredCheckboxes) {
            notRequired.addEventListener("change", (e: Event) => this.checkRequired(<HTMLInputElement>e.target));
            this.checkRequired(<HTMLInputElement>notRequired);
        }

        const yesNoRadios = document.getElementsByClassName("catsSystemCreditsRadioField");
        for (const yesNoRadio of yesNoRadios) {
            yesNoRadio.addEventListener("change", (e: Event) => this.checkForYesNo(<HTMLInputElement>e.target));
            this.checkForYesNo(<HTMLInputElement>yesNoRadio)
        }

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        this.saveAccess();

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        const addCreditsSchoolTechnicalRowButton = document.querySelector(`.addCreditsTechnicalRow`);
        if (addCreditsSchoolTechnicalRowButton !== null)
            addCreditsSchoolTechnicalRowButton.addEventListener("click", (e: Event) => this.addCreditsSchoolTechnicalRow());

        const addCreditsSchoolAcademicRows = document.getElementsByClassName("addCreditsAcademicRow");
        for (const addCreditsSchoolAcademicRow of addCreditsSchoolAcademicRows)
            addCreditsSchoolAcademicRow.addEventListener("click", (e: Event) => this.addCreditsSchoolAcademicRow(e));

        const fileUploader = document.getElementById("catsSystemCreditsArticulatedCreditsUploadArticulationAgreement");
        if(fileUploader !== null)
            fileUploader.addEventListener("change", (e: Event) => this.uploadFile(<HTMLInputElement>e.target));

        const catsSystemScopeDeleteCancelButton = document.getElementById("catsSystemScopeDeleteCancel");
        if (catsSystemScopeDeleteCancelButton !== null)
            catsSystemScopeDeleteCancelButton.addEventListener("click", (e: Event) => this.deleteScopeCancel());

        const catsSystemScopeDeleteConfirmButton = document.getElementById("catsSystemScopeDeleteConfirm");
        if (catsSystemScopeDeleteConfirmButton !== null)
            catsSystemScopeDeleteConfirmButton.addEventListener("click", (e: Event) => this.deleteScopeConfirm(e));

        this.bindDeleteButtons();
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    saveAccess() {
        const formName = "catsSystemCreditsForm";
        let planFK = 0;
        let form = document.getElementById(formName);
        planFK = parseInt(form.dataset.parentplanfk);

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemAccess', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {

            }
        };
        xhr.send(`planFK=${planFK}`);
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("catsSystemCreditsForm");
        let planPK = parseInt(formElement.dataset.planfk);
        const articulationAgreementPK = parseInt(formElement.dataset.articulationagreementfk);

        let textInputs = document.getElementsByClassName("catsSystemCreditsField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            let catsArticulationAgreementPlanPropertyFK = 0;
            if (element.dataset.catsarticulationagreementplanpropertyfk && element.dataset.catsarticulationagreementplanpropertyfk !== "")
                catsArticulationAgreementPlanPropertyFK = parseInt(element.dataset.catsarticulationagreementplanpropertyfk);

            if (element.value !== "" || hadValue) {
                let saveItem: IArticulationAgreementPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    CATSArticulationAgreementPlanPropertyFK: catsArticulationAgreementPlanPropertyFK
                };

                allSaveElements.push(saveItem);
            }
        }

        const checkboxes = document.getElementsByClassName("catsSystemCreditsCheckboxField");
        for (let ele of checkboxes) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            let textValue = "";
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;

                if (!element.checked)
                    textValue = "off";
            }

            if (element.checked)
                textValue = "on";

            let catsArticulationAgreementPlanPropertyFK = 0;
            if (element.dataset.catsarticulationagreementplanpropertyfk && element.dataset.catsarticulationagreementplanpropertyfk !== "")
                catsArticulationAgreementPlanPropertyFK = parseInt(element.dataset.catsarticulationagreementplanpropertyfk);

            if (element.value !== "" || hadValue) {
                let saveItem: IArticulationAgreementPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: textValue,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    CATSArticulationAgreementPlanPropertyFK: catsArticulationAgreementPlanPropertyFK
                };

                allSaveElements.push(saveItem);
            }
        }

        const radios = document.getElementsByClassName("catsSystemCreditsRadioField");
        for (let ele of radios) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                let catsArticulationAgreementPlanPropertyFK = 0;
                if (element.dataset.catsarticulationagreementplanpropertyfk && element.dataset.catsarticulationagreementplanpropertyfk !== "")
                    catsArticulationAgreementPlanPropertyFK = parseInt(element.dataset.catsarticulationagreementplanpropertyfk);

                if (element.value !== "" || hadValue) {
                    let saveItem: IArticulationAgreementPlanPropertyExtended = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                        ButtonRow: 0,
                        CATSArticulationAgreementPlanPropertyFK: catsArticulationAgreementPlanPropertyFK
                    };

                    allSaveElements.push(saveItem);
                }
            }
        }

        var allData = {
            "ElementData": allSaveElements,
            "ArticulationAgreementPK": articulationAgreementPK,
            "PlanFK": planPK
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemCredits', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }

    async addCreditsSchoolTechnicalRow() {
        Core.showLoader();
        const form = document.getElementById("catsSystemCreditsForm");
        const articulationAgreementPK = form.dataset.articulationagreementfk;
        const propertyCodeType = "technical";
        const planPK = form.dataset.planfk;
        const programPK = form.dataset.programfk;
        const allRows = document.querySelectorAll(".articulatedCreditsTechnicalRow");
        let row = 0;
        for (let thisRow of allRows) {
            const ele = <HTMLTableRowElement>thisRow;
            const currentRow = parseInt(ele.dataset.row);
            if (!isNaN(currentRow) && currentRow > row)
                row = currentRow
        }

        row++;

        const response = await fetch(`/CATSSystem/AddArticulatedCreditsTechnicalCoursesRow/${articulationAgreementPK}/${planPK}/${programPK}/${row}`, { credentials: 'include' })
        if (response.ok) {

            const output = await response.text();

            let newTR = <HTMLTableRowElement>document.createElement("tr");
            newTR.innerHTML = output;
            newTR.classList.add("articulatedCreditsTechnicalRow");
            newTR.classList.add("scopeAndSequenceRow");
            newTR.dataset.row = row.toString();
            newTR.dataset.propertycodetype = propertyCodeType;

            let container = document.querySelector("#articulatedCreditsTechnicalCourses tbody");
            container.append(newTR);

            this.bindDeleteButtons();
        }

        Core.hideLoader();
    }

    async addCreditsSchoolAcademicRow(e: Event) {
        Core.showLoader();
        const buttonElement = <HTMLButtonElement>e.target;
        const propertyCodeType = buttonElement.dataset.propertycodetype;
        const form = document.getElementById("catsSystemCreditsForm");
        const articulationAgreementPK = form.dataset.articulationagreementfk;
        const planPK = form.dataset.planfk;
        const programPK = form.dataset.programfk;
        const allRows = document.querySelectorAll(`.articulatedCreditsAcademicRow[data-propertycodetype='${propertyCodeType}']`);
        let row = 0;
        for (let thisRow of allRows) {
            const ele = <HTMLTableRowElement>thisRow;
            const currentRow = parseInt(ele.dataset.row);
            if (!isNaN(currentRow) && currentRow > row)
                row = currentRow
        }

        row++;

        const response = await fetch(`/CATSSystem/AddArticulatedCreditsAcademicCoursesRow/${articulationAgreementPK}/${planPK}/${programPK}/${row}/${propertyCodeType}`, { credentials: 'include' })
        if (response.ok) {

            const output = await response.text();

            let newTR = <HTMLTableRowElement>document.createElement("tr");
            newTR.innerHTML = output;
            newTR.classList.add("articulatedCreditsAcademicRow");
            newTR.classList.add("scopeAndSequenceRow");
            newTR.dataset.propertycodetype = propertyCodeType;
            newTR.dataset.row = row.toString();

            let container = document.querySelector(`.articulatedCreditsAcademicCourses[data-propertycodetype='${propertyCodeType}'] tbody`);
            container.append(newTR);

            this.bindDeleteButtons();
        }

        Core.hideLoader();
    }

    uploadFile(e: HTMLInputElement) {
        let core = this._core;
        Core.showLoader();
        let formName = e.dataset.formname;

        let row = e.dataset.row;

        const that = this;

        let uploadSampleForm = <HTMLFormElement>document.getElementById(formName);
        let formData = new FormData(uploadSampleForm);

        let propertyPK = e.dataset.propertypk;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();

                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);

                        let formFile = <HTMLInputElement>document.querySelector(`.catsSystemCreditsFileField[data-formname='${formName}']`);
                        formFile.value = "";

                        let fileList = document.querySelector(`.uploadFileList`)

                        let currentFileList = fileList.querySelectorAll(`.uploadFileList`);

                        let sequence = -1;
                        for (let file of currentFileList) {
                            let fileEle = <HTMLDivElement>file;

                            let thisSequence = parseInt(fileEle.dataset.sequencenbr);
                            if (thisSequence > sequence) {
                                sequence = thisSequence;
                            }
                        }

                        let uploadedContainer = document.querySelector(`#uploadedFiles`);
                        uploadedContainer.classList.remove("hide");

                        sequence++;

                        let fileWrapper = <HTMLDivElement>document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = <HTMLDivElement>document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = <HTMLAnchorElement>document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.row = row;
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.sequencenbr = sequence.toString();
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.classList.add("catsSystemCreditsFileUploadField");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);

                        fileList.appendChild(fileWrapper);
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }

    checkRequired(checkbox: HTMLInputElement) {
        const codeType = checkbox.dataset.codetype;

        if (codeType !== "technical") {
            const allTextFields = document.querySelectorAll(`.catsSystemCreditsField[data-propertycodetype='${codeType}']`);
            for (const text of allTextFields) {
                const ele = <HTMLInputElement>text;

                if (checkbox.checked)
                    this._core.forceElementRequired(ele);
                else
                    this._core.forceElementOptional(ele);
            }

            const requiredElement = <HTMLDivElement>document.querySelector(`.articulatedCreditsAcademicRequired[data-propertycodetype='${codeType}']`);
            const button = <HTMLButtonElement>document.querySelector(`.addRowButton[data-propertycodetype='${codeType}']`);
            if (checkbox.checked) {
                requiredElement.classList.remove("hide");
                button.disabled = false;
            } else {
                requiredElement.classList.add("hide");
                button.disabled = true;
            }
        }
    }

    checkForYesNo(radio: HTMLInputElement) {

        let allRequired = false;
        let go = false;
        if (radio.checked) {
            if (radio.dataset.lookuplabel === "Yes") {
                allRequired = true;
                go = true;
            } else {
                allRequired = false;
                go = true;
            }
        }

        if (go) {
            const allTextFields = document.querySelectorAll(`.catsSystemCreditsField`);
            for (const text of allTextFields) {
                const ele = <HTMLInputElement>text;
                const propertyCodeType = ele.dataset.propertycodetype;
                const checkEle = <HTMLInputElement>document.querySelector(`.catsSystemCreditsRequiredField[data-codetype='${propertyCodeType}']`);

                if (allRequired) {                  
                    if (propertyCodeType !== "technical") {
                        
                        if (checkEle && checkEle.checked)
                            this._core.forceElementRequired(ele);
                        else
                            this._core.forceElementOptional(ele);
                    } else {
                        this._core.forceElementRequired(ele);
                    }

                    if(checkEle)
                        checkEle.disabled = false;
                }
                else {
                    this._core.forceElementOptional(ele);
                    if (checkEle) {
                        if (checkEle.checked)
                            checkEle.click();
                        checkEle.disabled = true;
                    }
                }
            }
        }
    }

    bindDeleteButtons() {
        const deleteButtons = document.getElementsByClassName("deleteScope");
        for (const deleteButton of deleteButtons)
            deleteButton.addEventListener("click", (e: Event) => this.showDeleteRow(deleteButton as HTMLButtonElement));
    }

    showDeleteRow(e: HTMLButtonElement) {
        const buttonElement = e;
        const row = buttonElement.dataset.row;
        const propertyCodeType = buttonElement.dataset.propertycodetype;

        const modal: Modal = new Modal("deleteScopeModal", null);
        modal.addAttributeToElement("deleteScopeModal", "#catsSystemScopeDeleteConfirm", "propertycodetype", propertyCodeType);
        modal.addAttributeToElement("deleteScopeModal", "#catsSystemScopeDeleteConfirm", "row", row);
        modal.show();
    }

    deleteScopeCancel() {
        const modal: Modal = new Modal("deleteScopeModal", null);
        modal.hide();
    }

    async deleteScopeConfirm(e: Event) {
        const button = <HTMLButtonElement>e.target;
        const row = button.dataset.row;
        const propertyCodeType = button.dataset.propertycodetype;

        const planProperties = [];

        const allPropertiesInRow = document.querySelectorAll(`.catsSystemScopeAndSequenceField[data-row='${row}'][data-propertycodetype='${propertyCodeType}']`);
        for (const prop of allPropertiesInRow) {
            const element = <HTMLElement>prop;
            if (element.dataset.planpropertypk !== "0")
                planProperties.push(element.dataset.planpropertypk);
        }

        const dataModel = {
            "PropertyCodeType": propertyCodeType,
            "Row": row,
            "PlanPropertyValues": planProperties
        };

        const settings = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataModel)
        };


        const response = await fetch(`/CATSSystem/DeleteScopeAndSequenceRow`, settings)
        if (response.ok) {
            const row = <HTMLTableRowElement>document.querySelector(`.scopeAndSequenceRow[data-row='${dataModel.Row}'][data-propertycodetype='${dataModel.PropertyCodeType}']`);

            const modal: Modal = new Modal("deleteScopeModal", null);
            modal.hide();

            row.remove();

            Core.createHTMLAlert("alertMessageDiv", "The row was successfully deleted.", 'success', 3000, null);
        } else {
            Core.createHTMLAlert("alertMessageDiv", "There was an issue deleting this row. Please try again.", 'error', 3000, null);
        }
    }
}

// CatsSystemStudentIndustry
class CatsSystemStudentIndustry {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["catsSystemStudentIndustryField", "catsSystemStudentIndustryCheckboxField"];

        let catsSystemStudentIndustrySaveButton = document.getElementById("catsSystemStudentIndustrySave");
        if (catsSystemStudentIndustrySaveButton !== null)
            catsSystemStudentIndustrySaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        this.saveAccess();

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }


    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    saveAccess() {
        const formName = "catsSystemStudentIndustryForm";
        let planFK = 0;
        let form = document.getElementById(formName);
        planFK = parseInt(form.dataset.parentplanfk);

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemAccess', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {

            }
        };
        xhr.send(`planFK=${planFK}`);
    }

    save(referrer) {
        let allCredentialElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        const formElement = document.getElementById("catsSystemStudentIndustryForm");
        const planPK = parseInt(formElement.dataset.planfk);
        const programPK = parseInt(formElement.dataset.programfk);

        const checkboxes = document.getElementsByClassName("catsSystemStudentIndustryCheckboxField");
        for (let ele of checkboxes) {
            let element = <HTMLInputElement>ele;
            let hadValue = false;
            let catsStudentIndustryCredentialPK = 0;
            if (element.dataset.catsstudentindustrycredentialpk !== "" && element.dataset.catsstudentindustrycredentialpk !== "0") {
                catsStudentIndustryCredentialPK = parseInt(element.dataset.catsstudentindustrycredentialpk);
                hadValue = true;
            }

            if (element.checked || hadValue) {
                let isSelected = false;
                if (element.checked)
                    isSelected = true

                const catsTeacherFK = parseInt(element.dataset.catsteacherfk);
                const credentialFK = parseInt(element.dataset.industrycertificationcodepk);

                const saveItem: IIndustryCredentialStudentCredentialSaveData = {
                    CATSStudentIndustryCredentialPK: catsStudentIndustryCredentialPK,
                    PlanFK: planPK,
                    CATSProgramFK: programPK,
                    CATSIndustryCertificationCodeFK: credentialFK,
                    IsSelectedInd: isSelected
                };

                allCredentialElements.push(saveItem);
            }
        }

        const noCredentialsElement = <HTMLInputElement>document.querySelector(".catsSystemStudentIndustryNoCredentialsCheckboxField");
        let noCredentials = {
            "NoCredentials": null,
            "CATSStudentIndustryCredentialPK": null,
            "ProgramFK": programPK
        };
        if (noCredentialsElement !== null) {
            const catsStudentIndustryCredentialPK = noCredentialsElement.dataset.catsstudentindustrycredentialpk;

            noCredentials.NoCredentials = noCredentialsElement.checked;
            noCredentials.CATSStudentIndustryCredentialPK = catsStudentIndustryCredentialPK;
        }

        const allData = {
            "StudentCredentials": allCredentialElements,
            "NoCredentials": noCredentials
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemStudentIndustry', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    core.pageReload(true, planPK);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                    Core.hideLoader();
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };

        xhr.send(JSON.stringify(allData));
    }
}

// CatsSystemTeacherIndustry
class CatsSystemTeacherIndustry {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["workEmail"];

        let catsSystemTeacherIndustrySaveButton = document.getElementById("catsSystemTeacherIndustrySave");
        if (catsSystemTeacherIndustrySaveButton !== null)
            catsSystemTeacherIndustrySaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        this._core.initializeRequiredFields(this.validationClasses);
        this.saveAccess();

        const employmentStatuses = document.getElementsByClassName("catsSystemTeacherIndustryEmploymentStatus");
        for (const employmentStatus of employmentStatuses) {
            employmentStatus.addEventListener("change", (e: Event) => this.changeEmploymentStatus(e));
        }

        const catsSystemTeacherIndustryTeacherAddTeacherButton = document.getElementById("catsSystemTeacherIndustryTeacherAddTeacher");
        if (catsSystemTeacherIndustryTeacherAddTeacherButton !== null)
            catsSystemTeacherIndustryTeacherAddTeacherButton.addEventListener("click", (e: Event) => this.addTeacher());

        this.bindTeacherCredentials();

        const catsSystemTeacherIndustryTIMSReminderModalCloseButton = document.getElementById("catsSystemCATSTeacherIndustryTIMSClose");
        if (catsSystemTeacherIndustryTIMSReminderModalCloseButton !== null)
            catsSystemTeacherIndustryTIMSReminderModalCloseButton.addEventListener("click", (e: Event) => this.closeTIMSReminderModal());

        const catsSystemTeacherIndustryTeacherExistsModalCloseButton = document.getElementById("catsSystemCATSTeacherIndustryExistsClose");
        if (catsSystemTeacherIndustryTeacherExistsModalCloseButton !== null)
            catsSystemTeacherIndustryTeacherExistsModalCloseButton.addEventListener("click", (e: Event) => this.closeTeacherExistsModal());

        const catsSystemTeacherIndustryTeacherNotInTIMSModalCloseButton = document.getElementById("catsSystemCATSTeacherIndustryNotInTIMSClose");
        if (catsSystemTeacherIndustryTeacherNotInTIMSModalCloseButton !== null)
            catsSystemTeacherIndustryTeacherNotInTIMSModalCloseButton.addEventListener("click", (e: Event) => this.closeTeacherNotInTIMSModal());

        const catsSystemTeacherIndustryTeacherNotValidInCIPModalCloseButton = document.getElementById("catsSystemCATSTeacherIndustryNotValidInCIPClose");
        if (catsSystemTeacherIndustryTeacherNotValidInCIPModalCloseButton !== null)
            catsSystemTeacherIndustryTeacherNotValidInCIPModalCloseButton.addEventListener("click", (e: Event) => this.closeTeacherNotValidInCIPModal());

        const catsSystemTeacherIndustryCredentialsTeacherDeleteConfirmButton = document.getElementById("catsSystemTeacherIndustryCredentialsDeleteConfirm");
        if (catsSystemTeacherIndustryCredentialsTeacherDeleteConfirmButton !== null)
            catsSystemTeacherIndustryCredentialsTeacherDeleteConfirmButton.addEventListener("click", (e: Event) => this.deleteTeacherConfirm(e));

        const catsSystemTeacherIndustryCredentialsTeacherDeleteCancelButton = document.getElementById("catsSystemTeacherIndustryCredentialsDeleteCancel");
        if (catsSystemTeacherIndustryCredentialsTeacherDeleteCancelButton !== null)
            catsSystemTeacherIndustryCredentialsTeacherDeleteCancelButton.addEventListener("click", (e: Event) => this.deleteTeacherCancel());

        this.bindDeleteTeachers();
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    saveAccess() {
        const formName = "catsSystemTeacherIndustryForm";
        let planFK = 0;
        let form = document.getElementById(formName);
        planFK = parseInt(form.dataset.parentplanfk);

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemAccess', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {

            }
        };
        xhr.send(`planFK=${planFK}`);
    }

    save(referrer) {
        let allCredentialElements = [];
        let allTeacherElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLongLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        const formElement = document.getElementById("catsSystemTeacherIndustryForm");
        const planPK = parseInt(formElement.dataset.planfk);
        const programPK = parseInt(formElement.dataset.programpk);

        let showNotice: boolean = false;

        const teacherRows = document.getElementsByClassName("teacherRow");
        for (const teacherRow of teacherRows) {
            const ppidFKElement = <HTMLTableCellElement>teacherRow.querySelector("td[data-type='PPIDFK']");
            const ppidFK = parseInt(ppidFKElement.dataset.value);
            const teacherPK = parseInt(ppidFKElement.dataset.catsteacherfk)

            const emailElement = <HTMLInputElement>teacherRow.querySelector(".workEmail");
            const emailAddress = emailElement.value;
            const oldEmailAddress = emailElement.dataset.existingemail;

            if (oldEmailAddress !== "" && oldEmailAddress !== emailAddress)
                showNotice = true;

            const noCredentials = <HTMLInputElement>document.querySelector(`.allIndustryCredentialsNoCredentials[data-catsteacherfk='${teacherPK}']`);
            
            const teacherItem: IIndustryCredentialTeacherSaveData = {
                EmailAddress: emailAddress,
                CATSTeacherFK: teacherPK
            }

            if (ppidFK && ppidFK > 0)
                teacherItem.TIMSPPIDHolderFK = ppidFK;

            if (noCredentials) {
                teacherItem.NoCredentials = noCredentials.checked;
            }

            allTeacherElements.push(teacherItem);
        }
        
        const checkboxes = document.getElementsByClassName("catsSystemTeacherIndustryCheckboxField");
        for (let ele of checkboxes) {
            let element = <HTMLInputElement>ele;
            let hadValue = false;
            let catsTeacherIndustryCredentialPK = 0;
            if (element.dataset.catsteacherindustrycredentialpk !== "" && element.dataset.catsteacherindustrycredentialpk !== "0") {
                catsTeacherIndustryCredentialPK = parseInt(element.dataset.catsteacherindustrycredentialpk);
                hadValue = true;
            }

            if (element.checked || hadValue) {
                let isSelected = false;
                if (element.checked)
                    isSelected = true

                const catsTeacherFK = parseInt(element.dataset.catsteacherfk);
                const credentialFK = parseInt(element.dataset.industrycertificationcodepk);

                const saveItem: IIndustryCredentialCredentialSaveData = {
                    CATSTeacherIndustryCredentialPK: catsTeacherIndustryCredentialPK,
                    PlanFK: planPK,
                    CATSProgramFK: programPK,
                    CATSTeacherFK: catsTeacherFK,
                    CATSIndustryCertificationCodeFK: credentialFK,
                    IsSelectedInd: isSelected
                };

                allCredentialElements.push(saveItem);
            }
        }

        var saveData = {
            "TeacherData": allTeacherElements,
            "Credentials": allCredentialElements
        }

        if (showNotice) {
            let modal: Modal = new Modal("timsReminderModal", null);
            modal.show();
        }

        const that = this;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemTeacherIndustry', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    const whichOpenElement = <HTMLDivElement>document.getElementById("teacherIndustryCredentialsOpen");
                    if (whichOpenElement) {
                        const whichOpen = whichOpenElement.dataset.open;
                        const ppidFK = whichOpenElement.dataset.timsppidholderfk;

                        if (whichOpen === "TeacherIndustryCredentials") {
                            const buttonElement = <HTMLButtonElement>document.querySelector(`.catsSystemTeacherIndustryTeacherAddTeacherCredentials[data-timsppidholderfk='${ppidFK}']`);
                            Core.hideLongLoader();
                            buttonElement.click();
                        } else if (whichOpen === "TeacherData") {
                            const anchorElement = <HTMLAnchorElement>document.querySelector(`.teacherCredentials[data-timsppidholderfk='${ppidFK}']`);
                            Core.hideLongLoader();
                            anchorElement.click();
                        }
                    } else {
                        Core.hideLongLoader();
                    }

                    that.customValidation();
                }
                else {
                    Core.hideLongLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else
                Core.hideLongLoader();
        };
        xhr.send(JSON.stringify(saveData));
    }

    changeEmploymentStatus(e: Event) {
        const radioButton = <HTMLInputElement>e.target;

        const ppidElement = <HTMLInputElement>document.getElementById("catsSystemTeacherIndustryProfessionalPersonnelID");
        const nameElement = <HTMLInputElement>document.getElementById("catsSystemTeacherIndustryTeacherName");

        if (radioButton.checked) {
            const employmentStatus = radioButton.dataset.lookuplabel;
            if (employmentStatus.toLowerCase().indexOf("to be") >= 0) {
                ppidElement.disabled = true;
                nameElement.disabled = false;
                ppidElement.value = "";
                nameElement.value = "";
            } else {
                ppidElement.disabled = false;
                nameElement.disabled = true;
                ppidElement.value = "";
                nameElement.value = "";
            }
        }
    }

    async addTeacher() {
        Core.showLoader();
        const form = document.getElementById("catsSystemTeacherIndustryForm");
        const programPK = form.dataset.programpk;
        const planFK = form.dataset.planfk;

        const teacherEmploymentElement = <HTMLInputElement>document.querySelector(".catsSystemTeacherIndustryEmploymentStatus:checked");
        if (teacherEmploymentElement !== null) {
            const ppidElement = <HTMLInputElement>document.getElementById("catsSystemTeacherIndustryProfessionalPersonnelID");
            const nameElement = <HTMLInputElement>document.getElementById("catsSystemTeacherIndustryTeacherName");

            const employmentStatusLookupCodeElement = <HTMLInputElement>document.querySelector(".catsSystemTeacherIndustryEmploymentStatus:checked")
            const employmentStatusLookupCodeFK = employmentStatusLookupCodeElement.value;

            let dataModel = {
                "Name": "",
                "PPID": "",
                "ProgramPK": programPK,
                "EmploymentStatusLookupCodeFK": employmentStatusLookupCodeFK,
                "PlanFK": planFK
            };

            let newEmployee = false;
            let allowContinue = true;

            const employmentStatus = teacherEmploymentElement.dataset.lookuplabel;
            if (employmentStatus.toLowerCase().indexOf("to be") >= 0) {
                if (nameElement && nameElement.value !== "") {
                    const name = nameElement.value;

                    dataModel.Name = name;
                } else {
                    Core.createHTMLAlert("alertMessageDiv", "You must enter a name for a to be employed teacher.", "warning", 3000, null);
                    allowContinue = false;
                }

                newEmployee = true;
            } else {
                if (ppidElement && ppidElement.value !== "") {
                    const ppid = ppidElement.value;

                    dataModel.PPID = ppid.trim();
                } else {
                    Core.createHTMLAlert("alertMessageDiv", "You must enter a PPID for an employed teacher.", "warning", 3000, null);
                    allowContinue = false;
                }

                newEmployee = false;
            }

            if (allowContinue) {
                let teacherExists = false;
                let notValidForCIP = false;
                let notInTIMS = false;

                const settings = {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(dataModel)
                };

                if (newEmployee === false) {
                    if (dataModel.PPID !== "") {
                        const teacherExistsResponse = await fetch(`/CATSSystem/TeacherExists`, settings);
                        if (teacherExistsResponse) {
                            const output = await teacherExistsResponse.json();

                            if (output.teacherExists === true)
                                teacherExists = true;
                            if (output.notValidForCIP === true)
                                notValidForCIP = true;
                            if (output.notInTIMS === true)
                                notInTIMS = true;
                        }
                    }
                }

                if (notInTIMS === true) {
                    Core.hideLoader();
                    let modal: Modal = new Modal("notInTIMSModal", null);
                    modal.show();
                } else if (teacherExists === true) {
                    Core.hideLoader();
                    let modal: Modal = new Modal("teacherExistsModal", null);
                    modal.show();
                } else if (notValidForCIP === true) {
                    Core.hideLoader();
                    let modal: Modal = new Modal("notValidInCIPModal", null);
                    modal.show();
                } else {
                    const addTeacherResponse = await fetch(`/CATSSystem/AddTeacher`, settings);
                    if (addTeacherResponse.ok) {
                        const addTeacherOutput = await addTeacherResponse.text();

                        let addTeacherNewTR = <HTMLTableRowElement>document.createElement("tr");
                        addTeacherNewTR.classList.add("teacherRow");
                        addTeacherNewTR.innerHTML = addTeacherOutput;

                        const addTeacherContainer = document.querySelector("#teacherTable tbody");
                        addTeacherContainer.appendChild(addTeacherNewTR);

                        const addTeacherContainerElement = <HTMLDivElement>document.getElementById("teacherContainer");
                        addTeacherContainerElement.classList.remove("hide");

                        ppidElement.value = "";
                        ppidElement.disabled = true;
                        nameElement.value = "";
                        nameElement.disabled = true;
                        employmentStatusLookupCodeElement.checked = false;
                        Core.hideLoader();
                    }
                }

                this.bindTeacherCredentials();
                this.bindDeleteTeachers();
            }
        } else {
            Core.createHTMLAlert("alertMessageDiv", "You must choose an employment status and fill out the appropriate field.", "warning", 3000, null);
        }
    }

    bindTeacherCredentials() {
        const allTeachers = document.getElementsByClassName("teacherCredentials");

        for (const teacher of allTeachers) {
            teacher.addEventListener("click", (e: Event) => this.showTeacherCredentials(e));
        }

        const allTeacherAddCredentials = document.getElementsByClassName("catsSystemTeacherIndustryTeacherAddTeacherCredentials");
        for (const cred of allTeacherAddCredentials)
            cred.addEventListener("click", (e: Event) => this.showAddTeacherCredentials(e));

        const allUpdatePPIDs = document.getElementsByClassName("catsSystemTeacherIndustryTeacherUpdatePPID");
        for (const update of allUpdatePPIDs)
            update.addEventListener("click", (e: Event) => this.updatePPIDForTeacher(e));
    }

    async showTeacherCredentials(e: Event) {
        const link = <HTMLAnchorElement>e.target;
        const timsPPIDHolderFK = link.dataset.timsppidholderfk;
        const programFK = link.dataset.programfk;

        Core.showLoader();

        const response = await fetch(`/CATSSystem/GetCredentials/${timsPPIDHolderFK}/${programFK}`, { credentials: 'include' })
        if (response.ok) {

            let container = document.querySelector("#teacherData");
            container.innerHTML = "";

            const output = await response.text();

            const newDiv = <HTMLDivElement>document.createElement("div");
            newDiv.innerHTML = output;

            container.append(newDiv);
        }

        const credsResponse = await fetch(`/CATSSystem/ShowTeacherIndustryCredentials/${programFK}/${timsPPIDHolderFK}/false`, { credentials: 'include' })
        if (credsResponse.ok) {

            const output = await credsResponse.text();

            const newDiv = <HTMLDivElement>document.createElement("div");
            newDiv.classList.add("margin-top-xl");
            newDiv.innerHTML = output;

            let container = document.querySelector("#teacherData");
            container.append(newDiv);

            Core.hideLoader();
        }
    }

    async showAddTeacherCredentials(e: Event) {
        const button = <HTMLButtonElement>e.target;
        const timsPPIDHolderFK = button.dataset.timsppidholderfk;
        const programFK = button.dataset.programfk;

        Core.showLoader();

        const response = await fetch(`/CATSSystem/ShowTeacherIndustryCredentials/${programFK}/${timsPPIDHolderFK}/true`, { credentials: 'include' })
        if (response.ok) {
            let container = document.querySelector("#teacherData");
            container.innerHTML = "";

            const output = await response.text();

            const newDiv = <HTMLDivElement>document.createElement("div");
            newDiv.innerHTML = output;

            container.append(newDiv);

            Core.hideLoader();
        }
    }

    closeTeacherExistsModal() {
        let modal: Modal = new Modal("teacherExistsModal", null);
        modal.hide();
    }

    closeTeacherNotValidInCIPModal() {
        let modal: Modal = new Modal("notValidInCIPModal", null);
        modal.hide();
    }

    closeTeacherNotInTIMSModal() {
        let modal: Modal = new Modal("notInTIMSModal", null);
        modal.hide();
    }

    closeTIMSReminderModal() {
        let modal: Modal = new Modal("timsReminderModal", null);
        modal.hide();
    }

    async customValidation() {
        let showMessage = false;

        const allEmailElements = document.querySelectorAll(`.workEmail[data-catsteacheremployement='catsTeacherIndustryCredentialsEmploymentEmployed']`);

        //reset the page so it's properly re-validated each time.
        for (const email of allEmailElements) {
            const emailElement = <HTMLInputElement>email;
            emailElement.classList.remove("missing-field");
            const attr = emailElement.attributes.getNamedItem("aria-invalid");
            if (attr)
                emailElement.attributes.removeNamedItem("aria-invalid");
            Core.removeErrorLabelForInput(emailElement);
        }

        let totalErrors = 0;

        for (const email of allEmailElements) {
            const emailElement = <HTMLInputElement>email;

            if (emailElement.value === "") {
                emailElement.classList.add("missing-field");
                emailElement.setAttribute("aria-invalid", "true");
                Core.createErrorLabelForInput(emailElement);
                showMessage = true;
                totalErrors++;
            }
        }

        let additionalMessageNeeded: boolean = false;
        let additionalMessage: string = "";
        const customValidation = await this.customPageValidation();
        if (customValidation > 0) {
            totalErrors += customValidation;
            showMessage = true;
            additionalMessage = "One or more teachers require credentials to be added. Click the \"Add Credentials\" button to view/add credentials.";
            additionalMessageNeeded = true;
        }

        let message = <HTMLDivElement>document.getElementById("validationMessage");
        let messageContainerColumn = <HTMLElement>document.getElementById("validationColumn");
        let messageContainer = <HTMLElement>document.getElementById("validationMessageContainer");
        messageContainer.classList.remove("warning");
        messageContainer.classList.remove("success");
        messageContainerColumn.classList.add("show");
        let validationIcon = <HTMLElement>document.getElementById("validationMessageIcon");

        if (totalErrors > 0) {
            if (totalErrors === 1) {
                message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
                if (additionalMessageNeeded)
                    message.innerHTML += `<p>${additionalMessage}</p>`;
            } else {
                message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
                if (additionalMessageNeeded)
                    message.innerHTML += `<p>${additionalMessage}</p>`;
            }
            let goToError = document.getElementById("goToFirstError");

            if (goToError !== null) {

                let that = this;

                let firstFocusableEl = <HTMLElement>document.querySelector(".missing-field");

                if (firstFocusableEl !== null) {
                    goToError.addEventListener("click", function () {
                        let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                        if (accordion) {
                            let id = accordion.getAttribute("aria-labelledby");

                            let accordionElement = <HTMLButtonElement>document.getElementById(id);
                            accordionElement.click();
                        }

                        if (firstFocusableEl.classList.contains("mce")) {
                            tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                        } else {
                            firstFocusableEl.focus();
                        }
                    });
                } else {
                    goToError.parentNode.removeChild(goToError);
                }
            }
        }

        setTimeout(function () {
            messageContainer.focus();
        }, 500);

        const leftBarItem = <HTMLDivElement>document.querySelector("#leftBarcatsSystemTeacherIndustryParent[data-pagecode='catsSystemTeacherIndustry'] .status-indicator");

        if (showMessage) {

            let message = <HTMLElement>document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";

            leftBarItem.classList.remove("vertical-align-height");
            leftBarItem.classList.remove("complete");
        } else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";

            let successMessage = <HTMLElement>document.getElementById("validationMessage");

            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved.";
                successMessage.classList.remove("validation-message");
            }

            leftBarItem.classList.add("vertical-align-height");
            leftBarItem.classList.add("complete");
        }
    }

    async updatePPIDForTeacher(e: Event) {
        const button = <HTMLButtonElement>e.target;
        const catsTeacherFK = button.dataset.catsteacherfk;
        const catsProgramFK = button.dataset.programfk;
        const planFK = button.dataset.planfk;

        Core.showLoader();

        const response = await fetch(`/CATSSystem/ShowUpdatePPID/${catsProgramFK}/${catsTeacherFK}/${planFK}`, { credentials: 'include' })
        if (response.ok) {
            const container = <HTMLTableRowElement>document.querySelector(`.updateRow[data-catsteacherfk='${catsTeacherFK}']`);

            const output = await response.text();

            container.innerHTML = output;

            container.classList.remove("hide");

            Core.hideLoader();

            this.bindFindPPIDForUpdate();
        }
    }

    bindFindPPIDForUpdate() {
        const button = <HTMLButtonElement>document.getElementById("catsSystemTeacherIndustryTeacherAddTeacherAdd");
        button.addEventListener("click", (e: Event) => this.findTeacher());
    }

    async findTeacher() {
        const ppidElement = <HTMLInputElement>document.getElementById("catsSystemTeacherIndustryProfessionalPersonnelIDAddId");
        const ppid = ppidElement.value;
        const catsProgramFK = ppidElement.dataset.catsprogramfk;
        const planFK = ppidElement.dataset.planfk;
        const catsTeacherFK = ppidElement.dataset.catsteacherfk;

        if (ppid === "") {
            Core.createHTMLAlert("alertMessageDiv", "Enter a PPID to search for that teacher.", "warning", 3000, null);
        }
        else {
            let dataModel = {
                "Name": "",
                "PPID": ppid,
                "ProgramPK": catsProgramFK,
                "PlanFK": planFK
            };

            let teacherExists = false;
            let notValidForCIP = false;
            let notInTIMS = false;

            const settings = {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(dataModel)
            };

            if (dataModel.PPID !== "") {
                const teacherExistsResponse = await fetch(`/CATSSystem/TeacherExists`, settings);
                if (teacherExistsResponse) {
                    const output = await teacherExistsResponse.json();

                    if (output.teacherExists === true)
                        teacherExists = true;
                    if (output.notValidForCIP === true)
                        notValidForCIP = true;
                    if (output.notInTIMS === true)
                        notInTIMS = true;
                }
            }

            if (notInTIMS === true) {
                let modal: Modal = new Modal("notInTIMSModal", null);
                modal.show();
            } else if (teacherExists === true) {
                let modal: Modal = new Modal("teacherExistsModal", null);
                modal.show();
            } else if (notValidForCIP === true) {
                let modal: Modal = new Modal("notValidInCIPModal", null);
                modal.show();
            } else {

                const response = await fetch(`/CATSSystem/GetTeacherInformation/${ppid}`, { credentials: 'include' })
                if (response.ok) {
                    const output = await response.json();

                    const ppidHolderFK = output.ppidHolderFK;
                    const name = output.name;

                    const container = <HTMLDivElement>document.querySelector(`.updateTeacherInfo[data-catsprogramfk='${catsProgramFK}'][data-catsteacherfk='${catsTeacherFK}']`);

                    const newPPIDDiv = <HTMLDivElement>document.createElement("div");
                    newPPIDDiv.classList.add("medium-4");
                    newPPIDDiv.classList.add("column");
                    newPPIDDiv.classList.add("updateRowPPID");
                    newPPIDDiv.dataset.ppid = ppid;
                    newPPIDDiv.dataset.catsteacherfk = catsTeacherFK;
                    newPPIDDiv.dataset.catsprogramfk = catsProgramFK;
                    newPPIDDiv.dataset.ppidholderfk = ppidHolderFK;
                    newPPIDDiv.innerHTML = ppid;

                    container.appendChild(newPPIDDiv);

                    const newNameDiv = <HTMLDivElement>document.createElement("div");
                    newNameDiv.classList.add("medium-4");
                    newNameDiv.classList.add("column");
                    newNameDiv.innerHTML = name;
                    container.appendChild(newNameDiv);

                    const newButtonDiv = <HTMLDivElement>document.createElement("div");
                    newButtonDiv.classList.add("medium-4");
                    newButtonDiv.classList.add("column");

                    const newButton = <HTMLButtonElement>document.createElement("button");
                    newButton.classList.add("add-row-button");
                    newButton.classList.add("updateTeacherWithPPID");
                    newButton.dataset.catsteacherfk = catsTeacherFK;
                    newButton.dataset.ppidholderfk = ppidHolderFK;
                    newButton.innerHTML = "<i class='fas fa-user-edit'></i> Confirm Update";

                    newButtonDiv.append(newButton);

                    container.classList.add("update-teacher-info");
                    container.append(newButtonDiv);

                    this.bindUpdateByPPIDButton();
                }
            }
        }
    }

    bindUpdateByPPIDButton() {
        const buttons = document.getElementsByClassName("updateTeacherWithPPID");
        for (const button of buttons)
            button.addEventListener("click", (e: Event) => this.updateTeacher(e));
    }

    async updateTeacher(e: Event) {
        const button = <HTMLButtonElement>e.target;
        const catsTeacherFK = button.dataset.catsteacherfk;
        const ppidHolderFK = button.dataset.ppidholderfk;

        const response = await fetch(`/CATSSystem/UpdateTeacherInformation/${catsTeacherFK}/${ppidHolderFK}`, { credentials: 'include' })
        if (response.ok) {
            const output = await response.text();

            const thisTR = <HTMLTableRowElement>document.querySelector(`.teacherRow[data-catsteacherfk='${catsTeacherFK}']`);
            thisTR.innerHTML = output;

            const updateRowHide = <HTMLTableRowElement>document.querySelector(`.updateRowHide[data-catsteacherfk='${catsTeacherFK}']`);
            const updateRow = <HTMLTableRowElement>document.querySelector(`.updateRow[data-catsteacherfk='${catsTeacherFK}']`);

            updateRowHide.remove();
            updateRow.remove();

            this.bindTeacherCredentials();
            this.bindDeleteTeachers();
        }
    }

    bindDeleteTeachers() {
        const allSecondaryAgreements = document.getElementsByClassName("deleteTeacher");
        for (const agreement of allSecondaryAgreements)
            agreement.addEventListener("click", (e: Event) => this.showDeleteTeacher(e));
    }

    showDeleteTeacher(e: Event) {
        const buttonElement = <HTMLButtonElement>e.target;
        const catsTeacherFK = buttonElement.dataset.catsteacherfk;

        const modal: Modal = new Modal("teacherDeleteModal", null);
        modal.addAttributeToElement("teacherDeleteModal", "#catsSystemTeacherIndustryCredentialsDeleteConfirm", "catsteacherfk", catsTeacherFK);
        modal.show();
    }

    deleteTeacherCancel() {
        let modal: Modal = new Modal("teacherDeleteModal", null);
        modal.hide();
    }

    deleteTeacherConfirm(e: Event) {
        const buttonElement = <HTMLButtonElement>e.target;
        const catsTeacherFK = buttonElement.dataset.catsteacherfk;

        const modal: Modal = new Modal("teacherDeleteModal", null);

        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/DeleteTeacher', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    const element = document.querySelector(`.deleteTeacher[data-catsteacherfk='${catsTeacherFK}']`);
                    element.parentElement.parentElement.remove();

                    Core.createHTMLAlert("alertMessageDiv", "The teacher was successfully removed.", 'success', 3000, null);
                } else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the teacher. Please try again.", 'error', 3000, null);
                }

                Core.hideLoader();

                modal.hide();
            } else {
                modal.hide();

                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(`catsTeacherFK=${catsTeacherFK}`);
    }

    async customPageValidation() {
        //This page is unique since there are two partial views that could be displayed in the same spot. We need to check the one partial view
        //to make sure there is valid data, but the only way to do that is on the server side.

        const formElement = document.getElementById("catsSystemTeacherIndustryForm");
        const programPK = parseInt(formElement.dataset.programpk);
        let retVal = 0;
        const response = await fetch(`/CATSSystem/TeacherIndustryCredentialsCustomClientValidation/${programPK}`, { credentials: 'include' })
        if (response.ok) {

            const output = await response.json();

            if (output === false)
                retVal = 1;
        }

        return retVal;
    }
}

// CatsSystemSponsors
class CatsSystemSponsors {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["catsSystemSponsorsField", "catsSystemSponsorsRadioField"];

        let catsSystemSponsorsSaveButton = document.getElementById("catsSystemSponsorsSave");
        if (catsSystemSponsorsSaveButton !== null)
            catsSystemSponsorsSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        const catsSystemSponsorsShowSponsorsRadios = document.getElementsByClassName("catsSystemSponsorsShowSponsors");
        for (const catsSystemSponsorsShowSponsorsRadio of catsSystemSponsorsShowSponsorsRadios) {
            catsSystemSponsorsShowSponsorsRadio.addEventListener("click", (e: Event) => this.changeSponsor(<HTMLInputElement>e.target));

            this.changeSponsor(<HTMLInputElement>catsSystemSponsorsShowSponsorsRadio);
        }

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        this.saveAccess();

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        const addSponsorRowButton = document.querySelector(`#addSponsorRow`);
        if (addSponsorRowButton !== null)
            addSponsorRowButton.addEventListener("click", (e: Event) => this.addSponsorRow());

        const deleteURLRowConfirmButton = document.getElementById("catsSystemCATSRowDeleteConfirm");
        if (deleteURLRowConfirmButton !== null)
            deleteURLRowConfirmButton.addEventListener("click", (e: Event) => this.deleteRowConfirm(e));

        const deleteURLRowCancelButton = document.getElementById("catsSystemCATSRowDeleteCancel");
        if (deleteURLRowCancelButton !== null)
            deleteURLRowCancelButton.addEventListener("click", (e: Event) => this.deleteRowCancel());

        this.bindDeletes();
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    saveAccess() {
        const formName = "catsSystemSponsorsForm";
        let planFK = 0;
        let form = document.getElementById(formName);
        planFK = parseInt(form.dataset.parentplanfk);

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemAccess', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {

            }
        };
        xhr.send(`planFK=${planFK}`);
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("catsSystemSponsorsForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("catsSystemSponsorsField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let radios = document.getElementsByClassName("catsSystemSponsorsRadioField");
        for (let ele of radios) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                if (element.value !== "" || hadValue) {
                    let saveItem: IPlanPropertyExtended = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                        ButtonRow: 0
                    };

                    allSaveElements.push(saveItem);
                }
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemSponsors', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    async addSponsorRow() {
        Core.showLoader();
        const form = document.getElementById("catsSystemSponsorsForm");
        const planPK = form.dataset.planfk;
        const programPK = form.dataset.programfk;
        const allRows = document.querySelectorAll(".sponsorsRow");
        let row = 0;
        for (let thisRow of allRows) {
            const ele = <HTMLTableRowElement>thisRow;
            const currentRow = parseInt(ele.dataset.row);
            if (!isNaN(currentRow) && currentRow > row)
                row = currentRow
        }

        row++;

        const response = await fetch(`/CATSSystem/AddSponsor/${planPK}/${programPK}/${row}`, { credentials: 'include' })
        if (response.ok) {

            const output = await response.text();

            let newTR = <HTMLTableRowElement>document.createElement("tr");
            newTR.innerHTML = output;
            newTR.classList.add("sponsorsRow");
            newTR.dataset.row = row.toString();

            let container = document.querySelector("#sponsorsTable tbody");
            container.append(newTR);

            this.bindDeletes();
        }

        Core.hideLoader();
    }

    bindDeletes() {
        const allDeletes = document.getElementsByClassName("deleteSponsorRow");
        for (let del of allDeletes)
            del.addEventListener("click", (e: Event) => this.showDelete(e));
    }

    showDelete(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let row = buttonElement.dataset.row;

        let modal: Modal = new Modal("deleteRowModal", null);
        modal.addAttributeToElement("deleteRowModal", "#catsSystemCATSRowDeleteConfirm", "row", row);
        modal.show();
    }

    deleteRowCancel() {
        let modal: Modal = new Modal("deleteRowModal", null);
        modal.hide();
    }

    deleteRowConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let planProps = [];
        let row = buttonElement.dataset.row;

        let allElements = document.querySelectorAll(`.catsSystemSponsorData[data-row='${row}']`);
        for (let ele of allElements) {
            let element = <HTMLElement>ele;
            let planProp = element.dataset.planpropertypk;

            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/DeleteRowData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();

                let rowElement = document.querySelector(`#sponsorsTable tbody tr[data-row='${row}']`);
                rowElement.remove();

                let modal: Modal = new Modal("deleteRowModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The row data has been removed.", 'success', 3000, null);
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }

    changeSponsor(e: HTMLInputElement) {
        const ele = e;

        if (ele.checked) {
            const label = ele.dataset.lookuplabel;
            const catsSponsorsList = <HTMLDivElement>document.getElementById("catsSponsorsList");
            const allDataFields = document.getElementsByClassName("catsSystemSponsorData");

            if (label === "Yes") {
                catsSponsorsList.classList.remove("hide");

                for (const field of allDataFields) {
                    let fieldEle = <HTMLInputElement>field;
                    this._core.forceElementRequired(fieldEle);
                }
            } else {
                catsSponsorsList.classList.add("hide");

                for (const field of allDataFields) {
                    let fieldEle = <HTMLInputElement>field;
                    this._core.forceElementOptional(fieldEle);
                }
            }
        }
    }
}

// CatsSystemIndustryAccreditation
class CatsSystemIndustryAccreditation {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["catsSystemIndustryAccreditationField", "catsSystemIndustryAccreditationSelectField", "catsSystemIndustryAccreditationCheckboxField"];

        let catsSystemIndustryAccreditationSaveButton = document.getElementById("catsSystemIndustryAccreditationSave");
        if (catsSystemIndustryAccreditationSaveButton !== null)
            catsSystemIndustryAccreditationSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        const allDropdowns = document.getElementsByClassName("catsSystemIndustryAccreditationAccreditationField");
        for (const drop of allDropdowns)
            this.checkForOther(<HTMLSelectElement>drop);
        this.disableAccredidations();

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        this.saveAccess();

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        const addIndustryAccreditationRowButton = document.querySelector(`#addIndustryAccreditationRow`);
        if (addIndustryAccreditationRowButton !== null)
            addIndustryAccreditationRowButton.addEventListener("click", (e: Event) => this.addIndustryAccreditationRow());

        this.bindOtherDropdowns();
        this.bindDeletes();

        //const allDropdowns = document.getElementsByClassName("catsSystemIndustryAccreditationAccreditationField");
        //for (const drop of allDropdowns)
        //    this.checkForOther(<HTMLSelectElement>drop);

        const deleteURLRowConfirmButton = document.getElementById("catsSystemCATSRowDeleteConfirm");
        if (deleteURLRowConfirmButton !== null)
            deleteURLRowConfirmButton.addEventListener("click", (e: Event) => this.deleteRowConfirm(e));

        const deleteURLRowCancelButton = document.getElementById("catsSystemCATSRowDeleteCancel");
        if (deleteURLRowCancelButton !== null)
            deleteURLRowCancelButton.addEventListener("click", (e: Event) => this.deleteRowCancel());

        const accredidationButton = document.getElementById("catsSystemIndustryAccreditationNoIndustryAccreditation");
        if (accredidationButton !== null)
            accredidationButton.addEventListener("click", (e: Event) => this.disableAccredidations());
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    saveAccess() {
        const formName = "catsSystemIndustryAccreditationForm";
        let planFK = 0;
        let form = document.getElementById(formName);
        planFK = parseInt(form.dataset.parentplanfk);

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemAccess', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {

            }
        };
        xhr.send(`planFK=${planFK}`);
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("catsSystemIndustryAccreditationForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("catsSystemIndustryAccreditationField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let selectInputs = document.getElementsByClassName("catsSystemIndustryAccreditationSelectField");
        for (let ele of selectInputs) {
            let planPropertyPK = 0;
            let element = <HTMLSelectElement>ele;
            let rowNumber = "0";
            if (element.dataset.row !== null)
                rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if ((element.value !== "" && element.value !== "0") || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: null,
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }

        let checkboxes = document.getElementsByClassName("catsSystemIndustryAccreditationCheckboxField");
        for (let ele of checkboxes) {
            let planPropertyPK = 0;

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.checked || hadValue) {
                let val = "";
                if (element.checked)
                    val = "on";
                else
                    val = "";

                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemIndustryAccreditation', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    async addIndustryAccreditationRow() {
        Core.showLoader();
        const form = document.getElementById("catsSystemIndustryAccreditationForm");
        const planPK = form.dataset.planfk;
        const programPK = form.dataset.programfk;
        const allRows = document.querySelectorAll(".industryAccreditationRow");
        let row = 0;
        for (let thisRow of allRows) {
            const ele = <HTMLTableRowElement>thisRow;
            const currentRow = parseInt(ele.dataset.row);
            if (!isNaN(currentRow) && currentRow > row)
                row = currentRow
        }

        row++;

        const response = await fetch(`/CATSSystem/AddIndustryAccreditation/${planPK}/${programPK}/${row}`, { credentials: 'include' })
        if (response.ok) {

            const output = await response.text();

            let newTR = <HTMLTableRowElement>document.createElement("tr");
            newTR.innerHTML = output;
            newTR.classList.add("industryAccreditationRow");
            newTR.classList.add("industry-accreditation");
            newTR.dataset.row = row.toString();

            let container = document.querySelector("#industryAccreditationTable tbody");
            container.append(newTR);

            this.bindDeletes();
            this.bindOtherDropdowns();
        }

        Core.hideLoader();
    }

    disableAccredidations() {
        const accredidationButton = document.getElementById("catsSystemIndustryAccreditationNoIndustryAccreditation");
        let elements = document.getElementsByClassName("catsSystemIndustryAccreditationNOTOtherField");
        let otherElements = document.getElementsByClassName("catsSystemIndustryAccreditationOtherField");
        let button = <HTMLButtonElement>document.getElementById("addIndustryAccreditationRow");
        let accredidationElement = <HTMLInputElement>accredidationButton;
    //    const catsSponsorsList = <HTMLDivElement>document.getElementById("catsSponsorsList");
    //    const allDataFields = document.getElementsByClassName("catsSystemSponsorData");

    //    if (label === "Yes") {
    //        catsSponsorsList.classList.remove("hide");

    //        for (const field of allDataFields) {
    //            let fieldEle = <HTMLInputElement>field;
    //            this._core.forceElementRequired(fieldEle);
    //        }
    //    } else {
    //        catsSponsorsList.classList.add("hide");

    //        for (const field of allDataFields) {
    //            let fieldEle = <HTMLInputElement>field;
    //            this._core.forceElementOptional(fieldEle);
    //        }
    //    }
    //}
        if (accredidationElement.checked) {
            for (let ele of elements) {
                let element = <HTMLInputElement>ele;
                element.disabled = true;
                this._core.forceElementOptional(element);
                button.disabled = true;
            }
            for (let ele of otherElements) {
                let element = <HTMLInputElement>ele;
                if (!element.classList.contains("hide")) {
                    element.disabled = true;
                }
            }

        }
        else {
            for (let ele of elements) {
                let element = <HTMLInputElement>ele;
                element.disabled = false;
                this._core.forceElementRequired(element);
                button.disabled = false;
            }
            for (let ele of otherElements) {
                let element = <HTMLInputElement>ele;
                if (!element.classList.contains("hide")) {
                    element.disabled = false;
                }
            }
        }
    }

    bindOtherDropdowns() {
        const allDropdowns = document.getElementsByClassName("catsSystemIndustryAccreditationAccreditationField");
        for (const drop of allDropdowns)
            drop.addEventListener("change", (e: Event) => this.checkForOther(<HTMLSelectElement>e.target));
    }

    bindDeletes() {
        const allDeletes = document.getElementsByClassName("deleteIndustryAccreditationRow");
        for (let del of allDeletes)
            del.addEventListener("click", (e: Event) => this.showDelete(e));
    }

    checkForOther(e: HTMLSelectElement) {
        const dropdown = e;
        const row = dropdown.dataset.row;

        const otherField = document.querySelector(`.catsSystemIndustryAccreditationOtherField[data-row='${row}']`)

        if (dropdown[dropdown.selectedIndex].textContent.indexOf("Other") >= 0) {
            otherField.classList.remove("hide");
            dropdown.classList.add("other");
            let element = <HTMLInputElement>otherField;
            this._core.forceElementRequired(element);
        } else {
            otherField.classList.add("hide");
            dropdown.classList.remove("other");
            let element = <HTMLInputElement>otherField;
            this._core.forceElementOptional(element);
        }
    }

    showDelete(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let row = buttonElement.dataset.row;

        let modal: Modal = new Modal("deleteRowModal", null);
        modal.addAttributeToElement("deleteRowModal", "#catsSystemCATSRowDeleteConfirm", "row", row);
        modal.show();
    }

    deleteRowCancel() {
        let modal: Modal = new Modal("deleteRowModal", null);
        modal.hide();
    }

    deleteRowConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let planProps = [];
        let row = buttonElement.dataset.row;

        let allElements = document.querySelectorAll(`.catsSystemIndustryAccreditationData[data-row='${row}']`);
        for (let ele of allElements) {
            let element = <HTMLElement>ele;
            let planProp = element.dataset.planpropertypk;

            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/DeleteRowData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();

                let rowElement = document.querySelector(`#industryAccreditationTable tbody tr[data-row='${row}']`);
                rowElement.remove();

                let modal: Modal = new Modal("deleteRowModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The row data has been removed.", 'success', 3000, null);
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }
}

// CatsSystemComments
class CatsSystemComments {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["catsSystemCommentsField"];

        let catsSystemCommentsSaveButton = document.getElementById("catsSystemCommentsSave");
        if (catsSystemCommentsSaveButton !== null)
            catsSystemCommentsSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        this.saveAccess();

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    saveAccess() {
        const formName = "catsSystemCommentsForm";
        let planFK = 0;
        let form = document.getElementById(formName);
        planFK = parseInt(form.dataset.parentplanfk);

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemAccess', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {

            }
        };
        xhr.send(`planFK=${planFK}`);
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("catsSystemCommentsForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("catsSystemCommentsField");

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemComments', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

// CatsSystemSummaryofNewProgram
class CatsSystemSummaryofNewProgram {
    private _core: Core;
    constructor() {
        this._core = new Core();
        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.back(e));

        let submitButton = document.getElementById("submitPlanButton");
        if (submitButton !== null)
            submitButton.addEventListener("click", (e: Event) => this.submit(e));

        this._core.leftnav(this);
        this.saveAccess();
    }

    back(e: Event) {
        let newPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }

    getCore() {
        return this._core;
    }

    saveAccess() {
        const formName = "catsSystemSummaryofNewProgramForm";
        let planFK = 0;
        let form = document.getElementById(formName);
        planFK = parseInt(form.dataset.parentplanfk);

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemAccess', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {

            }
        };
        xhr.send(`planFK=${planFK}`);
    }

    submit(e: Event) {
        let element = <HTMLButtonElement>e.target;
        if (!element.classList.contains("disabled")) {
            let planFK = 0;
            planFK = parseInt(element.dataset.planfk);
            let core = this._core;
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/CATSSystem/SubmitPlan', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "This report has been submitted for review!", 'success', 3000, window.location.reload());

                    setTimeout(function () { window.location.href = '/Reports/StateRequiredReports' }, 3000);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status + "'", 'error', 3000, null);
                }
            };
            xhr.send("planFK=" + planFK);
        }
    }
}