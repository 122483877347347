/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />

// Chapter 12 Profile
class Chapter12Profile {

    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["chapter12ProfileField"];

        let chapter12ProfileSaveButton = document.getElementById("chapter12ProfileSave");
        if (chapter12ProfileSaveButton !== null)
            chapter12ProfileSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];

        let planPK = 0;

        let profileFormElement = <HTMLDivElement>document.getElementById("chapter12ProfileForm");
        let inputs = document.getElementsByClassName("chapter12ProfileField");

        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Chapter12Report/SaveChapter12Profile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

// Student Services Assurances
class StudentServicesAssurance {

    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["studentServicesAssuranceField"];

        let studentServicesSaveButton = document.getElementById("chapter12StudentServicesAssuranceSave");
        if (studentServicesSaveButton !== null)
            studentServicesSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }

        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let checkboxes = document.getElementsByClassName("studentServicesAssuranceCheckbox");
        for (let checkbox of checkboxes)
            checkbox.addEventListener("click", (e: Event) => this.checkForReadonly(e));
    }

    checkForReadonly(e: Event) {
        let checkbox = <HTMLInputElement>e.srcElement;

        if (checkbox.readOnly) {
            if (checkbox.checked)
                checkbox.checked = false;
            else
                checkbox.checked = true;

            return false;
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];

        let planPK = 0;
        let formElement = document.getElementById("studentServicesAssuranceForm");
        planPK = parseInt(formElement.dataset.planfk);

        let inputs = document.getElementsByClassName("studentServicesAssuranceField");

        for (let ele of inputs) {
            let planPropertyPK = 0;

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            let value: string;
            if (element.classList.contains("studentServicesAssuranceNonCheckbox")) {
                value = element.value;
            }
            else {
                if (element.checked === true)
                    value = "on";
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Chapter12Report/SaveStudentServicesAssurance', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

// Assurance and Signature
class Chapter12AssuranceAndSignature {

    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["chapter12AssuranceAndSignatureField"];

        let chapter12AssuranceAndSignatureSaveButton = document.getElementById("chapter12AssuranceAndSignatureSave");
        if (chapter12AssuranceAndSignatureSaveButton !== null)
            chapter12AssuranceAndSignatureSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];

        let planPK = 0;

        let profileFormElement = <HTMLDivElement>document.getElementById("chapter12AssuranceAndSignatureForm");
        let inputs = document.getElementsByClassName("chapter12AssuranceAndSignatureField");

        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Chapter12Report/SaveAssuranceAndSignature', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

//Curriculum Summary Checklist and Submission
class Chapter12SummaryChecklistAndSubmission {

    private _core: Core;
    constructor() {
        this._core = new Core();
        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.back(e));

        let submitButton = document.getElementById("submitPlanButton");
        if (submitButton !== null)
            submitButton.addEventListener("click", (e: Event) => this.submit(e));

        this._core.leftnav(this);
    }

    back(e: Event) {
        let newPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;

        //Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }

        if (refreshPage && refreshPage !== "")
            window.location.href = refreshPage;
        else
            Core.hideLoader();
    }

    submit(e: Event) {
        let element = <HTMLButtonElement>e.srcElement;
        if (!element.classList.contains("disabled")) {
            let planFK = 0;
            planFK = parseInt(element.dataset.planfk);
            let core = this._core;
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/Chapter12Report/SubmitPlan', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "This report has been submitted for review!", 'success', 3000, window.location.reload);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status + "'", 'error', 3000, null);
                }
            };
            xhr.send("planFK=" + planFK);
        }
    }
}