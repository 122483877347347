/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />

// NinetyFivePercentLEAProfile
class NinetyFivePercentLEAProfile {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["ninetyFivePercentLEAProfileField"];

        const ninetyFivePercentLEAProfileSaveButton = document.getElementById("ninetyFivePercentLEAProfileSave");
        if (ninetyFivePercentLEAProfileSaveButton !== null)
            ninetyFivePercentLEAProfileSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        const fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        const hidden = this._core.createHash(this.validationClasses);
        const hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let refreshButton = document.getElementById("resetProfile");
        if (refreshButton !== null)
            refreshButton.addEventListener("click", (e: Event) => Core.refreshProfile("ninetyFivePercentLEAProfileForm"));

        let executiveSaveButton = document.getElementById("ExecutiveSave");
        if (executiveSaveButton !== null)
            executiveSaveButton.addEventListener("click", (e: Event) => this.executiveSave("save"));
    }

    createHash() {
        const hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        const allSaveElements = [];
        const core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        const formElement = document.getElementById("ninetyFivePercentLEAProfileForm");
        const planPK = parseInt(formElement.dataset.planfk);

        const textInputs = document.getElementsByClassName("ninetyFivePercentLEAProfileField");
        for (const ele of textInputs) {
            let planPropertyPK = 0;
            const element = <HTMLInputElement>ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                const saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/NinetyFivePercentAssessmentParticipation/SaveNinetyFivePercentLEAProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    executiveSave(referrer) {
        const allSaveElements = [];
        const core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        const formElement = document.getElementById("ninetyFivePercentLEAProfileForm");
        const planPK = parseInt(formElement.dataset.planfk);

        const textInputs = document.getElementsByClassName("profileICData");
        for (const ele of textInputs) {
            let planPropertyPK = 0;
            const element = <HTMLInputElement>ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                const saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/NinetyFivePercentAssessmentParticipation/SaveNinetyFivePercentLEAProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

// NinetyFivePercentComplianceDetails
class NinetyFivePercentComplianceDetails {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["ninetyFivePercentComplianceDetailsField"];

        const ninetyFivePercentComplianceDetailsSaveButton = document.getElementById("ninetyFivePercentComplianceDetailsSave");
        if (ninetyFivePercentComplianceDetailsSaveButton !== null)
            ninetyFivePercentComplianceDetailsSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        const fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        const hidden = this._core.createHash(this.validationClasses);
        const hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }

    createHash() {
        const hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        const allSaveElements = [];
        const core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        const formElement = document.getElementById("ninetyFivePercentComplianceDetailsForm");
        const planPK = parseInt(formElement.dataset.planfk);

        const textInputs = document.getElementsByClassName("ninetyFivePercentComplianceDetailsField");
        for (const ele of textInputs) {
            let planPropertyPK = 0;
            const element = <HTMLInputElement>ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                const saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/NinetyFivePercentAssessmentParticipation/SaveNinetyFivePercentComplianceDetails', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

// NinetyFivePercentSignatureAssurance
class NinetyFivePercentSignatureAssurance {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["ninetyFivePercentSignatureAssuranceField", "ninetyFivePercentSignatureAssuranceCheckboxField"];

        const ninetyFivePercentSignatureAssuranceSaveButton = document.getElementById("ninetyFivePercentSignatureAssuranceSave");
        if (ninetyFivePercentSignatureAssuranceSaveButton !== null)
            ninetyFivePercentSignatureAssuranceSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        const fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        const hidden = this._core.createHash(this.validationClasses);
        const hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }

    createHash() {
        const hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        const allSaveElements = [];
        const core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        const formElement = document.getElementById("ninetyFivePercentSignatureAssuranceForm");
        const planPK = parseInt(formElement.dataset.planfk);

        const textInputs = document.getElementsByClassName("ninetyFivePercentSignatureAssuranceField");
        for (const ele of textInputs) {
            let planPropertyPK = 0;
            const element = <HTMLInputElement>ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                const saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        const checkboxes = document.getElementsByClassName("ninetyFivePercentSignatureAssuranceCheckboxField");
        for (let ele of checkboxes) {
            let planPropertyPK = 0;

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            planPropertyPK = parseInt(element.dataset.planpropertypk);

            let val = "";
            if (element.checked)
                val = "on";
            else
                val = "off";

            let saveItem: IPlanProperty = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: planPK,
                PropertyFK: propertyPK,
                TextValue: val,
                LookupCodeFK: null,
                RowNbr: parseInt(rowNumber),
                IsDeletedInd: false
            };
            allSaveElements.push(saveItem);
        }

        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/NinetyFivePercentAssessmentParticipation/SaveNinetyFivePercentSignatureAssurance', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

// NinetyFivePercentChecklistSummary
class NinetyFivePercentChecklistSummary {
    private _core: Core;
    constructor() {
        this._core = new Core();
        const backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.back(e));

        const submitButton = document.getElementById("submitPlanButton");
        if (submitButton !== null)
            submitButton.addEventListener("click", (e: Event) => this.submit(e));

        this._core.leftnav(this);
    }

    back(e: Event) {
        const newPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }

    getCore() {
        return this._core;
    }

    submit(e: Event) {
        const element = <HTMLButtonElement>e.srcElement;
        if (!element.classList.contains("disabled")) {
            const planFK = parseInt(element.dataset.planfk);
            const core = this._core;
            Core.showLoader();
            const xhr = new XMLHttpRequest();
            xhr.open('POST', '/NinetyFivePercentAssessmentParticipation/SubmitPlan', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "This report has been submitted for review!", 'success', 3000, window.location.reload());

                    setTimeout(function () { window.location.href = '/Reports/StateRequiredReports' }, 3000);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status + "'", 'error', 3000, null);
                }
            };
            xhr.send("planFK=" + planFK);
        }
    }
}
