/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />
/// <reference path="controls/modal.ts" />

//Profile
class FlexibleInstructionalDaysProfile {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["flexibleInstructionalDaysProfileField"];

        let specialEducationProfileSaveButton = document.getElementById("flexibleInstructionDaysProfileSave");
        if (specialEducationProfileSaveButton !== null)
            specialEducationProfileSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        let educatorEffectivenessExecutiveSaveButton = document.getElementById("ExecutiveSave");
        if (educatorEffectivenessExecutiveSaveButton !== null)
            educatorEffectivenessExecutiveSaveButton.addEventListener("click", (e: Event) => this.executiveSave("save"));

        this._core.leftnav(this);

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let refreshButton = document.getElementById("resetProfile");
        if (refreshButton !== null)
            refreshButton.addEventListener("click", (e: Event) => Core.refreshProfile("flexibleInstructionDaysProfileForm"));
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];

        let planPK = 0;

        let profileFormElement = <HTMLDivElement>document.getElementById("flexibleInstructionDaysProfileForm");
        let inputs = document.getElementsByClassName("flexibleInstructionalDaysProfileField");

        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FlexibleInstructionalDays/SaveFlexibleInstructionalDaysProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    executiveSave(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];

        let planPK = 0;

        let profileFormElement = <HTMLDivElement>document.getElementById("flexibleInstructionDaysProfileForm");
        let inputs = document.getElementsByClassName("profileICDataSave");

        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FlexibleInstructionalDays/SaveFlexibleInstructionalDaysProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

// Narratives
class FlexibleInstructionalDaysNarratives {
    validationClasses: string[];
    private _core: Core;
    constructor() {
        this._core = new Core();

        this.noContingencyPlan();

        this.validationClasses = ["flexibleInstructionalDaysNarrativesField"];

        let specialEducatioNarrativesSaveButton = document.getElementById("flexibleInstructionDaysNarrativesSave");
        if (specialEducatioNarrativesSaveButton !== null)
            specialEducatioNarrativesSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        initializeAllNA();
        initializeAllYes();
        initializeAllNo();

        const noContingencyPlan = document.querySelector(".noContingencyPlan") as HTMLInputElement;
        if (noContingencyPlan !== null) {
            noContingencyPlan.addEventListener("change", (e: Event) => this.noContingencyPlan());

            if (noContingencyPlan.checked) {
                const reviewAccordions = document.querySelectorAll("[data-review-container] .Accordion");
                if(reviewAccordions.length > 4) {
                    for (let i = 4; i < reviewAccordions.length; i++) {
                        const reviewQuestions = reviewAccordions.item(i).querySelectorAll("[data-action-type='componentCompliantNA'][data-lookup-code='actionNA']");
                        for (let question of reviewQuestions) {
                            let compliantActionElement = <HTMLInputElement>question;
                            compliantActionElement.click();
                            compliantActionElement.disabled = true;
                        }
                    }
                }
            }
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];

        let planPK = 0;

        let profileFormElement = <HTMLDivElement>document.getElementById("flexibleInstructionDaysNarrativesForm");
        let inputs = document.getElementsByClassName("flexibleInstructionalDaysNarrativesField");

        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        const checkboxes = document.getElementsByClassName("flexibleInstructionalDaysNarrativesCheckboxField");
        for (let ele of checkboxes) {
            const element = <HTMLInputElement>ele;
            const propertyPK = parseInt(element.dataset.propertypk);
            const planPropertyPK = parseInt(element.dataset.planpropertypk);

            let val = "";
            if (element.checked)
                val = "on";
            else
                val = "off";

            const saveItem: IPlanProperty = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: planPK,
                PropertyFK: propertyPK,
                TextValue: val,
                LookupCodeFK: null,
                RowNbr: 0,
                IsDeletedInd: false
            };
            allSaveElements.push(saveItem);
        }

        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/FlexibleInstructionalDays/SaveFlexibleInstructionalDaysNarratives', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    noContingencyPlan() {
        const noContingencyPlan = <HTMLInputElement>document.querySelector(".noContingencyPlan");
        const allContingencyDivs = document.querySelectorAll(".noContingencyPlanDiv");
        const allContingencyTexts = document.querySelectorAll(".noContingencyPlanText");

        if (noContingencyPlan !== null) {
            if (noContingencyPlan.checked) {
                for (const div of allContingencyDivs) {
                    const element = <HTMLDivElement>div;
                    element.classList.add("hide");
                }

                for (const text of allContingencyTexts) {
                    const element = <HTMLTextAreaElement>text;
                    this._core.forceElementOptional(element);
                }
            } else {
                for (const div of allContingencyDivs) {
                    const element = <HTMLDivElement>div;
                    element.classList.remove("hide");
                }

                for (const text of allContingencyTexts) {
                    const element = <HTMLTextAreaElement>text;
                    this._core.forceElementRequired(element);
                }

            }
        }
    }
}

//Exemplars
class FlexibleInstructionalDaysExemplars {
    validationClasses: string[];
    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["flexibleInstructionalDaysExemplarField", "flexibleInstructionDaysExemplarsRadioField"];

        let specialEducatioNarrativesSaveButton = document.getElementById("flexibleInstructionDaysExemplarsSave");
        if (specialEducatioNarrativesSaveButton !== null)
            specialEducatioNarrativesSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);

        let showMessage = this.initializeFieldsCustom();
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses, showMessage);
        }

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let exemplarUploads = document.getElementsByClassName("flexibleInstructionDaysExemplarsFileField");
        for (let exemplarUpload of exemplarUploads)
            exemplarUpload.addEventListener("change", (e: Event) => this.uploadFile(<HTMLInputElement>e.target));

        let radios = document.getElementsByClassName("flexibleInstructionDaysExemplarsRadioExemplarsField");
        for (let radio of radios)
            radio.addEventListener("change", (e: Event) => this.changeExemplarRadio(e));

        let cteRadios = document.getElementsByClassName("flexibleInstructionDaysExemplarsRadioCTEField");
        for (let cteRadio of cteRadios)
            cteRadio.addEventListener("change", (e: Event) => this.changeCTERadio(e));

        let deleteFileConfirmButton = <HTMLButtonElement>document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e: Event) => this.deleteFileConfirm(deleteFileConfirmButton));

        let deleteFileCancelButton = <HTMLButtonElement>document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e: Event) => this.deleteFileCancel());

        let deleteFileButtons = document.getElementsByClassName("deleteFile") as HTMLCollectionOf<HTMLButtonElement>;
        for (let deleteButton of deleteFileButtons)
            deleteButton.addEventListener("click", (e: Event) => this.deleteFile(e));

        this.showFiles();
        this.handleRadios();

        initializeAllNA();
        initializeAllYes();
        initializeAllNo();
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let allFileElements = [];

        let planPK = 0;

        let profileFormElement = <HTMLDivElement>document.getElementById("flexibleInstructionDaysExemplarsForm");

        let inputs = document.getElementsByClassName("flexibleInstructionDaysExemplarsField");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let fakeFiles = document.getElementsByClassName("flexibleInstructionDaysExemplarsFileField"); 
        for (let ele of fakeFiles) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            let saveItem: IPlanProperty = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: planPK,
                PropertyFK: propertyPK,
                TextValue: "File",
                LookupCodeFK: null,
                RowNbr: parseInt(rowNumber),
                IsDeletedInd: false
            };

            allSaveElements.push(saveItem);
        }
                                                      
        let radios = document.getElementsByClassName("flexibleInstructionDaysExemplarsRadioField");
        for (let ele of radios) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = "0";
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                if (element.value !== "" || hadValue) {
                    let saveItem: IPlanProperty = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };

                    allSaveElements.push(saveItem);
                }
            }
        }

        let files = document.getElementsByClassName("flexibleInstructionDaysExemplarsFile");
        for (let ele of files) {
            planPK = parseInt(profileFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;

            let sequenceNbr = parseInt(element.dataset.sequencenbr);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk);
            let propertyPK = parseInt(element.dataset.propertypk);
            let planPropertyPlanPropertyPK = parseInt(element.dataset.planpropertyplanpropertyfilepk);
            let propertyCode = element.dataset.propertycode;
            let planPropertyPK = parseInt(element.dataset.planpropertypk);

            let saveItem: IExemplarFileSaveData = {
                PlanFK: planPK,
                PlanPropertyFilePK: planPropertyFilePK,
                SequenceNbr: sequenceNbr,
                PropertyPK: propertyPK,
                PlanPropertyPlanPropertyFilePK: planPropertyPlanPropertyPK,
                PropertyCode: propertyCode,
                PlanPropertyPK: planPropertyPK
            };

            allFileElements.push(saveItem);
        }

        let allSaveData = {
            "ElementData": allSaveElements,
            "FileData": allFileElements
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FlexibleInstructionalDays/SaveFlexibleInstructionalDaysExemplars', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        xhr.send(JSON.stringify(allSaveData));
    }

    showFiles() {
        let allContainers = document.getElementsByClassName("uploadedExemplarFileList");
        for (let container of allContainers) {
            let containerElement = <HTMLDivElement>container;
            let exemplar = containerElement.dataset.exemplar;

            let elements = containerElement.querySelectorAll(`.flexibleInstructionDaysExemplarsFile[data-exemplar='${exemplar}']`);
            if (elements.length > 0) {
                let containingContainer = <HTMLDivElement>document.querySelector(`.uploadedExemplarFiles[data-exemplar='${exemplar}']`);
                if (containingContainer.classList.contains("hide"))
                    containingContainer.classList.remove("hide");
            }
        }
    }

    uploadFile(e: HTMLInputElement) {
        let core = this._core;
        Core.showLoader();
        let formName = e.dataset.formname;
        let exemplar = e.dataset.exemplar;

        let uploadSampleForm = <HTMLFormElement>document.getElementById(formName);
        let formData = new FormData(uploadSampleForm);
        let propertyPK = e.dataset.propertypk;
        let propertyCode = e.id;
        let planPropertyPlanPropertyFilePK = e.dataset.planpropertyplanpropertyfilepk;
        let planPropertyPK = e.dataset.planpropertypk;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FlexibleInstructionalDays/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();

                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);

                        e.value = "";

                        let fileList = document.querySelector(`.uploadedExemplarFileList[data-exemplar='${exemplar}']`)

                        let fileWrapper = <HTMLDivElement>document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = <HTMLDivElement>document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = <HTMLAnchorElement>document.createElement("a");
                        fileA.dataset.planpropertyfilepk = res.payload.planPropertyFilePK;
                        fileA.dataset.planpropertyplanpropertyfilepk = "0";
                        fileA.dataset.planpropertypk = planPropertyPK;
                        fileA.dataset.propertycode = propertyCode;
                        fileA.dataset.sequencenbr = "0";
                        fileA.dataset.propertypk = propertyPK;
                        fileA.classList.add("flexibleInstructionDaysExemplarsFile");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);

                        fileList.appendChild(fileWrapper);

                        let fullContainer = document.querySelector(`.uploadedExemplarFiles[data-exemplar='${exemplar}']`);
                        if (fullContainer.classList.contains("hide"))
                            fullContainer.classList.remove("hide");
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }

    changeExemplarRadio(e: Event) {
        let radioElement = <HTMLInputElement>e.target;
        let exemplar = radioElement.dataset.exemplar;
        let lookupCode = radioElement.dataset.lookupcode;

        let exemplarContainer = <HTMLDivElement>document.querySelector(`.exemplars .exemplar[data-exemplar='${exemplar}']`);

        if (lookupCode === "no") {
            let inputs = exemplarContainer.querySelectorAll("input");
            for (let input of inputs) {
                let element = <HTMLInputElement>input;

                if (element.classList.contains("flexibleInstructionDaysExemplarsFileField")) {
                    element.disabled = true;
                    this._core.forceElementOptional(element);
                } else if (element.classList.contains("flexibleInstructionDaysExemplarsField")){
                    element.readOnly = true;
                    this._core.forceElementOptional(element);
                }
            }

        } else {
            let inputs = exemplarContainer.querySelectorAll("input");
            for (let input of inputs) {
                let element = <HTMLInputElement>input;

                if (element.classList.contains("flexibleInstructionDaysExemplarsFileField")) {
                    element.disabled = false;
                    this._core.forceElementRequired(element);
                } else if (element.classList.contains("flexibleInstructionDaysExemplarsField")) {
                    element.readOnly = false;
                    this._core.forceElementRequired(element);
                }
            }
        }
    }

    changeCTERadio(e: Event) {
        let radioElement = <HTMLInputElement>e.target;
        let lookupCode = radioElement.dataset.lookupcode;

        let exemplarContainer = document.getElementById("cteExemplars");

        if (lookupCode === "no") {
            exemplarContainer.classList.add("hide");

            let dateElementG = <HTMLInputElement>document.querySelector(`.flexibleInstructionDaysExemplarsField[data-exemplar='flexibleInstructionDaysExemplarsAppendixG']`);
            this._core.forceElementOptional(dateElementG);

            let fileElementG = <HTMLInputElement>document.querySelector(`.flexibleInstructionDaysExemplarsFileField[data-exemplar='flexibleInstructionDaysExemplarsAppendixG']`);
            this._core.forceElementOptional(fileElementG);

            let dateElementH = <HTMLInputElement>document.querySelector(`.flexibleInstructionDaysExemplarsField[data-exemplar='flexibleInstructionDaysExemplarsAppendixH']`);
            this._core.forceElementOptional(dateElementH);

            let fileElementH = <HTMLInputElement>document.querySelector(`.flexibleInstructionDaysExemplarsFileField[data-exemplar='flexibleInstructionDaysExemplarsAppendixH']`);
            this._core.forceElementOptional(fileElementH);
        } else {
            exemplarContainer.classList.remove("hide");

            let dateElementG = <HTMLInputElement>document.querySelector(`.flexibleInstructionDaysExemplarsField[data-exemplar='flexibleInstructionDaysExemplarsAppendixG']`);
            this._core.forceElementRequired(dateElementG);

            let fileElementG = <HTMLInputElement>document.querySelector(`.flexibleInstructionDaysExemplarsFileField[data-exemplar='flexibleInstructionDaysExemplarsAppendixG']`);
            this._core.forceElementRequired(fileElementG);

            let dateElementH = <HTMLInputElement>document.querySelector(`.flexibleInstructionDaysExemplarsField[data-exemplar='flexibleInstructionDaysExemplarsAppendixH']`);
            this._core.forceElementRequired(dateElementH);

            let fileElementH = <HTMLInputElement>document.querySelector(`.flexibleInstructionDaysExemplarsFileField[data-exemplar='flexibleInstructionDaysExemplarsAppendixH']`);
            this._core.forceElementRequired(fileElementH);
        }
    }

    handleRadios() {

        let allRadios = document.getElementsByClassName("flexibleInstructionDaysExemplarsRadioExemplarsField");

        for (let radio of allRadios) {
            let radioElement = <HTMLInputElement>radio;
            if (radioElement.checked) {
                let exemplar = radioElement.dataset.exemplar;
                let lookupCode = radioElement.dataset.lookupcode;

                let exemplarContainer = <HTMLDivElement>document.querySelector(`.exemplars .exemplar[data-exemplar='${exemplar}']`);

                if (lookupCode === "no") {
                    let inputs = exemplarContainer.querySelectorAll("input");
                    for (let input of inputs) {
                        let element = <HTMLInputElement>input;

                        if (element.classList.contains("flexibleInstructionDaysExemplarsFileField")) {
                            element.disabled = true;

                            this._core.forceElementOptional(element);
                        } else if (element.classList.contains("flexibleInstructionDaysExemplarsField")) {
                            element.readOnly = true;

                            this._core.forceElementOptional(element);
                        }
                    }

                } else {
                    let inputs = exemplarContainer.querySelectorAll("input");
                    for (let input of inputs) {
                        let element = <HTMLInputElement>input;

                        if (element.classList.contains("flexibleInstructionDaysExemplarsFileField")) {
                            element.disabled = false;

                            this._core.forceElementRequired(element);
                        } else if (element.classList.contains("flexibleInstructionDaysExemplarsField")) {
                            element.readOnly = false;

                            this._core.forceElementRequired(element);
                        }
                    }
                }
            }
        }

        let allCTERadios = document.getElementsByClassName("flexibleInstructionDaysExemplarsRadioCTEField");

        for (let radio of allCTERadios) {
            let radioElement = <HTMLInputElement>radio;
            if (radioElement.checked) {
                let lookupCode = radioElement.dataset.lookupcode;

                let exemplarContainer = document.getElementById("cteExemplars");

                if (lookupCode === "no") {
                    exemplarContainer.classList.add("hide");

                    let dateElementG = <HTMLInputElement>document.querySelector(`.flexibleInstructionDaysExemplarsField[data-exemplar='flexibleInstructionDaysExemplarsAppendixG']`);
                    this._core.forceElementOptional(dateElementG);

                    let fileElementG = <HTMLInputElement>document.querySelector(`.flexibleInstructionDaysExemplarsFileField[data-exemplar='flexibleInstructionDaysExemplarsAppendixG']`);
                    this._core.forceElementOptional(fileElementG);

                    let dateElementH = <HTMLInputElement>document.querySelector(`.flexibleInstructionDaysExemplarsField[data-exemplar='flexibleInstructionDaysExemplarsAppendixH']`);
                    this._core.forceElementOptional(dateElementH);

                    let fileElementH = <HTMLInputElement>document.querySelector(`.flexibleInstructionDaysExemplarsFileField[data-exemplar='flexibleInstructionDaysExemplarsAppendixH']`);
                    this._core.forceElementOptional(fileElementH);
                } else {
                    exemplarContainer.classList.remove("hide");

                    let dateElementG = <HTMLInputElement>document.querySelector(`.flexibleInstructionDaysExemplarsField[data-exemplar='flexibleInstructionDaysExemplarsAppendixG']`);
                    this._core.forceElementRequired(dateElementG);

                    let fileElementG = <HTMLInputElement>document.querySelector(`.flexibleInstructionDaysExemplarsFileField[data-exemplar='flexibleInstructionDaysExemplarsAppendixG']`);
                    this._core.forceElementRequired(fileElementG);

                    let dateElementH = <HTMLInputElement>document.querySelector(`.flexibleInstructionDaysExemplarsField[data-exemplar='flexibleInstructionDaysExemplarsAppendixH']`);
                    this._core.forceElementRequired(dateElementH);

                    let fileElementH = <HTMLInputElement>document.querySelector(`.flexibleInstructionDaysExemplarsFileField[data-exemplar='flexibleInstructionDaysExemplarsAppendixH']`);
                    this._core.forceElementRequired(fileElementH);
                }
            }
        }
    }

    deleteFile(e: Event) {
        let fileButton = <HTMLButtonElement>e.target;
        let planPropertyFilePK = fileButton.dataset.planpropertyfilepk;

        if (planPropertyFilePK && parseInt(planPropertyFilePK) > 0) {
            let modal: Modal = new Modal("deleteFileModal", null);
            let deleteConfirmButton = <HTMLButtonElement>document.getElementById("deleteFileConfirm");
            deleteConfirmButton.dataset.planpropertyfilepk = planPropertyFilePK;
            modal.show();
        }
    }

    deleteFileConfirm(confirmButton: HTMLButtonElement) {
        let core = this._core;
        Core.showLoader();

        let planPropertyFilePK = confirmButton.dataset.planpropertyfilepk;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FlexibleInstructionalDays/DeleteFiles', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file has been successfully deleted.", 'success', 3000, null);

                        let containerElement = <HTMLElement>document.querySelector(`.flexibleInstructionDaysExemplarsFile[data-planpropertyfilepk='${planPropertyFilePK}']`);
                        if (containerElement !== null) {
                            containerElement.parentNode.removeChild(containerElement);
                        }

                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting file.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file removal failed. Please try again.", 'error', 3000, null);
            }

            let modal: Modal = new Modal("deleteFileModal", null);
            modal.hide();
        };
        xhr.send(JSON.stringify([planPropertyFilePK]));
    }

    deleteFileCancel() {
        let modal: Modal = new Modal("deleteFileModal", null);
        modal.hide();
    }

    initializeFieldsCustom() {
        let showMessage: boolean = false;

        let coreRadios = document.getElementsByClassName("flexibleInstructionDaysExemplarsRadioExemplarsField");
        for (let coreRadio of coreRadios) {
            let element = <HTMLInputElement>coreRadio;

            if (element.checked) {
                let label = element.dataset.lookuplabel;
                if (label.toLowerCase() === "yes".toLowerCase()) {
                    let labelExemplar = element.dataset.exemplar;

                    let dateElement = <HTMLInputElement>document.querySelector(`.flexibleInstructionDaysExemplarsField[data-exemplar='${labelExemplar}']`);
                    if (dateElement.value === "") {
                        showMessage = true;

                        this._core.forceElementRequired(dateElement);
                    }

                    let fileElements = document.querySelectorAll(`.flexibleInstructionDaysExemplarsFile[data-exemplar='${labelExemplar}']`);
                    if (fileElements.length < 0) {
                        let uploadElement = <HTMLInputElement>document.querySelector(`.flexibleInstructionDaysExemplarsFileField[data-exemplar='${labelExemplar}']`);
                        this._core.forceElementRequired(uploadElement);
                    }
                } else {
                    let labelExemplar = element.dataset.exemplar;

                    let dateElement = <HTMLInputElement>document.querySelector(`.flexibleInstructionDaysExemplarsField[data-exemplar='${labelExemplar}']`);
                    if (dateElement.value === "") {
                        this._core.forceElementOptional(dateElement);
                    }

                    let fileElements = document.querySelectorAll(`.flexibleInstructionDaysExemplarsFile[data-exemplar='${labelExemplar}']`);
                    if (fileElements.length < 0) {
                        let uploadElement = <HTMLInputElement>document.querySelector(`.flexibleInstructionDaysExemplarsFileField[data-exemplar='${labelExemplar}']`);
                        this._core.forceElementOptional(uploadElement);
                    }
                }
            }
        }

        let cteRadios = document.getElementsByClassName("flexibleInstructionDaysExemplarsRadioCTEField");
        for (let cteRadio of cteRadios) {
            let element = <HTMLInputElement>cteRadio;

            if (element.checked) {
                let label = element.dataset.lookuplabel;
                if (label.toLowerCase() === "yes".toLowerCase()) {
                    let dateElementG = <HTMLInputElement>document.querySelector(`.flexibleInstructionDaysExemplarsField[data-exemplar='flexibleInstructionDaysExemplarsAppendixH']`);
                    if (dateElementG.value === "") {
                        showMessage = true;
                        this._core.forceElementRequired(dateElementG);
                    }

                    let fileElementsG = document.querySelectorAll(`.flexibleInstructionDaysExemplarsFile[data-exemplar='$flexibleInstructionDaysExemplarsAppendixH']`);
                    if (fileElementsG.length < 0) {
                        showMessage = true;
                        let uploadElementG = <HTMLInputElement>document.querySelector(`.flexibleInstructionDaysExemplarsFileField[data-exemplar='flexibleInstructionDaysExemplarsAppendixH']`);
                        this._core.forceElementRequired(uploadElementG);
                    }

                    let dateElementH = <HTMLInputElement>document.querySelector(`.flexibleInstructionDaysExemplarsField[data-exemplar='flexibleInstructionDaysExemplarsAppendixH']`);
                    if (dateElementH.value === "") {
                        showMessage = true;
                        this._core.forceElementRequired(dateElementH);
                    }

                    let fileElementsH = document.querySelectorAll(`.flexibleInstructionDaysExemplarsFile[data-exemplar='flexibleInstructionDaysExemplarsAppendixH']`);
                    if (fileElementsH.length < 0) {
                        showMessage = true;
                        let uploadElementH = <HTMLInputElement>document.querySelector(`.flexibleInstructionDaysExemplarsFileField[data-exemplar='flexibleInstructionDaysExemplarsAppendixH']`);
                        this._core.forceElementRequired(uploadElementH);
                    }
                }
            }
        }

        let formattedAllClasses = [];
        formattedAllClasses.push(".flexibleInstructionDaysExemplarsField");
        formattedAllClasses.push(".flexibleInstructionDaysExemplarsRadioCTEField");
        formattedAllClasses.push(".flexibleInstructionDaysExemplarsRadioExemplarsField");
        formattedAllClasses.push(".flexibleInstructionDaysExemplarsFileField");

        let classesToValidate = formattedAllClasses.join(",");

        let allElements = document.querySelectorAll(classesToValidate);

        for (let element of allElements) {
            let htmlElement = <HTMLElement>element;

            if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {

                if (!(htmlElement.hasAttribute("aria-required") && htmlElement.getAttribute("aria-required") === "true")) {
                    htmlElement.setAttribute("aria-required", "true");
                    htmlElement.setAttribute("force-required", "true");
                }
            }

            //Manual override when data-forcerequired=true
            if ("forcerequired" in htmlElement.dataset && htmlElement.dataset.forcerequired === "true") {

                htmlElement.dataset.percent = "1.00";

                if (!(htmlElement.hasAttribute("aria-required") && htmlElement.getAttribute("aria-required") === "true")) {
                    htmlElement.setAttribute("aria-required", "true");
                }
            }
        }

        let totalErrors = 0;

        for (let element of allElements) {
            let htmlElement = <HTMLElement>element;

            if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {
                if (element instanceof HTMLInputElement) {
                    let inputElement = <HTMLInputElement>element;

                    if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "file") {
                        if ("hasuploaded" in inputElement.dataset && inputElement.dataset.hasuploaded != "true") {
                            inputElement.classList.add("missing-field");
                            inputElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(inputElement);
                            showMessage = true;
                            totalErrors++;
                        }
                    } else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "text" && inputElement.value === "") {
                        inputElement.classList.add("missing-field");
                        inputElement.setAttribute("aria-invalid", "true");
                        Core.createErrorLabelForInput(inputElement);
                        showMessage = true;
                        totalErrors++;
                    } else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "date" && inputElement.value === "") {
                        inputElement.classList.add("missing-field");
                        inputElement.setAttribute("aria-invalid", "true");
                        Core.createErrorLabelForInput(inputElement);
                        showMessage = true;
                        totalErrors++;
                    }
                } 
            }
        }

        let message = <HTMLDivElement>document.getElementById("validationMessage");

        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        } else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");

        if (goToError !== null) {

            let that = this;

            let firstFocusableEl = <HTMLElement>document.querySelector(".missing-field");

            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");

                        let accordionElement = <HTMLButtonElement>document.getElementById(id);
                        accordionElement.click();
                    }

                    firstFocusableEl.focus();
                });
            } else {
                goToError.parentNode.removeChild(goToError);
            }
        }

        return showMessage;
    }
}

//Signatures & Assurances
class FlexibleInstructionalDaysSignaturesAndAssurances {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["flexibleInstructionDaysSignaturesAndAssurancesField", "flexibleInstructionDaysSignaturesAndAssurancesNewFileField", "flexibleInstructionDaysSignaturesAndAssurancesCheckboxField"];

        let specialEducationProfileSaveButton = document.getElementById("flexibleInstructionDaysSignaturesAndAssurancesSave");
        if (specialEducationProfileSaveButton !== null)
            specialEducationProfileSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let boardMinutesUploads = document.getElementsByClassName("flexibleInstructionDaysFileUploadField");
        for (let boardMinute of boardMinutesUploads)
            boardMinute.addEventListener("change", (e: Event) => this.uploadFile(<HTMLInputElement>e.target));

        let deleteFileConfirmButton = <HTMLButtonElement>document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e: Event) => this.deleteFileConfirm(deleteFileConfirmButton));

        let deleteFileCancelButton = <HTMLButtonElement>document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e: Event) => this.deleteFileCancel());

        let deleteFileButtons = document.getElementsByClassName("deleteFile") as HTMLCollectionOf<HTMLButtonElement>;
        for (let deleteButton of deleteFileButtons)
            deleteButton.addEventListener("click", (e: Event) => this.deleteFile(e));
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];

        let planPK = 0;

        let profileFormElement = <HTMLDivElement>document.getElementById("flexibleInstructionDaysSignaturesAndAssurancesForm");

        let inputs = document.getElementsByClassName("flexibleInstructionDaysField");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let checkboxes = document.getElementsByClassName("flexibleInstructionDaysCheckboxField");
        for (let ele of checkboxes) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.checked || hadValue) {
                let val = "";
                if (element.checked)
                    val = "on";
                else
                    val = "off";

                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FlexibleInstructionalDays/SaveFlexibleInstructionalDaysSignaturesAndAssurances', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    deleteFile(e: Event) {
        let fileButton = <HTMLButtonElement>e.target;
        let planPropertyFilePK = fileButton.dataset.planpropertyfilepk;

        if (planPropertyFilePK && parseInt(planPropertyFilePK) > 0) {
            let modal: Modal = new Modal("deleteFileModal", null);
            let deleteConfirmButton = <HTMLButtonElement>document.getElementById("deleteFileConfirm");
            deleteConfirmButton.dataset.planpropertyfilepk = planPropertyFilePK;
            modal.show();
        }
    }

    deleteFileConfirm(confirmButton: HTMLButtonElement) {
        let core = this._core;
        Core.showLoader();

        let planPropertyFilePK = confirmButton.dataset.planpropertyfilepk;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FlexibleInstructionalDays/DeleteFiles', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file has been successfully deleted.", 'success', 3000, null);

                        let containerElement = <HTMLElement>document.querySelector(`.uploadFileColumn[data-planpropertyfilepk='${planPropertyFilePK}']`);
                        if (containerElement !== null) {
                            containerElement.parentNode.removeChild(containerElement);
                        }

                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting file.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file removal failed. Please try again.", 'error', 3000, null);
            }

            let modal: Modal = new Modal("deleteFileModal", null);
            modal.hide();
        };
        xhr.send(JSON.stringify([planPropertyFilePK]));
    }

    deleteFileCancel() {
        let modal: Modal = new Modal("deleteFileModal", null);
        modal.hide();
    }

    uploadFile(e: HTMLInputElement) {
        let core = this._core;
        Core.showLoader();

        let uploadSampleForm = <HTMLFormElement>document.getElementById("flexibleInstructionDaysSignaturesBoardMinutesUploadForm");
        let formData = new FormData(uploadSampleForm);

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FlexibleInstructionalDays/UploadFile', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file has been successfully uploaded! Save your progress to see the updated list of files.", 'success', 3000, null);
                        let formFile = <HTMLInputElement>document.getElementById("flexibleInstructionDaysSignaturesBoardMinutesUpload");
                        formFile.value = "";

                        let uploadedFilesElement = document.getElementById("uploadedFiles");
                        uploadedFilesElement.classList.remove("hide");
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
}

//Summary and Submission
class FlexibleInstructionalDaysSummaryChecklistAndSubmission {
    private _core: Core;
    constructor() {
        this._core = new Core();
        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.back(e));

        let submitButton = document.getElementById("submitPlanButton");
        if (submitButton !== null)
            submitButton.addEventListener("click", (e: Event) => this.submit(e));

        let withdrawPlanButton = document.getElementById("withdrawPlanButton");
        if (withdrawPlanButton !== null)
            withdrawPlanButton.addEventListener("click", (e: Event) => this.withdrawPlan(e));

        let withdrawPlanConfirmButton = <HTMLButtonElement>document.getElementById("withdrawPlanConfirm");
        if (withdrawPlanConfirmButton !== null)
            withdrawPlanConfirmButton.addEventListener("click", (e: Event) => this.withdrawPlanConfirm(e));

        let withdrawPlanCancelButton = <HTMLButtonElement>document.getElementById("withdrawPlanCancel");
        if (withdrawPlanCancelButton !== null)
            withdrawPlanCancelButton.addEventListener("click", (e: Event) => this.withdrawPlanCancel());

        this._core.leftnav(this);
    }

    back(e: Event) {
        let newPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }

    getCore() {
        return this._core;
    }

    submit(e: Event) {
        let element = <HTMLButtonElement>e.target;
        if (!element.classList.contains("disabled")) {
            let planFK = 0;
            planFK = parseInt(element.dataset.planfk);
            let core = this._core;
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/FlexibleInstructionalDays/SubmitPlan', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "This report has been submitted for review!", 'success', 3000, window.location.reload());

                    setTimeout(function () { window.location.href = '/Reports/StateRequiredReports' }, 3000);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "An unexpected error occurred while submitting report. Please try again later.", 'error', 3000, null);
                }
            };
            xhr.send("planFK=" + planFK);
        }
    }

    withdrawPlan(e: Event) {
        let fileButton = <HTMLButtonElement>e.target;
        let planFK = fileButton.dataset.planfk;

        let modal: Modal = new Modal("withdrawPlanModal", null);
        modal.addAttributeToElement("withdrawPlanModal", "#withdrawPlanConfirm", "planfk", planFK);

        modal.show();
    }

    withdrawPlanConfirm(e: Event) {
        let confirmButton = <HTMLButtonElement>e.target;
        let core = this._core;
        Core.showLoader();

        let planFK = confirmButton.dataset.planfk;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FlexibleInstructionalDays/WithdrawPlan', true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The plan has been withdrawn.", 'success', 3000, null);

                        //back to report page
                        setTimeout(function () { window.location.href = "/Reports/StateRequiredReports"; }, 3000);

                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "There was an unexpected withdrawing this plan.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file removal failed. Please try again.", 'error', 3000, null);
            }

            let modal: Modal = new Modal("withdrawPlanModal", null);
            modal.hide();
        };
        xhr.send("planFK=" + planFK);
    }

    withdrawPlanCancel() {
        let modal: Modal = new Modal("withdrawPlanModal", null);
        modal.hide();
    }
}

// FlexibleInstructionDaysExELAK8
class FlexibleInstructionDaysExELAK8 {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["flexibleInstructionDaysExField", "flexibleInstructionDaysExRadioField"];

        let flexibleInstructionDaysExELAK8SaveButton = document.getElementById("flexibleInstructionDaysExELAK8Save");
        if (flexibleInstructionDaysExELAK8SaveButton !== null)
            flexibleInstructionDaysExELAK8SaveButton.addEventListener("click", (e: Event) => this.save("save"));

        const radios = document.getElementsByClassName("flexibleInstructionDaysExRadioField");
        for (const radio of radios) {
            radio.addEventListener("change", (e: Event) => this.checkForYes(<HTMLInputElement>e.target));
            this.checkForYes(<HTMLInputElement>radio);
        }

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        const flexibleInstructionalDaysELAK8AddStandardButton = document.getElementById("flexibleInstructionalDaysELAK8AddStandard");
        if (flexibleInstructionalDaysELAK8AddStandardButton !== null)
            flexibleInstructionalDaysELAK8AddStandardButton.addEventListener("click", (e: Event) => this.addStandardRow(e));

        this.bindDeletes();

        const flexibleInstructionalDaysELAK8StandardRowDeleteCancelButton = document.getElementById("flexibleExemplarsStandardRowDeleteCancel");
        if (flexibleInstructionalDaysELAK8StandardRowDeleteCancelButton !== null)
            flexibleInstructionalDaysELAK8StandardRowDeleteCancelButton.addEventListener("click", (e: Event) => this.deleteRowCancel());

        const flexibleInstructionalDaysELAK8StandardRowDeleteConfirmButton = document.getElementById("flexibleExemplarsStandardRowDeleteConfirm");
        if (flexibleInstructionalDaysELAK8StandardRowDeleteConfirmButton !== null)
            flexibleInstructionalDaysELAK8StandardRowDeleteConfirmButton.addEventListener("click", (e: Event) => this.deleteRowConfirm(e));

        initializeAllNA();
        initializeAllYes();
        initializeAllNo();
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("flexibleInstructionDaysExELAK8Form");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("flexibleInstructionDaysExField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let radios = document.getElementsByClassName("flexibleInstructionDaysExRadioField");
        for (let ele of radios) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = "0";
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                if (element.value !== "" || hadValue) {
                    let saveItem: IPlanProperty = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };

                    allSaveElements.push(saveItem);
                }
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FlexibleInstructionalDays/SaveFlexibleInstructionDaysExELAK8', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    bindDeletes() {
        const allDeleteRows = document.getElementsByClassName("deleteFlexibleInstructionExemplarStandardRow");
        for (const deleteRow of allDeleteRows)
            deleteRow.addEventListener("click", (e: Event) => this.showDeleteStandardRow(e));
    }

    showDeleteStandardRow(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let row = buttonElement.dataset.row;

        let modal: Modal = new Modal("deleteStandardRowModal", null);
        modal.addAttributeToElement("deleteStandardRowModal", "#flexibleExemplarsStandardRowDeleteConfirm", "row", row);
        modal.show();
    }

    deleteRowConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let planProps = [];
        let row = buttonElement.dataset.row;

        let allElements = document.querySelectorAll(`.flexibleInstructionDaysExField[data-row='${row}']`);
        for (let ele of allElements) {
            let element = <HTMLElement>ele;
            let planProp = element.dataset.planpropertypk;

            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FlexibleInstructionalDays/DeleteRowData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();

                let rowElement = document.querySelector(`#flexibleExemplarsStandards tbody tr[data-row='${row}']`);
                rowElement.remove();

                let modal: Modal = new Modal("deleteStandardRowModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The row data has been removed.", 'success', 3000, null);
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }

    deleteRowCancel() {
        let modal: Modal = new Modal("deleteStandardRowModal", null);
        modal.hide();
    }

    async addStandardRow(e) {
        Core.showLoader();
        let numberOfRows = 0;
        let formElement = <HTMLDivElement>document.getElementById("flexibleInstructionDaysExELAK8Form");

        let planFK = formElement.dataset.planfk;
        let that = this;
        let newRow = 0;

        let allRows = document.querySelectorAll("#flexibleExemplarsStandards tbody tr");
        for (let row of allRows) {
            let rowEle = <HTMLTableRowElement>row;
            let thisRow = parseInt(rowEle.dataset.row);

            if (thisRow > newRow)
                newRow = thisRow;
        }

        newRow++;

        const button = <HTMLButtonElement>e.target;
        const pageCode = button.dataset.pagecode;

        let response = await fetch(`/FlexibleInstructionalDays/AddStandard/${planFK}/${pageCode}/ELAK8/${newRow}`, { credentials: 'include' })
        if (response.ok) {

            const output = await response.text();

            let newTR = <HTMLTableRowElement>document.createElement("tr");
            newTR.dataset.row = newRow.toString();

            newTR.innerHTML = output;

            const container = document.querySelector("#flexibleExemplarsStandards tbody");
            container.appendChild(newTR);

            that.bindDeletes();

            Core.hideLoader();
            Core.createHTMLAlert("alertMessageDiv", "The PA Academic Standard has been added!", 'success', 2000, null);
        } else {
            Core.hideLoader();
            Core.createHTMLAlert("alertMessageDiv", "There was an issue adding a new row. Please try again.", 'error', 2000, null);
        }
    }

    checkForYes(e: HTMLInputElement) {
        const radio = e;

        if (radio.checked) {
            const value = radio.dataset.lookupcode;

            const exemplarInformationDiv = <HTMLDivElement>document.getElementById("exemplarInformation");
            if (value === "yes") {
                exemplarInformationDiv.classList.remove("hide");

                const allElements = document.getElementsByClassName("flexibleInstructionDaysExField");
                for (const ele of allElements) {
                    let thisEle = <HTMLElement>ele;
                    this._core.forceElementRequired(thisEle);
                }
            } else {
                exemplarInformationDiv.classList.add("hide");

                const allElements = document.getElementsByClassName("flexibleInstructionDaysExField");
                for (const ele of allElements) {
                    let thisEle = <HTMLElement>ele;
                    this._core.forceElementOptional(thisEle);
                }
            }
        }
    }
}

// FlexibleInstructionDaysExELA912
class FlexibleInstructionDaysExELA912 {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["flexibleInstructionDaysExField", "flexibleInstructionDaysExRadioField"];

        let flexibleInstructionDaysExELA912SaveButton = document.getElementById("flexibleInstructionDaysExELA912Save");
        if (flexibleInstructionDaysExELA912SaveButton !== null)
            flexibleInstructionDaysExELA912SaveButton.addEventListener("click", (e: Event) => this.save("save"));

        const radios = document.getElementsByClassName("flexibleInstructionDaysExRadioField");
        for (const radio of radios) {
            radio.addEventListener("change", (e: Event) => this.checkForYes(<HTMLInputElement>e.target));
            this.checkForYes(<HTMLInputElement>radio);
        }

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        const flexibleInstructionalDaysELAK8AddStandardButton = document.getElementById("flexibleInstructionalDaysELA912AddStandard");
        if (flexibleInstructionalDaysELAK8AddStandardButton !== null)
            flexibleInstructionalDaysELAK8AddStandardButton.addEventListener("click", (e: Event) => this.addStandardRow(e));

        this.bindDeletes();

        const flexibleInstructionalDaysELAK8StandardRowDeleteCancelButton = document.getElementById("flexibleExemplarsStandardRowDeleteCancel");
        if (flexibleInstructionalDaysELAK8StandardRowDeleteCancelButton !== null)
            flexibleInstructionalDaysELAK8StandardRowDeleteCancelButton.addEventListener("click", (e: Event) => this.deleteRowCancel());

        const flexibleInstructionalDaysELAK8StandardRowDeleteConfirmButton = document.getElementById("flexibleExemplarsStandardRowDeleteConfirm");
        if (flexibleInstructionalDaysELAK8StandardRowDeleteConfirmButton !== null)
            flexibleInstructionalDaysELAK8StandardRowDeleteConfirmButton.addEventListener("click", (e: Event) => this.deleteRowConfirm(e));

        initializeAllNA();
        initializeAllYes();
        initializeAllNo();
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("flexibleInstructionDaysExELA912Form");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("flexibleInstructionDaysExField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let radios = document.getElementsByClassName("flexibleInstructionDaysExRadioField");
        for (let ele of radios) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = "0";
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                if (element.value !== "" || hadValue) {
                    let saveItem: IPlanProperty = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };

                    allSaveElements.push(saveItem);
                }
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FlexibleInstructionalDays/SaveFlexibleInstructionDaysExELA912', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    bindDeletes() {
        const allDeleteRows = document.getElementsByClassName("deleteFlexibleInstructionExemplarStandardRow");
        for (const deleteRow of allDeleteRows)
            deleteRow.addEventListener("click", (e: Event) => this.showDeleteStandardRow(e));
    }

    showDeleteStandardRow(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let row = buttonElement.dataset.row;

        let modal: Modal = new Modal("deleteStandardRowModal", null);
        modal.addAttributeToElement("deleteStandardRowModal", "#flexibleExemplarsStandardRowDeleteConfirm", "row", row);
        modal.show();
    }

    deleteRowConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let planProps = [];
        let row = buttonElement.dataset.row;

        let allElements = document.querySelectorAll(`.flexibleInstructionDaysExField[data-row='${row}']`);
        for (let ele of allElements) {
            let element = <HTMLElement>ele;
            let planProp = element.dataset.planpropertypk;

            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FlexibleInstructionalDays/DeleteRowData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();

                let rowElement = document.querySelector(`#flexibleExemplarsStandards tbody tr[data-row='${row}']`);
                rowElement.remove();

                let modal: Modal = new Modal("deleteStandardRowModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The row data has been removed.", 'success', 3000, null);
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }

    deleteRowCancel() {
        let modal: Modal = new Modal("deleteStandardRowModal", null);
        modal.hide();
    }

    async addStandardRow(e) {
        Core.showLoader();
        let numberOfRows = 0;
        let formElement = <HTMLDivElement>document.getElementById("flexibleInstructionDaysExELA912Form");

        let planFK = formElement.dataset.planfk;
        let that = this;
        let newRow = 0;

        let allRows = document.querySelectorAll("#flexibleExemplarsStandards tbody tr");
        for (let row of allRows) {
            let rowEle = <HTMLTableRowElement>row;
            let thisRow = parseInt(rowEle.dataset.row);

            if (thisRow > newRow)
                newRow = thisRow;
        }

        newRow++;

        const button = <HTMLButtonElement>e.target;
        const pageCode = button.dataset.pagecode;

        let response = await fetch(`/FlexibleInstructionalDays/AddStandard/${planFK}/${pageCode}/ELA912/${newRow}`, { credentials: 'include' })
        if (response.ok) {

            const output = await response.text();

            let newTR = <HTMLTableRowElement>document.createElement("tr");
            newTR.dataset.row = newRow.toString();

            newTR.innerHTML = output;

            const container = document.querySelector("#flexibleExemplarsStandards tbody");
            container.appendChild(newTR);

            that.bindDeletes();

            Core.hideLoader();
            Core.createHTMLAlert("alertMessageDiv", "The PA Academic Standard has been added!", 'success', 2000, null);
        } else {
            Core.hideLoader();
            Core.createHTMLAlert("alertMessageDiv", "There was an issue adding a new row. Please try again.", 'error', 2000, null);
        }
    }

    checkForYes(e: HTMLInputElement) {
        const radio = e;

        if (radio.checked) {
            const value = radio.dataset.lookupcode;

            const exemplarInformationDiv = <HTMLDivElement>document.getElementById("exemplarInformation");
            if (value === "yes") {
                exemplarInformationDiv.classList.remove("hide");

                const allElements = document.getElementsByClassName("flexibleInstructionDaysExField");
                for (const ele of allElements) {
                    let thisEle = <HTMLElement>ele;
                    this._core.forceElementRequired(thisEle);
                }
            } else {
                exemplarInformationDiv.classList.add("hide");

                const allElements = document.getElementsByClassName("flexibleInstructionDaysExField");
                for (const ele of allElements) {
                    let thisEle = <HTMLElement>ele;
                    this._core.forceElementOptional(thisEle);
                }
            }
        }
    }
}

// FlexibleInstructionDaysExMathK8
class FlexibleInstructionDaysExMathK8 {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["flexibleInstructionDaysExField", "flexibleInstructionDaysExRadioField"];

        let flexibleInstructionDaysExMathK8SaveButton = document.getElementById("flexibleInstructionDaysExMathK8Save");
        if (flexibleInstructionDaysExMathK8SaveButton !== null)
            flexibleInstructionDaysExMathK8SaveButton.addEventListener("click", (e: Event) => this.save("save"));

        const radios = document.getElementsByClassName("flexibleInstructionDaysExRadioField");
        for (const radio of radios) {
            radio.addEventListener("change", (e: Event) => this.checkForYes(<HTMLInputElement>e.target));
            this.checkForYes(<HTMLInputElement>radio);
        }

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        const flexibleInstructionalDaysELAK8AddStandardButton = document.getElementById("flexibleInstructionalDaysMathK8AddStandard");
        if (flexibleInstructionalDaysELAK8AddStandardButton !== null)
            flexibleInstructionalDaysELAK8AddStandardButton.addEventListener("click", (e: Event) => this.addStandardRow(e));

        this.bindDeletes();

        const flexibleInstructionalDaysELAK8StandardRowDeleteCancelButton = document.getElementById("flexibleExemplarsStandardRowDeleteCancel");
        if (flexibleInstructionalDaysELAK8StandardRowDeleteCancelButton !== null)
            flexibleInstructionalDaysELAK8StandardRowDeleteCancelButton.addEventListener("click", (e: Event) => this.deleteRowCancel());

        const flexibleInstructionalDaysELAK8StandardRowDeleteConfirmButton = document.getElementById("flexibleExemplarsStandardRowDeleteConfirm");
        if (flexibleInstructionalDaysELAK8StandardRowDeleteConfirmButton !== null)
            flexibleInstructionalDaysELAK8StandardRowDeleteConfirmButton.addEventListener("click", (e: Event) => this.deleteRowConfirm(e));

        initializeAllNA();
        initializeAllYes();
        initializeAllNo();
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("flexibleInstructionDaysExMathK8Form");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("flexibleInstructionDaysExField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let radios = document.getElementsByClassName("flexibleInstructionDaysExRadioField");
        for (let ele of radios) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = "0";
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                if (element.value !== "" || hadValue) {
                    let saveItem: IPlanProperty = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };

                    allSaveElements.push(saveItem);
                }
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FlexibleInstructionalDays/SaveFlexibleInstructionDaysExMathK8', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    bindDeletes() {
        const allDeleteRows = document.getElementsByClassName("deleteFlexibleInstructionExemplarStandardRow");
        for (const deleteRow of allDeleteRows)
            deleteRow.addEventListener("click", (e: Event) => this.showDeleteStandardRow(e));
    }

    showDeleteStandardRow(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let row = buttonElement.dataset.row;

        let modal: Modal = new Modal("deleteStandardRowModal", null);
        modal.addAttributeToElement("deleteStandardRowModal", "#flexibleExemplarsStandardRowDeleteConfirm", "row", row);
        modal.show();
    }

    deleteRowConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let planProps = [];
        let row = buttonElement.dataset.row;

        let allElements = document.querySelectorAll(`.flexibleInstructionDaysExField[data-row='${row}']`);
        for (let ele of allElements) {
            let element = <HTMLElement>ele;
            let planProp = element.dataset.planpropertypk;

            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FlexibleInstructionalDays/DeleteRowData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();

                let rowElement = document.querySelector(`#flexibleExemplarsStandards tbody tr[data-row='${row}']`);
                rowElement.remove();

                let modal: Modal = new Modal("deleteStandardRowModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The row data has been removed.", 'success', 3000, null);
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }

    deleteRowCancel() {
        let modal: Modal = new Modal("deleteStandardRowModal", null);
        modal.hide();
    }

    async addStandardRow(e) {
        Core.showLoader();
        let numberOfRows = 0;
        let formElement = <HTMLDivElement>document.getElementById("flexibleInstructionDaysExMathK8Form");

        let planFK = formElement.dataset.planfk;
        let that = this;
        let newRow = 0;

        let allRows = document.querySelectorAll("#flexibleExemplarsStandards tbody tr");
        for (let row of allRows) {
            let rowEle = <HTMLTableRowElement>row;
            let thisRow = parseInt(rowEle.dataset.row);

            if (thisRow > newRow)
                newRow = thisRow;
        }

        newRow++;

        const button = <HTMLButtonElement>e.target;
        const pageCode = button.dataset.pagecode;

        let response = await fetch(`/FlexibleInstructionalDays/AddStandard/${planFK}/${pageCode}/MathK8/${newRow}`, { credentials: 'include' })
        if (response.ok) {

            const output = await response.text();

            let newTR = <HTMLTableRowElement>document.createElement("tr");
            newTR.dataset.row = newRow.toString();

            newTR.innerHTML = output;

            const container = document.querySelector("#flexibleExemplarsStandards tbody");
            container.appendChild(newTR);

            that.bindDeletes();

            Core.hideLoader();
            Core.createHTMLAlert("alertMessageDiv", "The PA Academic Standard has been added!", 'success', 2000, null);
        } else {
            Core.hideLoader();
            Core.createHTMLAlert("alertMessageDiv", "There was an issue adding a new row. Please try again.", 'error', 2000, null);
        }
    }

    checkForYes(e: HTMLInputElement) {
        const radio = e;

        if (radio.checked) {
            const value = radio.dataset.lookupcode;

            const exemplarInformationDiv = <HTMLDivElement>document.getElementById("exemplarInformation");
            if (value === "yes") {
                exemplarInformationDiv.classList.remove("hide");

                const allElements = document.getElementsByClassName("flexibleInstructionDaysExField");
                for (const ele of allElements) {
                    let thisEle = <HTMLElement>ele;
                    this._core.forceElementRequired(thisEle);
                }
            } else {
                exemplarInformationDiv.classList.add("hide");

                const allElements = document.getElementsByClassName("flexibleInstructionDaysExField");
                for (const ele of allElements) {
                    let thisEle = <HTMLElement>ele;
                    this._core.forceElementOptional(thisEle);
                }
            }
        }
    }
}

// FlexibleInstructionDaysExMath912
class FlexibleInstructionDaysExMath912 {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["flexibleInstructionDaysExField", "flexibleInstructionDaysExRadioField"];

        let flexibleInstructionDaysExMath912SaveButton = document.getElementById("flexibleInstructionDaysExMath912Save");
        if (flexibleInstructionDaysExMath912SaveButton !== null)
            flexibleInstructionDaysExMath912SaveButton.addEventListener("click", (e: Event) => this.save("save"));

        const radios = document.getElementsByClassName("flexibleInstructionDaysExRadioField");
        for (const radio of radios) {
            radio.addEventListener("change", (e: Event) => this.checkForYes(<HTMLInputElement>e.target));
            this.checkForYes(<HTMLInputElement>radio);
        }

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        const flexibleInstructionalDaysELAK8AddStandardButton = document.getElementById("flexibleInstructionalDaysMath912AddStandard");
        if (flexibleInstructionalDaysELAK8AddStandardButton !== null)
            flexibleInstructionalDaysELAK8AddStandardButton.addEventListener("click", (e: Event) => this.addStandardRow(e));

        this.bindDeletes();

        const flexibleInstructionalDaysELAK8StandardRowDeleteCancelButton = document.getElementById("flexibleExemplarsStandardRowDeleteCancel");
        if (flexibleInstructionalDaysELAK8StandardRowDeleteCancelButton !== null)
            flexibleInstructionalDaysELAK8StandardRowDeleteCancelButton.addEventListener("click", (e: Event) => this.deleteRowCancel());

        const flexibleInstructionalDaysELAK8StandardRowDeleteConfirmButton = document.getElementById("flexibleExemplarsStandardRowDeleteConfirm");
        if (flexibleInstructionalDaysELAK8StandardRowDeleteConfirmButton !== null)
            flexibleInstructionalDaysELAK8StandardRowDeleteConfirmButton.addEventListener("click", (e: Event) => this.deleteRowConfirm(e));

        initializeAllNA();
        initializeAllYes();
        initializeAllNo();
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("flexibleInstructionDaysExMath912Form");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("flexibleInstructionDaysExField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let radios = document.getElementsByClassName("flexibleInstructionDaysExRadioField");
        for (let ele of radios) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = "0";
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                if (element.value !== "" || hadValue) {
                    let saveItem: IPlanProperty = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };

                    allSaveElements.push(saveItem);
                }
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FlexibleInstructionalDays/SaveFlexibleInstructionDaysExMath912', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    bindDeletes() {
        const allDeleteRows = document.getElementsByClassName("deleteFlexibleInstructionExemplarStandardRow");
        for (const deleteRow of allDeleteRows)
            deleteRow.addEventListener("click", (e: Event) => this.showDeleteStandardRow(e));
    }

    showDeleteStandardRow(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let row = buttonElement.dataset.row;

        let modal: Modal = new Modal("deleteStandardRowModal", null);
        modal.addAttributeToElement("deleteStandardRowModal", "#flexibleExemplarsStandardRowDeleteConfirm", "row", row);
        modal.show();
    }

    deleteRowConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let planProps = [];
        let row = buttonElement.dataset.row;

        let allElements = document.querySelectorAll(`.flexibleInstructionDaysExField[data-row='${row}']`);
        for (let ele of allElements) {
            let element = <HTMLElement>ele;
            let planProp = element.dataset.planpropertypk;

            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FlexibleInstructionalDays/DeleteRowData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();

                let rowElement = document.querySelector(`#flexibleExemplarsStandards tbody tr[data-row='${row}']`);
                rowElement.remove();

                let modal: Modal = new Modal("deleteStandardRowModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The row data has been removed.", 'success', 3000, null);
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }

    deleteRowCancel() {
        let modal: Modal = new Modal("deleteStandardRowModal", null);
        modal.hide();
    }

    async addStandardRow(e) {
        Core.showLoader();
        let numberOfRows = 0;
        let formElement = <HTMLDivElement>document.getElementById("flexibleInstructionDaysExMath912Form");

        let planFK = formElement.dataset.planfk;
        let that = this;
        let newRow = 0;

        let allRows = document.querySelectorAll("#flexibleExemplarsStandards tbody tr");
        for (let row of allRows) {
            let rowEle = <HTMLTableRowElement>row;
            let thisRow = parseInt(rowEle.dataset.row);

            if (thisRow > newRow)
                newRow = thisRow;
        }

        newRow++;

        const button = <HTMLButtonElement>e.target;
        const pageCode = button.dataset.pagecode;

        let response = await fetch(`/FlexibleInstructionalDays/AddStandard/${planFK}/${pageCode}/Math912/${newRow}`, { credentials: 'include' })
        if (response.ok) {

            const output = await response.text();

            let newTR = <HTMLTableRowElement>document.createElement("tr");
            newTR.dataset.row = newRow.toString();

            newTR.innerHTML = output;

            const container = document.querySelector("#flexibleExemplarsStandards tbody");
            container.appendChild(newTR);

            that.bindDeletes();

            Core.hideLoader();
            Core.createHTMLAlert("alertMessageDiv", "The PA Academic Standard has been added!", 'success', 2000, null);
        } else {
            Core.hideLoader();
            Core.createHTMLAlert("alertMessageDiv", "There was an issue adding a new row. Please try again.", 'error', 2000, null);
        }
    }

    checkForYes(e: HTMLInputElement) {
        const radio = e;

        if (radio.checked) {
            const value = radio.dataset.lookupcode;

            const exemplarInformationDiv = <HTMLDivElement>document.getElementById("exemplarInformation");
            if (value === "yes") {
                exemplarInformationDiv.classList.remove("hide");

                const allElements = document.getElementsByClassName("flexibleInstructionDaysExField");
                for (const ele of allElements) {
                    let thisEle = <HTMLElement>ele;
                    this._core.forceElementRequired(thisEle);
                }
            } else {
                exemplarInformationDiv.classList.add("hide");

                const allElements = document.getElementsByClassName("flexibleInstructionDaysExField");
                for (const ele of allElements) {
                    let thisEle = <HTMLElement>ele;
                    this._core.forceElementOptional(thisEle);
                }
            }
        }
    }
}

// FlexibleInstructionDaysExScience912
class FlexibleInstructionDaysExScience912 {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["flexibleInstructionDaysExField", "flexibleInstructionDaysExRadioField"];

        let flexibleInstructionDaysExScience912SaveButton = document.getElementById("flexibleInstructionDaysExScience912Save");
        if (flexibleInstructionDaysExScience912SaveButton !== null)
            flexibleInstructionDaysExScience912SaveButton.addEventListener("click", (e: Event) => this.save("save"));

        const radios = document.getElementsByClassName("flexibleInstructionDaysExRadioField");
        for (const radio of radios) {
            radio.addEventListener("change", (e: Event) => this.checkForYes(<HTMLInputElement>e.target));
            this.checkForYes(<HTMLInputElement>radio);
        }

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        const flexibleInstructionalDaysELAK8AddStandardButton = document.getElementById("flexibleInstructionalDaysScience912AddStandard");
        if (flexibleInstructionalDaysELAK8AddStandardButton !== null)
            flexibleInstructionalDaysELAK8AddStandardButton.addEventListener("click", (e: Event) => this.addStandardRow(e));

        this.bindDeletes();

        const flexibleInstructionalDaysELAK8StandardRowDeleteCancelButton = document.getElementById("flexibleExemplarsStandardRowDeleteCancel");
        if (flexibleInstructionalDaysELAK8StandardRowDeleteCancelButton !== null)
            flexibleInstructionalDaysELAK8StandardRowDeleteCancelButton.addEventListener("click", (e: Event) => this.deleteRowCancel());

        const flexibleInstructionalDaysELAK8StandardRowDeleteConfirmButton = document.getElementById("flexibleExemplarsStandardRowDeleteConfirm");
        if (flexibleInstructionalDaysELAK8StandardRowDeleteConfirmButton !== null)
            flexibleInstructionalDaysELAK8StandardRowDeleteConfirmButton.addEventListener("click", (e: Event) => this.deleteRowConfirm(e));

        initializeAllNA();
        initializeAllYes();
        initializeAllNo();
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("flexibleInstructionDaysExScience912Form");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("flexibleInstructionDaysExField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let radios = document.getElementsByClassName("flexibleInstructionDaysExRadioField");
        for (let ele of radios) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = "0";
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                if (element.value !== "" || hadValue) {
                    let saveItem: IPlanProperty = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };

                    allSaveElements.push(saveItem);
                }
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FlexibleInstructionalDays/SaveFlexibleInstructionDaysExScience912', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    bindDeletes() {
        const allDeleteRows = document.getElementsByClassName("deleteFlexibleInstructionExemplarStandardRow");
        for (const deleteRow of allDeleteRows)
            deleteRow.addEventListener("click", (e: Event) => this.showDeleteStandardRow(e));
    }

    showDeleteStandardRow(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let row = buttonElement.dataset.row;

        let modal: Modal = new Modal("deleteStandardRowModal", null);
        modal.addAttributeToElement("deleteStandardRowModal", "#flexibleExemplarsStandardRowDeleteConfirm", "row", row);
        modal.show();
    }

    deleteRowConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let planProps = [];
        let row = buttonElement.dataset.row;

        let allElements = document.querySelectorAll(`.flexibleInstructionDaysExField[data-row='${row}']`);
        for (let ele of allElements) {
            let element = <HTMLElement>ele;
            let planProp = element.dataset.planpropertypk;

            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FlexibleInstructionalDays/DeleteRowData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();

                let rowElement = document.querySelector(`#flexibleExemplarsStandards tbody tr[data-row='${row}']`);
                rowElement.remove();

                let modal: Modal = new Modal("deleteStandardRowModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The row data has been removed.", 'success', 3000, null);
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }

    deleteRowCancel() {
        let modal: Modal = new Modal("deleteStandardRowModal", null);
        modal.hide();
    }

    async addStandardRow(e) {
        Core.showLoader();
        let numberOfRows = 0;
        let formElement = <HTMLDivElement>document.getElementById("flexibleInstructionDaysExScience912Form");

        let planFK = formElement.dataset.planfk;
        let that = this;
        let newRow = 0;

        let allRows = document.querySelectorAll("#flexibleExemplarsStandards tbody tr");
        for (let row of allRows) {
            let rowEle = <HTMLTableRowElement>row;
            let thisRow = parseInt(rowEle.dataset.row);

            if (thisRow > newRow)
                newRow = thisRow;
        }

        newRow++;

        const button = <HTMLButtonElement>e.target;
        const pageCode = button.dataset.pagecode;

        let response = await fetch(`/FlexibleInstructionalDays/AddStandard/${planFK}/${pageCode}/Science912/${newRow}`, { credentials: 'include' })
        if (response.ok) {

            const output = await response.text();

            let newTR = <HTMLTableRowElement>document.createElement("tr");
            newTR.dataset.row = newRow.toString();

            newTR.innerHTML = output;

            const container = document.querySelector("#flexibleExemplarsStandards tbody");
            container.appendChild(newTR);

            that.bindDeletes();

            Core.hideLoader();
            Core.createHTMLAlert("alertMessageDiv", "The PA Academic Standard has been added!", 'success', 2000, null);
        } else {
            Core.hideLoader();
            Core.createHTMLAlert("alertMessageDiv", "There was an issue adding a new row. Please try again.", 'error', 2000, null);
        }
    }

    checkForYes(e: HTMLInputElement) {
        const radio = e;

        if (radio.checked) {
            const value = radio.dataset.lookupcode;

            const exemplarInformationDiv = <HTMLDivElement>document.getElementById("exemplarInformation");
            if (value === "yes") {
                exemplarInformationDiv.classList.remove("hide");

                const allElements = document.getElementsByClassName("flexibleInstructionDaysExField");
                for (const ele of allElements) {
                    let thisEle = <HTMLElement>ele;
                    this._core.forceElementRequired(thisEle);
                }
            } else {
                exemplarInformationDiv.classList.add("hide");

                const allElements = document.getElementsByClassName("flexibleInstructionDaysExField");
                for (const ele of allElements) {
                    let thisEle = <HTMLElement>ele;
                    this._core.forceElementOptional(thisEle);
                }
            }
        }
    }
}

// FlexibleInstructionDaysExSocial912
class FlexibleInstructionDaysExSocial912 {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["flexibleInstructionDaysExField", "flexibleInstructionDaysExRadioField"];

        let flexibleInstructionDaysExSocial912SaveButton = document.getElementById("flexibleInstructionDaysExSocial912Save");
        if (flexibleInstructionDaysExSocial912SaveButton !== null)
            flexibleInstructionDaysExSocial912SaveButton.addEventListener("click", (e: Event) => this.save("save"));

        const radios = document.getElementsByClassName("flexibleInstructionDaysExRadioField");
        for (const radio of radios) {
            radio.addEventListener("change", (e: Event) => this.checkForYes(<HTMLInputElement>e.target));
            this.checkForYes(<HTMLInputElement>radio);
        }

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        const flexibleInstructionalDaysELAK8AddStandardButton = document.getElementById("flexibleInstructionalDaysSocial912AddStandard");
        if (flexibleInstructionalDaysELAK8AddStandardButton !== null)
            flexibleInstructionalDaysELAK8AddStandardButton.addEventListener("click", (e: Event) => this.addStandardRow(e));

        this.bindDeletes();

        const flexibleInstructionalDaysELAK8StandardRowDeleteCancelButton = document.getElementById("flexibleExemplarsStandardRowDeleteCancel");
        if (flexibleInstructionalDaysELAK8StandardRowDeleteCancelButton !== null)
            flexibleInstructionalDaysELAK8StandardRowDeleteCancelButton.addEventListener("click", (e: Event) => this.deleteRowCancel());

        const flexibleInstructionalDaysELAK8StandardRowDeleteConfirmButton = document.getElementById("flexibleExemplarsStandardRowDeleteConfirm");
        if (flexibleInstructionalDaysELAK8StandardRowDeleteConfirmButton !== null)
            flexibleInstructionalDaysELAK8StandardRowDeleteConfirmButton.addEventListener("click", (e: Event) => this.deleteRowConfirm(e));

        initializeAllNA();
        initializeAllYes();
        initializeAllNo();
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("flexibleInstructionDaysExSocial912Form");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("flexibleInstructionDaysExField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let radios = document.getElementsByClassName("flexibleInstructionDaysExRadioField");
        for (let ele of radios) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = "0";
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                if (element.value !== "" || hadValue) {
                    let saveItem: IPlanProperty = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };

                    allSaveElements.push(saveItem);
                }
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FlexibleInstructionalDays/SaveFlexibleInstructionDaysExSocial912', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    bindDeletes() {
        const allDeleteRows = document.getElementsByClassName("deleteFlexibleInstructionExemplarStandardRow");
        for (const deleteRow of allDeleteRows)
            deleteRow.addEventListener("click", (e: Event) => this.showDeleteStandardRow(e));
    }

    showDeleteStandardRow(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let row = buttonElement.dataset.row;

        let modal: Modal = new Modal("deleteStandardRowModal", null);
        modal.addAttributeToElement("deleteStandardRowModal", "#flexibleExemplarsStandardRowDeleteConfirm", "row", row);
        modal.show();
    }

    deleteRowConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let planProps = [];
        let row = buttonElement.dataset.row;

        let allElements = document.querySelectorAll(`.flexibleInstructionDaysExField[data-row='${row}']`);
        for (let ele of allElements) {
            let element = <HTMLElement>ele;
            let planProp = element.dataset.planpropertypk;

            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FlexibleInstructionalDays/DeleteRowData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();

                let rowElement = document.querySelector(`#flexibleExemplarsStandards tbody tr[data-row='${row}']`);
                rowElement.remove();

                let modal: Modal = new Modal("deleteStandardRowModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The row data has been removed.", 'success', 3000, null);
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }

    deleteRowCancel() {
        let modal: Modal = new Modal("deleteStandardRowModal", null);
        modal.hide();
    }

    async addStandardRow(e) {
        Core.showLoader();
        let numberOfRows = 0;
        let formElement = <HTMLDivElement>document.getElementById("flexibleInstructionDaysExSocial912Form");

        let planFK = formElement.dataset.planfk;
        let that = this;
        let newRow = 0;

        let allRows = document.querySelectorAll("#flexibleExemplarsStandards tbody tr");
        for (let row of allRows) {
            let rowEle = <HTMLTableRowElement>row;
            let thisRow = parseInt(rowEle.dataset.row);

            if (thisRow > newRow)
                newRow = thisRow;
        }

        newRow++;

        const button = <HTMLButtonElement>e.target;
        const pageCode = button.dataset.pagecode;

        let response = await fetch(`/FlexibleInstructionalDays/AddStandard/${planFK}/${pageCode}/Social912/${newRow}`, { credentials: 'include' })
        if (response.ok) {

            const output = await response.text();

            let newTR = <HTMLTableRowElement>document.createElement("tr");
            newTR.dataset.row = newRow.toString();

            newTR.innerHTML = output;

            const container = document.querySelector("#flexibleExemplarsStandards tbody");
            container.appendChild(newTR);

            that.bindDeletes();

            Core.hideLoader();
            Core.createHTMLAlert("alertMessageDiv", "The PA Academic Standard has been added!", 'success', 2000, null);
        } else {
            Core.hideLoader();
            Core.createHTMLAlert("alertMessageDiv", "There was an issue adding a new row. Please try again.", 'error', 2000, null);
        }
    }

    checkForYes(e: HTMLInputElement) {
        const radio = e;

        if (radio.checked) {
            const value = radio.dataset.lookupcode;

            const exemplarInformationDiv = <HTMLDivElement>document.getElementById("exemplarInformation");
            if (value === "yes") {
                exemplarInformationDiv.classList.remove("hide");

                const allElements = document.getElementsByClassName("flexibleInstructionDaysExField");
                for (const ele of allElements) {
                    let thisEle = <HTMLElement>ele;
                    this._core.forceElementRequired(thisEle);
                }
            } else {
                exemplarInformationDiv.classList.add("hide");

                const allElements = document.getElementsByClassName("flexibleInstructionDaysExField");
                for (const ele of allElements) {
                    let thisEle = <HTMLElement>ele;
                    this._core.forceElementOptional(thisEle);
                }
            }
        }
    }
}

// FlexibleInstructionDaysExCTE
class FlexibleInstructionDaysExCTE {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["flexibleInstructionDaysExField", "flexibleInstructionDaysExRadioField", "flexibleInstructionDaysExCheckboxField"];

        let flexibleInstructionDaysExCTESaveButton = document.getElementById("flexibleInstructionDaysExCTESave");
        if (flexibleInstructionDaysExCTESaveButton !== null)
            flexibleInstructionDaysExCTESaveButton.addEventListener("click", (e: Event) => this.save("save"));

        const radios = document.getElementsByClassName("flexibleInstructionDaysExRadioField");
        for (const radio of radios) {
            radio.addEventListener("change", (e: Event) => this.checkForYes(<HTMLInputElement>e.target));
            this.checkForYes(<HTMLInputElement>radio);
        }

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        const flexibleInstructionalDaysAddStandardButtons = document.getElementsByClassName("flexibleInstructionalDaysAddStandard");
        for (const flexibleInstructionalDaysAddStandardButton of flexibleInstructionalDaysAddStandardButtons)
            flexibleInstructionalDaysAddStandardButton.addEventListener("click", (e: Event) => this.addStandardRow(e));

        const flexibleInstructionalDaysAddTaskButtons = document.getElementsByClassName("flexibleInstructionalDaysAddTask");
        for (const flexibleInstructionalDaysAddTaskButton of flexibleInstructionalDaysAddTaskButtons)
            flexibleInstructionalDaysAddTaskButton.addEventListener("click", (e: Event) => this.addTaskRow(e));

        this.bindStandardDeletes();
        this.bindTaskDeletes();

        const flexibleInstructionalDaysStandardRowDeleteCancelButton = document.getElementById("flexibleExemplarsStandardRowDeleteCancel");
        if (flexibleInstructionalDaysStandardRowDeleteCancelButton !== null)
            flexibleInstructionalDaysStandardRowDeleteCancelButton.addEventListener("click", (e: Event) => this.deleteStandardRowCancel());

        const flexibleInstructionalDaysStandardRowDeleteConfirmButton = document.getElementById("flexibleExemplarsStandardRowDeleteConfirm");
        if (flexibleInstructionalDaysStandardRowDeleteConfirmButton !== null)
            flexibleInstructionalDaysStandardRowDeleteConfirmButton.addEventListener("click", (e: Event) => this.deleteStandardRowConfirm(e));

        const flexibleInstructionalDaysTaskRowDeleteCancelButton = document.getElementById("flexibleExemplarsTaskRowDeleteCancel");
        if (flexibleInstructionalDaysTaskRowDeleteCancelButton !== null)
            flexibleInstructionalDaysTaskRowDeleteCancelButton.addEventListener("click", (e: Event) => this.deleteTaskRowCancel());

        const flexibleInstructionalDaysTaskRowDeleteConfirmButton = document.getElementById("flexibleExemplarsTaskRowDeleteConfirm");
        if (flexibleInstructionalDaysTaskRowDeleteConfirmButton !== null)
            flexibleInstructionalDaysTaskRowDeleteConfirmButton.addEventListener("click", (e: Event) => this.deleteTaskRowConfirm(e));

        initializeAllNA();
        initializeAllYes();
        initializeAllNo();
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("flexibleInstructionDaysExCTEForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("flexibleInstructionDaysExField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let radios = document.getElementsByClassName("flexibleInstructionDaysExRadioField");
        for (let ele of radios) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = "0";
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                if (element.value !== "" || hadValue) {
                    let saveItem: IPlanProperty = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };

                    allSaveElements.push(saveItem);
                }
            }
        }

        let checkboxes = document.getElementsByClassName("flexibleInstructionDaysExCheckboxField");
        for (let ele of checkboxes) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.checked || hadValue) {
                let val = "";
                if (element.checked)
                    val = "on";
                else
                    val = "off";

                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FlexibleInstructionalDays/SaveFlexibleInstructionDaysExCTE', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    bindStandardDeletes() {
        const allDeleteRows = document.getElementsByClassName("deleteFlexibleInstructionExemplarStandardRow");
        for (const deleteRow of allDeleteRows)
            deleteRow.addEventListener("click", (e: Event) => this.showDeleteStandardRow(e));
    }

    showDeleteStandardRow(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let row = buttonElement.dataset.row;
        let exemplar = buttonElement.dataset.exemplar;
        let type = buttonElement.dataset.type;

        let modal: Modal = new Modal("deleteStandardRowModal", null);
        modal.addAttributeToElement("deleteStandardRowModal", "#flexibleExemplarsStandardRowDeleteConfirm", "row", row);
        modal.addAttributeToElement("deleteStandardRowModal", "#flexibleExemplarsStandardRowDeleteConfirm", "exemplar", exemplar);
        modal.addAttributeToElement("deleteStandardRowModal", "#flexibleExemplarsStandardRowDeleteConfirm", "type", type);
        modal.show();
    }

    deleteStandardRowConfirm(e: Event) {
        const buttonElement = <HTMLButtonElement>e.target;
        let planProps = [];
        const row = buttonElement.dataset.row;
        const exemplar = buttonElement.dataset.exemplar;
        const type = buttonElement.dataset.type;

        const allElements = document.querySelectorAll(`.flexibleInstructionDaysExField[data-row='${row}'][data-exemplar='${exemplar}'][data-type='${type}']`);
        for (let ele of allElements) {
            let element = <HTMLElement>ele;
            let planProp = element.dataset.planpropertypk;

            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }

        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/FlexibleInstructionalDays/DeleteRowData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();

                let rowElement = document.querySelector(`#flexibleExemplarsStandards${exemplar} tbody tr[data-row='${row}'][data-type='${type}']`);
                rowElement.remove();

                let modal: Modal = new Modal("deleteStandardRowModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The row data has been removed.", 'success', 3000, null);
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }

    deleteStandardRowCancel() {
        let modal: Modal = new Modal("deleteStandardRowModal", null);
        modal.hide();
    }

    async addStandardRow(e) {
        Core.showLoader();
        let formElement = <HTMLDivElement>document.getElementById("flexibleInstructionDaysExCTEForm");

        let planFK = formElement.dataset.planfk;
        let that = this;
        let newRow = 0;
        const buttonElement = <HTMLButtonElement>e.target;
        const exemplar = buttonElement.dataset.exemplar;

        let allRows = document.querySelectorAll(`#flexibleExemplarsStandards${exemplar} tbody tr`);
        for (let row of allRows) {
            let rowEle = <HTMLTableRowElement>row;
            let thisRow = parseInt(rowEle.dataset.row);

            if (thisRow > newRow)
                newRow = thisRow;
        }

        newRow++;

        const button = <HTMLButtonElement>e.target;
        const pageCode = button.dataset.pagecode;

        let response = await fetch(`/FlexibleInstructionalDays/AddStandard/${planFK}/${pageCode}/CTE/${newRow}/${exemplar}`, { credentials: 'include' })
        if (response.ok) {

            const output = await response.text();

            let newTR = <HTMLTableRowElement>document.createElement("tr");
            newTR.dataset.row = newRow.toString();
            newTR.dataset.type = "Standard";
            newTR.classList.add("flexibleInstructionalDaysStandards");

            newTR.innerHTML = output;

            const container = document.querySelector(`#flexibleExemplarsStandards${exemplar} tbody`);
            container.appendChild(newTR);

            that.bindStandardDeletes();

            Core.hideLoader();
            Core.createHTMLAlert("alertMessageDiv", "The PA Academic Standard has been added!", 'success', 2000, null);
        } else {
            Core.hideLoader();
            Core.createHTMLAlert("alertMessageDiv", "There was an issue adding a new row. Please try again.", 'error', 2000, null);
        }
    }

    bindTaskDeletes() {
        const allDeleteRows = document.getElementsByClassName("deleteFlexibleInstructionExemplarTaskRow");
        for (const deleteRow of allDeleteRows)
            deleteRow.addEventListener("click", (e: Event) => this.showDeleteTaskRow(e));
    }

    showDeleteTaskRow(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let row = buttonElement.dataset.row;
        let exemplar = buttonElement.dataset.exemplar;
        let type = buttonElement.dataset.type;

        let modal: Modal = new Modal("deleteTaskRowModal", null);
        modal.addAttributeToElement("deleteTaskRowModal", "#flexibleExemplarsTaskRowDeleteConfirm", "row", row);
        modal.addAttributeToElement("deleteTaskRowModal", "#flexibleExemplarsTaskRowDeleteConfirm", "exemplar", exemplar);
        modal.addAttributeToElement("deleteTaskRowModal", "#flexibleExemplarsTaskRowDeleteConfirm", "type", type);
        modal.show();
    }

    deleteTaskRowConfirm(e: Event) {
        const buttonElement = <HTMLButtonElement>e.target;
        let planProps = [];
        const row = buttonElement.dataset.row;
        const exemplar = buttonElement.dataset.exemplar;
        const type = buttonElement.dataset.type;

        const allElements = document.querySelectorAll(`.flexibleInstructionDaysExField[data-row='${row}'][data-exemplar='${exemplar}'][data-type='${type}']`);
        for (let ele of allElements) {
            let element = <HTMLElement>ele;
            let planProp = element.dataset.planpropertypk;

            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }

        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/FlexibleInstructionalDays/DeleteRowData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();

                let rowElement = document.querySelector(`#flexibleExemplarsTasks${exemplar} tbody tr[data-row='${row}'][data-type='${type}']`);
                rowElement.remove();

                let modal: Modal = new Modal("deleteTaskRowModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The row data has been removed.", 'success', 3000, null);
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }

    deleteTaskRowCancel() {
        let modal: Modal = new Modal("deleteTaskRowModal", null);
        modal.hide();
    }

    async addTaskRow(e) {
        Core.showLoader();
        let formElement = <HTMLDivElement>document.getElementById("flexibleInstructionDaysExCTEForm");

        const planFK = formElement.dataset.planfk;
        const that = this;
        let newRow = 0;
        const buttonElement = <HTMLButtonElement>e.target;
        const exemplar = buttonElement.dataset.exemplar;

        let allRows = document.querySelectorAll(`#flexibleExemplarsTasks${exemplar} tbody tr`);
        for (let row of allRows) {
            let rowEle = <HTMLTableRowElement>row;
            let thisRow = parseInt(rowEle.dataset.row);

            if (thisRow > newRow)
                newRow = thisRow;
        }

        newRow++;

        const button = <HTMLButtonElement>e.target;
        const pageCode = button.dataset.pagecode;

        let response = await fetch(`/FlexibleInstructionalDays/AddTask/${planFK}/${pageCode}/CTE/${exemplar}/${newRow}`, { credentials: 'include' })
        if (response.ok) {

            const output = await response.text();

            let newTR = <HTMLTableRowElement>document.createElement("tr");
            newTR.dataset.row = newRow.toString();
            newTR.dataset.type = "Task";
            newTR.classList.add("flexibleInstructionalDaysTasks");

            newTR.innerHTML = output;

            const container = document.querySelector(`#flexibleExemplarsTasks${exemplar} tbody`);
            container.appendChild(newTR);

            that.bindTaskDeletes();

            Core.hideLoader();
            Core.createHTMLAlert("alertMessageDiv", "The PA Academic Task has been added!", 'success', 2000, null);
        } else {
            Core.hideLoader();
            Core.createHTMLAlert("alertMessageDiv", "There was an issue adding a new row. Please try again.", 'error', 2000, null);
        }
    }

    checkForYes(e: HTMLInputElement) {
        const radio = e;

        if (radio.checked) {
            const value = radio.dataset.lookupcode;

            const exemplarInformationDiv = <HTMLDivElement>document.getElementById("exemplarInformation");
            if (value === "yes") {
                exemplarInformationDiv.classList.remove("hide");

                const allElements = document.getElementsByClassName("flexibleInstructionDaysExField");
                for (const ele of allElements) {
                    let thisEle = <HTMLElement>ele;
                    this._core.forceElementRequired(thisEle);
                }
            } else {
                exemplarInformationDiv.classList.add("hide");

                const allElements = document.getElementsByClassName("flexibleInstructionDaysExField");
                for (const ele of allElements) {
                    let thisEle = <HTMLElement>ele;
                    this._core.forceElementOptional(thisEle);
                }
            }
        }
    }
}


function initializeAllNA() {
    let buttons = document.querySelectorAll(".notApplicableFIDS[data-action-group-code-fk]");
    for (let btn of buttons) {
        let btnEl = <HTMLButtonElement>btn;
        btnEl.addEventListener("click", () => {
            let compliantActionsNA = document.querySelectorAll(`[data-action-type='componentCompliantNA'][data-action-group-code-fk='${btnEl.dataset.actionGroupCodeFk}'][data-lookup-code='actionNA']`);
            for (let compliantAction of compliantActionsNA) {
                let compliantActionElement = <HTMLInputElement>compliantAction;
                compliantActionElement.click();
            }
        });
    }
}

function initializeAllYes() {
    let buttons = document.querySelectorAll(".allYesFIDS[data-action-group-code-fk]");
    for (let btn of buttons) {
        let btnEl = <HTMLButtonElement>btn;
        btnEl.addEventListener("click", () => {
            let compliantActionsYes = document.querySelectorAll(`[data-action-type='componentCompliantNA'][data-action-group-code-fk='${btnEl.dataset.actionGroupCodeFk}'][data-lookup-code='actionYes'], [data-action-type='componentCompliant'][data-action-group-code-fk='${btnEl.dataset.actionGroupCodeFk}'][data-lookup-code='actionYes']`);
            for (let compliantAction of compliantActionsYes) {
                let compliantActionElement = <HTMLInputElement>compliantAction;
                compliantActionElement.click();
            }
        });
    }
}

function initializeAllNo() {
    let buttons = document.querySelectorAll(".allNoFIDS[data-action-group-code-fk]");
    for (let btn of buttons) {
        let btnEl = <HTMLButtonElement>btn;
        btnEl.addEventListener("click", () => {
            let compliantActionsNo = document.querySelectorAll(`[data-action-type='componentCompliantNA'][data-action-group-code-fk='${btnEl.dataset.actionGroupCodeFk}'][data-lookup-code='actionNo'], [data-action-type='componentCompliant'][data-action-group-code-fk='${btnEl.dataset.actionGroupCodeFk}'][data-lookup-code='actionNo']`);
            for (let compliantAction of compliantActionsNo) {
                let compliantActionElement = <HTMLInputElement>compliantAction;
                compliantActionElement.click();
            }
        });
    }
}
