/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />

//SummaryChecklistAndSubmissionCompPlan
class SummaryChecklistAndSubmissionCompPlan {
    private _core: Core;
    constructor() {
        this._core = new Core();
        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.back(e));

        this._core.leftnav(this);

        let submitButton = document.getElementById("submitPlanButton");
        if (submitButton !== null)
            submitButton.addEventListener("click", (e: Event) => this.submit(e));
    }

    back(e: Event) {
        let newPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;

        //Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }

        if (refreshPage && refreshPage !== "")
            window.location.href = refreshPage;
        else
            Core.hideLoader();
    }

    submit(e: Event) {
        let planFK = 0;
        let element = <HTMLButtonElement>e.srcElement;
        planFK = parseInt(element.dataset.planfk);
        let core = this._core;
        Core.showLoader();
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SummaryAndSubmission/SubmitPlan', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Your plan has been submitted for review!", 'success', 3000, window.location.reload());
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "An unexpected error occurred while submitting plan. Please try again later.", 'error', 3000, null);
            }
        };
        xhr.send("planFK=" + planFK);
    }
}