/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />
/// <reference path="controls/modal.ts" />

// FederalSSAEProfile
class FederalSSAEProfile {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.fundsSelection();

        this.validationClasses = ["federalSSAEProfileField", "federalSSAEProfileRadioField"];

        let federalSSAEProfileSaveButton = document.getElementById("federalSSAEProfileSave");
        if (federalSSAEProfileSaveButton !== null)
            federalSSAEProfileSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let refreshButton = document.getElementById("resetProfile");
        if (refreshButton !== null)
            refreshButton.addEventListener("click", (e: Event) => Core.refreshProfile("federalSSAEProfileForm"));

        const federalSSAEProfileFunds = document.getElementsByClassName("federalSSAEProfileFunds");
        for (const federalSSAEProfileFund of federalSSAEProfileFunds)
            federalSSAEProfileFund.addEventListener("change", (e: Event) => this.fundsSelection());

    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("federalSSAEProfileForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("federalSSAEProfileField");

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let radioInputs = document.getElementsByClassName("federalSSAEProfileRadioField");
        for (let ele of radioInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = "0";

            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "",
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsSSAE/SaveFederalSSAEProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    fundsSelection() {
        const federalSSAEProfileFunds = <HTMLInputElement>document.querySelector(".federalSSAEProfileFunds:checked");
        const fundsDescriptionContainer = <HTMLDivElement>document.getElementById("fundsDescription");
        const fundsDescription = <HTMLTextAreaElement>document.getElementById("federalSSAEProfileFundsDescription");
        const form = <HTMLDivElement>document.getElementById("federalSSAEProfileForm");
        const planFK = form.dataset.planfk;

        if (federalSSAEProfileFunds !== null) {
            if (federalSSAEProfileFunds.dataset.lookupcode === "no") {
                fundsDescriptionContainer.classList.remove("hide");
                this._core.forceElementRequired(fundsDescription);
            } else {
                fundsDescriptionContainer.classList.add("hide");
                this._core.forceElementOptional(fundsDescription);
            }

            const xhr = new XMLHttpRequest();
            xhr.open('POST', '/FederalProgramsSSAE/FederalSSAEFunds', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                } else
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue setting statuses. Please try your selection again.", 'error', 3000, null);
            }
            xhr.send(`planFK=${planFK}&lookupCode=${federalSSAEProfileFunds.dataset.lookupcode}`);
        }
    }
}

// FederalSSAEPreparing
class FederalSSAEPreparing {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["federalSSAEPreparingField", "federalSSAEPreparingSelectField"];

        let federalSSAEPreparingSaveButton = document.getElementById("federalSSAEPreparingSave");
        if (federalSSAEPreparingSaveButton !== null)
            federalSSAEPreparingSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let federalPreparingAddRowButton = document.getElementById("federalPreparingAddRow");
        if (federalPreparingAddRowButton !== null)
            federalPreparingAddRowButton.addEventListener("click", (e: Event) => this.addPreparingRow());

        this.bindDeletePreparingNeedsAssessmentRow();

        let deleteRowCancelButton = document.getElementById("deletePreparingRowCancel");
        if (deleteRowCancelButton !== null)
            deleteRowCancelButton.addEventListener("click", (e: Event) => this.deleteRowCancel());

        let deleteRowConfirmButton = document.getElementById("deletePreparingRowConfirm");
        if (deleteRowConfirmButton !== null)
            deleteRowConfirmButton.addEventListener("click", (e: Event) => this.deleteRowConfirm(e));

        const federalSSAEProfileAddRowsNumberInput = document.getElementById("federalPreparingAddRowsNumber");
        if (federalSSAEProfileAddRowsNumberInput !== null)
            federalSSAEProfileAddRowsNumberInput.addEventListener("input", (e: Event) => this.checkForPlural());
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("federalSSAEPreparingForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("federalSSAEPreparingField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let selects = document.getElementsByClassName("federalSSAEPreparingSelectField");
        for (let ele of selects) {
            let planPropertyPK = 0;
            let element = <HTMLSelectElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: "",
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsSSAE/SaveFederalSSAEPreparing', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    async addPreparingRow() {
        let planForm = document.getElementById("federalSSAEPreparingForm");
        let planFK = planForm.dataset.planfk;

        let that = this;
        let newRow = 0;

        const rows = document.getElementsByClassName("preparingRow");
        for (const row of rows) {
            const ele = <HTMLTableRowElement>row;
            if (ele.dataset.row) {
                const thisRow = parseInt(ele.dataset.row);
                if (thisRow > newRow)
                    newRow = thisRow;
            }
        }

        const numElement = <HTMLInputElement>document.getElementById("federalPreparingAddRowsNumber")
        const num = parseInt(numElement.value);
        if (!isNaN(num)) {
            for (let i = 0; i < num; i++) {
                newRow++;

                let response = await fetch(`/FederalProgramsSSAE/AddPreparingForNeedsAssessmentRow/${planFK}/${newRow}`, { credentials: 'include' })
                if (response.ok) {
                    Core.hideLoader();
                    let newTR = <HTMLTableRowElement>document.createElement("tr");
                    newTR.classList.add("needs-assessment")
                    newTR.innerHTML = await response.text();
                    newTR.dataset.row = newRow.toString();

                    let table = document.querySelector("#preparingForNeedsAssessment tbody");

                    table.append(newTR);

                    that.bindDeletePreparingNeedsAssessmentRow();

                    Core.createHTMLAlert("alertMessageDiv", "Record added!", 'success', 2000, null);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", `Request failed.  Returned status of '${response.status}'`, 'error', 3000, null);
                    Core.hideLoader();
                }
            }
        } else {
            Core.createHTMLAlert("alertMessageDiv", `Please enter a valid number to add rows to the table.`, 'warning', 3000, null);
        }
    }

    bindDeletePreparingNeedsAssessmentRow() {
        let rowElements = document.getElementsByClassName("deletePreparingNeedsAssessmentRow");
        for (let row of rowElements) {
            row.addEventListener("click", (e: Event) => this.deleteThisRow(e));
        }
    }

    deleteThisRow(e) {
        let buttonElement = <HTMLButtonElement>e.target;
        let row = buttonElement.dataset.row;

        let modal: Modal = new Modal("deletePreparingRowModal", null);
        modal.addAttributeToElement("deletePreparingRowModal", "#deletePreparingRowConfirm", "row", row);
        modal.show();
    }

    deleteRowConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let planProps = [];
        let row = buttonElement.dataset.row;

        let allElements = document.querySelectorAll(`.preparingAssessment[data-row='${row}']`);
        for (let ele of allElements) {
            let element = <HTMLElement>ele;
            let planProp = element.dataset.planpropertypk;

            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsSSAE/DeletePreparingNeedsAssessmentRow', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();

                let rowElement = <HTMLTableRowElement>document.querySelector(`.needs-assessment[data-row='${row}']`);
                rowElement.remove();

                let modal: Modal = new Modal("deletePreparingRowModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The record has been removed.", 'success', 3000, null);
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }

    deleteRowCancel() {
        let modal: Modal = new Modal("deletePreparingRowModal", null);
        modal.hide();
    }

    checkForPlural(): void {
        let word = <HTMLSpanElement>document.getElementById("rowWord");
        let num = <HTMLInputElement>document.getElementById("federalPreparingAddRowsNumber")
        let newWord = "rows";
        if (parseInt(num.value) === 1) {
            newWord = "row";
        }

        word.textContent = newWord;
    }
}

// FederalSSAENeedsDesc
class FederalSSAENeedsDesc {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["federalSSAENeedsDescField"];

        let federalSSAENeedsDescSaveButton = document.getElementById("federalSSAENeedsDescSave");
        if (federalSSAENeedsDescSaveButton !== null)
            federalSSAENeedsDescSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("federalSSAENeedsDescForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("federalSSAENeedsDescField");

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsSSAE/SaveFederalSSAENeedsDesc', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

// FederalSSAEWellRounded
class FederalSSAEWellRounded {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["federalSSAEWellRoundedField"];

        let federalSSAEWellRoundedSaveButton = document.getElementById("federalSSAEWellRoundedSave");
        if (federalSSAEWellRoundedSaveButton !== null)
            federalSSAEWellRoundedSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let comprehensivePlanDataButton = document.getElementById("comprehensivePlanData");
        if (comprehensivePlanDataButton !== null)
            comprehensivePlanDataButton.addEventListener("click", (e: Event) => this.checkForComprehensiveData(e))

        let runDataCancelButton = document.getElementById("runDataCancel");
        if (runDataCancelButton !== null)
            runDataCancelButton.addEventListener("click", (e: Event) => this.runDataCancel());

        let runDataConfirmButton = document.getElementById("runDataConfirm");
        if (runDataConfirmButton !== null)
            runDataConfirmButton.addEventListener("click", (e: Event) => this.runDataConfirm(e));

        const closeNoCompPlan = document.getElementById("noCompPlanClose");
        if (closeNoCompPlan !== null)
            closeNoCompPlan.addEventListener("click", (e: Event) => this.closeNoCompPlan());
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let strengthElements = [];
        let challengeElements = [];
        let observationElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("federalSSAEWellRoundedForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let strengths = document.querySelectorAll(".federalSSAEWellRoundedField[data-type='strength']");
        for (let ele of strengths) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanPropertyExtended = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    ButtonRow: 0,
                    DataPointPlanPropertyPK: parseInt(element.dataset.datapointplanpropertypk)
                };

                strengthElements.push(saveItem);
            }
        }

        let challenges = document.querySelectorAll(".federalSSAEWellRoundedField[data-type='challenge']");
        for (let ele of challenges) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanPropertyExtended = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    ButtonRow: 0,
                    DataPointPlanPropertyPK: parseInt(element.dataset.datapointplanpropertypk)
                };

                challengeElements.push(saveItem);
            }
        }

        let observations = document.querySelectorAll(".federalSSAEWellRoundedField[data-type='observation']");
        for (let ele of observations) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanPropertyExtended = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    ButtonRow: 0,
                    DataPointPlanPropertyPK: parseInt(element.dataset.datapointplanpropertypk)
                };

                observationElements.push(saveItem);
            }
        }

        let saveData = {
            "Strengths": strengthElements,
            "Challenges": challengeElements,
            "Observations": observationElements,
            "PlanFK": planPK
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsSSAE/SaveFederalSSAEWellRounded', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (saveData === null) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(saveData));
        }
    }

    async checkForComprehensiveData(e) {
        let element = <HTMLButtonElement>e.target;
        let planFK = element.dataset.planfk;

        let existingDataElement = <HTMLInputElement>document.getElementById("existingData");
        if (existingDataElement.value === "true") {
            let modal: Modal = new Modal("confirmDataModal", null);
            modal.addAttributeToElement("confirmDataModal", "#runDataConfirm", "planfk", planFK);
            modal.show();
        }
        else {
            await this.getComprehensivePlanData(planFK);
        }
    }

    async runDataCancel() {
        let modal: Modal = new Modal("confirmDataModal", null);
        modal.hide();
    }

    closeNoCompPlan() {
        const modal: Modal = new Modal("noCompPlanModal", null);
        modal.hide();
    }

    async runDataConfirm(e: Event) {
        let element = <HTMLButtonElement>e.target;
        let planFK = element.dataset.planfk;

        let modal: Modal = new Modal("confirmDataModal", null);
        modal.hide();

        await this.getComprehensivePlanData(planFK);
    }

    async getComprehensivePlanData(planFK) {
        Core.showLoader();

        let response = await fetch(`/FederalProgramsSSAE/ComprehensivePlanData/${planFK}`, { credentials: 'include' })
        if (response.ok) {
            let json = await response.json();

            if (json.pageSummary.noCompPlan === true) {
                Core.hideLoader();
                let modal: Modal = new Modal("noCompPlanModal", null);
                modal.show();
            } else {

                let strengthContainer = document.getElementById("strengths");
                let challengeContainer = document.getElementById("challenges");

                if (json.pageSummary.strengths !== null && json.pageSummary.strengths.length > 0) {
                    strengthContainer.innerHTML = "";
                    for (let strength of json.pageSummary.strengths) {

                        let divRow = <HTMLDivElement>document.createElement("div");
                        divRow.classList.add("row");

                        let divColumn = <HTMLDivElement>document.createElement("div");
                        divColumn.classList.add("medium-12");
                        divColumn.classList.add("column");

                        let textArea = <HTMLTextAreaElement>document.createElement("textarea");
                        textArea.rows = 3;
                        textArea.classList.add("federalSSAEWellRoundedField");
                        textArea.setAttribute("aria-label", "Strengths");
                        textArea.dataset.datapointpropertypk = strength.primaryPropertyFK;
                        textArea.dataset.datapointplanpropertypk = strength.primaryPlanPropertyFK;
                        textArea.dataset.percent = "0";
                        textArea.dataset.row = strength.rowNbr;
                        textArea.dataset.type = "strength";
                        textArea.dataset.propertypk = json.pageSummary.strengthPropertyPK;
                        textArea.dataset.planpropertypk = "0";
                        textArea.textContent = strength.textValue;
                        textArea.readOnly = true;

                        divColumn.appendChild(textArea);
                        divRow.appendChild(divColumn);
                        strengthContainer.appendChild(divRow);
                    }
                }

                if (json.pageSummary.challenges !== null && json.pageSummary.challenges.length > 0) {
                    challengeContainer.innerHTML = "";
                    for (let challenge of json.pageSummary.challenges) {
                        let divRow = <HTMLDivElement>document.createElement("div");
                        divRow.classList.add("row");

                        let divColumn = <HTMLDivElement>document.createElement("div");
                        divColumn.classList.add("medium-12");
                        divColumn.classList.add("column");

                        let textArea = <HTMLTextAreaElement>document.createElement("textarea");
                        textArea.rows = 3;
                        textArea.classList.add("federalSSAEWellRoundedField");
                        textArea.setAttribute("aria-label", "Challenges");
                        textArea.dataset.datapointpropertypk = challenge.primaryPropertyFK;
                        textArea.dataset.datapointplanpropertypk = challenge.primaryPlanPropertyFK;
                        textArea.dataset.percent = "0";
                        textArea.dataset.row = challenge.rowNbr;
                        textArea.dataset.type = "challenge";
                        textArea.dataset.propertypk = json.pageSummary.challengePropertyPK;
                        textArea.dataset.planpropertypk = "0";
                        textArea.textContent = challenge.textValue;
                        textArea.readOnly = true;

                        divColumn.appendChild(textArea);
                        divRow.appendChild(divColumn);
                        challengeContainer.appendChild(divRow);
                    }
                }

                if (json.pageSummary.notableObservations !== null) {
                    let textArea = <HTMLTextAreaElement>document.getElementById("federalSSAEWellRoundedNotable");
                    textArea.textContent = json.pageSummary.notableObservations.textValue;
                    textArea.dataset.datapointplanpropertypk = json.pageSummary.notableObservations.planPropertyPK;
                    textArea.dataset.row = "0";
                }

                let existingDataElement = <HTMLInputElement>document.getElementById("existingData");
                existingDataElement.value = "true";

                Core.hideLoader();

                Core.createHTMLAlert("alertMessageDiv", "Data from the comprehensive plan has been loaded. Please make sure to save the page.", "success", 3000, null);
            }
        }
    }
}

// FederalSSAESafeSchools
class FederalSSAESafeSchools {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["federalSSAESafeSchoolsField"];

        let federalSSAESafeSchoolsSaveButton = document.getElementById("federalSSAESafeSchoolsSave");
        if (federalSSAESafeSchoolsSaveButton !== null)
            federalSSAESafeSchoolsSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let federalSSAESafeSchoolAddDataButton = document.getElementById("federalSSAESafeSchoolAddData");
        if (federalSSAESafeSchoolAddDataButton !== null)
            federalSSAESafeSchoolAddDataButton.addEventListener("click", (e: Event) => this.addSafeSchoolRow());

        let safeSchoolsDeleteCancelButton = document.getElementById("safeSchoolsDeleteCancel");
        if (safeSchoolsDeleteCancelButton !== null)
            safeSchoolsDeleteCancelButton.addEventListener("click", (e: Event) => this.cancelDelete());

        let safeSchoolsDeleteConfirmButton = document.getElementById("safeSchoolsDeleteConfirm");
        if (safeSchoolsDeleteConfirmButton !== null)
            safeSchoolsDeleteConfirmButton.addEventListener("click", (e: Event) => this.confirmDelete(e));

        this.bindDeleteSafeSchoolsRow();

        let safeSchoolsDeleteSummaryCancelButton = document.getElementById("safeSchoolsSummaryDeleteCancel");
        if (safeSchoolsDeleteSummaryCancelButton !== null)
            safeSchoolsDeleteSummaryCancelButton.addEventListener("click", (e: Event) => this.cancelSummaryDelete());

        let safeSchoolsDeleteSummaryConfirmButton = document.getElementById("safeSchoolsSummaryDeleteConfirm");
        if (safeSchoolsDeleteSummaryConfirmButton !== null)
            safeSchoolsDeleteSummaryConfirmButton.addEventListener("click", (e: Event) => this.confirmSummaryDelete(e));

        this.bindDeleteSafeSchoolsSummaryRow();
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("federalSSAESafeSchoolsForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("federalSSAESafeSchoolsField");

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsSSAE/SaveFederalSSAESafeSchools', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    addSafeSchoolRow() {
        let planForm = document.getElementById("federalSSAESafeSchoolsForm");
        let planFK = planForm.dataset.planfk;
        let className = "federalSSAESafeSchoolsField";
        let pageCode = planForm.dataset.pagecode;
        let that = this;
        let newRow = 0;

        let allRows = document.querySelectorAll("#federalSSAESafeSchoolsData tbody tr");
        for (let row of allRows) {
            let rowEle = <HTMLTableRowElement>row;
            let thisRow = parseInt(rowEle.dataset.row);

            if (thisRow > newRow)
                newRow = thisRow;
        }

        newRow++;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsSSAE/AddSafeSchoolsDataRow', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                let newTR = <HTMLTableRowElement>document.createElement("tr");
                newTR.innerHTML = xhr.responseText;
                newTR.dataset.row = newRow.toString();

                let table = document.querySelector("#federalSSAESafeSchoolsData tbody");

                table.append(newTR);

                that.bindDeleteSafeSchoolsRow();

                Core.createHTMLAlert("alertMessageDiv", "Row added!", 'success', 2000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", `Request failed.  Returned status of '${xhr.status}'`, 'error', 3000, null);
                Core.hideLoader();
            }
        };
        xhr.send(`planFK=${planFK}&row=${newRow}&className=${className}&pageCode=${pageCode}`);
    }

    bindDeleteSafeSchoolsRow() {
        let deleteSafeSchoolsRowButtons = document.getElementsByClassName("deleteSafeSchoolsRow");
        for (let deleteSafeSchoolsRowButton of deleteSafeSchoolsRowButtons)
            deleteSafeSchoolsRowButton.addEventListener("click", (e: Event) => this.showDeleteSafeSchoolRow(e));
    }

    showDeleteSafeSchoolRow(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let row = buttonElement.dataset.row;

        let modal: Modal = new Modal("deleteSafeSchoolsRowModal", null);
        modal.addAttributeToElement("deleteSafeSchoolsRowModal", "#safeSchoolsDeleteConfirm", "row", row);
        modal.show();
    }

    cancelDelete() {
        let modal: Modal = new Modal("deleteSafeSchoolsRowModal", null);
        modal.hide();
    }

    confirmDelete(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let planProps = [];
        let row = buttonElement.dataset.row;

        let allElements = document.querySelectorAll(`#federalSSAESafeSchoolsData tbody tr[data-row='${row}'] .federalSSAESafeSchoolsField[data-row='${row}']`);
        for (let ele of allElements) {
            let element = <HTMLElement>ele;
            let planProp = element.dataset.planpropertypk;

            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsSSAE/DeleteSafeSchoolsDataRow', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();

                let rowElement = <HTMLTableRowElement>document.querySelector(`#federalSSAESafeSchoolsData tbody tr[data-row='${row}']`);
                rowElement.remove();

                let modal: Modal = new Modal("deleteSafeSchoolsRowModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The row has been removed.", 'success', 3000, null);
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }

    bindDeleteSafeSchoolsSummaryRow() {
        let deleteSafeSchoolsRowButtons = document.getElementsByClassName("deleteSafeSchoolsStrengthChallengeRow");
        for (let deleteSafeSchoolsRowButton of deleteSafeSchoolsRowButtons)
            deleteSafeSchoolsRowButton.addEventListener("click", (e: Event) => this.showDeleteSafeSchoolSummaryRow(e));
    }

    showDeleteSafeSchoolSummaryRow(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let row = buttonElement.dataset.row;
        let propertyCode = buttonElement.dataset.propertycode;

        let modal: Modal = new Modal("deleteSafeSchoolsSummaryRowModal", null);
        modal.addAttributeToElement("deleteSafeSchoolsSummaryRowModal", "#safeSchoolsSummaryDeleteConfirm", "row", row);
        modal.addAttributeToElement("deleteSafeSchoolsSummaryRowModal", "#safeSchoolsSummaryDeleteConfirm", "propertycode", propertyCode);
        modal.show();
    }

    cancelSummaryDelete() {
        let modal: Modal = new Modal("deleteSafeSchoolsSummaryRowModal", null);
        modal.hide();
    }

    confirmSummaryDelete(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let planProps = [];
        let row = buttonElement.dataset.row;
        let propertyCode = buttonElement.dataset.propertycode;

        let element = <HTMLTextAreaElement>document.querySelector(`.federalSSAESafeSchoolsField[data-propertycode='${propertyCode}'][data-row='${row}']`);
        let planProp = element.dataset.planpropertypk;

        if (planProp && parseInt(planProp) > 0) {
            planProps.push(planProp);
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsSSAE/DeleteSafeSchoolsDataRow', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();

                let rowElement = <HTMLTextAreaElement>document.querySelector(`.federalSSAESafeSchoolsField[data-propertycode='${propertyCode}'][data-row='${row}']`);
                rowElement.textContent = "";
                rowElement.innerText = "";
                rowElement.value = "";
                rowElement.dataset.planpropertypk = "0";

                let modal: Modal = new Modal("deleteSafeSchoolsSummaryRowModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The row data has been removed.", 'success', 3000, null);
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }
}

// FederalSSAEEffectiveTech
class FederalSSAEEffectiveTech {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["federalSSAEEffectiveTechField"];

        let federalSSAEEffectiveTechSaveButton = document.getElementById("federalSSAEEffectiveTechSave");
        if (federalSSAEEffectiveTechSaveButton !== null)
            federalSSAEEffectiveTechSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let federalSSAEEffectiveTechAddDataButton = document.getElementById("federalSSAEEffectiveTechAddData");
        if (federalSSAEEffectiveTechAddDataButton !== null)
            federalSSAEEffectiveTechAddDataButton.addEventListener("click", (e: Event) => this.addEffectiveTechRow());

        let safeSchoolsDeleteCancelButton = document.getElementById("safeSchoolsDeleteCancel");
        if (safeSchoolsDeleteCancelButton !== null)
            safeSchoolsDeleteCancelButton.addEventListener("click", (e: Event) => this.cancelDataRowDelete());

        let safeSchoolsDeleteConfirmButton = document.getElementById("safeSchoolsDeleteConfirm");
        if (safeSchoolsDeleteConfirmButton !== null)
            safeSchoolsDeleteConfirmButton.addEventListener("click", (e: Event) => this.confirmDataRowDelete(e));

        this.bindDeleteEffectiveTechDataRow();

        let safeSchoolsDeleteSummaryCancelButton = document.getElementById("safeSchoolsSummaryDeleteCancel");
        if (safeSchoolsDeleteSummaryCancelButton !== null)
            safeSchoolsDeleteSummaryCancelButton.addEventListener("click", (e: Event) => this.cancelEffectiveTechSummaryDelete());

        let safeSchoolsDeleteSummaryConfirmButton = document.getElementById("safeSchoolsSummaryDeleteConfirm");
        if (safeSchoolsDeleteSummaryConfirmButton !== null)
            safeSchoolsDeleteSummaryConfirmButton.addEventListener("click", (e: Event) => this.confirmEffectiveTechSummaryDelete(e));

        this.bindDeleteEffectiveTechSummaryRow();
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("federalSSAEEffectiveTechForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("federalSSAEEffectiveTechField");

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsSSAE/SaveFederalSSAEEffectiveTech', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    addEffectiveTechRow() {
        let planForm = document.getElementById("federalSSAEEffectiveTechForm");
        let planFK = planForm.dataset.planfk;
        let className = "federalSSAEEffectiveTechField";
        let pageCode = planForm.dataset.pagecode;
        let that = this;
        let newRow = 0;

        let allRows = document.querySelectorAll("#federalSSAEEffectiveTechData tbody tr");
        for (let row of allRows) {
            let rowEle = <HTMLTableRowElement>row;
            let thisRow = parseInt(rowEle.dataset.row);

            if (thisRow > newRow)
                newRow = thisRow;
        }

        newRow++;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsSSAE/AddSafeSchoolsDataRow', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                let newTR = <HTMLTableRowElement>document.createElement("tr");
                newTR.innerHTML = xhr.responseText;
                newTR.dataset.row = newRow.toString();

                let table = document.querySelector("#federalSSAEEffectiveTechData tbody");

                table.append(newTR);

                that.bindDeleteEffectiveTechDataRow();

                Core.createHTMLAlert("alertMessageDiv", "Row added!", 'success', 2000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", `Request failed.  Returned status of '${xhr.status}'`, 'error', 3000, null);
                Core.hideLoader();
            }
        };
        xhr.send(`planFK=${planFK}&row=${newRow}&className=${className}&pageCode=${pageCode}`);
    }

    bindDeleteEffectiveTechDataRow() {
        let deleteEffectiveTechRowButtons = document.getElementsByClassName("deleteSafeSchoolsRow");
        for (let deleteEffectiveTechRowButton of deleteEffectiveTechRowButtons)
            deleteEffectiveTechRowButton.addEventListener("click", (e: Event) => this.showDeleteEffectiveTechDataRow(e));
    }

    showDeleteEffectiveTechDataRow(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let row = buttonElement.dataset.row;

        let modal: Modal = new Modal("deleteEffectiveTechRowModal", null);
        modal.addAttributeToElement("deleteEffectiveTechRowModal", "#safeSchoolsDeleteConfirm", "row", row);
        modal.show();
    }

    cancelDataRowDelete() {
        let modal: Modal = new Modal("deleteEffectiveTechRowModal", null);
        modal.hide();
    }

    confirmDataRowDelete(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let planProps = [];
        let row = buttonElement.dataset.row;

        let allElements = document.querySelectorAll(`#federalSSAEEffectiveTechData tbody tr[data-row='${row}'] .federalSSAESafeSchoolsField[data-row='${row}']`);
        for (let ele of allElements) {
            let element = <HTMLElement>ele;
            let planProp = element.dataset.planpropertypk;

            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsSSAE/DeleteSafeSchoolsDataRow', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();

                let rowElement = <HTMLTableRowElement>document.querySelector(`#federalSSAEEffectiveTechData tbody tr[data-row='${row}']`);
                rowElement.remove();

                let modal: Modal = new Modal("deleteEffectiveTechRowModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The row has been removed.", 'success', 3000, null);
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }

    bindDeleteEffectiveTechSummaryRow() {
        let deleteSafeSchoolsRowButtons = document.getElementsByClassName("deleteEffectiveTechStrengthChallengeRow");
        for (let deleteSafeSchoolsRowButton of deleteSafeSchoolsRowButtons)
            deleteSafeSchoolsRowButton.addEventListener("click", (e: Event) => this.showDeleteEffectiveTechSummaryRow(e));
    }

    showDeleteEffectiveTechSummaryRow(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let row = buttonElement.dataset.row;
        let propertyCode = buttonElement.dataset.propertycode;

        let modal: Modal = new Modal("deleteEffectiveTechSummaryRowModal", null);
        modal.addAttributeToElement("deleteEffectiveTechSummaryRowModal", "#safeSchoolsSummaryDeleteConfirm", "row", row);
        modal.addAttributeToElement("deleteEffectiveTechSummaryRowModal", "#safeSchoolsSummaryDeleteConfirm", "propertycode", propertyCode);
        modal.show();
    }

    cancelEffectiveTechSummaryDelete() {
        let modal: Modal = new Modal("deleteEffectiveTechSummaryRowModal", null);
        modal.hide();
    }

    confirmEffectiveTechSummaryDelete(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let planProps = [];
        let row = buttonElement.dataset.row;
        let propertyCode = buttonElement.dataset.propertycode;

        let element = <HTMLTextAreaElement>document.querySelector(`.federalSSAEEffectiveTechField[data-propertycode='${propertyCode}'][data-row='${row}']`);
        let planProp = element.dataset.planpropertypk;

        if (planProp && parseInt(planProp) > 0) {
            planProps.push(planProp);
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsSSAE/DeleteSafeSchoolsDataRow', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();

                let rowElement = <HTMLTextAreaElement>document.querySelector(`.federalSSAEEffectiveTechField[data-propertycode='${propertyCode}'][data-row='${row}']`);
                rowElement.textContent = "";
                rowElement.innerText = "";
                rowElement.value = "";
                rowElement.dataset.planpropertypk = "0";

                let modal: Modal = new Modal("deleteEffectiveTechSummaryRowModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The row data has been removed.", 'success', 3000, null);
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }
}

// FederalSSAESummaryOfStrengths
class FederalSSAESummaryOfStrengths {
    validationClasses: string[];

    private _core: Core;
    private uncheckChallengeForConsiderationModal: Modal;
    private uncheckStrengthForConsiderationModal: Modal;
    constructor() {
        this._core = new Core();

        this.uncheckChallengeForConsiderationModal = new Modal("uncheckChallengeForConsiderationModal", null);
        this.uncheckStrengthForConsiderationModal = new Modal("uncheckStrengthForConsiderationModal", null);

        let that = this;
        let uncheckChallengeConfirmButton = document.getElementById("uncheckChallengeForConsiderationConfirm");
        if (uncheckChallengeConfirmButton !== null) {
            uncheckChallengeConfirmButton.addEventListener("click", function () {
                if ("challengecheckplanpropertypk" in uncheckChallengeConfirmButton.dataset && parseInt(uncheckChallengeConfirmButton.dataset.challengecheckplanpropertypk) > 0) {
                    that.uncheckChallengeForConsideration(uncheckChallengeConfirmButton.dataset.challengecheckplanpropertypk);
                } else {
                    that.uncheckChallengeForConsiderationModal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error unchecking this challenge for consideration", "error", 3000, null);
                }
            });
        }

        const uncheckChallengeCancelButton = document.getElementById("uncheckChallengeForConsiderationCancel");
        if (uncheckChallengeCancelButton !== null) {
            uncheckChallengeCancelButton.addEventListener("click", function () {
                that.uncheckChallengeForConsiderationModal.hide();

                if (uncheckChallengeCancelButton.dataset.challengecheckplanpropertypk) {
                    const checkbox = document.querySelector(`[data-planpropertypk='${uncheckChallengeCancelButton.dataset.challengecheckplanpropertypk}']`) as HTMLInputElement;
                    if (checkbox != null) {
                        checkbox.checked = true;
                    }
                }
            });
        }

        const uncheckStrengthConfirmButton = document.getElementById("uncheckStrengthForConsiderationConfirm");
        if (uncheckStrengthConfirmButton !== null) {
            uncheckStrengthConfirmButton.addEventListener("click", function () {
                if ("strengthcheckplanpropertypk" in uncheckStrengthConfirmButton.dataset && parseInt(uncheckStrengthConfirmButton.dataset.strengthcheckplanpropertypk) > 0) {
                    that.uncheckStrengthForConsideration(uncheckStrengthConfirmButton.dataset.strengthcheckplanpropertypk);
                } else {
                    that.uncheckStrengthForConsiderationModal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error unchecking this strength for consideration", "error", 3000, null);
                }
            });
        }

        const uncheckStrengthCancelButton = document.getElementById("uncheckStrengthForConsiderationCancel");
        if (uncheckStrengthCancelButton !== null) {
            uncheckStrengthCancelButton.addEventListener("click", function () {
                that.uncheckStrengthForConsiderationModal.hide();

                if (uncheckStrengthCancelButton.dataset.strengthcheckplanpropertypk) {
                    const checkbox = document.querySelector(`[data-planpropertypk='${uncheckStrengthCancelButton.dataset.strengthcheckplanpropertypk}']`) as HTMLInputElement;
                    if (checkbox != null) {
                        checkbox.checked = true;
                    }
                }
            });
        }

        this.validationClasses = ["federalSSAESummaryOfStrengthsCheckboxField", "federalSSAESummaryOfChallengesCheckboxField"];

        let federalSSAEAnalyzingSaveButton = document.getElementById("federalSSAESummaryOfStrengthsSave");
        if (federalSSAEAnalyzingSaveButton !== null)
            federalSSAEAnalyzingSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.customDoValidation(this.validationClasses);
        }
        this.customInitializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        const checkboxes = document.getElementsByClassName("checkboxField");
        for (let checkbox of checkboxes)
            checkbox.addEventListener("click", (e: Event) => this.checkForReadonly(e));
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allStrengthElements = [];
        let allChallengeElements = [];
        let observationElement: IPlanProperty;
        let planElement = document.getElementById("federalSSAESummaryOfStrengthsForm");
        let planFK = planElement.dataset.planfk;

        let allElements = document.getElementsByClassName("federalSSAESummaryOfStrengthsCheckboxField");
        for (let element of allElements) {
            let htmlElement = <HTMLInputElement>element;
            let primaryPlanPropertyPK: number = 0;
            let secondaryPlanPropertyPK: number = 0;
            let rowNumber: number = parseInt(htmlElement.dataset.row);

            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                secondaryPlanPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }

            if (htmlElement.dataset.primaryplanpropertypk && htmlElement.dataset.primaryplanpropertypk !== "0") {
                primaryPlanPropertyPK = parseInt(htmlElement.dataset.primaryplanpropertypk);
            }

            let val = "off";
            if (htmlElement.checked) {
                val = "on";
            }

            if (!(secondaryPlanPropertyPK === 0 && val === "off")) {  //If the planpropertypk = 0 AND it's not checked, we don't need to save it.
                let saveItem: ISSAECheckboxModel = {
                    PrimaryPlanPropertyPK: primaryPlanPropertyPK,
                    SecondaryPlanPropertyPK: secondaryPlanPropertyPK,
                    PlanFK: parseInt(planFK),
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: rowNumber,
                    IsDeletedInd: false,
                };

                allStrengthElements.push(saveItem);
            }
        }

        allElements = document.getElementsByClassName("federalSSAESummaryOfChallengesCheckboxField");
        for (let element of allElements) {
            let htmlElement = <HTMLInputElement>element;
            let primaryPlanPropertyPK: number = 0;
            let secondaryPlanPropertyPK: number = 0;
            let rowNumber: number = parseInt(htmlElement.dataset.row);

            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                secondaryPlanPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }

            if (htmlElement.dataset.primaryplanpropertypk && htmlElement.dataset.primaryplanpropertypk !== "0") {
                primaryPlanPropertyPK = parseInt(htmlElement.dataset.primaryplanpropertypk);
            }

            let val = "off";
            if (htmlElement.checked) {
                val = "on";
            }

            if (!(secondaryPlanPropertyPK === 0 && val === "off")) {  //If the planpropertypk = 0 AND it's not checked, we don't need to save it.
                let saveItem: ISSAECheckboxModel = {
                    PrimaryPlanPropertyPK: primaryPlanPropertyPK,
                    SecondaryPlanPropertyPK: secondaryPlanPropertyPK,
                    PlanFK: parseInt(planFK),
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: rowNumber,
                    IsDeletedInd: false,
                };

                allChallengeElements.push(saveItem);
            }
        }

        let observationsElements = document.getElementsByClassName("federalSSAESummaryOfStrengthsObservationsField");
        for (let element of observationsElements) {
            let htmlElement = <HTMLInputElement>element;
            let planPropertyPK: number = 0;
            let rowNumber: number = 0;

            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }

            let saveItem: IPlanProperty = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: parseInt(planFK),
                PropertyFK: parseInt(htmlElement.dataset.propertypk),
                TextValue: htmlElement.value,
                LookupCodeFK: null,
                RowNbr: rowNumber,
                IsDeletedInd: false
            };

            observationElement = saveItem;
        }

        let allSaveElements = {
            "Strengths": allStrengthElements,
            "Challenges": allChallengeElements,
            "Observations": observationElement,
            "PlanFK": planFK
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsSSAE/SaveFederalSSAESummaryOfStrengths', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    core.pageReload(true, parseInt(planFK));
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                    Core.hideLoader();
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                    Core.hideLoader();
                }
            }
        };
        if (allChallengeElements.length === 0 && allStrengthElements.length === 0 && allSaveElements.Observations.TextValue === "") {
            if (referrer === "save") {
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'error', 3000, null);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    customDoValidation(allClasses: string[], showMessageOverride?: boolean) {
        let showMessage = showMessageOverride === undefined ? this.customClientSideValidation(allClasses) : showMessageOverride;
        let messageContainerColumn = <HTMLElement>document.getElementById("validationColumn");
        let messageContainer = <HTMLElement>document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = <HTMLElement>document.getElementById("validationMessageIcon");

        setTimeout(function () {
            messageContainer.focus();
        }, 500);

        if (showMessage) {

            let message = <HTMLElement>document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";

        } else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";

            let successMessage = <HTMLElement>document.getElementById("saveSuccess");

            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved."
            }
        }
    }

    customClientSideValidation(allClasses: string[]): boolean {
        let showMessage: boolean = false;
        let totalErrors = 0;

        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });

        let classesToValidate = formattedAllClasses.join(",");

        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });

        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });

        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });

        //Check to see if at least two challenges have been checked for consideration
        let challengeCheckboxes = document.getElementsByClassName("federalSSAESummaryOfChallengesCheckboxField") as HTMLCollectionOf<HTMLInputElement>;
        let challengeCheckedCount = 0;

        for (let check of challengeCheckboxes) {
            if (check.checked) {
                challengeCheckedCount++;
            }
        }

        if (challengeCheckedCount < 2) {
            totalErrors++;
            showMessage = true;
        }

        //Check to see if at least one strength has been checked for consideration
        let strengthCheckboxes = document.getElementsByClassName("federalSSAESummaryOfStrengthsCheckboxField") as HTMLCollectionOf<HTMLInputElement>;
        let strengthCheckedCount = 0;

        for (let check of strengthCheckboxes) {
            if (check.checked) {
                strengthCheckedCount++;
            }
        }

        if (strengthCheckedCount === 0) {
            totalErrors++;
            showMessage = true;
        }

        let message = <HTMLDivElement>document.getElementById("validationMessage");

        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        } else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");

        if (goToError !== null) {
            let firstFocusableEl = <HTMLElement>document.querySelector(".missing-field");

            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");

                        let accordionElement = <HTMLButtonElement>document.getElementById(id);
                        accordionElement.click();
                    }

                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    } else {
                        firstFocusableEl.focus();
                    }
                });
            } else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }

    customInitializeRequiredFields(allClasses: string[], refresh: boolean = false, allowDuplicates = false) {
        this._core.initializeRequiredFields(allClasses);
    }

    uncheckChallengeForConsideration(planPropertyPK: string) {
        let that = this;
        that.uncheckChallengeForConsiderationModal.hide();

        let checkbox = document.querySelector(`[data-planpropertypk='${planPropertyPK}']`) as HTMLInputElement;

        if (checkbox != null && "primaryplanpropertypk" in checkbox.dataset) {
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/FederalProgramsSSAE/UncheckChallengeForConsideration', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                    Core.createHTMLAlert("alertMessageDiv", "Challenge successfully unchecked for consideration", 'success', 3000, null);

                    //Uncheck checkbox and reset values
                    checkbox.checked = false;
                    checkbox.dataset.initiallychecked = "false";
                    checkbox.dataset.planpropertypk = "0";
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error unchecking challenge for consideration. Please try again later.", "error", 3000, null);
                }
                Core.hideLoader();
            };
            xhr.send(checkbox.dataset.primaryplanpropertypk);
        } else {
            Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error unchecking challenge for consideration. Please try again later.", "error", 3000, null);
        }
    }

    uncheckStrengthForConsideration(planPropertyPK: string) {
        let that = this;
        that.uncheckStrengthForConsiderationModal.hide();

        let checkbox = document.querySelector(`[data-planpropertypk='${planPropertyPK}']`) as HTMLInputElement;

        if (checkbox != null && "primaryplanpropertypk" in checkbox.dataset) {
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/FederalProgramsSSAE/UncheckStrengthForConsideration', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                    Core.createHTMLAlert("alertMessageDiv", "Strength successfully unchecked for consideration", 'success', 3000, null);

                    //Uncheck checkbox and reset values
                    if (checkbox != null) {
                        checkbox.checked = false;
                        checkbox.dataset.initiallychecked = "false";
                        checkbox.dataset.planpropertypk = "0";
                    }
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error unchecking strength for consideration. Please try again later.", "error", 3000, null);
                }
                Core.hideLoader();
            };
            xhr.send(checkbox.dataset.primaryplanpropertypk);
        } else {
            Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error unchecking strength for consideration. Please try again later.", "error", 3000, null);
        }
    }

    checkForReadonly(e: Event) {
        let checkbox = <HTMLInputElement>e.target;

        if (checkbox.readOnly) {
            if (checkbox.checked)
                checkbox.checked = false;
            else
                checkbox.checked = true;

            return false;
        } else {
            if ("propertycode" in checkbox.dataset && checkbox.dataset.propertycode == "federalSSAESummaryOfStrengthStrengthCheckForConsideration") {
                //if has a plan property and has been unchecked, show warning that things down the line will be affected
                if (checkbox.dataset.planpropertypk && parseInt(checkbox.dataset.planpropertypk) > 0 && !checkbox.checked && "initiallychecked" in checkbox.dataset && checkbox.dataset.initiallychecked == "true") {
                    e.preventDefault();
                    this.uncheckStrengthForConsiderationModal.show();
                    this.uncheckStrengthForConsiderationModal.addAttributeToElement(this.uncheckStrengthForConsiderationModal.id, "#uncheckStrengthForConsiderationConfirm", "strengthcheckplanpropertypk", checkbox.dataset.planpropertypk);
                    this.uncheckStrengthForConsiderationModal.addAttributeToElement(this.uncheckStrengthForConsiderationModal.id, "#uncheckStrengthForConsiderationCancel", "strengthcheckplanpropertypk", checkbox.dataset.planpropertypk);

                    if (checkbox.id) {
                        this.uncheckStrengthForConsiderationModal.callingId = checkbox.id;
                    }
                }
            } else if ("propertycode" in checkbox.dataset && checkbox.dataset.propertycode == "federalSSAESummaryOfStrengthChallengeCheckForConsideration") {
                //if has a plan property and has been unchecked, show warning that things down the line will be affected
                if (checkbox.dataset.planpropertypk && parseInt(checkbox.dataset.planpropertypk) > 0 && !checkbox.checked && "initiallychecked" in checkbox.dataset && checkbox.dataset.initiallychecked == "true") {
                    e.preventDefault();
                    this.uncheckChallengeForConsiderationModal.show();
                    this.uncheckChallengeForConsiderationModal.addAttributeToElement(this.uncheckChallengeForConsiderationModal.id, "#uncheckChallengeForConsiderationConfirm", "challengecheckplanpropertypk", checkbox.dataset.planpropertypk);
                    this.uncheckChallengeForConsiderationModal.addAttributeToElement(this.uncheckChallengeForConsiderationModal.id, "#uncheckChallengeForConsiderationCancel", "challengecheckplanpropertypk", checkbox.dataset.planpropertypk);

                    if (checkbox.id) {
                        this.uncheckChallengeForConsiderationModal.callingId = checkbox.id;
                    }
                }
            }
        }
    }
}

// FederalSSAEAnalyzing
class FederalSSAEAnalyzing {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["federalSSAEAnalyzingField", "federalSSAEAnalyzingCheckboxField"];

        let federalSSAEAnalyzingSaveButton = document.getElementById("federalSSAEAnalyzingSave");
        if (federalSSAEAnalyzingSaveButton !== null)
            federalSSAEAnalyzingSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        const federalSSAEAnalyzingChallengesPriorityChallengeCheckboxes = document.getElementsByClassName("federalSSAEAnalyzingChallengesPriorityChallenge");
        for (const federalSSAEAnalyzingChallengesPriorityChallengeCheckbox of federalSSAEAnalyzingChallengesPriorityChallengeCheckboxes)
            federalSSAEAnalyzingChallengesPriorityChallengeCheckbox.addEventListener("change", (e: Event) => this.checkPriorityChallenge(e));

        let deletePriorityRowCancelButton = document.getElementById("deleteAnalyzingPriorityCancel");
        if (deletePriorityRowCancelButton !== null)
            deletePriorityRowCancelButton.addEventListener("click", (e: Event) => this.priorityChallengeDeleteCancel());

        let deletePriorityRowConfirmButton = document.getElementById("deleteAnalyzingPriorityConfirm");
        if (deletePriorityRowConfirmButton !== null)
            deletePriorityRowConfirmButton.addEventListener("click", (e: Event) => this.priorityChallengeDeleteConfirm(e));
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        const allStrengthElements = [];
        const allChallengeElements = [];
        const allChallengeCheckElements = [];
        const allPriorityChallengeElements = [];
        const core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        const formElement = document.getElementById("federalSSAEAnalyzingForm");
        const planFK = parseInt(formElement.dataset.planfk);

        //Get all the textboxes for challenges
        const challengeElements = document.querySelectorAll(".federalSSAEAnalyzingField[data-descriptor='challenge']");
        for (let element of challengeElements) {
            let htmlElement = <HTMLInputElement>element;

            let planPropertyPK: number = 0;
            let dataPointPlanPropertyPK: number = 0;

            if (htmlElement.dataset.planpropertypk !== "")
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);

            if (htmlElement.dataset.datapointplanpropertypk !== "")
                dataPointPlanPropertyPK = parseInt(htmlElement.dataset.datapointplanpropertypk);

            let thisData: ISSAEAnalyzingStrengthsAndChallenges = {
                RowNbr: parseInt(htmlElement.dataset.row),
                TextValue: htmlElement.value,
                PropertyFK: parseInt(htmlElement.dataset.propertypk),
                PlanPropertyPK: planPropertyPK,
                DataPointPropertyPK: parseInt(htmlElement.dataset.datapointpropertypk),
                DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                PlanFK: planFK
            };
             
            allChallengeElements.push(thisData);
        }

        //Get all the checkboxes for challenges
        const challengeCheckElements = document.querySelectorAll(".federalSSAEAnalyzingCheckboxField[data-descriptor='challenge']");
        for (let element of challengeCheckElements) {
            let htmlElement = <HTMLInputElement>element;

            let planPropertyPK: number = 0;
            let dataPointPlanPropertyPK: number = 0;

            if (htmlElement.dataset.planpropertypk !== "")
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);

            if (htmlElement.dataset.datapointplanpropertypk !== "")
                dataPointPlanPropertyPK = parseInt(htmlElement.dataset.datapointplanpropertypk);

            let checkValue: string = "";
            if (htmlElement.checked)
                checkValue = "on";

            let thisData: ISSAEAnalyzingStrengthsAndChallenges = {
                RowNbr: parseInt(htmlElement.dataset.row),
                TextValue: checkValue,
                PropertyFK: parseInt(htmlElement.dataset.propertypk),
                PlanPropertyPK: planPropertyPK,
                DataPointPropertyPK: parseInt(htmlElement.dataset.datapointpropertypk),
                DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                PlanFK: planFK
            };

            allChallengeCheckElements.push(thisData);
        }

        //Get all the textboxes for Strengths
        const strengthElements = document.querySelectorAll(".federalSSAEAnalyzingField[data-descriptor='strength']");
        for (let element of strengthElements) {
            let htmlElement = <HTMLInputElement>element;

            let planPropertyPK: number = 0;
            let dataPointPlanPropertyPK: number = 0;

            if (htmlElement.dataset.planpropertypk !== "")
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);

            if (htmlElement.dataset.datapointplanpropertypk !== "")
                dataPointPlanPropertyPK = parseInt(htmlElement.dataset.datapointplanpropertypk);

            let thisData: ISSAEAnalyzingStrengthsAndChallenges = {
                RowNbr: parseInt(htmlElement.dataset.row),
                TextValue: htmlElement.value,
                PropertyFK: parseInt(htmlElement.dataset.propertypk),
                PlanPropertyPK: planPropertyPK,
                DataPointPropertyPK: parseInt(htmlElement.dataset.datapointpropertypk),
                DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                PlanFK: planFK
            };

            allStrengthElements.push(thisData);
        }

        //Get all the textboxes for Priority Statements
        const priorityElements = document.querySelectorAll(".federalSSAEAnalyzingField[data-descriptor='priority']");
        for (let element of priorityElements) {
            let htmlElement = <HTMLInputElement>element;

            let planPropertyPK: number = 0;
            let dataPointPlanPropertyPK: number = 0;
            let challengePointPlanPropertyPK: number = 0;

            if (htmlElement.dataset.planpropertypk !== "")
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);

            if (htmlElement.dataset.datapointplanpropertypk !== "")
                dataPointPlanPropertyPK = parseInt(htmlElement.dataset.datapointplanpropertypk);

            if (htmlElement.dataset.challengepointplanpropertypk !== "")
                challengePointPlanPropertyPK = parseInt(htmlElement.dataset.challengepointplanpropertypk);

            let thisData: ISSAEAnalyzingStrengthsAndChallenges = {
                RowNbr: parseInt(htmlElement.dataset.row),
                TextValue: htmlElement.value,
                PropertyFK: parseInt(htmlElement.dataset.propertypk),
                PlanPropertyPK: planPropertyPK,
                DataPointPropertyPK: parseInt(htmlElement.dataset.datapointpropertypk),
                DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                PlanFK: planFK,
                ChallengePointPlanPropertyPK: challengePointPlanPropertyPK
            };

            allPriorityChallengeElements.push(thisData);
        }

        const allData = {
            "Strengths": allStrengthElements,
            "Challenges": allChallengeElements,
            "ChallengeChecks": allChallengeCheckElements,
            "PriorityChallenges": allPriorityChallengeElements,
            "PlanFK": planFK
        };

        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsSSAE/SaveFederalSSAEAnalyzing', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planFK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        xhr.send(JSON.stringify(allData));
    }

    async checkPriorityChallenge(e: Event) {
        const formElement = document.getElementById("federalSSAEAnalyzingForm");
        const planFK = formElement.dataset.planfk;

        const checkbox = <HTMLInputElement>e.target;
        if (checkbox.disabled) {
            return false;
        } else {
            const challengePointPlanPropertyPK = checkbox.dataset.datapointplanpropertypk;
            const challengePointElement = <HTMLInputElement>document.querySelector(`.federalSSAEAnalyzingField[data-planpropertypk='${challengePointPlanPropertyPK}']`);
            const planPropertyPK = checkbox.dataset.planpropertypk;

            if (checkbox.checked) {
                const dataModel = {
                    "ChallengePointPlanPropertyPK": challengePointPlanPropertyPK,
                    "PlanFK": planFK
                };

                const settings = {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(dataModel)
                };

                const response = await fetch(`/FederalProgramsSSAE/PriorityChallengesRow`, settings);
                if (response.ok) {
                    const output = await response.text();
                    const tableElement = document.querySelector("#priorityChallenges tbody");
                    const trEle = <HTMLTableRowElement>document.createElement("tr");
                    trEle.innerHTML = output;
                    tableElement.appendChild(trEle);

                    Core.createHTMLAlert("alertMessageDiv", "New Priority Challenge row added!", "success", 3000, null);
                }

                this.checkLimits();
            } else {
                let modal: Modal = new Modal("deleteAnalyzingPriorityModal", null);
                modal.addAttributeToElement("deleteAnalyzingPriorityModal", "#deleteAnalyzingPriorityConfirm", "challengepointplanpropertypk", challengePointPlanPropertyPK);
                modal.addAttributeToElement("deleteAnalyzingPriorityModal", "#deleteAnalyzingPriorityConfirm", "checkboxplanpropertypk", planPropertyPK);
                modal.show();              
            }
        }
    }

    async priorityChallengeDeleteConfirm(e: Event) {
        const button = <HTMLButtonElement>e.target;
        const challengePointPlanPropertyPK = button.dataset.challengepointplanpropertypk;
        const checkboxPlanPropertyPK = button.dataset.checkboxplanpropertypk;

        //Check to see if the corresponding element is saved, if it is, throw the modal up for a delete, because it will have to delete the data.
        const priorityChallengeElement = <HTMLTextAreaElement>document.querySelector(`.federalSSAEAnalyzingPriorityChallengeField[data-prioritychallengeselected='true']`);
        const priorityChallengeDiscussionElement = <HTMLTextAreaElement>document.querySelector(`.federalSSAEAnalyzingPriorityChallengeField[data-prioritychallengediscussion='true'][data-challengepointplanpropertypk='${challengePointPlanPropertyPK}']`);

        let removeIt: boolean = true;
        const priorityChallengePlanPropertyPK = priorityChallengeDiscussionElement.dataset.planpropertypk;
        if (priorityChallengePlanPropertyPK !== "0") {

            const response = await fetch(`/FederalProgramsSSAE/DeletePriorityChallenge/${priorityChallengePlanPropertyPK}/${checkboxPlanPropertyPK}`, { credentials: 'include' })
            if (response.ok) {
                const success = await response.text();
                if (success === "true")
                    removeIt = true;
                else if (success === "false")
                    removeIt = false;
            } else {
                removeIt = false;
            }

            let modal: Modal = new Modal("deleteAnalyzingPriorityModal", null);
            modal.hide();
        }

        if (removeIt) {
            priorityChallengeDiscussionElement.parentElement.parentElement.remove();
            Core.createHTMLAlert("alertMessageDiv", "The priority challenge has been removed.", "success", 3000, null);
        } else {
            Core.createHTMLAlert("alertMessageDiv", "There was an issue removing this record. Please try again.", "success", 3000, null);
        }
    }

    priorityChallengeDeleteCancel() {
        let modal: Modal = new Modal("deleteAnalyzingPriorityModal", null);
        modal.hide();
    }

    checkLimits() {
        const federalSSAEAnalyzingChallengesPriorityChallengeCheckboxes = document.getElementsByClassName("federalSSAEAnalyzingChallengesPriorityChallenge");
        let checkedCount: number = 0;
        const maxChecked: number = 4;
        for (const federalSSAEAnalyzingChallengesPriorityChallengeCheckbox of federalSSAEAnalyzingChallengesPriorityChallengeCheckboxes) {
            const ele = <HTMLInputElement>federalSSAEAnalyzingChallengesPriorityChallengeCheckbox;

            if (ele.checked)
                checkedCount++;
        } 

        if (checkedCount == maxChecked) {
            for (const federalSSAEAnalyzingChallengesPriorityChallengeCheckbox of federalSSAEAnalyzingChallengesPriorityChallengeCheckboxes) {
                const ele = <HTMLInputElement>federalSSAEAnalyzingChallengesPriorityChallengeCheckbox;
                if (!ele.checked)
                    ele.disabled = true;
            }
        } else {
            for (const federalSSAEAnalyzingChallengesPriorityChallengeCheckbox of federalSSAEAnalyzingChallengesPriorityChallengeCheckboxes) {
                const ele = <HTMLInputElement>federalSSAEAnalyzingChallengesPriorityChallengeCheckbox;
                ele.disabled = false;
            }
        }
    }
}

// FederalSSAEGoalSetting
class FederalSSAEGoalSetting {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["federalSSAEGoalSettingField", "federalSSAEGoalSettingSelectField"];

        let federalSSAEGoalSettingSaveButton = document.getElementById("federalSSAEGoalSettingSave");
        if (federalSSAEGoalSettingSaveButton !== null)
            federalSSAEGoalSettingSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        const measurableGoalInputs = document.getElementsByClassName("measurableGoalStatement");
        for (const measurableGoalInput of measurableGoalInputs)
            measurableGoalInput.addEventListener("input", (e: Event) => this.changeTargetYear4(e));

        const deleteGoals = document.getElementsByClassName("deleteGoal");
        for (const deleteGoal of deleteGoals)
            deleteGoal.addEventListener("click", (e: Event) => this.showDelete(e));

        const federalSSAEGoalSettingDeleteCancel = document.getElementById("federalSSAEGoalSettingDeleteCancel");
        if (federalSSAEGoalSettingDeleteCancel !== null)
            federalSSAEGoalSettingDeleteCancel.addEventListener("click", (e: Event) => this.cancelDelete());

        const federalSSAEGoalSettingDeleteConfirm = document.getElementById("federalSSAEGoalSettingDeleteConfirm");
        if (federalSSAEGoalSettingDeleteConfirm !== null)
            federalSSAEGoalSettingDeleteConfirm.addEventListener("click", (e: Event) => this.confirmDelete(e));
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("federalSSAEGoalSettingForm");
        let planPK = parseInt(formElement.dataset.planfk);

        const allSmartGoals = [];
        const smartGoals = document.querySelectorAll(".federalSSAEGoalSettingField[data-smartgoal='true']");
        for (const smart of smartGoals) {
            const element = <HTMLInputElement>smart;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            const dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            const propertyRelationTypePK = parseInt(element.dataset.propertyrelationtypepk);
            let planPropertyPK = 0;

            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                const saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    PropertyRelationTypePK: propertyRelationTypePK,
                    DataPointPlanPropertyPK: dataPointPlanPropertyPK
                };

                allSmartGoals.push(saveItem);
            }
        }

        for (const smartGoal of allSmartGoals) {
            const row = smartGoal.RowNbr;
            const dataPointPlanPropertyPK = smartGoal.DataPointPlanPropertyPK;

            const allOtherFields = [];

            const allTheseInputElements = document.querySelectorAll(`.federalSSAEGoalSettingField[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
            for (const ele of allTheseInputElements) {
                const thisEle = <HTMLInputElement>ele;

                // We don't want the smart goal to be added to this list, since we're pulling it separately.
                if (!(thisEle.dataset.smartgoal && thisEle.dataset.smartgoal === "true")) {
                    const rowNumber = thisEle.dataset.row;
                    const propertyPK = parseInt(thisEle.dataset.propertypk);
                    const dataPointPlanPropertyPK = parseInt(thisEle.dataset.datapointplanpropertypk);
                    const propertyRelationTypePK = parseInt(thisEle.dataset.propertyrelationtypepk);
                    let planPropertyPK = 0;

                    let hadValue = false;
                    if (thisEle.dataset.planpropertypk !== "" && thisEle.dataset.planpropertypk !== "0") {
                        planPropertyPK = parseInt(thisEle.dataset.planpropertypk);
                        hadValue = true;
                    }

                    if (thisEle.value !== "" || hadValue) {
                        const saveItem = {
                            PlanPropertyPK: planPropertyPK,
                            PlanFK: planPK,
                            PropertyFK: propertyPK,
                            TextValue: thisEle.value,
                            LookupCodeFK: null,
                            RowNbr: parseInt(rowNumber),
                            IsDeletedInd: false,
                            PropertyRelationTypePK: propertyRelationTypePK,
                            DataPointPlanPropertyPK: dataPointPlanPropertyPK
                        };

                        allOtherFields.push(saveItem);
                    }
                }
            }

            const allTheseSelectElements = document.querySelectorAll(`.federalSSAEGoalSettingSelectField[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
            for (const ele of allTheseSelectElements) {
                const thisEle = <HTMLInputElement>ele;
                const rowNumber = thisEle.dataset.row;
                const propertyPK = parseInt(thisEle.dataset.propertypk);
                const dataPointPlanPropertyPK = parseInt(thisEle.dataset.datapointplanpropertypk);
                const propertyRelationTypePK = parseInt(thisEle.dataset.propertyrelationtypepk);
                let planPropertyPK = 0;
                let lookupCodePK = 0;

                let hadValue = false;
                if (thisEle.dataset.planpropertypk !== "" && thisEle.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(thisEle.dataset.planpropertypk);
                    hadValue = true;
                }

                if (thisEle.value !== "" || hadValue) {
                    lookupCodePK = parseInt(thisEle.value);

                    const saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: '',
                        LookupCodeFK: lookupCodePK,
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false,
                        PropertyRelationTypePK: propertyRelationTypePK,
                        DataPointPlanPropertyPK: dataPointPlanPropertyPK
                    };

                    allOtherFields.push(saveItem);
                }
            }

            const thisSet = {
                "SmartGoal": smartGoal,
                "OtherFields": allOtherFields,
            };

            allSaveElements.push(thisSet);
        }

        var allData = {
            "SaveData": allSaveElements,
            "PlanFK": planPK
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsSSAE/SaveFederalSSAEGoalSetting', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allData.SaveData.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }

    changeTargetYear4(e: Event) {
        const measurableInput = <HTMLInputElement>e.target;
        const row = measurableInput.dataset.row;
        const dataPointPlanPropertyPK = measurableInput.dataset.datapointplanpropertypk;
        const targetYear4Input = <HTMLInputElement>document.querySelector(`.measurableGoalTargetStatement[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        targetYear4Input.value = measurableInput.value;
    }

    showDelete(e: Event) {
        const button = <HTMLButtonElement>e.target;

        const goalPlanPropertyPK = button.dataset.goalplanpropertypk;
        const container = button.dataset.container;

        const modal: Modal = new Modal("federalSSAEGoalSettingDelete", null);
        modal.addAttributeToElement("federalSSAEGoalSettingDelete", "#federalSSAEGoalSettingDeleteConfirm", "goalplanpropertypk", goalPlanPropertyPK);
        modal.addAttributeToElement("federalSSAEGoalSettingDelete", "#federalSSAEGoalSettingDeleteConfirm", "container", container);
        modal.show();
    }

    cancelDelete() {
        const modal: Modal = new Modal("federalSSAEGoalSettingDelete", null);
        modal.hide();
    }

    confirmDelete(e: Event) {
        const button = <HTMLButtonElement>e.target;
        const goalPlanPropertyPK = button.dataset.goalplanpropertypk;
        const container = button.dataset.container;

        this.deleteGoal(goalPlanPropertyPK);
    }

    deleteGoal(goalPlanPropertyPK: string) {
        let that = this;
        const modal: Modal = new Modal("federalSSAEGoalSettingDelete", null);
        modal.hide();

        let goal = document.querySelector(`[data-planpropertypk='${goalPlanPropertyPK}']`) as HTMLTextAreaElement;

        if (goal != null && "row" in goal.dataset) {
            let container = document.getElementById(goal.dataset.container) as HTMLDivElement;
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/FederalProgramsSSAE/DeleteGoal', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                    Core.createHTMLAlert("alertMessageDiv", "Goal successfully deleted", 'success', 3000, null);

                    //reset fields
                    let goalFields = document.querySelectorAll(`.goalSettingField[data-row='${goal.dataset.row}'], .goalSettingLookupField[data-row='${goal.dataset.row}']`);
                    for (let field of goalFields) {
                        let htmlElement = <HTMLElement>field;
                        //Lookup
                        if (field instanceof HTMLSelectElement) {
                            let htmlField = <HTMLSelectElement>field;
                            htmlField.dataset.planpropertypk = "0";
                            htmlField.selectedIndex = 0;
                        } else if (field.classList.contains("goalSettingCheckbox")) {
                            //checkbox
                            let htmlField = <HTMLInputElement>field;
                            htmlField.dataset.planpropertypk = "0";
                            htmlField.checked = false;
                            that.omitQuarterlyTargets(htmlField);
                        } else if (field instanceof HTMLInputElement) {
                            let htmlField = <HTMLInputElement>field;
                            htmlField.dataset.planpropertypk = "0";
                            htmlField.value = "";
                        } else if (field instanceof HTMLTextAreaElement) {
                            let htmlField = <HTMLTextAreaElement>field;
                            htmlField.dataset.planpropertypk = "0";
                            htmlField.value = "";
                        }

                        //remove delete button for the goal
                        let deleteButton = document.querySelector(`.deleteGoal[data-goalplanpropertypk='${goalPlanPropertyPK}']`);
                        if (deleteButton != null) {
                            deleteButton.parentNode.removeChild(deleteButton);
                        }
                    }
                    let firstField = goalFields[0] as HTMLElement;
                    firstField.focus();
                    Core.createHTMLAlert("alertMessageDiv", "Successfully deleted goal", "success", 3000, null);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this goal. Please try again later.", "error", 3000, null);
                }
                Core.hideLoader();
            };
            xhr.send(goalPlanPropertyPK);
        } else {
            Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this goal. Please try again later.", "error", 3000, null);
        }
    }

    omitQuarterlyTargets(element: HTMLInputElement) {
        let core = this._core;

        if (element.readOnly && !("omitset" in element.dataset)) {
            if (element.checked) {
                element.checked = false;
            } else {
                element.checked = true;
            }
            return false;
        } else {
            let quarterlyTargets = document.querySelectorAll(`.goalSettingField[data-quarterlytarget='true'][data-row='${element.dataset.row}'][data-buttonrow='${element.dataset.buttonrow}']`) as NodeListOf<HTMLInputElement>;

            for (let target of quarterlyTargets) {
                element.dataset.omitset = "true";
                if (element.checked) {
                    //Make optional
                    core.forceElementOptional(target);
                    target.readOnly = true;
                    target.value = '';
                } else {
                    //Make required (if first)
                    if (("index" in target.dataset) && target.dataset.index == "0") {
                        core.forceElementRequired(target);
                    } else {
                        core.forceElementOptional(target);
                    }
                    target.readOnly = false;
                }
            }
        }
    }
}

// FederalSSAEActionPlan
class FederalSSAEActionPlan {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["federalSSAEActionPlanField"];

        let federalSSAEActionPlanSaveButton = document.getElementById("federalSSAEActionPlanSave");
        if (federalSSAEActionPlanSaveButton !== null)
            federalSSAEActionPlanSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        this.bindCreateActionPlans();
        this.bindAddAnActionStep();

        const that = this;
        //use event delegation for up/down buttons that are possibly added dynamically
        document.addEventListener("click", function (event) {
            let target = <HTMLElement>event.target;
            if (target.tagName == "BUTTON" && target.classList.contains("up-down") && target.classList.contains("up")) {
                that.moveActionStep(event, true);
            }
        });

        document.addEventListener("click", function (event) {
            let target = <HTMLElement>event.target;
            if (target.tagName == "BUTTON" && target.classList.contains("up-down") && target.classList.contains("down")) {
                that.moveActionStep(event, false);
            }
        });

        const addEvidenceBasedStrategyButton = document.getElementById("addMoreEvidenceBasedStrategies");
        if (addEvidenceBasedStrategyButton !== null) {
            addEvidenceBasedStrategyButton.addEventListener("click", (e: Event) => this.addEvidenceBasedStrategies("actionPlanEvidenceBasedStrategy"));
        }

        this.bindDeleteActionStep();
        this.bindDeleteActionPlan();

        const federalSSAEActionStepDeleteConfirm = document.getElementById("federalSSAEActionStepDeleteConfirm");
        if (federalSSAEActionStepDeleteConfirm !== null)
            federalSSAEActionStepDeleteConfirm.addEventListener("click", (e: Event) => this.actionStepDeleteConfirm(e));

        const federalSSAEActionStepDeleteCancel = document.getElementById("federalSSAEActionStepDeleteCancel");
        if (federalSSAEActionStepDeleteCancel !== null)
            federalSSAEActionStepDeleteCancel.addEventListener("click", (e: Event) => this.actionStepDeleteCancel());

        const federalSSAEActionPlanDeleteConfirm = document.getElementById("federalSSAEActionPlanDeleteConfirm");
        if (federalSSAEActionPlanDeleteConfirm !== null)
            federalSSAEActionPlanDeleteConfirm.addEventListener("click", (e: Event) => this.actionPlanDeleteConfirm(e));

        const federalSSAEActionPlanDeleteCancel = document.getElementById("federalSSAEActionPlanDeleteCancel");
        if (federalSSAEActionPlanDeleteCancel !== null)
            federalSSAEActionPlanDeleteCancel.addEventListener("click", (e: Event) => this.actionPlanDeleteCancel());

    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("federalSSAEActionPlanForm");
        let planFK = parseInt(formElement.dataset.planfk);
        let allActionPlans = [];
        let allActionSteps = [];
        let allPdSteps = [];
        let allComSteps = [];
        let allGoals = [];
        let allOthers = [];
        let actionStepOthers = [];

        let allElements = document.getElementsByClassName("actionPlanField");

        for (let element of allElements) {
            let planPropertyPK: number = 0;
            let dataPointPlanPropertyPK: number = 0;
            let buttonRow: number = 0;

            let htmlElement;
            let saveItem: IPlanPropertyExtended;

            if (element.classList.contains("actionPlanSelectedMeasurableGoals")) {
                htmlElement = <HTMLInputElement>element;
                let rowNumber: number = parseInt(htmlElement.dataset.row);
                if ("overriderow" in htmlElement.dataset) {
                    rowNumber = parseInt(htmlElement.dataset.overriderow);
                }
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;

                if (htmlElement.dataset.datapointplanpropertypk && htmlElement.dataset.datapointplanpropertypk !== "0")
                    dataPointPlanPropertyPK = parseInt(htmlElement.dataset.datapointplanpropertypk);
                else
                    dataPointPlanPropertyPK = 0;

                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;

                if (htmlElement.checked) {
                    saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: parseInt(htmlElement.dataset.propertypk),
                        TextValue: htmlElement.value,
                        LookupCodeFK: null,
                        RowNbr: rowNumber,
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                        ButtonRow: buttonRow
                    };
                }
            } else if (element.classList.contains("actionPlanPDStepField")) {
                htmlElement = <HTMLSelectElement>element;
                let rowNumber: number = parseInt(htmlElement.dataset.row);
                if ("overriderow" in htmlElement.dataset) {
                    rowNumber = parseInt(htmlElement.dataset.overriderow);
                }

                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;

                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;

                if (htmlElement.value !== "") {
                    saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: parseInt(htmlElement.dataset.propertypk),
                        TextValue: "",
                        LookupCodeFK: parseInt(htmlElement.value),
                        RowNbr: rowNumber,
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: 0,
                        ButtonRow: buttonRow
                    };
                }
            } else if (element.classList.contains("actionPlanComStepField")) {
                htmlElement = <HTMLSelectElement>element;
                let rowNumber: number = parseInt(htmlElement.dataset.row);
                if ("overriderow" in htmlElement.dataset) {
                    rowNumber = parseInt(htmlElement.dataset.overriderow);
                }
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;


                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;

                if (htmlElement.value !== "") {
                    saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: parseInt(htmlElement.dataset.propertypk),
                        TextValue: "",
                        LookupCodeFK: parseInt(htmlElement.value),
                        RowNbr: rowNumber,
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: 0,
                        ButtonRow: buttonRow
                    };
                }
            } else {
                htmlElement = <HTMLInputElement>element;
                let rowNumber: number = parseInt(htmlElement.dataset.row);
                if ("overriderow" in htmlElement.dataset) {
                    rowNumber = parseInt(htmlElement.dataset.overriderow);
                }
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;

                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;

                let toDelete = (htmlElement.value === "");

                if (htmlElement.value !== "" || planPropertyPK > 0) {
                    saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: parseInt(htmlElement.dataset.propertypk),
                        TextValue: htmlElement.value,
                        LookupCodeFK: null,
                        RowNbr: rowNumber,
                        IsDeletedInd: toDelete,
                        DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                        ButtonRow: buttonRow
                    };
                }
            }

            if (htmlElement.classList.contains("evidenceBasedStrategyName") && saveItem !== undefined)
                allActionPlans.push(saveItem);
            else if (htmlElement.classList.contains("actionPlanActionStepField") && saveItem !== undefined)
                allActionSteps.push(saveItem);
            else if (htmlElement.classList.contains("actionPlanPDStepField") && saveItem !== undefined)
                allPdSteps.push(saveItem);
            else if (htmlElement.classList.contains("actionPlanComStepField") && saveItem !== undefined)
                allComSteps.push(saveItem);
            else if (htmlElement.classList.contains("actionPlanSelectedMeasurableGoals") && saveItem !== undefined)
                allGoals.push(saveItem);
            else if (htmlElement.classList.contains("actionStepOtherField") && saveItem !== undefined)
                actionStepOthers.push(saveItem);
            else
                if (saveItem !== undefined)
                    allOthers.push(saveItem);
        }

        const nonPlanSaveElements = [];
        const nonPlanElements = document.getElementsByClassName("federalSSAEActionPlanNonActionPlanField");
        for (let ele of nonPlanElements) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planFK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                nonPlanSaveElements.push(saveItem);
            }
        }

        let saveElement = {
            "ActionPlans": allActionPlans,
            "ActionSteps": allActionSteps,
            "PDSteps": allPdSteps,
            "ComSteps": allComSteps,
            "GoalSteps": allGoals,
            "ActionStepOther": actionStepOthers,
            "Others": allOthers,
            "NonActionPlanSaveElements": nonPlanSaveElements
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsSSAE/SaveFederalSSAEActionPlan', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planFK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (saveElement.ActionPlans.length === 0 && saveElement.ActionStepOther.length === 0 && saveElement.ActionSteps.length === 0 && saveElement.ComSteps.length === 0 && saveElement.GoalSteps.length === 0 && saveElement.Others.length === 0 && saveElement.PDSteps.length === 0) {
            if (referrer === "save") {
                core.pageReload(true, planFK);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(saveElement));
        }
    }

    bindCreateActionPlans() {
        const actionPlanButtons = document.getElementsByClassName("createActionPlan");
        for (const button of actionPlanButtons) {
            const but = <HTMLButtonElement>button;
            but.addEventListener("click", (e: Event) => this.addActionPlan((<HTMLButtonElement>e.target)));
        }
    }

    bindAddAnActionStep() {
        const addActionSteps = document.getElementsByClassName("addAnActionStep");
        for (const action of addActionSteps) {
            const addActionStep = <HTMLButtonElement>action;
            const buttonRow = parseInt(addActionStep.dataset.buttonrow);
            addActionStep.addEventListener("click", (e: Event) => this.addActionStep(buttonRow))
        }
    }

    addActionPlan(button: HTMLButtonElement) {
        let _that = this;
        let dontDoIt: boolean = false;
        let row = button.dataset.row;
        let textElement = document.getElementsByClassName("evidenceBasedStrategyName");
        let hasName: boolean = true;
        for (let tElement of textElement) {
            let properElement = <HTMLInputElement>tElement;
            if (properElement.dataset.row === row) {
                if (properElement.value === "") {
                    dontDoIt = true;
                    hasName = false;
                }
            }
        }

        let selectedSequence = this.itemsSelected(row);
        if (selectedSequence.length === 0) {
            dontDoIt = true;
        }

        if (!dontDoIt) {
            let rowNumber = button.dataset.row;
            let textBoxId = "actionPlanEvidenceBasedStrategyName" + rowNumber.toString();
            let evidenceNameElement = <HTMLInputElement>document.getElementById(textBoxId);
            let newAccordionTitle = "Action Plan for: " + evidenceNameElement.value;
            let accordionParent = document.getElementsByClassName("actionPlanActionPlanAccordion");
            var thisElement: HTMLDivElement;

            for (let accordion of accordionParent) {
                let acc = <HTMLDivElement>accordion;
                if (acc.dataset.row == rowNumber) {
                    thisElement = acc;
                }
            }

            let theSpan = <HTMLSpanElement>thisElement.querySelector("span.Accordion-title");
            theSpan.textContent = newAccordionTitle;
            evidenceNameElement.readOnly = true;

            //Now show the accordion.
            let actionPlanElements = document.getElementsByClassName("actionPlanActionPlanAccordion");
            var actionPlanElement: HTMLDivElement;

            for (let ap of actionPlanElements) {
                let ape = <HTMLDivElement>ap;
                if (ape.dataset.row === rowNumber) {
                    actionPlanElement = ape;
                }
            }
            //Get the measurable goals list to display inside the accordion
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/FederalProgramsSSAE/MeasurableGoalsList', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let element = document.getElementById("actionPlanActionPlanSection" + rowNumber);
                    let divElement = <HTMLDivElement>element;
                    let measurableGoals = divElement.querySelector(".actionPlanMeasurableGoalsReaderList");
                    let measurableGoalsElement = <HTMLUListElement>measurableGoals;

                    $(measurableGoalsElement).append(xhr.responseText);

                    let allMeasurableGoals = divElement.querySelectorAll(".measurableGoalModal");
                    for (let goal of allMeasurableGoals) {
                        let goalElement = <HTMLAnchorElement>goal;
                        let modalValue: string = goalElement.dataset.measurablegoal;
                        goal.addEventListener("click", (e: Event) => _that.showGoalModal(modalValue));
                    }
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "New Action Plan Row Added", 'success', 3000, null);
                } else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send(JSON.stringify(selectedSequence));

            actionPlanElement.classList.remove("hide-action-plan");
            actionPlanElement.classList.add("open");

            button.classList.add("hide-action-plan");
            _that.recalculateUpDown();
        } else {
            if (!hasName) {
                Core.createHTMLAlert("alertMessageDiv", "A name for the evidence-based strategy must be provided", 'error', 3000, null);
            } else if (selectedSequence.length === 0) {
                Core.createHTMLAlert("alertMessageDiv", "At least one measurable goal must be selected", 'error', 3000, null);
            }

        }
    }

    addActionStep(buttonRow: number) {
        let that = this;
        let newRow: number = 0;

        let formElement = document.getElementById("federalSSAEActionPlanForm");
        let planFK = parseInt(formElement.dataset.planfk);

        let actionStepFields = document.getElementsByClassName("actionPlanActionStepField");
        for (let action of actionStepFields) {
            let actionElement = <HTMLInputElement>action;
            if (buttonRow === parseInt(actionElement.dataset.buttonrow)) {
                if (newRow < parseInt(actionElement.dataset.row)) {
                    newRow = parseInt(actionElement.dataset.row);
                }
            }
        }

        newRow++;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsSSAE/AddActionStep', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                $(".actionStepContainer[data-buttonrow='" + buttonRow + "']").append(xhr.responseText);
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "New Action Step Added", 'success', 3000, null);
                that.recalculateUpDown();
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("planFK=" + planFK + "&newRow=" + newRow + "&buttonRow=" + buttonRow);
    }

    showGoalModal(modalText: string) {
        let element = <HTMLDivElement>document.getElementById("actionPlanMeasurableGoalsDescription");

        element.innerHTML = modalText;

        let modal = new Modal('ActionPlanMeasurableGoalDescriptionModal', null);
        modal.show();
    }

    itemsSelected(row: string): string[] {
        let selectElements = document.getElementsByClassName("multiSelectMeasurableGoals");
        let elementSequence = [];

        for (let selectElement of selectElements) {
            let element = <HTMLDivElement>selectElement;

            if (element.dataset.row === row) {
                let selection = element.querySelectorAll("ul li.selected");
                if (selection.length > 0) {
                    for (let s of selection) {
                        let sElement = <HTMLLIElement>s;
                        let nextElement = sElement.querySelector("input");
                        let inputElement = <HTMLElement>nextElement;
                        elementSequence.push(inputElement.dataset.planpropertypk);
                    }
                }
            }
        }

        return elementSequence;
    }

    recalculateUpDown() {
        let actionStepContainers = document.getElementsByClassName("actionStepContainer") as HTMLCollectionOf<HTMLDivElement>;
        for (let container of actionStepContainers) {
            let actionSteps = container.querySelectorAll(".actionStepsRow");
            if (actionSteps != null) {
                let i = 1;
                for (let actionStep of actionSteps) {

                    let upArrow = actionStep.querySelector("button.up") as HTMLButtonElement;
                    let downArrow = actionStep.querySelector("button.down") as HTMLButtonElement;

                    if (upArrow != null && downArrow != null && upArrow.dataset.canedit == "true" && downArrow.dataset.canedit == "true") {
                        //Reset the state up the up and down arrows
                        upArrow.classList.remove("hide");
                        upArrow.disabled = false;
                        downArrow.classList.remove("hide");
                        downArrow.disabled = false;

                        if (actionSteps.length == 1) {
                            //Only one action step so hide the up/down arrows
                            upArrow.classList.add("hide");
                            downArrow.classList.add("hide");
                        } else {
                            if (i === 1) {
                                upArrow.disabled = true;
                            }

                            if (i === actionSteps.length) {
                                downArrow.disabled = true;
                            }
                        }
                    }
                    i++;
                }
            }
        }
    }

    moveActionStep(e: Event, up: boolean) {
        let that = this;
        e.preventDefault();
        let button = <HTMLButtonElement>e.target;
        if (button != null) {
            let actionStepContainer = <HTMLDivElement>Core.findClosest(button, ".action-steps-row");
            if (actionStepContainer != null) {
                let sibling: Element;
                if (up) {
                    sibling = actionStepContainer.previousElementSibling;
                    actionStepContainer.parentNode.insertBefore(actionStepContainer, sibling);
                } else {
                    sibling = actionStepContainer.nextElementSibling;
                    actionStepContainer.parentNode.insertBefore(sibling, actionStepContainer);
                }

                let siblingElement = <HTMLElement>sibling;
                let thisRow = actionStepContainer.dataset.overriderow;
                let siblingRow = siblingElement.dataset.overriderow;

                //swap override row number for the two
                actionStepContainer.dataset.overriderow = siblingRow;
                let thisActionStepFields = actionStepContainer.querySelectorAll(`[data-overriderow='${thisRow}']`);
                for (let field of thisActionStepFields) {
                    let htmlField = <HTMLElement>field;
                    htmlField.dataset.overriderow = siblingRow;
                }

                siblingElement.dataset.overriderow = thisRow;
                let siblingActionStepFields = siblingElement.querySelectorAll(`[data-overriderow='${siblingRow}']`);
                for (let field of siblingActionStepFields) {
                    let htmlField = <HTMLElement>field;
                    htmlField.dataset.overriderow = thisRow;
                }

                this.recalculateUpDown();

                if (button.disabled) {
                    if (up) {
                        let downButton = <HTMLButtonElement>actionStepContainer.querySelector("button.up-down.down");
                        if (downButton != null && !downButton.disabled) {
                            downButton.focus();
                        }
                    } else {
                        let upButton = <HTMLButtonElement>actionStepContainer.querySelector("button.up-down.up");
                        if (upButton != null && !upButton.disabled) {
                            upButton.focus();
                        }
                    }
                } else {
                    button.focus();
                }

                Core.createHTMLAlert("alertMessageDiv", "Action Step Has Been Reordered", "success", 3000, null);
            }
        }

        this._core.initializeRequiredFields(this.validationClasses, true);
    }

    addEvidenceBasedStrategies(classNameBase: string) {
        let core = this._core;
        let planFKElement = document.getElementById("federalSSAEActionPlanForm");
        let planFK = parseInt(planFKElement.dataset.planfk);
        let _that = this;

        let value = 1;
        let table = (<HTMLTableElement>document.getElementById("evidenceBasedStrategy"));

        let findRows = document.getElementsByClassName("addAnActionStep");
        let rowCount = 0;
        for (let row of findRows) {
            let element = <HTMLButtonElement>row;
            if (rowCount < parseInt(element.dataset.buttonrow)) {
                rowCount = parseInt(element.dataset.buttonrow);
            }
        }

        for (let i = 1; i <= value; i++) {
            rowCount++;
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/FederalProgramsSSAE/EvidenceBasedStrategy');
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    $("#evidenceBasedStrategy > tbody").append("<tr><td>" + xhr.responseText + "</td></tr>");

                    $('.multiSelectMeasurableGoals').MultiSelect();
                    _that.bindCreateActionPlans();
                    _that.bindAddAnActionStep();
                    new CustomAccordion("actionPlanActionPlanAccordion" + rowCount.toString());
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "New Evidence-Based Strategy Row Added", 'success', 3000, null);
                    _that.recalculateUpDown();
                } else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send("classNameBase=" + classNameBase + "&rowNumber=" + rowCount + "&planFK=" + planFK);
        }
    }

    bindDeleteActionStep() {
        const deleteActionSteps = document.getElementsByClassName("deleteActionStep");
        for (const deleteActionStep of deleteActionSteps)
            deleteActionStep.addEventListener("click", (e: Event) => this.showDeleteActionStep(e));
    }

    showDeleteActionStep(e) {
        const button = <HTMLButtonElement>e.target;
        const actionStepPlanPropertyPK = button.dataset.actionstepplanpropertypk;
        const container = button.dataset.container;

        const modal: Modal = new Modal("deleteActionStepModal", null);
        modal.addAttributeToElement("deleteActionStepModal", "#federalSSAEActionStepDeleteConfirm", "actionstepplanpropertypk", actionStepPlanPropertyPK);
        modal.addAttributeToElement("deleteActionStepModal", "#federalSSAEActionStepDeleteConfirm", "container", container);

        modal.show();
    }

    async actionStepDeleteConfirm(e: Event) {
        const button = <HTMLButtonElement>e.target;
        const actionStepPlanPropertyPK = button.dataset.actionstepplanpropertypk;
        const container = button.dataset.container;
        const modal: Modal = new Modal("deleteActionStepModal", null);

        const response = await fetch(`/FederalProgramsSSAE/DeleteActionStep/${actionStepPlanPropertyPK}`, { credentials: 'include' })
        if (response.ok) {
            const nextFocusable = Core.getNextFocusableElement(button);
            const containerObj = document.getElementById(container);
            containerObj.remove();
            this.recalculateUpDown();
            this._core.initializeRequiredFields(this.validationClasses, true);
            nextFocusable.focus();

            modal.hide();
            Core.createHTMLAlert("alertMessageDiv", "The Action Step was removed successfully.", 'success', 3000, null);
        } else {
            modal.hide();
            Core.createHTMLAlert("alertMessageDiv", "Request failed. There was an error deleting this record. Please try again.", 'error', 3000, null);
        }
    }

    actionStepDeleteCancel() {
        const modal: Modal = new Modal("deleteActionStepModal", null);
        modal.hide();
    }


    bindDeleteActionPlan() {
        const deleteActionPlans = document.getElementsByClassName("deleteActionPlan");
        for (const deleteActionPlan of deleteActionPlans)
            deleteActionPlan.addEventListener("click", (e: Event) => this.showDeleteActionPlan(e));
    }

    showDeleteActionPlan(e) {
        const button = <HTMLButtonElement>e.target;
        const actionPlanPlanPropertyPK = button.dataset.actionplanplanpropertypk;

        const modal: Modal = new Modal("deleteActionPlanModal", null);
        modal.addAttributeToElement("deleteActionPlanModal", "#federalSSAEActionPlanDeleteConfirm", "actionplanplanpropertypk", actionPlanPlanPropertyPK);
        modal.show();
    }

    actionPlanDeleteCancel() {
        const modal: Modal = new Modal("deleteActionPlanModal", null);
        modal.hide();
    }

    async actionPlanDeleteConfirm(e: Event) {
        const button = <HTMLButtonElement>e.target;
        const actionPlanPlanPropertyPK = button.dataset.actionplanplanpropertypk;

        const modal: Modal = new Modal("deleteActionPlanModal", null);

        let response = await fetch(`/FederalProgramsSSAE/DeleteActionPlan/${actionPlanPlanPropertyPK}`, { credentials: 'include' })
        if (response.ok) {
            //Get next focusable evidence based strategy name
            let strategyNames = document.querySelectorAll(".evidenceBasedStrategyName");
            if (strategyNames.length > 1) {
                let arr = Array.prototype.slice.call(strategyNames);
                let thisStrategyName = document.querySelector(`.evidenceBasedStrategyName[data-planpropertypk='${actionPlanPlanPropertyPK}']`);
                if (thisStrategyName != null) {
                    let index = arr.indexOf(thisStrategyName);
                    if (index > -1) {
                        let nextFocusable = arr[index + 1] || arr[index - 1] || arr[0];
                        nextFocusable.focus();
                    }
                }
            } else {
                //Focus on add button if no strategies left
                let addButton = document.getElementById("addMoreEvidenceBasedStrategies");
                if (addButton != null) {
                    addButton.focus();
                }
            }

            //remove from DOM
            let containers = document.querySelectorAll(`[data-containeractionplanplanpropertypk='${actionPlanPlanPropertyPK}'`);
            for (let container of containers) {
                container.parentNode.removeChild(container);
            }

            modal.hide();
            Core.createHTMLAlert("alertMessageDiv", "The Action Plan was removed successfully.", 'success', 3000, null);
        } else {
            modal.hide();
            Core.createHTMLAlert("alertMessageDiv", "There was an issue deleting the Action Plan, please try again.", 'error', 3000, null);
        }
    }
}

// FederalSSAESignaturesAndAssurances
class FederalSSAESignaturesAndAssurances {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["federalSSAESignaturesAndAssurancesField"];

        let federalSSAESignaturesAndAssurancesSaveButton = document.getElementById("federalSSAESignaturesAndAssurancesSave");
        if (federalSSAESignaturesAndAssurancesSaveButton !== null)
            federalSSAESignaturesAndAssurancesSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("federalSSAESignaturesAndAssurancesForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("federalSSAESignaturesAndAssurancesField");

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsSSAE/SaveFederalSSAESignaturesAndAssurances', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

// FederalSSAESummaryChecklist
class FederalSSAESummaryChecklist {
    private _core: Core;
    constructor() {
        this._core = new Core();
        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.back(e));

        let submitButton = document.getElementById("submitPlanButton");
        if (submitButton !== null)
            submitButton.addEventListener("click", (e: Event) => this.submit(e));

        this._core.leftnav(this);
    }

    back(e: Event) {
        let newPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }

    getCore() {
        return this._core;
    }

    submit(e: Event) {
        let element = <HTMLButtonElement>e.target;
        if (!element.classList.contains("disabled")) {
            let planFK = 0;
            planFK = parseInt(element.dataset.planfk);
            let core = this._core;
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/FederalProgramsSSAE/SubmitPlan', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "This report has been submitted for review!", 'success', 3000, window.location.reload());

                    setTimeout(function () { window.location.href = '/Reports/StateRequiredReports' }, 3000);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status + "'", 'error', 3000, null);
                }
            };
            xhr.send("planFK=" + planFK);
        }
    }
}

// FederalSSAEPerformance
class FederalSSAEPerformance {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["federalSSAEPerformanceField"];

        let federalSSAEPerformanceSaveButton = document.getElementById("federalSSAEPerformanceSave");
        if (federalSSAEPerformanceSaveButton !== null)
            federalSSAEPerformanceSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("federalSSAEPerformanceForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("federalSSAEPerformanceField");

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsSSAE/SaveFederalSSAEPerformance', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
