/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />

// Act35Profile
class Act35Profile {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["act35SurveyField"];

        let act35ProfileSaveButton = document.getElementById("act35ProfileSave");
        if (act35ProfileSaveButton !== null)
            act35ProfileSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        let act35ProfileExecutiveSaveButton = document.getElementById("ExecutiveSave");
        if (act35ProfileExecutiveSaveButton !== null)
            act35ProfileExecutiveSaveButton.addEventListener("click", (e: Event) => this.executiveSave("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let refreshButton = document.getElementById("resetProfile");
        if (refreshButton !== null)
            refreshButton.addEventListener("click", (e: Event) => Core.refreshProfile("act35ProfileForm"));
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("act35ProfileForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("act35SurveyField");

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CivicKnowledgeSurvey/SaveAct35Profile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    executiveSave(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("act35ProfileForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("profileICDataSave");

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CivicKnowledgeSurvey/SaveAct35Profile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

// Act35Survey
class Act35Survey {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["act35SurveyField", "act35SurveyRadioField"];

        let act35SurveySaveButton = document.getElementById("act35SurveySave");
        if (act35SurveySaveButton !== null)
            act35SurveySaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);

        this.calculateValues();

        let showMessage = this.initializeRequiredFieldsCustom();
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses, showMessage);
        }

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let graduationReportAct35SurveyAddACourseButton = document.getElementById("reportAct35SurveyAddACourse");
        if (graduationReportAct35SurveyAddACourseButton !== null)
            graduationReportAct35SurveyAddACourseButton.addEventListener("click", (e: Event) => this.addAct35Course(e))

        this.bindDeleteButtons();

        let deleteAct35SurveyCancelButton = document.getElementById("deleteAct35SurveyCancel");
        if (deleteAct35SurveyCancelButton !== null)
            deleteAct35SurveyCancelButton.addEventListener("click", (e: Event) => this.deleteAct35SurveyCancel());

        let deleteAct35SurveyConfirmButton = document.getElementById("deleteAct35SurveyConfirm");
        if (deleteAct35SurveyConfirmButton !== null)
            deleteAct35SurveyConfirmButton.addEventListener("click", (e: Event) => this.deleteAct35SurveyConfirm(e));

        this.bindCalculations();

        const additionalCommentsRadio = document.getElementsByClassName("act35SurveyRadioField");
        for (const ele of additionalCommentsRadio) {
            (ele as HTMLInputElement).addEventListener("change", (e: Event) => this.changeAdditionalComments());
        }

        this.changeAdditionalComments();
    }

    bindDeleteButtons() {
        let deleteAct35SurveyButtons = document.getElementsByClassName("deleteAct35Survey");
        for (let deleteAct35SurveyButton of deleteAct35SurveyButtons)
            deleteAct35SurveyButton.addEventListener("click", (e: Event) => this.deleteAct35Survey(e));
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("act35SurveyForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("act35SurveyField");

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let selectInputs = document.getElementsByClassName("act35SurveySelectField");
        for (let ele of selectInputs) {
            let planPropertyPK = 0;
            let element = <HTMLSelectElement>ele;
            let rowNumber = "0";
            if (element.dataset.row !== null)
                rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if ((element.value !== "" && element.value !== "0") || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: null,
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }

        let radios = document.getElementsByClassName("act35SurveyRadioField");
        for (let ele of radios) {
            let planPropertyPK = 0;

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                if (element.value !== "" || hadValue) {
                    let saveItem: IPlanPropertyExtended = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                        ButtonRow: 0
                    };

                    allSaveElements.push(saveItem);
                }
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CivicKnowledgeSurvey/SaveAct35Survey', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    addAct35Course(e: Event) {
        let srcElement = <HTMLButtonElement>e.srcElement;
        let core = this._core;
        let planFK = srcElement.dataset.planfk;
        let isLockdown = srcElement.dataset.islockdown;
        let isReview = srcElement.dataset.isreview;

        let row = 0;
        let allRows = document.getElementsByClassName("act35-civics");
        for (let thisRow of allRows) {
            if (parseInt(thisRow.getAttribute("data-row")) > row)
                row = parseInt(thisRow.getAttribute("data-row"));
        }

        row++;

        let that = this;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CivicKnowledgeSurvey/AddAct35Course', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();

                let div = <HTMLDivElement>document.createElement("div");
                div.classList.add("act35-civics");
                div.dataset.row = row.toString();
                div.innerHTML = xhr.responseText;

                let parent = document.getElementById("civicToolkit");
                parent.appendChild(div);

                $('.multiSelect').MultiSelect();

                that.bindDeleteButtons();
                that.bindCalculations();

                Core.createHTMLAlert("alertMessageDiv", "A Course has been added", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(`row=${row}&planFK=${planFK}&isLockdown=${isLockdown}$isReview=${isReview}`);
    }

    initializeRequiredFieldsCustom() {
        let showMessage: boolean = false;
        let allRows: number[] = [];
        let totalErrors: number = 0;

        let allClasses = ["act35SurveyMultiselectCheckField", "act35SurveyField", "act35SurveyRadioField"];

        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });

        let classesToValidate = formattedAllClasses.join(",");

        let allElements = document.querySelectorAll(classesToValidate);

        for (let element of allElements) {
            let alreadyExists = false;
            let htmlElement = <HTMLElement>element;

            if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {

                //check to see if there are other fields the same as this one on this page (i.e. fields that have the same propertypk)
                var otherElements = document.querySelectorAll(`[data-propertypk='${htmlElement.dataset.propertypk}']`);
                for (let otherElement of otherElements) {
                    let otherHtmlElement = <HTMLElement>otherElement;
                    if (otherHtmlElement.hasAttribute("aria-required") && otherHtmlElement.getAttribute("aria-required") === "true") {
                        alreadyExists = true;
                    }
                }

                if (!alreadyExists) {

                    if (!(htmlElement.hasAttribute("aria-required") && htmlElement.getAttribute("aria-required") === "true")) {
                        htmlElement.setAttribute("aria-required", "true");
                        let label = Core.findLabelForInput(htmlElement);

                        if (label !== null && !label.classList.contains("isRequired")) {
                            label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                            label.classList.add("isRequired");
                        }
                    }
                }
            }

            //Manual override when data-forcerequired=true
            if ("forcerequired" in htmlElement.dataset && htmlElement.dataset.forcerequired === "true") {

                htmlElement.dataset.percent = "1.00";

                if (!(htmlElement.hasAttribute("aria-required") && htmlElement.getAttribute("aria-required") === "true")) {
                    htmlElement.setAttribute("aria-required", "true");

                    let label = Core.findLabelForInput(htmlElement);

                    if (label !== null && !label.classList.contains("isRequired")) {
                        label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                        label.classList.add("isRequired");
                    }
                }
            }
        }


        //get the rows.
        let rowElements = document.getElementsByClassName("act35SurveyField");
        for (let row of rowElements) {
            let rowElement = <HTMLInputElement>row;

            let thisRow = parseInt(rowElement.dataset.row);
            if (allRows.indexOf(thisRow) === -1)
                allRows.push(thisRow);
        }

        let rowSuccesses: boolean[] = [];

        for (let rowNum of allRows) {

            let successes: boolean[] = [];

            let textElements = document.querySelectorAll(`.act35SurveyField[data-row='${rowNum}']`);

            for (let text of textElements) {
                let textElement = <HTMLInputElement>text;

                if (textElement.value !== "")
                    successes.push(true);
                else
                    successes.push(false);
            }

            let typeOfAssessmentChecks = document.querySelectorAll(`.act35SurveyMultiselectCheckField[data-type='TypeOfAssessment'][data-row='${rowNum}']`);
            let typeOfAssessmentIsChecked: boolean = false;
            for (let typeOfAssessment of typeOfAssessmentChecks) {
                let ele = <HTMLInputElement>typeOfAssessment;

                if (ele.checked)
                    typeOfAssessmentIsChecked = true;
            }

            let gradeChecks = document.querySelectorAll(`.act35SurveyMultiselectCheckField[data-type='Grade'][data-row='${rowNum}']`);
            let gradeIsChecked: boolean = false;
            for (let grade of gradeChecks) {
                let ele = <HTMLInputElement>grade;

                if (ele.checked)
                    gradeIsChecked = true;
            }

            successes.push(typeOfAssessmentIsChecked);
            successes.push(gradeIsChecked);

            let allFieldsFilled: boolean = false;

            if (successes.indexOf(false) > -1) {
                if (successes.indexOf(true) === -1) {
                    //If everything is false, this is fine. As long as ONE of the rows has a full set of data, we're good.
                    allFieldsFilled = true;
                } else {
                    allFieldsFilled = false;
                }
            } else {
                //If everything is true, this is good to go
                allFieldsFilled = true;
            }

            rowSuccesses.push(allFieldsFilled);
        }

        let allFails = document.querySelectorAll(".graduationReportNumbers[data-failed='failed']");
        let allFailedRows = [];
        if (allFails.length > 0) {
            for (let fail of allFails)
                allFailedRows.push((fail as HTMLDivElement).dataset.row);

            for (let row of allFailedRows) {
                let assessedElement = <HTMLInputElement>document.querySelector(`.act35SurveyField[data-row='${row}'][data-assessed='yes']`);
                let passedElement = <HTMLInputElement>document.querySelector(`.act35SurveyField[data-row='${row}'][data-passed='yes']`);
                let perfectElement = <HTMLInputElement>document.querySelector(`.act35SurveyField[data-row='${row}'][data-perfect='yes']`);

                assessedElement.classList.add("missing-element");
                passedElement.classList.add("missing-element");
                perfectElement.classList.add("missing-element");

                rowSuccesses.push(false);
                totalErrors++;
                showMessage = true;
            }
        }

        let missingComments: boolean = false;

        const radios = document.querySelectorAll(".act35SurveyRadioField");
        for (const radio of radios) {
            const ele = radio as HTMLInputElement;
            if (ele.checked) {
                const val = ele.dataset.lookupcode;

                if (val === "yes") {
                    const additionalCommentsElement = <HTMLTextAreaElement>document.getElementById("act35Act35SurveyAdditionalComments");
                    if (additionalCommentsElement.value === "") {
                        missingComments = true;
                        showMessage = true;
                        totalErrors++;
                        additionalCommentsElement.classList.add("missing-field");
                        additionalCommentsElement.setAttribute("aria-invalid", "true");
                        Core.createErrorLabelForInput(additionalCommentsElement);
                    }
                }
            }
        }

        //if any of the rowSuccesses are false, something is missing, so this is "invalid"
        if (rowSuccesses.indexOf(false) > -1 || missingComments) {
            for (let rowNum of allRows) {

                let textElements = document.querySelectorAll(`.act35SurveyField[data-row='${rowNum}']`);

                for (let text of textElements) {
                    let textElement = <HTMLInputElement>text;

                    if (textElement.value === "") {
                        textElement.classList.add("missing-field");
                        textElement.setAttribute("aria-invalid", "true");
                        Core.createErrorLabelForInput(textElement);
                        showMessage = true;
                        totalErrors++;
                    }
                }
            }

            let message = <HTMLDivElement>document.getElementById("validationMessage");

            if (totalErrors === 1) {
                message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
            } else {
                message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
            }
            let goToError = document.getElementById("goToFirstError");

            if (goToError !== null) {

                let that = this;

                let firstFocusableEl = <HTMLElement>document.querySelector(".missing-field");

                if (firstFocusableEl !== null) {
                    goToError.addEventListener("click", function () {
                        let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                        if (accordion) {
                            let id = accordion.getAttribute("aria-labelledby");

                            let accordionElement = <HTMLButtonElement>document.getElementById(id);
                            accordionElement.click();
                        }

                        firstFocusableEl.focus();
                    });
                } else {
                    goToError.parentNode.removeChild(goToError);
                }
            }
        } else {
            showMessage = false;
        }

        return showMessage;
    }

    deleteAct35Survey(e: Event) {
        let buttonElement = <HTMLButtonElement>e.srcElement;
        let row = buttonElement.dataset.row;

        let modal: Modal = new Modal("deleteAct35SurveyModal", null);
        modal.addAttributeToElement("deleteAct35SurveyModal", "#deleteAct35SurveyConfirm", "row", row);
        modal.show();
    }

    deleteAct35SurveyCancel() {
        let modal: Modal = new Modal("deleteAct35SurveyModal", null);
        modal.hide();
    }

    deleteAct35SurveyConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.srcElement;
        let planProps = [];
        let row = buttonElement.dataset.row;

        let allElements = document.querySelectorAll(`.act35SurveyField[data-row='${row}']`);
        for (let ele of allElements) {
            let element = <HTMLElement>ele;
            let planProp = element.dataset.planpropertypk;

            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }

        let allCheckElements = document.querySelectorAll(`.act35SurveyMultiselectCheckField[data-row='${row}']`);
        for (let chk of allCheckElements) {
            let checkElement = <HTMLElement>chk;
            let planProp = checkElement.dataset.planpropertypk;

            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CivicKnowledgeSurvey/DeleteAct35Survey', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();

                let divElement = <HTMLDivElement>document.querySelector(`.act35-civics[data-row='${row}']`);
                divElement.remove();

                let modal: Modal = new Modal("deleteAct35SurveyModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The Act 35 Survey row has been removed.", 'success', 3000, null);
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }

    bindCalculations() {
        let assessedElements = document.querySelectorAll(`.act35SurveyField[data-assessed='yes']`);
        for (let ele of assessedElements)
            ele.addEventListener("blur", (e: Event) => this.calculateValues());

        let passedElements = document.querySelectorAll(`.act35SurveyField[data-passed='yes']`);
        for (let ele of passedElements)
            ele.addEventListener("blur", (e: Event) => this.calculateValues());

        let perfectElements = document.querySelectorAll(`.act35SurveyField[data-perfect='yes']`);
        for (let ele of perfectElements)
            ele.addEventListener("blur", (e: Event) => this.calculateValues());
    }

    calculateValues() {
        let rows = [];
        let allRows = document.querySelectorAll(".act35-civics");
        for (let row of allRows) {
            let ele = <HTMLDivElement>row;
            rows.push(ele.dataset.row);
        }

        for (let row of rows) {
            let assessedElement = <HTMLInputElement>document.querySelector(`.act35SurveyField[data-row='${row}'][data-assessed='yes']`);
            let passedElement = <HTMLInputElement>document.querySelector(`.act35SurveyField[data-row='${row}'][data-passed='yes']`);
            let perfectElement = <HTMLInputElement>document.querySelector(`.act35SurveyField[data-row='${row}'][data-perfect='yes']`);

            let assessed: number = 0;
            let passed: number = 0;
            let perfect: number = 0;

            if (assessedElement)
                assessed = parseInt(assessedElement.value);
            if (passedElement)
                passed = parseInt(passedElement.value);
            if (perfectElement)
                perfect = parseInt(perfectElement.value);

            let errorElement = <HTMLDivElement>document.querySelector(`.act35ReportNumbers[data-row='${row}']`)

            if ((passed > assessed) || (perfect > assessed) || (perfect > passed)) {
                errorElement.classList.remove("hide");
                errorElement.dataset.failed = "failed";
            } else {
                errorElement.classList.add("hide");
                errorElement.removeAttribute("data-failed");
            }
        }
    }

    changeAdditionalComments() {
        const additionalCommentsTextAreaContainer = <HTMLDivElement>document.querySelector(".additional-comments");
        const radioElements = document.querySelectorAll(".act35SurveyRadioField");
        for (const radio of radioElements) {
            const ele = radio as HTMLInputElement;
            if (ele.checked) {
                const additionalCommentsElement = <HTMLTextAreaElement>document.getElementById("act35Act35SurveyAdditionalComments");

                const lookup = ele.dataset.lookupcode;
                if (lookup.toLowerCase() === "yes") {
                    additionalCommentsTextAreaContainer.classList.remove("hide");
                    this._core.forceElementRequired(additionalCommentsElement);
                }
                else {
                    additionalCommentsTextAreaContainer.classList.add("hide");
                    this._core.forceElementOptional(additionalCommentsElement);
                }
            }
        }
    }
}

// Act35Signatures
class Act35Signatures {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["act35SignaturesField", "act35SignaturesCheckboxField"];

        let act35SignaturesSaveButton = document.getElementById("act35SignaturesSave");
        if (act35SignaturesSaveButton !== null)
            act35SignaturesSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let exports = document.getElementsByClassName("fullReport") as HTMLCollectionOf<HTMLLinkElement>;
        for (let exportOption of exports) {
            exportOption.addEventListener("click", function () {
                Core.showLongLoader();

                //Get all components for given template
                let xhr = new XMLHttpRequest();
                xhr.open('POST', '/ExportDocx/FullReportCivicKnowledgeSurveyExport', true);
                xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
                xhr.responseType = 'blob';
                xhr.onload = function () {

                    Core.hideLongLoader();

                    if (xhr.status === 200) {
                        let blob = this.response;
                        let filename = exportOption.textContent;
                        filename = filename + ".docx";

                        if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                            window.navigator.msSaveBlob(blob, filename);
                        } else {
                            var a = <HTMLAnchorElement>document.createElement("a");
                            var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                            document.body.appendChild(a);
                            a.style.display = "none";
                            a.href = blobUrl;
                            a.download = filename;
                            a.click();
                        }
                    } else {
                        Core.createHTMLAlert("alertMessageDiv", "There was an issue generating this report. Please try again later.", 'error', 3000, null);
                    }
                }
                xhr.send("planFK=" + exportOption.dataset.planfk);
            });
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("act35SignaturesForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("act35SignaturesField");

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let checkboxes = document.getElementsByClassName("act35SignaturesCheckboxField");
        for (let ele of checkboxes) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.checked || hadValue) {
                let val = "";
                if (element.checked)
                    val = "on";
                else
                    val = "off";

                let saveItem: IPlanPropertyExtended = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                    ButtonRow: 0
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CivicKnowledgeSurvey/SaveAct35Signatures', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

// Act35Summary
class Act35Summary {
    private _core: Core;
    constructor() {
        this._core = new Core();
        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.back(e));

        let submitButton = document.getElementById("submitPlanButton");
        if (submitButton !== null)
            submitButton.addEventListener("click", (e: Event) => this.submit(e));

        this._core.leftnav(this);
    }

    back(e: Event) {
        let newPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }

    getCore() {
        return this._core;
    }

    submit(e: Event) {
        let element = <HTMLButtonElement>e.srcElement;
        if (!element.classList.contains("disabled")) {
            let planFK = 0;
            planFK = parseInt(element.dataset.planfk);
            let core = this._core;
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/CivicKnowledgeSurvey/SubmitPlan', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "This report has been submitted for review!", 'success', 3000, window.location.reload());

                    setTimeout(function () { window.location.href = '/Reports/StateRequiredReports' }, 3000);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status + "'", 'error', 3000, null);
                }
            };
            xhr.send("planFK=" + planFK);
        }
    }
}
