///<reference path="core.ts" />

//Lea Level User
class LeaLevelUser {
    constructor() {
        let core = new Core();
        //let saveButton = document.getElementById("LEALevelUserSave");
        //if (saveButton !== null)
        //    saveButton.addEventListener("click", (e: Event) => this.save());

        this.bindDeleteButtons();
        this.bindDeleteAllAccessButtons();
        this.bindSaveUserAccessButtons();

        let addNewRowButtons = document.getElementsByClassName("addAnotherPlan");
        for (let addNewRowButton of addNewRowButtons) {
            addNewRowButton.addEventListener("click", (e: Event) => this.addAnotherPlan(e));
        }
        core.activateSubMenu("LeaLevelUser");
    }

    bindDeleteButtons() {
        let deleteButtons = document.getElementsByClassName("deleteRow");
        for (let deleteButton of deleteButtons) {
            deleteButton.addEventListener("click", (e: Event) => this.removeLeaLevelUserRow(e));
        }
    }

    bindDeleteAllAccessButtons() {
        let deleteAllAccessButtons = document.getElementsByClassName("removeUserAccess");
        for (let deleteAllAccessButton of deleteAllAccessButtons) {
            deleteAllAccessButton.addEventListener("click", (e: Event) => this.removeUserAccess(e));
        }
    }

    bindSaveUserAccessButtons() {
        let saveUserAccessButtons = document.getElementsByClassName("saveUserAccess");
        for (let saveUserAccessButton of saveUserAccessButtons) {
            saveUserAccessButton.addEventListener("click", (e: Event) => this.saveUserAccess(e));
        }
    }

    //save() {
    //    let core = new Core();
    //    Core.showLoader();

    //    let allSaveElements = [];

    //    let rows = document.querySelectorAll("tr[data-rownumber]");

    //    for (let row of rows) {
    //        let allElements = row.getElementsByClassName("adminCheckbox");
    //        let selectElements = row.getElementsByClassName("adminSelect");
    //        let selectElementValue = 0;
    //        let isSchool = "true";
    //        for (let selectElement of selectElements) {
    //            let element = <HTMLSelectElement>selectElement;
    //            selectElementValue = parseInt(element.value);
    //            isSchool = element.options[element.selectedIndex].getAttribute('data-isschool')
    //        }
    //        for (let element of allElements) {
    //            let htmlElement = <HTMLInputElement>element;
    //            let edDirUserPK: number = parseInt((<HTMLTableRowElement>row).dataset.usereddiruserpk);
    //            let institutionFK: number = 0;
    //            let accessTypeCodeFK: number = 0;
    //            if (isSchool === "False" || isSchool === "false") {
    //                institutionFK = parseInt((<HTMLTableRowElement>row).dataset.leainstitution);
    //                accessTypeCodeFK = selectElementValue;
    //            }
    //            else {
    //                institutionFK = selectElementValue;
    //                accessTypeCodeFK = 8;
    //            }

    //            if (htmlElement.checked) {
    //                let saveItem: IEdDirUserAccessCreate = {
    //                    EdDirUserFK: edDirUserPK,
    //                    AccessCodeFK: parseInt(htmlElement.value),
    //                    InstitutionFK: institutionFK,
    //                    AccessTypeCodeFK: accessTypeCodeFK,
    //                    UserEdDirUserPK: ""
    //                };

    //                allSaveElements.push(saveItem);
    //            }
    //        }
    //    }

    //    let xhr = new XMLHttpRequest();
    //    xhr.open('POST', '/Admin/SaveEdDirUserAccess', true);
    //    xhr.setRequestHeader('Content-Type', 'application/json');
    //    xhr.onload = function () {
    //        if (xhr.status === 200) {
    //            Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, core.pageReload(true, null));
    //        }
    //        else {
    //            Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
    //        }
    //    };
    //        xhr.send(JSON.stringify(allSaveElements));
    //}

    removeLeaLevelUserRow(e: Event) {
        let core = new Core();
        let saveObject: IPDEBureauUserAccessObject;
        let allSaveElements = [];
        let allDeleteElements = [];
        let button = <HTMLButtonElement>e.currentTarget;
        let parent = button.parentElement.parentElement; //To the TR
        //parent.parentElement.removeChild(parent); // remove the row from the dom
        let edDirUserFK = button.dataset.eddiruserpk;// start to do a normal save
        let selectedValue = button.dataset.selectedvalue;
        let buttonInstitutionFK = button.dataset.institutionfk;
        let rowId = button.dataset.rowid;
        //let rowValue = 0;
        //if (selectedValue !== "none") {
        //    rowValue = parseInt(selectedValue);
        //}
        //if (rowValue !== 0 && rowValue < 11) {
        //    parent.parentElement.removeChild(parent); // remove the row from the dom for LEA level plans
        //}
        let tableName = button.dataset.tableid;
        let table = (<HTMLTableElement>document.getElementById(tableName));
        let rows = table.querySelectorAll(`tr[data-rownumber="${rowId}"]`);
        let numberOfLEARows = 0;

        for (let row of rows) {
            let allElements = row.getElementsByClassName("adminCheckbox");
            let selectElements = row.getElementsByClassName("adminSelect");
            let selectElementValue = 0;
            let isSchool = "True";
            for (let selectElement of selectElements) {
                let element = <HTMLSelectElement>selectElement;
                selectElementValue = parseInt(element.value);
                isSchool = element.options[element.selectedIndex].getAttribute('data-isschool');
            }
            for (let element of allElements) {
                let htmlElement = <HTMLInputElement>element;
                let edDirUserPK: number = parseInt((<HTMLTableRowElement>row).dataset.usereddiruserpk);
                let institutionFK: number = 0;
                let accessTypeCodeFK: number = 0;
                //let isSchool = true;
                //if (selectElementValue !== 0 && selectElementValue < 8) {
                //    isSchool = false;
                //}
                if (isSchool === "False" || isSchool === "false") {
                    institutionFK = parseInt((<HTMLTableRowElement>row).dataset.leainstitution);
                    accessTypeCodeFK = selectElementValue;
                }
                else {
                    institutionFK = selectElementValue;
                    accessTypeCodeFK = 8;
                }

                if (htmlElement.dataset.originallychecked === "True") {
                    let deleteItem: IUserAccessCreate = {
                        UserFK: edDirUserPK,
                        AccessCodeFK: parseInt(htmlElement.value),
                        FRCPPInstitutionFK: institutionFK,
                        AccessTypeCodeFK: accessTypeCodeFK
                    };

                    allDeleteElements.push(deleteItem);
                }
            }
        }

        saveObject = {
            CreateList: allSaveElements,
            DeleteList: allDeleteElements
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SaveLEAUserAccess', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, null);
                parent.parentElement.removeChild(parent);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));

        //for (let row of rows) {
        //    let allElements = row.getElementsByClassName("adminCheckbox");
        //    let selectElements = row.getElementsByClassName("adminSelect");
        //    let selectElementValue = 0;
        //    for (let selectElement of selectElements) {
        //        let element = <HTMLSelectElement>selectElement;
        //        selectElementValue = parseInt(element.value);
        //    }
        //    for (let element of allElements) {
        //        let htmlElement = <HTMLInputElement>element;
        //        let edDirUserPK: number = parseInt((<HTMLTableRowElement>row).dataset.usereddiruserpk);
        //        let institutionFK: number = 0;
        //        let accessTypeCodeFK: number = 0;
        //        let isSchool = true;
        //        if (selectElementValue !== 0 && selectElementValue < 8) {
        //            isSchool = false;
        //        }
        //        if (isSchool === false) {
        //            institutionFK = parseInt((<HTMLTableRowElement>row).dataset.leainstitution);
        //            accessTypeCodeFK = selectElementValue;
        //            numberOfLEARows++;
        //        }
        //        else {
        //            institutionFK = selectElementValue;
        //            accessTypeCodeFK = 8;
        //        }

        //        if (htmlElement.checked && selectElementValue !== rowValue) {
        //            let saveItem: IEdDirUserAccessCreate = {
        //                EdDirUserFK: edDirUserPK,
        //                AccessCodeFK: parseInt(htmlElement.value),
        //                InstitutionFK: institutionFK,
        //                AccessTypeCodeFK: accessTypeCodeFK,
        //                UserEdDirUserPK: ""
        //            };

        //            allSaveElements.push(saveItem);
        //        }
        //    }
        //}
        //if (rowValue !== 0 && rowValue > 11) {
        //    parent.parentElement.removeChild(parent); // remove the row from the dom for school level plans
        //}

        //if (allSaveElements.length === 0) {
        //    let instfk = 0
        //    if (rowValue !== 0 && rowValue <= 11) {
        //        instfk = parseInt(buttonInstitutionFK);
        //    }
        //    else {
        //        instfk = rowValue;
        //    }
        //    let saveItem: IEdDirUserAccessCreate = {
        //        EdDirUserFK: parseInt(edDirUserFK),
        //        AccessCodeFK: 0,
        //        InstitutionFK: instfk,
        //        AccessTypeCodeFK: 0,
        //        UserEdDirUserPK: ""
        //    };

        //    allSaveElements.push(saveItem);

        //    let xhr = new XMLHttpRequest();
        //    xhr.open('POST', '/Admin/DeleteAllEdDirUserAccess', true);
        //    xhr.setRequestHeader('Content-Type', 'application/json');
        //    xhr.onload = function () {
        //        if (xhr.status === 200) {
        //            Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, null);
        //        }
        //        else {
        //            Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
        //        }
        //    };
        //    xhr.send(JSON.stringify(allSaveElements));
        //}
        //else if (allSaveElements.length > 0 && rowValue !== 0 && rowValue > 11) {  // if you clicked on a school row delete it

        //    let saveItem: IEdDirUserAccessCreate = {
        //        EdDirUserFK: parseInt(edDirUserFK),
        //        AccessCodeFK: 0,
        //        InstitutionFK: rowValue,
        //        AccessTypeCodeFK: 0,
        //        UserEdDirUserPK: ""
        //    };

        //    allDeleteElements.push(saveItem);

        //    let xhr = new XMLHttpRequest();
        //    xhr.open('POST', '/Admin/DeleteAllEdDirUserAccess', true);
        //    xhr.setRequestHeader('Content-Type', 'application/json');
        //    xhr.onload = function () {
        //        if (xhr.status === 200) {
        //            Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, null);
        //        }
        //        else {
        //            Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
        //        }
        //    };
        //    xhr.send(JSON.stringify(allDeleteElements));
        //}
        //else if (allSaveElements.length > 0 && rowValue !== 0 && rowValue <= 11 && numberOfLEARows === 0) {  // if you clicked on an LEA row when there are more than 1 rows left but only one of them is and LEA row

        //    this.deleteLastLEARow(parseInt(edDirUserFK), parseInt(buttonInstitutionFK));

        //    let xhr = new XMLHttpRequest();
        //    xhr.open('POST', '/Admin/SaveEdDirUserAccess', true);
        //    xhr.setRequestHeader('Content-Type', 'application/json');
        //    xhr.onload = function () {
        //        if (xhr.status === 200) {
        //            Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, null);
        //        }
        //        else {
        //            Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
        //        }
        //    };
        //    xhr.send(JSON.stringify(allSaveElements));
        //}
        //else {
        //    let xhr = new XMLHttpRequest();
        //    xhr.open('POST', '/Admin/SaveEdDirUserAccess', true);
        //    xhr.setRequestHeader('Content-Type', 'application/json');
        //    xhr.onload = function () {
        //        if (xhr.status === 200) {
        //            Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, null);
        //        }
        //        else {
        //            Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
        //        }
        //    };
        //    xhr.send(JSON.stringify(allSaveElements));
        //}
    }

    deleteLastLEARow(EdDirUserFK, InstitutionFK) {
        let core = new Core();
        let allDeleteElements = [];
        let saveItem: IEdDirUserAccessCreate = {
            EdDirUserFK: EdDirUserFK,
            AccessCodeFK: 0,
            InstitutionFK: InstitutionFK,
            AccessTypeCodeFK: 0,
            UserEdDirUserPK: ""
        };

        allDeleteElements.push(saveItem);

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/DeleteAllEdDirUserAccess', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(allDeleteElements));
    }

    addAnotherPlan(e: Event) {
        let core = new Core();
        let that = this;
        let button = <HTMLButtonElement>e.currentTarget;
        let userPK = button.dataset.usereddiruser;
        let tableName = button.dataset.tableid;
        let table = (<HTMLTableElement>document.getElementById(tableName));
        let rows = document.querySelectorAll("tr[data-rownumber]");
        let rowCount = 0;

        for (let row of rows) {
            rowCount++;
        }

        let xhr = new XMLHttpRequest();

        xhr.open('POST', '/Admin/LeaLevelUserDataFieldRow', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = table.querySelector("tbody");
                $(element).append(xhr.responseText);
                Core.createHTMLAlert("alertMessageDiv", "New Plan Row Added", 'success', 3000, null);
                that.bindDeleteButtons();
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("rowNumber=" + rowCount + "&userPK=" + userPK);
    }

    removeUserAccess(e: Event) {
        let core = new Core();
        let saveObject: IPDEBureauUserAccessObject;
        let allSaveElements = [];
        let allDeleteElements = [];
        let button = <HTMLButtonElement>e.currentTarget;
        let edDirUserFK = button.dataset.usereddiruser;
        let tableName = button.dataset.tableid;
        let table = (<HTMLTableElement>document.getElementById(tableName));
        let rows = table.querySelectorAll("tr[data-rownumber]");

        for (let row of rows) {
            let allElements = row.getElementsByClassName("adminCheckbox");
            let selectElements = row.getElementsByClassName("adminSelect");
            let selectElementValue = 0;
            let isSchool = "True";
            for (let selectElement of selectElements) {
                let element = <HTMLSelectElement>selectElement;
                selectElementValue = parseInt(element.value);
                isSchool = element.options[element.selectedIndex].getAttribute('data-isschool');
            }
            for (let element of allElements) {
                let htmlElement = <HTMLInputElement>element;
                let edDirUserPK: number = parseInt((<HTMLTableRowElement>row).dataset.usereddiruserpk);
                let institutionFK: number = 0;
                let accessTypeCodeFK: number = 0;
                //let isSchool = true;
                //if (selectElementValue !== 0 && selectElementValue < 8) {
                //    isSchool = false;
                //}
                if (isSchool === "False" || isSchool === "false") {
                    institutionFK = parseInt((<HTMLTableRowElement>row).dataset.leainstitution);
                    accessTypeCodeFK = selectElementValue;
                }
                else {
                    institutionFK = selectElementValue;
                    accessTypeCodeFK = 8;
                }

                if (htmlElement.dataset.originallychecked === "True") {
                    let deleteItem: IUserAccessCreate = {
                        UserFK: edDirUserPK,
                        AccessCodeFK: parseInt(htmlElement.value),
                        FRCPPInstitutionFK: institutionFK,
                        AccessTypeCodeFK: accessTypeCodeFK
                    };

                    allDeleteElements.push(deleteItem);
                }
            }
        }

        saveObject = {
            CreateList: allSaveElements,
            DeleteList: allDeleteElements
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SaveLEAUserAccess', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));

        //for (let row of rows) {
        //    let allElements = row.getElementsByClassName("adminCheckbox");
        //    let selectElements = row.getElementsByClassName("adminSelect");
        //    let selectElementValue = 0;
        //    for (let selectElement of selectElements) {
        //        let element = <HTMLSelectElement>selectElement;
        //        selectElementValue = parseInt(element.value);
        //    }
        //    for (let element of allElements) {
        //        let htmlElement = <HTMLInputElement>element;
        //        let edDirUserPK: number = parseInt((<HTMLTableRowElement>row).dataset.usereddiruserpk);
        //        let institutionFK: number = 0;
        //        let accessTypeCodeFK: number = 0;
        //        let isSchool = true;
        //        if (selectElementValue !== 0 && selectElementValue < 8) {
        //            isSchool = false;
        //        }
        //        if (isSchool === false) {
        //            institutionFK = parseInt((<HTMLTableRowElement>row).dataset.leainstitution);
        //            accessTypeCodeFK = selectElementValue;
        //        }
        //        else {
        //            institutionFK = selectElementValue;
        //            accessTypeCodeFK = 8;
        //        }

        //        if (htmlElement.checked) {
        //            let saveItem: IEdDirUserAccessCreate = {
        //                EdDirUserFK: edDirUserPK,
        //                AccessCodeFK: parseInt(htmlElement.value),
        //                InstitutionFK: institutionFK,
        //                AccessTypeCodeFK: accessTypeCodeFK,
        //                UserEdDirUserPK: ""
        //            };

        //            allSaveElements.push(saveItem);
        //            htmlElement.checked = false;
        //        }
        //    }
        //}

        //let xhr = new XMLHttpRequest();
        //xhr.open('POST', '/Admin/DeleteAllEdDirUserAccess', true);
        //xhr.setRequestHeader('Content-Type', 'application/json');
        //xhr.onload = function () {
        //    if (xhr.status === 200) {
        //        Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, null);
        //    }
        //    else {
        //        Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
        //    }
        //};
        //xhr.send(JSON.stringify(allSaveElements));
    }

    saveUserAccess(e: Event) {
        let core = new Core();
        //making this save more like the bureauUserAccess Save
        let saveObject: IPDEBureauUserAccessObject;
        let allSaveElements = [];
        let allDeleteElements = [];
        let button = <HTMLButtonElement>e.currentTarget;
        let edDirUserFK = button.dataset.usereddiruser;
        let tableName = button.dataset.tableid;
        let table = (<HTMLTableElement>document.getElementById(tableName));
        let rows = table.querySelectorAll("tr[data-rownumber]");

        for (let row of rows) {
            let allElements = row.getElementsByClassName("adminCheckbox");
            let selectElements = row.getElementsByClassName("adminSelect");
            let selectElementValue = 0;
            let isSchool = "True";
            for (let selectElement of selectElements) {
                let element = <HTMLSelectElement>selectElement;
                selectElementValue = parseInt(element.value);
                isSchool = element.options[element.selectedIndex].getAttribute('data-isschool');
            }
            for (let element of allElements) {
                let htmlElement = <HTMLInputElement>element;
                let edDirUserPK: number = parseInt((<HTMLTableRowElement>row).dataset.usereddiruserpk);
                let institutionFK: number = 0;
                let accessTypeCodeFK: number = 0;
                //let isSchool = true;
                //if (selectElementValue !== 0 && selectElementValue < 8) {
                //    isSchool = false;
                //}
                if (isSchool === "False" || isSchool === "false") {
                    institutionFK = parseInt((<HTMLTableRowElement>row).dataset.leainstitution);
                    accessTypeCodeFK = selectElementValue;
                }
                else {
                    institutionFK = selectElementValue;
                    accessTypeCodeFK = 8;
                }
                // only push those elements that are different
                if (htmlElement.checked && htmlElement.dataset.originallychecked === "False") {
                    let saveItem: IUserAccessCreate = {
                        UserFK: edDirUserPK,
                        AccessCodeFK: parseInt(htmlElement.value),
                        FRCPPInstitutionFK: institutionFK,
                        AccessTypeCodeFK: accessTypeCodeFK
                    };

                    allSaveElements.push(saveItem);
                }

                if (htmlElement.checked === false && htmlElement.dataset.originallychecked === "True") {
                    let deleteItem: IUserAccessCreate = {
                        UserFK: edDirUserPK,
                        AccessCodeFK: parseInt(htmlElement.value),
                        FRCPPInstitutionFK: institutionFK,
                        AccessTypeCodeFK: accessTypeCodeFK
                    };

                    allDeleteElements.push(deleteItem);
                }
            }
        }

        saveObject = {
            CreateList: allSaveElements,
            DeleteList: allDeleteElements
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SaveLEAUserAccess', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));
    }
}

//School Plans
class SchoolPlansAdmin {
    constructor() {
        let core = new Core();
        let saveButton = document.getElementById("SchoolPlanSave");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e: Event) => this.save());

        this.bindDeleteButtons();
        this.bindIndSaveButtons(); 

        core.activateSubMenu("SchoolPlans");
    }

    bindDeleteButtons() {
        let deleteButtons = document.getElementsByClassName("deleteRow");
        for (let deleteButton of deleteButtons) {
            deleteButton.addEventListener("click", (e: Event) => this.removeSchoolPlansRow(e));
        }
    }

    bindIndSaveButtons() {
        let indSaveButtons = document.getElementsByClassName("saveIndSchoolAccess");
        for (let indSaveButton of indSaveButtons) {
            indSaveButton.addEventListener("click", (e: Event) => this.saveSchoolAccess(e));
        }
    }

    save() {
        let core = new Core();
        Core.showLoader();

        let allSaveElements = [];
        let allSelectElements = [];
        let saveObject: ISaveSchoolPlans;
        let selectElements = document.getElementsByClassName("adminSelect");
        let selectElementValue = 0;
        for (let selectElement of selectElements) {
            let element = <HTMLSelectElement>selectElement;
            let elementInstitutionFK = element.dataset.institutionfk;
            let elementPlanPK = element.dataset.planpk;
            //let datepicker = document.querySelector(".adminDatePicker[data-institutionfkcompletiondate='" + elementInstitutionFK + "']");
            //let datePickerElement = <HTMLInputElement>datepicker;
            //let date = datePickerElement.value;
            //let utc = new Date(date);
            //var dueDate = new Date(utc.getTime() + utc.getTimezoneOffset() * 60000);// this converts it back to the day it should be.
            selectElementValue = parseInt(element.value);
            if (selectElementValue > -1) {

                let saveItem: IPlanAdminSave = {
                    PlanPK: parseInt(elementPlanPK),
                    PlanTypeFK: selectElementValue
                };

                allSelectElements.push(saveItem);
            }
        }

        let rows = document.querySelectorAll("tr[data-rownumber]");

        for (let row of rows) {
            let allElements = row.getElementsByClassName("adminCheckbox");

            for (let element of allElements) {
                let htmlElement = <HTMLInputElement>element;
                let edDirUserPK: number = parseInt((<HTMLTableRowElement>row).dataset.usereddiruserpk);
                let institutionFK: number = parseInt(htmlElement.dataset.institutionfk);

                if (htmlElement.checked) {
                    let saveItem: IEdDirUserAccessCreate = {
                        EdDirUserFK: edDirUserPK,
                        AccessCodeFK: parseInt(htmlElement.value),
                        InstitutionFK: institutionFK,
                        AccessTypeCodeFK: 8,
                        UserEdDirUserPK: ""
                    };

                    allSaveElements.push(saveItem);
                }
            }
        }

        saveObject = {
            EdDirUserList: allSaveElements,
            PlanAdminSaveList: allSelectElements
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SaveSchoolPlans', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, core.pageReload(true, null));
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));
    }

    saveSchoolAccess(e: Event) {
        let core = new Core();
        //Core.showLoader();
        let button = <HTMLButtonElement>e.currentTarget;
        let edDirUserFK = parseInt(button.dataset.eddiruserpk);
        let selectid = button.dataset.selectid;
        let elementInstitutionFK = button.dataset.institutionfk;
        let tableid = button.dataset.tableid;

        let allSaveElements = [];
        let allSelectElements = [];
        let saveObject: ISaveSchoolPlans;
        let selectElements = document.getElementsByClassName("adminSelect");
        let selectElementValue = -1;
        //for (let selectElement of selectElements) {
        let element = <HTMLSelectElement>document.getElementById(selectid);
        let elementPlanPK = 0;
        if (element !== null) {
            elementPlanPK = parseInt(element.dataset.planpk);
            selectElementValue = parseInt(element.value);

            //let elementPlanPK = element.dataset.planpk;
            //let elementInstitutionFK = element.dataset.institutionfk;
            //let datepicker = document.querySelector(".adminDatePicker[data-institutionfkcompletiondate='" + elementInstitutionFK + "']");
            //let datePickerElement = <HTMLInputElement>datepicker;
            //let date = datePickerElement.value;
            //let utc = new Date(date);
            //var dueDate = new Date(utc.getTime() + utc.getTimezoneOffset() * 60000);// this converts it back to the day it should be.
            //selectElementValue = parseInt(element.value);
            if (selectElementValue > -1 && elementPlanPK !== 0) {

                let saveItem: IPlanAdminSave = {
                    PlanPK: elementPlanPK,
                    PlanTypeFK: selectElementValue
                };

                allSelectElements.push(saveItem);
            }
        }
        //}

        let table = document.getElementById(tableid);
        if (table !== null) {
            let rows = table.querySelectorAll("tr[data-rownumber]");

            for (let row of rows) {
                let allElements = row.getElementsByClassName("adminCheckbox");

                for (let element of allElements) {
                    let htmlElement = <HTMLInputElement>element;
                    let edDirUserPK: number = parseInt((<HTMLTableRowElement>row).dataset.usereddiruserpk);
                    let institutionFK: number = parseInt(htmlElement.dataset.institutionfk);

                    if (htmlElement.checked) {
                        let saveItem: IEdDirUserAccessCreate = {
                            EdDirUserFK: edDirUserPK,
                            AccessCodeFK: parseInt(htmlElement.value),
                            InstitutionFK: institutionFK,
                            AccessTypeCodeFK: 8,
                            UserEdDirUserPK: ""
                        };

                        allSaveElements.push(saveItem);
                    }
                }
            }
        }

        saveObject = {
            EdDirUserList: allSaveElements,
            PlanAdminSaveList: allSelectElements
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SaveSchoolPlans', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));
    }

    removeSchoolPlansRow(e: Event) {
        let core = new Core();
        let button = <HTMLButtonElement>e.currentTarget;
        let edDirUserFK = parseInt(button.dataset.eddiruserpk);
        let frppInstitutionFk = parseInt(button.dataset.institutionfk);
        let parent = button.parentElement.parentElement; //To the TR
        let xhr = new XMLHttpRequest();

        xhr.open('POST', '/Admin/DeleteEdDirUserAccessByInstitution', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                parent.parentElement.removeChild(parent);
                Core.createHTMLAlert("alertMessageDiv", "Row Successfully Removed", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("userFK=" + edDirUserFK + "&frppInstitutionFk=" + frppInstitutionFk);

    }
}

//District Configuration
class DistrictConfiguration {
    private _core: Core;
    constructor() {
        this._core = new Core();
        this._core.activateSubMenu("districtConfiguration");

        let fileUploader = document.getElementById("uploadNewLogo");
        if (fileUploader !== null) {
            fileUploader.addEventListener("change", (e: Event) => this.uploadFile(<HTMLInputElement>e.srcElement));
        }

        let saveButton = document.getElementById("adminDistrictConfigurationSave");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e: Event) => this.save(e));
    }

    save(e: Event) {
        let saveButton = <HTMLButtonElement>e.srcElement;
        let adminLogo = <HTMLDivElement>document.getElementById("adminLogo");
        let frcppInstitutionFK = saveButton.dataset.frcppinstitutionpk;
        let fileFK = adminLogo.dataset.filefk;
        let backgroundColor = "";
        let textColor = "";

        //We'll need to add in values from the color pickers here to make sure there is something to save before making sure it's saved.
        if (fileFK !== null) {
            console.log(`Save ${saveButton.dataset.frcppinstitutionpk} + ${fileFK}`);
            let core = this._core;
            Core.showLoader();

            let formData: IDistrictConfigurationSave = {
                FileFK: parseInt(fileFK),
                FRCPPInstitutionFK: parseInt(frcppInstitutionFK),
                ReportHeaderBackgroundColor: backgroundColor,
                ReportHeaderTextColor: textColor
            };

            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/Admin/SaveDistrictConfiguration', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status == 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "The district configuration settings have been updated", "success", 3000, null);
                } else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                }
            };
            xhr.send(JSON.stringify(formData));
        }
        else {
            console.log("No new file uploaded. Nothing to save here.");
        }
        
    }

    uploadFile(e: HTMLInputElement) {
        let core = this._core;
        Core.showLoader();
        let uploadLogoForm = <HTMLFormElement>document.getElementById("uploadLogo");
        let formData = new FormData(uploadLogoForm);

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/UploadFile', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file has been successfully uploaded!", 'success', 3000, null);

                        let adminLogo = <HTMLDivElement>document.getElementById("adminLogo");
                        adminLogo.innerHTML = '';

                        let image = document.createElement("img");
                        image.src = `${res.fileBytes}`;
                        image.height = image.width = 192;
                        image.alt = "District Logo";
                        adminLogo.dataset.filefk = res.fileFK
                        adminLogo.classList.remove("empty");
                        adminLogo.appendChild(image);
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
}

//Language Editor
class LanguageEditor {
    private _core: Core;
    constructor() {
        this._core = new Core();
        this._core.activateSubMenu("languageEditor");
    }
}

//Resources
class Resources {
    private _core: Core;
    private allDeleteElements = [];
    constructor() {
        this._core = new Core();
        this._core.activateSubMenu("Resources");

        this.bindAll();

        let resourceSelect = document.getElementById("resourceType");
        if (resourceSelect !== null) {
            resourceSelect.addEventListener("change", (e: Event) => this.showResourceType(e))
        }
    }

    bindAll() {
        let searchButton = document.getElementById("ResourceSearch");
        if (searchButton !== null)
            searchButton.addEventListener("click", (e: Event) => this.search(e));
        let clearButton = document.getElementById("ResourceSearchClear");
        if (clearButton !== null)
            clearButton.addEventListener("click", (e: Event) => this.clearSearch(e));
        let saveButton = document.getElementById("ResourcesSave");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e: Event) => this.save());
        let newResourceSaveButton = document.getElementById("newResourceSave");
        if (newResourceSaveButton !== null)
            newResourceSaveButton.addEventListener("click", (e: Event) => this.saveNewResource());
        let resourceCheckboxes = document.getElementsByClassName("actionPlanSelectedMeasurableGoals");
        for (let resourceCheckbox of resourceCheckboxes) {
            //let element = <HTMLInputElement>resourceCheckbox;
            resourceCheckbox.addEventListener("change", (e: Event) => this.setDeleteElement(e));
        }

        let editResourceButtons = document.getElementsByClassName("editResource") as HTMLCollectionOf<HTMLButtonElement>;
        for (let editResourceButton of editResourceButtons) {
            editResourceButton.addEventListener("click", (e: Event) => this.showEditResourceModal(editResourceButton));
        }

        let editResourceSaveButton = <HTMLButtonElement>document.getElementById("editResourceSave");
        if (editResourceSaveButton !== null)
            editResourceSaveButton.addEventListener("click", (e: Event) => this.editResourceSave(editResourceSaveButton));

        let editResourceCancelButton = <HTMLButtonElement>document.getElementById("editResourceCancel");
        if (editResourceCancelButton !== null)
            editResourceCancelButton.addEventListener("click", (e: Event) => this.editResourceCancel());

        let deleteResourceButtons = document.getElementsByClassName("deleteResourceButton") as HTMLCollectionOf<HTMLButtonElement>;
        for (let deleteResourceButton of deleteResourceButtons) {
            deleteResourceButton.addEventListener("click", (e: Event) => this.deleteResource(e));
        }

    }

    showEditResourceModal(editResourceButton: HTMLButtonElement) {
        let resourceName = editResourceButton.dataset.resourcename;
        let resourceURL = editResourceButton.dataset.resourceurl;
        let resourceFile = editResourceButton.dataset.resourcefile;
        let resourcepk = editResourceButton.dataset.resourcepk;


        let modal: Modal = new Modal("editResourceModal", null);
        let editResourceSaveButton = <HTMLButtonElement>document.getElementById("editResourceSave");
        editResourceSaveButton.dataset.resourcename = resourceName;
        editResourceSaveButton.dataset.resourceurl = resourceURL;
        editResourceSaveButton.dataset.resourcefile = resourceFile;
        editResourceSaveButton.dataset.resourcepk = resourcepk;

        modal.show();
        let resourceNameInput = <HTMLInputElement>document.getElementById("ResourceNameEditInput");
        //let resourceFileInput = <HTMLInputElement>document.getElementById("resourceFileEditInput");
        let resourceURLInput = <HTMLInputElement>document.getElementById("resourceURLEditInput");
        resourceNameInput.value = resourceName;
        let file = document.getElementById("editFileUploadDiv");
        let link = document.getElementById("editURLDiv");

        if (resourceURL !== undefined && resourceURL !== "") {
            resourceURLInput.value = resourceURL;
            editResourceSaveButton.dataset.type = "link";
            link.classList.remove("is-hidden");
            file.classList.add("is-hidden");
        }
        if (resourceFile !== undefined && resourceFile !== "") {
            //resourceFileInput.value = resourceFile;
            editResourceSaveButton.dataset.type = "file";
            file.classList.remove("is-hidden");
            link.classList.add("is-hidden");
        }
    }

    editResourceSave(editResourceSaveButton: HTMLButtonElement) {
        let core = this._core;
        let that = this;
        let saveButton = editResourceSaveButton;
        let type = saveButton.dataset.type;
        let resourceNameElement = <HTMLInputElement>document.getElementById("ResourceNameEditInput");
        let resourceName = resourceNameElement.value;
        let resourceURLElement = <HTMLInputElement>document.getElementById("resourceURLEditInput");
        let resourceURL = "";
        if (resourceURLElement !== null) {
            resourceURL = resourceURLElement.value;
        }
        let uploadForm = <HTMLFormElement>document.getElementById("resourceEditFileUpload");
        let formData = new FormData(uploadForm);
        let resource: IResource = {
            ResourcePK: parseInt(saveButton.dataset.resourcepk),
            ResourceName: resourceName,
            ResourceURL: null,
            ResourceCategoryCodeFK: null,
            FileUploadFK: null,
            IsDeletedInd: false
        };
        //you cant get the files.length from the uploadForm, so we need this
        let upload = <HTMLInputElement>document.getElementById("resourceEditFileUpload");

        if (type === "file" && upload[0].files.length > 0) {
            that.uploadResourceFileForExistingResource(formData, resource);
        }
        else if (type === "file" && upload[0].files.length === 0) {
            resource.FileUploadFK = parseInt(saveButton.dataset.resourcefile);
            that.updateResource(resource);
        }
        else if (type === "link") {
            resource.ResourceURL = resourceURL;
            that.updateResource(resource);
        }
        else
        {
            Core.createHTMLAlert("alertMessageDiv", "Save failed.", 'error', 3000, null);
        }
    }

    editResourceCancel() {
        let modal: Modal = new Modal("editResourceModal", null);
        modal.hide();
    }

    showResourceType(e: Event) {
        let element = <HTMLSelectElement>e.srcElement;
        let file = document.getElementById("fileUploadDiv");
        let link = document.getElementById("URLDiv");
        let linkInput = <HTMLInputElement>document.getElementById("resourceLinkInput");
        let fileInput = <HTMLInputElement>document.getElementById("resourceFileInput");

        if (element.value === "file") {
            file.classList.remove("is-hidden");
            link.classList.add("is-hidden");
            if (linkInput !== null) {
                linkInput.value = "";
            }
        }
        else if (element.value === "link") {
            file.classList.add("is-hidden");
            link.classList.remove("is-hidden");
            fileInput.value = "";
        }
        else {
            file.classList.add("is-hidden");
            link.classList.add("is-hidden");
            fileInput.value = "";
            linkInput.value = "";
        }
    }

    setDeleteElement(e: Event) {
        let element = <HTMLInputElement>e.srcElement;
        if (element.checked === false && parseInt(element.dataset.sequencenbr) !== 0) {
            let saveItem: IResourcePage = {
                ResourceFK: parseInt(element.dataset.resourcefk),
                PageFK: parseInt(element.dataset.pagefk),
                SequenceNbr: parseInt(element.dataset.sequencenbr),
            };

            this.allDeleteElements.push(saveItem);
        }
    }

    clearSearch(e: Event) {
        Core.createHTMLAlert("alertMessageDiv", "ReloadingPage", 'success', 3000, this._core.pageReload());
    }

    searchName(searchvalue: string) {
        let searchString = searchvalue.toUpperCase().split(" ");
        let searchRows = document.getElementsByClassName("rowForSearch");
        let resourceName = <HTMLInputElement>document.getElementById("ResourceNameInput");
        for (let searchRow of searchRows) {
            let count = 0;
            let element = <HTMLElement>searchRow;
            let searchRowValue = element.dataset.resourcename.toUpperCase();

            for (let search of searchString) {
                if (searchRowValue.includes(search)) {
                    count++;
                }
            }
            if (count === 0) {
                element.style.display = "none";
            }
            if (count !== 0) {
                element.style.display = "block";
            }
        }
        resourceName.value = searchvalue;
    }

    search(e: Event) {
        let core = this._core;
        let that = this;
        let resourceName = <HTMLInputElement>document.getElementById("ResourceNameInput");
        let resourcePage = <HTMLSelectElement>document.getElementById("ResourcePageSearchDropDown");
        let resourcePageValue = 0;
        let resourceNameValue = "";
        if (resourcePage !== null) {
            resourcePageValue = parseInt(resourcePage.value);
        } 
        if (resourceName !== null) {
            resourceNameValue = resourceName.value;
        }
        if ((resourceName !== null || resourceName.value !== "") && (resourcePage !== null && resourcePageValue === 0)) {
            that.searchName(resourceNameValue);
        }
        else {
            if (resourcePage !== null) {
                let xhr = new XMLHttpRequest();

                xhr.open('POST', '/Admin/SearchResoursesByPage', true);
                xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        let document = window.document.body;
                        document.innerHTML = xhr.responseText;
                        that.bindAll();
                        that.allDeleteElements = [];
                        $('.multiSelectResources').MultiSelect();
                        if (resourceNameValue !== "") {
                            that.searchName(resourceNameValue);
                        }
                        Core.createHTMLAlert("alertMessageDiv", "New data loaded", 'success', 3000, null);
                    }
                    else {
                        Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                    }
                };
                xhr.send("resourcePageValue=" + resourcePageValue);
            }
        }
    }

    uploadNewResourceFile(formData: FormData) {
        let core = this._core;
        let that = this;
        Core.showLoader();

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/UploadResourceFile', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file has been successfully uploaded!", 'success', 3000, null);
                        that.saveNewFileResource(res.resourceFileFK);
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                        return false;
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.  Please make sure you have a file chosen.", 'error', 3000, null);
                return false;
            }
        };
            xhr.send(formData);
    }

    uploadResourceFileForExistingResource(formData: FormData, resource: IResource) {
        let core = this._core;
        let that = this;
        Core.showLoader();

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/UploadResourceFile', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file has been successfully uploaded!", 'success', 3000, null);
                        resource.FileUploadFK = res.resourceFileFK;
                        that.updateResource(resource);
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                        return false;
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                return false;
            }
        };
        xhr.send(formData);
    }

    saveNewResource() {
        let core = this._core;
        let that = this;
        let resourceName = <HTMLInputElement>document.getElementById("resourceNameInput");
        let resourceNameValue = resourceName.value;
        if (resourceNameValue === "" || resourceNameValue === null) {
            Core.createHTMLAlert("alertMessageDiv", "Please enter a Resource Name", 'error', 3000, null);
            return false;
        }
        let resourceType = <HTMLSelectElement>document.getElementById("resourceType");
        let resourceTypeValue = resourceType.value;
        if (resourceTypeValue === "file") {
            let uploadForm = <HTMLFormElement>document.getElementById("resourceFileUpload");
            let formData = new FormData(uploadForm);
            //you cant get the files.length from the uploadForm, so we need this
            let upload = <HTMLInputElement>document.getElementById("resourceFileUpload");
            if (upload[0].files.length > 0) {
                that.uploadNewResourceFile(formData);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Please upload a file first.", 'error', 3000, null);
                return false;
            }
        }
        if (resourceTypeValue === "link") {
            let resourceURL = <HTMLInputElement>document.getElementById("resourceURLInput");
            let resourceURLValue = resourceURL.value;
            if (resourceURLValue !== null && resourceURLValue !== "") {
                that.saveNewLinkResource();
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Please enter a URL for the link. ", 'error', 3000, null);
                return false;
            }
        }
        else {
            Core.createHTMLAlert("alertMessageDiv", "Please choose a resource Type", 'error', 3000, null);
        }
    }

    saveNewLinkResource() {
        let core = this._core;
        let that = this;
        Core.showLoader();
        let saveObject: ISaveResourceObject;
        let allSaveElements = [];
        
        let resourceURL = <HTMLInputElement>document.getElementById("resourceURLInput");
        let resourceURLValue = resourceURL.value;
        let resourceName = <HTMLInputElement>document.getElementById("resourceNameInput");
        let resourceNameValue = resourceName.value;
        let resource: IResource = {
            ResourcePK: 0,
            ResourceName: resourceNameValue,
            ResourceURL: resourceURLValue,
            ResourceCategoryCodeFK: null,
            FileUploadFK: null,
            IsDeletedInd: false
        };

        let selectElements = document.getElementsByClassName("multiSelectResourcesNew");

        for (let selectElement of selectElements) {
            let element = <HTMLDivElement>selectElement;
            let selection = element.querySelectorAll("ul li.selected");
            if (selection.length > 0) {
                for (let s of selection) {
                    let sequenceLength = selection.length;
                    let sElement = <HTMLLIElement>s;
                    let nextElement = sElement.querySelector("input");
                    let inputElement = <HTMLElement>nextElement;
                    let saveItem: IResourcePage = {
                        ResourceFK: 0,
                        PageFK: parseInt(inputElement.dataset.pagefk),
                        SequenceNbr: 1,
                    };

                    allSaveElements.push(saveItem);
                }
            }
        }

        saveObject = {
            Resource: resource,
            SavePageList: allSaveElements,
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/CreateNewResource', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, core.pageReload(true, null));
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));
    }

    updateResource(resource: IResource) {
        let core = this._core;
        Core.showLoader();
        let saveObject: ISaveResourceObject;
        let allSaveElements = [];
       
        saveObject = {
            Resource: resource,
            SavePageList: allSaveElements,
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/CreateNewResource', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, core.pageReload(true, null));
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));
    }

    saveNewFileResource(resourceFileFK: number) {
        let core = this._core;
        Core.showLoader();
        let saveObject: ISaveResourceObject;
        let allSaveElements = [];

        let resourceName = <HTMLInputElement>document.getElementById("resourceNameInput");
        let resourceNameValue = resourceName.value;
        let resource: IResource = {
            ResourcePK: 0,
            ResourceName: resourceNameValue,
            ResourceURL: null,
            ResourceCategoryCodeFK: null,
            FileUploadFK: resourceFileFK,
            IsDeletedInd: false
        };


        let selectElements = document.getElementsByClassName("multiSelectResourcesNew");

        for (let selectElement of selectElements) {
            let element = <HTMLDivElement>selectElement;
            let selection = element.querySelectorAll("ul li.selected");
            if (selection.length > 0) {
                for (let s of selection) {
                    let sequenceLength = selection.length;
                    let sElement = <HTMLLIElement>s;
                    let nextElement = sElement.querySelector("input");
                    let inputElement = <HTMLElement>nextElement;
                    let saveItem: IResourcePage = {
                        ResourceFK: 0,
                        PageFK: parseInt(inputElement.dataset.pagefk),
                        SequenceNbr: 1,
                    };

                    allSaveElements.push(saveItem);
                }
            }
        }

        saveObject = {
            Resource: resource,
            SavePageList: allSaveElements,
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/CreateNewResource', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, core.pageReload(true, null));
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));
    }

    save() {
        let core = this._core;
        Core.showLoader();
        let saveObject: ISaveResourcePage;
        let allSaveElements = [];

        let selectElements = document.getElementsByClassName("multiSelectResourcesRow");

        for (let selectElement of selectElements) {
            let element = <HTMLDivElement>selectElement;
                let selection = element.querySelectorAll("ul li.selected");
                if (selection.length > 0) {
                    for (let s of selection) {
                        let sequenceLength = selection.length;
                        let sElement = <HTMLLIElement>s;
                        let nextElement = sElement.querySelector("input");
                        let inputElement = <HTMLElement>nextElement;
                        let sequenceNbr = parseInt(inputElement.dataset.sequencenbr);
                        if (sequenceNbr === 0) {
                            sequenceNbr = 1;
                        }
                        let saveItem: IResourcePage = {
                            ResourceFK: parseInt(inputElement.dataset.resourcefk),
                            PageFK: parseInt(inputElement.dataset.pagefk),
                            SequenceNbr: parseInt(inputElement.dataset.resourcefk),
                        };

                        allSaveElements.push(saveItem);
                    }
                }
        }

        saveObject = {
            SaveList: allSaveElements,
            DeleteList: this.allDeleteElements
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SaveResourcePage', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, core.pageReload(true, null));
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));
    }

    deleteResource(e: Event) {
        let core = this._core;
        let button = <HTMLButtonElement>e.srcElement;
        let resourcePK = parseInt(button.dataset.resourcepk);

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/DeleteResourceByResourcePK', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Row Successfully Removed", 'success', 3000, core.pageReload(true, null));
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("resourcePK=" + resourcePK);
    }
}

////EditLEA
//class EditLEA {
//    private _core: Core;
//    constructor() {
//        this._core = new Core();
//        this._core.activateSubMenu("EditLEA");
//    }
//}

class CommunicateWithLEA {
    private _core: Core;

    constructor() {
        this._core = new Core();
        this._core.activateSubMenu("CommunicateWithLEA");

        let comprehensivePlansTab = <HTMLButtonElement>document.querySelector(".tablinks.comprehensivePlans");
        if (comprehensivePlansTab != null) {
            comprehensivePlansTab.addEventListener("click", (e: Event) => this.tabLinks("CommunicateWithLEA"));
        }

        let schoolPlansTab = <HTMLButtonElement>document.querySelector(".tablinks.schoolPlans");
        if (schoolPlansTab != null) {
            schoolPlansTab.addEventListener("click", (e: Event) => this.tabLinks("SchoolCommunicate"));
        }

        let stateReportsTab = <HTMLButtonElement>document.querySelector(".tablinks.stateReports");
        if (stateReportsTab != null) {
            stateReportsTab.addEventListener("click", (e: Event) => this.tabLinks("StateReportsCommunicate"));
        }

        let leaClearButton = <HTMLButtonElement>document.getElementById("clearSearch");
        if (leaClearButton !== null)
            leaClearButton.addEventListener("click", (e: Event) => this.clearLEASearchFilter());

        let leaSearchButton = <HTMLButtonElement>document.getElementById("runSearch");
        if (leaSearchButton !== null)
            leaSearchButton.addEventListener("click", (e: Event) => Core.PlanSearch(1, '/Admin/CommunicateWithLEA'));

        let leaPlanName = <HTMLInputElement>document.getElementById("leaName");
        if (leaPlanName !== null)
            leaPlanName.addEventListener("keypress", (e: KeyboardEvent) => { if (e.keyCode === 13) { Core.PlanSearch(1, '/Admin/CommunicateWithLEA'); } });

        let emailButtons = document.getElementsByClassName("emailButton");
        for (let emailButton of emailButtons) {
            //let element = <HTMLInputElement>resourceCheckbox;
            emailButton.addEventListener("click", (e: Event) => this.sendEmail(e));
        }

        this._core.bindChangeLEA();
    }
    
    sendEmail(e: Event) {
        Core.showLoader();
        let planfkList: number[] = [];
        let checkboxes = document.getElementsByClassName("emailCheckBox");
        for (let checkbox of checkboxes) {
            let element = <HTMLInputElement>checkbox;
            let value = "";
            if (element.checked === true) {
                value = element.dataset.planfk;
                var planfk = parseInt(value);
                planfkList.push(planfk);
            }
        }
        if (planfkList.length > 0) {
            //make call to controller to get email string
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/Admin/GetEmailString', true);
            xhr.setRequestHeader('Content-type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    var resp = JSON.parse(xhr.response);

                    if (resp.success === true) {
                        Core.hideLoader();
                        if (resp.emailAddress.length === 0) {
                            Core.createHTMLAlert("alertMessageDiv", "No Email Addresses found", "error", 3000, null);
                        }
                        else {
                            window.location.href = `mailto:?bcc=${resp.emailAddress}`;
                        }
                    }
                    else {
                        Core.createHTMLAlert("alertMessageDiv", "Request failed.Returned status of '" + xhr.status, "error", 3000, null);
                        Core.hideLoader();
                    }
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send(JSON.stringify(planfkList));
        }
        else {
            alert("Please choose someone to email to.")
        }
    }

    clearLEASearchFilter() {
        window.location.href = "/Admin/CommunicateWithLEA";
    }

    tabLinks(link: string) {
        let url = "/Admin/" + link;
        window.location.href = url;
    }

}

class SchoolCommunicate {
    private _core: Core;

    constructor() {
        this._core = new Core();
        this._core.activateSubMenu("CommunicateWithLEA");

        let comprehensivePlansTab = <HTMLButtonElement>document.querySelector(".tablinks.comprehensivePlans");
        if (comprehensivePlansTab != null) {
            comprehensivePlansTab.addEventListener("click", (e: Event) => this.tabLinks("CommunicateWithLEA"));
        }

        let schoolPlansTab = <HTMLButtonElement>document.querySelector(".tablinks.schoolPlans");
        if (schoolPlansTab != null) {
            schoolPlansTab.addEventListener("click", (e: Event) => this.tabLinks("SchoolCommunicate"));
        }

        let stateReportsTab = <HTMLButtonElement>document.querySelector(".tablinks.stateReports");
        if (stateReportsTab != null) {
            stateReportsTab.addEventListener("click", (e: Event) => this.tabLinks("StateReportsCommunicate"));
        }

        let schoolClearButton = <HTMLButtonElement>document.getElementById("clearSearch");
        schoolClearButton.addEventListener("click", (e: Event) => this.clearSchoolSearchFilter());

        let schoolSearchButton = <HTMLButtonElement>document.getElementById("runSearch");
        schoolSearchButton.addEventListener("click", (e: Event) => Core.PlanSearch(1, '/Admin/SchoolCommunicate'));

        let schoolPlanName = <HTMLInputElement>document.getElementById("schoolName");
        schoolPlanName.addEventListener("keypress", (e: KeyboardEvent) => { if (e.keyCode === 13) { Core.PlanSearch(1, '/Admin/SchoolCommunicate'); } });

        let emailButtons = document.getElementsByClassName("emailButton");
        for (let emailButton of emailButtons) {
            emailButton.addEventListener("click", (e: Event) => this.sendEmail(e));
        }

        this._core.bindChangeLEA();
    }

    sendEmail(e: Event) {
        Core.showLoader();
        let planfkList: number[] = [];
        let checkboxes = document.getElementsByClassName("emailCheckBox");
        for (let checkbox of checkboxes) {
            let element = <HTMLInputElement>checkbox;
            let value = "";
            if (element.checked === true) {
                value = element.dataset.planfk;
                var planfk = parseInt(value);
                planfkList.push(planfk);
            }
        }
        if (planfkList.length > 0) {
            //make call to controller to get email string
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/Admin/GetEmailString', true);
            xhr.setRequestHeader('Content-type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    var resp = JSON.parse(xhr.response);

                    if (resp.success === true) {
                        Core.hideLoader();
                        if (resp.emailAddress.length === 0) {
                            Core.createHTMLAlert("alertMessageDiv", "No Email Addresses found", "error", 3000, null);
                        }
                        else {
                            window.location.href = `mailto:?bcc=${resp.emailAddress}`;
                        }
                    }
                    else {
                        Core.createHTMLAlert("alertMessageDiv", "Request failed.Returned status of '" + xhr.status, "error", 3000, null);
                        Core.hideLoader();
                    }
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send(JSON.stringify(planfkList));
        }
        else {
            alert("Please choose someone to email to.")
        }
    }

    tabLinks(link: string) {
        let url = "/Admin/" + link;
        window.location.href = url;
    }

    redirect(e: Event, url: string) {
        let element = <HTMLButtonElement>e.srcElement;
        let planPK = element.dataset.planpk;
        let newURL = url + planPK;

        window.location.href = newURL;
    }

    clearSchoolSearchFilter() {
        window.location.href = "/Admin/SchoolCommunicate";
    }
}

class StateReportsCommunicate {
    private _core: Core;

    constructor() {
        this._core = new Core();
        this._core.activateSubMenu("CommunicateWithLEA");

        let comprehensivePlansTab = <HTMLButtonElement>document.querySelector(".tablinks.comprehensivePlans");
        if (comprehensivePlansTab != null) {
            comprehensivePlansTab.addEventListener("click", (e: Event) => this.tabLinks("CommunicateWithLEA"));
        }

        let schoolPlansTab = <HTMLButtonElement>document.querySelector(".tablinks.schoolPlans");
        if (schoolPlansTab != null) {
            schoolPlansTab.addEventListener("click", (e: Event) => this.tabLinks("SchoolCommunicate"));
        }

        let stateReportsTab = <HTMLButtonElement>document.querySelector(".tablinks.stateReports");
        if (stateReportsTab != null) {
            stateReportsTab.addEventListener("click", (e: Event) => this.tabLinks("StateReportsCommunicate"));
        }

        let stateClearButton = <HTMLButtonElement>document.getElementById("clearSearch");
        stateClearButton.addEventListener("click", (e: Event) => this.clearStateSearchFilter());

        let stateSearchButton = <HTMLButtonElement>document.getElementById("runSearch");
        stateSearchButton.addEventListener("click", (e: Event) => Core.PlanSearch(1, '/Admin/StateReportsCommunicate'));

        let schoolPlanName = <HTMLInputElement>document.getElementById("leaName");
        schoolPlanName.addEventListener("keypress", (e: KeyboardEvent) => { if (e.keyCode === 13) { Core.PlanSearch(1, '/Admin/StateReportsCommunicate'); } });

        let emailButtons = document.getElementsByClassName("emailButton");
        for (let emailButton of emailButtons) {
            emailButton.addEventListener("click", (e: Event) => this.sendEmail(e));
        }

        let selectAll = document.getElementById("selectAllSchool");
        if (selectAll !== null) {
            selectAll.addEventListener("change", (e: Event) => this.selectAllSchools(<HTMLInputElement>e.srcElement));
        }

        this._core.bindChangeLEA();
    }

    clearStateSearchFilter() {
        window.location.href = "/Admin/StateReportsCommunicate";
    }

    sendEmail(e: Event) {
        Core.showLoader();
        let planfkList: number[] = [];
        let checkboxes = document.getElementsByClassName("emailCheckBox");
        for (let checkbox of checkboxes) {
            let element = <HTMLInputElement>checkbox;
            let value = "";
            if (element.checked === true) {
                value = element.dataset.planfk;
                var planfk = parseInt(value);
                planfkList.push(planfk);
            }
        }
        if (planfkList.length > 0)
        {
            //make call to controller to get email string
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/Admin/GetEmailString', true);
            xhr.setRequestHeader('Content-type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    var resp = JSON.parse(xhr.response);

                    if (resp.success === true) {
                        Core.hideLoader();
                        if (resp.emailAddress.length === 0) {
                            Core.createHTMLAlert("alertMessageDiv", "No Email Addresses found", "error", 3000, null);
                        }
                        else {
                            window.location.href = `mailto:?bcc=${resp.emailAddress}`;
                        }
                    }
                    else {
                        Core.createHTMLAlert("alertMessageDiv", "Request failed.Returned status of '" + xhr.status, "error", 3000, null);
                        Core.hideLoader();
                    }
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send(JSON.stringify(planfkList));
        }
        else {
            alert("Please choose someone to email to.")
        }
    }

    tabLinks(link: string) {
        let url = "/Admin/" + link;
        window.location.href = url;
    }

    redirect(e: Event, url: string) {
        let element = <HTMLButtonElement>e.srcElement;
        let planPK = element.dataset.planpk;
        let newURL = url + planPK;

        window.location.href = newURL;
    }

    selectAllSchools(selectAllCheckbox: HTMLInputElement) {
        let allCheckboxes = document.getElementsByClassName("emailCheckBox");
        let allChecked: boolean = false;
        if (selectAllCheckbox.checked) {
            allChecked = true;
        }

        if (allChecked) {
            for (let checkbox of allCheckboxes) {
                let element = <HTMLInputElement>checkbox;
                element.checked = true;
            }
        } else {
            for (let checkbox of allCheckboxes) {
                let element = <HTMLInputElement>checkbox;
                element.checked = false;
            }
        }
    }
}

//HomePage
class HomePage {
    private _core: Core;
    constructor() {
        this._core = new Core();
        this._core.activateSubMenu("HomePage");
        this.bindDeleteButtons();
        this.bindIndSaveButtons();
        let addNewRowButtons = document.getElementsByClassName("addAnotherPost");
        for (let addNewRowButton of addNewRowButtons) {
            addNewRowButton.addEventListener("click", (e: Event) => this.addAnotherPost(e));
        }

        this._core.activateSubMenu("SchoolPlans");
    }

    bindDeleteButtons() {
        let deleteButtons = document.getElementsByClassName("deleteRow");
        for (let deleteButton of deleteButtons) {
            deleteButton.addEventListener("click", (e: Event) => this.removeNewsUpdateRow(e));
        }
    }

    bindIndSaveButtons() {
        let indSaveButtons = document.getElementsByClassName("savenewsupdates");
        for (let indSaveButton of indSaveButtons) {
            indSaveButton.addEventListener("click", (e: Event) => this.saveNewsUpdate(e));
        }
    }

    saveNewsUpdate(e: Event) {
        let core = this._core;
        //Core.showLoader();
        let button = <HTMLButtonElement>e.currentTarget;
        let newsUpdatePK = parseInt(button.dataset.newsupdatepk);
        let titleElement = <HTMLInputElement>document.getElementById("title_" + newsUpdatePK);
        let titleElementValue = titleElement.value;

        let startDatePicker = <HTMLInputElement>document.getElementById("startDate_" + newsUpdatePK);
        let startDatePickerValue = startDatePicker.value;
        let startUTC = new Date(startDatePickerValue);
        let startDate = new Date(startUTC.getTime() + startUTC.getTimezoneOffset() * 60000);// this converts it back to the day it should be.

        let endDatePicker = <HTMLInputElement>document.getElementById("endDate_" + newsUpdatePK);
        let endDatePickerValue = endDatePicker.value;
        let endUTC = new Date(endDatePickerValue);
        let endDate = new Date(endUTC.getTime() + endUTC.getTimezoneOffset() * 60000);// this converts it back to the day it should be.
        let checkElement = <HTMLInputElement>document.getElementById("show_" + newsUpdatePK);
        let showOnHomePage = false;
        if (checkElement.checked) {
            showOnHomePage = true;
        }

        let saveObject: ISaveNewsUpdate = {
            NewsUpdatePK: newsUpdatePK,
            NewsTitle: titleElementValue,
            NewsDesc: " ",
            DisplayStartDate: startDate,
            DisplayEndDate: endDate,
            ShowOnHomePageInd: showOnHomePage,
            IsDeletedInd: false
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SaveNewsUpdate', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, core.pageReload(true, null));
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));
    }

    addAnotherPost(e: Event) {
        let core = new Core();
        let that = this;
        let button = <HTMLButtonElement>e.currentTarget;
        button.disabled = true;
        let tableName = button.dataset.tableid;
        let table = (<HTMLTableElement>document.getElementById(tableName));

        let xhr = new XMLHttpRequest();

        xhr.open('POST', '/Admin/AddAnotherPost', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = table.querySelector("tbody");
                $(element).append(xhr.responseText);
                Core.createHTMLAlert("alertMessageDiv", "New Post Row Added", 'success', 3000, null);
                that.bindDeleteButtons();
                that.bindIndSaveButtons();
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send();
    }

    removeNewsUpdateRow(e: Event) {
        let core = new Core();
        let button = <HTMLButtonElement>e.currentTarget;
        let newsUpdatePK = parseInt(button.dataset.newsupdatepk);
        let parent = button.parentElement.parentElement; //To the TR
        let xhr = new XMLHttpRequest();

        xhr.open('POST', '/Admin/DeleteNewsUpdateByNewsUpdatePK', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                parent.parentElement.removeChild(parent);
                Core.createHTMLAlert("alertMessageDiv", "Row Successfully Removed", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("newsUpdatePK=" + newsUpdatePK);

    }

}

//Read Only
class ReadOnly {
    constructor() {
        let core = new Core();

        this.bindDeleteButtons();
        this.bindDeleteAllAccessButtons();
        this.bindSaveUserAccessButtons();
        this.bindAutocompletes();

        let leaClearButton = <HTMLButtonElement>document.getElementById("readOnlySearchClear");
        if (leaClearButton !== null)
            leaClearButton.addEventListener("click", (e: Event) => this.clearLEASearchFilter());

        let leaSearchButton = <HTMLButtonElement>document.getElementById("readOnlySearchSearch");
        if (leaSearchButton !== null)
            leaSearchButton.addEventListener("click", (e: Event) => this.leaSearch());

        let addNewRowButtons = document.getElementsByClassName("addAnotherPlan");
        for (let addNewRowButton of addNewRowButtons) {
            addNewRowButton.addEventListener("click", (e: Event) => this.addAnotherPlan(e));
        }
        core.activateSubMenu("readOnly");
    }

    bindDeleteButtons() {
        let deleteButtons = document.getElementsByClassName("deleteRow");
        for (let deleteButton of deleteButtons) {
            deleteButton.addEventListener("click", (e: Event) => this.removeReadOnlyRow(e));
        }
    }

    bindAutocompletes() {
        let hidden = <HTMLInputElement>document.getElementById("hiddenlealist");
        let hiddenValue = hidden.value;
        let thisarray = new Array();
        thisarray = hiddenValue.split(',');
        let autocompletes = document.getElementsByClassName("institutionAutocomplete");
        for (let autocomplete of autocompletes) {
            let ac = <HTMLInputElement>autocomplete;
            let row = ac.dataset.rownumber;
            let tableid = ac.dataset.tableid
            this.autocomplete(autocomplete, thisarray, row, tableid);
        }
    }

    bindDeleteAllAccessButtons() {
        let deleteAllAccessButtons = document.getElementsByClassName("removeUserAccess");
        for (let deleteAllAccessButton of deleteAllAccessButtons) {
            deleteAllAccessButton.addEventListener("click", (e: Event) => this.removeUserAccess(e));
        }
    }

    bindSaveUserAccessButtons() {
        let saveUserAccessButtons = document.getElementsByClassName("saveUserAccess");
        for (let saveUserAccessButton of saveUserAccessButtons) {
            saveUserAccessButton.addEventListener("click", (e: Event) => this.saveUserAccess(e));
        }
    }

    clearLEASearchFilter() {
        let nameElement = <HTMLInputElement>document.getElementById("readOnlySearchClear");

        nameElement.value = "";

        //TODO: Turn this into a partial view, instead of a reload.
        window.location.href = "/Admin/ReadOnly";
    }

    leaSearch() {
        let nameElement = <HTMLInputElement>document.getElementById("institutionNameSearch");
        let name = nameElement.value;

        let url = "/Admin/ReadOnly/";
        if (name !== "")
            url += "?schoolName=" + name;

        window.location.href = url;
    }

    removeLeaLevelUserRow(e: Event) {
        let core = new Core();
        let saveObject: IPDEBureauUserAccessObject;
        let allSaveElements = [];
        let allDeleteElements = [];
        let button = <HTMLButtonElement>e.currentTarget;
        let parent = button.parentElement.parentElement; //To the TR
        //parent.parentElement.removeChild(parent); // remove the row from the dom
        let edDirUserFK = button.dataset.eddiruserpk;// start to do a normal save
        let selectedValue = button.dataset.selectedvalue;
        let buttonInstitutionFK = button.dataset.institutionfk;
        let rowId = button.dataset.rowid;
        //let rowValue = 0;
        //if (selectedValue !== "none") {
        //    rowValue = parseInt(selectedValue);
        //}
        //if (rowValue !== 0 && rowValue < 11) {
        //    parent.parentElement.removeChild(parent); // remove the row from the dom for LEA level plans
        //}
        let tableName = button.dataset.tableid;
        let table = (<HTMLTableElement>document.getElementById(tableName));
        let rows = table.querySelectorAll(`tr[data-rownumber="${rowId}"]`);
        let numberOfLEARows = 0;

        for (let row of rows) {
            let allElements = row.getElementsByClassName("adminCheckbox");
            let selectElements = row.getElementsByClassName("adminSelect");
            let selectElementValue = 0;
            let isSchool = "True";
            for (let selectElement of selectElements) {
                let element = <HTMLSelectElement>selectElement;
                selectElementValue = parseInt(element.value);
                isSchool = element.options[element.selectedIndex].getAttribute('data-isschool');
            }
            for (let element of allElements) {
                let htmlElement = <HTMLInputElement>element;
                let edDirUserPK: number = parseInt((<HTMLTableRowElement>row).dataset.usereddiruserpk);
                let institutionFK: number = 0;
                let accessTypeCodeFK: number = 0;
                //let isSchool = true;
                //if (selectElementValue !== 0 && selectElementValue < 8) {
                //    isSchool = false;
                //}
                if (isSchool === "False" || isSchool === "false") {
                    institutionFK = parseInt((<HTMLTableRowElement>row).dataset.leainstitution);
                    accessTypeCodeFK = selectElementValue;
                }
                else {
                    institutionFK = selectElementValue;
                    accessTypeCodeFK = 8;
                }

                if (htmlElement.dataset.wasSelected === "True") {
                    let deleteItem: IUserAccessCreate = {
                        UserFK: edDirUserPK,
                        AccessCodeFK: parseInt(htmlElement.value),
                        FRCPPInstitutionFK: institutionFK,
                        AccessTypeCodeFK: accessTypeCodeFK
                    };

                    allDeleteElements.push(deleteItem);
                }
            }
        }

        saveObject = {
            CreateList: allSaveElements,
            DeleteList: allDeleteElements
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SaveLEAUserAccess', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, null);
                parent.parentElement.removeChild(parent);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));

        //for (let row of rows) {
        //    let allElements = row.getElementsByClassName("adminCheckbox");
        //    let selectElements = row.getElementsByClassName("adminSelect");
        //    let selectElementValue = 0;
        //    for (let selectElement of selectElements) {
        //        let element = <HTMLSelectElement>selectElement;
        //        selectElementValue = parseInt(element.value);
        //    }
        //    for (let element of allElements) {
        //        let htmlElement = <HTMLInputElement>element;
        //        let edDirUserPK: number = parseInt((<HTMLTableRowElement>row).dataset.usereddiruserpk);
        //        let institutionFK: number = 0;
        //        let accessTypeCodeFK: number = 0;
        //        let isSchool = true;
        //        if (selectElementValue !== 0 && selectElementValue < 8) {
        //            isSchool = false;
        //        }
        //        if (isSchool === false) {
        //            institutionFK = parseInt((<HTMLTableRowElement>row).dataset.leainstitution);
        //            accessTypeCodeFK = selectElementValue;
        //            numberOfLEARows++;
        //        }
        //        else {
        //            institutionFK = selectElementValue;
        //            accessTypeCodeFK = 8;
        //        }

        //        if (htmlElement.checked && selectElementValue !== rowValue) {
        //            let saveItem: IEdDirUserAccessCreate = {
        //                EdDirUserFK: edDirUserPK,
        //                AccessCodeFK: parseInt(htmlElement.value),
        //                InstitutionFK: institutionFK,
        //                AccessTypeCodeFK: accessTypeCodeFK,
        //                UserEdDirUserPK: ""
        //            };

        //            allSaveElements.push(saveItem);
        //        }
        //    }
        //}
        //if (rowValue !== 0 && rowValue > 11) {
        //    parent.parentElement.removeChild(parent); // remove the row from the dom for school level plans
        //}

        //if (allSaveElements.length === 0) {
        //    let instfk = 0
        //    if (rowValue !== 0 && rowValue <= 11) {
        //        instfk = parseInt(buttonInstitutionFK);
        //    }
        //    else {
        //        instfk = rowValue;
        //    }
        //    let saveItem: IEdDirUserAccessCreate = {
        //        EdDirUserFK: parseInt(edDirUserFK),
        //        AccessCodeFK: 0,
        //        InstitutionFK: instfk,
        //        AccessTypeCodeFK: 0,
        //        UserEdDirUserPK: ""
        //    };

        //    allSaveElements.push(saveItem);

        //    let xhr = new XMLHttpRequest();
        //    xhr.open('POST', '/Admin/DeleteAllEdDirUserAccess', true);
        //    xhr.setRequestHeader('Content-Type', 'application/json');
        //    xhr.onload = function () {
        //        if (xhr.status === 200) {
        //            Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, null);
        //        }
        //        else {
        //            Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
        //        }
        //    };
        //    xhr.send(JSON.stringify(allSaveElements));
        //}
        //else if (allSaveElements.length > 0 && rowValue !== 0 && rowValue > 11) {  // if you clicked on a school row delete it

        //    let saveItem: IEdDirUserAccessCreate = {
        //        EdDirUserFK: parseInt(edDirUserFK),
        //        AccessCodeFK: 0,
        //        InstitutionFK: rowValue,
        //        AccessTypeCodeFK: 0,
        //        UserEdDirUserPK: ""
        //    };

        //    allDeleteElements.push(saveItem);

        //    let xhr = new XMLHttpRequest();
        //    xhr.open('POST', '/Admin/DeleteAllEdDirUserAccess', true);
        //    xhr.setRequestHeader('Content-Type', 'application/json');
        //    xhr.onload = function () {
        //        if (xhr.status === 200) {
        //            Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, null);
        //        }
        //        else {
        //            Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
        //        }
        //    };
        //    xhr.send(JSON.stringify(allDeleteElements));
        //}
        //else if (allSaveElements.length > 0 && rowValue !== 0 && rowValue <= 11 && numberOfLEARows === 0) {  // if you clicked on an LEA row when there are more than 1 rows left but only one of them is and LEA row

        //    this.deleteLastLEARow(parseInt(edDirUserFK), parseInt(buttonInstitutionFK));

        //    let xhr = new XMLHttpRequest();
        //    xhr.open('POST', '/Admin/SaveEdDirUserAccess', true);
        //    xhr.setRequestHeader('Content-Type', 'application/json');
        //    xhr.onload = function () {
        //        if (xhr.status === 200) {
        //            Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, null);
        //        }
        //        else {
        //            Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
        //        }
        //    };
        //    xhr.send(JSON.stringify(allSaveElements));
        //}
        //else {
        //    let xhr = new XMLHttpRequest();
        //    xhr.open('POST', '/Admin/SaveEdDirUserAccess', true);
        //    xhr.setRequestHeader('Content-Type', 'application/json');
        //    xhr.onload = function () {
        //        if (xhr.status === 200) {
        //            Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, null);
        //        }
        //        else {
        //            Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
        //        }
        //    };
        //    xhr.send(JSON.stringify(allSaveElements));
        //}
    }

    deleteLastLEARow(EdDirUserFK, InstitutionFK) {
        let core = new Core();
        let allDeleteElements = [];
        let saveItem: IEdDirUserAccessCreate = {
            EdDirUserFK: EdDirUserFK,
            AccessCodeFK: 0,
            InstitutionFK: InstitutionFK,
            AccessTypeCodeFK: 0,
            UserEdDirUserPK: ""
        };

        allDeleteElements.push(saveItem);

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/DeleteAllEdDirUserAccess', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(allDeleteElements));
    }

    addAnotherPlan(e: Event) {
        let core = new Core();
        let that = this;
        let button = <HTMLButtonElement>e.currentTarget;
        let userPK = button.dataset.usereddiruser;
        let tableName = button.dataset.tableid;
        let table = (<HTMLTableElement>document.getElementById(tableName));
        let rows = document.querySelectorAll("tr[data-rownumber]");
        let instInput = <HTMLInputElement>document.getElementById("institutionNameSearch");

        let rowCount = 0;

        for (let row of rows) {
            rowCount++;
        }

        let xhr = new XMLHttpRequest();

        xhr.open('POST', '/Admin/ReadOnlyUserDataFieldRow', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = table.querySelector("tbody");
                $(element).append(xhr.responseText);
                Core.createHTMLAlert("alertMessageDiv", "New Plan Row Added", 'success', 3000, null);
                that.bindDeleteButtons();
                that.bindAutocompletes();
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("rowNumber=" + rowCount + "&userPK=" + userPK + "&tableId=" + tableName);
    }

    removeUserAccess(e: Event) {
        let core = new Core();

        let allSaveElements = [];
        let button = <HTMLButtonElement>e.currentTarget;
        let edDirUserFK = button.dataset.usereddiruser;
        let tableName = button.dataset.tableid;
        let table = (<HTMLTableElement>document.getElementById(tableName));
        let rows = table.querySelectorAll("tr[data-rownumber]");

        for (let row of rows) {
            let allElements = row.getElementsByClassName("adminCheckbox");
            let selectElements = row.getElementsByClassName("adminSelect");
            let autcompleteElements = row.getElementsByClassName("institutionAutocomplete");
            let selectElementInstitutions = row.getElementsByClassName("adminSelectInstitution");
            let searchInstPK = 0;
            let selectElementValue = 0;// this is the plan dropdown value.  Ie the accesstype
            let selectElementInstitutionValue = 0;// this is the institution dropdown value.  If this has a value then its institutionpk, otherwise its PDE's institutionpk
            let isSchool = "False";
            let isAdmin = "False";
            let wasSelected = "False";
            let inputElements = row.getElementsByClassName("adminInputInstitution");
            let inputElementValue = 0;
            for (let inputElement of inputElements) {
                let element = <HTMLInputElement>inputElement;
                inputElementValue = parseInt(element.getAttribute('data-institutionfk'));
            }

            for (let autcompleteElement of autcompleteElements) {
                let element = <HTMLInputElement>autcompleteElement;
                searchInstPK = parseInt(element.getAttribute('data-searchinstpk'));
            }

            for (let selectElement of selectElements) {
                let element = <HTMLSelectElement>selectElement;
                selectElementValue = parseInt(element.value);
                isSchool = element.options[element.selectedIndex].getAttribute('data-isschool');
                isAdmin = element.options[element.selectedIndex].getAttribute('data-isadmin');
                wasSelected = element.options[element.selectedIndex].getAttribute('data-wasselected');

            }
            for (let element of allElements) {
                let htmlElement = <HTMLInputElement>element;
                let edDirUserPK: number = parseInt((<HTMLTableRowElement>row).dataset.usereddiruserpk);
                let institutionFK: number = 0;
                let accessTypeCodeFK: number = 0;

                if (isSchool === "False" || isSchool === "false") {
                    if (searchInstPK === 0 && wasSelected === "False") {//no search done
                        institutionFK = parseInt((<HTMLTableRowElement>row).dataset.leainstitution);
                    }
                    else if (searchInstPK === 0 && wasSelected === "True") {//institution is one not from the selected district
                        institutionFK = inputElementValue;
                    }
                    else if (searchInstPK !== 0 && isAdmin === "True") {//search has been done, and its an admin dropdown value
                        institutionFK = parseInt((<HTMLTableRowElement>row).dataset.leainstitution);
                    }
                    else {//picking a non admin LEA level plantype for the searched for LEA
                        institutionFK = searchInstPK;
                    }
                    accessTypeCodeFK = selectElementValue;
                }
                else {// its a school
                    institutionFK = selectElementValue;
                    accessTypeCodeFK = 8;
                }

                if (htmlElement.checked) {
                    let saveItem: IEdDirUserAccessCreate = {
                        EdDirUserFK: edDirUserPK,
                        AccessCodeFK: parseInt(htmlElement.value),
                        InstitutionFK: institutionFK,
                        AccessTypeCodeFK: accessTypeCodeFK,
                        UserEdDirUserPK: ""
                    };

                    allSaveElements.push(saveItem);
                }
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/DeleteAllReadOnlyViewerUserAccess', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(allSaveElements));
    }

    removeReadOnlyRow(e: Event) {
        let core = new Core();
        let button = <HTMLButtonElement>e.currentTarget;
        let institutionfk = button.dataset.institutionfk;
        let userpk = button.dataset.eddiruserpk;
        let parent = button.parentElement.parentElement; //To the TR
        /*parent.parentElement.removeChild(parent);*/ // remove the row from the dom 
        let tableName = button.dataset.tableid;
        let table = (<HTMLTableElement>document.getElementById(tableName));
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/DeleteReadOnlyViewerUserAccessRow', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Row removed, Save Successful", 'success', 3000, null);
                parent.parentElement.removeChild(parent);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("userToDelete=" + userpk + "&frppInstitutionFk=" + institutionfk);
    }

    //saveUserAccess(e: Event) {
    //    let core = new Core();

    //    let allSaveElements = [];
    //    let button = <HTMLButtonElement>e.currentTarget;
    //    let edDirUserFK = button.dataset.usereddiruser;
    //    let tableName = button.dataset.tableid;
    //    let table = (<HTMLTableElement>document.getElementById(tableName));
    //    let rows = table.querySelectorAll("tr[data-rownumber]");
    //    let instInput = <HTMLInputElement>document.getElementById("institutionNameSearch");
    //    let instSearchPk = parseInt(instInput.getAttribute('data-searchinstpk'));

    //    for (let row of rows) {
    //        let allElements = row.getElementsByClassName("adminCheckbox");
    //        let selectElements = row.getElementsByClassName("adminSelect");
    //        let selectElementInstitutions = row.getElementsByClassName("adminSelectInstitution");
    //        let selectElementValue = 0;// this is the plan dropdown value.  Ie the accesstype
    //        let selectElementInstitutionValue = 0;// this is the institution dropdown value.  If this has a value then its institutionpk, otherwise its PDE's institutionpk
    //        let isSchool = "False";
    //        let isAdmin = "False";
    //        let inputElements = row.getElementsByClassName("adminInputInstitution");
    //        let inputElementValue = 0;
    //        for (let inputElement of inputElements) {
    //            let element = <HTMLInputElement>inputElement;
    //            inputElementValue = parseInt(element.getAttribute('data-institutionfk'));
    //        }
    //        //for (let selectElementInstitution of selectElementInstitutions) {
    //        //    let element = <HTMLSelectElement>selectElementInstitution;
    //        //    selectElementInstitutionValue = parseInt(element.value);
    //        //    isSchool = element.options[element.selectedIndex].getAttribute('data-isschool');
    //        //}
    //        for (let selectElement of selectElements) {
    //            let element = <HTMLSelectElement>selectElement;
    //            selectElementValue = parseInt(element.value);
    //            isSchool = element.options[element.selectedIndex].getAttribute('data-isschool');
    //            isAdmin = element.options[element.selectedIndex].getAttribute('data-isadmin');
    //        }
    //        for (let element of allElements) {
    //            let htmlElement = <HTMLInputElement>element;
    //            let edDirUserPK: number = parseInt((<HTMLTableRowElement>row).dataset.usereddiruserpk);
    //            let institutionFK: number = 0;
    //            let accessTypeCodeFK: number = 0;

    //            if (isSchool === "False" || isSchool === "false") {
    //                if (instInput.value === "" && inputElementValue === 0) {//no search done
    //                    institutionFK = parseInt((<HTMLTableRowElement>row).dataset.leainstitution);
    //                }
    //                else if (instInput.value === "" && inputElementValue !== 0) {//institution is one not from the selected district
    //                    institutionFK = inputElementValue;
    //                }
    //                else if (instInput.value !== "" && isAdmin === "True") {//search has been done and value has been picked, and its an admin dropdown value
    //                    institutionFK = parseInt((<HTMLTableRowElement>row).dataset.leainstitution);
    //                }
    //                else {//picking a non admin LEA level plantype for the searched for LEA
    //                    institutionFK = instSearchPk;
    //                }
    //                accessTypeCodeFK = selectElementValue;
    //            }
    //            else {// its a school
    //                institutionFK = selectElementValue;
    //                accessTypeCodeFK = 8;

    //                if (inputElementValue !== 0) {
    //                    institutionFK = inputElementValue;
    //                }
    //            }

    //            if (htmlElement.checked) {
    //                let saveItem: IEdDirUserAccessCreate = {
    //                    EdDirUserFK: edDirUserPK,
    //                    AccessCodeFK: parseInt(htmlElement.value),
    //                    InstitutionFK: institutionFK,
    //                    AccessTypeCodeFK: accessTypeCodeFK,
    //                    UserEdDirUserPK: ""
    //                };

    //                allSaveElements.push(saveItem);
    //            }
    //        }
    //    }

    //    let xhr = new XMLHttpRequest();
    //    xhr.open('POST', '/Admin/SaveEdDirUserAccess', true);
    //    xhr.setRequestHeader('Content-Type', 'application/json');
    //    xhr.onload = function () {
    //        if (xhr.status === 200) {
    //            Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, null);
    //        }
    //        else {
    //            Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
    //        }
    //    };
    //    xhr.send(JSON.stringify(allSaveElements));
    //}

    saveUserAccess(e: Event) {
        let core = new Core();

        let allSaveElements = [];
        let button = <HTMLButtonElement>e.currentTarget;
        let edDirUserFK = button.dataset.usereddiruser;
        let tableName = button.dataset.tableid;
        let table = (<HTMLTableElement>document.getElementById(tableName));
        let rows = table.querySelectorAll("tr[data-rownumber]");

        for (let row of rows) {
            let allElements = row.getElementsByClassName("adminCheckbox");
            let selectElements = row.getElementsByClassName("adminSelect");
            let autcompleteElements = row.getElementsByClassName("institutionAutocomplete");
            let selectElementInstitutions = row.getElementsByClassName("adminSelectInstitution");
            let searchInstPK = 0;
            let selectElementValue = 0;// this is the plan dropdown value.  Ie the accesstype
            let selectElementInstitutionValue = 0;// this is the institution dropdown value.  If this has a value then its institutionpk, otherwise its PDE's institutionpk
            let isSchool = "False";
            let isAdmin = "False";
            let wasSelected = "False";
            let inputElements = row.getElementsByClassName("adminInputInstitution");
            let inputElementValue = 0;
            for (let inputElement of inputElements) {
                let element = <HTMLInputElement>inputElement;
                inputElementValue = parseInt(element.getAttribute('data-institutionfk'));
            }

            for (let autcompleteElement of autcompleteElements) {
                let element = <HTMLInputElement>autcompleteElement;
                searchInstPK = parseInt(element.getAttribute('data-searchinstpk'));
            }

            for (let selectElement of selectElements) {
                let element = <HTMLSelectElement>selectElement;
                selectElementValue = parseInt(element.value);
                isSchool = element.options[element.selectedIndex].getAttribute('data-isschool');
                isAdmin = element.options[element.selectedIndex].getAttribute('data-isadmin');
                wasSelected = element.options[element.selectedIndex].getAttribute('data-wasselected');

            }
            for (let element of allElements) {
                let htmlElement = <HTMLInputElement>element;
                let edDirUserPK: number = parseInt((<HTMLTableRowElement>row).dataset.usereddiruserpk);
                let institutionFK: number = 0;
                let accessTypeCodeFK: number = 0;

                if (isSchool === "False" || isSchool === "false") {
                    if (searchInstPK === 0 && wasSelected === "False") {//no search done
                        institutionFK = parseInt((<HTMLTableRowElement>row).dataset.leainstitution);
                    }
                    else if (searchInstPK === 0 && wasSelected === "True") {//institution is one not from the selected district
                        institutionFK = inputElementValue;
                    }
                    else if (searchInstPK !== 0 && isAdmin === "True") {//search has been done, and its an admin dropdown value
                        institutionFK = parseInt((<HTMLTableRowElement>row).dataset.leainstitution);
                    }
                    else {//picking a non admin LEA level plantype for the searched for LEA
                        institutionFK = searchInstPK;
                    }
                    accessTypeCodeFK = selectElementValue;
                }
                else {// its a school
                    institutionFK = selectElementValue;
                    accessTypeCodeFK = 8;
                }

                if (htmlElement.checked) {
                    let saveItem: IEdDirUserAccessCreate = {
                        EdDirUserFK: edDirUserPK,
                        AccessCodeFK: parseInt(htmlElement.value),
                        InstitutionFK: institutionFK,
                        AccessTypeCodeFK: accessTypeCodeFK,
                        UserEdDirUserPK: ""
                    };

                    allSaveElements.push(saveItem);
                }
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SaveReadOnlyUserAccess', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(allSaveElements));
    }

    autocomplete(inp, arr, row, tableid) {

        /*the autocomplete function takes two arguments,
        the text field element and an array of possible autocompleted values:*/
        var currentFocus;
        let that = this;
        /*execute a function when someone writes in the text field:*/

        inp.addEventListener("input", function (e) {
            var a, b, i, val = this.value;

            /*close any already open lists of autocompleted values*/
            closeAllLists(e);

            //if (!val) { return false; }
            if (!val) { that.resetDropdown(row, tableid) }
            currentFocus = -1;
            /*create a DIV element that will contain the items (values):*/
            a = document.createElement("DIV");
            a.setAttribute("id", this.id + "autocomplete-list");
            a.setAttribute("class", "autocomplete-items");
            /*append the DIV element as a child of the autocomplete container:*/
            this.parentNode.appendChild(a);
            /*for each item in the array...*/
            for (i = 0; i < arr.length; i++) {
                /*check if the item starts with the same letters as the text field value:*/
                if (arr[i].substr(0, val.length).toUpperCase() == val.toUpperCase()) {
                    /*create a DIV element for each matching element:*/
                    b = document.createElement("DIV");
                    /*make the matching letters bold:*/
                    b.innerHTML = "<strong>" + arr[i].substr(0, val.length) + "</strong>";
                    b.innerHTML += arr[i].substr(val.length);
                    /*insert a input field that will hold the current array item's value:*/
                    b.innerHTML += "<input type='hidden' value='" + arr[i] + "'>";
                    /*execute a function when someone clicks on the item value (DIV element):*/
                    b.addEventListener("click", function (e) {
                        /*insert the value for the autocomplete text field:*/
                        inp.value = this.getElementsByTagName("input")[0].value;
                        /*close the list of autocompleted values,
                        (or any other open lists of autocompleted values:*/
                        closeAllLists(e);
                        that.getListOfSchools(e, row, tableid);
                    });
                    a.appendChild(b);
                }
            }
        });
        /*execute a function presses a key on the keyboard:*/
        inp.addEventListener("keydown", function (e) {
            let that = this;
            let x = document.getElementById(this.id + "autocomplete-list");
            let y: any;
        if (x) y = x.getElementsByTagName("div");
        if (e.keyCode == 40) {
            /*If the arrow DOWN key is pressed,
            increase the currentFocus variable:*/
            currentFocus++;
            /*and and make the current item more visible:*/
            addActive(y);
        } else if (e.keyCode == 38) { //up
            /*If the arrow UP key is pressed,
            decrease the currentFocus variable:*/
            currentFocus--;
            /*and and make the current item more visible:*/
            addActive(y);
        } else if (e.keyCode == 13) {
            /*If the ENTER key is pressed, prevent the form from being submitted,*/
            e.preventDefault();
            if (currentFocus > -1) {
                /*and simulate a click on the "active" item:*/
                if (y) {
                    y[currentFocus].click();
                }
            }
        }
    });

        function addActive(x) {
            /*a function to classify an item as "active":*/
            if (!x) return false;
            /*start by removing the "active" class on all items:*/
            removeActive(x);
            if (currentFocus >= x.length) currentFocus = 0;
            if (currentFocus < 0) currentFocus = (x.length - 1);
            /*add class "autocomplete-active":*/
            x[currentFocus].classList.add("autocomplete-active");
        }
        function removeActive(x) {
            /*a function to remove the "active" class from all autocomplete items:*/
            for (var i = 0; i < x.length; i++) {
                x[i].classList.remove("autocomplete-active");
            }
        }
        function closeAllLists(elmnt) {
            /*close all autocomplete lists in the document,
            except the one passed as an argument:*/
            var x = document.getElementsByClassName("autocomplete-items");
            for (var i = 0; i < x.length; i++) {
                if (elmnt != x[i] && elmnt != inp) {
                    x[i].parentNode.removeChild(x[i]);
                }
            }
        }
        /*execute a function when someone clicks in the document:*/
        document.addEventListener("click", function (e) {
            closeAllLists(e.target);
        });
    }

    getListOfSchools(e: Event, row, tableid) {
        let core = new Core();
        let dropdown = <HTMLDivElement>e.srcElement;
        let parent = dropdown.parentElement;
        let parentid = parent.id;
        let name = encodeURIComponent(dropdown.textContent);
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/GetSchoolList', true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                //Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, null);
                let res = JSON.parse(xhr.responseText);
                if (res.success === true) {
                    let tableName = tableid;
                    let table = (<HTMLTableElement>document.getElementById(tableName));
                    //let selects = table.getElementsByClassName("adminSelect");
                    let selects = table.getElementsByTagName("select");
                    let autoCompletes = table.getElementsByClassName("institutionAutocomplete");
                    let thisSelect = <HTMLSelectElement>selects[0];
                    let thisAutoComplete: any;
                    for (let select of selects) {
                        if (select.id === ("'select'" + row)) {
                            thisSelect = <HTMLSelectElement>select;
                        }
                    }
                    for (let autoComplete of autoCompletes) {
                        if (autoComplete.id === ("'institutionNameSearch'" + row)) {
                            thisAutoComplete = autoComplete;
                        }
                    }

                    let mintoRemove = 0;
                    let maxToRemove = 0;
                    for (let option of thisSelect.options) {
                        let length = thisSelect.options.length
                        let thisOption = <HTMLOptionElement>option;
                        let data = thisOption.dataset;
                        data = JSON.parse(JSON.stringify(data));
                        let index = thisOption.index;
                        let isOriginal = data.isoriginal;
                        if (isOriginal === "False") {
                            maxToRemove = length - 1;
                            mintoRemove = index;
                            break;
                        }
                    }

                    if (maxToRemove > 0) {
                        while (mintoRemove < (maxToRemove + 1)) {
                            thisSelect.options.remove(maxToRemove);//want to remove the highest one first
                            maxToRemove--;
                        }
                    }

                    for (let inst of res.relatedInstList) {
                        let x = document.createElement("option");
                        x.setAttribute("value", inst.relatedInstitutionPK);
                        x.setAttribute("data-isadmin", "False");
                        x.setAttribute("data-isschool", "True");
                        x.setAttribute("data-wasselected", "False");
                        x.setAttribute("data-isoriginal", "False");
                        let t = document.createTextNode(inst.relatedInstitutionName);
                        x.appendChild(t);
                        thisSelect.appendChild(x);
                    }
                    thisAutoComplete.setAttribute("data-searchinstpk", res.searchedInstPK)
                    let options = thisSelect.options;
                    for (let option of options) {
                        if (option.dataset.isadmin == "True") {
                            option.disabled = true;
                        }
                        else {
                            option.disabled = false;
                        }
                    }
                    thisSelect.selectedIndex = -1;
                }
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("name=" + name);
    }

    resetDropdown(row, tableid) {
        let tableName = tableid;
        let table = (<HTMLTableElement>document.getElementById(tableName));
        let selects = table.getElementsByTagName("select");
        let thisSelect = <HTMLSelectElement>selects[0];
        for (let select of selects) {
            if (select.id === ("'select'" + row)) {
                thisSelect = <HTMLSelectElement>select;
            }
        }
        let mintoRemove = 0;
        let maxToRemove = 0;
        for (let option of thisSelect.options) {
            let length = thisSelect.options.length
            let thisOption = <HTMLOptionElement>option;
            let data = thisOption.dataset;
            data = JSON.parse(JSON.stringify(data));
            let index = thisOption.index;
            let isOriginal = data.isoriginal;
            if (isOriginal === "False") {
                maxToRemove = length - 1;
                mintoRemove = index;
                break;
            }
        }

        if (maxToRemove > 0) {
            while (mintoRemove < (maxToRemove + 1)) {
                thisSelect.options.remove(maxToRemove);//want to remove the highest one first
                maxToRemove--;
            }
        }

        let options = thisSelect.options;
        for (let option of options) {
            if (option.dataset.isadmin == "True") {
                option.disabled = false;
            }
            else {
                option.disabled = true;
            }
        }
        thisSelect.selectedIndex = -1;
        return false;
    }
}

class Impersonation {
    constructor() {
        let core = new Core();

        this.bindLEAAutocompletes();
        this.bindUserAutocompletes();

        let leaSearchButton = <HTMLButtonElement>document.getElementById("impersonationLEASearchSearch");
        if (leaSearchButton !== null)
            leaSearchButton.addEventListener("click", (e: Event) => this.leaSearch());

        let aUNSearchButton = <HTMLButtonElement>document.getElementById("impersonationLEAAUNSearchSearch");
        if (aUNSearchButton !== null)
            aUNSearchButton.addEventListener("click", (e: Event) => this.aUNSearch());

        let UserSearchButton = <HTMLButtonElement>document.getElementById("impersonationUserSearchSearch");
        if (UserSearchButton !== null)
            UserSearchButton.addEventListener("click", (e: Event) => this.userSearch());

        core.activateSubMenu("Impersonation");
    }


    bindLEAAutocompletes() {
        let hidden = <HTMLInputElement>document.getElementById("hiddenlealist");
        let hiddenValue = hidden.value;
        let thisarray = new Array();
        thisarray = hiddenValue.split(',');
        let autocompletes = document.getElementsByClassName("institutionAutocomplete");
        for (let autocomplete of autocompletes) {
            let ac = <HTMLInputElement>autocomplete;
            let row = ac.dataset.rownumber;
            let tableid = ac.dataset.tableid
            Core.autocomplete(autocomplete, thisarray);
        }
    }

    bindUserAutocompletes() {
        let hidden = <HTMLInputElement>document.getElementById("hiddenUserlist");
        let hiddenValue = hidden.value;
        let thisarray = new Array();
        thisarray = hiddenValue.split(',');
        let autocompletes = document.getElementsByClassName("userAutocomplete");
        for (let autocomplete of autocompletes) {
            let ac = <HTMLInputElement>autocomplete;
            let row = ac.dataset.rownumber;
            let tableid = ac.dataset.tableid
            Core.autocomplete(autocomplete, thisarray);
        }
    }

    leaSearch() {
        let nameElement = <HTMLInputElement>document.getElementById("institutionNameSearch");

        let name = document.getElementsByTagName("input")[0].value;

        let url = "/Admin/Impersonation/";
        if (name !== "")
            url += "?districtName=" + encodeURIComponent(name);

        window.location.href = url;
    }

    aUNSearch() {
        let nameElement = <HTMLInputElement>document.getElementById("institutionAUN");

        let AUN = document.getElementsByTagName("input")[3].value;
        let aUNNumber = parseInt(AUN);
        let branch = document.getElementsByTagName("input")[4].value;
        if (AUN === "" || branch === "") {
            alert("Both AUN and Branch need a value");
        }

        let url = "/Admin/Impersonation/";
        if (AUN !== "")
            url += "?districtAUN=" + encodeURIComponent(aUNNumber) + "&branch=" + branch;

        window.location.href = url;
    }

    userSearch() {
        let nameElement = <HTMLInputElement>document.getElementById("impersonationUserSearchSearch");
        let leaName = document.getElementsByTagName("input")[0].value;
        let AUN = document.getElementsByTagName("input")[3].value;
        let branch = document.getElementsByTagName("input")[4].value;
        let aUNNumber = parseInt(AUN);
        let userName = document.getElementsByTagName("input")[5].value;
        if (leaName !== "" && AUN !== "") {
            alert("please choose Name or AUN");
            return false;
        }

        let url = "/Admin/ImpersonateUser/";
        if (leaName !== "" && userName !== "") {
            url += "?districtName=" + encodeURIComponent(leaName) + "&userName=" + userName;

            window.location.href = url;
        }
        else {
            url = "/Admin/ImpersonateUserByAUN/";
            url += "?aUN=" + aUNNumber + "&branch=" + branch + "&userName=" + userName;

            window.location.href = url;
        }
    }
}

//School Plans
class PDEUserSchoolPlansAdmin {
    constructor() {
        let core = new Core();
        //let saveButton = document.getElementById("SchoolPlanSave");
        //if (saveButton !== null)
        //    saveButton.addEventListener("click", (e: Event) => this.save());

        this.bindIndSaveButtons();

        let schoolSearchButton = <HTMLButtonElement>document.getElementById("schoolSearchSearch");
        if (schoolSearchButton !== null)
            schoolSearchButton.addEventListener("click", (e: Event) => this.schoolSearch());

        core.activateSubMenu("PDEUserSchoolPlans");
    }

    bindIndSaveButtons() {
        let indSaveButtons = document.getElementsByClassName("saveIndSchoolAccess");
        for (let indSaveButton of indSaveButtons) {
            indSaveButton.addEventListener("click", (e: Event) => this.saveSchoolAccess(e));
        }
    }

    schoolSearch() {
        let nameElement = <HTMLInputElement>document.getElementById("institutionNameSearch");

        let name = document.getElementsByTagName("input")[0].value;

        let url = "/Admin/PDEUSerSchoolPlans/";
        if (name !== "")
            url += "?schoolName=" + name;

        window.location.href = url;
    }

    saveSchoolAccess(e: Event) {
        let core = new Core();
        //Core.showLoader();
        let button = <HTMLButtonElement>e.currentTarget;
        let selectid = button.dataset.selectid;
        let elementInstitutionFK = button.dataset.institutionfk;


        let saveObject: IPDEUserPlanAdminSave;
        let selectElementValue = -1;
        //for (let selectElement of selectElements) {
        let element = <HTMLSelectElement>document.getElementById(selectid);
        let elementPlanPK = 0;
        if (element !== null) {
            elementPlanPK = parseInt(element.dataset.planpk);
            selectElementValue = parseInt(element.value);

            let datepicker = document.querySelector(".adminDatePicker[data-planpkcompletiondate='" + elementPlanPK + "']");
            let datePickerElement = <HTMLInputElement>datepicker;
            let date = datePickerElement.value;
            let utc = new Date(date);
            var dueDate = new Date(utc.getTime() + utc.getTimezoneOffset() * 60000);// this converts it back to the day it should be.
            //selectElementValue = parseInt(element.value);
            if (selectElementValue > -1 && elementPlanPK !== 0) {

                saveObject = {
                    PlanPK: elementPlanPK,
                    PlanTypeFK: selectElementValue,
                    DueDate: dueDate
                };

            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SavePDEUserPlanRecords', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));
    }

}


//Update All Due Dates
class PDEUserUpdateAllDueDates {
    constructor() {
        let core = new Core();

        let saveButton = <HTMLButtonElement>document.getElementById("saveButton");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e: Event) => this.save(e));

        core.activateSubMenu("PDEUserUpdateAllDueDates");
    }



    save(e: Event) {
        let core = new Core();
        //Core.showLoader();
        let button = <HTMLButtonElement>e.currentTarget;
        //let edDirUserFK = parseInt(button.dataset.eddiruserpk);
        let selectid = button.dataset.selectid;
        //let elementInstitutionFK = button.dataset.institutionfk;
        //let tableid = button.dataset.tableid;

        let allSaveElements = [];
        let allSelectElements = [];
        let saveObject: IPDEUserSaveAllDueDates;
        let selectElements = document.getElementsByClassName("adminSelect");
        let selectElementValue = -1;
        /*let selectedPhaseCodeValue = -1;*/
        let selectedAcademicYearValue = -1;
        //for (let selectElement of selectElements) {
        let element = <HTMLSelectElement>document.getElementById(selectid);
        //let elementPlanPK = 0;
        if (element !== null) {
            //elementPlanPK = parseInt(element.dataset.planpk);
            selectElementValue = parseInt(element.value);

            //let elementPlanPK = element.dataset.planpk;
            //let elementInstitutionFK = element.dataset.institutionfk;
            let datepicker = document.getElementById("datePicker");
            let datePickerElement = <HTMLInputElement>datepicker;
            let date = datePickerElement.value;
            let utc = new Date(date);
            var dueDate = new Date(utc.getTime() + utc.getTimezoneOffset() * 60000);// this converts it back to the day it should be.
            //selectElementValue = parseInt(element.value);
            //let selectedPhaseCode = document.getElementById("updateDueDatesSelectPhase");
            //let selectedPhaseCodeElement = <HTMLSelectElement>selectedPhaseCode;
            //if (selectedPhaseCodeElement !== null) {
            //    selectedPhaseCodeValue = parseInt(selectedPhaseCodeElement.value);
            //}
            let selectedAcademicYear = document.getElementById("updateDueDatesSelectAcademicYear");
            let selectedAcademicYearElement = <HTMLSelectElement>selectedAcademicYear;
            if (selectedAcademicYearElement !== null) {
                selectedAcademicYearValue = parseInt(selectedAcademicYearElement.value);
            }
            if (selectElementValue > -1 && selectedAcademicYearValue > -1) {

                saveObject = {
                    PlanTypeFK: selectElementValue,
                    AcademicYearFK: selectedAcademicYearValue,
                    DueDate: dueDate
                };

                //allSelectElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SavePDEUserAllDueDates', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));
    }
}


//PDEBureau Access
class PDEBureauAccess {
    constructor() {
        let core = new Core();

        this.bindDeleteButtons();
        this.bindDeleteAllAccessButtons();
        this.bindSaveUserAccessButtons();
        this.bindAutocompletes();

        let addNewRowButtons = document.getElementsByClassName("addAnotherPlan");
        for (let addNewRowButton of addNewRowButtons) {
            addNewRowButton.addEventListener("click", (e: Event) => this.addAnotherPlan(e));
        }
        core.activateSubMenu("pdebureauaccess");
    }

    bindDeleteButtons() {
        let deleteButtons = document.getElementsByClassName("deleteRow");
        for (let deleteButton of deleteButtons) {
            deleteButton.addEventListener("click", (e: Event) => this.removeReadOnlyRow(e));
        }
    }

    bindAutocompletes() {
        let hidden = <HTMLInputElement>document.getElementById("hiddenlealist");
        let hiddenValue = hidden.value;
        let thisarray = new Array();
        thisarray = hiddenValue.split(',');
        let autocompletes = document.getElementsByClassName("institutionAutocomplete");
        for (let autocomplete of autocompletes) {
            let ac = <HTMLInputElement>autocomplete;
            let row = ac.dataset.rownumber;
            let tableid = ac.dataset.tableid
            this.autocomplete(autocomplete, thisarray, row, tableid);
        }
    }

    bindDeleteAllAccessButtons() {
        let deleteAllAccessButtons = document.getElementsByClassName("removeUserAccess");
        for (let deleteAllAccessButton of deleteAllAccessButtons) {
            deleteAllAccessButton.addEventListener("click", (e: Event) => this.removeUserAccess(e));
        }
    }

    bindSaveUserAccessButtons() {
        let saveUserAccessButtons = document.getElementsByClassName("saveUserAccess");
        for (let saveUserAccessButton of saveUserAccessButtons) {
            saveUserAccessButton.addEventListener("click", (e: Event) => this.saveUserAccess(e));
        }
    }

    addAnotherPlan(e: Event) {
        let core = new Core();
        let that = this;
        let button = <HTMLButtonElement>e.currentTarget;
        let userPK = button.dataset.usereddiruser;
        let tableName = button.dataset.tableid;
        let table = (<HTMLTableElement>document.getElementById(tableName));
        let rows = document.querySelectorAll("tr[data-rownumber]");
        let instInput = <HTMLInputElement>document.getElementById("institutionNameSearch");

        let rowCount = 0;

        for (let row of rows) {
            rowCount++;
        }

        let xhr = new XMLHttpRequest();

        xhr.open('POST', '/Admin/PDEBureauAccessDataFieldRow', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = table.querySelector("tbody");
                $(element).append(xhr.responseText);
                Core.createHTMLAlert("alertMessageDiv", "New Plan Row Added", 'success', 3000, null);
                that.bindDeleteButtons();
                that.bindAutocompletes();
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("rowNumber=" + rowCount + "&userPK=" + userPK + "&tableId=" + tableName);
    }

    removeUserAccess(e: Event) {
        let core = new Core();

        let allDeleteElements = [];
        let button = <HTMLButtonElement>e.currentTarget;
        let edDirUserFK = button.dataset.usereddiruser;
        let tableName = button.dataset.tableid;
        let table = (<HTMLTableElement>document.getElementById(tableName));
        let rows = table.querySelectorAll("tr[data-rownumber]");

        for (let row of rows) {
            let allElements = row.getElementsByClassName("adminCheckbox");
            let selectElements = row.getElementsByClassName("adminSelect");
            let autcompleteElements = row.getElementsByClassName("institutionAutocomplete");
            let selectElementInstitutions = row.getElementsByClassName("adminSelectInstitution");
            let searchInstPK = 0;
            let selectElementValue = 0;// this is the plan dropdown value.  Ie the accesstype
            let selectElementInstitutionValue = 0;// this is the institution dropdown value.  If this has a value then its institutionpk, otherwise its PDE's institutionpk
            let isSchool = "False";
            let isAdmin = "False";
            let wasSelected = "False";
            let inputElements = row.getElementsByClassName("adminInputInstitution");
            let inputElementValue = 0;
            for (let inputElement of inputElements) {
                let element = <HTMLInputElement>inputElement;
                inputElementValue = parseInt(element.getAttribute('data-institutionfk'));
            }

            for (let autcompleteElement of autcompleteElements) {
                let element = <HTMLInputElement>autcompleteElement;
                searchInstPK = parseInt(element.getAttribute('data-searchinstpk'));
            }

            for (let selectElement of selectElements) {
                let element = <HTMLSelectElement>selectElement;
                selectElementValue = parseInt(element.value);
                isSchool = element.options[element.selectedIndex].getAttribute('data-isschool');
                isAdmin = element.options[element.selectedIndex].getAttribute('data-isadmin');
                wasSelected = element.options[element.selectedIndex].getAttribute('data-wasselected');

            }
            for (let element of allElements) {
                let htmlElement = <HTMLInputElement>element;
                let wasChecked = htmlElement.dataset.waschecked;
                let edDirUserPK: number = parseInt((<HTMLTableRowElement>row).dataset.usereddiruserpk);
                let institutionFK: number = 0;
                let accessTypeCodeFK: number = 0;

                if (isSchool === "False" || isSchool === "false") {
                    if (searchInstPK === 0 && wasSelected === "False") {//no search done
                        institutionFK = parseInt((<HTMLTableRowElement>row).dataset.leainstitution);
                    }
                    else if (searchInstPK === 0 && wasSelected === "True") {//institution is one not from the selected district
                        institutionFK = inputElementValue;
                    }
                    else if (searchInstPK !== 0 && isAdmin === "True") {//search has been done, and its an admin dropdown value
                        institutionFK = parseInt((<HTMLTableRowElement>row).dataset.leainstitution);
                    }
                    else {//picking a non admin LEA level plantype for the searched for LEA
                        institutionFK = searchInstPK;
                    }
                    accessTypeCodeFK = selectElementValue;
                }
                else {// its a school
                    institutionFK = selectElementValue;
                    accessTypeCodeFK = 8;
                }

                if (htmlElement.checked || wasChecked == "true") {
                    let saveItem: IUserAccessDelete = {
                        UserFK: edDirUserPK,
                        AccessCodeFK: parseInt(htmlElement.value),
                        FRCPPInstitutionFK: institutionFK,
                        AccessTypeCodeFK: accessTypeCodeFK
                    };

                    allDeleteElements.push(saveItem);
                }
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/DeletePDEBureauAccessRow', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(allDeleteElements));
    }

    removeReadOnlyRow(e: Event) {
        let core = new Core();
        let allDeleteElements = [];
        let button = <HTMLButtonElement>e.currentTarget;
        let institutionfk = button.dataset.institutionfk;
        let userpk = button.dataset.eddiruserpk;
        let accessTypeCode = button.dataset.accesstypecodefk
        let parent = button.parentElement.parentElement; //To the TR
        /*parent.parentElement.removeChild(parent);*/ // remove the row from the dom 
        let tableName = button.dataset.tableid;
        let table = (<HTMLTableElement>document.getElementById(tableName));
        //create 2 items to be deleted
        let saveItem2: IUserAccessCreate = {
            UserFK: parseInt(userpk),
            AccessCodeFK: 2,
            FRCPPInstitutionFK: parseInt(institutionfk),
            AccessTypeCodeFK: parseInt(accessTypeCode)
        };

        allDeleteElements.push(saveItem2);
        let saveItem3: IUserAccessCreate = {
            UserFK: parseInt(userpk),
            AccessCodeFK: 3,
            FRCPPInstitutionFK: parseInt(institutionfk),
            AccessTypeCodeFK: parseInt(accessTypeCode)
        };

        allDeleteElements.push(saveItem3);

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/DeletePDEBureauAccessRow', true);
        xhr.setRequestHeader('Content-type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Row removed, Save Successful", 'success', 3000, null);
                parent.parentElement.removeChild(parent);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(allDeleteElements));
    }

    saveUserAccess(e: Event) {
        let core = new Core();

        let saveObject: IPDEBureauUserAccessObject;
        let allSaveElements = [];
        let allDeleteElements = [];
        let button = <HTMLButtonElement>e.currentTarget;
        let edDirUserFK = button.dataset.usereddiruser;
        let tableName = button.dataset.tableid;
        let table = (<HTMLTableElement>document.getElementById(tableName));
        let rows = table.querySelectorAll("tr[data-rownumber]");

        for (let row of rows) {
            let allElements = row.getElementsByClassName("adminCheckbox");
            let selectElements = row.getElementsByClassName("adminSelect");
            let autcompleteElements = row.getElementsByClassName("institutionAutocomplete");
            let selectElementInstitutions = row.getElementsByClassName("adminSelectInstitution");
            let searchInstPK = 0;
            let selectElementValue = 0;// this is the plan dropdown value.  Ie the accesstype
            let selectElementInstitutionValue = 0;// this is the institution dropdown value.  If this has a value then its institutionpk, otherwise its PDE's institutionpk
            let isSchool = "False";
            let isAdmin = "False";
            let wasSelected = "False";
            let isCATS = "False";
            let inputElements = row.getElementsByClassName("adminInputInstitution");
            let inputElementValue = 0;
            for (let inputElement of inputElements) {
                let element = <HTMLInputElement>inputElement;
                inputElementValue = parseInt(element.getAttribute('data-institutionfk'));
            }

            for (let autcompleteElement of autcompleteElements) {
                let element = <HTMLInputElement>autcompleteElement;
                searchInstPK = parseInt(element.getAttribute('data-searchinstpk'));
            }

            for (let selectElement of selectElements) {
                let element = <HTMLSelectElement>selectElement;
                selectElementValue = parseInt(element.value);
                isSchool = element.options[element.selectedIndex].getAttribute('data-isschool');
                isAdmin = element.options[element.selectedIndex].getAttribute('data-isadmin');
                isCATS = element.options[element.selectedIndex].getAttribute('data-iscats');
                wasSelected = element.options[element.selectedIndex].getAttribute('data-wasselected');

            }
            for (let element of allElements) {
                let htmlElement = <HTMLInputElement>element;
                let edDirUserPK: number = parseInt((<HTMLTableRowElement>row).dataset.usereddiruserpk);
                let institutionFK: number = 0;
                let accessTypeCodeFK: number = 0;
                let wasChecked = htmlElement.dataset.waschecked;

                if (isSchool === "False" || isSchool === "false") {
                    if (searchInstPK === 0 && wasSelected === "False") {//no search done
                        institutionFK = parseInt((<HTMLTableRowElement>row).dataset.leainstitution);
                    }
                    else if (searchInstPK === 0 && wasSelected === "True") {//institution is one not from the selected district
                        institutionFK = inputElementValue;
                    }
                    else if (searchInstPK !== 0 && isAdmin === "True") {//search has been done, and its an admin dropdown value
                        institutionFK = parseInt((<HTMLTableRowElement>row).dataset.leainstitution);
                    }
                    else {//picking a non admin LEA level plantype for the searched for LEA
                        institutionFK = searchInstPK;
                    }
                    accessTypeCodeFK = selectElementValue;
                }
                else if ((isSchool === "True" || isSchool === "true") && (isCATS === "True" || isCATS === "true")) {//its a cats plan
                    institutionFK = selectElementValue;
                    accessTypeCodeFK = 223;
                }
                else {// its a school
                    institutionFK = selectElementValue;
                    accessTypeCodeFK = 8;
                }

                if (htmlElement.checked) {
                    let saveItem: IUserAccessCreate = {
                        UserFK: edDirUserPK,
                        AccessCodeFK: parseInt(htmlElement.value),
                        FRCPPInstitutionFK: institutionFK,
                        AccessTypeCodeFK: accessTypeCodeFK
                    };

                    allSaveElements.push(saveItem);
                }

                if ((htmlElement.checked == false ) && wasChecked == "true") {
                    let deleteItem: IUserAccessDelete = {
                        UserFK: edDirUserPK,
                        AccessCodeFK: parseInt(htmlElement.value),
                        FRCPPInstitutionFK: institutionFK,
                        AccessTypeCodeFK: accessTypeCodeFK
                    };

                    allDeleteElements.push(deleteItem);
                }
            }
        }

        saveObject = {
            CreateList: allSaveElements,
            DeleteList: allDeleteElements
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SavePDEBureauUserAccess', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));
    }

    autocomplete(inp, arr, row, tableid) {

        /*the autocomplete function takes two arguments,
        the text field element and an array of possible autocompleted values:*/
        var currentFocus;
        let that = this;
        /*execute a function when someone writes in the text field:*/

        inp.addEventListener("input", function (e) {
            var a, b, i, val = this.value;

            /*close any already open lists of autocompleted values*/
            closeAllLists(e);

            //if (!val) { return false; }
            if (!val) { that.resetDropdown(row, tableid) }
            currentFocus = -1;
            /*create a DIV element that will contain the items (values):*/
            a = document.createElement("DIV");
            a.setAttribute("id", this.id + "autocomplete-list");
            a.setAttribute("class", "autocomplete-items");
            /*append the DIV element as a child of the autocomplete container:*/
            this.parentNode.appendChild(a);
            /*for each item in the array...*/
            for (i = 0; i < arr.length; i++) {
                /*check if the item starts with the same letters as the text field value:*/
                if (arr[i].substr(0, val.length).toUpperCase() == val.toUpperCase()) {
                    /*create a DIV element for each matching element:*/
                    b = document.createElement("DIV");
                    /*make the matching letters bold:*/
                    b.innerHTML = "<strong>" + arr[i].substr(0, val.length) + "</strong>";
                    b.innerHTML += arr[i].substr(val.length);
                    /*insert a input field that will hold the current array item's value:*/
                    b.innerHTML += "<input type='hidden' value='" + arr[i] + "'>";
                    /*execute a function when someone clicks on the item value (DIV element):*/
                    b.addEventListener("click", function (e) {
                        /*insert the value for the autocomplete text field:*/
                        inp.value = this.getElementsByTagName("input")[0].value;
                        /*close the list of autocompleted values,
                        (or any other open lists of autocompleted values:*/
                        closeAllLists(e);
                        that.getListOfSchools(e, row, tableid);
                    });
                    a.appendChild(b);
                }
            }
        });
        /*execute a function presses a key on the keyboard:*/
        inp.addEventListener("keydown", function (e) {
            let that = this;
            let x = document.getElementById(this.id + "autocomplete-list");
            let y: any;
            if (x) y = x.getElementsByTagName("div");
            if (e.keyCode == 40) {
                /*If the arrow DOWN key is pressed,
                increase the currentFocus variable:*/
                currentFocus++;
                /*and and make the current item more visible:*/
                addActive(y);
            } else if (e.keyCode == 38) { //up
                /*If the arrow UP key is pressed,
                decrease the currentFocus variable:*/
                currentFocus--;
                /*and and make the current item more visible:*/
                addActive(y);
            } else if (e.keyCode == 13) {
                /*If the ENTER key is pressed, prevent the form from being submitted,*/
                e.preventDefault();
                if (currentFocus > -1) {
                    /*and simulate a click on the "active" item:*/
                    if (y) {
                        y[currentFocus].click();
                    }
                }
            }
        });

        function addActive(x) {
            /*a function to classify an item as "active":*/
            if (!x) return false;
            /*start by removing the "active" class on all items:*/
            removeActive(x);
            if (currentFocus >= x.length) currentFocus = 0;
            if (currentFocus < 0) currentFocus = (x.length - 1);
            /*add class "autocomplete-active":*/
            x[currentFocus].classList.add("autocomplete-active");
        }
        function removeActive(x) {
            /*a function to remove the "active" class from all autocomplete items:*/
            for (var i = 0; i < x.length; i++) {
                x[i].classList.remove("autocomplete-active");
            }
        }
        function closeAllLists(elmnt) {
            /*close all autocomplete lists in the document,
            except the one passed as an argument:*/
            var x = document.getElementsByClassName("autocomplete-items");
            for (var i = 0; i < x.length; i++) {
                if (elmnt != x[i] && elmnt != inp) {
                    x[i].parentNode.removeChild(x[i]);
                }
            }
        }
        /*execute a function when someone clicks in the document:*/
        document.addEventListener("click", function (e) {
            closeAllLists(e.target);
        });
    }

    getListOfSchools(e: Event, row, tableid) {
        let core = new Core();
        let dropdown = <HTMLDivElement>e.srcElement;
        let parent = dropdown.parentElement;
        let parentid = parent.id;
        let name = encodeURIComponent(dropdown.textContent);
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/GetSchoolList', true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                //Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, null);
                let res = JSON.parse(xhr.responseText);
                if (res.success === true) {
                    let tableName = tableid;
                    let table = (<HTMLTableElement>document.getElementById(tableName));
                    //let selects = table.getElementsByClassName("adminSelect");
                    let selects = table.getElementsByTagName("select");
                    let autoCompletes = table.getElementsByClassName("institutionAutocomplete");
                    let thisSelect = <HTMLSelectElement>selects[0];
                    let thisAutoComplete: any;
                    for (let select of selects) {
                        if (select.id === ("'select'" + row)) {
                            thisSelect = <HTMLSelectElement>select;
                        }
                    }
                    for (let autoComplete of autoCompletes) {
                        if (autoComplete.id === ("'institutionNameSearch'" + row)) {
                            thisAutoComplete = autoComplete;
                        }
                    }

                    let mintoRemove = 0;
                    let maxToRemove = 0;
                    for (let option of thisSelect.options) {
                        let length = thisSelect.options.length
                        let thisOption = <HTMLOptionElement>option;
                        let data = thisOption.dataset;
                        data = JSON.parse(JSON.stringify(data));
                        let index = thisOption.index;
                        let isOriginal = data.isoriginal;
                        if (isOriginal === "False") {
                            maxToRemove = length - 1;
                            mintoRemove = index;
                            break;
                        }
                    }

                    if (maxToRemove > 0) {
                        while (mintoRemove < (maxToRemove + 1)) {
                            thisSelect.options.remove(maxToRemove);//want to remove the highest one first
                            maxToRemove--;
                        }
                    }

                    for (let inst of res.relatedInstList) {
                        let x = document.createElement("option");
                        x.setAttribute("value", inst.relatedInstitutionPK);
                        x.setAttribute("data-isadmin", "False");
                        x.setAttribute("data-isschool", "True");
                        x.setAttribute("data-wasselected", "False");
                        x.setAttribute("data-isoriginal", "False");
                        let t = document.createTextNode(inst.relatedInstitutionName);
                        x.appendChild(t);
                        thisSelect.appendChild(x);
                    }
                    //now add the cats options
                    for (let inst of res.relatedInstList) {
                        let x = document.createElement("option");
                        x.setAttribute("value", inst.relatedInstitutionPK);
                        x.setAttribute("data-isadmin", "False");
                        x.setAttribute("data-isschool", "True");
                        x.setAttribute("data-wasselected", "False");
                        x.setAttribute("data-isoriginal", "False");
                        x.setAttribute("data-isCATS", "True");
                        let t = document.createTextNode("CATS-" + inst.relatedInstitutionName);
                        x.appendChild(t);
                        thisSelect.appendChild(x);
                    }
                    thisAutoComplete.setAttribute("data-searchinstpk", res.searchedInstPK)
                    let options = thisSelect.options;
                    for (let option of options) {
                        if (option.dataset.isadmin == "True") {
                            option.disabled = true;
                        }
                        else {
                            option.disabled = false;
                        }
                    }
                    thisSelect.selectedIndex = -1;
                }
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("name=" + name);
    }

    resetDropdown(row, tableid) {
        let tableName = tableid;
        let table = (<HTMLTableElement>document.getElementById(tableName));
        let selects = table.getElementsByTagName("select");
        let thisSelect = <HTMLSelectElement>selects[0];
        for (let select of selects) {
            if (select.id === ("'select'" + row)) {
                thisSelect = <HTMLSelectElement>select;
            }
        }
        let mintoRemove = 0;
        let maxToRemove = 0;
        for (let option of thisSelect.options) {
            let length = thisSelect.options.length
            let thisOption = <HTMLOptionElement>option;
            let data = thisOption.dataset;
            data = JSON.parse(JSON.stringify(data));
            let index = thisOption.index;
            let isOriginal = data.isoriginal;
            if (isOriginal === "False") {
                maxToRemove = length - 1;
                mintoRemove = index;
                break;
            }
        }

        if (maxToRemove > 0) {
            while (mintoRemove < (maxToRemove + 1)) {
                thisSelect.options.remove(maxToRemove);//want to remove the highest one first
                maxToRemove--;
            }
        }

        let options = thisSelect.options;
        for (let option of options) {
            if (option.dataset.isadmin == "True") {
                option.disabled = false;
            }
            else {
                option.disabled = true;
            }
        }
        thisSelect.selectedIndex = -1;
        return false;
    }
}

class ViewPermissions {
    constructor() {
        let core = new Core();

        this.bindLEAAutocompletes();
        this.bindUserAutocompletes();

        let leaSearchButton = <HTMLButtonElement>document.getElementById("impersonationLEASearchSearch");
        if (leaSearchButton !== null)
            leaSearchButton.addEventListener("click", (e: Event) => this.leaSearch());

        let UserSearchButton = <HTMLButtonElement>document.getElementById("impersonationUserSearchSearch");
        if (UserSearchButton !== null)
            UserSearchButton.addEventListener("click", (e: Event) => this.userSearch());

        core.activateSubMenu("Impersonation");
    }


    bindLEAAutocompletes() {
        let hidden = <HTMLInputElement>document.getElementById("hiddenlealist");
        let hiddenValue = hidden.value;
        let thisarray = new Array();
        thisarray = hiddenValue.split(',');
        let autocompletes = document.getElementsByClassName("institutionAutocomplete");
        for (let autocomplete of autocompletes) {
            let ac = <HTMLInputElement>autocomplete;
            let row = ac.dataset.rownumber;
            let tableid = ac.dataset.tableid
            this.autocomplete(autocomplete, thisarray);
        }
    }

    bindUserAutocompletes() {
        let hidden = <HTMLInputElement>document.getElementById("hiddenUserlist");
        let hiddenValue = hidden.value;
        let thisarray = new Array();
        thisarray = hiddenValue.split(',');
        let autocompletes = document.getElementsByClassName("userAutocomplete");
        for (let autocomplete of autocompletes) {
            let ac = <HTMLInputElement>autocomplete;
            let row = ac.dataset.rownumber;
            let tableid = ac.dataset.tableid
            this.autocomplete(autocomplete, thisarray);
        }
    }

    leaSearch() {
        let nameElement = <HTMLInputElement>document.getElementById("institutionNameSearch");

        let name = document.getElementsByTagName("input")[0].value;

        let url = "/Admin/ViewPermissions/";
        if (name !== "")
            url += "?districtName=" + encodeURIComponent(name);

        window.location.href = url;
    }

    userSearch() {
        let nameElement = <HTMLInputElement>document.getElementById("impersonationUserSearchSearch");
        let leaName = document.getElementsByTagName("input")[0].value;
        let userName = document.getElementsByTagName("input")[3].value;
        let userNAme = <HTMLInputElement>document.getElementById("userNameSearch");
        let userNAmeValue = userNAme.value;

        let url = "/Admin/ViewPermissions/";
        if (leaName !== "" && userName !== "") {
            url += "?districtName=" + encodeURIComponent(leaName) + "&userName=" + userName;

            window.location.href = url;
        }
    }


    autocomplete(inp, arr) {

        /*the autocomplete function takes two arguments,
        the text field element and an array of possible autocompleted values:*/
        var currentFocus;
        let that = this;
        /*execute a function when someone writes in the text field:*/

        inp.addEventListener("input", function (e) {
            var a, b, i, val = this.value;

            /*close any already open lists of autocompleted values*/
            closeAllLists(e);

            if (!val) { return false; }
            currentFocus = -1;
            /*create a DIV element that will contain the items (values):*/
            a = document.createElement("DIV");
            a.setAttribute("id", this.id + "autocomplete-list");
            a.setAttribute("class", "autocomplete-items");
            /*append the DIV element as a child of the autocomplete container:*/
            this.parentNode.appendChild(a);
            /*for each item in the array...*/
            for (i = 0; i < arr.length; i++) {
                /*check if the item starts with the same letters as the text field value:*/
                if (arr[i].substr(0, val.length).toUpperCase() == val.toUpperCase()) {
                    /*create a DIV element for each matching element:*/
                    b = document.createElement("DIV");
                    /*make the matching letters bold:*/
                    b.innerHTML = "<strong>" + arr[i].substr(0, val.length) + "</strong>";
                    b.innerHTML += arr[i].substr(val.length);
                    /*insert a input field that will hold the current array item's value:*/
                    b.innerHTML += "<input type='hidden' value='" + arr[i] + "'>";
                    /*execute a function when someone clicks on the item value (DIV element):*/
                    b.addEventListener("click", function (e) {
                        /*insert the value for the autocomplete text field:*/
                        inp.value = this.getElementsByTagName("input")[0].value;
                        /*close the list of autocompleted values,
                        (or any other open lists of autocompleted values:*/
                        closeAllLists(e);
                        //that.getListOfSchools(e, row, tableid);
                    });
                    a.appendChild(b);
                }
            }
        });
        /*execute a function presses a key on the keyboard:*/
        inp.addEventListener("keydown", function (e) {
            let that = this;
            let x = document.getElementById(this.id + "autocomplete-list");
            let y: any;
            if (x) y = x.getElementsByTagName("div");
            if (e.keyCode == 40) {
                /*If the arrow DOWN key is pressed,
                increase the currentFocus variable:*/
                currentFocus++;
                /*and and make the current item more visible:*/
                addActive(y);
            } else if (e.keyCode == 38) { //up
                /*If the arrow UP key is pressed,
                decrease the currentFocus variable:*/
                currentFocus--;
                /*and and make the current item more visible:*/
                addActive(y);
            } else if (e.keyCode == 13) {
                /*If the ENTER key is pressed, prevent the form from being submitted,*/
                e.preventDefault();
                if (currentFocus > -1) {
                    /*and simulate a click on the "active" item:*/
                    if (y) {
                        y[currentFocus].click();
                    }
                }
            }
        });

        function addActive(x) {
            /*a function to classify an item as "active":*/
            if (!x) return false;
            /*start by removing the "active" class on all items:*/
            removeActive(x);
            if (currentFocus >= x.length) currentFocus = 0;
            if (currentFocus < 0) currentFocus = (x.length - 1);
            /*add class "autocomplete-active":*/
            x[currentFocus].classList.add("autocomplete-active");
        }
        function removeActive(x) {
            /*a function to remove the "active" class from all autocomplete items:*/
            for (var i = 0; i < x.length; i++) {
                x[i].classList.remove("autocomplete-active");
            }
        }
        function closeAllLists(elmnt) {
            /*close all autocomplete lists in the document,
            except the one passed as an argument:*/
            var x = document.getElementsByClassName("autocomplete-items");
            for (var i = 0; i < x.length; i++) {
                if (elmnt != x[i] && elmnt != inp) {
                    x[i].parentNode.removeChild(x[i]);
                }
            }
        }
        /*execute a function when someone clicks in the document:*/
        document.addEventListener("click", function (e) {
            closeAllLists(e.target);
        });
    }

}


class DeletePermissions {
    constructor() {
        let core = new Core();

        this.bindLEAAutocompletes();
        this.bindUserAutocompletes();

        let leaSearchButton = <HTMLButtonElement>document.getElementById("impersonationLEASearchSearch");
        if (leaSearchButton !== null)
            leaSearchButton.addEventListener("click", (e: Event) => this.leaSearch());

        let UserSearchButton = <HTMLButtonElement>document.getElementById("impersonationUserSearchSearch");
        if (UserSearchButton !== null)
            UserSearchButton.addEventListener("click", (e: Event) => this.userSearch());

        let deleteButtons = document.getElementsByClassName("deleteRow");
        for (let deleteButton of deleteButtons)
            deleteButton.addEventListener("click", (e: Event) => this.deleteRow(e));

        core.activateSubMenu("DeletePermissions");
    }


    bindLEAAutocompletes() {
        let hidden = <HTMLInputElement>document.getElementById("hiddenlealist");
        let hiddenValue = hidden.value;
        let thisarray = new Array();
        thisarray = hiddenValue.split(',');
        let autocompletes = document.getElementsByClassName("institutionAutocomplete");
        for (let autocomplete of autocompletes) {
            let ac = <HTMLInputElement>autocomplete;
            let row = ac.dataset.rownumber;
            let tableid = ac.dataset.tableid
            this.autocomplete(autocomplete, thisarray);
        }
    }

    bindUserAutocompletes() {
        let hidden = <HTMLInputElement>document.getElementById("hiddenUserlist");
        let hiddenValue = hidden.value;
        let thisarray = new Array();
        thisarray = hiddenValue.split(',');
        let autocompletes = document.getElementsByClassName("userAutocomplete");
        for (let autocomplete of autocompletes) {
            let ac = <HTMLInputElement>autocomplete;
            let row = ac.dataset.rownumber;
            let tableid = ac.dataset.tableid
            this.autocomplete(autocomplete, thisarray);
        }
    }

    leaSearch() {
        let nameElement = <HTMLInputElement>document.getElementById("institutionNameSearch");

        let name = document.getElementsByTagName("input")[0].value;

        let url = "/Admin/DeletePermissions/";
        if (name !== "")
            url += "?districtName=" + encodeURIComponent(name);

        window.location.href = url;
    }

    userSearch() {
        let nameElement = <HTMLInputElement>document.getElementById("impersonationUserSearchSearch");
        let leaName = document.getElementsByTagName("input")[0].value;
        let userName = document.getElementsByTagName("input")[3].value;
        let userNAme = <HTMLInputElement>document.getElementById("userNameSearch");
        let userNAmeValue = userNAme.value;

        let url = "/Admin/DeletePermissions/";
        if (leaName !== "" && userName !== "") {
            url += "?districtName=" + encodeURIComponent(leaName) + "&userName=" + userName;

            window.location.href = url;
        }
    }


    autocomplete(inp, arr) {

        /*the autocomplete function takes two arguments,
        the text field element and an array of possible autocompleted values:*/
        var currentFocus;
        let that = this;
        /*execute a function when someone writes in the text field:*/

        inp.addEventListener("input", function (e) {
            var a, b, i, val = this.value;

            /*close any already open lists of autocompleted values*/
            closeAllLists(e);

            if (!val) { return false; }
            currentFocus = -1;
            /*create a DIV element that will contain the items (values):*/
            a = document.createElement("DIV");
            a.setAttribute("id", this.id + "autocomplete-list");
            a.setAttribute("class", "autocomplete-items");
            /*append the DIV element as a child of the autocomplete container:*/
            this.parentNode.appendChild(a);
            /*for each item in the array...*/
            for (i = 0; i < arr.length; i++) {
                /*check if the item starts with the same letters as the text field value:*/
                if (arr[i].substr(0, val.length).toUpperCase() == val.toUpperCase()) {
                    /*create a DIV element for each matching element:*/
                    b = document.createElement("DIV");
                    /*make the matching letters bold:*/
                    b.innerHTML = "<strong>" + arr[i].substr(0, val.length) + "</strong>";
                    b.innerHTML += arr[i].substr(val.length);
                    /*insert a input field that will hold the current array item's value:*/
                    b.innerHTML += "<input type='hidden' value='" + arr[i] + "'>";
                    /*execute a function when someone clicks on the item value (DIV element):*/
                    b.addEventListener("click", function (e) {
                        /*insert the value for the autocomplete text field:*/
                        inp.value = this.getElementsByTagName("input")[0].value;
                        /*close the list of autocompleted values,
                        (or any other open lists of autocompleted values:*/
                        closeAllLists(e);
                        //that.getListOfSchools(e, row, tableid);
                    });
                    a.appendChild(b);
                }
            }
        });
        /*execute a function presses a key on the keyboard:*/
        inp.addEventListener("keydown", function (e) {
            let that = this;
            let x = document.getElementById(this.id + "autocomplete-list");
            let y: any;
            if (x) y = x.getElementsByTagName("div");
            if (e.keyCode == 40) {
                /*If the arrow DOWN key is pressed,
                increase the currentFocus variable:*/
                currentFocus++;
                /*and and make the current item more visible:*/
                addActive(y);
            } else if (e.keyCode == 38) { //up
                /*If the arrow UP key is pressed,
                decrease the currentFocus variable:*/
                currentFocus--;
                /*and and make the current item more visible:*/
                addActive(y);
            } else if (e.keyCode == 13) {
                /*If the ENTER key is pressed, prevent the form from being submitted,*/
                e.preventDefault();
                if (currentFocus > -1) {
                    /*and simulate a click on the "active" item:*/
                    if (y) {
                        y[currentFocus].click();
                    }
                }
            }
        });

        function addActive(x) {
            /*a function to classify an item as "active":*/
            if (!x) return false;
            /*start by removing the "active" class on all items:*/
            removeActive(x);
            if (currentFocus >= x.length) currentFocus = 0;
            if (currentFocus < 0) currentFocus = (x.length - 1);
            /*add class "autocomplete-active":*/
            x[currentFocus].classList.add("autocomplete-active");
        }
        function removeActive(x) {
            /*a function to remove the "active" class from all autocomplete items:*/
            for (var i = 0; i < x.length; i++) {
                x[i].classList.remove("autocomplete-active");
            }
        }
        function closeAllLists(elmnt) {
            /*close all autocomplete lists in the document,
            except the one passed as an argument:*/
            var x = document.getElementsByClassName("autocomplete-items");
            for (var i = 0; i < x.length; i++) {
                if (elmnt != x[i] && elmnt != inp) {
                    x[i].parentNode.removeChild(x[i]);
                }
            }
        }
        /*execute a function when someone clicks in the document:*/
        document.addEventListener("click", function (e) {
            closeAllLists(e.target);
        });
    }

    deleteRow(e: Event) {
        let buttonElement = <HTMLButtonElement>e.srcElement;
        let userpk = parseInt(buttonElement.dataset.eddiruserpk);
        let institutionfk = parseInt(buttonElement.dataset.institutionfk);
        let accesstypecodefk = parseInt(buttonElement.dataset.accesstypecodefk);
        if (userpk != 0) {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/Admin/DeleteUserAccess', true);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();

                    //let element = document.getElementById("planOwner");
                    //element.remove();

                    //let modal: Modal = new Modal("deleteOwnerModal", null);
                    //modal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "The data has been removed.  Please refresh the page if you want to see new data.", 'success', 3000, null);
                } else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send("userFK=" + userpk + "&frppInstitutionFK=" + institutionfk + "&accessTypeCodeFK=" + accesstypecodefk);
        }
    }

}

//SpecialEducationAssignLEA
class SpecialEducationAssignLEA {
    private _core: Core;
    private allDeleteElements = [];
    constructor() {
        this._core = new Core();
        this._core.activateSubMenu("SpecialEducationAssignLEA");

        this.bindAll();

    }

    bindAll() {
        let searchButton = document.getElementById("LEASearch");
        if (searchButton !== null)
            searchButton.addEventListener("click", (e: Event) => this.search(e));
        let clearButton = document.getElementById("LEASearchClear");
        if (clearButton !== null)
            clearButton.addEventListener("click", (e: Event) => this.clearSearch(e));
        let resourceCheckboxes = document.getElementsByClassName("actionPlanSelectedMeasurableGoals");
        for (let resourceCheckbox of resourceCheckboxes) {
            //let element = <HTMLInputElement>resourceCheckbox;
            resourceCheckbox.addEventListener("change", (e: Event) => this.setDeleteElement(e));
        }
        let saveButtons = document.getElementsByClassName("saveLEA");
        for (let saveButton of saveButtons) {
            //let element = <HTMLInputElement>resourceCheckbox;
            saveButton.addEventListener("click", (e: Event) => this.saveLEA(e));
        }

        let editResourceCancelButton = <HTMLButtonElement>document.getElementById("editResourceCancel");
        if (editResourceCancelButton !== null)
            editResourceCancelButton.addEventListener("click", (e: Event) => this.editResourceCancel());

        let deleteLEAConfirmButton = <HTMLButtonElement>document.getElementById("deleteLEAConfirm");
        if (deleteLEAConfirmButton !== null)
            deleteLEAConfirmButton.addEventListener("click", (e: Event) => this.deleteLEAConfirm(deleteLEAConfirmButton));

        let deleteLEACancelButton = <HTMLButtonElement>document.getElementById("deleteLEACancel");
        if (deleteLEACancelButton !== null)
            deleteLEACancelButton.addEventListener("click", (e: Event) => this.deleteLEACancel());

        let deleteLEAButtons = document.getElementsByClassName("deleteLEAButton") as HTMLCollectionOf<HTMLButtonElement>;
        for (let deleteLEAButton of deleteLEAButtons) {
            deleteLEAButton.addEventListener("click", (e: Event) => this.deleteLEA(e));
        }

    }

    deleteLEA(e: Event) {
        let button = <HTMLButtonElement>e.srcElement;
        let originallearningnetwork = button.dataset.originallearningnetwork;
        let inst = button.dataset.inst;

        if ((originallearningnetwork && parseInt(originallearningnetwork) > 0) && (inst && parseInt(inst) > 0)) {
            let modal: Modal = new Modal("deleteLEAModal", null);
            let deleteConfirmButton = <HTMLButtonElement>document.getElementById("deleteLEAConfirm");
            deleteConfirmButton.dataset.originallearningnetwork = originallearningnetwork;
            deleteConfirmButton.dataset.inst = inst;
            modal.show();
        }
    }

    deleteLEAConfirm(confirmButton: HTMLButtonElement) {
        let core = this._core;
        Core.showLoader();

        let originallearningnetwork = parseInt(confirmButton.dataset.originallearningnetwork);
        let inst = parseInt(confirmButton.dataset.inst);

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SaveSpecialEducationFRCPPInstutionDelete', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Delete Successful", 'success', 3000, core.pageReload(true, null));
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("originalNetwork=" + originallearningnetwork + "&frcppInstitution=" + inst);
    }

    deleteLEACancel() {
        let modal: Modal = new Modal("deleteLEAModal", null);
        modal.hide();
    }

    editResourceCancel() {
        let modal: Modal = new Modal("editResourceModal", null);
        modal.hide();
    }

    setDeleteElement(e: Event) {
        let element = <HTMLInputElement>e.srcElement;
        if (element.checked === false && parseInt(element.dataset.sequencenbr) !== 0) {
            let saveItem: IResourcePage = {
                ResourceFK: parseInt(element.dataset.resourcefk),
                PageFK: parseInt(element.dataset.pagefk),
                SequenceNbr: parseInt(element.dataset.sequencenbr),
            };

            this.allDeleteElements.push(saveItem);
        }
    }

    clearSearch(e: Event) {
        Core.createHTMLAlert("alertMessageDiv", "ReloadingPage", 'success', 3000, this._core.pageReload());
    }

    searchName(searchvalue: string) {
        let searchString = searchvalue.toUpperCase().split(" ");
        let searchRows = document.getElementsByClassName("rowForSearch");
        let iUName = <HTMLInputElement>document.getElementById("IUNameInput");
        for (let searchRow of searchRows) {
            let count = 0;
            let element = <HTMLElement>searchRow;
            let searchRowValue = element.dataset.iuname.toUpperCase();

            for (let search of searchString) {
                if (searchRowValue.includes(search)) {
                    count++;
                }
            }
            if (count === 0) {
                element.style.display = "none";
            }
            if (count !== 0) {
                element.style.display = "block";
            }
        }
        iUName.value = searchvalue;
    }

    search(e: Event) {
        let core = this._core;
        let that = this;
        let iUName = <HTMLInputElement>document.getElementById("IUNameInput");
        let iU = <HTMLSelectElement>document.getElementById("IUSearchDropDown");
        let iUValue = 0;
        let iUNameValue = "";
        if (iU !== null) {
            iUValue = parseInt(iU.value);
        }
        if (iUName !== null) {
            iUNameValue = iUName.value;
        }
        if ((iUName !== null || iUName.value !== "") && (iU !== null && iUValue === 0)) {
            that.searchName(iUNameValue);
        }
        else {
            if (iU !== null) {
                let xhr = new XMLHttpRequest();

                xhr.open('POST', '/Admin/SearchByLEA', true);
                xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        let document = window.document.body;
                        document.innerHTML = xhr.responseText;
                        that.bindAll();
                        that.allDeleteElements = [];
                        if (iUNameValue !== "") {
                            that.searchName(iUNameValue);
                        }
                        Core.createHTMLAlert("alertMessageDiv", "New data loaded", 'success', 3000, null);
                    }
                    else {
                        Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                    }
                };
                xhr.send("iUValue=" + iUValue);
            }
        }
    }

    saveLEA(e: Event) {
        let core = this._core;
        let button = <HTMLButtonElement>e.srcElement;
        let originallearningnetwork = parseInt(button.dataset.originallearningnetwork);
        let inst = parseInt(button.dataset.inst);
        let thisSelect = (button.dataset.thisselect);
        let select = document.getElementById(thisSelect);
        let selectElement = <HTMLSelectElement>select;
        let newLearningNetwork = parseInt(selectElement.value);

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SaveSpecialEducationFRCPPInstutionAssign', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, core.pageReload(true, null));
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("originalNetwork=" + originallearningnetwork + "&newNetwork=" + newLearningNetwork + "&frcppInstitution=" + inst);
    }
}

//SpecialEducationAddNewLEA
class SpecialEducationAddNewLEA {
    private _core: Core;
    constructor() {
        this._core = new Core();
        this._core.activateSubMenu("SpecialEducationAddNewLEA");

        this.bindLEAAutocompletes();

        let UserSaveButton = <HTMLButtonElement>document.getElementById("AddNewLEASave");
        if (UserSaveButton !== null)
            UserSaveButton.addEventListener("click", (e: Event) => this.saveLEA(e));

    }


    bindLEAAutocompletes() {
        let hidden = <HTMLInputElement>document.getElementById("hiddenlealist");
        let hiddenValue = hidden.value;
        let thisarray = new Array();
        thisarray = hiddenValue.split(',');
        let autocompletes = document.getElementsByClassName("institutionAutocomplete");
        for (let autocomplete of autocompletes) {
            let ac = <HTMLInputElement>autocomplete;
            let row = ac.dataset.rownumber;
            let tableid = ac.dataset.tableid
            this.autocomplete(autocomplete, thisarray);
        }
    }

    userSearch() {
        let nameElement = <HTMLInputElement>document.getElementById("impersonationUserSearchSearch");
        let leaName = document.getElementsByTagName("input")[0].value;
        let userName = document.getElementsByTagName("input")[3].value;
        let userNAme = <HTMLInputElement>document.getElementById("userNameSearch");
        let userNAmeValue = userNAme.value;

        let url = "/Admin/ImpersonateUser/";
        if (leaName !== "" && userName !== "") {
            url += "?districtName=" + encodeURIComponent(leaName) + "&userName=" + userName;

            window.location.href = url;
        }
    }


    autocomplete(inp, arr) {

        /*the autocomplete function takes two arguments,
        the text field element and an array of possible autocompleted values:*/
        var currentFocus;
        let that = this;
        /*execute a function when someone writes in the text field:*/

        inp.addEventListener("input", function (e) {
            var a, b, i, val = this.value;

            /*close any already open lists of autocompleted values*/
            closeAllLists(e);

            if (!val) { return false; }
            currentFocus = -1;
            /*create a DIV element that will contain the items (values):*/
            a = document.createElement("DIV");
            a.setAttribute("id", this.id + "autocomplete-list");
            a.setAttribute("class", "autocomplete-items");
            /*append the DIV element as a child of the autocomplete container:*/
            this.parentNode.appendChild(a);
            /*for each item in the array...*/
            for (i = 0; i < arr.length; i++) {
                /*check if the item starts with the same letters as the text field value:*/
                if (arr[i].substr(0, val.length).toUpperCase() == val.toUpperCase()) {
                    /*create a DIV element for each matching element:*/
                    b = document.createElement("DIV");
                    /*make the matching letters bold:*/
                    b.innerHTML = "<strong>" + arr[i].substr(0, val.length) + "</strong>";
                    b.innerHTML += arr[i].substr(val.length);
                    /*insert a input field that will hold the current array item's value:*/
                    b.innerHTML += "<input type='hidden' value='" + arr[i] + "'>";
                    /*execute a function when someone clicks on the item value (DIV element):*/
                    b.addEventListener("click", function (e) {
                        /*insert the value for the autocomplete text field:*/
                        inp.value = this.getElementsByTagName("input")[0].value;
                        /*close the list of autocompleted values,
                        (or any other open lists of autocompleted values:*/
                        closeAllLists(e);
                        //that.getListOfSchools(e, row, tableid);
                    });
                    a.appendChild(b);
                }
            }
        });
        /*execute a function presses a key on the keyboard:*/
        inp.addEventListener("keydown", function (e) {
            let that = this;
            let x = document.getElementById(this.id + "autocomplete-list");
            let y: any;
            if (x) y = x.getElementsByTagName("div");
            if (e.keyCode == 40) {
                /*If the arrow DOWN key is pressed,
                increase the currentFocus variable:*/
                currentFocus++;
                /*and and make the current item more visible:*/
                addActive(y);
            } else if (e.keyCode == 38) { //up
                /*If the arrow UP key is pressed,
                decrease the currentFocus variable:*/
                currentFocus--;
                /*and and make the current item more visible:*/
                addActive(y);
            } else if (e.keyCode == 13) {
                /*If the ENTER key is pressed, prevent the form from being submitted,*/
                e.preventDefault();
                if (currentFocus > -1) {
                    /*and simulate a click on the "active" item:*/
                    if (y) {
                        y[currentFocus].click();
                    }
                }
            }
        });

        function addActive(x) {
            /*a function to classify an item as "active":*/
            if (!x) return false;
            /*start by removing the "active" class on all items:*/
            removeActive(x);
            if (currentFocus >= x.length) currentFocus = 0;
            if (currentFocus < 0) currentFocus = (x.length - 1);
            /*add class "autocomplete-active":*/
            x[currentFocus].classList.add("autocomplete-active");
        }
        function removeActive(x) {
            /*a function to remove the "active" class from all autocomplete items:*/
            for (var i = 0; i < x.length; i++) {
                x[i].classList.remove("autocomplete-active");
            }
        }
        function closeAllLists(elmnt) {
            /*close all autocomplete lists in the document,
            except the one passed as an argument:*/
            var x = document.getElementsByClassName("autocomplete-items");
            for (var i = 0; i < x.length; i++) {
                if (elmnt != x[i] && elmnt != inp) {
                    x[i].parentNode.removeChild(x[i]);
                }
            }
        }
        /*execute a function when someone clicks in the document:*/
        document.addEventListener("click", function (e) {
            closeAllLists(e.target);
        });
    }

    saveLEA(e: Event) {
        let core = this._core;
        let institutionNameSearch = <HTMLInputElement>document.getElementById("institutionNameSearch");
        let instName = institutionNameSearch.value;
        let select = document.getElementById("IUSearchDropDown");
        let selectElement = <HTMLSelectElement>select;
        let newLearningNetwork = parseInt(selectElement.value);

        if (instName === "" || newLearningNetwork === 0) {
            Core.createHTMLAlert("alertMessageDiv", "Please enter both values", 'error', 3000, null);
            return false;
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SaveSpecialEducationNewLEA', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, core.pageReload(true, null));
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("newNetwork=" + newLearningNetwork + "&institution=" + instName);
    }

}

//UpdatePageInformation
class UpdatePageInformation {
    private _core: Core;
    constructor() {
        this._core = new Core();
        this.bindAll();
    }

    bindAll() {
        let leadInSaveButton = document.getElementById("leadInSave");
        if (leadInSaveButton !== null)
            leadInSaveButton.addEventListener("click", (e: Event) => this.savePlanSection(e));
        //let clearButton = document.getElementById("LEASearchClear");
        //if (clearButton !== null)
        //    clearButton.addEventListener("click", (e: Event) => this.clearSearch(e));
        let pageInstructionSaveButton = document.getElementById("pageInstructionSave");
        if (pageInstructionSaveButton !== null)
            pageInstructionSaveButton.addEventListener("click", (e: Event) => this.savePageInstructions(e));

        let pageSectionSaveButtons = document.getElementsByClassName("savePageSection");
        for (let pageSectionSaveButton of pageSectionSaveButtons) {
            pageSectionSaveButton.addEventListener("click", (e: Event) => this.savePageSection(e));
        }

        let pageSectionLabelSaveButtons = document.getElementsByClassName("pageSectionLabelSave");
        for (let pageSectionLabelSaveButton of pageSectionLabelSaveButtons) {
            pageSectionLabelSaveButton.addEventListener("click", (e: Event) => this.savePageSectionLabel(e));
        }

        let propertyLabelSaveButtons = document.getElementsByClassName("propertyLabelSave");
        for (let propertyLabelSaveButton of propertyLabelSaveButtons) {
            propertyLabelSaveButton.addEventListener("click", (e: Event) => this.savePropertyLabel(e));
        }

        let propertyDescriptionSaveButtons = document.getElementsByClassName("propertyDescriptionSave");
        for (let propertyDescriptionSaveButton of propertyDescriptionSaveButtons) {
            propertyDescriptionSaveButton.addEventListener("click", (e: Event) => this.savePropertyDescription(e));
        }

        let editResourceCancelButton = <HTMLButtonElement>document.getElementById("editResourceCancel");
        if (editResourceCancelButton !== null)
            editResourceCancelButton.addEventListener("click", (e: Event) => this.editResourceCancel());

    }

    deleteLEA(e: Event) {
        let button = <HTMLButtonElement>e.srcElement;
        let originallearningnetwork = button.dataset.originallearningnetwork;
        let inst = button.dataset.inst;

        if ((originallearningnetwork && parseInt(originallearningnetwork) > 0) && (inst && parseInt(inst) > 0)) {
            let modal: Modal = new Modal("deleteLEAModal", null);
            let deleteConfirmButton = <HTMLButtonElement>document.getElementById("deleteLEAConfirm");
            deleteConfirmButton.dataset.originallearningnetwork = originallearningnetwork;
            deleteConfirmButton.dataset.inst = inst;
            modal.show();
        }
    }

    deleteLEAConfirm(confirmButton: HTMLButtonElement) {
        let core = this._core;
        Core.showLoader();

        let originallearningnetwork = parseInt(confirmButton.dataset.originallearningnetwork);
        let inst = parseInt(confirmButton.dataset.inst);

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SaveSpecialEducationFRCPPInstutionDelete', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Delete Successful", 'success', 3000, core.pageReload(true, null));
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("originalNetwork=" + originallearningnetwork + "&frcppInstitution=" + inst);
    }

    deleteLEACancel() {
        let modal: Modal = new Modal("deleteLEAModal", null);
        modal.hide();
    }

    editResourceCancel() {
        let modal: Modal = new Modal("editResourceModal", null);
        modal.hide();
    }

    savePlanSection(e: Event) {
        let core = new Core();
        //Core.showLoader();
        let button = <HTMLButtonElement>e.currentTarget;
        //let edDirUserFK = parseInt(button.dataset.eddiruserpk);
        let PKid = button.dataset.pk;
        let inputElement = <HTMLInputElement>document.getElementById("leadInInput")
        let value = inputElement.value;
        let saveObject: IUpdatePageInformation;
        saveObject = {
            PK: parseInt(PKid),
            Value: value
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SavePlanSection', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, core.pageReload(true, null));
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));
    }

    savePageInstructions(e: Event) {
        let core = new Core();
        //Core.showLoader();
        let button = <HTMLButtonElement>e.currentTarget;
        //let edDirUserFK = parseInt(button.dataset.eddiruserpk);
        let PKid = button.dataset.pk;
        let inputElement = <HTMLInputElement>document.getElementById("pageInstructionInput")
        let value = inputElement.value;
        let saveObject: IUpdatePageInformation;
        saveObject = {
            PK: parseInt(PKid),
            Value: value
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SavePageInstructions', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, core.pageReload(true, null));
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));
    }

    savePageSection(e: Event) {
        let core = new Core();
        //Core.showLoader();
        let button = <HTMLButtonElement>e.currentTarget;
        //let edDirUserFK = parseInt(button.dataset.eddiruserpk);
        let PKid = button.dataset.pk;
        let inputElement = <HTMLInputElement>document.getElementById("pageSectionInput_" + PKid);
        let value = inputElement.value;
        let saveObject: IUpdatePageInformation;
        saveObject = {
            PK: parseInt(PKid),
            Value: value
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SavePageSection', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, core.pageReload(true, null));
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));
    }

    savePageSectionLabel(e: Event) {
        let core = new Core();
        //Core.showLoader();
        let button = <HTMLButtonElement>e.currentTarget;
        //let edDirUserFK = parseInt(button.dataset.eddiruserpk);
        let PKid = button.dataset.pk;
        let inputElement = <HTMLInputElement>document.getElementById("pageSectionLabelInput_" + PKid);
        let value = inputElement.value;
        let saveObject: IUpdatePageInformation;
        saveObject = {
            PK: parseInt(PKid),
            Value: value
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SavePageSection', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, core.pageReload(true, null));
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));
    }

    savePropertyLabel(e: Event) {
        let core = new Core();
        //Core.showLoader();
        let button = <HTMLButtonElement>e.currentTarget;
        //let edDirUserFK = parseInt(button.dataset.eddiruserpk);
        let PKid = button.dataset.pk;
        let inputElement = <HTMLInputElement>document.getElementById("propertyLabelInput_" + PKid);
        let value = inputElement.value;
        let saveObject: IUpdatePageInformation;
        saveObject = {
            PK: parseInt(PKid),
            Value: value
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SavePropertyLabel', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, core.pageReload(true, null));
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));
    }

    savePropertyDescription(e: Event) {
        let core = new Core();
        //Core.showLoader();
        let button = <HTMLButtonElement>e.currentTarget;
        //let edDirUserFK = parseInt(button.dataset.eddiruserpk);
        let PKid = button.dataset.pk;
        let inputElement = <HTMLInputElement>document.getElementById("propertyDescriptionInput_" + PKid);
        let value = inputElement.value;
        let saveObject: IUpdatePageInformation;
        saveObject = {
            PK: parseInt(PKid),
            Value: value
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SavePropertyDescription', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, core.pageReload(true, null));
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));
    }

}

//Update All Archive Dates
class UpdateAllArchiveDates {
    constructor() {
        let core = new Core();

        let saveButton = <HTMLButtonElement>document.getElementById("saveButton");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e: Event) => this.save(e));

        core.activateSubMenu("UpdateAllArchiveDates");
    }



    save(e: Event) {
        let core = new Core();

        let runSave = true;

        let button = <HTMLButtonElement>e.currentTarget;
        let selectid = button.dataset.selectid;

        let allSaveElements = [];
        let allSelectElements = [];
        let saveObject: IPDEUserSaveAllArchiveDates;
        let selectElements = document.getElementsByClassName("adminSelect");
        let selectElementValue = -1;
        let startYear = <HTMLSelectElement>document.getElementById("planYear");
        let startYearFK = parseInt(startYear.value);
        let selectedPhaseCodeValue = -1;
        let element = <HTMLSelectElement>document.getElementById(selectid);
        if (element !== null) {
            selectElementValue = parseInt(element.value);
            let datepicker = document.getElementById("datePicker");
            let datePickerElement = <HTMLInputElement>datepicker;
            let date = datePickerElement.value;
            let utc = new Date(date);
            var archiveDate = new Date(utc.getTime() + utc.getTimezoneOffset() * 60000);// this converts it back to the day it should be.
            let selectedPhaseCode = document.getElementById("updateArchiveDatesSelectPhase");
            let selectedPhaseCodeElement = <HTMLSelectElement>selectedPhaseCode;
            if (selectedPhaseCodeElement !== null) {
                selectedPhaseCodeValue = parseInt(selectedPhaseCodeElement.value);
            }
            if (selectElementValue > -1 && selectedPhaseCodeValue > -1 && date !== "" && startYear.value !== "") {

                saveObject = {
                    PlanTypeFK: selectElementValue,
                    PhaseCodeFK: selectedPhaseCodeValue,
                    StartYearFK: startYearFK,
                    ArchiveDate: archiveDate
                };
            }
        }

        if(typeof saveObject === "undefined" || saveObject === null) 
        {
            Core.createHTMLAlert("alertMessageDiv", "Please fill out all fields", 'error', 3000, null);
        }
        else
        {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/Admin/SaveAllArchiveDates', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, null);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send(JSON.stringify(saveObject));
        }
    }
}

class CATSEmail {
    private _core: Core;

    constructor() {
        this._core = new Core();
        this._core.activateSubMenu("CATSEmail");

        //let leaClearButton = <HTMLButtonElement>document.getElementById("clearSearch");
        //if (leaClearButton !== null)
        //    leaClearButton.addEventListener("click", (e: Event) => this.clearLEASearchFilter());

        let leaSearchButton = <HTMLButtonElement>document.getElementById("catsEmailSearch");
        if (leaSearchButton !== null)
            leaSearchButton.addEventListener("click", (e: Event) => this.doSearch(e));

        let emailButtons = document.getElementsByClassName("emailButton");
        for (let emailButton of emailButtons) {
            //let element = <HTMLInputElement>resourceCheckbox;
            emailButton.addEventListener("click", (e: Event) => this.sendEmail(e));
        }

        const instructionalRadioButtons = document.getElementsByClassName("catsSystemProgramDataTOPRadioField");
        for (const instructionalRadioButton of instructionalRadioButtons)
            instructionalRadioButton.addEventListener("change", (e: Event) => this.programAreaOrCluster(<HTMLInputElement>e.srcElement));

        const programAreaOrClusterRadio = <HTMLInputElement>document.querySelector(".catsSystemProgramDataTOPRadioField:checked");
        if (programAreaOrClusterRadio !== null)
            this.programAreaOrCluster(programAreaOrClusterRadio);


        const clusterProgramAreaSelects = document.getElementsByClassName("catsSystemProgramDataClusterPASelectField")
        for (const clusterProgramAreaSelect of clusterProgramAreaSelects) {
            clusterProgramAreaSelect.addEventListener("change", (e: Event) => this.narrowCIPs(<HTMLSelectElement>e.srcElement));
            this.narrowCIPs(<HTMLSelectElement>clusterProgramAreaSelect, false, true);
        }

        let selectAll = document.getElementById("selectAllSchool");
        if (selectAll !== null) {
            selectAll.addEventListener("change", (e: Event) => this.selectAllSchools(<HTMLInputElement>e.srcElement));
        }

        this._core.bindChangeLEA();
    }

    programAreaOrCluster(radioElement: HTMLInputElement) {
        if (radioElement && radioElement.checked) {
            const whichOne = radioElement.dataset.lookuplabel;
            const clusterElement = <HTMLSelectElement>document.querySelector(".catsSystemProgramDataSelectField[data-typeofprogram='cluster']");
            const programAreaElement = <HTMLSelectElement>document.querySelector(".catsSystemProgramDataSelectField[data-typeofprogram='programarea']");
            const cipSelect = <HTMLSelectElement>document.querySelector(".catsSystemProgramDataSelectField[data-typeofprogram='cip']");

            if (whichOne.toLowerCase().indexOf("cluster") >= 0) {
                clusterElement.disabled = false;
                programAreaElement.disabled = true;
            } else if (whichOne.toLowerCase().indexOf("program") >= 0) {
                clusterElement.disabled = true;
                programAreaElement.disabled = false;
            }

            cipSelect.disabled = true;
        }
    }

    narrowCIPs(selectElement: HTMLSelectElement, resetIndex: boolean = true, firstRun: boolean = false) {
        const typeOfProgram = selectElement.dataset.typeofprogram;
        const cipSelect = <HTMLSelectElement>document.querySelector(".catsSystemProgramDataSelectField[data-typeofprogram='cip']");
        if (resetIndex)
            cipSelect.selectedIndex = 0;

        const cluster = <HTMLSelectElement>document.querySelector(".catsSystemProgramDataClusterPASelectField[data-typeofprogram='cluster']");
        const programArea = <HTMLSelectElement>document.querySelector(".catsSystemProgramDataClusterPASelectField[data-typeofprogram='programarea']");

        if (cluster.selectedIndex >= 0 || programArea.selectedIndex >= 0)
            firstRun = false; //Override this if there is a value selected.

        const allCips = document.querySelectorAll(".catsSystemProgramDataSelectField[data-typeofprogram='cip'] option");
        for (let cip of allCips) {
            let ele = <HTMLOptionElement>cip;
            if (ele.classList.contains("hide"))
                ele.classList.remove("hide");
        }

        if (typeOfProgram === "cluster") {
            if (selectElement.selectedIndex <= 0) {
                cipSelect.disabled = true;
            } else {
                cipSelect.disabled = false;
                const clusterPK = selectElement.value;

                for (let i = 0; i < cipSelect.length; i++) {
                    const option = <HTMLOptionElement>cipSelect[i];

                    if (option.dataset.cluster !== clusterPK) {
                        cipSelect[i].classList.add("hide");
                    }
                }
            }
        } else if (typeOfProgram === "programarea") {
            if (selectElement.selectedIndex <= 0) {
                cipSelect.disabled = true;
            } else {
                cipSelect.disabled = false;
                const clusterPK = selectElement.value;

                for (let i = 0; i < cipSelect.length; i++) {
                    const option = <HTMLOptionElement>cipSelect[i];

                    if (option.dataset.cluster !== clusterPK) {
                        cipSelect[i].classList.add("hide");
                    }
                }
            }
        }

        if (!firstRun)
            cipSelect.disabled = false;
    }

    doSearch(e: Event) {
        let typeOfProgramElement = <HTMLInputElement>document.querySelector(".catsSystemProgramDataRadioField[data-save='typeofprogram']:checked");
        let typeValue = 0;
        if (typeOfProgramElement !== null)
            typeValue = parseInt(typeOfProgramElement.value);

        let cluster = <HTMLSelectElement>document.getElementById("CareerCluster");
        let clusterValue = 0;
        if(cluster !== null)
            clusterValue = parseInt(cluster.value);

        let programArea = <HTMLSelectElement>document.getElementById("ProgramArea");
        let programAreaValue = 0;
        if(programArea !== null)
            programAreaValue = parseInt(programArea.value);

        let cip = <HTMLSelectElement>document.getElementById("CipCode");
        let cipValue = 0;
        if(cip !== null)
            cipValue = parseInt(cip.value);

        let status = <HTMLSelectElement>document.getElementById("Status");
        let statusValue = 0;
        if(status !== null)
            statusValue = parseInt(status.value);

        let noTeacher = <HTMLInputElement>document.getElementById("noTeacherCheckbox");
        let noTeacherValue = false;
        if (noTeacher.checked)
            noTeacherValue = true;

        let noScope = <HTMLInputElement>document.getElementById("noScopeCheckbox");
        let noScopeValue = false;
        if (noScope.checked)
            noScopeValue = true;

        let noStudentCert = <HTMLInputElement>document.getElementById("noStudentCertCheckbox");
        let noStudentCertValue = false;
        if (noStudentCert.checked)
            noStudentCertValue = true;

        let noTeacherCert = <HTMLInputElement>document.getElementById("noTeacherIndCertCheckbox");
        let noTeacherCertValue = false;
        if (noTeacherCert.checked)
            noTeacherCertValue = true;

        let button = <HTMLButtonElement>document.getElementsByClassName("button page-nav-button active")[0];
        let pageNumber = 1;
        if (button != null)
            pageNumber = parseInt(button.dataset.pagenumber);

        let url = "/Admin/CatsEmail/";
        url += "?cipCodeFK=" + cipValue + "&typeOfProgram=" + typeValue + "&cluster=" + clusterValue + "&programArea=" + programAreaValue + "&statusPK=" + statusValue + "&noTeacher=" + noTeacherValue + "&noStudentIndCert=" + noStudentCertValue + "&noScope=" + noScopeValue + "&noTeacherIndCert=" + noTeacherCertValue + "&pageNumber=" + pageNumber;

        window.location.href = url;
    }

    
    selectAllSchools(selectAllCheckbox: HTMLInputElement) {
        let allCheckboxes = document.getElementsByClassName("emailCheckBox");
        let allChecked: boolean = false;
        if (selectAllCheckbox.checked) {
            allChecked = true;
        }

        if (allChecked) {
            for (let checkbox of allCheckboxes) {
                let element = <HTMLInputElement>checkbox;
                element.checked = true;
            }
        } else {
            for (let checkbox of allCheckboxes) {
                let element = <HTMLInputElement>checkbox;
                element.checked = false;
            }
        }
    }

    sendEmail(e: Event) {
        let button = <HTMLButtonElement>e.srcElement;
        let planPK = button.dataset.planpk;
        let href = "mailto:?bcc=";
        let checkboxes = document.getElementsByClassName("emailCheckBox");
        let counter = 0;
        for (let checkbox of checkboxes) {
            let element = <HTMLInputElement>checkbox;
            let value = "";
            if (element.checked === true) {
                value = element.dataset.email;
                if (counter > 0) {
                    value = "" + value;
                }
                counter++;
            }
            if (value !== "") {
                href = href + value;
            }
        }
        if (href !== "mailto:?bcc=") {
            document.location.href = href;
        }
        else {
            alert("Please choose someone to email to.")
        }
    }

    static pagingSearch(pageToGoTo){
        let typeOfProgramElement = <HTMLInputElement>document.querySelector(".catsSystemProgramDataRadioField[data-save='typeofprogram']:checked");
        let typeValue = 0;
        if (typeOfProgramElement !== null)
            typeValue = parseInt(typeOfProgramElement.value);

        let cluster = <HTMLSelectElement>document.getElementById("CareerCluster");
        let clusterValue = 0;
        if (cluster !== null)
            clusterValue = parseInt(cluster.value);

        let programArea = <HTMLSelectElement>document.getElementById("ProgramArea");
        let programAreaValue = 0;
        if (programArea !== null)
            programAreaValue = parseInt(programArea.value);

        let cip = <HTMLSelectElement>document.getElementById("CipCode");
        let cipValue = 0;
        if (cip !== null)
            cipValue = parseInt(cip.value);

        let status = <HTMLSelectElement>document.getElementById("Status");
        let statusValue = 0;
        if (status !== null)
            statusValue = parseInt(status.value);

        let noTeacher = <HTMLInputElement>document.getElementById("noTeacherCheckbox");
        let noTeacherValue = false;
        if (noTeacher.checked)
            noTeacherValue = true;

        let noScope = <HTMLInputElement>document.getElementById("noScopeCheckbox");
        let noScopeValue = false;
        if (noScope.checked)
            noScopeValue = true;

        let noStudentCert = <HTMLInputElement>document.getElementById("noStudentCertCheckbox");
        let noStudentCertValue = false;
        if (noStudentCert.checked)
            noStudentCertValue = true;

        let noTeacherCert = <HTMLInputElement>document.getElementById("noTeacherIndCertCheckbox");
        let noTeacherCertValue = false;
        if (noTeacherCert.checked)
            noTeacherCertValue = true;

        let url = "/Admin/CatsEmail/";
        url += "?cipCodeFK=" + cipValue + "&typeOfProgram=" + typeValue + "&cluster=" + clusterValue + "&programArea=" + programAreaValue + "&statusPK=" + statusValue + "&noTeacher=" + noTeacherValue + "&noStudentIndCert=" + noStudentCertValue + "&noScope=" + noScopeValue + "&noTeacherIndCert=" + noTeacherCertValue + "&pageNumber=" + pageToGoTo;

        window.location.href = url;
    }
}

class DeleteCATSProgram {
    private _core: Core;

    constructor() {
        this._core = new Core();
        this._core.activateSubMenu("DeleteCATSProgram");

        let deleteButtons = document.getElementsByClassName("deleteProgram");
        for (let deleteButton of deleteButtons) {
            deleteButton.addEventListener("click", (e: Event) => this.showDeleteProgram(e));
        }

        let deleteProgramConfirmButton = document.getElementById("deleteProgramConfirm");
        if (deleteProgramConfirmButton !== null)
            deleteProgramConfirmButton.addEventListener("click", (e: Event) => this.deleteProgramConfirm(e));

        let deleteProgramCancelButton = document.getElementById("deleteProgramCancel");
        if (deleteProgramCancelButton !== null)
            deleteProgramCancelButton.addEventListener("click", (e: Event) => this.deleteProgramCancel());
    }

    showDeleteProgram(e: Event) {
        let buttonElement = <HTMLButtonElement>e.srcElement;
        let programPK = buttonElement.dataset.catsprogrampk;

        let modal: Modal = new Modal("deleteProgramModal", null);
        modal.addAttributeToElement("deleteProgramModal", "#deleteProgramConfirm", "catsprogrampk", programPK);
        modal.show();
    }

    deleteProgramConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.srcElement;
        let catsProgramPK = buttonElement.dataset.catsprogrampk;
        let tr = document.getElementById("tr_" + catsProgramPK)

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/DeleteProgramByCATSProgramPK', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    tr.remove();
                    Core.createHTMLAlert("alertMessageDiv", "Program successfully removed.", 'success', 3000, null);
                } else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the program. Please try again.", 'error', 3000, null);
                }

                Core.hideLoader();

                let modal: Modal = new Modal("deleteProgramModal", null);
                modal.hide();
            } else {
                let modal: Modal = new Modal("deleteProgramModal", null);
                modal.hide();

                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("cATSProgramPK=" + catsProgramPK);
    }

    deleteProgramCancel() {
        let modal: Modal = new Modal("deleteProgramModal", null);
        modal.hide();
    }
}


//CATSAnnualTargets
class CATSAnnualTargets {
    private _core: Core;
    constructor() {
        this._core = new Core();
        this._core.activateSubMenu("CATSAnnualTargets");
        this.bindIndSaveButtons();
    }

    bindIndSaveButtons() {
        let indSaveButtons = document.getElementsByClassName("saveAnnualTargets");
        for (let indSaveButton of indSaveButtons) {
            indSaveButton.addEventListener("click", (e: Event) => this.saveAnnualTargets(e));
        }
    }

    saveAnnualTargets(e: Event) {
        let core = this._core;
        //Core.showLoader();
        let button = <HTMLButtonElement>e.currentTarget;
        let yearPK = parseInt(button.dataset.yearpk);
        let allSaveElements = [];
        let row = document.getElementById("tr_" + yearPK);
        let allElements = row.getElementsByClassName("annualTargetInput");
        let today = new Date();

        for (let element of allElements) {
            let htmlElement = <HTMLInputElement>element;
            let catsAnnualStateTargetPK = parseInt(htmlElement.dataset.statetargetpk);
            let indicator = htmlElement.dataset.indicator;
            let percent = parseFloat(htmlElement.value);

            let saveObject: ISaveAnnualTarget = {
                CATSAnnualStateTargetPK: catsAnnualStateTargetPK,
                PerformanceIndicatorCode: indicator,
                AnnualStateTargetPercent: percent,
                AcademicYearFK: yearPK,
                ModifiedByUserName: "",
                ModifiedDate: today,
                IsActiveInd: true
            };
            allSaveElements.push(saveObject);
        }


        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SaveAnnualTargets', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, core.pageReload(true, null));
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(allSaveElements));
    }


}

class ViewDeletedPrograms {
    private _core: Core;

    constructor() {
        this._core = new Core();
        this._core.activateSubMenu("ViewDeletedPrograms");
    }
}

//CATS Lockout
class CATSLockout {
    private _core: Core;
    constructor() {
        this._core = new Core();
        this._core.activateSubMenu("CATSLockout");
        this.bindClearExceptions();

        const saveMainPlanButton = document.getElementById("saveMainPlan");
        if (saveMainPlanButton !== null)
            saveMainPlanButton.addEventListener("click", (e: Event) => this.savelockout(e));

        const saveProgramButton = document.getElementById("saveProgram");
        if (saveProgramButton !== null)
            saveProgramButton.addEventListener("click", (e: Event) => this.saveProgramlockout(e));
    }

    bindClearExceptions() {
        const clearConfirmButton = document.getElementById("clearConfirm");
        if (clearConfirmButton !== null)
            clearConfirmButton.addEventListener("click", (e: Event) => this.ClearExceptionsConfirm(e));

        const clearCancelButton = document.getElementById("clearCancel");
        if (clearCancelButton !== null)
            clearCancelButton.addEventListener("click", (e: Event) => this.ClearExceptionsCancel());

        const clearExceptionsButton = document.getElementById("clearExceptionsButton");
        if (clearExceptionsButton !== null)
            clearExceptionsButton.addEventListener("click", (e: Event) => this.showClearExceptions(e));

        const clearProgramExceptionsButton = document.getElementById("clearProgramExceptionsButton");
        if (clearProgramExceptionsButton !== null)
            clearProgramExceptionsButton.addEventListener("click", (e: Event) => this.showClearExceptions(e));
    }

    savelockout(e: Event) {
        let core = this._core;
        //Core.showLoader();
        let button = <HTMLButtonElement>e.currentTarget;
        let lockoutPK = parseInt(button.dataset.lockoutpk);
        let plantypePK = parseInt(button.dataset.plantypepk);

        let startDatePicker = <HTMLInputElement>document.getElementById("startDate_" + lockoutPK);
        let startDatePickerValue = startDatePicker.value;
        let startUTC = new Date(startDatePickerValue);
        let startDate = new Date(startUTC.getTime() + startUTC.getTimezoneOffset() * 60000);// this converts it back to the day it should be.

        let endDatePicker = <HTMLInputElement>document.getElementById("endDate_" + lockoutPK);
        let endDatePickerValue = endDatePicker.value;
        let endUTC = new Date(endDatePickerValue);
        let endDate = new Date(endUTC.getTime() + endUTC.getTimezoneOffset() * 60000);// this converts it back to the day it should be.

        let saveObject: ISavePlanTypeLockout = {
            PlanTypeLockoutPK: lockoutPK,
            PlanTypeFK: plantypePK,
            LockoutStartDate: startDate,
            LockoutEndDate: endDate,
            ModifiedByUserName: "",
            ModifiedDate: startDate,//fix this when it gets to the controller
            IsDeletedInd: false
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SaveCATSLockout', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, core.pageReload(true, null));
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));
    }

    saveProgramlockout(e: Event) {
        let core = this._core;
        //Core.showLoader();
        let button = <HTMLButtonElement>e.currentTarget;
        let lockoutPK = parseInt(button.dataset.lockoutpk);
        //let plantypePK = parseInt(button.dataset.plantypepk);

        let startDatePicker = <HTMLInputElement>document.getElementById("startDate_" + lockoutPK);
        let startDatePickerValue = startDatePicker.value;
        let startUTC = new Date(startDatePickerValue);
        let startDate = new Date(startUTC.getTime() + startUTC.getTimezoneOffset() * 60000);// this converts it back to the day it should be.

        let endDatePicker = <HTMLInputElement>document.getElementById("endDate_" + lockoutPK);
        let endDatePickerValue = endDatePicker.value;
        let endUTC = new Date(endDatePickerValue);
        let endDate = new Date(endUTC.getTime() + endUTC.getTimezoneOffset() * 60000);// this converts it back to the day it should be.

        let saveObject: ISavePlanTypeLockout = {
            PlanTypeLockoutPK: lockoutPK,
            PlanTypeFK: 291,
            LockoutStartDate: startDate,
            LockoutEndDate: endDate,
            ModifiedByUserName: "",
            ModifiedDate: startDate,//fix this when it gets to the controller
            IsDeletedInd: false
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SaveCATSLockout', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, core.pageReload(true, null));
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));
    }

    showClearExceptions(e: Event) {
        let buttonElement = <HTMLButtonElement>e.currentTarget;
        let checkboxID = buttonElement.dataset.lockoutid;
        let checkbox = <HTMLInputElement>document.getElementById(checkboxID);
        if (checkbox.checked) {
            let modal: Modal = new Modal("clearExceptionsModal", null);
            modal.show();
            modal.addAttributeToElement("clearExceptionsModal", "#clearConfirm", "lockoutid", checkboxID);
        }
        else {
            alert("If you want to clear all exceptions check the checkbox.");
        }
    }

    ClearExceptionsCancel() {
        let modal: Modal = new Modal("clearExceptionsModal", null);
        modal.hide();
    }

    ClearExceptionsConfirm(e: Event) {
        let button = <HTMLButtonElement>e.currentTarget;
        let checkboxID = button.dataset.lockoutid;
        let checkbox = <HTMLInputElement>document.getElementById(checkboxID);
        let plantypelockoutfk = parseInt(checkbox.dataset.plantypelockout);

        if (checkbox.checked) {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/Admin/ClearAllExceptionsByPlanTypeLockoutFK', true);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();

                    let modal: Modal = new Modal("clearExceptionsModal", null);
                    modal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "All Exceptions have been cleared.", 'success', 3000, null);
                } else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send("planTypeLockoutFK=" + plantypelockoutfk);
        }
    }
}

class UpdatePlanTypeFRCPPInstitution {
    private _core: Core;

    constructor() {
        this._core = new Core();
        //this._core.activateSubMenu("DeleteCATSProgram");

        let deleteButtons = document.getElementsByClassName("deletePlanTypeFRCPPInstitution");
        for (let deleteButton of deleteButtons) {
            deleteButton.addEventListener("click", (e: Event) => this.showDeletePlanTypeFRCPPInstitution(e));
        }

        let deleteProgramConfirmButton = document.getElementById("deletePlanTypeFRCPPInstitutionConfirm");
        if (deleteProgramConfirmButton !== null)
            deleteProgramConfirmButton.addEventListener("click", (e: Event) => this.deletePlanTypeFRCPPInstitutionConfirm(e));

        let deleteProgramCancelButton = document.getElementById("deletePlanTypeFRCPPInstitutionCancel");
        if (deleteProgramCancelButton !== null)
            deleteProgramCancelButton.addEventListener("click", (e: Event) => this.deletePlanTypeFRCPPInstitutionCancel());

        let searchButton = document.getElementById("SearchButton");
        if (searchButton !== null)
            searchButton.addEventListener("click", (e: Event) => this.search());

        let saveButton = document.getElementById("saveButton");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e: Event) => this.save(e));
    }

    search() {
        let selectElement = <HTMLSelectElement>document.getElementById("institutionNameSearch");
        let value = parseInt(selectElement.value);

        let url = "/Admin/UpdatePlanTypeFRCPPInstitution/";
        if (value !== 0)
            url += "?fRCPPInstitution=" + value;

        window.location.href = url;
    }

    save(e) {
        let that = this;
        let lea = <HTMLInputElement>document.getElementById("hiddenLea");
        let leaValue = parseInt(lea.value);
        let ptfi = <HTMLSelectElement>document.getElementById("planTypeSearch");
        let ptfiValue = parseInt(ptfi.value);

        let saveObject: IPlanTypeFRCPPInstitution = {
            PlanTypeFK: ptfiValue,
            FRCPPInstitutionFK: leaValue,
            PlanTypeFRCPPInstitutionRelationTypeCodeFK: 1
        };
        
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SavePlanTypeFRCPPInstitution', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, that._core.pageReload(true, null));
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));
    }

    showDeletePlanTypeFRCPPInstitution(e: Event) {
        let buttonElement = <HTMLButtonElement>e.srcElement;
        let planTypeFK = buttonElement.dataset.plantypefk;
        let fRCPPInstitutionFK = buttonElement.dataset.frcppinstitutionfk;

        let modal: Modal = new Modal("deletePlanTypeFRCPPInstitutionModal", null);
        modal.addAttributeToElement("deletePlanTypeFRCPPInstitutionModal", "#deletePlanTypeFRCPPInstitutionConfirm", "plantypefk", planTypeFK);
        modal.addAttributeToElement("deletePlanTypeFRCPPInstitutionModal", "#deletePlanTypeFRCPPInstitutionConfirm", "frcppinstitutionfk", fRCPPInstitutionFK);
        modal.show();
    }

    deletePlanTypeFRCPPInstitutionConfirm(e: Event) {
        let that = this;
        let buttonElement = <HTMLButtonElement>e.srcElement;
        let planTypeFK = parseInt(buttonElement.dataset.plantypefk);
        let fRCPPInstitutionFK = parseInt(buttonElement.dataset.frcppinstitutionfk);
        //let tr = document.getElementById("tr_" + planTypeFK)
        let saveObject: IPlanTypeFRCPPInstitution = {
            PlanTypeFK: planTypeFK,
            FRCPPInstitutionFK: fRCPPInstitutionFK,
            PlanTypeFRCPPInstitutionRelationTypeCodeFK: 1
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/DeletePlanTypeFRCPPInstitution', true);
        xhr.setRequestHeader('Content-type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    //tr.remove();
                    Core.createHTMLAlert("alertMessageDiv", "Program successfully removed.", 'success', 3000, that._core.pageReload(true, null));
                } else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the program. Please try again.", 'error', 3000, null);
                }

                Core.hideLoader();

                let modal: Modal = new Modal("deletePlanTypeFRCPPInstitutionModal", null);
                modal.hide();
            } else {
                let modal: Modal = new Modal("deletePlanTypeFRCPPInstitutionModal", null);
                modal.hide();

                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));
    }

    deletePlanTypeFRCPPInstitutionCancel() {
        let modal: Modal = new Modal("deletePlanTypeFRCPPInstitutionModal", null);
        modal.hide();
    }
}

//Create New Plans No Copying Data
class CreateNewPlansNoCopy {
    constructor() {
        let core = new Core();

        let saveButton = <HTMLButtonElement>document.getElementById("saveButton");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e: Event) => this.save(e));

        core.activateSubMenu("CreateNewPlansNoCopy");
    }



    save(e: Event) {
        let core = new Core();

        let saveObject: ISaveCreateNewPlansNoCopy;
        let plantype = <HTMLSelectElement>document.getElementById("createPlanTypeSelect");
        let planTypeFK = parseInt(plantype.value);
        let phase = <HTMLSelectElement>document.getElementById("createPhaseSelect");
        let phaseFK = parseInt(phase.value);
        let startYear = <HTMLSelectElement>document.getElementById("startYear");
        let startYearFK = parseInt(startYear.value);
        let endYear = <HTMLSelectElement>document.getElementById("endYear");
        let endYearFk = parseInt(endYear.value);
        let instCtgyCode = <HTMLInputElement>document.getElementById("createCategorySelect");
        let instCtgyCodeFk = instCtgyCode.value;
        let datepicker = document.getElementById("datePicker");
        let datePickerElement = <HTMLInputElement>datepicker;
        let date = datePickerElement.value;
        let utc = new Date(date);
        var dueDate = new Date(utc.getTime() + utc.getTimezoneOffset() * 60000);// this converts it back to the day it should be.

        if (planTypeFK > -1) {

            saveObject = {
                PlanTypeFK: planTypeFK,
                PhaseCodeFK: phaseFK,
                StartAcademicYearFK: startYearFK,
                EndAcademicYearFK: endYearFk,
                InstCtgyCode: instCtgyCodeFk,
                DueDate: dueDate
            };
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SaveCreateNewPlansNoCopy', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            let res = JSON.parse(xhr.responseText);
            if (res.success === true) {
                Core.createHTMLAlert("alertMessageDiv", "Total Inst:" + res.savesToBeDone + " Total Saves:" + res.completedSaves, 'success', 10000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));
    }
}

//Create New Plans for CATS
class CreateNewCATSPlans {
    constructor() {
        let core = new Core();

        let saveButton = <HTMLButtonElement>document.getElementById("saveButton");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e: Event) => this.save(e));

        core.activateSubMenu("CreateNewCATSPlans");
    }



    save(e: Event) {
        let core = new Core();

        let programYear = <HTMLSelectElement>document.getElementById("programYear");
        let programYearAcademicYearFK = parseInt(programYear.value);

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SaveNewCATSPlans', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            let res = JSON.parse(xhr.responseText);
            if (res.success === true) {
                Core.createHTMLAlert("alertMessageDiv", "Total Inst:" + res.savesToBeDone + " Total Saves:" + res.completedSaves, 'success', 10000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("programYearAcademicYearFK=" + programYearAcademicYearFK);
    }
}

class PlanAccessDetailReport {
    private _core: Core;

    constructor() {
        this._core = new Core();
        this._core.activateSubMenu("PlanAccessDetailReport");

        let saveButton = <HTMLButtonElement>document.getElementById("reportButton");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e: Event) => this.runReport(e));

        this._core.bindChangeLEA();
    }

    runReport(e: Event) {
        let button = <HTMLButtonElement>e.currentTarget;
        let leaFRCPPInstitutionFK = parseInt(button.dataset.leafrcppinstitutionfk);
        let url = "/Reports/PlanAccessDetails" + "?leaFRCPPInstitutionFK=" + leaFRCPPInstitutionFK;

        window.location.href = url;
    }
}

class SchoolExpenditureReport {
    private _core: Core;

    constructor() {
        this._core = new Core();
        this._core.activateSubMenu("SchoolExpenditureReport");

        let saveButton = <HTMLButtonElement>document.getElementById("reportButton");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e: Event) => this.runReport(e));

        this._core.bindChangeLEA();
    }

    runReport(e: Event) {
        let academicYearElement = <HTMLSelectElement>document.getElementById("academicYearFK");
        let academicYearElementValue = parseInt(academicYearElement.value);
        let url = "/Reports/LEASchoolPlanExpenditureTablesExport" + "?academicYearFK=" + academicYearElementValue;

        window.location.href = url;
    }
}

class PlanContactReport {
    private _core: Core;
    constructor() {
        this._core = new Core();

        this._core.activateSubMenu("PlanContactReport");

        let runReportButton = <HTMLButtonElement>document.getElementById("runReport");
        if (runReportButton !== null)
            runReportButton.addEventListener("click", (e: Event) => this.runReport(e));
    }

    runReport(e: Event) {
        let academicYearElement = <HTMLSelectElement>document.getElementById("startAcademicYear");
        let planTypes = <HTMLSelectElement>document.getElementById("planTypes");
        var hasAcademicYear = false;
        var hasPlanTypes = false;

        let url = "/Admin/PlanContactReportExport/?";

        if (academicYearElement != null && academicYearElement.selectedIndex > 0 && parseInt(academicYearElement.value)) {
            url = url + "startAcademicYearFK" + "=" + parseInt(academicYearElement.value) + "&";
            hasAcademicYear = true;
        }

        if (planTypes != null && planTypes.selectedIndex > 0 && planTypes.value) {
            url = url + "planTypePK" + "=" + parseInt(planTypes.value) + "&";
            hasPlanTypes = true;
        }

        if (hasAcademicYear && hasPlanTypes) {
            window.location.href = url;
        }
        else {
            alert("Please choose a start year and a plan type.");
        }
    }
}

//School Designation
class UpdateSchoolDesignation {
    constructor() {
        let core = new Core();

        this.bindIndSaveButtons();

        let schoolSearchButton = <HTMLButtonElement>document.getElementById("schoolSearchSearch");
        if (schoolSearchButton !== null)
            schoolSearchButton.addEventListener("click", (e: Event) => this.schoolSearch());

        core.activateSubMenu("UpdateSchoolDesignation");
    }

    bindIndSaveButtons() {
        let indSaveButtons = document.getElementsByClassName("saveIndSchoolAccess");
        for (let indSaveButton of indSaveButtons) {
            indSaveButton.addEventListener("click", (e: Event) => this.saveSchoolDesignation(e));
        }
    }

    schoolSearch() {
        let nameElement = <HTMLInputElement>document.getElementById("institutionNameSearch");

        let name = document.getElementsByTagName("input")[0].value;

        let url = "/Admin/UpdateSchoolDesignation/";
        if (name !== "")
            url += "?schoolName=" + name;

        window.location.href = url;
    }

    saveSchoolDesignation(e: Event) {
        let core = new Core();
        //Core.showLoader();
        let button = <HTMLButtonElement>e.currentTarget;
        let selectid = button.dataset.selectid;
        let elementInstitutionFK = parseInt(button.dataset.institutionfk);


        let saveObject: ISchoolDesignationSave;
        let selectElementValue = -1;
        let element = <HTMLSelectElement>document.getElementById(selectid);
        if (element !== null) {
            selectElementValue = parseInt(element.value);

            if (selectElementValue > -1 ) {

                saveObject = {
                    InstitutionFK: elementInstitutionFK,
                    DesignationTypeFK: selectElementValue
                };

            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SaveSchoolDesignation', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));
    }

}


//HomePage
class UpcomingDueDate {
    private _core: Core;
    constructor() {
        this._core = new Core();
        this._core.activateSubMenu("UpcomingDueDate");
        this.bindDeleteButtons();
        this.bindIndSaveButtons();
        let addNewRowButtons = document.getElementsByClassName("addAnotherPost");
        for (let addNewRowButton of addNewRowButtons) {
            addNewRowButton.addEventListener("click", (e: Event) => this.addAnotherPost(e));
        }

    }

    bindDeleteButtons() {
        let deleteButtons = document.getElementsByClassName("deleteRow");
        for (let deleteButton of deleteButtons) {
            deleteButton.addEventListener("click", (e: Event) => this.removeRow(e));
        }
    }

    bindIndSaveButtons() {
        let indSaveButtons = document.getElementsByClassName("saveupcomingduedate");
        for (let indSaveButton of indSaveButtons) {
            indSaveButton.addEventListener("click", (e: Event) => this.saveUpcomingDueDate(e));
        }
    }

    saveUpcomingDueDate(e: Event) {
        let core = this._core;
        //Core.showLoader();
        let button = <HTMLButtonElement>e.currentTarget;
        let upcomingDueDatePK = parseInt(button.dataset.upcomingduedatepk);
        let titleElement = <HTMLInputElement>document.getElementById("title_" + upcomingDueDatePK);
        let titleElementValue = titleElement.value;

        let dueDateDatePicker = <HTMLInputElement>document.getElementById("dueDate_" + upcomingDueDatePK);
        let dueDatePickerValue = dueDateDatePicker.value;
        let dueDateUTC = new Date(dueDatePickerValue);
        let dueDate = new Date(dueDateUTC.getTime() + dueDateUTC.getTimezoneOffset() * 60000);// this converts it back to the day it should be.

        let saveObject: ISaveUpcomingDueDate = {
            UpcomingDueDatePK: upcomingDueDatePK,
            DueDateTitle: titleElementValue,
            DueDate: dueDate,
            IsDeletedInd: false
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SaveUpcomingDueDate', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, core.pageReload(true, null));
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));
    }

    addAnotherPost(e: Event) {
        let core = new Core();
        let that = this;
        let button = <HTMLButtonElement>e.currentTarget;
        button.disabled = true;
        let tableName = button.dataset.tableid;
        let table = (<HTMLTableElement>document.getElementById(tableName));

        let xhr = new XMLHttpRequest();

        xhr.open('POST', '/Admin/AddUpcomingDueDate', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = table.querySelector("tbody");
                $(element).append(xhr.responseText);
                Core.createHTMLAlert("alertMessageDiv", "New Due Date Row Added", 'success', 3000, null);
                that.bindDeleteButtons();
                that.bindIndSaveButtons();
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send();
    }

    removeRow(e: Event) {
        let core = new Core();
        let button = <HTMLButtonElement>e.currentTarget;
        let upcomingDueDatePK = parseInt(button.dataset.upcomingduedatepk);
        let parent = button.parentElement.parentElement; //To the TR
        let xhr = new XMLHttpRequest();

        xhr.open('POST', '/Admin/DeleteUpcomingDueDateByUpcomingDueDatePK', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                parent.parentElement.removeChild(parent);
                Core.createHTMLAlert("alertMessageDiv", "Row Successfully Removed", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("upcomingDueDatePK=" + upcomingDueDatePK);

    }

}


//Create New Plan
class CreateNewPlan {
    constructor() {
        let core = new Core();

        this.bindAutocompletes();

        let saveButton = <HTMLButtonElement>document.getElementById("saveButton");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e: Event) => this.save(e));
        core.activateSubMenu("createnewplan");
    }


    bindAutocompletes() {
        let hidden = <HTMLInputElement>document.getElementById("hiddenlealist");
        let hiddenValue = hidden.value;
        let thisarray = new Array();
        thisarray = hiddenValue.split(',');
        let autocompletes = document.getElementsByClassName("institutionAutocomplete");
        for (let autocomplete of autocompletes) {
            let ac = <HTMLInputElement>autocomplete;
            let row = ac.dataset.rownumber;
            let tableid = ac.dataset.tableid
            this.autocomplete(autocomplete, thisarray, row, tableid);
        }
    }


    save(e: Event) {
        let core = new Core();

        let saveObject: ISaveCreateNewPlan;
        let plantype = <HTMLSelectElement>document.getElementById("plantypeSelect");
        let planTypeFK = parseInt(plantype.value);
        let lea = <HTMLSelectElement>document.getElementById("institutionNameSearch");
        let leaString = lea.value;
        let school = <HTMLSelectElement>document.getElementById("schoolSelect");
        let schoolFK = parseInt(school.value);
        let startYear = <HTMLSelectElement>document.getElementById("selectStartAcademicYear");
        let startYearFK = parseInt(startYear.value);
        let endYear = <HTMLSelectElement>document.getElementById("selectEndAcademicYear");
        let endYearFk = parseInt(endYear.value);
        let datepicker = document.getElementById("datePicker");
        let datePickerElement = <HTMLInputElement>datepicker;
        let date = datePickerElement.value;
        let utc = new Date(date);
        var dueDate = new Date(utc.getTime() + utc.getTimezoneOffset() * 60000);// this converts it back to the day it should be.

        if (planTypeFK > -1 && startYearFK > -1 && endYearFk > -1) {

            saveObject = {
                PlanTypeFK: planTypeFK,
                LEAName: leaString,
                SchoolFK: schoolFK,
                StartAcademicYearFK: startYearFK,
                EndAcademicYearFK: endYearFk,
                DueDate: dueDate
            };
        }
        else {
            alert("You need to pick a PlanType and a start and end year!");
            return false;
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SaveCreateNewPlan', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Plan Created", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));
    }


    autocomplete(inp, arr, row, tableid) {

        /*the autocomplete function takes two arguments,
        the text field element and an array of possible autocompleted values:*/
        var currentFocus;
        let that = this;
        /*execute a function when someone writes in the text field:*/

        inp.addEventListener("input", function (e) {
            var a, b, i, val = this.value;

            /*close any already open lists of autocompleted values*/
            closeAllLists(e);

            //if (!val) { return false; }
            if (!val) { that.resetDropdown(row, tableid) }
            currentFocus = -1;
            /*create a DIV element that will contain the items (values):*/
            a = document.createElement("DIV");
            a.setAttribute("id", this.id + "autocomplete-list");
            a.setAttribute("class", "autocomplete-items");
            /*append the DIV element as a child of the autocomplete container:*/
            this.parentNode.appendChild(a);
            /*for each item in the array...*/
            for (i = 0; i < arr.length; i++) {
                /*check if the item starts with the same letters as the text field value:*/
                if (arr[i].substr(0, val.length).toUpperCase() == val.toUpperCase()) {
                    /*create a DIV element for each matching element:*/
                    b = document.createElement("DIV");
                    /*make the matching letters bold:*/
                    b.innerHTML = "<strong>" + arr[i].substr(0, val.length) + "</strong>";
                    b.innerHTML += arr[i].substr(val.length);
                    /*insert a input field that will hold the current array item's value:*/
                    b.innerHTML += "<input type='hidden' value='" + arr[i] + "'>";
                    /*execute a function when someone clicks on the item value (DIV element):*/
                    b.addEventListener("click", function (e) {
                        /*insert the value for the autocomplete text field:*/
                        inp.value = this.getElementsByTagName("input")[0].value;
                        /*close the list of autocompleted values,
                        (or any other open lists of autocompleted values:*/
                        closeAllLists(e);
                        that.getListOfSchools(e, row, tableid);
                    });
                    a.appendChild(b);
                }
            }
        });
        /*execute a function presses a key on the keyboard:*/
        inp.addEventListener("keydown", function (e) {
            let that = this;
            let x = document.getElementById(this.id + "autocomplete-list");
            let y: any;
            if (x) y = x.getElementsByTagName("div");
            if (e.keyCode == 40) {
                /*If the arrow DOWN key is pressed,
                increase the currentFocus variable:*/
                currentFocus++;
                /*and and make the current item more visible:*/
                addActive(y);
            } else if (e.keyCode == 38) { //up
                /*If the arrow UP key is pressed,
                decrease the currentFocus variable:*/
                currentFocus--;
                /*and and make the current item more visible:*/
                addActive(y);
            } else if (e.keyCode == 13) {
                /*If the ENTER key is pressed, prevent the form from being submitted,*/
                e.preventDefault();
                if (currentFocus > -1) {
                    /*and simulate a click on the "active" item:*/
                    if (y) {
                        y[currentFocus].click();
                    }
                }
            }
        });

        function addActive(x) {
            /*a function to classify an item as "active":*/
            if (!x) return false;
            /*start by removing the "active" class on all items:*/
            removeActive(x);
            if (currentFocus >= x.length) currentFocus = 0;
            if (currentFocus < 0) currentFocus = (x.length - 1);
            /*add class "autocomplete-active":*/
            x[currentFocus].classList.add("autocomplete-active");
        }
        function removeActive(x) {
            /*a function to remove the "active" class from all autocomplete items:*/
            for (var i = 0; i < x.length; i++) {
                x[i].classList.remove("autocomplete-active");
            }
        }
        function closeAllLists(elmnt) {
            /*close all autocomplete lists in the document,
            except the one passed as an argument:*/
            var x = document.getElementsByClassName("autocomplete-items");
            for (var i = 0; i < x.length; i++) {
                if (elmnt != x[i] && elmnt != inp) {
                    x[i].parentNode.removeChild(x[i]);
                }
            }
        }
        /*execute a function when someone clicks in the document:*/
        document.addEventListener("click", function (e) {
            closeAllLists(e.target);
        });
    }

    getListOfSchools(e: Event, row, tableid) {
        let core = new Core();
        let dropdown = <HTMLDivElement>e.target;
        let parent = dropdown.parentElement;
        let parentid = parent.id;
        let name = encodeURIComponent(dropdown.textContent);
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/GetSchoolList', true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                //Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, null);
                let res = JSON.parse(xhr.responseText);
                if (res.success === true) {

                    let thisSelect = <HTMLSelectElement>document.getElementById("schoolSelect");

                    let mintoRemove = 0;
                    let maxToRemove = 0;
                    for (let option of thisSelect.options) {
                        let length = thisSelect.options.length
                        let thisOption = <HTMLOptionElement>option;
                        let data = thisOption.dataset;
                        data = JSON.parse(JSON.stringify(data));
                        let index = thisOption.index;
                        let isOriginal = data.isoriginal;
                        if (isOriginal === "False") {
                            maxToRemove = length - 1;
                            mintoRemove = index;
                            break;
                        }
                    }

                    if (maxToRemove > 0) {
                        while (mintoRemove < (maxToRemove + 1)) {
                            thisSelect.options.remove(maxToRemove);//want to remove the highest one first
                            maxToRemove--;
                        }
                    }

                    for (let inst of res.relatedInstList) {
                        let x = document.createElement("option");
                        x.setAttribute("value", inst.relatedInstitutionPK);
                        x.setAttribute("data-isadmin", "False");
                        x.setAttribute("data-isschool", "True");
                        x.setAttribute("data-wasselected", "False");
                        x.setAttribute("data-isoriginal", "False");
                        let t = document.createTextNode(inst.relatedInstitutionName);
                        x.appendChild(t);
                        thisSelect.appendChild(x);
                    }
                }
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("name=" + name);
    }

    resetDropdown(row, tableid) {
        let tableName = tableid;
        let table = (<HTMLTableElement>document.getElementById(tableName));
        let selects = table.getElementsByTagName("select");
        let thisSelect = <HTMLSelectElement>selects[0];
        for (let select of selects) {
            if (select.id === ("'select'" + row)) {
                thisSelect = <HTMLSelectElement>select;
            }
        }
        let mintoRemove = 0;
        let maxToRemove = 0;
        for (let option of thisSelect.options) {
            let length = thisSelect.options.length
            let thisOption = <HTMLOptionElement>option;
            let data = thisOption.dataset;
            data = JSON.parse(JSON.stringify(data));
            let index = thisOption.index;
            let isOriginal = data.isoriginal;
            if (isOriginal === "False") {
                maxToRemove = length - 1;
                mintoRemove = index;
                break;
            }
        }

        if (maxToRemove > 0) {
            while (mintoRemove < (maxToRemove + 1)) {
                thisSelect.options.remove(maxToRemove);//want to remove the highest one first
                maxToRemove--;
            }
        }

        let options = thisSelect.options;
        for (let option of options) {
            if (option.dataset.isadmin == "True") {
                option.disabled = false;
            }
            else {
                option.disabled = true;
            }
        }
        thisSelect.selectedIndex = -1;
        return false;
    }
}