/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />
/// <reference path="../../../node_modules/foundation-sites/js/typescript/foundation.d.ts"/>

// Profile and Plan Essentials
class SchoolReconfigurationsProfile {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["schoolReconfigurationsProfileField", "schoolReconfigurationsProfileSelectField", "schoolReconfigurationsProfileCheckboxField"];

        this.checkNewHire();

        let specialEducationProfileSaveButton = document.getElementById("schoolReconfigurationsProfileSave");
        if (specialEducationProfileSaveButton !== null)
            specialEducationProfileSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        let educatorEffectivenessExecutiveSaveButton = document.getElementById("ExecutiveSave");
        if (educatorEffectivenessExecutiveSaveButton !== null)
            educatorEffectivenessExecutiveSaveButton.addEventListener("click", (e: Event) => this.executiveSave("save"));

        this._core.leftnav(this);

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let refreshButton = document.getElementById("resetProfile");
        if (refreshButton !== null)
            refreshButton.addEventListener("click", (e: Event) => Core.refreshProfile("schoolReconfigurationsProfileForm"));

        const schoolReconfigurationsNewHireCheckbox = document.querySelector(".schoolReconfigurationsNewHire");
        if (schoolReconfigurationsNewHireCheckbox !== null)
            schoolReconfigurationsNewHireCheckbox.addEventListener("change", (e: Event) => this.checkNewHire());

        this.bindDeleteFiles();

        let deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e: Event) => this.deleteFileConfirm(e));

        let deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e: Event) => this.deleteFileCancel());

        const fileUploader = document.getElementById("reconfigurationProfileCEODocumentation");
        if (fileUploader !== null)
            fileUploader.addEventListener("change", (e: Event) => this.uploadFile(<HTMLInputElement>e.target));
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];

        let planPK = 0;

        let profileFormElement = <HTMLDivElement>document.getElementById("schoolReconfigurationsProfileForm");
        const templateCode = profileFormElement.dataset.templatecode;

        let inputs = document.getElementsByClassName("schoolReconfigurationsProfileField");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let checkboxes = document.getElementsByClassName("schoolReconfigurationsProfileCheckboxField");
        for (let ele of checkboxes) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.checked || hadValue) {
                let val = "";
                if (element.checked)
                    val = "on";
                else
                    val = "off";

                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let selects = document.getElementsByClassName("schoolReconfigurationsProfileSelectField");
        for (let ele of selects) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);

            let element = <HTMLSelectElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: "",
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        const allFileElements = [];

        let files = document.getElementsByClassName("schoolReconfigurationsProfileFileUploadField");
        for (let ele of files) {
            planPK = parseInt(profileFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;

            let sequenceNbr = parseInt(element.dataset.sequencenbr);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk)
            let fileUploadPK = parseInt(element.dataset.fileuploadpk);
            let propertyPK = parseInt(element.dataset.propertypk);

            let saveItem: IPlanPropertyFile = {
                SequenceNbr: sequenceNbr,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanPropertyFilePK: planPropertyFilePK,
                PlanFK: planPK
            };

            allFileElements.push(saveItem);
        }

        var allData = {
            "SaveData": allSaveElements,
            "TemplateCode": templateCode,
            "FileData": allFileElements
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SchoolReconfigurations/SaveSchoolReconfigurationsProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }

    executiveSave(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];

        let planPK = 0;

        let profileFormElement = <HTMLDivElement>document.getElementById("schoolReconfigurationsProfileForm");
        const templateCode = profileFormElement.dataset.templatecode;

        let inputs = document.getElementsByClassName("profileICDataSave");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        const allFileElements = [];

        var allData = {
            "SaveData": allSaveElements,
            "TemplateCode": templateCode,
            "FileData": allFileElements
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SchoolReconfigurations/SaveSchoolReconfigurationsProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }

    checkNewHire() {
        const schoolReconfigurationsNewHireCheckbox = <HTMLInputElement>document.querySelector(".schoolReconfigurationsNewHire");

        const reconfigurationProfileChiefAdministratorName = <HTMLInputElement>document.getElementById("reconfigurationProfileChiefAdministratorName");
        const reconfigurationProfileChiefAdministratorEmail = <HTMLInputElement>document.getElementById("reconfigurationProfileChiefAdministratorEmail");
        const reconfigurationProfileNewChiefAdministratorName = <HTMLInputElement>document.getElementById("reconfigurationProfileNewChiefAdministratorName");
        const reconfigurationProfileNewChiefAdministratorEmail = <HTMLInputElement>document.getElementById("reconfigurationProfileNewChiefAdministratorEmail");

        if (reconfigurationProfileChiefAdministratorName !== null && reconfigurationProfileChiefAdministratorEmail !== null && reconfigurationProfileNewChiefAdministratorName !== null && reconfigurationProfileNewChiefAdministratorEmail !== null) {
            if (schoolReconfigurationsNewHireCheckbox.checked) {
                this._core.forceElementOptional(reconfigurationProfileChiefAdministratorName);
                this._core.forceElementOptional(reconfigurationProfileChiefAdministratorEmail);

                this._core.forceElementRequired(reconfigurationProfileNewChiefAdministratorName);
                this._core.forceElementRequired(reconfigurationProfileNewChiefAdministratorEmail);
            } else {
                this._core.forceElementRequired(reconfigurationProfileChiefAdministratorName);
                this._core.forceElementRequired(reconfigurationProfileChiefAdministratorEmail);

                this._core.forceElementOptional(reconfigurationProfileNewChiefAdministratorName);
                this._core.forceElementOptional(reconfigurationProfileNewChiefAdministratorEmail);
            }
        }
    }

    uploadFile(e: HTMLInputElement) {
        let core = this._core;
        Core.showLoader();
        let formName = e.dataset.formname;

        let row = e.dataset.row;

        const that = this;

        let uploadSampleForm = <HTMLFormElement>document.getElementById(formName);
        let formData = new FormData(uploadSampleForm);

        let propertyPK = e.dataset.propertypk;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SchoolReconfigurations/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();

                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);

                        let formFile = <HTMLInputElement>document.querySelector(`.schoolReconfigurationsProfileFileField[data-formname='${formName}']`);
                        formFile.value = "";

                        let fileList = document.querySelector(`.uploadFileList`)

                        let currentFileList = fileList.querySelectorAll(`.uploadFileList`);

                        let sequence = -1;
                        for (let file of currentFileList) {
                            let fileEle = <HTMLDivElement>file;

                            let thisSequence = parseInt(fileEle.dataset.sequencenbr);
                            if (thisSequence > sequence) {
                                sequence = thisSequence;
                            }
                        }

                        let uploadedContainer = document.querySelector(`#uploadedFiles`);
                        uploadedContainer.classList.remove("hide");

                        sequence++;

                        let fileWrapper = <HTMLDivElement>document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = <HTMLDivElement>document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = <HTMLAnchorElement>document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.row = row;
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.sequencenbr = sequence.toString();
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.classList.add("schoolReconfigurationsProfileFileUploadField");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);

                        fileList.appendChild(fileWrapper);

                        that.bindDeleteFiles();
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }

    deleteFileConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SchoolReconfigurations/DeleteFileData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    let element = document.querySelector(`.schoolReconfigurationsProfileFileField[data-planpropertyfilepk='${planPropertyFilePK}']`);
                    let parent = element.parentElement.parentElement;
                    parent.remove();

                    const moreFiles = document.querySelectorAll(`.uploadFileList .uploadFileColumn`);
                    if (moreFiles.length === 0) {
                        const uploadsElement = <HTMLDivElement>document.getElementById(`uploadedFiles`);

                        uploadsElement.classList.add("hide");
                    }

                    Core.createHTMLAlert("alertMessageDiv", "File successfully removed.", 'success', 3000, null);
                } else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the file. Please try again.", 'error', 3000, null);
                }

                Core.hideLoader();

                let modal: Modal = new Modal("deleteFileModal", null);
                modal.hide();
            } else {
                let modal: Modal = new Modal("deleteFileModal", null);
                modal.hide();

                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planPropertyFilePK));
    }

    deleteFileCancel() {
        let modal: Modal = new Modal("deleteFileModal", null);
        modal.hide();
    }

    bindDeleteFiles() {
        let deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (let deleteFileButton of deleteFileButtons)
            deleteFileButton.addEventListener("click", (e: Event) => this.showDeleteFile(e));
    }

    showDeleteFile(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let row = buttonElement.dataset.row;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;

        let modal: Modal = new Modal("deleteFileModal", null);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "planpropertyfilepk", planPropertyFilePK);
        modal.show();
    }
}

// Reconfiguration Details
class SchoolReconfigurationsDetail {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["schoolReconfigurationsDetailField", "schoolReconfigurationsDetailSelectField", "schoolReconfigurationsDetailCheckboxField"];

        let specialEducationProfileSaveButton = document.getElementById("schoolReconfigurationsDetailSave");
        if (specialEducationProfileSaveButton !== null)
            specialEducationProfileSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this.onlyTelephoneChange();

        this._core.leftnav(this);

        this.intializeCheckboxes();

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            let showMessage = this.initializeRequiredFieldsCustom();
            this._core.doValidation(this.validationClasses, showMessage);
        }

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let modalCloseButton = <HTMLButtonElement>document.getElementById("reconfigurationDetailsNoticeClose");
        if (modalCloseButton !== null)
            modalCloseButton.addEventListener("click", (e: Event) => this.closeModal());

        let createNewReconfiguration = <HTMLButtonElement>document.getElementById("schoolReconfigurationsCreate");
        if (createNewReconfiguration !== null)
            createNewReconfiguration.addEventListener("click", (e: Event) => this.createNewReconfiguration());

        let schoolReconfigurationsAddMailingAddressOriginalButtons = document.getElementsByClassName("schoolReconfigurationsAddMailingAddressOriginal");
        for (let schoolReconfigurationsAddMailingAddressOriginalButton of schoolReconfigurationsAddMailingAddressOriginalButtons)
            schoolReconfigurationsAddMailingAddressOriginalButton.addEventListener("click", (e: Event) => this.addMailingAddress(e));

        let schoolReconfigurationsAddMailingAddressProposedButtons = document.getElementsByClassName("schoolReconfigurationsAddMailingAddressProposed");
        for (let schoolReconfigurationsAddMailingAddressProposedButton of schoolReconfigurationsAddMailingAddressProposedButtons)
            schoolReconfigurationsAddMailingAddressProposedButton.addEventListener("click", (e: Event) => this.addMailingAddress(e));

        let schoolReconfigurationsAddPhysicalAddressOriginalButtons = document.getElementsByClassName("schoolReconfigurationsAddPhysicalAddressOriginal");
        for (let schoolReconfigurationsAddPhysicalAddressOriginalButton of schoolReconfigurationsAddPhysicalAddressOriginalButtons)
            schoolReconfigurationsAddPhysicalAddressOriginalButton.addEventListener("click", (e: Event) => this.addPhysicalAddress(e));

        let schoolReconfigurationsAddPhysicalAddressProposedButtons = document.getElementsByClassName("schoolReconfigurationsAddPhysicalAddressProposed");
        for (let schoolReconfigurationsAddPhysicalAddressProposedButton of schoolReconfigurationsAddPhysicalAddressProposedButtons)
            schoolReconfigurationsAddPhysicalAddressProposedButton.addEventListener("click", (e: Event) => this.addPhysicalAddress(e));

        let deleteFileConfirmButton = <HTMLButtonElement>document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e: Event) => this.deleteFileConfirm(deleteFileConfirmButton));

        let deleteFileCancelButton = <HTMLButtonElement>document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e: Event) => this.deleteFileCancel());

        let deleteFileButtons = document.getElementsByClassName("deleteFile") as HTMLCollectionOf<HTMLButtonElement>;
        for (let deleteButton of deleteFileButtons)
            deleteButton.addEventListener("click", (e: Event) => this.deleteFile(e));


        let formForNotice = <HTMLDivElement>document.getElementById("schoolReconfigurationsDetailForm");
        if (formForNotice.dataset.shownotice === "true") {
            let noticeModal = new Modal("noticeModal", null);
            noticeModal.show();
        }

        let exportToExcelButton = document.getElementById("schoolReconfigurationsDetailExportToExcel");
        if (exportToExcelButton != null) {
            exportToExcelButton.addEventListener("click", () => {
                Core.showLoader();
                this.exportToExcel()
                    .then((response) => {
                        Core.hideLoader();
                    })
                    .catch((error) => {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                    });
            });
        }

        this.bindCheckboxes();
        this.bindUploadButtons();
        this.bindSchoolNameSearch();
        this.bindAddEnrollmentButtons();
        this.bindAddEnrollmentChecks();

        this.bindDeleteSchoolReconfigurationButtons();

        let deleteReconfigurationCancelButton = document.getElementById("deleteReconfigurationCancel");
        if (deleteReconfigurationCancelButton !== null)
            deleteReconfigurationCancelButton.addEventListener("click", (e: Event) => this.deleteReconfigurationCancel());

        let deleteReconfigurationConfirmButton = document.getElementById("deleteReconfigurationConfirm");
        if (deleteReconfigurationConfirmButton !== null)
            deleteReconfigurationConfirmButton.addEventListener("click", (e: Event) => this.deleteReconfigurationConfirm(e));

        let reconfigurationDetailsNoReconfigurationsCheckbox = document.getElementById("reconfigurationdetailsnoreconfigurations");
        if (reconfigurationDetailsNoReconfigurationsCheckbox !== null)
            reconfigurationDetailsNoReconfigurationsCheckbox.addEventListener("click", (e: Event) => this.noReconfigurations())

        let deleteReconfigurationEnrollmentCancelButton = document.getElementById("deleteEnrollmentCancel");
        if (deleteReconfigurationEnrollmentCancelButton !== null)
            deleteReconfigurationEnrollmentCancelButton.addEventListener("click", (e: Event) => this.deleteEnrollmentRowCancel());

        let deleteReconfigurationEnrollmentConfirmButton = document.getElementById("deleteEnrollmentConfirm");
        if (deleteReconfigurationEnrollmentConfirmButton !== null)
            deleteReconfigurationEnrollmentConfirmButton.addEventListener("click", (e: Event) => this.deleteEnrollmentRowConfirm(e));

        this.noReconfigurations();

        this.bindDeleteSchoolEnrollmentButtons();

        this.bindDeleteButtons();
        this.bindReconfigurationPrincipalFileDeletes();
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    intializeCheckboxes() {
        let reconfigurationDetailsNoReconfigurationsCheckbox = <HTMLInputElement>document.getElementById("reconfigurationdetailsnoreconfigurations");

        if (reconfigurationDetailsNoReconfigurationsCheckbox && !reconfigurationDetailsNoReconfigurationsCheckbox.checked) {

            let allCheckboxes = document.getElementsByClassName("schoolReconfigurationsDetailCheckboxField");

            for (let checkbox of allCheckboxes) {
                let element = <HTMLInputElement>checkbox;
                let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);

                if (element.classList.contains("schoolReconfigurationsDetailAddressCheckboxField")) {
                    let proposed = element.dataset.proposed;
                    if (proposed === "mailing") {
                        let tabElement = <HTMLLIElement>document.querySelector(`.mailingAddressProposedTab[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);

                        if (element.checked) {
                            tabElement.classList.remove("hide");

                            let inputElements = document.querySelectorAll("#mailingAddressProposed input");
                            for (let input of inputElements) {
                                let ele = <HTMLInputElement>input;

                                if (!ele.classList.contains("schoolReconfigurationsDetailAddressLine2Field"))
                                    this._core.forceElementRequired(ele);
                                else
                                    this._core.forceElementOptional(ele);

                            }

                            let mailingContentElement = <HTMLDivElement>document.querySelector(`.mailingAddressOriginalContent[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                            if (mailingContentElement.classList.contains("hide"))
                                mailingContentElement.classList.remove("hide");

                            let toBeRequiredElements = document.querySelectorAll(`.canBeRequired[data-group='${proposed}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                            for (let reqElement of toBeRequiredElements) {
                                let ele = <HTMLElement>reqElement
                                this._core.forceElementRequired(ele)
                            }
                        } else {
                            tabElement.classList.add("hide");

                            let mailingContentElement = <HTMLDivElement>document.querySelector(`.mailingAddressOriginalContent[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                            if (!mailingContentElement.classList.contains("hide"))
                                mailingContentElement.classList.add("hide");

                            let toBeRequiredElementsNR = document.querySelectorAll(`.canBeRequired[data-group='${proposed}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                            for (let reqElement of toBeRequiredElementsNR) {
                                let ele = <HTMLElement>reqElement
                                this._core.forceElementOptional(ele)
                            }
                        }
                    } else if (proposed === "physical") {
                        let physicalTabElement = <HTMLLIElement>document.querySelector(`.physicalAddressProposedTab[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`); //There will only be 1 here.

                        if (element.checked) {
                            physicalTabElement.classList.remove("hide");

                            let inputElements = document.querySelectorAll(`.physicalAddressProposed[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'] input`);
                            for (let input of inputElements) {
                                let ele = <HTMLInputElement>input;

                                if (!ele.classList.contains("schoolReconfigurationsDetailAddressLine2Field"))
                                    this._core.forceElementRequired(ele);
                                else
                                    this._core.forceElementOptional(ele);
                            }

                            let physicalContentElement = <HTMLDivElement>document.querySelector(`.physicalAddressOriginalContent[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                            if (physicalContentElement.classList.contains("hide"))
                                physicalContentElement.classList.remove("hide");

                            let toBeRequiredElements = document.querySelectorAll(`.canBeRequired[data-group='${proposed}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                            for (let reqElement of toBeRequiredElements) {
                                let ele = <HTMLElement>reqElement
                                this._core.forceElementRequired(ele)
                            }
                        } else {
                            if (physicalTabElement)
                                physicalTabElement.classList.add("hide");

                            let physicalContentElement = <HTMLDivElement>document.querySelector(`.physicalAddressOriginalContent[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                            if (!physicalContentElement.classList.contains("hide"))
                                physicalContentElement.classList.add("hide");

                            let toBeRequiredElementsNR = document.querySelectorAll(`.canBeRequired[data-group='${proposed}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                            for (let reqElement of toBeRequiredElementsNR) {
                                let ele = <HTMLElement>reqElement
                                this._core.forceElementOptional(ele)
                            }
                        }
                    } else if (proposed === "same") {
                        let container = <HTMLDivElement>document.querySelector(`.physicalAddressContainer[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);

                        let elements = container.querySelectorAll("input");

                        if (element.checked) {
                            for (let ele of elements) {
                                ele.disabled = true;
                                ele.readOnly = true;
                                this._core.forceElementOptional(ele);
                            }

                            let buttons = container.querySelectorAll("button");
                            for (let ele of buttons) {
                                ele.disabled = true;
                            }
                        } else {

                            let buttons = container.querySelectorAll("button");
                            for (let ele of buttons) {
                                if (ele.disabled)
                                    ele.disabled = false;
                            }
                        }
                    }
                } else {
                    if (element.checked) {
                        let proposedType = element.dataset.proposed;
                        let proposedElementBox = document.querySelector(`.proposed-change[data-proposed='${proposedType}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);

                        if (proposedElementBox !== null) {
                            if (element.checked) {
                                proposedElementBox.classList.remove("hide");

                                let allInputs = proposedElementBox.querySelectorAll(`.proposed-change[data-proposed='${proposedType}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}'] input`);
                                for (let input of allInputs) {
                                    let inputEle = <HTMLElement>input;
                                    if (!inputEle.classList.contains("schoolReconfigurationsDetailAddressLine2Field") && !inputEle.classList.contains("notForced"))
                                        this._core.forceElementRequired(inputEle);
                                    else
                                        this._core.forceElementOptional(inputEle);
                                }

                                let allSelects = proposedElementBox.querySelectorAll(`.proposed-change[data-proposed='${proposedType}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}'] select`);
                                for (let select of allSelects) {
                                    let selectEle = <HTMLElement>select;
                                    this._core.forceElementRequired(selectEle);
                                }
                            }
                            else {
                                proposedElementBox.classList.add("hide");
                                proposedElementBox.classList.remove("hide");

                                let allInputs = proposedElementBox.querySelectorAll(`.proposed-change[data-proposed='${proposedType}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}'] input`);
                                for (let input of allInputs) {
                                    let inputEle = <HTMLElement>input;

                                    this._core.forceElementOptional(inputEle);
                                }

                                let allSelects = proposedElementBox.querySelectorAll(`.proposed-change[data-proposed='${proposedType}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}'] select`);
                                for (let select of allSelects) {
                                    let selectEle = <HTMLElement>select;
                                    this._core.forceElementOptional(selectEle);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    async toggleProposed(e: Event) {
        let checkbox = <HTMLInputElement>e.target;
        let dataPointPlanPropertyPK = checkbox.dataset.datapointplanpropertypk;

        let proposed = checkbox.dataset.proposed;
        let proposedElement = document.querySelector(`.proposed-change[data-proposed='${proposed}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);

        if (checkbox.checked) {
            proposedElement.classList.remove("hide");

            let toBeRequiredElements = document.querySelectorAll(`.canBeRequired[data-group='${proposed}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
            for (let reqElement of toBeRequiredElements) {
                let ele = <HTMLElement>reqElement
                this._core.forceElementRequired(ele)
            }

            if (proposed === "principal") {
                const institutionPK = checkbox.dataset.institutionpk;

                let response = await fetch(`/SchoolReconfigurations/GetICInfo/${institutionPK}`, { credentials: 'include' })
                if (response.ok) {
                    const output = await response.json();

                    const nameElement = <HTMLInputElement>document.querySelector(`.currentPrincipal[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                    nameElement.value = output.principalName;
                }
            } else if (proposed == "telephone") {
                const institutionPK = checkbox.dataset.institutionpk;

                let response = await fetch(`/SchoolReconfigurations/GetICInfo/${institutionPK}`, { credentials: 'include' })
                if (response.ok) {
                    const output = await response.json();

                    const phoneElement = <HTMLInputElement>document.querySelector(`.currentPhoneNumber[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                    phoneElement.value = output.phone;
                }
            }
        } else {
            proposedElement.classList.add("hide");

            let toBeRequiredElements = document.querySelectorAll(`.canBeRequired[data-group='${proposed}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
            for (let reqElement of toBeRequiredElements) {
                let ele = <HTMLElement>reqElement
                this._core.forceElementOptional(ele)
            }
        }

        if (proposed === "school") {
            let pdeSection = document.querySelector(".school-reconfiguration-section[data-special='pde']");

            if (pdeSection) {
                if (checkbox.checked)
                    pdeSection.classList.remove("hidden");
                else
                    pdeSection.classList.add("hidden");
            }
        }
    }

    toggleMailingProposed(e: Event) {
        let checkboxElement = <HTMLInputElement>e.target;
        let dataPointPlanPropertyPK = checkboxElement.dataset.datapointplanpropertypk;

        let proposed = checkboxElement.dataset.proposed;
        if (proposed === "mailing") {
            let tabElement = <HTMLLIElement>document.querySelector(`.mailingAddressProposedTab[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);

            if (tabElement.classList.contains("hide"))
                tabElement.classList.remove("hide");
            else
                tabElement.classList.add("hide");

            let mailingContentElement = <HTMLDivElement>document.querySelector(`.mailingAddressOriginalContent[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
            if (mailingContentElement.classList.contains("hide")) {
                mailingContentElement.classList.remove("hide");

                let toBeRequiredElements = document.querySelectorAll(`.canBeRequired[data-group='${proposed}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                for (let reqElement of toBeRequiredElements) {
                    let ele = <HTMLElement>reqElement
                    this._core.forceElementRequired(ele)
                }

            } else {
                mailingContentElement.classList.add("hide");

                let toBeRequiredElements = document.querySelectorAll(`.canBeRequired[data-group='${proposed}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                for (let reqElement of toBeRequiredElements) {
                    let ele = <HTMLElement>reqElement
                    this._core.forceElementOptional(ele)
                }
            }

        } else if (proposed === "physical") {
            let physicalTabElement = <HTMLLIElement>document.querySelector(`.physicalAddressProposedTab[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);

            if (physicalTabElement.classList.contains("hide"))
                physicalTabElement.classList.remove("hide");
            else
                physicalTabElement.classList.add("hide");

            let physicalContentElement = <HTMLDivElement>document.querySelector(`.physicalAddressOriginalContent[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
            if (physicalContentElement.classList.contains("hide")) {
                physicalContentElement.classList.remove("hide");

                let toBeRequiredElements = document.querySelectorAll(`.canBeRequired[data-group='${proposed}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                for (let reqElement of toBeRequiredElements) {
                    let ele = <HTMLElement>reqElement
                    this._core.forceElementRequired(ele)
                }
            } else {
                physicalContentElement.classList.add("hide");

                let toBeRequiredElements = document.querySelectorAll(`.canBeRequired[data-group='${proposed}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                for (let reqElement of toBeRequiredElements) {
                    let ele = <HTMLElement>reqElement
                    this._core.forceElementOptional(ele)
                }
            }
        } else if (proposed === "same") {
            let container = <HTMLDivElement>document.querySelector(`.physicalAddressContainer[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);

            let elements = container.querySelectorAll("input");

            for (let ele of elements) {
                if (ele.disabled) {
                    ele.disabled = false;
                    if (!ele.classList.contains("schoolReconfigurationsDetailAddressLine2Field"))
                        this._core.forceElementRequired(ele);
                    else
                        this._core.forceElementOptional(ele);
                } else {
                    ele.disabled = true;
                    this._core.forceElementOptional(ele);
                }
                if (ele.readOnly) {
                    ele.readOnly = false;
                    if (!ele.classList.contains("schoolReconfigurationsDetailAddressLine2Field"))
                        this._core.forceElementRequired(ele);
                    else
                        this._core.forceElementOptional(ele);
                } else {
                    ele.readOnly = true;
                    this._core.forceElementOptional(ele);
                }
            }

            let buttons = container.querySelectorAll("button");
            for (let ele of buttons) {
                if (ele.disabled) {
                    ele.disabled = false;
                } else {
                    ele.disabled = true;
                }
            }
        }
    }

    createNewReconfiguration() {
        let dropdown = <HTMLSelectElement>document.getElementById("reconfigurationdetailsschoolnamecreate");
        let dropdownValue = parseInt(dropdown.value);

        let planForm = document.getElementById("schoolReconfigurationsDetailForm");
        let planFK = planForm.dataset.planfk;

        let core = this._core;
        let that = this;
        let newRow = 0;

        if (dropdownValue > 0) {
            Core.showLoader();

            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SchoolReconfigurations/AddReconfiguration', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    let container = document.getElementById("schoolReconfigurationList");
                    let newDiv = <HTMLDivElement>document.createElement("div");

                    newDiv.innerHTML = xhr.responseText;

                    container.appendChild(newDiv);

                    let idControl = <HTMLButtonElement>newDiv.querySelector(".Accordion h2 button");
                    let id = idControl.id;

                    new CustomAccordion(id);

                    that.bindCheckboxes();
                    that.bindUploadButtons();
                    that.bindDeleteSchoolReconfigurationButtons();
                    that.bindSchoolNameSearch();

                    let mailing = new Foundation.Tabs($(".mailingAddressTabs"));
                    let physical = new Foundation.Tabs($(".physicalAddressTabs"));

                    let reconfigurationDetailsNoReconfigurationsCheckbox = <HTMLInputElement>document.getElementById("reconfigurationdetailsnoreconfigurations");
                    if (reconfigurationDetailsNoReconfigurationsCheckbox !== null)
                        if (reconfigurationDetailsNoReconfigurationsCheckbox.checked)
                            reconfigurationDetailsNoReconfigurationsCheckbox.checked = false;

                    reconfigurationDetailsNoReconfigurationsCheckbox.disabled = true;


                    Core.createHTMLAlert("alertMessageDiv", "School Reconfiguration Added", 'success', 2000, window.location.reload());
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", `Request failed.  Returned status of '${xhr.status}'`, 'error', 3000, null);
                }
            };
            xhr.send(`institutionPK=${dropdownValue}&planFK=${planFK}&row=${newRow}`);
        }
    }

    addMailingAddress(e: Event) {
        let planForm = document.getElementById("schoolReconfigurationsDetailForm");
        let planFK = planForm.dataset.planfk;

        let core = this._core;
        let that = this;

        let type = (<HTMLButtonElement>e.target).dataset.type;

        Core.showLoader();

        let dataPointPlanPropertyPK = (<HTMLButtonElement>e.target).dataset.datapointplanpropertypk;

        let currentRows = document.querySelectorAll(`.school-reconfiguration-mailingaddress[data-type='${type}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        let currentRow = 0;
        for (let thisRow of currentRows) {
            let thisRowElement = <HTMLDivElement>thisRow;
            let thisRowVal = parseInt(thisRowElement.dataset.row);
            if (thisRowVal > currentRow)
                currentRow = thisRowVal;
        }

        currentRow++;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SchoolReconfigurations/AddMailingAddress', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                let container = document.querySelector(`.mailAddressList[data-type='${type}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);

                var parser = new DOMParser()
                var el = parser.parseFromString(xhr.responseText, "text/html");

                let actualNode = <HTMLDivElement>(<HTMLDivElement>el.childNodes[0]).querySelector(".school-reconfiguration-mailingaddress");

                container.appendChild(actualNode);

                Core.createHTMLAlert("alertMessageDiv", "School Address Added", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", `Request failed.  Returned status of '${xhr.status}'`, 'error', 3000, null);
            }
        };
        xhr.send(`planFK=${planFK}&row=${currentRow}&dataPointPlanPropertyPK=${dataPointPlanPropertyPK}&addressType=${type}`);
    }

    addPhysicalAddress(e: Event) {
        let planForm = document.getElementById("schoolReconfigurationsDetailForm");
        let planFK = planForm.dataset.planfk;

        let core = this._core;
        let that = this;

        let type = (<HTMLButtonElement>e.target).dataset.type;

        Core.showLoader();

        let dataPointPlanPropertyPK = (<HTMLButtonElement>e.target).dataset.datapointplanpropertypk;

        let currentRows = document.querySelectorAll(`.school-reconfiguration-mailingaddress[data-type='${type}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        let currentRow = 0;
        for (let thisRow of currentRows) {
            let thisRowElement = <HTMLDivElement>thisRow;
            let thisRowVal = parseInt(thisRowElement.dataset.row);
            if (thisRowVal > currentRow)
                currentRow = thisRowVal;
        }

        currentRow++;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SchoolReconfigurations/AddMailingAddress', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                let container = document.querySelector(`.mailPhysicalList[data-type='${type}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);

                var parser = new DOMParser()
                var el = parser.parseFromString(xhr.responseText, "text/html");

                let actualNode = <HTMLDivElement>(<HTMLDivElement>el.childNodes[0]).querySelector(".school-reconfiguration-mailingaddress");

                container.appendChild(actualNode);

                Core.createHTMLAlert("alertMessageDiv", "School Address Added", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", `Request failed.  Returned status of '${xhr.status}'`, 'error', 3000, null);
            }
        };
        xhr.send(`planFK=${planFK}&row=${currentRow}&dataPointPlanPropertyPK=${dataPointPlanPropertyPK}&addressType=${type}`);
    }

    bindCheckboxes() {
        let checkboxes = document.getElementsByClassName("schoolReconfigurationsDetailCheckboxField");
        for (let checkbox of checkboxes)
            if (!checkbox.classList.contains("schoolReconfigurationsDetailAddressCheckboxField") && !checkbox.classList.contains("schoolReconfigurationPrincipalCharterSchool"))
                if (!checkbox.classList.contains("schoolReconfigurationsDetailOnlyTelephoneChange"))
                    checkbox.addEventListener("change", (e: Event) => this.toggleProposed(e));
            else
                checkbox.addEventListener("change", (e: Event) => this.toggleMailingProposed(e));


        const schoolReconfigurationPrincipalCharterSchoolCheckboxes = document.getElementsByClassName("schoolReconfigurationPrincipalCharterSchool");
        for (const schoolCheck of schoolReconfigurationPrincipalCharterSchoolCheckboxes)
            schoolCheck.addEventListener("change", (e: Event) => this.togglePrincipalCharterSchool(e));

        const schoolReconfigurationsDetailOnlyTelephoneChangeCheckbox = document.querySelector(".schoolReconfigurationsDetailOnlyTelephoneChange");
        if (schoolReconfigurationsDetailOnlyTelephoneChangeCheckbox !== null)
            schoolReconfigurationsDetailOnlyTelephoneChangeCheckbox.addEventListener("change", (e: Event) => this.onlyTelephoneChange());
    }

    bindUploadButtons() {
        let fileUploaders = document.getElementsByClassName("schoolReconfigurationsDetailUploadFileField");
        for (let fileUploader of fileUploaders)
            fileUploader.addEventListener("change", (e: Event) => this.uploadFile(<HTMLInputElement>e.target));
    }

    uploadFile(e: HTMLInputElement) {
        let core = this._core;
        Core.showLoader();
        let formName = e.dataset.formname;
        let dataPointPlanPropertyFK = e.dataset.datapointplanpropertypk;

        let uploadSampleForm = <HTMLFormElement>document.getElementById(formName);
        let formData = new FormData(uploadSampleForm);
        const uploadType = e.dataset.uploadtype;

        let propertyPK = e.dataset.propertypk;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SchoolReconfigurations/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();

                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);

                        let formFile = <HTMLInputElement>document.querySelector(`.schoolReconfigurationsDetailFileField[data-datapointplanpropertypk='${dataPointPlanPropertyFK}'][data-uploadtype='${uploadType}']`);
                        formFile.value = "";

                        let fileList = document.querySelector(`.uploadFileList[data-datapointplanpropertypk='${dataPointPlanPropertyFK}'][data-uploadtype='${uploadType}']`)
                        let currentFileList = document.querySelectorAll(`.uploadFileColumn[data-datapointplanpropertypk='${dataPointPlanPropertyFK}'][data-uploadtype='${uploadType}']`);

                        let sequence = -1;
                        for (let file of currentFileList) {
                            let fileEle = <HTMLDivElement>file;

                            let thisSequence = parseInt(fileEle.dataset.sequencenbr);
                            if (thisSequence > sequence) {
                                sequence = thisSequence;
                            }
                        }

                        let uploadedContainer = document.querySelector(`.uploadedFiles[data-datapointplanpropertypk='${dataPointPlanPropertyFK}'][data-uploadtype='${uploadType}']`);
                        uploadedContainer.classList.remove("hide");

                        sequence++;

                        let propertyRelationTypePK = e.dataset.propertyrelationtypepk;

                        let fileWrapper = <HTMLDivElement>document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = <HTMLDivElement>document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = <HTMLAnchorElement>document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.planpropertypk = dataPointPlanPropertyFK.toString();
                        fileA.dataset.datapointplanpropertypk = dataPointPlanPropertyFK.toString();
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.dataset.sequencenbr = sequence.toString();
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.propertyrelationtypepk = propertyRelationTypePK;
                        fileA.dataset.planpropertyplanpropertyfilepk = "0";
                        fileA.classList.add(`schoolReconfigurationsDetailFileUploadField`);
                        fileA.classList.add(`schoolReconfigurationsFileUploadFileField`);
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);

                        fileList.appendChild(fileWrapper);
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }

    bindDeleteButtons() {
        let fileButtons = document.getElementsByClassName("deleteFile");

        for (let fileButton of fileButtons)
            fileButton.addEventListener("click", (e: Event) => this.deleteFile(e));
    }

    bindAddEnrollmentButtons() {
        let allEnrollmentAddButtons = document.getElementsByClassName("schoolReconfigurationEnrollmentChangeEntryAdd");
        for (let enrollmentAddButton of allEnrollmentAddButtons)
            enrollmentAddButton.addEventListener("click", (e: Event) => this.addEnrollmentRecord(e));
    }

    bindAddEnrollmentChecks() {
        let allElements = document.getElementsByClassName("schoolReconfigurationEnrollmentChangeEntry");
        for (let element of allElements)
            element.addEventListener("input", (e: Event) => this.checkEnrollmentForComplete(e));
    }

    deleteFile(e: Event) {
        const fileButton = <HTMLButtonElement>e.target;
        const planPropertyFilePK = fileButton.dataset.planpropertyfilepk;
        const dataPointPlanPropertyPK = fileButton.dataset.datapointplanpropertypk;
        const fileGroup = fileButton.dataset.filegroup;

        if (planPropertyFilePK && parseInt(planPropertyFilePK) > 0) {
            let modal: Modal = new Modal("deleteFileModal", null);
            let deleteConfirmButton = <HTMLButtonElement>document.getElementById("deleteFileConfirm");
            deleteConfirmButton.dataset.planpropertyfilepk = planPropertyFilePK;
            deleteConfirmButton.dataset.datapointplanpropertypk = dataPointPlanPropertyPK;
            deleteConfirmButton.dataset.filegroup = fileGroup;
            modal.show();
        }
    }

    deleteFileConfirm(confirmButton: HTMLButtonElement) {
        let core = this._core;
        Core.showLoader();

        let planPropertyFilePK = confirmButton.dataset.planpropertyfilepk;

        let dataPointPlanPropertyPK = confirmButton.dataset.datapointplanpropertypk;
        const fileGroup = confirmButton.dataset.filegroup;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SchoolReconfigurations/DeleteFiles', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file has been successfully deleted.", 'success', 3000, null);

                        let containerElement = <HTMLElement>document.querySelector(`.uploadFileColumn[data-planpropertyfilepk='${planPropertyFilePK}']`);
                        if (containerElement !== null) {
                            containerElement.remove();
                        }

                        if (fileGroup === "principal") {
                            let uploads = document.querySelectorAll(`.uploadFileList[data-uploadtype='charter'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}'] .uploaded-file-container`);
                            if (uploads.length === 0) {
                                let uploadContainer = document.querySelector(`.uploadedPrinicipalFiles[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                                uploadContainer.classList.add("hide");
                            }
                        } else if (fileGroup === "gradeconfig") {
                            let uploads = document.querySelectorAll(`.uploadMultiyearChangeFileList[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'] .uploaded-file-container`);
                            if (uploads.length === 0) {
                                let uploadContainer = document.querySelector(`.multiGradeUploadedFiles[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                                uploadContainer.classList.add("hide");
                            }
                        }

                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting file.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file removal failed. Please try again.", 'error', 3000, null);
            }

            let modal: Modal = new Modal("deleteFileModal", null);
            modal.hide();
        };
        xhr.send(JSON.stringify([planPropertyFilePK]));
    }

    deleteFileCancel() {
        let modal: Modal = new Modal("deleteFileModal", null);
        modal.hide();
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLongLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let allFileElements = [];

        let planPK = 0;

        let profileFormElement = <HTMLDivElement>document.getElementById("schoolReconfigurationsDetailForm");

        let inputs = document.getElementsByClassName("schoolReconfigurationsDetailField");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanPropertyExtended = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                    ButtonRow: 0
                };

                allSaveElements.push(saveItem);
            }
        }

        let enrollments = document.querySelectorAll(".schoolReconfigurationEnrollmentChangeEntryValue[data-isnewdata='True']");
        for (let ele of enrollments) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);

            let element = <HTMLSpanElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let isNewData = element.dataset.isnewdata;
            if (isNewData === "true" || isNewData === "True") {

                let saveItem: IPlanPropertyExtended = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.textContent,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                    ButtonRow: 0
                };

                allSaveElements.push(saveItem);
            }
        }

        let enrollmentGrades = document.querySelectorAll(".schoolReconfigurationEnrollmentChangeEntryGradeValue[data-isnewdata='True']");
        for (let ele of enrollmentGrades) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);

            let element = <HTMLSpanElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let lookupCodeFK = parseInt(element.dataset.gradelookupcodepk);
            let isNewData = element.dataset.isnewdata;
            if (isNewData === "true" || isNewData === "True") {

                let saveItem: IPlanPropertyExtended = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: null,
                    LookupCodeFK: lookupCodeFK,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                    ButtonRow: 0
                };

                allSaveElements.push(saveItem);
            }
        }

        let checkboxes = document.getElementsByClassName("schoolReconfigurationsDetailCheckboxField");
        for (let ele of checkboxes) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.checked || hadValue) {
                let val = "";
                if (element.checked)
                    val = "on";
                else
                    val = "off";

                let saveItem: IPlanPropertyExtended = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                    ButtonRow: 0
                };

                allSaveElements.push(saveItem);
            }
        }

        let noReconfigCheckboxes = document.getElementsByClassName("schoolReconfigurationsDetailCheckboxNoReconfigurationsField");
        for (let ele of noReconfigCheckboxes) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.checked || hadValue) {
                let val = "";
                if (element.checked)
                    val = "on";
                else
                    val = "off";

                let saveItem: IPlanPropertyExtended = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                    ButtonRow: 0
                };

                allSaveElements.push(saveItem);
            }
        }

        let selects = document.getElementsByClassName("schoolReconfigurationsDetailSelectField");
        for (let ele of selects) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);

            let element = <HTMLSelectElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if ((element.value !== "" && element.value !== "0") || hadValue) {
                let saveItem: IPlanPropertyExtended = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: "",
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                    ButtonRow: 0
                };

                allSaveElements.push(saveItem);
            }
        }

        let files = document.getElementsByClassName("schoolReconfigurationsFileUploadFileField");
        for (let ele of files) {
            planPK = parseInt(profileFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;

            let sequenceNbr = parseInt(element.dataset.sequencenbr);
            let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk)
            let propertyRelationshipTypePK = parseInt(element.dataset.propertyrelationtypepk);
            let fileUploadPK = parseInt(element.dataset.fileuploadpk);
            let propertyPK = parseInt(element.dataset.propertypk);
            let planPropertyPlanPropertyFilePK = parseInt(element.dataset.planpropertyplanpropertyfilepk);

            let saveItem: IPlanPropertyPlanPropertyFile = {
                PlanPropertyPK: dataPointPlanPropertyPK,
                PlanPropertyFilePK: planPropertyFilePK,
                SequenceNbr: sequenceNbr,
                PropertyRelationshipTypePK: propertyRelationshipTypePK,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanPropertyPlanPropertyFilePK: planPropertyPlanPropertyFilePK
            };

            allFileElements.push(saveItem);
        }

        var allData = {
            "ElementData": allSaveElements,
            "FileData": allFileElements
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SchoolReconfigurations/SaveSchoolReconfigurationsDetail', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLongLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Page saved! Reloading.", 'success', 3000, null);
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLongLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLongLoader();
                }
                else {
                    Core.hideLongLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLongLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLongLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }

    closeModal() {
        let modal = new Modal("noticeModal", null);
        modal.hide();
    }

    initializeRequiredFieldsCustom() {
        let byPass: boolean = false;
        let showMessage: boolean = false;
        let totalErrors = 0;

        let reconfigurationDetailsNoReconfigurationsCheckbox = <HTMLInputElement>document.getElementById("reconfigurationdetailsnoreconfigurations");
        if (reconfigurationDetailsNoReconfigurationsCheckbox !== null) {
            if (reconfigurationDetailsNoReconfigurationsCheckbox.checked)
                byPass = true;
        }

        if (!byPass) {
            let checkboxes = document.getElementsByClassName("schoolReconfigurationsDetailCheckboxField");

            for (let checkbox of checkboxes) {
                let checkboxElement = <HTMLInputElement>checkbox;
                let group = checkboxElement.dataset.group;
                let dataPointPlanPropertyPK = parseInt(checkboxElement.dataset.datapointplanpropertypk);

                if (checkboxElement.classList.contains("schoolReconfigurationsDetailAddressCheckboxField")) {
                    if (group === "mailing") {
                        let proposed = checkboxElement.dataset.proposed;

                        if (proposed === "same") {
                            let physicalFields = document.querySelectorAll(`.physicalAddressOriginal[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'] input`);
                            if (checkboxElement.checked) {
                                for (let field of physicalFields) {
                                    let ele = <HTMLInputElement>field;
                                    this._core.forceElementOptional(ele);
                                }
                            }
                        } else if (proposed === "mailing") {
                            let mailAddressProposedFields = document.querySelectorAll(`.mailingAddressProposed[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'] input`);

                            if (checkboxElement.checked) {
                                for (let field of mailAddressProposedFields) {
                                    let ele = <HTMLInputElement>field;
                                    if (!ele.classList.contains("schoolReconfigurationsDetailAddressLine2Field"))
                                        this._core.forceElementRequired(ele);
                                    else
                                        this._core.forceElementOptional(ele);
                                }

                            } else {
                                for (let field of mailAddressProposedFields) {
                                    let ele = <HTMLInputElement>field;
                                    this._core.forceElementOptional(ele);
                                }
                            }
                        } else if (proposed === "physical") {
                            let mailAddressProposedFields = document.querySelectorAll(`.physicalAddressProposed[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'] input`);

                            if (checkboxElement.checked) {
                                for (let field of mailAddressProposedFields) {
                                    let ele = <HTMLInputElement>field;
                                    if (!ele.classList.contains("schoolReconfigurationsDetailAddressLine2Field"))
                                        this._core.forceElementRequired(ele);
                                    else
                                        this._core.forceElementOptional(ele);
                                }
                            } else {
                                for (let field of mailAddressProposedFields) {
                                    let ele = <HTMLInputElement>field;
                                    this._core.forceElementOptional(ele);
                                }
                            }
                        }
                    } else if (group === "physical") {
                        let physicalAddressProposedFields = document.querySelectorAll(`.physicalAddressProposed[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'] input`);

                        for (let field of physicalAddressProposedFields) {

                            if (checkboxElement.checked) {
                                //There was an issue with the physical address that causes the Line2 field to always become required, this gets around that.
                                let ele = <HTMLInputElement>field;
                                if (!ele.classList.contains("schoolReconfigurationsDetailAddressLine2Field"))
                                    this._core.forceElementRequired(ele);
                                else
                                    this._core.forceElementOptional(ele);
                            } else {
                                let ele = <HTMLInputElement>field;
                                this._core.forceElementOptional(ele);
                            }
                        }
                    }
                } else {

                    let fields = document.querySelectorAll(`input.schoolReconfigurationsDetailField[data-group='${group}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                    if (checkboxElement.checked) {
                        for (let element of fields) {
                            let ele = <HTMLElement>element;

                            if (!ele.classList.contains("schoolReconfigurationsDetailAddressLine2Field"))
                                this._core.forceElementRequired(ele);
                            else
                                this._core.forceElementOptional(ele);
                        }
                    }
                }
            }

            let formattedAllClasses = [];
            formattedAllClasses.push(".schoolReconfigurationsDetailField");
            formattedAllClasses.push(".schoolReconfigurationsDetailSelectField");
            formattedAllClasses.push(".schoolReconfigurationsDetailCheckboxField");

            let classesToValidate = formattedAllClasses.join(",");

            let allElements = document.querySelectorAll(classesToValidate);

            for (let element of allElements) {
                let htmlElement = <HTMLElement>element;

                if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {

                    if (!(htmlElement.hasAttribute("aria-required") && htmlElement.getAttribute("aria-required") === "true")) {
                        htmlElement.setAttribute("aria-required", "true");
                        htmlElement.setAttribute("force-required", "true");

                        let label = Core.findLabelForInput(htmlElement);

                        if (label !== null && !label.classList.contains("isRequired")) {
                            label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                            label.classList.add("isRequired");
                        }
                    }
                }

                //Manual override when data-forcerequired=true
                if ("forcerequired" in htmlElement.dataset && htmlElement.dataset.forcerequired === "true") {

                    htmlElement.dataset.percent = "1.00";

                    if (!(htmlElement.hasAttribute("aria-required") && htmlElement.getAttribute("aria-required") === "true")) {
                        htmlElement.setAttribute("aria-required", "true");

                        let label = Core.findLabelForInput(htmlElement);

                        if (label !== null && !label.classList.contains("isRequired")) {
                            label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                            label.classList.add("isRequired");
                        }
                    }
                }
            }



            for (let element of allElements) {
                let htmlElement = <HTMLElement>element;

                if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {
                    if (element instanceof HTMLInputElement) {
                        let inputElement = <HTMLInputElement>element;

                        if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "file") {
                            if ("hasuploaded" in inputElement.dataset && inputElement.dataset.hasuploaded != "true") {
                                inputElement.classList.add("missing-field");
                                inputElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(inputElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        } else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "text" && inputElement.value === "") {
                            inputElement.classList.add("missing-field");
                            inputElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(inputElement);
                            showMessage = true;
                            totalErrors++;
                        } else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "date" && inputElement.value === "") {
                            inputElement.classList.add("missing-field");
                            inputElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(inputElement);
                            showMessage = true;
                            totalErrors++;
                        }
                    } else if (element instanceof HTMLSelectElement) {
                        let selectElement = <HTMLSelectElement>element;
                        if (selectElement.selectedIndex <= 0 || selectElement.options[selectElement.selectedIndex].value === "") {
                            selectElement.classList.add("missing-field");
                            selectElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(selectElement);
                            showMessage = true;
                            totalErrors++;
                        }
                    } else if (element instanceof HTMLTextAreaElement) {
                        let textAreaElement = <HTMLTextAreaElement>element;
                        if (textAreaElement.value === "") {
                            textAreaElement.classList.add("missing-field");
                            textAreaElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(textAreaElement);
                            showMessage = true;
                            totalErrors++;
                        }
                    }
                }
            }
        }

        let calculateCheckboxes = document.querySelectorAll(".schoolReconfigurationsDetailCheckboxField[data-group='enrollment']");
        for (let calc of calculateCheckboxes) {
            let ele = <HTMLInputElement>calc;
            let dataPointPlanPropertyPK = parseInt(ele.dataset.datapointplanpropertypk);

            let calculated = this.calculateEnrollments(dataPointPlanPropertyPK);
            let calculationErrorMessage = document.querySelector(`.calculation-error[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
            if (calculated === false) {
                totalErrors++;
                calculationErrorMessage.classList.remove("hide");
                showMessage = true;
            } else {
                calculationErrorMessage.classList.add("hide");
            }
        }


        let message = <HTMLDivElement>document.getElementById("validationMessage");

        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        } else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");

        if (goToError !== null) {

            let that = this;

            let firstFocusableEl = <HTMLElement>document.querySelector(".missing-field");

            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");

                        let accordionElement = <HTMLButtonElement>document.getElementById(id);
                        accordionElement.click();
                    }

                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    } else {
                        firstFocusableEl.focus();
                    }
                });
            } else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }

    exportToExcel() {
        let that = this;
        return new Promise<void>((resolve, reject) => {
            let planForm = document.getElementById("schoolReconfigurationsDetailForm");
            let planFK = parseInt(planForm.dataset.planfk);

            let xhr = new XMLHttpRequest();
            xhr.open('POST', `/ExportExcel/SchoolReconfigurationsDetailsExcelExport`, true);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.responseType = "blob";
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let blob = this.response;
                    let filename = `SchoolReconfigurationDetails.xlsx`;
                    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                        window.navigator.msSaveBlob(blob, filename);
                    }
                    else {
                        var a = document.createElement("a");
                        var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                        document.body.appendChild(a);
                        a.style.display = "none";
                        a.href = blobUrl;
                        a.download = filename;
                        a.click();
                    }
                    resolve();
                }
                else {
                    reject("There was an issue during export to Excel. Please try again later.");
                }
            };
            xhr.send(`planFK=${planFK}`);
        });
    }

    bindDeleteSchoolReconfigurationButtons() {
        let buttons = document.getElementsByClassName("deleteThisSchoolReconfiguration");
        for (let button of buttons)
            button.addEventListener("click", (e: Event) => this.deleteSchoolReconfiguration(e));
    }

    deleteSchoolReconfiguration(e: Event) {
        this.showDeleteRowConfirm(e);
    }

    showDeleteRowConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let dataPointPlanPropertyPK = buttonElement.dataset.datapointplanpropertypk;

        let modal: Modal = new Modal("deleteSchoolReconfigurationModal", null);
        modal.addAttributeToElement("deleteSchoolReconfigurationModal", "#deleteReconfigurationConfirm", "datapointplanpropertypk", dataPointPlanPropertyPK);
        modal.show();
    }

    deleteReconfigurationCancel() {
        let modal: Modal = new Modal("deleteSchoolReconfigurationModal", null);
        modal.hide();
    }

    deleteReconfigurationConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let planProps = [];
        let dataPointPlanPropertyPK = buttonElement.dataset.datapointplanpropertypk;

        let container = <HTMLDivElement>document.querySelector(`.schoolReconfigurationSection[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        let allElements = container.querySelectorAll(`.schoolReconfigurationsDetailField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        for (let ele of allElements) {
            let element = <HTMLElement>ele;
            let planProp = element.dataset.planpropertypk;

            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }

        planProps.push(dataPointPlanPropertyPK);

        const that = this;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SchoolReconfigurations/DeleteSchoolReconfigurationDetail', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();

                let accordionElement = <HTMLButtonElement>document.querySelector(`.Accordion-trigger[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                accordionElement.parentElement.parentElement.parentElement.remove();

                let modal: Modal = new Modal("deleteSchoolReconfigurationModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The school reconfiguration has been removed.", 'success', 3000, null);

                that.noReconfigurations();

            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }

    bindSchoolNameSearch() {
        let allSchoolNames = document.getElementsByClassName("schoolReconfigurationsSchoolName");
        for (let schoolName of allSchoolNames)
            schoolName.addEventListener("blur", (e: Event) => this.schoolNameSearch(e));
    }

    schoolNameSearch(e: Event) {
        let textElement = <HTMLInputElement>e.target;

        let schoolName = textElement.value;

        let dataPointPlanPropertyPK = textElement.dataset.datapointplanpropertypk;

        if (schoolName !== "") {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SchoolReconfigurations/SearchForSchool', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let res = JSON.parse(xhr.responseText);

                    let element = <HTMLInputElement>document.querySelector(`.schoolReconfigurationDetailBranchField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);

                    if (res.instDetail.auNumber !== null && res.instDetail.auNumber > 0) {
                        element.value = res.instDetail.auNumber;
                    }
                }
            };
            xhr.send(JSON.stringify(schoolName));
        }
    }

    noReconfigurations() {
        let reconfigurationDetailsNoReconfigurationsCheckbox = <HTMLInputElement>document.getElementById("reconfigurationdetailsnoreconfigurations");

        let schoolReconfigurationList = <HTMLDivElement>document.getElementById("schoolReconfigurationList");
        let alreadyExistsElements = schoolReconfigurationList.querySelectorAll(".Accordion");
        let alreadyExists = false;

        if (alreadyExistsElements.length > 0)
            alreadyExists = true;

        if (alreadyExists) {
            if (reconfigurationDetailsNoReconfigurationsCheckbox !== null) {
                if (reconfigurationDetailsNoReconfigurationsCheckbox.checked) {
                    reconfigurationDetailsNoReconfigurationsCheckbox.checked = false;
                }
                reconfigurationDetailsNoReconfigurationsCheckbox.disabled = true;
            }
        }
        else
            reconfigurationDetailsNoReconfigurationsCheckbox.disabled = false;

        if (reconfigurationDetailsNoReconfigurationsCheckbox !== null) {
            let schoolReconfigurationsCreateButton = <HTMLButtonElement>document.getElementById("schoolReconfigurationsCreate");
            if (reconfigurationDetailsNoReconfigurationsCheckbox.checked) {
                schoolReconfigurationsCreateButton.disabled = true;
            } else {
                schoolReconfigurationsCreateButton.disabled = false;
            }
        }
    }

    addEnrollmentRecord(e: Event) {
        let button = <HTMLButtonElement>e.target;
        let dataPointPlanPropertyPK = button.dataset.datapointplanpropertypk;
        const enrollmentTableData = <HTMLDivElement>document.querySelector(`.enrollmentTableData[data-datapointplanpropertypk="${dataPointPlanPropertyPK}"]`);
        const schoolReconfigurationEnrollmentChangeEntryAdd = <HTMLButtonElement>document.querySelector(`.schoolReconfigurationEnrollmentChangeEntryAdd[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        schoolReconfigurationEnrollmentChangeEntryAdd.disabled = true;

        let planFK = 0;
        let formElement = document.getElementById("schoolReconfigurationsDetailForm");
        planFK = parseInt(formElement.dataset.planfk);

        let row = 0;
        let rowElements = document.querySelectorAll(`.enrollmentEntryResults[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'] tbody tr`);
        for (let thisRow of rowElements) {
            let ele = <HTMLTableRowElement>thisRow;
            let theRow = parseInt(ele.dataset.row);

            if (theRow > row)
                row = theRow;
        }

        row++;

        let gradeElement = <HTMLSelectElement>document.querySelector(`.schoolReconfigurationEnrollmentChangeEntryGrade[data-type='grade'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`)
        let originalInPersonElement = <HTMLInputElement>document.querySelector(`.schoolReconfigurationEnrollmentChangeEntry[data-type='original-inperson'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        let originalVirtualElement = <HTMLInputElement>document.querySelector(`.schoolReconfigurationEnrollmentChangeEntry[data-type='original-virtual'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        let proposedInPersonElement = <HTMLInputElement>document.querySelector(`.schoolReconfigurationEnrollmentChangeEntry[data-type='proposed-inperson'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        let proposedVirtualElement = <HTMLInputElement>document.querySelector(`.schoolReconfigurationEnrollmentChangeEntry[data-type='proposed-virtual'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);

        let grade = gradeElement.value;
        let gradeCaption = (<HTMLOptionElement>gradeElement[gradeElement.selectedIndex]).textContent;
        let originalInPerson = originalInPersonElement.value;
        let originalVirtual = originalVirtualElement.value;
        let proposedInPerson = proposedInPersonElement.value;
        let proposedVirtual = proposedVirtualElement.value;

        let data = {
            "DataPointPlanPropertyPK": dataPointPlanPropertyPK,
            "Grade": grade,
            "GradeCaption": gradeCaption,
            "OriginalInPerson": originalInPerson,
            "OriginalVirtual": originalVirtual,
            "ProposedInPerson": proposedInPerson,
            "ProposedVirtual": proposedVirtual,
            "RowNumber": row,
            "PlanFK": planFK
        }

        let that = this;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SchoolReconfigurations/AddEnrollmentRecord', true);
        xhr.setRequestHeader('Content-type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();

                let container = document.querySelector(`.enrollmentEntryResults[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'] tbody`);
                let newTr = <HTMLTableRowElement>document.createElement("tr");

                newTr.innerHTML = xhr.responseText;
                newTr.dataset.row = row.toString();
                newTr.dataset.datapointplanpropertypk = dataPointPlanPropertyPK.toString();
                newTr.dataset.newrow = "yes";

                container.appendChild(newTr);

                gradeElement.selectedIndex = 0;
                originalInPersonElement.value = "";
                originalVirtualElement.value = "";
                proposedInPersonElement.value = "";
                proposedVirtualElement.value = "";

                that.bindDeleteSchoolEnrollmentButtons();

                enrollmentTableData.classList.remove("hide");

                Core.createHTMLAlert("alertMessageDiv", "Enrollment added", 'success', 2000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", `Request failed.  Returned status of '${xhr.status}'`, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(data));
    }

    checkEnrollmentForComplete(e) {
        let element = <HTMLInputElement>e.target;
        let dataPointPlanPropertyPK = element.dataset.datapointplanpropertypk;

        let originalInPersonElement = <HTMLInputElement>document.querySelector(`.schoolReconfigurationEnrollmentChangeEntry[data-type='original-inperson'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        let originalVirtualElement = <HTMLInputElement>document.querySelector(`.schoolReconfigurationEnrollmentChangeEntry[data-type='original-virtual'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        let proposedInPersonElement = <HTMLInputElement>document.querySelector(`.schoolReconfigurationEnrollmentChangeEntry[data-type='proposed-inperson'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        let proposedVirtualElement = <HTMLInputElement>document.querySelector(`.schoolReconfigurationEnrollmentChangeEntry[data-type='proposed-virtual'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);

        let originalInPersonNum: number = parseInt(originalInPersonElement.value);
        let originalVirtualNum: number = parseInt(originalVirtualElement.value);
        let proposedInPersonNum: number = parseInt(proposedInPersonElement.value);
        let proposedVirtualNum: number = parseInt(proposedVirtualElement.value);

        let buttonElement = <HTMLButtonElement>document.querySelector(`.schoolReconfigurationEnrollmentChangeEntryAdd[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`)

        if (isNaN(originalInPersonNum) || isNaN(originalVirtualNum) || isNaN(proposedInPersonNum) || isNaN(proposedVirtualNum))
            buttonElement.disabled = true;
        else
            buttonElement.disabled = false;
    }

    calculateEnrollments(dataPointPlanPropertyPK: number) {
        let formElement = document.getElementById("schoolReconfigurationsDetailForm");
        let calculateEnrollment = formElement.dataset.calculateenrollments;

        if (calculateEnrollment && calculateEnrollment === "true") {
            let currentEnrollmentTotalElement = <HTMLInputElement>document.querySelector(`.schoolReconfigurationsDetailField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-group='enrollment'][data-type='current']`);
            let proposedEnrollmentTotalElement = <HTMLInputElement>document.querySelector(`.schoolReconfigurationsDetailField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-group='enrollment'][data-type='proposed']`);

            if (currentEnrollmentTotalElement.value !== "" && proposedEnrollmentTotalElement.value !== "") {
                let currentEnrollmentTotal = parseInt(currentEnrollmentTotalElement.value);
                let proposedEnrollmentTotal = parseInt(proposedEnrollmentTotalElement.value);

                if (!isNaN(currentEnrollmentTotal) && !isNaN(proposedEnrollmentTotal)) {

                    let currentEnrollmentCount: number = 0;
                    let proposedEnrollmentCount: number = 0;

                    let enrollmentBreakdownCurrentInPersonElements = document.querySelectorAll(`.schoolReconfigurationEnrollmentChangeEntryValue[data-type='original-inperson'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                    let enrollmentBreakdownCurrentVirtualElements = document.querySelectorAll(`.schoolReconfigurationEnrollmentChangeEntryValue[data-type='original-virtual'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                    let enrollmentBreakdownProposedInPersonElements = document.querySelectorAll(`.schoolReconfigurationEnrollmentChangeEntryValue[data-type='proposed-inperson'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                    let enrollmentBreakdownProposedVirtualElements = document.querySelectorAll(`.schoolReconfigurationEnrollmentChangeEntryValue[data-type='proposed-virtual'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);

                    for (let element of enrollmentBreakdownCurrentInPersonElements) {
                        let ele = <HTMLSpanElement>element;

                        currentEnrollmentCount += parseInt(ele.textContent);
                    }

                    for (let element of enrollmentBreakdownCurrentVirtualElements) {
                        let ele = <HTMLSpanElement>element;

                        currentEnrollmentCount += parseInt(ele.textContent);
                    }

                    for (let element of enrollmentBreakdownProposedInPersonElements) {
                        let ele = <HTMLSpanElement>element;

                        proposedEnrollmentCount += parseInt(ele.textContent);
                    }

                    for (let element of enrollmentBreakdownProposedVirtualElements) {
                        let ele = <HTMLSpanElement>element;

                        proposedEnrollmentCount += parseInt(ele.textContent);
                    }

                    return ((currentEnrollmentTotal === currentEnrollmentCount) && (proposedEnrollmentTotal === proposedEnrollmentCount));
                } else
                    return false;
            }
        }

        //We dont want to actually do the check, so make this value === true.
        return true;
    }

    bindDeleteSchoolEnrollmentButtons() {
        let deleteButtons = document.getElementsByClassName("schoolReconfigurationEnrollmentChangeEntryDelete");
        for (let deleteButton of deleteButtons)
            deleteButton.addEventListener("click", (e: Event) => this.deleteEnrollmentRow(e));
    }

    deleteEnrollmentRow(e: Event) {
        let deleteButton = <HTMLButtonElement>e.target;
        let row = deleteButton.dataset.row;
        let dataPointPlanPropertyPK = deleteButton.dataset.datapointplanpropertypk;

        let modal: Modal = new Modal("deleteSchoolReconfigurationEnrollmentModal", null);
        modal.addAttributeToElement("deleteSchoolReconfigurationEnrollmentModal", "#deleteEnrollmentConfirm", "datapointplanpropertypk", dataPointPlanPropertyPK);
        modal.addAttributeToElement("deleteSchoolReconfigurationEnrollmentModal", "#deleteEnrollmentConfirm", "row", row);
        modal.show();
    }

    deleteEnrollmentRowCancel() {
        let modal: Modal = new Modal("deleteSchoolReconfigurationEnrollmentModal", null);
        modal.hide();
    }

    deleteEnrollmentRowConfirm(e: Event) {
        let confirmButton = <HTMLButtonElement>e.target;
        let core = this._core;
        Core.showLoader();

        let dataPointPlanPropertyPK = confirmButton.dataset.datapointplanpropertypk;
        let row = confirmButton.dataset.row;

        let isNew: boolean = false;

        let isNewRowTRElement = <HTMLTableRowElement>document.querySelector(`.enrollmentEntryResults[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'] tbody tr[data-row='${row}']`);
        if (isNewRowTRElement !== null) {
            let isNewValue = isNewRowTRElement.dataset.newrow;

            if (isNewValue)
                if (isNewValue === "yes")
                    isNew = true;
        }

        if (!isNew) {

            let allElementsToDelete = document.querySelectorAll(`.schoolReconfigurationEnrollmentRecordValue[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
            let planPropertyPKs = [];

            for (let element of allElementsToDelete) {
                let ele = <HTMLElement>element;
                planPropertyPKs.push(ele.dataset.planpropertypk);
            }

            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SchoolReconfigurations/DeleteEnrollment', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status == 200) {
                    if (xhr.responseText !== null) {
                        let res = JSON.parse(xhr.responseText);
                        if (res.success === true) {
                            isNewRowTRElement.remove();

                            const anyRows = document.querySelectorAll(`.enrollmentEntryResults[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'] tbody tr`);
                            if (anyRows.length === 0) {
                                const enrollmentTableData = <HTMLDivElement>document.querySelector(`.enrollmentTableData[data-datapointplanpropertypk="${dataPointPlanPropertyPK}"]`);
                                enrollmentTableData.classList.add("hide")
                            }

                            Core.hideLoader();
                            Core.createHTMLAlert("alertMessageDiv", "The enrollment has been successfully deleted.", 'success', 3000, null);
                        } else {
                            Core.hideLoader();
                            Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting the enrollment.", 'error', 3000, null);
                        }
                    }

                    let modal: Modal = new Modal("deleteSchoolReconfigurationEnrollmentModal", null);
                    modal.hide();
                } else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting the enrollment.", 'error', 3000, null);
                }
            };
            xhr.send(JSON.stringify(planPropertyPKs));
        } else {
            isNewRowTRElement.remove();

            let modal: Modal = new Modal("deleteSchoolReconfigurationEnrollmentModal", null);
            modal.hide();

            const anyRows = document.querySelectorAll(`.enrollmentEntryResults[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'] tbody tr`);
            if (anyRows.length === 0) {
                const enrollmentTableData = <HTMLDivElement>document.querySelector(`.enrollmentTableData[data-datapointplanpropertypk="${dataPointPlanPropertyPK}"]`);
                enrollmentTableData.classList.add("hide")
            }

            Core.hideLoader();
            Core.createHTMLAlert("alertMessageDiv", "The enrollment has been successfully deleted.", 'success', 3000, null);
        }
    }

    togglePrincipalCharterSchool(e: Event) {
        const ele = <HTMLInputElement>e.target;

        const dataPointPlanPropertyPK = ele.dataset.datapointplanpropertypk;

        const uploadElement = <HTMLInputElement>document.querySelector(`.schoolReconfigurationPrincipalCharterSchoolUpload[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        if (uploadElement !== null) {
            if (ele.checked) {
                this._core.forceElementRequired(uploadElement);
            } else {
                this._core.forceElementOptional(uploadElement);
            }
        }
    }

    bindReconfigurationPrincipalFileDeletes() {
        let fileButtons = document.getElementsByClassName("schoolReconfigurationsPrincipalDeleteFile");

        for (let fileButton of fileButtons)
            fileButton.addEventListener("click", (e: Event) => this.deletePrincipalFile(e));
    }

    deletePrincipalFile(e: Event) {
        const fileButton = <HTMLButtonElement>e.target;
        const planPropertyFilePK = fileButton.dataset.planpropertyfilepk;
        const dataPointPlanPropertyPK = fileButton.dataset.datapointplanpropertypk;
        const fileGroup = fileButton.dataset.filegroup;

        if (planPropertyFilePK && parseInt(planPropertyFilePK) > 0) {
            let modal: Modal = new Modal("deleteFileModal", null);
            let deleteConfirmButton = <HTMLButtonElement>document.getElementById("deleteFileConfirm");
            deleteConfirmButton.dataset.planpropertyfilepk = planPropertyFilePK;
            deleteConfirmButton.dataset.datapointplanpropertypk = dataPointPlanPropertyPK;
            deleteConfirmButton.dataset.filegroup = fileGroup;
            modal.show();
        }
    }

    onlyTelephoneChange() {
        const schoolReconfigurationsDetailOnlyTelephoneChangeCheckbox = <HTMLInputElement>document.querySelector(".schoolReconfigurationsDetailOnlyTelephoneChange");
        if (schoolReconfigurationsDetailOnlyTelephoneChangeCheckbox !== null) {
            const checked = schoolReconfigurationsDetailOnlyTelephoneChangeCheckbox.checked
            const schoolReconfigurationsDetailEnrollmentButtonField = <HTMLButtonElement>document.querySelector(".schoolReconfigurationsDetailEnrollmentButtonField");
            const allCheckboxes = document.getElementsByClassName("schoolReconfigurationsDetailCheckboxField");
            const allEnrollmentFields = document.getElementsByClassName("schoolReconfigurationsDetailEnrollmentField");
            const schoolReconfigurationsDetailEnrollmentSelectField = <HTMLSelectElement>document.querySelector(".schoolReconfigurationsDetailEnrollmentSelectField");

            if (checked) {
                for (const checkbox of allCheckboxes) {
                    const element = <HTMLInputElement>checkbox;

                    if (!(element.classList.contains("schoolReconfigurationsDetailTelephoneChange") || element.classList.contains("schoolReconfigurationsDetailOnlyTelephoneChange"))) {
                        element.checked = false;
                        element.disabled = true;
                    }
                }

                for (const enrollmentElement of allEnrollmentFields) {
                    const element = <HTMLInputElement>enrollmentElement;

                    this._core.forceElementOptional(element);
                    element.readOnly = true;
                }


                if (schoolReconfigurationsDetailEnrollmentButtonField !== null)
                    schoolReconfigurationsDetailEnrollmentButtonField.disabled = true;

                if (schoolReconfigurationsDetailEnrollmentSelectField !== null) {
                    schoolReconfigurationsDetailEnrollmentSelectField.disabled = true;
                    this._core.forceElementOptional(schoolReconfigurationsDetailEnrollmentSelectField);
                }
            } else {
                for (const checkbox of allCheckboxes) {
                    const element = <HTMLInputElement>checkbox;

                    if (!(element.classList.contains("schoolReconfigurationsDetailTelephoneChange") || element.classList.contains("schoolReconfigurationsDetailOnlyTelephoneChange"))) {
                        element.disabled = false;
                    }
                }

                for (const enrollmentElement of allEnrollmentFields) {
                    const element = <HTMLInputElement>enrollmentElement;

                    this._core.forceElementRequired(element);
                    element.readOnly = false;
                }


                if (schoolReconfigurationsDetailEnrollmentButtonField !== null)
                    schoolReconfigurationsDetailEnrollmentButtonField.disabled = false;

                if (schoolReconfigurationsDetailEnrollmentSelectField !== null) {
                    schoolReconfigurationsDetailEnrollmentSelectField.disabled = false;
                    this._core.forceElementRequired(schoolReconfigurationsDetailEnrollmentSelectField);
                }
            }
        }
    }
}

// School Reconfigurations School Closure 
class SchoolReconfigurationsSchoolClosure  {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["schoolReconfigurationsClosureCheckboxField", "schoolReconfigurationsClosureField", "schoolReconfigurationsClosureUploadField", "schoolReconfigurationsClosureRadioField", "schoolReconfigurationsClosureSelectField"];

        let specialEducationProfileSaveButton = document.getElementById("schoolReconfigurationsSchoolClosureSave");
        if (specialEducationProfileSaveButton !== null)
            specialEducationProfileSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        let showMessage = this.initializeRequiredFieldsCustom(this.validationClasses);
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses, showMessage);
        }
        
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let schoolReconfigurationsClosureCreateButton = document.getElementById("schoolReconfigurationsClosureCreate");
        if (schoolReconfigurationsClosureCreateButton !== null)
            schoolReconfigurationsClosureCreateButton.addEventListener("click", (e: Event) => this.createSchoolClosure(e));

        let deleteFileConfirmButton = <HTMLButtonElement>document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e: Event) => this.deleteFileConfirm(deleteFileConfirmButton));

        let deleteFileCancelButton = <HTMLButtonElement>document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e: Event) => this.deleteFileCancel());

        let deleteFileButtons = document.getElementsByClassName("deleteFile") as HTMLCollectionOf<HTMLButtonElement>;
        for (let deleteButton of deleteFileButtons)
            deleteButton.addEventListener("click", (e: Event) => this.deleteFile(e));

        let exportToExcelDetailsButton = document.getElementById("schoolReconfigurationsClosureExportToExcel");
        if (exportToExcelDetailsButton != null) {
            exportToExcelDetailsButton.addEventListener("click", () => {
                Core.showLoader();
                this.exportToExcel()
                    .then((response) => {
                        Core.hideLoader();
                    })
                    .catch((error) => {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                    });
            });
        }

        this.bindUploadButtons();

        this.bindDeleteSchoolClosureButtons();

        let deleteReconfigurationClosureCancelButton = document.getElementById("deleteClosureCancel");
        if (deleteReconfigurationClosureCancelButton !== null)
            deleteReconfigurationClosureCancelButton.addEventListener("click", (e: Event) => this.deleteClosureCancel());

        let deleteReconfigurationClosureConfirmButton = document.getElementById("deleteClosureConfirm");
        if (deleteReconfigurationClosureConfirmButton !== null)
            deleteReconfigurationClosureConfirmButton.addEventListener("click", (e: Event) => this.deleteClosureConfirm(e));

        let reconfigurationDetailsNoClosuresCheckbox = document.getElementById("reconfigurationdetailsclosingnoclosings");
        if (reconfigurationDetailsNoClosuresCheckbox !== null)
            reconfigurationDetailsNoClosuresCheckbox.addEventListener("click", (e: Event) => this.noClosures())

        this.noClosures();
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let allFileElements = [];

        let planPK = 0;

        let profileFormElement = <HTMLDivElement>document.getElementById("schoolReconfigurationsSchoolClosureForm");

        let inputs = document.getElementsByClassName("schoolReconfigurationsClosureField");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanPropertyExtended = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                    ButtonRow: 0
                };

                allSaveElements.push(saveItem);
            }
        }

        let checkboxes = document.getElementsByClassName("schoolReconfigurationsClosureCheckboxField");
        for (let ele of checkboxes) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.checked || hadValue) {
                let val = "";
                if (element.checked)
                    val = "on";
                else
                    val = "off";

                let saveItem: IPlanPropertyExtended = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                    ButtonRow: 0
                };

                allSaveElements.push(saveItem);
            }
        }

        let checkboxesNoClosure = document.getElementsByClassName("schoolReconfigurationsClosureCheckboxNoClosureField");
        for (let ele of checkboxesNoClosure) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.checked || hadValue) {
                let val = "";
                if (element.checked)
                    val = "on";
                else
                    val = "off";

                let saveItem: IPlanPropertyExtended = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                    ButtonRow: 0
                };

                allSaveElements.push(saveItem);
            }
        }

        let radios = document.getElementsByClassName("schoolReconfigurationsClosureRadioField");
        for (let ele of radios) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                if (element.value !== "" || hadValue) {
                    let saveItem: IPlanPropertyExtended = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                        ButtonRow: 0
                    };

                    allSaveElements.push(saveItem);
                }
            }
        }

        let selects = document.getElementsByClassName("schoolReconfigurationsClosureSelectField");
        for (let selectEle of selects) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);

            let selectElement = <HTMLSelectElement>selectEle;
            let rowNumber = selectElement.dataset.row;
            let propertyPK = parseInt(selectElement.dataset.propertypk);
            let hadValue = false;
            if (selectElement.dataset.planpropertypk !== "" && selectElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(selectElement.dataset.planpropertypk);
                hadValue = true;
            }

            if ((selectElement.value !== "" && selectElement.value !== "0") || (hadValue && selectElement.value !== "0")) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: "",
                    LookupCodeFK: parseInt(selectElement.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let files = document.getElementsByClassName("schoolReconfigurationsClosureUploadFileField");
        for (let ele of files) {
            planPK = parseInt(profileFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;

            let sequenceNbr = parseInt(element.dataset.sequencenbr);
            let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk)
            let propertyRelationshipTypePK = parseInt(element.dataset.propertyrelationtypepk);
            let fileUploadPK = parseInt(element.dataset.fileuploadpk);
            let propertyPK = parseInt(element.dataset.propertypk);
            let planPropertyPlanPropertyFilePK = parseInt(element.dataset.planpropertyplanpropertyfilepk);

            let saveItem: IPlanPropertyPlanPropertyFile = {
                PlanPropertyPK: dataPointPlanPropertyPK,
                PlanPropertyFilePK: planPropertyFilePK,
                SequenceNbr: sequenceNbr,
                PropertyRelationshipTypePK: propertyRelationshipTypePK,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanPropertyPlanPropertyFilePK: planPropertyPlanPropertyFilePK
            };

            allFileElements.push(saveItem);
        }

        var allData = {
            "ElementData": allSaveElements,
            "FileData": allFileElements
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SchoolReconfigurations/SaveSchoolReconfigurationsBuildingClosure', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Page saved! Reloading.", 'success', 3000, null);
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }

    createSchoolClosure(e: Event) {
        let dropdown = <HTMLSelectElement>document.getElementById("reconfigurationdetailsclosingnameofschool");
        let dropdownValue = parseInt(dropdown.value);

        let planForm = document.getElementById("schoolReconfigurationsSchoolClosureForm");
        let planFK = planForm.dataset.planfk;

        let core = this._core;
        let that = this;
        let newRow = 0;

        if (dropdownValue > 0) {
            Core.showLoader();

            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SchoolReconfigurations/AddSchoolClosure', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    let container = document.getElementById("schoolClosureList");
                    let newDiv = <HTMLDivElement>document.createElement("div");

                    newDiv.innerHTML = xhr.responseText;

                    container.appendChild(newDiv);

                    let idControl = <HTMLButtonElement>newDiv.querySelector(".Accordion h2 button");
                    let id = idControl.id;

                    new CustomAccordion(id);

                    that.bindUploadButtons();

                    that.initializeNewAccordion();

                    Core.createHTMLAlert("alertMessageDiv", "Building Closure Added", 'success', 3000, null);

                    let reconfigurationDetailsNoReconfigurationsCheckbox = <HTMLInputElement>document.getElementById("reconfigurationdetailsnoreconfigurations");
                    if (reconfigurationDetailsNoReconfigurationsCheckbox !== null)
                        if (reconfigurationDetailsNoReconfigurationsCheckbox.checked)
                            reconfigurationDetailsNoReconfigurationsCheckbox.checked = false;

                    reconfigurationDetailsNoReconfigurationsCheckbox.disabled = true;
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", `Request failed.  Returned status of '${xhr.status}'`, 'error', 3000, null);
                }
            };
            xhr.send(`institutionPK=${dropdownValue}&planFK=${planFK}&row=${newRow}`);
        }
    }

    bindUploadButtons() {
        let fileUploaders = document.getElementsByClassName("schoolReconfigurationsClosureUploadField");
        for (let fileUploader of fileUploaders)
            fileUploader.addEventListener("change", (e: Event) => this.uploadFile(<HTMLInputElement>e.target));
    }

    uploadFile(e: HTMLInputElement) {
        let core = this._core;
        Core.showLoader();
        let formName = e.dataset.formname;
        let dataPointPlanPropertyFK = e.dataset.datapointplanpropertypk;

        let uploadSampleForm = <HTMLFormElement>document.getElementById(formName);
        let formData = new FormData(uploadSampleForm);
        let propertyPK = e.dataset.propertypk;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SchoolReconfigurations/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();

                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);

                        let formFile = <HTMLInputElement>document.querySelector(`.schoolReconfigurationsClosureUploadField[data-datapointplanpropertypk='${dataPointPlanPropertyFK}']`);
                        formFile.value = "";

                        let fileList = document.querySelector(`.schoolReconfigurationsClosureFileList[data-datapointplanpropertypk='${dataPointPlanPropertyFK}']`)

                        let currentFileList = fileList.querySelectorAll(".schoolReconfigurationsClosureUploadField");

                        let sequence = -1;
                        for (let file of currentFileList) {
                            let fileEle = <HTMLDivElement>file;

                            let thisSequence = parseInt(fileEle.dataset.sequencenbr);
                            if (thisSequence > sequence) {
                                sequence = thisSequence;
                            }
                        }


                        let uploadedContainer = document.querySelector(`.closureUploadedFiles[data-datapointplanpropertypk='${dataPointPlanPropertyFK}']`);
                        uploadedContainer.classList.remove("hide");

                        sequence++;

                        let propertyRelationTypePK = e.dataset.propertyrelationtypepk;

                        let fileWrapper = <HTMLDivElement>document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = <HTMLDivElement>document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = <HTMLAnchorElement>document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.planpropertypk = dataPointPlanPropertyFK.toString();
                        fileA.dataset.datapointplanpropertypk = dataPointPlanPropertyFK.toString();
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.dataset.sequencenbr = sequence.toString();
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.propertyrelationtypepk = propertyRelationTypePK;
                        fileA.dataset.planpropertyplanpropertyfilepk = "0";
                        fileA.classList.add("schoolReconfigurationsClosureUploadFileField");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);

                        fileList.appendChild(fileWrapper);
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }

    bindDeleteButtons() {
        let fileButtons = document.getElementsByClassName("deleteFile");

        for (let fileButton of fileButtons)
            fileButton.addEventListener("click", (e: Event) => this.deleteFile(e));
    }

    deleteFile(e: Event) {
        let fileButton = <HTMLButtonElement>e.target;
        let planPropertyFilePK = fileButton.dataset.planpropertyfilepk;

        if (planPropertyFilePK && parseInt(planPropertyFilePK) > 0) {
            let modal: Modal = new Modal("deleteFileModal", null);
            let deleteConfirmButton = <HTMLButtonElement>document.getElementById("deleteFileConfirm");
            deleteConfirmButton.dataset.planpropertyfilepk = planPropertyFilePK;
            modal.show();
        }
    }

    deleteFileConfirm(confirmButton: HTMLButtonElement) {
        let core = this._core;
        Core.showLoader();

        let planPropertyFilePK = confirmButton.dataset.planpropertyfilepk;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SchoolReconfigurations/DeleteFiles', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file has been successfully deleted.", 'success', 3000, null);

                        let containerElement = <HTMLElement>document.querySelector(`.uploadFileColumn[data-planpropertyfilepk='${planPropertyFilePK}']`);
                        if (containerElement !== null) {
                            containerElement.parentNode.removeChild(containerElement);
                        }

                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting file.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file removal failed. Please try again.", 'error', 3000, null);
            }

            let modal: Modal = new Modal("deleteFileModal", null);
            modal.hide();
        };
        xhr.send(JSON.stringify([planPropertyFilePK]));
    }

    deleteFileCancel() {
        let modal: Modal = new Modal("deleteFileModal", null);
        modal.hide();
    }

    initializeRequiredFieldsCustom(validationClasses: string[]) {
        let byPass: boolean = false;
        let showMessage: boolean = false;
        let totalErrors = 0;

        let reconfigurationDetailsNoClosuresCheckbox = <HTMLInputElement>document.getElementById("reconfigurationdetailsclosingnoclosings");
        if (reconfigurationDetailsNoClosuresCheckbox !== null) {
            if (reconfigurationDetailsNoClosuresCheckbox.checked)
                byPass = true;
        }

        if (!byPass) {

            let formattedAllClasses = [];

            for (const cssClass of validationClasses)
                formattedAllClasses.push(`.${cssClass}`);

            let classesToValidate = formattedAllClasses.join(",");

            let allElements = document.querySelectorAll(classesToValidate);

            for (let element of allElements) {
                let htmlElement = <HTMLElement>element;

                if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {

                    if (!(htmlElement.hasAttribute("aria-required") && htmlElement.getAttribute("aria-required") === "true")) {
                        htmlElement.setAttribute("aria-required", "true");
                        htmlElement.setAttribute("force-required", "true");

                        let label = Core.findLabelForInput(htmlElement);

                        if (label !== null && !label.classList.contains("isRequired")) {
                            label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                            label.classList.add("isRequired");
                        }
                    }
                }

                //Manual override when data-forcerequired=true
                if ("forcerequired" in htmlElement.dataset && htmlElement.dataset.forcerequired === "true") {

                    htmlElement.dataset.percent = "1.00";

                    if (!(htmlElement.hasAttribute("aria-required") && htmlElement.getAttribute("aria-required") === "true")) {
                        htmlElement.setAttribute("aria-required", "true");

                        let label = Core.findLabelForInput(htmlElement);

                        if (label !== null && !label.classList.contains("isRequired")) {
                            label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                            label.classList.add("isRequired");
                        }
                    }
                }
            }

            for (let element of allElements) {
                let htmlElement = <HTMLElement>element;

                if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {
                    if (element instanceof HTMLInputElement) {
                        let inputElement = <HTMLInputElement>element;

                        if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "file") {
                            if ("hasuploaded" in inputElement.dataset && inputElement.dataset.hasuploaded != "true") {
                                inputElement.classList.add("missing-field");
                                inputElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(inputElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        } else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "text" && inputElement.value === "") {
                            inputElement.classList.add("missing-field");
                            inputElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(inputElement);
                            showMessage = true;
                            totalErrors++;
                        } else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "radio" && inputElement.value === "") {
                            if (inputElement.hasAttribute("name")) {
                                let radioName = inputElement.getAttribute("name");
                                let radioButtons = document.getElementsByName(radioName);
                                let alreadyValidated = false;
                                let isChecked = false;
                                for (var i = 0, length = radioButtons.length; i < length; i++) {
                                    let radioElement = radioButtons[i] as HTMLInputElement;
                                    if (radioElement.classList.contains("missing-field")) {
                                        alreadyValidated = true;
                                    }
                                    if (radioElement.checked) {
                                        isChecked = true;
                                    }
                                }
                                if (!alreadyValidated && !isChecked) {
                                    inputElement.classList.add("missing-field");
                                    inputElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(inputElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                        }
                    } else if (element instanceof HTMLTextAreaElement) {
                        let textAreaElement = <HTMLTextAreaElement>element;
                        if (textAreaElement.value === "") {
                            textAreaElement.classList.add("missing-field");
                            textAreaElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(textAreaElement);
                            showMessage = true;
                            totalErrors++;
                        }
                    } else if (element instanceof HTMLSelectElement) {
                        let textAreaElement = <HTMLSelectElement>element;
                        if (textAreaElement.value === "") {
                            textAreaElement.classList.add("missing-field");
                            textAreaElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(textAreaElement);
                            showMessage = true;
                            totalErrors++;
                        }
                    }
                }
            }

            let message = <HTMLDivElement>document.getElementById("validationMessage");

            if (totalErrors === 1) {
                message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
            } else {
                message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
            }
            let goToError = document.getElementById("goToFirstError");

            if (goToError !== null) {

                let that = this;

                let firstFocusableEl = <HTMLElement>document.querySelector(".missing-field");

                if (firstFocusableEl !== null) {
                    goToError.addEventListener("click", function () {
                        let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                        if (accordion) {
                            let id = accordion.getAttribute("aria-labelledby");

                            let accordionElement = <HTMLButtonElement>document.getElementById(id);
                            accordionElement.click();
                        }

                        if (firstFocusableEl.classList.contains("mce")) {
                            tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                        } else {
                            firstFocusableEl.focus();
                        }
                    });
                } else {
                    goToError.parentNode.removeChild(goToError);
                }
            }

            this._core.initializeRequiredFields(validationClasses);
            return showMessage;
        }

        this._core.initializeRequiredFields(validationClasses);
    }

    initializeNewAccordion() {
        let formattedAllClasses = [];

        formattedAllClasses.push(".schoolReconfigurationsClosureField");
        formattedAllClasses.push(".schoolReconfigurationsClosureRadioField");

        let classesToValidate = formattedAllClasses.join(",");

        let allElements = document.querySelectorAll(classesToValidate);

        for (let element of allElements) {
            let htmlElement = <HTMLElement>element;

            if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {

                if (!(htmlElement.hasAttribute("aria-required") && htmlElement.getAttribute("aria-required") === "true")) {
                    htmlElement.setAttribute("aria-required", "true");
                    htmlElement.setAttribute("force-required", "true");

                    let label = Core.findLabelForInput(htmlElement);

                    if (label !== null && !label.classList.contains("isRequired")) {
                        label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                        label.classList.add("isRequired");
                    }
                }
            }

            //Manual override when data-forcerequired=true
            if ("forcerequired" in htmlElement.dataset && htmlElement.dataset.forcerequired === "true") {

                htmlElement.dataset.percent = "1.00";

                if (!(htmlElement.hasAttribute("aria-required") && htmlElement.getAttribute("aria-required") === "true")) {
                    htmlElement.setAttribute("aria-required", "true");

                    let label = Core.findLabelForInput(htmlElement);

                    if (label !== null && !label.classList.contains("isRequired")) {
                        label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                        label.classList.add("isRequired");
                    }
                }
            }
        }
    }

    exportToExcel() {
        let that = this;
        return new Promise<void>((resolve, reject) => {
            let planForm = document.getElementById("schoolReconfigurationsSchoolClosureForm");
            let planFK = parseInt(planForm.dataset.planfk);

            let xhr = new XMLHttpRequest();
            xhr.open('POST', `/ExportExcel/SchoolReconfigurationsClosureExcelExport`, true);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.responseType = "blob";
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let blob = this.response;
                    let filename = `SchoolReconfigurationClosure.xlsx`;
                    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                        window.navigator.msSaveBlob(blob, filename);
                    }
                    else {
                        var a = document.createElement("a");
                        var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                        document.body.appendChild(a);
                        a.style.display = "none";
                        a.href = blobUrl;
                        a.download = filename;
                        a.click();
                    }
                    resolve();
                }
                else {
                    reject("There was an issue during export to Excel. Please try again later.");
                }
            };
            xhr.send(`planFK=${planFK}`);
        });
    }

    bindDeleteSchoolClosureButtons() {
        let buttons = document.getElementsByClassName("deleteThisSchoolClosure");
        for (let button of buttons)
            button.addEventListener("click", (e: Event) => this.deleteSchoolClosure(e));
    }

    deleteSchoolClosure(e: Event) {
        this.showDeleteClosureConfirm(e);
    }

    showDeleteClosureConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let dataPointPlanPropertyPK = buttonElement.dataset.datapointplanpropertypk;

        let modal: Modal = new Modal("deleteSchoolClosureModal", null);
        modal.addAttributeToElement("deleteSchoolClosureModal", "#deleteClosureConfirm", "datapointplanpropertypk", dataPointPlanPropertyPK);
        modal.show();
    }

    deleteClosureCancel() {
        let modal: Modal = new Modal("deleteSchoolClosureModal", null);
        modal.hide();
    }

    deleteClosureConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let planProps = [];
        let dataPointPlanPropertyPK = buttonElement.dataset.datapointplanpropertypk;

        let container = <HTMLDivElement>document.querySelector(`.schoolReconfigurationSection[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        let allElements = container.querySelectorAll(`.schoolReconfigurationsClosureField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        for (let ele of allElements) {
            let element = <HTMLElement>ele;
            let planProp = element.dataset.planpropertypk;

            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }

        let allRadioElements = container.querySelectorAll(`.schoolReconfigurationsClosureRadioField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        for (let radEle of allRadioElements) {
            let element = <HTMLElement>radEle;
            let planProp = element.dataset.planpropertypk;

            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }

        planProps.push(dataPointPlanPropertyPK);

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SchoolReconfigurations/DeleteSchoolClosure', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();

                let accordionElement = <HTMLButtonElement>document.querySelector(`.Accordion-trigger[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                accordionElement.parentElement.parentElement.parentElement.remove();

                let modal: Modal = new Modal("deleteSchoolClosureModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The school closure has been removed.", 'success', 3000, null);
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }

    noClosures() {
        let reconfigurationDetailsNoClosuresCheckbox = <HTMLInputElement>document.getElementById("reconfigurationdetailsclosingnoclosings");

        let schoolReconfigurationList = <HTMLDivElement>document.getElementById("schoolClosureList");
        let alreadyExistsElements = schoolReconfigurationList.querySelectorAll(".Accordion");
        let alreadyExists = false;

        if (alreadyExistsElements.length > 0)
            alreadyExists = true;

        if (alreadyExists) {
            reconfigurationDetailsNoClosuresCheckbox.disabled = true;
        }

        if (reconfigurationDetailsNoClosuresCheckbox !== null) {
            let schoolReconfigurationsCreateButton = <HTMLButtonElement>document.getElementById("schoolReconfigurationsClosureCreate");
            if (reconfigurationDetailsNoClosuresCheckbox.checked) {
                schoolReconfigurationsCreateButton.disabled = true;
            } else {
                schoolReconfigurationsCreateButton.disabled = false;
            }
        }
    }
}

// Signatures & Assurances
class SchoolReconfigurationsSignaturesAndAssurances {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["schoolReconfigurationsSignaturesField", "schoolReconfigurationsSignaturesFileField", "schoolReconfigurationsSignaturesCheckboxField"];

        let specialEducationSignaturesSaveButton = document.getElementById("schoolReconfigurationsSignaturesSave");
        if (specialEducationSignaturesSaveButton !== null)
            specialEducationSignaturesSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        let fileUploaderBoardStatement = document.getElementById("reconfigurationdetailssignaturesuploadboardstatement");
        if (fileUploaderBoardStatement !== null) {
            fileUploaderBoardStatement.addEventListener("change", (e: Event) => this.uploadFile(<HTMLInputElement>e.target, "reconfigurationdetailssignaturesuploadboardstatementForm", "reconfigurationdetailssignaturesuploadboardstatement"));
        }

        let fileUploaderLEAReconfigurationLetter = document.getElementById("reconfigurationdetailssignaturesuploadleareconfigurationletter");
        if (fileUploaderLEAReconfigurationLetter !== null) {
            fileUploaderLEAReconfigurationLetter.addEventListener("change", (e: Event) => this.uploadFile(<HTMLInputElement>e.target, "reconfigurationdetailssignaturesuploadleareconfigurationletterForm", "reconfigurationdetailssignaturesuploadleareconfigurationletter"));
        }

        this.checkForOnlyAdminChanges();

        const schoolReconfigurationsSignaturesNoAdminChangesCheckbox = document.getElementById("reconfigurationSignaturesAdministratorChangesOnly");
        if (schoolReconfigurationsSignaturesNoAdminChangesCheckbox !== null)
            schoolReconfigurationsSignaturesNoAdminChangesCheckbox.addEventListener("change", (e: Event) => this.checkForOnlyAdminChanges());

        const reconfigurationSignaturesUploadofBoardStatementorAffirmationStatement = document.getElementById("reconfigurationSignaturesUploadofBoardStatementorAffirmationStatement");
        if (reconfigurationSignaturesUploadofBoardStatementorAffirmationStatement !== null)
            reconfigurationSignaturesUploadofBoardStatementorAffirmationStatement.addEventListener("change", (e: Event) => this.uploadFileSignatures(e.target as HTMLInputElement, "reconfigurationSignaturesUploadofBoardStatementorAffirmationStatementFormId", "reconfigurationSignaturesUploadofBoardStatementorAffirmationStatement"))

        const reconfigurationSignaturesUploadofLEAReconfigurationLetter = document.getElementById("reconfigurationSignaturesUploadofLEAReconfigurationLetter");
        if (reconfigurationSignaturesUploadofLEAReconfigurationLetter !== null)
            reconfigurationSignaturesUploadofLEAReconfigurationLetter.addEventListener("change", (e: Event) => this.uploadFileSignatures(e.target as HTMLInputElement, "reconfigurationSignaturesUploadofLEAReconfigurationLetterFormId", "reconfigurationSignaturesUploadofLEAReconfigurationLetter"))

        const reconfigurationSignaturesUploadAccuracyCertificationStatementACSOptional = document.getElementById("reconfigurationSignaturesUploadAccuracyCertificationStatementACSOptional");
        if (reconfigurationSignaturesUploadAccuracyCertificationStatementACSOptional !== null)
            reconfigurationSignaturesUploadAccuracyCertificationStatementACSOptional.addEventListener("change", (e: Event) => this.uploadFileSignatures(e.target as HTMLInputElement, "reconfigurationSignaturesUploadAccuracyCertificationStatementACSOptionalFormId", "reconfigurationSignaturesUploadAccuracyCertificationStatementACSOptional"))

        const reconfigurationSignaturesUploadofExecutedCharter = document.getElementById("reconfigurationSignaturesUploadofExecutedCharter");
        if (reconfigurationSignaturesUploadofExecutedCharter !== null)
            reconfigurationSignaturesUploadofExecutedCharter.addEventListener("change", (e: Event) => this.uploadFileSignatures(e.target as HTMLInputElement, "reconfigurationSignaturesUploadofExecutedCharterFormId", "reconfigurationSignaturesUploadofExecutedCharter"))

        const reconfigurationSignaturesUploadofAuthorizingSchoolDistrictMinutes = document.getElementById("reconfigurationSignaturesUploadofAuthorizingSchoolDistrictMinutes");
        if (reconfigurationSignaturesUploadofAuthorizingSchoolDistrictMinutes !== null)
            reconfigurationSignaturesUploadofAuthorizingSchoolDistrictMinutes.addEventListener("change", (e: Event) => this.uploadFileSignatures(e.target as HTMLInputElement, "reconfigurationSignaturesUploadofAuthorizingSchoolDistrictMinutesFormId", "reconfigurationSignaturesUploadofAuthorizingSchoolDistrictMinutes"))

        this.bindDeleteFileButtons();

        const reconfigurationSignaturesCharterSchool = document.getElementById("reconfigurationSignaturesCharterSchool");
        if (reconfigurationSignaturesCharterSchool !== null)
            reconfigurationSignaturesCharterSchool.addEventListener("change", (e: Event) => this.checkForCharter());

        this.checkForCharter();

        let deleteFileConfirmButton = <HTMLButtonElement>document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e: Event) => this.deleteFileConfirm(deleteFileConfirmButton));

        let deleteFileCancelButton = <HTMLButtonElement>document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e: Event) => this.deleteFileCancel());

        this._core.leftnav(this);

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.doCustomValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let ceoNameInInstructionsTextField = <HTMLInputElement>document.getElementById("reconfigurationdetailssignaturessuperintendent");
        if (ceoNameInInstructionsTextField !== null) {
            ceoNameInInstructionsTextField.addEventListener("input", (e: Event) => this.updateName(e));

            if (ceoNameInInstructionsTextField.value !== "") {
                let ceoNameElement = <HTMLSpanElement>document.getElementById("ceoNameInInstructions");

                ceoNameElement.textContent = ceoNameInInstructionsTextField.value;
            }
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let allFileElements = [];

        const formElement = <HTMLDivElement>document.getElementById("schoolReconfigurationsSignaturesForm");
        const planPK = parseInt(formElement.dataset.planfk);

        const inputs = document.getElementsByClassName("schoolReconfigurationsSignaturesField");
        for (let ele of inputs) {
            let planPropertyPK = 0;

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanPropertyExtended = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                    ButtonRow: 0
                };

                allSaveElements.push(saveItem);
            }
        }

        const checkboxes = document.getElementsByClassName("schoolReconfigurationsSignaturesCheckboxField");
        for (let ele of checkboxes) {
            let planPropertyPK = 0;

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.checked || hadValue) {
                let val = "";
                if (element.checked)
                    val = "on";
                else
                    val = "off";

                let saveItem: IPlanPropertyExtended = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                    ButtonRow: 0
                };

                allSaveElements.push(saveItem);
            }
        }

        const files = document.getElementsByClassName("schoolReconfigurationsSignaturesFileUploadField");
        for (let ele of files) {
            let element = <HTMLInputElement>ele;
            let sequenceNbr = parseInt(element.dataset.sequencenbr);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk)
            let fileUploadPK = parseInt(element.dataset.fileuploadpk);
            let propertyPK = parseInt(element.dataset.propertypk);

            let saveItem: IPlanPropertyFile = {
                PlanPropertyFilePK: planPropertyFilePK,
                SequenceNbr: sequenceNbr,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanFK: planPK
            };

            allFileElements.push(saveItem);
        }

        const allData = {
            "SaveData": allSaveElements,
            "FileData": allFileElements,
            "PlanFK": planPK
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SchoolReconfigurations/SaveSchoolReconfigurationsSignatures', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.createHTMLAlert("alertMessageDiv", "Page saved! Reloading.", 'success', 3000, null);
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0 && allFileElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }

    uploadFileSignatures(e: HTMLInputElement, formElementId, fileElementId) {
        let core = this._core;
        Core.showLoader();
        const formElement = document.getElementById(formElementId) as HTMLFormElement;
        let formName = e.dataset.formname;

        let formData = new FormData(formElement);

        let propertyPK = e.dataset.propertypk;

        const that = this;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SchoolReconfigurations/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();

                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);

                        let formFile = <HTMLInputElement>document.getElementById(fileElementId);
                        formFile.value = "";

                        let fileList = document.querySelector(`.uploadFileList[data-formname='${formName}']`)

                        let currentFileList = document.querySelectorAll(".uploadFileList[data-formname='${formName}']");

                        let sequence = -1;
                        for (let file of currentFileList) {
                            let fileEle = <HTMLDivElement>file;

                            let thisSequence = parseInt(fileEle.dataset.sequencenbr);
                            if (thisSequence > sequence) {
                                sequence = thisSequence;
                            }
                        }


                        let uploadedContainer = document.querySelector(`.uploadedFiles[data-formname='${formName}']`);
                        uploadedContainer.classList.remove("hide");

                        sequence++;

                        let propertyRelationTypePK = e.dataset.propertyrelationtypepk;

                        let fileWrapper = <HTMLDivElement>document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = <HTMLDivElement>document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = <HTMLAnchorElement>document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.sequencenbr = sequence.toString();
                        fileA.dataset.propertyrelationtypepk = propertyRelationTypePK;
                        fileA.dataset.planpropertyplanpropertyfilepk = "0";
                        fileA.classList.add("schoolReconfigurationsSignaturesFileUploadField");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);

                        fileList.appendChild(fileWrapper);

                        that.bindDeleteFileButtons();
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }

    uploadFile(e: HTMLInputElement, formElementId, fileElementId) {
        let core = this._core;
        Core.showLoader();
        let uploadSampleForm = <HTMLFormElement>document.getElementById(formElementId);
        let formData = new FormData(uploadSampleForm);

        const that = this;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SchoolReconfigurations/UploadFile', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file has been successfully uploaded! Save your progress to see the updated list of files.", 'success', 3000, null);

                        let formFile = <HTMLInputElement>document.getElementById(fileElementId);
                        formFile.value = "";
                        formFile.dataset.hasuploaded = "true";

                        that.bindDeleteFileButtons();
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }

    bindDeleteFileButtons() {
        let deleteFileButtons = document.getElementsByClassName("deleteFile") as HTMLCollectionOf<HTMLButtonElement>;
        for (let deleteButton of deleteFileButtons) {
            if ("planpropertyfilepk" in deleteButton.dataset && parseInt(deleteButton.dataset.planpropertyfilepk) > 0) {
                deleteButton.addEventListener("click", (e: Event) => this.showDeleteFileConfirmation(deleteButton));
            }
        }
    }

    showDeleteFileConfirmation(deleteButton: HTMLButtonElement) {
        let planPropertyFilePK = deleteButton.dataset.planpropertyfilepk;

        if (planPropertyFilePK && parseInt(planPropertyFilePK) > 0) {
            let modal: Modal = new Modal("deleteFileModal", null);
            let deleteConfirmButton = <HTMLButtonElement>document.getElementById("deleteFileConfirm");
            deleteConfirmButton.dataset.planpropertyfilepk = planPropertyFilePK;
            modal.show();
        }
    }

    deleteFileConfirm(confirmButton: HTMLButtonElement) {
        let core = this._core;
        Core.showLoader();

        let planPropertyFilePK = confirmButton.dataset.planpropertyfilepk;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SchoolReconfigurations/DeleteFiles', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file has been successfully deleted.", 'success', 3000, null);

                        let fileType = "";
                        let containerElement = <HTMLElement>document.querySelector(`.uploadFileColumn[data-planpropertyfilepk='${planPropertyFilePK}']`);
                        if (containerElement !== null) {
                            containerElement.parentNode.removeChild(containerElement);
                            fileType = containerElement.dataset.filetype;
                        }

                        const fileList = document.querySelectorAll(`.uploadFileList[data-filetype='${fileType}'] .uploadFileColumn[data-filetype='${fileType}']`);
                        if (fileList.length === 0) {
                            const fileListElement = <HTMLDivElement>document.querySelector(`.uploadedFiles[data-filetype='${fileType}']`);

                            fileListElement.classList.add("hide");
                        }

                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting file.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file removal failed. Please try again.", 'error', 3000, null);
            }

            let modal: Modal = new Modal("deleteFileModal", null);
            modal.hide();
        };
        xhr.send(JSON.stringify([planPropertyFilePK]));
    }

    deleteFileCancel() {
        let modal: Modal = new Modal("deleteFileModal", null);
        modal.hide();
    }

    updateName(e: Event) {
        let textElement = <HTMLInputElement>e.target;
        let ceoNameElement = <HTMLSpanElement>document.getElementById("ceoNameInInstructions");

        if (textElement && textElement.value !== "") {
            ceoNameElement.textContent = textElement.value;
        }
    }

    checkForCharter() {
        const charterElement = <HTMLInputElement>document.getElementById("reconfigurationSignaturesCharterSchool");
        if (charterElement != null) {
            const reconfigurationSignaturesUploadofAuthorizingSchoolDistrictMinutes = document.getElementById("reconfigurationSignaturesUploadofAuthorizingSchoolDistrictMinutes") as HTMLInputElement;
            const reconfigurationSignaturesUploadofExecutedCharter = document.getElementById("reconfigurationSignaturesUploadofExecutedCharter") as HTMLInputElement;
            const phoneOnly = document.getElementById("phoneOnly") as HTMLInputElement;
            
            if (charterElement.checked) {
                if(phoneOnly !== null && phoneOnly.value === "true")
                    this._core.forceElementOptional(reconfigurationSignaturesUploadofAuthorizingSchoolDistrictMinutes);
                else
                    this._core.forceElementRequired(reconfigurationSignaturesUploadofAuthorizingSchoolDistrictMinutes);

                this._core.forceElementRequired(reconfigurationSignaturesUploadofExecutedCharter);
            } else {
                this._core.forceElementOptional(reconfigurationSignaturesUploadofAuthorizingSchoolDistrictMinutes);
                this._core.forceElementOptional(reconfigurationSignaturesUploadofExecutedCharter);
            }
        }
    }

    doCustomValidation(allClasses: string[]) {
        let showMessage = this.clientSideValidationCustom(allClasses);

        let messageContainerColumn = <HTMLElement>document.getElementById("validationColumn");
        let messageContainer = <HTMLElement>document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = <HTMLElement>document.getElementById("validationMessageIcon");

        setTimeout(function () {
            messageContainer.focus();
        }, 500);

        if (showMessage) {

            let message = <HTMLElement>document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";

        } else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";

            let successMessage = <HTMLElement>document.getElementById("saveSuccess");

            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved."
            }
        }
    }

    clientSideValidationCustom(allClasses: string[]) {
        let showMessage: boolean = false;
        let totalErrors = 0;

        const allFileUploaders = document.getElementsByClassName("schoolReconfigurationsSignaturesFileValidationField");
        for (const fileUploader of allFileUploaders) {
            const element = fileUploader as HTMLInputElement;
            if (element.dataset.percent === "1.00") {
                const fileType = element.dataset.filetype;

                const anyFiles = document.querySelectorAll(`.uploadedFiles[data-filetype='${fileType}'] .uploadFileColumn[data-filetype='${fileType}']`);
                if (anyFiles.length === 0) {
                    element.classList.add("missing-field");
                    element.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(element);
                    showMessage = true;
                    totalErrors++;
                }
            }
        }

        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });

        let classesToValidate = formattedAllClasses.join(",");

        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });

        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });

        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });

        let allElements = document.querySelectorAll(classesToValidate);

        for (let element of allElements) {
            let alreadyExists = false;
            let htmlElement = <HTMLElement>element;
            if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {

                //check to see if there are other fields the same as this one on this page (i.e. fields that have the same propertypk)
                var otherElements = document.querySelectorAll(`[data-propertypk='${htmlElement.dataset.propertypk}']`);
                for (let otherElement of otherElements) {
                    let otherHtmlElement = <HTMLElement>otherElement;
                    if (otherHtmlElement.classList.contains("missing-field")) {
                        alreadyExists = true;
                    } else {
                        if (otherHtmlElement instanceof HTMLInputElement) {
                            if (otherHtmlElement.hasAttribute("type") && otherHtmlElement.getAttribute("type") === "radio") {
                                //Check to see if a prior group of radio buttons has already been validated
                                let OtherInputElement = <HTMLInputElement>otherHtmlElement;

                                if (OtherInputElement.hasAttribute("name")) {
                                    let radioGroupName = OtherInputElement.getAttribute("name");

                                    let radios = document.getElementsByName(radioGroupName);
                                    let radioIsChecked: boolean = false;
                                    let isAlreadyValidated: boolean = false;

                                    for (var i = 0, length = radios.length; i < length; i++) {
                                        let radioElement = <HTMLInputElement>radios[i];
                                        if (radioElement.checked) {
                                            radioIsChecked = true;
                                        }

                                        if (radioElement.classList.contains("missing-field")) {
                                            isAlreadyValidated = true;
                                        }
                                    }

                                    if (isAlreadyValidated || radioIsChecked) {
                                        alreadyExists = true;
                                    }
                                }
                            } else {
                                let OtherInputElement = <HTMLInputElement>otherHtmlElement;
                                if (OtherInputElement.value !== "") {
                                    alreadyExists = true;
                                }
                            }
                        } else if (otherHtmlElement instanceof HTMLSelectElement) {
                            let otherSelectElement = <HTMLSelectElement>otherHtmlElement;
                            if (otherSelectElement.selectedIndex > 0 && otherSelectElement.options[otherSelectElement.selectedIndex].value !== "") {
                                alreadyExists = true;
                            }
                        } else if (otherHtmlElement instanceof HTMLTextAreaElement) {
                            let otherTextAreaElement = <HTMLTextAreaElement>otherHtmlElement;
                            if (otherTextAreaElement.value !== "") {
                                alreadyExists = true;
                            }
                        } else if ("multiselectvalidate" in otherHtmlElement.dataset && otherHtmlElement.dataset.multiselectvalidate === "true") {
                            //See if any options have been checked in multiselect
                            let multiselectCheckboxes = otherHtmlElement.getElementsByTagName("input") as HTMLCollectionOf<HTMLInputElement>;
                            for (let selectBox of multiselectCheckboxes) {
                                if (selectBox.checked) {
                                    alreadyExists = true;
                                    break;
                                }
                            }
                        }
                    }
                }

                if (!alreadyExists || ("forcerequired" in htmlElement.dataset && htmlElement.dataset.forcerequired === "true")) {
                    if (!element.classList.contains("missing-field")) {
                        //For more custom validation, use data-is-valid to specify whether a field is valid/invalid
                        if ("isValid" in htmlElement.dataset) {
                            if (htmlElement.dataset.isValid === "false") {
                                htmlElement.classList.add("missing-field");
                                htmlElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(htmlElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        } else {
                            if (element instanceof HTMLInputElement) {
                                let inputElement = <HTMLInputElement>element;

                                //Only validate once for radio buttons
                                if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "radio" && !inputElement.checked) {
                                    if (inputElement.hasAttribute("name")) {
                                        let radioName = inputElement.getAttribute("name");

                                        let radioButtons = document.getElementsByName(radioName);
                                        let alreadyValidated: boolean = false;
                                        let isChecked: boolean = false;

                                        for (var i = 0, length = radioButtons.length; i < length; i++) {
                                            let radioElement = <HTMLInputElement>radioButtons[i];
                                            if (radioElement.classList.contains("missing-field")) {
                                                alreadyValidated = true;
                                            }

                                            if (radioElement.checked) {
                                                isChecked = true;
                                            }
                                        }

                                        if (!alreadyValidated && !isChecked) {
                                            inputElement.classList.add("missing-field");
                                            inputElement.setAttribute("aria-invalid", "true");
                                            Core.createErrorLabelForInput(inputElement);
                                            showMessage = true;
                                            totalErrors++;
                                        }
                                    }
                                } else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "file") {
                                    if ("hasuploaded" in inputElement.dataset && inputElement.dataset.hasuploaded != "true") {
                                        inputElement.classList.add("missing-field");
                                        inputElement.setAttribute("aria-invalid", "true");
                                        Core.createErrorLabelForInput(inputElement);
                                        showMessage = true;
                                        totalErrors++;
                                    }
                                } else if (inputElement.value === "") {
                                    inputElement.classList.add("missing-field");
                                    inputElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(inputElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            } else if (element instanceof HTMLSelectElement) {
                                let selectElement = <HTMLSelectElement>element;
                                //if the page select element does not have a place holder it needs custom validation.  do not use this one.
                                if (selectElement.selectedIndex <= 0 || selectElement.options[selectElement.selectedIndex].value === "") {
                                    selectElement.classList.add("missing-field");
                                    selectElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(selectElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            } else if (element instanceof HTMLTextAreaElement) {
                                let textAreaElement = <HTMLTextAreaElement>element;
                                if (textAreaElement.value === "") {
                                    textAreaElement.classList.add("missing-field");
                                    textAreaElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(textAreaElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            } else if ("multiselectvalidate" in htmlElement.dataset && htmlElement.dataset.multiselectvalidate === "true") {
                                let multiselectCheckboxes = htmlElement.getElementsByTagName("input") as HTMLCollectionOf<HTMLInputElement>;
                                let hasSelection = false;

                                for (let selectBox of multiselectCheckboxes) {
                                    if (selectBox.checked) {
                                        hasSelection = true;
                                        break;
                                    }
                                }

                                if (!hasSelection) {
                                    htmlElement.classList.add("missing-field");
                                    htmlElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(htmlElement);
                                    showMessage = true;
                                    totalErrors++;
                                }

                            }
                        }
                    }
                }
            }
        }

        let message = <HTMLDivElement>document.getElementById("validationMessage");

        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        } else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");

        if (goToError !== null) {

            let that = this;

            let firstFocusableEl = <HTMLElement>document.querySelector(".missing-field");

            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");

                        let accordionElement = <HTMLButtonElement>document.getElementById(id);
                        accordionElement.click();
                    }

                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    } else {
                        firstFocusableEl.focus();
                    }
                });
            } else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }

    checkForOnlyAdminChanges() {
        const schoolReconfigurationsSignaturesNoAdminChangesCheckbox = document.getElementById("reconfigurationSignaturesAdministratorChangesOnly");
        if (schoolReconfigurationsSignaturesNoAdminChangesCheckbox !== null) {
            const checkEle = <HTMLInputElement>schoolReconfigurationsSignaturesNoAdminChangesCheckbox;

            const schoolReconfigurationsSignaturesNoAdminChangesItems = document.getElementsByClassName("schoolReconfigurationsSignaturesNoAdminChangesItem");
            
            for (const schoolReconfigurationsSignaturesNoAdminChangesItem of schoolReconfigurationsSignaturesNoAdminChangesItems) {
                const ele = <HTMLInputElement>schoolReconfigurationsSignaturesNoAdminChangesItem;
                if (checkEle.checked) {
                    ele.readOnly = true
                    ele.disabled = true;
                    this._core.forceElementOptional(ele);
                } else {
                    
                    ele.readOnly = false
                    ele.disabled = false;

                    if (ele.dataset.originalpercent === "1.0")
                        this._core.forceElementRequired(ele);
                }
            }

            const reconfigurationdetailssignaturessuperintendent = <HTMLInputElement>document.getElementById("reconfigurationdetailssignaturessuperintendent");
            const reconfigurationdetailssignaturessuperintendentdate = <HTMLInputElement>document.getElementById("reconfigurationdetailssignaturessuperintendentdate");
            const reconfigurationSignaturesSinglePointofContactName = <HTMLInputElement>document.getElementById("reconfigurationSignaturesSinglePointofContactName");
            const reconfigurationSignaturesSinglePointofContactDate = <HTMLInputElement>document.getElementById("reconfigurationSignaturesSinglePointofContactDate");

            if (checkEle.checked) {
                this._core.forceElementOptional(reconfigurationdetailssignaturessuperintendent);
                this._core.forceElementOptional(reconfigurationdetailssignaturessuperintendentdate);
                this._core.forceElementRequired(reconfigurationSignaturesSinglePointofContactName);
                this._core.forceElementRequired(reconfigurationSignaturesSinglePointofContactDate);
            } else {
                this._core.forceElementRequired(reconfigurationdetailssignaturessuperintendent);
                this._core.forceElementRequired(reconfigurationdetailssignaturessuperintendentdate);
                this._core.forceElementRequired(reconfigurationSignaturesSinglePointofContactName);
                this._core.forceElementRequired(reconfigurationSignaturesSinglePointofContactDate);
            }
        }
    }
}

// Summary & Submission
class SchoolReconfigurationsSummaryChecklistAndSubmission {
    private _core: Core;
    constructor() {
        this._core = new Core();
        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.back(e));

        let submitButton = document.getElementById("submitPlanButton");
        if (submitButton !== null)
            submitButton.addEventListener("click", (e: Event) => this.submit(e));

        this._core.leftnav(this);
    }

    back(e: Event) {
        let newPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }

    getCore() {
        return this._core;
    }

    submit(e: Event) {
        let element = <HTMLButtonElement>e.target;
        if (!element.classList.contains("disabled")) {
            let planFK = 0;
            planFK = parseInt(element.dataset.planfk);
            let core = this._core;
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SchoolReconfigurations/SubmitPlan', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "This report has been submitted for review!", 'success', 3000, window.location.reload());

                    setTimeout(function () { window.location.href = '/Reports/StateRequiredReports' }, 3000);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "An unexpected error occurred while submitting report. Please try again later.", 'error', 3000, null);
                }
            };
            xhr.send("planFK=" + planFK);
        }
    }
}

// SchoolReconfigurationsOpening
class SchoolReconfigurationsOpening {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["schoolReconfigurationsOpeningField", "schoolReconfigurationsOpeningCheckboxField", "schoolReconfigurationsOpeningRadioField", "schoolReconfigurationsOpeningSelectField", "schoolReconfigurationsOpeningFileField"];

        let reconfigurationsOpeningSaveButton = document.getElementById("reconfigurationsOpeningSave");
        if (reconfigurationsOpeningSaveButton !== null)
            reconfigurationsOpeningSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        const reconfigurationOpeningRequestInvolves = document.getElementById("reconfigurationOpeningRequestInvolves");
        if (reconfigurationOpeningRequestInvolves !== null)
            reconfigurationOpeningRequestInvolves.addEventListener("change", (e: Event) => this.checkRequest());

        this.bindSameAsMailingAddress();
        this.checkRequest();

        const schoolReconfigurationsOpeningSameAsMailings = document.getElementsByClassName("schoolReconfigurationsOpeningSameAsMailing");
        for (const schoolReconfigurationsOpeningSameAsMailing of schoolReconfigurationsOpeningSameAsMailings)
            this.sameAsMailingAddress(schoolReconfigurationsOpeningSameAsMailing as HTMLInputElement);


        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.doValidationCustom(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses, false, true);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        const schoolReconfigurationsCreateBuildingOpeningButton = document.getElementById("schoolReconfigurationsCreateBuildingOpening");
        if (schoolReconfigurationsCreateBuildingOpeningButton !== null)
            schoolReconfigurationsCreateBuildingOpeningButton.addEventListener("click", (e: Event) => this.createSchoolOpening());

        let deleteFileConfirmButton = <HTMLButtonElement>document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e: Event) => this.deleteFileConfirm(deleteFileConfirmButton));

        let deleteFileCancelButton = <HTMLButtonElement>document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e: Event) => this.deleteFileCancel());

        let deleteReconfigurationClosureCancelButton = document.getElementById("deleteOpeningCancel");
        if (deleteReconfigurationClosureCancelButton !== null)
            deleteReconfigurationClosureCancelButton.addEventListener("click", (e: Event) => this.deleteSchoolOpeningCancel());

        let deleteReconfigurationClosureConfirmButton = document.getElementById("deleteOpeningConfirm");
        if (deleteReconfigurationClosureConfirmButton !== null)
            deleteReconfigurationClosureConfirmButton.addEventListener("click", (e: Event) => this.deleteSchoolOpeningConfirm(e));

        this.bindUploadButtons();
        this.bindDeleteFileButtons();
        this.bindDeleteSchoolButtons();
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("reconfigurationsOpeningForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let inputs = document.getElementsByClassName("schoolReconfigurationsOpeningField");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);

            const element = <HTMLInputElement>ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            const dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanPropertyExtended = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                    ButtonRow: 0
                };

                allSaveElements.push(saveItem);
            }
        }

        let radios = document.getElementsByClassName("schoolReconfigurationsOpeningRadioField");
        for (let ele of radios) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);

            const element = <HTMLInputElement>ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            const dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                if (element.value !== "" || hadValue) {
                    let saveItem: IPlanPropertyExtended = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                        ButtonRow: 0
                    };

                    allSaveElements.push(saveItem);
                }
            }
        }

        let allFileElements = [];
        let files = document.getElementsByClassName("schoolReconfigurationsOpeningUploadFileField");
        for (let ele of files) {
            planPK = parseInt(formElement.dataset.planfk);

            let element = <HTMLInputElement>ele;

            const sequenceNbr = parseInt(element.dataset.sequencenbr);
            const dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            const planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk)
            const propertyRelationshipTypePK = parseInt(element.dataset.propertyrelationtypepk);
            const fileUploadPK = parseInt(element.dataset.fileuploadpk);
            const propertyPK = parseInt(element.dataset.propertypk);
            const planPropertyPlanPropertyFilePK = parseInt(element.dataset.planpropertyplanpropertyfilepk);

            let saveItem: IPlanPropertyPlanPropertyFile = {
                PlanPropertyPK: dataPointPlanPropertyPK,
                PlanPropertyFilePK: planPropertyFilePK,
                SequenceNbr: sequenceNbr,
                PropertyRelationshipTypePK: propertyRelationshipTypePK,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanPropertyPlanPropertyFilePK: planPropertyPlanPropertyFilePK
            };

            allFileElements.push(saveItem);
        }

        let outsideSaveElements = [];
        let selects = document.getElementsByClassName("schoolReconfigurationsOpeningSelectField");
        for (let ele of selects) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);

            const element = <HTMLSelectElement>ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (parseInt(element.value) > -1) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                if (element.value !== "" || hadValue) {
                    let saveItem: IPlanProperty = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: 0,
                        IsDeletedInd: false
                    };

                    outsideSaveElements.push(saveItem);
                }
            }
        }

        const checkboxes = document.getElementsByClassName("schoolReconfigurationsOpeningCheckboxField");
        for (let ele of checkboxes) {
            planPK = parseInt(formElement.dataset.planfk);

            const element = <HTMLInputElement>ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            const dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            const planPropertyPK = parseInt(element.dataset.planpropertypk);

            let val = "";
            if (element.checked)
                val = "on";
            else
                val = "off";

            let saveItem: IPlanPropertyExtended = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: planPK,
                PropertyFK: propertyPK,
                TextValue: val,
                LookupCodeFK: null,
                RowNbr: parseInt(rowNumber),
                IsDeletedInd: false,
                ButtonRow: 0,
                DataPointPlanPropertyPK: dataPointPlanPropertyPK
            };
            allSaveElements.push(saveItem);
        }

        var allData = {
            "ElementData": allSaveElements,
            "FileData": allFileElements,
            "OutsideData": outsideSaveElements,
            "PlanPK": planPK
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SchoolReconfigurations/SaveSchoolReconfigurationsOpening', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0 && allFileElements.length === 0 && outsideSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }

    async createSchoolOpening() {
        const textElement = <HTMLInputElement>document.getElementById("reconfigurationOpeningNameofSchool");
        const schoolName = textElement.value;
        const planFK = textElement.dataset.planfk;
        const currentList = document.getElementsByClassName("reconfigurationOpeningExplaination");
        let currentRow = 0;

        if (schoolName !== "") {
            Core.showLoader();
            for (const item of currentList) {
                const ele = <HTMLTextAreaElement>item;

                let thisRow = parseInt(ele.dataset.row);

                if (!isNaN(thisRow) && thisRow > currentRow)
                    currentRow = thisRow;
            }

            currentRow++;

            const data = {
                "SchoolName": schoolName,
                "PlanFK": planFK,
                "Row": currentRow,
            };

            const config = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            }

            const response = await fetch("/SchoolReconfigurations/CreateBuildingOpening", config);
            if (response.ok) {

                const output = await response.text();

                let container = document.getElementById("buildingOpeningList");
                let newDiv = <HTMLDivElement>document.createElement("div");

                newDiv.innerHTML = output;

                container.appendChild(newDiv);

                let idControl = <HTMLButtonElement>newDiv.querySelector(".Accordion h2 button");
                let id = idControl.id;

                new CustomAccordion(id);

                this.bindUploadButtons();
                this.bindSameAsMailingAddress();

                let mailing = new Foundation.Tabs($(".mailingAddressTabs"));

                this._core.initializeRequiredFields(this.validationClasses, false, true);

                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The Building Opening record has been added!", 'success', 2000, null);
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "There was an issue adding a new row. Please try again.", 'error', 2000, null);
            }
        } else {
            Core.createHTMLAlert("alertMessageDiv", "Please enter a school name before adding a school.", 'warning', 2000, null);
        }
    }

    bindUploadButtons() {
        let fileUploaders = document.getElementsByClassName("schoolReconfigurationsOpeningFileField");
        for (let fileUploader of fileUploaders)
            fileUploader.addEventListener("change", (e: Event) => this.uploadFile(<HTMLInputElement>e.target));
    }

    uploadFile(e: HTMLInputElement) {
        let core = this._core;
        Core.showLoader();
        let formName = e.dataset.formname;
        let dataPointPlanPropertyFK = e.dataset.datapointplanpropertypk;

        let uploadSampleForm = <HTMLFormElement>document.getElementById(formName);
        let formData = new FormData(uploadSampleForm);
        let that = this;

        let propertyPK = e.dataset.propertypk;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SchoolReconfigurations/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();

                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);

                        let formFile = <HTMLInputElement>document.querySelector(`.schoolReconfigurationsOpeningFileField[data-datapointplanpropertypk='${dataPointPlanPropertyFK}']`);
                        formFile.value = "";

                        let fileList = document.querySelector(`.uploadFileList[data-datapointplanpropertypk='${dataPointPlanPropertyFK}']`)

                        let currentFileList = fileList.querySelectorAll(".schoolReconfigurationsClosureUploadField");

                        let sequence = -1;
                        for (let file of currentFileList) {
                            let fileEle = <HTMLDivElement>file;

                            let thisSequence = parseInt(fileEle.dataset.sequencenbr);
                            if (thisSequence > sequence) {
                                sequence = thisSequence;
                            }
                        }


                        let uploadedContainer = document.querySelector(`.uploadedFiles[data-datapointplanpropertypk='${dataPointPlanPropertyFK}']`);
                        uploadedContainer.classList.remove("hide");

                        sequence++;

                        let propertyRelationTypePK = e.dataset.propertyrelationtypepk;

                        let fileWrapper = <HTMLDivElement>document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = <HTMLDivElement>document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = <HTMLAnchorElement>document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.planpropertypk = dataPointPlanPropertyFK.toString();
                        fileA.dataset.datapointplanpropertypk = dataPointPlanPropertyFK.toString();
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.dataset.sequencenbr = sequence.toString();
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.propertyrelationtypepk = propertyRelationTypePK;
                        fileA.dataset.planpropertyplanpropertyfilepk = "0";
                        fileA.classList.add("schoolReconfigurationsOpeningUploadFileField");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);

                        fileList.appendChild(fileWrapper);
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }

    bindDeleteFileButtons() {
        let fileButtons = document.getElementsByClassName("deleteFile");

        for (let fileButton of fileButtons)
            fileButton.addEventListener("click", (e: Event) => this.showDeleteFile(e));
    }

    bindDeleteSchoolButtons() {
        let fileButtons = document.getElementsByClassName("deleteThisSchoolOpening");

        for (let fileButton of fileButtons)
            fileButton.addEventListener("click", (e: Event) => this.showDeleteSchool(e));
    }

    showDeleteFile(e: Event) {
        let fileButton = <HTMLButtonElement>e.target;
        let planPropertyFilePK = fileButton.dataset.planpropertyfilepk;

        if (planPropertyFilePK && parseInt(planPropertyFilePK) > 0) {
            let modal: Modal = new Modal("deleteFileModal", null);
            let deleteConfirmButton = <HTMLButtonElement>document.getElementById("deleteFileConfirm");
            deleteConfirmButton.dataset.planpropertyfilepk = planPropertyFilePK;
            modal.show();
        }
    }

    deleteFileConfirm(confirmButton: HTMLButtonElement) {
        let core = this._core;
        Core.showLoader();

        let planPropertyFilePK = confirmButton.dataset.planpropertyfilepk;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SchoolReconfigurations/DeleteFiles', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file has been successfully deleted.", 'success', 3000, null);

                        let containerElement = <HTMLElement>document.querySelector(`.uploadFileColumn[data-planpropertyfilepk='${planPropertyFilePK}']`);
                        if (containerElement !== null) {
                            containerElement.parentNode.removeChild(containerElement);
                        }

                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting file.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file removal failed. Please try again.", 'error', 3000, null);
            }

            let modal: Modal = new Modal("deleteFileModal", null);
            modal.hide();
        };
        xhr.send(JSON.stringify([planPropertyFilePK]));
    }

    deleteFileCancel() {
        let modal: Modal = new Modal("deleteFileModal", null);
        modal.hide();
    }

    showDeleteSchool(e: Event) {
        let fileButton = <HTMLButtonElement>e.target;
        let datapointPlanPropertyFilePK = fileButton.dataset.datapointplanpropertypk;

        let modal: Modal = new Modal("deleteSchoolOpeningModal", null);
        modal.addAttributeToElement("deleteSchoolOpeningModal", "#deleteOpeningConfirm", "datapointplanpropertypk", datapointPlanPropertyFilePK);
        modal.show();
    }

    deleteSchoolOpeningConfirm(e: Event) {
        const buttonElement = <HTMLButtonElement>e.target;

        let datapointPlanPropertyPK = buttonElement.dataset.datapointplanpropertypk;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SchoolReconfigurations/DeleteSchoolOpening', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {    
                    let res = JSON.parse(xhr.responseText);
                    if (res === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The school opening has been successfully deleted.", 'success', 3000, null);

                        let containerElement = <HTMLButtonElement>document.querySelector(`.Accordion-trigger[data-datapointplanpropertypk='${datapointPlanPropertyPK}']`);
                        if (containerElement !== null) {
                            containerElement.parentElement.parentElement.remove();
                        }

                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this record, please try again.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file removal failed. Please try again.", 'error', 3000, null);
            }

            let modal: Modal = new Modal("deleteSchoolOpeningModal", null);
            modal.hide();
        };
        xhr.send(JSON.stringify(datapointPlanPropertyPK));
    }

    deleteSchoolOpeningCancel() {
        let modal: Modal = new Modal("deleteSchoolOpeningModal", null);
        modal.hide();
    }

    checkRequest() {
        const element = <HTMLSelectElement>document.getElementById("reconfigurationOpeningRequestInvolves");

        if (element !== null) {
            if (element.selectedIndex > 0) {
                const option = <HTMLOptionElement>element[element.selectedIndex];
                const classes = [];
                classes.push("schoolReconfigurationsOpeningField");
                classes.push("schoolReconfigurationsOpeningRadioField");
                classes.push("schoolReconfigurationsOpeningFileField");

                if (option.dataset.lookupcode === "reconfigurationOpeningRequestInvolves") {
                    for (const thisClass of classes) {
                        const allTheseElements = document.getElementsByClassName(thisClass);

                        for (const thisElement of allTheseElements) {
                            const thisEle = <HTMLElement>thisElement;
                            if (!thisEle.classList.contains("address2") && !thisEle.classList.contains("ext"))
                                this._core.forceElementRequired(thisEle);
                        }
                    }
                } else {
                    for (const thisClass of classes) {
                        const allTheseElements = document.getElementsByClassName(thisClass);

                        for (const thisElement of allTheseElements)
                            this._core.forceElementOptional(thisElement as HTMLElement);
                    }
                }
            }
        }
    }

    doValidationCustom(allClasses: string[], showMessageOverride?: boolean) {
        let showMessage = showMessageOverride === undefined ? this.clientSideValidationCustom(allClasses) : showMessageOverride;
        let messageContainerColumn = <HTMLElement>document.getElementById("validationColumn");
        let messageContainer = <HTMLElement>document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = <HTMLElement>document.getElementById("validationMessageIcon");

        setTimeout(function () {
            messageContainer.focus();
        }, 500);

        if (showMessage) {

            let message = <HTMLElement>document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";

        } else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";

            let successMessage = <HTMLElement>document.getElementById("saveSuccess");

            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved."
            }
        }
    }

    clientSideValidationCustom(allClasses: string[]): boolean {
        let showMessage: boolean = false;
        let totalErrors = 0;
        let customMessage: boolean = false;

        const selectElement = document.getElementById("reconfigurationOpeningRequestInvolves") as HTMLSelectElement;
        let override = false;
        if (selectElement !== null) {
            const selectedOption = selectElement[selectElement.selectedIndex] as HTMLOptionElement;
            if (selectedOption.dataset.lookupcode === "reconfigurationOpeningRequestDoesNotInvolve")
                override = true;
        }


        if (!override) {

            //make sure there is at least one school in the list

            const buildingOpeningList = document.querySelectorAll("#buildingOpeningList .Accordion");
            if (buildingOpeningList !== null && buildingOpeningList.length > 0) {

                let formattedAllClasses = [];
                allClasses.forEach(function (part, index) {
                    formattedAllClasses[index] = "." + allClasses[index];
                });

                let classesToValidate = formattedAllClasses.join(",");

                //Remove all validation messages
                [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
                    e.parentNode.removeChild(e);
                });

                //Remove missing field class
                [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
                    e.classList.remove("missing-field");
                });

                //Remove hasBeenValidated class
                [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
                    e.classList.remove("hasBeenValidated");
                });

                let allElements = document.querySelectorAll(classesToValidate);

                for (let element of allElements) {
                    let alreadyExists = false;
                    let htmlElement = <HTMLElement>element;
                    if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {

                        //check to see if there are other fields the same as this one on this page (i.e. fields that have the same propertypk)
                        var otherElements = document.querySelectorAll(`[data-propertypk='${htmlElement.dataset.propertypk}']`);
                        for (let otherElement of otherElements) {
                            let otherHtmlElement = <HTMLElement>otherElement;
                            if (otherHtmlElement.classList.contains("missing-field")) {
                                alreadyExists = true;
                            } else {
                                if (otherHtmlElement instanceof HTMLInputElement) {
                                    if (otherHtmlElement.hasAttribute("type") && otherHtmlElement.getAttribute("type") === "radio") {
                                        //Check to see if a prior group of radio buttons has already been validated
                                        let OtherInputElement = <HTMLInputElement>otherHtmlElement;

                                        if (OtherInputElement.hasAttribute("name")) {
                                            let radioGroupName = OtherInputElement.getAttribute("name");

                                            let radios = document.getElementsByName(radioGroupName);
                                            let radioIsChecked: boolean = false;
                                            let isAlreadyValidated: boolean = false;

                                            for (var i = 0, length = radios.length; i < length; i++) {
                                                let radioElement = <HTMLInputElement>radios[i];
                                                if (radioElement.checked) {
                                                    radioIsChecked = true;
                                                }

                                                if (radioElement.classList.contains("missing-field")) {
                                                    isAlreadyValidated = true;
                                                }
                                            }

                                            if (isAlreadyValidated || radioIsChecked) {
                                                alreadyExists = true;
                                            }
                                        }
                                    } else {
                                        let OtherInputElement = <HTMLInputElement>otherHtmlElement;
                                        if (OtherInputElement.value !== "") {
                                            alreadyExists = true;
                                        }
                                    }
                                } else if (otherHtmlElement instanceof HTMLSelectElement) {
                                    let otherSelectElement = <HTMLSelectElement>otherHtmlElement;
                                    if (otherSelectElement.selectedIndex > 0 && otherSelectElement.options[otherSelectElement.selectedIndex].value !== "") {
                                        alreadyExists = true;
                                    }
                                } else if (otherHtmlElement instanceof HTMLTextAreaElement) {
                                    let otherTextAreaElement = <HTMLTextAreaElement>otherHtmlElement;
                                    if (otherTextAreaElement.value !== "") {
                                        alreadyExists = true;
                                    }
                                } else if ("multiselectvalidate" in otherHtmlElement.dataset && otherHtmlElement.dataset.multiselectvalidate === "true") {
                                    //See if any options have been checked in multiselect
                                    let multiselectCheckboxes = otherHtmlElement.getElementsByTagName("input") as HTMLCollectionOf<HTMLInputElement>;
                                    for (let selectBox of multiselectCheckboxes) {
                                        if (selectBox.checked) {
                                            alreadyExists = true;
                                            break;
                                        }
                                    }
                                }
                            }
                        }

                        if (!alreadyExists || ("forcerequired" in htmlElement.dataset && htmlElement.dataset.forcerequired === "true")) {
                            if (!element.classList.contains("missing-field")) {
                                //For more custom validation, use data-is-valid to specify whether a field is valid/invalid
                                if ("isValid" in htmlElement.dataset) {
                                    if (htmlElement.dataset.isValid === "false") {
                                        htmlElement.classList.add("missing-field");
                                        htmlElement.setAttribute("aria-invalid", "true");
                                        Core.createErrorLabelForInput(htmlElement);
                                        showMessage = true;
                                        totalErrors++;
                                    }
                                } else {
                                    if (element instanceof HTMLInputElement) {
                                        let inputElement = <HTMLInputElement>element;

                                        //Only validate once for radio buttons
                                        if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "radio" && !inputElement.checked) {
                                            if (inputElement.hasAttribute("name")) {
                                                let radioName = inputElement.getAttribute("name");

                                                let radioButtons = document.getElementsByName(radioName);
                                                let alreadyValidated: boolean = false;
                                                let isChecked: boolean = false;

                                                for (var i = 0, length = radioButtons.length; i < length; i++) {
                                                    let radioElement = <HTMLInputElement>radioButtons[i];
                                                    if (radioElement.classList.contains("missing-field")) {
                                                        alreadyValidated = true;
                                                    }

                                                    if (radioElement.checked) {
                                                        isChecked = true;
                                                    }
                                                }

                                                if (!alreadyValidated && !isChecked) {
                                                    inputElement.classList.add("missing-field");
                                                    inputElement.setAttribute("aria-invalid", "true");
                                                    Core.createErrorLabelForInput(inputElement);
                                                    showMessage = true;
                                                    totalErrors++;
                                                }
                                            }
                                        } else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "file") {
                                            if ("hasuploaded" in inputElement.dataset && inputElement.dataset.hasuploaded != "true") {
                                                inputElement.classList.add("missing-field");
                                                inputElement.setAttribute("aria-invalid", "true");
                                                Core.createErrorLabelForInput(inputElement);
                                                showMessage = true;
                                                totalErrors++;
                                            }
                                        } else if (inputElement.value === "") {
                                            inputElement.classList.add("missing-field");
                                            inputElement.setAttribute("aria-invalid", "true");
                                            Core.createErrorLabelForInput(inputElement);
                                            showMessage = true;
                                            totalErrors++;
                                        }
                                    } else if (element instanceof HTMLSelectElement) {
                                        let selectElement = <HTMLSelectElement>element;
                                        //if the page select element does not have a place holder it needs custom validation.  do not use this one.
                                        if (selectElement.selectedIndex <= 0 || selectElement.options[selectElement.selectedIndex].value === "") {
                                            selectElement.classList.add("missing-field");
                                            selectElement.setAttribute("aria-invalid", "true");
                                            Core.createErrorLabelForInput(selectElement);
                                            showMessage = true;
                                            totalErrors++;
                                        }
                                    } else if (element instanceof HTMLTextAreaElement) {
                                        let textAreaElement = <HTMLTextAreaElement>element;
                                        if (textAreaElement.value === "") {
                                            textAreaElement.classList.add("missing-field");
                                            textAreaElement.setAttribute("aria-invalid", "true");
                                            Core.createErrorLabelForInput(textAreaElement);
                                            showMessage = true;
                                            totalErrors++;
                                        }
                                    } else if ("multiselectvalidate" in htmlElement.dataset && htmlElement.dataset.multiselectvalidate === "true") {
                                        let multiselectCheckboxes = htmlElement.getElementsByTagName("input") as HTMLCollectionOf<HTMLInputElement>;
                                        let hasSelection = false;

                                        for (let selectBox of multiselectCheckboxes) {
                                            if (selectBox.checked) {
                                                hasSelection = true;
                                                break;
                                            }
                                        }

                                        if (!hasSelection) {
                                            htmlElement.classList.add("missing-field");
                                            htmlElement.setAttribute("aria-invalid", "true");
                                            Core.createErrorLabelForInput(htmlElement);
                                            showMessage = true;
                                            totalErrors++;
                                        }

                                    }
                                }
                            }
                        }
                    }
                }
            } else {
                totalErrors++;
                customMessage = true;
                showMessage = true;
            }
        }

        let message = <HTMLDivElement>document.getElementById("validationMessage");

        if (totalErrors === 1 && !customMessage) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        } else if (totalErrors === 1 && customMessage) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are no schools added to this list. You must add a school.</p>";
        } else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");

        if (goToError !== null) {

            let that = this;

            let firstFocusableEl = <HTMLElement>document.querySelector(".missing-field");

            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");

                        let accordionElement = <HTMLButtonElement>document.getElementById(id);
                        accordionElement.click();
                    }

                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    } else {
                        firstFocusableEl.focus();
                    }
                });
            } else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }

    bindSameAsMailingAddress() {
        const schoolReconfigurationsOpeningSameAsMailings = document.getElementsByClassName("schoolReconfigurationsOpeningSameAsMailing");
        for (const schoolReconfigurationsOpeningSameAsMailing of schoolReconfigurationsOpeningSameAsMailings) {
            schoolReconfigurationsOpeningSameAsMailing.addEventListener("change", (e: Event) => this.sameAsMailingAddress(e.target as HTMLInputElement));
            this.sameAsMailingAddress(schoolReconfigurationsOpeningSameAsMailing as HTMLInputElement);
        }
    }

    sameAsMailingAddress(checkbox: HTMLInputElement) {
        const dataPointPlanPropertyFK = checkbox.dataset.datapointplanpropertypk;
        const allPhysicalAddressInputs = document.querySelectorAll(`.schoolReconfigurationsOpeningPhysical[data-datapointplanpropertypk='${dataPointPlanPropertyFK}']`);

        if (checkbox.checked)
            for (const physical of allPhysicalAddressInputs) {
                const element = physical as HTMLInputElement;

                this._core.forceElementOptional(element);
                element.readOnly = true;
                element.disabled = true;
            }
        else
            for (const physical of allPhysicalAddressInputs) {
                const element = physical as HTMLInputElement;

                this._core.forceElementRequired(element);
                element.readOnly = false;
                element.disabled = false;
            }
    }
}

// Administrator Changes
class SchoolReconfigurationsAdministratorChanges {
    validationClasses: string[];

    private _core: Core;

    constructor() {
        let that = this;
        this._core = new Core();

        const reconfigurationAdminChangeNoticeClose = document.getElementById("reconfigurationAdminChangeNoticeClose");
        if (reconfigurationAdminChangeNoticeClose !== null)
            reconfigurationAdminChangeNoticeClose.addEventListener("click", (e: Event) => this.closeNoticeModal());

        this.bindChiefLEAAdminDropdowns();
        this.bindCommissionChanges();

        const reconfigurationAdministratorChangesTherearecurrentlynoAdministrativeChanges = document.getElementById("reconfigurationAdministratorChangesTherearecurrentlynoAdministrativeChanges");
        if (reconfigurationAdministratorChangesTherearecurrentlynoAdministrativeChanges !== null) {
            reconfigurationAdministratorChangesTherearecurrentlynoAdministrativeChanges.addEventListener("change", (e: Event) => this.noAdministrativeChanges(e.target as HTMLInputElement));
            this.noAdministrativeChanges(reconfigurationAdministratorChangesTherearecurrentlynoAdministrativeChanges as HTMLInputElement);
        }

        const dropdowns = document.getElementsByClassName("reconfigurationAdministratorChangesChiefLEAAdminUpdateField");
        for (const dropdown of dropdowns)
            this.changeChiefLEAAdminDropdown(dropdown as HTMLSelectElement);

        this.validationClasses = ["reconfigurationAdministratorChangesField", "reconfigurationAdministratorChangesRadioField", "reconfigurationAdministratorChangesSelectField", "reconfigurationAdministratorChangesCheckboxField", "reconfigurationAdministratorChangesFileField", "reconfigurationAdministratorChangesPrincipalField"];
        let reconfigurationAdministratorChangesSaveButton = document.getElementById("reconfigurationAdministratorChangesSave");
        if (reconfigurationAdministratorChangesSaveButton !== null)
            reconfigurationAdministratorChangesSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.doCustomValidation(this.validationClasses);

            const noticeModal: Modal = new Modal("reconfigurationAdminChangeNoticeClose", null);
            noticeModal.show();
        }
        this.customInitializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        const createAdministrativeUpdateButton = document.getElementById("createAdministrativeUpdate");
        if (createAdministrativeUpdateButton !== null)
            createAdministrativeUpdateButton.addEventListener("click", (e: Event) => this.createAdministrativeUpdate());

        const deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e: Event) => this.deleteFileConfirm(e));

        const deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e: Event) => this.deleteFileCancel());

        const deleteAdminUpdateConfirmButton = document.getElementById("deleteAdminUpdateConfirm");
        if (deleteAdminUpdateConfirmButton !== null)
            deleteAdminUpdateConfirmButton.addEventListener("click", (e: Event) => this.deleteAdminUpdateConfirm(e));

        const deleteAdminUpdateCancelButton = document.getElementById("deleteAdminUpdateCancel");
        if (deleteAdminUpdateCancelButton !== null)
            deleteAdminUpdateCancelButton.addEventListener("click", (e: Event) => this.deleteAdminUpdateCancel());

        this.bindUploadFiles();
        this.bindDeleteFiles();
        this.bindDeleteAdministrativeUpdates();

        const reconfigurationAdministratorChangesAdministrativeUpdate = document.getElementById("reconfigurationAdministratorChangesAdministrativeUpdate");
        if (reconfigurationAdministratorChangesAdministrativeUpdate !== null)
            reconfigurationAdministratorChangesAdministrativeUpdate.addEventListener("change", (e: Event) => this.changeAdminUpdateSelection(e));

        document.addEventListener("click", (e) => {
            let target = e.target as HTMLElement;
            
            if (target.classList.contains("submitReconfigAdminChange") && "datapointplanpropertypk" in target.dataset) {
                that.save("save", [parseInt(target.dataset.datapointplanpropertypk)]);
            }
        });
    }

    changeAdminUpdateSelection(e: Event) {
        const element = <HTMLSelectElement>e.target;
        const option = <HTMLOptionElement>element[element.selectedIndex];

        const allOptions = document.querySelectorAll(`#reconfigurationAdministratorChangesUpdateCategoryType option`);
        const dropdown = <HTMLSelectElement>document.getElementById("reconfigurationAdministratorChangesUpdateCategoryType");
        dropdown.value = "0";

        if (option.dataset.lookupcode === "administrativeUpdateAssistantChiefSchoolPrincipal" || option.dataset.lookupcode === "administrativeUpdateAssistantVicePrincipal") {
            for (const option of allOptions) {
                const ele = <HTMLElement>option;
                if (ele.classList.contains("canBeHidden"))
                    ele.classList.add("hide")
            }
        } else {
            for (const option of allOptions) {
                const ele = <HTMLElement>option;
                if (ele.classList.contains("canBeHidden"))
                    ele.classList.remove("hide")
            }
        }
    }

    noAdministrativeChanges(element: HTMLInputElement) {
        const allRadioElements = document.getElementsByClassName("reconfigurationAdministratorChangesRadioField");
        const allTextElements = document.getElementsByClassName("reconfigurationAdministratorChangesField");
        const allSelectElements = document.getElementsByClassName("reconfigurationAdministratorChangesSelectField");
        const allFileElements = document.getElementsByClassName("reconfigurationAdministratorChangesFileField");
        const allDeleteFileElements = document.getElementsByClassName("deleteFile");
        const createAdministrativeUpdate = <HTMLButtonElement>document.getElementById("createAdministrativeUpdate");
        const allDeleteAdminElements = document.getElementsByClassName("deleteAdminUpdate");

        if (element.checked) {
            for (var radio of allRadioElements) {
                const ele = <HTMLInputElement>radio;
                ele.readOnly = true;
                ele.disabled = true;
            }
            for (var text of allTextElements) {
                const ele = <HTMLInputElement>text;
                if (!(ele.dataset.omitreadonly && ele.dataset.omitreadonly === "true")) {
                    ele.readOnly = true;
                    ele.disabled = true;
                }
            }
            for (var select of allSelectElements) {
                const ele = <HTMLInputElement>select;
                ele.readOnly = true;
                ele.disabled = true;
            }
            for (var file of allFileElements) {
                const ele = <HTMLInputElement>file;
                ele.readOnly = true;
                ele.disabled = true;
            }
            for (var del of allDeleteFileElements) {
                const ele = <HTMLInputElement>del;
                ele.readOnly = true;
                ele.disabled = true;
            }
            for (var del of allDeleteAdminElements) {
                const ele = <HTMLInputElement>del;
                ele.readOnly = true;
                ele.disabled = true;
            }
            createAdministrativeUpdate.disabled = true;
        } else {
            for (var radio of allRadioElements) {
                const ele = <HTMLInputElement>radio;
                ele.readOnly = false;
                ele.disabled = false;
            }
            for (var text of allTextElements) {
                const ele = <HTMLInputElement>text;
                ele.readOnly = false;
                ele.disabled = false;
            }
            for (var select of allSelectElements) {
                const ele = <HTMLInputElement>select;
                ele.readOnly = false;
                ele.disabled = false;
            }
            for (var file of allFileElements) {
                const ele = <HTMLInputElement>file;
                ele.readOnly = false;
                ele.disabled = false;
            }
            for (var del of allDeleteFileElements) {
                const ele = <HTMLInputElement>del;
                ele.readOnly = false;
                ele.disabled = false;
            }
            for (var del of allDeleteAdminElements) {
                const ele = <HTMLInputElement>del;
                ele.readOnly = false;
                ele.disabled = false;
            }
            createAdministrativeUpdate.disabled = false;
        }
    }

    closeNoticeModal() {
        const modal: Modal = new Modal("reconfigurationAdminChangeNoticeClose", null);
        modal.hide();
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer, dataPointPlanPropertyPKsToSubmitForReview: number[] = null) {
        const allSaveElements = [];
        const allAdminSaveElements = [];
        const core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        const formElement = document.getElementById("reconfigurationAdministratorChangesForm");
        const planPK = parseInt(formElement.dataset.planfk);

        const textInputs = document.getElementsByClassName("reconfigurationAdministratorChangesField");
        for (const ele of textInputs) {
            let planPropertyPK = 0;
            const element = <HTMLInputElement>ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            const dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanPropertyExtended = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                    ButtonRow: 0
                };

                allSaveElements.push(saveItem);
            }
        }

        //This is a special case in a different place, it was just easier to handle it separately.
        const principalInputs = document.getElementsByClassName("reconfigurationAdministratorChangesPrincipalField");
        for (const ele of principalInputs) {
            let planPropertyPK = 0;
            const element = <HTMLInputElement>ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            const dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanPropertyExtended = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                    ButtonRow: 0
                };

                allSaveElements.push(saveItem);
            }
        }

        const radios = document.getElementsByClassName("reconfigurationAdministratorChangesRadioField");
        for (const ele of radios) {
            let planPropertyPK = 0;
            const element = <HTMLInputElement>ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            const dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);

            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                if (element.value !== "" || hadValue) {
                    let saveItem: IPlanPropertyExtended = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "",
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                        ButtonRow: 0
                    };

                    allSaveElements.push(saveItem);
                }
            }
        }

        const checkboxes = document.getElementsByClassName("reconfigurationAdministratorChangesCheckboxField");
        for (let ele of checkboxes) {
            let planPropertyPK = 0;
            const element = <HTMLInputElement>ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            const dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);

            let hadValue = false;
            let textValue = "off";
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;

                if (!element.checked)
                    textValue = "off";
            }

            if (element.checked)
                textValue = "on";

            if (textValue !== "" || hadValue) {
                let saveItem: IPlanPropertyExtended = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: textValue,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                    ButtonRow: 0
                };

                allSaveElements.push(saveItem);
            }
        }

        const administratorCheckboxes = document.getElementsByClassName("reconfigurationAdministratorChangesNoChanges");
        for (let ele of administratorCheckboxes) {
            let planPropertyPK = 0;
            const element = <HTMLInputElement>ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);

            let hadValue = false;
            let textValue = "off";
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;

                if (!element.checked)
                    textValue = "off";
            }

            if (element.checked)
                textValue = "on";

            if (textValue !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: textValue,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                };

                allAdminSaveElements.push(saveItem);
            }
        }

        const selects = document.getElementsByClassName("reconfigurationAdministratorChangesSelectField");
        for (let ele of selects) {
            let planPropertyPK = 0;
            const element = <HTMLSelectElement>ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            const dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);

            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if ((element.value !== "" && element.value !== "0") || hadValue) {
                let saveItem: IPlanPropertyExtended = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: "",
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                    ButtonRow: 0
                };

                allSaveElements.push(saveItem);
            }
        }

        const allFileElements = [];
        let files = document.getElementsByClassName("reconfigurationAdministratorChangesFileUploadField");
        for (let ele of files) {
            const element = <HTMLInputElement>ele;

            const sequenceNbr = parseInt(element.dataset.sequencenbr);
            const planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk);
            const propertyPK = parseInt(element.dataset.propertypk);
            const planPropertyPlanPropertyFilePK = parseInt(element.dataset.planpropertyplanpropertyfilepk);
            const planPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            const fileUploadFK = parseInt(element.dataset.fileuploadpk);

            const fileItem = {
                PlanFK: planPK,
                PlanPropertyFilePK: planPropertyFilePK,
                SequenceNbr: sequenceNbr,
                PropertyPK: propertyPK,
                PlanPropertyPlanPropertyFilePK: planPropertyPlanPropertyFilePK,
                PlanPropertyPK: planPropertyPK,
                FileUploadPK: fileUploadFK,
                File: null
            };

            allFileElements.push(fileItem);
        }

        const saveData = {
            "SaveData": allSaveElements,
            "PlanFK": planPK,
            "AdminCheckboxes": allAdminSaveElements,
            "FileData": allFileElements,
            "DataPointPlanPropertyPKsToSubmitForReview": dataPointPlanPropertyPKsToSubmitForReview
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SchoolReconfigurations/SaveReconfigurationAdministratorChanges', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.createHTMLAlert("alertMessageDiv", "Page saved! Reloading.", "success", 3000, null);
                    window.location.reload();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0 && allFileElements.length === 0 && allAdminSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(saveData));
        }
    }

    async createAdministrativeUpdate() {
        Core.showLoader();
        const adminElement = <HTMLSelectElement>document.getElementById("reconfigurationAdministratorChangesAdministrativeUpdate");
        const updateElement = <HTMLSelectElement>document.getElementById("reconfigurationAdministratorChangesUpdateCategoryType");

        const planForm = <HTMLDivElement>document.getElementById("reconfigurationAdministratorChangesForm");
        const planFK = planForm.dataset.planfk;

        const that = this;

        const adminValue = adminElement.value;
        const updateValue = updateElement.value;

        if (!isNaN(parseInt(adminValue)) && parseInt(adminValue) > 0 && !isNaN(parseInt(updateValue)) && parseInt(updateValue) > 0) {
            let row = 0;

            const allRows = document.querySelectorAll(".rowCheck");
            for (const r of allRows) {
                const ele = <HTMLDivElement>r;
                const thisRow = parseInt(ele.dataset.row);
                if (!isNaN(thisRow) && thisRow > row)
                    row = thisRow;
            }

            row++;

            let response = await fetch(`/SchoolReconfigurations/CreateAdministrativeUpdate/${adminValue}/${updateValue}/${row}/${planFK}`, { credentials: 'include' })
            if (response.ok) {

                const output = await response.text();

                let newDiv = <HTMLTableRowElement>document.createElement("div");
                newDiv.innerHTML = output;0

                const container = <HTMLDivElement>document.querySelector("#administratorChanges");              
                container.prepend(newDiv);


                const thisId = `accordionUpdate${adminValue}${updateValue}${row}Id`;

                new CustomAccordion(thisId);

                that.bindDeleteAdministrativeUpdates();
                that.bindCommissionChanges();
                that.bindChiefLEAAdminDropdowns();
                that.bindUploadFiles();

                that.customInitializeRequiredFields(that.validationClasses);

                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The Administrative Update has been added!", "success", 2000, null);

                adminElement.selectedIndex = 0;
                updateElement.selectedIndex = 0;

                Core.hideLoader();
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "There was an issue adding a new row. Please try again.", 'error', 2000, null);
            }
        } else {
            Core.createHTMLAlert("alertMessageDiv", "The Administrative Update and Update Category Type selections are both required.", "warning", 2000, null);
        }
    }

    bindUploadFiles() {
        const uploaders = document.getElementsByClassName("reconfigurationAdministratorChangesFileField");
        for (const upload of uploaders)
            upload.addEventListener("change", (e: Event) => this.uploadFile(e.target as HTMLInputElement));
    }

    uploadFile(e: HTMLInputElement) {
        Core.showLoader();
        const formName = e.dataset.formname;
        const row = e.dataset.row;
        const that = this;
        const files = e.dataset.files;
        const uploadSampleForm = <HTMLFormElement>document.getElementById(formName);
        const formData = new FormData(uploadSampleForm);
        const propertyPK = e.dataset.propertypk;
        const dataPointPlanPropertyPK = e.dataset.datapointplanpropertypk;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();

                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);

                        let formFile = <HTMLInputElement>document.querySelector(`.reconfigurationAdministratorChangesFileField[data-formname='${formName}'][data-files='${files}']`);
                        formFile.value = "";

                        let fileList = document.querySelector(`.uploadFileList[data-files='${files}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`)

                        let currentFileList = fileList.querySelectorAll(`.uploadFileList[data-files='${files}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);

                        let sequence = -1;
                        for (let file of currentFileList) {
                            let fileEle = <HTMLDivElement>file;

                            let thisSequence = parseInt(fileEle.dataset.sequencenbr);
                            if (thisSequence > sequence) {
                                sequence = thisSequence;
                            }
                        }

                        let uploadedContainer = document.querySelector(`.uploadedFiles[data-files='${files}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                        uploadedContainer.classList.remove("hide");

                        sequence++;

                        let fileWrapper = <HTMLDivElement>document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = <HTMLDivElement>document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = <HTMLAnchorElement>document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.row = row;
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.sequencenbr = sequence.toString();
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.classList.add("reconfigurationAdministratorChangesFileUploadField");
                        fileA.dataset.planpropertyplanpropertyfilepk = "0";
                        fileA.dataset.datapointplanpropertypk = dataPointPlanPropertyPK;
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);

                        fileList.appendChild(fileWrapper);

                        that.bindDeleteFiles();
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }

    bindDeleteFiles() {
        const deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (const deleteFileButton of deleteFileButtons)
            deleteFileButton.addEventListener("click", (e: Event) => this.showDeleteFile(e));
    }

    showDeleteFile(e: Event) {
        const buttonElement = <HTMLButtonElement>e.target;
        const planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        const dataPointPlanPropertyPK = buttonElement.dataset.datapointplanpropertypk;

        const modal: Modal = new Modal("deleteFileModal", null);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "planpropertyfilepk", planPropertyFilePK);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "datapointplanpropertypk", dataPointPlanPropertyPK);
        modal.show();
    }

    deleteFileConfirm(e: Event) {
        const buttonElement = <HTMLButtonElement>e.target;
        const planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        const dataPointPlanPropertyPK = buttonElement.dataset.datapointplanpropertypk;
        const files = buttonElement.dataset.files;

        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/SchoolReconfigurations/DeleteAdminFileData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    let element = document.querySelector(`.reconfigurationAdministratorChangesFileUploadField[data-planpropertyfilepk='${planPropertyFilePK}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                    let parent = element.parentElement.parentElement;
                    parent.remove();

                    const moreFiles = document.querySelectorAll(`.uploadFileList[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-file='${files}'] .uploadFileColumn[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-file='${files}']`);
                    if (moreFiles.length === 0) {
                        const uploadsElement = <HTMLDivElement>document.getElementById(`uploadedFiles`);
                        if (uploadsElement !== null) {
                            uploadsElement.classList.add("hide");
                        }
                    }

                    Core.createHTMLAlert("alertMessageDiv", "File successfully removed.", 'success', 3000, null);
                } else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the file. Please try again.", 'error', 3000, null);
                }

                Core.hideLoader();

                let modal: Modal = new Modal("deleteFileModal", null);
                modal.hide();
            } else {
                let modal: Modal = new Modal("deleteFileModal", null);
                modal.hide();

                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planPropertyFilePK));
    }

    deleteFileCancel() {
        const modal: Modal = new Modal("deleteFileModal", null);
        modal.hide();
    }

    bindDeleteAdministrativeUpdates() {
        const deleteAdminUpdateButtons = document.getElementsByClassName("deleteAdminUpdate");
        for (const deleteAdminUpdateButton of deleteAdminUpdateButtons)
            deleteAdminUpdateButton.addEventListener("click", (e: Event) => this.showDeleteAdminUpdate(e));
    }

    showDeleteAdminUpdate(e: Event) {
        const buttonElement = <HTMLButtonElement>e.target;
        const dataPointPlanPropertyPK = buttonElement.dataset.datapointplanpropertypk;

        const modal: Modal = new Modal("deleteAdminUpdateModal", null);
        modal.addAttributeToElement("deleteAdminUpdateModal", "#deleteAdminUpdateConfirm", "datapointplanpropertypk", dataPointPlanPropertyPK);
        modal.show();
    }

    deleteAdminUpdateConfirm(e: Event) {
        const buttonElement = <HTMLButtonElement>e.target;
        const dataPointPlanPropertyPK = buttonElement.dataset.datapointplanpropertypk;

        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/SchoolReconfigurations/DeleteAdminUpdate', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {

                    const accordionTitle = document.querySelector(`.Accordion-panel[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                    const accordionContainer = document.querySelector(`.Accordion-trigger[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);

                    accordionTitle.remove();
                    accordionContainer.remove();

                    Core.createHTMLAlert("alertMessageDiv", "File successfully removed.", 'success', 3000, null);
                } else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the file. Please try again.", 'error', 3000, null);
                }

                Core.hideLoader();

                let modal: Modal = new Modal("deleteAdminUpdateModal", null);
                modal.hide();
            } else {
                let modal: Modal = new Modal("deleteAdminUpdateModal", null);
                modal.hide();

                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(dataPointPlanPropertyPK));
    }

    deleteAdminUpdateCancel() {
        const modal: Modal = new Modal("deleteAdminUpdateModal", null);
        modal.hide();
    }

    bindChiefLEAAdminDropdowns() {
        const dropdowns = document.getElementsByClassName("reconfigurationAdministratorChangesChiefLEAAdminUpdateField");
        for (const dropdown of dropdowns) {
            dropdown.addEventListener("change", (e: Event) => this.changeChiefLEAAdminDropdown(e.target as HTMLSelectElement));
            this.changeChiefLEAAdminDropdown(dropdown as HTMLSelectElement);
        }
    }

    changeChiefLEAAdminDropdown(selectElement: HTMLSelectElement) {
        const dataPointPlanPropertyPK = selectElement.dataset.datapointplanpropertypk;
        if (parseInt(selectElement.value) > 0) {
            const selectedOption = <HTMLOptionElement>selectElement[selectElement.selectedIndex];
            const selectedLookupCode = selectedOption.dataset.lookupcode;

            if (selectedLookupCode === "chiefLEAAdminUpdateExecutiveDirector" || selectedLookupCode === "chiefLEAAdminUpdateSuperintendent") {
                this.vacancyChange(false, dataPointPlanPropertyPK);
                this.charterCEOChange(false, dataPointPlanPropertyPK);
                this.chiefLEAAdministratorInformationChange(true, dataPointPlanPropertyPK);
                this.chiefLEACompleteCommissionChange(true, dataPointPlanPropertyPK);
                this.actingSubstituteExtensionChange(true, dataPointPlanPropertyPK);

                const allCommissions = document.querySelectorAll(`.reconfigurationAdministratorChangesCommission[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                for (const commission of allCommissions)
                    this.commissionChanges(commission as HTMLInputElement);

                

                this.fiveFiveTwoNineChange(true, dataPointPlanPropertyPK);

            } else if (selectedLookupCode === "chiefLEAAdminpdateCEOCharter") {
                this.vacancyChange(false, dataPointPlanPropertyPK);
                this.fiveFiveTwoNineChange(false, dataPointPlanPropertyPK);
                this.charterCEOChange(true, dataPointPlanPropertyPK);
                this.chiefLEAAdministratorInformationChange(false, dataPointPlanPropertyPK);
                this.chiefLEACompleteCommissionChange(false, dataPointPlanPropertyPK);
                this.actingSubstituteExtensionChange(false, dataPointPlanPropertyPK);

                const commission = document.querySelector(`.reconfigurationAdministratorChangesCommission[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                if (commission !== null)
                    this.commissionChanges(commission as HTMLInputElement);
            } 
        } else {
            this.vacancyChange(false, dataPointPlanPropertyPK);
            this.fiveFiveTwoNineChange(false, dataPointPlanPropertyPK);
            this.charterCEOChange(false, dataPointPlanPropertyPK);
            this.chiefLEAAdministratorInformationChange(false, dataPointPlanPropertyPK);
            this.chiefLEACompleteCommissionChange(false, dataPointPlanPropertyPK);
            this.actingSubstituteExtensionChange(false, dataPointPlanPropertyPK);

            const commission = document.querySelector(`.reconfigurationAdministratorChangesCommission[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
            if (commission !== null)
                this.commissionChanges(commission as HTMLInputElement);
        }
    }

    bindCommissionChanges() {
        const allCommissions = document.getElementsByClassName("reconfigurationAdministratorChangesCommission");
        for (const commission of allCommissions) {
            commission.addEventListener("change", (e: Event) => this.commissionChanges(e.target as HTMLInputElement));
            this.commissionChanges(commission as HTMLInputElement);
        }
    }

    commissionChanges(element: HTMLInputElement) {
        const dataPointPlanPropertyPK = element.dataset.datapointplanpropertypk;
        const lookupCode = element.dataset.lookupcode;

        if (element.checked) {
            if (lookupCode === "chiefLEACommisionYes" || lookupCode === "assistantLEACommissionYes") {
                this.vacancyChange(false, dataPointPlanPropertyPK);
            } else {
                this.vacancyChange(true, dataPointPlanPropertyPK);
            }
        }
    }

    vacancyChange(isRequired: boolean, dataPointPlanPropertyPK: string) {
        const vacancyContainer = <HTMLDivElement>document.querySelector(`.evidenceOfVacancyContainer[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        const vacancyElement = <HTMLInputElement>document.querySelector(`.reconfigurationAdministratorChangesVacancy[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);

        if (vacancyContainer && vacancyElement)
            if (isRequired) {
                vacancyContainer.classList.remove("hide");
                this._core.forceElementRequired(vacancyElement);
            } else {
                vacancyContainer.classList.add("hide");
                this._core.forceElementOptional(vacancyElement);
            }
    }

    fiveFiveTwoNineChange(isRequired: boolean, dataPointPlanPropertyPK: string) {
        const fiveFiveTwoNineContainer = <HTMLDivElement>document.querySelector(`.fiveFiveTwoNineContainer[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        const fiveFiveTwoNineElement = <HTMLInputElement>document.querySelector(`.reconfigurationAdministratorChanges5529[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);

        if (fiveFiveTwoNineContainer && fiveFiveTwoNineElement)
            if (isRequired) {
                fiveFiveTwoNineContainer.classList.remove("hide");
                this._core.forceElementRequired(fiveFiveTwoNineElement);
            } else {
                fiveFiveTwoNineContainer.classList.add("hide");
                this._core.forceElementOptional(fiveFiveTwoNineElement);
            }
    }

    charterCEOChange(isRequired: boolean, dataPointPlanPropertyPK: string) {
        const chiefLEAAdminCEOContainer = <HTMLDivElement>document.querySelector(`.chiefLEAAdminCEOContainer[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        const charterCEOFields = document.querySelectorAll(`.reconfigurationAdministratorChangesCEOField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        const charterCEOUploads = document.querySelectorAll(`.reconfigurationAdministratorChangesCEOFileField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);

        if (isRequired) {
            chiefLEAAdminCEOContainer.classList.remove("hide");
            for (const charterCEOField of charterCEOFields) {
                const ele = <HTMLInputElement>charterCEOField;
                this._core.forceElementRequired(ele);
            }
            for (const charterCEOUpload of charterCEOUploads) {
                const ele = <HTMLInputElement>charterCEOUpload;
                this._core.forceElementRequired(ele);
            }
        } else {
            chiefLEAAdminCEOContainer.classList.add("hide");
            for (const charterCEOField of charterCEOFields) {
                const ele = <HTMLInputElement>charterCEOField;
                this._core.forceElementOptional(ele);
            }
            for (const charterCEOUpload of charterCEOUploads) {
                const ele = <HTMLInputElement>charterCEOUpload;
                this._core.forceElementOptional(ele);
            }
        }
    }

    chiefLEAAdministratorInformationChange(isRequired: boolean, dataPointPlanPropertyPK: string) {
        const chiefLEAAdministratorInformationContainer = <HTMLDivElement>document.querySelector(`.chiefLEAAdministratorInformationContainer[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        const chiefLEAAdministratorInformationContainerFields = document.querySelectorAll(`.chiefLEAAdministratorInformationContainerField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);

        if (isRequired) {
            chiefLEAAdministratorInformationContainer.classList.remove("hide");
            for (const chiefLEAAdministratorInformationContainerField of chiefLEAAdministratorInformationContainerFields) {
                const ele = <HTMLInputElement>chiefLEAAdministratorInformationContainerField;
                if(!(ele.dataset.ignorerequired && ele.dataset.ignorerequired === "true"))
                    this._core.forceElementRequired(ele);
            }
        } else {
            chiefLEAAdministratorInformationContainer.classList.add("hide");
            for (const chiefLEAAdministratorInformationContainerField of chiefLEAAdministratorInformationContainerFields) {
                const ele = <HTMLInputElement>chiefLEAAdministratorInformationContainerField;
                this._core.forceElementOptional(ele);
            }
        }
    }

    chiefLEACompleteCommissionChange(isRequired: boolean, dataPointPlanPropertyPK: string) {
        const chiefLEACompleteCommissionContainer = <HTMLDivElement>document.querySelector(`.chiefLEACompleteCommissionContainer[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        const chiefLEACompleteCommissionContainerFields = document.querySelectorAll(`.chiefLEACompleteCommissionContainerField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);

        if (isRequired) {
            chiefLEACompleteCommissionContainer.classList.remove("hide");
            for (const chiefLEACompleteCommissionContainerField of chiefLEACompleteCommissionContainerFields) {
                const ele = <HTMLInputElement>chiefLEACompleteCommissionContainerField;
                this._core.forceElementRequired(ele);
            }
        } else {
            chiefLEACompleteCommissionContainer.classList.add("hide");
            for (const chiefLEACompleteCommissionContainerField of chiefLEACompleteCommissionContainerFields) {
                const ele = <HTMLInputElement>chiefLEACompleteCommissionContainerField;
                this._core.forceElementOptional(ele);
            }
        }
    }

    actingSubstituteExtensionChange(isRequired: boolean, dataPointPlanPropertyPK: string) {
        const actingSubstituteExtensionContainer = <HTMLDivElement>document.querySelector(`.actingSubstituteExtensionContainer[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        const actingSubstituteExtensionContainerFields = document.querySelectorAll(`.actingSubstituteExtensionContainerField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);

        if (actingSubstituteExtensionContainer)
            if (isRequired) {
                actingSubstituteExtensionContainer.classList.remove("hide");
                for (const actingSubstituteExtensionContainerField of actingSubstituteExtensionContainerFields) {
                    const ele = <HTMLInputElement>actingSubstituteExtensionContainerField;
                    this._core.forceElementRequired(ele);
                }
            } else {
                actingSubstituteExtensionContainer.classList.add("hide");
                for (const actingSubstituteExtensionContainerField of actingSubstituteExtensionContainerFields) {
                    const ele = <HTMLInputElement>actingSubstituteExtensionContainerField;
                    this._core.forceElementOptional(ele);
                }
            }
    }

    doCustomValidation(allClasses: string[], showMessageOverride?: boolean) {
        let showMessage = showMessageOverride === undefined ? this.customClientSideValidation(allClasses) : showMessageOverride;
        let messageContainerColumn = <HTMLElement>document.getElementById("validationColumn");
        let messageContainer = <HTMLElement>document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = <HTMLElement>document.getElementById("validationMessageIcon");

        setTimeout(function () {
            messageContainer.focus();
        }, 500);

        if (showMessage) {

            let message = <HTMLElement>document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";

        } else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";

            let successMessage = <HTMLElement>document.getElementById("saveSuccess");

            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved."
            }
        }
    }

    customClientSideValidation(allClasses: string[]): boolean {
        let showMessage: boolean = false;
        let totalErrors = 0;

        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });

        let classesToValidate = formattedAllClasses.join(",");

        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });

        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });

        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });

        const allElements = document.querySelectorAll(classesToValidate);

        for (let element of allElements) {
            const htmlElement = <HTMLElement>element;
            if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {
                if (!element.classList.contains("missing-field")) {
                    //For more custom validation, use data-is-valid to specify whether a field is valid/invalid
                    if ("isValid" in htmlElement.dataset) {
                        if (htmlElement.dataset.isValid === "false") {
                            htmlElement.classList.add("missing-field");
                            htmlElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(htmlElement);
                            showMessage = true;
                            totalErrors++;
                        }
                    } else {
                        if (element instanceof HTMLInputElement) {
                            let inputElement = <HTMLInputElement>element;

                            //Only validate once for radio buttons
                            if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "radio" && !inputElement.checked) {
                                if (inputElement.hasAttribute("name")) {
                                    let radioName = inputElement.getAttribute("name");

                                    let radioButtons = document.getElementsByName(radioName);
                                    let alreadyValidated: boolean = false;
                                    let isChecked: boolean = false;

                                    for (var i = 0, length = radioButtons.length; i < length; i++) {
                                        let radioElement = <HTMLInputElement>radioButtons[i];
                                        if (radioElement.classList.contains("missing-field")) {
                                            alreadyValidated = true;
                                        }

                                        if (radioElement.checked) {
                                            isChecked = true;
                                        }
                                    }

                                    if (!alreadyValidated && !isChecked) {
                                        inputElement.classList.add("missing-field");
                                        inputElement.setAttribute("aria-invalid", "true");
                                        Core.createErrorLabelForInput(inputElement);
                                        showMessage = true;
                                        totalErrors++;
                                    }
                                }
                            } else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "file") {
                                if ("hasuploaded" in inputElement.dataset && inputElement.dataset.hasuploaded != "true") {
                                    inputElement.classList.add("missing-field");
                                    inputElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(inputElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            } else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "checkbox") {
                                if (!inputElement.checked) {
                                    inputElement.classList.add("missing-field");
                                    inputElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(inputElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            } else if (inputElement.value === "") {
                                inputElement.classList.add("missing-field");
                                inputElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(inputElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        } else if (element instanceof HTMLSelectElement) {
                            let selectElement = <HTMLSelectElement>element;
                            //if the page select element does not have a place holder it needs custom validation.  do not use this one.
                            if (selectElement.selectedIndex <= 0 || selectElement.options[selectElement.selectedIndex].value === "") {
                                selectElement.classList.add("missing-field");
                                selectElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(selectElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        } else if (element instanceof HTMLTextAreaElement) {
                            let textAreaElement = <HTMLTextAreaElement>element;
                            if (textAreaElement.value === "") {
                                textAreaElement.classList.add("missing-field");
                                textAreaElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(textAreaElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        } else if ("multiselectvalidate" in htmlElement.dataset && htmlElement.dataset.multiselectvalidate === "true") {
                            let multiselectCheckboxes = htmlElement.getElementsByTagName("input") as HTMLCollectionOf<HTMLInputElement>;
                            let hasSelection = false;

                            for (let selectBox of multiselectCheckboxes) {
                                if (selectBox.checked) {
                                    hasSelection = true;
                                    break;
                                }
                            }

                            if (!hasSelection) {
                                htmlElement.classList.add("missing-field");
                                htmlElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(htmlElement);
                                showMessage = true;
                                totalErrors++;
                            }

                        } else if (htmlElement.classList.contains("multiSelect")) {
                            let multiselectCheckboxes = htmlElement.getElementsByTagName("input") as HTMLCollectionOf<HTMLInputElement>;
                            let hasSelection = false;

                            for (let selectBox of multiselectCheckboxes) {
                                if (selectBox.checked) {
                                    hasSelection = true;
                                    break;
                                }
                            }

                            if (!hasSelection) {
                                htmlElement.classList.add("missing-field");
                                htmlElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(htmlElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        }
                    }
                }
            }
        }

        let message = <HTMLDivElement>document.getElementById("validationMessage");

        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        } else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");

        if (goToError !== null) {
            let firstFocusableEl = <HTMLElement>document.querySelector(".missing-field");

            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");

                        let accordionElement = <HTMLButtonElement>document.getElementById(id);
                        if (!accordionElement.classList.contains("open")) {
                            accordionElement.click();
                        }
                    }

                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    } else {
                        firstFocusableEl.focus();
                    }
                });
            } else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }

    customInitializeRequiredFields(allClasses: string[]) {

        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });

        let classesToValidate = formattedAllClasses.join(",");

        let allElements = document.querySelectorAll(classesToValidate);

        for (let element of allElements) {
            let htmlElement = <HTMLElement>element;

            if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {
                if (!(htmlElement.hasAttribute("aria-required") && htmlElement.getAttribute("aria-required") === "true")) {
                    htmlElement.setAttribute("aria-required", "true");
                    let label = Core.findLabelForInput(htmlElement);

                    if (label !== null && !label.classList.contains("isRequired")) {
                        label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                        label.classList.add("isRequired");
                    }
                }
            }

            //Manual override when data-forcerequired=true
            if ("forcerequired" in htmlElement.dataset && htmlElement.dataset.forcerequired === "true") {

                htmlElement.dataset.percent = "1.00";

                if (!(htmlElement.hasAttribute("aria-required") && htmlElement.getAttribute("aria-required") === "true")) {
                    htmlElement.setAttribute("aria-required", "true");

                    let label = Core.findLabelForInput(htmlElement);

                    if (label !== null && !label.classList.contains("isRequired")) {
                        label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                        label.classList.add("isRequired");
                    }
                }
            }
        }
    }
}