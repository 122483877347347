/// <reference path="core.ts" / >

class PlanSectionSet {
    private deleteDataSourceConfirmModal: Modal;

    constructor() {
        let that = this;
        this.deleteDataSourceConfirmModal = new Modal("deleteDataSourceModal", null);

        let deleteDataSourceCancelButton = document.getElementById("deleteDataSourceCancel");
        if (deleteDataSourceCancelButton != null) {
            deleteDataSourceCancelButton.addEventListener("click", () => {
                that.deleteDataSourceConfirmModal.hide();
            });
        }

        let deleteDataSourceConfirmButton = document.getElementById("deleteDataSourceConfirm");
        if (deleteDataSourceConfirmButton != null) {
            deleteDataSourceConfirmButton.addEventListener("click", () => {
                if ("buttonid" in deleteDataSourceConfirmButton.dataset) {
                    that.deleteDataSourceConfirm(deleteDataSourceConfirmButton.dataset.buttonid);
                }
            });
        }

        document.addEventListener("click", (e) => {
            let target = e.target as HTMLElement;
            if (target.classList.contains("deleteDataSourceComments")) {
                let deleteButton = <HTMLButtonElement>target;
                that.deleteDataSource(deleteButton);
            }
        });
    }

    showDeleteDataSource() {
        var programContainers = document.querySelectorAll(".performance-data-table, #futureReadyPAIndexGradeLevelAndStudentGroupStrengths, #futureReadyPAIndexGradeLevelAndStudentGroupChallenges");
        for (let container of programContainers) {
            let dataSources = container.querySelectorAll(".rowContainer");
            let deleteButtons = container.querySelectorAll(".deleteDataSourceComments");
            if (dataSources.length > 1) {
                for (let deleteButton of deleteButtons) {
                    let deleteButtonElement = <HTMLElement>deleteButton;
                    deleteButtonElement.classList.remove("hide");
                }
            } else {
                for (let deleteButton of deleteButtons) {
                    let deleteButtonElement = <HTMLElement>deleteButton;
                    deleteButtonElement.classList.add("hide");
                }
            }

        }
    }

    deleteDataSource(deleteButton: HTMLButtonElement) {
        let that = this;
        let hasExistingData = false;
        let dataSourceRow = Core.findClosest(deleteButton, ".rowContainer");
        let dataSourceElements = dataSourceRow.querySelectorAll('.dataSourceField');
        for (let element of dataSourceElements) {
            let htmlElement = <HTMLElement>element;
            if ("planpropertypk" in htmlElement.dataset && parseInt(htmlElement.dataset.planpropertypk) > 0) {
                hasExistingData = true;
                break;
            }
        }

        if (hasExistingData) {
            that.deleteDataSourceConfirmModal.callingId = deleteButton.id;
            that.deleteDataSourceConfirmModal.show();
            that.deleteDataSourceConfirmModal.addAttributeToElement(that.deleteDataSourceConfirmModal.id, "#deleteDataSourceConfirm", "buttonid", deleteButton.id);
        } else {
            let container = <HTMLElement>Core.findClosest(deleteButton, ".rowContainer");
            if (container != null) {
                let nextFocusable = Core.getNextFocusableElement(deleteButton);
                container.parentNode.removeChild(container);
                Core.createHTMLAlert("alertMessageDiv", "Data Source row removed", 'success', 3000, null);
                that.showDeleteDataSource();
                nextFocusable.focus();
            }
        }
    }

    deleteDataSourceConfirm(buttonId: string) {
        let that = this;
        let deleteButton = document.getElementById(buttonId);
        let dataSourceRow = Core.findClosest(deleteButton, ".rowContainer");

        that.deleteDataSourceConfirmModal.hide();
        Core.showLoader();
        let allRemoveElements = [];

        //Get all data source elements with this row and button row
        let dataSourceElements = dataSourceRow.querySelectorAll('.dataSourceField');
        for (let element of dataSourceElements) {
            let htmlElement = <HTMLElement>element;
            if ("planpropertypk" in htmlElement.dataset && parseInt(htmlElement.dataset.planpropertypk) > 0) {
                allRemoveElements.push(parseInt(htmlElement.dataset.planpropertypk));
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/DeleteDataSource', true);
        xhr.setRequestHeader('Content-type', 'application/json');
        xhr.onload = function () {
            Core.hideLoader();
            let jsonResponse = JSON.parse(xhr.response);
            if (xhr.status === 200 && jsonResponse.success) {
                let nextFocusable = Core.getNextFocusableElement(deleteButton);
                dataSourceRow.parentNode.removeChild(dataSourceRow);
                Core.createHTMLAlert("alertMessageDiv", "Data Source row successfully deleted", 'success', 3000, null);
                that.showDeleteDataSource();
                nextFocusable.focus();
            } else {
                Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this data source", 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(allRemoveElements));
    }

    setUpDeleteDataSourceConfirmModal(buttonId: string) {
        let that = this;
        let deleteButton = document.getElementById(buttonId);

        that.deleteDataSourceConfirmModal.callingId = deleteButton.id;
        that.deleteDataSourceConfirmModal.show();
        that.deleteDataSourceConfirmModal.addAttributeToElement(that.deleteDataSourceConfirmModal.id, "#deleteDataSourceConfirm", "buttonid", deleteButton.id);
    }

}

//FutureReadyPAIndex
class FutureReadyPAIndex {

    validationClasses: string[];

    private _core: Core;
    private _planSectionSet: PlanSectionSet;
    constructor() {
        let that = this;
        this._core = new Core();
        this._planSectionSet = new PlanSectionSet();
        this.validationClasses = ["futureReadyPAIndexField","futureReadyPAIndexFieldMulti"];
        let statewide = document.getElementById("futureReadyPAIndexMeasureASS2030GAddRow");
        if (statewide !== null)
            statewide.addEventListener("click", (e: Event) => this.addStrengthAllStudent());

        let improvement = document.getElementById("futureReadyPAIndexMeasureSIOSITAddRow");
        if (improvement !== null)
            improvement.addEventListener("click", (e: Event) => this.addStrengthStatewideImprovement());

        let challenge = document.getElementById("futureReadyPAIndexChallengeAddRow");
        if (challenge !== null)
            challenge.addEventListener("click", (e: Event) => this.addChallenge());

        let schoollevelstrength = document.getElementById("futureReadyPAIndexSchoolLevelStrengthsAddRow");
        if (schoollevelstrength !== null)
            schoollevelstrength.addEventListener("click", (e: Event) => this.addSchoolLevelStrength());

        let schoollevelchallenge = document.getElementById("futureReadyPAIndexSchoolLevelChallengesAddRow");
        if (schoollevelchallenge !== null)
            schoollevelchallenge.addEventListener("click", (e: Event) => this.addSchoolLevelChallenge());

        let gradelevelstudentgroupstrength = document.getElementById("futureReadyPAIndexGradeLevelAndStudentGroupStrengthsAddRow");
        if (gradelevelstudentgroupstrength !== null)
            gradelevelstudentgroupstrength.addEventListener("click", (e: Event) => this.addGradeLevelStudentGroupStrength());

        let gradelevelstudentgroupchallenge = document.getElementById("futureReadyPAIndexGradeLevelAndStudentGroupChallengesAddRow");
        if (gradelevelstudentgroupchallenge !== null)
            gradelevelstudentgroupchallenge.addEventListener("click", (e: Event) => this.addGradeLevelStudentGroupChallenge());

        let saveButton = document.getElementById("futureReadyPAIndexSave");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e: Event) => this.save("save"));

        document.addEventListener("click", (e) => {
            let target = e.target as HTMLElement;
            if (target.classList.contains("deleteStrengthComments")) {
                let gradelevelstudentgroupstrengthButton = <HTMLButtonElement>target;
                that.deleteGradeLevelStudentGroupStrength(gradelevelstudentgroupstrengthButton);
            } else if (target.classList.contains("deleteChallengeComments")) {
                let gradelevelstudentgroupchallengeButton = <HTMLButtonElement>target;
                that.deleteGradeLevelStudentGroupChallenge(gradelevelstudentgroupchallengeButton);
            }
        });

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        this._core.activateSubMenu("subNavReadySetGo");
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let reminderCloseButton = <HTMLButtonElement>document.getElementById("futureReadyPAIndexReportReminderClose");
        if (reminderCloseButton !== null) {
            reminderCloseButton.addEventListener("click", (e: Event) => this.closeReminderModal());
        }

        const downloadFRPAIFile = document.getElementById("downloadFRPAIFile");
        if (downloadFRPAIFile !== null)
            downloadFRPAIFile.addEventListener("click", (e: Event) => this.getFRPAIFile(e));
    }

    closeReminderModal() {
        let close = document.getElementById("closeModalFRPAIndexReportsReminder");
        close.click();
    }

    getCore() {
        return this._core;
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];

        let planElement = document.getElementById("futureReadyPAIndexForm");
        let planPk = planElement.dataset.planpk;
        let allElements = document.getElementsByClassName("futureReadyPAIndexField");
        for (let element of allElements) {
            let htmlElement = <HTMLInputElement>element;
            let planPropertyPK: number = 0;
            let rowNumber: number = parseInt(htmlElement.dataset.row);

            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }

            if (htmlElement.value !== "" || planPropertyPK > 0) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: parseInt(planPk),
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: htmlElement.value,
                    LookupCodeFK: null,
                    RowNbr: rowNumber,
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let allCheckElements = document.getElementsByClassName("futureReadyPAIndexCheckboxField");
        for (let ele of allCheckElements) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            planPropertyPK = parseInt(element.dataset.planpropertypk);
            const dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            const buttonRow = parseInt(element.dataset.buttonrow);

            let val = "";
            if (element.checked)
                val = "on";
            else
                val = "off";

            let saveItem: IPlanPropertyExtended = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: parseInt(planPk),
                PropertyFK: propertyPK,
                TextValue: val,
                LookupCodeFK: null,
                RowNbr: parseInt(rowNumber),
                IsDeletedInd: false,
                DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                ButtonRow: buttonRow
            };

            allSaveElements.push(saveItem);
        }

        const allMultis = [];
        const multiCheckboxes = document.getElementsByClassName("futureReadyPAIndexFieldMulti");
        for (const multi of multiCheckboxes) {
            //get the data for this dropdown
            const multiElement = <HTMLDivElement>multi;

            const parentPropertyPK = multiElement.dataset.propertypk;
            const parentPlanPropertyPK = multiElement.dataset.planpropertypk;
            const thisRow = multiElement.dataset.row;

            const thisMulti = {
                "PropertyPK": parentPropertyPK,
                "PlanPropertyPK": parentPlanPropertyPK,
                "RowNbr": thisRow,
                "Checkboxes": []
            };

            //get all checks for this checkbox
            const allTheseCheckboxes = document.querySelectorAll(`.futureReadyPAIndexFieldMultiCheckbox[data-row='${thisRow}'][data-parentpropertypk='${parentPropertyPK}']`);
            const theseChecks = [];
            for (const thisCheckbox of allTheseCheckboxes) {
                const checkElement = <HTMLInputElement>thisCheckbox;

                const checkLookupCodePK = checkElement.dataset.lookupcodepk;
                const checkPlanPropertyPK = checkElement.dataset.planpropertypk;
                const checkPropertyPK = checkElement.dataset.parentpropertypk;

                if ((checkPlanPropertyPK !== "" && checkPlanPropertyPK !== "0") || checkElement.checked) {
                    let textValue = "off";
                    if (checkElement.checked)
                        textValue = "on";

                    const thisCheck = {
                        "PropertyPK": checkPropertyPK,
                        "PlanPropertyPK": checkPlanPropertyPK,
                        "LookupCodePK": checkLookupCodePK,
                        "DataPointPlanPropertyPK": parentPlanPropertyPK,
                        "TextValue": textValue
                    };

                    theseChecks.push(thisCheck);
                }
            }

            thisMulti.Checkboxes = theseChecks;

            allMultis.push(thisMulti);
        }

        const saveData = {
            "SaveData": allSaveElements,
            "MultiDropdown": allMultis,
            "PlanFK": planPk
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/SaveFutureReadyPAIndex', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, parseInt(planPk));
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(saveData));
        }
    }

    addStrengthAllStudent() {
        let core = this._core;
        let table = (<HTMLTableElement>document.getElementById("futureReadyPAIndexMeasureASS2030G"));

        let newRow: number = 0;
        let strengthList = document.getElementsByClassName("frStrengthMeasureASS2030G");
        let _that = this;
        for (let strength of strengthList) {
            let element = <HTMLSelectElement>strength;
            if (newRow < parseInt(element.dataset.row)) {
                newRow = parseInt(element.dataset.row);
            }
        }

        newRow++;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/FutureReadyPAIndexStrength');
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = table.querySelector("tbody");
                $(element).append(xhr.responseText);
                Core.createHTMLAlert("alertMessageDiv", "New Strength Row Added", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("rowNumber=" + newRow + "&label=ASS2030G");
    }

    addStrengthStatewideImprovement() {
        let core = this._core;
        let table = (<HTMLTableElement>document.getElementById("futureReadyPAIndexMeasureSIOSIT"));

        let newRow: number = 0;
        let strengthList = document.getElementsByClassName("frStrengthMeasureSIOSIT");
        let _that = this;
        for (let strength of strengthList) {
            let element = <HTMLSelectElement>strength;
            if (newRow < parseInt(element.dataset.row)) {
                newRow = parseInt(element.dataset.row);
            }
        }

        newRow++;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/FutureReadyPAIndexStrength');
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = table.querySelector("tbody");
                $(element).append(xhr.responseText);
                Core.createHTMLAlert("alertMessageDiv", "New Statewide Strength Row Added", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("rowNumber=" + newRow + "&label=SIOSIT");
    }

    addChallenge() {
        let core = this._core;
        let table = (<HTMLTableElement>document.getElementById("futureReadyPAIndexChallenge"));

        let newRow: number = 0;
        let challengeList = document.getElementsByClassName("frChallenge");
        let _that = this;
        for (let challenge of challengeList) {
            let element = <HTMLSelectElement>challenge;
            if (newRow < parseInt(element.dataset.row)) {
                newRow = parseInt(element.dataset.row);
            }
        }

        newRow++;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/FutureReadyPAIndexChallenge');
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = table.querySelector("tbody");
                $(element).append(xhr.responseText);
                Core.createHTMLAlert("alertMessageDiv", "New Challenge Row Added", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("rowNumber=" + newRow + "");
    }

    addSchoolLevelStrength() {
        let core = this._core;
        let table = (<HTMLTableElement>document.getElementById("futureReadyPAIndexSchoolLevelStrengths"));
        let planElement = document.getElementById("futureReadyPAIndexForm");
        let planPk = planElement.dataset.planpk;

        let newRow: number = 0;
        let strengthList = document.getElementsByClassName("frSchoolLevelStrengths");
        let _that = this;
        for (let strength of strengthList) {
            let element = <HTMLSelectElement>strength;
            if (newRow < parseInt(element.dataset.row)) {
                newRow = parseInt(element.dataset.row);
            }
        }

        newRow++;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/FutureReadyPAIndexSchoolLevel');
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = table.querySelector("tbody");
                $(element).append(xhr.responseText);
                Core.createHTMLAlert("alertMessageDiv", "New Strength Row Added", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("rowNumber=" + newRow + "&label=Strengths&planFK=" + planPk);
    }

    addSchoolLevelChallenge() {
        let core = this._core;
        let table = (<HTMLTableElement>document.getElementById("futureReadyPAIndexSchoolLevelChallenges"));
        let planElement = document.getElementById("futureReadyPAIndexForm");
        let planPk = planElement.dataset.planpk;

        let newRow: number = 0;
        let challengeList = document.getElementsByClassName("frSchoolLevelChallenges");
        let _that = this;
        for (let challenge of challengeList) {
            let element = <HTMLSelectElement>challenge;
            if (newRow < parseInt(element.dataset.row)) {
                newRow = parseInt(element.dataset.row);
            }
        }

        newRow++;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/FutureReadyPAIndexSchoolLevel');
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = table.querySelector("tbody");
                $(element).append(xhr.responseText);
                Core.createHTMLAlert("alertMessageDiv", "New Challenge Row Added", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("rowNumber=" + newRow + "&label=Challenges&planFK=" + planPk);
    }

    addGradeLevelStudentGroupStrength() {
        let core = this._core
        let newRow: number = 0;
        let _that = this;

        let gradeLevelStudentGroupFields = document.getElementsByClassName("frGradeLevelAndStudentGroupStrengths");
        for (let glsg of gradeLevelStudentGroupFields) {
            let glsgElement = <HTMLInputElement>glsg;
            if (newRow < parseInt(glsgElement.dataset.row))
                newRow = parseInt(glsgElement.dataset.row);
        }

        newRow++;

        let planElement = document.getElementById("futureReadyPAIndexForm");
        let planPk = planElement.dataset.planpk;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/FutureReadyPAIndexGradeLevelAndStudentGroup', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                $("#futureReadyPAIndexGradeLevelAndStudentGroupStrengths > div > div").append(xhr.responseText);
                $('.multiSelectESSA').MultiSelect({
                    ShowAllCheckbox: false
                });
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "New Strength Row Added", 'success', 3000, null);
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("rowNumber=" + newRow + "&label=Strengths&planFK=" + parseInt(planPk));
    }

    addGradeLevelStudentGroupChallenge() {
        let core = this._core;
        let newRow: number = 0;
        let _that = this;

        let gradeLevelStudentGroupFields = document.getElementsByClassName("frGradeLevelAndStudentGroupChallenges");
        for (let glsg of gradeLevelStudentGroupFields) {
            let glsgElement = <HTMLInputElement>glsg;
            if (newRow < parseInt(glsgElement.dataset.row))
                newRow = parseInt(glsgElement.dataset.row);
        }

        let planElement = document.getElementById("futureReadyPAIndexForm");
        let planPk = planElement.dataset.planpk;

        newRow++;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/FutureReadyPAIndexGradeLevelAndStudentGroup', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                $("#futureReadyPAIndexGradeLevelAndStudentGroupChallenges > div > div").append(xhr.responseText);
                $('.multiSelectESSA').MultiSelect({
                    ShowAllCheckbox: false
                });
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "New Challenge Row Added", 'success', 3000, null);
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("rowNumber=" + newRow + "&label=Challenges&planFK=" + parseInt(planPk));
    }

    deleteGradeLevelStudentGroupStrength(deleteButton: HTMLButtonElement) {
        let that = this;
        let hasExistingData = false;
        let dataSourceRow = Core.findClosest(deleteButton, ".rowContainer");
        let dataSourceElements = dataSourceRow.querySelectorAll('.dataSourceField');
        for (let element of dataSourceElements) {
            let htmlElement = <HTMLElement>element;
            if ("planpropertypk" in htmlElement.dataset && parseInt(htmlElement.dataset.planpropertypk) > 0) {
                hasExistingData = true;
                break;
            }
        }

        if (hasExistingData) {
            that._planSectionSet.setUpDeleteDataSourceConfirmModal(deleteButton.id);
        } else {
            let container = <HTMLElement>Core.findClosest(deleteButton, ".rowContainer");
            if (container != null) {
                let nextFocusable = Core.getNextFocusableElement(deleteButton);
                container.parentNode.removeChild(container);
                Core.createHTMLAlert("alertMessageDiv", "Data Source row removed", 'success', 3000, null);
                that._planSectionSet.showDeleteDataSource();
                nextFocusable.focus();
            }
        }
    }

    deleteGradeLevelStudentGroupChallenge(deleteButton: HTMLButtonElement) {
        let that = this;
        let hasExistingData = false;
        let dataSourceRow = Core.findClosest(deleteButton, ".rowContainer");
        let dataSourceElements = dataSourceRow.querySelectorAll('.dataSourceField');
        for (let element of dataSourceElements) {
            let htmlElement = <HTMLElement>element;
            if ("planpropertypk" in htmlElement.dataset && parseInt(htmlElement.dataset.planpropertypk) > 0) {
                hasExistingData = true;
                break;
            }
        }

        if (hasExistingData) {
            that._planSectionSet.setUpDeleteDataSourceConfirmModal(deleteButton.id);
        } else {
            let container = <HTMLElement>Core.findClosest(deleteButton, ".rowContainer");
            if (container != null) {
                let nextFocusable = Core.getNextFocusableElement(deleteButton);
                container.parentNode.removeChild(container);
                Core.createHTMLAlert("alertMessageDiv", "Data Source row removed", 'success', 3000, null);
                that._planSectionSet.showDeleteDataSource();
                nextFocusable.focus();
            }
        }
    }

    getFRPAIFile(e: Event) {
        const element = <HTMLAnchorElement>e.target;

        
    }
}

//Future Ready PA Academics
class FutureReadyPAAcademics {

    validationClasses: string[];

    private _core: Core;
    private _planSectionSet: PlanSectionSet;
    constructor() {
        this._core = new Core();
        this._planSectionSet = new PlanSectionSet();
        this.validationClasses = ["futureReadyPAAcademicsField", "futureReadyPAAcademicsCheckboxField"];
        let elaButton = document.getElementById("futureReadyPAAcademicsAddDataSourceELA");
        if (elaButton !== null)
            elaButton.addEventListener("click", (e: Event) => this.addDataSource("futureReadyPAAcademicsELA", "ELA", "futureReadyPAAcademicsField", "spdfuturereadypaacademics"));

        let mathButton = document.getElementById("futureReadyPAAcademicsAddDataSourceMath");
        if (mathButton !== null)
            mathButton.addEventListener("click", (e: Event) => this.addDataSource("futureReadyPAAcademicsMath", "Mathematics", "futureReadyPAAcademicsField", "spdfuturereadypaacademics"));

        let steeButton = document.getElementById("futureReadyPAAcademicsAddDataSourceSTEE");
        if (steeButton !== null)
            steeButton.addEventListener("click", (e: Event) => this.addDataSource("futureReadyPAAcademicsSTEE", "STEE", "futureReadyPAAcademicsField", "spdfuturereadypaacademics"));

        let saveButton = document.getElementById("futureReadyPAAcademicsSave");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e: Event) => this.save("save"));

        this.grouping();
        this.initializeRequiredFieldsCustom();

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }

        this._core.activateSubMenu("subNavReadySetGo");
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        const futureReadyPAAcademicsGroupings = document.getElementsByClassName("futureReadyPAAcademicsGrouping");
        for (const futureReadyPAAcademicsGrouping of futureReadyPAAcademicsGroupings)
            futureReadyPAAcademicsGrouping.addEventListener("change", (e: Event) => this.groupingChange(e.target as HTMLInputElement));
    }

    getCore() {
        return this._core;
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planElement = document.getElementById("futureReadyPAAcademicsForm");
        let planPk = planElement.dataset.planpk;
        let allElements = document.getElementsByClassName("futureReadyPAAcademicsField");
        for (let element of allElements) {
            let htmlElement = <HTMLInputElement>element;
            let planPropertyPK: number = 0;
            let rowNumber: number = parseInt(htmlElement.dataset.row);

            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }

            if (htmlElement.value !== "" || planPropertyPK > 0) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: parseInt(planPk),
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: htmlElement.value,
                    LookupCodeFK: null,
                    RowNbr: rowNumber,
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        const checkboxInputs = document.getElementsByClassName("futureReadyPAAcademicsCheckboxField");
        for (let ele of checkboxInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;

            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            let textValue = "off";
            if (element.checked)
                textValue = "on";
            else
                textValue = "off";

            if (element.checked || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: parseInt(planPk),
                    PropertyFK: propertyPK,
                    TextValue: textValue,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/SaveFutureReadyPAAcademics', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    core.pageReload(true, parseInt(planPk));
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                    Core.hideLoader();
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    addDataSource(tableName: string, pageSection: string, className: string, pageCode: string) {
        let core = this._core;
        let table = (<HTMLTableElement>document.getElementById(tableName));
        let inputBoxes = table.querySelectorAll<HTMLTextAreaElement>("textarea");
        let rowCount = 0;

        for (let input of inputBoxes) {
            if (parseInt(input.getAttribute("data-row")) > rowCount) {
                rowCount++;
            }
        }

        rowCount++;

        let xhr = new XMLHttpRequest();

        xhr.open('POST', '/Set/StudentPerformanceDataDataSource', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = table.querySelector("tbody");
                $(element).append(xhr.responseText);
                Core.createHTMLAlert("alertMessageDiv", "New Data Source Row Added", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("pageSection=" + pageSection + "&rowNumber=" + rowCount + "&className=" + className + "&pageCode=" + pageCode);
    }

    grouping() {
        const futureReadyPAAcademicsGroupings = document.getElementsByClassName("futureReadyPAAcademicsGrouping");
        for (const futureReadyPAAcademicsGrouping of futureReadyPAAcademicsGroupings)
            this.groupingChange(futureReadyPAAcademicsGrouping as HTMLInputElement);
    }

    groupingChange(checkElement: HTMLInputElement) {
        const group = checkElement.dataset.group;

        const allElementsInGroup = document.querySelectorAll(`.futureReadyPAAcademicsGroupField[data-group='${group}']`);
        for (const element of allElementsInGroup) {
            const ele = <HTMLTextAreaElement>element;

            if (checkElement.checked) {
                this.makeElementOptional(ele);
            } else {
                this.makeElementRequired(ele);
            }
        }
    }

    makeElementOptional(inputElement: HTMLTextAreaElement) {
        inputElement.dataset.percent = "0.00";
        inputElement.setAttribute("aria-required", "false");
        inputElement.readOnly = true;
        inputElement.disabled = true;
    }

    makeElementRequired(inputElement: HTMLTextAreaElement) {
        inputElement.dataset.percent = "1.00";
        inputElement.setAttribute("aria-required", "true");
        inputElement.readOnly = false;
        inputElement.disabled = false;

        let label = Core.findLabelForInput(inputElement);

        if (label !== null && !label.classList.contains("isRequired")) {
            label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
            label.classList.add("isRequired");
        }
    }

    initializeRequiredFieldsCustom() {

        //If any field in a row is filled out, force rest of row required 
        let accordions = document.querySelectorAll(".Accordion-panel:not(.summary-dark)");
        for (let accordion of accordions) {
            let accordionRows = accordion.querySelectorAll("tr.rowContainer");
            for (let accordionRow of accordionRows) {
                let elements = accordionRow.getElementsByClassName("futureReadyPAAcademicsField") as HTMLCollectionOf<HTMLElement>;

                for (let element of elements) {

                    if (element instanceof HTMLTextAreaElement) {
                        let htmlElement = <HTMLTextAreaElement>element;
                        if (element.value != "" && !element.disabled) {
                            for (let otherElement of elements) {
                                    otherElement.dataset.forcerequired = "true";
                            }
                            break;
                        }
                    }
                }
            }
        }

        this._core.initializeRequiredFields(this.validationClasses);
    }
}

//Related Academics
class RelatedAcademics {

    validationClasses: string[];

    private _core: Core;
    private _planSectionSet: PlanSectionSet;
    constructor() {
        this._core = new Core();
        this._planSectionSet = new PlanSectionSet();
        this.validationClasses = ["relatedAcademicsField","relatedAcademicsFileField"];
        let careerReadinessButton = document.getElementById("relatedAcademicsAddDataSourceCareerReadiness");
        if (careerReadinessButton !== null)
            careerReadinessButton.addEventListener("click", (e: Event) => this.addDataSource("relatedAcademicsCareerReadiness", "Career", "relatedAcademicsField", "spdrelatedacademics"));

        let careerAndTechButton = document.getElementById("relatedAcademicsAddDataSourceCareerAndTech");
        if (careerAndTechButton !== null)
            careerAndTechButton.addEventListener("click", (e: Event) => this.addDataSource("relatedAcademicsCareerAndTech", "CTEP", "relatedAcademicsField", "spdrelatedacademics"));

        let artsAndHumanitiesButton = document.getElementById("relatedAcademicsAddDataSourceArtsAndHumanities");
        if (artsAndHumanitiesButton !== null)
            artsAndHumanitiesButton.addEventListener("click", (e: Event) => this.addDataSource("relatedAcademicsArtsAndHumanities", "Arts", "relatedAcademicsField", "spdrelatedacademics"));

        let environmentAndEcologyButton = document.getElementById("relatedAcademicsAddDataSourceEnvironmentAndEcology");
        if (environmentAndEcologyButton !== null)
            environmentAndEcologyButton.addEventListener("click", (e: Event) => this.addDataSource("relatedAcademicsEnvironmentAndEcology", "EAE", "relatedAcademicsField", "spdrelatedacademics"));

        let familyAndConsumerButton = document.getElementById("relatedAcademicsAddDataSourceFamilyAndConsumer");
        if (familyAndConsumerButton !== null)
            familyAndConsumerButton.addEventListener("click", (e: Event) => this.addDataSource("relatedAcademicsFamilyAndConsumer", "FCS", "relatedAcademicsField", "spdrelatedacademics"));

        let healthAndSafetyButton = document.getElementById("relatedAcademicsAddDataSourceHealthAndSafety");
        if (healthAndSafetyButton !== null)
            healthAndSafetyButton.addEventListener("click", (e: Event) => this.addDataSource("relatedAcademicsHealthAndSafety", "HSPE", "relatedAcademicsField", "spdrelatedacademics"));

        let socialStudiesButton = document.getElementById("relatedAcademicsAddDataSourceSocialStudies");
        if (socialStudiesButton !== null)
            socialStudiesButton.addEventListener("click", (e: Event) => this.addDataSource("relatedAcademicsSocialStudies", "SS", "relatedAcademicsField", "spdrelatedacademics"));

        let saveButton = document.getElementById("relatedAcademicsSave");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);                                              
        let noCTEPCheckbox = document.getElementById("relatedAcademicsCareerandTechnicalEducationCTEProgramsOmit");
        if (noCTEPCheckbox !== null)
            noCTEPCheckbox.addEventListener("click", (e: Event) => this.noPrograms("relatedAcademicsCareerAndTech", "relatedAcademicsCareerandTechnicalEducationCTEProgramsOmit"))

        let noArtsAndHumanitiesCheckbox = document.getElementById("relatedAcademicsArtsandHumanitiesOmit");
        if (noArtsAndHumanitiesCheckbox !== null)
            noArtsAndHumanitiesCheckbox.addEventListener("click", (e: Event) => this.noPrograms("relatedAcademicsArtsAndHumanities", "relatedAcademicsArtsandHumanitiesOmit"));

        let noEnvironmentAndEcologyCheckbox = document.getElementById("relatedAcademicsEnvironmentandEcologyOmit");
        if (noEnvironmentAndEcologyCheckbox !== null)
            noEnvironmentAndEcologyCheckbox.addEventListener("click", (e: Event) => this.noPrograms("relatedAcademicsEnvironmentAndEcology", "relatedAcademicsEnvironmentandEcologyOmit"));

        let noFamilyAndConsumerCheckbox = document.getElementById("relatedAcademicsFamilyandConsumerSciencesOmit");
        if (noFamilyAndConsumerCheckbox !== null)
            noFamilyAndConsumerCheckbox.addEventListener("click", (e: Event) => this.noPrograms("relatedAcademicsFamilyAndConsumer", "relatedAcademicsFamilyandConsumerSciencesOmit"));

        let noHealthAndSafetyCheckbox = document.getElementById("relatedAcademicsHealthSafetyandPhysicalEducationOmit");
        if (noHealthAndSafetyCheckbox !== null)
            noHealthAndSafetyCheckbox.addEventListener("click", (e: Event) => this.noPrograms("relatedAcademicsHealthAndSafety", "relatedAcademicsHealthSafetyandPhysicalEducationOmit"));

        let noSocialStudiesCheckbox = document.getElementById("relatedAcademicsSocialStudiesCivicsandGovernmentEconomicsGeographyHistoryOmit");
        if (noSocialStudiesCheckbox !== null)
            noSocialStudiesCheckbox.addEventListener("click", (e: Event) => this.noPrograms("relatedAcademicsSocialStudies", "relatedAcademicsSocialStudiesCivicsandGovernmentEconomicsGeographyHistoryOmit"));

        let allPDEApprovedCheckboxes = document.getElementsByClassName("checkIfPDEApprovedProgramCheckbox");
        for (let allPDEApprovedCheckbox of allPDEApprovedCheckboxes)
            allPDEApprovedCheckbox.addEventListener("click", (e: Event) => this.pdeApprovedProgram(e))

        const relatedAcademicsArticulationAgreementsNotAvailableCheck = document.getElementById("relatedAcademicsArticulationAgreementsNotAvailable");
        if (relatedAcademicsArticulationAgreementsNotAvailableCheck !== null) {
            relatedAcademicsArticulationAgreementsNotAvailableCheck.addEventListener("change", (e: Event) => this.articulationAgreementNotAvailable(e.target as HTMLInputElement));
            this.articulationAgreementNotAvailable(relatedAcademicsArticulationAgreementsNotAvailableCheck as HTMLInputElement);
        }

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        this.initializeRequiredFieldsCustom();
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.activateSubMenu("subNavReadySetGo");
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        this.bindUploads();

        let deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e: Event) => this.deleteFileConfirm(e));

        let deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e: Event) => this.deleteFileCancel());

        this.bindDeleteFiles();

        const addArticulationAgreementButton = document.getElementById("relatedAcademicsAddArticulationAgreement");
        if (addArticulationAgreementButton !== null)
            addArticulationAgreementButton.addEventListener("click", (e: Event) => this.addArticulationAgreement());

        let deleteArticulationAgreementConfirmButton = document.getElementById("relatedAcademicsDeleteArticulationAgreementConfirm");
        if (deleteArticulationAgreementConfirmButton !== null)
            deleteArticulationAgreementConfirmButton.addEventListener("click", (e: Event) => this.deleteArticulationAgreementConfirm(e));

        let deleteArticulationAgreementCancelButton = document.getElementById("relatedAcademicsDeleteArticulationAgreementCancel");
        if (deleteArticulationAgreementCancelButton !== null)
            deleteArticulationAgreementCancelButton.addEventListener("click", (e: Event) => this.deleteArticulationAgreementCancel());

        this.bindDeleteArticulationAgreements();


    }

    getCore() {
        return this._core;
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }

        let allSaveElements = [];
        let planElement = document.getElementById("relatedAcademicsForm");
        let planPk = planElement.dataset.planpk;
        let allElements = document.getElementsByClassName("relatedAcademicsField");
        for (let element of allElements) {
            let htmlElement = <HTMLInputElement>element;
            let planPropertyPK: number = 0;
            let rowNumber: number = parseInt(htmlElement.dataset.row);

            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }

            if (htmlElement.value !== "" || planPropertyPK > 0) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: parseInt(planPk),
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: htmlElement.value,
                    LookupCodeFK: null,
                    RowNbr: rowNumber,
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        allElements = document.getElementsByClassName("relatedAcademicsCheckField");
        for (let element of allElements) {
            let htmlElement = <HTMLInputElement>element;
            let planPropertyPK = 0;
            let rowNumber = 0;
            let isChecked = "off";
            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }

            if (htmlElement.dataset.row && htmlElement.dataset.row !== "0") {
                rowNumber = parseInt(htmlElement.dataset.row);
            }

            if (htmlElement.checked) {
                isChecked = "on";
            }
            let saveItem = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: parseInt(planPk),
                PropertyFK: parseInt(htmlElement.dataset.propertypk),
                TextValue: isChecked,
                LookupCodeFK: null,
                RowNbr: rowNumber,
                IsDeletedInd: false
            };
            allSaveElements.push(saveItem);
        }

        const allFileElements = [];
        const files = document.getElementsByClassName("relatedAcademicsFileUploadField");
        for (let ele of files) {
            let element = <HTMLInputElement>ele;
            let sequenceNbr = parseInt(element.dataset.sequencenbr);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk)
            let fileUploadPK = parseInt(element.dataset.fileuploadpk);
            let propertyPK = parseInt(element.dataset.propertypk);

            let saveItem: IPlanPropertyFile = {
                PlanPropertyFilePK: planPropertyFilePK,
                SequenceNbr: sequenceNbr,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanFK: parseInt(planPk)
            };

            allFileElements.push(saveItem);
        }

        const allData = {
            "ElementData": allSaveElements,
            "FileData": allFileElements,
            "PlanFK": planPk
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/SaveRelatedAcademics', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    core.pageReload(true, parseInt(planPk))
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }

    initializeRequiredFieldsCustom() {

        //If any field in a row is filled out, force rest of row required 
        let accordions = document.querySelectorAll(".Accordion-panel:not(.summary-dark)");
        for (let accordion of accordions) {
            let accordionRows = accordion.querySelectorAll("tr.rowContainer");
            for (let accordionRow of accordionRows) {
                let elements = accordionRow.getElementsByClassName("relatedAcademicsField") as HTMLCollectionOf<HTMLElement>;

                for (let element of elements) {

                    if (element instanceof HTMLTextAreaElement) {
                        let htmlElement = <HTMLTextAreaElement>element;
                        if (element.value != "" && !element.disabled) {
                            for (let otherElement of elements) {
                                if (otherElement.dataset.percent == "1.00") {
                                    otherElement.dataset.forcerequired = "true";
                                }
                            }
                            break;
                        }
                    }
                }
            }
        }

        this._core.initializeRequiredFields(this.validationClasses);
    }

    addDataSource(tableName: string, pageSection: string, className: string, pageCode: string) {
        let core = this._core;
        let table = (<HTMLTableElement>document.getElementById(tableName));
        let inputBoxes = table.querySelectorAll<HTMLTextAreaElement>("textarea");
        let rowCount = 0;

        for (let input of inputBoxes) {
            if (parseInt(input.getAttribute("data-row")) > rowCount) {
                rowCount++;
            }
        }

        rowCount++;

        let xhr = new XMLHttpRequest();

        xhr.open('POST', '/Set/StudentPerformanceDataDataSource', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = table.querySelector("tbody");
                $(element).append(xhr.responseText);
                Core.createHTMLAlert("alertMessageDiv", "New Data Source Row Added", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("pageSection=" + pageSection + "&rowNumber=" + rowCount + "&className=" + className + "&pageCode=" + pageCode);
    }

    noPrograms(tableName: string, checkboxName: string) {
        let table = (<HTMLTableElement>document.getElementById(tableName));
        let inputBoxes = table.querySelectorAll<HTMLTextAreaElement>("textarea");
        let checkbox = <HTMLInputElement>document.getElementById(checkboxName);

        if (checkbox.readOnly) {
            if (checkbox.checked)
                checkbox.checked = false;
            else
                checkbox.checked = true;
            return false;
        } else {
            for (let input of inputBoxes) {
                if (checkbox.checked) {
                    input.value = " ";
                    input.readOnly = true;
                }
                else {
                    input.readOnly = false;
                    input.value = "";
                }
            }
        }
    }

    pdeApprovedProgram(e: Event) {
        let checkboxElement = <HTMLInputElement>e.target;
        if (checkboxElement.readOnly) {
            if (checkboxElement.checked)
                checkboxElement.checked = false;
            else
                checkboxElement.checked = true;

            return false;
        }
    }

    checkForCheckboxes() {
        let allElements = document.getElementsByClassName("relatedAcademicsOmitCheckField");
        for (let element of allElements) {
            let htmlElement = <HTMLInputElement>element;
            let tableName = element.getAttribute("data-tablename");
            let table = (<HTMLTableElement>document.getElementById(tableName));
            let inputBoxes = table.querySelectorAll<HTMLTextAreaElement>("textarea");

            if (htmlElement.checked) {
                for (let input of inputBoxes) {
                    input.readOnly = true;
                }
            }
        }
    }

    bindUploads() {
        const uploadArticulationAgreements = document.querySelectorAll(`.relatedAcademicsArticulationAgreementsUploadArticulationAgreement`);
        for (const uploadArticulationAgreement of uploadArticulationAgreements)
            uploadArticulationAgreement.addEventListener("change", (e: Event) => this.uploadFile(e.target as HTMLInputElement));
    }

    uploadFile(e: HTMLInputElement) {
        let core = this._core;
        Core.showLoader();
        let formName = e.dataset.formname;

        let row = e.dataset.row;

        const that = this;

        let uploadSampleForm = <HTMLFormElement>document.getElementById(formName);
        let formData = new FormData(uploadSampleForm);

        let propertyPK = e.dataset.propertypk;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();

                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);

                        e.value = "";

                        let fileList = document.querySelector(`.uploadFileList[data-row='${row}']`)

                        let currentFileList = fileList.querySelectorAll(`.uploadFileList[data-row='${row}']`);

                        let sequence = -1;
                        for (let file of currentFileList) {
                            let fileEle = <HTMLDivElement>file;

                            let thisSequence = parseInt(fileEle.dataset.sequencenbr);
                            if (thisSequence > sequence) {
                                sequence = thisSequence;
                            }
                        }

                        let uploadedContainer = document.querySelector(`.uploadedFiles[data-row='${row}']`);
                        uploadedContainer.classList.remove("hide");

                        sequence++;

                        let fileWrapper = <HTMLDivElement>document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = <HTMLDivElement>document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = <HTMLAnchorElement>document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.row = row;
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.sequencenbr = row;
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.classList.add("relatedAcademicsFileUploadField");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);

                        fileList.appendChild(fileWrapper);

                        that.bindDeleteFiles();
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }

    bindDeleteFiles() {
        let deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (let deleteFileButton of deleteFileButtons)
            deleteFileButton.addEventListener("click", (e: Event) => this.showDeleteFile(e));
    }

    showDeleteFile(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let row = buttonElement.dataset.row;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;

        let modal: Modal = new Modal("deleteFileModal", null);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "row", row);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "planpropertyfilepk", planPropertyFilePK);
        modal.show();
    }

    deleteFileConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        let row = buttonElement.dataset.row;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/DeleteFileData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    let element = document.querySelector(`.uploadFileColumn[data-planpropertyfilepk='${planPropertyFilePK}']`);
                    element.remove();

                    const moreFiles = document.querySelectorAll(`.uploadFileList[data-row='${row}'] .uploadFileColumn`);
                    if (moreFiles.length === 0) {
                        const uploadsElement = <HTMLDivElement>document.querySelector(`.uploadedFiles[data-row='${row}']`);
                        if (uploadsElement !== null) {
                            uploadsElement.classList.add("hide");
                        }
                    }

                    Core.createHTMLAlert("alertMessageDiv", "File successfully removed.", 'success', 3000, null);
                } else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the file. Please try again.", 'error', 3000, null);
                }

                Core.hideLoader();

                let modal: Modal = new Modal("deleteFileModal", null);
                modal.hide();
            } else {
                let modal: Modal = new Modal("deleteFileModal", null);
                modal.hide();

                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planPropertyFilePK));
    }

    deleteFileCancel() {
        let modal: Modal = new Modal("deleteFileModal", null);
        modal.hide();
    }

    async addArticulationAgreement() {
        Core.showLoader();
        let formElement = <HTMLDivElement>document.getElementById("relatedAcademicsForm");

        let planFK = formElement.dataset.planpk;
        let that = this;
        let newRow = 0;

        let allRows = document.querySelectorAll(".relatedAcademicsArticulationAgreement");
        for (let row of allRows) {
            let rowEle = <HTMLTableRowElement>row;
            let thisRow = parseInt(rowEle.dataset.row);

            if (thisRow > newRow)
                newRow = thisRow;
        }

        newRow++;

        let response = await fetch(`/Set/RelatedAcademicsAddArticulationAgreement/${planFK}/${newRow}`, { credentials: 'include' })
        if (response.ok) {

            const output = await response.text();

            const container = <HTMLDivElement>document.createElement("div");
            container.classList.add("relatedAcademicsArticulationAgreement");
            container.classList.add("articulation-agreement");
            container.dataset.row = newRow.toString();

            container.innerHTML = output;

            const articulationAgreementList = <HTMLDivElement>document.getElementById("articulationAgreementList");
            articulationAgreementList.appendChild(container);

            that.bindUploads();
            that.bindDeleteFiles();
            that.bindDeleteArticulationAgreements();

            Core.hideLoader();
        } else {
            Core.hideLoader();
        }
    }

    bindDeleteArticulationAgreements() {
        const allDeletes = document.getElementsByClassName("deleteArticulationAgreement");
        for (const deleteButton of allDeletes)
            (deleteButton as HTMLButtonElement).addEventListener("click", (e: Event) => this.showDeleteArticulationAgreement(e));
    }

    showDeleteArticulationAgreement(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let row = buttonElement.dataset.row;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;

        let modal: Modal = new Modal("deleteArticulationAgreementModal", null);
        modal.addAttributeToElement("deleteArticulationAgreementModal", "#relatedAcademicsDeleteArticulationAgreementConfirm", "row", row);
        modal.show();
    }

    async deleteArticulationAgreementConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let row = buttonElement.dataset.row;

        const planPKs = [];
        const filePKs = [];

        const allPlanData = document.querySelectorAll(`.relatedAcademicsArticulationAgreementField[data-row='${row}']`);
        const allFileData = document.querySelectorAll(`.uploadFileArticulationAgreementColumn[data-row='${row}']`);

        for (const planData of allPlanData) {
            const ele = <HTMLInputElement>planData;
            planPKs.push(ele.dataset.planpropertypk);
        }

        for (const fileData of allFileData) {
            const ele = <HTMLDivElement>fileData;
            filePKs.push(ele.dataset.planpropertyfilepk);
        }


        const dataModel = {
            "PlanPropertyPKs": planPKs,
            "PlanPropertyFilePKs": filePKs
        };

        const settings = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataModel)
        };

        const response = await fetch(`/Set/DeleteArticulationAgreement`, settings);
        if (response.ok) {
            const output = await response.json();

            if (output === true) {
                const articulationAgreement = document.querySelector(`.relatedAcademicsArticulationAgreement[data-row='${row}']`);
                articulationAgreement.remove();

                Core.hideLoader();

                let modal: Modal = new Modal("deleteArticulationAgreementModal", null);
                modal.hide();

                Core.createHTMLAlert("alertMessageDiv", "Articulation Agreement successfully removed.", 'success', 3000, null);
            } else {
                Core.hideLoader();

                let modal: Modal = new Modal("deleteArticulationAgreementModal", null);
                modal.hide();

                Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the articulation agreement. Please try again.", 'error', 3000, null);
            }
        }
    }

    deleteArticulationAgreementCancel() {
        let modal: Modal = new Modal("deleteArticulationAgreementModal", null);
        modal.hide();
    }

    articulationAgreementNotAvailable(e: HTMLInputElement) {
        const checkBox = e;

        if (!checkBox.checked) {
            const allInputs = document.getElementsByClassName("relatedAcademicsArticulationAgreementField");
            for (const input of allInputs) {
                const ele = input as HTMLInputElement
                this._core.forceElementRequired(ele);
                ele.readOnly = false;
                ele.disabled = false;
            }

            const allFiles = document.getElementsByClassName("relatedAcademicsArticulationAgreementsUploadArticulationAgreement");
            for (const file of allFiles) {
                const ele = file as HTMLInputElement
                this._core.forceElementRequired(ele);
                ele.readOnly = false;
                ele.disabled = false;

            }
        } else {
            const allInputs = document.getElementsByClassName("relatedAcademicsArticulationAgreementField");
            for (const input of allInputs) {
                const ele = input as HTMLInputElement
                this._core.forceElementOptional(ele);
                ele.readOnly = true;
                ele.disabled = true;
            }

            const allFiles = document.getElementsByClassName("relatedAcademicsArticulationAgreementsUploadArticulationAgreement");
            for (const file of allFiles) {
                const ele = file as HTMLInputElement
                this._core.forceElementOptional(ele);
                ele.readOnly = true;
                ele.disabled = true;
            }
        }
    }

    doValidationCustom() {
    
}
}

//Equity Considerations
class EquityConsiderations {

    validationClasses: string[];

    private _core: Core;
    private _planSectionSet: PlanSectionSet;
    constructor() {
        this._core = new Core();
        this._planSectionSet = new PlanSectionSet();
        this.validationClasses = ["equityConsiderationsField"];
        let studentEnglishLearnersButton = document.getElementById("equityConsiderationsAddStudentGroupEnglishLearners");
        if (studentEnglishLearnersButton !== null)
            studentEnglishLearnersButton.addEventListener("click", (e: Event) => this.addDataSource("equityConsiderationsEnglishLearners", "EL", "equityConsiderationsField", "spdequityconsiderations"));

        let studentWithDisabilitiesButton = document.getElementById("equityConsiderationsAddStudentGroupStudentsWithDisabilities");
        if (studentWithDisabilitiesButton !== null)
            studentWithDisabilitiesButton.addEventListener("click", (e: Event) => this.addDataSource("equityConsiderationsStudentsWithDisabilities", "DIS", "equityConsiderationsField", "spdequityconsiderations"));

        let studentsConsideredEconomicallyDisadvantagedButton = document.getElementById("equityConsiderationsAddStudentGroupEconomicallyDisadvantaged");
        if (studentsConsideredEconomicallyDisadvantagedButton !== null)
            studentsConsideredEconomicallyDisadvantagedButton.addEventListener("click", (e: Event) => this.addDataSource("equityConsiderationsEconomicallyDisadvantaged", "ED", "equityConsiderationsField", "spdequityconsiderations"));

        let groupsByRace = document.getElementById("equityConsiderationsAddStudentGroupGroupsByRace");
        if (groupsByRace !== null)
            groupsByRace.addEventListener("click", (e: Event) => this.addStudentGroup("equityConsiderationsGroupsByRace", "equityConsiderationsDropdownField"));

        let saveButton = document.getElementById("equityConsiderationsSave");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let noEnglishLearnersCheckbox = document.getElementById("equityConsiderationsEnglishLearnersOmit");
        if (noEnglishLearnersCheckbox !== null)
            noEnglishLearnersCheckbox.addEventListener("click", (e: Event) => this.noPrograms("equityConsiderationsEnglishLearners", "equityConsiderationsEnglishLearnersOmit"))

        let noStudentWithDisabilitiesCheckbox = document.getElementById("equityConsiderationsStudentswithDisabilitiesOmit");
        if (noStudentWithDisabilitiesCheckbox !== null)
            noStudentWithDisabilitiesCheckbox.addEventListener("click", (e: Event) => this.noPrograms("equityConsiderationsStudentsWithDisabilities", "equityConsiderationsStudentswithDisabilitiesOmit"));

        let noStudentsConsideredEconomicallyDisadvantagedCheckbox = document.getElementById("equityConsiderationsStudentsConsideredEconomicallyDisadvantagedOmit");
        if (noStudentsConsideredEconomicallyDisadvantagedCheckbox !== null)
            noStudentsConsideredEconomicallyDisadvantagedCheckbox.addEventListener("click", (e: Event) => this.noPrograms("equityConsiderationsEconomicallyDisadvantaged", "equityConsiderationsStudentsConsideredEconomicallyDisadvantagedOmit"));

        let noStudentsByRaceEthnicityCheckbox = document.getElementById("equityConsiderationsStudentGroupsbyRaceEthnicityOmit");
        if (noStudentsByRaceEthnicityCheckbox !== null)
            noStudentsByRaceEthnicityCheckbox.addEventListener("click", (e: Event) => this.noPrograms("equityConsiderationsGroupsByRace", "equityConsiderationsStudentGroupsbyRaceEthnicityOmit"));

        this.initializeRequiredFieldsCustom();

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        
        this._core.activateSubMenu("subNavReadySetGo");
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planElement = document.getElementById("equityConsideraionsForm");
        let planPk = planElement.dataset.planpk;
        let allElements = document.getElementsByClassName("equityConsiderationsField");
        for (let element of allElements) {
            let htmlElement = <HTMLInputElement>element;
            let planPropertyPK: number = 0;
            let rowNumber: number = parseInt(htmlElement.dataset.row);

            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }

            if (htmlElement.value !== "" || planPropertyPK > 0) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: parseInt(planPk),
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: htmlElement.value,
                    LookupCodeFK: null,
                    RowNbr: rowNumber,
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        allElements = document.getElementsByClassName("equityConsiderationsDropdownField");
        let rowCount = 0;
        for (let element of allElements) {
            let htmlElement = <HTMLSelectElement>element;
            let planPropertyPK = 0;
            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }

            if (htmlElement.selectedIndex > 0) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: parseInt(planPk),
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: "",
                    LookupCodeFK: htmlElement.value,
                    RowNbr: rowCount,
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
                rowCount++;
            }
        }

        allElements = document.getElementsByClassName("equityConsiderationsCheckField");
        for (let element of allElements) {
            let htmlElement = <HTMLInputElement>element;
            let planPropertyPK = 0;
            let isChecked = "off";
            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }

            if (htmlElement.checked) {
                isChecked = "on";
            }
            let saveItem = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: parseInt(planPk),
                PropertyFK: parseInt(htmlElement.dataset.propertypk),
                TextValue: isChecked,
                LookupCodeFK: null,
                RowNbr: 0,
                IsDeletedInd: false
            };
            allSaveElements.push(saveItem);
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/SaveEquityConsiderations', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    core.pageReload(true, parseInt(planPk));
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    initializeRequiredFieldsCustom() {

        //If any field in a row is filled out, force rest of row required 
        let accordions = document.querySelectorAll(".Accordion-panel:not(.summary-dark)");
        for (let accordion of accordions) {
            let accordionRows = accordion.querySelectorAll("tr.rowContainer");
            for (let accordionRow of accordionRows) {
                let elements = accordionRow.getElementsByClassName("equityConsiderationsField") as HTMLCollectionOf<HTMLElement>;

                for (let element of elements) {

                    if (element instanceof HTMLSelectElement) {
                        let htmlElement = <HTMLSelectElement>element;
                        if (element.selectedIndex > 0) {
                            for (let otherElement of elements) {
                                if (otherElement.dataset.percent == "1.00") {
                                    otherElement.dataset.forcerequired = "true";
                                }
                            }
                            break;
                        }
                    } else if (element instanceof HTMLTextAreaElement) {
                        let htmlElement = <HTMLTextAreaElement>element;
                        if (element.value != "" && !element.disabled) {
                            for (let otherElement of elements) {
                                if (otherElement.dataset.percent == "1.00") {
                                    otherElement.dataset.forcerequired = "true";
                                }
                            }
                            break;
                        }
                    }
                }
            }
        }

        this._core.initializeRequiredFields(this.validationClasses);
    }

    addDataSource(tableName: string, pageSection: string, className: string, pageCode: string) {
        let core = this._core;
        let table = (<HTMLTableElement>document.getElementById(tableName));
        let inputBoxes = table.querySelectorAll<HTMLTextAreaElement>("textarea");
        let rowCount = 0;

        for (let input of inputBoxes) {
            if (parseInt(input.getAttribute("data-row")) > rowCount) {
                rowCount++;
            }
        }

        rowCount++;

        let xhr = new XMLHttpRequest();

        xhr.open('POST', '/Set/EquityConsiderationsStudentGroupText', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = table.querySelector("tbody");
                $(element).append(xhr.responseText);
                Core.createHTMLAlert("alertMessageDiv", "New Data Source Row Added", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("pageSection=" + pageSection + "&rowNumber=" + rowCount + "&className=" + className + "&pageCode=" + pageCode);
    }

    addStudentGroup(tableName: string, classNameBase: string) {
        let core = this._core;
        let table = (<HTMLTableElement>document.getElementById(tableName));
        let inputBoxes = table.querySelectorAll<HTMLTextAreaElement>("textarea");
        let rowCount = 0;

        for (let input of inputBoxes) {
            if (parseInt(input.getAttribute("data-row")) > rowCount) {
                rowCount++;
            }
        }

        rowCount++;

        let xhr = new XMLHttpRequest();

        xhr.open('POST', '/Set/EquityConsiderationsStudentGroup', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = table.querySelector("tbody");
                $(element).append(xhr.responseText);
                Core.createHTMLAlert("alertMessageDiv", "New Student Group Row Added", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("classNameBase=" + classNameBase + "&rowNumber=" + rowCount);
    }

    noPrograms(tableName: string, checkboxName: string) {
        let table = (<HTMLTableElement>document.getElementById(tableName));
        let inputBoxes = table.querySelectorAll<HTMLTextAreaElement>("textarea");
        let checkbox = <HTMLInputElement>document.getElementById(checkboxName);

        for (let input of inputBoxes) {
            if (checkbox.readOnly) {
                if (checkbox.checked)
                    checkbox.checked = false;
                else
                    checkbox.checked = true;
                return false;
            } else {
                if (checkbox.checked) {
                    input.value = " ";
                    input.readOnly = true;
                }
                else {
                    input.readOnly = false;
                    input.value = "";
                }
            }
        }
    }

    checkForCheckboxes() {
        let allElements = document.getElementsByClassName("equityConsiderationsOmitCheckField");
        for (let element of allElements) {
            let htmlElement = <HTMLInputElement>element;
            let tableName = element.getAttribute("data-tablename");
            let table = (<HTMLTableElement>document.getElementById(tableName));
            let inputBoxes = table.querySelectorAll<HTMLTextAreaElement>("textarea");

            if (htmlElement.checked) {
                for (let input of inputBoxes) {
                    input.readOnly = true;
                }
            }
        }
    }
}

//Supplemental LEA Plans
class SupplementalLEAPlans {

    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();
        this.validationClasses = ["supplementalLEAField"];
        let addAPlanButton = document.getElementById("supplementalLEAPlansAddPlan");
        if (addAPlanButton !== null)
            addAPlanButton.addEventListener("click", (e: Event) => this.addPlan("supplementalLEAPlans", "supplementalLEAPlan"));

        let saveButton = document.getElementById("supplementalLEAPlansSave");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e: Event) => this.save("save"));
        this._core.leftnav(this);

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        this._core.activateSubMenu("subNavReadySetGo");
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planElement = document.getElementById("supplementalLEAForm");
        let planPk = planElement.dataset.planpk;
        let allElements = document.getElementsByClassName("supplementalLEAField");
        for (let element of allElements) {
            let htmlElement = <HTMLInputElement>element;
            let planPropertyPK: number = 0;
            let rowNumber: number = parseInt(htmlElement.dataset.row);

            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }

            if (htmlElement.value !== "" || planPropertyPK > 0) {

                let toDelete = (htmlElement.value === "");

                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: parseInt(planPk),
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: htmlElement.value,
                    LookupCodeFK: null,
                    RowNbr: rowNumber,
                    IsDeletedInd: toDelete
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/SaveSupplementalLEAPlan', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    core.pageReload(true, parseInt(planPk))
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    addPlan(tableName: string, classNameBase: string) {
        let core = this._core;
        let table = (<HTMLTableElement>document.getElementById(tableName));
        let inputBoxes = table.querySelectorAll<HTMLTextAreaElement>("textarea");
        let rowCount = 0;

        for (let input of inputBoxes) {
            if (parseInt(input.getAttribute("data-row")) > rowCount) {
                rowCount++;
            }
        }

        rowCount++;

        let xhr = new XMLHttpRequest();

        xhr.open('POST', '/Set/SupplementalLEAPlansAddPlan', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = table.querySelector("tbody");
                $(element).append(xhr.responseText);
                Core.createHTMLAlert("alertMessageDiv", "New Plan Row Added", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("classNameBase=" + classNameBase + "&rowNumber=" + rowCount);

    }
}

//Designated Schools
class DesignatedSchools {

    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();
        this.validationClasses = ["designatedSchoolsDesignatedSchoolField", "designatedSchoolsChallengeField"];
        let addChallengeButton = document.getElementById("designatedSchoolsAddChallenge");
        if (addChallengeButton !== null)
            addChallengeButton.addEventListener("click", (e: Event) => this.addChallenge("designatedSchoolsChallenges"));

        let saveButton = document.getElementById("designatedSchoolsSave");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        this._core.activateSubMenu("subNavReadySetGo");
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        const addDesignatedSchool = document.getElementById("addDesignatedSchool");
        if (addDesignatedSchool !== null)
            addDesignatedSchool.addEventListener("click", (e: Event) => this.addDesignatedSchool());

        this.bindDeleteDesignatedSchoolRows();
        this.bindDeleteChallengeRows();
        this.bindDeletes();

        const deleteDesignatedSchoolRowConfirmButton = document.getElementById("designatedSchoolDeleteRowConfirm");
        if (deleteDesignatedSchoolRowConfirmButton !== null)
            deleteDesignatedSchoolRowConfirmButton.addEventListener("click", (e: Event) => this.deleteDesignatedSchoolRowConfirm(e));

        const deleteDesignatedSchoolRowCancelButton = document.getElementById("designatedSchoolDeleteRowCancel");
        if (deleteDesignatedSchoolRowCancelButton !== null)
            deleteDesignatedSchoolRowCancelButton.addEventListener("click", (e: Event) => this.deleteDesignatedSchoolRowCancel());

        const designatedSchoolChallengeDeleteRowConfirm = document.getElementById("designatedSchoolChallengeDeleteRowConfirm");
        if (designatedSchoolChallengeDeleteRowConfirm !== null)
            designatedSchoolChallengeDeleteRowConfirm.addEventListener("click", (e: Event) => this.deleteDesignatedSchoolChallengeConfirm(e));

        const designatedSchoolChallengeDeleteRowCancel = document.getElementById("designatedSchoolChallengeDeleteRowCancel");
        if (designatedSchoolChallengeDeleteRowCancel !== null)
            designatedSchoolChallengeDeleteRowCancel.addEventListener("click", (e: Event) => this.deleteDesignatedSchoolChallengeCancel());

        const designatedSchoolAccordionDeleteConfirmButton = document.getElementById("designatedSchoolAccordionDeleteConfirm");
        if (designatedSchoolAccordionDeleteConfirmButton !== null)
            designatedSchoolAccordionDeleteConfirmButton.addEventListener("click", (e: Event) => this.deleteDesignatedSchoolAccordionConfirm(e));

        const designatedSchoolAccordionDeleteCancelButton = document.getElementById("designatedSchoolAccordionDeleteCancel");
        if (designatedSchoolAccordionDeleteCancelButton !== null)
            designatedSchoolAccordionDeleteCancelButton.addEventListener("click", (e: Event) => this.deleteDesignatedSchoolAccordionCancel());

    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planElement = document.getElementById("designatedSchoolsForm");
        let planPk = planElement.dataset.planpk;

        let allAccordionElements = document.getElementsByClassName("designatedSchoolsDesignatedSchoolField");
        for (let element of allAccordionElements) {
            let htmlElement = <HTMLInputElement>element;
            let planPropertyPK: number = 0;
            let rowNumber: number = parseInt(htmlElement.dataset.row);

            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }

            const dataPointPlanPropertyFK = parseInt(htmlElement.dataset.datapointplanpropertypk);

            if (htmlElement.value !== "" || planPropertyPK > 0) {

                let toDelete = (htmlElement.value === "");

                let saveItem: IPlanPropertyExtended = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: parseInt(planPk),
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: htmlElement.value,
                    LookupCodeFK: null,
                    RowNbr: rowNumber,
                    IsDeletedInd: toDelete,
                    DataPointPlanPropertyPK: dataPointPlanPropertyFK,
                    ButtonRow: 0
                };

                allSaveElements.push(saveItem);
            }
        }

        const allOtherSaveElements = [];

        const allOtherElements = document.getElementsByClassName("designatedSchoolsChallengeField");
        for (let ele of allOtherElements) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: parseInt(planPk),
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allOtherSaveElements.push(saveItem);
            }
        }

        var data = {
            "AccordionElementData": allSaveElements,
            "ElementData": allOtherSaveElements,
            "PlanFK": planPk
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/SaveDesignatedSchools', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    core.pageReload(true, parseInt(planPk));
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                    Core.hideLoader();
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                    Core.hideLoader();
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                core.doValidation(this.validationClasses);
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(data));
        }
    }

    addChallenge(tableName: string) {
        const inputBoxes = document.querySelectorAll(".designatedSchoolsChallenge");
        let rowCount = 0;

        for (const input of inputBoxes) 
            if (parseInt(input.getAttribute("data-row")) > rowCount) 
                rowCount = parseInt(input.getAttribute("data-row"));

        rowCount++;

        const that = this;
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/DesignatedSchoolsChallengesAddChallenge', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                const tableElement = document.querySelector("#designatedSchoolsChallenges tbody");
                const newTRElement = <HTMLTableRowElement>document.createElement("tr");
                newTRElement.dataset.row = rowCount.toString();
                newTRElement.innerHTML = xhr.responseText;
                tableElement.append(newTRElement);

                that.bindDeleteChallengeRows();

                Core.createHTMLAlert("alertMessageDiv", "New Challenge Row Added", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("rowNumber=" + rowCount);

    }

    async addDesignatedSchool() {
        const designatedSchoolsSchoolNameAndBranchNew = <HTMLSelectElement>document.getElementById("designatedSchoolsSchoolNameAndBranchNew");
        const designatedSchoolsDesignationTypeNew = <HTMLSelectElement>document.getElementById("designatedSchoolsDesignationTypeNew");

        const schoolName = designatedSchoolsSchoolNameAndBranchNew.value;
        const designationType = designatedSchoolsDesignationTypeNew.value;

        if (schoolName === "0" || designationType === "0") {
            Core.createHTMLAlert("alertMessageDiv", "Please choose a school and designation to add.", 'warning', 3000, null);
        }
        else {
            //set the values in the dropdowns back to -1 so they can't double click
            designatedSchoolsSchoolNameAndBranchNew.value = "0";
            designatedSchoolsDesignationTypeNew.value = "0";
            const formElement = <HTMLDivElement>document.getElementById("designatedSchoolsForm");
            const planFK = formElement.dataset.planpk;

            const dataModel = {
                "PlanFK": planFK,
                "DesignationTypeLookupFK": designationType,
                "SchoolName": schoolName
            };

            const settings = {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(dataModel)
            };

            const response = await fetch(`/Set/AddDesignatedSchool`, settings);
            if (response.ok) {

                const output = await response.text();

                const newAccordion = <HTMLDivElement>document.createElement("div");
                newAccordion.innerHTML = output;

                const newAccordionIdElement = <HTMLButtonElement>newAccordion.querySelector(".Accordion-trigger");
                const newAccordionId = newAccordionIdElement.id;

                const container = document.querySelector("#designatedSchoolsListing");
                container.append(newAccordion);

                new CustomAccordion(newAccordionId);

                this.bindDeleteDesignatedSchoolRows();

                Core.createHTMLAlert("alertMessageDiv", "Designated School Added", 'success', 3000, null);
            }
        }
    }

    bindDeletes() {
        const allDeletes = document.getElementsByClassName("deleteDesignatedSchoolAccordion");
        for (const thisDelete of allDeletes)
            thisDelete.addEventListener("click", (e: Event) => this.showDeleteDesignatedSchoolAccordion(e));
    }

    showDeleteDesignatedSchoolAccordion(e: Event) {
        const buttonElement = <HTMLButtonElement>e.target;
        const dataPointPlanPropertyFK = buttonElement.dataset.datapointplanpropertypk;

        const modal: Modal = new Modal("deleteDesignatedSchoolAccordionModal", null);
        modal.addAttributeToElement("deleteDesignatedSchoolAccordionModal", "#designatedSchoolAccordionDeleteConfirm", "datapointplanpropertypk", dataPointPlanPropertyFK);
        modal.show();
    }

    deleteDesignatedSchoolAccordionCancel() {
        const modal: Modal = new Modal("deleteDesignatedSchoolAccordionModal", null);
        modal.hide();
    }

    async deleteDesignatedSchoolAccordionConfirm(e: Event) {
        const buttonElement = <HTMLButtonElement>e.target;
        const dataPointPlanPropertyFK = buttonElement.dataset.datapointplanpropertypk;

        Core.showLoader();

        const response = await fetch(`/Set/DeleteDesignatedSchoolAccordion/${dataPointPlanPropertyFK}`, { credentials: 'include' })
        if (response.ok) {
            const output = await response.text();
            if (output === "true") {
                const container = <HTMLButtonElement>document.querySelector(`.Accordion-trigger[data-datapointplanpropertyfk='${dataPointPlanPropertyFK}']`);
                container.parentElement.remove();
                const container2 = <HTMLButtonElement>document.querySelector(`.Accordion-panel[data-datapointplanpropertyfk='${dataPointPlanPropertyFK}']`);
                container2.remove();

                Core.createHTMLAlert("alertMessageDiv", `The record was successfully removed.`, "success", 3000, null);
                Core.hideLoader();
            } else {
                Core.createHTMLAlert("alertMessageDiv", `There was an issue removing the record. Please try again.`, "error", 3000, null);
                Core.hideLoader();
            }

            const modal: Modal = new Modal("deleteDesignatedSchoolAccordionModal", null);
            modal.hide();
        } else {
            Core.createHTMLAlert("alertMessageDiv", `There was an issue removing the record. Please try again.`, "error", 3000, null);
            Core.hideLoader();

            const modal: Modal = new Modal("deleteDesignatedSchoolAccordionModal", null);
            modal.hide();
        }
    }

    bindDeleteDesignatedSchoolRows() {
        const deleteRows = document.getElementsByClassName("deleteDesignatedSchoolRow");
        for (const deleteRow of deleteRows)
            deleteRow.addEventListener("click", (e: Event) => this.showDeleteDesignatedSchoolRow(e));
    }

    showDeleteDesignatedSchoolRow(e: Event) {
        const buttonElement = <HTMLButtonElement>e.target;
        const row = buttonElement.dataset.row;
        const dataPointPlanPropertyFK = buttonElement.dataset.datapointplanpropertypk;

        const modal: Modal = new Modal("deleteDesignatedSchoolRowModal", null);
        modal.addAttributeToElement("deleteDesignatedSchoolRowModal", "#designatedSchoolDeleteRowConfirm", "row", row);
        modal.addAttributeToElement("deleteDesignatedSchoolRowModal", "#designatedSchoolDeleteRowConfirm", "datapointplanpropertypk", dataPointPlanPropertyFK);
        modal.show();
    }

    async deleteDesignatedSchoolRowConfirm(e: Event) {
        const buttonElement = <HTMLButtonElement>e.target;
        const planPropertyPKs = [];
        const row = buttonElement.dataset.row;
        const dataPointPlanPropertyFK = buttonElement.dataset.datapointplanpropertypk;

        const allPlanProps = document.querySelectorAll(`.designatedSchoolsDesignatedSchoolField[data-datapointplanpropertypk='${dataPointPlanPropertyFK}'][data-row='${row}']`);
        for (const prop of allPlanProps) {
            const ele = <HTMLTextAreaElement>prop;

            if (ele.dataset.planpropertypk && ele.dataset.planpropertypk !== "0")
                planPropertyPKs.push(ele.dataset.planpropertypk);
        }

        const settings = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(planPropertyPKs)
        };

        const response = await fetch(`/Set/DeleteDesignatedSchoolRow`, settings);
        if (response.ok) {

            const output = await response.text();

            if (output.toLowerCase().indexOf("true") >= 0) {
                const trToRemove = <HTMLTableRowElement>document.querySelector(`.designatedSchoolData[data-datapointplanpropertypk='${dataPointPlanPropertyFK}'] tr[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyFK}']`);
                trToRemove.remove();

                this.addDesignatedSchoolRow(dataPointPlanPropertyFK);

                Core.createHTMLAlert("alertMessageDiv", "The Designated School data was successfully removed.", 'success', 3000, null);
            } else {
                Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the record. Please try again.", 'error', 3000, null);
            }

            Core.hideLoader();

            const modal: Modal = new Modal("deleteDesignatedSchoolRowModal", null);
            modal.hide();
        } else {
            const modal: Modal = new Modal("deleteDesignatedSchoolRowModal", null);
            modal.hide();

            Core.hideLoader();
            Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the record. Please try again.", 'error', 3000, null);
        }
    }

    deleteDesignatedSchoolRowCancel() {
        const modal: Modal = new Modal("deleteDesignatedSchoolRowModal", null);
        modal.hide();
    }

    async addDesignatedSchoolRow(dataPointPlanPropertyPK) {
        const formElement = <HTMLDivElement>document.getElementById("designatedSchoolsForm");
        const planFK = formElement.dataset.planpk;
        let row = 0;
        const allRows = document.querySelectorAll(`#designatedSchoolData tr[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        for (const thisRow of allRows) {
            const trEle = <HTMLTableRowElement>thisRow;
            const thisRowVal = parseInt(trEle.dataset.row);

            if (row < thisRowVal)
                row = thisRowVal;
        }

        row++;

        const response = await fetch(`/Set/AddDesignatedSchoolRow/${planFK}/${dataPointPlanPropertyPK}/${row}`, { "credentials": "include" });
        if (response.ok) {
            const output = await response.text();

            const table = <HTMLTableElement>document.querySelector(`.designatedSchoolData[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'] tbody`);
            const newTR = document.createElement("tr");
            newTR.dataset.row = row.toString();
            newTR.dataset.datapointplanpropertypk = dataPointPlanPropertyPK;

            newTR.innerHTML = output;

            table.append(newTR);
        } else {
            Core.createHTMLAlert("alertMessageDiv", "There was an issue adding a new record.", 'error', 3000, null); 
        }
    }

    bindDeleteChallengeRows() {
        const deleteDesignatedChallenges = document.getElementsByClassName("deleteDesignatedChallenge");
        for (const deleteDesignatedChallenge of deleteDesignatedChallenges)
            deleteDesignatedChallenge.addEventListener("click", (e: Event) => this.showDeleteChallenge(e));
    }

    showDeleteChallenge(e: Event) {
        const button = <HTMLInputElement>e.target;
        const row = button.dataset.row;
        const element = <HTMLInputElement>document.querySelector(`.designatedSchoolsChallenge[data-row='${row}']`);
        const planPropertyPK = element.dataset.planpropertypk;

        if (element.value !== "" || planPropertyPK !== "0") {
            const modal: Modal = new Modal("deleteDesignatedSchoolChallengeRowModal", null);
            modal.addAttributeToElement("deleteDesignatedSchoolChallengeRowModal", "#designatedSchoolChallengeDeleteRowConfirm", "planpropertypk", planPropertyPK);
            modal.addAttributeToElement("deleteDesignatedSchoolChallengeRowModal", "#designatedSchoolChallengeDeleteRowConfirm", "row", row);

            modal.show();
        } else {
            Core.createHTMLAlert("alertMessageDiv", "There is no data to delete.", 'success', 3000, null);
        }
    }

    deleteDesignatedSchoolChallengeCancel() {
        const modal: Modal = new Modal("deleteDesignatedSchoolChallengeRowModal", null);
        modal.hide();
    }

    async deleteDesignatedSchoolChallengeConfirm(e: Event) {
        const button = <HTMLButtonElement>e.target;
        const planPropertyPK = button.dataset.planpropertypk;
        const row = button.dataset.row;
        const form = <HTMLDivElement>document.getElementById("designatedSchoolsForm");
        const planFK = form.dataset.planpk;
        const modal: Modal = new Modal("deleteDesignatedSchoolChallengeRowModal", null);

        const response = await fetch(`/Set/DeleteDesignatedSchoolChallenge/${planFK}/${planPropertyPK}/${row}`, { "credentials": "include" });
        if (response.ok) {
            const output = await response.json();

            if (output.success) {
                const element = <HTMLTableRowElement>document.querySelector(`#designatedSchoolsChallenges tr[data-row='${row}']`);
                element.remove();
                modal.hide();
            } else {
                Core.createHTMLAlert("alertMessageDiv", "There was an issue removing this challenge. Please try again.", 'error', 3000, null);
                modal.hide();
            }
        } else {
            Core.createHTMLAlert("alertMessageDiv", "There was an issue removing this challenge. Please try again.", 'error', 3000, null);
            modal.hide();
        }
    }
}

//ConditionsForLeadership
class ConditionsForLeadership {

    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();
        this.validationClasses = ["conditionsForLeadershipField", "conditionsForLeadershipSummaryField"];
        let saveButton = document.getElementById("conditionsForLeadershipSave");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        this._core.activateSubMenu("subNavReadySetGo");
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planElement = document.getElementById("conditionsForLeadershipForm");
        let planPk = planElement.dataset.planpk;
        let allElements = document.getElementsByClassName("conditionsForLeadershipField");
        for (let element of allElements) {
            let htmlElement = <HTMLInputElement>element;
            let planPropertyPK: number = 0;
            let rowNumber: number = parseInt(htmlElement.dataset.row);

            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }

            if (htmlElement.checked) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: parseInt(planPk),
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: "",
                    LookupCodeFK: parseInt(htmlElement.value),
                    RowNbr: rowNumber,
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        allElements = document.getElementsByClassName("conditionsForLeadershipSummaryField");
        for (let element of allElements) {
            let htmlElement = <HTMLInputElement>element;
            let planPropertyPK: number = 0;
            let rowNumber: number = parseInt(htmlElement.dataset.row);

            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }

            if (htmlElement.value !== "" || planPropertyPK > 0) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: parseInt(planPk),
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: htmlElement.value,
                    LookupCodeFK: null,
                    RowNbr: rowNumber,
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/SaveConditionsForLeadership', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    core.pageReload(true, parseInt(planPk));
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

//SummaryOfStrengths
class SummaryOfStrengths {

    validationClasses: string[];
    private _core: Core;
    private uncheckChallengeForConsiderationModal: Modal;
    private uncheckStrengthForConsiderationModal: Modal;

    constructor() {
        this._core = new Core();

        this.validationClasses = [
            "summaryOfStrengthsField",
            "summaryOfChallengesField",
            "summaryOfStrengthsReflectionsField"
        ];

        this.uncheckChallengeForConsiderationModal = new Modal("uncheckChallengeForConsiderationModal", null);
        this.uncheckStrengthForConsiderationModal = new Modal("uncheckStrengthForConsiderationModal", null);

        let that = this;
        let uncheckChallengeConfirmButton = document.getElementById("uncheckChallengeForConsiderationConfirm");
        if (uncheckChallengeConfirmButton !== null) {
            uncheckChallengeConfirmButton.addEventListener("click", function () {
                if ("challengecheckplanpropertypk" in uncheckChallengeConfirmButton.dataset && parseInt(uncheckChallengeConfirmButton.dataset.challengecheckplanpropertypk) > 0) {
                    that.uncheckChallengeForConsideration(uncheckChallengeConfirmButton.dataset.challengecheckplanpropertypk);
                } else {
                    that.uncheckChallengeForConsiderationModal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error unchecking this challenge for consideration", "error", 3000, null);
                }
            });
        }

        let exportWord = document.getElementById("exportSummaryOfStrengthsWord");
        if (exportWord !== null) {
            exportWord.addEventListener("click", (e: Event) => this._core.exportDocx(e, "SummaryOfStrengthsAndChallengesDataExport", "summarystrengthschallenges"));
        }

        let uncheckChallengeCancelButton = document.getElementById("uncheckChallengeForConsiderationCancel");
        if (uncheckChallengeCancelButton !== null) {
            uncheckChallengeCancelButton.addEventListener("click", function () {
                that.uncheckChallengeForConsiderationModal.hide();

                if (uncheckChallengeCancelButton.dataset.challengecheckplanpropertypk) {
                    let checkbox = document.querySelector(`[data-planpropertypk='${uncheckChallengeCancelButton.dataset.challengecheckplanpropertypk}']`) as HTMLInputElement;
                    if (checkbox != null) {
                        checkbox.checked = true;
                    }
                }
            });
        }

        let uncheckStrengthConfirmButton = document.getElementById("uncheckStrengthForConsiderationConfirm");
        if (uncheckStrengthConfirmButton !== null) {
            uncheckStrengthConfirmButton.addEventListener("click", function () {
                if ("strengthcheckplanpropertypk" in uncheckStrengthConfirmButton.dataset && parseInt(uncheckStrengthConfirmButton.dataset.strengthcheckplanpropertypk) > 0) {
                    that.uncheckStrengthForConsideration(uncheckStrengthConfirmButton.dataset.strengthcheckplanpropertypk);
                } else {
                    that.uncheckStrengthForConsiderationModal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error unchecking this strength for consideration", "error", 3000, null);
                } 
            });
        }

        let uncheckStrengthCancelButton = document.getElementById("uncheckStrengthForConsiderationCancel");
        if (uncheckStrengthCancelButton !== null) {
            uncheckStrengthCancelButton.addEventListener("click", function () {
                that.uncheckStrengthForConsiderationModal.hide();

                if (uncheckStrengthCancelButton.dataset.strengthcheckplanpropertypk) {
                    let checkbox = document.querySelector(`[data-planpropertypk='${uncheckStrengthCancelButton.dataset.strengthcheckplanpropertypk}']`) as HTMLInputElement;
                    if (checkbox != null) {
                        checkbox.checked = true;
                    }
                }
            });
        }

        let saveButton = document.getElementById("summaryOfStrengthsSave");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.customValidation();
        }
        this._core.initializeRequiredFields(this.validationClasses);
        this._core.activateSubMenu("subNavReadySetGo");
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let checkboxes = document.getElementsByClassName("checkbox");
        for (let checkbox of checkboxes)
            checkbox.addEventListener("click", (e: Event) => this.checkForReadonly(e));
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    checkForReadonly(e: Event) {
        let checkbox = <HTMLInputElement>e.target;

        if (checkbox.readOnly) {
            if (checkbox.checked)
                checkbox.checked = false;
            else
                checkbox.checked = true;

            return false;
        } else {
            if ("propertycode" in checkbox.dataset && checkbox.dataset.propertycode == "summaryOfStrengthsStrengthCheckForConsideration") {
                //if has a plan property and has been unchecked, show warning that things down the line will be affected
                if (checkbox.dataset.planpropertypk && parseInt(checkbox.dataset.planpropertypk) > 0 && !checkbox.checked && "initiallychecked" in checkbox.dataset && checkbox.dataset.initiallychecked == "true") {
                    e.preventDefault();
                    this.uncheckStrengthForConsiderationModal.show();
                    this.uncheckStrengthForConsiderationModal.addAttributeToElement(this.uncheckStrengthForConsiderationModal.id, "#uncheckStrengthForConsiderationConfirm", "strengthcheckplanpropertypk", checkbox.dataset.planpropertypk);
                    this.uncheckStrengthForConsiderationModal.addAttributeToElement(this.uncheckStrengthForConsiderationModal.id, "#uncheckStrengthForConsiderationCancel", "strengthcheckplanpropertypk", checkbox.dataset.planpropertypk);

                    if (checkbox.id) {
                        this.uncheckStrengthForConsiderationModal.callingId = checkbox.id;
                    }
                } 
            } else if ("propertycode" in checkbox.dataset && checkbox.dataset.propertycode == "summaryOfStrengthsChallengeCheckForConsideration") {
                //if has a plan property and has been unchecked, show warning that things down the line will be affected
                if (checkbox.dataset.planpropertypk && parseInt(checkbox.dataset.planpropertypk) > 0 && !checkbox.checked && "initiallychecked" in checkbox.dataset && checkbox.dataset.initiallychecked == "true") {
                    e.preventDefault();
                    this.uncheckChallengeForConsiderationModal.show();
                    this.uncheckChallengeForConsiderationModal.addAttributeToElement(this.uncheckChallengeForConsiderationModal.id, "#uncheckChallengeForConsiderationConfirm", "challengecheckplanpropertypk", checkbox.dataset.planpropertypk);
                    this.uncheckChallengeForConsiderationModal.addAttributeToElement(this.uncheckChallengeForConsiderationModal.id, "#uncheckChallengeForConsiderationCancel", "challengecheckplanpropertypk", checkbox.dataset.planpropertypk);

                    if (checkbox.id) {
                        this.uncheckChallengeForConsiderationModal.callingId = checkbox.id;
                    }
                } 
            }
        }
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allStrengthElements = [];
        let allChallengeElements = [];
        let reflectionElement: IPlanProperty;
        let planElement = document.getElementById("summaryOfStrengthsForm");
        let planPk = planElement.dataset.planpk;
        let allElements = document.getElementsByClassName("summaryOfStrengthsField");
        for (let element of allElements) {
            let htmlElement = <HTMLInputElement>element;
            let planPropertyPK: number = 0;
            let rowNumber: number = parseInt(htmlElement.dataset.row);

            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }

            let val = "";

            if (htmlElement.checked) {
                val = "on";
            }

            let saveItem: IPlanPropertyExtended = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: parseInt(planPk),
                PropertyFK: parseInt(htmlElement.dataset.propertypk),
                TextValue: val,
                LookupCodeFK: null,
                RowNbr: rowNumber,
                IsDeletedInd: false,
                DataPointPlanPropertyPK: parseInt(htmlElement.dataset.datapointplanpropertypk),
                ButtonRow: 0
            };

            allStrengthElements.push(saveItem);
        }

        allElements = document.getElementsByClassName("summaryOfChallengesField");
        for (let element of allElements) {
            let htmlElement = <HTMLInputElement>element;
            let planPropertyPK: number = 0;
            let rowNumber: number = parseInt(htmlElement.dataset.row);

            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }

            let val = "";

            if (htmlElement.checked) {
                val = "on";
            }

            let saveItem: IPlanPropertyExtended = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: parseInt(planPk),
                PropertyFK: parseInt(htmlElement.dataset.propertypk),
                TextValue: val,
                LookupCodeFK: null,
                RowNbr: rowNumber,
                IsDeletedInd: false,
                DataPointPlanPropertyPK: parseInt(htmlElement.dataset.datapointplanpropertypk),
                ButtonRow: 0
            };

            allChallengeElements.push(saveItem);
        }

        let reflectionElements = document.getElementsByClassName("summaryOfStrengthsReflectionsField");
        for (let element of reflectionElements) {
            let htmlElement = <HTMLInputElement>element;
            let planPropertyPK: number = 0;
            let rowNumber: number = parseInt(htmlElement.dataset.row);

            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }

            let saveItem: IPlanProperty = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: parseInt(planPk),
                PropertyFK: parseInt(htmlElement.dataset.propertypk),
                TextValue: htmlElement.value,
                LookupCodeFK: null,
                RowNbr: rowNumber,
                IsDeletedInd: false
            };

            reflectionElement = saveItem;
        }

        let allSaveElements = {
            "Strengths": allStrengthElements,
            "Challenges": allChallengeElements,
            "Reflections": reflectionElement,
            "PlanFK": planPk
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/SaveSummaryOfStrengths', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    core.pageReload(true, parseInt(planPk));
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allChallengeElements.length === 0 && allStrengthElements.length === 0 && allSaveElements.Reflections.TextValue === "") {
            if (referrer === "save") {
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'error', 3000, null);
                this.customValidation();
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    customValidation() {
        let errorCount = 0;

        //Check to see if at least two challenges have been checked for consideration
        let challengeCheckboxes = document.getElementsByClassName("summaryOfChallengesField") as HTMLCollectionOf<HTMLInputElement>;
        let challengeCheckedCount = 0;

        for (let check of challengeCheckboxes) {
            if (check.checked) {
                challengeCheckedCount++;
            }
        }

        if (challengeCheckedCount < 2) {
            errorCount++;
            let checkboxErrorMessage = document.getElementById("summaryChallengesErrorContainer");
            if (checkboxErrorMessage !== null) {
                checkboxErrorMessage.classList.add("show");
            }
        }

        //Check to see if at least one strength has been checked for consideration
        let strengthCheckboxes = document.getElementsByClassName("summaryOfStrengthsField") as HTMLCollectionOf<HTMLInputElement>;
        let strengthCheckedCount = 0;

        for (let check of strengthCheckboxes) {
            if (check.checked) {
                strengthCheckedCount++;
            }
        }

        if (strengthCheckedCount === 0) {
            errorCount++;
            let strengthCheckboxErrorMessage = document.getElementById("summaryStrengthsErrorContainer");
            if (strengthCheckboxErrorMessage !== null) {
                strengthCheckboxErrorMessage.classList.add("show");
            }
        }

        if (errorCount > 0) {
            //Set text for error message
            let message = <HTMLDivElement>document.getElementById("validationMessage");

            if (errorCount === 1) {
                message.innerHTML = "<p class='validationErrorCountMessage'>There is " + errorCount + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
            } else {
                message.innerHTML = "<p class='validationErrorCountMessage'>There are " + errorCount + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
            }
            let goToError = document.getElementById("goToFirstError");

            if (goToError !== null) {

                let firstFocusableEl = <HTMLElement>document.querySelector(".custom-error.show");

                if (firstFocusableEl !== null) {
                    goToError.addEventListener("click", function () {
                        firstFocusableEl.focus();
                    });
                } else {
                    goToError.parentNode.removeChild(goToError);
                }
            }

            this._core.doValidation(null, true);

        } else {
            this._core.doValidation(null, false);
        }
    }

    uncheckChallengeForConsideration(planPropertyPK: string) {
        let that = this;
        that.uncheckChallengeForConsiderationModal.hide();

        let checkbox = document.querySelector(`[data-planpropertypk='${planPropertyPK}']`) as HTMLInputElement;

        if (checkbox != null && "datapointplanpropertypk" in checkbox.dataset) {
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/Set/UncheckChallengeForConsideration', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                    Core.createHTMLAlert("alertMessageDiv", "Challenge successfully unchecked for consideration", 'success', 3000, null);

                    //Uncheck checkbox and reset values
                    checkbox.checked = false;
                    checkbox.dataset.initiallychecked = "false";
                    checkbox.dataset.planpropertypk = "0";
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error unchecking challenge for consideration. Please try again later.", "error", 3000, null);
                }
                Core.hideLoader();
            };
            xhr.send(checkbox.dataset.datapointplanpropertypk);
        } else {
            Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error unchecking challenge for consideration. Please try again later.", "error", 3000, null);
        }
    }

    uncheckStrengthForConsideration(planPropertyPK: string) {
        let that = this;
        that.uncheckStrengthForConsiderationModal.hide();

        let checkbox = document.querySelector(`[data-planpropertypk='${planPropertyPK}']`) as HTMLInputElement;

        if (checkbox != null && "datapointplanpropertypk" in checkbox.dataset) {
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/Set/UncheckStrengthForConsideration', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                    Core.createHTMLAlert("alertMessageDiv", "Strength successfully unchecked for consideration", 'success', 3000, null);

                    //Uncheck checkbox and reset values
                    if (checkbox != null) {
                        checkbox.checked = false;
                        checkbox.dataset.initiallychecked = "false";
                        checkbox.dataset.planpropertypk = "0";
                    }
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error unchecking strength for consideration. Please try again later.", "error", 3000, null);
                }
                Core.hideLoader();
            };
            xhr.send(checkbox.dataset.datapointplanpropertypk);
        } else {
            Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error unchecking strength for consideration. Please try again later.", "error", 3000, null);
        }
    }
}

class IUPerformanceData {

    validationClasses: string[];
    hashClasses: string[];
    private _core: Core;
    private planFK: number;
    private templateFK: number;
    private deleteDataSourceConfirmModal: Modal;
    private deleteOtherServiceConfirmModal: Modal;

    constructor() {
        let that = this;
        this._core = new Core();
        this.validationClasses = ["multiSelectServices", "performanceDataField", "performanceDataOtherField", "performanceDataStrengthChallenge"];
        this.hashClasses = ["performanceDataField", "performanceDataOtherField", "performanceDataStrengthChallenge"];

        this.deleteDataSourceConfirmModal = new Modal("deleteDataSourceModal", null);
        this.deleteOtherServiceConfirmModal = new Modal("deleteServiceModal", null);

        let deleteDataSourceCancelButton = document.getElementById("deleteDataSourceCancel");
        if (deleteDataSourceCancelButton != null) {
            deleteDataSourceCancelButton.addEventListener("click", () => {
                that.deleteDataSourceConfirmModal.hide();
            });
        }

        let deleteServiceCancelButton = document.getElementById("deleteServiceCancel");
        if (deleteServiceCancelButton != null) {
            deleteServiceCancelButton.addEventListener("click", () => {
                that.deleteOtherServiceConfirmModal.hide();
            });
        }

        let deleteDataSourceConfirmButton = document.getElementById("deleteDataSourceConfirm");
        if (deleteDataSourceConfirmButton != null) {
            deleteDataSourceConfirmButton.addEventListener("click", () => {
                if ("buttonid" in deleteDataSourceConfirmButton.dataset) {
                    that.deleteDataSourceConfirm(deleteDataSourceConfirmButton.dataset.buttonid);
                }
            });
        }

        let deleteServiceConfirmButton = document.getElementById("deleteServiceConfirm");
        if (deleteServiceConfirmButton != null) {
            deleteServiceConfirmButton.addEventListener("click", () => {
                if ("servicepk" in deleteServiceConfirmButton.dataset && "buttonid" in deleteServiceConfirmButton.dataset) {
                    that.deleteServiceConfirm(parseInt(deleteServiceConfirmButton.dataset.servicepk), deleteServiceConfirmButton.dataset.buttonid);
                }
            });
        }

        let planElement = document.getElementById("iuPerformanceDataForm");
        this.planFK = parseInt(planElement.dataset.planpk);
        this.templateFK = parseInt(planElement.dataset.templatefk);

        let saveButton = document.getElementById("iuPerformanceDataSave");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);                                              
        this.customInitializeRequiredFields();
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        
        this._core.activateSubMenu("subNavReadySetGo");
        let hidden = this._core.createHash(this.hashClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let reportServicesButton = document.getElementById("performanceDataSelectedServices");
        if (reportServicesButton !== null) {
            reportServicesButton.addEventListener("click", (e: Event) => this.reportServices());
        }

        document.addEventListener("click", (e) => {
            let target = e.target as HTMLElement;
            if (target.classList.contains("addDataSource") && "row" in target.dataset && "serviceLookupPk" in target.dataset) {
                that.addProgram(parseInt(target.dataset.row), parseInt(target.dataset.serviceLookupPk));
            } else if (target.classList.contains("addDataSourceOtherService") && "row" in target.dataset) {
                that.addOtherServiceProgram(parseInt(target.dataset.row));
            } else if (target.classList.contains("deleteDataSource")) {
                let deleteButton = <HTMLButtonElement>target;
                that.deleteDataSource(deleteButton);
            } else if (target.classList.contains("deleteService")) {
                let deleteServiceButton = <HTMLButtonElement>target;
                that.deleteService(deleteServiceButton);
            }
        });

        document.addEventListener("change", (e) => {
            let target = e.target as HTMLElement;
            if (target.classList.contains("serviceProgram")) {
                let element = <HTMLSelectElement>target;
                let container = Core.findClosest(element, ".rowContainer");
                let otherElementContainer = container.querySelector(".serviceProgramOtherContainer");
                let otherElement = container.querySelector(".serviceProgramOther") as HTMLInputElement;

                if (element.options[element.selectedIndex].dataset.lookupcode === "performanceProgramOther") {
                    otherElementContainer.classList.remove("hide");
                    that._core.forceElementRequired(otherElement);
                } else {
                    otherElementContainer.classList.add("hide");
                    that._core.forceElementOptional(otherElement);
                }
            }
        });

        let addOtherServiceButton = document.getElementById("addOtherService") as HTMLButtonElement;
        if (addOtherServiceButton != null) {
            addOtherServiceButton.addEventListener("click", () => {
                that.addOtherService();
            });
        }

        that.showDeleteDataSource();

        let exportToExcelButton = document.getElementById("exportExcelPerformanceData");
        if (exportToExcelButton != null) {
            exportToExcelButton.addEventListener("click", () => {
                that.exportToExcel()
            });
        }
    }

    createHash() {
        let hash = this._core.createHash(this.hashClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let that = this;
        let core = that._core;
        if (referrer !== "save" && this._core.checkSave(that) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }

        let allServiceData: IPerformanceDataServiceSaveModel[] = [];
        let removeServicesData: IPlanPropertyExtended[] = [];

        let allServiceElements = document.querySelectorAll(".performanceDataService, .otherService");
        for (let element of allServiceElements) {

            let serviceElement = <HTMLInputElement>element;

            let propertyPK: number = 0;
            let lookupCodeFK: number = null;
            let planPropertyPK: number = 0;
            let dataPointPlanPropertyPK: number = 0;
            let buttonRow: number = 0;
            let rowNbr: number = 0;
            let textValue: string = null;
            
            if (serviceElement.classList.contains("performanceDataService") && "lookupcodepk" in serviceElement.dataset) {
                lookupCodeFK = parseInt(serviceElement.dataset.lookupcodepk);
            }

            if ("propertypk" in serviceElement.dataset) {
                propertyPK = parseInt(serviceElement.dataset.propertypk);
            }

            if ("planpropertypk" in serviceElement.dataset) {
                planPropertyPK = parseInt(serviceElement.dataset.planpropertypk);
            }

            if (serviceElement.classList.contains("otherService")) {
                textValue = serviceElement.value;
            }

            let serviceSaveItem: IPlanPropertyExtended = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: that.planFK,
                PropertyFK: propertyPK,
                TextValue: textValue,
                RowNbr: rowNbr,
                LookupCodeFK: lookupCodeFK,
                IsDeletedInd: false,
                ButtonRow: buttonRow,
                DataPointPlanPropertyPK: dataPointPlanPropertyPK
            };

            if (serviceElement.checked || serviceElement.classList.contains("otherService")) {

                let allDataSourceData: IPerformanceDataDataSourceSaveModel[] = [];
                let equityConsiderationsSaveItem: IPlanPropertyExtended = null;

                let equityConsiderationsElement = null;
                if (serviceElement.classList.contains("otherService")) {
                    equityConsiderationsElement = document.querySelector(`.performanceDataOtherField.serviceProgramEquityConsiderations[data-buttonrow='${serviceElement.dataset.row}']`) as HTMLTextAreaElement;
                } else {
                    equityConsiderationsElement = document.querySelector(`.performanceDataField.serviceProgramEquityConsiderations[data-service-lookup-pk='${serviceElement.dataset.lookupcodepk}']`) as HTMLTextAreaElement;
                }

                if(equityConsiderationsElement != null) {
                    let equityPropertyPK: number = 0;
                    let equityLookupCodeFK: number = null;
                    let equityPlanPropertyPK: number = 0;
                    let equityDataPointPlanPropertyPK: number = 0;
                    let equityButtonRow: number = 0;
                    let equityRowNbr: number = 0;
                    let equityTextValue = equityConsiderationsElement.value;

                    if ("propertypk" in equityConsiderationsElement.dataset) { equityPropertyPK = parseInt(equityConsiderationsElement.dataset.propertypk); }

                    if ("planpropertypk" in equityConsiderationsElement.dataset) { equityPlanPropertyPK = parseInt(equityConsiderationsElement.dataset.planpropertypk); }

                    if ("row" in equityConsiderationsElement.dataset) { equityRowNbr = parseInt(equityConsiderationsElement.dataset.row); }

                    if ("buttonrow" in equityConsiderationsElement.dataset) { equityButtonRow = parseInt(equityConsiderationsElement.dataset.buttonrow); }

                    if ("pointplanpropertypk" in equityConsiderationsElement.dataset) { equityDataPointPlanPropertyPK = parseInt(equityConsiderationsElement.dataset.pointplanpropertypk); }

                    equityConsiderationsSaveItem = {
                        PlanPropertyPK: equityPlanPropertyPK,
                        PlanFK: that.planFK,
                        PropertyFK: equityPropertyPK,
                        TextValue: equityTextValue,
                        RowNbr: equityRowNbr,
                        LookupCodeFK: equityLookupCodeFK,
                        IsDeletedInd: false,
                        ButtonRow: equityButtonRow,
                        DataPointPlanPropertyPK: equityDataPointPlanPropertyPK
                    };
                }

                //Get data source elements for this service
                let dataSourceRows;
                if (serviceElement.classList.contains("otherService")) {
                    dataSourceRows = document.querySelectorAll(`.otherProgramContainer[data-buttonrow='${serviceElement.dataset.row}']`);
                } else {
                    dataSourceRows = document.querySelectorAll(`.programContainer[data-service-lookup-pk='${serviceElement.dataset.lookupcodepk}']`);
                }

                for(let row of dataSourceRows) {
                    let dataSourceElements = row.querySelectorAll('.dataSourceField');

                    let dataSourceSaveItem: IPlanPropertyExtended = null;
                    let measureOfQualitySaveItem: IPlanPropertyExtended = null;
                    let measureOfImpactSaveItem: IPlanPropertyExtended = null;

                    for (let programElement of dataSourceElements) {

                        let programHTMLElement;

                        let propertyPK: number = 0;
                        let lookupCodeFK: number = null;
                        let planPropertyPK: number = 0;
                        let dataPointPlanPropertyPK: number = 0;
                        let buttonRow: number = 0;
                        let rowNbr: number = 0;
                        let textValue: string = null;

                        if (programElement.classList.contains("serviceProgramDataSource")) {
                            programHTMLElement = <HTMLTextAreaElement>programElement;
                            textValue = programHTMLElement.value;
                        } else if (programElement.classList.contains("serviceProgramMeasureQuality")) {
                            programHTMLElement = <HTMLTextAreaElement>programElement;
                            textValue = programHTMLElement.value;
                        } else if (programElement.classList.contains("serviceProgramMeasureImpact")) {
                            programHTMLElement = <HTMLTextAreaElement>programElement;
                            textValue = programHTMLElement.value;
                        }

                        if ("propertypk" in programHTMLElement.dataset) { propertyPK = parseInt(programHTMLElement.dataset.propertypk); }

                        if ("planpropertypk" in programHTMLElement.dataset) { planPropertyPK = parseInt(programHTMLElement.dataset.planpropertypk); }

                        if ("row" in programHTMLElement.dataset) { rowNbr = parseInt(programHTMLElement.dataset.row); }

                        if ("buttonrow" in programHTMLElement.dataset) { buttonRow = parseInt(programHTMLElement.dataset.buttonrow); }

                        if ("pointplanpropertypk" in programHTMLElement.dataset) { dataPointPlanPropertyPK = parseInt(programHTMLElement.dataset.pointplanpropertypk); }

                        let programData: IPlanPropertyExtended = {
                            PlanPropertyPK: planPropertyPK,
                            PlanFK: that.planFK,
                            PropertyFK: propertyPK,
                            TextValue: textValue,
                            RowNbr: rowNbr,
                            LookupCodeFK: lookupCodeFK,
                            IsDeletedInd: false,
                            ButtonRow: buttonRow,
                            DataPointPlanPropertyPK: dataPointPlanPropertyPK
                        };

                        if(programHTMLElement.classList.contains("serviceProgram") || programData.LookupCodeFK != null || programData.PlanPropertyPK != 0 || programData.TextValue != "") {
                            if (programHTMLElement.classList.contains("serviceProgramDataSource")) {
                                dataSourceSaveItem = programData;
                            } else if (programHTMLElement.classList.contains("serviceProgramMeasureQuality")) {
                                measureOfQualitySaveItem = programData;
                            } else if (programHTMLElement.classList.contains("serviceProgramMeasureImpact")) {
                                measureOfImpactSaveItem = programData;
                            }
                        }
                    }

                    let dataSourceData: IPerformanceDataDataSourceSaveModel = {
                        DataSource: dataSourceSaveItem,
                        MeasureOfImpact: measureOfImpactSaveItem,
                        MeasureOfQuality: measureOfQualitySaveItem
                    }
                    allDataSourceData.push(dataSourceData)
                }

                let serviceSaveData: IPerformanceDataServiceSaveModel = {
                    DataSources: allDataSourceData,
                    Service: serviceSaveItem,
                    EquityConsiderations: equityConsiderationsSaveItem
                }

                allServiceData.push(serviceSaveData);

            } else if("hadvalue" in serviceElement.dataset){
                removeServicesData.push(serviceSaveItem);
            }
        }

        let otherData: IPlanProperty[] = [];
        let allOtherElements = document.querySelectorAll(".performanceDataStrengthChallenge");
        for (let element of allOtherElements) {
            let htmlElement = <HTMLInputElement>element;
            let planPropertyPK: number = 0;
            let rowNumber: number = parseInt(htmlElement.dataset.row);

            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }

            if (htmlElement.value !== "" || planPropertyPK > 0) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: that.planFK,
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: htmlElement.value,
                    LookupCodeFK: null,
                    RowNbr: rowNumber,
                    IsDeletedInd: false
                };

                otherData.push(saveItem);
            }
        }

        let saveData: IPerformanceDataSaveModel = {
            PlanFK: that.planFK,
            RemoveServices: removeServicesData,
            Services: allServiceData,
            OtherData: otherData
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/SaveIUPerformanceData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    core.pageReload(true, that.planFK)
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        xhr.send(JSON.stringify(saveData));
    }

    reportServices() {
        let _that = this;
        let successes: boolean[] = [];
        let allServices = document.getElementsByClassName("performanceDataService") as HTMLCollectionOf<HTMLInputElement>;

        for (let service of allServices) {
            let topLevelAccordionElement = <HTMLDivElement>document.getElementById("performanceDataAccordionList");
            let accordionTrigger = <HTMLButtonElement>topLevelAccordionElement.querySelector(`.Accordion-trigger[data-lookupcodepk="${service.dataset.lookupcodepk}"]`);
            let accordionPanel = <HTMLDivElement>topLevelAccordionElement.querySelector(`.Accordion-panel[data-lookupcodepk="${service.dataset.lookupcodepk}"]`);

            if (service.checked) {
                if (accordionTrigger.classList.contains("hide-accordion")) {
                    accordionTrigger.classList.remove("hide-accordion");
                    accordionPanel.classList.remove("hide-accordion");

                    var elementsToMakeRequired = accordionPanel.querySelectorAll(`.performanceDataField[data-isrequired='true']`);
                    for (let element of elementsToMakeRequired) {
                        let htmlElement = <HTMLElement>element;
                        _that._core.forceElementRequired(htmlElement);
                    }
                }
            } else {
                if (!accordionTrigger.classList.contains("hide-accordion")) {
                    accordionTrigger.classList.add("hide-accordion");
                    accordionPanel.classList.add("hide-accordion");

                    var elementsToMakeOptional = accordionPanel.querySelectorAll(`.performanceDataField[data-isrequired='true']`);
                    for (let element of elementsToMakeOptional) {
                        let htmlElement = <HTMLElement>element;
                        _that._core.forceElementOptional(htmlElement);
                    }
                }
            }
        }

        Core.createHTMLAlert("alertMessageDiv", "Service(s) and/or Program(s) successfully added", 'success', 3000, null);

        this._core.initializeRequiredFields(this.validationClasses);
    }

    addProgram(row: number, serviceLookupPK: number = null) {
        let that = this;

        //Get the current max program row number
        let rowNbr = 0;
        let programContainers = document.querySelectorAll(`.programContainer[data-buttonrow='${row}']`);

        for (let programContainer of programContainers) {
            let element = <HTMLElement>programContainer;
            if ("row" in element.dataset && parseInt(element.dataset.row) > rowNbr) {
                rowNbr = parseInt(element.dataset.row);
            }
        }

        rowNbr++;

        let xhr = new XMLHttpRequest();

        let serviceProgramContainer = document.querySelector(`.serviceProgramContainer[data-row='${row}']`); 

        xhr.open('POST', '/Set/PerformanceDataNewProgram', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                $(serviceProgramContainer).append(xhr.responseText);
                Core.createHTMLAlert("alertMessageDiv", "New Data Source Row Added", 'success', 3000, null);

                var insertedProgram = serviceProgramContainer.querySelector(`.rowContainer[data-row='${rowNbr}']`);
                if(insertedProgram != null) {
                    var elementsToMakeRequired = insertedProgram.querySelectorAll(`.performanceDataField[data-isrequired='true']`);
                    for (let element of elementsToMakeRequired) {
                        let htmlElement = <HTMLElement>element;
                        that._core.forceElementRequired(htmlElement);
                    }
                }

                that.showDeleteDataSource();
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "There was an error adding a new data source row. Please try again later.", 'error', 3000, null);
            }
        };
        xhr.send(`templateFK=${that.templateFK}&rowNumber=${rowNbr}&buttonRow=${row}&isOtherService=false&serviceLookupPK=${serviceLookupPK}`);
    }

    addOtherServiceProgram(row: number) {
        let that = this;

        //Get the current max program row number
        let rowNbr = 0;
        let programContainers = document.querySelectorAll(`.otherProgramContainer[data-buttonrow='${row}']`);

        for (let programContainer of programContainers) {
            let element = <HTMLElement>programContainer;
            if ("row" in element.dataset && parseInt(element.dataset.row) > rowNbr) {
                rowNbr = parseInt(element.dataset.row);
            }
        }

        rowNbr++;

        let xhr = new XMLHttpRequest();

        let serviceProgramContainer = document.querySelector(`.serviceProgramOtherContainer[data-row='${row}']`); 

        xhr.open('POST', '/Set/PerformanceDataNewProgram', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                $(serviceProgramContainer).append(xhr.responseText);
                Core.createHTMLAlert("alertMessageDiv", "New Data Source Row Added", 'success', 3000, null);

                var insertedProgram = serviceProgramContainer.querySelector(`.rowContainer[data-row='${rowNbr}']`);
                if(insertedProgram != null) {
                    var elementsToMakeRequired = insertedProgram.querySelectorAll(`.performanceDataOtherField[data-isrequired='true']`);
                    for (let element of elementsToMakeRequired) {
                        let htmlElement = <HTMLElement>element;
                        that._core.forceElementRequired(htmlElement);
                    }
                }

                that.showDeleteDataSource();
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "There was an error adding a new data source row. Please try again later.", 'error', 3000, null);
            }
        };

        xhr.send(`templateFK=${that.templateFK}&rowNumber=${rowNbr}&buttonRow=${row}&isOtherService=true&serviceLookupPK=null`);
    }

    addOtherService() {
        let that = this;

        //Get the current max program row number
        let rowNbr = 0;
        let otherServiceContainers = document.querySelectorAll(`.serviceProgramOtherContainer`);
        for (let programContainer of otherServiceContainers) {
            let element = <HTMLElement>programContainer;
            if ("row" in element.dataset && parseInt(element.dataset.row) > rowNbr) {
                rowNbr = parseInt(element.dataset.row);
            }
        }

        let newOtherServiceName = document.getElementById("nameOtherSerivce") as HTMLInputElement;

        rowNbr++;

        let xhr = new XMLHttpRequest();

        let container = document.getElementById("performanceDataOtherAccordionList");

        if (newOtherServiceName.value != "") {
            xhr.open('POST', '/Set/PerformanceDataNewOtherService', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    $(container).append(xhr.responseText);
                    Core.createHTMLAlert("alertMessageDiv", "New Program/Service Added", 'success', 3000, null);
                    new CustomAccordion(null);
                    newOtherServiceName.value = "";

                    var newAccordion = document.getElementById(`serviceContent${rowNbr}`);

                    if (newAccordion != null) {
                        var elementsToMakeRequired = newAccordion.querySelectorAll(`.performanceDataOtherField[data-isrequired='true']`);
                        for (let element of elementsToMakeRequired) {
                            let htmlElement = <HTMLElement>element;
                            that._core.forceElementRequired(htmlElement);
                        }
                    }

                    that.showDeleteDataSource();
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an error adding a new program/service. Please try again later.", 'error', 3000, null);
                }
            };
            xhr.send(`templateFK=${that.templateFK}&serviceName=${newOtherServiceName.value}&rowNumber=${rowNbr}`);
        } else {
            Core.createHTMLAlert("alertMessageDiv", "Please specify a name for the new program/service", 'error', 3000, null);
        }
    }

    customInitializeRequiredFields() {
        let that = this;
        //Only make fields from services that have been selected required

        var elementsToMakeRequired = document.querySelectorAll(`.Accordion-panel:not(.hide-accordion) .performanceDataField[data-isrequired='true'], .performanceDataOtherField[data-isrequired='true']`);
        for (let element of elementsToMakeRequired) {
            let htmlElement = <HTMLElement>element;
            that._core.forceElementRequired(htmlElement);
        }

        let serviceProgramElements = document.getElementsByClassName("serviceProgram") as HTMLCollectionOf<HTMLSelectElement>;
        for (let el of serviceProgramElements) {
            let container = Core.findClosest(el, ".rowContainer");
            let otherElementContainer = container.querySelector(".serviceProgramOtherContainer");
            let otherElement = container.querySelector(".serviceProgramOther") as HTMLInputElement;

            if (el.options[el.selectedIndex].dataset.lookupcode === "performanceProgramOther") {
                otherElementContainer.classList.remove("hide");
                that._core.forceElementRequired(otherElement);
            } else {
                otherElementContainer.classList.add("hide");
                that._core.forceElementOptional(otherElement);
            }
        }

        that._core.initializeRequiredFields(that.validationClasses);
    } 

    showDeleteDataSource() {
        var programContainers = document.querySelectorAll(".serviceProgramOtherContainer, .serviceProgramContainer");
        for (let container of programContainers) {
            let dataSources = container.querySelectorAll(".rowContainer");
            let deleteButtons = container.querySelectorAll(".deleteDataSource");
            if (dataSources.length > 1) {
                for (let deleteButton of deleteButtons) {
                    let deleteButtonElement = <HTMLElement>deleteButton;
                    deleteButtonElement.classList.remove("hide");
                }
            } else {
                for (let deleteButton of deleteButtons) {
                    let deleteButtonElement = <HTMLElement>deleteButton;
                    deleteButtonElement.classList.add("hide");
                }
            }

        }
    }

    deleteDataSource(deleteButton: HTMLButtonElement) {
        let that = this;
        let hasExistingData = false;
        let dataSourceRow = Core.findClosest(deleteButton, ".rowContainer");
        let dataSourceElements = dataSourceRow.querySelectorAll('.dataSourceField');
        for (let element of dataSourceElements) {
            let htmlElement = <HTMLElement>element;
            if ("planpropertypk" in htmlElement.dataset && parseInt(htmlElement.dataset.planpropertypk) > 0) {
                hasExistingData = true;
                break;
            }
        }

        if(hasExistingData) {
            that.deleteDataSourceConfirmModal.callingId = deleteButton.id;
            that.deleteDataSourceConfirmModal.show();
            that.deleteDataSourceConfirmModal.addAttributeToElement(that.deleteDataSourceConfirmModal.id, "#deleteDataSourceConfirm", "buttonid", deleteButton.id);
        } else {
            let container = <HTMLElement>Core.findClosest(deleteButton, ".rowContainer");
            if (container != null) {
                let nextFocusable = Core.getNextFocusableElement(deleteButton);
                container.parentNode.removeChild(container);
                Core.createHTMLAlert("alertMessageDiv", "Data Source row removed", 'success', 3000, null);
                that.showDeleteDataSource();
                nextFocusable.focus();
            }
        }
    }

    deleteDataSourceConfirm(buttonId: string) {
        let that = this;
        let deleteButton = document.getElementById(buttonId);
        let dataSourceRow = Core.findClosest(deleteButton, ".rowContainer");

        that.deleteDataSourceConfirmModal.hide();
        Core.showLoader();
        let allRemoveElements = [];

        //Get all data source elements with this row and button row
        let dataSourceElements = dataSourceRow.querySelectorAll('.dataSourceField');
        for (let element of dataSourceElements) {
            let htmlElement = <HTMLElement>element;
            if ("planpropertypk" in htmlElement.dataset && parseInt(htmlElement.dataset.planpropertypk) > 0) {
                allRemoveElements.push(parseInt(htmlElement.dataset.planpropertypk));
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/DeletePerformanceDataDataSource', true);
        xhr.setRequestHeader('Content-type', 'application/json');
        xhr.onload = function () {
            Core.hideLoader();
            let jsonResponse = JSON.parse(xhr.response);
            if (xhr.status === 200 && jsonResponse.success) {
                let nextFocusable = Core.getNextFocusableElement(deleteButton);
                dataSourceRow.parentNode.removeChild(dataSourceRow);
                Core.createHTMLAlert("alertMessageDiv", "Data Source row successfully deleted", 'success', 3000, null);
                that.showDeleteDataSource();
                nextFocusable.focus();     
            } else {
                Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this data source", 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(allRemoveElements));
    }

    deleteService(deleteServiceButton: HTMLButtonElement) {
        let that = this;
        let servicePlanPropertyPK = parseInt(deleteServiceButton.dataset.servicepk);
        if (servicePlanPropertyPK > 0) {
            that.deleteOtherServiceConfirmModal.callingId = deleteServiceButton.id;
            that.deleteOtherServiceConfirmModal.show();
            that.deleteOtherServiceConfirmModal.addAttributeToElement(that.deleteOtherServiceConfirmModal.id, "#deleteServiceConfirm", "servicepk", deleteServiceButton.dataset.servicepk);
            that.deleteOtherServiceConfirmModal.addAttributeToElement(that.deleteOtherServiceConfirmModal.id, "#deleteServiceConfirm", "buttonid", deleteServiceButton.id);
        } else {
            let accordion = <HTMLElement>Core.findClosest(deleteServiceButton, ".Accordion");
            if (accordion != null) {

                let otherServicesContainer = document.getElementById("performanceDataOtherAccordionList");

                //Get next focusable accordion
                let accordionTriggers = otherServicesContainer.querySelectorAll(".Accordion-trigger");
                if (accordionTriggers.length > 1) {
                    let arr = Array.prototype.slice.call(accordionTriggers);
                    let thisAccordionTrigger = accordion.querySelector(".Accordion-trigger");
                    if (thisAccordionTrigger != null) {
                        let index = arr.indexOf(thisAccordionTrigger);
                        if (index > -1) {
                            let nextFocusable = arr[index + 1] || arr[index - 1] || arr[0];
                            nextFocusable.focus();
                        }
                    }
                } else {
                    //Focus on create other service
                    let create = document.getElementById("nameOtherSerivce");
                    if (create != null) {
                        create.focus();
                    }
                }

                accordion.parentNode.removeChild(accordion);
                Core.createHTMLAlert("alertMessageDiv", "Other program/service removed", 'success', 3000, null);
            }
        }
    }

    deleteServiceConfirm(servicePK: number, buttonId: string) {
        let that = this;

        that.deleteOtherServiceConfirmModal.hide();
        Core.showLoader();

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/DeletePerformanceDataService', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            Core.hideLoader();
            let jsonResponse = JSON.parse(xhr.response);
            if (xhr.status === 200 && jsonResponse.success) {

                let deleteButton = document.getElementById(buttonId);
                if (deleteButton != null) {
                    let accordion = <HTMLElement>Core.findClosest(deleteButton, ".Accordion");
                    if (accordion != null) {

                        let otherServicesContainer = document.getElementById("performanceDataOtherAccordionList");

                        //Get next focusable accordion
                        let accordionTriggers = otherServicesContainer.querySelectorAll(".Accordion-trigger");
                        if (accordionTriggers.length > 1) {
                            let arr = Array.prototype.slice.call(accordionTriggers);
                            let thisAccordionTrigger = accordion.querySelector(".Accordion-trigger");
                            if (thisAccordionTrigger != null) {
                                let index = arr.indexOf(thisAccordionTrigger);
                                if (index > -1) {
                                    let nextFocusable = arr[index + 1] || arr[index - 1] || arr[0];
                                    nextFocusable.focus();
                                }
                            }
                        } else {
                            //Focus on create other service
                            let create = document.getElementById("nameOtherSerivce");
                            if (create != null) {
                                create.focus();
                            }
                        }

                        accordion.parentNode.removeChild(accordion);
                        Core.createHTMLAlert("alertMessageDiv", "Other program/service successfully deleted", 'success', 3000, null);
                    }
                }
            } else {
                Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this program/service", 'error', 3000, null);
            }
        };
        xhr.send("servicePK="+servicePK);
    }

    exportToExcel() {
        let that = this;
        Core.showLoader();
        let xhr = new XMLHttpRequest();
        xhr.open('GET', `/ExportExcel/PerformanceDataExport?planFK=${that.planFK}`, true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.responseType = "blob";
        xhr.onload = function () {
            Core.hideLoader();
            if (xhr.status === 200) {
                let blob = this.response;
                let filename = "Performance Data.xlsx";

                if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                    window.navigator.msSaveBlob(blob, filename);
                } else {
                    var a = <HTMLAnchorElement>document.createElement("a");
                    var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                    document.body.appendChild(a);
                    a.style.display = "none";
                    a.href = blobUrl;
                    a.download = filename;
                    a.click();
                }
            } else {
                Core.createHTMLAlert("alertMessageDiv", "There was an issue during export to Excel. Please try again later.", 'error', 3000, null);
            }
        }
        xhr.send();
    }
}

class IUStateSystemSupport {

    validationClasses: string[];
    private _core: Core;
    private planFK: number;
    private templateFK: number;

    constructor() {
        let that = this;
        this._core = new Core();
        this.validationClasses = ["stateSystemSupportField"];

        let planElement = document.getElementById("iuStateSystemSupportForm");
        this.planFK = parseInt(planElement.dataset.planpk);
        this.templateFK = parseInt(planElement.dataset.templatefk);

        let saveButton = document.getElementById("iuStateSystemSupportSave");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);                                              

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        this._core.activateSubMenu("subNavReadySetGo");
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let addDataButton = document.getElementsByClassName("addDataSource") as HTMLCollectionOf<HTMLButtonElement>;
        for (let button of addDataButton) {
            button.addEventListener("click", () => that.addDataSource(button.dataset.section));
        }

        let omitCheckbox = document.getElementsByClassName("stateSystemSupportOmitCheckField") as HTMLCollectionOf<HTMLInputElement>;
        for (let checkbox of omitCheckbox) {
            checkbox.addEventListener("click", () => that.noPrograms(checkbox.dataset.section));
        }

        that.checkForCheckboxes();

    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let that = this;
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];

        let allElements = document.getElementsByClassName("stateSystemSupportField");
        for (let element of allElements) {
            let htmlElement = <HTMLInputElement>element;
            let planPropertyPK: number = 0;
            let rowNumber: number = parseInt(htmlElement.dataset.row);

            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }

            if (htmlElement.value !== "" || planPropertyPK > 0) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: that.planFK,
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: htmlElement.value,
                    LookupCodeFK: null,
                    RowNbr: rowNumber,
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let checkElements = document.getElementsByClassName("stateSystemSupportCheckField");
        for (let element of checkElements) {
            let htmlElement = <HTMLInputElement>element;
            let planPropertyPK = 0;
            let isChecked = "off";
            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }

            if (htmlElement.checked) {
                isChecked = "on";
            }
            let saveItem = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: that.planFK,
                PropertyFK: parseInt(htmlElement.dataset.propertypk),
                TextValue: isChecked,
                LookupCodeFK: null,
                RowNbr: 0,
                IsDeletedInd: false
            };
            allSaveElements.push(saveItem);
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/SaveIUStateSystemOfSupports', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            Core.hideLoader();
            if (referrer === "save") {
                if (xhr.status === 200) {
                    core.pageReload(true, that.planFK);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "") {
                        window.location.href = refreshPage;
                    }
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    addDataSource(sectionCode: string) {
        let that = this;
        let core = this._core;
        let table = document.querySelector(`.supportTableContainer[data-section='${sectionCode}']`) as HTMLElement;
        let inputBoxes = table.querySelectorAll<HTMLTextAreaElement>("textarea");
        let rowCount = 0;

        for (let input of inputBoxes) {
            if (parseInt(input.getAttribute("data-row")) > rowCount) {
                rowCount++;
            }
        }

        rowCount++;

        let xhr = new XMLHttpRequest();

        xhr.open('POST', '/Set/StateSystemOfSupportsNewRow', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = table.querySelector("tbody");
                $(element).append(xhr.responseText);
                Core.createHTMLAlert("alertMessageDiv", "New Data Source Row Added", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(`sectionCode=${sectionCode}&templateFK=${that.templateFK}&rowNumber=${rowCount}`);
    }

    noPrograms(sectionCode: string) {
        let table = document.querySelector(`.supportTableContainer[data-section='${sectionCode}']`) as HTMLElement;
        let inputBoxes = table.querySelectorAll<HTMLTextAreaElement>("textarea");
        let checkbox = document.querySelector(`.stateSystemSupportOmitCheckField[data-section='${sectionCode}']`) as HTMLInputElement;

        for (let input of inputBoxes) {
            if (checkbox.readOnly) {
                if (checkbox.checked)
                    checkbox.checked = false;
                else
                    checkbox.checked = true;
                return false;
            } else {
                if (checkbox.checked) {
                    input.value = " ";
                    input.readOnly = true;
                }
                else {
                    input.readOnly = false;
                    input.value = "";
                }
            }
        }
    }

    checkForCheckboxes() {
        let allElements = document.getElementsByClassName("stateSystemSupportOmitCheckField");
        for (let element of allElements) {
            let htmlElement = <HTMLInputElement>element;
            let sectionCode = element.getAttribute("data-section");
            let table = document.querySelector(`.supportTableContainer[data-section='${sectionCode}']`) as HTMLElement;
            let inputBoxes = table.querySelectorAll<HTMLTextAreaElement>("textarea");

            if (htmlElement.checked) {
                for (let input of inputBoxes) {
                    input.readOnly = true;
                }
            }
        }
    }
}