/// <reference path="../core.ts" />

abstract class LazyAccordion {
    constructor(accordionId: string = null) {
        if (accordionId === null) {
            const allLazyAccordions = document.getElementsByClassName("lazyAccordionTrigger");
            for (const accordion of allLazyAccordions) {
                const accordionButton = <HTMLButtonElement>accordion;
                if (!accordionButton.hasAttribute("data-hasclick")) {
                    accordion.setAttribute("data-hasclick", "true");
                    accordion.addEventListener("click", (e: Event) => this.toggle(accordion.id))
                }
            }
        }
        else {
            const thisLazyAccordion = <HTMLButtonElement>document.getElementById(accordionId);
            if (thisLazyAccordion !== null) {
                if (!thisLazyAccordion.hasAttribute("data-hasclick")) {
                    thisLazyAccordion.setAttribute("data-hasclick", "true");
                    thisLazyAccordion.addEventListener("click", (e: Event) => this.toggle(thisLazyAccordion.id))
                }
            }
        }
   }

    toggle(accordionId: string) {
        const accordion = document.getElementById(accordionId);

        let isOpen: Boolean;
        const contentId = accordion.getAttribute("aria-controls");
        const contentElement = document.getElementById(contentId);

        if (contentElement.classList.contains("open")) {
            isOpen = true;
        }

        if (isOpen === true) {
            this.hide(accordion, contentElement);
        } else {
            if (accordion.dataset.loaded === "true") {
                this.show(accordion, contentElement);
            } else {
                this.loadContent(accordion, contentElement);
            }
            
        }
    }

    //Create a new class for whatever page you are using, extending LazyAccordion, and implement loadContent.
    //See specialeducation.ts for an example, class SpecialEducationFacilitiesLazyAccordion.

    //This can be used as a template for loadContent.
    /*
    async loadContent(accordion: HTMLElement, contentElement: HTMLElement) {
        const icon = <HTMLSpanElement>accordion.querySelector(".lazy-accordion-loaded-icon");
        icon.classList.replace("empty", "loading");
        this.show(accordion, contentElement);

        const specialEducationFaciltyFK = accordion.dataset.specialeducationfaciltyfk;
        const controller = accordion.dataset.controller;
        const action = accordion.dataset.action;
        const url = `${controller}/${action}/${specialEducationFaciltyFK}`;

        //load
        const response = await fetch(`${url}`, { credentials: 'include' })
        if (response.ok) {
            contentElement.innerHTML = await response.text();

            icon.classList.replace("loading", "loaded");
        }
    }
    */
    public abstract loadContent(accordion: HTMLElement, contentElement: HTMLElement);
    
    show(accordion: HTMLElement, contentElement: HTMLElement) {
        accordion.classList.add("open");
        accordion.setAttribute("aria-expanded", "true");
        accordion.setAttribute("data-loaded", "true");
        contentElement.classList.add("open");
    }

    hide(accordion: HTMLElement, contentElement: HTMLElement) {
        accordion.classList.remove("open");
        accordion.setAttribute("aria-expanded", "false");
        contentElement.classList.remove("open");
    }
}