/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />

class Reports {
    private _core: Core;
    constructor() {
        this._core = new Core();

        let leaClearButton = <HTMLButtonElement>document.getElementById("clearSearch");
        if (leaClearButton !== null)
            leaClearButton.addEventListener("click", (e: Event) => this.clearLEASearchFilter());

        let leaSearchButton = <HTMLButtonElement>document.getElementById("runSearch");
        if (leaSearchButton !== null)
            leaSearchButton.addEventListener("click", (e: Event) => this.planSearch(1));

        let leaPlanName = <HTMLInputElement>document.getElementById("leaName");
        if (leaPlanName !== null)
            leaPlanName.addEventListener("keypress", (e: KeyboardEvent) => { if (e.keyCode === 13) { this.planSearch(1); } });

        let individualSaveButtons = document.getElementsByClassName("saveIndDueDates");
        for (let individualSaveButton of individualSaveButtons) {
            individualSaveButton.addEventListener("click", (e: Event) => this._core.updatePlanDueDate(e));
        }

        let lockoutexceptions = document.getElementsByClassName("lockoutException");
        for (let lockoutexception of lockoutexceptions) {
            lockoutexception.addEventListener("click", (e: Event) => this._core.updateLockoutException(e));
        }

        this._core.bindChangeLEA();
        this._core.bindDeleteOwner();
    }

    planSearch(pageNumber: number) {
        let query = Core.GetPlanQueryModel(pageNumber);
        let planTypeGroupCodeInput = <HTMLInputElement>document.getElementById("planTypeGroup");
        if (planTypeGroupCodeInput != null) {
            query.PlanTypeGroupCode = planTypeGroupCodeInput.value;
        }

        let params = "";
        for (var key in query) {
            if (params != "") {
                params += "&";
            }
            params += key + "=" + encodeURIComponent(query[key]);
        }

        window.location.href = "/Reports/StateRequiredReportsGroup/?" + params;
    }

    clearLEASearchFilter() {
        let planTypeGroupCodeInput = <HTMLInputElement>document.getElementById("planTypeGroup");

        let url = "/Reports/StateRequiredReportsGroup/?";

        if (planTypeGroupCodeInput !== null) {
            url += "PlanTypeGroupCode=" + planTypeGroupCodeInput.value;
        }

        window.location.href = url;
    }
}

class Reporting {
    private _core: Core;
    constructor() {
        this._core = new Core();

        //let leaClearButton = <HTMLButtonElement>document.getElementById("clearSearch");
        //if (leaClearButton !== null)
        //    leaClearButton.addEventListener("click", (e: Event) => this.clearLEASearchFilter());

        //let leaSearchButton = <HTMLButtonElement>document.getElementById("runSearch");
        //if (leaSearchButton !== null)
        //    leaSearchButton.addEventListener("click", (e: Event) => this.planSearch(1));

        this._core.bindChangeLEA();
    }

    //planSearch(pageNumber: number) {
    //    let query = Core.GetPlanQueryModel(pageNumber);
    //    let planTypeGroupCodeInput = <HTMLInputElement>document.getElementById("planTypeGroup");
    //    if (planTypeGroupCodeInput != null) {
    //        query.PlanTypeGroupCode = planTypeGroupCodeInput.value;
    //    }

    //    let params = "";
    //    for (var key in query) {
    //        if (params != "") {
    //            params += "&";
    //        }
    //        params += key + "=" + encodeURIComponent(query[key]);
    //    }

    //    window.location.href = "/Reports/StateRequiredReportsGroup/?" + params;
    //}

    //clearLEASearchFilter() {
    //    let planTypeGroupCodeInput = <HTMLInputElement>document.getElementById("planTypeGroup");

    //    let url = "/Reports/StateRequiredReportsGroup/?";

    //    if (planTypeGroupCodeInput !== null) {
    //        url += "PlanTypeGroupCode=" + planTypeGroupCodeInput.value;
    //    }

    //    window.location.href = url;
    //}
}

class FRCPPReport {
    private _core: Core;
    constructor() {
        this._core = new Core();

        let runReportButton = <HTMLButtonElement>document.getElementById("runReport");
        if (runReportButton !== null)
            runReportButton.addEventListener("click", (e: Event) => this.runReport(e));
    }

    runReport(e: Event) {
        //add more elements as we add more options for selectors in reports  these must match exactly what is in the reportParamater table
        let academicYearElement = <HTMLSelectElement>document.getElementById("academicYearFK");
        let showOnlyNotApproved = <HTMLInputElement>document.getElementById("showOnlyNotApproved");
        let showOnlyApproved = <HTMLInputElement>document.getElementById("showOnlyApproved");
        let cipElement = <HTMLSelectElement>document.getElementById("cipCodeFK");
        let planTypes = <HTMLSelectElement>document.getElementById("planTypes");

        let button = <HTMLButtonElement>e.currentTarget;
        let reportCode = button.dataset.reportcode;
        let url = "/Reports/" + reportCode + "/?";

        if (academicYearElement != null && academicYearElement.selectedIndex > 0 && parseInt(academicYearElement.value)) {
            url = url + academicYearElement.id + "=" + parseInt(academicYearElement.value) + "&";
        }

        if (showOnlyNotApproved != null) {
            var ischecked = "false";
            if (showOnlyNotApproved.checked)
                ischecked = "true";
            url = url + showOnlyNotApproved.id + "=" + ischecked + "&";
        }

        if (showOnlyApproved != null) {
            var ischecked = "false";
            if (showOnlyApproved.checked)
                ischecked = "true";
            url = url + showOnlyApproved.id + "=" + ischecked + "&";
        }

        if (cipElement != null && cipElement.selectedIndex > 0 && cipElement.value) {
            url = url + cipElement.id + "=" + cipElement.value + "&";
        }

        if (planTypes != null && planTypes.selectedIndex > 0 && planTypes.value) {
            url = url + planTypes.id + "=" + parseInt(planTypes.value) + "&";
        }

        window.location.href = url;
        }
}