/// <reference path="page.ts" />
/// <reference path="interfaces/educatorinduction/Interfaces.ts" />

class CyberCharterSchoolRenewalApplicationPage extends Page {
    constructor(
        navigationCode: string,
        pageCode: string,
        pageLabel: string,
        planPK: number,
        templateFK: number,
        allowDuplicates: boolean = false,
        customValidationFields: string[] = null,
        useGenericValidation: boolean = true
    ) {
        super(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates, customValidationFields)

        if (this._fromSaveElement?.value === 'true' && useGenericValidation) this._validationHandler.validate()
    }
}

class CyberCharterSchoolRenewalApplicationProfileAndPlanEssentials extends CyberCharterSchoolRenewalApplicationPage {
    private _profileHandler: ProfileHandler

    constructor(
        navigationCode: string,
        pageCode: string,
        pageLabel: string,
        planPK: number,
        templateFK: number,
        allowDuplicates: boolean = false,
    ) {
        super(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates)
        this._profileHandler = new ProfileHandler(this._navigationCode, this._planPK, 'cyberCharter')
    }
}

class CyberCharterSchoolRenewalApplicationApplicationFacts extends CyberCharterSchoolRenewalApplicationPage {
    constructor(
        navigationCode: string,
        pageCode: string,
        pageLabel: string,
        planPK: number,
        templateFK: number,
        allowDuplicates: boolean = false,
        customValidationFields: string[] = null
    ) {
        super(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates, customValidationFields)

        const label = document.getElementById('cyberCharterApplicationFactsGradesEducated')
        label.innerHTML = `${label.innerHTML} <span class='required-label'>*</span>`
    }
}

class CyberCharterSchoolRenewalApplicationStudentAchievementAndOtherOutcomes extends CyberCharterSchoolRenewalApplicationPage { }

class CyberCharterSchoolRenewalApplicationProgressTowardInitialGoals extends CyberCharterSchoolRenewalApplicationPage {
    constructor(
        navigationCode: string,
        pageCode: string,
        pageLabel: string,
        planPK: number,
        templateFK: number,
        allowDuplicates: boolean = false,
        customValidationFields: string[] = null
    ) {
        super(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates, customValidationFields, false)
        let that = this;
        const createSchoolDesignationButton = document.getElementById('cyberCharterProgressSchoolDesignationCreate') as HTMLButtonElement
        if (createSchoolDesignationButton) createSchoolDesignationButton.addEventListener('click', (e: Event) => that.createSchoolDesignation())

        const deleteSchoolDesignationButtons = document.querySelectorAll('.delete-school-designation-button') as NodeListOf<HTMLButtonElement>
        if (deleteSchoolDesignationButtons?.length) for (const button of deleteSchoolDesignationButtons) button.addEventListener('click', (e: Event) => that.deleteSchoolDesignation(button))

        var hasDesignationRadio = document.querySelectorAll(".cybercharterschoolrenewalapplicationProgressTowardInitialGoalsRadioField[data-propertycode='cyberCharterProgressStateAssessmentComprehensiveSupport']");
        if(hasDesignationRadio != null && hasDesignationRadio.length > 0)
        {
            for(let radio of hasDesignationRadio)
            {
                radio.addEventListener("change", function() {
                    that.handleHasDesignation();
                });
            }
        }
        that.handleHasDesignation();
        that._saveHandler.SkipElements.push(...['50349', '50350']);

        if (that._fromSaveElement?.value === 'true') that._validationHandler.validate()
    }

    private async deleteSchoolDesignation(button: HTMLButtonElement) {
        Core.showLoader()

        const schoolDesignationAccordion = button.closest('.school-designation-accordion-container') as HTMLDivElement
        const schoolDesignationPlanPropertyModel: IPlanProperty = {
            PlanFK: this._planPK,
            PlanPropertyPK: parseInt(schoolDesignationAccordion.dataset.planpropertypk),
            PropertyFK: 0,
            TextValue: null,
            LookupCodeFK: null,
            RowNbr: null,
            IsDeletedInd: false
        }

        const response = await fetch(
            `/${this._navigationCode}/DeleteSchoolDesignation/`,
            {
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ PlanPK: this._planPK, PageCode: this._pageCode, PlanPropertyModels: [schoolDesignationPlanPropertyModel] })
            }
        )

        if (response?.ok && response.json()) {
            schoolDesignationAccordion.remove()
            Core.createHTMLAlert('alertMessageDiv', 'Successfully deleted the School Designation.', 'success', 3000, null)
        } else {
            Core.createHTMLAlert('alertMessageDiv', 'There was an error deleting the School Designation, please try again.', 'error', 3000, null)
        }

        Core.hideLoader()
    }

    private async createSchoolDesignation(): Promise<boolean> {
        const grade = document.getElementById('cyberCharterProgressSchoolDesignationGrade') as HTMLSelectElement
        const subject = document.getElementById('cyberCharterProgressSchoolDesignationSubject') as HTMLSelectElement
        const measurableStudentGroups = document.querySelectorAll('input[name="studentGroup"][data-propertypk]:checked') as NodeListOf<HTMLElement>

        if (!grade.selectedIndex) {
            Core.createHTMLAlert('alertMessageDiv', 'Please select a grade from the Grade dropdown menu.', 'warning', 3000, null)
            return
        }
        if (!subject.selectedIndex) {
            Core.createHTMLAlert('alertMessageDiv', 'Please select a subject from the Subject dropdown menu.', 'warning', 3000, null)
            return
        }
        if (!measurableStudentGroups.length) {
            Core.createHTMLAlert('alertMessageDiv', 'Please select a measurable student group from dropdown menu.', 'warning', 3000, null)
            return
        }

        const gradePlanProp: IPlanProperty = {
            PropertyFK: parseInt(grade.dataset.propertypk),
            PlanFK: this._planPK,
            PlanPropertyPK: 0,
            TextValue: null,
            LookupCodeFK: parseInt(grade.value),
            IsDeletedInd: false,
            RowNbr: null
        }

        const subjectPlanProp: IPlanProperty = {
            PropertyFK: parseInt(subject.dataset.propertypk),
            PlanFK: this._planPK,
            PlanPropertyPK: 0,
            TextValue: null,
            LookupCodeFK: parseInt(subject.value),
            IsDeletedInd: false,
            RowNbr: null
        }

        Core.showLoader()

        const request = await fetch(
            `/${this._navigationCode}/CreateSchoolDesignation/`,
            {
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    PlanPK: this._planPK,
                    Grade: gradePlanProp,
                    Subject: subjectPlanProp,
                    MeasurableStudentGroupPropertyFKs: [...measurableStudentGroups].map(sg => sg.dataset.propertypk)
                })
            })

        if (request?.ok && await request.json()) this.reloadPageOnCreatingSchoolDesignation()

        Core.hideLoader()
    }

    private reloadPageOnCreatingSchoolDesignation() {
        const url: string = window.location.href
        const fromSave: string = '?fromSave=true'

        if (url.includes(fromSave)) url.replace(fromSave, '')
        
        this._saveHandler.sendXMLHttpRequest(
            JSON.stringify({
                PlanPK: this._planPK,
                PageCode: this._pageCode,
                PlanPropertyModels: this._saveHandler.getAllPlanPropertiesToSave()
            }),
            `/${this._navigationCode}/savecybercharterschoolrenewalapplicationpage/`,
            null,
            null,
            true            
        )

        window.location.href = window.location.href
    }

    private handleHasDesignation() {
        var hasDesignationRadioYesEl = document.querySelectorAll(".cybercharterschoolrenewalapplicationProgressTowardInitialGoalsRadioField[data-propertycode='cyberCharterProgressStateAssessmentComprehensiveSupport'][data-lookuplabel='Yes']:checked");
        var hasDesignation = hasDesignationRadioYesEl != null && hasDesignationRadioYesEl.length > 0
        var designationFieldsContainer = document.getElementById("designationFields");
        if(hasDesignation) {
            designationFieldsContainer.classList.remove("hide");
        } else {
            designationFieldsContainer.classList.add("hide");
        }

        const allElements: HTMLElement[] = []

        for (const validationClass of this._fieldset.ValidationClasses) {
            const elementsWithClass = document.querySelectorAll(`.${validationClass}:not([data-propertycode='cyberCharterProgressStateAssessmentComprehensiveSupport'])`) as NodeListOf<HTMLElement>
            allElements.push(...elementsWithClass)
        }

        for (const element of allElements) {
            if(hasDesignation) {
                if(element.closest('.shared-page-row')) {
                    element.closest('.shared-page-row').classList.remove('hide');
                }
                if(element.dataset.initiallyrequired === "true") {
                    this._validationHandler.forceElementRequired(element);
                }
            } else {
                if(element.closest('.shared-page-row')) {
                    element.closest('.shared-page-row').classList.add('hide');
                }
                if(element.dataset.initiallyrequired === "true") {
                    this._validationHandler.forceElementOptional(element);
                }
            }
        }

        if(hasDesignation) {
            var improvementPlan = document.querySelector("[data-propertycode='cyberCharterProgressStateAssessmentSubmittedImprovementPlan'][data-lookuplabel='No']") as HTMLInputElement;
            var improvementPlanExplanation = document.querySelector("[data-propertycode='cyberCharterProgressStateAssessmentSubmittedImprovementPlanExplanation']") as HTMLTextAreaElement;
            if(improvementPlan != null && improvementPlan.checked) {
                this._validationHandler.forceElementRequired(improvementPlanExplanation);
            } else {
                this._validationHandler.forceElementOptional(improvementPlanExplanation);
            }
        }
    }
}

class CyberCharterSchoolRenewalApplicationBenchmarksAndGoals extends CyberCharterSchoolRenewalApplicationPage { }

class CyberCharterSchoolRenewalApplicationEducationalPrograms extends CyberCharterSchoolRenewalApplicationPage { }

class CyberCharterSchoolRenewalApplicationSchoolOperationsAndManagemnet extends CyberCharterSchoolRenewalApplicationPage { }

class CyberCharterSchoolRenewalApplicationStaffEvaluationAndProfessionalDevelopment extends CyberCharterSchoolRenewalApplicationPage {
    constructor(
        navigationCode: string,
        pageCode: string,
        pageLabel: string,
        planPK: number,
        templateFK: number,
        allowDuplicates: boolean = false,
        customValidationFields: string[] = null
    ) {
        super(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates, customValidationFields)

        const label = document.getElementById('cyberCharterStaffEvaluationRetirementSystemLabel')
        label.innerHTML = `${label.innerHTML} <span class='required-label'>*</span>`
    }
}

class CyberCharterSchoolRenewalApplicationFinancialSolvency extends CyberCharterSchoolRenewalApplicationPage { }

class CyberCharterSchoolRenewalApplicationStudentServices extends CyberCharterSchoolRenewalApplicationPage { }

class CyberCharterSchoolRenewalApplicationSchoolGovernance extends CyberCharterSchoolRenewalApplicationPage { }

class CyberCharterSchoolRenewalApplicationOverallSchoolDesign extends CyberCharterSchoolRenewalApplicationPage { }

class CyberCharterSchoolRenewalApplicationCommunicationToParentsGuardiansAndCommunity extends CyberCharterSchoolRenewalApplicationPage { }

class CyberCharterSchoolRenewalApplicationStudentEnrollment extends CyberCharterSchoolRenewalApplicationPage {
    private _enrollementDeclineRadioButtons: NodeListOf<HTMLInputElement>
    private _enrollementVarianceRadioButtons: NodeListOf<HTMLInputElement>

    constructor(
        navigationCode: string,
        pageCode: string,
        pageLabel: string,
        planPK: number,
        templateFK: number,
        allowDuplicates: boolean = false,
        customValidationFields: string[] = null,
        useGenericValidation: boolean = false
    ) {
        super(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates, customValidationFields, useGenericValidation)
        this.setUpPage()

        if (this._fromSaveElement?.value === 'true') this.validateStudentEnrollment()
    }

    private setUpPage() {
        const label = document.getElementById('cyberCharterStudentEnrollmentDeclineWhatYears')
        label.innerHTML = `${label.innerHTML} <span class='required-label'>*</span>`

        // this._validationHandler.validate = this.validateStudentEnrollment

        this._enrollementDeclineRadioButtons = document.getElementsByName('cyberCharterStudentEnrollmentDeclineGreaterThan10PercentRadio') as NodeListOf<HTMLInputElement>
        this._enrollementVarianceRadioButtons = document.getElementsByName('cyberCharterStudentEnrollmentUnderOverEnrolledRadio') as NodeListOf<HTMLInputElement>

        for (const button of this._enrollementDeclineRadioButtons) {
            if (button.dataset.lookuplabel === 'Yes') {
                button.addEventListener('click', (e: Event) => this.toggleEnrollmentDeclineCheckboxVisibility(button, true))
                if (button.checked) {
                    this.toggleEnrollmentDeclineCheckboxVisibility(button, true)
                }
            } else if (button.dataset.lookuplabel === 'No') {
                button.addEventListener('click', (e: Event) => this.toggleEnrollmentDeclineCheckboxVisibility(button, false))
                if (button.checked) {
                    this.toggleEnrollmentDeclineCheckboxVisibility(button, false)                    
                }
            }
        }

        for (const button of this._enrollementVarianceRadioButtons) {
            if (button.dataset.lookuplabel === 'Yes') {
                button.addEventListener('click', (e: Event) => this.toggleEnrollmentVarianceTableVisibility(button, true))
                if (button.checked) {
                    this.toggleEnrollmentVarianceTableVisibility(button, true)
                }                
            } else if (button.dataset.lookuplabel === 'No') {
                button.addEventListener('click', (e: Event) => this.toggleEnrollmentVarianceTableVisibility(button, false))
                if (button.checked) {
                    this.toggleEnrollmentVarianceTableVisibility(button, false)
                }
            }
        }
    }

    private toggleEnrollmentDeclineCheckboxVisibility(button: HTMLInputElement, isYes: boolean) {
        const checkboxContainer = document.querySelector('.property-group[data-propertygroupcodepk="5029"]')
        if (button.checked && isYes) {
            checkboxContainer.classList.remove('display-none')
        } else if (button.checked && !isYes) {
            checkboxContainer.classList.add('display-none')
        }
    }

    private toggleEnrollmentVarianceTableVisibility(button: HTMLInputElement, isYes: boolean) {
        const tableContainer = document.querySelector('.shared-page-table-container[data-propertygroupcodepk="5032"]')
        if (button.checked && isYes) {
            tableContainer.classList.remove('display-none')
        } else if (button.checked && !isYes) {
            tableContainer.classList.add('display-none')
        }
    }

    public validateStudentEnrollment() {
        const skipElements: string[] = []    

        for (const button of this._enrollementVarianceRadioButtons) {
            if (button.dataset.lookuplabel === 'No' && button.checked) {
                skipElements.push('50262')
                skipElements.push('50263')
            }
        }

        for (const button of this._enrollementDeclineRadioButtons) {
            if (button.dataset.lookuplabel === 'No' && button.checked) {
                skipElements.push('50235')
                skipElements.push('50236')
                skipElements.push('50237')
                skipElements.push('50238')
                skipElements.push('50239')
            }
        }

        const errorCount = this._validationHandler.validateElements(skipElements)
        const showErrorMessage = !!errorCount

        const singularOrPlural = {
            toBe: errorCount === 1 ? 'is' : 'are',
            plural: errorCount === 1 ? '' : 's',
            first: errorCount === 1 ? '' : 'first'
        }

        const message = document.getElementById('validationMessage') as HTMLDivElement

        message.innerHTML = `<p class='validationErrorCountMessage'>There ${singularOrPlural.toBe
            } ${errorCount
            } issue${singularOrPlural.plural
            } to fix on this page.</p><a id='goToFirstError' href='javascript:void(0)'>Go to ${singularOrPlural.first
            } issue.</a>`

        const goToError = document.getElementById('goToFirstError')

        if (goToError) {
            const firstMissingElement = document.querySelector('.missing-field') as HTMLElement

            if (firstMissingElement) {
                goToError.addEventListener('click', (e: Event) => {
                    const accordion = Core.findClosest(firstMissingElement, '.Accordion-panel')

                    if (accordion) {
                        const id = accordion.getAttribute('aria-labelledby')
                        const accordionElement = document.getElementById(id) as HTMLButtonElement
                        accordionElement.click()
                    }
                    if(!firstMissingElement.hasAttribute("tabindex")) {
                        firstMissingElement.setAttribute("tabindex", "-1");
                    }
                    firstMissingElement.focus()
                })
            } else {
                goToError.parentNode.removeChild(goToError)
            }
        }

        const messageContainerColumn = document.getElementById('validationColumn') as HTMLElement
        const messageContainer = document.getElementById('validationMessageContainer') as HTMLElement
        messageContainerColumn.classList.add('show')
        const validationIcon = document.getElementById('validationMessageIcon') as HTMLElement

        setTimeout(() => messageContainer.focus(), 500)

        if (showErrorMessage) {
            const message = document.getElementById('validationMessage') as HTMLElement
            messageContainer.classList.add('warning')
            message.classList.add('show')
            validationIcon.innerHTML = `<i class='fas fa-exclamation-triangle'></i>`
        } else {
            messageContainer.classList.add('success')
            validationIcon.innerHTML = `<i class='fas fa-check-circle'></i>`

            const successMessage = document.getElementById('saveSuccess') as HTMLElement
            if (successMessage) successMessage.innerHTML = 'The page has been successfully saved.'
        }
    }
}

class CyberCharterSchoolRenewalApplicationPoliciesAndProcedures extends CyberCharterSchoolRenewalApplicationPage { }

class CyberCharterSchoolRenewalApplicationTechnologyAndSupport extends CyberCharterSchoolRenewalApplicationPage { }

class CyberCharterSchoolRenewalApplicationTruancyPolicies extends CyberCharterSchoolRenewalApplicationPage { }

class CyberCharterSchoolRenewalApplicationExtracurricularActivities extends CyberCharterSchoolRenewalApplicationPage { }

class CyberCharterSchoolRenewalApplicationSchoolSafety extends CyberCharterSchoolRenewalApplicationPage { }

class CyberCharterSchoolRenewalApplicationSignatureAndAssurances extends CyberCharterSchoolRenewalApplicationPage { 
    constructor(
        navigationCode: string,
        pageCode: string,
        pageLabel: string,
        planPK: number,
        templateFK: number,
        allowDuplicates: boolean = false,
        customValidationFields: string[] = null
    ) {
        super(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates, customValidationFields)
        let that = this;
        that._fileHandler.OnUpload = function(input: HTMLInputElement) {
            if(input.dataset.propertycode === "cyberCharterSignaturesAndAffirmationsUploadBoardAffirmationStatement") {
                that._saveHandler.save("save");
            }
        }
    }
}

class CyberCharterSchoolRenewalApplicationSummaryChecklistAndSubmission extends CyberCharterSchoolRenewalApplicationPage { 
    private _summaryChecklistAndSubmissionHandler: SummaryChecklistAndSubmissionHandler

    constructor(
        navigationCode: string,
        pageCode: string,
        pageLabel: string,
        planPK: number,
        templateFK: number,
        allowDuplicates: boolean = false
    ) {
        super(navigationCode, pageCode, pageLabel, planPK, templateFK)
        this._summaryChecklistAndSubmissionHandler = new SummaryChecklistAndSubmissionHandler(navigationCode)
    }
}
