/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />

// Profile Essentials
class CurriculumProfileEssentials {

    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["curriculumReportsProfileEssentialsField"];

        let curriculumProfileSaveButton = document.getElementById("curriculumReportsProfileSave");
        if (curriculumProfileSaveButton !== null)
            curriculumProfileSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        const refreshButton = document.getElementById("resetProfile");
        if (refreshButton !== null)
            refreshButton.addEventListener("click", (e: Event) => Core.refreshProfile("curriculumReportsProfileEssentialsForm"));
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];

        let planPK = 0;

        let profileFormElement = <HTMLDivElement>document.getElementById("curriculumReportsProfileEssentialsForm");
        let inputs = document.getElementsByClassName("curriculumReportsProfileEssentialsField");

        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CurriculumInstructionAssessmentReports/SaveProfileEssentials', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

// Academic Standards and Planning
class CurriculumAcademicStandardsAndPlanning {

    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["curriculumReportsAcademicStandardsField"];

        let curriculumStandardsSaveButton = document.getElementById("curriculumReportsAcademicStandardsAndPlanningSave");
        if (curriculumStandardsSaveButton !== null)
            curriculumStandardsSaveButton.addEventListener("click", (e: Event) => this.save("save"));
                      
        let curriculumStandardsExportToWordButton = document.getElementById("curriculumReportsAcademicStandardsAndPlanningExportToWord");
        if (curriculumStandardsExportToWordButton !== null)
            curriculumStandardsExportToWordButton.addEventListener("click", (e: Event) => this.exportToWord());
                      
        let curriculumStandardsExportToExcelButton = document.getElementById("curriculumReportsAcademicStandardsAndPlanningExportToExcel");
        if (curriculumStandardsExportToExcelButton !== null)
            curriculumStandardsExportToExcelButton.addEventListener("click", (e: Event) => this.exportToExcel());
        
        //let continueButton = document.getElementById("continueButton");
        //if (continueButton !== null)
        //    continueButton.addEventListener("click", (e: Event) => this._core.continue(e));

        //let backButton = document.getElementById("backButton");
        //if (backButton !== null)
        //    backButton.addEventListener("click", (e: Event) => this._core.back(e));
        this._core.leftnav(this);

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }

        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let checkboxes = document.getElementsByClassName("curriculumReportsAcademicStandardsCheckbox");
        for (let checkbox of checkboxes)
            checkbox.addEventListener("click", (e: Event) => this.checkForReadonly(e));
    }

    checkForReadonly(e: Event) {
        let checkbox = <HTMLInputElement>e.srcElement;
        if (checkbox.readOnly) {
            if (checkbox.checked)
                checkbox.checked = false;
            else
                checkbox.checked = true;

            return false;
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    exportToWord() {

    }

    exportToExcel() {

    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];

        let planPK = 0;

        let standardsFormElement = <HTMLDivElement>document.getElementById("curriculumReportsAcademicStandardsAndPlanningForm");
        let inputs = document.getElementsByClassName("curriculumReportsAcademicStandardsField");

        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(standardsFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.checked) {
                if (element.value !== "" || hadValue) {
                    let saveItem: IPlanProperty = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: element.value,
                        LookupCodeFK: null,
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };

                    allSaveElements.push(saveItem);
                }
            } else {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: "",
                    LookupCodeFK: parseInt(element.dataset.lookupcodepk),
                    RowNbr: rowNumber,
                    IsDeletedInd: true
                };
                allSaveElements.push(saveItem);
            }

        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CurriculumInstructionAssessmentReports/SaveAcademicStandardsAndPlanning', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                //TODO: come back to this when we have client-side validation for checkboxes
                //core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

// Assurances Standards Alignment
class CurriculumAssurancesStandardsAlignment {

    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = [
            "assurancesStandardsAlignmentRadioField",
            "assurancesStandardsAlignmentField",
            "assurancesStandardsAlignmentFileField"
        ];

        let curriculumStandardsAlignmentSaveButton = document.getElementById("curriculumReportsAssurancesStandardsAlignmentSave");
        if (curriculumStandardsAlignmentSaveButton !== null)
            curriculumStandardsAlignmentSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        //let continueButton = document.getElementById("continueButton");
        //if (continueButton !== null)
        //    continueButton.addEventListener("click", (e: Event) => this._core.continue(e));

        //let backButton = document.getElementById("backButton");
        //if (backButton !== null)
        //    backButton.addEventListener("click", (e: Event) => this._core.back(e));
        this._core.leftnav(this);

        let standardsAlignmentRadioButtons = document.querySelectorAll(".assurancesStandardsAlignmentRadioField:not([data-propertycode='curriculumReportsStandardsLEAStandardized']):not([data-propertycode='curriculumReportsStandardsIUStandardized'])");
        for (let standardsAlignmentRadioButton of standardsAlignmentRadioButtons) {
            standardsAlignmentRadioButton.addEventListener("change", (e: Event) => this.noReasonShowOrHide(<HTMLInputElement>e.srcElement));
            this.noReasonShowOrHide(<HTMLInputElement>standardsAlignmentRadioButton);
        }

        let standardizedFormatRadioButtons = document.querySelectorAll(".assurancesStandardsAlignmentRadioField[data-propertycode='curriculumReportsStandardsLEAStandardized'], .assurancesStandardsAlignmentRadioField[data-propertycode='curriculumReportsStandardsIUStandardized']");
        for (let standardizedFormatRadioButton of standardizedFormatRadioButtons) {
            standardizedFormatRadioButton.addEventListener("change", (e: Event) => this.standardizedFormatShowOrHide(<HTMLInputElement>e.srcElement));
            this.standardizedFormatShowOrHide(<HTMLInputElement>standardizedFormatRadioButton);
        }

        let fileUploader = document.getElementById("uploadSampleFile");
        if (fileUploader !== null) {
            fileUploader.addEventListener("change", (e: Event) => this.uploadFile(<HTMLInputElement>e.srcElement));
        }

        let deleteFileButtons = document.getElementsByClassName("deleteFile") as HTMLCollectionOf<HTMLButtonElement>;
        for (let deleteButton of deleteFileButtons) {
            if ("planpropertyfilepk" in deleteButton.dataset && parseInt(deleteButton.dataset.planpropertyfilepk) > 0) {
                deleteButton.addEventListener("click", (e: Event) => this.showDeleteFileConfirmation(deleteButton));
            }
        }

        let deleteFileConfirmButton = <HTMLButtonElement>document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e: Event) => this.deleteFileConfirm(deleteFileConfirmButton));

        let deleteFileCancelButton = <HTMLButtonElement>document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e: Event) => this.deleteFileCancel());

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    uploadFile(e: HTMLInputElement) {
        let core = this._core;
        Core.showLoader();
        let uploadSampleForm = <HTMLFormElement>document.getElementById("uploadSample");
        let formData = new FormData(uploadSampleForm);

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CurriculumInstructionAssessmentReports/UploadFile', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file has been successfully uploaded! Save your progress to see the updated list of files.", 'success', 3000, null);

                        let formFile = <HTMLInputElement>document.getElementById("uploadSampleFile");
                        formFile.value = "";
                        formFile.dataset.hasUploaded = "true";
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }

    showDeleteFileConfirmation(deleteButton: HTMLButtonElement) {
        let planPropertyFilePK = deleteButton.dataset.planpropertyfilepk;

        if (planPropertyFilePK && parseInt(planPropertyFilePK) > 0) {
            let modal: Modal = new Modal("deleteFileModal", null);
            let deleteConfirmButton = <HTMLButtonElement>document.getElementById("deleteFileConfirm");
            deleteConfirmButton.dataset.planpropertyfilepk = planPropertyFilePK;
            modal.show();
        }
    }

    deleteFileConfirm(confirmButton: HTMLButtonElement) {
        let core = this._core;
        Core.showLoader();

        let planPropertyFilePK = confirmButton.dataset.planpropertyfilepk;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CurriculumInstructionAssessmentReports/DeleteFiles', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file has been successfully deleted.", 'success', 3000, null);

                        let containerElement = <HTMLElement>document.querySelector(`.uploadFileColumn[data-planpropertyfilepk='${planPropertyFilePK}']`);
                        if (containerElement !== null) {
                            containerElement.parentNode.removeChild(containerElement);
                        }

                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting file.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file removal failed. Please try again.", 'error', 3000, null);
            }

            let modal: Modal = new Modal("deleteFileModal", null);
            modal.hide();
        };
        xhr.send(JSON.stringify([planPropertyFilePK]));
    }

    deleteFileCancel() {
        let modal: Modal = new Modal("deleteFileModal", null);
        modal.hide();
    }

    noReasonShowOrHide(e: HTMLInputElement) {
        let thisRadio = <HTMLInputElement>e;
        if (thisRadio.checked) {
            let name = thisRadio.name;

            let trToShowOrHide = document.querySelector(`tr[data-name='${name}']`);

            if (trToShowOrHide !== null) {
                let hiddenField = trToShowOrHide.querySelector(".assurancesStandardsAlignmentField") as HTMLTextAreaElement;

                if (thisRadio.dataset.lookupcode === "yes") {
                    trToShowOrHide.classList.add("hide");
                    if (hiddenField != null) {
                        this._core.forceElementOptional(hiddenField);
                    }
                } else {
                    trToShowOrHide.classList.remove("hide");
                    if (hiddenField != null) {
                        this._core.forceElementRequired(hiddenField);
                    }
                }
            }
        }
    }

    standardizedFormatShowOrHide(e: HTMLInputElement) {
        let thisRadio = <HTMLInputElement>e;
        if (thisRadio.checked) {
            let elementToShowOrHide = document.querySelector(`div[data-name='curriculumReportsStandardsLocallyDevelopedSample'], div[data-name='curriculumReportsStandardsLocallyDevelopedSampleIU']`);

            if (elementToShowOrHide !== null) {
                let hiddenField = document.getElementById("uploadSampleFile") as HTMLInputElement;

                if (thisRadio.dataset.lookupcode === "no") {
                    elementToShowOrHide.classList.add("hide");
                    if (hiddenField != null) {
                        this._core.forceElementOptional(hiddenField);
                    }
                } else {
                    elementToShowOrHide.classList.remove("hide");
                    if (hiddenField != null) {
                        this._core.forceElementRequired(hiddenField);
                    }
                }
            }
        }
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];

        let planPK = 0;
        let profileFormElement = <HTMLDivElement>document.getElementById("curriculumReportsAssurancesStandardsAlignmentForm");
        let inputs = document.getElementsByClassName("assurancesStandardsAlignmentField");
        let radios = document.getElementsByClassName("assurancesStandardsAlignmentRadioField");

        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        for (let ele of radios) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "",
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CurriculumInstructionAssessmentReports/SaveAssurancesStandardsAlignment', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

// AssurancesCurriculumAndInstruction
class CurriculumAssurancesCurriculumAndInstruction {

    validationClasses: string[];

    private _core: Core;
    constructor() {
        let that = this;
        this._core = new Core();

        this.validationClasses = [
            "curriculumReportsAssurancesCurriculumAndInstructionField",
            "curriculumReportsAssurancesCurriculumAndInstructionRadioField",
            "curriculumReportsAssurancesCurriculumAndInstructionMultiSelectField",
            "curriculumReportsAssurancesCurriculumAndInstructionSelectField"
        ];

        let curriculumCurriculumAndInstructionSaveButton = document.getElementById("curriculumReportsAssurancesCurriculumAndInstructionSave");
        if (curriculumCurriculumAndInstructionSaveButton !== null)
            curriculumCurriculumAndInstructionSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        //let continueButton = document.getElementById("continueButton");
        //if (continueButton !== null)
        //    continueButton.addEventListener("click", (e: Event) => this._core.continue(e));

        //let backButton = document.getElementById("backButton");
        //if (backButton !== null)
        //    backButton.addEventListener("click", (e: Event) => this._core.back(e));
        this._core.leftnav(this);

        let instructionalRadioButtons = document.getElementsByClassName("curriculumReportsAssurancesCurriculumAndInstructionRadioField");
        for (let instructionalRadioButton of instructionalRadioButtons) {
            instructionalRadioButton.addEventListener("change", (e: Event) => this.noReasonShowOrHide(<HTMLInputElement>e.srcElement));
            this.noReasonShowOrHide(<HTMLInputElement>instructionalRadioButton);
        }

        let sloCheckbox = document.getElementById("omitSLOCheckbox") as HTMLInputElement;
        let fieldToToggle = document.querySelector(".sloInform[data-required]") as HTMLTextAreaElement;
        if (sloCheckbox != null && fieldToToggle != null) {
            if (sloCheckbox.checked) {
                that._core.forceElementOptional(fieldToToggle);
            } else {
                that._core.forceElementRequired(fieldToToggle);
                fieldToToggle.readOnly = false;
            }
            sloCheckbox.addEventListener("change", () => {
                if (sloCheckbox.checked) {
                    that._core.forceElementOptional(fieldToToggle);
                    fieldToToggle.readOnly = true;
                } else {
                    that._core.forceElementRequired(fieldToToggle);
                    fieldToToggle.readOnly = false;
                }
            });
        }

        let charterAct13OmitCheckbox = document.getElementById("curriculumReportsInstructionCharterOmitId") as HTMLInputElement;
        let act13FieldsToToggle = document.querySelectorAll(".act13Field[data-required]");
        if (charterAct13OmitCheckbox != null && act13FieldsToToggle != null) {
            if (charterAct13OmitCheckbox.checked) {
                for (let act13FieldToToggle of act13FieldsToToggle) {
                    let act13FieldToToggleEle = <HTMLElement>act13FieldToToggle;
                    that._core.forceElementOptional(act13FieldToToggleEle);
                    if (act13FieldToToggleEle instanceof HTMLInputElement) {
                        let Act13FieldToToggleEle = <HTMLInputElement>act13FieldToToggleEle;
                        Act13FieldToToggleEle.readOnly = true;
                    } else if (act13FieldToToggleEle instanceof HTMLSelectElement) {
                        let Act13FieldToToggleSelectElement = <HTMLSelectElement>act13FieldToToggleEle;
                        Act13FieldToToggleSelectElement.disabled = true;
                    } else if (act13FieldToToggleEle instanceof HTMLTextAreaElement) {
                        let Act13FieldToToggleTextAreaElement = <HTMLTextAreaElement>act13FieldToToggleEle;
                        Act13FieldToToggleTextAreaElement.readOnly = true;
                    }
                }
            } else {
                for (let act13FieldToToggle of act13FieldsToToggle) {
                    let act13FieldToToggleEle = <HTMLElement>act13FieldToToggle;
                    that._core.forceElementRequired(act13FieldToToggleEle);
                    if (act13FieldToToggleEle instanceof HTMLInputElement) {
                        let Act13FieldToToggleEle = <HTMLInputElement>act13FieldToToggleEle;
                        Act13FieldToToggleEle.readOnly = false;
                    } else if (act13FieldToToggleEle instanceof HTMLSelectElement) {
                        let Act13FieldToToggleSelectElement = <HTMLSelectElement>act13FieldToToggleEle;
                        Act13FieldToToggleSelectElement.disabled = false;
                    } else if (act13FieldToToggleEle instanceof HTMLTextAreaElement) {
                        let Act13FieldToToggleTextAreaElement = <HTMLTextAreaElement>act13FieldToToggleEle;
                        Act13FieldToToggleTextAreaElement.readOnly = false;
                    }
                }
            }
            charterAct13OmitCheckbox.addEventListener("change", () => {
                if (charterAct13OmitCheckbox.checked) {
                    for (let act13FieldToToggle of act13FieldsToToggle) {
                        let act13FieldToToggleEle = <HTMLElement>act13FieldToToggle;
                        that._core.forceElementOptional(act13FieldToToggleEle);
                        if (act13FieldToToggleEle instanceof HTMLInputElement) {
                            let Act13FieldToToggleEle = <HTMLInputElement>act13FieldToToggleEle;
                            Act13FieldToToggleEle.readOnly = true;
                        } else if (act13FieldToToggleEle instanceof HTMLSelectElement) {
                            let Act13FieldToToggleSelectElement = <HTMLSelectElement>act13FieldToToggleEle;
                            Act13FieldToToggleSelectElement.disabled = true;
                        } else if (act13FieldToToggleEle instanceof HTMLTextAreaElement) {
                            let Act13FieldToToggleTextAreaElement = <HTMLTextAreaElement>act13FieldToToggleEle;
                            Act13FieldToToggleTextAreaElement.readOnly = true;
                        }
                    }
                } else {
                    for (let act13FieldToToggle of act13FieldsToToggle) {
                        let act13FieldToToggleEle = <HTMLElement>act13FieldToToggle;
                        that._core.forceElementRequired(act13FieldToToggleEle);
                        if (act13FieldToToggleEle instanceof HTMLInputElement) {
                            let Act13FieldToToggleEle = <HTMLInputElement>act13FieldToToggleEle;
                            Act13FieldToToggleEle.readOnly = false;
                        } else if (act13FieldToToggleEle instanceof HTMLSelectElement) {
                            let Act13FieldToToggleSelectElement = <HTMLSelectElement>act13FieldToToggleEle;
                            Act13FieldToToggleSelectElement.disabled = false;
                        } else if (act13FieldToToggleEle instanceof HTMLTextAreaElement) {
                            let Act13FieldToToggleTextAreaElement = <HTMLTextAreaElement>act13FieldToToggleEle;
                            Act13FieldToToggleTextAreaElement.readOnly = false;
                        }
                    }
                }
            });
        }

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }

        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let checkboxes = document.getElementsByClassName("commonCIACheckbox");
        for (let checkbox of checkboxes)
            checkbox.addEventListener("click", (e: Event) => this.checkForReadonly(e));

        let morecheckboxes = document.getElementsByClassName("curriculumReportsAssurancesCurriculumAndInstructionCheckboxField");
        for (let morecheckbox of morecheckboxes)
            morecheckbox.addEventListener("click", (e: Event) => this.checkForReadonly(e));

        let ciaNotIUExplainCheckboxElement = document.getElementById("curriculumReportsInstructionNoIUId");
        if (ciaNotIUExplainCheckboxElement !== null)
            ciaNotIUExplainCheckboxElement.addEventListener("change", (e: Event) => this.IUExplainShowHide(e));

        let percentageOfEducatorsEvaluatedAct13TextBoxes = document.querySelectorAll('input.curriculumReportsAssurancesCurriculumAndInstructionField.number-box.act13Field') as NodeListOf<HTMLInputElement>;
        let totalInput = document.querySelector('input.number-box-total') as HTMLInputElement;
        this.totalPercentageOfEducatorsEvaluatedAct13(percentageOfEducatorsEvaluatedAct13TextBoxes, totalInput);
        for (let percentageOfEducatorsEvaluatedAct13TextBox of percentageOfEducatorsEvaluatedAct13TextBoxes) {
            percentageOfEducatorsEvaluatedAct13TextBox.addEventListener("input", (e: Event) => this.totalPercentageOfEducatorsEvaluatedAct13(percentageOfEducatorsEvaluatedAct13TextBoxes, totalInput));
        }
    }

    checkForReadonly(e: Event) {
        let checkbox = <HTMLInputElement>e.srcElement;

        if (checkbox.readOnly) {
            if (checkbox.checked)
                checkbox.checked = false;
            else
                checkbox.checked = true;

            return false;
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    noReasonShowOrHide(e: HTMLInputElement) {
        let thisRadio = <HTMLInputElement>e;
        if (thisRadio.checked) {
            let name = thisRadio.name;

            let trToShowOrHide = document.querySelector(`tr[data-name='${name}']`);

            if (trToShowOrHide !== null) {
                let hiddenField = trToShowOrHide.querySelector(".curriculumReportsAssurancesCurriculumAndInstructionField") as HTMLTextAreaElement;

                if (thisRadio.dataset.lookupcode === "yes") {
                    trToShowOrHide.classList.add("hide");
                    if (hiddenField != null) {
                        this._core.forceElementOptional(hiddenField);
                    }
                } else {
                    trToShowOrHide.classList.remove("hide");
                    if (hiddenField != null) {
                        this._core.forceElementRequired(hiddenField);
                    }
                }
            }
        }
    }

    totalPercentageOfEducatorsEvaluatedAct13(inputsInColumn: NodeListOf<HTMLInputElement>, totalInput: HTMLInputElement) {
        let total = 0

        for (const input of inputsInColumn) {
            if (input.value && !isNaN(parseInt(input.value))) total += parseInt(input.value)
        }

        totalInput.value = total.toString()
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];

        let planPK = 0;

        let profileFormElement = <HTMLDivElement>document.getElementById("curriculumReportsAssurancesCurriculumAndInstructionForm");

        let inputs = document.getElementsByClassName("curriculumReportsAssurancesCurriculumAndInstructionField");
        let radios = document.getElementsByClassName("curriculumReportsAssurancesCurriculumAndInstructionRadioField");
        let multis = document.getElementsByClassName("curriculumReportsAssurancesCurriculumAndInstructionMultiSelectField");
        let selects = document.getElementsByClassName("curriculumReportsAssurancesCurriculumAndInstructionSelectField");
        let checkboxInputs = document.getElementsByClassName("curriculumReportsAssurancesCurriculumAndInstructionCheckboxField");

        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let value = element.value;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.id == "omitSLOCheckbox") {
                if (element.checked) {
                    value = "on";
                } else {
                    value = "off";
                }
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        for (let ele of selects) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: null,
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        for (let ele of radios) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "",
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }

        for (let parent of multis) {
            let parentElement = <HTMLDivElement>parent;
            let parentPropertyPK = parentElement.dataset.propertypk;
            let innerElements = parentElement.querySelectorAll(`input[data-propertypk='${parentPropertyPK}']`);

            for (let ele of innerElements) {
                let planPropertyPK = 0;
                let element = <HTMLInputElement>ele;
                let rowNumber = 0;
                let propertyPK = parseInt(element.dataset.propertypk);
                let hadValue = false;
                if (element.checked) {
                    if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                        planPropertyPK = parseInt(element.dataset.planpropertypk);
                        hadValue = true;
                    }
                    if (element.value !== "" || hadValue) {
                        let saveItem = {
                            PlanPropertyPK: planPropertyPK,
                            PlanFK: planPK,
                            PropertyFK: propertyPK,
                            TextValue: "",
                            LookupCodeFK: parseInt(element.dataset.lookupcodepk),
                            RowNbr: rowNumber,
                            IsDeletedInd: false
                        };
                        allSaveElements.push(saveItem);
                    }
                } else {
                    if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                        planPropertyPK = parseInt(element.dataset.planpropertypk);
                        let saveItem = {
                            PlanPropertyPK: planPropertyPK,
                            PlanFK: planPK,
                            PropertyFK: propertyPK,
                            TextValue: "",
                            LookupCodeFK: parseInt(element.dataset.lookupcodepk),
                            RowNbr: rowNumber,
                            IsDeletedInd: true
                        };
                        allSaveElements.push(saveItem);
                    }
                }
            }
        }

        for (let ele of checkboxInputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);
            let element = <HTMLInputElement>ele;
            let rowNumber = 0;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.checked || hadValue) {
                let val = "";
                if (element.checked)
                    val = "on";
                else
                    val = "off";
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: rowNumber,
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CurriculumInstructionAssessmentReports/SaveAssurancesCurriculumAndInstruction', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    IUExplainShowHide(e: Event) {
        let element = <HTMLInputElement>e.srcElement;

        let divElement = <HTMLDivElement>document.getElementById("ciaNotIUExplain");
        let textElement = document.getElementById("curriculumReportsInstructionNoIUExplainId");

        if (element.checked) {
            divElement.classList.remove("hide");

            this._core.forceElementRequired(textElement);
        } else {
            divElement.classList.add("hide");

            this._core.forceElementOptional(textElement);
        }
    }
}

// Assessment
class CurriculumAssessment {

    validationClasses: string[];
    private deleteAssessmentModal: Modal;
    private _core: Core;

    constructor() {
        this._core = new Core();
        this.deleteAssessmentModal = new Modal("deleteAssessmentModal", null);
        this.validationClasses = [
            "curriculumReportsAssessmentField",
            "curriculumReportsAssessmentRadioField",
            "curriculumReportsAssessmentSelectField",
            "curriculumReportsAssessmentFileUploadField"
        ];

        let curriculumAssessmentSaveButton = document.getElementById("curriculumReportsAssessmentSave");
        if (curriculumAssessmentSaveButton !== null)
            curriculumAssessmentSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);

        let addAnAssessmentButton = document.getElementById("addAnAssessment");
        if (addAnAssessmentButton !== null)
            addAnAssessmentButton.addEventListener("click", (e: Event) => this.addAnAssessment(e));

        let fileUploader3rdGrade = document.getElementById("earlycurriculumReportsAssessmentGrade3Document");
        if (fileUploader3rdGrade !== null) {
            fileUploader3rdGrade.addEventListener("change", (e: Event) => this.uploadFile(<HTMLInputElement>e.srcElement, "UploadGrade3Document", "earlycurriculumReportsAssessmentGrade3Document"));
        }

        let fileUploader7thGrade = document.getElementById("earlycurriculumReportsAssessmentGrade7Document");
        if (fileUploader7thGrade !== null) {
            fileUploader7thGrade.addEventListener("change", (e: Event) => this.uploadFile(<HTMLInputElement>e.srcElement, "UploadGrade7Document", "earlycurriculumReportsAssessmentGrade7Document"));
        }

        let deleteFileButtons = document.getElementsByClassName("deleteFile") as HTMLCollectionOf<HTMLButtonElement>;
        for (let deleteButton of deleteFileButtons) {
            if ("planpropertyfilepk" in deleteButton.dataset && parseInt(deleteButton.dataset.planpropertyfilepk) > 0) {
                deleteButton.addEventListener("click", (e: Event) => this.showDeleteFileConfirmation(deleteButton));
            }
        }

        let deleteFileConfirmButton = <HTMLButtonElement>document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e: Event) => this.deleteFileConfirm(deleteFileConfirmButton));

        let deleteFileCancelButton = <HTMLButtonElement>document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e: Event) => this.deleteFileCancel());

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }

        let that = this;
        document.addEventListener("click", function (e) {
            let buttonElement = <HTMLElement>event.target;
            if (buttonElement.classList.contains("deleteAssessment") && buttonElement instanceof HTMLButtonElement) {
                e.preventDefault();
                let button = <HTMLButtonElement>buttonElement;
                if ("hasdata" in button.dataset && button.dataset.hasdata == "True") {
                    that.deleteAssessmentModal.show();
                    that.deleteAssessmentModal.addAttributeToElement(that.deleteAssessmentModal.id, "#deleteAssessmentConfirm", "row", button.dataset.row);
                } else {
                    //remove from DOM
                    let container = document.getElementById(button.dataset.container);
                    if ("container" in button.dataset && document.getElementById(button.dataset.container) != null) {
                        let container = document.getElementById(button.dataset.container);
                        container.parentNode.removeChild(container);
                        Core.createHTMLAlert("alertMessageDiv", "Successfully removed assessment row", 'success', 3000, null);
                    } else {
                        Core.createHTMLAlert("alertMessageDiv", "There was an error removing assessment row", 'error', 3000, null);
                    }
                }
            };
        });

        let deleteAssessmentConfirmButton = document.getElementById("deleteAssessmentConfirm");
        if (deleteAssessmentConfirmButton !== null) {
            deleteAssessmentConfirmButton.addEventListener("click", function (e) {
                e.preventDefault();
                if (deleteAssessmentConfirmButton.dataset.row) {
                    that.deleteAssessment(deleteAssessmentConfirmButton.dataset.row);
                } else {
                    that.deleteAssessmentModal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this assessment", "error", 3000, null);
                }
            });
        }

        let deleteAssessmentCancelButton = document.getElementById("deleteAssessmentCancel");
        if (deleteAssessmentCancelButton !== null) {
            deleteAssessmentCancelButton.addEventListener("click", function (e) {
                e.preventDefault();
                that.deleteAssessmentModal.hide();
            });
        }

        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    uploadFile(e: HTMLInputElement, formElementId, fileElementId) {
        let core = this._core;
        Core.showLoader();
        let uploadSampleForm = <HTMLFormElement>document.getElementById(formElementId);
        let formData = new FormData(uploadSampleForm);

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CurriculumInstructionAssessmentReports/UploadFile', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file has been successfully uploaded! Save your progress to see the updated list of files.", 'success', 3000, null);

                        let formFile = <HTMLInputElement>document.getElementById(fileElementId);
                        formFile.value = "";
                        formFile.dataset.hasuploaded = "true";
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }

    showDeleteFileConfirmation(deleteButton: HTMLButtonElement) {
        let planPropertyFilePK = deleteButton.dataset.planpropertyfilepk;

        if (planPropertyFilePK && parseInt(planPropertyFilePK) > 0) {
            let modal: Modal = new Modal("deleteFileModal", null);
            let deleteConfirmButton = <HTMLButtonElement>document.getElementById("deleteFileConfirm");
            deleteConfirmButton.dataset.planpropertyfilepk = planPropertyFilePK;
            modal.show();
        }
    }

    deleteFileConfirm(confirmButton: HTMLButtonElement) {
        let core = this._core;
        Core.showLoader();

        let planPropertyFilePK = confirmButton.dataset.planpropertyfilepk;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CurriculumInstructionAssessmentReports/DeleteFiles', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file has been successfully deleted.", 'success', 3000, null);

                        let containerElement = <HTMLElement>document.querySelector(`.uploadFileColumn[data-planpropertyfilepk='${planPropertyFilePK}']`);
                        if (containerElement !== null) {
                            containerElement.parentNode.removeChild(containerElement);
                        }

                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting file.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file removal failed. Please try again.", 'error', 3000, null);
            }

            let modal: Modal = new Modal("deleteFileModal", null);
            modal.hide();
        };
        xhr.send(JSON.stringify([planPropertyFilePK]));
    }

    deleteFileCancel() {
        let modal: Modal = new Modal("deleteFileModal", null);
        modal.hide();
    }

    addAnAssessment(e: Event) {
        let core = this._core;
        let newRow: number = 0;
        let _that = this;

        let profileFormElement = <HTMLDivElement>document.getElementById("curriculumReportsAssessmentForm");
        let planPK = parseInt(profileFormElement.dataset.planfk);

        let assessmentFields = document.getElementsByClassName("curriculumReportsAssessmentFieldRow");
        for (let assmt of assessmentFields) {
            let assmtElement = <HTMLInputElement>assmt;
            if (newRow < parseInt(assmtElement.dataset.row))
                newRow = parseInt(assmtElement.dataset.row);
        }

        newRow++;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CurriculumInstructionAssessmentReports/AddAnAssessment', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                $("#assessmentAssessmentList").append(xhr.responseText);
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Assessment Added", 'success', 3000, null);
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("newRow=" + newRow + "&planFK=" + planPK);
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];

        let planPK = 0;

        let profileFormElement = <HTMLDivElement>document.getElementById("curriculumReportsAssessmentForm");
        let inputs = document.getElementsByClassName("curriculumReportsAssessmentField");
        let radios = document.getElementsByClassName("curriculumReportsAssessmentRadioField");
        let selects = document.getElementsByClassName("curriculumReportsAssessmentSelectField");

        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = "0";
            if (element.dataset.row !== null)
                rowNumber = element.dataset.row;

            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        for (let ele of radios) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = "0";
            if (element.dataset.row !== null)
                rowNumber = element.dataset.row;

            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "",
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }

        for (let ele of selects) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = "0";
            if (element.dataset.row !== null)
                rowNumber = element.dataset.row;

            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: null,
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CurriculumInstructionAssessmentReports/SaveAssessment', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    deleteAssessment(rowNumber: string) {
        let that = this;
        that.deleteAssessmentModal.hide();

        let planPK = 0;

        let formElement = <HTMLDivElement>document.getElementById("curriculumReportsAssessmentForm");
        if (formElement != null) {
            planPK = parseInt(formElement.dataset.planfk);
        }

        let deleteAssessmentButton = document.querySelector(`.deleteAssessment[data-row='${rowNumber}']`) as HTMLButtonElement;
        if (deleteAssessmentButton != null && "container" in deleteAssessmentButton.dataset && document.getElementById(deleteAssessmentButton.dataset.container) != null) {

            let toRemove = [];

            let assessmentInputFields = document.querySelectorAll(`.assessment-list-item[data-row='${rowNumber}'] .curriculumReportsAssessmentField[data-row='${rowNumber}']`);
            let assessmentRadioFields = document.querySelectorAll(`.assessment-list-item[data-row='${rowNumber}'] .curriculumReportsAssessmentRadioField[data-row='${rowNumber}']`);
            let assessmentSelectFields = document.querySelectorAll(`.assessment-list-item[data-row='${rowNumber}'] .curriculumReportsAssessmentSelectField[data-row='${rowNumber}']`);

            for (let ele of assessmentInputFields) {
                let planPropertyPK = 0;

                let element = <HTMLInputElement>ele;
                let rowNumber = "0";
                if (element.dataset.row !== null)
                    rowNumber = element.dataset.row;

                let propertyPK = parseInt(element.dataset.propertypk);

                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                }

                let removeItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: true
                };

                toRemove.push(removeItem);
            }

            for (let ele of assessmentRadioFields) {
                let planPropertyPK = 0;
                let element = <HTMLInputElement>ele;
                let rowNumber = "0";
                if (element.dataset.row !== null)
                    rowNumber = element.dataset.row;

                let propertyPK = parseInt(element.dataset.propertypk);

                if (element.checked) {

                    if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                        planPropertyPK = parseInt(element.dataset.planpropertypk);
                    }

                    let removeItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "",
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: true
                    };
                    toRemove.push(removeItem);
                }
            }

            for (let ele of assessmentSelectFields) {
                let planPropertyPK = 0;

                let element = <HTMLInputElement>ele;
                let rowNumber = "0";
                if (element.dataset.row !== null)
                    rowNumber = element.dataset.row;

                let propertyPK = parseInt(element.dataset.propertypk);

                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                }

                let removeItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: null,
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: true
                };

                toRemove.push(removeItem);
            }

            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/CurriculumInstructionAssessmentReports/SaveAssessment', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                    Core.createHTMLAlert("alertMessageDiv", "Assessment successfully deleted", 'success', 3000, null);

                    //remove from DOM
                    let container = document.getElementById(deleteAssessmentButton.dataset.container);
                    container.parentNode.removeChild(container);

                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this activity. Please try again later.", "error", 3000, null);
                }
                Core.hideLoader();
            };
            xhr.send(JSON.stringify(toRemove));
        } else {
            Core.createHTMLAlert("alertMessageDiv", "There was an issue deleting assessment. Please try again later.", "error", 3000, null);
        }
    }
}

// Signature and Submit
class CurriculumSignatureAndSubmit {

    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["curriculumReportsSignatureAndSubmitField"];

        let curriculumSignatureAndSubmitSaveButton = document.getElementById("curriculumReportsSignatureAndSubmitSave");
        if (curriculumSignatureAndSubmitSaveButton !== null)
            curriculumSignatureAndSubmitSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        //let continueButton = document.getElementById("continueButton");
        //if (continueButton !== null)
        //    continueButton.addEventListener("click", (e: Event) => this._core.continue(e));

        //let backButton = document.getElementById("backButton");
        //if (backButton !== null)
        //    backButton.addEventListener("click", (e: Event) => this._core.back(e));
        this._core.leftnav(this);

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];

        let planPK = 0;

        let profileFormElement = <HTMLDivElement>document.getElementById("curriculumReportsSignatureAndSubmitForm");
        let inputs = document.getElementsByClassName("curriculumReportsSignatureAndSubmitField");

        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CurriculumInstructionAssessmentReports/SaveSignatureAndSubmit', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

//Curriculum Summary Checklist and Submission
class CurriculumSummaryChecklistAndSubmission {
    private _core: Core;
    constructor() {
        this._core = new Core();
        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.back(e));

        let submitButton = document.getElementById("submitPlanButton");
        if (submitButton !== null)
            submitButton.addEventListener("click", (e: Event) => this.submit(e));

        this._core.leftnav(this);
    }

    back(e: Event) {
        let newPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }

    getCore() {
        return this._core;
    }

    submit(e: Event) {
        let element = <HTMLButtonElement>e.srcElement;
        if (!element.classList.contains("disabled")) {
            let planFK = 0;
            planFK = parseInt(element.dataset.planfk);
            let core = this._core;
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/CurriculumInstructionAssessmentReports/SubmitPlan', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "This report has been submitted for review!", 'success', 3000, window.location.reload);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status + "'", 'error', 3000, null);
                }
            };
            xhr.send("planFK=" + planFK);
        }
    }
}

// CurriculumReportsV3StandardsAssess
class CurriculumReportsV3StandardsAssess {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.iuOriginalPercent();

        this.checkForCTCorIU();
        this.checkIUNotOffering();

        this.validationClasses = ["curriculumReportsV3StandardsAssessField", "curriculumReportsV3StandardsAssessRadioField", "curriculumReportsV3StandardsAssessCheckboxField", "curriculumReportsV3StandardsAssessSelectField"];

        let curriculumReportsV3StandardsAssessSaveButton = document.getElementById("curriculumReportsV3StandardsAssessSave");
        if (curriculumReportsV3StandardsAssessSaveButton !== null)
            curriculumReportsV3StandardsAssessSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        const studentPopulationNumbersFields = document.getElementsByClassName("studentPopulationNumbers");
        for (const studentPopulationNumbersField of studentPopulationNumbersFields)
            studentPopulationNumbersField.addEventListener("input", (e: Event) => this.sumTotalPopulationNumbers());

        const curriculumReportsV3TaughtInEntityFields = document.getElementsByClassName("curriculumReportsV3TaughtInEntity");
        for (const curriculumReportsV3TaughtInEntityField of curriculumReportsV3TaughtInEntityFields)
            curriculumReportsV3TaughtInEntityField.addEventListener("change", (e: Event) => this.checkForTaughtInEntity());

        this.checkForTaughtInEntity();

        const curriculumReportsV3StandardsAssessIdentifyyourschoolentitytypefromthedropdownlist = document.getElementById("curriculumReportsV3StandardsAssessIdentifyyourschoolentitytypefromthedropdownlist");
        if (curriculumReportsV3StandardsAssessIdentifyyourschoolentitytypefromthedropdownlist !== null)
            curriculumReportsV3StandardsAssessIdentifyyourschoolentitytypefromthedropdownlist.addEventListener("change", (e: Event) => this.checkForCTCorIU());

        const curriculumReportsV3StandardsAssessThisIUdoesNOToffercenterbasedprograms = document.getElementById("curriculumReportsV3StandardsAssessThisIUdoesNOToffercenterbasedprograms");
        if (curriculumReportsV3StandardsAssessThisIUdoesNOToffercenterbasedprograms !== null)
            curriculumReportsV3StandardsAssessThisIUdoesNOToffercenterbasedprograms.addEventListener("change", (e: Event) => this.checkIUNotOffering())

    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("curriculumReportsV3StandardsAssessForm");
        let planPK = parseInt(formElement.dataset.planfk);

        const textInputs = document.getElementsByClassName("curriculumReportsV3StandardsAssessField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        const selects = document.getElementsByClassName("curriculumReportsV3StandardsAssessSelectField");
        for (let ele of selects) {
            let planPropertyPK = 0;
            let element = <HTMLSelectElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: null,
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        const checkboxes = document.getElementsByClassName("curriculumReportsV3StandardsAssessCheckboxField");
        for (let ele of checkboxes) {
            let planPropertyPK = 0;

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            planPropertyPK = parseInt(element.dataset.planpropertypk);

            let val = "";
            if (element.checked)
                val = "on"
            else
                val = "off";

            let saveItem: IPlanProperty = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: planPK,
                PropertyFK: propertyPK,
                TextValue: val,
                LookupCodeFK: null,
                RowNbr: parseInt(rowNumber),
                IsDeletedInd: false
            };

            allSaveElements.push(saveItem);
        }

        const radios = document.getElementsByClassName("curriculumReportsV3StandardsAssessRadioField");
        for (let ele of radios) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                if (element.value !== "" || hadValue) {
                    let saveItem: IPlanPropertyExtended = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                        ButtonRow: 0
                    };

                    allSaveElements.push(saveItem);
                }
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CurriculumInstructionAssessmentReports/SaveCurriculumReportsV3StandardsAssess', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    sumTotalPopulationNumbers() {
        const totalPopulationField = <HTMLInputElement>document.getElementById("curriculumReportsV3StandardsAssessStudentPopulationTotal");
        let totalSum: number = 0;
        const studentPopulationNumbersFields = document.getElementsByClassName("studentPopulationNumbers");
        for (const studentPopulationNumbersField of studentPopulationNumbersFields) {
            const parsedValue = parseInt((<HTMLInputElement>studentPopulationNumbersField).value);
            if (!isNaN(parsedValue))
                totalSum += parsedValue;
        }

        totalPopulationField.value = totalSum.toString();
    }

    checkForTaughtInEntity() {
        const curriculumReportsV3TaughtInEntityFields = document.getElementsByClassName("curriculumReportsV3TaughtInEntity");
        for (const curriculumReportsV3TaughtInEntityField of curriculumReportsV3TaughtInEntityFields) {
            const element = <HTMLInputElement>curriculumReportsV3TaughtInEntityField;
            if (element.checked) {
                const entity = element.dataset.entity;
                const value = element.dataset.lookupcode;

                const numberElement = <HTMLInputElement>document.querySelector(`.studentPopulationNumbers[data-entity='${entity}']`);
                if (value === "no") {
                    numberElement.readOnly = true;
                    numberElement.value = "0";
                } else {
                    numberElement.readOnly = false;
                }
            }
        }

        this.sumTotalPopulationNumbers();
    }

    checkForCTCorIU() {
        const curriculumReportsV3StandardsAssessIdentifyyourschoolentitytypefromthedropdownlist = <HTMLSelectElement>document.getElementById("curriculumReportsV3StandardsAssessIdentifyyourschoolentitytypefromthedropdownlist");
        if (curriculumReportsV3StandardsAssessIdentifyyourschoolentitytypefromthedropdownlist !== null) {
            const ctcContainerElement = <HTMLDivElement>document.getElementById("curriculumReportsV3StandardsAssessmentCTC");
            const ctcElement = <HTMLTextAreaElement>document.getElementById("curriculumReportsV3StandardsAssessThisParttimeCTCoffersthefollowingcorecontent");
            const selectedElement = <HTMLOptionElement>curriculumReportsV3StandardsAssessIdentifyyourschoolentitytypefromthedropdownlist[curriculumReportsV3StandardsAssessIdentifyyourschoolentitytypefromthedropdownlist.selectedIndex];

            const iuContainerElement = <HTMLDivElement>document.getElementById("iuOnly");
            const iuTextElements = document.getElementsByClassName("curriculumReportIUOnlyText");
            const iuRadioElements = document.getElementsByClassName("curriculumReportIUOnlyRadio");

            const reportTypeElement = <HTMLDivElement>document.getElementById("curriculumReportsV3StandardsAssessForm");
            let reportType = "";
            if (reportTypeElement !== null) {
                reportType = reportTypeElement.dataset.reporttemplate;
            }

            if (selectedElement.dataset.lookupcode === "curriculumSchoolEntityPartTimeCTC") {//Part-Time CTC
                if (reportType !== "curriculumreportsiuv3") {
                    ctcContainerElement.classList.remove("hide");
                    this._core.forceElementRequired(ctcElement);

                    if (iuContainerElement !== null) {
                        iuContainerElement.classList.add("hide");
                        for (const iuText of iuTextElements) {
                            const ele = <HTMLInputElement>iuText;
                            this._core.forceElementOptional(ele);
                        }

                        for (const iuRadio of iuRadioElements) {
                            const ele = <HTMLInputElement>iuRadio;
                            this._core.forceElementOptional(ele);
                        }
                    }
                }
            }

            else if (selectedElement.dataset.lookupcode === "curriculumSchoolEntityIntermediateUnit") { //IU
                ctcContainerElement.classList.add("hide");
                this._core.forceElementOptional(ctcElement);

                if (iuContainerElement !== null) {
                    iuContainerElement.classList.remove("hide");
                    for (const iuTextT of iuTextElements) {
                        const eleT = <HTMLInputElement>iuTextT;
                        if (eleT.dataset.originalpercent === "1.0")
                            this._core.forceElementRequired(eleT);
                    }

                    for (const iuRadioR of iuRadioElements) {
                        const eleR = <HTMLInputElement>iuRadioR;
                        if (eleR.dataset.originalpercent === "1.0")
                            this._core.forceElementRequired(eleR);
                    }
                }
            }
            else {
                ctcContainerElement.classList.add("hide");
                this._core.forceElementOptional(ctcElement);

                if (iuContainerElement !== null) {
                    iuContainerElement.classList.add("hide");
                    for (const iuTextTT of iuTextElements) {
                        const eleTT = <HTMLInputElement>iuTextTT;
                        this._core.forceElementOptional(eleTT);
                    }

                    for (const iuRadioRR of iuRadioElements) {
                        const eleRR = <HTMLInputElement>iuRadioRR;
                        this._core.forceElementOptional(eleRR);
                    }
                }
            }
        }
    }

    iuOriginalPercent() {
        const iuTextElements = document.getElementsByClassName("curriculumReportIUOnlyText");
        const iuRadioElements = document.getElementsByClassName("curriculumReportIUOnlyRadio");

        for (const text of iuTextElements) {
            const ele = <HTMLInputElement>text;
            ele.dataset.originalpercent = ele.dataset.percent;
        }

        for (const radio of iuRadioElements) {
            const ele = <HTMLInputElement>radio;
            ele.dataset.originalpercent = ele.dataset.percent;
        }
    }

    checkIUNotOffering() {
        const curriculumReportsV3StandardsAssessThisIUdoesNOToffercenterbasedprograms = document.getElementById("curriculumReportsV3StandardsAssessThisIUdoesNOToffercenterbasedprograms");
        if (curriculumReportsV3StandardsAssessThisIUdoesNOToffercenterbasedprograms !== null) {
            const ele = <HTMLInputElement>curriculumReportsV3StandardsAssessThisIUdoesNOToffercenterbasedprograms;
            const iuTextElements = document.getElementsByClassName("curriculumReportIUOnlyText");
            const iuRadioElements = document.getElementsByClassName("curriculumReportIUOnlyRadio");

            if (ele.checked) {
                for (const textE of iuTextElements) {
                    const eleT = <HTMLInputElement>textE;
                    eleT.value = "";
                    eleT.readOnly = true;
                    this._core.forceElementOptional(eleT);
                }

                for (const radioE of iuRadioElements) {
                    const eleR = <HTMLInputElement>radioE;
                    eleR.checked = false;
                    eleR.disabled = true;
                    this._core.forceElementOptional(eleR);
                }
            } else {
                for (const textEle of iuTextElements) {
                    const eleTT = <HTMLInputElement>textEle;
                    eleTT.readOnly = false;

                    if (eleTT.dataset.originalpercent === "1.0")
                        this._core.forceElementRequired(eleTT);
                }

                for (const radioEle of iuRadioElements) {
                    const eleRR = <HTMLInputElement>radioEle;
                    eleRR.disabled = false;

                    if (eleRR.dataset.originalpercent === "1.0")
                        this._core.forceElementRequired(eleRR);

                }
            }
        }
    }
}

// CurriculumReportsV3StandardsAlign
class CurriculumReportsV3StandardsAlign {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["curriculumReportsV3StandardsAlignField", "curriculumReportsV3StandardsAlignRadioField", "curriculumReportsV3StandardsAlignCheckboxField", "curriculumReportsV3StandardsAlignFileField"];

        let curriculumReportsV3StandardsAlignSaveButton = document.getElementById("curriculumReportsV3StandardsAlignSave");
        if (curriculumReportsV3StandardsAlignSaveButton !== null)
            curriculumReportsV3StandardsAlignSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this.checkAllAlignmentQuestions();
        this.checkCTC();

        const curriculumReportsV3StandardsAlignSecondaryGradeLevelcontentdoesnotapply = document.getElementById("curriculumReportsV3StandardsAlignSecondaryGradeLevelcontentdoesnotapply");
        if (curriculumReportsV3StandardsAlignSecondaryGradeLevelcontentdoesnotapply !== null) {
            curriculumReportsV3StandardsAlignSecondaryGradeLevelcontentdoesnotapply.addEventListener("change", (e: Event) => this.contentDoesNotApply(e.srcElement as HTMLInputElement));
            this.contentDoesNotApply(curriculumReportsV3StandardsAlignSecondaryGradeLevelcontentdoesnotapply as HTMLInputElement);
        }

        const curriculumReportsV3StandardsAlignElementaryGradeLevelcontentdoesnotapply = document.getElementById("curriculumReportsV3StandardsAlignElementaryGradeLevelcontentdoesnotapply");
        if (curriculumReportsV3StandardsAlignElementaryGradeLevelcontentdoesnotapply !== null) {
            curriculumReportsV3StandardsAlignElementaryGradeLevelcontentdoesnotapply.addEventListener("change", (e: Event) => this.contentDoesNotApply(e.srcElement as HTMLInputElement));
            this.contentDoesNotApply(curriculumReportsV3StandardsAlignElementaryGradeLevelcontentdoesnotapply as HTMLInputElement);
        }

        this._core.leftnav(this);

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this.initializeRequiredFieldsCustom(this.validationClasses);        

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        const curriculumReportsV3AlignmentQuestions = document.getElementsByClassName("curriculumReportsV3AlignmentQuestions");
        for (const curriculumReportsV3AlignmentQuestion of curriculumReportsV3AlignmentQuestions)
            curriculumReportsV3AlignmentQuestion.addEventListener("change", (e: Event) => this.checkAlignmentQuestions(e.srcElement as HTMLInputElement));

        const fileUploads = document.querySelectorAll(`.curriculumReportsV3StandardsAlignFileField[type='file']`);
        for (const fileUpload of fileUploads)
            fileUpload.addEventListener("change", (e: Event) => this.uploadFile(<HTMLInputElement>e.srcElement, (fileUpload as HTMLInputElement).dataset.filetype));

        let deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e: Event) => this.deleteFileConfirm(e));

        let deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e: Event) => this.deleteFileCancel());

        this.bindDeleteFiles();
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("curriculumReportsV3StandardsAlignForm");
        let planPK = parseInt(formElement.dataset.newplanfk);

        let textInputs = document.getElementsByClassName("curriculumReportsV3StandardsAlignField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        const radios = document.getElementsByClassName("curriculumReportsV3StandardsAlignRadioField");
        for (let ele of radios) {
            let planPropertyPK = 0;

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                if (element.value !== "" || hadValue) {
                    let saveItem: IPlanPropertyExtended = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                        ButtonRow: 0
                    };

                    allSaveElements.push(saveItem);
                }
            }
        }

        const checkboxes = document.getElementsByClassName("curriculumReportsV3StandardsAlignCheckboxField");
        for (let ele of checkboxes) {
            let planPropertyPK = 0;

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            planPropertyPK = parseInt(element.dataset.planpropertypk);

            let val = "";
            if (element.checked)
                val = "on";
            else
                val = "off";

            let saveItem: IPlanProperty = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: planPK,
                PropertyFK: propertyPK,
                TextValue: val,
                LookupCodeFK: null,
                RowNbr: parseInt(rowNumber),
                IsDeletedInd: false
            };
            allSaveElements.push(saveItem);
        }

        let allFileElements: IPlanPropertyFile[] = [];
        let files = document.getElementsByClassName("curriculumReportsV3StandardsAlignFileUploadField");
        for (let ele of files) {
            let element = <HTMLInputElement>ele;
            let sequenceNbr = parseInt(element.dataset.sequencenbr);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk)
            let fileUploadPK = parseInt(element.dataset.fileuploadpk);
            let propertyPK = parseInt(element.dataset.propertypk);

            let saveItem: IPlanPropertyFile = {
                PlanPropertyFilePK: planPropertyFilePK,
                SequenceNbr: sequenceNbr,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanFK: planPK
            };

            allFileElements.push(saveItem);
        }

        var allData = {
            "ElementData": allSaveElements,
            "FileData": allFileElements,
            "PlanFK": planPK
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CurriculumInstructionAssessmentReports/SaveCurriculumReportsV3StandardsAlign', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allData.ElementData.length == 0 && allData.FileData.length == 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }

    checkAllAlignmentQuestions() {
        const curriculumReportsV3AlignmentQuestions = document.getElementsByClassName("curriculumReportsV3AlignmentQuestions");
        for (const curriculumReportsV3AlignmentQuestion of curriculumReportsV3AlignmentQuestions)
            this.checkAlignmentQuestions(curriculumReportsV3AlignmentQuestion as HTMLInputElement);
    }

    checkAlignmentQuestions(srcElement: HTMLInputElement) {
        const questionNumber = srcElement.dataset.question;
        const textElement = <HTMLTextAreaElement>document.querySelector(`.curriculumReportsV3AlignmentQuestionsNoResponse[data-question='${questionNumber}']`);
        const textElementContainer = <HTMLDivElement>document.querySelector(`.curriculumReportsV3AlignmentQuestionsNoResponseContainer[data-question='${questionNumber}']`);
        if (srcElement.checked) {
            if (srcElement.dataset.lookupcode === "no") {
                this._core.forceElementRequired(textElement);
                textElementContainer.classList.remove("hide");

                if (srcElement.dataset.question === "5") {
                    const allCheckboxes = document.getElementsByClassName("curriculumReportsV3StandardsAlignCheckboxField");
                    for (const checkbox of allCheckboxes) {
                        const checkEle = <HTMLInputElement>checkbox;
                        this._core.forceElementOptional(checkEle);
                    }

                    const allFiles = document.getElementsByClassName("curriculumReportsV3StandardsAlignFileField");
                    for (const file of allFiles) {
                        const fileEle = <HTMLInputElement>file;
                        this._core.forceElementOptional(fileEle);
                    }

                    const elementaryAndSecondaryEle = <HTMLDivElement>document.getElementById("elementaryAndSecondary");
                    elementaryAndSecondaryEle.classList.add("hide");
                }
            } else {
                this._core.forceElementOptional(textElement);
                textElementContainer.classList.add("hide");

                if (srcElement.dataset.question === "5") {
                    const allCheckboxes = document.getElementsByClassName("curriculumReportsV3StandardsAlignCheckboxField");
                    for (const checkbox of allCheckboxes) {
                        const checkEle = <HTMLInputElement>checkbox;
                        this._core.forceElementOptional(checkEle);
                    }

                    const allFiles = document.getElementsByClassName("curriculumReportsV3StandardsAlignFileField");
                    for (const file of allFiles) {
                        const fileEle = <HTMLInputElement>file;
                        this._core.forceElementRequired(fileEle);
                    }

                    const elementaryAndSecondaryEle = <HTMLDivElement>document.getElementById("elementaryAndSecondary");
                    elementaryAndSecondaryEle.classList.remove("hide");
                }
            }
        }
    }

    checkCTC() {
        const excludeCTCElement = <HTMLInputElement>document.getElementById("excludeCTC");
        if (excludeCTCElement.value === "true") {
            const excludes = document.getElementsByClassName("ctcExclude");
            for (const exclude of excludes) {
                const ele = <HTMLInputElement>exclude;
                this._core.forceElementOptional(ele);
                ele.disabled = true;
            }
        }
    }

    uploadFile(e: HTMLInputElement, fileType) {
        Core.showLoader();
        const formName = e.dataset.formname;
        const uploadSampleForm = <HTMLFormElement>document.getElementById(formName);
        const formData = new FormData(uploadSampleForm);
        const propertyPK = e.dataset.propertypk;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CurriculumInstructionAssessmentReports/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();

                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);

                        const formFile = <HTMLInputElement>document.querySelector(`.curriculumReportsV3StandardsAlignFileField[data-formname='${formName}']`);
                        formFile.value = "";

                        const fileList = document.querySelector(`.uploadFileList[data-filetype='${fileType}']`)

                        let uploadedContainer = document.querySelector(`.uploadedFiles[data-filetype='${fileType}']`);
                        uploadedContainer.classList.remove("hide");

                        let fileWrapper = <HTMLDivElement>document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = <HTMLDivElement>document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = <HTMLAnchorElement>document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.sequencenbr = "0";
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.dataset.save = fileType;
                        fileA.classList.add("curriculumReportsV3StandardsAlignFileUploadField");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);

                        fileList.appendChild(fileWrapper);
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }

    bindDeleteFiles() {
        let deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (let deleteFileButton of deleteFileButtons)
            deleteFileButton.addEventListener("click", (e: Event) => this.showDeleteFile(e));
    }

    showDeleteFile(e: Event) {
        let buttonElement = <HTMLButtonElement>e.srcElement;
        let row = buttonElement.dataset.row;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;

        let modal: Modal = new Modal("deleteFileModal", null);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "row", row);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "planpropertyfilepk", planPropertyFilePK);
        modal.show();
    }

    deleteFileConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        let row = buttonElement.dataset.row;
        Core.showLoader();
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/DeleteFileData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    let element = document.querySelector(`.curriculumReportsV3StandardsAlignFileUploadField[data-planpropertyfilepk='${planPropertyFilePK}']`);
                    let parent = element.parentElement.parentElement;
                    parent.remove();

                    const moreFiles = document.querySelectorAll(`.uploadFileList[data-row='${row}'] .uploadFileColumn`);
                    if (moreFiles.length === 0) {
                        const uploadsElement = <HTMLDivElement>document.getElementById(`uploadedFiles${row}`);
                        if (uploadsElement !== null) {
                            uploadsElement.classList.add("hide");
                        }
                    }

                    Core.createHTMLAlert("alertMessageDiv", "File successfully removed.", 'success', 3000, null);
                } else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the file. Please try again.", 'error', 3000, null);
                }

                Core.hideLoader();

                let modal: Modal = new Modal("deleteFileModal", null);
                modal.hide();
            } else {
                let modal: Modal = new Modal("deleteFileModal", null);
                modal.hide();

                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planPropertyFilePK));
    }

    deleteFileCancel() {
        let modal: Modal = new Modal("deleteFileModal", null);
        modal.hide();
    }

    contentDoesNotApply(element: HTMLInputElement) {
        const fileType = element.dataset.filetype;
        const container = <HTMLDivElement>document.querySelector(`.fileUploaderContainer[data-filetype='${fileType}']`);
        const fileUploader = <HTMLInputElement>document.querySelector(`.curriculumReportsV3StandardsAlignFileField[data-filetype='${fileType}'][type='file']`);

        if (element.checked) {
            container.classList.add("hide");
            this._core.forceElementOptional(fileUploader);
        } else {
            container.classList.remove("hide");
            this._core.forceElementRequired(fileUploader);
        }
    }

    initializeRequiredFieldsCustom(allClasses: string[]) {
        this._core.initializeRequiredFields(allClasses);
    }
}

// CurriculumReportsV3EdEffectiveness
class CurriculumReportsV3EdEffectiveness {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["curriculumReportsV3EdEffectivenessField", "curriculumReportsV3EdEffectivenessCheckboxField", "curriculumReportsV3EdEffectivenessRadioField", "curriculumReportsV3EdEffectivenessSelectField"];

        this.addOriginalPercent();

        this.checkForAct13NotUsed();

        this.totalAllCategoryValues();

        let curriculumReportsV3EdEffectivenessSaveButton = document.getElementById("curriculumReportsV3EdEffectivenessSave");
        if (curriculumReportsV3EdEffectivenessSaveButton !== null)
            curriculumReportsV3EdEffectivenessSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        const curriculumReportsV3EdEffectivenessAct13NotUsedChecks = document.getElementsByClassName("curriculumReportsV3EdEffectivenessCheckboxField");
        for (const curriculumReportsV3EdEffectivenessAct13NotUsedCheck of curriculumReportsV3EdEffectivenessAct13NotUsedChecks)
            curriculumReportsV3EdEffectivenessAct13NotUsedCheck.addEventListener("click", (e: Event) => this.checkForAct13NotUsed());

        const curriculumReportsV3EdEffectivenessAct13DataFields = document.getElementsByClassName("curriculumReportsV3EdEffectivenessAct13DataField");
        for (const curriculumReportsV3EdEffectivenessAct13DataField of curriculumReportsV3EdEffectivenessAct13DataFields) {
            curriculumReportsV3EdEffectivenessAct13DataField.addEventListener("input", (e: Event) => this.checkForNumericError(e.srcElement as HTMLInputElement));
            this.checkForNumericError(curriculumReportsV3EdEffectivenessAct13DataField as HTMLInputElement);
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("curriculumReportsV3EdEffectivenessForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("curriculumReportsV3EdEffectivenessField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        const radios = document.getElementsByClassName("curriculumReportsV3EdEffectivenessRadioField");
        for (let ele of radios) {
            let planPropertyPK = 0;

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                if (element.value !== "" || hadValue) {
                    let saveItem: IPlanPropertyExtended = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                        ButtonRow: 0
                    };

                    allSaveElements.push(saveItem);
                }
            }
        }

        const checkboxes = document.getElementsByClassName("curriculumReportsV3EdEffectivenessCheckboxField");
        for (let ele of checkboxes) {
            let planPropertyPK = 0;

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            planPropertyPK = parseInt(element.dataset.planpropertypk);

            let val = "";
            if (element.checked)
                val = "on";
            else
                val = "off";

            let saveItem: IPlanProperty = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: planPK,
                PropertyFK: propertyPK,
                TextValue: val,
                LookupCodeFK: null,
                RowNbr: parseInt(rowNumber),
                IsDeletedInd: false
            };
            allSaveElements.push(saveItem);
        }

        const selects = document.getElementsByClassName("curriculumReportsV3EdEffectivenessSelectField");
        for (let ele of selects) {
            let planPropertyPK = 0;
            let element = <HTMLSelectElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: null,
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CurriculumInstructionAssessmentReports/SaveCurriculumReportsV3EdEffectiveness', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    checkForAct13NotUsed() {
        const curriculumReportsV3EdEffectivenessAct13NotUsedCheck = <HTMLInputElement>document.getElementById("curriculumReportsV3EdEffectivenessAct13NotUsed");
        if (curriculumReportsV3EdEffectivenessAct13NotUsedCheck !== null) {
            const divElement = <HTMLDivElement>document.getElementById("act13");

            const allRadios = document.getElementsByClassName("curriculumReportsV3EdEffectivenessRadioField");
            const allTexts = document.getElementsByClassName("curriculumReportsV3EdEffectivenessField");
            const allSelects = document.getElementsByClassName("curriculumReportsV3EdEffectivenessSelectField");


            if (curriculumReportsV3EdEffectivenessAct13NotUsedCheck.checked) {
                divElement.classList.add("hide");

                for (const radio of allRadios) {
                    this._core.forceElementOptional((radio as HTMLInputElement));
                }

                for (const text of allTexts) {
                    this._core.forceElementOptional((text as HTMLInputElement));
                }

                for (const select of allSelects) {
                    this._core.forceElementOptional((select as HTMLSelectElement));
                }

            } else {
                divElement.classList.remove("hide");

                for (const radio of allRadios) {
                    const ele = <HTMLInputElement>radio;
                    if(ele.dataset.originalpercent === "1.0")
                        this._core.forceElementRequired(ele);
                }

                for (const text of allTexts) {
                    const ele = <HTMLInputElement>text;
                    if (ele.dataset.originalpercent === "1.0")
                        this._core.forceElementRequired(ele);
                }

                for (const select of allSelects) {
                    const ele = <HTMLInputElement>select;
                    if (ele.dataset.originalpercent === "1.0")
                        this._core.forceElementRequired(ele);
                }
            }
        }
            
    }

    addOriginalPercent() {
        const allRadios = document.getElementsByClassName("curriculumReportsV3EdEffectivenessRadioField");
        const allTexts = document.getElementsByClassName("curriculumReportsV3EdEffectivenessField");
        const allSelects = document.getElementsByClassName("curriculumReportsV3EdEffectivenessSelectField");

        for (const radio of allRadios) {
            const ele = <HTMLInputElement>radio;
            const originalPercent = ele.dataset.percent;
            ele.dataset.originalpercent = originalPercent;
        }

        for (const text of allTexts) {
            const ele = <HTMLInputElement>text;
            const originalPercent = ele.dataset.percent;
            ele.dataset.originalpercent = originalPercent;
        }

        for (const select of allSelects) {
            const ele = <HTMLInputElement>select;
            const originalPercent = ele.dataset.percent;
            ele.dataset.originalpercent = originalPercent;
        }
    }

    checkForNumericError(element: HTMLInputElement) {
        const category = element.dataset.category;
        if (element.value !== "") {
            const value = parseFloat(element.value);
            const errorEle = <HTMLTableRowElement>document.querySelector(`.act13-error[data-category='${category}']`);
            if (isNaN(value) || (value < 0 || value > 100)) {
                errorEle.classList.remove("hide");
            } else {
                errorEle.classList.add("hide");
            }

            this.totalAllCategoryValues();
        }
    }

    totalAllCategoryValues() {
        const allValueElements = document.getElementsByClassName("curriculumReportsV3EdEffectivenessAct13DataField");
        let total = 0.0;
        for (const valueElement of allValueElements) {
            const ele = <HTMLInputElement>valueElement;
            const val = parseFloat(ele.value);
            if (!isNaN(val))
                total += val;

            total = Math.round((total + Number.EPSILON) * 100) / 100
        }

        const curriculumReportsV3EdEffectivenessAct13Total = <HTMLInputElement>document.getElementById("curriculumReportsV3EdEffectivenessAct13Total");
        if (total > 0 || curriculumReportsV3EdEffectivenessAct13Total.value !== "") {
            curriculumReportsV3EdEffectivenessAct13Total.value = total.toString();
            const errorEle = <HTMLTableRowElement>document.querySelector(`.act13-error[data-category='total']`);
            if (total != 100.00)
                errorEle.classList.remove("hide");
            else
                errorEle.classList.add("hide");
        }
    }
}

