/// <reference path="core.ts" />

class Workflow {

    ActionResponses: WorkflowActionResponse[];
    Core: Core;
    ReturnModal: Modal;
    ApproveModal: Modal;
    CanSave: boolean;
    ConfirmDialog: WorkflowConfirmDialog;
    WorkflowInstancePK: number;
    ReviewURL: string = '/Review/HandleReviewPageLocation';
    BeforeSave: Function;

    //Initialize all actions in the DOM
    constructor(workflowInstancePK: number) {
        let that = <Workflow>this;
        that.WorkflowInstancePK = workflowInstancePK;
        that.ActionResponses = [];
        that.CanSave = true;
        that.ConfirmDialog = new WorkflowConfirmDialog();
        that.BeforeSave = () => Promise.resolve();
        let actions = document.querySelectorAll(`[data-action][data-workflow-instance-fk='${workflowInstancePK}']`);

        let usedRadioNames: string[] = [];
        for (let action of actions)  {
            let actionElement = <HTMLElement>action;

            if (actionElement instanceof HTMLInputElement) {
                if (actionElement.type == "radio") {
                    if (usedRadioNames.indexOf(actionElement.name) === -1) {
                        that.ActionResponses.push(new WorkflowActionResponseRadio(actionElement));
                        usedRadioNames.push(actionElement.name);
                    }
                } else if (actionElement.type == "checkbox") {
                    that.ActionResponses.push(new WorkflowActionResponseCheckbox(actionElement));
                } else {
                    that.ActionResponses.push(new WorkflowActionResponseInput(actionElement));
                }
            } else if (actionElement instanceof HTMLSelectElement) {
                that.ActionResponses.push(new WorkflowActionResponseSelect(actionElement));
            } else if (actionElement instanceof HTMLButtonElement) {
                let buttonInit = new WorkflowActionResponseButton(actionElement);
                that.ActionResponses.push(buttonInit);

                //Handle save button clicks
                switch(buttonInit.ActionType)
                {
                    case "saveButton":
                        if(!("eventAdded" in actionElement.dataset))
                        {
                            actionElement.dataset.eventAdded = "true";
                            actionElement.addEventListener("click", () => {
                                let url = window.location.href;
                                if (url.indexOf("fromReviewSave") === -1) {

                                    if (window.location.search.length > 0) {
                                        url += "&fromReviewSave=true"
                                    } else {
                                        url += "?fromReviewSave=true";
                                    }
                                } 

                                Core.showLoader();
                                let beforeSavePromise = that.BeforeSave();
                                beforeSavePromise
                                .then(() => {
                                    return that.Save()
                                })
                                .then((success) => {
                                    window.location.href = url;
                                })
                                .catch((error) => {
                                    Core.hideLoader();
                                    Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                                });                       
                            });
                        }
                        break;
                    case "submitReviewButton":
                    case "submitReviewButtonWithoutValidation":
                    case "denyReviewButton":
                        if(!("eventAdded" in actionElement.dataset))
                        {
                            actionElement.dataset.eventAdded = "true";

                            actionElement.addEventListener("click", () => {

                                if(buttonInit.ActionType == "submitReviewButton" && this.ActionResponses.some(action => !action.IsModalAction && action.IsRequired && !action.HasValue())) {
                                    that.Validate();
                                } else {

                                    that.ConfirmDialog.confirm(buttonInit.Element.innerHTML, buttonInit.Element.id)
                                    .then((confirm) => {
                                        if (confirm) {
                                            buttonInit.SubmitAction = true;
                                            Core.showLoader();
                                            let beforeSavePromise = that.BeforeSave();
                                            beforeSavePromise
                                            .then(() => {
                                                return that.Save()
                                            })
                                            .then((success) => {
                                                if(buttonInit.ActionCode == 'authorizedLEAAcknowledge')
                                                {
                                                    Core.createHTMLAlert("alertMessageDiv", 'Successfully acknowledged report', 'success', 3000, window.location.reload());
                                                }
                                                else
                                                {
                                                    window.location.href = that.ReviewURL;
                                                }
                                            })
                                            .catch((error) => {
                                                Core.hideLoader();
                                                Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                                            });
                                        }
                                    })
                                    .catch((error) => {
                                        Core.hideLoader();
                                        Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                                    });
                                }
                            });
                        }
                        break;
                    case "modalApproveConfirm":
                    case "modalReturnConfirm":
                        if(!("eventAdded" in actionElement.dataset))
                        {
                            actionElement.dataset.eventAdded = "true";
                            actionElement.addEventListener("click", () => {
                                //First, check to see that all required fields are filled out
                                let allRequiredFilledOut = true;
                                for (let action of that.ActionResponses) {
                                    //Only check elements that are in the same modal as the button
                                    if (action.IsModalAction && action.IsRequired && buttonInit.ModalContainerId == action.ModalContainerId && !action.HasValue()) {
                                        allRequiredFilledOut = false;
                                        break;
                                    }
                                }

                                if (allRequiredFilledOut) {
                                    buttonInit.SubmitAction = true;
                                    Core.showLoader();
                                    let beforeSavePromise = that.BeforeSave();
                                    beforeSavePromise
                                    .then(() => {
                                        return that.Save(buttonInit.ModalContainerId)
                                    })
                                    .then((success) => {
                                        window.location.href = that.ReviewURL;
                                    })
                                    .catch((error) => {
                                        Core.hideLoader();
                                        that.ReturnModal.hide();
                                        Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                                    });
                                } else {
                                    let alert = document.querySelector(`#${buttonInit.ModalContainerId} .workflowModalAlert`);
                                    if (alert != null) {
                                        alert.classList.remove("hide");

                                        //Show errors for required elements without value
                                        for (let action of that.ActionResponses) {
                                            if (action.IsModalAction && action.IsRequired && buttonInit.ModalContainerId == action.ModalContainerId && !action.HasValue()) {
                                                action.Element.classList.add("missing-field");
                                                action.Element.setAttribute("aria-invalid", "true");
                                                Core.createErrorLabelForInput(action.Element);
                                            }
                                        }
                                    }
                                }
                            });
                        }
                }
            } else if (actionElement instanceof HTMLTextAreaElement) {
                that.ActionResponses.push(new WorkflowActionResponseTextArea(actionElement));
            }
        }

        //Initialize multiselect
        $('.actionMultiSelect').MultiSelect({ 
            ShowAllCheckbox: false
        });

        that.InitializeRequiredActions();

        if (window.location.href.indexOf("fromReviewSave") !== -1) {
            that.Validate();
        }

        //Event listener for deleting comments
        let deleteCommentButtons = document.getElementsByClassName("deleteComment") as HTMLCollectionOf<HTMLButtonElement>;
        for (let deleteButton of deleteCommentButtons) {
            if("workflowInstanceActionResponsePk" in deleteButton.dataset) {
                if(!("eventAdded" in deleteButton.dataset))
                {
                    deleteButton.dataset.eventAdded = "true";
                    deleteButton.addEventListener("click", () => {
                        that.DeleteComment(parseInt(deleteButton.dataset.workflowInstanceActionResponsePk))
                    });
                }
            }
        }

        //Initialize Modals
        if (document.getElementById(`workflowReturnModal${that.WorkflowInstancePK}`) != null) {
            that.ReturnModal = new Modal(`workflowReturnModal${that.WorkflowInstancePK}`, null);

            //Open modal on click of modalTriggerReturn action type
            let modalTriggerReturn = document.querySelector(`.review-container-actions [data-action-type='modalTriggerReturn'][data-workflow-instance-fk='${that.WorkflowInstancePK}']`) as HTMLButtonElement;
            if (modalTriggerReturn != null) {
                if(!("eventAdded" in modalTriggerReturn.dataset))
                {
                    modalTriggerReturn.dataset.eventAdded = "true";
                    modalTriggerReturn.addEventListener("click", () => {
                        if(that.ActionResponses.some(action => !action.IsModalAction && action.IsRequired && !action.HasValue())) {
                            that.Validate();
                        } else {
                            that.ReturnModal.show();
                        }
                    });
                }
            }

            let cancelButton = document.getElementById(`returnCancel${that.WorkflowInstancePK}`) as HTMLButtonElement;
            if (cancelButton != null) {
                if(!("eventAdded" in cancelButton.dataset))
                {
                    cancelButton.dataset.eventAdded = "true";
                    cancelButton.addEventListener("click", () => {
                        that.ReturnModal.hide();
                    });
                }
            }

        } else {
            that.ReturnModal = null;
        }
        
        if (document.getElementById(`workflowApproveModal${that.WorkflowInstancePK}`) != null) {
            that.ApproveModal = new Modal(`workflowApproveModal${that.WorkflowInstancePK}`, null);

            //Open modal on click of modalTriggerApprove action type (should only be one on page)
            let modalTriggerApprove = document.querySelector(`.review-container-actions [data-action-type='modalTriggerApprove'][data-workflow-instance-fk='${that.WorkflowInstancePK}']`) as HTMLButtonElement;
            if (modalTriggerApprove != null) {
                if(!("eventAdded" in modalTriggerApprove.dataset))
                {
                    modalTriggerApprove.dataset.eventAdded = "true";
                    modalTriggerApprove.addEventListener("click", () => {
                        if(that.ActionResponses.some(action => !action.IsModalAction && action.IsRequired && !action.HasValue())) {
                            that.Validate();
                        } else {
                            that.ApproveModal.show();
                        }
                    });
                }
            }

            let cancelButton = document.getElementById(`approveCancel${that.WorkflowInstancePK}`) as HTMLButtonElement;
            if (cancelButton != null) {
                if(!("eventAdded" in cancelButton.dataset))
                {
                    cancelButton.dataset.eventAdded = "true";
                    cancelButton.addEventListener("click", () => {
                        that.ApproveModal.hide();
                    });
                }
            }
        } else {
            that.ApproveModal = null;
        }

        //If there are multiple "Feedback to LEA" dropdowns on the page, check only the actions in that group. Else, look at all compliant actions
        let feedbackComments = document.querySelectorAll(`[data-action][data-workflow-instance-fk='${workflowInstancePK}'][data-action-type='feedbackToLEA']`);
        let feedbackToLEAActions = [];
        let hasMultipleFeedback = false;
        for(let comment of feedbackComments)
        {
            let commentElement = <HTMLElement>comment;
            if (feedbackToLEAActions.indexOf(commentElement.dataset.actionPk) == -1) {
                feedbackToLEAActions.push(commentElement.dataset.actionPk);
                if (feedbackToLEAActions.length > 1) {
                    hasMultipleFeedback = true;
                    break;
                }
            }
        }

        //Initialize compliant radio buttons event listener. 

        /*
        Here's the logic:
        When "no" is selected, check the comment like '%Comment' and any comment with lookupcode like '%CommentNonCompliant%'
        When "yes" is selected, check the comment like '%CommentYes'
        The comment with lookupcode like '%CommentCompliant' is checked when all compliant actions are checked "yes" or "na" and not all of them are "na"
        */

        let compliantActions = document.querySelectorAll(`[data-action-type='componentCompliant'][data-workflow-instance-fk='${workflowInstancePK}']:not([disabled]), [data-action-type='componentCompliantNA'][data-workflow-instance-fk='${workflowInstancePK}']:not([disabled])`);
        for (let compliantAction of compliantActions) {
            let compliantActionElement = <HTMLInputElement>compliantAction;
            if (!("eventAdded" in compliantActionElement.dataset)) {
                compliantActionElement.addEventListener("change", () => {

                    //Specific edge case
                    if (compliantActionElement.checked && compliantActionElement.dataset.lookupCode == "actionYes" && compliantActionElement.dataset.actionCode == "ch49FirstYearIncluded") {
                        //Check the option of NA for the action 'ch49FirstYearIncludedExplanation'
                        let relatedRadioExplanationNA = document.querySelector("[data-action-code='ch49FirstYearIncludedExplanation'][data-lookup-code='actionNA']") as HTMLInputElement;
                        if (relatedRadioExplanationNA != null) {
                            relatedRadioExplanationNA.click();
                        }        
                    }

                    //Find the corresponding comment
                    let comment = document.querySelector(`[data-workflow-instance-fk='${workflowInstancePK}'][data-action-type='feedbackToLEA'][data-lookup-code='${compliantActionElement.dataset.actionCode}Comment']`) as HTMLInputElement;
                    if (compliantActionElement.dataset.lookupCode == "actionNo" && compliantActionElement.checked) {

                        //Check the corresponding comment
                        if (comment != null && !comment.checked) {
                            comment.disabled = false;
                            comment.click();
                            comment.disabled = true;
                        }

                        //Uncheck the compliant comment because they said "no"
                        let compliantComment: HTMLInputElement;
                        if (hasMultipleFeedback) {
                            compliantComment = document.querySelector(`[data-workflow-instance-fk='${workflowInstancePK}'][data-action-type='feedbackToLEA'][data-action-group-code-fk='${compliantActionElement.dataset.actionGroupCodeFk}'][data-lookup-code$='CommentCompliant']`) as HTMLInputElement;
                        } else {
                            compliantComment = document.querySelector(`[data-workflow-instance-fk='${workflowInstancePK}'][data-action-type='feedbackToLEA'][data-lookup-code$='CommentCompliant']`) as HTMLInputElement;
                        }
                        if (compliantComment != null && compliantComment.checked) {
                            compliantComment.disabled = false;
                            compliantComment.click();
                            compliantComment.disabled = true;
                        }

                        //Uncheck comment like '%CommentYes'
                        let yesComment = document.querySelector(`[data-workflow-instance-fk='${workflowInstancePK}'][data-action-type='feedbackToLEA'][data-lookup-code='${compliantActionElement.dataset.actionCode}CommentYes']`) as HTMLInputElement;
                        if (yesComment != null && yesComment.checked) {
                            yesComment.disabled = false;
                            yesComment.click();
                            yesComment.disabled = true;
                        }

                        //Check the '%CommentNonCompliant%' comment
                        let nonCompliantComment: HTMLInputElement;
                        if (hasMultipleFeedback) {
                            nonCompliantComment = document.querySelector(`[data-workflow-instance-fk='${workflowInstancePK}'][data-action-type='feedbackToLEA'][data-action-group-code-fk='${compliantActionElement.dataset.actionGroupCodeFk}'][data-lookup-code$='CommentNonCompliant']`) as HTMLInputElement;
                        } else {
                            nonCompliantComment = document.querySelector(`[data-workflow-instance-fk='${workflowInstancePK}'][data-action-type='feedbackToLEA'][data-lookup-code$='CommentNonCompliant']`) as HTMLInputElement;
                        }
                        if (nonCompliantComment != null && !nonCompliantComment.checked) {
                            nonCompliantComment.disabled = false;
                            nonCompliantComment.click();
                            nonCompliantComment.disabled = true;
                        }

                    } else if ((compliantActionElement.dataset.lookupCode == "actionYes" || compliantActionElement.dataset.lookupCode == "actionNA") && compliantActionElement.checked) {
                        if (comment != null && comment.checked) {
                            comment.disabled = false;
                            comment.click();
                            comment.disabled = true;
                        }

                        //Check comment like '%CommentYes'
                        if (compliantActionElement.dataset.lookupCode == "actionYes") {
                            let yesComment = document.querySelector(`[data-workflow-instance-fk='${workflowInstancePK}'][data-action-type='feedbackToLEA'][data-lookup-code='${compliantActionElement.dataset.actionCode}CommentYes']`) as HTMLInputElement;
                            if (yesComment != null) {
                                yesComment.disabled = false;
                                yesComment.click();
                                yesComment.disabled = true;
                            }
                        } else {
                            //Uncheck comment like '%CommentYes'
                            let yesComment = document.querySelector(`[data-workflow-instance-fk='${workflowInstancePK}'][data-action-type='feedbackToLEA'][data-lookup-code='${compliantActionElement.dataset.actionCode}CommentYes']`) as HTMLInputElement;
                            if (yesComment != null && yesComment.checked) {
                                yesComment.disabled = false;
                                yesComment.click();
                                yesComment.disabled = true;
                            }
                        }

                        //Check to see if all of the other compliant actions are checked yes. If so, check the comment like '%CommentCompliant'
                        let allCheckedYesNA = true;
                        let atLeastOneCheckedYes = false;
                        let atLeastOneCheckedNo = false;

                        let compliantActionsToCheck: NodeListOf<Element>;

                        if(hasMultipleFeedback) {
                            compliantActionsToCheck = document.querySelectorAll(`[data-workflow-instance-fk='${workflowInstancePK}'][data-action-type='componentCompliant'][data-action-group-code-fk='${compliantActionElement.dataset.actionGroupCodeFk}']:not([disabled]), [data-workflow-instance-fk='${workflowInstancePK}'][data-action-type='componentCompliantNA'][data-action-group-code-fk='${compliantActionElement.dataset.actionGroupCodeFk}']:not([disabled])`);
                        }
                        else {
                            compliantActionsToCheck = document.querySelectorAll(`[data-workflow-instance-fk='${workflowInstancePK}'][data-action-type='componentCompliant'], [data-workflow-instance-fk='${workflowInstancePK}'][data-action-type='componentCompliantNA']:not([disabled])`);
                        }
                        for (let act of compliantActionsToCheck) {
                            let actElement = <HTMLInputElement>act;
                                
                            let checkedRadio = document.querySelector(`[data-workflow-instance-fk='${workflowInstancePK}'][name='${actElement.name}']:checked`) as HTMLInputElement;
                            if (checkedRadio != null) {

                                //Custom logic for the edge case in Induction Plan - Documentation of Participation
                                if (checkedRadio.dataset.actionCode == "ch49FirstYearIncluded" ) {
                                    if(checkedRadio.dataset.lookupCode == "actionNo")
                                    {
                                        //Check to see if ch49FirstYearIncludedExplanation action is "Yes"
                                        let relatedRadio= document.querySelectorAll("[data-action-code='ch49FirstYearIncludedExplanation']");
                                        let relatedIsCheckedYes = false;
                                        for (let radio of relatedRadio) {
                                            let radioElement = <HTMLInputElement>radio;
                                            if (radioElement.checked && radioElement.dataset.lookupCode == "actionYes") {
                                                relatedIsCheckedYes = true;
                                            }
                                        } 

                                        if (!relatedIsCheckedYes) {
                                            allCheckedYesNA = false;
                                        }
                                    }
                                }
                                else if (checkedRadio.dataset.lookupCode != "actionYes" && checkedRadio.dataset.lookupCode != "actionNA") {
                                    allCheckedYesNA = false;
                                }

                                if (checkedRadio.dataset.lookupCode == "actionYes") {
                                    atLeastOneCheckedYes = true;
                                }

                                if (checkedRadio.dataset.lookupCode == "actionNo") {
                                    atLeastOneCheckedNo = true;
                                }
                            } else {
                                allCheckedYesNA = false;
                            }
                        }

                        if (allCheckedYesNA && atLeastOneCheckedYes) {
                            let compliantComment: HTMLInputElement;
                            if(hasMultipleFeedback) {
                                compliantComment = document.querySelector(`[data-workflow-instance-fk='${workflowInstancePK}'][data-action-type='feedbackToLEA'][data-action-group-code-fk='${compliantActionElement.dataset.actionGroupCodeFk}'][data-lookup-code$='CommentCompliant']`) as HTMLInputElement;
                            } else {
                                compliantComment = document.querySelector(`[data-workflow-instance-fk='${workflowInstancePK}'][data-action-type='feedbackToLEA'][data-lookup-code$='CommentCompliant']`) as HTMLInputElement;
                            }
                            if (compliantComment != null && !compliantComment.checked) {
                                compliantComment.disabled = false;
                                compliantComment.click();
                                compliantComment.disabled = true;
                            }
                        }
                        else {
                            let compliantComment: HTMLInputElement;
                            if (hasMultipleFeedback) {
                                compliantComment = document.querySelector(`[data-workflow-instance-fk='${workflowInstancePK}'][data-action-type='feedbackToLEA'][data-action-group-code-fk='${compliantActionElement.dataset.actionGroupCodeFk}'][data-lookup-code$='CommentCompliant']`) as HTMLInputElement;
                            } else {
                                compliantComment = document.querySelector(`[data-workflow-instance-fk='${workflowInstancePK}'][data-action-type='feedbackToLEA'][data-lookup-code$='CommentCompliant']`) as HTMLInputElement;
                            }
                            if (compliantComment != null && compliantComment.checked) {
                                compliantComment.disabled = false;
                                compliantComment.click();
                                compliantComment.disabled = true;
                            }
                        }

                        let nonCompliantComment: HTMLInputElement;
                        if(hasMultipleFeedback) {
                            nonCompliantComment = document.querySelector(`[data-workflow-instance-fk='${workflowInstancePK}'][data-action-type='feedbackToLEA'][data-action-group-code-fk='${compliantActionElement.dataset.actionGroupCodeFk}'][data-lookup-code$='CommentNonCompliant']`) as HTMLInputElement;
                        } else {
                            nonCompliantComment = document.querySelector(`[data-workflow-instance-fk='${workflowInstancePK}'][data-action-type='feedbackToLEA'][data-lookup-code$='CommentNonCompliant']`) as HTMLInputElement;
                        }

                        if (nonCompliantComment != null) {
                            if ((atLeastOneCheckedNo && !nonCompliantComment.checked) || (!atLeastOneCheckedNo && nonCompliantComment.checked)) {
                                nonCompliantComment.disabled = false;
                                nonCompliantComment.click();
                                nonCompliantComment.disabled = true;
                            }
                        }
                    }
                });
                compliantActionElement.dataset.eventAdded = "true";
            }
        }
    }

    Save(modalId: string = null) {
        let that = this;

        return new Promise<string>((resolve, reject) => {
            if(that.CanSave)
            {
                that.CanSave = false;
                let allSaveData = that.GetWorkflowSaveData(modalId);

                let xhr = new XMLHttpRequest();
                xhr.open('POST', '/Base/SaveActions', true);
                xhr.setRequestHeader('Content-Type', 'application/json');
                xhr.onload = function () {
                    //Allow saving again
                    that.CanSave = true;

                    if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                        resolve("Successfully saved review");
                    }
                    else {
                        reject("There was an unexpected error saving review")
                    }
                };

                if (allSaveData.length > 0) {
                    xhr.send(JSON.stringify(allSaveData));
                } else {
                    that.CanSave = true;
                    resolve("Nothing to save");
                }
            } else {
                resolve("Unable to save review");
            }
        });
    }

    GetWorkflowSaveData(modalId: string = null) : WorkflowInstanceActionResponseSave[] {
        let that = this;
        let allSaveData: WorkflowInstanceActionResponseSave[] = [];
        for (let actionResponse of that.ActionResponses.filter(ar => !ar.IsReadOnly && (ar.HasChanged() || (!ar.IsComplete && ar.HasValue())))) {
            if((modalId != null && actionResponse.IsModalAction && modalId == actionResponse.ModalContainerId) || !actionResponse.IsModalAction) {
                let saveItem: WorkflowInstanceActionResponseSave = actionResponse.GetSaveItem();
                if (saveItem != null) {
                    allSaveData.push(saveItem);
                }
            }
        }
        return allSaveData;
    }

    InitializeRequiredActions() {
        let that = this;
        for (let action of that.ActionResponses) {
            if (action.IsRequired) {
                action.Element.setAttribute("aria-required", "true");

                let label = Core.findLabelForInput(action.Element);

                if (label !== null && !label.classList.contains("isRequired")) {
                    label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                    label.classList.add("isRequired");
                }
            }
        }
    }

    Validate() : boolean {
        let that = this;
        let errorCount = 0;
        for (let action of that.ActionResponses.filter(a => !a.IsModalAction)) {
            if (action.IsRequired && !action.HasValue()) {
                action.Element.classList.add("missing-field");
                action.Element.setAttribute("aria-invalid", "true");
                Core.createErrorLabelForInput(action.Element);
                errorCount++;
            }
        }

        let messageContainerColumn = <HTMLElement>document.querySelector(`.validationColumnReview[data-workflow-instance-fk='${that.WorkflowInstancePK}']`);
        let messageContainer = <HTMLElement>messageContainerColumn.querySelector(".validationMessageContainerReview");
        let message = <HTMLDivElement>messageContainerColumn.querySelector(".validationMessageReview");
        messageContainerColumn.classList.add("show");
        let validationIcon = <HTMLElement>messageContainerColumn.querySelector(".validationMessageIconReview");

        if (errorCount > 0) {
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
            let successMessage = <HTMLElement>messageContainerColumn.querySelector(".saveSuccessReview");
            if(successMessage != null) {
                successMessage.classList.add("hide");
            }

            let accordions = document.querySelectorAll(`.review-container-actions[data-workflow-instance-fk='${that.WorkflowInstancePK}'] .Accordion`);
            for (let accordion of accordions) {
                let accordionElement = <HTMLElement>accordion;
                let issues = accordionElement.querySelector(".hasBeenValidated");
                if (issues != null) {
                    let trigger = accordionElement.querySelector(".Accordion-trigger") as HTMLButtonElement;
                    if (trigger != null) {
                        trigger.click();
                    }
                }
            }
        } else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";
            let successMessage = <HTMLElement>messageContainerColumn.querySelector(".saveSuccessReview");
            if (successMessage !== null) {
                successMessage.innerHTML = "The review has been successfully saved."
                successMessage.classList.remove("hide");
            }
        }

        if (errorCount === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + errorCount + " issue to fix for this review component</p>";
        } else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + errorCount + " issues to fix for this review component</p>";
        }

        messageContainer.focus();

        return errorCount === 0;
    }

    DeleteComment(responsePK: number) {
        let that = this;
        let xhr = new XMLHttpRequest();
        xhr.open('DELETE', '/Base/DeleteWorkflowResponse', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                Core.createHTMLAlert("alertMessageDiv", "Successfully deleted comment", 'success', 3000, null);
                //Remove comment from DOM
                let deleteButton = document.querySelector(`.deleteComment[data-workflow-instance-action-response-pk='${responsePK}']`) as HTMLButtonElement;
                if (deleteButton != null) {
                    let container = Core.findClosest(deleteButton, "[data-comment-container]") as HTMLElement;
                    if (container != null) {
                        let nextFocusable = Core.getNextFocusableElement(deleteButton);
                        container.parentNode.removeChild(container);
                        if (nextFocusable != null) {
                            nextFocusable.focus();
                        }
                    }
                }
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting comment", 'error', 3000, null);
            }
        };

        xhr.send(responsePK.toString());
    }

    //Saves all workflows in one call
    static SaveAllWorkflows(workflows: Workflow[]) : Promise<string> {
        return new Promise<string>((resolve, reject) => {

            let allSaveData: WorkflowInstanceActionResponseSave[] = [];
            for(let workflow of workflows)
            {
                if(workflow.CanSave)
                {
                    //Set this to false for now so that user can't hit the save button again and save. (Safeguard for double saving)
                    workflow.CanSave = false;
                    let workflowSaveData = workflow.GetWorkflowSaveData();
                    if(workflowSaveData != null && workflowSaveData.length > 0) {
                        allSaveData = [...workflowSaveData, ...allSaveData];
                    }
                }
            }

            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/Base/SaveActions', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                workflows.forEach(workflow => workflow.CanSave = true);
                if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                    resolve("Successfully saved review");
                }
                else {
                    reject("There was an unexpected error saving review")
                }
            };

            if (allSaveData.length > 0) {
                xhr.send(JSON.stringify(allSaveData));
            } else {
                workflows.forEach(workflow => workflow.CanSave = true);
                resolve("Nothing to save");
            }

        });
    }
}

abstract class WorkflowActionResponse {
    ActionPK: number;
    ActionCode: string;
    ActionType: string;
    WorkflowInstanceFK: number;
    WorkflowInstanceActionResponsePK: number;
    RowNbr: number;
    IsRequired: boolean;
    Element: HTMLElement;
    IsModalAction: boolean;
    ModalContainerId: string;
    IsComplete: boolean;
    IsReadOnly: boolean;
    protected SaveItem: WorkflowInstanceActionResponseSave; 

    constructor(actionElement: HTMLElement) {
        this.Element = actionElement;
        this.ActionType = "actionType" in actionElement.dataset ? actionElement.dataset.actionType : null;
        this.ActionPK = "actionPk" in actionElement.dataset ? parseInt(actionElement.dataset.actionPk) : null;
        this.ActionCode = "actionCode" in actionElement.dataset ? actionElement.dataset.actionCode : null;
        this.ActionType = "actionType" in actionElement.dataset ? actionElement.dataset.actionType : null;
        this.WorkflowInstanceFK = "workflowInstanceFk" in actionElement.dataset ? parseInt(actionElement.dataset.workflowInstanceFk) : null;
        this.WorkflowInstanceActionResponsePK = "workflowInstanceActionResponsePk" in actionElement.dataset && parseInt(actionElement.dataset.workflowInstanceActionResponsePk) > 0 ? parseInt(actionElement.dataset.workflowInstanceActionResponsePk) : null;
        this.RowNbr = "row" in actionElement.dataset ? parseInt(actionElement.dataset.row) : null;
        this.ActionType = "actionType" in actionElement.dataset ? actionElement.dataset.actionType : null;
        this.IsRequired = "required" in actionElement.dataset ? true : false;
        this.IsModalAction = "modalAction" in actionElement.dataset ? true: false;
        this.IsComplete = "isComplete" in actionElement.dataset ? true : false;
        this.IsReadOnly = "isReadOnly" in actionElement.dataset ? true : false;
        this.ModalContainerId = "modalId" in actionElement.dataset ? actionElement.dataset.modalId : null;
        this.SaveItem = {
            ActionPK: this.ActionPK,
            IsDeletedInd: false,
            WorkflowInstanceFK: this.WorkflowInstanceFK,
            RowNbr: this.RowNbr,
            WorkflowInstanceActionResponsePK: this.WorkflowInstanceActionResponsePK
        }
    }

    abstract GetSaveItem(): WorkflowInstanceActionResponseSave;
    abstract HasValue(): boolean;
    abstract HasChanged(): boolean;
}

class WorkflowActionResponseInput extends WorkflowActionResponse {
    InputElement: HTMLInputElement;
    OriginalInputValue: string;

    constructor(actionElement: HTMLInputElement) {
        super(actionElement);
        this.InputElement = actionElement;
        this.OriginalInputValue = this.InputElement.value;
    }

    GetSaveItem() {
        let that = this;

        if (that.HasValue() || that.WorkflowInstanceActionResponsePK != null) {
            that.SaveItem.TextValue = that.InputElement.value;
            return that.SaveItem;
        } else {
            return null;
        }
    }

    HasValue() {
        return this.InputElement.value != "";
    }

    HasChanged() {
        return this.OriginalInputValue != this.InputElement.value;
    }
}

class WorkflowActionResponseTextArea extends WorkflowActionResponse {
    TextAreaElement: HTMLTextAreaElement;
    OriginalTextValue: string;

    constructor(actionElement: HTMLTextAreaElement) {
        super(actionElement);
        this.TextAreaElement = actionElement;
        this.OriginalTextValue = this.TextAreaElement.value;
    }

    GetSaveItem() {
        let that = this;

        if (that.ActionType == "additionalFeedbackToLEA" || that.ActionType == "internalComments") {
            if (that.TextAreaElement.value != that.OriginalTextValue) {
                that.SaveItem.TextValue = that.TextAreaElement.value;
                return that.SaveItem;
            } else {
                return null;
            }
        }

        if (that.HasValue() || that.WorkflowInstanceActionResponsePK != null) {
            that.SaveItem.TextValue = that.TextAreaElement.value;
            return that.SaveItem;
        } else {
            return null;
        }
    }

    HasValue() {
        return this.TextAreaElement.value != "";
    }

    HasChanged() {
        return this.OriginalTextValue != this.TextAreaElement.value;
    }
}

class WorkflowActionResponseSelect extends WorkflowActionResponse {
    SelectElement: HTMLSelectElement;
    OriginalLookupCodeFK: number;
    OriginalEmailTemplateFK: number;

    constructor(actionElement: HTMLSelectElement) {
        super(actionElement);
        let that = this;
        that.SelectElement = actionElement;

        if (that.ActionType == "emailTemplateSelect") {
            that.OriginalEmailTemplateFK = parseInt(that.SelectElement.value);
        } else {
            that.OriginalLookupCodeFK = parseInt(that.SelectElement.value);
        }
    }

    GetSaveItem() {
        let that = this;

        if (that.ActionType == "emailTemplateSelect") {
            if (that.HasValue() || that.WorkflowInstanceActionResponsePK != null) {
                that.SaveItem.EmailTemplateFK = parseInt(that.SelectElement.value);
                return that.SaveItem;
            }     
        } else {
            if (that.HasValue() || that.WorkflowInstanceActionResponsePK != null) {
                that.SaveItem.LookupCodeFK = parseInt(that.SelectElement.value);
                return that.SaveItem;
            }
        }

        return null;
    }

    HasValue() {
        return this.SelectElement.selectedIndex > 0;
    }

    HasChanged() {
        let that = this;
        if (that.ActionType == "emailTemplateSelect") {
            return that.OriginalEmailTemplateFK != that.SelectElement.selectedIndex;
        } else {
            return that.OriginalLookupCodeFK != that.SelectElement.selectedIndex;
        }
    }
}

class WorkflowActionResponseRadio extends WorkflowActionResponse {
    WorkflowInstanceActionResponsePK: number;
    RadioElements: HTMLInputElement[];
    OriginalLookupCodeFK: number;
    RadioName: string;

    constructor(actionElement: HTMLInputElement) {
        super(actionElement);
        let that = this;
        //Get all radio inputs with this name
        that.RadioElements = [];
        that.RadioName = actionElement.name;
        let radioInputs = document.querySelectorAll(`[name='${actionElement.name}']`);
        for (let radioInput of radioInputs) {
            let radioElement = <HTMLInputElement>radioInput;
            that.RadioElements.push(radioElement);
            if (radioElement.checked) {
                that.OriginalLookupCodeFK = parseInt(radioElement.value);
            }
        }
    }

    GetSaveItem() {
        let that = this;
        for (let radioElement of that.RadioElements) {
            if (radioElement.checked && (that.HasValue() || that.WorkflowInstanceActionResponsePK != null)) {
                that.SaveItem.LookupCodeFK = parseInt(radioElement.value);
                return that.SaveItem;
            }
        }

        return null;
    }

    HasValue() {
        let that = this;
        for (let radioElement of that.RadioElements) {
            if (radioElement.checked) {
                return true;
            }
        }

        return false;
    }

    HasChanged() {
        let that = this;
        let newLookupCodeFK: number;
        for (let radioElement of that.RadioElements) {
            if (radioElement.checked && (that.HasValue() || that.WorkflowInstanceActionResponsePK != null)) {
                newLookupCodeFK = parseInt(radioElement.value);
                break;
            }
        }
        return newLookupCodeFK != this.OriginalLookupCodeFK;
    }
}

class WorkflowActionResponseButton extends WorkflowActionResponse {
    ButtonElement: HTMLButtonElement;
    SubmitAction: boolean;

    constructor(actionElement: HTMLButtonElement) {
        super(actionElement);
        this.ButtonElement = actionElement;
        this.SubmitAction = false;
    }

    GetSaveItem() {
        let that = this;
        if (that.SubmitAction) {
            that.SaveItem.TextValue = "Submitted";
            return that.SaveItem;
        } else {
            return null;
        }
    }

    HasValue() {
        return false;
    }

    HasChanged() {
        return this.SubmitAction;
    }
}

class WorkflowActionResponseCheckbox extends WorkflowActionResponse {
    CheckboxElement: HTMLInputElement;
    OriginalIsChecked: boolean;

    constructor(actionElement: HTMLInputElement) {
        super(actionElement);
        let that = this;
        that.CheckboxElement = actionElement;
        that.OriginalIsChecked = that.CheckboxElement.checked;
    }

    GetSaveItem() {
        let that = this;

        if (that.HasValue() || that.WorkflowInstanceActionResponsePK != null) {

            if ("lookupCodeFk" in that.CheckboxElement.dataset) {
                that.SaveItem.LookupCodeFK = parseInt(that.CheckboxElement.dataset.lookupCodeFk);
            }

            if (that.CheckboxElement.checked) {
                that.SaveItem.TextValue = "on";
            } else {
                that.SaveItem.TextValue = "off";
            }

            return that.SaveItem;
     
        } else {
            return null;
        }
    }

    HasValue() {
        return this.CheckboxElement.checked;
    }

    HasChanged() {
        return this.OriginalIsChecked != this.CheckboxElement.checked;
    }
}

class WorkflowInstanceActionResponseSave {
    WorkflowInstanceActionResponsePK?: number;   
    WorkflowInstanceFK: number;
    ActionPK: number;
    TextValue?: string;
    LookupCodeFK?: number;
    FileUploadFK?: number;
    EmailTemplateFK?: number;
    IsDeletedInd: boolean;
    RowNbr: number;
}

class WorkflowConfirmDialog {
    ConfirmModal: Modal;
    ConfirmEventListener: EventListener;
    CancelEventListener: EventListener;

    constructor() {
        this.ConfirmModal = new Modal("workflowConfirmModal", null);
    }

    confirm(confirmText:string, returnFocusTo: string) {
        let that = this;
        let modalElement = document.getElementById(that.ConfirmModal.id);
        if (modalElement != null) {
            let text = modalElement.querySelector("#workflowConfirmText");
            if (text != null) {
                text.innerHTML = confirmText;
            }
        }
        return new Promise((resolve, reject) => {

            that.ConfirmModal.show();
            that.ConfirmModal.callingId = returnFocusTo;

            let confirmButton = document.getElementById("workflowConfirmButton") as HTMLButtonElement;
            if (confirmButton != null) {
                if (that.ConfirmEventListener !== undefined) {
                    confirmButton.removeEventListener("click", that.ConfirmEventListener);
                }
                confirmButton.addEventListener("click", that.ConfirmEventListener = function () {
                    that.ConfirmModal.hide();
                    resolve(true);
                });
            } else {
                that.ConfirmModal.hide();
                reject("An unexpected error occurred. Please try again later.");
            }

            let cancelButton = document.getElementById("workflowCancelButton") as HTMLButtonElement;
            if (cancelButton != null) {
                if (that.CancelEventListener !== undefined) {
                    cancelButton.removeEventListener("click", that.CancelEventListener);
                }
                cancelButton.addEventListener("click", that.CancelEventListener = function () {
                    that.ConfirmModal.hide();
                    resolve(false);
                });
            } else {
                that.ConfirmModal.hide();
                reject("An unexpected error occurred. Please try again later.");
            }
        });
    }
}