/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />

//Approvals and Signatures
class ApprovalsAndSignatures {

    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["approvalsAndSignaturesField", "approvalsAndSignaturesFileField"];

        let approvalsAndSignaturesSaveButton = document.getElementById("saveApprovalsAndSignatures");
        if (approvalsAndSignaturesSaveButton !== null)
            approvalsAndSignaturesSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        let fileUploader = document.getElementById("approvalsAndSignaturesBoardApproval");
        if (fileUploader !== null) {
            fileUploader.addEventListener("change", (e: Event) => this.uploadFile(<HTMLInputElement>e.srcElement));
        }

        this._core.leftnav(this);

        this.setOptional();
        this.setRequired();

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }

        let copyButton = <HTMLInputElement>document.getElementById("copyToSchoolImprovementPlan");
        if (copyButton !== null) {
            copyButton.addEventListener("click", (e: Event) => this.copyPlan(<HTMLButtonElement>e.srcElement));
        }

        let deleteFileButtons = document.getElementsByClassName("deleteFile") as HTMLCollectionOf<HTMLButtonElement>;
        for (let deleteButton of deleteFileButtons) {
            if ("planpropertyfilepk" in deleteButton.dataset && parseInt(deleteButton.dataset.planpropertyfilepk) > 0) {
                deleteButton.addEventListener("click", (e: Event) => this.showDeleteFileConfirmation(deleteButton));
            }
        }

        let deleteFileConfirmButton = <HTMLButtonElement>document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e: Event) => this.deleteFileConfirm(deleteFileConfirmButton));

        let deleteFileCancelButton = <HTMLButtonElement>document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e: Event) => this.deleteFileCancel());


        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let that = this;
        let exports = document.getElementsByClassName("fullReport") as HTMLCollectionOf<HTMLLinkElement>;
        for (let exportOption of exports) {
            exportOption.addEventListener("click", function () {
                Core.showLoader();
                //this needs to get reworked if we add any other reports to the dropdowns-- see outcomebasedreports
                let core = that._core;
                let url = "/ExportDocx/FullReportCompPlanExportExport";
                let planfk = parseInt(exportOption.dataset.planfk);
                let templatefk = parseInt(exportOption.dataset.templatefk);
                //let reportTypeCode = exportOption.dataset.reporttypecode;
                //if (typeof(reportTypeCode) !== "undefined" && reportTypeCode !== "") {
                //    url = `/ExportDocx/FullReport${reportTypeCode}Export`;
                //}
                let xhr = new XMLHttpRequest();
                xhr.open('POST', url, true);
                xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
                xhr.responseType = "blob";
                xhr.onload = function () {
                    Core.hideLoader();
                    if (xhr.status === 200) {
                        let blob = this.response;
                        let filename = "planexport";
                        let planDetails = document.querySelector(`.planContainer[data-planfk='${planfk}'] .planTypeDetails`);
                        if (planDetails != null) {
                            filename = planDetails.innerHTML;
                        }
                        filename = filename + ".docx";
                        if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                            window.navigator.msSaveBlob(blob, filename);
                        }
                        else {
                            var a = document.createElement("a");
                            var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                            document.body.appendChild(a);
                            a.style.display = "none";
                            a.href = blobUrl;
                            a.download = filename;
                            a.click();
                        }
                    }
                    else {
                        Core.createHTMLAlert("alertMessageDiv", "There was an issue during export to Word. Please try again later.", 'error', 3000, null);
                    }
                };
                xhr.send(`planFK=${planfk}&templateFK=${templatefk}`);

                //Get all components for given template
                //let xhr = new XMLHttpRequest();
                //xhr.open('POST', '/OutcomeBasedReports/FullReportCompPlanExportExport', true);
                //xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
                //xhr.responseType = 'blob';
                //xhr.onload = function () {

                //    Core.hideLoader();

                //    if (xhr.status === 200) {
                //        let blob = this.response;
                //        let filename = exportOption.textContent;
                //        filename = filename + ".pdf";

                //        if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                //            window.navigator.msSaveBlob(blob, filename);
                //        } else {
                //            var a = <HTMLAnchorElement>document.createElement("a");
                //            var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                //            document.body.appendChild(a);
                //            a.style.display = "none";
                //            a.href = blobUrl;
                //            a.download = filename;
                //            a.click();
                //        }
                //    } else {
                //        Core.createHTMLAlert("alertMessageDiv", "There was an issue generating this report. Please try again later.", 'error', 3000, null);
                //    }
                //}
                //xhr.send("planFK=" + exportOption.dataset.planfk + "&templateFK=" + exportOption.dataset.templatefk);
            });
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    uploadFile(e: HTMLInputElement) {
        let core  = this._core;
        Core.showLoader();
        let uploadMinutesForm = <HTMLFormElement>document.getElementById("uploadMinutes");
        let formData = new FormData(uploadMinutesForm);

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/ApprovalsAndSignatures/UploadFile', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file has been successfully uploaded! Save your progress to see the updated list of files.", 'success', 3000, null);

                        let formFile = <HTMLInputElement>document.getElementById("approvalsAndSignaturesBoardApproval");
                        formFile.value = "";
                        formFile.dataset.hasuploaded = "true";
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }

    showDeleteFileConfirmation(deleteButton: HTMLButtonElement) {
        let planPropertyFilePK = deleteButton.dataset.planpropertyfilepk;

        if (planPropertyFilePK && parseInt(planPropertyFilePK) > 0) {
            let modal: Modal = new Modal("deleteFileModal", null);
            let deleteConfirmButton = <HTMLButtonElement>document.getElementById("deleteFileConfirm");
            deleteConfirmButton.dataset.planpropertyfilepk = planPropertyFilePK;
            modal.show();
        }
    }

    deleteFileConfirm(confirmButton: HTMLButtonElement) {
        let core = this._core;
        Core.showLoader();

        let planPropertyFilePK = confirmButton.dataset.planpropertyfilepk;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/ApprovalsAndSignatures/DeleteFiles', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file has been successfully deleted.", 'success', 3000, null);

                        let containerElement = <HTMLElement>document.querySelector(`.uploadFileColumn[data-planpropertyfilepk='${planPropertyFilePK}']`);
                        if (containerElement !== null) {
                            containerElement.parentNode.removeChild(containerElement);
                        }
                        
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting file.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file removal failed. Please try again.", 'error', 3000, null);
            }

            let modal: Modal = new Modal("deleteFileModal", null);
            modal.hide();
        };
        xhr.send(JSON.stringify([planPropertyFilePK]));
    }

    deleteFileCancel() {
        let modal: Modal = new Modal("deleteFileModal", null);
        modal.hide();
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planPK = 0;
        let formElement = document.getElementById("approvalsAndSignaturesForm");
        let inputs = document.getElementsByClassName("approvalsAndSignaturesField");

        let numberRequired = 0;

        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.dataset.percent === "1.00") {
                numberRequired++;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/ApprovalsAndSignatures/SaveApprovalsAndSignatures', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        xhr.send(JSON.stringify(allSaveElements));
    }

    setOptional() {
        let superintendentSignatureOptional = <HTMLInputElement>document.querySelector('input[name="hdnSuperintendentSignatureOptional"]');
        let fileUploadOptional = <HTMLInputElement>document.querySelector('input[name="hndFileUploadOptional"]');
        let dateOfApprovalOptional = <HTMLInputElement>document.querySelector('input[name="hndDateOfApprovalOptional"]');
        let approvalsAndSignaturesChiefAdministatorField = <HTMLInputElement>document.querySelector('input[name="approvalsAndSignaturesChiefAdministator"]');
        let approvalsAndSignaturesChiefAdministatorDateField = <HTMLInputElement>document.querySelector('input[name="approvalsAndSignaturesChiefAdministatorDate"]');
        let fileField = <HTMLInputElement>document.querySelector('input[name="file"]');
        let approvalsAndSignaturesBoardApprovalDateField = <HTMLInputElement>document.querySelector('input[name="approvalsAndSignaturesBoardApprovalDate"]');

        //For Title I Schoolwide Plans  (incuding Single Entity)-  do not need Superintendent signature  and Affirmation statement requirement (does not apply to them).
        if (superintendentSignatureOptional !== null && superintendentSignatureOptional.value == "true") {
            if (approvalsAndSignaturesChiefAdministatorField !== null) {
                this._core.forceElementOptional(approvalsAndSignaturesChiefAdministatorField);
            }
            if (approvalsAndSignaturesChiefAdministatorDateField !== null) {
                this._core.forceElementOptional(approvalsAndSignaturesChiefAdministatorDateField);
            }
        }
        if (fileUploadOptional !== null && fileUploadOptional.value == "true" && fileField !== null) {
            this._core.forceElementOptional(fileField);
        }
        if (dateOfApprovalOptional !== null && dateOfApprovalOptional.value == "true" && approvalsAndSignaturesBoardApprovalDateField !== null) {
            this._core.forceElementOptional(approvalsAndSignaturesBoardApprovalDateField);
        }
    }

    setRequired() {
        let schoolImprovementFacilitatorSignatureRequired = <HTMLInputElement>document.querySelector('input[name="hdnSchoolImprovementFacilitatorSignatureRequired"]');
        let schoolSignaturesSchoolImprovementFacilitatorField = <HTMLInputElement>document.querySelector('input[name="schoolSignaturesSchoolImprovementFacilitator"]');
        let schoolSignaturesSchoolImprovementFacilitatorDateField = <HTMLInputElement>document.querySelector('input[name="schoolSignaturesSchoolImprovementFacilitatorDate"]');

        //For CSI and ATSI Title 1 Plans  (incuding Single Entity)-  need School Improvement Facilitator signature
        if (schoolImprovementFacilitatorSignatureRequired !== null && schoolImprovementFacilitatorSignatureRequired.value == "true") {
            if (schoolSignaturesSchoolImprovementFacilitatorField !== null) {
                this._core.forceElementRequired(schoolSignaturesSchoolImprovementFacilitatorField);
            }
            if (schoolSignaturesSchoolImprovementFacilitatorDateField !== null) {
                this._core.forceElementRequired(schoolSignaturesSchoolImprovementFacilitatorDateField);
            }
        }
    }

    copyPlan(e: HTMLButtonElement) {
        let core = this._core;
        Core.showLoader();

        let planPK = 0;
        let formElement = document.getElementById("approvalsAndSignaturesForm");
        planPK = parseInt(formElement.dataset.planfk);

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/ApprovalsAndSignatures/CopyPlan', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "Successfully copied information to new School Improvement Plan.", 'success', 3000, null);

                        e.classList.add("disabled");
                        e.setAttribute("disabled", "disabled");

                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The plan failed to copy. Please try again.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The plan failed to copy. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send("planPK=" + planPK);
    }
}