/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />

// FederalFiscalProfile
class FederalFiscalProfile {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["federalFiscalProfileField"];

        let federalFiscalProfileSaveButton = document.getElementById("federalFiscalProfileSave");
        if (federalFiscalProfileSaveButton !== null)
            federalFiscalProfileSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let refreshButton = document.getElementById("resetProfile");
        if (refreshButton !== null)
            refreshButton.addEventListener("click", (e: Event) => Core.refreshProfile("federalFiscalProfileForm"));
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("federalFiscalProfileForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("federalFiscalProfileField");

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsFiscal/SaveFederalFiscalProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

// FederalFiscalSignatures
class FederalFiscalSignatures {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["federalFiscalSignaturesField", "federalFiscalSignaturesCheckboxField"];

        let federalFiscalSignaturesSaveButton = document.getElementById("federalFiscalSignaturesSave");
        if (federalFiscalSignaturesSaveButton !== null)
            federalFiscalSignaturesSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
            this.assuranceCheck();
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("federalFiscalSignaturesForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("federalFiscalSignaturesField");

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let checkboxes = document.getElementsByClassName("federalFiscalSignaturesCheckboxField");
        for (let ele of checkboxes) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.checked || hadValue) {
                let val = "";
                if (element.checked)
                    val = "on";
                else
                    val = "off";

                let saveItem: IPlanPropertyExtended = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                    ButtonRow: 0
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsFiscal/SaveFederalFiscalSignatures', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    assuranceCheck() {
        const allAssurances = document.getElementsByClassName("federalFiscalSignaturesCheckboxField");
        let showAssuranceWarning = false;
        for (const assurance of allAssurances) {
            const ele = <HTMLInputElement>assurance;

            if (!ele.checked)
                showAssuranceWarning = true;
        }

        const warningElement = <HTMLDivElement>document.getElementById("assuranceWarning");
        if (showAssuranceWarning)
            warningElement.classList.remove("hide");
        else
            warningElement.classList.add("hide");
    }
}

// FederalFiscalSummary
class FederalFiscalSummary {
    private _core: Core;
    constructor() {
        this._core = new Core();
        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.back(e));

        let submitButton = document.getElementById("submitPlanButton");
        if (submitButton !== null)
            submitButton.addEventListener("click", (e: Event) => this.submit(e));

        this._core.leftnav(this);
    }

    back(e: Event) {
        let newPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }

    getCore() {
        return this._core;
    }

    submit(e: Event) {
        let element = <HTMLButtonElement>e.target;
        if (!element.classList.contains("disabled")) {
            let planFK = 0;
            planFK = parseInt(element.dataset.planfk);
            let core = this._core;
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/FederalProgramsFiscal/SubmitPlan', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "This report has been submitted for review!", 'success', 3000, window.location.reload());

                    setTimeout(function () { window.location.href = '/Reports/StateRequiredReports' }, 3000);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status + "'", 'error', 3000, null);
                }
            };
            xhr.send("planFK=" + planFK);
        }
    }
}

// FederalFiscalEquipmentInventory
class FederalFiscalEquipmentInventory {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["federalFiscalEquipmentInventoryField", "federalFiscalEquipmentInventoryCheckboxField", "federalFiscalEquipmentInventoryRadioField", "federalFiscalEquipmentInventoryFileUploadField"];

        let federalFiscalEquipmentInventorySaveButton = document.getElementById("federalFiscalEquipmentInventorySave");
        if (federalFiscalEquipmentInventorySaveButton !== null)
            federalFiscalEquipmentInventorySaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this.useFederalFunds();
        this.ownEquipmentInventory();
        this.bindEquipmentTransferred();
        this.bindEquipmentDisposed();
        this.bindDeleteEquipment();
        this.bindOwnEquipment();
        this.bindDeleteFiles();
        this.bindUseFederalFunds();


        this._core.leftnav(this);
        this._core.initializeRequiredFields(this.validationClasses);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.doValidationCustom(this.validationClasses);
        }

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        const insertEquipmentCreateButton = document.getElementById("federalFiscalTechnologyInsertEquipment");
        if (insertEquipmentCreateButton !== null)
            insertEquipmentCreateButton.addEventListener("click", (e: Event) => this.addEquipment());

        const deleteInsertEquipmentCancelButton = document.getElementById("deleteEquipmentCancel");
        if (deleteInsertEquipmentCancelButton !== null)
            deleteInsertEquipmentCancelButton.addEventListener("click", (e: Event) => this.deleteEquipmentCancel());

        const deleteInsertEquipmentConfirmButton = document.getElementById("deleteEquipmentConfirm");
        if (deleteInsertEquipmentConfirmButton !== null)
            deleteInsertEquipmentConfirmButton.addEventListener("click", (e: Event) => this.deleteEquipmentConfirm(e));

        const uploads = document.getElementsByClassName("fileUploaders");
        for (const upload of uploads)
            upload.addEventListener("change", (e: Event) => this.uploadFile(<HTMLInputElement>e.target));

        const deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e: Event) => this.deleteFileConfirm(e));

        const deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e: Event) => this.deleteFileCancel());
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        const allSaveElements = [];
        const allFileElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("federalFiscalEquipmentInventoryForm");
        let planPK = parseInt(formElement.dataset.planfk);

        const textInputs = document.getElementsByClassName("federalFiscalEquipmentInventoryField");
        for (const ele of textInputs) {
            let planPropertyPK = 0;
            const element = <HTMLInputElement>ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        const checkboxes = document.getElementsByClassName("federalFiscalEquipmentInventoryCheckboxField");
        for (const ele of checkboxes) {
            let planPropertyPK = 0;
            const element = <HTMLInputElement>ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            const dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.checked || hadValue) {
                let val = "";
                if (element.checked)
                    val = "on";
                else
                    val = "off";

                let saveItem: IPlanPropertyExtended = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                    ButtonRow: 0
                };

                allSaveElements.push(saveItem);
            }
        }

        const radios = document.getElementsByClassName("federalFiscalEquipmentInventoryRadioField");
        for (const ele of radios) {
            let planPropertyPK = 0;
            const element = <HTMLInputElement>ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                if (element.value !== "" || hadValue) {
                    let saveItem: IPlanProperty = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };

                    allSaveElements.push(saveItem);
                }
            }
        }

        const files = document.getElementsByClassName("federalFiscalEquipmentInventoryFile");
        for (let ele of files) {
            let element = <HTMLElement>ele;
            let sequenceNbr = parseInt(element.dataset.sequencenbr);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk);
            let fileUploadPK = 0;
            let propertyPK = parseInt(element.dataset.propertypk);
            let saveItem = {
                PlanPropertyFilePK: planPropertyFilePK,
                SequenceNbr: sequenceNbr,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanFK: planPK,
                PlanPropertyPlanPropertyFilePK: 0
            };
            allFileElements.push(saveItem);
        }

        const allData = {
            "SaveData": allSaveElements,
            "FileData": allFileElements,
            "PlanFK": planPK
        };

        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsFiscal/SaveFederalFiscalEquipmentInventory', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", `Request failed.  Returned status of '${xhr.status}'`, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0 && allFileElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }

    bindDeleteEquipment() {
        const allDeletes = document.getElementsByClassName("deleteThisEquipment");
        for (const del of allDeletes)
            del.addEventListener("click", (e: Event) => this.deleteThisEquipment(e));
    }

    deleteThisEquipment(e) {
        const buttonElement = <HTMLButtonElement>e.target;
        const row = buttonElement.dataset.row;

        const modal: Modal = new Modal("deleteEquipmentModal", null);
        modal.addAttributeToElement("deleteEquipmentModal", "#deleteEquipmentConfirm", "row", row);
        modal.show();
    }

    deleteEquipmentConfirm(e: Event) {
        const buttonElement = <HTMLButtonElement>e.target;
        const planProps = [];
        const row = buttonElement.dataset.row;

        const container = <HTMLDivElement>document.querySelector(`.equipmentContainer[data-row='${row}']`);
        const allElements = container.querySelectorAll(`.equipmentField[data-row='${row}']`);
        for (const ele of allElements) {
            const element = <HTMLElement>ele;
            const planProp = element.dataset.planpropertypk;

            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }

        const accElement = <HTMLButtonElement>document.querySelector(`.Accordion-trigger[data-row='${row}']`);
        const accPlanPropPK = accElement.dataset.planpropertypk;

        planProps.push(parseInt(accPlanPropPK));

        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsFiscal/DeleteEquipment', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();

                const accordionElement = <HTMLButtonElement>document.querySelector(`.Accordion-trigger[data-row='${row}']`);
                accordionElement.parentElement.parentElement.parentElement.remove();

                const modal: Modal = new Modal("deleteEquipmentModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The equity worksheet has been removed.", 'success', 3000, null);
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }

    deleteEquipmentCancel() {
        const modal: Modal = new Modal("deleteEquipmentModal", null);
        modal.hide();
    }

    addEquipment() {
        const equipment = <HTMLInputElement>document.getElementById("federalFiscalTechnologyEquipmentNameorQuickReference");
        const equipmentValue = equipment.value;

        const planForm = document.getElementById("federalFiscalEquipmentInventoryForm");
        const planFK = planForm.dataset.planfk;

        const that = this;
        let newRow = -1;

        if (equipmentValue !== "") {
            Core.showLoader();

            const allAccordions = document.getElementsByClassName("Accordion-panel");
            if (allAccordions && allAccordions.length > 0) {
                for (const accordion of allAccordions) {
                    const accord = <HTMLDivElement>accordion;
                    if (parseInt(accord.dataset.row) > newRow)
                        newRow = parseInt(accord.dataset.row);
                }
            }

            newRow++;

            const data = {
                "EquipmentName": equipmentValue,
                "PlanFK": planFK,
                "Row": newRow
            };

            const xhr = new XMLHttpRequest();
            xhr.open('POST', '/FederalProgramsFiscal/AddEquipment', true);
            xhr.setRequestHeader('Content-type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    const container = document.getElementById("equipment");
                    const newDiv = <HTMLDivElement>document.createElement("div");

                    newDiv.innerHTML = xhr.responseText;

                    container.appendChild(newDiv);

                    const idControl = <HTMLButtonElement>newDiv.querySelector(".Accordion h2 button");
                    const id = idControl.id;

                    new CustomAccordion(id);

                    that.bindDeleteEquipment();
                    that.bindEquipmentTransferred();
                    that.bindEquipmentDisposed();

                    equipment.value = "";

                    Core.createHTMLAlert("alertMessageDiv", "Equipment record added!", 'success', 2000, null);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", `Request failed.  Returned status of '${xhr.status}'`, 'error', 3000, null);
                    Core.hideLoader();
                }
            };
            xhr.send(JSON.stringify(data));
        } else {
            Core.createHTMLAlert("alertMessageDiv", `Enter a value in the Equipment Name or Quick Reference field to add a record.`, 'warning', 3000, null);
        }
    }

    useFederalFunds() {
        const useFederalFunds = <HTMLInputElement>document.querySelector(".useFederalFunds:checked");
        if (useFederalFunds) {
            const value = useFederalFunds.dataset.lookupcode;

            if (value === "yes") {
                const allElementsToDisable = document.querySelectorAll(".equipmentInventory");
                for (const element of allElementsToDisable) {
                    if (element instanceof HTMLButtonElement) {
                        const ele = <HTMLButtonElement>element;

                        ele.disabled = false;
                    } else {
                        const ele = <HTMLInputElement>element;
                        ele.disabled = false;
                        ele.readOnly = false;

                        if(ele.dataset.originalpercent === "1.00")
                            this._core.forceElementRequired(ele);
                    }
                }
            } else {
                //Nothing else needs to be filled in.
                const allElementsToEnable = document.querySelectorAll(".equipmentInventory");
                for (const element of allElementsToEnable) {
                    if (element instanceof HTMLButtonElement) {
                        const ele = <HTMLButtonElement>element;

                        ele.disabled = true;
                    } else {
                        const ele = <HTMLInputElement>element;
                        ele.disabled = true;
                        ele.readOnly = true;

                        this._core.forceElementOptional(ele);
                    }
                }
            }
        }
    }

    ownEquipmentInventory() {
        const ownEquipmentInventory = document.querySelector<HTMLInputElement>(".ownEquipmentInventory:checked");
        if (ownEquipmentInventory !== null) {
            const value = ownEquipmentInventory.dataset.lookupcode;

            const federalFiscalTechnologyEquipmentNameorQuickReference = <HTMLInputElement>document.getElementById("federalFiscalTechnologyEquipmentNameorQuickReference");
            const federalFiscalTechnologyInsertEquipment = <HTMLButtonElement>document.getElementById("federalFiscalTechnologyInsertEquipment");
            const federalFiscalEquipmentInventoryUploadLEAInventoryManagementofEquipment = <HTMLInputElement>document.getElementById("federalFiscalEquipmentInventoryUploadLEAInventoryManagementofEquipment");

            let hasAccordions = false;
            const accordions = document.getElementsByClassName("Accordion");
            if (accordions && accordions.length > 0)
                hasAccordions = true;

            if (value === "no") {
                federalFiscalTechnologyEquipmentNameorQuickReference.disabled = false;
                federalFiscalTechnologyEquipmentNameorQuickReference.readOnly = false;
                federalFiscalTechnologyInsertEquipment.disabled = false;
                federalFiscalEquipmentInventoryUploadLEAInventoryManagementofEquipment.disabled = true;
                this._core.forceElementOptional(federalFiscalEquipmentInventoryUploadLEAInventoryManagementofEquipment);

                if (hasAccordions) {
                    const allElementsToEnable = document.querySelectorAll(".equipmentField");
                    for (const element of allElementsToEnable) {
                        const ele = <HTMLInputElement>element;
                        ele.disabled = false;
                        ele.readOnly = false;

                        if (ele.dataset.originalpercent === "1.00")
                            this._core.forceElementRequired(ele);
                    }
                }
            } else {
                federalFiscalTechnologyEquipmentNameorQuickReference.disabled = true;
                federalFiscalTechnologyEquipmentNameorQuickReference.readOnly = true;
                federalFiscalTechnologyInsertEquipment.disabled = true;
                federalFiscalEquipmentInventoryUploadLEAInventoryManagementofEquipment.disabled = false;
                this._core.forceElementRequired(federalFiscalEquipmentInventoryUploadLEAInventoryManagementofEquipment);

                if (hasAccordions) {
                    const allElementsToDisable = document.querySelectorAll(".equipmentField");
                    for (const element of allElementsToDisable) {
                        const ele = <HTMLInputElement>element;
                        ele.disabled = true;
                        ele.readOnly = true;

                        this._core.forceElementOptional(ele);
                    }
                }
            }
        }
    }

    equipmentTransferred(element: HTMLInputElement) {
        const row = element.dataset.row;

        const targetElements = document.querySelectorAll(`.equipmentTransferredTarget[data-row='${row}']`);
        if (!element.checked) {
            for (const targetElement of targetElements) {
                const ele = <HTMLInputElement>targetElement;
                ele.readOnly = true;
                ele.disabled = true;

                this._core.forceElementOptional(ele);
            }
        } else {
            for (const targetElement of targetElements) {
                const ele = <HTMLInputElement>targetElement;
                ele.readOnly = false;
                ele.disabled = false;

                if(ele.dataset.originalpercent === "1.00")
                    this._core.forceElementRequired(ele);
            }
        }
    }

    uploadFile(e: HTMLInputElement) {
        Core.showLoader();
        let formName = e.dataset.formname;

        const uploadSampleForm = <HTMLFormElement>document.getElementById(formName);
        const formData = new FormData(uploadSampleForm);
        const propertyPK = e.dataset.propertypk;
        const propertyCode = e.id;
        const planPropertyPK = "0"; // e.dataset.planpropertypk;
        const file = e.dataset.file;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsFiscal/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();

                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);

                        e.value = "";

                        let fileList = document.querySelector(`.uploadFileList[data-file='${file}']`);

                        let fileWrapper = <HTMLDivElement>document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = <HTMLDivElement>document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = <HTMLAnchorElement>document.createElement("a");
                        fileA.dataset.planpropertyfilepk = res.payload.planPropertyFilePK;
                        fileA.dataset.planpropertyplanpropertyfilepk = "0";
                        fileA.dataset.planpropertypk = planPropertyPK;
                        fileA.dataset.propertycode = propertyCode;
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.sequencenbr = "0";
                        fileA.dataset.propertypk = propertyPK;
                        fileA.classList.add("federalFiscalEquipmentInventoryFile");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);

                        fileList.appendChild(fileWrapper);

                        let fullContainer = document.querySelector(`.uploadedFiles[data-file='${file}']`);
                        if (fullContainer.classList.contains("hide"))
                            fullContainer.classList.remove("hide");
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }

    bindDeleteFiles() {
        const deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (const deleteFileButton of deleteFileButtons)
            deleteFileButton.addEventListener("click", (e: Event) => this.showDeleteFile(e));
    }

    showDeleteFile(e: Event) {
        const buttonElement = <HTMLButtonElement>e.target;
        const planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        const planFK = buttonElement.dataset.planfk;
        const fileType = buttonElement.dataset.filetype;

        const modal: Modal = new Modal("deleteFileModal", null);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "planpropertyfilepk", planPropertyFilePK);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "filetype", fileType);
        modal.show();
    }

    deleteFileConfirm(e: Event) {
        const buttonElement = <HTMLButtonElement>e.target;
        const planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        const planFK = parseInt(buttonElement.dataset.planfk);
        const fileType = buttonElement.dataset.filetype;
        const that = this;

        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsFiscal/DeleteFileData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    const element = document.querySelector(`.uploadFileColumn[data-planpropertyfilefk='${planPropertyFilePK}'][data-filetype='${fileType}']`);
                    element.remove();

                    const moreFiles = document.querySelectorAll(`.uploadFileColumn[data-filetype='${fileType}']`);
                    if (moreFiles.length === 0) {
                        const uploadsElement = <HTMLDivElement>document.querySelector(`.uploadedFiles[data-filetype='${fileType}']`);
                        if (uploadsElement !== null) {
                            uploadsElement.classList.add("hide");
                        }
                    }

                    Core.createHTMLAlert("alertMessageDiv", "File successfully removed.", 'success', 3000, null);
                } else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the file. Please try again.", 'error', 3000, null);
                }

                Core.hideLoader();

                let modal: Modal = new Modal("deleteFileModal", null);
                modal.hide();
            } else {
                let modal: Modal = new Modal("deleteFileModal", null);
                modal.hide();

                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planPropertyFilePK));
    }

    deleteFileCancel() {
        const modal: Modal = new Modal("deleteFileModal", null);
        modal.hide();
    }

    equipmentDisposed(equipmentDisposedElement: HTMLInputElement) {
        if (equipmentDisposedElement !== null && equipmentDisposedElement.checked) {
            const row = equipmentDisposedElement.dataset.row;

            const element = <HTMLTextAreaElement>document.querySelector(`.equipmentInventory[data-row='${row}'][data-disposed='disposed']`);

            if (equipmentDisposedElement.dataset.lookupcode === "yes") {
                element.disabled = false;
                element.readOnly = false;
                this._core.forceElementRequired(element);
            } else {
                element.disabled = true;
                element.readOnly = true;
                this._core.forceElementOptional(element);
            }
        }
    }

    bindEquipmentDisposed() {
        const equipmentDisposedElements = document.getElementsByClassName("disposed");
        for (const equipmentDisposedElement of equipmentDisposedElements) {
            equipmentDisposedElement.addEventListener("change", (e: Event) => this.equipmentDisposed(e.target as HTMLInputElement));
            this.equipmentDisposed(equipmentDisposedElement as HTMLInputElement);
        }
    }

    bindEquipmentTransferred() {
        const equipmentTransferreds = document.getElementsByClassName("equipmentTransferred");
        for (const equipmentTransferred of equipmentTransferreds) {
            equipmentTransferred.addEventListener("change", (e: Event) => this.equipmentTransferred(<HTMLInputElement>e.target));
            this.equipmentTransferred(<HTMLInputElement>equipmentTransferred);
        }
    }

    bindOwnEquipment() {
        const ownEquipmentInventorys = document.getElementsByClassName("ownEquipmentInventory");
        for (const ownEquipmentInventory of ownEquipmentInventorys)
            ownEquipmentInventory.addEventListener("change", (e: Event) => this.ownEquipmentInventory());
    }

    bindUseFederalFunds() {
        const useFederalFunds = document.getElementsByClassName("useFederalFunds");
        for (const useFederalFund of useFederalFunds)
            useFederalFund.addEventListener("change", (e: Event) => this.useFederalFunds());
    }

    doValidationCustom(allClasses: string[], showMessageOverride?: boolean) {
        let showMessage = showMessageOverride === undefined ? this.clientSideValidationCustom(allClasses) : showMessageOverride;
        let messageContainerColumn = <HTMLElement>document.getElementById("validationColumn");
        let messageContainer = <HTMLElement>document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = <HTMLElement>document.getElementById("validationMessageIcon");

        setTimeout(function () {
            messageContainer.focus();
        }, 500);

        if (showMessage) {

            let message = <HTMLElement>document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";

        } else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";

            let successMessage = <HTMLElement>document.getElementById("saveSuccess");

            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved."
            }
        }
    }

    clientSideValidationCustom(allClasses: string[]): boolean {
        let showMessage: boolean = false;
        let totalErrors = 0;

        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });

        let classesToValidate = formattedAllClasses.join(",");

        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });

        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });

        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });


        const federalFiscalTechnologyEquipmentNameorQuickReferenceElement = <HTMLInputElement>document.getElementById("federalFiscalTechnologyEquipmentNameorQuickReference");
        if (!federalFiscalTechnologyEquipmentNameorQuickReferenceElement.disabled) {
            const allAccordions = document.querySelectorAll(`#equipment .Accordion`);
            if (allAccordions.length === 0) {

                federalFiscalTechnologyEquipmentNameorQuickReferenceElement.classList.add("missing-field");
                federalFiscalTechnologyEquipmentNameorQuickReferenceElement.setAttribute("aria-invalid", "true");
                Core.createErrorLabelForInput(federalFiscalTechnologyEquipmentNameorQuickReferenceElement);
                showMessage = true;
                totalErrors++;
            }
        }

        let allElements = document.querySelectorAll(classesToValidate);
        for (let element of allElements) {
            let alreadyExists = false;
            let htmlElement = <HTMLElement>element;
            if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {

                //check to see if there are other fields the same as this one on this page (i.e. fields that have the same propertypk)
                var otherElements = document.querySelectorAll(`[data-propertypk='${htmlElement.dataset.propertypk}']`);
                for (let otherElement of otherElements) {
                    let otherHtmlElement = <HTMLElement>otherElement;
                    if (otherHtmlElement.classList.contains("missing-field")) {
                        alreadyExists = true;
                    } else {
                        if (otherHtmlElement instanceof HTMLInputElement) {
                            if (otherHtmlElement.hasAttribute("type") && otherHtmlElement.getAttribute("type") === "radio") {
                                //Check to see if a prior group of radio buttons has already been validated
                                let OtherInputElement = <HTMLInputElement>otherHtmlElement;

                                if (OtherInputElement.hasAttribute("name")) {
                                    let radioGroupName = OtherInputElement.getAttribute("name");

                                    let radios = document.getElementsByName(radioGroupName);
                                    let radioIsChecked: boolean = false;
                                    let isAlreadyValidated: boolean = false;

                                    for (var i = 0, length = radios.length; i < length; i++) {
                                        let radioElement = <HTMLInputElement>radios[i];
                                        if (radioElement.checked) {
                                            radioIsChecked = true;
                                        }

                                        if (radioElement.classList.contains("missing-field")) {
                                            isAlreadyValidated = true;
                                        }
                                    }

                                    if (isAlreadyValidated || radioIsChecked) {
                                        alreadyExists = true;
                                    }
                                }
                            } else {
                                let OtherInputElement = <HTMLInputElement>otherHtmlElement;
                                if (OtherInputElement.hasAttribute("type") && otherHtmlElement.getAttribute("type") === "checkbox") {
                                    if (OtherInputElement.checked) {
                                        alreadyExists = true;
                                    }
                                } else {
                                    if (OtherInputElement.value !== "") {
                                        alreadyExists = true;
                                    }
                                }
                            }
                        } else if (otherHtmlElement instanceof HTMLSelectElement) {
                            let otherSelectElement = <HTMLSelectElement>otherHtmlElement;
                            if (otherSelectElement.selectedIndex > 0 && otherSelectElement.options[otherSelectElement.selectedIndex].value !== "") {
                                alreadyExists = true;
                            }
                        } else if (otherHtmlElement instanceof HTMLTextAreaElement) {
                            let otherTextAreaElement = <HTMLTextAreaElement>otherHtmlElement;
                            if (otherTextAreaElement.value !== "") {
                                alreadyExists = true;
                            }
                        } else if ("multiselectvalidate" in otherHtmlElement.dataset && otherHtmlElement.dataset.multiselectvalidate === "true") {
                            //See if any options have been checked in multiselect
                            let multiselectCheckboxes = otherHtmlElement.getElementsByTagName("input") as HTMLCollectionOf<HTMLInputElement>;
                            for (let selectBox of multiselectCheckboxes) {
                                if (selectBox.checked) {
                                    alreadyExists = true;
                                    break;
                                }
                            }
                        }
                    }
                }

                if (!alreadyExists || ("forcerequired" in htmlElement.dataset && htmlElement.dataset.forcerequired === "true")) {
                    if (!element.classList.contains("missing-field")) {
                        //For more custom validation, use data-is-valid to specify whether a field is valid/invalid
                        if ("isValid" in htmlElement.dataset) {
                            if (htmlElement.dataset.isValid === "false") {
                                htmlElement.classList.add("missing-field");
                                htmlElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(htmlElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        } else {
                            if (element instanceof HTMLInputElement) {
                                let inputElement = <HTMLInputElement>element;

                                //Only validate once for radio buttons
                                if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "radio" && !inputElement.checked) {
                                    if (inputElement.hasAttribute("name")) {
                                        let radioName = inputElement.getAttribute("name");

                                        let radioButtons = document.getElementsByName(radioName);
                                        let alreadyValidated: boolean = false;
                                        let isChecked: boolean = false;

                                        for (var i = 0, length = radioButtons.length; i < length; i++) {
                                            let radioElement = <HTMLInputElement>radioButtons[i];
                                            if (radioElement.classList.contains("missing-field")) {
                                                alreadyValidated = true;
                                            }

                                            if (radioElement.checked) {
                                                isChecked = true;
                                            }
                                        }

                                        if (!alreadyValidated && !isChecked) {
                                            inputElement.classList.add("missing-field");
                                            inputElement.setAttribute("aria-invalid", "true");
                                            Core.createErrorLabelForInput(inputElement);
                                            showMessage = true;
                                            totalErrors++;
                                        }
                                    }
                                } else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "file") {
                                    if ("hasuploaded" in inputElement.dataset && inputElement.dataset.hasuploaded != "true") {
                                        inputElement.classList.add("missing-field");
                                        inputElement.setAttribute("aria-invalid", "true");
                                        Core.createErrorLabelForInput(inputElement);
                                        showMessage = true;
                                        totalErrors++;
                                    }
                                } else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "checkbox") {
                                    if (!inputElement.checked) {
                                        inputElement.classList.add("missing-field");
                                        inputElement.setAttribute("aria-invalid", "true");
                                        Core.createErrorLabelForInput(inputElement);
                                        showMessage = true;
                                        totalErrors++;
                                    }
                                } else if (inputElement.value === "") {
                                    inputElement.classList.add("missing-field");
                                    inputElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(inputElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            } else if (element instanceof HTMLSelectElement) {
                                let selectElement = <HTMLSelectElement>element;
                                //if the page select element does not have a place holder it needs custom validation.  do not use this one.
                                if (selectElement.selectedIndex <= 0 || selectElement.options[selectElement.selectedIndex].value === "") {
                                    selectElement.classList.add("missing-field");
                                    selectElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(selectElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            } else if (element instanceof HTMLTextAreaElement) {
                                let textAreaElement = <HTMLTextAreaElement>element;
                                if (textAreaElement.value === "") {
                                    textAreaElement.classList.add("missing-field");
                                    textAreaElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(textAreaElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            } else if ("multiselectvalidate" in htmlElement.dataset && htmlElement.dataset.multiselectvalidate === "true") {
                                let multiselectCheckboxes = htmlElement.getElementsByTagName("input") as HTMLCollectionOf<HTMLInputElement>;
                                let hasSelection = false;

                                for (let selectBox of multiselectCheckboxes) {
                                    if (selectBox.checked) {
                                        hasSelection = true;
                                        break;
                                    }
                                }

                                if (!hasSelection) {
                                    htmlElement.classList.add("missing-field");
                                    htmlElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(htmlElement);
                                    showMessage = true;
                                    totalErrors++;
                                }

                            } else if (htmlElement.classList.contains("multiSelect")) {
                                let multiselectCheckboxes = htmlElement.getElementsByTagName("input") as HTMLCollectionOf<HTMLInputElement>;
                                let hasSelection = false;

                                for (let selectBox of multiselectCheckboxes) {
                                    if (selectBox.checked) {
                                        hasSelection = true;
                                        break;
                                    }
                                }

                                if (!hasSelection) {
                                    htmlElement.classList.add("missing-field");
                                    htmlElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(htmlElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                        }
                    }
                }
            }
        }

        let message = <HTMLDivElement>document.getElementById("validationMessage");

        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        } else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");

        if (goToError !== null) {

            let that = this;

            let firstFocusableEl = <HTMLElement>document.querySelector(".missing-field");

            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");

                        let accordionElement = <HTMLButtonElement>document.getElementById(id);
                        if (!accordionElement.classList.contains("open")) {
                            accordionElement.click();
                        }
                    }

                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    } else {
                        firstFocusableEl.focus();
                    }
                });
            } else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }
}

// FederalFiscalUGGPolicies
class FederalFiscalUGGPolicies {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["federalFiscalUGGPoliciesFileUploadField"];

        let federalFiscalUGGPoliciesSaveButton = document.getElementById("federalFiscalUGGPoliciesSave");
        if (federalFiscalUGGPoliciesSaveButton !== null)
            federalFiscalUGGPoliciesSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        const uploads = document.getElementsByClassName("fileUploaders");
        for (const upload of uploads)
            upload.addEventListener("change", (e: Event) => this.uploadFile(<HTMLInputElement>e.target));

        this.bindDeleteFiles();

        const deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e: Event) => this.deleteFileConfirm(e));

        const deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e: Event) => this.deleteFileCancel());
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("federalFiscalUGGPoliciesForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("federalFiscalUGGPoliciesField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        const allFileElements = [];

        let files = document.getElementsByClassName("federalFiscalUGGPoliciesFile");
        for (let ele of files) {
            let element = <HTMLElement>ele;
            let sequenceNbr = parseInt(element.dataset.sequencenbr);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk);
            let fileUploadPK = parseInt(element.dataset.fileuploadpk);
            let propertyPK = parseInt(element.dataset.propertypk);
            let saveItem = {
                PlanPropertyFilePK: planPropertyFilePK,
                SequenceNbr: sequenceNbr,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanFK: planPK,
                PlanPropertyPlanPropertyFilePK: 0
            };
            allFileElements.push(saveItem);
        }

        const allData = {
            "SaveData": allSaveElements,
            "FileData": allFileElements,
            "PlanFK": planPK
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsFiscal/SaveFederalFiscalUGGPolicies', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                    Core.createHTMLAlert("alertMessageDiv", "Page successefully saved. Please wait.", 'success', 3000, null);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0 && allFileElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }

    uploadFile(e: HTMLInputElement) {
        Core.showLoader();
        let formName = e.dataset.formname;

        const uploadSampleForm = <HTMLFormElement>document.getElementById(formName);
        const formData = new FormData(uploadSampleForm);
        const propertyPK = e.dataset.propertypk;
        const propertyCode = e.id;
        const planPropertyPK = e.dataset.planpropertypk;
        const file = e.dataset.file;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsFiscal/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();

                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);

                        e.value = "";

                        let fileList = document.querySelector(`.uploadFileList[data-file='${file}']`);

                        let fileWrapper = <HTMLDivElement>document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = <HTMLDivElement>document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = <HTMLAnchorElement>document.createElement("a");
                        fileA.dataset.planpropertyfilepk = res.payload.planPropertyFilePK;
                        fileA.dataset.planpropertyplanpropertyfilepk = "0";
                        fileA.dataset.planpropertypk = planPropertyPK;
                        fileA.dataset.propertycode = propertyCode;
                        fileA.dataset.sequencenbr = "0";
                        fileA.dataset.propertypk = propertyPK;
                        fileA.classList.add("federalFiscalUGGPoliciesFile");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);

                        fileList.appendChild(fileWrapper);

                        let fullContainer = document.querySelector(`.uploadedFiles[data-file='${file}']`);
                        if (fullContainer.classList.contains("hide"))
                            fullContainer.classList.remove("hide");
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }

    bindDeleteFiles() {
        const deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (const deleteFileButton of deleteFileButtons)
            deleteFileButton.addEventListener("click", (e: Event) => this.showDeleteFile(e));
    }

    showDeleteFile(e: Event) {
        const buttonElement = <HTMLButtonElement>e.target;
        const planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        const planFK = buttonElement.dataset.planfk;
        const file = buttonElement.dataset.file;

        const modal: Modal = new Modal("deleteFileModal", null);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "planpropertyfilepk", planPropertyFilePK);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "planfk", planFK);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "file", file);
        modal.show();
    }

    deleteFileConfirm(e: Event) {
        const buttonElement = <HTMLButtonElement>e.target;
        const planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        const planFK = parseInt(buttonElement.dataset.planfk);
        const file = buttonElement.dataset.file;
        const that = this;

        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsFiscal/DeleteFileData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    let element = document.querySelector(`.uploadFileColumn[data-planpropertyfilefk='${planPropertyFilePK}'][data-file='${file}']`);
                    element.remove();

                    const moreFiles = document.querySelectorAll(`.uploadFileColumn[data-file='${file}']`);
                    if (moreFiles.length === 0) {
                        const uploadsElement = <HTMLDivElement>document.querySelector(`.uploadedFiles[data-file='${file}']`);
                        if (uploadsElement !== null) {
                            uploadsElement.classList.add("hide");
                        }
                    }

                    Core.createHTMLAlert("alertMessageDiv", "File successfully removed.", 'success', 3000, null);
                } else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the file. Please try again.", 'error', 3000, null);
                }

                Core.hideLoader();

                let modal: Modal = new Modal("deleteFileModal", null);
                modal.hide();
            } else {
                let modal: Modal = new Modal("deleteFileModal", null);
                modal.hide();

                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planPropertyFilePK));
    }

    deleteFileCancel() {
        const modal: Modal = new Modal("deleteFileModal", null);
        modal.hide();
    }
}