/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />

//Summary
class CovidReportSummary {
    private _core: Core;
    validationClasses: string[];

    constructor() {
        this._core = new Core();

        this.validationClasses = ["covidSummaryField"];
        this._core.leftnav(this);

        let textAreaControlStr = document.getElementsByClassName("covidSummaryStrength");
        for (let textArea of textAreaControlStr)
            textArea.addEventListener("input", (e: Event) => this.checkTheStrengthCheckbox(e, "covidSummaryStrength"));

        let textAreaControlCha = document.getElementsByClassName("covidSummaryChallenge");
        for (let textArea of textAreaControlCha)
            textArea.addEventListener("input", (e: Event) => this.checkTheStrengthCheckbox(e, "covidSummaryChallenge"));

        let saveButton = document.getElementById("listOfStrengthsAndChallengesSave");
        saveButton.addEventListener("click", (e: Event) => this.save("save"));
    }

    checkTheStrengthCheckbox(e: Event, className: string) {
        let textElement = <HTMLTextAreaElement>e.srcElement;
        let fullClassName = `${className}Checkbox`;
        let row = parseInt(textElement.dataset.row);
        let rowCheckbox = <HTMLInputElement>document.querySelector(`.${fullClassName}[data-row='${row}']`);

        if (textElement.value !== "") {
            rowCheckbox.checked = true;
        }
        else {
            rowCheckbox.checked = false;
        } 
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allStrengthElements = [];
        let allStrengthCheckboxElements = [];
        let allChallengeElements = [];
        let allChallengeCheckboxElements = [];
        let planElement = document.getElementById("covidListOfStrengthsAndChallengesForm");
        let planPk = planElement.dataset.planpk;

        let allElements = document.getElementsByClassName("covidSummaryStrength");
        for (let element of allElements) {
            let htmlElement = <HTMLInputElement>element;
            let planPropertyPK: number = 0;
            let rowNumber: number = parseInt(htmlElement.dataset.row);

            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }

            if (htmlElement.value !== '') {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: parseInt(planPk),
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: htmlElement.value,
                    LookupCodeFK: null,
                    RowNbr: rowNumber,
                    IsDeletedInd: false
                };

                allStrengthElements.push(saveItem);
            }
        }

        allElements = document.getElementsByClassName("covidSummaryStrengthCheckbox");
        for (let element of allElements) {
            let htmlElement = <HTMLInputElement>element;
            let planPropertyPK: number = 0;
            let rowNumber: number = parseInt(htmlElement.dataset.row);

            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }

            let val = "";

            if (htmlElement.checked) {
                val = "on";
            }

            if (htmlElement.checked) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: parseInt(planPk),
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: rowNumber,
                    IsDeletedInd: false
                };

                allStrengthCheckboxElements.push(saveItem);
            }
        }

        allElements = document.getElementsByClassName("covidSummaryChallenge");
        for (let element of allElements) {
            let htmlElement = <HTMLInputElement>element;
            let planPropertyPK: number = 0;
            let rowNumber: number = parseInt(htmlElement.dataset.row);

            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }

            if (htmlElement.value !== '') {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: parseInt(planPk),
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: htmlElement.value,
                    LookupCodeFK: null,
                    RowNbr: rowNumber,
                    IsDeletedInd: false
                };

                allChallengeElements.push(saveItem);
            }
        }

        allElements = document.getElementsByClassName("covidSummaryChallengeCheckbox");
        for (let element of allElements) {
            let htmlElement = <HTMLInputElement>element;
            let planPropertyPK: number = 0;
            let rowNumber: number = parseInt(htmlElement.dataset.row);

            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }

            let val = "";

            if (htmlElement.checked) {
                val = "on";
            }

            if (htmlElement.checked) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: parseInt(planPk),
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: rowNumber,
                    IsDeletedInd: false
                };

                allChallengeCheckboxElements.push(saveItem);
            }
        }

        let reflectionElement: IPlanProperty;
        let reflectionElements = document.getElementsByClassName("summaryOfStrengthsReflectionsField");
        for (let element of reflectionElements) {
            let htmlElement = <HTMLInputElement>element;
            let planPropertyPK: number = 0;
            let rowNumber: number = parseInt(htmlElement.dataset.row);

            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }

            let saveItem: IPlanProperty = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: parseInt(planPk),
                PropertyFK: parseInt(htmlElement.dataset.propertypk),
                TextValue: htmlElement.value,
                LookupCodeFK: null,
                RowNbr: rowNumber,
                IsDeletedInd: false
            };

            reflectionElement = saveItem;
        }

        let allSaveElements = {
            "Strengths": allStrengthElements,
            "StrengthCheckboxes": allStrengthCheckboxElements,
            "Challenges": allChallengeElements,
            "ChallengeCheckboxes": allChallengeCheckboxElements,
            "PlanFK": planPk,
            "Reflections": reflectionElement
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CovidReopeningReport/SaveListOfSummaryAndStrengths', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    core.pageReload(true, parseInt(planPk));
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allChallengeElements.length === 0 && allStrengthElements.length === 0) {
            if (referrer === "save") {
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'error', 3000, null);
                this.customValidation();
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    //TODO:
    customValidation() {
        let errorCount = 0;

        //Check to see if at least two challenges have been checked for consideration
        let challengeCheckboxes = document.getElementsByClassName("summaryOfChallengesField") as HTMLCollectionOf<HTMLInputElement>;
        let challengeCheckedCount = 0;

        for (let check of challengeCheckboxes) {
            if (check.checked) {
                challengeCheckedCount++;
            }
        }

        if (challengeCheckedCount < 2) {
            errorCount++;
            let checkboxErrorMessage = document.getElementById("summaryChallengesErrorContainer");
            if (checkboxErrorMessage !== null) {
                checkboxErrorMessage.classList.add("show");
            }
        }

        //Check to see if at least one strength has been checked for consideration
        let strengthCheckboxes = document.getElementsByClassName("summaryOfStrengthsField") as HTMLCollectionOf<HTMLInputElement>;
        let strengthCheckedCount = 0;

        for (let check of strengthCheckboxes) {
            if (check.checked) {
                strengthCheckedCount++;
            }
        }

        if (strengthCheckedCount === 0) {
            errorCount++;
            let strengthCheckboxErrorMessage = document.getElementById("summaryStrengthsErrorContainer");
            if (strengthCheckboxErrorMessage !== null) {
                strengthCheckboxErrorMessage.classList.add("show");
            }
        }

        if (errorCount > 0) {
            //Set text for error message
            let message = <HTMLDivElement>document.getElementById("validationMessage");

            if (errorCount === 1) {
                message.innerHTML = "<p class='validationErrorCountMessage'>There is " + errorCount + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
            } else {
                message.innerHTML = "<p class='validationErrorCountMessage'>There are " + errorCount + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
            }
            let goToError = document.getElementById("goToFirstError");

            if (goToError !== null) {

                let firstFocusableEl = <HTMLElement>document.querySelector(".custom-error.show");

                if (firstFocusableEl !== null) {
                    goToError.addEventListener("click", function () {
                        firstFocusableEl.focus();
                    });
                } else {
                    goToError.parentNode.removeChild(goToError);
                }
            }

            this._core.doValidation(null, true);

        } else {
            this._core.doValidation(null, false);
        }
    }
}

//GoalSetting
class CovidGoalSetting {

    validationClasses: string[];
    private deleteGoalModal: Modal;
    private _core: Core;

    constructor() {
        this._core = new Core();
        this.validationClasses = ["covidGoalSettingField", "covidGoalSettingLookupField"];
        this.deleteGoalModal = new Modal("deleteGoalModal", null);

        let saveButton = document.getElementById("covidGoalSettingSave");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);

        let that = this;
        let deleteGoalButtons = document.querySelectorAll(".deleteGoal") as NodeListOf<HTMLButtonElement>;
        for (let button of deleteGoalButtons) {
            button.addEventListener("click", function (e) {
                e.preventDefault();
                that.deleteGoalModal.show();
                that.deleteGoalModal.addAttributeToElement(that.deleteGoalModal.id, "#deleteGoalConfirm", "goalplanpropertypk", button.dataset.goalplanpropertypk);
            });
        }

        let deleteGoalConfirmButton = document.getElementById("deleteGoalConfirm");
        if (deleteGoalConfirmButton !== null) {
            deleteGoalConfirmButton.addEventListener("click", function (e) {
                e.preventDefault();
                if (deleteGoalConfirmButton.dataset.goalplanpropertypk && parseInt(deleteGoalConfirmButton.dataset.goalplanpropertypk)) {
                    that.deleteGoal(deleteGoalConfirmButton.dataset.goalplanpropertypk);
                } else {
                    that.deleteGoalModal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this goal", "error", 3000, null);
                }
            });
        }

        let deleteGoalCancelButton = document.getElementById("deleteGoalCancel");
        if (deleteGoalCancelButton !== null) {
            deleteGoalCancelButton.addEventListener("click", function (e) {
                e.preventDefault();
                that.deleteGoalModal.hide();
            });
        }

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }

        this._core.initializeRequiredFields(this.validationClasses);
        this._core.activateSubMenu("subNavReadySetGo");
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("covidGoalSettingForm");
        let planFK = parseInt(formElement.dataset.planfk);
        let pageFK = parseInt(formElement.dataset.pagefk);
        let goals = [];

        let counter: number = 0;

        let goalStatements = document.querySelectorAll(".covidGoalSettingField[data-propertycode='goalSettingMeasurableGoalStatement']");
        for (let goal of goalStatements) {

            let goalElement = <HTMLTextAreaElement>goal;
            let goalStatementPropertyPK: number = 0;
            let goalStatement: IPlanPropertyExtended = {
                PlanPropertyPK: parseInt(goalElement.dataset.planpropertypk),
                PlanFK: planFK,
                PropertyFK: parseInt(goalElement.dataset.propertypk),
                TextValue: goalElement.value,
                LookupCodeFK: null,
                RowNbr: parseInt(goalElement.dataset.row),
                IsDeletedInd: false,
                DataPointPlanPropertyPK: parseInt(goalElement.dataset.datapointplanpropertypk),
                ButtonRow: parseInt(goalElement.dataset.buttonrow)
            };

            console.log("PlanPropertyPK: " + goalElement.dataset.planpropertypk);
            console.log("PlanPropertyPK: " + parseInt(goalElement.dataset.planpropertypk));
            console.log("PlanFK: " + planFK);
            console.log("PropertyFK: " + goalElement.dataset.propertypk);
            console.log("PropertyFK: " + parseInt(goalElement.dataset.propertypk));
            console.log("TextValue: " + goalElement.value);
            console.log("RowNbr: " + goalElement.dataset.row);
            console.log("RowNbr: " + parseInt(goalElement.dataset.row));
            console.log("DataPointPlanPropertyPK: " + goalElement.dataset.datapointplanpropertypk);
            console.log("DataPointPlanPropertyPK: " + parseInt(goalElement.dataset.datapointplanpropertypk));
            console.log("ButtonRow: " + goalElement.dataset.buttonrow);
            console.log("ButtonRow: " + parseInt(goalElement.dataset.buttonrow));

            //Get fields associated with goal statement
            let goalDetails = [];
            let goalDetailsElements = document.querySelectorAll(`.covidGoalSettingField[data-row='${goalElement.dataset.row}']:not([data-propertycode='goalSettingMeasurableGoalStatement']), .covidGoalSettingLookupField[data-row='${goalElement.dataset.row}']:not([data-propertycode='goalSettingMeasurableGoalStatement'])`);
            for (let detail of goalDetailsElements) {
                counter = counter + 1;
                console.log(counter);
                if (detail.classList.contains("covidGoalSettingLookupField")) {
                    console.log(counter);
                    let htmlElement = <HTMLSelectElement>detail;
                    let planPropertyPK: number = 0;
                    let dataPointPlanPropertyPK: number = 0;
                    let rowNumber: number = parseInt(htmlElement.dataset.row);
                    let buttonRow: number = parseInt(htmlElement.dataset.buttonrow);

                    if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                        planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);

                    if (htmlElement.dataset.datapointplanpropertypk && htmlElement.dataset.datapointplanpropertypk !== "0")
                        dataPointPlanPropertyPK = parseInt(htmlElement.dataset.datapointplanpropertypk);
                    console.log('here' + counter);

                    console.log("saveItemBefore: " + counter);

                    if (htmlElement.value !== "" || planPropertyPK > 0) {
                        let saveItem: IPlanPropertyExtended = {
                            PlanPropertyPK: planPropertyPK,
                            PlanFK: planFK,
                            PropertyFK: parseInt(htmlElement.dataset.propertypk),
                            TextValue: "",
                            LookupCodeFK: parseInt(htmlElement.value),
                            RowNbr: rowNumber,
                            IsDeletedInd: false,
                            DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                            ButtonRow: buttonRow
                        };
                        goalDetails.push(saveItem);
                    }
                } else {
                    let htmlElement = <HTMLInputElement>detail;
                    let planPropertyPK: number = 0;
                    let doNotInclude: boolean = false;
                    let rowNumber: number = parseInt(htmlElement.dataset.row);
                    let buttonRow: number = parseInt(htmlElement.dataset.buttonrow);

                    if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                        planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);

                    let value = htmlElement.value;

                    if (htmlElement.classList.contains("goalSettingCheckbox")) {
                        if (htmlElement.checked) {
                            value = "on";
                        } else {
                            value = "off";
                            if (planPropertyPK === 0) {
                                doNotInclude = true;
                            }
                        }
                    }

                    if ((value || planPropertyPK > 0) && !doNotInclude) {
                        let saveItem: IPlanPropertyExtended = {
                            PlanPropertyPK: planPropertyPK,
                            PlanFK: planFK,
                            PropertyFK: parseInt(htmlElement.dataset.propertypk),
                            TextValue: value,
                            LookupCodeFK: null,
                            RowNbr: rowNumber,
                            IsDeletedInd: false,
                            DataPointPlanPropertyPK: goalStatement.PlanPropertyPK,
                            ButtonRow: buttonRow
                        };

                        console.log("PlanPropertyPK: " + planPropertyPK);
                        console.log("PlanFK: " + planFK);
                        console.log("PropertyFK: " + htmlElement.dataset.propertypk);
                        console.log("PropertyFK: " + parseInt(htmlElement.dataset.propertypk));
                        console.log("TextValue: " + value);
                        console.log("RowNbr: " + rowNumber);
                        console.log("DataPointPlanPropertyPK: " + goalStatement.PlanPropertyPK);
                        console.log("ButtonRow: " + buttonRow);

                        console.log("saveItem: " + counter);
                        goalDetails.push(saveItem);
                    }
                }

            }

            let goalSettingSaveData: IGoalSettingMeasurableGoal = {
                GoalDetails: goalDetails,
                GoalStatement: goalStatement
            }

            goals.push(goalSettingSaveData);
        }

        let saveData = JSON.stringify({
            "PlanFK": planFK,
            "PageFK": pageFK,
            "MeasurableGoals": goals
        });

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CovidReopeningReport/SaveGoalSetting', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planFK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (goals.length === 0) {
            if (referrer === "save") {
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            console.log('hereToSave');
            xhr.send(saveData);
        }
    }

    deleteGoal(goalPlanPropertyPK: string) {
        let that = this;
        that.deleteGoalModal.hide();

        let goal = document.querySelector(`[data-planpropertypk='${goalPlanPropertyPK}']`) as HTMLTextAreaElement;

        if (goal != null && goal.dataset.row) {
            let container = document.getElementById(goal.dataset.container) as HTMLDivElement;
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/CovidReopeningReport/DeleteGoal', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                    Core.createHTMLAlert("alertMessageDiv", "Goal successfully deleted", 'success', 3000, null);

                    //reset fields
                    let goalFields = document.querySelectorAll(`.covidGoalSettingField[data-row='${goal.dataset.row}'], .covidGoalSettingLookupField[data-row='${goal.dataset.row}']`);
                    for (let field of goalFields) {
                        let htmlElement = <HTMLElement>field;
                        //Lookup
                        if (field instanceof HTMLSelectElement) {
                            let htmlField = <HTMLSelectElement>field;
                            htmlField.dataset.planpropertypk = "0";
                            htmlField.selectedIndex = 0;
                        } else if (field instanceof HTMLInputElement) {
                            let htmlField = <HTMLInputElement>field;
                            htmlField.dataset.planpropertypk = "0";
                            htmlField.value = "";
                        } else if (field instanceof HTMLTextAreaElement) {
                            let htmlField = <HTMLTextAreaElement>field;
                            htmlField.dataset.planpropertypk = "0";
                            htmlField.value = "";
                        }

                        //remove delete button for the goal
                        let deleteButton = document.querySelector(`.deleteGoal[data-goalplanpropertypk='${goalPlanPropertyPK}']`);
                        if (deleteButton != null) {
                            deleteButton.parentNode.removeChild(deleteButton);
                        }
                    }

                    Core.createHTMLAlert("alertMessageDiv", "Successfully deleted goal", "success", 3000, null);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this goal. Please try again later.", "error", 3000, null);
                }
                Core.hideLoader();
            };
            xhr.send(goalPlanPropertyPK);
        } else {
            Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this goal. Please try again later.", "error", 3000, null);
        }
    }
}