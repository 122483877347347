/// <reference path="../core.ts" />

class CustomAccordion {
    constructor(accordionId: string) {
        let accordions = document.getElementsByClassName("Accordion-trigger");
        for (let accordion of accordions) {
            let accordionButton = <HTMLButtonElement>accordion;
            if (!accordionButton.hasAttribute("data-hasclick")) {
                accordion.setAttribute("data-hasclick", "true");
                accordion.addEventListener("click", (e: Event) => this.toggle(accordion.id))
            }
        }
    }

    public toggle(id: string) {
        let isOpen: Boolean;
        let element = document.getElementById(id);
        let contentId = element.getAttribute("aria-controls");
        let contentElement = document.getElementById(contentId);
        if (contentElement.classList.contains("open")) {
            isOpen = true;
        }

        if (isOpen === true) {
            this.hide(element, contentElement);
        } else {
            this.show(element, contentElement);
        }
    }

    show(element: HTMLElement, contentElement: HTMLElement) {
        //let _that = this;
        //let element = document.getElementById(this.id);
        //element.classList.add("opened");
        //let closeElementId = "closeModal" + this.id;
        //let closeElement = document.getElementById(closeElementId);
        //closeElement.addEventListener("click", function (e) { _that.hide() });
        //closeElement.addEventListener("keypress", function (e) { if (_that.Core.a11yClick(e)) { _that.hide(); } });       

        element.classList.add("open");
        element.setAttribute("aria-expanded", "true");
        contentElement.classList.add("open");

    /*
     * 1. Get the id of the button
     * 2. Get the id of the content
     * 3. open the content
     * 4. change the button to have the other icon (add/remove class)
     */
    }

    hide(element: HTMLElement, contentElement: HTMLElement) {
        //let _that = this;
        //let element = document.getElementById(this.id);
        //element.classList.remove("opened");
        //let closeElementId = "closeModal" + this.id;
        //let closeElement = document.getElementById(closeElementId);
        //closeElement.removeEventListener("click", function (e) { _that.hide() });
        //closeElement.removeEventListener("keypress", function (e) { if (_that.Core.a11yClick(e)) { _that.hide(); } });       

        element.classList.remove("open");
        element.setAttribute("aria-expanded", "false");
        contentElement.classList.remove("open");

    /*
     * 1. Get the id of the button
     * 2. Get the id of the content
     * 3. close the content
     * 4. change the button to have the other icon (add/remove class)
     */
    }
}