/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />

// Profile and Plan Essentials
class ProfessionalDevelopmentProfileAndPlanEssentials {

    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["act48ProfileField"];

        let act48ProfileSaveButton = document.getElementById("act48ProfileSave");
        if (act48ProfileSaveButton !== null)
            act48ProfileSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }

        let allSaveElements = [];
        let planPK = 0;

        const profileFormElement = <HTMLDivElement>document.getElementById("act48ProfileForm");
        planPK = parseInt(profileFormElement.dataset.planfk);

        const templateCode = profileFormElement.dataset.templatecode;

        const inputs = document.getElementsByClassName("act48ProfileField");

        for (let ele of inputs) {
            let planPropertyPK = 0;

            let element = <HTMLInputElement>ele;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: 0,
                    IsDeletedInd: false,
                };

                allSaveElements.push(saveItem);
            }
        }

        const allData = {
            "SaveData": allSaveElements,
            "PlanFK": planPK,
            "TemplateCode": templateCode
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/ProfessionalDevelopmentReport/SaveAct48Profile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) { //This is still the valid check, as long as allSaveElements has data in it, we can save, if not, no need.
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }
}

//Steering Committee
class ProfessionalDevelopmentSteeringCommittee {

    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["steeringCommitteeField"];

        this.bindDeleteRowButtons();

        let numberTextBox = document.getElementById("steeringCommitteeAddRowsNumber");
        if (numberTextBox !== null)
            numberTextBox.addEventListener("change", (e: Event) => this.checkForPlural());

        let deleteSteeringCommitteeConfirmButton = document.getElementById("pdReportSteeringCommitteeDeleteConfirm");
        if (deleteSteeringCommitteeConfirmButton !== null)
            deleteSteeringCommitteeConfirmButton.addEventListener("click", (e: Event) => this.deleteRowConfirmConfirm(e));

        let deleteSteeringCommitteeCancelButton = document.getElementById("pdReportSteeringCommitteeDeleteCancel");
        if (deleteSteeringCommitteeCancelButton !== null)
            deleteSteeringCommitteeCancelButton.addEventListener("click", (e: Event) => this.deleteRowConfirmCancel());

        let steeringCommitteeAddRowsButton = document.getElementById("steeringCommitteeAddRows");
        if (steeringCommitteeAddRowsButton !== null)
            steeringCommitteeAddRowsButton.addEventListener("click", (e: Event) => this.addNewSteeringCommitteeRow());

        let steeringCommitteeSave = document.getElementById("saveCommitteeMembers");
        if (steeringCommitteeSave !== null)
            steeringCommitteeSave.addEventListener("click", (e: Event) => this.save("save"));

        let steeringCommitteeAddRowsNumberInput = document.getElementById("steeringCommitteeAddRowsNumber");
        if (steeringCommitteeAddRowsNumberInput !== null)
            steeringCommitteeAddRowsNumberInput.addEventListener("change", (e: Event) => this.checkForPlural());

        let steeringCommitteeExportExcel = document.getElementById("exportExcelCommitteeMembers");
        if (steeringCommitteeExportExcel !== null)
            steeringCommitteeExportExcel.addEventListener("click", (e: Event) => this.exportExcel());

        //let continueButton = document.getElementById("continueButton");
        //if (continueButton !== null)
        //    continueButton.addEventListener("click", (e: Event) => this._core.continue(e));

        //let backButton = document.getElementById("backButton");
        //if (backButton !== null)
        //    backButton.addEventListener("click", (e: Event) => this._core.back(e));
        this._core.leftnav(this);

        this.initializeRequiredFieldsCustom();

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }

        let steeringCommitteeTable = document.getElementById("steeringCommittee");
        let steeringCommitteeTableRows = steeringCommitteeTable.querySelectorAll("tr[data-row]");
        let allSaveElements = [];
        let planPK = 0;

        let inputs = document.getElementsByClassName("steeringCommitteeField");

        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(steeringCommitteeTable.dataset.planpk);

            if (ele instanceof HTMLSelectElement) {
                let element = <HTMLSelectElement>ele;
                let rowNumber = element.dataset.row;
                let propertyPK = parseInt(element.dataset.propertypk);
                let hadValue = false;
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                let lookupCodeFK = null;
                let textValue = null;
                if (ele.dataset.propertycode == "pdAct48ReportSteeringAppointedBy") {
                    lookupCodeFK = parseInt(element.value);
                } else if (ele.dataset.propertycode == "pdAct48ReportSteeringCommitteeRole") {
                    //Note that even though this is a select, the value is being stored as TextValue NOT LookupCodeFK
                    textValue = ele.value;
                }

                if (element.value !== "" || hadValue) {
                    let saveItem: IPlanProperty = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: textValue,
                        LookupCodeFK: lookupCodeFK,
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };

                    allSaveElements.push(saveItem);
                }
            } else {
                let element = <HTMLInputElement>ele;
                let rowNumber = element.dataset.row;
                let propertyPK = parseInt(element.dataset.propertypk);
                let hadValue = false;
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                if (element.value !== "" || hadValue) {
                    let saveItem: IPlanProperty = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: element.value,
                        LookupCodeFK: null,
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };

                    allSaveElements.push(saveItem);
                }
            } 
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/ProfessionalDevelopmentReport/SaveSteeringCommittee', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    bindDeleteRowButtons() {
        let deleteButtons = document.getElementsByClassName("removeSteeringCommitteeRow");
        for (let deleteButton of deleteButtons) {
            let deleteButtonElement = <HTMLButtonElement>deleteButton;

            deleteButtonElement.addEventListener("click", (e: Event) => this.showDeleteRowConfirm(e));
        }
    }

    showDeleteRowConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let row = buttonElement.dataset.row;

        let modal: Modal = new Modal("profDevAct48DeleteSteeringCommitteeRow", null);
        modal.addAttributeToElement("profDevAct48DeleteSteeringCommitteeRow", "#pdReportSteeringCommitteeDeleteConfirm", "row", row);
        modal.show();
    }

    deleteRowConfirmCancel() {
        let modal: Modal = new Modal("profDevAct48DeleteSteeringCommitteeRow", null);
        modal.hide();
    }

    deleteRowConfirmConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let row = buttonElement.dataset.row;

        let planProps = [];
        let core = this._core;
        let formData = document.getElementById("steeringCommittee");
        let planPK = parseInt(formData.dataset.planpk);
        let allBlank = true;
        Core.showLoader();

        let committeeFields = document.getElementsByClassName("steeringCommitteeField");
        for (let comm of committeeFields) {
            let inputElement = <HTMLInputElement>comm;
            if (inputElement.dataset.row === row) {
                planProps.push(inputElement.dataset.planpropertypk);
                if (inputElement.value !== "" && inputElement.value != "-- Select One --")
                    allBlank = false;
            }
        }

        if (allBlank) {
            let allTheRows = document.getElementsByClassName("steeringCommitteeRow");
            for (let thisRow of allTheRows) {
                let thisRowElement = <HTMLDivElement>thisRow;
                if (thisRowElement.dataset.row === row) {
                    thisRowElement.parentNode.removeChild(thisRowElement);

                    let modal: Modal = new Modal("profDevAct48DeleteSteeringCommitteeRow", null);
                    modal.hide();

                    Core.createHTMLAlert("alertMessageDiv", "The steering committee row has been removed.", 'success', 3000, null);
                }
            }
            Core.hideLoader();
        } else {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/ProfessionalDevelopmentReport/DeleteSteeringCommitteeRow', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();

                    let allTheRows = document.getElementsByClassName("steeringCommitteeRow");
                    for (let thisRow of allTheRows) {
                        let thisRowElement = <HTMLDivElement>thisRow;
                        if (thisRowElement.dataset.row === row) {
                            thisRowElement.parentNode.removeChild(thisRowElement);
                        }
                    }

                    let modal: Modal = new Modal("profDevAct48DeleteSteeringCommitteeRow", null);
                    modal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "The steering committee row has been removed.", 'success', 3000, null);
                } else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send(JSON.stringify(planProps));
        }
    }

    addNewSteeringCommitteeRow() {
        let core = this._core;
        let newRow: number = 0;
        let _that = this;
        let formData = document.getElementById("steeringCommittee");
        let planFK = parseInt(formData.dataset.planpk);
        let numberOfRowsElement = <HTMLInputElement>document.getElementById("steeringCommitteeAddRowsNumber");
        let numberOfRows = parseInt(numberOfRowsElement.value);

        if (numberOfRows !== null && numberOfRows > 0) {

            let communicationFields = document.getElementsByClassName("steeringCommitteeField");
            for (let comm of communicationFields) {
                let commElement = <HTMLInputElement>comm;
                if (newRow < parseInt(commElement.dataset.row))
                    newRow = parseInt(commElement.dataset.row);
            }

            for (let i = 0; i < numberOfRows; i++) {
                newRow++;
                let xhr = new XMLHttpRequest();
                xhr.open('POST', '/ProfessionalDevelopmentReport/AddSteeringCommitteeRow', true);
                xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        $("#steeringCommittee tbody").append(xhr.responseText);
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "New steering committee row added", 'success', 3000, null);
                        _that.bindDeleteRowButtons();
                        numberOfRowsElement.value = "";
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                    }
                };
                xhr.send("newRow=" + newRow + "&planFK=" + planFK);
            }
        } else {
            Core.createHTMLAlert("alertMessageDiv", "Please specify the number of rows to add", 'error', 3000, null);
        }
    }

    checkForPlural(): void {
        let word = <HTMLSpanElement>document.getElementById("rowWord");
        let num = <HTMLInputElement>document.getElementById("steeringCommitteeAddRowsNumber")
        let newWord = "rows";
        if (parseInt(num.value) === 1) {
            newWord = "row";
        }

        word.textContent = newWord;
    }

    exportExcel() {
        let core = this._core;
        Core.showLoader();

        let steeringCommitteeTable = document.getElementById("steeringCommittee");
        let planPK = 0;
        planPK = parseInt(steeringCommitteeTable.dataset.planpk);
        window.open("/ExportExcel/PDSteeringCommitteeDataExport?id=" + planPK);
        setTimeout(function () {
            Core.hideLoader();
        }, 5000);
    }

    initializeRequiredFieldsCustom() {
        let steeringCommitteeTableRows = document.querySelectorAll("tr.steeringCommitteeRow[data-row]");

        for (let row of steeringCommitteeTableRows) {

            let fields = row.getElementsByClassName("steeringCommitteeField") as HTMLCollectionOf<HTMLElement>;

            for (let element of fields) {

                if (element instanceof HTMLSelectElement) {
                    if(element.selectedIndex > 0) {
                        for (let otherElement of fields) {
                            otherElement.dataset.forcerequired = "true";
                        }
                        break;
                    }
                } else if (element instanceof HTMLInputElement) {
                    if(element.value != "") {
                        for (let otherElement of fields) {
                            otherElement.dataset.forcerequired = "true";
                        }
                        break;
                    }
                }
            }
        }

        this._core.initializeRequiredFields(this.validationClasses);
    }
}

//Professional Education Plan
class ProfessionalDevelopmentProfessionalEducationPlan {

    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.structuredLiteracyImplementation();

        this.validationClasses = ["professionalDevelopmentEdPlanField", "professionalDevelopmentEdPlanTextField", "professionalDevelopmentEdPlanSelectField"];

        let saveButton = document.getElementById("saveProfessionalEducationPlan");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e: Event) => this.save("save"));

        let exportToWordButton = document.getElementById("professionalEdPlanExportToWord");
        if (exportToWordButton !== null)
            exportToWordButton.addEventListener("click", (e: Event) => this.exportToWord());

        let exportToExcelButton = document.getElementById("professionalEdPlanExportToExcel");
        if (exportToExcelButton !== null)
            exportToExcelButton.addEventListener("click", (e: Event) => this.exportToExcel());

        this._core.leftnav(this);

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        const professionalDevelopmentImplementationFields = document.getElementsByClassName("professionalDevelopmentImplementation");
        for (const professionalDevelopmentImplementationField of professionalDevelopmentImplementationFields)
            professionalDevelopmentImplementationField.addEventListener("change", (e: Event) => this.structuredLiteracyImplementation());
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }

        let allSaveElements = [];

        let planPK = 0;
        let inputs = document.getElementsByClassName("professionalDevelopmentEdPlanField");
        let professionalEdPlanElement = document.getElementById("professionalEducationPlan");

        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(professionalEdPlanElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            if (element.checked) {
                let propertyPK = parseInt(element.dataset.propertypk);
                let hadValue = false;
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                if (element.value !== "" || hadValue) {
                    let saveItem: IPlanProperty = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "",
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: 0,
                        IsDeletedInd: false
                    };

                    allSaveElements.push(saveItem);
                }
            }
        }

        const selects = document.getElementsByClassName("professionalDevelopmentEdPlanSelectField");
        for (let ele of selects) {
            let planPropertyPK = 0;
            planPK = parseInt(professionalEdPlanElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: "",
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: 0,
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let texts = document.getElementsByClassName("professionalDevelopmentEdPlanTextField");
        for (let ele of texts) {
            let planPropertyPK = 0;
            planPK = parseInt(professionalEdPlanElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: 0,
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/ProfessionalDevelopmentReport/SaveProfessionalEducationPlan', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    exportToWord() {
        console.log("Export to Word");
    }

    exportToExcel() {
        console.log("Export to Excel");
    }

    structuredLiteracyImplementation() {
        const professionalDevelopmentImplementationFields = document.getElementsByClassName("professionalDevelopmentImplementation");
        const noDisplayDiv = <HTMLDivElement>document.getElementById("structuredImplementationNo");
        const noReqElement = <HTMLInputElement>document.getElementById("pdAct48V2PDPlanAssuranceDescribeyourreadingcurriculumandincludegradelevels");
        for (const professionalDevelopmentImplementationField of professionalDevelopmentImplementationFields) {
            const element = <HTMLInputElement>professionalDevelopmentImplementationField;
            if (element.checked) {
                if (element.dataset.lookupcode === "structuredLiteracyImplemenationNo") {
                    noDisplayDiv.classList.remove("hide");
                    this._core.forceElementRequired(noReqElement);
                } else {
                    noDisplayDiv.classList.add("hide");
                    this._core.forceElementOptional(noReqElement);
                }
            }
        }
    }
}

//Action Plans Steps from Comprehensive Plan
class ProfessionalDevelopmentActionPlansStepsFromComprehensivePlan {

    private _core: Core;
    constructor() {
        this._core = new Core();

        let exportToWordButton = document.getElementById("act48professionalDevelopmentExportToWord");
        if (exportToWordButton !== null)
            exportToWordButton.addEventListener("click", (e) => this.exportToWord());

        let exportToExcelButton = document.getElementById("act48professionalDevelopmentExportToExcel");
        if (exportToExcelButton !== null)
            exportToExcelButton.addEventListener("click", (e) => this.exportToExcel());

        let tableExportExcel = document.getElementById("professionalDevelopmentActionStepTableExportExcel");
        if (tableExportExcel !== null)
            tableExportExcel.addEventListener("click", (e: Event) => this.tableExportExcel());

        this._core.leftnav(this);
    }

    getCore() {
        return this._core;
    }

    exportToWord() {

    }

    exportToExcel() {
        let core = this._core;
        Core.showLoader();

        let planElement = document.getElementById("professionalDevelopmentForm");
        let planFK = planElement.dataset.planfk;
        window.open("/ExportExcel/ProfessionalDevelopmentDataExport?id=" + planFK);
        setTimeout(function () {
            Core.hideLoader();
        }, 5000);
    }

    tableExportExcel() {
        let core = this._core;
        Core.showLoader();

        let planElement = document.getElementById("professionalDevelopmentForm");
        let planFK = planElement.dataset.planfk;
        window.open("/ExportExcel/ProfessionalDevelopmentActionStepTableDataExport?id=" + planFK);
        setTimeout(function () {
            Core.hideLoader();
        }, 5000);
    }

    save(referrer) {
        let core = this._core;

        //Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }

        if (refreshPage && refreshPage !== "")
            window.location.href = refreshPage;
        else
            Core.hideLoader();
    }
}

//Other Professional Development Activities
class OtherProfessionalDevelopmentActivies {

    validationClasses: string[];
    hashClasses: string[];
    private deleteActivityModal: Modal;
    private _core: Core;
    constructor() {
        this._core = new Core();
        this.deleteActivityModal = new Modal("deleteProfessionalDevelopmentActivityModal", null);
        this.validationClasses = [
            "professionalDevelopmentField",
            "addDanielsonFrameworkToListItem",
            "professionalDevelopmentLearningFormatStateRequired",
            "professionalDevelopmentFrequencyField"
        ];

        this.hashClasses = [
            "professionalDevelopmentField",
        ];


        let saveButton = document.getElementById("act48OtherProfessionalDevelopmentSave");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);

        let addMoreRowsButton = document.getElementById("addAct48OtherProfessionalDevelopmentRows");
        if (addMoreRowsButton !== null)
            addMoreRowsButton.addEventListener("click", (e: Event) => this.addMoreProfessionalDevelopmentRows());

        let exportToWordButton = document.getElementById("act48OtherProfessionalDevelopmentExportToWord");
        if (exportToWordButton !== null)
            exportToWordButton.addEventListener("click", (e: Event) => this.exportToWord());

        let exportToExcelButton = document.getElementById("act48OtherProfessionalDevelopmentExportToExcel");
        if (exportToExcelButton !== null)
            exportToExcelButton.addEventListener("click", (e: Event) => this.exportToExcel());

        this.customInitializeRequiredFields();

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }

        let that = this;
        let deleteActivityButtons = document.querySelectorAll(".deleteProfessionalDevelopmentActivity") as NodeListOf<HTMLButtonElement>;
        for (let button of deleteActivityButtons) {
            button.addEventListener("click", function (e) {
                e.preventDefault();
                that.deleteActivityModal.show();
                that.deleteActivityModal.addAttributeToElement(that.deleteActivityModal.id, "#deleteProfessionalDevelopmentActivityConfirm", "activityplanpropertypk", button.dataset.activityplanpropertypk);
            });
        }

        let deleteActivityConfirmButton = document.getElementById("deleteProfessionalDevelopmentActivityConfirm");
        if (deleteActivityConfirmButton !== null) {
            deleteActivityConfirmButton.addEventListener("click", function (e) {
                e.preventDefault();
                if ("activityplanpropertypk" in deleteActivityConfirmButton.dataset && parseInt(deleteActivityConfirmButton.dataset.activityplanpropertypk) > 0) {
                    that.deleteProfessionalDevelopmentActivity(deleteActivityConfirmButton.dataset.activityplanpropertypk);
                } else {
                    that.deleteActivityModal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this professional development activity", "error", 3000, null);
                }
            });
        }

        let deleteActivityCancelButton = document.getElementById("deleteProfessionalDevelopmentActivityCancel");
        if (deleteActivityCancelButton !== null) {
            deleteActivityCancelButton.addEventListener("click", function (e) {
                e.preventDefault();
                that.deleteActivityModal.hide();
            });
        }

        this.bindCreatePDActivityButtons();
        this.bindPDActionStepAddButtons();
        this.bindDanielsonAddButtons();
        this.bindAddLearningFormatButtons();
        this.bindPDActionStepRemoveButtons();
        this.bindDanielsonListRemove();
        this.bindRemoveLearningFormatButtons();
        let hidden = this._core.createHash(this.hashClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }

    createHash() {
        let hash = this._core.createHash(this.hashClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("otherProfessionalDevelopmentForm");
        let planFK = parseInt(formElement.dataset.planfk);
        //let allSaveElements = [];
        let allPDActivities = [];
        let allLearningFormats = [];
        let allActionSteps = [];
        let allOthers = [];
        let learningFormatOthers = [];
        let learningFormatFrameworks = [];
        let learningFormatFrequency = [];
        let learningFormatStateRequired = [];
        let omit;

        let allElements = document.getElementsByClassName("professionalDevelopmentField");

        for (let element of allElements) {
            let planPropertyPK: number = 0;
            let dataPointPlanPropertyPK: number = 0;
            let buttonRow: number = 0;

            let htmlElement;
            let saveItem: IPlanPropertyExtended;

            if (element.classList.contains("actionStepList")) {
                htmlElement = <HTMLInputElement>element;
                let rowNumber: number = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;

                if (htmlElement.dataset.datapointplanpropertypk && htmlElement.dataset.datapointplanpropertypk !== "0")
                    dataPointPlanPropertyPK = parseInt(htmlElement.dataset.datapointplanpropertypk);
                else
                    dataPointPlanPropertyPK = 0;

                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;

                saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planFK,
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: "",
                    LookupCodeFK: null,
                    RowNbr: rowNumber,
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: 0,
                    ButtonRow: buttonRow
                };
            } else if (element.classList.contains("professionalDevelopmentLearningFormatActivity")) {
                htmlElement = <HTMLSelectElement>element;
                let rowNumber: number = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;

                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;

                let lookupCode: number = 0;

                if (htmlElement.value !== "") {
                    lookupCode = parseInt(htmlElement.value);
                } else {
                    lookupCode = null;
                }

                if (htmlElement.value !== "" || planPropertyPK > 0) {
                    saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: parseInt(htmlElement.dataset.propertypk),
                        TextValue: "",
                        LookupCodeFK: lookupCode,
                        RowNbr: rowNumber,
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: 0,
                        ButtonRow: buttonRow
                    };
                }
            } else if (element.classList.contains("professionalDevelopmentLearningFormatStateRequired")) {
                htmlElement = <HTMLSelectElement>element;
                let rowNumber: number = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;


                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;

                let lookupCode: number = 0;

                if (htmlElement.value !== "") {
                    lookupCode = parseInt(htmlElement.value);
                } else {
                    lookupCode = null;
                }

                if (htmlElement.value !== "" || planPropertyPK > 0) {
                    saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: parseInt(htmlElement.dataset.propertypk),
                        TextValue: "",
                        LookupCodeFK: lookupCode,
                        RowNbr: rowNumber,
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: 0,
                        ButtonRow: buttonRow
                    };
                }
            } else if (element.classList.contains("professionalDevelopmentFrequencyField")) {
                htmlElement = <HTMLSelectElement>element;
                let rowNumber: number = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;


                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;

                if (htmlElement.value !== "" || planPropertyPK > 0) {
                    saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: parseInt(htmlElement.dataset.propertypk),
                        TextValue: htmlElement.value,
                        LookupCodeFK: null,
                        RowNbr: rowNumber,
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: 0,
                        ButtonRow: buttonRow
                    };
                }
            } else if (element.classList.contains("addDanielsonFrameworkToListItem")) {
                htmlElement = <HTMLSelectElement>element;
                let rowNumber: number = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;

                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;

                saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planFK,
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: "",
                    LookupCodeFK: parseInt(htmlElement.dataset.lookupcodepk),
                    RowNbr: rowNumber,
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: 0,
                    ButtonRow: buttonRow
                };
            } else {
                htmlElement = <HTMLInputElement>element;
                let rowNumber: number = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;

                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;

                let value = htmlElement.value;

                if (value !== "") {
                    saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: parseInt(htmlElement.dataset.propertypk),
                        TextValue: value,
                        LookupCodeFK: null,
                        RowNbr: rowNumber,
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                        ButtonRow: buttonRow
                    };
                }
            }

            if (htmlElement.classList.contains("professionalDevelopmentActivity") && saveItem !== undefined)
                allPDActivities.push(saveItem);
            else if (htmlElement.classList.contains("professionalDevelopmentLearningFormatActivity") && saveItem !== undefined)
                allLearningFormats.push(saveItem);
            else if (htmlElement.classList.contains("actionStepList") && saveItem !== undefined)
                allActionSteps.push(saveItem);
            else if (htmlElement.classList.contains("actionStepOtherField") && saveItem !== undefined)
                learningFormatOthers.push(saveItem);
            else if (htmlElement.classList.contains("professionalDevelopmentLearningFormatStateRequired") && saveItem !== undefined)
                learningFormatStateRequired.push(saveItem);
            else if (htmlElement.classList.contains("addDanielsonFrameworkToListItem") && saveItem !== undefined)
                learningFormatFrameworks.push(saveItem);
            else if (htmlElement.classList.contains("professionalDevelopmentFrequencyField") && saveItem !== undefined)
                learningFormatFrequency.push(saveItem);
            else
                if (saveItem !== undefined && !htmlElement.classList.contains("omitCheckbox"))
                    allOthers.push(saveItem);
        }

        //Get omit property

        let omitSave: IPlanProperty = null;
        let omitCheckbox = <HTMLInputElement>document.getElementById("omitPageCheckbox");
        if (omitCheckbox) {
            let rowNumber: number = parseInt(omitCheckbox.dataset.row);
            let planPropertyPK = 0;
            if (omitCheckbox.dataset.planpropertypk && omitCheckbox.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(omitCheckbox.dataset.planpropertypk);
            }

            let value = "";
            if (omitCheckbox.checked) {
                value = "on";
            } else {
                value = "off";
            }

            omitSave = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: planFK,
                PropertyFK: parseInt(omitCheckbox.dataset.propertypk),
                TextValue: value,
                LookupCodeFK: null,
                RowNbr: rowNumber,
                IsDeletedInd: false,
            }
        }

        let saveElement = {
            "PDActivities": allPDActivities,
            "LearningFormats": allLearningFormats,
            "ActionSteps": allActionSteps,
            "LearningFormatOther": learningFormatOthers,
            "LearningFormatDanielsonFrameworks": learningFormatFrameworks,
            "LearningFormatStateRequired": learningFormatStateRequired,
            "LearningFormatFrequency": learningFormatFrequency,
            "Others": allOthers,
            "OmitPage" : omitSave,
            "PlanFK": planFK
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/ProfessionalDevelopmentReport/SaveProfessionalDevelopment', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planFK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (saveElement.ActionSteps.length === 0 && saveElement.LearningFormatDanielsonFrameworks.length === 0 && saveElement.LearningFormatFrequency.length === 0 && saveElement.LearningFormatOther.length === 0 && saveElement.LearningFormats.length === 0 && saveElement.LearningFormatStateRequired.length === 0 && saveElement.Others.length === 0 && saveElement.PDActivities.length === 0 && saveElement.OmitPage === null) {
            if (refreshPage && refreshPage !== "")
                window.location.href = refreshPage;
            else
                Core.hideLoader();
        }
        else {
            xhr.send(JSON.stringify(saveElement));
        }
    }

    customInitializeRequiredFields() {
        let activities = document.getElementsByClassName("professionalDevelopmentActivity") as HTMLCollectionOf<HTMLInputElement>;
        for (let activity of activities) {
            if ("buttonrow" in activity.dataset) {
                let requiredChildren = document.querySelectorAll(`.professionalDevelopmentField[data-buttonrow='${activity.dataset.buttonrow}'][data-percent='1.00']`);
                for (let child of requiredChildren) {
                    let childElement = <HTMLElement>child;
                    if (activity.value.length > 0) {
                        this._core.forceElementRequired(childElement);
                    } else {
                        this._core.forceElementOptional(childElement);
                    }
                }
            }
        }
    }

    exportToWord() {

    }

    exportToExcel() {
        let core = this._core;
        Core.showLoader();

        let planElement = document.getElementById("otherProfessionalDevelopmentForm");
        let planFK = planElement.dataset.planfk;
        window.open("/ExportExcel/OtherProfessionalDevelopmentDataExport?id=" + planFK);
        setTimeout(function () {
            Core.hideLoader();
        }, 5000);
    }

    bindRemoveLearningFormatButtons() {
        let activityButtons = document.getElementsByClassName("professionalDevelopmentDeleteLearningFormatRow");
        //let count = 0;
        for (let activityButton of activityButtons) {
            let element = <HTMLButtonElement>activityButton;
            //if (element.dataset.listener !== "true") {
            element.removeEventListener("click", (e: Event) => this.removeLearningFormat(e));
            element.addEventListener("click", (e: Event) => this.removeLearningFormat(e));
            element.dataset.listener = "true";
            //}
        }
        this.showHideDeleteActivityButtons();
    }

    showHideDeleteActivityButtons() {
        let activityButtons = document.getElementsByClassName("professionalDevelopmentDeleteLearningFormatRow");
        let count = 0;
        for (let activityButton of activityButtons) {
            count++;
        }
        if (count > 1) {
            for (let activityButton of activityButtons) {
                let element = <HTMLButtonElement>activityButton;
                for (let activityButton of activityButtons) {
                    let element = <HTMLButtonElement>activityButton;
                    element.setAttribute("style", "visibility:visible;");
                }
            }
        }
        if (count === 1) {
            for (let activityButton of activityButtons) {
                let element = <HTMLButtonElement>activityButton;
                for (let activityButton of activityButtons) {
                    let element = <HTMLButtonElement>activityButton;
                    element.setAttribute("style", "visibility:hidden;");
                }
            }
        }
    }

    removeLearningFormat(e: Event) {
        e.stopPropagation();
        let core = this._core;
        let element: HTMLButtonElement;
        let go = true;
        element = <HTMLButtonElement>e.target;
        let rowNumber: string = element.dataset.row;
        let buttonrow: string = element.dataset.buttonrow;
        let count = 0;

        let allLearningFormats = document.getElementsByClassName("professionalDevelopmentLearningFormat");
        for (let learningFormat of allLearningFormats) {
            let thisElement = <HTMLDivElement>learningFormat;
            if (thisElement.dataset.buttonrow === buttonrow && thisElement.dataset.row === rowNumber) {
                thisElement.parentNode.removeChild(thisElement);
            }
        }
        this.showHideDeleteActivityButtons();
        Core.createHTMLAlert("alertMessageDiv", "Learning Format Removed", 'success', 3000, null);
    }

    bindDanielsonListRemove() {
        let activityButtons = document.getElementsByClassName("deletePDDanielsonFramework");

        for (let activityButton of activityButtons) {
            let element = <HTMLButtonElement>activityButton;

            //if (element.dataset.listener !== "true") {
            element.removeEventListener("click", (e: Event) => this.removeDanielsonFrameworkList(e));
            element.addEventListener("click", (e: Event) => this.removeDanielsonFrameworkList(e));
            element.dataset.listener = "true";
            //}
        }
    }

    bindPDActionStepRemoveButtons() {
        let activityButtons = document.getElementsByClassName("deletePDAddedActionStep");

        for (let activityButton of activityButtons) {
            let element = <HTMLButtonElement>activityButton;

            //if (element.dataset.listener !== "true") {
            element.removeEventListener("click", (e: Event) => this.removeItemFromActionStepList(e))
            element.addEventListener("click", (e: Event) => this.removeItemFromActionStepList(e));
            element.dataset.listener = "true";
            //}
        }
    }

    removeItemFromActionStepList(e: Event) {
        e.stopPropagation();
        let element: HTMLButtonElement;
        let go = true;
        element = <HTMLButtonElement>e.target;
        let rowNumber: string = element.dataset.row;

        let planPropertyPK = element.dataset.planpropertypk;

        let allActionSteps = document.getElementsByClassName("actionStepList");
        for (let actionStep of allActionSteps) {
            let thisElement = <HTMLDivElement>actionStep;

            if (thisElement.dataset.planpropertypk === planPropertyPK && thisElement.dataset.row === rowNumber) {
                thisElement.parentNode.removeChild(thisElement);
            }
        }
    }

    bindAddLearningFormatButtons() {
        let activityButtons = document.getElementsByClassName("professionalDevelopmentAddLearningFormat");

        for (let activityButton of activityButtons) {
            let element = <HTMLButtonElement>activityButton;

            if (element.dataset.listener !== "true") {
                element.addEventListener("click", (e: Event) => this.addLearningFormat(<HTMLButtonElement>e.target));
                element.dataset.listener = "true";
            }
        }
    }

    addLearningFormat(button: HTMLButtonElement) {
        let core = this._core;
        let buttonRow = parseInt(button.dataset.buttonrow);
        let newRow: number = 0;
        let learningFormatList = document.getElementsByClassName("professionalDevelopmentLearningFormatActivity");
        let _that = this;
        let formElement = document.getElementById("otherProfessionalDevelopmentForm");
        let planFK = formElement.dataset.planfk;
        for (let activity of learningFormatList) {
            let element = <HTMLSelectElement>activity;
            if (buttonRow === parseInt(element.dataset.buttonrow)) {
                if (newRow < parseInt(element.dataset.row)) {
                    newRow = parseInt(element.dataset.row);
                }
            }
        }

        newRow++;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/ProfessionalDevelopmentReport/AddLearningFormat');
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                $(".learningFormats[data-buttonrow='" + buttonRow + "']").append(xhr.responseText);
                _that.bindDanielsonAddButtons();
                _that.bindRemoveLearningFormatButtons();
                Core.createHTMLAlert("alertMessageDiv", "New Learning Format Row Added", 'success', 3000, null);
                _that.customInitializeRequiredFields();
            } else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("rowNumber=" + newRow + "&buttonRow=" + buttonRow + "&planFK=" + planFK);
    }

    bindCreatePDActivityButtons() {
        let activityButtons = document.getElementsByClassName("createProfessionalDevelopmentActivity");

        for (let activityButton of activityButtons) {
            let element = <HTMLButtonElement>activityButton;

            element.removeEventListener("click", (e: Event) => this.createNewPDActivity(<HTMLButtonElement>e.target))
            element.addEventListener("click", (e: Event) => this.createNewPDActivity(<HTMLButtonElement>e.target))
            element.dataset.listener = "true";
        }
    }

    createNewPDActivity(button: HTMLButtonElement) {
        let _that = this;
        let core = this._core;
        let dontDoIt: boolean = false;
        let hasName: boolean = false;
        let row = button.dataset.buttonrow;
        let textElement = document.getElementsByClassName("professionalDevelopmentActivity");
        for (let tElement of textElement) {
            let properElement = <HTMLInputElement>tElement;
            if (properElement.dataset.row === row) {
                if (properElement.value === "") {
                    dontDoIt = true;
                } else {
                    hasName = true;
                }
            }
        }

        if (!dontDoIt) {
            let rowNumber = button.dataset.buttonrow;
            let textBoxId = "professionalDevelopmentActivity" + rowNumber.toString();
            let professionalDevelopmentNameElement = <HTMLInputElement>document.getElementById(textBoxId);
            let newAccordionTitle = "" + professionalDevelopmentNameElement.value;
            let accordionParent = document.getElementsByClassName("professionalDevelopmentAccordion");
            var thisElement: HTMLDivElement;

            for (let accordion of accordionParent) {
                let acc = <HTMLDivElement>accordion;
                if (acc.dataset.buttonrow == rowNumber) {
                    thisElement = acc;
                }
            }

            let theSpan = <HTMLSpanElement>thisElement.querySelector("span.Accordion-title");
            theSpan.textContent = newAccordionTitle;
            professionalDevelopmentNameElement.readOnly = true;

            //Now show the accordion.
            let professionalDevelopmentElements = document.getElementsByClassName("professionalDevelopmentAccordion");
            var professionalDevelopmentElement: HTMLDivElement;

            for (let ap of professionalDevelopmentElements) {
                let pde = <HTMLDivElement>ap;
                if (pde.dataset.buttonrow === rowNumber) {
                    professionalDevelopmentElement = pde;
                }
            }

            professionalDevelopmentElement.classList.remove("hide-professional-development");
            professionalDevelopmentElement.classList.add("open");
            button.classList.add("hide-professional-development");
            Core.createHTMLAlert("alertMessageDiv", "New Professional Development Activity Added", 'success', 3000, null);
            this.customInitializeRequiredFields();
        } else if (!hasName) {
            Core.createHTMLAlert("alertMessageDiv", "A name for the activity must be provided", 'error', 3000, null);
        }
    }

    bindPDActionStepAddButtons() {
        let actionStepButtons = document.getElementsByClassName("professionalDevelopmentActionStepAdd");

        for (let actionStepButton of actionStepButtons) {
            let element = <HTMLButtonElement>actionStepButton;

            if (element.dataset.listener !== "true") {
                element.addEventListener("click", (e: Event) => this.addActionStepToActivity(<HTMLButtonElement>e.target))
                element.dataset.listener = "true";
            }
        }
    }

    addActionStepToActivity(button: HTMLButtonElement) {
        let core = this._core;
        let _that = this;
        let rowNumber = button.dataset.buttonrow;
        let formName = document.getElementById("professionalDevelopmentForm");
        let planFK = parseInt(formName.dataset.planfk);
        let selectElements = document.getElementsByClassName("professionalDevelopmentAddActionStep") as HTMLCollectionOf<HTMLSelectElement>;

        let actionStepPlanPropertyPK: number;
        for (let selectElement of selectElements) {
            if (selectElement.dataset.buttonrow === rowNumber) {
                actionStepPlanPropertyPK = parseInt(selectElement.value);
            }
        }

        let isNotInList: boolean = true;

        let allListElements = document.getElementsByClassName("actionStepList");
        for (let listElement of allListElements) {
            let element = <HTMLDivElement>listElement;
            if (parseInt(element.dataset.planpropertypk) === actionStepPlanPropertyPK && element.dataset.buttonrow === rowNumber) {
                isNotInList = false;
            }
        }

        if (isNotInList) {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/Go/AddPDActionStepToList');
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    $(".addActionStepToList[data-row='" + rowNumber + "']").append(xhr.responseText);
                    _that.bindPDActionStepRemoveButtons();
                    Core.createHTMLAlert("alertMessageDiv", "New Action Step Row Added", 'success', 3000, null);
                } else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send("rowNumber=" + rowNumber + "&planFK=" + planFK + "&actionStepPlanPropertyPK=" + actionStepPlanPropertyPK);
        } else {
            Core.createHTMLAlert("alertMessageDiv", "This action step has already been added to this activity", 'error', 3000, null);
        }
    }

    bindDanielsonAddButtons() {
        let danielsonButtons = document.getElementsByClassName("professionalDevelopmentDanielsonFrameworkAdd");

        for (let danielsonButton of danielsonButtons) {
            let element = <HTMLButtonElement>danielsonButton;

            if (element.dataset.listener !== "true") {
                element.addEventListener("click", (e: Event) => this.addDanielsonFrameworkToList(<HTMLButtonElement>e.target))
                element.dataset.listener = "true";
            }
        }
    }

    addDanielsonFrameworkToList(button: HTMLButtonElement) {
        let core = this._core;
        let _that = this;
        let rowNumber = button.dataset.row;
        let buttonrow = button.dataset.buttonrow;
        let propertyfk = button.dataset.propertypk;
        let formName = document.getElementById("otherProfessionalDevelopmentForm");
        let planFK = parseInt(formName.dataset.planfk);
        let selectElements = document.getElementsByClassName("professionalDevelopmentAddDanielsonFramework") as HTMLCollectionOf<HTMLSelectElement>;

        let lookupCodePK: number;
        for (let selectElement of selectElements) {
            if (selectElement.dataset.buttonrow === buttonrow && selectElement.dataset.row === rowNumber) {
                lookupCodePK = parseInt(selectElement.value);
            }
        }
        let isNotInList: boolean = true;

        let allListElements = document.getElementsByClassName("addDanielsonFrameworkToListItem");
        for (let listElement of allListElements) {
            let element = <HTMLDivElement>listElement;
            if (element.dataset.lookupcodepk === lookupCodePK.toString() && element.dataset.buttonrow === buttonrow && element.dataset.row === rowNumber) {
                isNotInList = false;
            }
        }

        //See if user has selected a danielson framework or if it's still set to default value
        let userHasSelected: boolean = true;
        if (isNaN(lookupCodePK)) {
            userHasSelected = false;
            Core.createHTMLAlert("alertMessageDiv", "A Danielson Framework must first be selected", 'error', 3000, null);
        }

        if (isNotInList && userHasSelected) {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/Go/AddDanielsonFrameworkToList');
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    $(".addDanielsonFrameworkToList[data-row='" + rowNumber + "'][data-buttonrow='" + buttonrow + "']").append(xhr.responseText);
                    _that.bindDanielsonListRemove();
                    Core.createHTMLAlert("alertMessageDiv", "New Danielson Framework Row Added", 'success', 3000, null);
                } else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send("rowNumber=" + rowNumber + "&buttonRowNumber=" + buttonrow + "&planFK=" + planFK + "&lookupCodePK=" + lookupCodePK + "&propertyfk=" + propertyfk);
        } else {
            Core.createHTMLAlert("alertMessageDiv", "This Danielson Framework item has already been added", 'error', 3000, null);
        }
    }

    removeDanielsonFrameworkList(e: Event) {
        e.stopPropagation();
        let core = this._core;
        let element: HTMLButtonElement;
        let go = true;
        element = <HTMLButtonElement>e.target;
        let rowNumber: string = element.dataset.row;
        let buttonrow: string = element.dataset.buttonrow;

        let lookupCodePK = element.dataset.lookupcodepk;

        let allLookups = document.getElementsByClassName("addDanielsonFrameworkToListItem");
        for (let lookup of allLookups) {
            let thisElement = <HTMLDivElement>lookup;

            if (thisElement.dataset.lookupcodepk === lookupCodePK && thisElement.dataset.buttonrow === buttonrow && thisElement.dataset.row === rowNumber) {
                thisElement.parentNode.removeChild(thisElement);
                Core.createHTMLAlert("alertMessageDiv", "Danielson Framework Removed", 'success', 3000, null);
            }
        }
    }

    addMoreProfessionalDevelopmentRows() {
        let core = this._core;
        let _that = this;
        let howManyElement = "1";
        let howMany = parseInt(howManyElement);
        let planElement = document.getElementById("otherProfessionalDevelopmentForm");
        let planFK = planElement.dataset.planfk;
        let currentRow = 0;

        let activityElements = document.getElementsByClassName("professionalDevelopmentActivity");
        for (let activity of activityElements) {
            let activityElement = <HTMLInputElement>activity;

            if (parseInt(activityElement.dataset.buttonrow) > currentRow) {
                currentRow = parseInt(activityElement.dataset.buttonrow);
            }
        }

        if (howMany && howMany > 0) {
            for (let i = 1; i <= howMany; i++) {
                currentRow++;
                let xhr = new XMLHttpRequest();
                xhr.open('POST', '/ProfessionalDevelopmentReport/AddNewProfessionalDevelopmentActivity');
                xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        $("#professionalDevelopmentContent").append(xhr.responseText);
                        new CustomAccordion("professionalDevelopmentAccordion" + currentRow.toString());
                        _that.bindDanielsonAddButtons();
                        _that.bindAddLearningFormatButtons();
                        _that.bindPDActionStepAddButtons();
                        _that.bindCreatePDActivityButtons();
                        _that.bindRemoveLearningFormatButtons();
                        Core.createHTMLAlert("alertMessageDiv", "New Professional Development Activity Row Added", 'success', 3000, null);
                    } else {
                        Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                    }
                };
                xhr.send("rowNumber=" + currentRow + "&planFK=" + planFK);
            }
        }
    }

    deleteProfessionalDevelopmentActivity(activityPlanPropertyPK: string) {
        let that = this;
        that.deleteActivityModal.hide();

        Core.showLoader();
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Go/DeleteProfessionalDevelopmentActivity', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                Core.createHTMLAlert("alertMessageDiv", "Professional development activity successfully deleted", 'success', 3000, null);

                //remove from DOM
                let containers = document.querySelectorAll(`[data-containeractivityplanplanpropertypk='${activityPlanPropertyPK}'`);
                for (let container of containers) {
                    container.parentNode.removeChild(container);
                }

            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this activity. Please try again later.", "error", 3000, null);
            }
            Core.hideLoader();
        };
        xhr.send(activityPlanPropertyPK);
    }
}

//Evaluation and Review
class EvaluationAndReview {

    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["evaluationAndReviewField"];

        let evaluationAndReviewSaveButton = document.getElementById("act48EvaluationAndReviewSave");
        if (evaluationAndReviewSaveButton !== null)
            evaluationAndReviewSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        let evaluationAndReviewExportToWordButton = document.getElementById("act48EvaluationAndReviewExportToWord");
        if (evaluationAndReviewExportToWordButton !== null)
            evaluationAndReviewExportToWordButton.addEventListener("click", (e: Event) => this.exportToWord());

        let evaluationAndReviewExportToExcelButton = document.getElementById("act48EvaluationAndReviewExportToExcel");
        if (evaluationAndReviewExportToExcelButton !== null)
            evaluationAndReviewExportToExcelButton.addEventListener("click", (e: Event) => this.exportToExcel());

        //let continueButton = document.getElementById("continueButton");
        //if (continueButton !== null)
        //    continueButton.addEventListener("click", (e: Event) => this._core.continue(e));

        //let backButton = document.getElementById("backButton");
        //if (backButton !== null)
        //    backButton.addEventListener("click", (e: Event) => this._core.back(e));
        this._core.leftnav(this);

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }

        let allSaveElements = [];

        let planPK = 0;
        let formElement = document.getElementById("evaluationAndReviewForm");
        planPK = parseInt(formElement.dataset.planfk);

        let inputs = document.getElementsByClassName("evaluationAndReviewField");

        for (let ele of inputs) {
            let planPropertyPK = 0;

            let element = <HTMLTextAreaElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/ProfessionalDevelopmentReport/SaveEvaluationAndReview', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    exportToWord() {

    }

    exportToExcel() {

    }
}

//Professional Education Plan Assurances
class ProfessionalEducationPlanAssurances {

    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["professionalEducationPlanAssurancesField"];

        let professionalEducationPlanSaveButton = document.getElementById("act48ProfessionalEducationPlanAssurancesSave");
        if (professionalEducationPlanSaveButton !== null)
            professionalEducationPlanSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        let professionalEducationPlanExportToWordButton = document.getElementById("act48ProfessionalEducationPlanAssurancesExportToWord");
        if (professionalEducationPlanExportToWordButton !== null)
            professionalEducationPlanExportToWordButton.addEventListener("click", (e: Event) => this.exportToWord());

        let fileUploader = document.getElementById("uploadMinutesFile");
        if (fileUploader !== null) {
            fileUploader.addEventListener("change", (e: Event) => this.uploadFile(<HTMLInputElement>e.target));
        }

        let professionalEducationExportToExcelButton = document.getElementById("act48ProfessionalEducationPlanAssurancesExportToExcel");
        if (professionalEducationExportToExcelButton !== null)
            professionalEducationExportToExcelButton.addEventListener("click", (e: Event) => this.exportToExcel());

        this._core.leftnav(this);

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    uploadFile(e: HTMLInputElement) {
        let core = this._core;
        Core.showLoader();
        let uploadMinutesForm = <HTMLFormElement>document.getElementById("uploadMinutes");
        let formData = new FormData(uploadMinutesForm);

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/ProfessionalDevelopmentReport/UploadFile', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file has been successfully uploaded! Save your progress to see the updated list of files.", 'success', 3000, null);

                        let formFile = <HTMLInputElement>document.getElementById("uploadMinutesFile");
                        formFile.value = "";
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }

        let allSaveElements = [];

        let formElement = document.getElementById("professionalEducationPlanAssurancesForm");
        const planPK = parseInt(formElement.dataset.planfk);
        const templateCode = formElement.dataset.templatecode;

        let inputs = document.getElementsByClassName("professionalEducationPlanAssurancesField");

        for (let ele of inputs) {
            let planPropertyPK = 0;

            let element = <HTMLTextAreaElement>ele;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: 0,
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        var saveData = {
            "SaveData": allSaveElements,
            "PlanFK": planPK,
            "TemplateCode": templateCode
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/ProfessionalDevelopmentReport/SaveProfessionalEducationPlanAssurances', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(saveData));
        }
    }

    exportToWord() {

    }

    exportToExcel() {

    }
}

//Curriculum Summary Checklist and Submission
class ProfessionalDevelopmentSummaryChecklistAndSubmission {
    private _core: Core;
    constructor() {
        this._core = new Core();
        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.back(e));

        let submitButton = document.getElementById("submitPlanButton");
        if (submitButton !== null)
            submitButton.addEventListener("click", (e: Event) => this.submit(e));
        this._core.leftnav(this);
    }

    back(e: Event) {
        let newPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;

        //Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }

        if (refreshPage && refreshPage !== "")
            window.location.href = refreshPage;
        else
            Core.hideLoader();
    }

    submit(e: Event) {
        let element = <HTMLButtonElement>e.target;
        if (!element.classList.contains("disabled")) {
            let planFK = 0;
            planFK = parseInt(element.dataset.planfk);
            let core = this._core;
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/ProfessionalDevelopmentReport/SubmitPlan', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "This report has been submitted for review!", 'success', 3000, window.location.reload);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status + "'", 'error', 3000, null);
                }
            };
            xhr.send("planFK=" + planFK);
        }
    }
}

//V2 report

// PdAct48V2PDActivities
class PDAct48V2PDActivities {
    validationClasses: string[];
    private deleteActivityModal: Modal;
    private _core: Core;

    constructor() {
        this._core = new Core();

        this.deleteActivityModal = new Modal("deleteProfessionalDevelopmentActivityModal", null);

        this.validationClasses = ["pdAct48V2PDActiviesField","professionalDevelopmentField","professionalDevelopmentFieldV2"];

        let pdAct48V2PDActiviesSaveButton = document.getElementById("pdAct48V2PDActivitiesSave");
        if (pdAct48V2PDActiviesSaveButton !== null)
            pdAct48V2PDActiviesSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        const addAct48OtherProfessionalDevelopmentV2Rows = document.getElementById("addAct48OtherProfessionalDevelopmentV2Rows");
        if (addAct48OtherProfessionalDevelopmentV2Rows !== null)
            addAct48OtherProfessionalDevelopmentV2Rows.addEventListener("click", (e: Event) => this.addOtherProfessionalDevelopmentRow());


        const that = this;
        const deleteActivityButtons = document.querySelectorAll(".deleteProfessionalDevelopmentActivityV2") as NodeListOf<HTMLButtonElement>;
        for (let button of deleteActivityButtons) {
            button.addEventListener("click", function (e) {
                e.preventDefault();
                that.deleteActivityModal.show();
                that.deleteActivityModal.addAttributeToElement(that.deleteActivityModal.id, "#deleteProfessionalDevelopmentActivityConfirm", "activityplanpropertypk", button.dataset.activityplanpropertypk);
            });
        }

        const deleteActivityConfirmButton = document.getElementById("deleteProfessionalDevelopmentActivityConfirm");
        if (deleteActivityConfirmButton !== null) {
            deleteActivityConfirmButton.addEventListener("click", function (e) {
                e.preventDefault();
                if ("activityplanpropertypk" in deleteActivityConfirmButton.dataset && parseInt(deleteActivityConfirmButton.dataset.activityplanpropertypk) > 0) {
                    that.deleteProfessionalDevelopmentActivity(deleteActivityConfirmButton.dataset.activityplanpropertypk);
                } else {
                    that.deleteActivityModal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this professional development activity", "error", 3000, null);
                }
            });
        }

        const deleteActivityCancelButton = document.getElementById("deleteProfessionalDevelopmentActivityCancel");
        if (deleteActivityCancelButton !== null) {
            deleteActivityCancelButton.addEventListener("click", function (e) {
                e.preventDefault();
                that.deleteActivityModal.hide();
            });
        }

        this.bindCreatePDActivityButtons();
        this.bindPDActionStepAddButtons();
        this.bindDanielsonAddButtons();
        this.bindAddLearningFormatButtons();
        this.bindPDActionStepRemoveButtons();
        this.bindDanielsonListRemove();
        this.bindRemoveLearningFormatButtons();
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("professionalDevelopmentForm");
        let planFK = parseInt(formElement.dataset.planfk);

        //This save needs to be split into 2 parts: 1 - The comp plan Prof Dev stuff (the top half of the page). 2 - the "other prof dev" stuff (bottom half). Since they get
        //saved in different places, we need to make sure all the data is separate.

        let goSave = false;

        // Part 1.
        let allPDActivities = [];
        let allLearningFormats = [];
        let allActionSteps = [];
        let allOthers = [];
        let learningFormatOthers = [];
        let learningFormatFrameworks = [];
        let learningFormatFrequency = [];
        let learningFormatStateRequired = [];

        let allElements = document.getElementsByClassName("professionalDevelopmentField");
        for (let element of allElements) {
            let planPropertyPK: number = 0;
            let dataPointPlanPropertyPK: number = 0;
            let buttonRow: number = 0;

            let htmlElement;
            let saveItem: IPlanPropertyExtended;

            if (element.classList.contains("actionStepList")) {
                htmlElement = <HTMLInputElement>element;
                let rowNumber: number = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;

                if (htmlElement.dataset.datapointplanpropertypk && htmlElement.dataset.datapointplanpropertypk !== "0")
                    dataPointPlanPropertyPK = parseInt(htmlElement.dataset.datapointplanpropertypk);
                else
                    dataPointPlanPropertyPK = 0;

                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;

                saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planFK,
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: "",
                    LookupCodeFK: null,
                    RowNbr: rowNumber,
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: 0,
                    ButtonRow: buttonRow
                };
            } else if (element.classList.contains("professionalDevelopmentLearningFormatActivity")) {
                htmlElement = <HTMLSelectElement>element;
                let rowNumber: number = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;

                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;

                let lookupCode: number = 0;

                if (htmlElement.value !== "") {
                    lookupCode = parseInt(htmlElement.value);
                } else {
                    lookupCode = null;
                }

                if (htmlElement.value !== "" || planPropertyPK > 0) {
                    saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: parseInt(htmlElement.dataset.propertypk),
                        TextValue: "",
                        LookupCodeFK: lookupCode,
                        RowNbr: rowNumber,
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: 0,
                        ButtonRow: buttonRow
                    };
                }
            } else if (element.classList.contains("professionalDevelopmentLearningFormatStateRequired")) {
                htmlElement = <HTMLSelectElement>element;
                let rowNumber: number = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;


                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;

                let lookupCode: number = 0;

                if (htmlElement.value !== "") {
                    lookupCode = parseInt(htmlElement.value);
                } else {
                    lookupCode = null;
                }

                if (htmlElement.value !== "" || planPropertyPK > 0) {
                    saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: parseInt(htmlElement.dataset.propertypk),
                        TextValue: "",
                        LookupCodeFK: lookupCode,
                        RowNbr: rowNumber,
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: 0,
                        ButtonRow: buttonRow
                    };
                }
            } else if (element.classList.contains("professionalDevelopmentFrequencyField")) {
                htmlElement = <HTMLSelectElement>element;
                let rowNumber: number = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;


                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;

                let toDelete = (htmlElement.value === "");

                if (htmlElement.value !== "" || planPropertyPK > 0) {
                    saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: parseInt(htmlElement.dataset.propertypk),
                        TextValue: htmlElement.value,
                        LookupCodeFK: null,
                        RowNbr: rowNumber,
                        IsDeletedInd: toDelete,
                        DataPointPlanPropertyPK: 0,
                        ButtonRow: buttonRow
                    };
                }
            } else if (element.classList.contains("addDanielsonFrameworkToListItem")) {
                htmlElement = <HTMLSelectElement>element;
                let rowNumber: number = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;

                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;

                saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planFK,
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: "",
                    LookupCodeFK: parseInt(htmlElement.dataset.lookupcodepk),
                    RowNbr: rowNumber,
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: 0,
                    ButtonRow: buttonRow
                };
            } else if (element.classList.contains("occupationalCTC")) {
                htmlElement = <HTMLSelectElement>element;
                let rowNumber: number = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;

                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;

                let value = "";
                if (htmlElement.checked) {
                    value = "on";
                } else {
                    value = "off";
                }

                saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planFK,
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: value,
                    LookupCodeFK: null,
                    RowNbr: rowNumber,
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: 0,
                    ButtonRow: buttonRow
                };

            } else {
                htmlElement = <HTMLInputElement>element;
                let rowNumber: number = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;

                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;

                let toDelete = (htmlElement.value === "");

                if (htmlElement.value !== "" || planPropertyPK > 0) {
                    saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: parseInt(htmlElement.dataset.propertypk),
                        TextValue: htmlElement.value,
                        LookupCodeFK: null,
                        RowNbr: rowNumber,
                        IsDeletedInd: toDelete,
                        DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                        ButtonRow: buttonRow
                    };
                }
            }

            if (htmlElement.classList.contains("professionalDevelopmentActivity") && saveItem !== undefined) {
                allPDActivities.push(saveItem);
                goSave = true;
            }
            else if (htmlElement.classList.contains("professionalDevelopmentLearningFormatActivity") && saveItem !== undefined) {
                allLearningFormats.push(saveItem);
                goSave = true;
            }
            else if (htmlElement.classList.contains("actionStepList") && saveItem !== undefined) {
                allActionSteps.push(saveItem);
                goSave = true;
            }
            else if (htmlElement.classList.contains("actionStepOtherField") && saveItem !== undefined) {
                learningFormatOthers.push(saveItem);
                goSave = true;
            }
            else if (htmlElement.classList.contains("professionalDevelopmentLearningFormatStateRequired") && saveItem !== undefined) {
                learningFormatStateRequired.push(saveItem);
                goSave = true;
            }
            else if (htmlElement.classList.contains("addDanielsonFrameworkToListItem") && saveItem !== undefined) {
                learningFormatFrameworks.push(saveItem);
                goSave = true;
            }
            else if (htmlElement.classList.contains("professionalDevelopmentFrequencyField") && saveItem !== undefined) {
                learningFormatFrequency.push(saveItem);
                goSave = true;
            }
            else {
                if (saveItem !== undefined && !htmlElement.classList.contains("omitCheckbox")) {
                    allOthers.push(saveItem);
                    goSave = true;
                }
            }
        }

        let saveElement = {
            "PDActivities": allPDActivities,
            "LearningFormats": allLearningFormats,
            "ActionSteps": allActionSteps,
            "LearningFormatOther": learningFormatOthers,
            "LearningFormatDanielsonFrameworks": learningFormatFrameworks,
            "LearningFormatStateRequired": learningFormatStateRequired,
            "LearningFormatFrequency": learningFormatFrequency,
            "Others": allOthers
        };

        // Part 2.

        ////Get omit property

        let omitSave: IPlanProperty = null;
        const omitCheckbox = <HTMLInputElement>document.getElementById("pdAct48V2PDActivitiesOtherOmit");
        if (omitCheckbox) {
            let rowNumber: number = parseInt(omitCheckbox.dataset.row);
            let planPropertyPK = 0;
            if (omitCheckbox.dataset.planpropertypk && omitCheckbox.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(omitCheckbox.dataset.planpropertypk);
            }

            let value = "";
            if (omitCheckbox.checked) {
                value = "on";
            } else {
                value = "off";
            }

            omitSave = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: planFK,
                PropertyFK: parseInt(omitCheckbox.dataset.propertypk),
                TextValue: value,
                LookupCodeFK: null,
                RowNbr: rowNumber,
                IsDeletedInd: false,
            }
        }

        const totalActivities = [];

        const allActivities = document.querySelectorAll(".professionalDevelopmentActivityV2");
        for (const activity of allActivities) {
            const titleElement = <HTMLInputElement>activity;

            let datapointPlanPropertyPK = 0;
            if (titleElement && titleElement.dataset.planpropertypk && titleElement.dataset.planpropertypk !== "")
                datapointPlanPropertyPK = parseInt(titleElement.dataset.planpropertypk);

            const allActivityItems = document.querySelectorAll(`.professionalDevelopmentActivityFieldV2[data-buttonrow='${titleElement.dataset.buttonrow}']`);
            const activities = [];
            let buttonRow = 0;

            const totalLearningData = [];

            for (const inside of allActivityItems) {
                let element = null;
                if (inside.classList.contains("isSelect"))
                    element = inside as HTMLSelectElement;
                else
                    element = inside as HTMLInputElement;

                const planPropertyPK = element.dataset.planpropertypk;
                const propertyPK = parseInt(element.dataset.propertypk);
                let textValue = "";
                let lookupCodePK = null;
                if (inside.classList.contains("isSelect")) {
                    textValue = "";
                    lookupCodePK = parseInt(element.value);
                }
                else {
                    textValue = element.value;
                }
                
                const rowNumber = parseInt(element.dataset.row);
                buttonRow = parseInt(element.dataset.buttonrow);

                const activityItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planFK,
                    PropertyFK: propertyPK,
                    TextValue: textValue,
                    LookupCodeFK: lookupCodePK,
                    RowNbr: rowNumber,
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: datapointPlanPropertyPK,
                    ButtonRow: buttonRow
                };

                activities.push(activityItem);
            }

            const learningFormatContainer = document.querySelectorAll(`.professionalDevelopmentLearningFormatV2[data-buttonrow='${titleElement.dataset.buttonrow}']`);
            for (const learning of learningFormatContainer) {
                const learnRow = (learning as HTMLElement).dataset.row;
                const allLearnings = learning.querySelectorAll(`.professionalDevelopmentLearningV2[data-row='${learnRow}']`);

                const allLearningData = [];

                for (const learn of allLearnings) {
                    let learnElement = null;
                    if (learn.classList.contains("isSelect"))
                        learnElement = learn as HTMLSelectElement;
                    else
                        learnElement = learn as HTMLInputElement;

                    const planPropertyPK = learnElement.dataset.planpropertypk;
                    const propertyPK = parseInt(learnElement.dataset.propertypk);
                    let textValue = "";
                    let lookupCodePK = null;
                    if (learn.classList.contains("isSelect")) {
                        textValue = "";
                        lookupCodePK = parseInt(learnElement.value);
                    }
                    else {
                        textValue = learnElement.value;
                    }

                    const learnRowNumber = parseInt(learnElement.dataset.row);
                    const learnButtonRow = parseInt(learnElement.dataset.buttonrow);

                    const learnItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: propertyPK,
                        TextValue: textValue,
                        LookupCodeFK: lookupCodePK,
                        RowNbr: learnRowNumber,
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: datapointPlanPropertyPK,
                        ButtonRow: learnButtonRow,
                    };

                    allLearningData.push(learnItem);
                }

                const learnDanielsons = [];

                const allDanielson = document.querySelectorAll(`.addDanielsonFrameworkToListItemV2[data-buttonrow='${titleElement.dataset.buttonrow}'][data-row='${learnRow}']`);
                for (const danielson of allDanielson) {
                    const danielsonElement = danielson as HTMLDivElement;
                    let danielsonPlanPropertyPK = 0;
                    if (danielsonElement.dataset.planpropertypk && danielsonElement.dataset.planpropertypk !== "")
                        danielsonPlanPropertyPK = parseInt(danielsonElement.dataset.planpropertypk);
                    const danielsonPropertyPK = parseInt(danielsonElement.dataset.propertypk);
                    const danielsonLookupCodePK = parseInt(danielsonElement.dataset.lookupcodepk);


                    const danielsonItem = {
                        PlanPropertyPK: danielsonPlanPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: danielsonPropertyPK,
                        TextValue: "",
                        LookupCodeFK: danielsonLookupCodePK,
                        RowNbr: learnRow,
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: datapointPlanPropertyPK,
                        ButtonRow: buttonRow,
                    };

                    learnDanielsons.push(danielsonItem);
                }

                totalLearningData.push({
                    "LearningFormats": allLearningData,
                    "Danielson": learnDanielsons,
                    "DataPointPlanPropertyPK": datapointPlanPropertyPK
                });
            }

            const fullActivity = {
                "Activity": activities,
                "LearningFormat": totalLearningData,
            };

            totalActivities.push(fullActivity);
        }


        var saveData = {
            "CompPlanSaveData": saveElement,
            "OtherSaveData": totalActivities,
            "PlanFK": planFK,
            "OmitData": omitSave
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/ProfessionalDevelopmentReport/SavePDAct48V2PDActivities', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planFK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        xhr.send(JSON.stringify(saveData));
    }

    addOtherProfessionalDevelopmentRow() {
        let core = this._core;
        let _that = this;
        let howManyElement = "1";
        let howMany = parseInt(howManyElement);
        let planElement = document.getElementById("otherProfessionalDevelopmentForm");
        let planFK = planElement.dataset.planfk;
        let currentRow = 0;

        let activityElements = document.getElementsByClassName("professionalDevelopmentActivityV2");
        for (let activity of activityElements) {
            let activityElement = <HTMLInputElement>activity;

            if (parseInt(activityElement.dataset.buttonrow) > currentRow) {
                currentRow = parseInt(activityElement.dataset.buttonrow);
            }
        }

        if (howMany && howMany > 0) {
            for (let i = 1; i <= howMany; i++) {
                currentRow++;
                let xhr = new XMLHttpRequest();
                xhr.open('POST', '/ProfessionalDevelopmentReport/AddNewProfessionalDevelopmentActivityV2');
                xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        $("#professionalDevelopmentContentV2").append(xhr.responseText);
                        new CustomAccordion(`professionalDevelopmentAccordion${currentRow.toString()}V2`);
                        _that.bindDanielsonAddButtons();
                        _that.bindAddLearningFormatButtons();
                        _that.bindPDActionStepAddButtons();
                        _that.bindCreatePDActivityButtons();
                        _that.bindRemoveLearningFormatButtons();
                        Core.createHTMLAlert("alertMessageDiv", "New Professional Development Activity Row Added", 'success', 3000, null);
                    } else {
                        Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                    }
                };
                xhr.send("rowNumber=" + currentRow + "&planFK=" + planFK);
            }
        }
    }

    bindRemoveLearningFormatButtons() {
        let activityButtons = document.getElementsByClassName("professionalDevelopmentDeleteLearningFormatRowV2");
        //let count = 0;
        for (let activityButton of activityButtons) {
            let element = <HTMLButtonElement>activityButton;
            element.removeEventListener("click", (e: Event) => this.removeLearningFormat(e));
            element.addEventListener("click", (e: Event) => this.removeLearningFormat(e));
            element.dataset.listener = "true";
        }
        this.showHideDeleteActivityButtons();
    }

    showHideDeleteActivityButtons() {
        let activityButtons = document.getElementsByClassName("professionalDevelopmentDeleteLearningFormatRowV2");
        let count = 0;
        for (let activityButton of activityButtons) {
            count++;
        }
        if (count > 1) {
            for (let activityButton of activityButtons) {
                let element = <HTMLButtonElement>activityButton;
                for (let activityButton of activityButtons) {
                    let element = <HTMLButtonElement>activityButton;
                    element.setAttribute("style", "visibility:visible;");
                }
            }
        }
        if (count === 1) {
            for (let activityButton of activityButtons) {
                let element = <HTMLButtonElement>activityButton;
                for (let activityButton of activityButtons) {
                    let element = <HTMLButtonElement>activityButton;
                    element.setAttribute("style", "visibility:hidden;");
                }
            }
        }
    }

    removeLearningFormat(e: Event) {
        e.stopPropagation();
        let core = this._core;
        let element: HTMLButtonElement;
        let go = true;
        element = <HTMLButtonElement>e.target;
        let rowNumber: string = element.dataset.row;
        let buttonrow: string = element.dataset.buttonrow;
        let count = 0;

        let allLearningFormats = document.getElementsByClassName("professionalDevelopmentLearningFormatV2");
        for (let learningFormat of allLearningFormats) {
            let thisElement = <HTMLDivElement>learningFormat;
            if (thisElement.dataset.buttonrow === buttonrow && thisElement.dataset.row === rowNumber) {
                thisElement.parentNode.removeChild(thisElement);
            }
        }
        this.showHideDeleteActivityButtons();
        Core.createHTMLAlert("alertMessageDiv", "Learning Format Removed", 'success', 3000, null);
    }

    bindDanielsonListRemove() {
        let activityButtons = document.getElementsByClassName("deletePDDanielsonFrameworkV2");

        for (let activityButton of activityButtons) {
            let element = <HTMLButtonElement>activityButton;

            element.removeEventListener("click", (e: Event) => this.removeDanielsonFrameworkList(e));
            element.addEventListener("click", (e: Event) => this.removeDanielsonFrameworkList(e));
            element.dataset.listener = "true";
        }
    }

    bindPDActionStepRemoveButtons() {
        let activityButtons = document.getElementsByClassName("deletePDAddedActionStepV2");

        for (let activityButton of activityButtons) {
            let element = <HTMLButtonElement>activityButton;

            element.removeEventListener("click", (e: Event) => this.removeItemFromActionStepList(e))
            element.addEventListener("click", (e: Event) => this.removeItemFromActionStepList(e));
            element.dataset.listener = "true";
        }
    }

    removeItemFromActionStepList(e: Event) {
        e.stopPropagation();
        let element: HTMLButtonElement;
        let go = true;
        element = <HTMLButtonElement>e.target;
        let rowNumber: string = element.dataset.row;

        let planPropertyPK = element.dataset.planpropertypk;

        let allActionSteps = document.getElementsByClassName("actionStepListV2");
        for (let actionStep of allActionSteps) {
            let thisElement = <HTMLDivElement>actionStep;

            if (thisElement.dataset.planpropertypk === planPropertyPK && thisElement.dataset.row === rowNumber) {
                thisElement.parentNode.removeChild(thisElement);
            }
        }
    }

    bindAddLearningFormatButtons() {
        let activityButtons = document.getElementsByClassName("professionalDevelopmentAddLearningFormatV2");

        for (let activityButton of activityButtons) {
            let element = <HTMLButtonElement>activityButton;

            if (element.dataset.listener !== "true") {
                element.addEventListener("click", (e: Event) => this.addLearningFormat(<HTMLButtonElement>e.target));
                element.dataset.listener = "true";
            }
        }
    }

    addLearningFormat(button: HTMLButtonElement) {
        let core = this._core;
        let buttonRow = parseInt(button.dataset.buttonrow);
        let newRow: number = 0;
        let learningFormatList = document.getElementsByClassName("professionalDevelopmentLearningFormatActivityV2");
        let _that = this;
        let formElement = document.getElementById("otherProfessionalDevelopmentForm");
        let planFK = formElement.dataset.planfk;
        for (let activity of learningFormatList) {
            let element = <HTMLSelectElement>activity;
            if (buttonRow === parseInt(element.dataset.buttonrow)) {
                if (newRow < parseInt(element.dataset.row)) {
                    newRow = parseInt(element.dataset.row);
                }
            }
        }

        newRow++;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/ProfessionalDevelopmentReport/AddLearningFormatV2');
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                $(".learningFormatsV2[data-buttonrow='" + buttonRow + "']").append(xhr.responseText);
                _that.bindDanielsonAddButtons();
                _that.bindRemoveLearningFormatButtons();
                Core.createHTMLAlert("alertMessageDiv", "New Learning Format Row Added", 'success', 3000, null);
//                _that.customInitializeRequiredFields();
            } else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("rowNumber=" + newRow + "&buttonRow=" + buttonRow + "&planFK=" + planFK);
    }

    bindCreatePDActivityButtons() {
        let activityButtons = document.getElementsByClassName("createProfessionalDevelopmentActivityV2");

        for (let activityButton of activityButtons) {
            let element = <HTMLButtonElement>activityButton;

            element.removeEventListener("click", (e: Event) => this.createNewPDActivity(<HTMLButtonElement>e.target))
            element.addEventListener("click", (e: Event) => this.createNewPDActivity(<HTMLButtonElement>e.target))
            element.dataset.listener = "true";
        }
    }

    createNewPDActivity(button: HTMLButtonElement) {
        let dontDoIt: boolean = false;
        let hasName: boolean = false;
        const row = button.dataset.buttonrow;
        const textElement = document.getElementsByClassName("professionalDevelopmentActivityV2");
        for (let tElement of textElement) {
            let properElement = <HTMLInputElement>tElement;
            if (properElement.dataset.row === row) {
                if (properElement.value === "") {
                    dontDoIt = true;
                } else {
                    hasName = true;
                }
            }
        }

        if (!dontDoIt) {
            let rowNumber = button.dataset.buttonrow;
            let textBoxId = `professionalDevelopmentActivity${rowNumber.toString()}V2`;
            let professionalDevelopmentNameElement = <HTMLInputElement>document.getElementById(textBoxId);
            let newAccordionTitle = "" + professionalDevelopmentNameElement.value;
            let accordionParent = document.getElementsByClassName("professionalDevelopmentAccordionV2");
            var thisElement: HTMLDivElement;

            for (let accordion of accordionParent) {
                let acc = <HTMLDivElement>accordion;
                if (acc.dataset.buttonrow == rowNumber) {
                    thisElement = acc;
                }
            }

            let theSpan = <HTMLSpanElement>thisElement.querySelector("span.Accordion-title");
            theSpan.textContent = newAccordionTitle;
            professionalDevelopmentNameElement.readOnly = true;

            //Now show the accordion.
            let professionalDevelopmentElements = document.getElementsByClassName("professionalDevelopmentAccordionV2");
            var professionalDevelopmentElement: HTMLDivElement;

            for (let ap of professionalDevelopmentElements) {
                let pde = <HTMLDivElement>ap;
                if (pde.dataset.buttonrow === rowNumber) {
                    professionalDevelopmentElement = pde;
                }
            }

            professionalDevelopmentElement.classList.remove("hide-professional-development");
            professionalDevelopmentElement.classList.add("open");
            button.classList.add("hide-professional-development");
            Core.createHTMLAlert("alertMessageDiv", "New Professional Development Activity Added", 'success', 3000, null);
//            this.customInitializeRequiredFields();
        } else if (!hasName) {
            Core.createHTMLAlert("alertMessageDiv", "A name for the activity must be provided", 'error', 3000, null);
        }
    }

    bindPDActionStepAddButtons() {
        let actionStepButtons = document.getElementsByClassName("professionalDevelopmentActionStepAddV2");

        for (let actionStepButton of actionStepButtons) {
            let element = <HTMLButtonElement>actionStepButton;

            if (element.dataset.listener !== "true") {
                element.addEventListener("click", (e: Event) => this.addActionStepToActivity(<HTMLButtonElement>e.target))
                element.dataset.listener = "true";
            }
        }
    }

    addActionStepToActivity(button: HTMLButtonElement) {
        let core = this._core;
        let _that = this;
        let rowNumber = button.dataset.buttonrow;
        let formName = document.getElementById("professionalDevelopmentForm");
        let planFK = parseInt(formName.dataset.planfk);
        let selectElements = document.getElementsByClassName("professionalDevelopmentAddActionStepV2") as HTMLCollectionOf<HTMLSelectElement>;

        let actionStepPlanPropertyPK: number;
        for (let selectElement of selectElements) {
            if (selectElement.dataset.buttonrow === rowNumber) {
                actionStepPlanPropertyPK = parseInt(selectElement.value);
            }
        }

        let isNotInList: boolean = true;

        let allListElements = document.getElementsByClassName("actionStepListV2");
        for (let listElement of allListElements) {
            let element = <HTMLDivElement>listElement;
            if (parseInt(element.dataset.planpropertypk) === actionStepPlanPropertyPK && element.dataset.buttonrow === rowNumber) {
                isNotInList = false;
            }
        }

        if (isNotInList) {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/Go/AddPDActionStepToList');
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    $(".addActionStepToListV2[data-row='" + rowNumber + "']").append(xhr.responseText);
                    _that.bindPDActionStepRemoveButtons();
                    Core.createHTMLAlert("alertMessageDiv", "New Action Step Row Added", 'success', 3000, null);
                } else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send("rowNumber=" + rowNumber + "&planFK=" + planFK + "&actionStepPlanPropertyPK=" + actionStepPlanPropertyPK);
        } else {
            Core.createHTMLAlert("alertMessageDiv", "This action step has already been added to this activity", 'error', 3000, null);
        }
    }

    bindDanielsonAddButtons() {
        let danielsonButtons = document.getElementsByClassName("professionalDevelopmentDanielsonFrameworkAddV2");

        for (let danielsonButton of danielsonButtons) {
            let element = <HTMLButtonElement>danielsonButton;

            if (element.dataset.listener !== "true") {
                element.addEventListener("click", (e: Event) => this.addDanielsonFrameworkToList(<HTMLButtonElement>e.target))
                element.dataset.listener = "true";
            }
        }
    }

    addDanielsonFrameworkToList(button: HTMLButtonElement) {
        let core = this._core;
        let _that = this;
        let rowNumber = button.dataset.row;
        let buttonrow = button.dataset.buttonrow;
        let propertyfk = button.dataset.propertypk;
        let formName = document.getElementById("otherProfessionalDevelopmentForm");
        let planFK = parseInt(formName.dataset.planfk);
        let selectElements = document.getElementsByClassName("professionalDevelopmentAddDanielsonFrameworkV2") as HTMLCollectionOf<HTMLSelectElement>;

        let lookupCodePK: number;
        for (let selectElement of selectElements) {
            if (selectElement.dataset.buttonrow === buttonrow && selectElement.dataset.row === rowNumber) {
                lookupCodePK = parseInt(selectElement.value);
            }
        }
        let isNotInList: boolean = true;

        let allListElements = document.getElementsByClassName("addDanielsonFrameworkToListItemV2");
        for (let listElement of allListElements) {
            let element = <HTMLDivElement>listElement;
            if (element.dataset.lookupcodepk === lookupCodePK.toString() && element.dataset.buttonrow === buttonrow && element.dataset.row === rowNumber) {
                isNotInList = false;
            }
        }

        //See if user has selected a danielson framework or if it's still set to default value
        let userHasSelected: boolean = true;
        if (isNaN(lookupCodePK)) {
            userHasSelected = false;
            Core.createHTMLAlert("alertMessageDiv", "A Danielson Framework must first be selected", 'error', 3000, null);
        }

        if (isNotInList && userHasSelected) {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/ProfessionalDevelopmentReport/AddDanielsonFrameworkToListV2');
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    $(".addDanielsonFrameworkToListV2[data-row='" + rowNumber + "'][data-buttonrow='" + buttonrow + "']").append(xhr.responseText);
                    _that.bindDanielsonListRemove();
                    Core.createHTMLAlert("alertMessageDiv", "New Danielson Framework Row Added", 'success', 3000, null);
                } else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send("rowNumber=" + rowNumber + "&buttonRowNumber=" + buttonrow + "&planFK=" + planFK + "&lookupCodePK=" + lookupCodePK + "&propertyfk=" + propertyfk);
        } else {
            Core.createHTMLAlert("alertMessageDiv", "This Danielson Framework item has already been added", 'error', 3000, null);
        }
    }

    removeDanielsonFrameworkList(e: Event) {
        e.stopPropagation();
        let core = this._core;
        let element: HTMLButtonElement;
        let go = true;
        element = <HTMLButtonElement>e.target;
        let rowNumber: string = element.dataset.row;
        let buttonrow: string = element.dataset.buttonrow;

        let lookupCodePK = element.dataset.lookupcodepk;

        let allLookups = document.getElementsByClassName("addDanielsonFrameworkToListItemV2");
        for (let lookup of allLookups) {
            let thisElement = <HTMLDivElement>lookup;

            if (thisElement.dataset.lookupcodepk === lookupCodePK && thisElement.dataset.buttonrow === buttonrow && thisElement.dataset.row === rowNumber) {
                thisElement.parentNode.removeChild(thisElement);
                Core.createHTMLAlert("alertMessageDiv", "Danielson Framework Removed", 'success', 3000, null);
            }
        }
    }

    addMoreProfessionalDevelopmentRows() {
        let core = this._core;
        let _that = this;
        let howManyElement = "1";
        let howMany = parseInt(howManyElement);
        let planElement = document.getElementById("otherProfessionalDevelopmentForm");
        let planFK = planElement.dataset.planfk;
        let currentRow = 0;

        let activityElements = document.getElementsByClassName("professionalDevelopmentActivityV2");
        for (let activity of activityElements) {
            let activityElement = <HTMLInputElement>activity;

            if (parseInt(activityElement.dataset.buttonrow) > currentRow) {
                currentRow = parseInt(activityElement.dataset.buttonrow);
            }
        }

        if (howMany && howMany > 0) {
            for (let i = 1; i <= howMany; i++) {
                currentRow++;
                let xhr = new XMLHttpRequest();
                xhr.open('POST', '/ProfessionalDevelopmentReport/AddNewProfessionalDevelopmentActivity');
                xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        $("#professionalDevelopmentContentV2").append(xhr.responseText);
                        new CustomAccordion(`professionalDevelopmentAccordion${currentRow.toString()}V2`);
                        _that.bindDanielsonAddButtons();
                        _that.bindAddLearningFormatButtons();
                        _that.bindPDActionStepAddButtons();
                        _that.bindCreatePDActivityButtons();
                        _that.bindRemoveLearningFormatButtons();
                        Core.createHTMLAlert("alertMessageDiv", "New Professional Development Activity Row Added", 'success', 3000, null);
                    } else {
                        Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                    }
                };
                xhr.send("rowNumber=" + currentRow + "&planFK=" + planFK);
            }
        }
    }

    deleteProfessionalDevelopmentActivity(activityPlanPropertyPK: string) {
        let that = this;
        that.deleteActivityModal.hide();

        Core.showLoader();
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Go/DeleteProfessionalDevelopmentActivity', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                Core.createHTMLAlert("alertMessageDiv", "Professional development activity successfully deleted", 'success', 3000, null);

                //remove from DOM
                let containers = document.querySelectorAll(`[data-containeractivityplanplanpropertypk='${activityPlanPropertyPK}'`);
                for (let container of containers) {
                    container.parentNode.removeChild(container);
                }

            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this activity. Please try again later.", "error", 3000, null);
            }
            Core.hideLoader();
        };
        xhr.send(activityPlanPropertyPK);
    }
}
