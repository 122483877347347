/// <reference path="../core.ts" />

class Modal {
    id: string;
    callingId: string;

    constructor(modalId: string, callingId: string) {
        this.id = modalId;
        this.callingId = callingId;
    }

    show() {
        let _that = this;
        let element = document.getElementById(this.id);
        Core.trapFocus(element);
        element.classList.add("opened");
        element.setAttribute("aria-hidden", "false");
        let closeElementId = "closeModal" + this.id;
        let closeElement = document.getElementById(closeElementId);
        closeElement.addEventListener("click", function (e) { _that.hide() });
        closeElement.addEventListener("keypress", function (e) { if (Core.a11yClick(e)) { _that.hide(); } });       
        let h1ElementId = "h1Modal" + this.id;
        let h1Element = document.getElementById(h1ElementId);
        h1Element.focus();
    }

    hide() {
        let _that = this;
        let element = document.getElementById(this.id);
        element.classList.remove("opened");
        element.setAttribute("aria-hidden", "true");
        let closeElementId = "closeModal" + this.id;
        let closeElement = document.getElementById(closeElementId);
        closeElement.removeEventListener("click", function (e) { _that.hide() });
        closeElement.removeEventListener("keypress", function (e) { if (Core.a11yClick(e)) { _that.hide(); } });
        if (this.callingId !== null) {
            let callingElement = document.getElementById(this.callingId);
            callingElement.focus();
        }
    }

    addAttributeToElement(modalId: string, elementQuery: string, attributeName: string, attributeValue: string, noDataPart: boolean = false) {
        let modalElement = <HTMLDivElement>document.getElementById(modalId);
        let targetElements = modalElement.querySelectorAll(elementQuery);
        let dataAttributeName: string;
        if (!noDataPart)
            dataAttributeName = "data-" + attributeName;
        else
            dataAttributeName = attributeName;

        for (let target of targetElements) {
            let targetElement = <HTMLElement>target;
            if (targetElement.hasAttribute(dataAttributeName)) {
                targetElement.dataset[attributeName] = attributeValue;
            } else {
                targetElement.setAttribute(dataAttributeName, attributeValue);
            }
        }
    }
}